import { createSelector, } from 'reselect'
import { propsSelector, } from './selectors'

/*** State Selectors */

const uiState = state => state.ui || {}

export const uiSelector = createSelector(
  uiState,
  ui => {
    return {
      Modal: {
        showModal : ui.Modal.showModal || false,
        modalKey  : ui.Modal.modalKey || '',
      },
    }
  }
)

export const isModalOpen = createSelector(
  uiState,
  propsSelector,
  (ui, modalKey) => {
    return ui.Modal.modalKey === modalKey && ui.Modal.showModal
  }
)

export const isNavbarOpen = createSelector(
  uiState,
  ({ Navbar, }) => {
    const { isOpen, } = Navbar || { isOpen: false, }
    return isOpen
  }
)
