import React from 'react'
import { string, } from 'prop-types'

const ExternalLink = (props) => {
  const { text, } = props
  return <a
    {...props}
    target={'_blank'}
    rel={'noreferrer noopener'}
  >
    {text}
    <i className={'fa fa-external-link-alt mx-1'} />
  </a>
}

ExternalLink.propTypes = {
  text      : string,
  className : string,
  title     : string,
}

export default React.memo(ExternalLink)