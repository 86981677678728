// Libraries
import React, { useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
} from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'

// Selectors
import { lastRouteSelector, } from '../selectors/appSelectors'
 
/**
 * Inspired by the 404 page for www.dnr.wa.gov that can be demoed here: https://www.dnr.wa.gov/a
 */
const NotFound = () => {
  const lastRoute = useSelector(lastRouteSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AppActions.setPageTitle('Page Not Found'))
  }, [ dispatch, ])

  const colSizes = useMemo(() => ({ size: '6', offset: '3', }), [])

  return (
    <Container>
      <Row className={'mt-4'}>
        <Col md={colSizes}>
          <Card className={'not-found'}>
            <CardHeader tag={'h3'}>404 Error (Page Not Found)</CardHeader>
            <CardBody>
              <CardText className={'text-center'}>
                {'You\'ve'} taken the road less traveled.
                <br/>{'Let\'s'} get you back on track.
              </CardText>
              <div className={'card-actions'}>
                <Link to={lastRoute} className={'btn btn-link'}>Back to where you were</Link>
                <Link to={'/'} className={'btn btn-secondary'}>Go to the Home Page</Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(NotFound)
