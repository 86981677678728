import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { useDispatch, useSelector, } from 'react-redux'
import { Navigate, useNavigate, } from 'react-router-dom'
import { object, bool, } from 'prop-types'

// Redux
import BurnPermitOrderActions from '../../redux/BurnPermitOrderRedux'
import UiActions from '../../redux/UiRedux'

// Components
import Instructions from '../Instructions'
import CloseModalButton from './CloseModalButton'

// Selectors
import { isModalOpen, } from '../../selectors/uiSelectors'
import { instructionsSelector, } from '../../selectors/instructionsSelectors'
import { activeBurnPermitIdSelector, } from '../../selectors/burnPermitSelectors'
import { newBurnPermitOrderId, orderIdForActivePermitSelector, } from '../../selectors/orderSelectors'


const modalkey = 'APPLICATION_NEXT_STEPS'

const ApplicationNextSteps = props => {
  const dispatch = useDispatch()

  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const { instructionStep, completedSteps, } = useSelector(instructionsSelector)
  const newPermitOrderId = useSelector(newBurnPermitOrderId)
  const orderId = useSelector(orderIdForActivePermitSelector)

  const isOpen = useSelector(state => isModalOpen(state, modalkey))

  const navigate = useNavigate()
  const { shouldOpen, } = props

  // These hooks must be declared/rendered regardless of which step
  // is set to render
  const onClick = React.useCallback(() => {
    dispatch(UiActions.closeModal())
    if (orderId) {
      navigate(`/permits/orders/${orderId}`)
    }
    else {
      dispatch(BurnPermitOrderActions.createBurnPermitOrder([ burnPermitId, ]))
    }
  }, [ dispatch, orderId, navigate, burnPermitId, ])

  const newPermit = React.useCallback(() => {
    dispatch(UiActions.closeModal())
    navigate('/permits/new')
  }, [ dispatch, navigate, ])
  
  const closeModal = React.useCallback(() => dispatch(UiActions.closeModal()), [ dispatch, ])

  if (!isOpen && shouldOpen) {
    dispatch(UiActions.openModal(modalkey))
  }
  
  if (newPermitOrderId) {
    return <Navigate to={`/permits/orders/${newPermitOrderId}`} />
  }

  let title, body, actionButton = <CloseModalButton />

  if (instructionStep === 'Apply') {
    title = 'Complete your Application'
  }
  else if (instructionStep === 'Sign') {
    title = 'Sign your Application'
  }
  else if (instructionStep === 'Pay') {
    title = 'Pay for your Application'
    if (completedSteps.includes(instructionStep)) {
      body = <>Now that you&apos;ve signed your application, you must pay the Application Fee.</>
    }

    actionButton = <>
      <Button
        color={'secondary'}
        onClick={newPermit}
      >
        Apply
      </Button>
      <Button
        className={'ml-3'}
        color={'secondary'}
        onClick={onClick}
      >
        {orderId ? 'View' : 'Create'} Order
      </Button>
    </>
  }
  else if (instructionStep === 'Review') {
    title = 'Application Review'
  }
  else if (instructionStep === 'Approved') {
    title = 'Sign your Permit'
  }
  else if (instructionStep === 'Denied') {
    title = 'Apply Again or Contact DNR'
  }
  else if (instructionStep === 'Issued') {
    title = 'Create a Burn Request'
  }

  return <Modal
    isOpen={isOpen}
    className={'applicationNextSteps'}
    style={{ maxWidth: '100%', }}
  >
    <ModalHeader tag={'h4'}
      toggle={closeModal}>Next Step: {title}</ModalHeader>
    <ModalBody>
      <p>{body}</p>
      <Instructions />
    </ModalBody>
    <ModalFooter className={'my-2 d-flex justify-content-between'}>
      {actionButton}
    </ModalFooter>
  </Modal>
}

ApplicationNextSteps.defaultProps = {
  shouldOpen : false,
  history    : {},
  location   : {},
}

ApplicationNextSteps.propTypes = {
  shouldOpen : bool,
  history    : object,
  location   : object,
}


export default ApplicationNextSteps