import React from 'react'
import { bool, func, } from 'prop-types'
import { Button, } from 'reactstrap'


const FindBurnsBtn = props => {
  const { onClick, show, } = props
  if (!show) {
    return null
  }
  return (
    <div className={'d-flex justify-content-end mb-2'}>
      <Button
        size={'sm'}
        color={'success'}
        onClick={onClick}
      >Find Burns</Button>
    </div>
  )
}

FindBurnsBtn.propTypes = {
  show    : bool,
  onClick : func,
}

export default React.memo(FindBurnsBtn)
