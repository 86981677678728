// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader, } from 'reactstrap'
import { render as ReactDOMRender, } from 'react-dom'
import { useNavigate, } from 'react-router-dom'

// Components
import DataTable from '../components/DataTable'

// Utilities
import { dateFormatter, } from '../utilities'
import { renderDate, } from '../utilities/columnHelpers'
import stopEvent from '../utilities/stopEvent'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

// format ignition date
const renderIgnitionDateTime = (data, type, row) => type === 'display' ? `${dateFormatter(data, 'MMM DD, YYYY')} ${row.PlannedIgnitionTime}` : data
// generate a unit formatter
const createCellUnitFormatter = (unit) => (data, type) => data ? type === 'display' ? `${data} ${unit}` : data : ''
// generate a null replacement formatter
const createCellNullFormatter = (other = '') => (data) => data ? data : other
// generate a date formatter
const createCellDateFormatter = () => (data, type) => data ? renderDate(data, type) : ''

// generate a navigate action to trigger when a link is clicked
const createLinkCallback = (navigate) => (e) => {
  stopEvent(e)
  if (e.target.href) {
    const pathname = e.target.href.replace(window.location.origin, '')
    navigate(pathname)
    window.scrollTo(0, 0)
  }
}

//  create a burn request link renderer
const createRequestLinkRenderer = (navigate) => {
  const cellClick = createLinkCallback(navigate)
  const renderer = (td, cellData) => {
    if (cellData) {
      return ReactDOMRender(<a href={`/burn-requests/${cellData}`} onClick={cellClick}>{cellData}</a>, td)
    }
    return td
  }
  return renderer
}

//  create a primary (id column) burn request link renderer with multi-day icon
const createPrimaryRequestLinkRenderer = (navigate) => {
  const cellClick = createLinkCallback(navigate)
  const renderer = (td, cellData, rowData) => {
    if (!cellData) {
      return td
    }
    const requestLink = `/burn-requests/${cellData}`
    let ico = null
    if (rowData.MultiDayBurnFlag) {
      ico = <i className={'fas fa-m pl-1'} aria-hidden={true} aria-label={'Multi-Day Burn'} title={'Multi-Day Burn'}></i>
    }
    return ReactDOMRender(<><a href={requestLink} className={'text-dark'} onClick={cellClick}>{cellData}</a>{ico}</>, td)
  }
  return renderer
}


// create a burn permit number renderer for the id column
const createPermitLinkRenderer = (navigate) => {
  const cellClick = createLinkCallback(navigate)
  const renderer = (td, cellData, rowData) => {
    if (cellData) {
      return ReactDOMRender(<a href={`/permits/${cellData}`} onClick={cellClick}>{rowData.BurnPermitNumber}</a>, td)
    }
    return td
  }
  return renderer
}

const ajaxError = (err) => {
  console.error(err)
  console.dir(err)
}

const COLS = [
  { title: 'Id', data: 'BurnRequestId', },
  { title: 'Permit #', data: 'BurnPermitId', },
  { title: 'Priority', data: 'BurnPriority', render: createCellNullFormatter(), },
  { title: 'Date Requested', data: 'BurnRequestDate', render: createCellDateFormatter(), },
  { title: 'Ignition Date',  data: 'PlannedIgnitionDate', render: renderIgnitionDateTime, },
  { title: 'Burn Quantity', data: 'TotalProposedBurnQuantity', render: createCellUnitFormatter('tons'), },
  { title: 'Burn Area', data: 'ProposedBurnArea', render: createCellUnitFormatter('acres'), },
  { title: 'Date Updated', data: 'UpdateDate', render: createCellDateFormatter(), },
  { title: 'Parent', data: 'ParentRequestId', render: createCellNullFormatter(), },
  { title: 'Status', data: 'BurnRequestStatusName', render: createCellNullFormatter('Not Submitted'), },
]

const buildColumnDefs = (navigate) => [
  { targets: 0, createdCell: createPrimaryRequestLinkRenderer(navigate), className: 'text-nowrap pr-2', },
  { targets: 1, createdCell: createPermitLinkRenderer(navigate), },
  { targets: 8, createdCell: createRequestLinkRenderer(navigate), },
]

const RelatedBurnRequests = ({ 
  requestId = 0,
  requestStatus = '',
  updateDate = '',
}) => {
  const navigate = useNavigate()
  const dataTable = React.useMemo(() => {
    let dt = null
    if (requestId && requestId > 0) {
      const columnDefs = buildColumnDefs(navigate)
      dt = <DataTable
        columns={COLS}
        columnDefs={columnDefs}
        ajaxConfig={{
          url  : `${REACT_APP_SERVER_URL}BurnRequests/${requestId}/Related`,
          data : {},
        }}
        elementId={'related-requests-table'}
        disableRowClick={true}
        disableDefaultOrdering={true}
        onAjaxError={ajaxError}
      />
    }
    return dt
  // request status and update date is in the deps list to refresh when status changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ requestId, requestStatus, updateDate, navigate, ])

  if (!requestId || requestId < 1) {
    return null
  }

  return (
    <Card>
      <CardHeader>
        <h5>Related Burn Requests</h5>
      </CardHeader>
      <CardBody>
        {dataTable}
      </CardBody>
    </Card>
  )
}

RelatedBurnRequests.propTypes = {
  requestId     : PropTypes.number,
  requestStatus : PropTypes.string,
  updateDate    : PropTypes.string,
}

export default RelatedBurnRequests