import { createActions, } from 'reduxsauce'
    
/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  getForestHealthData    : [ 'forestHealthId', ],
  createForestHealthData : [ 'burnPermitId', 'forestHealthData', ],
  updateForestHealthData : [ 'burnPermitId', 'exemptId', 'forestHealthData', ],
})
  
export const ForestHealthTypes = Types
export default Creators