import React, { useCallback, useEffect, useMemo, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, useParams, } from 'react-router-dom'
import { Field, Form, Formik, } from 'formik'
import { bool, } from 'prop-types'
import { Button, Card, CardBody, CardText, Col, Container, FormGroup, Label, Row, } from 'reactstrap'

// Hooks
import { useInterval, useShowModal, } from '../../../hooks'

// Redux
import MapActions from '../../../redux/MapRedux'
import AppActions from '../../../redux/AppRedux'
import CustomerInteractionActions from '../../../redux/CustomerInteractionRedux'

// Components
import TourSteps from './TourSteps'
import LatLongEffect from './LatLongEffect'
import FollowUpForm from './FollowUpForm'
import ValidationSchema from './ValidationSchema'
import { LinkBurnRequest, } from './LinkBurn'
import CancelInteraction from '../CancelInteraction'
import CustomerInteractionsMap from '../CustomerInteractionsMap'
import StatusDialog from '../Status/StatusDialog'
import {
  FormTour,
  RequiredLabel,
  ValidatingField,
  YesNoButtonGroup,
} from '../../FormControls'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'
import { userIsDNR, } from '../../../selectors/userSelectors'
import {
  createdInteractionIdSelector,
  customerInteractionStateSelector,
  getSmokeComplaintSelector,
  smokeCompMapStateSelector,
  userAcknowledgedSmokeComplaintPrompt,
} from '../../../selectors/customerInteractionSelectors'

// Models
import BurnPermitLocation from '../../../models/BurnPermitLocation'

// Icons
import RequiredIndicator from '../../Icons/RequiredIndicator'


const { Latitude, Longitude, } = BurnPermitLocation.fields


const interactionType = 'Smoke'

const StatusBtn = props => {
  const dispatch = useDispatch()
  const [ , setModalOpen, ] = useShowModal(dispatch, 'CUSTOMER_INTERACTION_STATUS_DIALOG')
  const statusClick = useCallback(() => (setModalOpen()), [ setModalOpen, ])
  if (!props.userIsDnr) {
    return null
  }

  return <Button
    className={'mr-2'}
    color={'dark'}
    onClick={statusClick}
    size={'md'}
  >Change Status</Button>
}

StatusBtn.propTypes = {
  userIsDnr: bool,
}

const SmokeForm = () => {
  const { id, } = useParams()
  const [ mapCheckDelay, setMapCheckDelay, ] = useState(300)
  const [ showTour, setShowTour, ] = useState(false)

  // Selectors
  const online = useSelector(appIsOnlineSelector)
  const userIsDnr = useSelector(userIsDNR)
  const successId = useSelector(createdInteractionIdSelector)
  const acknowledged = useSelector(userAcknowledgedSmokeComplaintPrompt)
  const smokeComplaint = useSelector(state => getSmokeComplaintSelector(state, id))
  const { tourCompleted, } = useSelector(state => customerInteractionStateSelector(state, interactionType))
  const { location, regionId, } = useSelector(smokeCompMapStateSelector)

  // Redux actions
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // mount effect
  useEffect(() => {
    let title = 'Smoke Complaint'
    if (id) {
      title += ` #${id}`
    }
    else {
      title = `New ${title}`
    }
    dispatch(AppActions.setPageTitle(title))
    dispatch(MapActions.clearLatLong())
    dispatch(CustomerInteractionActions.newCustomerInteraction(interactionType))
  }, [ id, dispatch, ])

  useEffect(() => {
    if (userIsDnr !== true) {
      if (!id && successId > 0) {
        navigate('/smokecomplaints/success')
      }
      else if (parseInt(id) > 0 && !successId) {
        navigate('/')
      }
      return
    }
    if (!id && successId > 0) {
      navigate(`/smokecomplaints/${successId}`)
    }
    else if (parseInt(id) > 0) {
      dispatch(CustomerInteractionActions.getCustomerInteraction(id))
    }
  }, [ successId, id, userIsDnr, dispatch, navigate, ])

  useEffect(() => {
    if (smokeComplaint.Latitude && smokeComplaint.Longitude) {
      dispatch(MapActions.setLatLong(smokeComplaint.Latitude, smokeComplaint.Longitude))
    }
  }, [ smokeComplaint.Latitude, smokeComplaint.Longitude, dispatch, ])

  useEffect(() => {
    if ((smokeComplaint && (!smokeComplaint.Latitude || !smokeComplaint.Longitude)) && (location && location.x && location.y)) {
      dispatch(MapActions.setLatLong(location.y.toFixed(5), location.x.toFixed(5)))
    }
  }, [ location, smokeComplaint, dispatch, ])

  const formIsReadOnly = useMemo(() => {
    if (!online) {
      return true
    }
    if (userIsDnr !== true && parseInt(id) > 0) {
      return true
    }
    return false
  }, [ online, userIsDnr, id, ])

  const fieldIsReadOnly = useMemo(() => {
    if (!online) {
      return true
    }
    if (userIsDnr && parseInt(id) > 0) {
      return true
    }
    return false
  }, [ online, userIsDnr, id, ])

  const handleReset = useCallback(values => {
    const { Latitude, Longitude, } = values
    dispatch(MapActions.setLatLong(Latitude, Longitude))
  }, [ dispatch, ])

  const submitComplaint = useCallback(values => {
    dispatch(CustomerInteractionActions.submitSmokeComplaint({
      ...values,
      RegionId: values.RegionId || regionId || '',
    }))
  }, [ regionId, dispatch, ])

  const acknowledgeSmokePrompt = useCallback(() => {
    dispatch(CustomerInteractionActions.smokePromptAcknowledged())
  }, [ dispatch, ])

  const onFinished = useCallback(() => (
    // Need to set our running state to false, so we can restart if we click start again.
    dispatch(CustomerInteractionActions.tourCompleted(interactionType))
  ), [ dispatch, ])

  const updateLocation = useCallback((e) => {
    const { name, value, } = e.target
    let _Latitude, _Longitude
    if (name === 'Latitude') {
      _Latitude = value || ''
    }
    else {
      _Longitude = value || ''
    }
    dispatch(MapActions.setLatLong(_Latitude, _Longitude))
  }, [ dispatch, ])

  const getLatLong = useCallback((e) => {
    const { value, } = e.target
    if (!smokeComplaint.Latitude || !smokeComplaint.Longitude) {
      dispatch(CustomerInteractionActions.geocodeCustIntAddress(value))
    }
  }, [ smokeComplaint, dispatch, ])

  const acknowledgeSize = useMemo(() => ({ offset: 3, size: 6, }), [])

  useInterval(() => {
    const layerListExists = document.getElementsByClassName('esri-icon-layer-list')
    if (layerListExists.length) {
      setMapCheckDelay(null)
      setShowTour(true)
    }
    else {
      setMapCheckDelay(mapCheckDelay)
    }
  }, mapCheckDelay)

  const headerText = useMemo(() => {
    let _headerText = 'Smoke Complaint'
    if (id && parseInt(id) > 0) {
      _headerText += ` #${id}`
    }
    else {
      _headerText = `New ${_headerText}`
    }
    return _headerText
  }, [ id, ])

  const tourSteps = useMemo(() => (TourSteps({ isDnr: userIsDnr, })), [ userIsDnr, ])
  const flexEndStyle = useMemo(() => ({ marginTop: '-1em', columnGap: '1.5em', }), [])

  if (acknowledged !== true && userIsDnr !== true) {
    return <Container className={'py-4'}>
      <h1>{headerText}</h1>
      <Row>
        <Col md={acknowledgeSize}>
          <Card>
            <CardBody>
              <CardText>
                Please provide as much detail as possible about the nature of your complaint 
                including the following details:
              </CardText>
              <ul>
                <li>A description of the impacts, duration of the event, etc.</li>
                <li>An address where the event occurred and/or</li>
                <li>Latitude and Longitude of the location of the event</li>
                <li>Contact information if you wish to have DNR follow up with you</li>
              </ul>
              <Row>
                <Col className={'d-flex justify-content-end'}>
                  <Button
                    color={'secondary'}
                    onClick={acknowledgeSmokePrompt}
                  >I acknowledge</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  }

  const submitText = userIsDnr
    ? 'Modify'
    : 'Submit'

  const markup = <Container className={'py-4'}>
    <Row>
      <Col className={'d-flex align-items-start'}>
        <h1>
          {headerText}
        </h1>
        <FormTour
          onFinished={onFinished}
          run={showTour}
          steps={tourSteps}
          tourCompleted={tourCompleted}
        />
      </Col>
      <Col className={'d-flex align-items-center justify-content-end'} style={flexEndStyle}>
        <LinkBurnRequest
          CustomerInteractionId={id}
          userIsDnr={userIsDnr}
        />
        <div className={'d-flex align-items-center'}><StatusDialog CustomerInteractionId={id} /></div>
      </Col>
    </Row>
    <Formik
      initialValues={smokeComplaint}
      validationSchema={ValidationSchema}
      onReset={handleReset}
      onSubmit={submitComplaint}
      readOnly={formIsReadOnly}
      enableReinitialize={true}
    >
      <Form>
        <LatLongEffect />
        <Row>
          <Col xs={12} md={6}>
            <CustomerInteractionsMap
              layerTitle={'Smoke Complaint Location'}
              mapConfig={'SmokeComplaints'}
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <small className={'d-block mt-1 mb-3'}>All labels with the<RequiredIndicator /> indicate required inputs.</small>
                    <FormGroup>
                      <RequiredLabel labelFor={'InteractionDescription'}>
                        Provide a brief description of the Smoke Event you observed.
                      </RequiredLabel>
                      <Field
                        id={'InteractionDescription'}
                        name={'InteractionDescription'}
                        type={'textarea'}
                        component={ValidatingField}
                        readOnly={fieldIsReadOnly}
                      />
                    </FormGroup>
                    <Row>
                      <Col xs={12}>
                        <p><small>Click anywhere on the map to modify the Lat/Long</small></p>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for={'Latitude'}>Latitude</Label>
                          <Field
                            name={'Latitude'}
                            type={'number'}
                            step={0.000001}
                            min={Latitude.opts.min}
                            max={Latitude.opts.max}
                            component={ValidatingField}
                            onBlur={updateLocation}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for={'Longitude'}>Longitude</Label>
                          <Field
                            name={'Longitude'}
                            type={'number'}
                            step={0.000001}
                            min={Longitude.opts.min}
                            max={Longitude.opts.max}
                            component={ValidatingField}
                            onBlur={updateLocation}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for={'Address'}>Address</Label>
                      <Field
                        id={'Address'}
                        name={'Address'}
                        type={'textarea'}
                        onBlur={getLatLong}
                        component={ValidatingField}
                      />
                    </FormGroup>
                    <FormGroup>
                      <YesNoButtonGroup
                        name={'FollowUp'}
                        readOnly={fieldIsReadOnly}
                      >
                        <RequiredLabel labelFor={'FollowUp'}>
                          Would you like a DNR Representative follow-up with you?
                        </RequiredLabel>
                      </YesNoButtonGroup>
                    </FormGroup>
                    <FollowUpForm readOnly={fieldIsReadOnly} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'py-3'}>
              <Col className={'d-flex justify-content-between'}>
                <div>
                  <CancelInteraction interactionType={'Smoke Complaint'} />
                  <Button
                    type={'reset'}
                    color={'link'}
                    size={'md'}
                  >Reset</Button>
                </div>
                <div>
                  <StatusBtn userIsDnr={userIsDnr} />
                  <Button
                    type={'submit'}
                    size={'md'}
                    color={'secondary'}
                  >{submitText}</Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Formik>
  </Container>
  return markup
}

export default React.memo(SmokeForm)
