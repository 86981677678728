// Libraries
import React from 'react'
import { number, string, } from 'prop-types'
import { useDispatch, } from 'react-redux'

// Components
import DocuSignModal from './DocuSignModal'

// Redux
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'


const ReIssuePermit = props => {
  const {
    burnPermitId,
    className,
  } = props

  const dispatch = useDispatch()

  const reissuePermit = React.useCallback(() => {
    dispatch(BurnPermitSignatureActions.reIssuePermit(burnPermitId))
  }, [ dispatch, burnPermitId, ])

  return <DocuSignModal
    onClick={reissuePermit}
    className={className}
    modalHeader={'Re-Issue Burn Permit'}
    buttonText={'Re-Issue Permit'}
    signatureType={SignatureProcessTypes.reissue}
  />
}

ReIssuePermit.defaultProps = {
  burnPermitId : '',
  className    : '',
}

ReIssuePermit.propTypes = {
  burnPermitId : number,
  className    : string,
}

export default ReIssuePermit