import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  clearObjectIds       : null,
  setObjectOneId       : [ 'ObjectId', ],
  setObjectTwoId       : [ 'ObjectId', ],
  fetchingDataForMerge : [ 'isFetchingData', ],
})

export const MergeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Object.freeze({
  ObjectOneId    : null,
  ObjectTwoId    : null,
  isFetchingData : false,
})

/* ------------- Reducers ------------- */

export const clearObjectIds = () => {
  return INITIAL_STATE
}

export const fetchingData = (state, { isFetchingData = false, }) => {
  return { ...state, isFetchingData, }
}

export const setObjectOneId = (state, { ObjectId, }) => {
  return { ...state, ObjectOneId: ObjectId, }
}

export const setObjectTwoId = (state, { ObjectId, }) => {
  return { ...state, ObjectTwoId: ObjectId, }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_OBJECT_IDS]        : clearObjectIds,
  [Types.SET_OBJECT_ONE_ID]       : setObjectOneId,
  [Types.SET_OBJECT_TWO_ID]       : setObjectTwoId,
  [Types.FETCHING_DATA_FOR_MERGE] : fetchingData,
})