import { RED, GRAY, PRIMARY_BLUE, PRIMARY_ORANGE, GREEN, } from './colors'

export const POLYGON_SYMBOL = {
  type    : 'simple-fill',
  color   : [ ...PRIMARY_BLUE, 0.25, ],
  style   : 'solid',
  outline : {
    color : [ ...PRIMARY_BLUE, ],
    width : 1,
  },
}

export const POINT_SYMBOL = {
  type    : 'simple-marker',
  style   : 'circle',
  size    : 8,
  color   : [ ...PRIMARY_ORANGE, ],
  outline : {
    color : [ ...PRIMARY_BLUE, ],
    width : .5,
  },
}

export const POLYLINE_SYMBOL = {
  type  : 'simple-line',
  color : [ ...PRIMARY_BLUE, ],
  width : 2,
}

export const DOCUMENTATION_SYMBOL = {
  type      : 'text',
  text      : '\ue64b',
  haloColor : '#fff',
  haloSize  : 1,
  font      : {
    family : 'CalciteWebCoreIcons',
    size   : 14,
  },
}

export const SUBMITTED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: PRIMARY_ORANGE,
}

export const INFO_REQUIRED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: PRIMARY_BLUE,
}

export const UNDER_REVIEW_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
}

export const DENIED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: [ ...RED, ],
}

export const APPROVED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: GREEN,
}

export const ISSUED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: PRIMARY_BLUE,
}

export const SUSPENDED_SYMBOL = {
  ...DOCUMENTATION_SYMBOL,
  color: GRAY,
}

export const GetPermitSymbolByStatus = (status, IsUGA) => {
  let symbol
  switch (status) {
  case 'Submitted':
    symbol = { ...SUBMITTED_SYMBOL, }
    break
  case 'Under Review':
    symbol = { ...UNDER_REVIEW_SYMBOL, }
    break
  case 'Denied':
  case 'Revoked':
    symbol = { ...DENIED_SYMBOL, }
    break
  case 'Info Required':
    symbol = { ...INFO_REQUIRED_SYMBOL, }
    break
  case 'Approved':
    symbol = { ...APPROVED_SYMBOL, }
    break
  case 'Extended':
  case 'Issued':
    symbol = { ...ISSUED_SYMBOL, }
    break
  case 'Suspended':
    symbol = { ...SUSPENDED_SYMBOL, }
    break
  case 'Pending':
  case 'Expired':
  default:
    symbol = { ...DOCUMENTATION_SYMBOL, }
  }
  if (IsUGA) {
    symbol.text = IN_UGA_SYMBOL.text
  }
  return symbol
}



export const PIN_SYMBOL = {
  type : 'text',
  text : '\ue61d',
  font : {
    family : 'CalciteWebCoreIcons',
    size   : 14,
  },
}

export const REQUEST_SUBMITTED_SYMBOL = {
  ...PIN_SYMBOL,
  color: PRIMARY_BLUE,
}

export const REQUEST_UNDER_REVIEW_SYMBOL = {
  ...PIN_SYMBOL,
  color: PRIMARY_BLUE,
}

export const REQUEST_INFO_REQUIRED_SYMBOL = {
  ...PIN_SYMBOL,
  color: PRIMARY_ORANGE,
}

export const REQUEST_DENIED_SYMBOL = {
  ...PIN_SYMBOL,
  color: RED,
}

export const REQUEST_APPROVED_SYMBOL = {
  ...PIN_SYMBOL,
  color: GREEN,
}

// eslint-disable-next-line no-unused-vars
export const GetRequestSymbolByStatus = (status, IsUGA) => {
  let symbol
  switch (status) {
  case 'Submitted':
    symbol = { ...REQUEST_SUBMITTED_SYMBOL, }
    break
  case 'Under Review':
    symbol = { ...REQUEST_UNDER_REVIEW_SYMBOL, }
    break
  case 'Info Required':
    symbol = { ...REQUEST_INFO_REQUIRED_SYMBOL, }
    break
  case 'Denied':
    symbol = { ...REQUEST_DENIED_SYMBOL, }
    break
  case 'Approved':
    symbol = { ...REQUEST_APPROVED_SYMBOL, }
    break
  case 'Cancelled':
  default:
    symbol = { ...PIN_SYMBOL, }
  }
  if (IsUGA) {
    symbol.text = IN_UGA_SYMBOL.text
  }
  return symbol
}

export const POST_BURN_SYMBOL = {
  ...PIN_SYMBOL,
  color: PRIMARY_ORANGE,
}

export const IN_UGA_SYMBOL = {
  ...PIN_SYMBOL,
  text     : '\ue687', //esri-icon-organization
  color    : '#000000',
  haloSize : '3px',
  font     : {
    family : 'CalciteWebCoreIcons',
    size   : 16,
    weight : 'lighter',
  },
}