import React from 'react'
import { Button, } from 'reactstrap'

const Sign = props => {
  const { paysByVoucher, } = props || {}
  return {
    label   : 'Sign',
    caption : <>
      <p>
        Once all sections are completed with all required data, you must click the <Button size={'sm'} color={'secondary'} className={'readonly'}>Sign</Button>&nbsp;
        button in the <b>Signature</b> section to sign your application in DocuSign.
      </p>
      <p>Once you&apos;ve signed, you can download a copy of your signed Application at any time in the <b>Signature</b> section.</p>
      <p>Click the <Button size={'sm'} color={'primary'} className={'readonly'}>Unlock</Button> on the application to modify it after you&apos;ve signed.</p>
      {
        !paysByVoucher
          ? <b>Once you pay, you cannot unlock your application. If you unlock your application, you will need to sign again.</b>
          : <b>Once your application signature is confirmed, it will be submitted to DNR for review.</b>
      }
      
    </>,
    icon  : 'fas fa-file-signature',
    color : 'secondary',
  }
}

export default Sign