// Libraries
import { put, select, call, all, fork, spawn, takeLatest, } from 'redux-saga/effects'

// Sagas
import { fetchAgencyData, } from './AgencySagas'
import { getAllAgencies, } from './AgencySagas'
import { hideLoading, showLoading, } from './AppSagas'
import { doFetch, showError, checkModelConcurrency, } from './ApiSagas'
import { replaceAll, } from './OrmSagas'
import { updateLocalModel, } from './OrmSagas'
import { getAllAgents, getAllLandowners, getAgentInfo, getPerson, } from './PersonSagas'
import { getBurnPermitStatusHistory, } from './BurnPermitSagas'

// Reducers
import ApiActions, { ApiTypes, } from '../redux/ApiRedux'
import { ApplicantInfoTypes, } from '../redux/ApplicantInfoSectionRedux'
import { BurnPermitFormTypes, } from '../redux/BurnPermitFormRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'

// Models
import BurnPermit from '../models/BurnPermit'
import BurnPermitSearch from '../models/BurnPermitSearch'

// Selectors
import { applInfoStateSelector, } from '../selectors/applicantInfoSelectors'
import { userNeedsLandownersOffline, } from '../selectors/permissionSelectors'
import { networkStateSelector, } from '../selectors/selectors'
import { modelByIdSelector, getModelEndpoint, } from '../selectors/modelSelectors'
import { activeBurnPermitIdSelector, permitApplicationStatus, } from '../selectors/burnPermitSelectors'
import { userCanSelectPermitLandowner, } from '../selectors/burnPermitPermissionSelectors'


// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const BURN_PERMIT_MODEL_NAME = BurnPermit.modelName
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName


function* getPersonContactData (modelName) {
  let modelEndpoint = yield select(getModelEndpoint, modelName)

  if (modelEndpoint.indexOf(REACT_APP_SERVER_URL) === -1) {
    modelEndpoint = `${REACT_APP_SERVER_URL}${modelEndpoint}`
  }

  const isConcurrent = yield call(checkModelConcurrency, modelEndpoint, modelName)
  if (isConcurrent) {
    return isConcurrent
  }

  const response = yield call(doFetch, modelEndpoint)

  const { responseBody, } = response

  if (Array.isArray(responseBody)) {
    yield fork(replaceAll, modelName, responseBody)
  }
}

export function* downloadApplicantInfo () {
  try {

    // Get the related contact data and write them to the local orm
    yield spawn(getPersonContactData, 'Phone')
    yield spawn(getPersonContactData, 'Address')
    yield spawn(getPersonContactData, 'Email')

    // Get the agents and landowners, if authorized, and write them to the local orm
    const forceRefreshFromServer = true
    const personReqs = [ call(getAllAgents, forceRefreshFromServer), call(getAllAgencies, forceRefreshFromServer), ]
    const downloadLandowners = yield select(userNeedsLandownersOffline)
    if (downloadLandowners) {
      personReqs.push(call(getAllLandowners, forceRefreshFromServer))
    }

    yield all(personReqs)
    yield call(getAllPeopleLookupData)

    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'ApplicantInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}


function* getAllPeopleLookupData () {
  yield call(showLoading)
  // Get the xrefs to link up the related data
  const xrefResp = yield call(doFetch, `${REACT_APP_SERVER_URL}People/Xrefs`)
  if (xrefResp.ok === false) {
    yield call(showError, 'An error occurred getting contact information for applicants.')
    return
  }
  const { addressXrefs, phoneXrefs, alertPrefsXrefs, agencyXrefs, } = xrefResp.responseBody
  
  if (Array.isArray(addressXrefs)) {
    // These are virtual models so we have to manipulate the respone objects to match the local orm keys
    yield spawn(replaceAll, 'PersonAddresses', addressXrefs.map(x => { return { fromPersonId: x.PersonAddressXrefPersonId, toAddressId: x.PersonAddressXrefAddressId, }}))
  }
  if (Array.isArray(phoneXrefs)) {
    // These are virtual models so we have to manipulate the respone objects to match the local orm keys
    yield spawn(replaceAll, 'PersonPhones', phoneXrefs.map(x => { return { fromPersonId: x.PersonPhoneXrefPersonId, toPhoneId: x.PersonPhoneXrefPhoneId, }}))
  }
  if (Array.isArray(alertPrefsXrefs)) {
    // This is an explicit model that the response object matches
    yield spawn(replaceAll, 'PersonAlertPreferenceXref', alertPrefsXrefs)
  }
  if (Array.isArray(agencyXrefs)) {
    // This is an explicit model that the response object matches
    yield spawn(replaceAll, 'PersonAgencyXref', agencyXrefs)
  }
  yield call(hideLoading)
}


function* getApplicantInfoData () {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    yield call(showLoading)
    const BurnPermitId = yield select(activeBurnPermitIdSelector)
    let appStatus = yield select(permitApplicationStatus, BurnPermitId)
    // If we don't have the statuses, go get them since there is logic
    // later in this saga that is dependent on that status value
    if (!appStatus) {
      yield call(getBurnPermitStatusHistory, { burnPermitId: BurnPermitId, })
      // Set the status now that we have it
      appStatus = yield select(permitApplicationStatus, BurnPermitId)
    }
    const forceRefresh = false, basicInfo = true
    let reqs = [], activeOnly = null
    // Only get the Agents, Agencies, and Landowners if the app is pending
    if (appStatus && appStatus.Status === 'Pending') {
      activeOnly = true // Only get the active addresses and phones if the application is still pending
      reqs = [ call(getAllAgents, forceRefresh, basicInfo), call(getAllAgencies, forceRefresh, basicInfo), ]
      const canSelectLandowner = yield select(userCanSelectPermitLandowner)
      if (canSelectLandowner) {
        reqs.push(call(getAllLandowners, forceRefresh, basicInfo))
      }
    }
    yield all(reqs)
    reqs = []
    // Set the ApplicantInfo state slice with the info from the permit
    yield call(setApplicantInfo, { BurnPermitId, })
    const { landownerId, agentId, agencyId, } = yield select(applInfoStateSelector)
    if (landownerId && landownerId > 0) {
      reqs.push(call(getPerson, { personId: landownerId, activeOnly, }))
    }
    if (agentId && agentId > 0) {
      reqs.push(call(getAgentInfo, { personId: agentId, activeOnly, }))
    }
    if (agencyId && agencyId > 0) {
      reqs.push(call(setSelectedAgency))
    }
    yield all(reqs)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield all([
      call(hideLoading),
      put({ type: ApplicantInfoTypes.SET_APPLICANT_SECTION_LOADING, loading: false, }),
    ])
  }
}


function* setApplicantInfo ({ BurnPermitId, }) {
  const burnPermit = yield select(modelByIdSelector, { modelName: BURN_PERMIT_MODEL_NAME, modelId: BurnPermitId, })
  if (burnPermit) {
    const applicantInfo = {
      mailToAgent: burnPermit.MailPermitToAgentFlag,
    }
    if (burnPermit.BurnerId) {
      applicantInfo.landownerId = burnPermit.BurnerId
    }
    if (burnPermit.AgentId) {
      applicantInfo.agentId = burnPermit.AgentId
    }
    if (burnPermit.AgencyId) {
      applicantInfo.agencyId = burnPermit.AgencyId
    }
    yield put({ type: ApplicantInfoTypes.SET_SELECTED_APPLICANT_INFO, applicantInfo, })
  }
}



function* updateApplicantInfo ({ info, }) {
  const { online, } = yield select(networkStateSelector)
  const { BurnPermitId, } = info
  const localBurnPermit = yield select(modelByIdSelector, { modelName: BURN_PERMIT_MODEL_NAME, modelId: BurnPermitId, })
  const burnPermit = {
    ...localBurnPermit._fields,
    BurnPermitId,
    MailPermitToLandownerFlag    : info.MailPermitToLandownerFlag,
    BurnerId                     : info.BurnerId,
    BurnerPermitAddressId        : info.BurnerPermitAddressId,
    BurnerPermitPrimaryPhoneId   : info.BurnerPermitPrimaryPhoneId,
    BurnerPermitSecondaryPhoneId : info.BurnerPermitSecondaryPhoneId,
    MailPermitToAgentFlag        : info.MailPermitToAgentFlag,
    AgencyId                     : info.AgencyId,
    AgentId                      : info.AgentId,
    AgentPermitAddressId         : info.AgentPermitAddressId,
    AgentPermitPrimaryPhoneId    : info.AgentPermitPrimaryPhoneId,
    AgentPermitSecondaryPhoneId  : info.AgentPermitSecondaryPhoneId,
  }
  if (!localBurnPermit.IsLocal) {
    const url = `${BURN_PERMIT_ENDPOINT}/ApplicantInfo`
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : ApiTypes.UPDATE_RECORD_REQUEST,
        url         : url,
        method      : 'PUT',
        keyName     : BurnPermit.options.idAttribute,
        keyValue    : burnPermit.BurnPermitId,
      })
    }

    yield put(ApiActions.updateRecordRequest(BURN_PERMIT_MODEL_NAME, url, burnPermit, online))
  }
  if (!online) {
    const updatedPermitInfo = yield call(updateLocalModel, BURN_PERMIT_MODEL_NAME, BurnPermitId, burnPermit)
    // update local search result so the My Permits and Search results show the pending updates
    const updateSearchObj = {}
    if (updatedPermitInfo.Burner) {
      updateSearchObj.Landowner = updatedPermitInfo.Burner.toString()
    }
    if (updatedPermitInfo.Agent) {
      updateSearchObj.Agent = updatedPermitInfo.Agent.toString()
    }
    if (updatedPermitInfo.Agency) {
      updateSearchObj.Agency = updatedPermitInfo.Agency.toString()
    }
    
    yield call(updateLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, BurnPermitId, updateSearchObj)
  }
}

function* setSelectedAgency () {
  const { online, } = yield select(networkStateSelector)
  const { agencyId, } = yield select(applInfoStateSelector)
  if (!online || !agencyId || agencyId < 1) {
    return
  }
  // Get the full set of data so we get the email, address, and phone IDs
  yield call(fetchAgencyData, { agencyId, basicInfo: false, })
}

function* setSelectedAgent ({ personId, }) {
  const { online, } = yield select(networkStateSelector)
  if (!online || !personId || personId === -1) {
    return
  }
  yield call(getPerson, { personId, activeOnly: true, })
}

function* setSelectedLandowner ({ personId, }) {
  const { online, } = yield select(networkStateSelector)
  if (!online || !personId || personId === -1) {
    return
  }
  yield call(getPerson, { personId, activeOnly: true, })
}

export const BurnPermitApplicantInfoSagas = [
  takeLatest(ApplicantInfoTypes.SET_SELECTED_AGENCY_ID, setSelectedAgency),
  takeLatest(ApplicantInfoTypes.SET_SELECTED_AGENT_ID, setSelectedAgent),
  takeLatest(ApplicantInfoTypes.SET_SELECTED_LANDOWNER_ID, setSelectedLandowner),
  takeLatest(ApplicantInfoTypes.UPDATE_APPLICANT_INFO, updateApplicantInfo),
  takeLatest(ApplicantInfoTypes.GET_APPLICANT_INFO_DATA, getApplicantInfoData),
  takeLatest(ApplicantInfoTypes.SET_APPLICANT_INFO, setApplicantInfo),
  takeLatest(BurnPermitFormTypes.ACTIVE_BURN_PERMIT, setApplicantInfo),
]