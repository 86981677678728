import { attr, Model, } from 'redux-orm'

export default class BurnIgnitionType extends Model {
  static get modelName () {
    return 'BurnIgnitionType'
  }

  toString () {
    return `${this.BurnIgnitionTypeName}`
  }

  static endpoint () {
    return 'BurnIgnitionTypes'
  }

  static get options () {
    return {
      idAttribute: 'BurnIgnitionTypeId',
    }
  }

  static get fields () {
    return {
      BurnIgnitionTypeId          : attr({ type: 'number', }),
      BurnIgnitionTypeName        : attr(),
      BurnIgnitionTypeDescription : attr(),
      CreateBy                    : attr(),
      CreateDate                  : attr(),
      UpdateBy                    : attr(),
      UpdateDate                  : attr(),
    }
  }
}