import { createSelector, } from 'reselect'
import { propsSelector, } from './selectors'

/*** State Selectors */

const instructionsState = state => state.BurnPermitInstructions || {}

export const instructionsSelector = createSelector(
  instructionsState,
  propsSelector,
  (instructions, burnPermitId) => {
    return {
      instructionStep          : instructions.instructionStep || 'Apply',
      instructionsAcknowledged : instructions.instructionsAcknowledged || false,
      completedSteps           : 'completedSteps' in instructions ? instructions.completedSteps[burnPermitId] || [] : [],
    }
  }
)

export const currentInstructionStepSelector = createSelector(
  instructionsState,
  instructionsSelector,
  instructions => instructions.instructionStep
)

export const userAcknowledgedInstructions = createSelector(
  instructionsState,
  instructionsSelector,
  instructions => instructions.instructionsAcknowledged
)
