import React from 'react'
import { Alert, } from 'reactstrap'
import { CalculateButton, } from '../FormControls'


const TonnageCalculationDocAlert = () => {
  return <Alert color={'warning'}>
    <p>
      Before signing this application you <b>must</b> either:
    </p>
    <ul>
      <li>click the <CalculateButton /> in the <b>Pile Groups</b> section</li>
      <li>upload your tonnage calculator results in the <b>Documents</b> section and set the Document Type to <b>Tonnage Calculations</b>.</li>
    </ul>
  </Alert>
}

export default TonnageCalculationDocAlert