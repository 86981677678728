import React from 'react'
import { Alert, } from 'reactstrap'

const MustCalculate = () => {
  return <Alert
    color={'danger'}
    className={'mt-2'}
  >
    <p>
      Before you can sign and submit your application, you must click the <b>Calculate</b> button above to
      estimate your consumed tonnage and attach the Tonnage Calculation documents.
    </p>
  </Alert>
}

export default React.memo(MustCalculate)