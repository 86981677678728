import React from 'react'
import { Button, } from 'reactstrap'

const Apply = () => {
  return {
    label   : 'Apply',
    caption : <>
      <p>Complete each section, providing values for all required fields.</p>
      <p>Click <Button size={'sm'} color={'secondary'} className={'readonly'}>Continue</Button> to save the section and move to the next.</p>
      <p>Click <Button size={'sm'} color={'light'} className={'readonly'}>Back</Button> to go to the previous section.</p>
      <p>Click the name of any section to jump to that section at any time.</p>
    </>,
    icon  : 'fas fa-file-contract',
    color : 'primary',
  }
}

export default Apply