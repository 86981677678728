import { attr, Model, } from 'redux-orm'

export default class BurnPermitApplicationStatus extends Model {
  static get modelName () {
    return 'BurnPermitApplicationStatus'
  }

  toString () {
    return `${this.BurnPermitApplicationStatusName}`
  }

  static endpoint () {
    return 'BurnPermitApplicationStatuses'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitApplicationStatusId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitApplicationStatusId          : attr(),
      BurnPermitApplicationStatusName        : attr({ getDefault: () => '', }),
      BurnPermitApplicationStatusDescription : attr({ getDefault: () => '', }),
    
      // Audit Properties
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}