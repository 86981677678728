// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, } from 'reactstrap'
import { object, bool, func, number, string, PropTypes, } from 'prop-types'
import { render as ReactDOMRender, } from 'react-dom'
import { isEqual, } from 'lodash'

// Reducers
import UiActions from '../redux/UiRedux'
import AgencyActions from '../redux/AgencyRedux'
import ApiActions from '../redux/ApiRedux'
import AppActions from '../redux/AppRedux'
import MergeActions from '../redux/MergeRedux'

// Selectors
import { 
  agencySavedSelector,
  agencySavingSelector,
  currentAgencySelector,
  userCreatedAgency,
} from '../selectors/agencySelectors'
import { userCanViewAgencyAuitData, } from '../selectors/permissionSelectors'

// Components
import { PhoneForm, AgencyForm, EmailForm, AddressForm, } from '../components/Forms'
import ConfirmationModal from '../components/ConfirmationModal'
import DataTable from '../components/DataTable'
import withRouter from '../components/withRouter'

// Utilities
import { renderCheckOrXIcon, } from '../utilities/columnHelpers'
import stopEvent from '../utilities/stopEvent'

// Models
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnRequestSearch from '../models/BurnRequestSearch'


const DELETE_AGENCY_MODAL_KEY = 'delete-agency'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const AGENCY_PEOPLE_URL = id => `${REACT_APP_SERVER_URL}Agencies/${id}/People`


const DATATABLE_COLS = [
  {
    title      : 'Id',
    data       : 'PersonId',
    orderable  : true,
    searchable : false,
    target     : [ 0, ],
    width      : 15,
  }, 
  {
    title      : 'Verified',
    data       : 'IsVerified',
    searchable : false,
    render     : renderCheckOrXIcon,
  },
  {
    title : 'Type',
    data  : 'PersonTypeName',
  },
  {
    title : 'First Name',
    data  : 'PersonFirstName',
  },
  {
    title : 'Middle Name',
    data  : 'PersonMiddleName',
  },
  {
    title : 'Last Name',
    data  : 'PersonLastName',
  },
]

class AgencyContainer extends React.Component {
  static propTypes = {
    // from withRouter HOC
    navigate : func,
    location : object,
    params   : object,
    
    actions             : object,
    agencyId            : PropTypes.oneOfType([ string, number, ]),
    isNewAgency         : bool,
    agency              : object,
    address             : object,
    phone               : object,
    email               : object,
    readOnly            : bool,
    saved               : object,
    saving              : object,
    userCreatedAgency   : bool,
    OpenModal           : func,
    ResetAgencyId       : func,
    CreateNewAgency     : func,
    CreateAgency        : func,
    CreateAgencyObject  : func,
    DeleteAgency        : func,
    UpdateAgency        : func,
    UpdateAgencyObject  : func,
    ClearAllSaveState   : func,
    FetchAgencyData     : func,
    SetAgencyId         : func,
    Failure             : func,
    canViewAuditData    : bool,
    SetMergeObjectOneId : func,
    RedirectTo          : func,
    SetPageTitle        : func,
  }

  static defaultProps = {
    readOnly          : false,
    userCreatedAgency : false,
    canViewAuditData  : false,
  }

  state = {
    emailToCreate   : null,
    phoneToCreate   : null,
    addressToCreate : null,
  }

  componentWillUnmount () {
    this.props.ResetAgencyId()
    this.props.ClearAllSaveState()
  }

  componentDidUpdate (prevProps) {
    const { agency, } = this.props
    const { url, id, } = this.props.params
    
    // Handles the scenario where a user creates a new Agency, saves,
    // then uses the browser navigation tools to navigate back to the new page
    if (prevProps.params.url !== url && url.endsWith('/new')) {
      this.props.CreateNewAgency()
    }
    else if (prevProps.params.id !== id) {
      this.props.FetchAgencyData(id, false)
      this.props.SetPageTitle(`Agency #${id}`)
    }
    else if (!isEqual(prevProps.agency, agency) && !!agency && 'AgencyName' in agency) {
      this.props.SetPageTitle(`Agency #${id} - ${agency.AgencyName}`)
    }
  }

  componentDidMount () {
    const { params, location, } = this.props
    this.props.ResetAgencyId()
    this.props.ClearAllSaveState()
    if (params && params.id) {
      const id = parseInt(params.id)
      this.props.FetchAgencyData(id, false)
      this.props.SetPageTitle(`Agency #${id}`)
    }
    else if (location.pathname.endsWith('new')) {
      this.props.CreateNewAgency()
      this.props.SetPageTitle('Create New Agency')
    }
  }

  createAddress = (values) => {
    const { CreateAgencyObject, agency, } = this.props
    if (agency && agency.AgencyId) {
      CreateAgencyObject(values, 'Address', agency.AgencyId)
    }
    else {
      this.setState({ addressToCreate: values, })
    }
  }


  createPhone = (values) => {
    const { CreateAgencyObject, agency, } = this.props
    if (agency && agency.AgencyId) {
      CreateAgencyObject(values, 'Phone', agency.AgencyId)
    }
    else {
      this.setState({ phoneToCreate: values, })
    }
  }

  createEmail = (values) => {
    const { CreateAgencyObject, agency, } = this.props
    if (agency && agency.AgencyId) {
      CreateAgencyObject(values, 'Email', agency.AgencyId)
    }
    else {
      this.setState({ emailToCreate: values, })
    }
  }

  createAgency = (values) => {
    if (values.AgencyId) {
      delete values['AgencyId']
    }
    this.props.CreateAgency(values)
  }

  updateAgency = (values) => {
    const { agency, } = this.props
    if (agency.AgencyId) {
      this.props.UpdateAgency(values, agency.AgencyId)
    }
  }

  doUpdate = (id, updateType, values) => {
    if (id) {
      this.props.UpdateAgencyObject(values, updateType, values[updateType + 'Id'], id)
    }
  }

  updatePhone = values => {
    const { AgencyId, } = this.props.agency
    this.doUpdate(AgencyId, 'Phone', values)
  }

  updateAddress = values => {
    const { AgencyId, } = this.props.agency
    this.doUpdate(AgencyId, 'Address', values)
  }

  updateEmail = values => {
    const { AgencyId, } = this.props.agency
    this.doUpdate(AgencyId, 'Email', values)
  }

  handleKeyPress = (evt) => {
    if (evt.charCode === 13) {
      this.submit()
    }
  }

  submit = async () => {
    let validForms = 0
    const { isNewAgency, } = this.props

    if (this.AgencyForm) {
      const agtErrs = await this.AgencyForm.validateForm()
      if (Object.keys(agtErrs).length === 0) {
        validForms++
      }
    }

    if (isNewAgency && validForms === 1) {
      if (this.AgencyForm.touched()) {
        this.AgencyForm.submitForm()
      }
      return
    }

    if (this.EmailForm) {
      const emailErrs = await this.EmailForm.validate()
      if (Object.keys(emailErrs).length === 0) {
        validForms++
      }
    }
    
    if (this.AddressForm) {
      const addressErrs = await this.AddressForm.validateForm()
      if (Object.keys(addressErrs).length === 0) {
        validForms++
      }
    }
    
    if (this.PhoneForm) {
      const phoneErrs = await this.PhoneForm.validateForm()
      if (Object.keys(phoneErrs).length === 0) {
        validForms++
      }
    }

    if (validForms === 4) {
      if (this.AgencyForm.touched()) {
        this.AgencyForm.submitForm()
      }
      if (this.AddressForm.touched()) {
        this.AddressForm.submitForm()
      }
      if (this.EmailForm.touched()) {
        this.EmailForm.submit()
      }
      if (this.PhoneForm.touched()) {
        this.PhoneForm.submitForm()
      }
    }
  }

  ajaxConfig = id => {
    return {
      url  : AGENCY_PEOPLE_URL(id),
      data : {},
    }
  }

  columnDefs = () => {
    const personIdColIdx = DATATABLE_COLS.findIndex(c => c.title === 'Id')
    const { navigate, } = this.props

    return [
      {
        targets     : personIdColIdx,
        createdCell : (td, cellData) =>
          ReactDOMRender(
            <a href={'/admin/people/' + cellData}
              onClick={evt => {
                stopEvent(evt)
                navigate({ pathname: '/admin/people/' + cellData, state: '/admin/people/', })
              }}>{cellData}</a>
            , td
          ),
      },
    ]
  }
  
  setAgencyNode = node => this.AgencyForm = node

  setAddressNode = node => this.AddressForm = node

  setPhoneNode = node => this.PhoneForm = node

  setEmailNode = node => this.EmailForm = node

  openModal = evt => {
    const { dataset, } = evt.target
    this.props.OpenModal(dataset.target)
  }

  mergeAgency = () => {
    this.props.SetMergeObjectOneId(this.props.agencyId)
    this.props.RedirectTo('/admin/agencies/merge')
  }

  render () {
    const { agency, userCreatedAgency, canViewAuditData, } = this.props
    const readOnly = !userCreatedAgency || this.props.readOnly
    let Address = null
    let Email = null
    let Phone = null
    if (agency) {
      Address = agency.Address
      Email = agency.Email || { EmailAddress: '', }
      Phone = agency.Phone || { PhoneCountryCode: '', PhoneNumber: '', PhoneExtension: '', }
    }
    let saving = false
    if (this.props.saving) {
      saving = Object.values(this.props.saving).some(k => k)
    }
    return (
      <Container className={'my-4'} onKeyPress={this.handleKeyPress}>
        <Row className={'mt-3'}>
          <Col>
            <Card>
              <CardHeader>
                <h5>{(agency && agency.AgencyId && !this.props.isNewAgency) ? agency.AgencyName + ' (#' + agency.AgencyId + ')' : 'New Agency'}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <AgencyForm 
                      createFn={this.createAgency} 
                      updateFn={this.updateAgency}
                      agency={agency} 
                      readOnly={readOnly || this.props.saving['Agency']} 
                      ref={this.setAgencyNode} 
                      showAuditData={canViewAuditData}
                      isNewAgency={this.props.isNewAgency}
                    />
                  </Col>
                </Row>
                { agency && !this.props.isNewAgency && <>
                  <Row><Col><hr/></Col></Row>
                  <Row>
                    <Col xs={12} lg={6} className={'px-2'}>
                      <h5>Agency Address</h5>
                      <AddressForm
                        address={Address}
                        createFn={this.createAddress}
                        updateFn={this.updateAddress}
                        readOnly={readOnly || this.props.saving['Address']}
                        shouldGeocode={false}
                        validateStrict={true}
                        ref={this.setAddressNode}
                        showAuditData={canViewAuditData}
                        enableReinitialize={true}
                      />
                    </Col>
                    <Col xs={12} lg={6}>
                      <Row className={'mt-3'}>
                        <Col className={'px-2'}>
                          <h5>Agency Phone</h5>
                          <PhoneForm 
                            phone={Phone} 
                            createFn={this.createPhone} 
                            updateFn={this.updatePhone}
                            readOnly={readOnly || this.props.saving['Phone']} 
                            ref={this.setPhoneNode} 
                            showAuditData={canViewAuditData}
                            enableReinitialize={true}
                          />
                        </Col>
                      </Row>
                      <Row className={'mt-3'}>
                        <Col className={'px-2'}>
                          <h5>Agency Email</h5>
                          <EmailForm 
                            email={Email} 
                            createFn={this.createEmail}
                            updateFn={this.updateEmail}
                            readOnly={readOnly || this.props.saving['Email']} 
                            ref={this.setEmailNode} 
                            showAuditData={canViewAuditData}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
                }
                <Row><Col><hr/></Col></Row>
                <Row className={'mt-3'}>
                  <Col>
                    <Link className={'btn btn-link'} to={'/admin/agencies'} disabled={saving}>Back to List</Link>
                  </Col>
                  { !readOnly &&
                    <Col className={'d-flex justify-content-end'}>
                      { this.props.agency && !this.props.agency.ParentId && <Button className={'mr-2'} color={'primary'} onClick={this.mergeAgency}>Merge Agency</Button> }
                      { !this.props.isNewAgency && <Button className={'mr-2'} color={'danger'} data-target={DELETE_AGENCY_MODAL_KEY} onClick={this.openModal} disabled={saving}>Delete</Button> }
                      <Button onClick={this.submit} disabled={saving}>Save</Button>
                    </Col>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        { agency && !this.props.isNewAgency && 
        <>
          <Row className={'my-3'}>
            <Col>
              <Card>
                <CardHeader>
                  <h5>Agents</h5>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={DATATABLE_COLS}
                    columnDefs={this.columnDefs()}
                    ajaxConfig={this.ajaxConfig(agency.AgencyId)}
                    elementId={'agency-person-table'}
                    disableRowClick={true}
                    disableDefaultOrdering={true}
                    onAjaxError={req => {
                      console.error(req)
                      console.dir(req)
                      this.props.Failure('An error occurred accessing Agency People.')
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            !!agency && <>
              <Row className={'my-3'}>
                <Col>
                  <Card>
                    <CardHeader>
                      <h5>Permits</h5>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        columns={BurnPermitSearch.getDataTablesColumns({ showLandownerAgent: true, })}
                        ajaxConfig={{ url: BurnPermitSearch.endpoint({ AgencyId: agency ? agency.AgencyId : -1, }), data: {}, }}
                        elementId={'agency-permit-table'}
                        disableRowClick={true}
                        disableDefaultOrdering={true}
                        onAjaxError={req => {
                          console.error(req)
                          console.dir(req)
                          this.props.Failure('An error occurred accessing Agency Permits.')
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className={'my-3'}>
                <Col>
                  <Card>
                    <CardHeader>
                      <h5>Burn Requests</h5>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        columns={BurnRequestSearch.getDataTablesColumns({ showLandownerAgent: true, })}
                        ajaxConfig={{ url: BurnRequestSearch.endpoint({ AgencyId: agency ? agency.AgencyId : -1, }), data: {}, }}
                        elementId={'agency-request-table'}
                        disableRowClick={true}
                        disableDefaultOrdering={true}
                        onAjaxError={req => {
                          console.error(req)
                          console.dir(req)
                          this.props.Failure('An error occurred accessing Agency Requests.')
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </>
        }
        <ConfirmationModal
          modalKey={DELETE_AGENCY_MODAL_KEY}
          modalTitle={'Delete Agency'}
          modalBody={'Are you sure you want to delete this Agency?'}
          submitAction={this.props.DeleteAgency}
          submitArgs={[ this.props.agencyId, ]}
        />
      </Container>
    )
  }
}


function mapStateToProps (state) {
  const { agencyId, isNew, } = state.Agency
  return {
    agencyId,
    isNewAgency       : isNew,
    agency            : currentAgencySelector(state, agencyId),
    userCreatedAgency : userCreatedAgency(state),
    saved             : agencySavedSelector(state),
    saving            : agencySavingSelector(state),
    canViewAuditData  : userCanViewAgencyAuitData(state),
  }
}

const mapDispatchToProps = {
  OpenModal           : UiActions.openModal,
  ResetAgencyId       : AgencyActions.resetAgencyId,
  ClearAllSaveState   : AgencyActions.clearAllSaveState,
  FetchAgencyData     : AgencyActions.fetchAgencyData,
  SetAgencyId         : AgencyActions.setAgencyId,
  CreateNewAgency     : AgencyActions.createNewAgency,
  CreateAgency        : AgencyActions.createAgency,
  UpdateAgency        : AgencyActions.updateAgency,
  DeleteAgency        : AgencyActions.deleteAgency,
  CreateAgencyObject  : AgencyActions.createAgencyObject,
  UpdateAgencyObject  : AgencyActions.updateAgencyObject,
  Failure             : ApiActions.failure,
  RedirectTo          : AppActions.redirectTo,
  SetPageTitle        : AppActions.setPageTitle,
  SetMergeObjectOneId : MergeActions.setObjectOneId,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyContainer))