import React, { useEffect, useMemo, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardText, Col, Container, Row, } from 'reactstrap'

// Reducers
import AppActions from '../../../redux/AppRedux'
import CustomerInteractionActions from '../../../redux/CustomerInteractionRedux'

// Selectors
import { lastRouteSelector, } from '../../../selectors/appSelectors'

// Icons
import { SuccessIcon, } from '../../Icons'


const SmokeSuccess = () => {
  const lastRoute = useSelector(lastRouteSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AppActions.setPageTitle('Smoke Complaint Submitted'))
    dispatch(CustomerInteractionActions.upsertCustomerInteractionSuccess({ id: null, }))
  }, [ dispatch, ])

  const colSizes = useMemo(() => ({ size: '6', offset: '3', }), [])
  return (
    <Container>
      <Row className={'mt-4'}>
        <Col md={colSizes}>
          <Card className={'not-found'}>
            <CardHeader tag={'h3'}>Smoke Complaint Submitted <SuccessIcon /></CardHeader>
            <CardBody>
              <CardText className={'text-center'}>
                Your Smoke Complaint has been submitted successfully!
              </CardText>
              <CardText className={'text-center'}>
                If you requested
                to have follow up from DNR and provided valid contact information, a 
                DNR Representative will be in contact with you soon.
              </CardText>
              <div className={'card-actions'}>
                <Link to={lastRoute} className={'btn btn-link'}>Back to where you were</Link>
                <Link to={'/'} className={'btn btn-secondary'}>Go to the Home Page</Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(SmokeSuccess)
