// Libraries
import { call, all, } from 'redux-saga/effects'

// Sagas
import { showLoading, hideLoading, } from './AppSagas'
import { showError, } from './ApiSagas'

/**
 * Abstract function generator to execute function generators within a try/catch/finally
 * block while also executing the `ApiRedux.SHOW_LOADING` and `ApiRedux.HIDE_LOADING` actions
 * @generator
 * @param {GeneratorFunction} mainSaga
 * @param {GeneratorFunction?} catchSagas
 * @param {GeneratorFunction?} finallySagas
 */
export function* execWithLoading (mainSaga, catchSagas = null, finallySagas = null, disableLoading = false) {
  try {
    if (!disableLoading) {
      yield call(showLoading)
    }
    yield mainSaga()
  }
  catch (error) {
    if (catchSagas) {
      yield all(catchSagas)
    }
    yield call(showError, error)
  }
  finally {
    if (finallySagas) {
      yield all(finallySagas)
    }
    if (!disableLoading) {
      yield call(hideLoading)
    }
  }
}