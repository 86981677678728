import { bool, string, number, oneOfType, } from 'prop-types'

export const authPropsTypes = {
  isAuthenticated : bool,
  isDnr           : bool,
  isAdmin         : bool,
  isSAWUser       : bool,
  isPrivate       : bool,
  isGovAgent      : bool,
  paysByVoucher   : bool,
  isAgent         : bool,
  isVerifiedAgent : bool,
  IsLoadingUser   : bool, 
  LoginError      : string, 
  isStateGovCust  : bool,
  isADFS          : bool,
}

export const authPropsDefaults = {
  isAuthenticated : false,
  isDnr           : false,
  isAdmin         : false,
  isSAWUser       : false,
  isPrivate       : false,
  isGovAgent      : false,
  paysByVoucher   : false,
  isAgent         : false,
  isVerifiedAgent : false,
  IsLoadingUser   : false, 
  LoginError      : '', 
  isStateGovCust  : false,
  isADFS          : false,
}

export const addressPropsTypes = {
  // Accept strings in case an empty string is provided
  AddressId      : oneOfType([ string, number, ]),
  // Accept strings in case an empty string is provided
  AddressTypeId  : oneOfType([ string, number, ]),
  StreetLine1    : string.isRequired,
  StreetLine2    : string,
  StreetLine3    : string,
  AddressCity    : string.isRequired,
  AddressState   : string.isRequired,
  AddressZipCode : oneOfType([ string, number, ]).isRequired,
}

export const addressPropsDefaults = {
  AddressId      : '',
  AddressTypeId  : '',
  StreetLine1    : '',
  StreetLine2    : '',
  StreetLine3    : '',
  AddressCity    : '',
  AddressState   : '',
  AddressZipCode : '',
}