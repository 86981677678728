// Libraries
import React from 'react'
import { debounce, } from 'lodash'
import { Input, Button, ButtonGroup, } from 'reactstrap'
import { string, func, object, bool, } from 'prop-types'

// Utilities
import stopEvent from '../../utilities/stopEvent'


const MilitaryButton = ({ onClick, active, readOnly, children, }) => {
  return <Button
    color={'light'}
    size={'sm'}
    active={active}
    onClick={onClick}
    style={{ boxShadow: 'none', }}
    disabled={readOnly}
  >{children}</Button>
}

MilitaryButton.propTypes = {
  onClick  : func.isRequired,
  active   : bool.isRequired,
  readOnly : bool,
  children : string,
}

export default class TimePicker extends React.Component{

  static propTypes = {
    value    : string,
    name     : string,
    id       : string,
    onBlur   : func,
    onChange : func.isRequired,
    children : object,
    invalid  : bool,
    readOnly : bool,
    format   : string,
  }

  static defaultProps = {
    format: null,
  }

  state = {
    hours          : '',
    minutes        : '',
    militaryFormat : this.props.format === 'military',
    amPm           : 'AM',
  }

  componentDidMount () {
    let { value, } = this.props
    if (value && value.length >= 5) {
      if (value.length === 8) {
        // When coming from the server, the string will have seconds attached, which we need to chop
        value = value.substr(0, value.length - 3)
      }
      const [ , , amPm, ] = this.parseValue(value)
      this.setState({ amPm, }, this.toggleMilitary)
    }
    this._isMounted = true
    this.updateButtonOrientation()
    window.addEventListener('resize', debounce(this.updateButtonOrientation, 250))
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      this.parseForDisplay()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    window.removeEventListener('resize', debounce(this.updateButtonOrientation, 250))
  }

  updateButtonOrientation = () => {
    if (!this._isMounted) {
      return
    }
    var isMidWidth = window.innerWidth < 1200 && window.innerWidth > 990 
    var isMobile = window.innerWidth < 768
    if (isMidWidth || isMobile) {
      this.setState({ verticalbuttons: true, })
    }
    else if (this.state.verticalbuttons) {
      this.setState({ verticalbuttons: false, })
    }
  }

  /***
   * Parses the string representation of the time span into individual values for hours and minutes,
   * then calls the onChange prop.
   *
   * @param {string} inputValue - Example '23:15'
   */
  parseValue = inputValue => {
    if (!inputValue) {
      return [ '', '', this.state.amPm, ]
    }
    const matches = inputValue.split(':')
    let hours = parseInt(matches[0] || '00')
    let minutes = parseInt(matches[1] || '00')
    let { amPm, } = this.state
    if (hours >= 12) {
      amPm = 'PM'
    }
    if (minutes < 10) {
      minutes = `${0}${minutes}`
    }
    if (this.state.militaryFormat && amPm === 'PM' && hours < 12) {
      hours += 12
    }
    return [ hours, minutes, amPm, ]
  }

  /***
   * Handler for changing the input value for hours
   *
   * @param {Object} e - Change event
   */
  onChangeHours = e => {
    stopEvent(e)

    let hours = parseInt(e.target.value)
    const { amPm, militaryFormat, } = this.state
    if (amPm === 'PM' && !militaryFormat && hours < 12) {
      hours += 12
    }
    else if (amPm === 'AM' && !militaryFormat && hours > 12) {
      hours -= 12
    }
    const [ , minutes, ] = this.parseValue(this.props.value)
    this.props.onChange(`${hours}:${minutes || '00'}`)
    if (militaryFormat && hours < 12) {
      this.setState({ amPm: 'AM', })
    }
  }

  /***
   * Handler for changing the input value for minutes
   *
   * @param {Object} e - Change event
   */
  onChangeMinutes = e => {
    stopEvent(e)

    let { value, } = e.target
    value = parseInt(value)
    const [ hours, ] = this.parseValue(this.props.value)
    if (value < 10) {
      value = `${0}${value}`
    }
    this.props.onChange(`${hours || '00' }:${value}`)
  }

  /***
   * Allows user to toggle between AM and PM for the time entered
   *
   * @param {Object} e - Click event
   */
  onAmPmClick = e => {
    stopEvent(e)
    
    if (this.props.readOnly) {
      return
    }

    let { amPm, } = this.state
    let [ hours, minutes, ] = this.parseValue(this.props.value)
    if (amPm === 'AM') {
      amPm = 'PM'
      if (hours < 12) {
        hours += 12
      }
    }
    else if (amPm === 'PM') {
      amPm = 'AM'
      hours = hours > 12 ? hours - 12 : hours
    }

    this.setState({ amPm, }, () => {
      this.props.onChange(`${hours}:${minutes}`)
    })
  }

  /***
   * Switches UI representation between 12hr and 24hr
   *
   * @param {Object}  e          - Click event
   * @param {boolean} militaryOn - Should use 24hr format
   */
  toggleMilitary = (e, militaryFormat = false) => {
    if (e) {
      stopEvent(e)
      militaryFormat = e.target.innerText === '24'
    }

    this.setState({ militaryFormat, }, () => {
      this.parseForDisplay()
    })
  }

  parseForDisplay = () => {
    const { amPm, militaryFormat, } = this.state
    let [ hours, minutes, ] = this.parseValue(this.props.value)
    if (!militaryFormat && amPm === 'PM' && hours > 12) {
      hours -= 12
    }
    this.setState({ hours, minutes, })
  }

  render () {
    const { readOnly, } = this.props
    const { militaryFormat, amPm, hours, minutes, } = this.state
    const cursor = `${readOnly || militaryFormat ? '' : 'pointer'}`
    return <>
      <div className={'d-flex flex-row'} style={{ alignItems: 'center', }}>
        <div className={'d-flex flex-row'}>
          <Input
            type={'number'}
            min={1}
            max={18}
            onChange={this.onChangeHours}
            style={{ width: '2em', textAlign: 'center', }}
            value={hours}
            readOnly={readOnly}
            aria-label={'Hours'}
          />
          <span className={'pt-2'}>:</span>
          <Input
            type={'number'}
            min={0}
            max={59}
            onChange={this.onChangeMinutes}
            style={{ width: '2em', textAlign: 'center', }}
            value={minutes}
            readOnly={readOnly}
            aria-label={'Minutes'}
          />
          <span
            className={'ml-2 pt-2'}
            style={{ cursor, width: '2em', }}
            readOnly={readOnly}
            role={'button'}
            tabIndex={0}
            {...(!militaryFormat ? {
              onClick    : this.onAmPmClick,
              onKeyPress : this.onAmPmClick,
            } : {})}
          >
            { militaryFormat ? '' : amPm }
          </span>
        </div>
        {
          !this.props.format &&
          <ButtonGroup {...(this.state.verticalbuttons ? { vertical: true, } : {})} className={'shadow-sm'}>
            <MilitaryButton
              active={!militaryFormat}
              data
              onClick={this.toggleMilitary}
            >12</MilitaryButton>
            <MilitaryButton
              active={militaryFormat}
              onClick={this.toggleMilitary}
            >24</MilitaryButton>
          </ButtonGroup>
        }
      </div>
      { this.props.children }
    </>
  }
}