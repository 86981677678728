// Libraries
import React, { useCallback, useEffect, useMemo, } from 'react'
import { Formik, Field, useFormikContext, } from 'formik'
import {
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import { useDispatch, useSelector, } from 'react-redux'
import * as Yup from 'yup'
import { string, number, bool, object, } from 'prop-types'

// Components
import {  ValidatingField, } from '../FormControls'
import { IssuePermit, PreviewPermit, } from '../Modals'
import ValidDateField from './ValidDateField'
import PermitDurationField from './PermitDurationField'

// Redux
import BurnPermitSignatureActions from '../../redux/BurnPermitSignatureRedux'

// Selectors
import { signatureByPermitId, } from '../../selectors/burnPermitSignatureSelectors'
import { appIsOnlineSelector, } from '../../selectors/selectors'

// Utilities
import { dateFormatter, } from '../../utilities'


const PERMIT_STATUS_SCHEMA = Yup.object().shape({
  ValidDate      : Yup.date().required('You must set a Valid date.'),
  ExpirationDate : Yup.date().required('You must set an Expiration date.'),
})


const FormValidator = () => {
  const { values, submitForm, } = useFormikContext()
  const { ValidDate, PermitDuration, } = values
  useEffect(() => {
    if (!ValidDate || !PermitDuration) {
      submitForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}


const IssuePermitForm = props => {

  const {
    burnPermitId,
    ValidDate,
    ExpirationDate,
    isIssued,
    readOnly,
    showPermitDurationRadioButtons,
  } = props

  const dispatch = useDispatch()

  const online = useSelector(appIsOnlineSelector)

  const signature = useSelector((state) => signatureByPermitId(state, burnPermitId))

  const issuePermit = useCallback(args => {
    const { values, submitForm, } = args

    const validDate = dateFormatter(values.ValidDate)
    const expirationDate = dateFormatter(values.ExpirationDate)

    let hasError = false
    let showModal = false
    if (!validDate || validDate.isValid() === false) {
      hasError = true
    }

    if (!hasError && (!expirationDate || expirationDate.isValid() === false)) {
      hasError = true
    }

    if (hasError === false) {
      dispatch(BurnPermitSignatureActions.signPermit(burnPermitId, validDate, expirationDate))
      showModal = true
      return showModal
    }
    submitForm()
    return showModal
  }, [ dispatch, burnPermitId, ])

  const PermitDuration = useMemo(() => {
    if (ValidDate && ExpirationDate) {
      return (new Date(ExpirationDate).getFullYear() - new Date(ValidDate).getFullYear()).toString()
    }
    return '2'
  }, [ ValidDate, ExpirationDate, ])

  const initValues = useMemo(() => ({
    BurnPermitId   : burnPermitId,
    ValidDate      : ValidDate || '',
    ExpirationDate : ExpirationDate || '',
    Comment        : '', // Comments are not actually supported yet
    PermitDuration,
  }), [ burnPermitId, ValidDate, ExpirationDate, PermitDuration, ])

  const formChildren = useCallback((formProps) => {
    // const noValidDate = !formProps.values.ValidDate || dateFormatter(formProps.values.ValidDate).isValid() === false
    // const noExpiration = !formProps.values.ExpirationDate || dateFormatter(formProps.values.ExpirationDate).isValid() === false
    // const datesInvalid = noValidDate || noExpiration
    return <>
      <FormValidator />
      <Row>
        { !online && !isIssued && <Col xs={12}>
          <p><b>Issuing a permit requires an internet connection. The form will enable when a connection is restored.</b></p>
        </Col>
        }
        <Col md={6}>
          <Label for={'ValidDate'}>Valid Date</Label>
          <ValidDateField readOnly={readOnly} />
        </Col>
        <Col md={6}>
          <Label for={'ExpirationDate'}>Expiration Date</Label>
          <Field
            type={'date'}
            id={'ExpirationDate'}
            name={'ExpirationDate'}
            readOnly={true}
            component={ValidatingField}
          />
        </Col>
        { showPermitDurationRadioButtons &&
            <Col xs={12} className={'mt-4'}>
              <FormGroup>
                <PermitDurationField readOnly={readOnly} />
              </FormGroup>
            </Col>
        }
      </Row>
      { !signature.PermitApproverSignedBy &&
          <Row>
            <Col xs={12}>
              <div className={'d-flex justify-content-between'}>
                <PreviewPermit burnPermitId={burnPermitId} />
                <IssuePermit
                  burnPermitId={burnPermitId}
                  onClick={issuePermit}
                  disabled={formProps.submitCount > 1 && !formProps.isValid}
                  ExpirationDate={formProps.values.ExpirationDate}
                  ValidDate={formProps.values.ValidDate}
                />
              </div>
            </Col>
          </Row>
      }
    </>
  }, [ burnPermitId, online, isIssued, readOnly, issuePermit, showPermitDurationRadioButtons, signature, ])

  return <Formik
    initialValues={initValues}
    enableReinitialize={true}
    validationSchema={PERMIT_STATUS_SCHEMA}
    onSubmit={issuePermit}
    readOnly={!online}
  >
    {formChildren}
  </Formik>
}

IssuePermitForm.propTypes = {
  burnPermitId                   : number,
  ValidDate                      : string,
  ExpirationDate                 : string,
  isIssued                       : bool,
  readOnly                       : bool,
  showPermitDurationRadioButtons : bool,
  form                           : object,
}

export default IssuePermitForm
