import { all, spawn, } from 'redux-saga/effects'

/* ------------- Sagas ------------- */
import { AgencySagas, } from './AgencySagas'
import { ApiSagas, } from './ApiSagas'
import { BurnPermitAreaSagas, } from './BurnPermitAreaSagas'
import { BurnPermitApplicantInfoSagas, } from './BurnPermitApplicantInfoSagas'
import { BurnPermitConditionSagas, } from './BurnPermitConditionSagas'
import { BurnPermitFuelsSagas, } from './BurnPermitFuelsSagas'
import { BurnPermitLocationSagas, } from './BurnPermitLocationSagas'
import { BurnPermitOrderSagas, } from './BurnPermitOrdersSagas'
import { BurnPermitPileGroupSagas, } from './BurnPermitPileGroupSagas'
import { BurnPermitSiteSagas, } from './BurnPermitSiteSagas'
import { BurnPermitSagas, } from './BurnPermitSagas'
import { BurnPermitSignatureSagas, } from './BurnPermitSignatureSagas'
import { BurnRequestDetailSagas, } from './BurnRequestDetailSagas'
import { CalendarSagas, } from './CalendarSagas'
import { CustomerInteractionSagas, } from './CustomerInteractionSagas'
import { DashboardSagas, } from './DashboardSagas'
import { ForestHealthSagas, } from './ForestHealthSagas'
import { GeoCoordinateSagas, } from './GeoCoordinateSagas'
import { PersonSagas, } from './PersonSagas'
import { PostBurnSagas, } from './PostBurnSagas'
import { RegionSagas, } from './RegionSagas'
import { ReportSagas, } from './ReportSagas'
import { RulesSagas, } from './RulesSagas'
import { SearchSagas, } from './SearchSagas'
import { UserSagas, } from './UserSagas'


export default function* root () {
  const allSagas = [
    AgencySagas,
    ApiSagas,
    BurnPermitAreaSagas,
    BurnPermitApplicantInfoSagas,
    BurnPermitConditionSagas,
    BurnPermitFuelsSagas,
    BurnPermitLocationSagas,
    BurnPermitOrderSagas,
    BurnPermitPileGroupSagas,
    BurnPermitSagas,
    BurnPermitSiteSagas,
    BurnPermitSignatureSagas,
    BurnRequestDetailSagas,
    CalendarSagas,
    CustomerInteractionSagas,
    DashboardSagas,
    ForestHealthSagas,
    GeoCoordinateSagas,
    PersonSagas,
    PostBurnSagas,
    RegionSagas,
    ReportSagas,
    RulesSagas,
    SearchSagas,
    UserSagas,
  ].reduce((acc, val) => acc.concat(val), [])

  // Now when sagas fail or an action type is not found, it won't kill all the other sagas
  yield all(
    allSagas.map(saga => {
      return spawn(function* () {
        while (true) {
          try {
            yield saga
            break
          }
          catch (e) {
            console.error(e)
          }
        }
      })
    })
  )
}