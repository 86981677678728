import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitWindDirectionConditionXref extends Model {
  static get modelName () {
    return 'BurnPermitWindDirectionConditionXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitWindDirectionConditionXrefId',
    }
  }

  static get fields () {
    return {
      BurnPermitWindDirectionConditionXrefId : attr(),
      BurnPermitConditionXrefId              : fk({ 
        to          : 'BurnPermitConditionXref', 
        as          : 'BurnPermitConditionXref', 
        relatedName : 'BurnPermitWindDirectionConditionXrefs',
      }),
      DirectionId: fk({
        to          : 'Direction',
        as          : 'Direction',
        relatedName : 'BurnPermitWindDirectionConditionXrefs',
      }),
      CreateBy   : attr(),
      CreateDate : attr(),
      UpdateBy   : attr(),
      UpdateDate : attr(),
    }
  }
}