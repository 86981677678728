import { attr, fk, Model, } from 'redux-orm'

/**
 * PersonAgencyXref model
 */
export default class PersonAgencyXref extends Model {
  static get modelName () {
    return 'PersonAgencyXref'
  }

  static get options () {
    return {
      idAttribute: 'PersonAgencyXrefId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PersonAgencyXrefId       : attr(),
      PersonAgencyXrefAgencyId : fk({ to: 'Agency', relatedName: 'PersonAgencyXrefs', as: 'PersonAgencyXrefAgency', }),
      PersonAgencyXrefPersonId : fk({ to: 'Person', relatedName: 'PersonAgencyXrefs', as: 'PersonAgencyXrefPerson', }),
      ConfirmedBy              : attr(),
      ConfirmedOn              : attr(),
      CreateDate               : attr(),
      CreateBy                 : attr(),
      UpdateDate               : attr(),
      UpdateBy                 : attr(),
    }
  }
}