import { createActions, } from 'reduxsauce'

import BurnPermitFuel from '../models/BurnPermitFuel'

const BURN_PERMIT_FUEL_MODEL_NAME = BurnPermitFuel.modelName
const BURN_PERMIT_FUEL_ENDPOINT = BurnPermitFuel.endpoint()


const { Types, Creators, } = createActions({
  getBurnPermitFuelsInfo       : [ 'burnPermitFuelId', ],
  updateBurnPermitFuels        : [ 'burnPermitFuel', ],
  updateBurnPermitFuelsRequest : (burnPermitFuel, showSuccess, calcFee) => ({
    type : 'UPDATE_BURN_PERMIT_FUEL_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_FUEL_ENDPOINT}/${burnPermitFuel.BurnPermitFuelId}`,
          init : { method: 'PUT', body: { ...burnPermitFuel, }, },
        },
        commit: {
          type      : 'FUELS_REQUEST_SUCCESS',
          modelName : BURN_PERMIT_FUEL_MODEL_NAME,
          showSuccess,
          calcFee,
        },
        rollback: { type: 'API_FAIL', },
      },
    },
  }),
  fuelsRequestSuccess: [ 'payload', ],
})

export const BurnPermitFuelsTypes = Types
export default Creators