import React from 'react'
import { Alert, } from 'reactstrap'


const BurnReasonDoc = () => {
  const alert = <Alert
    color={'primary'}
    className={'w-100'}>
    <p>Make sure you have uploaded a document outlining the Burn Reason.</p>
    <p>
      Click <b>Sign</b> below when you have completed the 
      application to electronically sign it and submit it to DNR for review.
    </p>
  </Alert>
  return alert
}

export default React.memo(BurnReasonDoc)
