import React from 'react'
import { FormFeedback, FormGroup, Label, Input, Fade, } from 'reactstrap'
import { func, bool, string, array, object, } from 'prop-types'
import RequiredLabel from './RequiredLabel'

export class CheckBoxes extends React.Component {

  static propTypes = {
    onCheckboxChange   : func,
    inputName          : string,
    CheckboxLabel      : string,
    CheckboxData       : array,
    CheckedData        : array,
    CheckboxError      : string,
    onOtherInputChange : func,
    OtherDescription   : string,
    OtherDescError     : string,
    OtherInputName     : string,
    readOnly           : bool,
    required           : bool,
    form               : object,
    className          : string,
    showSelectAll      : bool,
    showLegend         : bool,
  }

  static defaultProps = {
    showSelectAll : false,
    readOnly      : false,
    showLegend    : true,
  }

  onCheckboxChange = evt => {
    const { inputName, form, } = this.props
    if (typeof this.props.onCheckboxChange === 'function') {
      this.props.onCheckboxChange(evt)
    }
    else if (form && typeof form.setFieldValue === 'function') {
      const checkedData = this.getCheckedData()
      const value = parseInt(evt.target.value)
      let newData = [ ...checkedData, ]
      const checkedIdx = newData.indexOf(value)
      if (evt.target.checked) {
        if (checkedIdx === -1) {
          newData.push(value)
        }
      }
      else {
        newData.splice(checkedIdx, 1)
      }
      form.setFieldValue(inputName, newData)
    }
  }

  getCheckedData = () => {
    const { CheckedData, form, inputName, } = this.props
    return CheckedData || (form && form.values[inputName]) || []
  }

  onSelectAllChanged = (evt) => {
    const { inputName, form, CheckboxData, } = this.props
    if (form && typeof form.setFieldValue === 'function') {
      let newData = []
      if (evt.target.checked) {
        newData = CheckboxData.map(c => c.Value, [])
      }
      form.setFieldValue(inputName, newData)
    }
    else {
      this.props.onCheckboxChange(evt)
    }
  }

  buildCheckboxes (readOnly = false) {
    const markup = []
    const { inputName, CheckboxData, showSelectAll, } = this.props
    const checkedData = this.getCheckedData()

    for (let i = 0, len = CheckboxData.length; i < len; i++) {
      const data = CheckboxData[i]
      const checked = checkedData.some(c => c === data.Value)
      const key = `${inputName}-${data.Value}`
      markup.push(
        <FormGroup className={'pl-5'} key={key}>
          <Label check id={key}>
            <Input
              type={'checkbox'}
              aria-label={data.Text}
              name={inputName}
              onChange={this.onCheckboxChange} 
              value={data.Value}
              key={data.Value}
              checked={checked}
              readOnly={readOnly || data.readOnly}
              disabled={readOnly || data.readOnly}
              aria-labelledby={`${inputName} ${key}`}
            />
            <div className={!checked && readOnly ? 'grey-text' : null}>{data.Text}</div>
          </Label>
        </FormGroup>
      )
    }

    if (CheckboxData.length > 0 && showSelectAll) {
      markup.push(<hr key={'selectall-spacer'}/>)
      markup.push(
        <FormGroup className={'pl-5'} key={'chk-selectall'}>
          <Label check id={`${inputName}-chk-selectall`}>
            <Input
              type={'checkbox'}
              aria-label={'Select All'}
              name={inputName}
              onChange={this.onSelectAllChanged} 
              value={'all'}
              key={'all'}
              checked={checkedData.length === CheckboxData.length}
              readOnly={readOnly}
              disabled={readOnly}
              aria-labelledby={`${inputName}-chk-selectall`}
            />
            <div>Select All</div>
          </Label>
        </FormGroup>
      )
    }
    return markup
  }

  otherIsChecked = () => {
    const {
      CheckboxData,
      CheckedData,
      inputName,
      form,
    } = this.props
    const checkedData = (
      (CheckedData && CheckedData.length ? CheckedData : null)
      || (form && form.values[inputName] && form.values[inputName].length ? form.values[inputName] : null)
      || []
    )
    return CheckboxData.some(i => i.Text === 'Other' && checkedData.includes(i.Value))
  }

  onOtherInputChange = evt => {
    const { onOtherInputChange, OtherInputName, form, } = this.props
    if (typeof onOtherInputChange === 'function') {
      onOtherInputChange(evt)
    }
    else if (form && typeof form.setFieldValue === 'function') {
      form.setFieldValue(OtherInputName, evt.target.value)
    }
  }

  getLegend = () => {
    if (!this.props.showLegend) {
      return null
    }
    const {
      inputName,
      CheckboxLabel,
      readOnly,
    } = this.props
    return <legend>
      {
        this.props.required
          ? <RequiredLabel id={inputName}>{CheckboxLabel}</RequiredLabel>
          : <Label id={inputName}>{CheckboxLabel}</Label>
      }
      { !readOnly && <small className={'d-block mb-2'}>(Check all that apply)</small> }
    </legend>
  }

  render () {

    let otherInput
    const {
      CheckboxError,
      CheckboxData,
      CheckedData,
      OtherDescription,
      OtherDescError,
      OtherInputName,
      readOnly,
      inputName,
      form,
    } = this.props
    const checkedData = (CheckedData || (form && form.values[inputName]) || [])
    if (CheckboxData.some(i => i.Text === 'Other' && checkedData.includes(i.Value))) {
      const otherDescError = OtherDescError || (form && form.errors[OtherInputName])
      const value = OtherDescription || (form && form.values[OtherInputName])
      const inputId = `other-input-${OtherInputName}_${(new Date()).getMilliseconds()}`
      otherInput = <FormGroup>
        <Label for={inputId} id={OtherInputName}>Please describe</Label>
        <Input 
          id={inputId}
          type={'text'} 
          name={OtherInputName}
          value={value}
          onChange={this.onOtherInputChange}
          required
          invalid={!!otherDescError}
          readOnly={readOnly}
          aria-labelledby={OtherInputName}
        />
        <Fade in={!!otherDescError} style={{ height: otherDescError ? 'auto' : '0', }}>
          <Input invalid={!!otherDescError} type={'hidden'}/>
          <FormFeedback>{otherDescError}</FormFeedback>
        </Fade>
      </FormGroup>
    }
    const checkboxError = (CheckboxError || (form && form.errors[inputName]))
    return <fieldset className={this.props.className}>
      { this.getLegend() }
      <Fade in={!!checkboxError} style={{ height: checkboxError ? 'auto' : '0', }}>
        <Input invalid={!!checkboxError} type={'hidden'}/>
        <FormFeedback>{checkboxError}</FormFeedback>
      </Fade>
      { this.buildCheckboxes(readOnly) }
      <Fade in={!!otherInput} style={{ height: otherInput ? 'auto' : '0', }}>
        { otherInput }
      </Fade>
    </fieldset>
  }
}
export default CheckBoxes