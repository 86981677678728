import {
  BURN_PERMIT_STATUS_POINT,
  BURN_REQUEST_STATUS_POINT,
  UGA_BURN_REQUEST_STATUS_POINT,
  UGA_POST_BURN_POINT,
  POST_BURN_POINT,
} from './renderers'
import { permitAction, burnRequestAction, } from './actions'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env


const dateExpression = (fieldName) => {
  return {
    name       : fieldName,
    expression : `(Month($feature.${fieldName}) + 1) + '/' + Day($feature.${fieldName}) + '/' + YEAR($feature.${fieldName})`,
  }
}

const latLongExpression = {
  title      : 'Lat, Long',
  name       : 'LatLong',
  expression : 'Concatenate($feature.Latitude, \', \', $feature.Longitude)',
}

export const BURN_PERMITS_LAYER_ID = 'BurnPermits'

export function BURN_PERMITS () {
  
  const fieldInfos = [
    { fieldName: 'BurnPermitId', label: 'Burn Permit ID', },
    { fieldName: 'BurnPermitNumber', label: 'Burn Permit #', },
    { fieldName: 'TotalPermitTonnage', label: 'Permit Tonnage', },
    { fieldName: 'Elevation', label: 'Elevation', },
    { fieldName: 'BurnAcres', label: 'Burn Acres', },
    { fieldName: 'expression/BurnPermitExpirationDate', },
    { fieldName: 'BurnPermitStatus', label: 'Permit Status', },
    { fieldName: 'IsUGA', label: 'Is in UGA', },
    { fieldName: 'IsForestHealthExempt', label: 'Is Forest Health Exempt', },
    { fieldName: 'expression/LatLong', },
  ]

  if (this !== undefined && this.showLandownerAgent) {
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'expression/BurnPermitExpirationDate'), 0, { fieldName: 'Landowner', label: 'Landowner', })
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'expression/BurnPermitExpirationDate'), 0, { fieldName: 'Agent', label: 'Agent', })
  }

  return {
    id            : BURN_PERMITS_LAYER_ID,
    url           : `${REACT_APP_SERVER_URL}BurnPermits/GeoJSON`,
    title         : 'Permitted Burn Locations',
    outFields     : [ '*', ],
    renderer      : BURN_PERMIT_STATUS_POINT,
    geometryType  : 'point',
    popupTemplate : {
      title   : 'Permit #{BurnPermitNumber}',
      content : [
        {
          type: 'fields',
          fieldInfos,
        },
      ],
      expressionInfos: [
        { title: 'Expiration Date', ...dateExpression('BurnPermitExpirationDate'), },
        { ...latLongExpression, },
      ],
      actions: [ permitAction.bind(this)(BURN_PERMITS_LAYER_ID), ],
    },
  }
}

export function BURN_REQUESTS (ugaOnly = false) {

  const fieldInfos = [
    { fieldName: 'BurnRequestId', label: 'Burn Request ID', format: 'string', },
    { fieldName: 'BurnPermitId', label: 'Burn Permit ID', format: 'string', },
    { fieldName: 'BurnPermitNumber', label: 'Burn Permit #', },
    { fieldName: 'TotalProposedBurnQuantity', label: 'Proposed Burn Quantity', },
    { fieldName: 'ProposedBurnArea', label: 'Proposed Burn Area', },
    { fieldName: 'expression/PlannedIgnitionDate', },
    { fieldName: 'PlannedIgnitionTime', label: 'Planned Ignition Time', },
    { fieldName: 'expression/BurnRequestDate', },
    { fieldName: 'MultiDayBurn', label: 'Multi Day Burn', },
    { fieldName: 'Elevation', label: 'Elevation', },
    { fieldName: 'BurnAcres', label: 'Burn Acres', },
    { fieldName: 'BurnRequestStatus', label: 'Status', },
    { fieldName: 'expression/BurnPermitExpirationDate', },
    { fieldName: 'HasPostBurn', label: 'Has Post Burn', },
    { fieldName: 'IsUGA', label: 'Is in UGA', },
    { fieldName: 'IsForestHealthExempt', label: 'Is Forest Health Exempt', },
    { fieldName: 'expression/LatLong', },
  ]
  
  if (this !== undefined && this.showLandownerAgent) {
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'BurnRequestStatus'), 0, { fieldName: 'Landowner', label: 'Landowner', })
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'BurnRequestStatus'), 0, { fieldName: 'Agent', label: 'Agent', })
  }

  const id = `Planned${ugaOnly ? 'UGA' : ''}Burns`
  const url = `${REACT_APP_SERVER_URL}BurnRequests/GeoJSON?isUga=${ugaOnly}`
  
  return {
    id,
    url,
    title         : `Planned ${ugaOnly ? 'UGA' : ''} Burns`,
    outFields     : [ '*', ],
    renderer      : ugaOnly ? UGA_BURN_REQUEST_STATUS_POINT : BURN_REQUEST_STATUS_POINT,
    geometryType  : 'point',
    popupTemplate : {
      title   : 'Planned Burn #{BurnRequestId}',
      content : [
        {
          type: 'fields',
          fieldInfos,
        },
      ],
      expressionInfos: [
        { title: 'Planned Ignition Date', ...dateExpression('PlannedIgnitionDate'), },
        { title: 'Burn Request Date', ...dateExpression('BurnRequestDate'), },
        { title: 'Expiration Date', ...dateExpression('BurnPermitExpirationDate'), },
        { ...latLongExpression, },
      ],
      actions: [ burnRequestAction.bind(this)(id), permitAction.bind(this)(id), ],
    },
  }
}

export function UGA_BURN_REQUESTS () {
  const burnReqs =  { ...BURN_REQUESTS.bind(this)(true), }
  return {
    ...burnReqs,
  }
}

export function POST_BURNS (ugaOnly = false) {

  const fieldInfos = [
    { fieldName: 'BurnRequestId', label: 'Burn Request Id', },
    { fieldName: 'expression/PlannedIgnitionDate', },
    { fieldName: 'expression/BurnDate', },
    
    { fieldName: 'PlannedIgnitionPeriod', label: 'Planned Ignition Period', },
    { fieldName: 'IgnitionPeriod', label: 'Reported Ignition Period', },

    { fieldName: 'PlannedIgnitionTime', label: 'Planned Ignition Time', },
    { fieldName: 'BurnTime', label: 'Reported Burn Time', },

    { fieldName: 'ProposedBurnedArea', label: 'Proposed Burned Area', },
    { fieldName: 'BurnedAcres', label: 'Reported Burned Acres', },
    { fieldName: 'HarvestAcres', label: 'Total Permit Area', },

    { fieldName: 'TotalProposedBurnQuantity', label: 'Proposed Burn Tonnage', },
    { fieldName: 'ConsumedTons', label: 'Reported Burned Tonnage', },
    { fieldName: 'TotalPermitTonnage', label: 'Total Permit Tonnage', },

    { fieldName: 'expression/CreateDate', },

    { fieldName: 'MultiDayBurnFlag', label: 'Multi Day Burn', },
    { fieldName: 'IsUGA', label: 'Is in UGA', },
    { fieldName: 'IsForestHealthExempt', label: 'Is Forest Health Exempt', },
    { fieldName: 'expression/LatLong', },
  ]

  if (this !== undefined && this.showLandownerAgent) {
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'expression/CreateDate'), 0, { fieldName: 'LandownerName', label: 'Landowner', })
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'expression/CreateDate'), 0, { fieldName: 'AgentName', label: 'Agent', })
    fieldInfos.splice(fieldInfos.findIndex(f => f.fieldName === 'expression/CreateDate'), 0, { fieldName: 'PostBurnComments', label: 'Post-Burn Comments', })
  }

  const id = `Completed${ugaOnly ? 'UGA' : ''}Burns`
  const url = `${REACT_APP_SERVER_URL}PostBurnReports/GeoJSON?isUga=${ugaOnly}`

  return {
    id,
    url,
    title         : `Recent ${ugaOnly ? 'UGA' : 'Completed'} Burns`,
    outFields     : [ '*', ],
    renderer      : ugaOnly ? UGA_POST_BURN_POINT : POST_BURN_POINT,
    geometryType  : 'point',
    popupTemplate : {
      title   : 'Burn Permit #{BurnPermitNumber}',
      content : [
        {
          type: 'fields',
          fieldInfos,
        },
      ],
      expressionInfos: [
        { title: 'Planned Ignition Date', ...dateExpression('PlannedIgnitionDate'), },
        { title: 'Burn Date', ...dateExpression('BurnDate'), },
        { title: 'Post Burn Reported On', ...dateExpression('CreateDate'), },
        { ...latLongExpression, },
      ],
      actions: [ burnRequestAction.bind(this)(id), permitAction.bind(this)(id), ],
    },
  }
}

export function UGA_POST_BURNS () {
  const postBurns =  { ...POST_BURNS.bind(this)(true), }
  return {
    ...postBurns,
  }
}

const ALL_LAYERS = [
  BURN_PERMITS,
  BURN_REQUESTS,
  UGA_BURN_REQUESTS,
  POST_BURNS,
  UGA_POST_BURNS,
]

export default ALL_LAYERS