import { createReducer, createActions, } from 'reduxsauce'
  
/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  userFetchStart             : null,
  userFetchEnd               : null,
  loginReceived              : [ 'token', ],
  loginError                 : [ 'error', ],
  logout                     : null,
  destroyTokenAndResetState  : null,
  userInfoRequest            : null,
  userInfoSuccess            : [ 'personId', 'userName', ],
  userInfoError              : [ 'error', ],
  setGroups                  : [ 'groups', ],
  checkAgentVerification     : null,
  updateUserAgencyStatus     : [ 'isVerifiedAgent', ],
  toggleNetworkNotifications : null,
  checkTokenExpiration       : null,
})

export const UserTypes = Types
export default Creators

export const INITIAL_STATE = {
  isAuthenticated             : false,
  isFetching                  : false,
  errorMessage                : '',
  token                       : null,
  personId                    : -1,
  userName                    : '',
  groups                      : [],
  isVerifiedAgent             : false,
  // Default this to false
  // Enable it programmatically only if the user installs the app
  networkNotificationsEnabled : false,
}
  
/* ------------- Reducers ------------- */
export const isFetching = (state) => {
  const newState = { ...state, }
  newState.isFetching = true
  return newState
}

export const doneFetching = (state) => {
  const newState = { ...state, }
  newState.isFetching = false
  return newState
}

export const toggleNetworkNotifications = (state) => {
  const newState = { ...state, }
  newState.networkNotificationsEnabled = !newState.networkNotificationsEnabled
  return newState
}

/**
 * Add groups to User state
 * @param {Object} state 
 * @param {Object} action - The login request response
 * @param {string[]} action.groups - Array of group names
 */
export const setGroups = (state, { groups, }) => {
  if (!groups || !Array.isArray(groups)){
    return state
  }
  const newState = { ...state, }
  newState.groups = groups
  return newState
}

/**
 * Add JWT to User state and update flags
 * @param {Object} state 
 * @param {Object} action - The login request response
 * @param {string} action.token - The user's auth token
 */
export const loginReceived = (state, action) => {
  const newState = { ...state, }
  newState.isFetching = true
  newState.isAuthenticated = !!action.token
  newState.token = action.token
  newState.errorMessage = ''
  return newState
}

/**
 * Reset user state and clear stored token
 * @param {Object} [state]
 */
// eslint-disable-next-line no-unused-vars
export const destroyTokenAndResetState = (state) => {
  return INITIAL_STATE
}

export const userInfoSuccess = (state, { personId, userName, }) => {
  const newState = { ...state, }
  newState.personId = personId
  newState.userName = userName
  return newState
}

export const updateAgencyStatus = (state, { isVerifiedAgent, }) => {
  const newState = { ...state, }
  newState.isVerifiedAgent = isVerifiedAgent
  return newState
}

export const userInfoError = (state, { error, }) => {
  const newState = { ...state, }
  newState.errorMessage = error
  newState.personId = -1
  newState.userName = ''
  newState.token = null
  newState.isAuthenticated = false
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_FETCH_START]              : isFetching,
  [Types.USER_FETCH_END]                : doneFetching,
  [Types.LOGIN_RECEIVED]                : loginReceived,
  [Types.DESTROY_TOKEN_AND_RESET_STATE] : destroyTokenAndResetState,
  [Types.USER_INFO_SUCCESS]             : userInfoSuccess,
  [Types.USER_INFO_ERROR]               : userInfoError,
  [Types.SET_GROUPS]                    : setGroups,
  [Types.UPDATE_USER_AGENCY_STATUS]     : updateAgencyStatus,
  [Types.TOGGLE_NETWORK_NOTIFICATIONS]  : toggleNetworkNotifications,
})