import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Button, } from 'reactstrap'
import { func, bool, string, } from 'prop-types'

import BurnRequestSearchActions from '../redux/BurnRequestSearchRedux'


const ExportRequests = props => {
  const isDownloading = useSelector(state => state.BurnRequestSearch.isDownloadingExport)
  const dispatch = useDispatch()
  
  const onClick = React.useCallback(() => {
    dispatch(BurnRequestSearchActions.downloadRequestsExport())
  }, [ dispatch, ])

  return <Button
    color={props.color}
    onClick={onClick}
    readOnly={isDownloading}
    disabled={isDownloading}
  >
    {props.buttonText}
  </Button>
}

ExportRequests.propTypes = {
  color      : string,
  buttonText : string,
  onClick    : func,
  readOnly   : bool,
}

ExportRequests.defaultProps = {
  color      : 'dark',
  buttonText : 'Export All Requests',
  onClick    : () => {},
  readOnly   : false,
}

export default ExportRequests