import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { Navbar, Container, } from 'reactstrap'

// Selectors
import { isNavbarOpen, } from '../../selectors/uiSelectors'

// Components
import Banner from './Banner'
import LeftNavbarItems from './Left'
import RightNavbarItems from './Right'


const DNRNavbar = () => {
  const navbarIsOpen = useSelector(isNavbarOpen)
  const navbarCssClasses = useMemo(() => {
    let classes = 'navbar-collapse offcanvas-collapse'
    if (navbarIsOpen) {
      classes += ' open'
    }
    return classes
  }, [ navbarIsOpen, ])
  return <>
    <Banner />
    <Navbar
      id={'navbar-menu-items'}
      className={navbarCssClasses}
      role={'navigation'}
      title={'Burn Portal Navigation'}
    >
      <Container>
        <LeftNavbarItems />
        <RightNavbarItems />
      </Container>
    </Navbar>
  </>
}

export default React.memo(DNRNavbar)
