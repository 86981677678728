// Libraries
import { put, call, } from 'redux-saga/effects'

// Sagas
import { doFetch, showError, } from './ApiSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { FileTypes, } from '../redux/FileRedux'


export function* downloadFile ({ url, fileName, }) {
  try {

    const link = document.createElement('a')
    link.target = '_blank'
    link.download = fileName
    link.href = url

    document.body.appendChild(link)
        
    link.click()

    setTimeout(() => {
      link.parentNode.removeChild(link)
    }, 100)
  }
  catch (error) {
    yield call(showError, error)
  }
}


export function* uploadFiles ({ url, files, }) {
  try {
    yield put({ type: FileTypes.FILE_UPLOADING, isUploading: true, })

    if (!url) {
      const message = 'You must supply a url to upload files to.'
      yield put({ type: ApiTypes.FAILURE, message, })
      return { ok: false, responseBody: { error: message, }, }
    }

    if (!files || !files.length) {
      const message = 'No files provided to upload.'
      yield put({ type: ApiTypes.FAILURE, message, })
      return { ok: false, responseBody: { error: message, }, }
    }

    const fileData = new FormData()

    for (let i = 0, len = files.length; i < len; i++) {
      const file = files[i]
      fileData.append('files[]', file, file.name)
    }

    const body = {
      method  : 'POST',
      body    : fileData,
      headers : { 'Accept': '*/*', },
    }

    const fileResp = yield call(doFetch, url, body)

    yield put({ type: FileTypes.SET_SUCCESSFUL_UPLOAD, successful: fileResp.ok, })

    return fileResp
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: FileTypes.FILE_UPLOADING, isUploading: false, })
  }
}
