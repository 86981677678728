import React from 'react'
import { Link, } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, CardText, } from 'reactstrap'

const colSize = { size: '6', offset: '3', }

const AgentUnverified = () => {
  return (
    <Container>
      <Row className={'mt-4'}>
        <Col md={colSize}>
          <Card>
            <CardBody>
              <CardText>
                  Your user account is awaiting verification.<br/>
                  You are unable to create or manage Burn Permits, Burn Requests or Post Burn data until your account is verified.<br/>
                  Click the button below to navigate back to the home page.
              </CardText>
              <Link to={'/'} className={'btn btn-md btn-secondary'}>Home</Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(AgentUnverified)