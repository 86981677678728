import { attr, fk, many, Model, } from 'redux-orm'
import { getTomorrowsDate, dateFormatter, getTodaysDate, } from '../utilities'
import * as Yup from 'yup'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnRequest extends Model {
  static get modelName () {
    return 'BurnRequest'
  }

  toString () {
    return `${this.id}`
  }

  static endpoint (burnRequestId = null) {
    let endpoint = `${REACT_APP_SERVER_URL}BurnRequests`
    if (burnRequestId) {
      endpoint += `/${burnRequestId}`
    }
    return endpoint
  }

  static postBurnEndpoint ({ burnRequestId, }) {
    let endpoint = `${REACT_APP_SERVER_URL}BurnRequests`
    if (burnRequestId) {
      endpoint += `/${burnRequestId}/PostBurn`
    }
    return endpoint
  }

  static getValidationSchema (isDnr = false) {
    const {
      PlannedIgnitionPeriod,
      ProposedBurnArea,
      TotalProposedBurnQuantity,
    } = this.fields

    const schemaShape = {
      PlannedIgnitionTime: Yup.string()
        .test(
          'between-0600-and-1800',
          'Ignition time must be between the hours of 0600 and 1800',
          (ignTime) => {
            if (!ignTime) {
              return false
            }
            const hoursMins = parseInt(ignTime.replace(':', ''))
            return hoursMins >= 600 && hoursMins <= 1800
          }
        )
        .required('Please enter an ignition time'),
      PlannedIgnitionPeriod: Yup.number()
        .typeError('Ignition period must be a number')
        .min(PlannedIgnitionPeriod.opts.min, `Ignition period must be between ${PlannedIgnitionPeriod.opts.min} and ${PlannedIgnitionPeriod.opts.max}`)
        .max(PlannedIgnitionPeriod.opts.max, `Ignition period must be between ${PlannedIgnitionPeriod.opts.min} and ${PlannedIgnitionPeriod.opts.max}`)
        .required('Please enter an ignition duration'),
      ProposedBurnArea: Yup.number()
        .typeError('Burn area must be a number')
        .min(ProposedBurnArea.opts.min, `Burn area must be greater than ${ProposedBurnArea.opts.min}`)
        .required('Please enter the amount of acres to be burned')
        .test(
          'proposed-burn-acres-must-be-less-than-remaining-permit-acres',
          'The requested burn acres cannot exceed the remaining permit acreage.',
          function (acres) {
            return acres <= this.parent.RemainingPermitAcres
          }
        ),
      TotalProposedBurnQuantity: Yup.number()
        .typeError('Burn tonnage must be a number')
        .min(TotalProposedBurnQuantity.opts.min, `Burn tonnage must be at least ${TotalProposedBurnQuantity.opts.min}`)
        .required('Please enter the tonnage to be burned')
        .test(
          'proposed-quantity-must-be-less-than-remaining-permit-tonnage',
          'The requested tonnage cannot exceed the remaining permit tonnage.',
          function (tons) {
            return tons <= this.parent.RemainingPermitTonnage
          }
        ),
      MultiDayBurnFlag : Yup.boolean().required('Please choose Multi-Day or Single Day'),
      DaysOfWeek       : Yup.array().min(1, 'You must select at least one Burn Day.').nullable()
        .test(
          'days-must-be-consecutive',
          'You must select consecutive days for a Multi-Day Burn.',
          function (daysOfWeek) {
            if (!this.parent.PlannedIgnitionDate || (daysOfWeek && daysOfWeek.length === 1)) {
              return true
            }

            if (!daysOfWeek) {
              return false
            }
            
            const ignDate = dateFormatter(this.parent.PlannedIgnitionDate)
            const ignDay = ignDate.day() + 1
            // transform daysOfWeek ints to dates based on `this.parent.PlannedIgnitionDate`
            const burnDates = daysOfWeek
              .map(d => {
                const diff = d - ignDay
                // Day of the week after the ignition date 
                if (diff < 0) {
                  return ignDate.add(7 - Math.abs(diff), 'day')
                }
                // Day of the week of the ignition date
                else if (diff > 0) {
                  return ignDate.add(diff, 'day')
                }
                // Day of the igition date
                return ignDate
              }, [])
              // sort the transformed array
              .sort((d1, d2) => d2.isAfter(d1) ? -1 : 1)

            let isValid = true
            for (let i = 0, len = burnDates.length; i < len; i++) {
              if (i + 1 === len) {
                break
              }
              const currItem = burnDates[i]
              const nextItem = burnDates[i + 1]
              // compare date diffs to ensure they're consecutive
              isValid = (currItem && nextItem && currItem.diff(nextItem, 'day') === -1)
              if (!isValid) {
                break
              }
            }
            return isValid
          }
        ),
    }
    
    // add ignition date validators
    schemaShape.PlannedIgnitionDate = Yup.date().required('Please enter an ignition date')
    if (!isDnr) {
      schemaShape.PlannedIgnitionDate = schemaShape.PlannedIgnitionDate
        .when('ParentRequestId', {
          is        : (prid) => (!!prid) && !isNaN(prid),
          then      : schemaShape.PlannedIgnitionDate.max(getTomorrowsDate(), 'Ignition date must be within 24 hours.'),
          otherwise : schemaShape.PlannedIgnitionDate.min(getTomorrowsDate(), 'You must enter a future date.'),
        })
        .when('TotalProposedBurnQuantity', {
          is        : tons => tons >= 100,
          then      : schemaShape.PlannedIgnitionDate.min(getTomorrowsDate(), 'You must enter a future date.'),
          otherwise : schemaShape.PlannedIgnitionDate.min(getTodaysDate(), 'You must enter today&apos;s date or a future date.'),
        })
    }
    
    return Yup.object().shape(schemaShape)
  }
  
  static get options () {
    return {
      idAttribute: 'BurnRequestId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      BurnRequestId             : attr(),
      // Model-Specific Properties
      MultiDayBurnFlag          : attr(),
      BurnPriority              : attr(),
      BurnRequestDate           : attr(),
      PlannedIgnitionDate       : attr(),
      PlannedIgnitionPeriod     : attr({ min: 0, max: 480, }),
      PlannedIgnitionTime       : attr(),
      SmokeDispersedFlag        : attr(),
      TotalProposedBurnQuantity : attr({ min: 0.01, }),
      ProposedBurnArea          : attr({ min: 0.01, }),
      RequestorComments         : attr(),
      AutoGenerated             : attr(),
      ParentRequestId           : attr(),
      
      // Relationships
      BurnPermitId        : fk({ to: 'BurnPermit', relatedName: 'BurnRequests', as: 'BurnPermit', }),
      BurnRequestStatuses : many({ to: 'BurnRequestStatus', as: 'BurnRequestStatuses', through: 'BurnRequestStatusXref', }),
      DaysOfWeek          : many({ to: 'DaysOfWeek', relatedName: 'BurnRequests', }),
  
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}