import React from 'react'
import { array, string, } from 'prop-types'

const Tile = (props) => {
  const { title, children, } = props
  return (
    <div className={'tile'}>
      <div className={'tile-title-container'}>
        <span className={'tile-title'}>{title}</span>
      </div>
      <div className={'tile-overlay-menu'}>
        <ul>
          { children.map((child, index) => <li key={index}>{child}</li>) }
        </ul>
      </div>
    </div>
  )
}

Tile.propTypes = {
  children : array,
  title    : string,
}

export default Tile