import React, { useEffect, useState, } from 'react'
import { Navigate, useLocation, } from 'react-router-dom'
import { connect, useSelector, } from 'react-redux'
import { Container, Row, Col, Card, CardHeader, CardBody, NavLink, } from 'reactstrap'
import { func, object, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import ApiActions from '../redux/ApiRedux'
import UserTypes from '../redux/UserRedux'

// Selectors
import { returnUrlSelector, } from '../selectors/appSelectors'


// eslint-disable-next-line no-undef
const { REACT_APP_SAW_LOGIN_URL, REACT_APP_SERVER, } = process.env
const ADFS_LOGIN_URL = `${REACT_APP_SERVER}Account/ADFS`


const LoginContainer = props => {
  const [ redirectUrl, setRedirect, ] = useState('')

  const returnUrl = useSelector(returnUrlSelector)
  const location = useLocation()
  const { search, } = location

  useEffect(() => {
    props.SetPageTitle('Login')

    const params = new URLSearchParams(search)
    const token = params.get('token')

    if (token) {
      props.LoginReceived(token)
      if (returnUrl) {
        setRedirect(returnUrl)
      }
      else {
        setRedirect('/Profile')
      }
    }

    const error = params.get('error')
    if (error) {
      props.Failure(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />
  }

  return <Container className={'pt-2'}>
    <h1>Login</h1>
    <Row>
      <Col md={6} className={'mb-4'}>
        <Card className={'h-100'}>
          <CardHeader>SAW Login</CardHeader>
          <CardBody className={'d-flex flex-column justify-content-between'}>
            <p>Are you a Landowner, Agent, or an employee of another municipality?</p>
            <p>Use Secure Access Washington (SAW) to log in. Then you will be able to create and manage your Burn Permits, Requests, and Post Burn data.</p>
            <NavLink href={REACT_APP_SAW_LOGIN_URL} className={'align-self-end'}>
              SAW Login
              <span className={'fa fa-external-link-alt ml-2'}></span>
            </NavLink>
          </CardBody>
        </Card>
      </Col>
      <Col md={6} className={'mb-4'}>
        <Card className={'h-100'}>
          <CardHeader>WA State Employee Login</CardHeader>
          <CardBody className={'d-flex flex-column justify-content-between'}>
            <p>Are you a Washington State employee?</p>
            <p>Use the link below to log in with your state issued active directory account. Then you will be able to review and manage Burn Permits, Requests, and Post Burn data.</p>
            <NavLink href={ADFS_LOGIN_URL} className={'align-self-end'}>
              WA State Employee Login
              <span className={'fa fa-external-link-alt ml-2'}></span>
            </NavLink>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
}

LoginContainer.propTypes = {
  location      : object,
  SetReturnUrl  : func,
  LoginReceived : func,
  SetPageTitle  : func,
  Failure       : func,
}

const mapDispatchToProps = {
  SetPageTitle  : AppActions.setPageTitle,
  LoginReceived : UserTypes.loginReceived,
  Failure       : ApiActions.failure,
  SetReturnUrl  : AppActions.setReturnUrl,
}

export default connect(null, mapDispatchToProps)(LoginContainer)