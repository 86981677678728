import {
  TRS,
  UGA,
  COUNTIES,
  REGIONS,
  FIRE_DISTRICTS,
  SMOKE_PLANNING_AREAS,
} from '../map/featureLayers'
import ALL_LAYERS from '../map/geojsonLayers'

const RequestSearchLayerConfig = {
  name         : 'Request Search',
  hiddenLayers : [
    TRS.id,
    UGA.id,
    COUNTIES.id,
    REGIONS.id,
    FIRE_DISTRICTS.id,
    SMOKE_PLANNING_AREAS.id,
  ],
  unavailableLayers: ALL_LAYERS.map(l => l().id),
}

export default RequestSearchLayerConfig