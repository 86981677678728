// Libraries
import React from 'react'
import { array, } from 'prop-types'

// Utilities
import { formatPhone, } from '../../utilities'

const ContactConditionList = ({ contacts, }) => {
  if (contacts.length > 0) {
    return <ul>
      {
        contacts.map((val, idx) => {
          const formattedPhone = formatPhone(val.PhoneNumber)
          return <li key={`contact-cond-${idx}`} className={'mb-2'}>
            <b>To Notify Before Ignition: </b>{ val.ContactName }
            <br />
            <b>Phone: </b><a href={`tel:${formattedPhone}`}>{ formattedPhone }</a>&nbsp;
            {
              val.PhoneExt 
                ? <><b>Ext: </b>{ val.PhoneExt }</>
                : null
            }
          </li>
        })
      }
    </ul>
  }
  return null
}

ContactConditionList.propTypes = {
  contacts: array,
}
ContactConditionList.defaultProps = {
  contacts: null,
}

export default ContactConditionList