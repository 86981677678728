// Libraries
import React from 'react'
import * as Yup from 'yup'
import { Formik, Field, } from 'formik'
import { bool, string, func, } from 'prop-types'
import { Col, Label, Row, } from 'reactstrap'

// Components
import ValidatingField from '../FormControls/ValidatingField'
import ExtendPermit from '../Modals/ExtendPermit'

// Utilities
import { getDateInXDays, isAfter, } from '../../utilities'


const PERMIT_EXTENSION_SCHEMA = Yup.object().shape({
  ExpirationDate: Yup.date()
    .label('Expiration Date')
    .typeError('Expiration Date must be a valid date.')
    .test('exp-after-valid',
      'Expiration Date must be after Valid Date.',
      function (value) {
        if (value && this.parent.ValidDate) {
          return isAfter(this.parent.ValidDate, value)
        }
        return true
      }
    ),
  ValidDate: Yup.date().required('You must set a Valid date.'),
})

const ExtendPermitForm = (props) => {
  const { 
    readOnly,
    onSubmit,
    ValidDate,
  } = props

  return <Formik
    validationSchema={PERMIT_EXTENSION_SCHEMA}
    enableReinitialize={true}
    initialValues={{ ExpirationDate: '', ValidDate, }}
    onSubmit={onSubmit}
    validateOnMount={true}
  >
    {({ values, handleSubmit, }) => (
      <>
        {!readOnly && <p>Set the new expiration date for the permit. It must be a date later than 14 days from today.</p>}
        <Row className={'mb-2'}>
          <Col>
            <Label for={'ExpirationDate'}>New Expiration Date</Label>
            <Field
              type={'date'}
              id={'ExpirationDate'}
              name={'ExpirationDate'}
              min={getDateInXDays(15)}
              readOnly={readOnly}
              component={ValidatingField}
            />
          </Col>
        </Row>
        { !readOnly &&
        <Row className={'mb-2'}>
          <Col className={'d-flex justify-content-end'}>
            <ExtendPermit
              submitAction={handleSubmit}
              submitArgs={[ values, ]}
            />
          </Col>
        </Row>
        }
      </>
    )}
  </Formik>
}

ExtendPermitForm.propTypes = {
  readOnly       : bool,
  ExpirationDate : string,
  onSubmit       : func,
  ValidDate      : string,
}

ExtendPermitForm.defaultProps = {
  readOnly       : false,
  ExpirationDate : '',
  onSubmit       : () => {},
  ValidDate      : '',
}

export default ExtendPermitForm