// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Toast, ToastBody, } from 'reactstrap'
import { func, bool, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'

// Components
import { SuccessIcon, } from './Icons'

 
class SuccessToast extends React.Component {

  static propTypes = {
    onSuccess   : func,
    HideSuccess : func,
    ShowSuccess : bool,
  }
  
  onClick = () => {
    if (typeof this.props.onSuccess === 'function') {
      this.props.onSuccess()
    }
    this.props.HideSuccess()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.ShowSuccess === false && this.props.ShowSuccess === true) {
      setTimeout(this.props.HideSuccess, 3000)
    }
  }

  render () {
    const { ShowSuccess, } = this.props

    if (!ShowSuccess) {
      return null
    }

    return (
      <Toast className={'success-toast'}>
        <ToastBody>
          <SuccessIcon />
          Success
          <button className={'ml-2 mb-1 close'} data-dismiss={'toast'}
            aria-label={'Close'}
            onClick={this.onClick}>
            Ok
          </button>
        </ToastBody>
      </Toast>
    )
  }
}

function mapStateToProps (state) {
  return {
    ShowSuccess: state.app.showSuccess,
  }
}

const mapDispatchToProps = {
  HideSuccess: AppActions.hideSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessToast)