import { attr, Model, } from 'redux-orm'

export default class County extends Model {
  static get modelName () {
    return 'County'
  }

  toString () {
    return `${this.CountyName}`
  }

  static endpoint () {
    return 'Counties'
  }

  static get options () {
    return {
      idAttribute: 'CountyId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      CountyId   : attr(),
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
      // Model-Specific Properties
      CountyName : attr(),

      // Relationships
    }
  }
}