import { attr, Model, } from 'redux-orm'

export default class DuffType extends Model {
  static get modelName () {
    return 'DuffType'
  }

  toString () {
    return `${this.DuffTypeName}`
  }

  static endpoint () {
    return 'DuffTypes'
  }

  static get options () {
    return {
      idAttribute: 'DuffTypeId',
    }
  }

  static get fields () {
    return {
      DuffTypeId   : attr({ type: 'number', }),
      DuffTypeName : attr(),
      CreateBy     : attr(),
      CreateDate   : attr(),
      UpdateBy     : attr(),
      UpdateDate   : attr(),
    }
  }
}