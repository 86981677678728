// Libraries
import React, { useCallback, } from 'react'
import { useField, useFormikContext, } from 'formik'
import { bool, string, } from 'prop-types'

// Components
import { ValidatingField, } from '../FormControls'

// Utilities
import { dateFormatter, } from '../../utilities'


const ValidDateField = (props) => {

  const { values, setValues, errors, } = useFormikContext()

  const { ValidDate, PermitDuration, } = values
  const [ inputProps, ] = useField({ ...props, value: ValidDate, })
  const formProps = {
    errors,
  }

  const validDateChange = useCallback((evt) => {
    let expDate = ''
    const { value, } = evt.target
    if (value) {
      expDate = dateFormatter(value).add(PermitDuration, 'year')
      expDate = dateFormatter(expDate.toDate(), 'YYYY-MM-DD')
    }
    setValues({
      ...values,
      ValidDate      : value,
      ExpirationDate : expDate,
      PermitDuration : PermitDuration,
    })
    return evt || value
  }, [ setValues, values, PermitDuration, ])

  return <ValidatingField
    {...props}
    form={formProps}
    field={inputProps}
    onChange={validDateChange}
  />
}

ValidDateField.defaultProps = {
  name     : 'ValidDate',
  type     : 'date',
  readOnly : false,
}

ValidDateField.propTypes = {
  name     : string,
  type     : string,
  readOnly : bool,
}

export default React.memo(ValidDateField)
