import React, { useEffect, } from 'react'
import { useDispatch, } from 'react-redux'
import { useLocation, useNavigate, } from 'react-router-dom'
import { func, object, oneOfType, bool, string, } from 'prop-types'

// Components
import NotFound from '../components/NotFound'
import LoggingIn from '../components/LoggingIn'

// Actions
import AppActions from '../redux/AppRedux'


function AdminRoute (props) {
  const {
    children,
    IsLoadingUser,
    LoginError,
    isAuthenticated,
    isDnr,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      if (location && 'pathname' in location) {
        dispatch(AppActions.setReturnUrl(location.pathname))
      }
      navigate('/Login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isAuthenticated, dispatch, navigate, ])
  if (IsLoadingUser || LoginError) {
    return <LoggingIn loginError={LoginError} />
  }
  else if (isDnr === true) {
    return children
  }
  return <NotFound />
}

AdminRoute.propTypes = {
  children        : oneOfType([ func, object, ]),
  IsLoadingUser   : bool,
  LoginError      : string,
  isAuthenticated : bool,
  isDnr           : bool,
}

AdminRoute.defaultProps = {
  children        : null,
  IsLoadingUser   : false,
  LoginError      : '',
  isAuthenticated : false,
  isDnr           : false,
}

export default React.memo(AdminRoute)
