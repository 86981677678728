import { createReducer, createActions, } from 'reduxsauce'

import { removeItemFromArray, addItemToArray, } from '../utilities'

import DEFAULT_COMPONENT_LAYER_CONFIGS from '../config/layerConfigs'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  resetToInitialMapState              : null,
  getLatLongFromMap                   : [ 'enable', 'IncludeIntersectingFeatures', ],
  clearLatLong                        : null,
  setLatLong                          : [ 'Latitude', 'Longitude', 'IntersectingFeatures', ],
  showLayer                           : [ 'layerId', ],
  hideLayer                           : [ 'layerId', ],
  setConfigLayerVisibility            : [ 'configName', 'layerId', 'visible', ],
  toggleAllConfigLayerVisibility      : [ 'configName', 'layerIds', 'visible', ],
  mapLoading                          : null,
  mapDoneLoading                      : null,
  toggleDownloadMapData               : null,
  checkIfGraphicsOverlapLayerFeatures : [ 'layerId', 'graphics', ],
  graphicsOverlapFeatures             : [ 'overlap', 'features', ],
  activeMapId                         : [ 'mapId', ],
  mapDragEnd                          : [ 'evt', ],
})

export const MapTypes = Types
export default Creators

/* ------------- Initial State ------------- */

const INITIAL_STATE = Object.freeze({
  LatLong: {
    Latitude  : null,
    Longitude : null,
  },
  GetLatLongFromMap           : false,
  IncludeIntersectingFeatures : false,
  hiddenLayerIds              : [],
  MapLoading                  : false,
  MapActive                   : false,
  downloadMapData             : false,
  layerId                     : null,
  overlapGraphics             : [],
  intersectingFeatures        : [],
  graphicsOverlap             : false,
  componentLayerConfigs       : DEFAULT_COMPONENT_LAYER_CONFIGS,
  activeMapId                 : null,
})

/* ------------- Reducers ------------- */

const resetToInitialMapState = () => {
  return INITIAL_STATE
}

const mapLoading = (state) => {
  const newState = { ...state, }
  newState.GetLatLongFromMap = false
  newState.MapLoading = true
  newState.MapActive = false
  newState.graphicsOverlap = false
  newState.overlapGraphics = []
  newState.layerId = null
  return newState
}

const mapDoneLoading = (state) => {
  const newState = { ...state, }
  newState.MapLoading = false
  newState.MapActive = true
  return newState
}

const getLatLongFromMap = (state, { enable, IncludeIntersectingFeatures, }) => {
  return { ...state, GetLatLongFromMap: enable || false, IncludeIntersectingFeatures: IncludeIntersectingFeatures || false, }
}

const clearLatLong = state => {
  const newState = { ...state, }
  newState.LatLong = {
    Latitude  : null,
    Longitude : null,
  }
  return newState
}

const setLatLong = (state, { Latitude, Longitude, IntersectingFeatures = null, }) => {
  const newState = { ...state, }
  if (Latitude) {
    newState.LatLong.Latitude = Latitude
  }
  if (Longitude) {
    newState.LatLong.Longitude = Longitude
  }
  newState.intersectingFeatures = [ ...(IntersectingFeatures || []), ]
  return newState
}

const showLayer = (state, { layerId, }) => {
  const newState = { ...state, }
  newState.hiddenLayerIds = removeItemFromArray(newState.hiddenLayerIds, layerId)
  return newState
}

const hideLayer = (state, { layerId, }) => {
  const newState = { ...state, }
  newState.hiddenLayerIds = addItemToArray(newState.hiddenLayerIds, layerId)
  return newState
}

const toggleDownloadMapData = (state) => {
  return { ...state, downloadMapData: !state.downloadMapData, }
}

// Provide default values so this can be called without any arguments to allow
// callers to clear/reset the state values
const checkIfGraphicsOverlapLayerFeatures = (state, { layerId = '', graphics = [], }) => {
  return { ...state, layerId, overlapGraphics: [ ...graphics, ], graphicsOverlap: false, }
}

const graphicsOverlapFeatures = (state, { overlap = false, features = [], }) => {
  return { ...state, graphicsOverlap: overlap, overlappingFeatures: [ ...features, ], }
}

const setConfigLayerVisibility = (state, { configName, layerId, visible, }) => {
  const newState = { ...state, }
  const layerConfigIdx = newState.componentLayerConfigs.findIndex(c => c.name === configName)
  const layerConfig = newState.componentLayerConfigs[layerConfigIdx]
  if (!layerConfig) {
    return newState
  }
  if (visible) {
    layerConfig.hiddenLayers = removeItemFromArray(layerConfig.hiddenLayers, layerId)
  }
  else {
    layerConfig.hiddenLayers = addItemToArray(layerConfig.hiddenLayers, layerId)
  }
  newState.componentLayerConfigs[layerConfigIdx] = layerConfig
  return newState
}

const toggleAllConfigLayerVisibility = (state, { configName, layerIds, visible, }) => {
  const newState = { ...state, }
  const layerConfigIdx = newState.componentLayerConfigs.findIndex(c => c.name === configName)
  const layerConfig = newState.componentLayerConfigs[layerConfigIdx]
  if (!layerConfig) {
    return newState
  }
  if (visible) {
    layerConfig.hiddenLayers = []
  }
  else {
    layerConfig.hiddenLayers = [ ...layerIds, ]
  }
  newState.componentLayerConfigs[layerConfigIdx] = layerConfig
  return newState
}

const activeMapId = (state, { mapId = null, }) => ({ ...state, activeMapId: mapId, })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_TO_INITIAL_MAP_STATE]               : resetToInitialMapState,
  [Types.CLEAR_LAT_LONG]                           : clearLatLong,
  [Types.SET_LAT_LONG]                             : setLatLong,
  [Types.GET_LAT_LONG_FROM_MAP]                    : getLatLongFromMap,
  [Types.SHOW_LAYER]                               : showLayer,
  [Types.HIDE_LAYER]                               : hideLayer,
  [Types.MAP_LOADING]                              : mapLoading,
  [Types.MAP_DONE_LOADING]                         : mapDoneLoading,
  [Types.TOGGLE_DOWNLOAD_MAP_DATA]                 : toggleDownloadMapData,
  [Types.CHECK_IF_GRAPHICS_OVERLAP_LAYER_FEATURES] : checkIfGraphicsOverlapLayerFeatures,
  [Types.GRAPHICS_OVERLAP_FEATURES]                : graphicsOverlapFeatures,
  [Types.SET_CONFIG_LAYER_VISIBILITY]              : setConfigLayerVisibility,
  [Types.TOGGLE_ALL_CONFIG_LAYER_VISIBILITY]       : toggleAllConfigLayerVisibility,
  [Types.ACTIVE_MAP_ID]                            : activeMapId,
})