import React, { useCallback, useEffect, useMemo, useRef, } from 'react'
import { bool, number, oneOfType, string, } from 'prop-types'
import { Button, Col, Form, FormGroup, Input, Label, Row, } from 'reactstrap'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import AssociatedBurns from './AssociatedBurns'
import { DateRange, PermitNumber, SearchInput, } from '../../../SearchInputs'
import { AutoComplete, } from '../../../FormControls'

// Selectors
import { regionsForSelectSelector, } from '../../../../selectors/regionSelectors'
import {
  associatedBurnsSearchParams,
  getSmokeComplaintSelector,
  smokeComplaintAssociatedBurnSearchResults,
} from '../../../../selectors/customerInteractionSelectors'
import {
  countiesForSelectSelector,
  fireDistrictDepartmentsForSelectSelector,
} from '../../../../selectors/selectors'

// Reducers
import CustomerInteractionActions from '../../../../redux/CustomerInteractionRedux'

// Hooks
import { useAutoComplete, useEnterPressHandler, } from '../../../Search/hooks'

// Utilities
import { dateFormatter, } from '../../../../utilities'


const SearchForm = props => {
  const { CustomerInteractionId, show, } = props

  // Selectors
  const smokeComplaint = useSelector(state => getSmokeComplaintSelector(state, CustomerInteractionId))
  const searchResults = useSelector(smokeComplaintAssociatedBurnSearchResults)
  const searchParams = useSelector(associatedBurnsSearchParams)
  const regions = useSelector(regionsForSelectSelector)
  const counties = useSelector(countiesForSelectSelector)
  const fireDistricts = useSelector(fireDistrictDepartmentsForSelectSelector)

  // Redux actions
  const dispatch = useDispatch()

  const initialParams = useMemo(() => {
    if (!smokeComplaint || !smokeComplaint.CustomerInteractionId) {
      return
    }
    const { CustomerInteractionId, CreateDate, } = smokeComplaint
    let StartDate = dateFormatter(CreateDate, null, true).add(-1, 'day').format('YYYY-MM-DD')
    return {
      CustomerInteractionId,
      StartDate,
      EndDate          : dateFormatter(CreateDate, 'YYYY-MM-DD', true),
      Radius           : 50,
      BurnPermitId     : '',// - In case the DNR rep knows the specific burn it's associated with
      BurnPermitNumber : '',// - In case the DNR rep knows the specific burn it's associated with
      PostBurnId       : '',// - In case the DNR rep knows the specific burn it's associated with
      BurnRequestId    : '',// - In case the DNR rep knows the specific burn it's associated with
      RegionId         : '',
      CountyId         : '',
      FireDistrictId   : '',
    }
  }, [ smokeComplaint, ])

  // UI Callbacks
  const cancelSearch = useCallback(() => {
    dispatch(CustomerInteractionActions.toggleFindBurnsForm(false))
  }, [ dispatch, ])

  const resetSearch = useCallback(() => {
    dispatch(CustomerInteractionActions.findAssociatedBurns(initialParams))
  }, [ initialParams, dispatch, ])

  // mount effect
  useEffect(() => {
    dispatch(CustomerInteractionActions.findAssociatedBurns(initialParams))
  }, [ initialParams, dispatch, ])

  const prevShow = useRef(show)
  useEffect(() => {
    if (prevShow.current !== true && show === true) {
      dispatch(CustomerInteractionActions.findAssociatedBurns({ ...searchParams, submit: true, }))
    }
    prevShow.current = show
  }, [ show, searchParams, dispatch, ])

  const onChange = useCallback(e => {
    const { name, value, } = e.target
    const newParams = {
      ...searchParams,
      [name]                : value || '',
      CustomerInteractionId : smokeComplaint.CustomerInteractionId,
      submit                : false,
    }
    dispatch(CustomerInteractionActions.findAssociatedBurns(newParams))
  }, [ smokeComplaint, searchParams, dispatch, ])

  const updateSearchParams = useCallback(e => {
    const { name, value, } = e.target
    const newParams = {
      ...searchParams,
      [name]                : value || '',
      CustomerInteractionId : smokeComplaint.CustomerInteractionId,
    }
    dispatch(CustomerInteractionActions.findAssociatedBurns(newParams))
  }, [ smokeComplaint, searchParams, dispatch, ])

  const onSearchClick = useCallback(() => {
    const newParams = {
      ...searchParams,
      submit: true,
    }
    dispatch(CustomerInteractionActions.findAssociatedBurns(newParams))
  }, [ searchParams, dispatch, ])

  const onSubmittedDateChosen = useCallback(({ from, to, }) => {
    const newParams = {
      ...searchParams,
      StartDate             : dateFormatter(from || '', 'YYYY-MM-DD'),
      EndDate               : dateFormatter(to || '', 'YYYY-MM-DD'),
      CustomerInteractionId : smokeComplaint.CustomerInteractionId,
      submit                : false,
    }
    dispatch(CustomerInteractionActions.findAssociatedBurns(newParams))
  }, [ smokeComplaint, searchParams, dispatch, ])

  const onAutoComplete = useCallback(e => {
    const { name, id, value, } = e.target
    const newParams = {
      ...searchParams,
      [name]                : value || '',
      [`${name}Id`]         : id || '',
      CustomerInteractionId : smokeComplaint.CustomerInteractionId,
      submit                : false,
    }
    dispatch(CustomerInteractionActions.findAssociatedBurns(newParams))
  }, [ smokeComplaint, searchParams, dispatch, ])

  const [ regionAutoChange, ] = useAutoComplete('Region', onAutoComplete)
  const [ countyAutoChange, ] = useAutoComplete('County', onAutoComplete)
  const [ fireDistrictChange, ] = useAutoComplete('FireDistrict', onAutoComplete)

  const handleKeyPress = useEnterPressHandler(onSearchClick)

  if (!show) {
    return null
  }

  return <div>
    <Button
      size={'sm'}
      onClick={cancelSearch}
      color={'dark'}
    >Back</Button>
    <Form onKeyPress={handleKeyPress} className={'mt-3'}>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <SearchInput
              label={'Smoke Complaint ID'}
              labelClassName={'m-0'}
              name={'CustomerInteractionId'}
              defaultValue={searchParams.CustomerInteractionId || smokeComplaint.CustomerInteractionId}
              onChange={onChange}
              onBlur={updateSearchParams}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <SearchInput
              label={'Burn Permit ID'}
              labelClassName={'m-0'}
              name={'BurnPermitId'}
              defaultValue={searchParams.BurnPermitId}
              onChange={onChange}
              onBlur={updateSearchParams}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <PermitNumber
              value={searchParams.BurnPermitNumber}
              onChange={onChange}
              onBlur={updateSearchParams}
              showPopover={false}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <SearchInput
              label={'Burn Request ID'}
              labelClassName={'m-0'}
              name={'BurnRequestId'}
              defaultValue={searchParams.BurnRequestId}
              onChange={onChange}
              onBlur={updateSearchParams}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <SearchInput
              label={'Post Burn ID'}
              labelClassName={'m-0'}
              name={'PostBurnId'}
              defaultValue={searchParams.PostBurnId}
              onChange={onChange}
              onBlur={updateSearchParams}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <Label>Smoke/Burn Date</Label>
            <DateRange
              name={'SmokeBurnDate'}
              from={searchParams.StartDate}
              to={searchParams.EndDate}
              onClick={onSubmittedDateChosen}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <Label>Radius (mi.)</Label>
            <Input
              name={'Radius'}
              type={'number'}
              defaultValue={searchParams.Radius}
              onChange={onChange}
              onBlur={updateSearchParams}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <Label for={'Region'}>Region</Label>
            <AutoComplete
              items={regions}
              value={searchParams.Region}
              fieldName={'Region'}
              onChange={regionAutoChange}
              minCharactersToEnter={0}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <Label for={'County'}>County</Label>
            <AutoComplete
              items={counties}
              value={searchParams.County}
              fieldName={'County'}
              onChange={countyAutoChange}
              minCharactersToEnter={2}
            />
          </FormGroup>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <FormGroup>
            <Label for={'FireDistrict'}>Fire District</Label>
            <AutoComplete
              items={fireDistricts}
              value={searchParams.FireDistrict}
              fieldName={'FireDistrict'}
              onChange={fireDistrictChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Button
            color={'primary'}
            id={'btn-clear'}
            type={'reset'}
            onClick={resetSearch}
          >Reset</Button>
        </Col>
        <Col xs={6}>
          <Button
            color={'secondary'}
            id={'btn-search'}
            onClick={onSearchClick}
            className={'float-right'}>Search</Button>
        </Col>
      </Row>
    </Form>
    <AssociatedBurns
      CustomerInteractionId={CustomerInteractionId}
      elementId={'associated-burns-search-results'}
      burns={searchResults}
      enableAdd={true}
      show={show}
    />
  </div>
}

SearchForm.propTypes = {
  CustomerInteractionId : oneOfType([ number, string, ]),
  show                  : bool,
}

export default React.memo(SearchForm)
