import { attr, Model, } from 'redux-orm'

export default class PhoneType extends Model {
  static get modelName () {
    return 'PhoneType'
  }

  toString () {
    return `${this.PhoneTypeName}`
  }

  static endpoint () {
    return 'PhoneTypes'
  }

  static get options () {
    return {
      idAttribute: 'PhoneTypeId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PhoneTypeId          : attr({ inputType: 'number', inputLabel: 'Phone Type Id', }),
      CreateDate           : attr(),
      CreateBy             : attr(),
      UpdateDate           : attr(),
      UpdateBy             : attr(),
      // Model-Specific Properties
      PhoneTypeName        : attr(),
      PhoneTypeDescription : attr(),
  
      // Relationships
    }
  }
}