//Libraries
import React from 'react'
import { Button, } from 'reactstrap'
import { bool, func, number, string, PropTypes, } from 'prop-types'

// Components
import { PopoverButton, } from '../FormControls'

//Utilities
import stopEvent from '../../utilities/stopEvent'


const CartButton = props => {
  return <div className={'d-flex'}>
    <PopoverButton popoverHeader={'Order Help'}
      buttonClassName={'py-0'}
      popoverBody={<>
        <p>Click the Add <button className={'btn btn-sm btn-success fa fa-plus'} title={'Add Application to Order'} onClick={stopEvent} /> in the table below to add that Permit Application to your Order.</p>
        <p>Click the Remove <button className={'btn btn-sm btn-danger fa fa-minus'} title={'Remove Application from Order'} onClick={stopEvent} /> in the table below to remove that Permit Application from your Order.</p>
        <p>Click the Checkout <button className={'btn btn-sm btn-secondary fa fa-shopping-cart'} onClick={stopEvent} /> in the top right to finish creating your Order.</p>
      </>}
      placement={'bottom'}
      style={{ margin: 'auto 0', }}
    />
    <Button
      className={'btn btn-sm btn-secondary'}
      onClick={props.onClick}
      style={{ 'height': '2.75em', margin: 'auto 0', }}
      disabled={props.disabled}
    >
      <span className={'fa fa-shopping-cart mr-2'} />
      <span className={'badge badge-light'}>{props.badgeValue}</span>
    </Button>
  </div>
}

CartButton.propTypes = {
  onClick    : func,
  disabled   : bool,
  badgeValue : PropTypes.oneOfType([ number, string, ]),
}

CartButton.defaultProps = {
  onClick    : () => {},
  disabled   : false,
  badgeValue : '',
}

export default CartButton