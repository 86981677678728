// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Formik, Field, } from 'formik'
import { Row, Col, FormGroup, Label, Fade, } from 'reactstrap'
import { bool, array, func, } from 'prop-types'
import { isEmpty, } from 'lodash'

// Components
import {
  CheckBoxes,
  Select,
  ValidatingField,
  YesNoRadioButtonGroup,
} from './'
import Effect from '../Effect'

// Selectors
import {
  arrivalTimeForSelectSelector,
  burnPermitSiteEquipmentSelector,
  equipmentTypeCheckboxSelector,
} from '../../selectors/burnPermitSiteSelectors'

// Utilities
import { removeItemFromArray, addItemToArray, } from '../../utilities'

// Models
import BurnPermitSiteEquipmentXref from '../../models/BurnPermitSiteEquipmentXref'

// CONSTANTS
const FIRE_LINE_CONSTRUCTION = 'Fire Line Construction'
const EXTINGUISHING = 'Extinguishing'

class BurnPermitSiteEquipment extends React.Component {
  constructor (props) {
    super(props)
    
    this.submit = this.submit.bind(this)
    this.validateForms = this.validateForms.bind(this)
    this.formiks = []
  }

  static propTypes = {
    readOnly                : bool,
    ArrivalTime             : array,
    ExtinguishEquipmentType : array,
    FireLineEquipmentType   : array,
    siteEquipment           : array,
    isValidating            : bool,
    onChange                : func,
  }

  rowClasses = 'mb-3'

  componentDidUpdate (prevProps) {
    if (prevProps.isValidating === false && this.props.isValidating) {
      this.validateForms()
    }
  }

  async validateForms () {
    const validForms = await Promise.all([
      ...this.formiks
        .filter(f => !!f)
        .map(async f => {
          const errors = await f.validateForm()
          return isEmpty(errors)
        }, []), 
    ])
    return validForms.every(v => v)
  }

  submit () {
    // Transform the values to be an array of the objects
    // that flattens the EquipmentTypeId onto the object
    let siteEquip = []
    const forms = this.formiks.filter(f => !!f)
    for (let i = 0, len = forms.length; i < len; i++) {
      const { values, } = forms[i]
      if (values[`ShowEquipment_${values.FormIndex}`] && values) {
        if (values.OffsiteEquipmentArrivalTime) {
          const arrivalTime = this.props.ArrivalTime
            .find(a => a.Value.toString() === values.OffsiteEquipmentArrivalTime.toString())
          values.OffsiteEquipmentArrivalTime = arrivalTime
            ? arrivalTime.Text
            : values.OffsiteEquipmentArrivalTime
        }
        const mappedValues = values.EquipmentTypeIds.filter(i => !!i)
          .map(e => {
            return { 
              ...values,
              EquipmentTypeId: e,
            }
          })

        siteEquip.push(...mappedValues)
      }
    }
    siteEquip = siteEquip.flat()
    return siteEquip
  }

  isOtherEquipType = id => {
    const {
      FireLineEquipmentType,
      ExtinguishEquipmentType,
    } = this.props
    return [ ...ExtinguishEquipmentType, FireLineEquipmentType, ].some(e =>
      e.Value === id
      && e.Text === 'Other'
    )
  }

  setFormik = node => {
    if (node) {
      this.formiks.splice(node.values.FormIndex, 1, node)
    }
  }

  labels = [
    <>Do you have equipment <b>on site</b>  capable of fire line construction or for assistance in extinguishing burning/smoldering piles?</>,
    <>Do you have equipment <b>off site</b> capable of fire line construction or for assistance in extinguishing burning/smoldering piles?</>,
    <>Do you have water available <b>on site</b> to extinguish burning/smoldering piles?</>,
    <>Do you have water available <b>off site</b> to extinguish burning/smoldering piles?</>,
  ]

  render () {
    const {
      readOnly,
      onChange,
      siteEquipment,
      FireLineEquipmentType,
      ExtinguishEquipmentType,
    } = this.props
    const markup = siteEquipment.map((s, idx) => {
      return <Formik
        key={`SiteEquipForm_${idx}`}
        enableReinitialize={true}
        initialValues={{
          FormIndex                     : idx,
          BurnPermitSiteEquipmentXrefId : s.BurnPermitSiteEquipmentXrefId,
          OnsiteFlag                    : s.OnsiteFlag,
          OffsiteFlag                   : s.OffsiteFlag,
          FireLineConstructionFlag      : s.FireLineConstructionFlag,
          ExtinguishingFlag             : s.ExtinguishingFlag,
          OffsiteEquipmentArrivalTime   : s.OffsiteEquipmentArrivalTime,
          NumberOfGallons               : s.NumberOfGallons || '',
          OtherEquipmentDescription     : s.OtherEquipmentDescription,
          EquipmentTypeIds              : s.EquipmentTypeIds,
          // UI/Validation props
          Label                         : s.Label,
          [`ShowEquipment_${idx}`]      : s.HasEquipAnswer,
          [`ShowOther_${idx}`]          : !!s.OtherEquipmentDescription || s.EquipmentTypeIds.some(this.isOtherEquipType),
        }}
        innerRef={this.setFormik}
        validationSchema={BurnPermitSiteEquipmentXref.validationSchema({ idx, })}
      >
        {({ values, errors, }) => (
          <>
            {
              typeof onChange === 'function'
                ? <Effect values={values} onChange={onChange} />
                : null
            }
            <Row>
              <Col xs={12} sm={6} className={'align-items-top-bottom'}>
                <FormGroup>
                  <Label>{this.labels[values.FormIndex]}</Label>
                  <Field name={`ShowEquipment_${idx}`}>
                    {({ field, ...formProps }) => (
                      <YesNoRadioButtonGroup
                        onChange={evt => {
                          const { name, value, } = evt.target
                          formProps.form.setFieldValue(name, value === 'yes')
                        }}
                        inputName={field.name}
                        checkedValue={field.value}
                        readOnly={readOnly}
                        errorMessage={errors[field.name]}
                      />
                    )}
                  </Field>
                </FormGroup>
                <Fade 
                  in={!!values[`ShowEquipment_${idx}`] && values.ExtinguishingFlag}
                  style={{ padding: '0 0 0 1em', }}
                >
                  <FormGroup>
                    <Label for={'txt-gallons'}>
                      How many gallons of water are available {values.OnsiteFlag ? 'on' : 'off'} site?
                    </Label>
                    <Field
                      type={'number'}
                      id={'txt-gallons'}
                      name={'NumberOfGallons'}
                      className={'form-control'}
                      readOnly={readOnly}
                      component={ValidatingField}
                    />
                  </FormGroup>
                </Fade>
                <Fade 
                  in={!!values[`ShowEquipment_${idx}`] && (values.OffsiteFlag && values.FireLineConstructionFlag)}
                  style={{ padding: '0 0 0 1em', }}
                >
                  <Field name={'OffsiteEquipmentArrivalTime'}>
                    {({ field, }) => (
                      <Select
                        label={'What is the time it would take to get the equipment to the site?'}
                        items={this.props.ArrivalTime}
                        propertyName={field.name}
                        onChange={field.onChange}
                        selectedValue={field.value}
                        errorMessage={errors[field.name]}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                </Fade>
              </Col>
              <Col xs={12} sm={6}>
                <Fade in={!!values[`ShowEquipment_${idx}`]}>
                  <Field name={'EquipmentTypeIds'}>
                    {({ field, ...formProps }) => (
                      <CheckBoxes
                        className={'pl-3 pl-sm-0'}
                        inputName={field.name}
                        form={formProps.form}
                        onCheckboxChange={evt => {
                          const { name, value, checked, } = evt.target
                          const intValue = parseInt(value)
                          let currValues = [ ...formProps.form.values[name], ]
                          if (checked) {
                            currValues = addItemToArray(currValues, intValue)
                          }
                          else {
                            currValues = removeItemFromArray(currValues, intValue)
                          }
                          formProps.form.setValues({
                            ...formProps.form.values,
                            [name]               : currValues,
                            [`ShowOther_${idx}`] : currValues.some(this.isOtherEquipType),
                          })
                        }}
                        CheckboxLabel={`${values.OnsiteFlag ? 'On' : 'Off'} site equipment`}
                        CheckboxData={
                          values.FireLineConstructionFlag
                            ? FireLineEquipmentType
                            : values.OnsiteFlag
                              ? ExtinguishEquipmentType.filter(e => ![ 'Trailer', 'Water Truck', ].includes(e.Text) || e.Text === 'Other')
                              : ExtinguishEquipmentType.filter(e => [ 'Trailer', 'Water Truck', ].includes(e.Text) || e.Text === 'Other')
                        }
                        CheckedData={field.value}
                        OtherInputName={'OtherEquipmentDescription'}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                </Fade>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    })
    return markup
  }
}

function mapStateToProps (state, props) {
  const { online, } = state.offline
  return {
    online,
    ArrivalTime             : arrivalTimeForSelectSelector(state),
    FireLineEquipmentType   : equipmentTypeCheckboxSelector(state, { category: FIRE_LINE_CONSTRUCTION, }),
    ExtinguishEquipmentType : equipmentTypeCheckboxSelector(state, { category: EXTINGUISHING, useDesc: true, }),
    siteEquipment           : burnPermitSiteEquipmentSelector(state, props.burnPermitSiteId),
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true, })(BurnPermitSiteEquipment)