import { useCallback, } from 'react'
import { useSelector, } from 'react-redux'

// Reducer Actions
import UIActions  from '../redux/UiRedux'

// Selectors
import { isNavbarOpen, } from '../selectors/uiSelectors'

const useNavClick = dispatch => {
  const navbarOpen = useSelector(isNavbarOpen)
  const toggleNavbar = useCallback(() => {
    if (navbarOpen) {
      dispatch(UIActions.toggleNavbar())
    }
  }, [ dispatch, navbarOpen, ])
  return [ navbarOpen, toggleNavbar, ]
}

export default useNavClick
