import {
  createReducer,
  createActions,
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getBurnPermitSignatures       : [ 'burnPermitId', ],
  signPermitApplication         : [ 'burnPermitId', 'force', ],
  confirmApplicationSignature   : [ 'burnPermitId', 'envelopeId', ],
  applicationSignatureConfirmed : [ 'burnPermitId', ],
  previewPermit                 : [ 'burnPermitId', ],
  signPermit                    : [ 'burnPermitId', 'validDate', 'expirationDate', ],
  reIssuePermit                 : [ 'burnPermitId', ],
  confirmPermitSignature        : [ 'burnPermitId', 'envelopeId', ],
  permitSignatureConfirmed      : [ 'burnPermitId', ],
  resetSignatureState           : null,
  loadingDocuSignUrl            : [ 'loading', ],
  gettingSignatures             : [ 'getting', ],
  verifyingSignatures           : [ 'verifying', ],
  docuSignSignatureEvent        : [ 'event', ],
  signatureComplete             : [ 'burnPermitId', ],
  signatureFailed               : [ 'burnPermitId', 'error', 'enableForce', 'abandonedPermitApplicationId', ],
  acknowledgeSignatureSteps     : [ 'processName', 'acknowledgedSteps', 'doNotShowAgain', ],
})

export const BurnPermitSignatureTypes = Types
export default Creators

export const SignatureProcessTypes = {
  preview     : 'preview',
  issue       : 'issue',
  reissue     : 'reissue',
  application : 'application',
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  applicationSignatureConfirmed : false,
  permitSignatureConfirmed      : false,
  isLoadingDocuSignUrl          : false,
  isGettingSignatures           : false,
  isVerifyingSignatures         : false,
  isReissuingPermit             : false,
  burnPermitId                  : null,
  SignatureUrl                  : '',
  event                         : '',
  confirmingSignature           : false,
  confirmationFailed            : false,
  signatureError                : '',
  enableForceSignature          : false,
  abandonedPermitApplicationId  : '',
  // These state values should persist between resets
  acknowledgedSteps             : {
    [SignatureProcessTypes.preview]     : false,
    [SignatureProcessTypes.issue]       : false,
    [SignatureProcessTypes.reissue]     : false,
    [SignatureProcessTypes.application] : false,
  },
  doNotShowSteps: {
    [SignatureProcessTypes.preview]     : false,
    [SignatureProcessTypes.issue]       : false,
    [SignatureProcessTypes.reissue]     : false,
    [SignatureProcessTypes.application] : false,
  },
}

/* ------------- Reducers ------------- */

export const resetSignatureState = (state) => {
  return {
    ...INITIAL_STATE,
    // These state values should persist between resets
    acknowledgedSteps : state.acknowledgedSteps,
    doNotShowSteps    : state.doNotShowSteps,
  }
}

export const applicationSignatureConfirmed = (state, { burnPermitId, }) => {
  const newState = { ...state, }
  newState.burnPermitId = burnPermitId
  newState.applicationSignatureConfirmed = true
  newState.confirmingSignature = false
  return newState
}

export const permitSignatureConfirmed = (state, { burnPermitId, }) => {
  const newState = { ...state, }
  newState.burnPermitId = burnPermitId
  newState.permitSignatureConfirmed = true
  newState.confirmingSignature = false
  return newState
}

const loadingDocuSignUrl = (state, { loading, }) => {
  return { ...state, isLoadingDocuSignUrl: loading, }
}

const gettingSignatures = (state, { getting, }) => {
  return { ...state, isGettingSignatures: getting, }
}

const verifyingSignatures = (state, { verifying, }) => {
  return { ...state, isVerifyingSignatures: verifying, }
}

const reIssuePermit = (state) => {
  return { ...state, isReissuingPermit: true, }
}

const docuSignSignatureEvent = (state, { event, }) => ({
  ...state,
  event,
  confirmationFailed  : false,
  confirmingSignature : true,
})

const signatureComplete = (state, { burnPermitId, }) => ({
  ...state,
  burnPermitId,
  confirmingSignature: false,
})

const signatureFailed = (state, { burnPermitId, error, enableForce, abandonedPermitApplicationId, }) => ({
  ...state,
  burnPermitId,
  confirmingSignature  : false,
  confirmationFailed   : true,
  signatureError       : error,
  enableForceSignature : enableForce || false,
  isLoadingDocuSignUrl : false,
  abandonedPermitApplicationId,
})

const acknowledgedSteps = (state, { processName, acknowledgedSteps, doNotShowAgain = false, }) => {
  const newState = { ...state, }
  if (newState.doNotShowSteps[processName] === false) {
    newState.acknowledgedSteps[processName] = acknowledgedSteps
    newState.doNotShowSteps[processName] = doNotShowAgain
  }
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.APPLICATION_SIGNATURE_CONFIRMED] : applicationSignatureConfirmed,
  [Types.PERMIT_SIGNATURE_CONFIRMED]      : permitSignatureConfirmed,
  [Types.RESET_SIGNATURE_STATE]           : resetSignatureState,
  [Types.LOADING_DOCU_SIGN_URL]           : loadingDocuSignUrl,
  [Types.GETTING_SIGNATURES]              : gettingSignatures,
  [Types.VERIFYING_SIGNATURES]            : verifyingSignatures,
  [Types.RE_ISSUE_PERMIT]                 : reIssuePermit,
  [Types.DOCU_SIGN_SIGNATURE_EVENT]       : docuSignSignatureEvent,
  [Types.SIGNATURE_COMPLETE]              : signatureComplete,
  [Types.SIGNATURE_FAILED]                : signatureFailed,
  [Types.ACKNOWLEDGE_SIGNATURE_STEPS]     : acknowledgedSteps,
})