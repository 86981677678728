import { useFormikContext, } from 'formik'
import React, { useCallback, } from 'react'
import { useSelector, } from 'react-redux'
import { Button, } from 'reactstrap'
import { func, string, } from 'prop-types'

// Utilities
import stopEvent from '../../utilities/stopEvent'

// Selectors
import { userPersonModelSelector, } from '../../selectors/userSelectors'


const AssignToMe = (props) => {
  const { fieldName, } = props
  // Selectors
  const currentUserPerson = useSelector(userPersonModelSelector)

  // Redux actions
  const formik = useFormikContext()

  // UI Callbacks
  const assignToMe = useCallback((e) => {
    stopEvent(e)
    formik.setFieldValue(`${fieldName}`, currentUserPerson.toString())
    formik.setFieldValue(`${fieldName}Id`, currentUserPerson.PersonId)
  }, [ formik, currentUserPerson, fieldName, ])

  return <Button
    onClick={assignToMe}
    size={'md'}
    color={'primary'}
  >Assign to Me</Button>
}

AssignToMe.defaultProps = {
  fieldName: 'AssignedTo',
}

AssignToMe.propTypes = {
  fieldName : string,
  onClick   : func,
}

export default React.memo(AssignToMe)
