// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { object, bool, array, string, func, shape, } from 'prop-types'
import { Link, } from 'react-router-dom'
import { isEmpty, isEqual, } from 'lodash'
import { Container, Button, Row, Col, CardText, Alert, ListGroup, ListGroupItem, FormGroup, Label, ButtonGroup, } from 'reactstrap'

// Components
import ConfirmationModal from '../components/ConfirmationModal'
import LoadingBar from '../components/LoadingBar'
import { SuccessIcon, } from '../components/Icons'
import { ButtonGroupButton, } from '../components/FormControls'
import BurnPermitTable from '../components/Search/BurnPermitTable'

// Actions
import AppActions from '../redux/AppRedux'
import BurnPermitListActions from '../redux/BurnPermitListRedux'
import MapActions from '../redux/MapRedux'
import UiActions from '../redux/UiRedux'

// Selectors
import { permitSearchSelector, } from '../selectors/burnPermitSelectors'
import { orderByIdSelector, } from '../selectors/orderSelectors'
import { usersAgencyPaysByVoucher, } from '../selectors/userSelectors'
import { userCanBeAssignedPermits, } from '../selectors/burnPermitPermissionSelectors'

// Utilities
import stopEvent from '../utilities/stopEvent'


const DOWNLOAD_BURN_PERMITS = 'DOWNLOAD_BURN_PERMITS'


export class BurnPermitListContainer extends React.Component {

  static propTypes = {
    GetMyBurnPermits           : func,
    DownloadPermits            : func,
    SetPageTitle               : func,
    ToggleMap                  : func,
    isDnr                      : bool,
    isMapShowing               : bool,
    permits                    : array,
    history                    : object,
    order                      : object,
    SetDownloadStatus          : func,
    ResetDownloadStatus        : func,
    CloseModal                 : func,
    CloseDownloadModal         : func,
    DoneDownloadingBurnPermits : func,
    OpenModal                  : func,
    showModal                  : bool,
    openModalKey               : string,
    isDownloading              : bool,
    lastDownload               : string,
    lastDownloadDuration       : string,
    canBeAssigned              : bool,
    DownloadStatuses           : shape({
      BurnPermitsDownloaded   : bool,
      StatusHistoryDownloaded : bool,
      AreaInfoDownloaded      : bool,
      ApplicantInfoDownloaded : bool,
      LocationInfoDownloaded  : bool,
      SiteInfoDownloaded      : bool,
      PileInfoDownloaded      : bool,
      FuelsInfoDownloaded     : bool,
      ConditionsDownloaded    : bool,
      MapDataDownloaded       : bool,
    }),
    isGovAgent            : bool,
    isAgent               : bool,
    isVerifiedAgent       : bool,
    enableCart            : bool,
    online                : bool,
    DownloadMapData       : bool,
    ToggleDownloadMapData : func,
  }

  state = {
    validationError: '',
  }

  componentDidMount () {
    const { SetPageTitle, GetMyBurnPermits, } = this.props

    SetPageTitle('My Burn Permit Applications')
    GetMyBurnPermits()
  }

  componentDidUpdate (prevProps) {
    const { CloseModal, openModalKey, DoneDownloadingBurnPermits, order, enableCart, } = this.props
    if (openModalKey === DOWNLOAD_BURN_PERMITS) {

      const { DownloadStatuses, } = this.props

      if (prevProps.DownloadMapData && !this.props.DownloadMapData && !DownloadStatuses.MapDataDownloaded) {
        this.props.SetDownloadStatus('MapData', true)
      }
  
      const downloadStatusValues = Object.values(DownloadStatuses)

      if (downloadStatusValues.every(s => s)) {
        setTimeout(() => {
          CloseModal()
          DoneDownloadingBurnPermits()
        }, 750)
      }
    }
    if (enableCart && !isEmpty(order) && !isEqual(prevProps.order, order)) {
      this.props.GetMyBurnPermits()
    }
  }

  closeDownloadModal = () => {
    const { openModalKey, CloseDownloadModal, } = this.props
    if (openModalKey === DOWNLOAD_BURN_PERMITS) {
      CloseDownloadModal()
    }
  }

  toggleMapDownload = evt => {
    const { classList, } = evt.target
    if (classList.contains('active')) {
      return
    }
    this.setState({ downloadMapData: !this.state.downloadMapData, })
  }

  downloadPermits = evt => {
    stopEvent(evt)

    const { OpenModal, } = this.props

    OpenModal(DOWNLOAD_BURN_PERMITS)
  }

  confirmDownloadPermits = () => {
    if (this.state.downloadMapData) {
      if (!this.props.isMapShowing) {
        this.props.ToggleMap()
      }
      this.props.ToggleDownloadMapData()
    }
    this.props.DownloadPermits()
  }

  render () {
    const {
      validationError,
    } = this.state

    const {
      permits,
      DownloadStatuses,
      online,
      enableCart,
    } = this.props

    const aboveTableContent = <Row>
      <Col>
        <CardText className={'my-2 px-2'}>
          {
            this.props.canBeAssigned
              ? 'View the Burn Permits and Applications you\'re assigned to here.'
              : (
                online
                  ? <>
                    If you&apos;re ready to pay for your Application(s), click the <Button size={'sm'} className={'fa fa-plus btn-success readonly'} />&nbsp;
                    next to each Application you wish to pay for to add the Application to your Cart.</>
                  : ''
              )
          }
        </CardText>
        {
          this.props.online &&
            <CardText className={'my-2 px-2'}>
              Click <Button color={'light'} size={'sm'} onClick={this.downloadPermits} className={'mx-1'}><span className={'fa fa-download mr-2'}></span>Download</Button> to allow access to your Burn Permit Applications while offline.
            </CardText>
        }
      </Col>
    </Row>

    const belowTableContent = <Row>
      <Col>
        <Link to={'/permits/new'} className={'btn btn-link text-dark'}>New Application</Link>
        {
          validationError && validationError.length > 0 && <span className={'text-danger'}>{validationError}</span>
        }
      </Col>
    </Row>

    return <Container className={'py-2'}>
      <BurnPermitTable
        title={'My Burn Permits'}
        permits={permits}
        aboveTableContent={aboveTableContent}
        belowTableContent={belowTableContent}
        enableCart={enableCart}
        showNewAppLink={true}
        showMap={this.props.isMapShowing}
        showLandownerAgent={this.props.isDnr}
        layerConfig={'My Permits'}
      />
      <ConfirmationModal
        disableSubmit={this.props.isDownloading}
        submitActionLabel={'Download'}
        submitAction={this.confirmDownloadPermits}
        cancelActionLabel={'Close'}
        cancelAction={this.closeDownloadModal}
        modalKey={DOWNLOAD_BURN_PERMITS}
        modalTitle={'Download Burn Permits'}
        modalBody={<>
          { this.props.lastDownload
            ? <>
              <p>You last downloaded { permits.length } Burn Permits on<br/>{ this.props.lastDownload } which took { this.props.lastDownloadDuration }.</p>
              <Alert color={'primary'}>This can take a couple minutes depending on your internet connection.</Alert>
            </>
            : <p>Click Download below to download the your Burn Permits for offline use.</p>
          }
          <FormGroup>
            <Label>Download Map Data?</Label>
            <ButtonGroup className={'d-block'}>
              <ButtonGroupButton text={'Yes'} isActive={this.state.downloadMapData} onClick={this.toggleMapDownload} />
              <ButtonGroupButton text={'No'} isActive={!this.state.downloadMapData} onClick={this.toggleMapDownload} />
            </ButtonGroup>
            {
              this.state.downloadMapData && <>
                <p>If you want access to Imagery offline, make sure to set it as your active basemap before starting the download</p>
                <p>Not all basemaps are available offline or at all zoom levels.</p>
                <Alert color={'warning'} className={'mt-2'}><b>Warning!</b> Downloading map data is best performed on a fast, stable Wi-Fi connection.</Alert>
              </>
            }
          </FormGroup>
          { this.props.isDownloading && <>
            <p>Please wait while we download your { permits.length } Burn Permits.</p>
            <ListGroup>
              { this.state.downloadMapData &&
                  <ListGroupItem>
                    Map Data <LoadingBar className={'download-progress'} show={!DownloadStatuses.MapDataDownloaded} /> { this.props.DownloadStatuses.MapDataDownloaded && <SuccessIcon className={'download-success'} /> }
                  </ListGroupItem>
              }
              <ListGroupItem>
                  Burn Permits <LoadingBar className={'download-progress'} show={!DownloadStatuses.BurnPermitsDownloaded} /> { DownloadStatuses.BurnPermitsDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Status History <LoadingBar className={'download-progress'} show={!DownloadStatuses.StatusHistoryDownloaded} /> { DownloadStatuses.StatusHistoryDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Area Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.AreaInfoDownloaded} /> { DownloadStatuses.AreaInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Applicant Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.ApplicantInfoDownloaded} /> { DownloadStatuses.ApplicantInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Location Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.LocationInfoDownloaded} /> { DownloadStatuses.LocationInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Site Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.SiteInfoDownloaded} /> { DownloadStatuses.SiteInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Pile Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.PileInfoDownloaded} /> { DownloadStatuses.PileInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Fuels Info <LoadingBar className={'download-progress'} show={!DownloadStatuses.FuelsInfoDownloaded} /> { DownloadStatuses.FuelsInfoDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
              <ListGroupItem>
                  Conditions <LoadingBar className={'download-progress'} show={!DownloadStatuses.ConditionsDownloaded} /> { DownloadStatuses.ConditionsDownloaded && <SuccessIcon className={'download-success'} /> }
              </ListGroupItem>
            </ListGroup>
          </>
          }
        </>}
      />
    </Container>
  }
}

function mapStateToProps (state, props) {

  const { isMapShowing, } = state.BurnPermitList

  const { newBurnPermitOrderId, } = state.BurnPermitOrder

  const order = orderByIdSelector(state, newBurnPermitOrderId)

  const permits = permitSearchSelector(state)

  const { showModal, modalKey, } = state.ui.Modal

  const {
    isDownloading,
    lastDownloadFormatted,
    lastDownloadDuration,
    DownloadStatuses,
  } = state.BurnPermitList

  // By default, enable for SAW users that are not agents, or are verified agents, or DNR users
  let enableCart = !(props.isAgent && !props.isVerifiedAgent)
  // However, if the user is an agent for a gov't agency
  if (props.isGovAgent) {
    // Enable the cart if their agency does not pay by voucher
    const paysByVoucher = usersAgencyPaysByVoucher(state)
    enableCart = !paysByVoucher
  }

  const { online, } = state.offline
  return {
    online,
    order,
    permits,
    isMapShowing,
    showModal,
    lastDownloadDuration,
    isDownloading,
    enableCart,
    openModalKey     : modalKey,
    lastDownload     : lastDownloadFormatted,
    DownloadStatuses : { ...DownloadStatuses, },
    DownloadMapData  : state.Map.downloadMapData,
    canBeAssigned    : userCanBeAssignedPermits(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle               : AppActions.setPageTitle,
  ToggleMap                  : BurnPermitListActions.togglePermitMap,
  GetMyBurnPermits           : BurnPermitListActions.getMyBurnPermits,
  DownloadPermits            : BurnPermitListActions.downloadBurnPermits,
  CloseDownloadModal         : BurnPermitListActions.closeDownloadModal,
  DoneDownloadingBurnPermits : BurnPermitListActions.doneDownloadingBurnPermits,
  SetDownloadStatus          : BurnPermitListActions.setDownloadStatus,
  ResetDownloadStatus        : BurnPermitListActions.resetDownloadStatus,
  OpenModal                  : UiActions.openModal,
  CloseModal                 : UiActions.closeModal,
  ToggleDownloadMapData      : MapActions.toggleDownloadMapData,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitListContainer)