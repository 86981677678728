import {
  Apply,
  Approved,
  Denied,
  Issued,
  Pay,
  Review,
  Separator,
  Sign,
} from './'

const PrivateAndAgentAndDNRSteps = ({ isIssued, isDenied, isApproved, }) => {
  const steps = [
    Apply(),
    Separator(),
    Sign(),
    Separator(),
    Pay(),
    Separator(),
    Review(),
  ]
  if (isApproved) {
    steps.push(Separator())
    steps.push(Approved())
  }
  if (isDenied) {
    steps.push(Separator())
    steps.push(Denied())
  }
  if (isApproved || isIssued) {
    steps.push(Separator())
    steps.push(Issued({ isIssued, }))
  }
  return steps
}

export default PrivateAndAgentAndDNRSteps