import {
  createReducer,
  createActions,
} from 'reduxsauce'
  
/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  updateAppVersion : [ 'version', ],
  setPageTitle     : [ 'pageTitle', ],
  showLoading      : null,
  hideLoading      : null,
  showSuccess      : null,
  hideSuccess      : null,
  logLastRoute     : [ 'lastRoute', 'title', ],
  setReturnUrl     : [ 'returnUrl', ],
  setHistoryLength : [ 'newLength', ],
  redirectTo       : [ 'route', ],
})

export const AppTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  version            : '0.0.0',
  pageTitle          : '',
  showLoading        : false,
  showSuccess        : false,
  routeHistory       : [],
  returnUrl          : '',
  routeHistoryLength : 5,
  redirectToRoute    : '',
}
  
/* ------------- Reducers ------------- */

export const updateAppVersion = (state, { version, }) => {
  const newState = { ...state, }
  newState.version = version || 'Burn Portal'
  return newState
}

export const setPageTitle = (state, { pageTitle, }) => {
  const newState = { ...state, }
  newState.pageTitle = pageTitle
  return newState
}

export const setReturnUrl = (state, { returnUrl, }) => {
  const newState = { ...state, }
  newState.returnUrl = returnUrl
  return newState
}

export const showLoading = (state) => {
  const newState = { ...state, }
  newState.showLoading = true
  return newState
}

export const hideLoading = (state) => {
  const newState = { ...state, }
  newState.showLoading = false
  return newState
}

export const showSuccess = (state) => {
  const newState = { ...state, }
  newState.showSuccess = true
  return newState
}

export const hideSuccess = (state) => {
  const newState = { ...state, }
  newState.showSuccess = false
  return newState
}

export const logLastRoute = (state, { lastRoute, title, }) => {
  const newState = { ...state, }
  newState.showLoading = true
  newState.routeHistoryLength = state.routeHistoryLength || INITIAL_STATE.routeHistoryLength
  newState.routeHistory = [ { route: lastRoute, title, }, ...state.routeHistory.slice(0, newState.routeHistoryLength - 1), ]
  return newState
}

export const setHistoryLength = (state, { newLength, }) => {
  if (isNaN(newLength)){
    return state
  }
  const newState = { ...state, }
  newState.routeHistoryLength = newLength
  newState.routeHistory = [ ...state.routeHistory.slice(-1 * newLength), ]
  return newState
}

// This is used by sagas to redirect to a url
export const redirectTo = (state, { route, }) => {
  const newState = { ...state, }
  newState.redirectToRoute = route || ''
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_APP_VERSION] : updateAppVersion,
  [Types.SET_PAGE_TITLE]     : setPageTitle,
  [Types.SHOW_LOADING]       : showLoading,
  [Types.HIDE_LOADING]       : hideLoading,
  [Types.SHOW_SUCCESS]       : showSuccess,
  [Types.HIDE_SUCCESS]       : hideSuccess,
  [Types.LOG_LAST_ROUTE]     : logLastRoute,
  [Types.SET_RETURN_URL]     : setReturnUrl,
  [Types.SET_HISTORY_LENGTH] : setHistoryLength,
  [Types.REDIRECT_TO]        : redirectTo,
})