import React from 'react'
import { string, } from 'prop-types'

const MailTo = ({ email, subject, }) => {
  let mailto = `mailto:${email}`
  if (subject) {
    mailto += `?subject:${subject}`
  }
  return <a href={mailto}>{email}</a>
}

MailTo.propTypes = {
  email   : string,
  subject : string,
}

MailTo.defaultProps = {
  email   : '',
  subject : '',
}

export default MailTo