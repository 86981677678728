import React from 'react'
import PopoverButton from './FormControls/PopoverButton'
import { bool, } from 'prop-types'


export const NetworkStatus = props => {
  const { online, } = props
  
  const networkIcon = `fa-wifi text-white bg-${online ? 'success' : 'danger'}`

  return <PopoverButton
    buttonClassName={'network-status'}
    iconClassName={networkIcon}
    popoverHeader={'Network Status'}
    popoverBody={<>
      <p>Current status: <b>{online ? 'Online' : 'Offline'}</b></p>
      <p>
        This application is capable of offline functionality.
        However, when using this application offline, you will have limited functionality as 
        you will not have access to the internet.
        You <b>must</b> be logged in on your device before going offline.
      </p>
      <p>What you <b>can</b> do offline:</p>
      <ul>
        <li>Create a new Burn Permit Application</li>
        <li>Create a new Burn Request</li>
        <li>Enter new Post Burn data</li>
        <li>Search and view your <b>saved</b> Burn Permits and Burn Requests</li>
      </ul>
      <p>What you <b>cannot</b> do offline:</p>
      <ul>
        <li>Log in</li>
        <li>Edit your user profile</li>
        <li>Create and pay for Permit Orders</li>
        <li>Sign Applications and Permits</li>
      </ul>
    </>}
    placement={'bottom'}
  />
}

NetworkStatus.propTypes = {
  online: bool,
}

NetworkStatus.defaultProps = {
  online: true,
}

export default NetworkStatus