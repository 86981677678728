// Libraries
import React from 'react'
import { Button, } from 'reactstrap'
import { useDispatch, } from 'react-redux'
import { func, array, } from 'prop-types'

// Components
import ConfirmationModal from '../ConfirmationModal'

// Redux
import UiActions from '../../redux/UiRedux'


const modalKey = 'EXTEND_PERMIT'

const ExtendPermit = props => {
  const { submitAction, submitArgs, } = props
  const dispatch = useDispatch()
  const openModal = React.useCallback(() => dispatch(UiActions.openModal(modalKey)), [ dispatch, ])
  return <>
    <Button
      color={'secondary'}
      onClick={openModal}
      data-modalkey={modalKey}
    >
      Extend Permit
    </Button>
    <ConfirmationModal
      modalKey={modalKey}
      modalTitle={'Extend Burn Permit'}
      modalBody={<>
        <p>Are you sure you wish to extend this Burn Permit?</p>
        <p>You and the applicant will have to sign the Burn Permit in DocuSign.</p>
      </>}
      submitActionLabel={'Yes, I am sure'}
      submitAction={submitAction}
      submitArgs={submitArgs}
    />
  </>
}

ExtendPermit.propTypes = {
  submitArgs   : array,
  submitAction : func,
}

export default ExtendPermit