// Libraries
import React from 'react'
import { bool, } from 'prop-types'
import { Field, } from 'formik'
import {
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'

// Components
import { ValidatingField, } from '../FormControls'


const CommentRow = props => {
  const { online, } = props
  return  <Row>
    <Col>
      <FormGroup>
        <Label for={'Comment'}>Comment</Label>
        <Field
          name={'Comment'}
          type={'textarea'}
          component={ValidatingField}
          className={'form-control'}
          readOnly={!online}
        />
      </FormGroup>
    </Col>
  </Row>
}

CommentRow.propTypes = {
  online: bool,
}

CommentRow.defaultProps = {
  online: true,
}

export default CommentRow