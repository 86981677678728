// Libraries
import React from 'react'
import { useDispatch, } from 'react-redux'
import { func, array, string, } from 'prop-types'
import { Button, ButtonGroup, } from 'reactstrap'

// Redux
import BurnPermitInstructionsActions from '../../redux/BurnPermitInstructionsRedux'

const StepsNav = (props) => {
  const dispatch = useDispatch()
  let dot
  const dots = []
  const { currentStep, steps, completedSteps, } = props
  for (let i = 0, len = steps.length; i < len; i++) {
    const { icon, label, readOnly , } = steps[i]
    if (readOnly === true) {
      dot = <span className={`${icon} nav`} key={`step-${i}`}/>
    }
    else {
      const isActive = currentStep === label
      const isCompleted = completedSteps.includes(label)
      const buttonProps = {
        key       : `step-${i}`,
        color     : 'nav',
        className : `p-2 ${isActive ? ' active': ''}`,
        onClick   : () => dispatch(BurnPermitInstructionsActions.goToInstructionStep(label)),
      }
      if (!isActive && isCompleted) {
        buttonProps.style = { color: 'green', }
      }
      dot = <Button {...buttonProps}>
        <span className={isCompleted ? 'fa fa-check-circle' : icon}/>
        { label && <p className={`pt-2 m-0 ${isActive ? 'active': ''}`}>{label}</p> }
      </Button>
    }
    dots.push(dot)
  }
    
  return <ButtonGroup className={'application-nav'}>{dots}</ButtonGroup>
}

  
StepsNav.propTypes = {
  steps          : array.isRequired,
  currentStep    : string.isRequired,
  goToStep       : func.isRequired,
  completedSteps : array,
}

StepsNav.defaultProps = {
  steps       : [],
  currentStep : 'Apply',
  goToStep    : () => {},
}

export default StepsNav