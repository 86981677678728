import { attr, Model, } from 'redux-orm'

export default class CustomerInteractionStatus extends Model {
  static get modelName () {
    return 'CustomerInteractionStatus'
  }

  toString () {
    return `${this.CustomerInteractionStatusId}`
  }

  static endpoint () {
    return 'CustomerInteractionStatuses'
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionStatusId',
    }
  }

  static get fields () {
    return {
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),

      // Model-Specific Properties
      CustomerInteractionStatusId          : attr(),
      CustomerInteractionStatusName        : attr(),
      CustomerInteractionStatusDescription : attr(),
    }
  }
}
