// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { object, number, func, bool, PropTypes, string, array, } from 'prop-types'
import { Container, Col, Card, CardBody, CardHeader, Row, Label, Collapse, } from 'reactstrap'

// Components
import { PostBurnForm, } from '../components/Forms'
import BurnPermitSummaryCard from '../components/BurnPermitSummary'
import BurnRequestSummaryCard from '../components/BurnRequestSummary'
import PermitNumberSearch from '../components/PermitNumberSearch'
import { Select, } from '../components/FormControls'
import withRouter from '../components/withRouter'

// Selectors
import { permitDaysSelector, burnPermitNumberById, burnTypesByBurnPermitId, } from '../selectors/burnPermitSelectors'
import {
  burnedAcresByPermitSelector,
  burnedTonsByPermitSelector,
  daysOfWeekCheckboxSelector,
} from '../selectors/selectors'
import { 
  postBurnByRequestIdSelector, 
  postBurnLoadingSelector,
  postBurnDDLSelector,
  activePostBurnRequestSelector,
  havePermitDataForPostBurn,
  haveRequestDataForPostBurn,
  activePostBurnIds,
  activePostBurnIsLocal,
  submittingPostburnsSelector,
} from '../selectors/postBurnSelectors'

// Redux
import AppActions from '../redux/AppRedux'
import PostBurnActions from '../redux/PostBurnRedux'

// Utilities
import { dateFormatter, } from '../utilities'


const paramsShape = PropTypes.shape({
  BurnPermitId  : PropTypes.oneOfType([ number, string, ]),
  BurnRequestId : PropTypes.oneOfType([ number, string, ]),
  PostBurnId    : PropTypes.oneOfType([ number, string, ]),
})

const noopAction = () => {
  console.error('An action was not executed')
}

export class PostBurnContainer extends React.Component {
  static propTypes = {
    // from withRouter HOC
    navigate : func,
    location : object,
    params   : paramsShape,
    
    // from selectors
    BurnPermitId           : PropTypes.oneOfType([ number, string, ]),
    BurnPermitNumber       : string,
    BurnRequest            : object,
    BurnRequestId          : PropTypes.oneOfType([ number, string, ]),
    BurnedAcres            : number,
    BurnedTons             : number,
    DaysOfWeek             : PropTypes.arrayOf(object),
    IsLoading              : bool,
    PermitDays             : PropTypes.arrayOf(number),
    BurnRequestsForSelect  : PropTypes.arrayOf(object),
    PostBurn               : object,
    PostBurnIsSubmitting   : bool,
    BurnTypes              : array,
    HaveRequestData        : bool,
    HavePermitData         : bool,
    PostBurnId             : number,
    PostBurnUsesRequest    : bool,
    PostBurnIsLocal        : bool,
    PermitFailedToLoad     : bool,
    online                 : bool,
    // actions
    CancelPostBurnSubmit   : func,
    ClearPostBurnPrompts   : func,
    CreatePostBurn         : func,
    SetPageTitle           : func,
    SetPostBurnPermit      : func,
    UpdatePostBurn         : func,
    InitializeForm         : func,
    ClearPermitLoadFail    : func,
    SetPostBurnUsesRequest : func,
  }

  static defaultProps = {
    location              : {},
    params                : {},
    BurnPermitId          : -1,
    BurnRequest           : null,
    BurnRequestId         : -1,
    BurnedAcres           : 0,
    BurnedTons            : 0,
    DaysOfWeek            : [],
    IsLoading             : false,
    PermitDays            : [],
    BurnRequestsForSelect : [],
    PostBurn              : null,
    BurnTypes             : [],
    ClearPostBurnPrompts  : noopAction,
    CreatePostBurn        : noopAction,
    SetPageTitle          : noopAction,
    SetPostBurnPermit     : noopAction,
    UpdatePostBurn        : noopAction,
    HavePermitData        : false,
    HaveRequestData       : false,
  }

  state = {
    permitIsOpen        : false,
    requestIsOpen       : false,
    requestSelectIsOpen : true,
  }

  updateRouteParams = () => {
    const { BurnPermitId, BurnRequestId, PostBurnId, PostBurn, PostBurnUsesRequest, } = this.props
    const { pathname, } = this.props.location
    let route = '', newTitle = ''

    if (PostBurnId > 0) {
      if (PostBurn && PostBurn.IsLocal === true) {
        if (BurnRequestId > 0) {
          newTitle = `Burn Request #${BurnRequestId} - Post Burn`
          route = `/burn-requests/${BurnRequestId}/postburn`
        } else if (!PostBurnUsesRequest && BurnPermitId > 0) {
          newTitle = `Permit #${BurnPermitId} - Post Burn`
          route = `/permits/${BurnPermitId}/postburn`
        } else {
          newTitle = `Post Burn #${PostBurnId}`
          route = `/post-burns/${PostBurnId}`
        }
      } else {
        newTitle = `Post Burn #${PostBurnId}`
        route = `/post-burns/${PostBurnId}`
      }
    }
    else if (BurnRequestId > 0) {
      newTitle = `Burn Request #${BurnRequestId} - Post Burn`
      route = `/burn-requests/${BurnRequestId}/postburn`
    } else if (BurnPermitId > 0) {
      newTitle = `Permit #${BurnPermitId} - Post Burn`
      route = `/permits/${BurnPermitId}/postburn`
    } else {
      newTitle = 'New Post Burn'
      route = '/post-burns/new'
    }
    if (pathname !== route) {
      this.props.SetPageTitle(newTitle)
      this.props.navigate(route)
    }
  }

  parseRouteParams = () => {
    const { InitializeForm, } = this.props
    const { BurnPermitId, BurnRequestId, PostBurnId, } = this.props.params
    
    let pmtId = (BurnPermitId && BurnPermitId > 0) ? BurnPermitId : -1
    let reqId = (BurnRequestId && BurnRequestId > 0) ? BurnRequestId : -1
    let pbId = (PostBurnId && PostBurnId > 0) ? PostBurnId : -1

    InitializeForm(pmtId, reqId, pbId)
  }

  componentDidMount () {
    this.props.ClearPostBurnPrompts()
    this.parseRouteParams()
  }

  componentDidUpdate (prevProps) {
    const { BurnRequestId, PostBurnUsesRequest, PostBurn, BurnRequest, } = this.props

    let changeRoute = false
    const { key, pathname, } = this.props.location

    if (prevProps.location.key !== key && pathname.indexOf('new') > 0) {
      this.props.InitializeForm(-1, -1, -1)
    }

    if (prevProps.BurnPermitId !== this.props.BurnPermitId && this.props.BurnPermitId > 0) {
      this.setState({ permitIsOpen: true, })
      changeRoute = true
    }
    if (!prevProps.BurnRequest && BurnRequest) {
      changeRoute = true
    }
    if (!prevProps.PostBurn && PostBurn) {
      if (!this.props.PostBurnIsLocal && !PostBurn.IsLocal) {
        changeRoute = true
      }
    }
    if (PostBurnUsesRequest) {
      if (prevProps.BurnRequestId !== BurnRequestId && Number.isInteger(BurnRequestId) && BurnRequestId > 0) {
        changeRoute = true
      }
    }
    if (changeRoute) {
      this.updateRouteParams()
    }
  } 

  componentWillUnmount () {
    this.props.InitializeForm(-1, -1, -1)
    this.props.ClearPostBurnPrompts()
  }

  onPermitSearchChanged = permitNumber => {
    if (this.props.PermitFailedToLoad){
      this.props.ClearPermitLoadFail()
    }
    this.setState({ BurnPermitNumber: permitNumber, BurnPermitNumberError: '', })
  }

  doPermitSearch = permitNumber => {
    if (permitNumber) {
      this.props.SetPostBurnPermit(permitNumber)
      return
    }
    this.setState({ BurnPermitNumberError: 'You must provide a valid Burn Permit Number.', })
  }

  submitPostBurn = (values) => {
    const {
      PostBurn,
      UpdatePostBurn,
      CreatePostBurn,
      BurnPermitId,
    } = this.props

    // clean up the post burn object
    let BurnTime = values.BurnTime.toString()
    if (BurnTime) {
      if (BurnTime.length < 4) {
        BurnTime = `0000${BurnTime}`.substring(-4)
        BurnTime = BurnTime.substring(BurnTime.length - 4)
      }
      const hours = BurnTime.slice(0, -2)
      const mins = BurnTime.slice(-2)
      values['BurnTime'] = `${hours}:${mins}:00`
    }

    if ((!values.BurnPermitId || values.BurnPermitId < 0) && BurnPermitId) {
      values['BurnPermitId'] = BurnPermitId
    }


    if (PostBurn && !PostBurn.IsLocal && PostBurn.PostBurnId) {
      UpdatePostBurn(PostBurn.PostBurnId, values)
    }
    else if (BurnPermitId) {
      CreatePostBurn(values)
    }
  }

  selectedRequestChanged = (e) => {
    const { value, } = e.target
    const { 
      SetPostBurnUsesRequest, 
      BurnPermitId, 
      InitializeForm,
    } = this.props
    
    if (value === 'norequest'){
      SetPostBurnUsesRequest(false)
      this.updateRouteParams()
      return
    }

    if (isNaN(value)) {
      return
    }

    // re-initialize the form with the burn request id and no initial post burn
    InitializeForm(BurnPermitId, value, -1)
    this.setState({ requestIsOpen: true, })
  }

  renderRequestSelector = () => {
    const { BurnPermitId, BurnRequestsForSelect, PostBurnUsesRequest, } = this.props
    if (this.props.IsLoading) {
      return <span>Loading Burn Requests...</span>
    }
    if (!BurnRequestsForSelect || BurnRequestsForSelect.length === 0) {
      if (!BurnPermitId) {
        return <span>Loading...</span>
      }
      return <span>No Burn Requests submitted for Burn Permit {BurnPermitId}</span>
    }
    const selectedRequest = PostBurnUsesRequest ? this.props.BurnRequestId : 'norequest'
    return <>
      <Label for={'burn-requests'} style={{ display: 'hidden', }}>Burn Requests</Label>
      <Select
        className={'m-0'}
        id={'burn-requests'}
        onChange={this.selectedRequestChanged}
        items={BurnRequestsForSelect}
        selectedValue={selectedRequest}
        defaultText={'Burn Request'}
      />
    </>
  }

  togglePermitCard = () => this.setState({ permitIsOpen: !this.state.permitIsOpen, })

  toggleRequestCard = () => this.setState({ requestIsOpen: !this.state.requestIsOpen, })

  toggleRequestSelectCard = () => this.setState({ requestSelectIsOpen: !this.state.requestSelectIsOpen, })

  renderPostBurn = () => {
    const {
      BurnRequest,
      PostBurn,
      BurnPermitId,
      CancelPostBurnSubmit,
      PostBurnIsSubmitting,
      BurnTypes,
    } = this.props
    
    if (!PostBurn) {
      return <p>Please wait while we gather the Burn Permit, Burn Request and Post Burn data.</p>
    }
    if (PostBurn.PlannedIgnitionDate && dateFormatter(PostBurn.PlannedIgnitionDate).isAfter(Date.now())) {
      return <p>You cannot submit Post Burn information for a future Burn Request.</p>
    }
    return <PostBurnForm
      burnRequest={BurnRequest}
      postBurn={PostBurn}
      burnPermitId={BurnPermitId}
      onSaveClick={this.submitPostBurn}
      CancelPostBurnSubmit={CancelPostBurnSubmit}
      PostBurnIsSubmitting={PostBurnIsSubmitting}
      burnTypes={BurnTypes}
    />
  }

  render () {
    const { permitIsOpen, requestIsOpen, } = this.state
    const { BurnPermitId, BurnRequestId, } = this.props
    const {
      BurnPermitNumber,
      BurnedTons,
      BurnedAcres,
      DaysOfWeek,
      PermitDays,
      BurnRequest,
      PostBurn,
      PostBurnUsesRequest,
    } = this.props
    const permitUrl = `/permits/${BurnPermitId}`
    let children, header, showPostBurnForm = false
    if (PostBurn) {
      if ((BurnRequestId > 0 && PostBurnUsesRequest) || !PostBurnUsesRequest){
        showPostBurnForm = true
      }
    }
    if (BurnPermitId < 0) {
      header = <h1 style={{ fontSize: '2rem', }}>Enter Post Burn Report</h1>
      children = 
      <PermitNumberSearch
        doSearch={this.doPermitSearch}
        onChange={this.onPermitSearchChanged}
        error={this.state.BurnPermitNumberError}
        searchHeaderText={'Please search for a permit and then provide the required information about your burn.'}
      />
    } else {
      let burnRequestCard = null
      let collapse
      if (typeof onClick === 'function') {
        collapse = <span
          onClick={this.toggleRequestSelectCard}
          onKeyPress={this.toggleRequestSelectCard}
          role={'button'}
          tabIndex={0}
          className={`fa fa-chevron-${this.state.requestSelectIsOpen ? 'down' : 'up'} cursor-pointer float-right`}
          style={{ transform: 'translateY(50%)', }}
        ></span>
      }
      let burnRequestSelector = <Card className={'mb-3'}>
        <CardHeader tag={'h3'}>Select a Burn Request{collapse}</CardHeader>
        <Collapse isOpen={this.state.requestSelectIsOpen}>
          <CardBody>
            <Row>
              <Col>
                  Select a Burn Request. If a Burn Request has not been submitted for the Burn, select No Request.
              </Col>
            </Row>
            <Row>
              <Col>{ this.renderRequestSelector() }</Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
      if (BurnRequest || !PostBurnUsesRequest) {
        burnRequestCard = <BurnRequestSummaryCard
          burnRequest={BurnRequest}
          burnedTons={BurnedTons}
          burnedAcres={BurnedAcres}
          daysOfWeek={DaysOfWeek}
          permitDays={PermitDays}
          isOpen={requestIsOpen}
          onClick={this.toggleRequestCard}
        />
      } else if (BurnRequestId > 0) {
        burnRequestCard = <Card><CardBody>Loading Burn Request...</CardBody></Card>
      }

      header = <h1 style={{ fontSize: '2rem', }}><Link to={permitUrl}>Burn Permit {BurnPermitNumber || BurnPermitId}</Link> - Post Burn</h1>
      children = <>
        <Row className={'mt-3'}>
          <Col xs={12} lg={6} className={'h-100'}>
            {
            // (BurnPermitId && HavePermitData) &&
              BurnPermitId &&
              <BurnPermitSummaryCard
                BurnPermitId={BurnPermitId}
                burnedTons={BurnedTons}
                burnedAcres={BurnedAcres}
                daysOfWeek={DaysOfWeek}
                permitDays={PermitDays}
                isOpen={permitIsOpen}
                onClick={this.togglePermitCard}
                showConditionLink={false}
              />
            }
          </Col>
          <Col xs={12} lg={6} className={'h-100'}>
            <Row>
              <Col xs={12}>
                {burnRequestSelector}
              </Col>
              <Col xs={12}>
                {burnRequestCard}
              </Col>
            </Row>
          </Col>
        </Row>
        {
          (showPostBurnForm) &&
          <Row className={'my-3'}>
            <Col>
              <Card>
                <CardHeader tag={'h2'}>
                  {PostBurn && ((PostBurn.ConsumedTons === 0 && PostBurn.BurnDate) || PostBurn.DidNotBurn) ? 'Did Not Burn Reason' : 'Post-Burn Information'}
                </CardHeader>
                <CardBody>
                  {PostBurn && ((PostBurn.ConsumedTons === 0 && PostBurn.BurnDate) || PostBurn.DidNotBurn) ? (PostBurn.DidNotBurnReason === '') ? 'No Reason was Listed' : PostBurn.DidNotBurnReason : this.renderPostBurn() }
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </>
    }
    return <Container className={'py-2'}>
      {header}
      {children}
    </Container>
  }
}

function mapStateToProps (state) {
  const { BurnPermitId, BurnRequestId, PostBurnId, } = activePostBurnIds(state)
  const PostBurn = postBurnByRequestIdSelector(state)
  const submittingPostBurns = submittingPostburnsSelector(state)
  const PostBurnIsSubmitting = submittingPostBurns.includes(PostBurnId)

  return {
    PostBurnId,
    BurnPermitId,
    BurnRequestId,
    PostBurn,
    PostBurnIsLocal       : activePostBurnIsLocal(state),
    BurnPermitNumber      : burnPermitNumberById(state, BurnPermitId),
    PostBurnIsSubmitting,
    PostBurnUsesRequest   : state.PostBurn.PostBurnUsesRequest,
    BurnTypes             : burnTypesByBurnPermitId(state, BurnPermitId),
    BurnRequest           : activePostBurnRequestSelector(state),
    BurnedAcres           : burnedAcresByPermitSelector(state, BurnPermitId),
    BurnedTons            : burnedTonsByPermitSelector(state, BurnPermitId),
    DaysOfWeek            : daysOfWeekCheckboxSelector(state),
    IsLoading             : postBurnLoadingSelector(state),
    PermitDays            : permitDaysSelector(state, BurnPermitId),
    BurnRequestsForSelect : postBurnDDLSelector(state),
    HavePermitData        : havePermitDataForPostBurn(state),
    HaveRequestData       : haveRequestDataForPostBurn(state),
    online                : state.offline.online,
  }
}

const mapDispatchToProps = {
  SetPageTitle           : AppActions.setPageTitle,
  ClearPostBurnPrompts   : PostBurnActions.clearPostBurnPrompt,
  // api calls
  CancelPostBurnSubmit   : PostBurnActions.cancelPostBurnSubmit,
  CreatePostBurn         : PostBurnActions.createPostBurn,
  UpdatePostBurn         : PostBurnActions.updatePostBurn,
  // form meta actions
  SetPostBurnPermit      : PostBurnActions.setPostBurnPermitByNumber,
  SetPostBurnUsesRequest : PostBurnActions.postBurnUseRequest,
  InitializeForm         : PostBurnActions.initializePostBurnForm,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PostBurnContainer))
