import React from 'react'
import { connect, } from 'react-redux'
import { Row, Col, Button, Card, CardHeader, CardBody, } from 'reactstrap'
import { string, func, bool, } from 'prop-types'

import FileActions from '../../redux/FileRedux'
import Dropzone from './Dropzone'
import stopEvent from '../../utilities/stopEvent'

class Upload extends React.Component {

  static propTypes = {
    online              : bool,
    title               : string,
    uploadFiles         : func,
    successfulUpload    : bool,
    uploading           : bool,
    validFileExtensions : string,
    multiple            : bool,
    ResetFileUploading  : func,
    onFileSelected      : func,
    readOnly            : bool,
  }

  state = {
    files: [],
  }

  componentWillUnmount () {
    this.props.ResetFileUploading()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.successfulUpload && !this.props.successfulUpload) {
      this.setState({ files: [], })
    }
  }

  onFilesAdded = (files) => {
    if (this.props.multiple) {
      files = [ ...this.state.files, ...files, ]
    }
    if (typeof this.props.onFileSelected === 'function') {
      this.props.onFileSelected(files)
    }
    this.setState({ files, })
  }

  clearFiles = (evt) => {
    evt.preventDefault()

    this.setState({ files: [], })
  }

  uploadFiles = evt => {
    stopEvent(evt)

    if (this.props.online && this.state.files.length) {
      this.props.uploadFiles(this.state.files)
    }
  }

  render () {
    const { title, uploading, validFileExtensions, multiple, online, readOnly, } = this.props
    const { files, } = this.state
    return (
      <Card>
        <CardHeader tag={'h4'}>{title || `Upload File${multiple ? 's' : ''}`}</CardHeader>
        <CardBody>
          <Row>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={uploading || !online || readOnly}
              validFileExtensions={validFileExtensions}
              multiple={multiple}
            />
          </Row>
          {
            files.length
              ? <>
                <h5 className={'mt-4'}>{`File${multiple ? 's' : ''}`}</h5>
                {
                  files.map(file => {
                    return (
                      <Row key={file.name} className={'mt-2'}>
                        <Col>
                          <span className={'Filename'}>{file.name} - {((file.size / 1024) / 1024).toFixed(2)}MB</span>
                        </Col>
                      </Row>
                    )
                  })
                }
                <Row className={'mt-4'}>
                  <Col>
                    <Button color={'link'} onClick={this.clearFiles}>Cancel</Button>
                  </Col>
                  <Col>
                    <Button onClick={this.uploadFiles} className={'float-right'}>Upload</Button>
                  </Col>
                </Row>
              </>
              : null
          }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { online, } = state.offline
  return {
    online,
    ...state.Files,
  }
}

const mapDispatchToProps = {
  ResetFileUploading: FileActions.resetFileUploading,
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)