import React from 'react'
import ReactDomServer from 'react-dom/server'
import { dateFormatter, } from '../utilities'

const ICON = (className, asHtml) => {
  const html = <span className={`fa d-block ${className}`} style={{ margin: 'auto', width: '1em', }} />
  if (asHtml === true) {
    return html
  }
  return ReactDomServer.renderToString(html)
}

const SUCCESS_ICON = ICON('fa-check-circle text-success')
const DANGER_ICON = ICON('fa-times-circle text-danger')

export const renderCheckIcon = (data, drawType, asHtml) => {
  if (drawType !== 'display') {
    return `${data}`
  }
  if (asHtml === true) {
    if (data) {
      return ICON('fa-check-circle text-success', asHtml)
    }
  }
  return data
    ? SUCCESS_ICON
    : ''
}

export const renderCheckOrXIcon = (data, drawType, asHtml) => {
  if (drawType !== 'display') {
    return `${data}`
  }
  if (asHtml === true) {
    if (data) {
      return ICON('fa-check-circle text-success', asHtml)
    }
    return ICON('fa-times-circle text-danger', asHtml)
  }
  return data
    ? SUCCESS_ICON
    : DANGER_ICON
}

export const renderDate = (data, drawType) => {
  if (drawType !== 'display') {
    return data
  }
  return dateFormatter(data, 'MMM DD, YYYY')
}

export const renderDateTime = (data, drawType) => {
  if (drawType !== 'display') {
    return data
  }
  const dateTime = dateFormatter(data, 'MMM DD, YYYY HH:mm:ss')
  if (dateTime && dateTime.substring(dateTime.length - 8) === '00:00:00') {
    return dateTime.substring(0, dateTime.length - 9)
  }
  return dateTime
}

export const formatLegalDesc = row => {
  let legalDesc = ''
  if (row.LegalDescQ1) {
    legalDesc += row.LegalDescQ1 + ' of '
  }
  if (row.LegalDescQ2) {
    legalDesc += row.LegalDescQ2 + ' of '
  }
  if (row.LegalDescSection) {
    legalDesc += ' S' + row.LegalDescSection
  }
  if (row.LegalDescTownship) {
    legalDesc += ' T' + row.LegalDescTownship
  }
  if (row.LegalDescRange) {
    legalDesc += ' R' + row.LegalDescRange
  }
  if (row.LegalDescDirection) {
    legalDesc += ' ' + row.LegalDescDirection
  }
  return legalDesc
}

export const renderTons = (data, drawType) => {
  if (drawType !== 'display') {
    return data
  }
  if (!data) {
    return '' 
  }
  return `${data.toLocaleString()} tons`
}

export const renderAcres = (data, drawType) => {
  if (drawType !== 'display') {
    return data
  }
  if (!data) {
    return '' 
  }
  return `${data.toLocaleString()} acres`
}

export const renderLatLong = (data, drawType, row) => {
  if (drawType !== 'display') {
    return ''
  }
  
  if (!row || !row.Latitude || !row.Longitude) {
    return ''
  }
  
  return `${row.Latitude}, ${row.Longitude}`
}

export const formatAddress = address => {
  if (!address) {
    return address
  }
  const streetLines = []
  const cityStateZip = []
  if (address.StreetLine1) {
    streetLines.push(address.StreetLine1)
  }
  if (address.StreetLine2) {
    streetLines.push(address.StreetLine2)
  }
  if (address.StreetLine3) {
    streetLines.push(address.StreetLine3)
  }
  if (address.AddressCity) {
    cityStateZip.push(address.AddressCity + ',')
  }
  if (address.AddressState) {
    cityStateZip.push(address.AddressState)
  }
  if (address.AddressZipCode) {
    cityStateZip.push(address.AddressZipCode)
  }
  let addrStr = ''
  if (streetLines.length) {
    addrStr = streetLines.join('\n')
  }
  if (cityStateZip.length) {
    addrStr += '\n' + cityStateZip.join(' ')
  }
  return addrStr
}
