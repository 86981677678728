import React from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { Col, Row, } from 'reactstrap'
import { Link, } from 'react-router-dom'
import { number, } from 'prop-types'

// Actions
import BurnPermitSignatureActions from '../../redux/BurnPermitSignatureRedux'

// Selectors
import { permitSignatureState, } from '../../selectors/burnPermitSignatureSelectors'

// Components
import ConfirmationModal from '../ConfirmationModal'

// Utilities
import stopEvent from '../../utilities/stopEvent'


const ForcePermitApplicationSignature = (props) => {
  
  const { BurnPermitId, } = props

  // Selectors
  const {
    abandonedPermitApplicationId,
  } = useSelector(permitSignatureState)

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks
  const forceSignApp = React.useCallback(e => {
    stopEvent(e)
    dispatch(BurnPermitSignatureActions.signPermitApplication(BurnPermitId, true))
  }, [ dispatch, BurnPermitId, ])

  const markup = <ConfirmationModal
    modalKey={'FORCE_PERMIT_APPLICATION_SIGNATURE'}
    modalTitle={'Permit Application Signature'}
    modalBody={<>
      <p>
        The system determined you initiated a signing ceremony for <b>Permit Application #{abandonedPermitApplicationId}</b>,
        but never finished.
      </p>
      <Row>
        <Col>
          <p>
            Click the <b>Cancel</b> button below to cancel signing Permit Application #{BurnPermitId} and finish signing&nbsp;
            <Link to={`/permits/${abandonedPermitApplicationId}`}>
              Permit Application #{abandonedPermitApplicationId}
            </Link>
          </p>
        </Col>
        <Col>
          <p>
            Click the <b>Confirm</b> button below to sign Permit Application #{BurnPermitId} and cancel the signing of&nbsp;
            <Link to={`/permits/${abandonedPermitApplicationId}`}>
              Permit Application #{abandonedPermitApplicationId}
            </Link>
          </p>
        </Col>
      </Row>
      
    </>}
    submitAction={forceSignApp}
    submitActionLabel={'Confirm'}
  />
  return markup
}

ForcePermitApplicationSignature.defaultProps = {
  BurnPermitId: '',
}

ForcePermitApplicationSignature.propTypes = {
  BurnPermitId: number,
}

export default React.memo(ForcePermitApplicationSignature)