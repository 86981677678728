import { FIRE_DISTRICTS, TRS, UGA, } from '../map/featureLayers'
import ALL_LAYERS from '../map/geojsonLayers'

const CalendarLayerConfig = {
  name         : 'Calendar',
  hiddenLayers : [
    TRS.id,
    FIRE_DISTRICTS.id,
    UGA.id,
  ],
  unavailableLayers: ALL_LAYERS.map(l => l().id),
}

export default CalendarLayerConfig