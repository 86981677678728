// Libraries
import { put, select, call, fork, takeLatest, } from 'redux-saga/effects'

// Sagas
import { hideLoading, } from './AppSagas'
import { getLookupData, doFetch, showError, extractPayload, } from './ApiSagas'
import { updateLocalModel, upsertLocalModel, upsertLocalModels, serverHasNewerData, } from './OrmSagas'
import { updateTonnageAndFee, } from './BurnPermitSagas'

// Reducers
import { AppTypes, } from '../redux/AppRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import BurnPermitAreaActions, { BurnPermitAreaTypes, } from '../redux/BurnPermitAreaRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'

// Selectors
import {
  getBurnPermit as getBurnPermitSelector,
  getBurnPermitId,
  networkStateSelector,
} from '../selectors/selectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'

// Models
import BurnPermitArea from '../models/BurnPermitArea'
import BurnPermitSearch from '../models/BurnPermitSearch'

// Constants
const BURN_PERMIT_AREA_ENDPOINT = BurnPermitArea.endpoint()
const PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName
const PERMIT_AREA_MODEL_NAME = BurnPermitArea.modelName


export function* getBurnAreaInfo ({ burnPermitAreaId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (isNaN(burnPermitAreaId)) {
      yield call(showError, 'You must supply a valid Burn Permit Area ID.')
      return
    }

    yield fork(getLookupData, { modelName: 'BurnType', })

    const burnPermitAreaInfoUrl = `${BURN_PERMIT_AREA_ENDPOINT}/${burnPermitAreaId}`

    const burnPermAreaResp = yield call(doFetch, burnPermitAreaInfoUrl)

    if (burnPermAreaResp.ok !== true) {
      yield call(showError, `An error occurred requesting Burn Permit Area info for ID ${burnPermitAreaId}`)
      return
    }
    const hasNewData = yield call(serverHasNewerData, PERMIT_AREA_MODEL_NAME, { BurnPermitAreaId: burnPermitAreaId, }, burnPermAreaResp.responseBody)
    if (hasNewData) {
      yield call(upsertLocalModel, PERMIT_AREA_MODEL_NAME, burnPermAreaResp.responseBody)
    }
  }
  catch (error) {
    yield call(showError, error.message)
  }
}


export function* downloadBurnPermitAreaInfo () {
  try {
    const downloadResp = yield call(doFetch, `${BURN_PERMIT_AREA_ENDPOINT}/Download`)

    if (downloadResp.ok !== true) {
      yield call(showError, 'An error occurred downloading Burn Permit Area info.')
      return
    }

    yield call(upsertLocalModels, PERMIT_AREA_MODEL_NAME, downloadResp.responseBody)

    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'AreaInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error.message)
  }
}

function* updateBurnPermitArea ({ burnPermitAreaInfo, }) {
  
  const { online, } = yield select(networkStateSelector)

  const burnPermit = yield select(getBurnPermitSelector, { BurnPermitAreaId: burnPermitAreaInfo.BurnPermitAreaId, })
  
  // check if model is related to a burn permit that is local
  // if it is not, check for and cancel any queued requests
  if (!burnPermit.IsLocal) {
    // if we're offline, cancel any queued req, then submit a new one
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitAreaTypes.UPDATE_BURN_PERMIT_AREA_REQUEST,
        url         : `${BURN_PERMIT_AREA_ENDPOINT}/${burnPermitAreaInfo.BurnPermitAreaId}`,
        method      : 'PUT',
        keyName     : BurnPermitArea.options.idAttribute,
        keyValue    : burnPermitAreaInfo.BurnPermitAreaId,
      })
    }
    const calcFee = true
    yield put(BurnPermitAreaActions.updateBurnPermitAreaRequest(burnPermitAreaInfo, online, calcFee))
  }

  if (!online) {
    const model = { ...burnPermitAreaInfo, Submit: true, }
    const updatedAreaInfo = yield call(updateLocalModel, PERMIT_AREA_MODEL_NAME, burnPermitAreaInfo.BurnPermitAreaId, model)
    // update local search result so the My Permits and Search results show the pending updates
    const burnPermitId = yield select(getBurnPermitId, { BurnPermitAreaId: updatedAreaInfo.BurnPermitAreaId, })
    const BurnType = updatedAreaInfo.BurnTypes.toModelArray().map(t => t.BurnTypeName).join(', ')
    const HarvestAcres = updatedAreaInfo.HarvestAcres
    yield call(updateLocalModel, PERMIT_SEARCH_MODEL_NAME, burnPermitId, { BurnType, BurnAcres: HarvestAcres, })
  }
}

function* updateBurnPermitAreaSuccess (resp) {
  try {
    const { showSuccess, calcFee, } = resp
    const burnPermitArea = yield call(extractPayload, resp)
    const { BurnPermitAreaId, } = burnPermitArea
    yield call(updateLocalModel, PERMIT_AREA_MODEL_NAME, BurnPermitAreaId, burnPermitArea)

    if (showSuccess) {
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
    if (calcFee === true) {
      // Get the current burn permit id based on the Area Id
      const burnPermitId = yield select(getBurnPermitId, { BurnPermitAreaId: BurnPermitAreaId, })
      if (burnPermitId) {
        // Issue a request to update the detail to get the updated estimated fee and tonnage
        yield call(updateTonnageAndFee)
      }
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* submitOfflineAreaEdits (localId, serverId) {
  try {
    // Get the local area model
    const burnPermitArea = yield select(modelByIdSelector, { modelName: BurnPermitArea.modelName, modelId: localId, })
    if (burnPermitArea.Submit) {
      // Get the ref data
      const permitAreaRef = { ...burnPermitArea._fields, }
      // Set the server id
      permitAreaRef.BurnPermitAreaId = serverId
      // Map the virtual fk data
      permitAreaRef.BurnTypes = burnPermitArea.BurnTypes.toRefArray().map(t => t.BurnTypeId)
      // Update it
      const resp = yield call(doFetch, `${BURN_PERMIT_AREA_ENDPOINT}/${serverId}`, { method: 'PUT', body: permitAreaRef, })
      if (resp.ok) {
        yield fork(updateBurnPermitAreaSuccess, { payload: resp.responseBody, })
        return
      }
      yield call(showError, resp.responseBody)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}


export const BurnPermitAreaSagas = [
  takeLatest(BurnPermitAreaTypes.GET_BURN_AREA_INFO, getBurnAreaInfo),
  takeLatest(BurnPermitAreaTypes.UPDATE_BURN_PERMIT_AREA, updateBurnPermitArea),
  takeLatest(BurnPermitAreaTypes.UPDATE_BURN_PERMIT_AREA_SUCCESS, updateBurnPermitAreaSuccess),
]