// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { bool, string, number, func, array, object, PropTypes, } from 'prop-types'
import { debounce, isEqual, isEmpty, } from 'lodash'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, } from 'formik'
import { Link, } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  ButtonGroup,
  Button,
} from 'reactstrap'

// Actions
import ApplicantInfoActions from '../../redux/ApplicantInfoSectionRedux'

// Components
import BurnPermitFormSection from './FormSection'
import { PersonForm, } from '../Forms'
import {
  AutoComplete,
  Select,
  PopoverButton,
  ValidatingField,
} from '../FormControls'
import Effect from '../Effect'
import AuditData from '../AuditData'

// Selectors
import { agentsForSelectSelector, } from '../../selectors/agentSelectors'
import { agenciesForSelectSelector, } from '../../selectors/agencySelectors'
import { landownerSelector, } from '../../selectors/personSelectors'
import {
  permitForApplicantInfoSelector,
  applicantInfoBurnerSelector,
  applicantInfoAgentSelector,
  applicantInfoAgencySelector,
} from '../../selectors/applicantInfoSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import retry from '../../utilities/retry'
import { userCanSelectPermitLandowner, } from '../../selectors/burnPermitPermissionSelectors'
import { appIsOnlineSelector, } from '../../selectors/selectors'

// eslint-disable-next-line no-undef
const { NODE_ENV, } = process.env


function requiredNumberSchema (message) {
  return Yup.number().min(1, message).required(message)
}

const validationSchema = Yup.object().shape({
  MailPermitToAgentFlag : Yup.boolean(),
  AgencyId              : Yup.string()
    .when('MailPermitToAgentFlag', {
      is   : true,
      then : Yup.string()
        .ensure('Agent is not associated with an Agency. Update their profile to set their Agency.')
        .required('Agent is not associated with an Agency. Update their profile to set their Agency.'),
      otherwise: Yup.string().nullable(),
    }),
  Verified: Yup.string()
    .when('MailPermitToAgentFlag', {
      is   : true,
      then : Yup.string()
        .ensure('Agent is not verified. Update their profile to verify their Agency.')
        .required('Agent is not verified. Update their profile to verify their Agency.'),
      otherwise: Yup.string().nullable(),
    }),
  AgentId: Yup.number()
    .when('MailPermitToAgentFlag', {
      is        : true,
      then      : requiredNumberSchema('Please select an Agent'),
      otherwise : Yup.number().nullable(),
    }),
  AgentPermitAddressId: Yup.number()
    .when('AgentId', {
      is: agentId => {
        return agentId > 0
      },
      then      : requiredNumberSchema('Please select an Agent Address'),
      otherwise : Yup.number().nullable(),
    }),
  AgentPermitPrimaryPhoneId: Yup.number()
    .when('AgentId', {
      is: agentId => {
        return agentId > 0
      },
      then      : requiredNumberSchema('Please select an Agent Phone'),
      otherwise : Yup.number().nullable(),
    }),
  BurnerPermitAddressId: Yup.number()
    .when('BurnerId', {
      is: burnerId => {
        return burnerId > 0
      },
      then      : requiredNumberSchema('Please select a Landowner Address'),
      otherwise : Yup.number().nullable(),
    }),
  BurnerPermitPrimaryPhoneId: Yup.number()
    .when('BurnerId', {
      is: burnerId => {
        return burnerId > 0
      },
      then      : requiredNumberSchema('Please select a Landowner Phone'),
      otherwise : Yup.number().nullable(),
    }),
})


const LoadingCard = ({ message, }) => {
  return <Row>
    <Col>
      <Card>
        <CardBody><h5>{ message }</h5></CardBody>
      </Card>
    </Col>
  </Row>
}

LoadingCard.defaultProps = {
  message: 'Loading...',
}

LoadingCard.propTypes = {
  message: string,
}


class ApplicantInfoSection extends React.Component {
  
  constructor (props) {
    super(props)
    this.validateSection = this.validateSection.bind(this)
    this.onFormChange = debounce(this.onFormChange.bind(this), 300)
  }

  static whyDidYouRender = NODE_ENV === 'development'

  static propTypes = {
    // mapStateToProps
    activeBurnPermit       : object,
    agents                 : array,
    children               : array,
    landowners             : array,
    online                 : bool,
    burner                 : object,
    agent                  : object,
    isDnr                  : bool,
    isAgent                : bool,
    agency                 : object,
    agencies               : array,
    selectedAgencyId       : PropTypes.oneOfType([ number, string, ]),
    selectedAgentId        : PropTypes.oneOfType([ number, string, ]),
    selectedLandownerId    : PropTypes.oneOfType([ number, string, ]),
    targetLandowner        : string,
    sectionIsLoading       : bool,
    canSelectLandowner     : bool,
    isActive               : bool,
    // mapDispatchToProps
    UpdateApplicantInfo    : func,
    GetApplicantInfoData   : func,
    ResetApplicantInfo     : func,
    SetApplicantInfo       : func,
    SetSelectedAgencyId    : func,
    SetSelectedAgentId     : func,
    SetSelectedLandownerId : func,
    SetTargetLandowner     : func,
    SetMailToAgent         : func,
    SetContactData         : func,
    // from parent
    title                  : string.isRequired,
    sectionId              : number.isRequired,
    isDisabled             : bool,
    readOnly               : bool,
    userPersonId           : PropTypes.oneOfType([ number, string, ]),
  }

  static defaultProps = {
    readOnly           : false,
    userPersonId       : null,
    burner             : null,
    agent              : null,
    canSelectLandowner : false,
  }

  state = {
    // generic validation error
    validationError : '',
    // save flags
    saving          : false,
    saved           : false,
    isValid         : '',
  }

  async validateSection ({ evt, submit = true, }) {
    if (evt) {
      stopEvent(evt)
    }
    if (!this.formik) {
      return await retry(this.validateSection, { submit, }, 100)
    }
    const validationError = []
    const {
      MailPermitToAgentFlag,
      Landowner,
      Agency,
      AgentId,
    } = this.formik.values

    let showGenericError = false
    let landownerFormIsValid = true
    let saving = false
    const sectionErrors = await this.formik.validateForm()
    const sectionErrorEntries = Object.entries(sectionErrors).filter(e => e[0] !== 'isCanceled')
    if (sectionErrorEntries.length > 0) {
      showGenericError = true
    }
    
    const { targetLandowner, canSelectLandowner, isAgent, } = this.props
    
    if (targetLandowner === 'Person' || !targetLandowner) {
      if (this.LandownerForm) {
        const errors = await this.LandownerForm.validateForm()
        if (Object.keys(errors).length > 0) {
          landownerFormIsValid = false
          showGenericError = true
        }
      }
      // This is easier than trying to configure the Yup schema to support the
      // circular dependency between choosing a Landowner or Agency
      // Since Agents can also choose a Landowner or Agency, we need this
      // validation to also block them from saving the section without choosing one
      if ((canSelectLandowner || isAgent) && !Landowner && !Agency) {
        const msg = 'You must choose a Landowner or Agency'
        sectionErrorEntries.push([ 'Landowner/Agency', msg, ])
        landownerFormIsValid = false
      }
    }
    else if (this.props.targetLandowner === 'Agency' && (!MailPermitToAgentFlag || (!AgentId || AgentId < 0))) {
      this.formik.setFieldError('Agent', 'You must specify an Agent when creating an Agency Permit.')
      showGenericError = true
    }

    if (MailPermitToAgentFlag && (!AgentId || AgentId < 0)) {
      this.formik.setFieldError('Agent', 'You must specify an Agent to email Permit Documents to or uncheck the Agent checkbox above.')
      showGenericError = true
    }

    if (MailPermitToAgentFlag && AgentId) {
      if (!this.formik.values.AgencyId) {
        this.formik.setFieldTouched('AgencyId')
      }
      if (!this.formik.values.Verified) {
        this.formik.setFieldTouched('Verified')
      }
    }

    let agentEmailError = ''
    if (AgentId > 0 && this.props.agent && !this.props.agent.Email) {
      agentEmailError = <>
        <p>
          An Email must be configured for this Agent. Click <Link to={`/admin/people/${AgentId}`}>here </Link>
          to go to their profile and configure an Email.
        </p>
      </>
      validationError.push(agentEmailError)
    }

    if (showGenericError) {
      validationError.push('See forms below for specific errors.')
    }

    if (sectionErrorEntries.length > 0) {
      let errorMsg
      for (let se = 0, seLen = sectionErrorEntries.length; se < seLen; se++) {
        errorMsg = sectionErrorEntries[se][1]
        // Push the error message into the validation error array
        if (!validationError.includes(errorMsg)) {
          validationError.push(errorMsg)
        }
      }
    }
    
    // Always update the state of the validation errors to clear any out that should no longer be there
    const updateObj = {
      saving,
      saved           : false,
      validationError : validationError.join(';'),
      agentEmailError,
    }
    this.setState(updateObj)
    let isValid = validationError.length === 0 && landownerFormIsValid
    if (isValid && !showGenericError) {
      if (submit) {
        const val = await this.formik.submitForm()
        isValid = (val && isEmpty(val)) || true
      }
    }
    return isValid
  }

  submit = (values) => {
    const {
      BurnerId,
      BurnerPermitAddressId,
      BurnerPermitPrimaryPhoneId,
      BurnerPermitSecondaryPhoneId,
      AgencyId,
      AgentId,
      AgentPermitAddressId,
      AgentPermitPrimaryPhoneId,
      AgentPermitSecondaryPhoneId,
      MailPermitToAgentFlag,
    } = values

    const { UpdateApplicantInfo, activeBurnPermit, targetLandowner, } = this.props
    let burnPermit = { ...activeBurnPermit, AgencyId, }

    burnPermit.MailPermitToAgentFlag = MailPermitToAgentFlag
    // This is now always required
    burnPermit.MailPermitToLandownerFlag = true
    if (MailPermitToAgentFlag) {
      burnPermit.AgentId = AgentId
      burnPermit.AgentPermitAddressId = AgentPermitAddressId
      burnPermit.AgentPermitPrimaryPhoneId = AgentPermitPrimaryPhoneId
      burnPermit.AgentPermitSecondaryPhoneId = AgentPermitSecondaryPhoneId
    }
    else {
      burnPermit.AgentPermitAddressId = null
      burnPermit.AgentPermitPrimaryPhoneId = null
      burnPermit.AgentPermitSecondaryPhoneId = null
      burnPermit.AgentId = null
    }
    if (targetLandowner === 'Person') {
      burnPermit.BurnerId = BurnerId
      burnPermit.BurnerPermitAddressId = BurnerPermitAddressId
      burnPermit.BurnerPermitPrimaryPhoneId = BurnerPermitPrimaryPhoneId
      burnPermit.BurnerPermitSecondaryPhoneId = BurnerPermitSecondaryPhoneId
      // We only want to null out the Agency if an Agent is not set
      burnPermit.AgencyId = MailPermitToAgentFlag ? AgencyId : null
    }
    else if (targetLandowner === 'Agency') {
      burnPermit.AgencyId = AgencyId
      burnPermit.BurnerPermitSecondaryPhoneId = null
      burnPermit.BurnerPermitPrimaryPhoneId = null
      burnPermit.BurnerPermitAddressId = null
      burnPermit.BurnerId = null
    }
    else {
      // If no target is set, null out everything and let the API return the appropriate error message
      burnPermit.BurnerPermitSecondaryPhoneId = null
      burnPermit.BurnerPermitPrimaryPhoneId = null
      burnPermit.BurnerPermitAddressId = null
      burnPermit.BurnerId = null
      burnPermit.AgencyId = null
    }

    delete burnPermit['Agency']
    delete burnPermit['Agent']
    delete burnPermit['Landowner']
    
    if (!isEqual(activeBurnPermit, burnPermit)) {
      if (this.props.isAgent) {
        burnPermit.MailPermitToAgentFlag = true
      }
      UpdateApplicantInfo(burnPermit)
    }
  }
  
  componentWillUnmount () {
    this.props.ResetApplicantInfo()
  }

  componentDidUpdate (prevProps) {
    // Don't fire off anything unless we're active
    if (!this.props.isActive) {
      return
    }
    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.GetApplicantInfoData()
      this.props.SetApplicantInfo(this.props.activeBurnPermit.BurnPermitId)
    }
    // If a user picks an agent first before an agency is selected
    if (prevProps.selectedAgentId !== this.props.selectedAgentId && this.props.selectedAgentId && !this.props.selectedAgencyId && this.props.agencies.length === 1) {
      // The state should update to only have that person's agency in the props
      const { Text, } = this.props.agencies[0]
      
      // So update the form to set that agencyId as the selected Agency
      // This should also trigger the AgencyOnChange method to update
      // the app state.
      this.agencyAutoSelect(Text)
    }
    if (prevProps.agency && !this.props.agency) {
      this.agencyAutoSelect('')
    }
    if (this.formik && this.formik.dirty !== true) {
      const { agency, agencies, agent, agents, burner, } = this.props
      const { AgencyId, AgentId, BurnerId, } = this.formik.values
      // Only change the selection if there is an actual change
      if (!isEmpty(burner) && !isEqual(prevProps.burner, burner) && BurnerId !== burner.Person.PersonId) {
        this.landownerAutoSelect(`${burner.Person.PersonId}: ${burner.Person.PersonName}`)
      }
      const newAgent = !isEqual(prevProps.agent, agent)
      const agentsNowAvailable = !prevProps.agents.length && agents.length
      // Only change the selection if there is an actual change
      if (!isEmpty(agent) && (newAgent || agentsNowAvailable) && AgentId !== agent.Person.PersonId) {
        this.agentAutoSelect(`${agent.Person.PersonId}: ${agent.Person.PersonName}`)
      }
      const newAgency = !isEqual(prevProps.agency, agency)
      const agenciesNowAvailable = !prevProps.agencies.length && agencies.length
      // Only change the selection if there is an actual change
      if (!isEmpty(agency) && (newAgency || agenciesNowAvailable) && AgencyId !== agency.AgencyId) {
        this.agencyAutoSelect(`${agency.AgencyId}: ${agency.AgencyName}`)
      }
      if (prevProps.targetLandowner !== this.props.targetLandowner && this.props.targetLandowner === 'Agency') {
        this.formik.setFieldValue('BurnerId', '')
        // This is to support existing Pending Applications where only the Agent is selected
        // Agent users cannot change the Agent or the Agency of the Application, so we need
        // to default it for them if their app was created before this update is deployed
        if (this.props.agencies.length === 1 && !this.props.selectedAgencyId) {
          this.agencyAutoSelect(this.props.agencies[0].Text)
        }
      }
      if (prevProps.activeBurnPermit.MailPermitToAgentFlag !== this.props.activeBurnPermit.MailPermitToAgentFlag) {
        this.validateSection({ submit: false, })
      }
    }
  }

  componentDidMount = () => {
    const { GetApplicantInfoData, canSelectLandowner, agency, agent, burner, isActive, } = this.props
    if (isActive) {
      GetApplicantInfoData()
    }

    if (canSelectLandowner && burner) {
      if (this.formik) {
        this.formik.setFieldValue('BurnerId', burner.Person.PersonId)
      }
      this.landownerAutoSelect(`${burner.Person.PersonId}: ${burner.Person.PersonName}`, true)
    }
    if (agent) {
      if (this.formik) {
        this.formik.setFieldValue('AgentId', agent.Person.PersonId)
      }
      this.agentAutoSelect(`${agent.Person.PersonId}: ${agent.Person.PersonName}`, true)
    }
    if (agency && agency.AgencyName) {
      if (this.formik) {
        this.formik.setFieldValue('AgencyId', agency.AgencyId)
      }
      this.agencyAutoSelect(`${agency.AgencyId}: ${agency.AgencyName}`, true)
    }
  }

  getPersonAddress = (person, value) => {
    if (value && person.Addresses.length) {
      const [ addr, ] = person.Addresses.filter(a => a.Value === value)
      if (addr && addr.Text) {
        return addr.Text
      }
    }
    return ''
  }

  addressForm = (person, namePrefix) => {
    const { online, } = this.props

    if (!person) {
      return null
    }
        
    if (!person.Addresses) {
      return <span>Loading Addresses...</span>
    }
    if (person.Addresses && person.Addresses.length === 0) {
      if (Number.isInteger(person.Person.PersonId) && person.Person.PersonId !== this.props.userPersonId) {
        if (this.props.canSelectLandowner) {
          return <Row>
            <Col xs={12} className={'mt-3'}>
              <p className={'text-danger'}>
                Add an Active Address for this Person <Link to={'/admin/people/' + person.Person.PersonId} disabled={!online}>here</Link>.
                { !online && <PopoverButton buttonClassName={'py-0'} key={'create-person-addr'} popoverHeader={'Online only'} popoverBody={'Creating an Address for a Person can only be performed while connected to the internet.'} /> }
              </p>
            </Col>
          </Row>
        }
        else {
          return <Row>
            <Col xs={12} className={'mt-3'}>
              <p className={'text-danger'}>
                No Addresses are available for this Person.
              </p>
            </Col>
          </Row>
        }
      }
      else {
        return <Row>
          <Col xs={12} className={'mt-3'}>
            <p className={'text-danger'}>
              Add an Active Address in <Link to={'/profile'} disabled={!online}>your profile</Link>.
              { !online && <PopoverButton buttonClassName={'py-0'} key={'create-person-addr'} popoverHeader={'Online only'} popoverBody={'Creating an Address can only be performed while connected to the internet.'} /> }
            </p>
          </Col>
        </Row>
      }
    }

    return <>
      <Effect onChange={this.onFormChange}></Effect>
      <Row>
        <Col xs={12} className={'mt-3'}>
          <FormGroup>
            <Field name={namePrefix + 'PermitAddressId'}>
              {({ field, form, }) => (
                this.props.readOnly !== true
                  ? <Select
                    label={'Mailing Address'}
                    items={person.Addresses}
                    propertyName={field.name}
                    {...field}
                    selectedValue={field.value || this.state[field.name]}
                    errorMessage={form.errors[field.name]}
                    readOnly={this.props.readOnly}
                    required={true}
                  />
                  : <FormGroup>
                    <Label>Mailing Address</Label>
                    <Input
                      id={field.name}
                      value={this.getPersonAddress(person, field.value)}
                      readOnly={true}
                      rows={3}
                      className={'p-0'}
                      type={'textarea'}
                    />
                  </FormGroup>
              )}
            </Field>
            <ErrorMessage name={namePrefix + 'PermitAddressId'} component={FormFeedback}/>
          </FormGroup>
        </Col>
      </Row>
    </>
  }

  phoneForms = (person, namePrefix) => {
    const { online, } = this.props

    if (!person) {
      return null
    }
    
    if (!person.Phones) {
      return <span>Loading Phone Numbers...</span>
    }

    if (person.Phones && person.Phones.length === 0) {
      if (Number.isInteger(person.Person.PersonId) && person.Person.PersonId !== this.props.userPersonId) {
        if (this.props.canSelectLandowner) {
          return <Row>
            <Col xs={12} className={'mt-3'}>
              <p className={'text-danger'}>
                Add an Active Phone Number for this Person <Link to={'/admin/people/' + person.Person.PersonId} disabled={!online}>here</Link>.
                { !online && <PopoverButton buttonClassName={'py-0'} key={'create-person-phone'} popoverHeader={'Online only'} popoverBody={'Creating an Phone for a Person can only be performed while connected to the internet.'} /> }
              </p>
            </Col>
          </Row>
        }
        else {
          return <Row>
            <Col xs={12} className={'mt-3'}>
              <p className={'text-danger'}>
                No Phone Numbers are available for this Person.
              </p>
            </Col>
          </Row>
        }
      }
      else {
        return <Row>
          <Col xs={12} className={'mt-3'}>
            <p className={'text-danger'}>
              Add an Active Phone Number in <Link to={'/profile'} disabled={!online}>your profile</Link>.
              { !online && <PopoverButton buttonClassName={'py-0'} key={'create-person-addr'} popoverHeader={'Online only'} popoverBody={'Creating a Phone can only be performed while connected to the internet.'} /> }
            </p>
          </Col>
        </Row>
      }
    }
    const multiplePhones = person.Phones.length > 1
    return <Row>
      <Col xs={12} md={multiplePhones ? 6 : 12} className={'mt-3'}>
        <FormGroup>
          <Field name={namePrefix + 'PermitPrimaryPhoneId'}>
            {({ field, form, }) => (
              <Select
                label={'Primary Phone'}
                items={person.Phones}
                propertyName={field.name}
                {...field}
                selectedValue={field.value || this.state[field.name]}
                errorMessage={form.errors[field.name]}
                readOnly={this.props.readOnly}
                required={true}
              />
            )}
          </Field>
        </FormGroup>
      </Col>
      {
        multiplePhones && <Col xs={12} md={6} className={'mt-3'}>
          <FormGroup>
            <Field name={namePrefix + 'PermitSecondaryPhoneId'}>
              {({ field, form, }) => (
                <Select
                  label={'Secondary Phone'}
                  items={person.Phones}
                  propertyName={field.name}
                  {...field}
                  selectedValue={field.value || this.state[field.name]}
                  errorMessage={form.errors[field.name]}
                  readOnly={this.props.readOnly}
                />
              )}
            </Field>
          </FormGroup>
        </Col>
      }
    </Row>
  }

  agentAutoSelect = ({ value, label, }) => {
    const newFormState = this.agentSetFormValues({ value, label, })
    if (this.props.selectedAgentId !== newFormState.AgentId) {
      this.props.SetSelectedAgentId(newFormState.AgentId)
    }
  }

  agentSetFormValues = ({ value, label, }) => {
    if (!this.formik) {
      return {}
    }
    const newFormState = { ...this.formik.values, }
    if (value) {
      newFormState.AgentId = value
    }
    else if (!value) {
      newFormState.AgentId = ''
      this.props.SetSelectedAgentId('')
    }
    newFormState.Agent = label
    if (this.formik.values.AgentId !== newFormState.AgentId) {
      newFormState.AgentPermitAddressId = ''
      newFormState.AgentPermitPrimaryPhoneId = ''
      newFormState.AgentPermitSecondaryPhoneId = ''
    }
    this.formik.setValues(newFormState)
    return newFormState
  }

  agentForm = (values) => {
    const {
      agents,
      readOnly,
      selectedAgentId,
      canSelectLandowner,
      isDnr,
      isAgent,
      online,
    } = this.props
    let markup = <Input readOnly={true} value={'No Agents found'} id={'Agent'} />
    if (agents && agents.length > 0) {
      let value = values.Agent
      if (value && values.AgentId > 0 && value.startsWith(values.AgentId) === false) {
        value = `${values.AgentId}: ${values.Agent}`
      }
      markup = <Row>
        <Col xs={12}>
          <FormGroup>
            <AutoComplete
              items={agents}
              fieldName={'AgentId'}
              onChange={this.agentAutoSelect}
              readOnly={readOnly || isAgent}
              minCharactersToEnter={values.Agency ? 0 : 2}
            />
          </FormGroup>
        </Col>
      </Row>
    }
    return <Row>
      <Col>
        <FormGroup>
          <Label for={'Agent'}>
            Agent{ readOnly || selectedAgentId ? '' : 's'}
          </Label>
          {
            selectedAgentId > 0 && canSelectLandowner &&
              <Link
                to={`/admin/people/${selectedAgentId}`}
                className={'ml-2'}>
                View Profile
              </Link>
          }
          { markup }
          {
            isDnr && !readOnly && !values.AgentId && <Col xs={12}>
              <span>Unable to find the Agent you&apos;re looking for? Add them </span>
              <Link
                to={'/admin/people/new'}
                disabled={!online}> here.
              </Link>
              {
                !online &&
                  <PopoverButton
                    buttonClassName={'py-0'}
                    key={'create-agent'}
                    popoverHeader={'Online only'}
                    popoverBody={'Creating an Agent can only be performed while connected to the internet.'}
                  />
              }
            </Col>
          }
        </FormGroup>
      </Col>
    </Row>
  }

  personForm = () => {
    const { isDisabled, userPersonId, burner, canSelectLandowner, } = this.props

    if (canSelectLandowner) {
      return this.personSelect()
    }
    if (!burner) {
      return 'Unable to select a Person as Landowner for this Permit Application.'
    }
    return <PersonForm
      person={burner.Person}
      personId={userPersonId}
      email={burner.Email}
      isDisabled={isDisabled}
      showAddresses={false}
      showPhones={false}
      showEmails={true}
      disableDeleteEmail={true}
      showSaveButton={false}
      showTypes={false}
      showAuditData={false}
      showAlertPrefs={false}
      showRegion={false}
      readOnly={true}
      ref={node => this.LandownerForm = node}
      onChange={this.onChange}
    />
  }

  agencySetFormValues = ({ label, value, }) => {
    if (!this.formik) {
      return {}
    }
    const newFormState = { ...this.formik.values, }
    if (value) {
      newFormState.AgencyId = value
      if (!!newFormState.BurnerId && parseInt(newFormState.BurnerId > 0)) {
        newFormState.BurnerId = ''
        newFormState.BurnerPermitAddressId = ''
        newFormState.BurnerPermitPrimaryPhoneId = ''
        newFormState.BurnerPermitSecondaryPhoneId = ''
      }
    }
    else if (!value) {
      newFormState.AgencyId = ''
      this.props.SetSelectedAgencyId('')
      if (newFormState.AgentId > 0) {
        // Clear out the AgentId also if one is set so that the full
        // Agency dataset is available for searching since if an AgentId
        // is set, it's used to filter down the Agencys to only the Agency
        // that Agent is a part of.
        newFormState.AgentId = ''
        newFormState.Agent = ''
        this.props.SetSelectedAgentId('')
      }
    }
    newFormState.Agency = label
    this.formik.setValues(newFormState)
    return newFormState
  }

  agencyAutoSelect = ({ label, value, }) => {
    const newFormState = this.agencySetFormValues({ label, value, })
    if ((label && newFormState.AgencyId > 0 ) && this.props.selectedAgencyId !== newFormState.AgencyId) {
      this.props.SetSelectedAgencyId(newFormState.AgencyId)
    }
    else if (!label && !newFormState.AgencyId) {
      this.props.SetSelectedAgencyId()
    }
  }

  agencyFormCard = (values) => {
    const { AgencyId, } = values
    const { agency, targetLandowner, } = this.props
    let markup
    // Don't render the Agency if there is a Burner/Landowner selected, even if there is an Agency set
    // This is because the Agency will be autoset on the backend if/when an Agent is set on the Application
    // so that the App/Permit can be searched by either the Agent or the Agent's Agency they're associated with
    if (targetLandowner !== 'Agency') {
      return markup
    }
    else if (AgencyId && !agency) {
      markup = <LoadingCard />
    }
    const {
      readOnly,
      agencies,
      isDnr,
      online,
      selectedAgencyId,
      canSelectLandowner,
      isAgent,
    } = this.props
    
    markup = <Input readOnly={true} value={'No Agencies found'} id={'Agency'} />

    if (agencies && agencies.length > 0) {
      markup = <Row>
        <Col xs={12}>
          <FormGroup>
            <AutoComplete
              items={agencies}
              fieldName={'AgencyId'}
              onChange={this.agencyAutoSelect}
              readOnly={readOnly || (canSelectLandowner && !isDnr)}
              minCharactersToEnter={2}
            />
          </FormGroup>
        </Col>
      </Row>
    }
    markup = <Card>
      <CardBody>
        <Col xs={12}><h5>Landowner</h5></Col>
        {
          isAgent && <p className={'text-warning'}>Your Agency is chosen by default and cannot be changed because you are an Agent.</p>
        }
        <Row>
          <Col xs={12} md={agency ? 6 : 12}>
            <Label for={'Agency'}>Agency</Label>
            {
              selectedAgencyId > 0 && isDnr
                ? <Link to={`/admin/agencies/${selectedAgencyId}`} className={'ml-2'}>View Profile</Link>
                : <p className={'ml-2 inline-text'}>(ID: {selectedAgencyId})</p>
            }
            { markup }
            {
              isDnr && !readOnly && !AgencyId && <Col xs={12}>
                <span>Unable to find the Agency you&apos;re looking for? Add them </span><Link to={'/admin/agencies/new'} disabled={!online}> here.</Link>
                {
                  !online &&
                    <PopoverButton
                      buttonClassName={'py-0'}
                      key={'create-agency'}
                      popoverHeader={'Online only'}
                      popoverBody={'Creating an Agency can only be performed while connected to the internet.'}
                    />
                }
              </Col>
            }
          </Col>
          {
            agency && <>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'agency-email'}>Email Address</Label>
                  <Input id={'agency-email'} value={agency.Email ? agency.Email : 'No email address'} readOnly={true} />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'agency-address'}>Address</Label>
                  <Input
                    id={'agency-address'}
                    value={agency.Address ? agency.Address : 'No address'}
                    readOnly={true}
                    rows={3}
                    className={'p-0'}
                    type={'textarea'}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'agency-phone'}>Phone</Label>
                  <Input id={'agency-phone'} value={agency.Phone ? agency.Phone : 'No phone'} readOnly={true} />
                </FormGroup>
              </Col>
            </>
          }
        </Row>
      </CardBody>
    </Card>

    return <Col xs={12} className={'mt-3'}>{ markup }</Col>
  }

  agentFormCard = (values, errors) => {
    const { MailPermitToAgentFlag, AgentId, } = values
    const { agent, selectedAgentId, isAgent, } = this.props
    let markup
    if (!MailPermitToAgentFlag) {
      return markup
    }
    else if (AgentId && !agent) {
      markup = <LoadingCard />
    }
    markup = <Card>
      <CardBody>
        <Col xs={12}><h5>Agent</h5></Col>
        {
          isAgent && <p className={'text-warning'}>You are chosen by default and cannot be changed because you are an Agent.</p>
        }
        <Row>
          <Col xs={12} md={agent && selectedAgentId ? 6 : 12}>
            { this.agentForm(values, errors) }
            <Row>
              <Col>
                <FormGroup>
                  <Label for={'agent-agencyId'}>Agency ID</Label>
                  <Field
                    id={'agent-agencyId'}
                    name={'AgencyId'}
                    type={'text'}
                    value={agent ? agent.AgencyId : ''}
                    readOnly={true}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for={'agent-agency-verified'}>Is Verified</Label>
                  <Field
                    id={'agent-agency-verified'}
                    name={'Verified'}
                    type={'text'}
                    value={agent ? agent.Verified : ''}
                    readOnly={true}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          {
            agent && selectedAgentId && <>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'agent-email'}>Email Address</Label>
                  <Input
                    id={'agent-email'}
                    value={agent.Email ? agent.Email.EmailAddress : 'No email address'}
                    className={this.state.agentEmailError ? 'is-invalid' : ''}
                    readOnly={true}
                  />
                  { this.state.agentEmailError && <FormFeedback>{this.state.agentEmailError}</FormFeedback> }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                { this.addressForm(agent, 'Agent') }
              </Col>
              <Col xs={12} md={6}>
                { this.phoneForms(agent, 'Agent') }
              </Col>
            </>
          }
        </Row>
      </CardBody>
    </Card>

    return <Col className={'mt-3'}>{ markup }</Col>
  }

  landownerAutoSelect = ({ label, value, }) => {
    const newFormState = this.landownerSetFormValues({ label, value, })
    if ((label && newFormState.BurnerId > 0) && this.props.selectedLandownerId !== newFormState.BurnerId) {
      this.props.SetSelectedLandownerId(newFormState.BurnerId)
    }
    else if (!label && !newFormState.BurnerId) {
      this.props.SetSelectedLandownerId()
    }
  }

  landownerSetFormValues = ({ label, value, }) => {
    if (!this.formik) {
      return {}
    }
    const newFormState = { ...this.formik.values, }
    if (value) {
      newFormState.BurnerId = value
      if (!!newFormState.AgencyId && parseInt(newFormState.AgencyId > 0)) {
        newFormState.AgencyId = ''
      }
    }
    else if (!value) {
      newFormState.BurnerId = ''
      this.props.SetSelectedLandownerId('')
    }
    newFormState.Landowner = label
    if (this.formik.values.BurnerId !== newFormState.BurnerId) {
      newFormState.BurnerPermitAddressId = ''
      newFormState.BurnerPermitPrimaryPhoneId = ''
      newFormState.BurnerPermitSecondaryPhoneId = ''
    }
    this.formik.setValues(newFormState)
    return newFormState
  }

  personSelect = () => {
    const { readOnly, selectedLandownerId, canSelectLandowner, } = this.props
    return <FormGroup>
      <Label for={'Landowner'}>Landowner{ readOnly ? 's' : ''}</Label>
      { selectedLandownerId > 0 && canSelectLandowner && <Link to={`/admin/people/${selectedLandownerId}`} className={'ml-2'}>View Profile</Link> }
      <AutoComplete
        items={this.props.landowners}
        fieldName={'BurnerId'}
        onChange={this.landownerAutoSelect}
        readOnly={readOnly}
        minCharactersToEnter={2}
      />
    </FormGroup>
  }

  personFormCard = (values) => {
    const { BurnerId, } = values
    const { readOnly, targetLandowner, } = this.props
    let markup
    if (targetLandowner !== 'Person') {
      return markup
    }
    else if (BurnerId && !this.props.burner) {
      markup = <LoadingCard />
    }
    const { canSelectLandowner, online, burner, } = this.props
    const showBurnerEmail = (canSelectLandowner && BurnerId && burner)
    markup =<Card>
      <CardBody>
        <Row>
          <Col xs={12}><h5>Landowner</h5></Col>
          <Col xs={12} md={showBurnerEmail ? 6 : 12}>
            { this.personForm() }
          </Col>
          {
            showBurnerEmail &&
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'burner-email'}>Email Address</Label>
                  <Input id={'burner-email'} value={burner.Email ? burner.Email.EmailAddress : 'No email address'} readOnly={true} />
                </FormGroup>
              </Col>
          }
        </Row>
        {
          BurnerId > 0
            ? <Row>
              <Col xs={12} md={6}>
                { this.addressForm(burner, 'Burner') }
              </Col>
              <Col xs={12} md={6}>
                { this.phoneForms(burner, 'Burner') }
              </Col>
            </Row>
            : <Row>
              <Col>
                {
                  canSelectLandowner && !readOnly && <>
                    <span>Unable to find who you&apos;re looking for? Add them </span><Link to={'/admin/people/new'} disabled={!online}> here.</Link>
                    { !online && <PopoverButton buttonClassName={'py-0'} key={'create-person'} popoverHeader={'Online only'} popoverBody={'Creating a Person can only be performed while connected to the internet.'} /> }
                  </>
                }
              </Col>
            </Row>
        }
      </CardBody>
    </Card>

    return <Col xs={12} className={'mt-3'}>{ markup }</Col>
  }

  renderForms = (values, errors) => {
    return <>
      <Row>
        { this.personFormCard(values) }
        { this.agencyFormCard(values) }
      </Row>
      <Row>
        { this.agentFormCard(values, errors) }
      </Row>
    </>
  }

  contactDataProps = [
    'BurnerPermitAddressId', 'BurnerPermitPrimaryPhoneId', 'BurnerPermitSecondaryPhoneId',
    'AgentPermitAddressId', 'AgentPermitPrimaryPhoneId', 'AgentPermitSecondaryPhoneId',
  ]

  onFormChange = (changedEntries) => {
    // This handles instances when a user chooses Address and Phones for a Agent, then
    // unchecks the mail the permit to the Agent.
    // This will maintain the selected address and phone IDs across the form state change
    const entries = changedEntries.map(x => x[0]).filter(x => this.contactDataProps.includes(x))
    if (this.formik && entries.length) {
      let prop, value
      for (let i = 0, len = entries.length; i < len; i++) {
        prop = entries[i]
        value = this.formik.values[prop]
        this.props.SetContactData(prop, value)
      }
    }
    this.validateSection({ submit: false, }).then(isValid => {
      this.setState({ isValid, })
    })
  }
  
  setTargetLandowner = evt => {
    const { target, } = evt.target.dataset
    this.props.SetTargetLandowner(target || '')
  }

  setMailToAgent = evt => {
    const { checked, } = evt.target
    this.props.SetMailToAgent(checked)
  }
  
  setFormikNode = node => this.formik = node

  render () {
    const {
      activeBurnPermit,
      readOnly,
      targetLandowner,
      sectionIsLoading,
      isAgent,
      isDnr,
    } = this.props
    let children = null
    if (!activeBurnPermit || sectionIsLoading) {
      children = <Col xs={12}>
        <LoadingCard />
      </Col>
    }
    else {
      children = <Formik
        enableReinitialize={true}
        initialValues={activeBurnPermit}
        validationSchema={validationSchema}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
      >
        {({ values, errors, }) => (
          <Col xs={12}>
            <Effect values={values} onChange={this.onFormChange} />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      {
                        !readOnly && (isDnr || isAgent) && <Col xs={12} md={6}>
                          <p>
                            Choose the Landowner Type:
                            <br/>
                            <small className={'text-danger'}>A Landowner or Agency is now always required.</small>
                          </p>
                          <ButtonGroup>
                            <Button
                              size={'sm'}
                              color={'light'}
                              onClick={this.setTargetLandowner}
                              data-target={'Person'}
                              active={targetLandowner === 'Person'}
                            >
                              Person
                            </Button>
                            <Button
                              size={'sm'}
                              color={'light'}
                              onClick={this.setTargetLandowner}
                              data-target={'Agency'}
                              active={targetLandowner === 'Agency'}
                            >
                              Agency
                            </Button>
                          </ButtonGroup>
                        </Col>
                      }
                      <Col xs={12} md={6} className={'mt-2 mt-lg-0'}>
                        <p>
                          Check to also Email Burn Permit documents to an Agent:
                          {
                            isAgent && <>
                              <br/>
                              <small className={'text-warning'}>This is checked by default and cannot be changed because you are an Agent.</small>
                            </>
                          }
                        </p>
                        <FormGroup check>
                          <Label check>
                            <Field
                              type={'checkbox'}
                              name={'MailPermitToAgentFlag'}
                              checked={values.MailPermitToAgentFlag}
                              readOnly={readOnly || isAgent || targetLandowner === 'Agency'}
                              disabled={readOnly || isAgent || targetLandowner === 'Agency'}
                              onChange={this.setMailToAgent}
                            />
                            <div className={'d-inline ml-2' + (readOnly && !values.MailPermitToAgentFlag ? ' grey-text' : '')}>Agent</div>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <div className={'d-flex h-100'}>
                          <AuditData {...this.props.activeBurnPermit} style={{ marginTop: 'auto', }} />
                        </div>
                      </Col>
                    </Row>
                    {
                      targetLandowner === 'Agency' && <Row>
                        <Col>
                          <p className={'text-danger my-2'}>
                            You acknowledge the selected Agency owns the property the proposed burning will occur.
                            If the proposed burn will occur on another person&apos;s property, you should specify that person as the landowner.
                            The application and permit will still be searchable by the Agent&apos;s Agency name.
                          </p>
                        </Col>
                      </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            { this.renderForms(values, errors) }
          </Col>
        )}
      </Formik>
    }
    
    return (
      <BurnPermitFormSection
        {...this.props}
        validateSection={this.validateSection}
        validationError={this.state.validationError}
        readOnly={readOnly}
        isValid={typeof this.state.isValid === 'boolean' ? this.state.isValid : ''}
      >
        { children }
      </BurnPermitFormSection> 
    )
  }
}

function mapStateToProps (state, props) {
  const {
    agentId: selectedAgentId,
    agencyId: selectedAgencyId,
    landownerId: selectedLandownerId,
    targetLandowner,
    sectionIsLoading,
  } = state.ApplicantInfo
  let agentsForAgencyFilter
  if (selectedAgencyId > 0) {
    agentsForAgencyFilter = a => {
      return (a.PersonId === selectedAgentId || (a.Agencies.exists() && a.Agencies.toRefArray().map(ag => ag.AgencyId).includes(selectedAgencyId)))
    }
  }
  let agenciesForAgentFilter
  if (selectedAgentId > 0) {
    agenciesForAgentFilter = a => {
      return (a.AgencyId === selectedAgencyId || (a.People.exists() && a.People.toRefArray().map(p => p.PersonId).includes(selectedAgentId)))
    }
  }
  let selectedLandownerFilter
  if (selectedLandownerId > 0) {
    selectedLandownerFilter = l => {
      return l.Value === selectedLandownerId
    }
  }

  let agents = [], agencies = [], landowners = []
  agents = agentsForSelectSelector(state, agentsForAgencyFilter)
  if (targetLandowner === 'Person') {
    landowners = landownerSelector(state, selectedLandownerFilter)
  }
  else if (targetLandowner === 'Agency') {
    agencies = agenciesForSelectSelector(state, agenciesForAgentFilter)
  }

  const { applications, activeBurnPermitId, } = state.BurnPermitForm
  const isActive = applications[activeBurnPermitId].activeStep === props.sectionId
  return {
    selectedAgentId,
    selectedAgencyId,
    selectedLandownerId,
    targetLandowner,
    sectionIsLoading,
    agents,
    agencies,
    landowners,
    isActive,
    online             : appIsOnlineSelector(state),
    canSelectLandowner : userCanSelectPermitLandowner(state),
    activeBurnPermit   : permitForApplicantInfoSelector(state),
    burner             : applicantInfoBurnerSelector(state),
    agent              : applicantInfoAgentSelector(state),
    agency             : applicantInfoAgencySelector(state, selectedAgencyId),
  }
}

const mapDispatchToProps = {
  GetApplicantInfoData   : ApplicantInfoActions.getApplicantInfoData,
  ResetApplicantInfo     : ApplicantInfoActions.resetApplicantInfo,
  SetApplicantInfo       : ApplicantInfoActions.setApplicantInfo,
  SetSelectedAgencyId    : ApplicantInfoActions.setSelectedAgencyId,
  SetSelectedAgentId     : ApplicantInfoActions.setSelectedAgentId,
  SetSelectedLandownerId : ApplicantInfoActions.setSelectedLandownerId,
  SetMailToAgent         : ApplicantInfoActions.setMailToAgent,
  SetContactData         : ApplicantInfoActions.setContactData,
  SetTargetLandowner     : ApplicantInfoActions.setTargetLandowner,
  UpdateApplicantInfo    : ApplicantInfoActions.updateApplicantInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantInfoSection)
