// Libraries
import React, { useCallback, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { useFormikContext, } from 'formik'
import { bool, number, string, object, func, oneOfType, } from 'prop-types'

// Components
import DocuSignModal from './DocuSignModal'

// Redux
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'

// Selectors
import { activeBurnPermitIdSelector, permitDates, } from '../../selectors/burnPermitSelectors'

// Utilities
import { dateFormatter, } from '../../utilities'


const IssuePermit = props => {
  const {
    disabled,
    className,
    onClick,
  } = props
  let {
    ValidDate,
    ExpirationDate,
  } = props

  const dispatch = useDispatch()

  const BurnPermitId = useSelector(activeBurnPermitIdSelector)
  const burnPermitDates = useSelector((state) => permitDates(state, BurnPermitId))

  if (!ValidDate || !ExpirationDate) {
    ({ ValidDate, ExpirationDate, } = burnPermitDates)
  }
  ValidDate = dateFormatter(ValidDate)
  ExpirationDate = dateFormatter(ExpirationDate)

  const datesMissing = useMemo(() => (!ValidDate || !ExpirationDate), [ ValidDate, ExpirationDate, ])

  const formikContext = useFormikContext()

  let onClickArgs
  if (formikContext && Object.keys(formikContext).length) {
    onClickArgs = formikContext
  }

  let issuePermit = useCallback(() => {
    if (typeof onClick === 'function') {
      return onClick(onClickArgs)
    }
    dispatch(BurnPermitSignatureActions.signPermit(BurnPermitId, ValidDate, ExpirationDate))
  }, [ dispatch, onClick, BurnPermitId, ValidDate, ExpirationDate, onClickArgs, ])

  let content = null
  if (!disabled && datesMissing && !onClick) {
    content = <>
      <p>You must set the Valid Date and Expiration Date in the Application Summary section.</p>
      <div className={'d-flex justify-content-end'}>
        <Link to={`/permits/${BurnPermitId}`}>Application Summary</Link>
      </div>
    </>
    issuePermit = null
  }

  return <DocuSignModal
    onClick={issuePermit}
    className={className}
    modalHeader={'Issue Burn Permit'}
    buttonText={'Issue Permit'}
    signatureType={SignatureProcessTypes.issue}
    disabled={disabled}
  >
    {content}
  </DocuSignModal>
}

IssuePermit.defaultProps = {
  className : '',
  disabled  : false,
}

IssuePermit.propTypes = {
  burnPermitId   : number,
  className      : string,
  onClick        : func,
  disabled       : bool,
  ValidDate      : oneOfType([ object, string, ]),
  ExpirationDate : oneOfType([ object, string, ]),
}

export default IssuePermit
