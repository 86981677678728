import { createActions, createReducer, } from 'reduxsauce'
import BurnPermitPileGroup from '../models/BurnPermitPileGroup'

const BURN_PERMIT_PILE_GROUP_ENDPOINT = BurnPermitPileGroup.endpoint()
    
/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  getBurnPileGroup                 : [ 'BurnPermitPileGroupId', ],
  getBurnPileGroups                : [ 'burnPermitId', ],
  createBurnPermitPileGroup        : [ 'BurnPermitPileGroup', ],
  createBurnPermitPileGroupRequest : (BurnPermitPileGroup, showSuccess) => ({
    type : 'CREATE_BURN_PERMIT_PILE_GROUP_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : BURN_PERMIT_PILE_GROUP_ENDPOINT,
          init : { method: 'POST', body: { ...BurnPermitPileGroup, }, },
        },
        commit   : { type: 'UPDATE_BURN_PERMIT_PILE_GROUP_SUCCESS', showSuccess, },
        rollback : { type: 'PILE_GROUP_REQUEST_FAILED', },
      },
    },
  }),
  updateBurnPermitPileGroup        : [ 'BurnPermitPileGroup', ],
  updateBurnPermitPileGroupRequest : (BurnPermitPileGroup, showSuccess) => ({
    type : 'UPDATE_BURN_PERMIT_PILE_GROUP_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/${BurnPermitPileGroup.BurnPermitPileGroupId}/ForPermit/${BurnPermitPileGroup.BurnPermitId}`,
          init : { method: 'PUT', body: { ...BurnPermitPileGroup, }, },
        },
        commit   : { type: 'UPDATE_BURN_PERMIT_PILE_GROUP_SUCCESS', showSuccess, },
        rollback : { type: 'PILE_GROUP_REQUEST_FAILED', },
      },
    },
  }),
  updateBurnPermitPileGroupSuccess : [ 'payload', ],
  deleteteBurnPermitPileGroup      : [ 'BurnPermitPileGroupId', ],
  deleteBurnPermitPileGroupRequest : (BurnPermitPileGroupId, permitId, showSuccess) => ({
    type : 'DELETE_BURN_PERMIT_PILE_GROUP_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/${BurnPermitPileGroupId}/ForPermit/${permitId}`,
          init : { method: 'DELETE', },
        },
        commit   : { type: 'DELETE_BURN_PERMIT_PILE_GROUP_SUCCESS', BurnPermitPileGroupId, showSuccess, },
        rollback : { type: 'PILE_GROUP_REQUEST_FAILED', },
      },
    },
  }),
  deleteBurnPermitPileGroupSuccess : [ 'payload', ],
  estimateConsumedTonnage          : [ 'burnPermitId', ],
  addLocalPileGroup                : null,
  editBurnPermitPileGroup          : [ 'BurnPermitPileGroupId', ],
  deletePileGroup                  : [ 'BurnPermitPileGroupId', ],
  deleteLocalPileGroups            : null,
  pileGroupRequestFailed           : [ 'failed', ],
  uploadBatchPiles                 : [ 'permitId', 'files', ],
  deleteAllPileGroups              : [ 'permitId', ],
})

export const BurnPermitPileGroupTypes = Types
export default Creators

export const INITIAL_STATE = {
  EditBurnPermitPileGroupId : '',
  RequestFailed             : false,
}

const pileGroupRequestFailed = (state, { failed, }) => {
  return { ...state, RequestFailed: typeof failed === 'boolean' ? failed : true, }
}

const editBurnPermitPileGroup = (state, { BurnPermitPileGroupId, }) => {
  return {
    ...state,
    EditBurnPermitPileGroupId : Number.isInteger(BurnPermitPileGroupId) && BurnPermitPileGroupId > 0 ? BurnPermitPileGroupId : '',
    RequestFailed             : false,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PILE_GROUP_REQUEST_FAILED]   : pileGroupRequestFailed,
  [Types.EDIT_BURN_PERMIT_PILE_GROUP] : editBurnPermitPileGroup,
})