import {
  createReducer,
  createActions,
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  fileUploading       : [ 'isUploading ', ],
  setSuccessfulUpload : [ 'successful', ],
  resetFileUploading  : null,
})

export const FileTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  uploading        : false,
  successfulUpload : false,
}

/* ------------- Reducers ------------- */

export const resetFileUploading = () => {
  return { ...INITIAL_STATE, }
}

export const fileUploading = (state, { isUploading, }) => {
  const newState = Object.assign({}, state)
  newState.uploading = isUploading || false
  return newState
}

export const setSuccessfulUpload = (state, { successful, }) => {
  const newState = Object.assign({}, state)
  newState.successfulUpload = successful || false
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FILE_UPLOADING]        : fileUploading,
  [Types.SET_SUCCESSFUL_UPLOAD] : setSuccessfulUpload,
  [Types.RESET_FILE_UPLOADING]  : resetFileUploading,
})
