// Libraries
import { fork, put, call, all, select, takeEvery, takeLatest, } from 'redux-saga/effects'
import { difference, } from 'lodash'

// Sagas
import { hideLoading, showLoading, } from './AppSagas'
import { fetchAgencyData, getAllAgencies, } from './AgencySagas'
import { fetchAndCheck, getLookupData, doFetch, showError, } from './ApiSagas'
import { createLocalModel, destroyLocalModel, destroyLocalModels, upsertLocalModel, replaceAll, serverHasNewerData, upsertLocalModels, } from './OrmSagas'
import { GetAllRegions, } from './RegionSagas'
import { downloadBurnPermitSignatures, } from './BurnPermitSignatureSagas'

// Reducers
import { OrmTypes, destroyRange, } from '../redux/OrmRedux'
import { AgencyTypes, } from '../redux/AgencyRedux'
import { AppTypes, } from '../redux/AppRedux'
import { UiTypes, } from '../redux/UiRedux'
import { UserTypes, } from '../redux/UserRedux'
import { PersonTypes, } from '../redux/PersonRedux'
import { MergeTypes, } from '../redux/MergeRedux'

// Selectors
import { getLatestDateByModelName, modelByIdSelector, } from '../selectors/modelSelectors'
import { userObjSelector, userNameSelector, userPersonIdSelector, authPropsSelector, } from '../selectors/userSelectors'
import {
  getPersonTypeIdByName,
  personIsDnrSelector,
  personIsAgentOrAgencyPerson,
  agentPersonTypeIds,
  personPhoneSelector,
  personAddressesSelector,
} from '../selectors/personSelectors'
import { 
  storedAgentIds, 
  storedLandownerIds, 
  checkedLookupDataSelector, 
  storedPersonIds,
  networkStateSelector,
  getDnrUsersForSelect,
} from '../selectors/selectors'
import { DNR_GROUP_SIDS, } from '../selectors/env'

// Utilities
import { objHasProp, } from '../utilities'

// Models
import Address from '../models/Address'
import Phone from '../models/Phone'
import Person from '../models/Person'
import BurnPermit from '../models/BurnPermit'
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnRequest from '../models/BurnRequest'
import BurnRequestSearch from '../models/BurnRequestSearch'
import { userCanCreateNewPeople, userCanViewAppUserData, } from '../selectors/permissionSelectors'

// CONSTANTS
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const ADDRESS_MODEL_NAME = Address.modelName
const PHONE_MODEL_NAME = Phone.modelName
const PERSON_MODEL_NAME = Person.modelName
const DNR_ENDPOINT = Person.endpoint({ dnrOnly: true, })
const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName
const BURN_REQUEST_ENDPOINT = BurnRequest.endpoint()
const BURN_REQUEST_SEARCH_MODEL_NAME = BurnRequestSearch.modelName

export function* getPersonLookupData () {
  yield all([
    call(getLookupData, { modelName: 'PersonType', }),
    call(getLookupData, { modelName: 'ContactMethod', }),
    call(getLookupData, { modelName: 'AlertMethod', }),
    call(getLookupData, { modelName: 'AlertPreference', }),
  ])
}


/**
 * Update the local database to reflect the server state of Application Users
 */
export function* applicationUsersSuccess (users) {
  try {
    if (!users) {
      return
    }
    if (Array.isArray(users)) {
      const hasNewData = yield call(serverHasNewerData, 'ApplicationUser', null, users)
      if (!hasNewData) {
        return
      }
      yield put({
        type      : OrmTypes.UPSERT_RANGE,
        modelName : 'ApplicationUser',
        records   : users,
      })
    }
    else if (users !== null) {
      const hasNewData = yield call(serverHasNewerData, 'ApplicationUser', { PersonId: users.PersonId, }, users)
      if (!hasNewData) {
        return
      }
      yield put({
        type       : OrmTypes.UPSERT,
        modelName  : 'ApplicationUser',
        properties : users,
      })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Retrieve all DNR Users and update the local database to reflect the server state
 */
export function* getAssignableUsers () {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    // If there is only one user record locally, it's the current users
    // so we want to force check with the server
    const dnrUserCount = yield select(getDnrUsersForSelect)
    const forceCheck = dnrUserCount.length === 1
    // If it's not though, get the latest date for the DNR Users
    if (!forceCheck) {
      // build filter func for getLatestDateByModelName to filter on the DNR ClaimValue
      const dnrGroups = DNR_GROUP_SIDS.map(g => g.toLowerCase())
      const dnrUsersFilter = u => dnrGroups.includes(u.ClaimValue.toLowerCase())
      const date = yield select(getLatestDateByModelName, 'ApplicationUser', dnrUsersFilter)
      if (date) {
        const concurrencyResp = yield call(doFetch, `${DNR_ENDPOINT}/IsConcurrent?date=${date}`)
        if (concurrencyResp.ok && concurrencyResp.responseBody === true) {
          return
        }
      }
    }

    const resp = yield call(doFetch, DNR_ENDPOINT)
    if (!resp.ok) {
      yield call(showError, resp.responseBody)
    }
    yield call(applicationUsersSuccess, resp.responseBody)
  }
}

function* checkPeopleConcurrencyByType (personType, forceCheck = false, forceRefreshFromServer = false, basicInfo = false) {
  try {
    yield call(showLoading)
    const checkedData = yield select(checkedLookupDataSelector)
    const modelHasBeenChecked = checkedData.some(l => l === personType)
    if (modelHasBeenChecked && forceCheck === false) {
      return true
    }
  
    let endpoint = `${REACT_APP_SERVER_URL}People`
    if (forceRefreshFromServer === false) {
      let filterObj
  
      if (personType !== PERSON_MODEL_NAME) {
        const personTypeId = yield select(getPersonTypeIdByName, personType)
        filterObj = { PersonTypeId: personTypeId, }
      }
  
      const latestPersonDate = yield select(getLatestDateByModelName, PERSON_MODEL_NAME, filterObj)
  
      if (latestPersonDate) {
        const concurrencyEndpoint = personType === 'Agent' ? `${endpoint}/Agents` : endpoint
        const concurrencyResponse = yield call(doFetch, `${concurrencyEndpoint}/IsConcurrent?date=${latestPersonDate}${personType !== 'Agent' ? '&landownersOnly=true' : ''}`)
        
        const { responseBody: isConcurrent, } = concurrencyResponse
        if (isConcurrent) {
          return isConcurrent
        }
      }
    }
  
    yield fork(getPersonLookupData)
  
    if (personType === 'Private') {
      endpoint += '/Landowners'
    }
    else if (personType === 'Agent') {
      endpoint += '/Agents'
    }

    if (basicInfo !== null) {
      endpoint += `?basicInfo=${basicInfo}`
    }
  
    const response = yield call(doFetch, endpoint)
  
    if (response.ok !== true) {
      const error = response.responseBody || `Could not retrieve ${personType} users`
      yield call(showError, error)
      return
    }
  
    const { responseBody, } = response
  
    let storedPeople = []
    if (personType === 'Private') {
      storedPeople = yield select(storedLandownerIds)
    }
    else if (personType === 'Agent') {
      storedPeople = yield select(storedAgentIds)
    }
    else {
      storedPeople = yield select(storedPersonIds)
    }
    
    const incomingPersonIds = responseBody.map(r => r.PersonId)
    const peopleToDelete = difference(storedPeople, incomingPersonIds)
    if (peopleToDelete.length) {
      yield call(destroyRange, PERSON_MODEL_NAME, peopleToDelete)
    }
  
    yield call(upsertLocalModels, PERSON_MODEL_NAME, responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Retrieve all People and update the local database to reflect the server state
 */
export function* getAllPeople ({ forceRefreshFromServer = false, }) {
  yield call(getAllAgencies)
  const forceCheck = true
  yield call(checkPeopleConcurrencyByType, PERSON_MODEL_NAME, forceCheck, forceRefreshFromServer)
}

/**
 * Retrieve all Landowners and update the local database to reflect the server state
 */
export function* getAllLandowners (forceRefreshFromServer = false, basicInfo) {
  yield fork(checkPeopleConcurrencyByType, 'Private', false, forceRefreshFromServer, basicInfo)
}

/**
 * Retrieve all Agents and update the local database to reflect the server state
 */
export function* getAllAgents (forceRefreshFromServer = false, basicInfo) {
  yield fork(checkPeopleConcurrencyByType, 'Agent', false, forceRefreshFromServer, basicInfo)
}

export function* newPerson () {
  const username = yield select(userNameSelector)
  const newPerson = yield call(createLocalModel, PERSON_MODEL_NAME, { IsUser: false, CreateBy: username, CreateDate: new Date(), })
  yield put({
    type     : PersonTypes.ACTIVE_PERSON_ID,
    personId : newPerson.PersonId,
  })
}

export function* createPerson ({ person, email, }) {
  const user = yield select(userObjSelector)
  if (!user) {
    yield call(showError, 'Failed creating Person.')
    return
  }

  const canCreate = yield select(userCanCreateNewPeople)
  if (!canCreate) {
    yield call(showError, 'Failed creating Person.')
    return
  }

  try {
    if (Number.isInteger(person.PersonId)) {
      yield put({
        type      : OrmTypes.DESTROY,
        modelName : PERSON_MODEL_NAME,
        modelId   : person.PersonId,
      })
    }
    const personEndpoint = REACT_APP_SERVER_URL + 'People'
    const formattedPerson = {
      PersonFirstName  : person.PersonFirstName,
      PersonMiddleName : person.PersonMiddleName,
      PersonLastName   : person.PersonLastName,
      PersonTypeId     : person.PersonTypeId,
      ContactMethodId  : person.ContactMethodId,
      AlertMethodId    : person.AlertMethodId,
    }

    if (email && email.EmailAddress) {
      formattedPerson.Email = {
        EmailAddress: email.EmailAddress,
      }
    }

    const request = { method: 'POST', body: formattedPerson, }

    const { response, statuscode, } = yield call(fetchAndCheck, personEndpoint, request)
    
    if (statuscode > 201) {
      return
    }

    if (!response || isNaN(response.PersonId) || response.PersonId < 1) {
      yield call(showError, 'Bad response received from create Person endpoint.')
      return
    }
  
    yield put({ type: AppTypes.REDIRECT_TO, route: `/admin/people/${response.PersonId}`, })
  }
  catch (err) {
    yield call(showError, 'Failed creating Person.')
  }
  finally {
    yield call(hideLoading)
  }
}

export function* getAgentInfo ({ personId, }) {
  yield call(showLoading)
  yield all([
    call(getAgent, { personId, }),
    call(getAgentEmail, { personId, }),
    call(getAgentPhones, { personId, }),
    call(getAgentAddresses, { personId, }),
  ])
  yield call(hideLoading)
}


export function* updatePerson ({ person, }) {

  yield call(showLoading)

  const personEndpoint = `${REACT_APP_SERVER_URL}People/${person.PersonId}`
  
  const request = { method: 'PUT', body: person, }
  
  try {
    let { statuscode, } = yield call(fetchAndCheck, personEndpoint, request)

    if (statuscode !== 204) {
      return
    }

    const personId = person.PersonId
    yield fork(getPerson, { personId, })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


export function* mergePeople ({ person, mergedPersonId, }) {

  yield call(showLoading)

  const personEndpoint = `${REACT_APP_SERVER_URL}People/${person.PersonId}/Merge`
  
  const request = { method: 'PUT', body: { Person: person, MergedPersonId: mergedPersonId, }, }
  
  try {
    const { statuscode, } = yield call(fetchAndCheck, personEndpoint, request)

    if (statuscode !== 204) {
      // we do not need to show a failure toast here as the `fetchAndCheck` saga does that for us
      return
    }

    yield all([
      put({ type: AppTypes.SHOW_SUCCESS, }),
      // Destroy the local copy of both people that were merged as well as their related data
      call(destroyLocalModel, PERSON_MODEL_NAME, mergedPersonId),
      call(destroyLocalModel, PERSON_MODEL_NAME, person.PersonId),
      call(destroyLocalModels, 'PersonPhones', { fromPersonId: person.PersonId, }),
      call(destroyLocalModels, 'PersonPhones', { fromPersonId: mergedPersonId, }),
      call(destroyLocalModels, 'PersonAddresses', { fromPersonId: person.PersonId, }),
      call(destroyLocalModels, 'PersonAddresses', { fromPersonId: mergedPersonId, }),
      call(destroyLocalModels, 'PersonAgencyXref', { PersonAgencyXrefPersonId: person.PersonId, }),
      call(destroyLocalModels, 'PersonAgencyXref', { PersonAgencyXrefPersonId: mergedPersonId, }),
      call(destroyLocalModels, 'PersonAlertPreferenceXref', { PersonId: person.PersonId, }),
      call(destroyLocalModels, 'PersonAlertPreferenceXref', { PersonId: mergedPersonId, }),
    ])
    yield put({ type: AppTypes.REDIRECT_TO, route: `/admin/people/${person.PersonId}`, })
  }
  catch (error) {
    yield call(showError, 'Failed merging Person.')
  }
  finally {
    yield call(hideLoading)
  }
}

export function* convertPersonToAgency ({ personId, }) {

  yield put({ type: UiTypes.CLOSE_MODAL, })
  yield call(showLoading)
  
  try {
    const { statuscode, response, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}People/${personId}/ConvertToAgency`, { method: 'POST', })

    if (statuscode !== 201) {
      // we do not need to show a failure toast here as the `fetchAndCheck` saga does that for us
      return
    }

    yield put({ type: AppTypes.REDIRECT_TO, route: `/admin/agencies/${response.AgencyId}`, })
  }
  catch (error) {
    yield call(showError, 'Failed converting Person to Agency.')
  }
  finally {
    yield call(hideLoading)
  }
}


/**
 * Get an Agent Person and related information
 * @param {number} personId - The ID of the Agent Person to request
 */
export function* getAgent ({ personId, }) {
  // can't do anything without a person id
  if (!personId || personId < 1) {
    return
  }

  try {
    const { res, response, statuscode, } = yield call(fetchPerson, personId, true)
    if (statuscode !== 200) {
      return
    }

    // if response is null, stop here
    if (!response) {
      yield call(showError, 'Did not receive a response for Agent ' + personId)
      return
    }

    const hasNewData = yield call(serverHasNewerData, PERSON_MODEL_NAME, { PersonId: personId, EmailId: response.EmailId, }, response)
    if (hasNewData) {
      yield call(upsertLocalModel, PERSON_MODEL_NAME, response)
    }
    return res
  }
  catch (e) {
    yield call(showError, 'An error occurred requesting Agent ' + personId)
    return
  }
}

/**
 * Get a person and related information
 * @param {number} action.personId - The ID of the Person to request
 */
export function* getPerson ({ personId, permitAndRequestSearch, includeLookupData = true, }) {
  permitAndRequestSearch = {
    permits       : false,
    requests      : false,
    clearPrevious : false,
    ...(permitAndRequestSearch || {}),
  }
  const { online, } = yield select(networkStateSelector)
  if (!online) {
    return true
  }

  // can't do anything without a person id
  if (!personId || personId < 1) {
    return
  }

  // get response body or throw
  let personResponse = null, res
  try {
    const result = yield call(fetchPerson, personId)
    res = result.res
    personResponse = result.response
    if (result.statuscode !== 200) {
      return
    }
  }
  catch (e) {
    yield call(showError, 'An error occurred requesting Person ' + personId)
    return
  }

  const reqs = []
  if (includeLookupData === true) {
    // do this synchronously to make sure we have person types
    reqs.push(call(getPersonLookupData))
  }

  if (Object.values(permitAndRequestSearch).some(v => v === true)) {
    if (permitAndRequestSearch.clearPrevious) {
      yield all([
        call(replaceAll, BURN_PERMIT_SEARCH_MODEL_NAME, []),
        call(replaceAll, BURN_REQUEST_SEARCH_MODEL_NAME, []),
      ])
    }
    if (permitAndRequestSearch.permits) {
      reqs.push(call(getPersonBurnPermits, { personId, }))
    }
    if (permitAndRequestSearch.requests) {
      reqs.push(call(getPersonBurnRequests, { personId, }))
    }
  }
  yield all(reqs)

  const canViewUserData = yield select(userCanViewAppUserData)
  if (canViewUserData) {
    if (personResponse.IsUser) {
      const userResp = yield call(doFetch, Person.endpoint({ personId, }))
      if (userResp.ok) {
        yield call(applicationUsersSuccess, userResp.responseBody)
      }
    }
  }

  const personAgencyXrefs = personResponse.PersonAgencyXrefs

  let isVerifiedAgent = false
  const isCurrentUser = (yield select(userPersonIdSelector)) === personId
  const personTypeId = personResponse ? personResponse.PersonTypeId : -1
  const agentPersonTypes = yield select(agentPersonTypeIds)
  if (agentPersonTypes.includes(personTypeId)) {
    isVerifiedAgent = personAgencyXrefs.some((x) => x.ConfirmedBy || x.ConfirmedDate)
  }

  const authProps = yield select(authPropsSelector)
  if (authProps.isVerifiedAgent !== isVerifiedAgent && isCurrentUser) {
    yield put({ type: UserTypes.UPDATE_USER_AGENCY_STATUS, isVerifiedAgent, })
  }

  if (Array.isArray(personAgencyXrefs) && (personAgencyXrefs.length > 0)) {
    try {
      const hasNewData = yield call(serverHasNewerData, 'PersonAgencyXref', { PersonAgencyXrefPersonId: personId, }, personAgencyXrefs)
      if (hasNewData) {
        yield call(replaceAll, 'PersonAgencyXref', personAgencyXrefs, { PersonAgencyXrefPersonId: personId, })
      }
      // Only request data if the current user is verified, otherwise
      // their requests will be rejected
      if ((canViewUserData || isVerifiedAgent) && personAgencyXrefs.length === 1 && personAgencyXrefs[0]) {
        const agencyId = personAgencyXrefs[0].PersonAgencyXrefAgencyId
        if (!isNaN(agencyId)) {
          yield call(fetchAgencyData, { agencyId, basicInfo: false, })
        }
      }
    }
    catch (e) {
      console.error(e)
      yield call(showError, 'An error occurred updating Person Agency information.')
      return
    }
  }
  else if (yield select(personIsAgentOrAgencyPerson, personId)) {
    yield call(destroyLocalModels, 'PersonAgencyXref', { PersonAgencyXrefPersonId: personId, })
    if (isCurrentUser) {
      yield put({ type: UserTypes.UPDATE_USER_AGENCY_STATUS, isVerifiedAgent: false, })
    }
  }

  yield call(handleEmail, personResponse.EmailId)

  const hasNewPersonData = yield call(serverHasNewerData, PERSON_MODEL_NAME, { PersonId: personId, EmailId: personResponse.EmailId, }, personResponse)
  if (hasNewPersonData) {
    // Delete the property since the agency xrefs are fetched above and handled separately
    // and also because they do not match the property names defined in Person.js
    delete personResponse.PersonAgencyXrefs
    
    yield call(upsertLocalModel, PERSON_MODEL_NAME, personResponse)
  }

  if (includeLookupData !== true) {
    return res
  }

  yield all([
    call(getLookupData, { modelName: 'AddressType', }),
    call(getLookupData, { modelName: 'PhoneType', }),
    call(getPersonAddresses, { personId, }),
    call(getPersonPhones, { personId, }),
  ])
  // Only req person regions if current user is DNR
  const personIsDnr = yield select(personIsDnrSelector, personId)
  if (personIsDnr) {
    yield all([
      call(GetAllRegions),
      call(getPersonRegions, personId),
    ])
  }
  return res
}

export function* setPersonRegionInfo ({ personId, regionId, makesDecisions, }) {
  if (!regionId || !personId || makesDecisions === null || makesDecisions === undefined) {
    yield call(showError, 'Cannot set person region information without required parameters')
    return
  }
  let endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Regions`
  let request = {
    method : 'POST',
    body   : {
      PersonRegionXrefPersonId      : personId,
      PersonRegionXrefRegionId      : regionId,
      MakesSmokeManagementDecisions : makesDecisions,
    },
  }
  
  try {
    const { response, statuscode, } = yield call(fetchAndCheck, endpoint, request)
    if (statuscode !== 201) {
      return
    }

    yield put({
      type       : OrmTypes.UPSERT,
      properties : response,
      modelName  : 'PersonRegionXref',
    })
  }
  catch (error) {
    yield call(showError, 'Setting person Region Information failed.')
  }
}

export function* updatePersonRegionInfo ({ personId, regionId, makesDecisions, }) {
  if (!regionId || !personId || makesDecisions === null || makesDecisions === undefined) {
    yield call(showError, 'Cannot update person region information without required parameters')
    return
  }
  let endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Regions`
  let request = {
    method : 'PUT',
    body   : {
      PersonRegionXrefPersonId      : personId,
      PersonRegionXrefRegionId      : regionId,
      MakesSmokeManagementDecisions : makesDecisions,
    },
  }
  
  try {
    const { statuscode, } = yield call(fetchAndCheck, endpoint, request)
    if (statuscode !== 204) {
      return
    }
  }
  catch (error) {
    yield call(showError, 'Updating Region Information failed.')
    return
  }
  
  try {
    const { response, statuscode, } = yield call(fetchAndCheck, endpoint)
    if (statuscode !== 200) {
      return
    }
    
    yield put({
      type       : OrmTypes.UPSERT,
      properties : response,
      modelName  : 'PersonRegionXref',
    })
  }
  catch (e) {
    yield call(showError, 'Failed retrieving current region information.')
    return
  }
}

export function* getAgentPhones ({ personId, }) {
  yield call(getPersonPhones, { personId, isAgent: true, })
}

function* getPersonPhones ({ personId, isAgent, }) {
  if (!personId || isNaN(personId) || personId < 1) {
    yield call(showError, 'Cannot get Phone Numbers for Person without a Person Id.')
    return
  }

  let endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Phones`
  if (isAgent === true) {
    endpoint += '/Agent'
  }
  
  try {
    // do the api call
    const { response, statuscode, } = yield call(fetchAndCheck, endpoint)
    if (!response || statuscode !== 200) {
      return
    }

    if (response.length === 0) {
      const localPhones = yield select(personPhoneSelector, personId)
      if (localPhones.length) {
        const localPhoneIds = localPhones.map(p => p.PhoneId)
        yield call(destroyLocalModels, PHONE_MODEL_NAME, p => localPhoneIds.includes(p.PhoneId))
      }
      return
    }

    const phoneIds = response.map(r => r.PhoneId)
    const phoneIdFilter = a => { return phoneIds.includes(a.PhoneId) }
    const hasNewData = yield call(serverHasNewerData, PHONE_MODEL_NAME, phoneIdFilter, response)
    if (hasNewData) {
      yield call(replaceAll, PHONE_MODEL_NAME, response, phoneIdFilter)
    }
    // ensure the xrefs map up tho since they don't carry any audit data
    const xrefs = response.map(r => { return { fromPersonId: personId, toPhoneId: r.PhoneId, } })
    yield call(replaceAll, 'PersonPhones', xrefs, { fromPersonId: personId, })
  }
  catch (error) {
    yield call(showError, 'Get phones for person failed.')
  }
}

export function* getAgentAddresses ({ personId, }) {
  yield call(getPersonAddresses, { personId, isAgent: true, })
}

function* getPersonAddresses ({ personId, isAgent, }) {
  if (!personId || isNaN(personId) || personId < 1) {
    yield call(showError, 'Cannot get Addresses for Person without a Person Id.')
    return
  }

  let endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Addresses`
  if (isAgent === true) {
    endpoint += '/Agent'
  }
  
  try {
    // do the api call
    const { response, statuscode, } = yield call(fetchAndCheck, endpoint)
    if (!response || statuscode !== 200) {
      return
    }

    if (response.length === 0) {
      const localAddrs = yield select(personAddressesSelector, personId)
      if (localAddrs.length) {
        const localAddrIds = localAddrs.map(a => a.AddressId)
        yield call(destroyLocalModels, ADDRESS_MODEL_NAME, a => localAddrIds.includes(a.AddressId))
      }
      return
    }
  
    const addrIds = response.map(r => r.AddressId)
    const addrIdFilter = a => { return addrIds.includes(a.AddressId) }
    const hasNewData = yield call(serverHasNewerData, ADDRESS_MODEL_NAME, addrIdFilter, response)
    if (hasNewData) {
      yield call(replaceAll, ADDRESS_MODEL_NAME, response, addrIdFilter)
    }
    // ensure the xrefs map up tho since they don't carry any audit data
    const xrefs = response.map(r => { return { fromPersonId: personId, toAddressId: r.AddressId, } })
    yield call(replaceAll, 'PersonAddresses', xrefs, { fromPersonId: personId, })
  }
  catch (error) {
    yield call(showError, 'Get addresses for person failed.')
  }
}

function* getPersonRegions (personId) {
  if (!personId || isNaN(personId) || personId < 1) {
    yield call(showError, 'Cannot get Region for Person without a valid Person Id.')
    return
  }

  try {
    const { response, statuscode, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}People/${personId}/Regions`)
    if (!response || response.length === 0 || statuscode !== 200) {
      yield call(destroyLocalModels, 'PersonRegionXref', { PersonRegionXrefPersonId: personId, })
      return
    }
    let regionXrefs = response
    if (!Array.isArray(regionXrefs)) {
      regionXrefs = [ response, ]
    }
    
    const filter = { PersonRegionXrefPersonId: personId, }
    const hasNewData = yield call(serverHasNewerData, 'PersonRegionXref', filter, regionXrefs)
    if (hasNewData) {
      yield call(replaceAll, 'PersonRegionXref', regionXrefs, filter)
    }
  }
  catch (error) {
    yield call(showError, 'Get Region for person failed.')
  }
}

export function* getPersonDataForMerge ({ personId, }) {
  yield call(showLoading)
  yield put({ type: MergeTypes.FETCHING_DATA_FOR_MERGE, isFetchingData: true, })
  yield call(getPerson, { personId, permitAndRequestSearch: { permits: true, requests: true, }, })
  yield put({ type: MergeTypes.FETCHING_DATA_FOR_MERGE, isFetchingData: false, })
  yield call(hideLoading)
}

export function* deletePersonPhone ({ personId, phoneId, }) {
  if (!personId || isNaN(personId) || personId < 1) {
    yield call(showError, 'Cannot delete Phone Number for Person without a Person Id.')
    return
  }
  if (!phoneId || isNaN(phoneId) || phoneId < 1) {
    yield call(showError, 'Cannot delete Phone Number for Person without a Phone Number Id.')
    return
  }

  try {
    // check if local model is tagged with IsLocal
    // if it is, do no submit call to api
    const phone = yield select(modelByIdSelector, { modelName: PHONE_MODEL_NAME, modelId: phoneId, })
    if (phone && !phone.IsLocal) {
      const endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Phones/${phoneId}`
      const { statuscode, } = yield call(fetchAndCheck, endpoint, { method: 'DELETE', })
      if (statuscode !== 200) {
        return
      }
    }
    yield call(destroyLocalModel, PHONE_MODEL_NAME, phoneId)
  }
  catch (error) {
    const errorMessage = objHasProp(error, 'message') ? error.message : 'Phone Number delete failed.'
    yield call(showError, errorMessage)
    return
  }
}

export function* deletePersonAddress ({ personId, addressId, }) {
  if (!personId || isNaN(personId) || personId < 1) {
    yield call(showError, 'Cannot delete Address for Person without a Person Id.')
    return
  }
  if (isNaN(addressId)) {
    yield call(showError, 'Cannot delete Address for Person without an Address Id.')
    return
  }
  
  try {
    // check if local model is tagged with IsLocal
    // if it is, do no submit call to api
    const address = yield select(modelByIdSelector, { modelName: ADDRESS_MODEL_NAME, modelId: addressId, })
    if (address && !address.IsLocal) {
      const endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Addresses/${addressId}`
      const { statuscode, } = yield call(fetchAndCheck, endpoint, { method: 'DELETE', })
      if (statuscode !== 200) {
        return
      }
    }

    yield call(destroyLocalModel, ADDRESS_MODEL_NAME, addressId)
  }
  catch (error) {
    const errorMessage = objHasProp(error, 'message') ? error.message : 'Address delete failed.'
    yield call(showError, errorMessage)
    return
  }
}

export function* createLocalPhoneForPerson ({ personId, }) {
  const newPhone = yield call(createLocalModel, PHONE_MODEL_NAME)
  yield call(createLocalModel, 'PersonPhones', { fromPersonId: personId, toPhoneId: newPhone.PhoneId, })
}

export function* createPersonPhone ({ personId, phone, }) {
  if (!phone) {
    yield call(showError, 'Cannot create Phone Number for Person without a Phone Number object.')
    return
  }
  if (!personId) {
    yield call(showError, 'Cannot create Phone Number for Person without a Person Id.')
    return
  }

  const endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Phones`
  let createdPhone
  try {
    let res = yield call(fetchAndCheck, endpoint, { method: 'POST', body: phone, })
    if (res.statuscode !== 201) {
      return
    }
    createdPhone = res.response
  }
  catch (error) {
    yield call(showError, 'Phone Number creation failed.')
    return
  }

  if (!createdPhone || !createdPhone.PersonPhoneXrefId) {
    yield call(showError, 'Error in Phone Number creation: did not receive an Phone Number Xref.')
    return
  }
  
  const { PhoneId, } = phone
  if (!isNaN(PhoneId)) {
    yield call(destroyLocalModel, PHONE_MODEL_NAME, PhoneId)
  }

  yield call(upsertLocalModel, PHONE_MODEL_NAME, createdPhone.PersonPhoneXrefPhone)

  yield call(upsertLocalModel, 'PersonPhones', { fromPersonId: personId, toPhoneId: createdPhone.PersonPhoneXrefPhoneId, })
}

export function* createLocalAddressForPerson ({ personId, }) {
  const newAddress = yield call(createLocalModel, ADDRESS_MODEL_NAME)
  yield call(createLocalModel, 'PersonAddresses', { fromPersonId: personId, toAddressId: newAddress.AddressId, })
}

export function* createPersonAddress ({ personId, address, }) {
  if (!address) {
    yield call(showError, 'Cannot create Address for Person without an Address object.')
    return
  }
  if (!personId) {
    yield call(showError, 'Cannot create Address for Person without a Person Id.')
    return
  }

  const endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Addresses`
  let createdAddress
  try {
    let res = yield call(fetchAndCheck, endpoint, { method: 'POST', body: address, })
    if (res.statuscode !== 201) {
      return
    }
    createdAddress = res.response
  }
  catch (error) {
    yield call(showError, 'Address creation failed.')
    return
  }

  if (!createdAddress || !createdAddress.PersonAddressXrefId) {
    yield call(showError, 'Error in Address creation: did not receive an Address Xref.')
    return
  }

  const { AddressId, } = address
  if (!isNaN(AddressId)) {
    yield call(destroyLocalModel, ADDRESS_MODEL_NAME, AddressId)
  }

  yield call(upsertLocalModel, ADDRESS_MODEL_NAME, createdAddress.PersonAddressXrefAddress)

  yield call(upsertLocalModel, 'PersonAddresses', { fromPersonId: personId, toAddressId: createdAddress.PersonAddressXrefAddressId, })
}

export function* createPersonEmail ({ personId, email, }) {
  const personEmailEndpoint = `${REACT_APP_SERVER_URL}People/${personId}/Email`
  
  const request = { method: 'POST', body: JSON.stringify(email), }
  let response = null
  try {
    let res = yield call(fetchAndCheck, personEmailEndpoint, request)
    if (res.statuscode !== 201) {
      return
    }
    response = res.response
  }
  catch (error) {
    yield call(showError, 'Failed creating Person Email.')
    return
  }
  yield call(upsertLocalModel, 'Email', response)
  yield call(upsertLocalModel, 'Person', { PersonId: personId, EmailId: response.EmailId, })
  
  if (!response || isNaN(response.EmailId) || response.EmailId < 1) {
    yield call(showError, 'Bad response recieved from create Person Email endpoint.')
    return
  }
  return
}

export function* deletePersonEmail ({ personId, }) {
  const personEmailEndpoint = `${REACT_APP_SERVER_URL}People/${personId}/Email`
  let response = null
  
  try {
    let res = yield call(fetchAndCheck, personEmailEndpoint, { method: 'DELETE', })
    if (res.statuscode !== 200) {
      return
    }
    response = res.response
  }
  catch (error) {
    yield call(showError, 'Deleting Person Email failed.')
    return
  }

  if (!response) {
    yield call(showError, 'Bad response received from delete Person Email endpoint.')
    return
  }

  const { success, deletedId, } = response
  if (success === false) {
    yield call(showError, 'Deleting Person Email failed.')
    return
  } 
  yield call(destroyLocalModel, 'Email', deletedId)
}

/**
 * Get and upsert the Person Agent Email Address
 * @param {number} personId - The ID of the Person Agent to request the email for
 */
export function* getAgentEmail ({ personId, }) {
  // quick exit
  if (!personId) {
    return
  }
  // set up request
  const endpoint = `${REACT_APP_SERVER_URL}People/${personId}/Email/Agent`
  
  const res = yield call(fetchAndCheck, endpoint)
  const { response, statuscode, } = res

  if (statuscode !== 200 && statuscode !== 204) {
    return
  }

  if (response) {
    const hasNewData = yield call(serverHasNewerData, 'Email', { EmailId: response.EmailId, }, response)
    if (hasNewData) {
      yield call(upsertLocalModel, 'Email', response)
    }
  }
}

/**
 * Get and upsert the email address
 * @param {number} emailId - The ID of the Email to request
 */
function* handleEmail (emailId) {
  // quick exit
  if (!emailId) {
    return
  }
  // set up request
  const endpoint = REACT_APP_SERVER_URL + 'Emails/' + emailId
  // do the request
  const { response, statuscode, } = yield call(fetchAndCheck, endpoint)
  
  if (statuscode !== 200) {
    return
  }

  if (response) {
    const hasNewData = yield call(serverHasNewerData, 'Email', { EmailId: emailId, }, response)
    if (hasNewData) {
      yield call(upsertLocalModel, 'Email', response)
    }
  }
}

/**
 * Helper to fetch an Person/Agent by id
 * @param {int} personId 
 * @returns {Object} The fetch response
 */
function* fetchPerson (personId, isAgent) {
  // set up the person request
  let endpoint = REACT_APP_SERVER_URL + 'People/' + personId
  if (isAgent === true) {
    endpoint += '/Agent'
  }

  // get response body or throw
  return yield call(fetchAndCheck, endpoint)
}

/**
 * Retrieve all Burn Permits the Person is the Landowner or Agent of
 */
function* getPersonBurnPermits ({ personId, }) {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    const [
      landownerPermits,
      agentPermits,
    ] = yield all([
      call(doFetch, `${BURN_PERMIT_ENDPOINT}/Search?LandownerId=${personId}`),
      call(doFetch, `${BURN_PERMIT_ENDPOINT}/Search?AgentId=${personId}`),
      call(downloadBurnPermitSignatures, { personId, }),
      call(getPersonsPermitDocuments, { personId, }),
    ])
    let permits = []
    if (landownerPermits.ok && Array.isArray(landownerPermits.responseBody)) {
      permits = [ ...landownerPermits.responseBody, ]
    }
    if (agentPermits.ok && Array.isArray(agentPermits.responseBody)) {
      permits = [ ...permits, ...agentPermits.responseBody, ]
    }
    yield call(upsertLocalModels, BURN_PERMIT_SEARCH_MODEL_NAME, [ ...permits, ])
  }
}

/**
 * Retrieve all Burn Requests the Person is the Landowner or Agent of
 */
function* getPersonBurnRequests ({ personId, }) {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    const [
      landownerRequests,
      agentRequests,
    ] = yield all([
      call(doFetch, `${BURN_REQUEST_ENDPOINT}/Search?LandownerId=${personId}`),
      call(doFetch, `${BURN_REQUEST_ENDPOINT}/Search?AgentId=${personId}`),
    ])
    let requests = []
    if (landownerRequests.ok) {
      requests = [ ...landownerRequests.responseBody, ]
    }
    if (agentRequests.ok) {
      requests = [ ...requests, ...agentRequests.responseBody, ]
    }
    yield call(upsertLocalModels, BURN_REQUEST_SEARCH_MODEL_NAME, [ ...requests, ])
  }
}

function* getDataForPeopleMerge () {
  yield all([
    call(getAllPeople, { forceRefreshFromServer: true, }),
    call(getLookupData, { modelName: 'BurnPermitDocumentType', }),
    put({ type: MergeTypes.SET_OBJECT_TWO_ID, }), // Clear out the second object ID
  ])
}

function* getPersonsPermitDocuments ({ personId, }) {
  try {
    yield call(showLoading)

    if (!personId) {
      yield call(showError, 'You must supply a Person ID to retrieve documents for.')
      return
    }
    const url = `${BURN_PERMIT_ENDPOINT}/DownloadDocuments?personId=${personId}`

    const fileResp = yield call(doFetch, url)
    const { responseBody, } = fileResp
    if (fileResp.ok !== true) {
      let error = 'An error occurred retrieving files for the Person\'s Permit Applications'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    if (responseBody.length) {
      yield call(upsertLocalModels, 'BurnPermitDocument', responseBody)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* checkPersonBurnRequests ({ personId = null, }) {
  const { online, } = yield select(networkStateSelector)
  if (!online) {
    return true
  }
  let endpoint = REACT_APP_SERVER_URL + 'BurnRequests/Unsubmitted/' + personId
  const resp = yield call(fetchAndCheck, endpoint)
  const action = { type: PersonTypes.SET_UNSUBMITTED_REQUESTS, }
  if (resp.response && resp.response.length > 0) {
    action.requests = resp.response
  } else {
    action.requests = null
  }
  yield put(action)
}


export const PersonSagas = [
  takeLatest(PersonTypes.NEW_PERSON, newPerson),
  takeLatest(PersonTypes.APPLICATION_USERS_SUCCESS, applicationUsersSuccess),
  takeLatest(PersonTypes.GET_ALL_PEOPLE, getAllPeople),
  takeLatest(PersonTypes.GET_ALL_LANDOWNERS, getAllLandowners),
  takeLatest(PersonTypes.GET_ALL_AGENTS, getAllAgents),
  takeLatest(PersonTypes.GET_PERSON, getPerson),
  takeLatest(PersonTypes.GET_LANDOWNER_INFO, getPerson),
  takeLatest(PersonTypes.GET_AGENT_INFO, getAgentInfo),
  takeLatest(PersonTypes.GET_AGENT_EMAIL, getAgentEmail),
  takeLatest(PersonTypes.GET_AGENT_ADDRESSES, getAgentAddresses),
  takeLatest(PersonTypes.GET_AGENT_PHONES, getAgentPhones),
  takeLatest(PersonTypes.GET_PERSON_LOOKUP_DATA, getPersonLookupData),
  takeLatest(PersonTypes.GET_ASSIGNABLE_USERS, getAssignableUsers),
  takeLatest(PersonTypes.GET_DATA_FOR_PEOPLE_MERGE, getDataForPeopleMerge),
  takeLatest(PersonTypes.GET_PERSON_DATA_FOR_MERGE, getPersonDataForMerge),
  takeLatest(AgencyTypes.CONVERT_PERSON_TO_AGENCY, convertPersonToAgency),
  takeLatest(PersonTypes.GET_UNSUBMITTED_REQUESTS, checkPersonBurnRequests),

  takeEvery(PersonTypes.GET_PERSON_DATA_FOR_MERGE, getPersonDataForMerge),
  takeEvery(PersonTypes.CREATE_PERSON, createPerson),
  takeEvery(PersonTypes.CREATE_LOCAL_ADDRESS_FOR_PERSON, createLocalAddressForPerson),
  takeEvery(PersonTypes.DELETE_PERSON_ADDRESS, deletePersonAddress),
  takeEvery(PersonTypes.CREATE_PERSON_ADDRESS, createPersonAddress),
  takeEvery(PersonTypes.DELETE_PERSON_PHONE, deletePersonPhone),
  takeEvery(PersonTypes.CREATE_LOCAL_PHONE_FOR_PERSON, createLocalPhoneForPerson),
  takeEvery(PersonTypes.CREATE_PERSON_PHONE, createPersonPhone),
  takeEvery(PersonTypes.CREATE_PERSON_EMAIL, createPersonEmail),
  takeEvery(PersonTypes.DELETE_PERSON_EMAIL, deletePersonEmail),
  takeEvery(PersonTypes.UPDATE_PERSON, updatePerson),
  takeEvery(PersonTypes.MERGE_PEOPLE, mergePeople),
  takeEvery(PersonTypes.UPDATE_PERSON_REGION, updatePersonRegionInfo),
  takeEvery(PersonTypes.SET_PERSON_REGION, setPersonRegionInfo),
]
