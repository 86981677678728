import { attr, fk, Model, } from 'redux-orm'

export default class PersonRegionXref extends Model {
  static get modelName () {
    return 'PersonRegionXref'
  }

  toString () {
    return `${this.PersonRegionXrefId}`
  }
}

PersonRegionXref.options = {
  idAttribute: 'PersonRegionXrefId',
}

PersonRegionXref.fields = {
  // Common Properties
  PersonRegionXrefId : attr(),
  CreateDate         : attr(),
  CreateBy           : attr(),
  UpdateDate         : attr(),
  UpdateBy           : attr(),

  // Model-Specific Properties
  RegionDefaultAssignee: attr(),
  
  // Relationships
  PersonRegionXrefRegionId: fk({ 
    to          : 'Region', 
    as          : 'PersonRegionXrefRegion', 
    relatedName : 'PersonRegionXrefs', 
  }),
  PersonRegionXrefPersonId: fk({ 
    to          : 'Person', 
    as          : 'PersonRegionXrefPerson', 
    relatedName : 'PersonRegionXrefs', 
  }),
}