// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { array, func, bool, } from 'prop-types'
import { Container, Card, CardBody, Row, Col, } from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'
import AgencyActions from '../redux/AgencyRedux'

// Selectors
import { agenciesForTableSelector, } from '../selectors/agencySelectors'

// Components
import DataTable from '../components/DataTable'

// Models
import Agency from '../models/Agency'


const DATATABLE_COLS = Agency.getDataTablesColumns()

export class AgencyListContainer extends React.Component {
  static propTypes = {
    SetPageTitle   : func,
    GetAllAgencies : func,
    agencies       : array,
    isDnr          : bool,
    online         : bool,
  }

  componentDidMount () {
    const { SetPageTitle, GetAllAgencies, } = this.props

    SetPageTitle('Agencies')
    GetAllAgencies()
  }

  render () {
    return <Container className={'mt-3'}>
      <Row className={'mt-3'}>
        <Col xs={12} md={3} lg={6}>
          <h1 className={'m-0'}>Agencies</h1>
        </Col>
        <Col xs={12} md={9} lg={6} className={'d-inline-flex align-items-center justify-content-between'}>
          { this.props.isDnr && <Link className={'btn btn-link btn-md'} to={'/admin/agencies/merge'}>Merge Agencies<span className={'fa fa-users-cog ml-2'} style={{ fontSize: '0.8em', }}></span></Link>}
          <Link className={'btn btn-success btn-md'} to={'/admin/agencies/new'}>New Agency<span className={'fa fa-plus ml-2'} style={{ fontSize: '0.8em', }}></span></Link>
        </Col>
      </Row>
      <Row className={'mt-1 mb-3'}>
        <Card className={'w-100'}>
          <CardBody className={'p-2'}>
            <DataTable
              columns={DATATABLE_COLS}
              records={this.props.agencies}
              elementId={'agnecies-table'}
              rowIdProp={'AgencyId'}
            />
          </CardBody>
        </Card>
      </Row>
    </Container>
  }
}

const mapStateToProps = (state) => {
  const { online, } = state.offline
  return {
    online,
    agencies: agenciesForTableSelector(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle   : AppActions.setPageTitle,
  GetAllAgencies : AgencyActions.getAllAgencies,
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyListContainer)