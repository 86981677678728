// Libraries
import React, { useCallback, useState, } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardText,
} from 'reactstrap'
import { func, } from 'prop-types'

// Components
import NoPermitModal from './NoPermitModal'
import ImprovedPropertyCard from './ImprovedPropertyCard'
import UnimprovedPropertyCard from './UnimprovedPropertyCard'


const DoYouNeedABurnPermit = props => {
  const { yesCallback, } = props

  // State
  const [ isModalVisible, setIsModalVisible, ] = useState(false)
  const [ improvedVisible, setImprovedVisible, ] = useState(false)
  const [ unimprovedVisible, setUnimprovedVisible, ] = useState(false)

  // UI Callbacks
  const isUnimprovedProperty = useCallback(() => {
    if (typeof yesCallback === 'function') {
      yesCallback()
    }
  }, [ yesCallback, ])

  const isImprovedProperty = useCallback(() => setIsModalVisible(true), [])

  const showImproved = useCallback(() => setImprovedVisible(true), [])
  const showUnimproved = useCallback(() => setUnimprovedVisible(true), [])
  const back = useCallback(() => {
    setImprovedVisible(false)
    setUnimprovedVisible(false)
  }, [])

  let markup = null
  if (!improvedVisible && !unimprovedVisible) {
    markup = <>
      <h1>Do you need a Burn Permit from us?</h1>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardText>
                Department of Natural Resources issues Burn Permits for individuals or companies that have 
                collected or harvested brush, timber, etc. from unimproved properties; such as grasslands 
                and forests.
              </CardText>
              <CardText>
                If you are not sure if the material you need to burn is on a property that is improved or 
                unimproved, click the buttons below to review examples of each.
              </CardText>
              <div className={'d-flex justify-content-between mt-2'}>
                <Button
                  color={'primary'}
                  onClick={showImproved}
                >Improved</Button>
                <Button
                  color={'primary'}
                  onClick={showUnimproved}
                >Unimproved</Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={'d-flex justify-content-between mt-2'}>
            <Button
              className={'mr-3'}
              color={'light'}
              onClick={isImprovedProperty}
            >No</Button>
            <Button
              color={'secondary'}
              onClick={isUnimprovedProperty}
            >Yes</Button>
          </div>
          <NoPermitModal isOpen={isModalVisible} />
        </Col>
      </Row>
    </>
  }
  else if (improvedVisible) {
    markup = <ImprovedPropertyCard />
  }
  else if (unimprovedVisible) {
    markup = <UnimprovedPropertyCard />
  }

  if (improvedVisible || unimprovedVisible) {
    markup = <>
      {markup}
      <Button className={'my-3'} onClick={back}>Back</Button>
    </>
  }

  return <Container className={'pt-2 py-4'}>
    {markup}
  </Container>
}

DoYouNeedABurnPermit.propTypes = {
  yesCallback: func,
}

export default React.memo(DoYouNeedABurnPermit)