import React, { useCallback, useEffect, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'
import { userIsAuthenticated, } from '../../../selectors/userSelectors'

// Reducers
import UserActions from '../../../redux/UserRedux'


const Logout = () => {
  // Selectors
  const online = useSelector(appIsOnlineSelector)
  const isAuthenticated = useSelector(userIsAuthenticated)

  // Redux actions
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // UI Callbacks
  const logout = useCallback(() => {
    dispatch(UserActions.logout())
    // todo
    // close this menu item and close the navbar
  }, [ dispatch, ])

  // mount effect
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/')
    }
  }, [ navigate, isAuthenticated, ])

  const markup = <Link
    to={'/Logout'}
    className={'nav-link'}
    onClick={logout}
    disabled={!online}
    title={'Log out of the Burn Portal'}
  >
    Logout
  </Link>
  return markup
}

export default React.memo(Logout)
