// Libraries
import React, { useCallback, useMemo, useState, } from 'react'
import { Alert, Button, Col, Input, Label, Row, } from 'reactstrap'
import { useDispatch, } from 'react-redux'
import { bool, } from 'prop-types'

// Components
import ConditionsWarning from './ConditionsWarning'

// Actions
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'

// Utilities
import stopEvent from '../../utilities/stopEvent'


const DocuSignSteps = props => {
  const {
    isPreviewing,
    isIssuing,
    isReissuing,
  } = props

  // State
  const [ doNotShow, setDoNotShow, ] = useState(false)
  const [ stepIdx, setStepIdx, ] = useState(0)

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks

  const processType = useMemo(() => {
    if (isIssuing) {
      return {
        type    : SignatureProcessTypes.issue,
        btnText : 'Issue Permit',
      }
    }
    else if (isReissuing) {
      return {
        type    : SignatureProcessTypes.reissue,
        btnText : 'Reissue Permit',
      }
    }
    else if (isPreviewing) {
      return {
        type    : SignatureProcessTypes.preview,
        btnText : 'Preview Permit',
      }
    }
    return {
      type    : SignatureProcessTypes.application,
      btnText : 'sign',
    }
  }, [ isPreviewing, isIssuing, isReissuing, ])

  const doNotShowCheck = useCallback(() => {
    setDoNotShow(!doNotShow)
  }, [ doNotShow, ])

  const acknowledge = useCallback(e => {
    stopEvent(e)
    dispatch(BurnPermitSignatureActions.acknowledgeSignatureSteps(processType.type, true, doNotShow))
  }, [ dispatch, doNotShow, processType, ])

  const prevClick = useCallback(e => {
    stopEvent(e)
    setStepIdx(stepIdx - 1)
  }, [ setStepIdx, stepIdx, ])

  const nextClick = useCallback(e => {
    stopEvent(e)
    setStepIdx(stepIdx + 1)
  }, [ setStepIdx, stepIdx, ])

  let eSignatureSteps = useMemo(() => {
    if (isPreviewing) {
      return [
        <Col key={'burnportal-step-preview'}>
          <h4>Burn Portal</h4>
          <hr/>
          <div className={'mx-2'}>
            <p>Please read the brief description of the Preview Permit process.</p>
            <p>Once you have read all the steps, <Button size={'sm'} color={'info'}>acknowledge</Button> you read them and initiate the Burn Permit document preview process.</p>
            <p>If you wish to <b>Issue</b> the Burn Permit, use the <Button size={'sm'} color={'primary'}>issue permit</Button> button to generate the Burn Permit document and electronically sign it.</p>
            <p>Click the <Button size={'sm'} color={'light'}>{processType.btnText}</Button> button below to begin the Preview Permit process in DocuSign.</p>
          </div>
        </Col>,
        <Col key={'docusign-step-preview'}>
          <h4>DocuSign</h4>
          <hr/>
          <div className={'mx-2'}>
            <p>In DocuSign, review the document to ensure the information is correct.</p>
            <p>Click the <b>FINISH</b> button to have DocuSign send you back to the Burn Portal.</p>
          </div>
        </Col>,
        <Col key={'docusign-step-issue'}>
          <h4>Burn Portal</h4>
          <hr/>
          <div className={'mx-2'}>
            <p>Once you are back in the Burn Portal, correct any correctable information (i.e., Contacts, Conditions) and use the <Button size={'sm'} color={'light'}>{processType.btnText}</Button> process to review the information again if desired.</p>
            <p>If you are ready to Issue the Burn Permit and generate a document to electronically sign, set the <b>Valid Date</b> and Valid Duration then click the <Button size={'sm'} color={'primary'}>issue permit</Button> button.</p>
          </div>
        </Col>,
      ]
    }
    let steps = [
      <Col key={'docusign-step-review'}>
        <h4>Burn Portal</h4>
        <hr/>
        <div className={'mx-2'}>
          <p>Please read the brief description of the e-signature process.</p>
          <p>Once you have read all the steps, <Button size={'sm'} color={'info'}>acknowledge</Button> you read them to revewal the <Button size={'sm'} color={'primary'}>{processType.btnText}</Button> button.</p>
          <p>Click the button to begin the e-signature process in DocuSign.</p>
        </div>
      </Col>,
      <Col key={'docusign-step-sign'}>
        <h4>DocuSign</h4>
        <hr/>
        <div className={'mx-2'}>
          <p>In DocuSign, review the document to ensure the information is correct.</p>
          <p>If there are any discrepancies, click the <b>Actions</b> drop down then choose <b>Finish later</b> to be sent back to the Burn Portal.</p>
          <p>When you are ready to sign, click the <b>Sign</b> button to provide your e-signature.</p>
          <p>Click the <b>FINISH</b> button to submit your e-signature and wait for DocuSign to send you back to the Burn Portal.</p>
        </div>
      </Col>,
      <Col key={'docusign-step-confirmation'}>
        <h4>Burn Portal</h4>
        <hr/>
        <div className={'mx-2'}>
          <p>Once you are back in the Burn Portal, please wait for the system to confirm your signature information with DocuSign.</p>
          <p>After your signature is confirmed, you can continue using the Burn Portal for your remaining tasks.</p>
        </div>
      </Col>,
    ]
    if (isIssuing) {
      const issueStep = <Col key={'docusign-step-issue'}>
        <h4>Burn Portal</h4>
        <hr/>
        <div className={'mx-2'}>
          <p>Only initiate the Issue Permit process once you are confident all information on the Permit Application is correct and you are ready to generate and electronically sign the Burn Permit.</p>
          <Alert color={'warning'}>
            <b>You cannot undo your signature once you have signed it and any modifications to the Burn Permit will require the Burn Permit to be revoked and a new Permit Application created.</b>
          </Alert>
          <p>If you wish to review the information that will be written on to the Burn Permit document, use the <Button size={'sm'} color={'light'}>preview permit</Button> button to review the information in DocuSign without creating a final Burn Permit.</p>
        </div>
      </Col>
      steps.splice(1, 0, issueStep)
    }
    return steps
  }, [ isPreviewing, isIssuing, processType, ])

  const atLastStep = useMemo(() => stepIdx === eSignatureSteps.length - 1, [ stepIdx, eSignatureSteps, ])

  let pageControls = <>
    <Col>
      <Button
        onClick={prevClick}
        disabled={stepIdx === 0}
        color={'link'}
        size={'sm'}
        className={'h-100'}
      >
        <span className={'fa fa-chevron-left'} /> Previous
      </Button>
    </Col>
    <Col className={'d-flex justify-content-end'}>
      <Button
        onClick={nextClick}
        disabled={atLastStep}
        color={'link'}
        size={'sm'}
        className={'h-100'}
      >
        Next <span className={'fa fa-chevron-right'} />
      </Button>
    </Col>
  </>

  let acknowledgeControls = <></>
  if (atLastStep) {
    acknowledgeControls = <>
      <Col className={'d-flex justify-content-end'}>
        <Label check className={'mr-4 pt-1'}>
          <Input
            type={'checkbox'}
            onClick={doNotShowCheck}
          />
          Do Not Show Again
        </Label>
        <Button
          onClick={acknowledge}
          color={'info'}
          size={'sm'}
        >I acknowledge</Button>
      </Col>
    </>
  }

  const conditions = useMemo(() => {
    if (isPreviewing || isIssuing || isReissuing) {
      return <ConditionsWarning />
    }
    return null
  }, [ isPreviewing, isIssuing, isReissuing, ])

  return <>
    <Row className={'py-3'}>
      {eSignatureSteps[stepIdx]}
    </Row>
    <Row className={'justify-content-center'}>
      {conditions}
    </Row>
    <Row>
      {acknowledgeControls}
    </Row>
    <Row className={'mt-4'}>
      {pageControls}
    </Row>
  </>
}


DocuSignSteps.propTypes = {
  isPreviewing : bool,
  isIssuing    : bool,
  isReissuing  : bool,
}

export default React.memo(DocuSignSteps)
