import React, { useCallback, useEffect, useMemo, useState, } from 'react'
import { Link, } from 'react-router-dom'
import { useDispatch, useSelector, } from 'react-redux'
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  PopoverBody,
  PopoverHeader,
  Popover,
  Container,
} from 'reactstrap'

// Selectors
import { appVersionSelector, } from '../../selectors/appSelectors'
import { authPropsSelector, userNameSelector, userPersonIdSelector, } from '../../selectors/userSelectors'

// Components
import NetworkStatus from './../NetworkStatus'

// Hooks
import { useOnlineSelector, } from '../../hooks'
import useToggleNavbar from '../../hooks/useToggleNavbar'


import logo from '../../images/dnr_logo_white.png'


const {
  REACT_APP_SUPPORT_EMAIL,
  // eslint-disable-next-line no-undef
} = process.env

const Banner = () => {
  // State and refs
  const [ helpOpen, setHelpOpen, ] = useState(false)

  // Reducers
  const dispatch = useDispatch()

  // Selectors
  const AppVersion = useSelector(appVersionSelector)
  const online = useOnlineSelector()
  const authProps = useSelector(authPropsSelector)
  const userName = useSelector(userNameSelector)
  const userId = useSelector(userPersonIdSelector)


  // UI Callbacks
  const mailTo = useMemo(() => {
    const authInfo = {
      isAuthenticated : authProps.isAuthenticated,
      isAgent         : authProps.isAgent,
      isGovAgent      : authProps.isGovAgent,
      isDnr           : authProps.isDnr,
      userId          : userId,
      userName        : userName,
    }
    let mailToBody = 'Hello,\r\n\r\nI am trying to use the Burn Portal to...'
    mailToBody += `\r\n\r\n
PLEASE DO NOT DELETE THE INFORMATION BELOW THIS LINE
----------------------------------------------------------------------------------
Version ${AppVersion}
Date ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}
URL ${window.location.href}
Browser Agent ${window.navigator.userAgent}
User Info ${Object.entries(authInfo).reduce((s, e) => { return `${s}\r\n  ${e[0]}: ${e[1]}` }, '')}
`
    return `mailto:${REACT_APP_SUPPORT_EMAIL}?Subject=Burn Portal Help&Body=${encodeURIComponent(mailToBody)}`
  }, [ AppVersion, authProps, userId, userName, ])

  const contactSupportStyle = useMemo(() => ({ color: '#fff', position: 'absolute', top: 0, right: '1em', }), [])

  const navbarHref = useMemo(() => (online ? 'https://dnr.wa.gov' : '/'), [ online, ])

  const toggleHelp = useCallback(() => {
    setHelpOpen(!helpOpen)
  }, [ helpOpen, setHelpOpen, ])

  useEffect(() => {
    if (helpOpen) {
      setTimeout(function () {
        // Tried creating a passing a ref to the Popover component, but the
        // reactstrap library doesn't expose that interface
        const pop = document.getElementsByClassName('help-popover')
        if (pop && pop.length) {
          pop[0].getElementsByClassName('popover-body')[0].focus()
        }
      }, 500)
    }
  }, [ helpOpen, ])

  const [ ,toggleNavbar, ] = useToggleNavbar(dispatch)


  const markup = <Navbar
    id={'banner'}
    color={'dark'}
    expand={'lg'}
    title={'Burn Portal Header'}>
    <Container className={'h-100 position-relative'}>
      <Nav navbar className={'navbar-left'}>
        <NavItem>
          <NavbarBrand href={navbarHref}>
            <img
              src={logo}
              alt={'Washington Department of Natural Resources'}
              title={'Washington Department of Natural Resources'}
            />
          </NavbarBrand>
        </NavItem>
      </Nav>
      <Nav navbar className={'navbar-right'}>
        <NavbarToggler onClick={toggleNavbar} />
      </Nav>
      <NetworkStatus online={online} />
      <small style={contactSupportStyle}>
        <>
          <div
            id={'help-button'}
            className={'text-underline cursor-pointer d-inline'}
            onClick={toggleHelp}
            onKeyPress={toggleHelp}
            role={'button'}
            tabIndex={0}
          >
          Contact Support
          </div> | v{AppVersion}
          <Popover
            placement={'bottom'}
            target={'help-button'}
            isOpen={helpOpen}
            className={'help-popover'}
          >
            <PopoverHeader>Burn Portal Help</PopoverHeader>
            <PopoverBody tabIndex={0}>
              <p>If you are experiencing an issue with this website, please send an email to our support group by clicking this link: <a href={mailTo}>{REACT_APP_SUPPORT_EMAIL}</a></p>
              <p>If you have questions regarding how to use this website, please visit our <Link to={'/help'}>Help</Link> page for more information.</p>
            </PopoverBody>
          </Popover>
        </>
      </small>
    </Container>
  </Navbar>
  return markup
}

export default React.memo(Banner)
