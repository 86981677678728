import { attr, Model, } from 'redux-orm'

export default class SpeciesWoodDensity extends Model {
  static get modelName () {
    return 'SpeciesWoodDensity'
  }

  toString () {
    return `${this.CommonName} (${this.Density})`
  }

  static endpoint () {
    return 'SpeciesWoodDensities'
  }

  static get options () {
    return {
      idAttribute: 'SpeciesWoodDensityId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      SpeciesWoodDensityId : attr(),
      TSN                  : attr(),
      ScientificName       : attr(),
      CommonName           : attr(),
      Density              : attr(),
      CreateDate           : attr(),
      CreateBy             : attr(),
      UpdateDate           : attr(),
      UpdateBy             : attr(),
    }
  }
}