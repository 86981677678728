// Libraries
import React from 'react'
import { Formik, Field, FieldArray, } from 'formik'
import { Row, Col,FormGroup, Button, Input, Spinner, ButtonGroup, } from 'reactstrap'
import { bool, func, arrayOf, number, object, string, PropTypes, } from 'prop-types'
// Components
import { ValidatingField, } from '../FormControls'
import { LinkPreview, LinkEditablePreview, } from './LinkRuleForm'
import Rule from '../../models/Rule'

export const RuleForm = ({ canEdit, readOnly, rule, onToggleEdit, onSubmit, colSizes, updating, }) => {
  if (!rule) {
    return null
  }

  const buttonColor = readOnly ? 'secondary' : 'success'
  const buttonText = readOnly ? 'Edit' : 'Save'
  const valSchema = Rule.validationSchema(rule.RuleType, rule.RuleId)

  let ruleValueFieldType = 'text'

  if (rule.RuleType === 'number') {

    ruleValueFieldType = 'number'
  } else if (rule.RuleType === 'json-links') {
    ruleValueFieldType = 'json-links'
  } else {
    ruleValueFieldType = 'textarea'
  }

  const extraValueRow = false

  return <Formik
    initialValues={rule}
    validationSchema={valSchema}
    enableReinitialize={true}
    onSubmit={readOnly ? null : onSubmit}
    key={'rule-form-' + rule.RuleId}
  >
    {({ values, handleSubmit, handleReset, isValid, dirty, setFieldValue, }) => (
      <>
        <Row>
          <Col md={colSizes[0]}>
            <span style={{ float: 'left', verticalAlign: 'top', }} className={'mr-1'}> {rule.RuleId}</span>
            {canEdit ? 
              !readOnly && updating ? <><Spinner color={'primary'} />Saving...</> :
                <ButtonGroup vertical>
                  <Button color={buttonColor} size={'sm'} className={'w-100'} onClick={readOnly ? () => onToggleEdit(rule.RuleId, handleReset) : handleSubmit}>
                    {buttonText}
                  </Button>
                  {!readOnly && <Button color={'warning'} className={'w-100'} size={'sm'} onClick={() => onToggleEdit(rule.RuleId, handleReset)}>Cancel</Button>}
                </ButtonGroup> : null
            }
          </Col>
          <Col md={colSizes[1]}>
            {values.RuleName}
          </Col>
          <Col md={colSizes[2]}>
            {readOnly ? values.RuleEnabled ? 'Yes' : 'No' :
              <FormGroup check>
                <Field name={'RuleEnabled'}>
                  {({ field, }) => (<Input type={'checkbox'} {...field} disabled={readOnly} checked={field.value} aria-labelledby={'RuleEnabledLabel'}/>)}
                </Field>
              </FormGroup>
            }
          </Col>
          <Col md={extraValueRow ? colSizes[3] + colSizes[4] : colSizes[3]}>
            {readOnly ? values.RuleDescription :
              <Field name={'RuleDescription'} readOnly={readOnly} component={ValidatingField} type={'textarea'} className={'table-txtedit'} aria-labelledby={'RuleDescLabel'} />
            }
          </Col>
          <Col md={colSizes[4]}>
            <RuleFormValue value={values.RuleValue} type={ruleValueFieldType} readOnly={readOnly} isValid={isValid} dirty={dirty} setFieldValue={setFieldValue}/>
          </Col>
        </Row>
      </>
    )}
  </Formik>
}

RuleForm.propTypes = {
  canEdit      : bool,
  readOnly     : bool,
  rule         : object,
  onToggleEdit : func,
  onSubmit     : func,
  colSizes     : arrayOf(number),
  updating     : bool,
  updated      : bool,
  updateFailed : bool,
}

RuleForm.defaultProps = {
  canEdit      : false,
  readOnly     : false,
  rule         : null,
  onToggleEdit : () => { },
  onSubmit     : () => { },
  colSizes     : [ 1, 2, 2, 2, 2, ],
  updating     : false,
  updated      : false,
  updateFailed : false,
}



const RuleFormValue = ({ value, type, readOnly, additionalClasses, isValid, dirty, setFieldValue, }) => {
  let valueField = null
  if (readOnly) {
    if (type === 'text') {
      valueField = <p className={'table-p'}>{value}</p>
    } else if (type === 'number') {
      valueField = value
    } else if (type === 'json-links' && Array.isArray(value)) {
      valueField = <>{value.map((x, idx) => {
        return <p className={'table-p'} key={'lnk-p-' + idx}><LinkPreview {...x} key={'lnk-prv-' + idx} /></p>
      })}</>
    }
  } else if (type === 'json-links') {
    const listLen = value.length
    valueField = <FieldArray name={'RuleValue'}>
      {(arrayHelpers, anything) => (
        <>
          {value.map((val, idx) => {
            return <LinkEditablePreview 
              val={val} 
              value={value} 
              idx={idx} 
              arrayHelpers={arrayHelpers} 
              listLen={listLen}
              otherProps={anything}
              key={'rule-val-pair-' + idx}
              isValid={isValid}
              dirty={dirty}
              setFieldValue={setFieldValue}
            />
          })}
          <Button color={'success'} onClick={() => arrayHelpers.push({ Href: '', Text: '', isNew: true, })} size={'sm'} title={'Add Link'} alt={'Add Link'}>Add Link</Button>
        </>
      )}
    </FieldArray>
  } else {
    valueField = <FormGroup>
      <Field name={'RuleValue'} readOnly={readOnly} component={ValidatingField} type={type} className={additionalClasses} aria-labelledby={'RuleValueLabel'} />
    </FormGroup>
  }
  return valueField
}

RuleFormValue.propTypes = {
  value             : PropTypes.oneOfType([ object, PropTypes.arrayOf(object), number, string, ]),
  type              : string,
  readOnly          : bool,
  additionalClasses : string,
}

RuleFormValue.defaultProps = {
  value             : null,
  type              : '',
  readOnly          : false,
  additionalClasses : '',
}