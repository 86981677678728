// Libraries
import { put, select, } from 'redux-saga/effects'

// Reducers
import { AppTypes, } from '../redux/AppRedux'

// Selectors
import { showLoadingSelector, } from '../selectors/selectors'


export function* hideLoading () {
  const loadingBarIsShowing = yield select(showLoadingSelector)
  if (loadingBarIsShowing) {
    yield put({ type: AppTypes.HIDE_LOADING, })
  }
}

export function* showLoading () {
  const loadingBarIsShowing = yield select(showLoadingSelector)
  if (!loadingBarIsShowing) {
    yield put({ type: AppTypes.SHOW_LOADING, })
  }
}