import { attr, fk, many, Model, } from 'redux-orm'
import { number, } from 'prop-types'
import * as Yup from 'yup'
import { renderDate, } from '../utilities/columnHelpers'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnPermit extends Model {
  static get modelName () {
    return 'BurnPermit'
  }

  toString () {
    return `${this.BurnPermitNumber || this.BurnPermitId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermits`
  }

  toEmptyJson (includeId = true) {
    const obj = {}
    const _this = this.getClass()
    const attrs = Object.entries(_this.fields).filter((f) => {
      return f[1].constructor.name === 'Attribute' && (!includeId && f[0] !== _this.idAttribute)
    })
    for(let i = 0, len = attrs.length; i < len; i++){
      obj[attrs[i][0]] = null
    }
    return obj
  }

  static getValidationSchema () {
    return Yup.object().shape({
      AgentId               : Yup.number(),
      BurnerPermitAddressId : Yup.number()
        .when(
          'AgentId', {
            is: (a) => {
              return !a || (a && (a < 0))
            }, 
            then: Yup.number().min(1, 'Please select an address').required('Please select an address'),
          }
        ),
      BurnerPermitPrimaryPhoneId: Yup.number()
        .when(
          'AgentId', {
            is: (a) =>  {
              return !a || (a && (a < 0))
            }, 
            then: Yup.number().min(1, 'Please select a phone number').required('Please select a phone number'),
          }
        ),
    })
  }

  static getDataTablesColumns () {
    return [
      {
        title      : '',
        data       : 'BurnPermitId',
        orderable  : false,
        searchable : false,
        targets    : [ 0, ],
        width      : 15,
        render     : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return `<input class='add-permit-to-order' data-burn-permit-id='${data}' type='checkbox' />`
        },
      },
      {
        title  : 'Application Id',
        data   : 'BurnPermitId',
        type   : 'num',
        render : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return `<a href='/permits/${data}' data-burn-permit-id='${data}'>${data}</a>`
        },
      },
      {
        title          : 'Permit Number',
        data           : 'BurnPermitNumber',
        defaultContent : '',
      },
      {
        title          : 'Landowner',
        data           : 'LandownerName',
        defaultContent : '',
        width          : '150px',
      },
      {
        title          : 'Burner',
        data           : 'Burner',
        defaultContent : '',
        width          : '150px',
      },
      {
        title          : 'Application Status',
        data           : 'BurnPermitApplicationStatus.StatusName',
        defaultContent : '',
        width          : '75px',
      },
      {
        title          : 'Application Status Date',
        data           : 'BurnPermitApplicationStatus.StatusDate',
        defaultContent : '',
        render         : renderDate,
        width          : '75px',
      },
      {
        title          : 'Permit Status',
        data           : 'BurnPermitStatus.StatusName',
        defaultContent : '',
        width          : '75px',
      },
      {
        title          : 'Permit Status Date',
        data           : 'BurnPermitStatus.StatusDate',
        defaultContent : '',
        render         : renderDate,
        width          : '75px',
      },
      {
        title          : 'Expiration Date',
        data           : 'BurnPermitExpirationDate',
        defaultContent : '',
        render         : renderDate,
        width          : '75px',
      },
      {
        title          : 'County',
        data           : 'County',
        defaultContent : '',
      },
      {
        title          : 'Legal Description',
        data           : 'LegalDescription',
        defaultContent : '',
      },
      {
        title          : 'Burn Address',
        data           : 'BurnAddress',
        defaultContent : '',
        width          : '200px',
      },
      {
        title          : 'Total Tonnage',
        data           : 'TotalPermitTonnage',
        defaultContent : '',
      },
      {
        title          : 'Latitude',
        data           : 'Latitude',
        defaultContent : '',
        type           : 'num',
      },
      {
        title          : 'Longitude',
        data           : 'Longitude',
        defaultContent : '',
        type           : 'num',
      },
    ]
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      BurnPermitId : attr({ inputType: 'number', inputLabel: 'Burn Permit Id', }),
      CreateDate   : attr(),
      CreateBy     : attr(),
      UpdateDate   : attr(),
      UpdateBy     : attr(),

      // Model-Specific Properties
      BurnPermitExpirationDate  : attr(),
      BurnPermitEstimatedFee    : attr(),
      BurnPermitNumber          : attr(),
      BurnPermitValidDate       : attr(),
      MailPermitToAgentFlag     : attr(),
      MailPermitToLandownerFlag : attr(),
      TotalPermitTonnage        : attr(),
      SuspensionStartDate       : attr(),
      SuspensionEndDate         : attr(),
      SuspensionComment         : attr(),

      // Relationships
      AgencyId                     : fk({ to: 'Agency',             relatedName: 'AgencyPermit',         as: 'Agency', }),
      AgentId                      : fk({ to: 'Person',             relatedName: 'AgentPermit',          as: 'Agent', }),
      AgentPermitAddressId         : fk({ to: 'Address',            relatedName: 'AgentPermit',          as: 'AgentPermitAddress', }),
      AgentPermitPrimaryPhoneId    : fk({ to: 'Phone',              relatedName: 'AgentPermitPrimary',   as: 'AgentPermitPrimaryPhone', }),
      AgentPermitSecondaryPhoneId  : fk({ to: 'Phone',              relatedName: 'AgentPermitSecondary', as: 'AgentPermitSecondaryPhone', }),
      BurnerId                     : fk({ to: 'Person',             relatedName: 'BurnerPermit',         as: 'Burner', } ),
      BurnerPermitAddressId        : fk({ to: 'Address',            relatedName: 'BurnerPermit',         as: 'BurnerPermitAddress', }),
      BurnerPermitPrimaryPhoneId   : fk({ to: 'Phone',              relatedName: 'BurnPermitPrimary',    as: 'BurnerPermitPrimaryPhone', }),
      BurnerPermitSecondaryPhoneId : fk({ to: 'Phone',              relatedName: 'BurnPermitSecondary',  as: 'BurnerPermitSecondaryPhone', }),  
      BurnPermitAreaId             : fk({ to: 'BurnPermitArea',     relatedName: 'BurnPermits',          as: 'BurnPermitArea', }),
      BurnPermitFuelId             : fk({ to: 'BurnPermitFuel',     relatedName: 'BurnPermits',          as: 'BurnPermitFuel', }),
      BurnPermitLocationId         : fk({ to: 'BurnPermitLocation', relatedName: 'BurnPermits',          as: 'BurnPermitLocation', }),
      BurnPermitSiteId             : fk({ to: 'BurnPermitSite',     relatedName: 'BurnPermits',          as: 'BurnPermitSite', }),
      ForestHealthExemptId         : fk({ to: 'ForestHealthExempt', relatedName: 'BurnPermits',          as: 'ForestHealthExempt', }),

      BurnPermitApplicationStatuses : many({ to: 'BurnPermitApplicationStatus', relatedName: 'ApplicationStatuses', through: 'BurnPermitApplicationStatusXref', }),
      BurnPermitConditions          : many({ to: 'BurnPermitCondition',         relatedName: 'BurnPermits',         through: 'BurnPermitConditionXref', }),
      BurnPermitStatuses            : many({ to: 'BurnPermitStatus',            relatedName: 'PermitStatuses',      through: 'BurnPermitStatusXref', }),
    }
  }

  static get propTypes () {
    return {
      BurnPermitId: number,
    }
  }
}