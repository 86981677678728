import React from 'react'
import { Alert, } from 'reactstrap'


const AutoApproval = () => {
  const alert = <Alert
    color={'primary'}
    className={'w-100'}>
    <p key={'no-sig-req'}>
      No signature required. Click <b>Submit</b> below when you have completed the 
      application and you have uploaded a document outlining the Burn Reason.
    </p>
  </Alert>
  return alert
}

export default React.memo(AutoApproval)
