// Libraries
import React from 'react'
import { bool, string, number, PropTypes, } from 'prop-types'
import { Row, Col, Badge, } from 'reactstrap'

// Components
import { PopoverButton, } from '../FormControls'
import PermitFeePopover from '../PermitFeePopover'

const TonnageSection = props => {
  
  const { EstimatedFee, TotalPermitTonnage, online, } = props

  let estFee, totalTonnage = 0
  if (online) {
    if (EstimatedFee) {
      estFee = `$${EstimatedFee.toLocaleString()}`
    }
    if (TotalPermitTonnage) {
      totalTonnage = TotalPermitTonnage.toLocaleString()
    }
  }
  else {
    if (!EstimatedFee) {
      estFee = 'Fee calculation requires an internet connection'
    } 
    if (!TotalPermitTonnage) {
      totalTonnage = 'Tonnage calculation requires an internet connection'
    }
  }
  
  return <Row>
    <Col xs={12}>
      <div className={'d-block d-md-flex align-items-center justify-content-end h-100'}>
        <h3 className={'d-inline-block secondary m-0 mr-2'}>
          Tonnage:
          <Badge color={'light'} className={'ml-2'}>{totalTonnage}</Badge>
        </h3>
        <PopoverButton
          buttonClassName={'ml-2 p-0'}
          popoverHeader={'Permit Tonnage'}
          popoverBody={<p>Total estimated tons of consumed fuel from <b>the Pile Groups Estimated Consumed Fuel</b>, <b>broadcast</b>, and <b>natural fuel</b> loadings, if applicable.</p>}
        />
      </div>
    </Col>
    <Col xs={12}>
      <div className={'d-block d-md-flex align-items-center justify-content-end h-100'}>
        <h3 className={'d-inline-block secondary m-0 mr-2'}>
          Est. Fee:
          <Badge color={'light'} className={'ml-2'}>{(estFee || '$0.00')}</Badge>
        </h3>
        <PermitFeePopover buttonClassName={'p-0 ml-2'} />
      </div>
    </Col>
  </Row>
}

TonnageSection.propTypes = {
  EstimatedFee       : PropTypes.oneOfType([ number, string, ]),
  TotalPermitTonnage : PropTypes.oneOfType([ number, string, ]),
  totalTonnage       : string,
  showFee            : bool,
  online             : bool,
}

export default TonnageSection