// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { array, object, func, number, bool, } from 'prop-types'
import { Row, Col, Button, } from 'reactstrap'
import { Link, } from 'react-router-dom'

// Selectors
import { permitsByIds, } from '../../selectors/burnPermitSelectors'

// Utilities
import { currencyFormatter, } from '../../utilities'

// Components
import PermitFeePopover from '../PermitFeePopover'
import ExternalLink from '../ExternalLink'

class BurnPermitCartSummary extends React.Component {

  static propTypes = {
    OrderId               : number,
    permits               : array,
    order                 : object,
    RemovePermitFromOrder : func,
    ShowModal             : func,
    usePopover            : bool,
    orderHasPayment       : bool,
    BurnPermitOrderAmount : number,
  }

  static defaultProps = {
    usePopover: true,
  }
  
  render () {
    const { permits, usePopover, orderHasPayment, BurnPermitOrderAmount, } = this.props
    let totalEstFee = 0.0, showDelete = permits.length > 1, orderXref, landowner, agent, unitName
    let totalEstTonnage = 0.0
    return <Col className={'permit-cart-summary'}>
      <Row className={'mb-2 py-2 border-bottom'}>
        {
          orderHasPayment || !showDelete
            ? <Col xs={2}><b>Permit ID</b></Col>
            : <Col xs={2} className={'text-right'}><b>Permit ID</b></Col>
        }
        <Col xs={4}><b>Landowner / Agent</b></Col>
        <Col xs={2}><b>Unit</b></Col>
        <Col xs={2} className={'text-right'}><b>Tonnage</b></Col>
        <Col xs={2} className={'text-right'}><b>Est. Fee</b></Col>
      </Row>
      { 
        permits.map(p => {
          totalEstTonnage += p.TotalPermitTonnage
          totalEstFee += p.BurnPermitEstimatedFee
          orderXref = p.BurnPermitOrderXrefs.first()
          unitName = p.BurnPermitSite ? p.BurnPermitSite.BurnUnitName : ''
          if (orderXref) {
            if (orderXref.BurnUnitName && orderXref.BurnUnitName !== unitName) {
              unitName = orderXref.BurnUnitName
            }
            const burner = orderXref.Burner || orderXref.BurnPermit.Burner || p.Burner
            const agency = orderXref.Agency || orderXref.BurnPermit.Agency || p.Agency
            const permitAgent = orderXref.Agent || orderXref.BurnPermit.Agent || p.Agent
            if (burner) {
              landowner = `Landowner: ${(burner || '')}`
            }
            else if (agency) {
              landowner = `Landowner: ${(agency || '')}`
            }
            if (permitAgent) {
              agent = `Agent: ${(permitAgent || '')}`
            }
          }
          else {
            landowner = `Landowner: ${(p.Burner || p.Agency || '')}`
            agent = `Agent: ${(p.Agent || '')}`
          }
          return <Row key={p.BurnPermitId}>
            <Col xs={orderHasPayment ? 1 : 2} className={'d-flex justify-content-between py-2'}>
              {
                !orderHasPayment && showDelete &&
                  <Button
                    title={`Remove Permit #${p.BurnPermitId} from order`}
                    size={'sm'}
                    data-id={p.BurnPermitId}
                    onClick={this.props.RemovePermitFromOrder}
                    className={'fa fa-trash-alt'}
                    color={'danger'}
                    style={{ height: '1.75em', }}
                  />
              }
              <Link to={`/permits/${p.BurnPermitId}`} style={{ marginLeft: 'auto', }}>{p.BurnPermitId}</Link></Col>
            <Col xs={{ size: 4, offset: orderHasPayment ? 1 : 0, }} className={'pl-2 py-2'}>
              { landowner && <div>{landowner}</div> }
              { agent && <div>{agent}</div> }
            </Col>
            <Col xs={2} className={'pl-2 py-2'}><div>{unitName}</div></Col>
            <Col xs={2} className={'text-right py-2'}>{p.TotalPermitTonnage}</Col>
            <Col xs={2} className={'text-right py-2'}>{currencyFormatter.format(p.BurnPermitEstimatedFee)}</Col>
          </Row>
        })
      }
      <Row className={'mt-2 py-2 border-top text-right'}>
        <Col xs={{ size: 2, offset: 6, }} md={{ size: 1, offset: 7, }} lg={{ size: 1, offset: 8, }}>
          <b>Total</b>
        </Col>
        <Col xs={2} lg={{ size: 1, offset: 0, }}>
          <b>{totalEstTonnage.toLocaleString()}</b>
        </Col>
        <Col xs={2}>
          <b>{currencyFormatter.format(BurnPermitOrderAmount || totalEstFee)}</b>
        </Col>
      </Row>
      {
        Number.isFinite(BurnPermitOrderAmount) && totalEstFee !== BurnPermitOrderAmount
          ? <Row>
            <Col className={'text-right'}>
              <small>Why do these numbers not add up?</small>
              { usePopover
                ? <PermitFeePopover />
                : <small> Check out the <ExternalLink href={'https://www.dnr.wa.gov/publications/rp_burn_feesched.pdf'} text={' Fee Schedule'} />.</small>
              }
            </Col>
          </Row>
          : null
      }
    </Col>
  }
}

const mapStateToProps = (state, props) => {
  return { 
    permits: permitsByIds(state, props.BurnPermitIds),
  }
}

export default connect(mapStateToProps, null)(BurnPermitCartSummary)