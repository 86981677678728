export function permitAction (layerId) {
  const actionId = 'burn-permit'
  layerId = layerId || 'BurnPermits'
  return {
    id           : actionId,
    title        : 'Permit',
    className    : 'fa fa-link',
    customAction : evt => {
      if (evt.action.id === actionId) {
        for (let i = 0, len = evt.features.length; i < len; i++) {
          const feat = evt.features[i]
          if (feat.layer.id === layerId) {
            const { BurnPermitId, } = feat.attributes
            this.navigate(`/permits/${BurnPermitId}`)
            break
          }
        }
      }
    },
  }
}

export function burnRequestAction (layerId) {
  const actionId = 'burn-request'
  layerId = layerId || 'BurnRequests'
  return {
    id           : actionId,
    title        : 'Request',
    className    : 'fa fa-link',
    customAction : evt => {
      if (evt.action.id === actionId) {
        for (let i = 0, len = evt.features.length; i < len; i++) {
          const feat = evt.features[i]
          if (feat.layer.id === layerId) {
            const { BurnRequestId, } = feat.attributes
            this.navigate(`/burn-requests/${BurnRequestId}`)
            break
          }
        }
      }
    },
  }
}

export function customerInteractionAction (layerId, route, title = 'Customer Interaction') {
  if (!layerId) {
    throw new Error('No `layerId` provided for customerInteractionAction')
  }
  if (!route) {
    throw new Error('No `route` provided for customerInteractionAction')
  }
  const actionId = 'customer-interaction'
  return {
    id           : actionId,
    title,
    className    : 'fa fa-link',
    customAction : evt => {
      if (evt.action.id === actionId) {
        for (let i = 0, len = evt.features.length; i < len; i++) {
          const feat = evt.features[i]
          if (feat.layer.id === layerId) {
            const { CustomerInteractionId, } = feat.attributes
            this.navigate(`/${route}/${CustomerInteractionId}`)
            break
          }
        }
      }
    },
  }
}
