import React from 'react'


const RequiredIndicator = () => {
  return <span
    className={'pl-1 text-danger'}
    title={'You must set a value for this input'}
  >*</span>
}

export default React.memo(RequiredIndicator)
