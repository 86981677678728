// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { bool, } from 'prop-types'
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardBody,
  Alert,
  Fade,
  Input,
  ButtonGroup,
} from 'reactstrap'

// Reducers
import ApiActions from '../../redux/ApiRedux'
import AppActions from '../../redux/AppRedux'
import BurnRequestSearchActions from '../../redux/BurnRequestSearchRedux'

// Selectors
import { userCanSearchByAssignee, } from '../../selectors/permissionSelectors'
import { regionsForSelectSelector, } from '../../selectors/regionSelectors'
import {
  burnTypesCheckboxSelector,
  countySelectSelector,
} from '../../selectors/selectors'
import { burnRequestSearchDataSelector, burnRequestStatuses, } from '../../selectors/burnRequestSelectors'

// Components
import { AssignedStaff, AutoComplete, RowToggler, Select, }  from '../FormControls'
import { PermitNumber, SearchInput, DateRange, NumberRange, UGA, ForestHealth, } from '../SearchInputs'

// Utilities
import { INITIAL_SEARCH_PARAMS, } from '../../redux/BurnRequestSearchRedux'

// Hooks
import {
  useInputChange,
  useSearchLookupsFetch,
  useEnterPressHandler,
  useSearchDateClick,
  useRequestSearchActions,
  useAutoComplete,
} from './hooks'

const initialState = {
  searchParams: { 
    ...INITIAL_SEARCH_PARAMS,
  },
  datesValid: {
    BurnRequestDateValid     : true, 
    PlannedIgnitionDateValid : true,
  },
}

function initState (nextState = null) {
  if (nextState) {
    return { ...nextState, }
  }
  return { 
    ...initialState,  
    datesValid: { ...initialState.datesValid, },
  }
}

function searchFormReducer (state, action) {
  if (!action || !action.type) {
    return state
  }
  switch (action.type) {
  case 'INPUT_CHANGE': {
    if (!action.payload) {
      break
    }
    const nextParams = { ...state.searchParams, ...action.payload, }
    return { ...state, searchParams: nextParams, }
  }
  case 'RESET_PARAMS' : {
    return initState()
  }
  case 'SET_DATE_VALID' : {
    if (!action.payload) {
      break
    }
    const nextDatesValid = { ...state.datesValid, ...action.payload, }
    return { ...state, datesValid: nextDatesValid, }
  }
  default: 
    break
  }
  return state
}

const BurnRequestSearchForm = ({ 
  isAuthenticated,
}) => {
  const BurnTypes = useSelector(burnTypesCheckboxSelector)
  const Counties = useSelector(countySelectSelector)
  const Regions = useSelector(regionsForSelectSelector)
  const BurnRequestStatuses = useSelector(burnRequestStatuses)
  const canSearchByAssignee = useSelector(userCanSearchByAssignee)
  const { searchParams: savedSearchParams, searchWarning, showLocation, showMap, } = useSelector(burnRequestSearchDataSelector)
  const [ state, dispatch, ] = React.useReducer(searchFormReducer, {
    searchParams : savedSearchParams,
    datesValid   : { ...initialState.datesValid, },
  }, initState)
  const { searchParams, datesValid, } = state
  const reduxDispatch = useDispatch()
  const [ dispatchInputChange, onChange, onSelect, ] = useInputChange(dispatch)
  const onReset = React.useCallback(() => { dispatch({ type: 'RESET_PARAMS', }) }, [])
  const [ onToggleMap, toggleLocation, onResetClick, ] = useRequestSearchActions(reduxDispatch, onReset)
  const onRequestDateClick = useSearchDateClick(dispatchInputChange, 'BurnRequestStartDate', 'BurnRequestEndDate')
  const onIgnitionDateClick = useSearchDateClick(dispatchInputChange, 'PlannedIgnitionStartDate', 'PlannedIgnitionEndDate')

  const onAutoComplete = useCallback(e => {
    const { name, id, value, } = e.target
    dispatchInputChange({ [name]: value, [`${name}Id`]: id, })
  }, [ dispatchInputChange, ])

  const [ regionAutoChange, ] = useAutoComplete('Region', onAutoComplete)

  const [ countyAutoChange, ] = useAutoComplete('County', onAutoComplete)

  const [ assignedStaffChange, ] = useAutoComplete('AssignedTo', onAutoComplete)

  // hook to request lookup data on mount
  useSearchLookupsFetch(reduxDispatch, canSearchByAssignee)

  const dispatchValidChanges = React.useCallback((values) => {
    dispatch({ type: 'SET_DATE_VALID', payload: values, })
  }, [])

  /**
   * Search button handler, update AJAX data object with new values 
   */
  const onSearchClick = React.useCallback(() => {
    try {
      reduxDispatch(AppActions.showLoading())
      const { Region, RegionId, County, CountyId, } = searchParams
      const { BurnRequestDateValid, PlannedIgnitionDateValid, } = datesValid
      if (!!Region && !Number.isInteger(RegionId)) {
        reduxDispatch(ApiActions.failure('Invalid Region value. Please check your spelling and select a matching Region.'))
        reduxDispatch(AppActions.hideLoading())
        return
      }
      if (!!County && !Number.isInteger(CountyId)) {
        reduxDispatch(ApiActions.failure('Invalid County value. Please check your spelling and select a matching County.'))
        reduxDispatch(AppActions.hideLoading())
        return
      }
      if (!BurnRequestDateValid) {
        reduxDispatch(ApiActions.failure('Invalid Request Date Range value. Please check that your entries are formatted correctly.'))
        reduxDispatch(AppActions.hideLoading())
        return
      }
      if (!PlannedIgnitionDateValid) {
        reduxDispatch(ApiActions.failure('Invalid Planned Ignition Date Range value. Please check that your entries are formatted correctly.'))
        reduxDispatch(AppActions.hideLoading())
        return
      }
      reduxDispatch(BurnRequestSearchActions.searchBurnRequests(searchParams))
    } catch (err) {
      console.error('An error ocurred: ', err)
    }
  }, [ reduxDispatch, searchParams, datesValid, ])

  const handleKeyPress = useEnterPressHandler(onSearchClick)

  const dateRangeValidChange = React.useCallback((rangeName, isValid) => {
    if ((isValid === true) || ((isValid === false) && !!rangeName)) {
      const oldValid = datesValid[rangeName + 'Valid']
      if (oldValid !== isValid){
        let nextState = {}
        nextState[rangeName + 'Valid'] = isValid
        dispatchValidChanges(nextState)
      }
    }
  }, [ dispatchValidChanges, datesValid, ])

  return (
    <Card className={'w-100'}>
      <CardBody onKeyPress={handleKeyPress} className={'py-2 px-3'}>
        <Row>
          <Col sm={6} md={4} lg={2}>
            <FormGroup className={'mb-2'}>
              <SearchInput
                label={'Request ID'}
                name={'BurnRequestId'}
                value={searchParams.BurnRequestId}
                onChange={onChange}
                showPopover={true}
                popoverHeader={'Burn Request ID'}
                popoverBody={<>Use the <b>Burn Request ID</b> to find Burn Requests that are submitted or in review.</>}
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <FormGroup className={'mb-2'}>
              <PermitNumber
                value={searchParams.BurnPermitNumber}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
          {
            isAuthenticated && <>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Agent'}
                    name={'AgentName'}
                    onChange={onChange}
                    value={searchParams.AgentName}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Agency'}
                    name={'AgencyName'}
                    onChange={onChange}
                    value={searchParams.AgencyName}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Landowner'}
                    name={'LandownerName'}
                    onChange={onChange}
                    value={searchParams.LandownerName}
                  />
                </FormGroup>
              </Col>
            </>
          }
          <Col sm={6} md={4} lg={2}>
            <Select
              label={'Burn Type'}
              items={BurnTypes}
              propertyName={'BurnTypeId'}
              selectedValue={searchParams.BurnTypeId}
              onChange={onSelect}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <FormGroup>
              <Select
                label={'Request Status'}
                items={BurnRequestStatuses}
                propertyName={'BurnRequestStatusId'}
                onChange={onSelect}
                selectedValue={searchParams.BurnRequestStatusId || ''}
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <NumberRange
              label={'Acres'}
              minValue={searchParams.MinAcres}
              minName={'MinAcres'}
              maxValue={searchParams.MaxAcres}
              maxName={'MaxAcres'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <NumberRange
              label={'Tons'}
              minValue={searchParams.MinTons}
              minName={'MinTons'}
              maxValue={searchParams.MaxTons}
              maxName={'MaxTons'}
              onChange={onChange}
            />
          </Col>
          <Col sm={'6'} md={4} lg={'3'}>
            <FormGroup>
              <Label>Burn Request Date</Label>
              <DateRange
                from={searchParams.BurnRequestStartDate}
                to={searchParams.BurnRequestEndDate}
                onClick={onRequestDateClick}
                name={'BurnRequestDate'}
                setValid={dateRangeValidChange}
              />
            </FormGroup>
          </Col>
          <Col sm={'6'} md={4} lg={'3'}>
            <FormGroup>
              <Label>Planned Ignition Date</Label>
              <DateRange
                from={searchParams.PlannedIgnitionStartDate}
                to={searchParams.PlannedIgnitionEndDate}
                onClick={onIgnitionDateClick}
                name={'PlannedIgnitionDate'}
                setValid={dateRangeValidChange}
              />
            </FormGroup>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <FormGroup>
              <Label>Has Post Burn</Label>
              <br/>
              <ButtonGroup>
                <Button color={'light'} name={'HasPostBurn'} onClick={onChange} value={true} active={searchParams.HasPostBurn === 'true'}>Yes</Button>
                <Button color={'light'} name={'HasPostBurn'} onClick={onChange} value={false} active={searchParams.HasPostBurn === 'false'}>No</Button>
                <Button color={'light'} name={'HasPostBurn'} onClick={onChange} value={''} active={searchParams.HasPostBurn === ''}>All</Button>
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <FormGroup>
              <Label>Multi Day Burns</Label>
              <br/>
              <ButtonGroup>
                <Button color={'light'} name={'MultiDayBurns'} onClick={onChange} value={true} active={searchParams.MultiDayBurns === 'true'}>Yes</Button>
                <Button color={'light'} name={'MultiDayBurns'} onClick={onChange} value={false} active={searchParams.MultiDayBurns === 'false'}>No</Button>
                <Button color={'light'} name={'MultiDayBurns'} onClick={onChange} value={''} active={searchParams.MultiDayBurns === ''}>All</Button>
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <UGA IsUGA={searchParams.IsUGA} onChange={onChange} />
          </Col>
          <Col xs={6} md={4} xl={2}>
            <ForestHealth IsForestHealthExempt={searchParams.IsForestHealthExempt} onChange={onChange} />
          </Col>
          {
            canSearchByAssignee &&
              <Col sm={6} md={4} lg={2}>
                <AssignedStaff
                  value={searchParams.AssignedTo}
                  onChange={assignedStaffChange}
                />
              </Col>
          }
        </Row>
        <Row>
          <Col sm={'5'} md={'4'} lg={'3'} className={'mb-3'}>
            <RowToggler rowLabel={'Location Fields'} onClick={toggleLocation} show={!!showLocation}/>
          </Col>
        </Row>
        <Fade in={!!showLocation} style={{ height: showLocation ? 'auto' : '0', }}>
          <Row>
            <Col sm={6} md={4} lg={2}>
              <FormGroup>
                <SearchInput
                  label={'Unit Name'}
                  name={'UnitName'}
                  onChange={onChange}
                  value={searchParams.UnitName}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'2'}>
              <FormGroup>
                <Label>County</Label>
                <AutoComplete
                  items={Counties}
                  value={searchParams.County}
                  onChange={countyAutoChange}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'2'}>
              <FormGroup>
                <Label>Region</Label>
                <AutoComplete
                  items={Regions}
                  value={searchParams.Region}
                  onChange={regionAutoChange}
                  minCharactersToEnter={0}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'3'}>
              <FormGroup>
                <Label>Address</Label>
                <Input name={'Address'} onChange={onChange} value={searchParams.Address} placeholder={'123 Main St Spokane, WA 12345'} />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'3'}>
              <FormGroup>
                <Label>Legal Description</Label>
                <Input name={'LegalDesc'} onChange={onChange} value={searchParams.LegalDesc} placeholder={'S00 T00 R00 East'}/>
              </FormGroup>
            </Col>
          </Row>
        </Fade>
        <Row>
          <Col xs={6} md={2}>
            <Button outline color={'primary'} onClick={onResetClick}>Reset</Button>
          </Col>
          <Col xs={12} md={6} className={'search-warning'}>
            <Fade in={!!searchWarning}><Alert color={'warning'}>{searchWarning}</Alert></Fade>
          </Col>
          <Col xs={6} md={4}>
            <Button color={'light'} onClick={onToggleMap}>{showMap ? 'Hide' : 'Show'} Map</Button>
            <Button color={'primary'} onClick={onSearchClick} className={'float-right'}>Search</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

BurnRequestSearchForm.propTypes = {
  isAuthenticated: bool,
}

export default React.memo(BurnRequestSearchForm)
