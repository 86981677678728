/**
 * Utility function to preventDefault and stopPropagation if an Event object is provided
 * @param {MouseEvent<HTMLAnchorElement, MouseEvent>} evt 
 */
const stopEvent = (evt) => {
  if (evt) {
    if (typeof evt.preventDefault === 'function') {
      evt.preventDefault()
    }
    if (typeof evt.stopPropagation === 'function') {
      evt.stopPropagation()
    }
  }
}
export default stopEvent