// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { PropTypes, string, number, func, object, array, bool, } from 'prop-types'
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Alert,
} from 'reactstrap'
import { Formik, Form, Field, ErrorMessage, } from 'formik'
import * as Yup from 'yup'
import { isEqual, } from 'lodash'

// Redux
import BurnRequestDetailActions from '../redux/BurnRequestDetailRedux'
import PersonActions from '../redux/PersonRedux'
import UiActions from '../redux/UiRedux'

// Components
import DataTable from './DataTable'
import ConfirmationModal from './ConfirmationModal'
import { AssignedStaff, Select, } from './FormControls'

// Selectors
import {
  burnRequestStatuses,
  burnRequestStatus,
  burnRequestStatusHistory,
  burnRequestIsApproved,
  burnRequestInPersonsRegion,
  burnRequestRegion,
} from '../selectors/burnRequestSelectors'
import { userNameSelector, userPersonIdSelector, } from '../selectors/userSelectors'
import {
  userSeesInternalReqStatus,
  userCanAssignBurnRequest,
  userCanChangeRequestStatus,
  userMustEnterStatusComment,
  userIsDivisionApprover,
} from '../selectors/burnRequestPermissionSelectors'

// Utilities
import { renderDateTime, renderCheckIcon, } from '../utilities/columnHelpers'
import stopEvent from '../utilities/stopEvent'
import { dateFormatter, } from '../utilities'

const SUBMIT_REQUEST_REVIEW = 'SUBMIT_REQUEST_REVIEW'
const OVERRIDE_BURN_REQUEST_DECISION = 'OVERRIDE_BURN_REQUEST_DECISION'

class BurnRequestReview extends React.Component {
  static propTypes = {
    burnRequestId               : number.isRequired,
    GetAssignableUsers          : func,
    GetBurnRequestStatusHistory : func.isRequired,
    BurnRequestStatus           : object,
    BurnRequestStatuses         : array,
    BurnRequestStatusId         : PropTypes.oneOfType([ number, string, ]),
    BurnRequestStatusHistory    : array,
    SubmitRequestStatus         : func,
    DeleteRequestStatus         : func,
    ShowOverride                : bool,
    MakesSMDecisions            : bool,
    canChangeStatus             : bool,
    canAssignRequest            : bool,
    statusCommentRequired       : bool,
    showInternalStatus          : bool,
    isOpen                      : bool,
    userName                    : string,
    requestStatus               : object,
    hideForm                    : bool,
    online                      : bool,
    OpenModal                   : func,
    OverrideDecision            : func,
    BurnRequestRegionId         : number,
    isDivisionApprover          : bool,
  }

  state = {
    BurnRequestId       : this.props.burnRequestId,
    BurnRequestStatusId : this.props.BurnRequestStatusId || -1,
    InternalOnly        : false,
    Comment             : '',
    redraw              : false,
    modalState          : {
      isModalVisible : false,
      modalType      : '',
      modalData      : null,
    },
  }

  get columns () {
    const defs = [ 
      {
        title : 'Status',
        data  : 'Status',
      },
      {
        data   : 'StatusDate',
        title  : 'Status Date',
        render : renderDateTime,
      },
      {
        title          : 'Comment',
        data           : 'Comment',
        defaultContent : '',
      },
      {
        title          : 'Assigned To',
        data           : 'AssignedTo',
        defaultContent : '',
      }, 
      {
        title          : 'Submitted By',
        data           : 'CreateBy',
        defaultContent : '',
      }, 
    ]
    if (this.props.showInternalStatus) {
      defs.push({
        data           : 'InternalOnly',
        title          : 'Internal',
        width          : '10px',
        defaultContent : '',
        render         : renderCheckIcon,
      })
    }
    return defs
  }

  getValidationSchema = () => {
    if (this.props.statusCommentRequired) {
      return Yup.object().shape({ Comment: Yup.string().ensure().required('You must enter a comment.'), })
    }
    return Yup.object().shape({
      BurnRequestStatusId : Yup.number().min(1, 'You must choose an status.'),
      AssignedTo          : Yup.string().ensure().required('You must assign someone.'),
    })
  }

  getModalHeader = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    if (modalState.modalType === SUBMIT_REQUEST_REVIEW) {
      return 'Submit Request Review'
    }
  }

  getModalBody = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    if ((modalState.modalType === SUBMIT_REQUEST_REVIEW) && modalState.modalData) {
      const { modalData, } = modalState
      let msg, defaultComment, group = this.props.isDivisionApprover ? '[Division] ' : '[Region] '
      if (modalData.StatusName === 'Approved') {
        if (this.props.isDivisionApprover) {
          if (Number.isInteger(this.props.BurnRequestRegionId)) {
            msg = <>Region Users that make smoke management decisions will be alerted of the <b>approval</b>.</>
          }
          else {
            msg = <>No region can be found for this Permit. You must alert the Region yourself this have been Approved and they need to submit their review. Or close this modal, then make sure you assign one of the Region users so they get alerted.</>
          }
        }
        else if (this.props.MakesSMDecisions) {
          msg = <>The Burner will be <b>immediately alerted of the <i>approval</i></b>.</>
        }
        defaultComment = `${group} Your Burn Request has been ${modalData.StatusName}`
      }
      else if (modalData.StatusName === 'Denied') {
        defaultComment = `${group} Your Burn Request has been ${modalData.StatusName}`
        msg = <>The Burner will be <b>immediately alerted of the <i>denial</i></b>.</>
      }
      else {
        defaultComment = `${group} You Burn Request status is now ${modalData.StatusName}`
      }
      return <>
        <p>Are you sure you want this Burn Request status to be set to <b>{modalData.StatusName}</b>?</p>
        { !modalState.modalData.Comment && <Alert color={'warning'}>
          <p>You did not include a Comment detailing your decision.</p>
          <p>Either click <b>No</b> then enter a comment, or click <b>Yes</b> and the following comment will be entered for you:</p>
          <p className={'pl-4 m-0'}><i>{defaultComment}</i></p>
        </Alert>
        }
        { msg && <Alert color={'info'}><p className={'m-0'}>{msg}</p></Alert> }
      </>
    }
  }

  getModalFooter = () => {
    const { modalState, } = this.state
    if (!modalState.isModalVisible) {
      return null
    }
    if (modalState.modalType === SUBMIT_REQUEST_REVIEW && modalState.modalData) {
      return <>
        <Button color={'secondary'} onClick={() => this.formik.submitForm()} disabled={this.formik.isSubmitting}>Yes</Button>
        <Button color={'light'} onClick={() => { 
          this.setState({ 
            modalState: { 
              isModalVisible : false, 
              modalData      : null, 
              modalType      : '', 
            },
          })
        }
        }>No</Button>
      </>
    }
  }

  componentDidMount () {
    if (this.props.online) {
      const { canAssignRequest, burnRequestId, GetBurnRequestStatusHistory, GetAssignableUsers, } = this.props
      
      if (canAssignRequest) {
        GetAssignableUsers()
      }
      GetBurnRequestStatusHistory(burnRequestId)
    }
    this.setState({ showModal: false, })
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.requestStatus, this.props.requestStatus) && this.state.modalState.isModalVisible) {
      this.setState({ modalState: { isModalVisible: false, }, })
    }
  }

  submit = (values) => {
    values.AssignedTo = null // Null this out as the API tries to serialize it to a class which will throw an error;
    if (this.props.canChangeStatus !== true) {
      values.BurnRequestStatusId = -1
    }

    this.props.SubmitRequestStatus(values)
    this.formik.resetForm()
  }

  submitReview = evt => {
    stopEvent(evt)
    const { BurnRequestStatusId, Comment, } = this.formik.values
    let StatusName = ''
    const status = this.props.BurnRequestStatuses.find(s => s.Value === parseInt(BurnRequestStatusId))
    if (status) {
      StatusName = status.Text
    }
    this.setState({
      modalState: {
        modalType      : SUBMIT_REQUEST_REVIEW,
        isModalVisible : true,
        modalData      : { StatusName, Comment, },
      },
    })
  }

  showOverrideModal = evt => {
    stopEvent(evt)
    this.setState({ showSpinner: false, })
    this.props.OpenModal(OVERRIDE_BURN_REQUEST_DECISION)
  }

  setFormikNode = node => this.formik = node

  render () {

    const {
      requestStatus,
      BurnRequestStatuses,
      hideForm,
      online,
      ShowOverride,
      canChangeStatus,
    } = this.props

    if (!requestStatus) {
      return null
    }

    const requestStatusIsReadOnly = !canChangeStatus

    return <>
      {
        !hideForm &&
        <Col xs={'12'} className={'mb-3'}>
          <Card>
            <CardHeader tag={'h3'}>Burn Request Status</CardHeader>
            <CardBody>
              <Formik
                initialValues={{
                  BurnRequestId       : this.state.BurnRequestId,
                  BurnRequestStatusId : this.state.BurnRequestStatusId,
                  InternalOnly        : this.state.InternalOnly,
                  Comment             : this.state.Comment,
                }}
                validationSchema={this.getValidationSchema()}
                onSubmit={this.submit}
                innerRef={this.setFormikNode}
              >
                <Form>
                  { !online && <p><b>Recording a Status requires an internet connection. The form will enable when a connection is restored.</b></p> }
                  {
                    canChangeStatus &&
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Field name={'BurnRequestStatusId'}>
                              {({ field, form, }) => (
                                <Select
                                  {...field}
                                  label={'Request Status'}
                                  items={BurnRequestStatuses}
                                  propertyName={field.name}
                                  selectedValue={field.value}
                                  errorMessage={form.errors[field.name]}
                                  readOnly={!online || requestStatusIsReadOnly}
                                />
                              )}
                            </Field>
                            <ErrorMessage name={'BurnRequestStatusId'} component={FormFeedback}/>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <AssignedStaff />
                            <ErrorMessage name={'AssignedToId'} component={FormFeedback}/>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup check>
                            <Field name={'InternalOnly'}>
                              {({ field, }) => (
                                <>
                                  <Label check>
                                    <Input type={'checkbox'}
                                      {...field}
                                      disabled={!online || requestStatusIsReadOnly}
                                      checked={field.value}
                                    />{' '}
                                    Internal Only
                                  </Label>
                                  { 
                                    !field.value && 
                                    <small className={'text-warning d-block'}>Warning: This comment will be visible to the burner unless this box is checked.</small>
                                  }
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                  }
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for={'Comment'}>Comment</Label>
                        <Field
                          name={'Comment'}
                          id={'Comment'}
                          component={'textarea'}
                          className={'form-control'}
                          readOnly={!online}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button type={'submit'} className={'float-right'} disabled={!online} onClick={this.submitReview}>Save</Button>
                </Form>
              </Formik>
            </CardBody>
          </Card>
        </Col>
      }
      <Col xs={'12'} className={'mb-3'}>
        <Card>
          <CardBody style={{ padding: '0.75em', }}>
            <div>{/* For some reason the app crashes if this conditional render is not wrapped in its own tag */}
              {
                ShowOverride && <>
                  <Button onClick={this.showOverrideModal} color={'danger'}>Override Decision</Button>
                  <ConfirmationModal
                    modalKey={OVERRIDE_BURN_REQUEST_DECISION}
                    modalTitle={'Override Burn Request ' + (requestStatus.Status === 'Approved' ? 'Approval' : 'Denial') + '?'}
                    modalBody={<>
                      <p>Are you sure you want to override this {requestStatus.Status === 'Approved' ? 'Approval' : 'Denial'}?</p>
                      {
                        requestStatus.Status === 'Denied' && 
                        <Alert color={'warning'}>
                          <p>This will <b>Approve</b> this Burn Request at the Region level also.</p>
                        </Alert>
                      }
                      <Label for={'override-comment'}>Please provide a reason why you are overriding this Burn Request Decision</Label>
                      <Input
                        id={'override-comment'}
                        name={'override-comment'}
                        type={'textarea'}
                        className={'mb-3' + (this.state.overrideCommentError ? ' is-invalid' : '')}
                        onBlur={evt => this.setState({ overrideComment: evt.target.value, })}
                      />
                      <FormFeedback className={'mb-3'}>{this.state.overrideCommentError}</FormFeedback>
                      <Alert color={'danger'}>
                        <p style={{ color: '#fff', margin: 0, }}><b>You should contact the Burner as soon as possible if they have not been contacted already.</b></p>
                      </Alert>
                    </>}
                    disableButtons={this.state.showSpinner}
                    submitActionLabel={requestStatus.Status === 'Approved' ? 'Deny' : 'Approve'}
                    submitAction={() => {
                      if (this.state.overrideComment) {
                        this.setState({ showSpinner: true, })
                        this.props.OverrideDecision(this.props.burnRequestId, this.state.overrideComment)
                      }
                      else {
                        this.setState({ overrideCommentError: 'Please enter a reason for the override', })
                      }
                    }}
                    showSpinner={this.state.showSpinner}
                  />
                </>
              }
            </div>
            <DataTable
              columns={this.columns}
              ordering={false}
              pageLength={5}
              disablePageLengthChange={true}
              disableRowClick={true}
              records={this.props.BurnRequestStatusHistory}
              elementId={'burn-permit-status-history-table'}
              redraw={this.state.redraw}
            />
          </CardBody>
        </Card>
      </Col>
      {
        canChangeStatus && <Modal isOpen={this.state.modalState.isModalVisible}>
          <ModalHeader>
            {this.getModalHeader()}
          </ModalHeader>
          <ModalBody>
            {this.getModalBody()}
          </ModalBody>
          <ModalFooter>
            {this.getModalFooter()}
          </ModalFooter>
        </Modal>
      }
    </>
  }
}

function mapStateToProps (state, props) {
  const { burnRequestId, } = props
  
  const requestStatus = burnRequestStatus(state, burnRequestId)
  let BurnRequestStatuses = burnRequestStatuses(state, s => s.BurnRequestStatusName !== 'Submitted' && s.BurnRequestStatusName !== 'Cancelled')

  // Double check it has not been approved by a Region user
  const isRegionApproved = burnRequestIsApproved(state, burnRequestId)
  const isDivisionApprover = userIsDivisionApprover(state)
  let hideForm = props.hideForm || isRegionApproved || !props.MakesSMDecisions
  // If the current user is Smoke Mgmt
  if (isDivisionApprover) {
    // Hide the form if the request is approved or denied
    hideForm = (props.IsApproved || props.IsDenied)
  }
  // If the current user can make Smoke Mgmt Decisions
  else if (props.MakesSMDecisions) {
    hideForm = true
    if (props.IsApproved && !props.IsDenied) {
      // Show the form if it has not been approved by Region
      hideForm = isRegionApproved
      BurnRequestStatuses = BurnRequestStatuses.filter(s => s.Text === 'Approved' || s.Text === 'Denied')
    }
  }

  const now = new Date()
  now.setUTCHours(0, 0, 0, 0)
  const ignDate = new Date(props.IgnitionDate)
  ignDate.setUTCHours(0, 0, 0, 0)
  const _ignDate = dateFormatter(ignDate)
  const ignitionDateIsToday = _ignDate.isSame(now.toJSON()) || _ignDate.isAfter(now.toJSON())

  const ShowOverride = requestStatus && props.CanOverrideDecisions && ignitionDateIsToday && (props.IsApproved || props.IsDenied)

  const userPersonId = userPersonIdSelector(state)
  let burnReqInPersonRegion = false
  if (isDivisionApprover && !props.IsApproved) {
    burnReqInPersonRegion = true
  }
  else {
    burnReqInPersonRegion = burnRequestInPersonsRegion(state, burnRequestId, userPersonId)
  }
  if (!burnReqInPersonRegion) {
    hideForm = true
  }

  const BurnRequestRegionId = burnRequestRegion(state, burnRequestId)

  const { online, } = state.offline
  return {
    online,
    hideForm,
    requestStatus,
    BurnRequestStatuses,
    burnReqInPersonRegion,
    ShowOverride,
    BurnRequestRegionId,
    isDivisionApprover,
    BurnRequestStatusHistory : burnRequestStatusHistory(state, burnRequestId),
    userName                 : userNameSelector(state),
    showInternalStatus       : userSeesInternalReqStatus(state),
    canAssignRequest         : userCanAssignBurnRequest(state),
    canChangeStatus          : userCanChangeRequestStatus(state),
    statusCommentRequired    : userMustEnterStatusComment(state),
  }
}

const mapDispatchToProps = {
  OpenModal                   : UiActions.openModal,
  GetAssignableUsers          : PersonActions.getAssignableUsers,
  GetBurnRequestStatusHistory : BurnRequestDetailActions.getBurnRequestStatusHistory,
  SubmitRequestStatus         : BurnRequestDetailActions.submitRequestStatus,
  DeleteRequestStatus         : BurnRequestDetailActions.deleteRequestStatus,
  OverrideDecision            : BurnRequestDetailActions.overrideDecision,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnRequestReview)
