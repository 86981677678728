import React from 'react'
import { string, } from 'prop-types'

const TelPhone = ({ phoneNumber, }) => {
  let mailto = `tel:${phoneNumber}`
  return <a href={mailto}>{phoneNumber}</a>
}

TelPhone.propTypes = {
  phoneNumber: string,
}

TelPhone.defaultProps = {
  phoneNumber: '',
}

export default TelPhone