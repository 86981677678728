import React from 'react'
import { useSelector, } from 'react-redux'

// Components
import NavbarItem from '../NavbarItem'

// Selectors
import { userCanViewAdminMenu, } from '../../../selectors/permissionSelectors'


const Regions = () => {
  const canViewAdminMenu = useSelector(userCanViewAdminMenu)
  if (canViewAdminMenu) {
    return null
  }
  const markup = <NavbarItem
    to={'/regions'}
    text={'Regions'}
    title={'View Region Offices'}
  />
  return markup
}

export default React.memo(Regions)
