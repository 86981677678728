// Libraries
import { fork, put, call, takeLatest, } from 'redux-saga/effects'

// Sagas
import { doFetch, showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { downloadFile, } from './FileSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { ReportTypes, } from '../redux/ReportsRedux'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, } = process.env
const REPORT_SERVER_URL = `${REACT_APP_SERVER}Reports`


function* getReports () {
  try {

    yield call(showLoading)

    const resp = yield call(doFetch, REPORT_SERVER_URL)
    if (resp.ok !== true) {
      const error = resp.responseBody.error || 'An error occurred requesting reports.'
      yield put({ type: ApiTypes.FAILURE, error, })
      return
    }

    const reports = []

    const { value, } = resp.responseBody
    for(let i = 0, len = value.length; i < len; i++) {
      const report = value[i]
      report.Name = report.Name.split(/(?=[A-Z])/).join(' ')
      reports.push(report)
    }

    yield put({ type: ReportTypes.SET_REPORTS, reports, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
    yield put({ type: ReportTypes.REPORTS_DONE_LOADING, })
  }
}


function* downloadReport ({ reportPath, format, }) {
  try {

    yield call(showLoading)

    const resp = yield call(doFetch, `${REPORT_SERVER_URL}/Export?reportPath=${reportPath}&format=${format}`)
    if (resp.ok !== true) {
      const error = resp.responseBody.error || 'An error occurred requesting report export.'
      yield put({ type: ApiTypes.FAILURE, error, })
      return
    }

    const { fileCode, } = resp.responseBody

    yield fork(downloadFile, { url: `${REPORT_SERVER_URL}/Download/${fileCode}`, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


export const ReportSagas = [
  takeLatest(ReportTypes.GET_REPORTS, getReports),
  takeLatest(ReportTypes.DOWNLOAD_REPORT, downloadReport),
]