import React from 'react'
import { object, } from 'prop-types'
import { Row, Col, } from 'reactstrap'
import { dateFormatter, currencyFormatter, } from '../../utilities'

const OrderPaymentSummary = ({ order, appDetail, }) => {
  if (!order) {
    return null
  }

  let paymentMethod = ''
  if (order && order.CardType) {
    paymentMethod = order.CardType
  }
  else if (order && appDetail && appDetail.PaymentMethod) {
    paymentMethod = appDetail.PaymentMethod
  }
  else if (!appDetail) {
    paymentMethod = 'E-Check'
  }
  return <>
    <Row className={'mt-4'}>
      <Col>
        <h2 style={{ fontSize: '1.5rem', }}>Payment Summary</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <b>{order.CardType && order.CardType.indexOf('Check') === -1 ? 'Card' : 'Payment'} Type:</b>
      </Col>
      <Col className={'text-right'}>
        {paymentMethod}
      </Col>
    </Row>
    {
      appDetail && 
          <Row>
            <Col>
              <b>Transaction Date:</b>
            </Col>
            <Col className={'text-right'}>
              {dateFormatter(appDetail.TransactionDate, 'MM-DD-YYYY')}
            </Col>
          </Row>
    }
    {
      paymentMethod !== 'Voucher' && paymentMethod !== 'Check' && paymentMethod !== 'Third Party Payments (ACH Credit, POS, Cash)' && order.SettlementSubmissionDate &&
          <Row>
            <Col xs={7}>
              <b>Transaction Completion Date:</b>
            </Col>
            <Col xs={5} className={'text-right'}>
              {dateFormatter(order.SettlementSubmissionDate, 'MM-DD-YYYY hh:mm:ss a')}
            </Col>
          </Row>
    }
    {
      (paymentMethod === 'Voucher' || paymentMethod === 'Check') &&
          <Row>
            <Col xs={7}>
              <b>{paymentMethod} Date:</b>
            </Col>
            <Col xs={5} className={'text-right'}>
              {dateFormatter(order.SettlementSubmissionDate, 'MM-DD-YYYY')}
            </Col>
          </Row>
    }
    {
      order.ConfirmationNumber &&
        <Row>
          <Col>
            <b>Confirmation Number:</b>
          </Col>
          <Col className={'text-right'}>
            {order.ConfirmationNumber}
          </Col>
        </Row>
    }
    {
      paymentMethod !== 'E-Check' && order.AuthorizationCode &&
        <Row>
          <Col>
            <b>Authorization Code:</b>
          </Col>
          <Col className={'text-right'}>
            {order.AuthorizationCode}
          </Col>
        </Row>
    }
    {
      (order.TotalAmountPaid || (appDetail && appDetail.PaymentAmount)) && 
        <Row>
          <Col>
            <b>Total Paid:</b>
          </Col>
          <Col className={'text-right'}>
            {currencyFormatter.format(order.TotalAmountPaid)}
          </Col>
        </Row>
    }
    {
      (appDetail && (appDetail.PaymentCommandCode === 'Refund' || appDetail.PaymentCommandCode === 'Partial Refund')) &&
        <Row>
          <Col>
            <b>Refund Amount:</b>
          </Col>
          <Col className={'text-right'}>
            {currencyFormatter.format(appDetail.PaymentAmount)}
          </Col>
        </Row>
    }
    {
      !!order.EPayResultMessage && order.EPayResultMessage.length > 2 && (
        <Row>
          <Col>
            <b>Message:</b>
          </Col>
          <Col className={'text-right'}>
            {order.EPayResultMessage}
          </Col>
        </Row>
      )
    }
    {
      (paymentMethod === 'Check' || paymentMethod === 'Third Party Payments (ACH Credit, POS, Cash)') &&
        <>
          <Row>
            <Col>
              <b>Payer Name:</b>
            </Col>
            <Col className={'text-right'}>
              {appDetail.FirstName} {appDetail.LastName}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{paymentMethod === 'Check' ? 'Check' : 'Payment'} Date:</b>
            </Col>
            <Col className={'text-right'}>
              {dateFormatter(order.SettlementSubmissionDate, 'MM-DD-YYYY')}
            </Col>
          </Row>
        </>
    }
    {
      paymentMethod === 'Check' &&
        <Row>
          <Col>
            <b>Check Number:</b>
          </Col>
          <Col className={'text-right'}>
            {appDetail.CheckNumber}
          </Col>
        </Row>
    }
    {
      order.BurnPermitOrderVoucher && <>
        <Row>
          <Col>
            <b>Voucher Number:</b>
          </Col>
          <Col className={'text-right'}>
            {order.BurnPermitOrderVoucher.VoucherNumber}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Contact:</b>
          </Col>
          <Col className={'text-right'}>
            {order.BurnPermitOrderVoucher.Contact}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <b>Comment:</b>
          </Col>
          <Col xs={12} className={'p-4'}>
            {order.BurnPermitOrderVoucher.Comment}
          </Col>
        </Row>
      </>
    }
  </>
}


OrderPaymentSummary.propTypes = {
  order     : object,
  appDetail : object,
}

OrderPaymentSummary.defaultProps = {
  order     : null,
  appDetail : null,
}

export default OrderPaymentSummary