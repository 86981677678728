import React, { useCallback, useMemo, useState, } from 'react'
import Joyride, { STATUS, } from 'react-joyride'
import { array, bool, func, string, } from 'prop-types'
import { Button, } from 'reactstrap'


const styles = {
  options: {
    primaryColor: '#172545',
  },
}

const FormTour = props => {
  const {
    run,
    steps,
    tourCompleted,
    onFinished,
  } = props

  const [ restartTour, setRestartTour, ] = useState(false)

  const showTour = useMemo(() => {
    if (restartTour === true) {
      return restartTour
    }
    if (tourCompleted === true) {
      return false
    }
    return run
  }, [ tourCompleted, restartTour, run, ])

  const joyrideCallback = useCallback(data => {
    const { status, } = data
    if (typeof onFinished === 'function' && [ STATUS.FINISHED, STATUS.SKIPPED, ].includes(status)) {
      onFinished()
      setRestartTour(false)
    }
  }, [ onFinished, ])

  const onHelpClick = useCallback(() => {
    setRestartTour(true)
  }, [])

  return <>
    <Button
      color={'link'}
      onClick={onHelpClick}
      className={'pt-1 pb-0'}
    >
      Help<span className={'far fa-question-circle ml-1'}/>
    </Button>
    <Joyride
      callback={joyrideCallback}
      continuous
      showProgress
      showSkipButton
      hideCloseButton
      run={showTour}
      steps={steps}
      styles={styles}
    />
  </>
}

FormTour.defaultProps = {
  run           : false,
  tourCompleted : false,
}

FormTour.propTypes = {
  run             : bool,
  interactionType : string,
  onFinished      : func,
  steps           : array,
  tourCompleted   : bool,
}

export default React.memo(FormTour)
