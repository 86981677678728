import React, { useMemo, } from 'react'
import { Field, useFormikContext, } from 'formik'
import { Col, Collapse, FormGroup, Label, Row, } from 'reactstrap'
import { bool, } from 'prop-types'

// Components
import { ValidatingField, } from '../../FormControls'


const FollowUpForm = props => {
  const { readOnly, } = props
  const { values, } = useFormikContext()

  const prompt = useMemo(() => {
    if (readOnly) {
      return null
    }
    return <p className={'p-2'}>
      <strong>
        If you would like to follow-up on your complaint,
        or would be willing to have a representative from DNR contact
        you if additional information is needed, please leave your contact information below.
      </strong>
    </p>
  }, [ readOnly, ])

  // todo: will prob need to do a value properties cleanup
  //   if a user chooses yes, fills out some/all info, then
  //   toggles to no
  //   on the backend we should honor this property value and
  //   ignore any FollowUp values if the submitter does not
  //   request follow up.
  if (values.FollowUp !== true) {
    return null
  }

  const markup = <Collapse isOpen={values.FollowUp}>
    {prompt}
    <Row>
      <Col>
        <FormGroup>
          <Label for={'FirstName'}>First Name</Label>
          <Field
            className={'form-control'}
            name={'FirstName'}
            readOnly={readOnly}
          />
        </FormGroup>
      </Col>
      <Col><FormGroup>
        <Label for={'LastName'}>Last Name</Label>
        <Field
          className={'form-control'}
          name={'LastName'}
          readOnly={readOnly}
        />
      </FormGroup>
      </Col>
    </Row>
    <FormGroup>
      <Label for={'PhoneNumber'}>Phone Number</Label>
      <Field
        name={'PhoneNumber'}
        readOnly={readOnly}
        component={ValidatingField}
      />
    </FormGroup>
    <FormGroup>
      <Label for={'EmailAddress'}>Email Address</Label>
      <Field
        name={'EmailAddress'}
        readOnly={readOnly}
        component={ValidatingField}
      />
    </FormGroup>
  </Collapse>
  return markup
}

FollowUpForm.defaultProps = {
  readOnly: false,
}

FollowUpForm.propTypes = {
  readOnly: bool,
}

export default React.memo(FollowUpForm)