// Libraries
import React, { useState, useEffect, useCallback, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { bool, } from 'prop-types'

// Actions
import AppActions from '../redux/AppRedux'
import PersonActions from '../redux/PersonRedux'

// Selectors
import { appStateSelector, } from '../selectors/appSelectors'
import { currentUserGroupsSelector, userSelector, } from '../selectors/userSelectors'
import {
  personSelector,
  personPhoneSelector,
  personAddressesSelector,
  personAlertPrefXrefSelector,
  personAgencyXrefSelector,
  personRegionXrefSelector,
  personEmailSelector,
  getUnsubmittedRequests,
} from '../selectors/personSelectors'

// Components
import { PersonForm, } from '../components/Forms'
import {
  ApiToken,
  HistorySettingForm,
  NetworkNotifications,
  ProfileStatus,
  UserGroups,
} from '../components/Profile'
import BurnRequestNotices from '../components/Modals/BurnRequestNotices'
import MapLayers from '../components/Profile/MapLayers'

// Utilities
import stopEvent from '../utilities/stopEvent'


const ProfileContainer = (props) => {
  const { isLoadingUser, isAgent, } = props

  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const User = useSelector(userSelector)
  const userGroups = useSelector(currentUserGroupsSelector)
  const personId = User ? User.personId : null

  const person = useSelector(state => personSelector(state, personId))
  const addresses = useSelector(state => personAddressesSelector(state, personId))
  const phones = useSelector(state => personPhoneSelector(state, personId))
  const alertPrefs = useSelector(state => personAlertPrefXrefSelector(state, personId))
  const agency = useSelector(state => personAgencyXrefSelector(state, personId))
  const region = useSelector(state => personRegionXrefSelector(state, personId))
  const email = useSelector(state => personEmailSelector(state, personId))

  const { returnUrl, } = useSelector(appStateSelector)
  const burnRequestNotices = useSelector(getUnsubmittedRequests)

  // State
  const [ userInfoIsOpen, setUserInfoIsOpen, ] = useState(true)
  const [ userProfileIsOpen, setUserProfileIsOpen, ] = useState(true)
  const [ requestModalDismissed, setRequestModalDismissed, ] = useState(false)

  // OnMount
  useEffect(() => {
    const shouldFetch = !isLoadingUser && User && Number.isInteger(User.personId) && (User.personId > 0)
    if (shouldFetch) { 
      dispatch(PersonActions.getPerson(User.personId))
      dispatch(PersonActions.getUnsubmittedRequests(User.personId))
    }
    dispatch(AppActions.setPageTitle('Profile'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // DidUpdate
  useEffect(() => {
    if (isLoadingUser === true && returnUrl) {
      dispatch(AppActions.setReturnUrl(''))
      navigate(returnUrl)
    }
  }, [ dispatch, navigate, isLoadingUser, returnUrl, ])

  // UI Callbacks
  const closeBurnReqNotices = useCallback(e => {
    stopEvent(e)
    setRequestModalDismissed(true)
  }, [])

  const burnReqNoticesOpen = useMemo(() => {
    return !requestModalDismissed && burnRequestNotices.length > 0
  }, [ requestModalDismissed, burnRequestNotices, ])

  return <Container className={'pt-2 pb-4 profile-container'}>
    <h1>Profile</h1>
    <BurnRequestNotices
      requests={burnRequestNotices}
      isOpen={burnReqNoticesOpen}
      closeModal={closeBurnReqNotices}
    />
    <ProfileStatus />
    {
      personId && 
        <Col xs={'12'} className={'mt-3'}>
          <Card>
            <CardHeader
              tag={'h2'}
              onClick={setUserProfileIsOpen}
            >
              Your Profile (ID: {personId})
            </CardHeader>
            <Collapse isOpen={userProfileIsOpen}>
              <CardBody>
                <Row>
                  <Col>
                    <PersonForm
                      person={person}
                      showAddresses={true}
                      addresses={addresses}
                      showPhones={true}
                      phones={phones}
                      showEmails={true}
                      showTypes={true}
                      shouldGeocode={false}
                      isAgent={isAgent}
                      alertPrefs={alertPrefs}
                      agency={agency}
                      region={region}
                      showRegion={true}
                      email={email}
                      disableMerge={true}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
    }
    <Col xs={'12'} className={'mt-3'}>
      <Card>
        <CardHeader
          tag={'h2'}
          onClick={setUserInfoIsOpen}
        >
          Your User Information
        </CardHeader>
        <Collapse isOpen={userInfoIsOpen}>
          <CardBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for={'username'}>
                    Username
                  </Label>
                  <Input
                    id={'username'}
                    value={User.userName}
                    readOnly
                  />
                </FormGroup>
                <UserGroups userGroups={userGroups} />
                <FormGroup className={'mt-3'}>
                  <HistorySettingForm />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <ApiToken />
                </FormGroup>
                <FormGroup>
                  <NetworkNotifications />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <MapLayers />
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </Col>
  </Container>
}

ProfileContainer.defaultProps = {
  isLoadingUser : false,
  isAgent       : false,
}

ProfileContainer.propTypes = {
  isLoadingUser : bool,
  isAgent       : bool,
}

export default React.memo(ProfileContainer)