const TourSteps = ({ isDnr = false, }) => {
  const steps = [
    {
      target        : 'span.esri-icon-layer-list',
      content       : 'Toggle layers on and off',
      placement     : 'right',
      disableBeacon : true,
    },
    {
      target    : 'span.esri-icon-basemap',
      content   : 'Change basemaps between Satellite, street map, and others',
      placement : 'right',
    },
    {
      target    : 'span.esri-icon-locate',
      content   : 'Zoom to your current location',
      placement : 'right',
    },
    {
      target    : '.esri-ui',
      content   : 'Click anywhere within the state of Washington to estimate the location of the Smoke Event',
      placement : 'right',
    },
    {
      target    : 'form .card-body',
      content   : 'Fill out the form, providing as much detailed information as you can',
      placement : 'left',
    },
    {
      target    : 'form button[type=submit]',
      content   : 'Submit the data for DNR to review',
      placement : 'top',
    },
  ]

  if (isDnr === true) {
    const formStep = { ...steps[4], }
    formStep.content = 'You can modify the Latitude, Longitude, and Address inputs if needed. All other data is read only.'
    steps[4] = formStep

    const submitStep = { ...steps[5], }
    submitStep.content = 'Save the location data modifications'
    steps[5] = submitStep

    steps.push({
      target    : '.link-burn-reqs-btn-group',
      content   : 'View or associate one or more burn request / post burn events to this complaint',
      placement : 'left',
    })
    steps.push({
      target    : '.smoke-complaint-status',
      content   : 'Route this complaint to another DNR rep or region or manage its status',
      placement : 'left',
    })
  }

  return steps
}

export default TourSteps
