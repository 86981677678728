// Libraries
import React, { useEffect, useMemo, } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap'
import { func, bool, string, element, arrayOf, oneOfType, } from 'prop-types'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import CloseModalButton from './CloseModalButton'
import DocuSignSteps from './DocuSignSteps'

// Redux
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'
import UiActions from '../../redux/UiRedux'

// Selectors
import { isModalOpen, } from '../../selectors/uiSelectors'
import { appIsOnlineSelector, } from '../../selectors/selectors'
import { permitSignatureState, } from '../../selectors/burnPermitSignatureSelectors'


const DocuSignModal = props => {
  const {
    onClick,
    modalHeader,
    children,
    className,
    buttonText,
    signatureType,
    disabled,
    disableModalLaunchBtn,
  } = props

  const modalKey = `DOCUSIGN_MODAL_${modalHeader.replace(/ /g, '_').toUpperCase()}`

  // Selectors
  const isOpen = useSelector(state => isModalOpen(state, modalKey))

  const online = useSelector(appIsOnlineSelector)
  const {
    doNotShowSteps,
    acknowledgedSteps,
    isLoadingDocuSignUrl,
  } = useSelector(permitSignatureState)

  const isPreviewing = useMemo(() => (signatureType === SignatureProcessTypes.preview), [ signatureType, ])
  const isIssuing = useMemo(() => (signatureType === SignatureProcessTypes.issue), [ signatureType, ])
  const isReissuing = useMemo(() => (signatureType === SignatureProcessTypes.reissue), [ signatureType, ])

  const docusignScenario = SignatureProcessTypes[signatureType]

  const acknowledgedProcessSteps = acknowledgedSteps[docusignScenario]

  // Redux actions
  const dispatch = useDispatch()

  const openModal = React.useCallback(() => {
    let showModal = true
    if (doNotShowSteps[docusignScenario] && !isLoadingDocuSignUrl && typeof onClick === 'function') {
      showModal = onClick()
    }
    if (showModal) {
      dispatch(UiActions.openModal(modalKey))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, modalKey, onClick, ])

  let closeModal = React.useCallback(() => {
    dispatch(UiActions.closeModal())
  }, [ dispatch, ])

  useEffect(() => {
    if (disabled) {
      dispatch(UiActions.closeModal())
    }
  }, [ dispatch, disabled, ])

  useEffect(() => {
    dispatch(BurnPermitSignatureActions.loadingDocuSignUrl(false))
    dispatch(BurnPermitSignatureActions.acknowledgeSignatureSteps(SignatureProcessTypes[docusignScenario], false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If the user has already acknowledged the steps
  // call the onClick callback to begin the redirection
  useEffect(() => {
    dispatch(BurnPermitSignatureActions.acknowledgeSignatureSteps(SignatureProcessTypes[docusignScenario], false))
    if (isOpen && doNotShowSteps[docusignScenario] && !isLoadingDocuSignUrl && typeof onClick === 'function') {
      onClick()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isOpen, ])

  const btnColor = useMemo(() => {
    if (isPreviewing) {
      return 'light'
    }
    return 'primary'
  }, [ isPreviewing, ])

  let content = children || <DocuSignSteps
    key={'docusign-steps'}
    isPreviewing={isPreviewing}
    isIssuing={isIssuing}
    isReissuing={isReissuing}
  />

  let footer = <ModalFooter className={'d-flex justify-content-between'}>
    <CloseModalButton />
    <Button
      color={btnColor}
      onClick={onClick}
      disabled={isLoadingDocuSignUrl || !online || !acknowledgedProcessSteps}
    >
      {buttonText}
    </Button>
  </ModalFooter>

  if (acknowledgedProcessSteps !== true || !onClick) {
    footer = <></>
  }

  if (isLoadingDocuSignUrl) {
    content = <p>Please wait while we redirect you to DocuSign. This may take a few seconds.</p>
    footer = <></>
    closeModal = null
  }

  return <>
    { disableModalLaunchBtn !== true &&
        <Button
          color={btnColor}
          onClick={openModal}
          data-modalkey={modalKey}
          disabled={disabled || isLoadingDocuSignUrl || !online}
          className={className}
        >
          {buttonText}
        </Button>
    }
    <Modal
      isOpen={isOpen}
      className={'docusign-modal'}
    >
      <ModalHeader toggle={closeModal}>
        {modalHeader || buttonText}
      </ModalHeader>
      <ModalBody>
        {content}
      </ModalBody>
      {footer}
    </Modal>
  </>
}

DocuSignModal.defaultProps = {
  onClick               : () => {},
  isPreviewing          : false,
  isIssuing             : false,
  isReissuing           : false,
  disabled              : false,
  disableModalLaunchBtn : false,
}

DocuSignModal.propTypes = {
  onClick               : func,
  modalHeader           : string,
  buttonText            : string,
  children              : oneOfType([ element, arrayOf(element), string, ]),
  signatureType         : string,
  isPreviewing          : bool,
  isIssuing             : bool,
  isReissuing           : bool,
  disabled              : bool,
  disableModalLaunchBtn : bool,
  className             : string,
}

export default React.memo(DocuSignModal)
