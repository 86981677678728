import React from 'react'
import { func, bool, string, object, } from 'prop-types'

const RowToggler = ({ onClick, show, rowLabel, rowClass, style, }) => {
  return <div 
    className={`ml-2 btn-link small d-inline-block ${rowClass}`}
    style={{ cursor: 'pointer', ...style, }}
    onClick={onClick}
    onKeyPress={onClick}
    role={'button'}
    tabIndex={0}
  >
    <span className={`fa fa-eye${show ? '-slash' : ''} mr-2`}></span>
    {show ? 'Hide' : 'Show'} {rowLabel}
  </div>
}

RowToggler.propTypes = {
  onClick  : func.isRequired,
  show     : bool.isRequired,
  rowLabel : string.isRequired,
  rowClass : string,
  style    : object,
}

RowToggler.defaultProps = {
  show     : false,
  rowClass : '',
}

export default RowToggler