import { attr, Model, } from 'redux-orm'

export default class ApplicationUser extends Model {
  static get modelName () {
    return 'ApplicationUser'
  }

  toString () {
    return `${this.FirstName} ${this.LastName}`
  }

  static getDataTablesColumns () {
    const cols = [
      {
        title : 'Person Id',
        type  : 'num',
        data  : 'PersonId',
        width : 15,
      },
      {
        title : 'UserName',
        data  : 'UserName',
      },
      {
        title : 'First Name',
        data  : 'FirstName',
      },
      {
        title : 'Last Name',
        data  : 'LastName',
      },
      {
        title : 'Claim Type',
        data  : 'ClaimType',
      },
      {
        title : 'Claim Value',
        data  : 'ClaimValue',
      },
    ]
    return cols
  }

  static get options () {
    return {
      idAttribute: 'PersonId',
    }
  }

  static get fields () {
    return {
      PersonId   : attr(),
      FirstName  : attr(),
      LastName   : attr(),
      UserName   : attr(),
      ClaimType  : attr(),
      ClaimValue : attr(),
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}