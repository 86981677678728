// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Formik, Field, } from 'formik'
import { isEmpty, } from 'lodash'
import { Row, Col, FormGroup, Label, Card, CardBody, } from 'reactstrap'
import { bool, string, number, object, array, func, } from 'prop-types'

// Reducers
import BurnPermitSiteActions from '../../redux/BurnPermitSiteRedux'

// Components
import BurnPermitFormSection from './FormSection'
import BurnPermitSiteEquipment from '../FormControls/BurnPermitSiteEquipment'
import BurnPermitReferenceDirections from '../FormControls/BurnPermitReferenceDirections'
import {
  PopoverButton,
  Select,
  CheckBoxes,
  YesNoRadioButtonGroup,
  AutoComplete,
  ValidatingField,
  RequiredLabel,
} from '../FormControls'

// Selectors
import { 
  daysOfWeekCheckboxSelector,
  equipmentTypeCheckboxSelector,
} from '../../selectors/selectors'
import {
  burnPermitSiteByIdSelector,
  forestTypesSelectSelector,
  burnReasonSelectSelector,
  burnUnitSelector,
} from '../../selectors/burnPermitSiteSelectors'
import { itemsByModelNameSelector, } from '../../selectors/modelSelectors'

// Utilities
import { getTodaysDate, } from '../../utilities'
import retry from '../../utilities/retry'
import stopEvent from '../../utilities/stopEvent'


// Models
import BurnPermitSite from '../../models/BurnPermitSite'
import Effect from '../Effect'

// CONSTANTS
const FIRE_LINE_CONSTRUCTION = 'Fire Line Construction'
const EXTINGUISHING = 'Extinguishing'
const ARRIVAL_TIME = 'ArrivalTime'


export class BurnPermitSiteSection extends React.Component {
  constructor (props) {
    super(props)

    this.validateSection = this.validateSection.bind(this)
    this.validationSchema = BurnPermitSite.validationSchema
    this.onFormChange = this.onFormChange.bind(this)
  }

  static propTypes = {
    validationError         : string,
    sectionId               : number.isRequired,
    burnPermitSite          : object,
    burnPermitSiteId        : number,
    PlannedBurnDays         : array,    
    ArrivalTime             : array,
    FireLineEquipmentType   : array,
    ExtinguishEquipmentType : array,
    ForestTypes             : array,
    BurnReasons             : array,
    GetBurnPermitSiteInfo   : func,
    UpdateBurnPermitSite    : func,
    readOnly                : bool,
    BurnUnits               : array,
    online                  : bool,
    isActive                : bool,
  }

  static defualtProps = {
    readOnly: false,
  }

  state = {
    isValid: '',
  }

  rowClasses = 'mb-3'

  componentDidMount () {
    const { GetBurnPermitSiteInfo, online, isActive, } = this.props

    if (online && isActive) {
      GetBurnPermitSiteInfo(this.props.burnPermitSiteId)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.GetBurnPermitSiteInfo(this.props.burnPermitSiteId)
    }
  }

  async validateSection ({ evt, submit = true, }) {
    if (evt) {
      stopEvent(evt)
    }

    if (!this.formik) {
      return await retry(this.validateSection, { submit, }, 100)
    }

    const thisFormsErrors = await this.formik.validateForm()

    let refDirsAreValid = false, referenceDirections = []
    if (this.ReferenceDirectionForm) {
      refDirsAreValid = await this.ReferenceDirectionForm.validateForms()
      if (refDirsAreValid) {
        referenceDirections = this.ReferenceDirectionForm.submit()
      }
    }

    let siteEquipsAreValid = false, siteEquip = []
    if (this.SiteEquipmentForm) {
      siteEquipsAreValid = await this.SiteEquipmentForm.validateForms()
      if (siteEquipsAreValid) {
        siteEquip = this.SiteEquipmentForm.submit()
      }
    }

    let isValid = isEmpty(thisFormsErrors) && refDirsAreValid && siteEquipsAreValid
    if (isValid && submit) {
      const burnPermitSite = {
        ...this.formik.values,
        BurnPermitSiteEquipment           : siteEquip,
        BurnPermitSiteReferenceDirections : referenceDirections,
      }

      this.props.UpdateBurnPermitSite(burnPermitSite)
    }

    return isValid
  }

  setSiteEquipFormikRef = node => this.SiteEquipmentForm = node

  setRefDirFormikRef = node => this.ReferenceDirectionForm = node

  firstRowStyles = {
    display        : 'flex',
    justifyContent : 'space-between',
    flexDirection  : 'column',
    height         : '100%',
    padding        : '0 0 1em',
  }

  onFormChange () {
    this.validateSection({ submit: false, }).then(isValid => {
      this.setState({ isValid, })
    })
  }

  setFormikNode = node => this.formik = node

  render () {
    const { readOnly, burnPermitSite, } = this.props
    let form = null
    if (burnPermitSite !== null) {
      form = <Formik
        initialValues={burnPermitSite}
        enableReinitialize={true}
        validationSchema={this.validationSchema}
        innerRef={this.setFormikNode}
      >
        {({ values, errors, isValidating, }) => (
          <>
            <Effect values={values} onChange={this.onFormChange} />
            <Col>
              <Card>
                <CardBody>
                  <Row className={this.rowClasses}>
                    <Col xs={12} md={4}>
                      <div style={this.firstRowStyles}>
                        <div>
                          <RequiredLabel style={{ margin: 0, }} for={'forest-types'}>Primary Forest Types</RequiredLabel>
                          <PopoverButton
                            buttonClassName={'py-0'}
                            popoverHeader={'Forest Types'}
                            popoverBody={<>
                              <p>
                                Select the <b>primary</b> forest/fuel type that the piles were harvested from or where broadcast/natural burning
                                will occur. Select a value from the drop down menu for your area. You may view more detailed descriptions
                                <a href={'/permits/forestfueltypes/'}
                                  target={'_blank'}
                                  rel={'noreferrer noopener'}> here</a>.
                              </p>
                            </>
                            }
                          />
                        </div>
                        <Field name={'ForestTypeId'}>
                          {({ field, }) => (
                            <Select
                              id={'forest-types'}
                              items={this.props.ForestTypes}
                              onChange={field.onChange}
                              propertyName={field.name}
                              selectedValue={field.value}
                              readOnly={readOnly}
                              style={{ margin: 0, }}
                              errorMessage={errors[field.name]}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Field name={'BurnReasonId'}>
                        {({ field, }) => (
                          <Select
                            label={'Reason for Burning'}
                            items={this.props.BurnReasons}
                            propertyName={field.name}
                            selectedValue={field.value}
                            onChange={field.onChange}
                            readOnly={readOnly}
                            style={this.firstRowStyles}
                            errorMessage={errors[field.name]}
                            required={true}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup style={this.firstRowStyles}>
                        <RequiredLabel for={'NumberPeopleOnsite'}>How many people will be on site when burning?</RequiredLabel>
                        <Field
                          type={'number'}
                          id={'NumberPeopleOnsite'}
                          name={'NumberPeopleOnsite'}
                          readOnly={readOnly}
                          component={ValidatingField}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={4}>
                      <FormGroup>
                        <RequiredLabel for={'BurnUnitName'}>Unit Name</RequiredLabel>
                        <AutoComplete
                          items={this.props.BurnUnits}
                          fieldName={'BurnUnitName'}
                          readOnly={readOnly}
                          createable={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4}>
                      <FormGroup>
                        <Label for={'harvest-date'}>Harvest Date</Label>
                        <PopoverButton
                          buttonClassName={'py-0'}
                          popoverHeader={'Harvest Date'}
                          popoverBody={<>Approximate date unit was harvested.<br/>If unit was harvested over an extended period of time, enter date when the unit was 70 percent cut. Leave blank if natural fuels (i.e. not harvested)</>}
                        />
                        <Field
                          type={'date'}
                          id={'harvest-date'}
                          name={'HarvestDate'}
                          max={getTodaysDate('YYYY-MM-DD')}
                          className={'form-control'}
                          readOnly={readOnly}
                          component={ValidatingField}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4}>
                      <FormGroup>
                        <Label for={'snow-date'}>Snow Off Date</Label>
                        <PopoverButton
                          buttonClassName={'py-0'}
                          popoverHeader={'Snow Off Date'}
                          popoverBody={<>Approximate date snow melted off.<br/>Leave blank if unit was not covered with snow last winter</>}
                        />
                        <Field
                          type={'date'}
                          id={'snow-date'}
                          name={'SnowOffDate'}
                          className={'form-control'}
                          readOnly={readOnly}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for={'ForestPracticePermitNumber'}>
                          Forest Practice Permit Number
                          <br/>
                          <small>(If material to be burned was generated from a permitted forest practice)</small>
                        </Label>
                        <Field
                          id={'ForestPracticePermitNumber'}
                          name={'ForestPracticePermitNumber'}
                          className={'form-control'}
                          readOnly={readOnly}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <Label>How many piles will you burn at one time?</Label>
                      <Row className={this.rowClasses}>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label>Hand Piles</Label>
                            <Field
                              type={'number'}
                              id={'NumberOfHandPilesToBurnAtOnce'}
                              name={'NumberOfHandPilesToBurnAtOnce'}
                              className={'form-control'}
                              readOnly={readOnly}
                              component={ValidatingField} />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label>Machine Piles</Label>
                            <Field
                              type={'number'}
                              id={'NumberOfMachinePilesToBurnAtOnce'}
                              name={'NumberOfMachinePilesToBurnAtOnce'}
                              className={'form-control'}
                              readOnly={readOnly}
                              component={ValidatingField} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={this.rowClasses}>
                    <Col xs={12} sm={6}>
                      <Field name={'PlannedBurnDays'}>
                        {({ field, ...formProps }) => (
                          <CheckBoxes
                            inputName={field.name}
                            form={formProps.form}
                            CheckboxLabel={'On what day(s) of the week do you plan to burn?'}
                            CheckboxData={this.props.PlannedBurnDays}
                            readOnly={readOnly}
                            required={true}
                            showSelectAll={true}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} sm={6} className={'pl-0 pl-sm-4'}>
                      <FormGroup>
                        <RequiredLabel id={'ClosedSeasonBurn'} className={'mb-0'}>
                          Do you plan to burn during the closed season?
                        </RequiredLabel>
                        <br/>
                        <small className={'mb-2'}>(April 15 through October 15)</small>
                        <Field name={'ClosedSeasonBurn'}>
                          {({ field, ...formProps }) => (
                            <YesNoRadioButtonGroup
                              onChange={evt => {
                                const { name, value, } = evt.target
                                formProps.form.setFieldValue(name, value === 'yes')
                              }}
                              inputName={field.name}
                              checkedValue={field.value}
                              readOnly={readOnly}
                              errorMessage={errors[field.name]}
                              required={true}
                            />
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup className={'d-flex align-items-top'}>
                        <Field 
                          id={'beetle-check'}
                          type={'checkbox'} 
                          name={'BarkBeetleOutbreakPreventionFlag'}
                          checked={values.BarkBeetleOutbreakPreventionFlag}
                          className={'form-check-input position-relative ml-0 mr-2'}
                          readOnly={readOnly}
                          disabled={readOnly}
                        /> 
                        <Label check for={'beetle-check'}>
                          Piles are composed of predominately ponderosa pine planned for burning between January and June to prevent bark beetle outbreaks AND other debris disposal alternatives are not available  
                        </Label>
                      </FormGroup>
                      <FormGroup className={'d-flex align-items-top'}>
                        <Field 
                          id={'wet-check'}
                          type={'checkbox'}
                          name={'WaitForWetConditionsFlag'}
                          checked={values.WaitForWetConditionsFlag}
                          className={'form-check-input position-relative ml-0 mr-2'}
                          readOnly={readOnly}
                          disabled={readOnly}
                        /> 
                        <Label check for={'wet-check'}>
                          Will you wait until wet conditions or snow are present before burning?
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={this.rowClasses}>
                    <Col>
                      <BurnPermitReferenceDirections
                        burnPermitSiteId={this.props.burnPermitSiteId}
                        readOnly={readOnly}
                        ref={this.setRefDirFormikRef}
                        onChange={this.onFormChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BurnPermitSiteEquipment
                        burnPermitSiteId={this.props.burnPermitSiteId}
                        isValidating={isValidating}
                        readOnly={readOnly}
                        ref={this.setSiteEquipFormikRef}
                        onChange={this.onFormChange}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Formik>
    }
    return <BurnPermitFormSection
      {...this.props}
      validateSection={this.validateSection}
      isValid={typeof this.state.isValid === 'boolean' ? this.state.isValid : ''}
    >
      { form }
    </BurnPermitFormSection>
  }
}

function mapStateToProps (state, props) {
  const { online, } = state.offline
  const { applications, activeBurnPermitId, } = state.BurnPermitForm
  const isActive = applications[activeBurnPermitId].activeStep === props.sectionId
  return {
    online,
    isActive,
    PlannedBurnDays         : daysOfWeekCheckboxSelector(state),
    ArrivalTime             : itemsByModelNameSelector(state, ARRIVAL_TIME),
    FireLineEquipmentType   : equipmentTypeCheckboxSelector(state, { category: FIRE_LINE_CONSTRUCTION, }),
    ExtinguishEquipmentType : equipmentTypeCheckboxSelector(state, { category: EXTINGUISHING, useDesc: true, }),
    burnPermitSite          : burnPermitSiteByIdSelector(state, props.burnPermitSiteId),
    ForestTypes             : forestTypesSelectSelector(state),
    BurnReasons             : burnReasonSelectSelector(state),
    BurnUnits               : burnUnitSelector(state),
  }
}
  
const mapDispatchToProps = {
  GetBurnPermitSiteInfo : BurnPermitSiteActions.getBurnPermitSiteInfo,
  UpdateBurnPermitSite  : BurnPermitSiteActions.updateBurnPermitSite,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitSiteSection)