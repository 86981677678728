// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Container, Row, Col, CardText,  } from 'reactstrap'
import { func, array, bool, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import ReportActions from '../redux/ReportsRedux'

// Components
import ReportCard from '../components/Reports/ReportCard'
import ReportMessageCard from '../components/Reports/ReportMessageCard'


export class ReportListContainer extends React.Component {
  static propTypes = {
    online         : bool,
    SetPageTitle   : func,
    GetReports     : func,
    reports        : array,
    reportsLoading : bool,
  }

  static defaultProps = {
    reports: [],
  }

  componentDidMount () {
    const { online, SetPageTitle, GetReports, } = this.props
    SetPageTitle('Burn Portal Reports')
    if (online) {
      GetReports()
    }
  }

  render () {
    const { online, reportsLoading, reports, } = this.props
    let children
    if (reportsLoading !== false) {
      children = <ReportMessageCard message={'Loading reports...'} />
    }
    else if (reportsLoading === false && reports.length === 0) {
      children = <ReportMessageCard message={'No reports were found or an error occurred.'} />
    }
    else {
      children = reports.map(r => {
        return <Col xs={12} md={4} key={`Report-${r.Name}`} className={'mb-2'}>
          <ReportCard {...r} />
        </Col>
      })
    }
    if (!online) {
      return <Container className={'mt-4'}>
        <Row><h1>Reports</h1></Row>
        <Row><ReportMessageCard message={'Accessing reports require an internet connection.'} /></Row>
      </Container>
    }
    return <Container className={'mt-4'}>
      <Row><h1>Reports</h1></Row>
      <Row><CardText><i>Reports not loading? Refresh your browser to try again.</i></CardText></Row>
      <Row>{children}</Row>
    </Container>
  }
}

function mapStateToProps (state) {
  const { reportsLoading, reports, } = state.Reports
  const { online, } = state.offline
  return {
    online,
    reports,
    reportsLoading,
  }
}

const mapDispatchToProps = {
  SetPageTitle : AppActions.setPageTitle,
  GetReports   : ReportActions.getReports,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportListContainer)