import { attr, Model, } from 'redux-orm'
import { renderAcres, renderCheckOrXIcon, renderDateTime, renderLatLong, renderTons, } from '../utilities/columnHelpers'
import { dateFormatter, } from '../utilities'

export default class BurnComplaintSearchResult extends Model {
  static get modelName () {
    return 'BurnComplaintSearchResult'
  }

  toString () {
    return `${this.BurnRequestId}`
  }

  static endpoint () {
    return 'BurnComplaintSearchResults'
  }

  static get options () {
    return {
      idAttribute: 'BurnRequestId',
    }
  }

  static getDataTablesColumns () {

    const cols = [
      {
        title : '',
        data  : 'IsAssociated',
      },
      {
        title     : 'Burn Permit ID',
        data      : 'BurnPermitId',
        className : 'text-right pr-4',
        type      : 'num',
      },
      {
        title     : 'Burn Request ID',
        data      : 'BurnRequestId',
        className : 'text-right pr-4',
        type      : 'num',
      },
      {
        title  : 'Miles Away',
        data   : 'MilesAway',
        render : (data, drawType, row) => {
          if (drawType !== 'display' || !data) {
            return data
          }
          return `${data.toFixed(2)} mi ${row.CardinalDirection}`
        },
      },
      {
        title     : 'Permit Number',
        data      : 'BurnPermitNumber',
        className : 'text-right pr-4',
      },
      {
        title : 'Unit Name',
        data  : 'BurnUnitName',
        width : '100px',
      },
      {
        defaultContent : '',
        title          : 'Landowner',
        data           : 'Landowner',
        width          : '150px',
      },
      {
        defaultContent : '',
        title          : 'Agent',
        data           : 'Agent',
        width          : '150px',
      },
      {
        defaultContent : '',
        title          : 'Agency',
        data           : 'Agency',
        width          : '150px',
      },
      {
        title     : 'Request Date',
        data      : 'BurnRequestDate',
        className : 'text-right pr-4',
        render    : renderDateTime,
        width     : '75px',
      },
      {
        title  : 'Status',
        data   : 'BurnRequestStatus',
        width  : '75px',
        render : (data, drawType, row) => {
          if (drawType === 'sort') {
            return row.BurnRequestStatusDate
          }
          if (drawType !== 'display') {
            return row.BurnRequestStatus
          }
          else if (!data) {
            return data
          }
          return `${row.BurnRequestStatus}<br/>${dateFormatter(row.BurnRequestStatusDate, 'MMM DD, YYYY')}`
        },
      },
      {
        title  : 'Ignition Date',
        data   : 'PlannedIgnitionDate',
        render : (data, drawType, row) => {
          if (drawType !== 'display') {
            return data
          }
          return `${dateFormatter(data, 'MMM DD, YYYY')} ${row.PlannedIgnitionTime}`
        },
      },
      {
        title     : 'Post Burn Date',
        data      : 'PostBurnDate',
        className : 'text-right pr-4',
        render    : renderDateTime,
      },
      {
        title  : 'In UGA',
        data   : 'IsUGA',
        render : renderCheckOrXIcon,
      },
      {
        title          : 'Forest Health Exempt',
        data           : 'IsForestHealthExempt',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType === 'sort') {
            return data === null ? 0 : data === true ? 1 : -1
          }
          if (drawType !== 'display') {
            return data
          }
          if (data === null) {
            return data
          }
          return renderCheckOrXIcon(data, drawType)
        },
      },
      {
        title     : 'Permit Tonnage',
        data      : 'TotalPermitTonnage',
        className : 'text-right pr-4',
        render    : renderTons,
      },
      {
        title     : 'Post Burn Tonnage',
        data      : 'PostBurnTonnage',
        className : 'text-right pr-4',
        render    : renderTons,
      },
      {
        title     : 'Post Burn Area',
        data      : 'PostBurnArea',
        className : 'text-right pr-4',
        render    : renderAcres,
      },   
      {
        title   : 'Lat, Long',
        data    : '',
        visible : false,
        render  : renderLatLong,
      },
    ]

    return cols
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnRequestId         : attr(),
      BurnPermitId          : attr(),
      BurnPermitNumber      : attr(),
      MilesAway             : attr(),
      CardinalDirection     : attr(),
      TotalPermitTonnage    : attr(),
      BurnUnitName          : attr(),
      Landowner             : attr(),
      Agency                : attr(),
      Agent                 : attr(),
      BurnRequestDate       : attr(),
      PlannedIgnitionDate   : attr(),
      PlannedIgnitionTime   : attr(),
      PostBurnDate          : attr(),
      PostBurnTonnage       : attr(),
      PostBurnArea          : attr(),
      County                : attr(),
      Region                : attr(),
      IsUGA                 : attr(),
      BurnRequestStatus     : attr(),
      BurnRequestStatusDate : attr(),
      IsForestHealthExempt  : attr(),
      Latitude              : attr(),
      Longitude             : attr(),
    }
  }
}
