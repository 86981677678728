import React from 'react'
import { Button, } from 'reactstrap'
import { string, func, } from 'prop-types'

const CalculateButton = ({ onClick, size, color, buttonText, }) => {
  const onCalculateClick = React.useCallback(() => onClick(), [ onClick, ])
  return <Button
    size={size}
    color={color}
    onClick={onCalculateClick}
  >
    {buttonText}
  </Button>
}

CalculateButton.defaultProps = {
  onClick    : () => {},
  size       : 'sm',
  color      : 'info',
  buttonText : 'Calculate',
}

CalculateButton.propTypes = {
  onClick    : func,
  buttonText : string,
  size       : string,
  color      : string,
}

export default CalculateButton