import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { number, } from 'prop-types'

// Components
import DocuSignModal from './DocuSignModal'

// Actions
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'

// Selectors
import { userIsDNR, } from '../../selectors/userSelectors'


const SignApplication = props => {
  const { burnPermitId, } = props

  const userIsDnr = useSelector(userIsDNR)

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks
  const onClick = useCallback(() => {
    dispatch(BurnPermitSignatureActions.signPermitApplication(burnPermitId))
  }, [ dispatch, burnPermitId, ])

  if (userIsDnr) {
    return null
  }

  return <DocuSignModal
    onClick={onClick}
    modalHeader={'Sign Burn Permit Application'}
    buttonText={'Sign'}
    disableModalLaunchBtn={true}
    signatureType={SignatureProcessTypes.application}
  />
}

SignApplication.defaultProps = {
  burnPermitId: null,
}

SignApplication.propTypes = {
  burnPermitId: number,
}

export default React.memo(SignApplication)
