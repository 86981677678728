// Libraries
import React from 'react'
import { Row,  Col, Label, } from 'reactstrap'
import { bool, array, func, } from 'prop-types'
// Components
import { RuleForm, } from './RuleForm'

export default class RuleListForm extends React.Component {
  static propTypes = {
    rules             : array,
    canEdit           : bool,
    onSubmit          : func,
    rulesUpdating     : array,
    rulesUpdated      : array,
    rulesUpdateFailed : array,
    resetLoad         : func,
  }

  static defaultProps = {
    rules    : [],
    canEdit  : false,
    onSubmit : () => console.error('Submit method was not provided'),
  }

  state = {
    editing: [],
  }

  componentDidMount () {
    this.props.resetLoad()
  }

  componentDidUpdate ({ rulesUpdated = [], rulesUpdateFailed = [], }) {
    const newRulesUpdated = this.props.rulesUpdated || []
    if (newRulesUpdated.length > 0) {
      const diff = newRulesUpdated.filter(x => !rulesUpdated.includes(x))
      if (diff.length > 0){
        this.rulesUpdated(diff)
      }
    }

    const newRulesUpdateFailed = this.props.rulesUpdateFailed || []
    if (newRulesUpdateFailed.length > 0) {
      const diff = newRulesUpdateFailed.filter(x => !rulesUpdateFailed.includes(x))
      if (diff.length > 0) {
        this.rulesFailed(diff)
      }
    }
  }

  rulesFailed = (ids = []) => {
    this.props.resetLoad(ids)
  }

  rulesUpdated = (ids = []) => {
    const { editing = [], } = this.state
    const nextState = editing.filter(x => !ids.includes(x))
    this.props.resetLoad(ids)
    this.setState({ editing: nextState, })
  }

  updateRule = (values) => {
    this.props.onSubmit(values.RuleId, values)
  }

  setEditState = (id, handleReset = null) => {
    const { editing = [], } = this.state
    if (!id) {
      return
    }
    let nextState = null
    if (!editing.includes(id)) {
      nextState = editing.concat(id)
    } else {
      nextState = editing.filter(x => x !== id)
    }
    if (nextState) {
      this.props.resetLoad(id)
      if (handleReset) {
        handleReset()
      }
      this.setState({ editing: nextState, })
    }
  }

  render () {
    const { 
      canEdit, 
      rules, 
      rulesUpdated,
    } = this.props
    const { editing, } = this.state
    const colSizes = canEdit ? [ 1, 2, 1, 4, 3, ] : [ 1, 2, 1, 4, 4, ]
    

    return (
      <>
        <Row className={'rule-table-header'}>
          <Col md={colSizes[0]}>#</Col>
          <Col md={colSizes[1]}>Name</Col>
          <Col md={colSizes[2]}><Label for={'RuleEnabled'} id={'RuleEnabledLabel'}>Enabled</Label></Col>
          <Col md={colSizes[3]}><Label for={'RuleEnabled'} id={'RuleDescLabel'}>Description</Label></Col>
          <Col md={colSizes[4]}><Label for={'RuleValue'} id={'RuleValueLabel'}>Value</Label></Col>
        </Row>
        <hr/>
        {rules.map((r) => {
          const { RuleId, } = r
          const isEditing = editing.includes(RuleId)
          const updated = rulesUpdated.includes(RuleId)
          return <div key={'rule-wrap-' + RuleId}>
            <RuleForm
              canEdit={canEdit}
              readOnly={!isEditing}
              rule={r}
              onToggleEdit={this.setEditState}
              onSubmit={this.updateRule}
              colSizes={colSizes}
              updated={updated}
            />
            <hr/>
          </div>
        })}
      </>
    )
  }
}