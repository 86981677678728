// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { isEqual, } from 'lodash'
import { object, array, func, bool, } from 'prop-types'
import { Container, Row, Col, Card, CardBody, CardHeader, FormGroup, Label, Input, ListGroup, ListGroupItem, } from 'reactstrap'
import { render as ReactDOMRender, } from 'react-dom'

// Components
import { PersonForm, } from '../components/Forms'
import DataTable from '../components/DataTable'
import withRouter from '../components/withRouter'

// Actions
import AppActions from '../redux/AppRedux'
import PersonActions from '../redux/PersonRedux'

// Selectors
import { activePersonFormSelector, } from '../selectors/personSelectors'
import { permitSearchSelector, } from '../selectors/burnPermitSelectors'
import { burnRequestsSelector, } from '../selectors/burnRequestSelectors'
import {
  personSelector,
  personPhoneSelector,
  personAddressesSelector,
  personAlertPrefXrefSelector,
  personAgencyXrefSelector,
  personRegionXrefSelector,
  personEmailSelector,
} from '../selectors/personSelectors'

// Models
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnRequestSearch from '../models/BurnRequestSearch'

// Utilities
import stopEvent from '../utilities/stopEvent'


export class PersonContainer extends React.Component {

  constructor (props) {
    super(props)

    const { navigate, } = this.props
    this.burnPermitColumnDefs = [
      {
        targets     : 0,
        createdCell : (td, cellData, rowData) =>
          ReactDOMRender(
            rowData.PermitOrderId
              ? <a href={`/permits/orders/${rowData.PermitOrderId}`}
                className={'text-dark'}
                onClick={e => {
                  stopEvent(e)
                  navigate(`/permits/orders/${rowData.PermitOrderId}`)
                }}>{rowData.PermitOrderId}</a>
              : !rowData.BurnPermitNumber || (rowData.BurnPermitNumber && rowData.AgencyRegionApproval)
                ? ''
                : 'N/A'
            , td
          ),
      },
      {
        targets     : 1,
        createdCell : (td, cellData) =>
          ReactDOMRender(
            <a href={`/permits/${cellData}`}
              onClick={evt => {
                stopEvent(evt)
                navigate(`/permits/${cellData}`)
              }}>{cellData}</a>
            , td
          ),
      },
    ]
    this.burnReqColumnDefs = [
      {
        targets     : 0,
        createdCell : (td, cellData) =>
          ReactDOMRender(
            <a href={`/burn-requests/${cellData}`}
              onClick={evt => {
                stopEvent(evt)
                navigate(`/burn-requests/${cellData}`)
              }}>{cellData}</a>
            , td
          ),
      },
    ]
  }

  static propTypes = {
    // from withRouter HOC
    params   : object,
    location : object,
    navigate : func,
    
    User             : object,
    actions          : object,
    App              : object,
    userGroups       : array,
    SetPageTitle     : func,
    GetPerson        : func,
    DeactivatePerson : func,
    NewPerson        : func,
    isAgent          : bool,
    person           : object,
    addresses        : object,
    phones           : object,
    permits          : array,
    requests         : array,
    alertPrefs       : object,
    region           : object,
    email            : object,
    agency           : object,
    isNewPerson      : bool,
  }

  static defaultProps = {
    shouldGeocode : true,
    isAgent       : false,
  }

  state = {
    UserInfoError : '',
    copiedMessage : '',
  }

  componentWillUnmount () {
    this.props.DeactivatePerson()
  }

  componentDidMount () {
    const {
      params,
      DeactivatePerson,
      NewPerson,
    } = this.props
    DeactivatePerson()
    if (params && params.id && !isNaN(params.id)) {
      const {
        person,
        GetPerson,
        SetPageTitle,
      } = this.props
      const id = parseInt(params.id)
      // don't activate person unless there's a person id
      // it gets passed to the saga
      // Pass an object that states we should search for their permits and
      // requests and to clear out any previous search results
      GetPerson(id, { permits: true, requests: true, clearPrevious: true, })
      if (person) {
        SetPageTitle(`${person.PersonFirstName} ${person.PersonLastName}`)
      }
    }
    else {
      NewPerson()
    }
  }

  componentDidUpdate (prevProps) {
    const { person, params, location, } = this.props
    // Handles the scenario where a user creates a new Person, saves,
    // then uses the browser navigation tools to navigate back to the new page
    if (prevProps.location.pathname !== location.pathname && location.pathname.endsWith('/new')) {
      this.props.NewPerson()
    }
    const personId = parseInt(params.id)
    if (person && Number.isInteger(person.PersonId) && Number.isInteger(personId) && person.PersonId !== personId) {
      this.props.GetPerson(personId)
    }
    else if (prevProps.location.pathname.endsWith('/new') && Number.isInteger(personId)) {
      this.props.GetPerson(personId)
    }
    if (!isEqual(prevProps.person, this.props.person) && this.props.person) {
      this.props.SetPageTitle(`${this.props.person.PersonFirstName} ${this.props.person.PersonLastName}`)
    }
  }

  render () {
    const {
      person,
      isAgent,
      addresses,
      phones,
      alertPrefs,
      email,
      region,
      agency,
      permits,
      requests,
    } = this.props
    let title = ''
    if (person && person.CreateBy) {
      title = `${person.PersonFirstName} ${person.PersonLastName}`
    }
    else {
      title = 'New Person'
    }
    return (
      <Container className={'my-3'}>
        <Row className={'mt-3'}>
          <Col>
            <h1><Link to={'/admin/people'}>People</Link> - {title}</h1>
          </Col>
        </Row>
        <Row className={'mb-3'}>
          <Col>
            <Card>
              <CardBody>
                {
                  person && <>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for={'username'}>Username</Label>
                          <Input id={'username'} value={person.UserName || 'Not a User'} readOnly />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Groups</Label>
                          <ListGroup>
                            {
                              Array.isArray(person.UserGroups)
                                ? person.UserGroups.map(g => <ListGroupItem key={g}>{g}</ListGroupItem>)
                                : <ListGroupItem>Not a User</ListGroupItem>
                            }
                          </ListGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr style={{ marginBottom: '2em', }}/>
                  </>
                }
                <PersonForm
                  person={person}
                  showAddresses={true}
                  addresses={addresses}
                  showPhones={true}
                  phones={phones}
                  showEmails={true}
                  email={email}
                  showTypes={true}
                  shouldGeocode={false}
                  isAgent={isAgent}
                  isNewPerson={this.props.isNewPerson}
                  alertPrefs={alertPrefs}
                  agency={agency}
                  region={region}
                  showRegion={true}
                  enableAgencyConversion={person && !person.ConvertedToAgency}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {
          !!person && <>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5>Permits</h5>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      columns={BurnPermitSearch.getDataTablesColumns({ showLandownerAgent: true, })}
                      columnDefs={this.burnPermitColumnDefs}
                      records={permits}
                      elementId={'person-permit-table'}
                      disableRowClick={true}
                      disableDefaultOrdering={true}
                      onAjaxError={req => {
                        console.error(req)
                        console.dir(req)
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'my-3'}>
              <Col>
                <Card>
                  <CardHeader>
                    <h5>Burn Requests</h5>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      columns={BurnRequestSearch.getDataTablesColumns({ showLandownerAgent: true, })}
                      columnDefs={this.burnReqColumnDefs}
                      records={requests}
                      elementId={'person-request-table'}
                      disableRowClick={true}
                      disableDefaultOrdering={true}
                      onAjaxError={req => {
                        console.error(req)
                        console.dir(req)
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        }
      </Container>
    )
  }
}

function mapStateToProps (state) {
  const activePerson = activePersonFormSelector(state)
  const person = personSelector(state, activePerson)
  const permits = permitSearchSelector(state)
  const requests = burnRequestsSelector(state)
  return {
    person,
    permits,
    requests,
    isNewPerson : state.Person.isNewPerson,
    addresses   : personAddressesSelector(state, activePerson),
    phones      : personPhoneSelector(state, activePerson),
    alertPrefs  : personAlertPrefXrefSelector(state, activePerson),
    agency      : personAgencyXrefSelector(state, activePerson),
    region      : personRegionXrefSelector(state, activePerson),
    email       : personEmailSelector(state, activePerson),
  }
}

const mapDispatchToProps = {
  SetPageTitle     : AppActions.setPageTitle,
  GetPerson        : PersonActions.getPerson,
  NewPerson        : PersonActions.newPerson,
  DeactivatePerson : PersonActions.deactivatePerson,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PersonContainer))