// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { array, func, object, } from 'prop-types'
import { Toast, ToastBody, ToastHeader, } from 'reactstrap'

// Actions
import ApiActions from '../redux/ApiRedux'

// Components
import withRouter from './withRouter'

class GeneralMessage extends React.Component {

  static propTypes = {
    // from withRouter HOC
    location: object,

    generalMessages         : array,
    ClearGeneralMessage     : func,
    ClearAllGeneralMessages : func,
  }

  static defaultProps = {
    generalMessages: [],
  }

  onClick = evt => {
    const { id, } = evt.target.dataset
    this.props.ClearGeneralMessage(id)
  }

  // This should clear the Messages on change of the route
  componentDidUpdate (prevProps) {
    const { location, ClearAllGeneralMessages, } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      ClearAllGeneralMessages()
    }
  }
  
  render () {
    const { generalMessages, } = this.props
    if (generalMessages.length === 0) {
      return null
    }
    const messages = []
    for (let i = 0, len = generalMessages.length; i < len; i++) {
      const Message = generalMessages[i]
      messages.push(
        <Toast className={'general-message'} key={'general-message-' + i}>
          <ToastHeader className={'pl-2 pr-1'}>
            <span className={'fa fa-exclamation-circle mr-2'}></span>
            { Message.title }
            <small className={'ml-2'}>{Message.occurredAt}</small>
            <button
              className={'ml-2 mb-1 close fa fa-times'}
              data-dismiss={'toast'}
              aria-label={'Close'}
              data-id={Message.id}
              onClick={this.onClick}
            ></button>
          </ToastHeader>
          <ToastBody>
            { Message.message }
          </ToastBody>
        </Toast>
      )
    }
    return <div className={'general-messages'}>{messages}</div>
  }
}
  
const mapStateToProps = (state) => {
  return {
    generalMessages: state.api.generalMessages,
  }
}

const mapDispatchToProps = {
  ClearGeneralMessage     : ApiActions.clearGeneralMessage,
  ClearAllGeneralMessages : ApiActions.clearAllGeneralMessages,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralMessage))