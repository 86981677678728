// Libraries
import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, } from 'reactstrap'
import { bool, } from 'prop-types'

// Actions
import BurnPermitOrderActions from '../../redux/BurnPermitOrderRedux'

// Components
import PermitAppLink from './PermitAppLink'
import Instructions from '../Instructions'

// Selectors
import { newBurnPermitOrderId, } from '../../selectors/orderSelectors'
import { signatureCallbackState, } from '../../selectors/burnPermitSignatureSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'


const ApplicationSigned = ({ enableCreateOrder, }) => {

  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const { BurnPermitId, } = useSelector(signatureCallbackState)
  const orderId = useSelector(newBurnPermitOrderId)

  // UI Callbacks
  const createOrder = React.useCallback((e) => {
    stopEvent(e)
    if (enableCreateOrder) {
      dispatch(BurnPermitOrderActions.createBurnPermitOrder([ BurnPermitId, ]))
    }
  }, [ dispatch, enableCreateOrder, BurnPermitId, ])

  React.useEffect(() => {
    if (orderId > 0) {
      navigate(`/permits/orders/${orderId}`)
    }
  }, [ navigate, orderId, ])

  return <Card>
    <CardHeader>
      <div className={'d-flex justify-content-between'}>
        <h3>Successfully Signed Application #{BurnPermitId}!</h3>
        <PermitAppLink BurnPermitId={BurnPermitId}/>
      </div>
    </CardHeader>
    <CardBody>
      <Instructions burnPermitId={BurnPermitId} />
    </CardBody>
    <div className={'d-flex justify-content-between p-4'}>
      <Link
        to={'/permits/new'}
        className={'p-3'}>
          New Application
      </Link>
      {
        enableCreateOrder && <Button
          color={'secondary'}
          onClick={createOrder}
        >
          Create Order
        </Button>
      }
    </div>
  </Card>
}

ApplicationSigned.defaultProps = {
  enableCreateOrder: false,
}

ApplicationSigned.propTypes = {
  enableCreateOrder: bool,
}

export default React.memo(ApplicationSigned)