// Libraries
import React, { useMemo, } from 'react'
import { bool, string, func, } from 'prop-types'
import SearchInput from './SearchInput'

const PermitNumber = props => {
  const {
    value,
    name,
    onChange,
    onBlur,
    showPopover,
  } = props
  const popoverBody = useMemo(() => {
    return <>Use the <b>Burn Permit Number</b> to find Burn Requests associated with a Burn Permit.</>
  }, [])
  return <SearchInput
    label={'Permit Number'}
    labelClassName={'m-0'}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    showPopover={showPopover}
    popoverHeader={'Burn Permit Number'}
    popoverBody={popoverBody}
  />
}

PermitNumber.propTypes = {
  name        : string,
  value       : string,
  onChange    : func,
  onBlur      : func,
  showPopover : bool,
}

PermitNumber.defaultProps = {
  name: 'BurnPermitNumber',
}

export default React.memo(PermitNumber)
