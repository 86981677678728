import { ormSelector, ormByIdSelector, } from './selectors'



export const permitForestHealthData = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.ForestHealthExemptId) {
      return null
    }

    let problemType
    if (permit.ForestHealthExempt && permit.ForestHealthExempt.ForestHealthProblemTypes && permit.ForestHealthExempt.ForestHealthProblemTypes.exists()) {
      problemType = permit.ForestHealthExempt.ForestHealthProblemTypes.first()
    }

    return {
      BurnPermitId                      : permit.BurnPermitId,
      ForestHealthExemptId              : permit.ForestHealthExemptId,
      ProblemTypeId                     : problemType ? problemType.ForestHealthProblemTypeId : '',
      AlternativeToRxFire               : permit.ForestHealthExempt ? permit.ForestHealthExempt.AlternativeToRxFire: '',
      ForestHealthExemptionApprovalFlag : permit.ForestHealthExempt ? permit.ForestHealthExempt.ForestHealthExemptionApprovalFlag: '',
      AlternativeNotAppropriateReason   : permit.ForestHealthExempt && permit.ForestHealthExempt.ForestHealthExemptionApprovalFlag ? permit.ForestHealthExempt.AlternativeNotAppropriateReason: '',
      ForestHealthExemptionApprovalDate : permit.ForestHealthExempt && permit.ForestHealthExempt.ForestHealthExemptionApprovalFlag ? permit.ForestHealthExempt.ForestHealthExemptionApprovalDate || permit.ForestHealthExempt.CreateDate: '',
      ForestHealthExemptionApprovalBy   : permit.ForestHealthExempt && permit.ForestHealthExempt.ForestHealthExemptionApprovalFlag ? permit.ForestHealthExempt.ForestHealthExemptionApprovalBy || permit.ForestHealthExempt.CreateBy: '',
    }
  }
)

export const problemTypesSelector = ormSelector(
  ({ ForestHealthProblemType, }) => {
    if (!ForestHealthProblemType) {
      return []
    }
    return ForestHealthProblemType.all().toRefArray().map(t => {
      return { Value: t.ForestHealthProblemTypeId, Text: t.ForestHealthProblemTypeName, }
    })
  }
)


export const permitNeedsForestHealthDoc = ormByIdSelector(
  ({ BurnPermit, BurnPermitDocument, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {	
      return false	
    }

    const permit = BurnPermit.withId(BurnPermitId)	
    if (!permit) {	
      return false	
    }

    let hasForestHealthBurnReason = false
    if (permit.BurnPermitSite && permit.BurnPermitSite.BurnReason) {
      hasForestHealthBurnReason = permit.BurnPermitSite.BurnReason.BurnReasonName === 'FOREST HEALTH'
    }

    if (permit.ForestHealthExemptId !== null || hasForestHealthBurnReason) {
      const hasForestHealthDoc = BurnPermitDocument.filter({ BurnPermitId, }).toModelArray()
        .some(d => d.BurnPermitDocumentType && d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Forest Health Burn Documentation')
  
      return !hasForestHealthDoc
    }

    return false
  }
)