import { useCallback, } from 'react'

// Actions
import BurnPermitFormActions from '../redux/BurnPermitFormRedux'

const useDownloadPermitFile = (dispatch, burnPermitId, fileInfo) => {
  return useCallback(() => {
    const {
      fileName,
      documentId,
    } = fileInfo
    dispatch(BurnPermitFormActions.downloadPermitFile(burnPermitId, fileName, documentId))
  }, [ dispatch, burnPermitId, fileInfo, ])
}

export default useDownloadPermitFile
