// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Row, Col, Button, } from 'reactstrap'
import { bool, string, number, func, array, object, PropTypes, } from 'prop-types'

// Redux
import BurnPermitFormActions from '../../redux/BurnPermitFormRedux'

// Selectors
import { permitStatus, } from '../../selectors/selectors'
import { activeBurnPermitStateSelector, permitApplicationStatus, } from '../../selectors/burnPermitSelectors'

// Utilities
import { scrollToErrors, } from '../../utilities'

const SUPPORTS_SMOOTH_SCROLL = 'scrollBehavior' in document.documentElement.style

class BurnPermitFormSection extends React.Component {

  static propTypes = {
    isOpen             : bool,
    isDisabled         : bool,
    isComplete         : bool,
    validationError    : string,
    title              : string.isRequired,
    sectionId          : number.isRequired,
    validateSection    : func,
    shouldValidate     : bool,
    children           : PropTypes.oneOfType([ object, array, ]),
    PreviousStep       : func,
    NextStep           : func,
    SavedAndNextStep   : func,
    InvalidStep        : func,
    isInvalid          : bool,
    isValid            : PropTypes.oneOfType([ bool, string, ]),
    showControls       : bool,
    readOnly           : bool,
    canValidate        : bool,
    sectionBadge       : object,
    disableBack        : bool,
    disableContinue    : bool,
    hideContinue       : bool,
    preContinueControl : PropTypes.oneOfType([ object, ]),
    continueText       : string,
    continueFunc       : func,
  }

  static defaultProps = {
    validationError    : '',
    validationErrors   : [],
    hideContinue       : false,
    disableContinue    : false,
    showControls       : true,
    readOnly           : false,
    disableBack        : false,
    preContinueControl : null,
    continueText       : 'Continue',
  }

  state = {
    isValidating: false,
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isOpen !== true && this.props.isOpen === true) {
      this.onEntered()
    }
  }

  validationErrors = () => {
    const markup = []
    const { validationError, } = this.props
    if (validationError !== '') {
      const errors = validationError.split(';')
      for (let i = 0, len = errors.length; i < len; i++) {
        const error = errors[i]
        markup.push(
          <li className={'text-danger is-invalid'} key={'error-' + i}>{error}</li>
        )
      }
    }
    return markup
  }

  saveAndNextStep = (evt) => {
    const {
      sectionId,
      validateSection,
      InvalidStep,
      SavedAndNextStep,
    } = this.props
    const domId = `#burn-permit-section-${sectionId}`
    const isValid = typeof validateSection === 'function'
      ? validateSection({ evt, submit: true, })
      : true
    if (typeof isValid === 'boolean') {
      if (isValid) {
        // Formik will cancel any submission actions if it's ripped from the DOM
        // Add a timeout so that the submit event finishes in its
        // event loop and this occurs in the next
        setTimeout(() => SavedAndNextStep(sectionId), 100)
      }
      else {
        scrollToErrors(domId)
        InvalidStep(sectionId)
      }
    }
    else if (isValid instanceof Promise) {
      isValid.then(resp => {
        if (resp !== false) {
          // Formik will cancel any submission actions if it's ripped from the DOM
          // Add a timeout so that the submit event finishes in its
          // event loop and this occurs in the next
          setTimeout(() => SavedAndNextStep(sectionId), 100)
        }
        else {
          scrollToErrors(domId)
          InvalidStep(sectionId)
        }
      })
    }
  }
  
  onEntered () {
    if (typeof this.props.validateSection === 'function' && (this.props.shouldValidate || this.props.isInvalid)) {
      this.props.validateSection({ submit: false, })
    }
    let sectionCol = document.querySelector('#burn-permit-section-' + this.props.sectionId)
    if (sectionCol && !this.state.isValidating) {
      let { top, } = sectionCol.getBoundingClientRect()
      top = (window.pageYOffset + top) - 100
      if (SUPPORTS_SMOOTH_SCROLL) {
        window.scrollTo({ top, behavior: 'smooth', })
      }
      else {
        window.scrollTo(0, top)
      }
    }
    this.setState({ isValidating: false, })
  }

  previousClick = () => {
    this.props.PreviousStep()
  }

  nextClick = () => {
    this.props.NextStep()
  }

  render () {

    if (this.props.isOpen !== true) {
      return null
    }

    const {
      isDisabled,
      sectionId,
      disableContinue,
      hideContinue,
      disableBack,
      showControls,
      children,
    } = this.props

    let classes = 'burn-permit-section pt-2'

    if (isDisabled) {
      classes += ' disabled'
    }

    return (
      <div className={classes} id={`burn-permit-section-${sectionId}`}>
        <div className={'col-12 pb-2 px-0'}>
          <Row>
            <ul className={'m-0'}>
              {this.validationErrors()}
            </ul>
          </Row>
          <Row>
            { children }
          </Row>
          {
            showControls &&
              <Row>
                <Col className={`d-flex justify-content-${disableBack ? 'end' : 'between'} py-3`}>
                  <>
                    {
                      disableBack
                        ? null
                        : <Button
                          color={'light'}
                          onClick={this.previousClick}
                          className={'mr-2'}
                        >
                          Back
                        </Button>
                    }
                    {
                      hideContinue
                        ? null
                        : <div>
                          { this.props.preContinueControl }
                          <Button
                            color={'secondary'}
                            disabled={disableContinue || this.props.isInvalid}
                            onClick={this.props.continueFunc || (this.props.readOnly ? this.nextClick : this.saveAndNextStep)}
                          >
                            { this.props.continueText }
                          </Button>
                        </div>
                    }
                  </>
                </Col>
              </Row>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { sectionId, isValid, } = props
  
  const {
    activeStep,
    completedSteps,
    invalidSteps,
    shouldValidate,
    activeBurnPermitId,
  } = activeBurnPermitStateSelector(state)
  let canValidate = false

  const permitId = props.activeBurnPermit || activeBurnPermitId
  if (permitId) {
    let status = permitStatus(state, permitId)
    if (!status) {
      status = permitApplicationStatus(state, permitId)
      if (status && (status.Status === 'Pending' || status.Status === 'Info Required')) {
        canValidate = true
      }
    }
  }
  return {
    isOpen     : props.isOpen || sectionId === activeStep,
    isComplete : completedSteps.includes(sectionId),
    isInvalid  : typeof isValid === 'boolean' ? !isValid : invalidSteps.includes(sectionId),
    shouldValidate,
    canValidate,
  }
}

const mapDispatchToProps = {
  PreviousStep     : BurnPermitFormActions.previousStep,
  NextStep         : BurnPermitFormActions.nextStep,
  SavedAndNextStep : BurnPermitFormActions.savedAndNextStep,
  InvalidStep      : BurnPermitFormActions.invalidStep,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitFormSection)