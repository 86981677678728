import { attr, many, Model, } from 'redux-orm'
import * as Yup from 'yup'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnPermitArea extends Model {
  static get modelName () {
    return 'BurnPermitArea'
  }

  toString () {
    return `${this.BurnPermitAreaId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermitAreas`
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitAreaId',
    }
  }

  static get validationSchema () {

    return Yup.object().shape({
      HarvestAcres: Yup.number()
        .min(.01, 'Acres must be a number greater than 0.')
        .required('Acres must be a number greater than 0.'),
      BurnTypes: Yup.array()
        .min(1, 'You must choose one or more burn type.')
        .required('You must choose one or more burn type.'),
    })
  }

  static get fields () {
    return {
      // Common Properties
      BurnPermitAreaId : attr({ type: 'number', }),
      CreateDate       : attr(),
      CreateBy         : attr(),
      UpdateDate       : attr(),
      UpdateBy         : attr(),
  
      // Model-Specific properties
      HarvestAcres : attr({ type: 'number', inputType: 'number', inputLabel: 'Size of harvest area (acres)', }),
      // Relationships
      BurnTypes    : many({ to: 'BurnType', relatedName: 'BurnPermitAreas', }),
    }
  }
}