// Libraries
import React, { Component, } from 'react'
import { connect, } from 'react-redux'
import { bool, string, object, PropTypes, } from 'prop-types'
import { Progress, } from 'reactstrap'

class LoadingBar extends Component {

  static propTypes = {
    showLoading : bool.isRequired,
    show        : PropTypes.oneOfType([ bool, string, ]),
    className   : string,
    style       : object,
  }

  static defaultProps = {
    className: '',
  }
  
  render () {
    const { showLoading, show, className, style, } = this.props
    if (show === false) {
      return null
    }

    return <Progress
      value={'100'}
      color={showLoading || show ? 'secondary' : 'dark'}
      animated={showLoading || show}
      className={`progress-bar ${className}`}
      style={{ ...style, }}
    />
  }
}
  
function mapStateToProps (state) {
  return {
    showLoading: state.app.showLoading,
  }
}

export default connect(mapStateToProps)(LoadingBar)