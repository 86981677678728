// Libraries
import { put, all, call, fork, select, takeEvery, takeLatest, } from 'redux-saga/effects'

// Sagas
import { getLookupData, doFetch, showError, apiSuccess, extractPayload, } from './ApiSagas'
import { upsertLocalModel, upsertLocalModels, updateLocalModel, replaceAll, destroyLocalModel, destroyLocalModels, } from './OrmSagas'
import { getBurnPermitUnits, getBurnPermit as getBurnPermitSaga, } from './BurnPermitSagas'

// Reducers
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'
import BurnPermitSiteActions, { BurnPermitSiteTypes, } from '../redux/BurnPermitSiteRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'

// Models
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnPermitSite from '../models/BurnPermitSite'
import BurnPermitSiteEquipmentXref from '../models/BurnPermitSiteEquipmentXref'

// Selectors
import { getBurnPermit as getBurnPermitSelector, } from '../selectors/selectors'

import {
  networkStateSelector,
  getBurnPermitId,
} from '../selectors/selectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'

// Constants
const BURN_PERMIT_SITE_ENDPOINT = BurnPermitSite.endpoint()
const BURN_PERMIT_SITE_MODEL_NAME = BurnPermitSite.modelName
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName
const EQUIPMENT_XREF_MODEL_NAME = BurnPermitSiteEquipmentXref.modelName


function* getPermitSiteLookupData () {
  // The getLookupData saga will update the ORM for us
  // so we don't need to worry about the response
  yield fork(getLookupData, { modelName: 'ArrivalTime', })
  yield fork(getLookupData, { modelName: 'Distance', })
  yield fork(getLookupData, { modelName: 'Direction', })
  yield fork(getLookupData, { modelName: 'DaysOfWeek', })
  yield fork(getLookupData, { modelName: 'ReferenceDirectionType', })
  yield fork(getLookupData, { modelName: 'EquipmentType', })
  yield fork(getLookupData, { modelName: 'ForestType', })
  yield fork(getLookupData, { modelName: 'BurnReason', })
  yield fork(getLookupData, { modelName: 'ForestHealthProblemType', })
}


/**
 * Get the Burn Permit Site information for the provided Burn Permit Site ID.
 * Will also request the lookup data to map the statuses to for the controls
 * @param {number} burnPermitSiteId
 */
export function* getBurnPermitSiteInfo ({ burnPermitSiteId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (!burnPermitSiteId) {
      yield call(showError, 'You must provide a Burn Permit Site ID in order to retrieve Site Information.')
      return
    }
    
    const burnPermit = yield select(getBurnPermitSelector, { BurnPermitSiteId: burnPermitSiteId, })
    const burnPermitId = burnPermit.BurnPermitId

    yield all([
      call(getBurnPermitUnits, { burnPermitId, }),
      call(getPermitSiteLookupData),
    ])

    // Creates the Burn Permit Site Urls
    const permitSiteUrl = `${BURN_PERMIT_SITE_ENDPOINT}/${burnPermitSiteId}`
    
    const permitSiteResp = yield call(doFetch, permitSiteUrl)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitSiteResp.ok === false) {
      yield call(showError, `An error occurred fetching the Burn Permit Site Information for ID: ${burnPermitSiteId}.`)
      return
    }

    return yield call(upsertBurnPermitSite, permitSiteResp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* downloadBurnPermitSiteInfo () {
  try {
    const permitSiteResp = yield call(doFetch, `${BURN_PERMIT_SITE_ENDPOINT}/Download`)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitSiteResp.ok === false) {
      yield call(showError, 'An error occurred downloading the Burn Permit Site Information.')
      return
    }

    const {
      permitSites,
      equipXrefs,
      burnDays,
      referenceDirections,
    } = permitSiteResp.responseBody

    if (Array.isArray(permitSites)) {
      const sagas = []
      for (let i = 0, len = permitSites.length; i < len; i++) {
        const site = permitSites[i]
        const { BurnPermitSiteId, } = site
        site.IsLocal = false
        site.BurnPermitSiteEquipment = equipXrefs.filter(x => x.BurnPermitSiteId === BurnPermitSiteId)
        site.DaysOfWeek = burnDays.filter(x => x.BurnPermitSiteId === BurnPermitSiteId).map(x => x.DayOfWeekId)
        site.BurnPermitSiteReferenceDirections = referenceDirections.filter(x => x.BurnPermitSiteId === BurnPermitSiteId)

        sagas.push(call(upsertBurnPermitSite, site))
      }
      yield all(sagas)
    }
    
    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'SiteInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}


function* updateBurnPermitSite ({ burnPermitSite, }) {
  const { online, } = yield select(networkStateSelector)
  const { BurnPermitSiteId, } = burnPermitSite
  const localBurnPermitSite = yield select(modelByIdSelector, { modelName: BURN_PERMIT_SITE_MODEL_NAME, modelId: BurnPermitSiteId, })
  
  if (!localBurnPermitSite || !localBurnPermitSite.IsLocal) {
    const url = `${BURN_PERMIT_SITE_ENDPOINT}/${BurnPermitSiteId}`
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitSiteTypes.UPDATE_BURN_PERMIT_SITE_REQUEST,
        url         : url,
        method      : 'PUT',
        keyName     : BurnPermitSite.options.idAttribute,
        keyValue    : BurnPermitSiteId,
      })
    }
    yield put(BurnPermitSiteActions.updateBurnPermitSiteRequest(BURN_PERMIT_SITE_MODEL_NAME, url, burnPermitSite, online))
  }
  
  if (!online) {
    // redux-orm doesn't seem to be handling upserting xrefs when we define the model, so let's manually handle them
    const xrefIds = yield call(replaceAll, EQUIPMENT_XREF_MODEL_NAME, burnPermitSite.BurnPermitSiteEquipment, { BurnPermitSiteId, })
    const model = {
      ...burnPermitSite,
      BurnPermitSiteEquipment : xrefIds,
      DaysOfWeek              : [ ...burnPermitSite.PlannedBurnDays, ],
      Submit                  : true,
    }
    const updatedPermitSiteInfo = yield call(updateLocalModel, BURN_PERMIT_SITE_MODEL_NAME, BurnPermitSiteId, model)
    if (updatedPermitSiteInfo) {
      // update local search result so the My Permits and Search results show the pending updates
      const BurnPermitId = yield select(getBurnPermitId, { BurnPermitSiteId, })
      yield call(updateLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, BurnPermitId, { BurnUnitName: updatedPermitSiteInfo.BurnUnitName, })
    }
  }
}

function* updateBurnPermitSiteSuccess (resp) {
  const burnPermitSite = yield call(extractPayload, resp)

  yield call(upsertBurnPermitSite, burnPermitSite)
  
  // call get burn permit detail saga
  const burnPermit = yield select(getBurnPermitSelector, { BurnPermitSiteId: burnPermitSite.BurnPermitSiteId, })
  const permitResp = yield call(getBurnPermitSaga, { burnPermitId: burnPermit.BurnPermitId, })
  const updatedPermit = permitResp.responseBody
  // If there was once an Exempt Id, but after getting the latest from the server and there is no
  // longer an ID, then delete the local model
  if (burnPermit.ForestHealthExemptId && !updatedPermit.ForestHealthExemptId) {
    // delete forest health exempt records
    yield call(destroyLocalModels, 'ForestHealthExemptProblemTypeXref', { ForestHealthExemptId: burnPermit.ForestHealthExemptId, })
    yield call(destroyLocalModel, 'ForestHealthExempt', burnPermit.ForestHealthExemptId)
  }
  const { online, } = yield select(networkStateSelector)
  if (online) {
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
}

export function* submitOfflineSiteEdits (localId, serverId) {
  try {
  // Get the local site model
    const burnPermitSite = yield select(modelByIdSelector, { modelName: BurnPermitSite.modelName, modelId: localId, })
    if (burnPermitSite.Submit) {
    // Get the ref data
      const permitSiteRef = { ...burnPermitSite._fields, }
      // Set the server id
      permitSiteRef.BurnPermitSiteId = serverId
      // Map the virtual many to many fk data
      // The Proximity Directions are stored as direct fks and don't need to be mapped manually
      permitSiteRef.BurnPermitSiteEquipment = burnPermitSite.BurnPermitSiteEquipment.toRefArray()
      // Update it
      const url = `${BURN_PERMIT_SITE_ENDPOINT}/${serverId}`
      const siteResp = yield call(doFetch, url, { method: 'PUT', body: permitSiteRef, })
      if (siteResp.ok) {
        yield fork(apiSuccess, { payload: siteResp.responseBody, modelName: BURN_PERMIT_SITE_MODEL_NAME, })
        return
      }
      yield call(showError, siteResp.responseBody)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* upsertBurnPermitSite (permitSite) {

  permitSite = yield call(replaceAllSiteEquipment, permitSite)

  permitSite = yield call(replaceAllReferenceDirections, permitSite)

  permitSite.IsLocal = false

  yield call(upsertLocalModel, BURN_PERMIT_SITE_MODEL_NAME, permitSite)
  
  return permitSite
}

function* replaceAllSiteEquipment (permitSite) {
  const BurnPermitSiteEquipment = permitSite.BurnPermitSiteEquipment || []

  const { BurnPermitSiteId, } = permitSite

  yield call(replaceAll, EQUIPMENT_XREF_MODEL_NAME, BurnPermitSiteEquipment, { BurnPermitSiteId, })

  return permitSite
}

function* replaceAllReferenceDirections (permitSite) {
  const xrefs = permitSite.BurnPermitReferenceDirectionXref
  if (xrefs.length) {
    const refDirs = []
    for (let i = 0, len = xrefs.length; i < len; i++) {
      const x = xrefs[i]
      refDirs.push({ ...x.ReferenceDirection, })
      delete x['ReferenceDirection']
    }
    const { BurnPermitSiteId, } = permitSite
    yield call(replaceAll, 'BurnPermitReferenceDirectionXref', xrefs, { BurnPermitSiteId, })
    yield call(upsertLocalModels, 'ReferenceDirection', refDirs)
  }
  delete permitSite['BurnPermitReferenceDirectionXref']
    
  return permitSite
}


export const BurnPermitSiteSagas = [
  takeLatest(BurnPermitSiteTypes.GET_BURN_PERMIT_SITE_INFO, getBurnPermitSiteInfo),
  takeEvery(BurnPermitSiteTypes.UPDATE_BURN_PERMIT_SITE, updateBurnPermitSite),
  takeEvery(BurnPermitSiteTypes.UPDATE_BURN_PERMIT_SITE_SUCCESS, updateBurnPermitSiteSuccess),
]