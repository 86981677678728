import React from 'react'
import { useDispatch, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { NavItem, NavLink, } from 'reactstrap'
import { bool, element, oneOfType, string, } from 'prop-types'

// Hooks
import useNavClick from '../../hooks/useNavClick'


const NavbarItem = (props) => {
  const {
    children,
    disabled,
    href,
    to,
    text,
    title,
  } = props
  
  const itemProps = {
    title: title || text,
    disabled,
  }
  if (to) {
    itemProps.to = to
  }
  if (href) {
    itemProps.href = href
  }

  // Redux actions
  const dispatch = useDispatch()

  const [ ,toggleNavbar, ] = useNavClick(dispatch)

  const markup = <NavItem>
    <NavLink
      tag={Link}
      onClick={toggleNavbar}
      {...itemProps}
    >
      {children || text}
    </NavLink>
  </NavItem>
  return markup
}

NavbarItem.propTypes = {
  disabled : bool,
  children : oneOfType([ element, string, ]),
  href     : string,
  to       : string,
  text     : string,
  title    : string,
}

export default React.memo(NavbarItem)
