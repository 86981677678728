import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getTileLinks : null,
  setTileLinks : [ 'links', ],
  getDashMsgs  : null,
  setDashMsgs  : [ 'messages', ],
})

export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */


export const INITIAL_STATE = {
  TileLinks: {
    AirQuality       : [],
    BurnRestrictions : [],
    Helpful          : [],
    Permit           : [],
    Smoke            : [],
    StateLaw         : [],
  },
  Messages: {
    Alert   : null,
    Warning : null,
    Info    : null,
  },
}

export const setMessages = (state, { messages, }) => {
  const newState = { ...state, }
  newState.Messages = messages
  return newState
}

export const setTileLinks = (state, { links, }) => {
  const newState = { ...state, }
  newState.TileLinks = links
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TILE_LINKS] : setTileLinks,
  [Types.SET_DASH_MSGS]  : setMessages,
})