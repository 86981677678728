import { useCallback, useEffect, } from 'react'
import BurnRequestSearchActions from '../../redux/BurnRequestSearchRedux'
import BurnPermitSearchActions from '../../redux/BurnPermitSearchRedux'
import SearchActions from '../../redux/SearchRedux'
import PersonActions from '../../redux/PersonRedux'
import { dateFormatter, } from '../../utilities'

export function useInputChange (dispatch) {
  const dispatchInputChange = useCallback((newValues) => {
    dispatch({ type: 'INPUT_CHANGE', payload: newValues, })
  }, [ dispatch, ])

  /**
   * Generic change handler
   * @param {Object} Change Event 
   */
  const onTextChange = useCallback(evt => {
    const { name, type, step, } = evt.target
    let { value, } = evt.target
    if (!!value && (type === 'number' || type === 'select-one')) {
      if (step && step.indexOf('.') > -1) {
        value = parseFloat(value)
      }
      else {
        value = parseInt(value)
      }
    }
    dispatchInputChange({ [name]: value, })
  }, [ dispatchInputChange, ])
  

  const onSelect = useCallback(evt => {
    const { name, } = evt.target
    let { value, } = evt.target
    const resultName = name.substring(0, name.indexOf('Id'))
    let resultValue = ''
    if (value) {
      resultValue = [ ...evt.target.options, ].filter(o => o.value === value)[0].text
      value = parseInt(value)
    }
    dispatchInputChange({ [name]: value, [resultName]: resultValue, })
  }, [ dispatchInputChange, ])

  return [ dispatchInputChange, onTextChange, onSelect, ]
}


export function usePermitSearchActions (reduxDispatch, resetCallback) {
  /**
   * Show/hide map handler
   */
  const onToggleMap = useCallback(() => {
    reduxDispatch(BurnPermitSearchActions.toggleSearchMap())
  }, [ reduxDispatch, ])
  
  const toggleLocation = useCallback(() => {
    reduxDispatch(BurnPermitSearchActions.toggleLocation())
  }, [ reduxDispatch, ])

  /**
   * Reset button handler, reset redux with default search values
   */
  const onResetClick = useCallback(() => {
    if (resetCallback) {
      resetCallback()
    }
    reduxDispatch(BurnPermitSearchActions.resetSearchParams())
  }, [ reduxDispatch, resetCallback, ])

  return [ onToggleMap, toggleLocation, onResetClick, ]
}


export function useAutoSelectChange (dispatchInputChange, lookupToSearch, { textKey, valueKey, }) {
  const autoSelectSelected = useCallback(value => {
    const id = (lookupToSearch.find(u => u.Text === value) || {}).Value || ''
    dispatchInputChange({ [textKey]: value, [valueKey]: id, })
  }, [ dispatchInputChange, lookupToSearch, textKey, valueKey, ])

  const autoSelectChanged = useCallback(evt => {
    const { value, } = evt.target
    autoSelectSelected(value)
  }, [ autoSelectSelected, ])
  return [ autoSelectSelected, autoSelectChanged, ]
}


export function useSearchLookupsFetch (reduxDispatch, canSearchByAssignee) {
  useEffect(() => {
    if (reduxDispatch) {
      reduxDispatch(SearchActions.getSearchLookupData())
      if (canSearchByAssignee) {
        reduxDispatch(PersonActions.getAssignableUsers())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useEnterPressHandler (callback) {
  const handleKeyPress = useCallback((evt) => {
    if (evt.charCode === 13) {
      callback()
    }
  }, [ callback, ])
  return handleKeyPress
}

export function useSearchDateClick (dispatchInputChange, startKey, endKey) {
  const onDateClick = useCallback(({ from, to, }) => {
    let start = '', end = ''
    if (from) {
      start = dateFormatter(from, 'YYYY-MM-DD')
    }
    if (to) {
      end = dateFormatter(to, 'YYYY-MM-DD')
    }
    dispatchInputChange({ [startKey]: start, [endKey]: end, })
  }, [ dispatchInputChange, endKey, startKey, ])
  return onDateClick
}

export function useRequestSearchActions (reduxDispatch, resetCallback) {
  /**
   * Show/hide map handler
   */
  const onToggleMap = useCallback(() => {
    reduxDispatch(BurnRequestSearchActions.toggleRequestSearchMap())
  }, [ reduxDispatch, ])

  const toggleLocation = useCallback(() => {
    reduxDispatch(BurnRequestSearchActions.toggleRequestLocation())
  }, [ reduxDispatch, ])

  /**
   * Reset button handler, reset redux with default search values
   */
  const onResetClick = useCallback(() => {
    if (resetCallback) {
      resetCallback()
    }
    reduxDispatch(BurnRequestSearchActions.resetRequestSearchParams())
  }, [ reduxDispatch, resetCallback, ])

  return [ onToggleMap, toggleLocation, onResetClick, ]
}

export const useAutoComplete = (name, evtCallback, optProps) => {
  optProps = optProps || { textKey: 'Text', valueKey: 'Value', }
  const { textKey, valueKey, } = optProps

  const onChange = useCallback(evt => {
    const { label, value, } = evt
    if (typeof evtCallback === 'function') {
      evtCallback({ target: { name, value: label, id: value, }, })
    }
    return { [textKey]: label, [valueKey]: value, }
  }, [ textKey, valueKey, name, evtCallback, ])
  return [ onChange, ]
}
