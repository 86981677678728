import { attr, Model, } from 'redux-orm'

export default class AlertMethod extends Model {
  static get modelName () {
    return 'AlertMethod'
  }

  static endpoint () {
    return 'AlertMethods'
  }

  static get options () {
    return {
      idAttribute: 'AlertMethodId',
    }
  }

  static get fields () {
    return {
      AlertMethodId          : attr(),
      AlertMethodName        : attr(),
      AlertMethodDescription : attr(),
      CreateDate             : attr(),
      CreateBy               : attr(),
      UpdateDate             : attr(),
      UpdateBy               : attr(),
    }
  }
}