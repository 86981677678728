// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { isEqual, isEmpty, } from 'lodash'
import { Row, Col, FormGroup, Label, Button, Card, CardBody, Fade, ListGroupItem, ListGroup, ButtonGroup, } from 'reactstrap'
import { Formik, Form, Field, } from 'formik'
import { object, bool, array, func, } from 'prop-types'
import * as Yup from 'yup'

// Selectors
import {
  contactMethodsForSelectSelector,
  alertMethodsSelector,
  alertPreferencesSelect,
} from '../../selectors/selectors'
import {
  personTypesForPerson,
  personIsDnrSelector,
  personIsAgentOrAgencyPerson,
  personTypesByPermissionSelector,
  personInactiveData,
} from '../../selectors/personSelectors'
import { userSelector, userPersonIdSelector, } from '../../selectors/userSelectors'
import { regionsForSelectSelector, } from '../../selectors/regionSelectors'
import { agenciesForSelectSelector, } from '../../selectors/agencySelectors'
import {
  userCanViewPersonRegion,
  userCanUpdatePersonRegion,
  userCanChangeAlertPrefs,
  userCanEditVerifiedAgentPersonType,
  userCanVerifyAgents,
  userCanChangeSMDecisionStatus,
} from '../../selectors/permissionSelectors'

// Components
import AddressForm from './AddressForm'
import PhoneForm from './PhoneForm'
import EmailForm from './EmailForm'
import Effect from '../Effect'
import AuditData from '../AuditData'
import ConfirmationModal from '../ConfirmationModal'
import {
  AutoComplete,
  PopoverButton,
  ValidatingField,
  RowToggler,
  Select,
  YesNoRadioButtonGroup,
  RequiredLabel,
  ButtonGroupButton,
} from '../FormControls'

// Actions
import UiActions from '../../redux/UiRedux'
import ApiActions from '../../redux/ApiRedux'
import AppActions from '../../redux/AppRedux'
import PersonActions from '../../redux/PersonRedux'
import RegionActions from '../../redux/RegionsRedux'
import AgencyActions from '../../redux/AgencyRedux'
import MergeActions from '../../redux/MergeRedux'

// Models
import Person from '../../models/Person'

// Utilities
import { dateFormatter, objHasProp, scrollToErrors, } from '../../utilities'
import stopEvent from '../../utilities/stopEvent'
import { validateAll, submitAll, } from '../../utilities/forms'


// eslint-disable-next-line no-undef
const { REACT_APP_SUPPORT_EMAIL, } = process.env
const VERIFY_AGENCY_USER = 'verify_agency_user'
const CONVERT_TO_AGENCY = 'CONVERT_TO_AGENCY'


class PersonForm extends React.Component {

  constructor (props) {
    super(props)

    this.onSaveClick = this.onSaveClick.bind(this)
  }

  AddressForms  = []
  PhoneForms    = []
  EmailForm     = null

  static propTypes = {
    // actions
    OpenModal                   : func,
    Failure                     : func,
    RedirectTo                  : func,
    CreatePerson                : func,
    CreateLocalAddressForPerson : func,
    CreatePersonAddress         : func,
    DeletePersonAddress         : func,
    CreateLocalPhoneForPerson   : func,
    CreatePersonPhone           : func,
    DeletePersonPhone           : func,
    CreatePersonEmail           : func,
    DeletePersonEmail           : func,
    SetPersonRegion             : func,
    UpdatePerson                : func,
    UpdatePersonRegion          : func,
    GetPersonLookupData         : func,
    SetInactiveMetadata         : func,
    GetAllRegions               : func,
    GetAllAgencies              : func,
    VerifyAgencyUser            : func,
    ConvertPersonToAgency       : func,
    SetMergeObjectOneId         : func,
    // own props
    online                      : bool,
    user                        : object,
    inactiveData                : object,
    showAddresses               : bool,
    addresses                   : object,
    showPhones                  : bool,
    phones                      : object,
    showEmails                  : bool,
    disableDeleteEmail          : bool,
    email                       : object,
    region                      : object,
    agency                      : object,
    alertPrefs                  : object,
    showAuditData               : bool,
    readOnly                    : bool,
    person                      : object,
    shouldGeocode               : bool,
    showTypes                   : bool,
    deletePhoneAction           : func,
    deleteAddressAction         : func,
    personIsDnr                 : bool,
    isNewPerson                 : bool,
    isAgent                     : bool,
    isStateGovCust              : bool,
    showAlertPrefs              : bool,
    showRegion                  : bool,
    enableAgencyConversion      : bool,
    disableMerge                : bool,
    // selectors
    personTypes                 : array,
    contactMethods              : array,
    agencies                    : array,
    regions                     : array,
    AlertMethods                : array,
    AlertPreferences            : array,
    canChangeAlertPrefs         : bool,
    canUpdateRegion             : bool,
    canEditAgentPersonType      : bool,
    canVerifyAgents             : bool,
    canViewPersonRegion         : bool,
    canSetSMDecisionStatus      : bool,
  }
  
  static defaultProps = {
    readOnly               : false,
    showTypes              : false,
    showAuditData          : true,
    personIsDnr            : false,
    isAgent                : false,
    agencies               : [],
    regions                : [],
    AlertMethods           : [],
    showAlertPrefs         : true,
    showRegion             : true,
    canChangeAlertPrefs    : false,
    canUpdateRegion        : false,
    canEditAgentPersonType : false,
    canVerifyAgents        : false,
    canViewPersonRegion    : false,
    canSetSMDecisionStatus : false,
    disableMerge           : false,
  }

  state = {
    hideVerifyButton     : false,
    addressesAreShowing  : false,
    phonesAreShowing     : false,
    alertPrefsAreShowing : false,
  }

  validateForm = async () => {
    // Dunno why, but had an error thrown here saying could not call
    // validateForm of object null
    if (!this.formik) {
      return false
    }
    return await this.formik.validateForm()
  }

  onAddAddressClick = (e) => {
    stopEvent(e)

    this.props.CreateLocalAddressForPerson(this.props.person.PersonId)
  }

  onDeleteAddressClick = e => {
    stopEvent(e)

    const { addressid, } = e.target.dataset

    const { deleteAddressAction, } = this.props

    if (typeof deleteAddressAction === 'function') {
      deleteAddressAction(parseInt(addressid))
    }
    else {
      this.props.DeletePersonAddress(this.props.person.PersonId, parseInt(addressid))
    }
  }

  onAddPhoneClick = evt => {
    stopEvent(evt)

    this.props.CreateLocalPhoneForPerson(this.props.person.PersonId)
  }

  onDeletePhoneClick = evt => {
    stopEvent(evt)

    const { phoneid, } = evt.target.dataset

    const { deletePhoneAction, } = this.props

    if (typeof deletePhoneAction === 'function') {
      deletePhoneAction(parseInt(phoneid))
    }
    else {
      this.props.DeletePersonPhone(this.props.person.PersonId, parseInt(phoneid))
    }
  }

  createEmail = (values) => {
    if (values.EmailAddress !== '') {
      this.props.CreatePersonEmail(this.props.person.PersonId, values)
    }
  }

  deleteEmail = () => {
    this.props.DeletePersonEmail(this.props.person.PersonId)
  }

  validateAlertPrefs = () => {
    if (!this.formik) {
      return false
    }
    const { AlertMethods, phones, } = this.props
    const textableMethods = AlertMethods.filter(m => m.Text === 'SMS Text' || m.Text === 'Both').map(m => m.Value)
    const prefs = Object.entries(this.formik.values)
      .filter(x => x[0].indexOf('PersonAlertPreferenceXref_') > -1)
      .map(x => x[1])

    if (!prefs.length) {
      return true
    }
    
    // For some reason formik can't handle value properties that are objects, so we'll manage the errors ourselves.
    const hasAlertablePhone = phones.phones.filter(p => p.ReceivesAlerts).length > 0
    const newErrorState = {}
    for (let i = 0, len = prefs.length; i < len; i++) {
      const pref = prefs[i]
      const prefId = pref.AlertPreferenceId
      const fieldName = `PersonAlertPreferenceXref_${prefId}_Error`
      let errorMsg = ''
      if (!hasAlertablePhone && textableMethods.includes(pref.AlertMethodId)) {
        errorMsg = 'You not will receive these alerts via SMS Texts until you create a Mobile Phone set to Receive Alerts.'
      }
      newErrorState[fieldName] = errorMsg
    }
    this.setState(newErrorState)
  }

  async onSaveClick (values) {
    const { person, personIsDnr, isNewPerson, } = this.props
    const {
      PersonFirstName,
      PersonMiddleName,
      PersonLastName,
      PersonTypeId,
      ContactMethodId,
      RegionId,
      MakesSmokeManagementDecisions,
    } = values
    
    let PersonId = person ? person.PersonId : null
    let newPerson = isNaN(PersonId) || PersonId < 1 || isNewPerson

    this.formik.setSubmitting(true)

    const timedSubmissionFail = () => {
      setTimeout(() => {
        if(this.formik) {
          this.formik.setSubmitting(false) 
        }
      }, 300)
    }

    const PersonAlertPreferenceXref = Object.entries(this.formik.values)
      .filter(x => x[0].indexOf('PersonAlertPreferenceXref_') > -1)
      .reduce((acc, x, idx) => {
        acc[idx] = x[1]
        return acc
      }, [])
      .filter(x => x.AlertMethodId)

    if (newPerson) {
      // validate email address or die
      const emailErrors = await this.EmailForm.validate()
      if (Object.keys(emailErrors).length > 0) {
        this.formik.setSubmitting(false)
        return
      }
      // get email address or die
      const EmailAddress = this.EmailForm.getEmailAddress()
      
      // validate person form or die
      const personIsValid = await this.validateForm()
      if (Object.keys(personIsValid).length > 0) {
        this.formik.setSubmitting(false)
        return
      }

      const personToSubmit = {
        PersonFirstName,
        PersonMiddleName,
        PersonLastName,
        PersonTypeId,
        ContactMethodId,
        PersonAlertPreferenceXref,
      }
      // simple email object for person creation
      const emailToSubmit = { EmailAddress, }
      
      // submit person and email
      this.props.CreatePerson(personToSubmit, emailToSubmit)
      timedSubmissionFail()
      return
    }

    let emailErrorsExist = false
    if (person.IsUser === false && this.EmailForm) {
      emailErrorsExist = await validateAll([ this.EmailForm, ])
    }

    const [
      addressErrorsExist,
      phoneErrorsExist,
    ] = await Promise.all([
      validateAll(this.AddressForms),
      validateAll(this.PhoneForms),
    ])

    let personErrorsExist = false
    const errors = await this.formik.validateForm()
    personErrorsExist = Object.keys(errors).length > 0

    if (addressErrorsExist || phoneErrorsExist || emailErrorsExist || personErrorsExist) {
      this.formik.setSubmitting(false)
      scrollToErrors()
      return false
    }
    // Start submitting these since there couple be multiples or dozens
    // and the actions are not batched.
    submitAll(this.AddressForms)
    submitAll(this.PhoneForms)
      
    if (person.IsUser === false) {
      this.EmailForm.submit()
    }

    // update region information for staff
    if (this.props.showRegion && this.props.canUpdateRegion && personIsDnr) {
      const makesDecisions = MakesSmokeManagementDecisions === 'yes'

      if (Number.isInteger(parseInt(RegionId)) && parseInt(RegionId) > 0) {
        if (Number.isInteger(this.props.region.PersonRegionXrefId)) {
          this.props.UpdatePersonRegion(person.PersonId, RegionId, makesDecisions)
        }
        else {
          this.props.SetPersonRegion(person.PersonId, RegionId, makesDecisions)
        }
      }
    }

    const updateObj = {
      PersonTypeId,
      ContactMethodId,
      PersonAlertPreferenceXref,
      PersonId,
    }

    if (person.IsUser === false) {
      updateObj.PersonFirstName = PersonFirstName
      updateObj.PersonMiddleName = PersonMiddleName
      updateObj.PersonLastName = PersonLastName
    }

    if (values.Agency && values.Agency.AgencyId) {
      updateObj.PersonAgencyXref = [ { PersonAgencyXrefAgencyId: values.Agency.AgencyId, PersonAgencyXrefPersonId: person.PersonId, }, ]
      // reset the state when saving
      this.setState({ hideVerifyButton: false, })
    }

    this.props.UpdatePerson(updateObj)
    timedSubmissionFail()
  }

  renderPersonTypes = (values) => {
    let { readOnly, canEditAgentPersonType, personIsDnr, personTypes, } = this.props

    if (canEditAgentPersonType) {
      readOnly = false
    }
    else if (values.Agency && objHasProp(values.Agency, 'ConfirmedBy') && !personIsDnr) {
      readOnly = !!values.Agency.ConfirmedBy
    }

    // If there's only one value, select it by default
    if (personTypes.length === 1) {
      values.PersonTypeId = personTypes[0].Value
    }
    
    return <Col xs={12} sm={6} md={3}>
      <FormGroup>
        <RequiredLabel labelFor={'person-type'}>
          Person Type
          <PopoverButton
            popoverHeader={'Person Type'}
            popoverBody={
              <ul className={'pl-3'}>
                <li>
                  Private - You conduct burns on property that you personally own
                </li>
                <li>
                  Agent - You own or work for a company that conducts burns on 
                  property you are authorized to burn on</li>
                <li>
                  Government Agency - You work for local, state, or federal entity that is 
                  conducts burns on property you are authorized to burn on</li>
              </ul>
            }
          />
        </RequiredLabel>
        <Field name={'PersonTypeId'}>
          {({ field, form, }) => (
            <Select
              id={'person-type'}
              items={personTypes}
              propertyName={field.name}
              {...field}
              selectedValue={field.value}
              errorMessage={form.errors[field.name]}
              readOnly={readOnly}
              required={true}
            />
          )}
        </Field>
      </FormGroup>
    </Col>
  }

  touched = () => {
    if (this.formik && this.formik && this.formik.touched) {
      return Object.values(this.formik.touched).some(t => t === true)
    }
    // unable to check if touched, just return true
    return true
  }

  renderContactMethod = () => {
    return <Col xs={12} sm={6} lg={4}>
      <FormGroup>
        <RequiredLabel labelFor={'contact-method'}>
            Preferred Contact Method
          <PopoverButton
            popoverHeader={'Contact Method'}
            popoverBody={'This is your preferred method for being contacted directly by a person.'}
          />
        </RequiredLabel>
        <Field name={'ContactMethodId'}>
          {({ field, form, }) => (
            <Select
              id={'contact-method'}
              items={this.props.contactMethods}
              propertyName={field.name}
              {...field}
              selectedValue={field.value}
              errorMessage={form.errors[field.name]}
              readOnly={this.props.readOnly}
            />
          )}
        </Field>
      </FormGroup>
    </Col>
  }

  renderRegion = () => {
    const {
      canViewPersonRegion,
      personIsDnr,
      regions,
      readOnly,
      showRegion,
      canSetSMDecisionStatus,
    } = this.props
    if (!showRegion || !(canViewPersonRegion && personIsDnr)) {
      return null
    }
    return <>
      <Col xs={12} sm={6} md={4}>
        <FormGroup>
          <Field name={'RegionId'}>
            {({ field, form, }) => (
              <Select
                label={'Region'}
                items={regions}
                propertyName={field.name}
                onChange={field.onChange}
                selectedValue={field.value}
                errorMessage={form.errors[field.name]}
                readOnly={readOnly}
              />
            )}
          </Field>
        </FormGroup>
      </Col>
      {
        canSetSMDecisionStatus && <Col xs={12} sm={6} md={4}>
          <FormGroup>
            <Field name={'MakesSmokeManagementDecisions'}>
              {({ field, form, }) => (
                <YesNoRadioButtonGroup
                  {...field}
                  legend={'Do you make Smoke Management Decisions?'}
                  inputName={field.name}
                  checkedValue={field.value}
                  readOnly={readOnly}
                  errorMessage={form.errors[field.name]}
                  invalid={!!form.errors[field.name]}
                />
              )}
            </Field>
          </FormGroup>
        </Col>
      }
    </>
  }

  verifyUserClick = e => {
    stopEvent(e)
    this.props.OpenModal(VERIFY_AGENCY_USER)
  }

  renderAgencies = (currFormValues) => {
    const { personTypes, isAgent, canEditAgentPersonType, } = this.props

    if (!Array.isArray(personTypes) || !isAgent) {
      return null
    }

    const { agencies, person, } = this.props
    let { readOnly, } = this.props

    if (canEditAgentPersonType) {
      readOnly = false
    }
    else if (currFormValues.Agency && objHasProp(currFormValues.Agency, 'ConfirmedBy') && !this.props.personIsDnr) {
      readOnly = !!currFormValues.Agency.ConfirmedBy
    }

    const mailToHref =`mailto:${REACT_APP_SUPPORT_EMAIL}?Subject=New Burn Portal Agency&Body=Hey Burn Portal%0D%0A%0D%0AThis is ${this.props.user.userName} and I need my Agency added to the Burn Portal.`

    return <>
      <Col xs={12} sm={6} md={4}>
        <Label>Agency</Label>
        <AutoComplete
          fieldName={'Agency.AgencyId'}
          items={agencies}
          readOnly={readOnly}
          onChange={({ value, }) => {
            if (value !== currFormValues.Agency.AgencyId) {
              this.setState({ hideVerifyButton: true, })
            }
          }}
        />
      </Col>
      <Col xs={12} sm={6} md={4} lg={{ size: 4, offset: 7, }} className={'pb-4'}>
        <div>
          { 
            !this.props.readOnly
              ? !isEmpty(currFormValues.Agency) && currFormValues.Agency.AgencyId && this.state.hideVerifyButton === false
                ? <>
                  { currFormValues.Agency.ConfirmedBy ? <>
                    <small>Verified By: {currFormValues.Agency.ConfirmedBy}</small><br/>
                    <small>Verified On: {dateFormatter(currFormValues.Agency.ConfirmedOn, 'MM-DD-YYYY hh:mm:ss a')}</small>
                  </>
                    : this.props.canVerifyAgents
                      ? <>
                        <Button
                          size={'sm'}
                          className={`mt-2${person.isUser ? '' : 'disabled'}`}
                          onClick={this.verifyUserClick}
                          disabled={!person.IsUser}
                          title={(person.Agency && person.Agency.ConfirmedBy === null) ? 'Verify User' : 'Cannot Verify this Person. They are not a user.'}>
                          Verify User
                        </Button>
                        <Link
                          to={`/admin/agencies/${currFormValues.Agency.AgencyId}`}
                          className={'ml-2'}
                        >View Agency</Link>
                      </>
                      : <><b>Verification Pending.</b><br/><small>You are unable to create or manage Burn Permits, Burn Requests or Post Burn data until your account is verified.</small></>
                  }
                  <ConfirmationModal
                    modalKey={VERIFY_AGENCY_USER}
                    modalTitle={'Verify Agency User'}
                    modalBody={<p>Are you sure <b>{currFormValues.PersonFirstName} {currFormValues.PersonLastName}</b> is part of <b>{currFormValues.Agency.AgencyName}</b>?</p>}
                    submitAction={this.props.VerifyAgencyUser}
                    submitArgs={[ currFormValues.Agency.AgencyId, currFormValues.Agency.PersonAgencyXrefId, ]}
                  />
                </>
                : <small>Don&apos;t see your Agency? <a href={mailToHref}>Email us</a> and we&apos;ll set you up!</small>
              : null
          }
        </div>
      </Col>
    </>
  }

  componentDidMount () {
    if (this.props.online) {
      const { personIsDnr, GetPersonLookupData, GetAllRegions, GetAllAgencies, readOnly, isAgent, canViewPersonRegion, } = this.props
      GetPersonLookupData()
      if (!readOnly && isAgent) {
        GetAllAgencies()
      }

      if (this.props.showRegion && canViewPersonRegion && personIsDnr) {
        GetAllRegions()
      }
    }
  }

  componentDidUpdate (prevProps) {

    const oldPerson = prevProps.person
    const { person, canViewPersonRegion, personIsDnr, isAgent, readOnly, showRegion, } = this.props

    // if the person record loaded in props, get related data
    if (!isEmpty(person) && !isEqual(oldPerson, person)) {
      this.AddressForms = []
      this.PhoneForms = []
      this.validateAlertPrefs()
    }

    if (!isEmpty(oldPerson) && isEmpty(person)) {
      this.AddressForms = []
      this.PhoneForms = []
    }

    if (!readOnly && !prevProps.isAgent && isAgent) {
      this.props.GetAllAgencies()
    }

    if ((!prevProps.canViewPersonRegion || !prevProps.personIsDnr || !prevProps.showRegion) 
      && (canViewPersonRegion && personIsDnr && showRegion)) {
      this.props.GetAllRegions()
    }
  }

  onAlertPrefChange = evt => {
    stopEvent(evt)
    const { id, value, name, dataset, } = evt.target
    const { xrefId, } = dataset
    const pref = {
      PersonAlertPreferenceXrefId : parseInt(xrefId),
      AlertMethodId               : parseInt(value),
      AlertPreferenceId           : parseInt(id),
    }
    this.formik.setFieldValue(name, pref)
  }

  buildAlertMethods = prefId => {
    if (!this.formik) {
      return
    }
    const name = `PersonAlertPreferenceXref_${prefId}`
    const selectedPref = this.formik.values[name]
    let selectedMethod = '', xrefId = 0
    if (selectedPref) {
      selectedMethod = selectedPref.AlertMethodId
      xrefId = selectedPref.PersonAlertPreferenceXrefId
    }
    return <FormGroup>
      <Field name={name}>
        {({ field, }) => (
          <Select
            label={'Alert Method'}
            items={this.props.AlertMethods}
            propertyName={field.name}
            {...field}
            id={prefId}
            selectedValue={selectedMethod}
            errorMessage={this.state[field.name + '_Error']}
            readOnly={this.props.readOnly}
            onChange={this.onAlertPrefChange}
            dataset={{ 'data-xref-id': xrefId, }}
          />
        )}
      </Field>
    </FormGroup>
  }

  buildAlertPrefItem = p => {
    return <ListGroupItem key={p.AlertPreferenceId}>
      <Row>
        <Col>
          <p>{p.AlertPreferenceName}</p>
          <p className={'m-0 pl-2'}>
            <small>{p.AlertPreferenceDescription}</small>
          </p>
        </Col>
        <Col>{ this.buildAlertMethods(p.AlertPreferenceId) }</Col>
      </Row>
    </ListGroupItem>
  }

  buildAlertPrefGroup = pref => {
    return this.props.AlertPreferences
      .filter(p => p.AlertPreferenceName.indexOf(pref) > -1)
      .map(this.buildAlertPrefItem)
  }

  buildAlertPrefs = () => {
    const permitPrefs = this.buildAlertPrefGroup('Burn Permit')
    const requestPrefs = this.buildAlertPrefGroup('Burn Request')
    // There are non configured at this time
    const postBurnPrefs = this.buildAlertPrefGroup('Post Burn')
    return <>
      {
        permitPrefs.length > 0 && 
          <Col>
            <b>Burn Permit Events</b>
            <ListGroup>{ permitPrefs }</ListGroup>
          </Col>
      }
      {
        requestPrefs.length > 0 &&
          <Col>
            <b>Burn Request Events</b>
            <ListGroup>{ requestPrefs }</ListGroup>
          </Col>
      }
      {
        postBurnPrefs.length > 0 &&
          <Col>
            <b>Post Burn Events</b>
            <ListGroup>{ postBurnPrefs }</ListGroup>
          </Col>
      }
    </>
  }

  createPersonAddress = values => {
    this.props.CreatePersonAddress(this.props.person.PersonId, values)
  }

  buildAddresses = () => {
    let { addresses, } = this.props.addresses
    if (!addresses || addresses.length === 0) {
      return <Col className={'mb-2'}><p>No addresses found</p></Col>
    }
    const { readOnly, shouldGeocode, } = this.props
    return addresses.map(address => {
      return (
        <Col xs={12} sm={6} lg={4} key={`row-adr-${address.AddressId}`} className={'mb-2'}>
          <Card>
            <CardBody>
              <AddressForm
                address={address}
                shouldGeocode={shouldGeocode}
                ref={node => this.AddressForms[address.AddressId] = node}
                readOnly={readOnly}
                showAuditData={true}
                showActiveControl={true}
                validateStrict={true}
                createFn={this.createPersonAddress}
                actionButton={readOnly ? null : 
                  <Button
                    key={`btn-del-adr-${address.AddressId}`}
                    size={'sm'}
                    color={'danger'}
                    data-addressid={address.AddressId}
                    onClick={this.onDeleteAddressClick}
                  >
                  Delete
                  </Button>
                }
              />
            </CardBody>
          </Card>
        </Col>
      )
    })
  }

  createPersonPhone = values => {
    this.props.CreatePersonPhone(this.props.person.PersonId, values)
  }

  buildPhones = () => {
    let { phones, } = this.props.phones
    if (!phones || phones.length === 0) {
      return <Col className={'mb-2'}><p>No phones found</p></Col>
    }
    const { readOnly, } = this.props
    return phones.map(phone => {
      return (
        <Col xs={12} sm={6} lg={4} key={`row-phn-${phone.PhoneId}`} className={'mb-2'}>
          <Card>
            <CardBody>
              <PhoneForm
                key={phone.PhoneId}
                phone={phone}
                ref={node => this.PhoneForms[phone.PhoneId] = node}
                readOnly={readOnly}
                showAuditData={true}
                showActiveControl={true}
                createFn={this.createPersonPhone}
                actionButton={
                  !readOnly && 
                    <Button
                      size={'sm'}
                      color={'danger'}
                      data-phoneid={phone.PhoneId}
                      onClick={this.onDeletePhoneClick}
                    >
                      Delete
                    </Button>
                }
              />
            </CardBody>
          </Card>
        </Col>
      )
    })
  }

  addRegionValidation = (schema) => {
    return schema.shape({
      RegionId: Yup.number()
        .min(1, 'Please select a region')
        .required('Please select a region'),
    })
  }

  toggleAddresses = () => {
    this.setState({ addressesAreShowing: !this.state.addressesAreShowing, })
  }

  togglePhones = () => {
    this.setState({ phonesAreShowing: !this.state.phonesAreShowing, })
  }

  toggleAlertPrefs = () => {
    this.setState({ alertPrefsAreShowing: !this.state.alertPrefsAreShowing, })
  }

  onFormChange = () => {
    this.validateAlertPrefs()
  }

  // We're intentionally ignoring the specific errors as the app now
  // scrolls the page to make the inline errors available.
  // eslint-disable-next-line no-unused-vars
  static hasErrors (_errors) {
    scrollToErrors()
  }

  convertToAgency = () => {
    this.props.OpenModal(CONVERT_TO_AGENCY)
  }

  mergeUser = () => {
    this.props.SetMergeObjectOneId(this.props.person.PersonId)
    this.props.RedirectTo('/admin/people/merge')
  }

  setFormikNode = node => this.formik = node

  render () {
    const { person, region, email, alertPrefs, } = this.props

    if (!person) {
      return <Row><Col><span>Loading ...</span></Col></Row>
    }

    const {
      showAddresses,
      showEmails,
      showAuditData,
      regions,
      showPhones,
      showTypes,
      personIsDnr,
      readOnly,
      isNewPerson,
      addresses,
      phones,
      canViewPersonRegion,
      disableDeleteEmail,
      inactiveData,
    } = this.props
    let validationSchema = (showTypes ? Person.getValidationSchema() : Person.getMinValidationSchema())

    if (this.props.showRegion && canViewPersonRegion && personIsDnr) {
      validationSchema = this.addRegionValidation(validationSchema, person, regions)
    }

    const mergedPersonRoute = `/admin/people/${person.ParentId}`

    let initialValues = {
      ...person,
    }
    
    if (!isEmpty(region)) {
      initialValues = { ...initialValues, ...region, }
    }
    if (!isEmpty(alertPrefs)) {
      initialValues = { ...initialValues, ...alertPrefs, }
    }

    let enableMerge = !this.props.disableMerge
    let disableMergeMessage
    if (enableMerge) {
      disableMergeMessage = 'Unable to merge:'
      if (person && person.ConvertedToAgency === true) {
        disableMergeMessage = `${disableMergeMessage} ${person.PersonFirstName} ${person.PersonLastName} has been converted to an Agency.`
        enableMerge = false
      }
      if (person && person.ParentId) {
        disableMergeMessage = `${disableMergeMessage} ${person.PersonFirstName} ${person.PersonLastName} has already been merged.`
        enableMerge = false
      }
      if (person && personIsDnr) {
        disableMergeMessage = `${disableMergeMessage} ${person.PersonFirstName} ${person.PersonLastName} is a DNR Person and cannot be merged.`
        enableMerge = false
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={!readOnly ? this.onSaveClick : null}
        validationSchema={!readOnly ? validationSchema : null}
        innerRef={this.setFormikNode}
        enableReinitialize={true}
      >
        {({ values, errors, isSubmitting, dirty, }) => (
          <Form>
            <Effect
              values={values}
              errors={errors}
              hasErrors={this.hasErrors}
              isSubmitting={isSubmitting}
              showDetailedError={isSubmitting}
              isDirty={dirty}
              onChange={this.onFormChange}
            />
            {
              Number.isInteger(person.ParentId) &&
              <Row>
                <Col>
                  <p>This Person has been merged and is no longer editable. Click <Link to={mergedPersonRoute}>here</Link> to edit the merged Person.</p>
                </Col>
              </Row>
            }
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label for={'txt-first-name'}>First Name</Label>
                  <Field
                    name={'PersonFirstName'}
                    id={'txt-first-name'}
                    readOnly={person.IsUser || readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3} lg={2}>
                <FormGroup>
                  <Label for={'txt-middle-name'}>Middle Name</Label>
                  <Field
                    name={'PersonMiddleName'}
                    className={'form-control'}
                    id={'txt-middle-name'}
                    readOnly={person.IsUser || readOnly}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label for={'txt-last-name'}>Last Name</Label>
                  <Field
                    name={'PersonLastName'}
                    id={'txt-last-name'}
                    readOnly={person.IsUser || readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              {
                showEmails &&
                  <Col xs={12} md={5} lg={4}>
                    <EmailForm
                      email={email}
                      createFn={this.createEmail}
                      ref={node => this.EmailForm = node}
                      readOnly={person.IsUser || readOnly}
                      isEmailRequired={person.IsUser}
                      showDelete={disableDeleteEmail ? false : (!person.IsUser || readOnly)}
                      deleteFn={this.deleteEmail}
                    />
                  </Col>
              }
              {
                this.props.showTypes &&
                <>
                  { this.renderContactMethod() }
                  { this.renderPersonTypes(values) }
                </>
              }
              { this.renderRegion() }
              { this.renderAgencies(values) }
            </Row>
            {
              showAddresses && person && !isNewPerson && <>
                <Row>
                  <Col className={'mb-3'}>
                    <RowToggler rowLabel={`${addresses.total || 0} Addresses`} onClick={this.toggleAddresses} show={this.state.addressesAreShowing} />
                  </Col>
                </Row>
                <Fade in={this.state.addressesAreShowing}>
                  <Row className={'mb-3 px-1'} style={{ height: this.state.addressesAreShowing ? 'auto' : '0', }}>
                    <Col xs={12} className={'mb-3'}>
                      <h3 className={'pr-4 d-inline-block'}>Addresses</h3>
                      { this.props.readOnly ? null :
                        <Button
                          size={'sm'}
                          color={'success'}
                          onClick={this.onAddAddressClick}
                        >
                        Add
                        </Button>
                      }
                      {
                        'Addresses' in inactiveData && 
                          <ButtonGroup className={'ml-3'}>
                            <ButtonGroupButton
                              isActive={inactiveData.Addresses.activeOnly}
                              text={`Active (${addresses.activeCount})`}
                              onClick={e => {
                                stopEvent(e)
                                this.props.SetInactiveMetadata('Addresses', true)
                              }}
                            />
                            <ButtonGroupButton
                              isActive={inactiveData.Addresses.activeOnly !== true}
                              text={`All (${addresses.total})`}
                              onClick={e => {
                                stopEvent(e)
                                this.props.SetInactiveMetadata('Addresses', false)
                              }}
                            />
                          </ButtonGroup>
                      }
                    </Col>
                  </Row>
                  <Row className={'px-1'}>{ this.buildAddresses() }</Row>
                </Fade>
              </>
            }
            {
              showPhones && person && !isNewPerson && <>
                <Row>
                  <Col className={'mb-3'}>
                    <RowToggler rowLabel={`${phones.total || 0} Phones`} onClick={this.togglePhones} show={this.state.phonesAreShowing} />
                  </Col>
                </Row>
                <Fade in={this.state.phonesAreShowing}>
                  <Row className={'mb-3 px-1'} style={{ height: this.state.phonesAreShowing ? 'auto' : '0', }}>
                    <Col xs={12} className={'mb-3'}>
                      <h3 className={'pr-4 d-inline-block'}>Phones</h3>
                      { this.props.readOnly ? null : 
                        <Button
                          size={'sm'}
                          color={'success'}
                          onClick={this.onAddPhoneClick}
                        >
                          Add
                        </Button>
                      }
                      {
                        'Phones' in inactiveData &&
                          <ButtonGroup className={'ml-3'}>
                            <ButtonGroupButton
                              isActive={inactiveData.Phones.activeOnly}
                              text={`Active (${phones.activeCount})`}
                              onClick={e => {
                                stopEvent(e)
                                this.props.SetInactiveMetadata('Phones', true)
                              }}
                            />
                            <ButtonGroupButton
                              isActive={inactiveData.Phones.activeOnly !== true}
                              text={`All (${phones.total})`}
                              onClick={e => {
                                stopEvent(e)
                                this.props.SetInactiveMetadata('Phones', false)
                              }}
                            />
                          </ButtonGroup>
                      }
                    </Col>
                  </Row>
                  <Row className={'px-1'}>{ this.buildPhones() }</Row>
                </Fade>
              </>
            }
            {
              person && this.props.canChangeAlertPrefs && this.props.showAlertPrefs && <>
                <Row>
                  <Col className={'mb-3'}>
                    <RowToggler rowLabel={'Alert Preferences'} onClick={this.toggleAlertPrefs} show={this.state.alertPrefsAreShowing} />
                  </Col>
                </Row>
                <Fade in={this.state.alertPrefsAreShowing} className={'alert-preferences'}>
                  <Row className={'mb-3 px-1'} style={{ height: this.state.alertPrefsAreShowing ? 'auto' : '0', }}>
                    <Col>
                      <h3 className={'pr-4 d-inline-block'}>Alert Preferences</h3>
                      <PopoverButton
                        popoverHeader={'Alert Preferences'}
                        popoverBody={'Set your preferred Alert Method for the events available below. All other events will be sent via email.'}
                      />
                    </Col>
                  </Row>
                  <Row>{ this.buildAlertPrefs() }</Row>
                </Fade>
              </>
            }
            {
              !this.props.readOnly &&
                <Row className={'mt-2'}>
                  <Col className={'d-flex justify-content-end'}>
                    {
                      showAuditData && person && !isNewPerson && <div className={'pt-2 mr-4'}>
                        <AuditData
                          CreateBy={person.CreateBy}
                          CreateDate={person.CreateDate}
                          UpdateBy={person.UpdateBy}
                          UpdateDate={person.UpdateDate}
                        />
                      </div>
                    }
                    { this.props.enableAgencyConversion && <>
                      <Button
                        onClick={this.convertToAgency}
                        color={'info'}
                        className={'mr-4'}
                        disabled={!isEmpty(this.props.agency)}
                        title={!isEmpty(this.props.agency) ? 'Cannot convert person to Agency as they are already associated to an Agency' : ''}
                      >Convert to Agency</Button>
                      <ConfirmationModal
                        modalKey={CONVERT_TO_AGENCY}
                        modalTitle={'Convert Person to Agency'}
                        modalBody={<>
                          <p>Are you sure you want to convert <b>{person.PersonFirstName} {person.PersonLastName}</b> to a new Agency?</p>
                          <p>This will perform the following actions:</p>
                          <ul className={'pl-3'}>
                            <li>Create a new Agency with the name <b>{person.PersonFirstName}</b></li>
                            <li>Update this Person&apos;s Permits to be associated with the new Agency</li>
                            <li>Convert this Person to be a verified Agent for the new Agency</li>
                          </ul>
                        </>
                        }
                        submitAction={this.props.ConvertPersonToAgency}
                        submitArgs={[ this.props.person.PersonId, ]}
                      />
                    </>
                    }
                    { !this.props.disableMerge 
                      && <Button 
                        onClick={enableMerge ? this.mergeUser : null} 
                        disabled={!enableMerge} 
                        title={enableMerge ? 'Merge Person' : disableMergeMessage} 
                        color={'primary'} 
                        className={'mr-4'}
                      >Merge Person</Button> 
                    }
                    <Button type={'submit'} disabled={isSubmitting}>Save</Button>
                  </Col>
                </Row>
            }
          </Form>
        )}
      </Formik>
    )
  }
}

function mapStateToProps (state, props) {
  
  let personTypes = [], regions = [], agencies = []
  /** @type {Number} `props.personId` by default, otherwise `person.PersonId` */
  let personId =  props.PersonId
  const { person, } = props
  if (!personId && person && person.PersonId) {
    personId = person.PersonId
    person.Agency = props.agency
  }

  const inactiveData = personInactiveData(state)

  const canViewPersonRegion = userCanViewPersonRegion(state)
  
  // Only select regions if the person is DNR
  if (props.showRegion && canViewPersonRegion) {
    regions = regionsForSelectSelector(state, true)
  }
  const personIsDnr = personIsDnrSelector(state, personId)
  const userPersonId = userPersonIdSelector(state)

  // if this is a person managing themselves, get types by permission
  if ((person && person.PersonId === userPersonId) || (personId === userPersonId)) {
    personTypes = personTypesByPermissionSelector(state)
  } else if (personId) {
    personTypes = personTypesForPerson(state, personId)
  }

  // Only select Agencies if the person is an Agent
  const isAgent = personIsAgentOrAgencyPerson(state, personId)
  if (isAgent) {
    agencies = agenciesForSelectSelector(state)
  }

  const readOnly = props.readOnly || (props.person && Number.isInteger(props.person.ParentId))
  const { online, } = state.offline
  return {
    online,
    readOnly,
    regions,
    agencies,
    personTypes,
    personIsDnr,
    isAgent,
    canViewPersonRegion,
    inactiveData,
    user                   : userSelector(state),
    contactMethods         : contactMethodsForSelectSelector(state),
    AlertMethods           : alertMethodsSelector(state),
    AlertPreferences       : alertPreferencesSelect(state),
    canUpdateRegion        : userCanUpdatePersonRegion(state),
    canChangeAlertPrefs    : userCanChangeAlertPrefs(state),
    canEditAgentPersonType : userCanEditVerifiedAgentPersonType(state),
    canVerifyAgents        : userCanVerifyAgents(state),
    canSetSMDecisionStatus : userCanChangeSMDecisionStatus(state),
  }
}

const mapDispatchToProps = {
  OpenModal                   : UiActions.openModal,
  Failure                     : ApiActions.failure,
  RedirectTo                  : AppActions.redirectTo,
  CreatePerson                : PersonActions.createPerson,
  CreateLocalAddressForPerson : PersonActions.createLocalAddressForPerson,
  CreatePersonAddress         : PersonActions.createPersonAddress,
  DeletePersonAddress         : PersonActions.deletePersonAddress,
  CreateLocalPhoneForPerson   : PersonActions.createLocalPhoneForPerson,
  CreatePersonPhone           : PersonActions.createPersonPhone,
  DeletePersonPhone           : PersonActions.deletePersonPhone,
  CreatePersonEmail           : PersonActions.createPersonEmail,
  DeletePersonEmail           : PersonActions.deletePersonEmail,
  UpdatePerson                : PersonActions.updatePerson,
  UpdatePersonRegion          : PersonActions.updatePersonRegion,
  SetPersonRegion             : PersonActions.setPersonRegion,
  GetPersonLookupData         : PersonActions.getPersonLookupData,
  SetInactiveMetadata         : PersonActions.setInactiveMetadata,
  GetAllRegions               : RegionActions.getAllRegions,
  GetAllAgencies              : AgencyActions.getAllAgencies,
  VerifyAgencyUser            : AgencyActions.verifyAgencyUser,
  ConvertPersonToAgency       : AgencyActions.convertPersonToAgency,
  SetMergeObjectOneId         : MergeActions.setObjectOneId,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PersonForm)