import React from 'react'
import { bool, func, string, } from 'prop-types'

export default class Dropzone extends React.Component {
  constructor (props) {
    super(props)

    this.fileInputRef = React.createRef()
    
    this.validFileExtensions = props.validFileExtensions || this.validFileExtensions
  }

  static propTypes = {
    disabled            : bool,
    onFilesAdded        : func,
    validFileExtensions : string,
    multiple            : bool,
  }

  validFileExtensions = '.pdf,.jpg,.doc,.docx,.xls,.xlsx,.kml,.kmz,.msg,.zip'

  state = {
    hightlight: false,
  }

  onDragOver = (evt) => {
    evt.preventDefault()
  
    if (this.props.disabled) {
      return
    }
    
    this.setState({ hightlight: true, })
  }

  onDragLeave = () => {
    this.setState({ hightlight: false, })
  }

  onDrop = (evt) => {
    evt.preventDefault()
  
    if (this.props.disabled) {
      return
    }

    this.setState({ error: '', hightlight: false, })

    const files = evt.dataTransfer.files
    if (typeof this.props.onFilesAdded === 'function') {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }

  openFileDialog = () => {
    if (this.props.disabled) {
      return
    }

    this.setState({ error: '', files: [], })
    this.fileInputRef.current.value = '' // Ensure there is no value set in case they try to choose the same file again
    this.fileInputRef.current.click()
  }

  onFilesAdded = (evt) => {
    if (this.props.disabled) {
      return
    }

    const files = evt.target.files
    if (typeof this.props.onFilesAdded === 'function') {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }

  fileListToArray = (list) => {
    let array = []
    const validExts = (this.validFileExtensions).split(',')
    for (var i = 0; i < list.length; i++) {
      const file = list.item(i)
      const fileExt = file.name.substring(file.name.lastIndexOf('.'))
      if (validExts.map(x => x.toLowerCase()).includes(fileExt.toLowerCase()) === false) {
        this.setState({ error: `The file type ${fileExt} is not allowed. Only the following are allowed: ${validExts.join(', ')}.`, })
        array = []
        break
      }
      array.push(file)
    }
    return array
  }

  render () {
    const { disabled, multiple, } = this.props
    const dropZoneClass = `dropzone${disabled ? ' disabled' : ''}`
    const dropZoneLabel = `Upload File${multiple ? 's' : ''}`
    return <div
      className={dropZoneClass}
      onClick={this.openFileDialog}
      onKeyPress={this.openFileDialog}
      onDragOver={this.onDragOver}
      onDragLeave={this.onDragLeave}
      onDrop={this.onDrop}
      role={'none'}
      tabIndex={0}
      style={{ borderColor: this.state.hightlight ? '#62abd0' : '', }}
    >
      <span className={'fa fa-cloud-upload-alt'}></span>
      <input
        ref={this.fileInputRef}
        className={'file-input'}
        type={'file'}
        onChange={this.onFilesAdded}
        accept={this.validFileExtensions}
        multiple={multiple}
      />
      <span>{dropZoneLabel}</span>
      {
        this.state.error && <div className={'text-danger w-100 px-2'}>{this.state.error}</div>
      }
    </div>
  }
}