import React from 'react'
import { Navigate, } from 'react-router-dom'
import { func, object, PropTypes, bool, string, } from 'prop-types'
import NotFound from '../components/NotFound'
import LoggingIn from '../components/LoggingIn'
import AgentUnverified from '../components/AgentUnverified'


function AdminOrAgentRoute ({ children, ...rest }) {
  if (rest.IsLoadingUser || rest.LoginError) {
    return <LoggingIn loginError={rest.LoginError} />
  }
  if (rest.isVerifiedAgent !== true && rest.isAgent === true) {
    return <AgentUnverified />
  }
  if (!rest.isAuthenticated) {
    return <Navigate to={'/Login'} />
  }
  else if (rest.isDnr === true || rest.isVerifiedAgent === true) {
    return children
  }
  return <NotFound />
}

AdminOrAgentRoute.propTypes = {
  children: PropTypes.oneOfType([ func, object, ]),
}

AdminOrAgentRoute.propTypes = {
  children                : PropTypes.oneOfType([ func, object, ]),
  restrictAgency          : bool,
  restrictUnverifiedAgent : bool,
  IsLoadingUser           : bool,
  LoginError              : string,
  isAuthenticated         : bool,
  isAgent                 : bool,
  isVerifiedAgent         : bool,
  isDnr                   : bool,
}

AdminOrAgentRoute.defaultProps = {
  component               : null,
  restrictAgency          : false,
  restrictUnverifiedAgent : true,
  IsLoadingUser           : false,
  LoginError              : '',
  isAuthenticated         : false,
  isAgent                 : false,
  isVerifiedAgent         : false,
  isDnr                   : false,
}

export default AdminOrAgentRoute