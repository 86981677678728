import { createSelector, } from 'reselect'
import { ormSelector, } from './selectors'
import { 
  REACT_APP_LANDOWNER_GROUP,
  REACT_APP_REGION_STAFF,
  REACT_APP_REGION_ADMIN,
  REACT_APP_SMOKE_STAFF,
  REACT_APP_SMOKE_ADMIN,
  REACT_APP_STATE_GOV_CUST, 
  PERSON_TYPE_DNR_STAFF, 
  PERSON_TYPE_GOV_CUST, 
  PERSON_TYPE_PRIVATE, 
  PERSON_TYPE_GOV_AGENT, 
  PERSON_TYPE_AGENT,
  mapGroupToReadableName,
} from './env'

/**
 * Selector for the current User state
 * @returns {Object} The current User state or null
 */
export const userObjSelector = state => state ? state.User : null

/**
 * Memoized selector for current user information
 * @returns {Object} The current user object
 */
export const userSelector = createSelector(userObjSelector,
  user => user ? user : null
)

/**
 * Returns whether or not the current user is authenticated
 * @returns {Boolean} The user is authenticated
 */
export const userIsAuthenticated = createSelector(userObjSelector,
  ({ isAuthenticated, }) => isAuthenticated ? isAuthenticated : false
)

//#region User Group Claim Selectors

/**
 * Selector for the current user's groups
 * @returns {Array} The current user's groups or an empty array
 */
export const userGroupsSelector = createSelector(
  userObjSelector,
  userIsAuthenticated,
  (user, isAuthenticated) => isAuthenticated && user && user.groups ? user.groups.map(x => x.toLowerCase()) : []
)

/**
 * Selector to determine if the user is in the Smoke Staff group
 * @returns {Boolean} True if the user is in the Smoke Staff group
 */
export const userIsSmokeStaff = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_SMOKE_STAFF)
)

/**
 * Selector to determine if the user is in the Smoke Admin group
 * @returns {Boolean} True if the user is in the Smoke Admin group
 */
export const userIsSmokeAdmin = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_SMOKE_ADMIN)
)

/**
 * Selector to determine if the user is in the Gov Customer group
 * @returns {Boolean} True if the user is in the Gov Customers group
 */
export const userIsStateGovCust = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_STATE_GOV_CUST)
)

/**
 * Selector to determine if the user is in the Region Staff group
 * @returns {Boolean} True if the user is in the Region Staff group
 */
export const userIsRegionStaff = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_REGION_STAFF)
)

/**
 * Selector to determine if the user is in the Region Admin group
 * @returns {Boolean} True if the user is in the Region Admin group
 */
export const userIsRegionAdmin = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_REGION_ADMIN)
)

/**
 * Selector to determine if the user has the SAW group claim
 * @returns {Boolean} True if the user has the SAW group claim
 */
export const userIsSAW = createSelector(
  userGroupsSelector,
  userIsAuthenticated,
  (groups, isAuthenticated) => isAuthenticated && groups.includes(REACT_APP_LANDOWNER_GROUP)
)

//#endregion

//#region Group Claim Derived Selectors
/**
 * Returns whether or not the current user is in a DNR staff group
 * @returns {Boolean} The user is in a DNR staff group
 */
export const userIsADFS = createSelector(
  userIsSmokeStaff,
  userIsSmokeAdmin,
  userIsStateGovCust,
  userIsRegionStaff,
  userIsRegionAdmin,
  (isSmokeStaff, isSmokeAdmin, isGovCust, isRegionStaff, isRegionAdmin) =>
    isSmokeStaff === true || isSmokeAdmin === true
    || isGovCust === true || isRegionStaff === true
    || isRegionAdmin === true
)

/**
 * Returns whether or not the current user is in a DNR Region group
 * @returns {Boolean} The user is in a DNR Region group
 */
export const userIsRegionUser = createSelector(
  userIsRegionStaff,
  userIsRegionAdmin,
  (isRegionStaff, isRegionAdmin) => 
    isRegionStaff === true || isRegionAdmin === true
)

/**
 * Returns whether or not the current user is in a Smoke Managment group
 * @returns {Boolean} The user is in a Smoke Managment group
 */
export const userIsSmokeMgmt = createSelector(
  userIsSmokeStaff,
  userIsSmokeAdmin,
  (isSmokeStaff, isSmokeAdmin) => 
    isSmokeStaff === true || isSmokeAdmin === true
)

/**
 * Return the current user's groups
 * @returns {Array} The current user's groups
 */
export const currentUserGroupsSelector = createSelector(
  userGroupsSelector,
  (groups) => {
    if (Array.isArray(groups)) {
      const usrGroups = groups.map(mapGroupToReadableName).filter(g => !!g)
      return usrGroups.sort()
    }
    return []
  }
)

//#endregion

//#region User State selectors

/**
 * Returns the current user's username
 * @returns {Boolean} The user is in a DNR staff gropu
 */
export const userNameSelector = createSelector(userObjSelector,
  ({ userName, isAuthenticated, }) => isAuthenticated && userName ? userName : null 
)

/**
 * Return the current user's token
 * @returns {string} the current user's api token
 */
export const getUserToken = createSelector(userObjSelector,
  ({ token, isAuthenticated, }) => isAuthenticated && token ? token : null
)

/**
 * Return the status of the user information fetch
 * @returns {Boolean} true if the user information fetch is in progress
 */
const userIsFetching = createSelector(userObjSelector,
  ({ isFetching, }) => isFetching ? isFetching : false
)

/**
 * Return any error captured during login
 * @returns {String} the captured login error or null
 */
const loginError = createSelector(userObjSelector,
  ({ errorMessage, }) => errorMessage ? errorMessage : null
)

/**
 * Return the current user's network notification preference
 * @returns {Boolean} the user's network notification preference
 */
export const userNetworkNotifEnabled = createSelector(userObjSelector,
  ({ networkNotificationsEnabled, }) => networkNotificationsEnabled ? networkNotificationsEnabled : false
)

/**
 * Return the current user's Person ID
 * @returns {Number} The current user's Person ID or null
 */
export const userPersonIdSelector = createSelector(userObjSelector,
  ({ personId, isAuthenticated, }) => 
    isAuthenticated && personId ? personId : null
)

/**
 * Return the current user's verified agent status
 * @returns {Boolean} true if the user is a verified agent
 */
const userIsVerifiedAgent = createSelector(userObjSelector,
  ({ isVerifiedAgent, isAuthenticated, }) => 
    isAuthenticated && isVerifiedAgent ? isVerifiedAgent : false
)

//#endregion

//#region ORM User Person selectors

/**
 * Return the current user's associated Person Model
 * @returns {Object} the user's person model or null if unavailable 
 */
export const userPersonModelSelector = ormSelector(
  userPersonIdSelector,
  userIsAuthenticated,
  ({ Person, }, personId, isAuthenticated) => 
    isAuthenticated && !isNaN(personId) && Person 
      ? Person.withId(personId) 
      : null
)

/**
 * Return the current user's associated Person Type name
 * @returns {String} the user's Person Type if available or empty string
 */
const userPersonTypeNameSelector = ormSelector(userPersonModelSelector,
  (session, person) => person && person.PersonType ? person.PersonType.PersonTypeName : ''
)

/**
 * Selector to determine if the Agency the current user is a part of is configured
 * to pay by voucher or not
 * @returns {Boolean}
 */
export const usersAgencyPaysByVoucher = ormSelector(
  userPersonIdSelector,
  ({ PersonAgencyXref, }, PersonId) => {
    const agency = PersonAgencyXref.filter({ PersonAgencyXrefPersonId: PersonId, }).first()
    if (!agency || !agency.PersonAgencyXrefAgency) {
      return false
    }
    return agency.PersonAgencyXrefAgency.PaysByVoucher || false
  }
)

/**
 * Selector to determine if the current user's person type is Government Agency
 * @returns {Boolean} True if the current user's person type is government agency
 */
const userIsGovAgencyType = createSelector(
  userPersonTypeNameSelector,
  userIsSAW,
  (personTypeName, isSAW) => isSAW && personTypeName === PERSON_TYPE_GOV_AGENT
)

/**
 * Selector to determine if the current user's person type is Agent
 * @returns {Boolean} True if the current user's person type is agent
 */
const userIsAgentType = createSelector(
  userPersonTypeNameSelector,
  userIsSAW,
  (personTypeName, isSAW) => isSAW && personTypeName === PERSON_TYPE_AGENT
)

/**
 * Selector to determine if the current user's person type is Private
 * @returns {Boolean} True if the current user's person type is Private
 */
const userIsPrivateType = createSelector(
  userPersonTypeNameSelector,
  userIsSAW,
  (personTypeName, isSAW) => isSAW && personTypeName === PERSON_TYPE_PRIVATE
)

/**
 * Selector to determine if the current user's person type is DNR Staff
 * @returns {Boolean} True if the current user's person type is DNR Staff
 */
const userIsDnrStaffType = createSelector(
  userPersonTypeNameSelector,
  userIsADFS,
  (personTypeName, isADFS) => isADFS && personTypeName === PERSON_TYPE_DNR_STAFF
)

/**
 * Selector to determine if the current user's person type is DNR Staff
 * @returns {Boolean} True if the current user's person type is DNR Staff
 */
const userPersonIsGovCustType = createSelector(
  userPersonTypeNameSelector,
  userIsStateGovCust,
  (personTypeName, isStateGovCust) => isStateGovCust && personTypeName === PERSON_TYPE_GOV_CUST
)


export const userIsDNR = createSelector(
  userIsSmokeStaff,
  userIsSmokeAdmin,
  userIsRegionStaff,
  userIsRegionAdmin,
  userIsDnrStaffType,
  (isSmokeStaff, isSmokeAdmin, isRegionStaff, isRegionAdmin, isDNRStaffType) => 
    (isSmokeStaff || isSmokeAdmin || isRegionStaff || isRegionAdmin) && isDNRStaffType
)


/**
 * Return the current user's verified agent/agency/state gov customer status
 * @returns {Boolean} true if the user is a verified agent
 */
export const userIsUnverifiedAgent = createSelector(
  userObjSelector,
  userIsAgentType,
  userIsGovAgencyType,
  userIsSAW,
  userIsAuthenticated,
  userIsStateGovCust,
  ({ isVerifiedAgent, }, isAgent, isGovAgent, isSAWUser, isAuthenticated, isStateGovCust) => 
    isAuthenticated && ((isSAWUser && (isAgent || isGovAgent)) || isStateGovCust) && !isVerifiedAgent
)

//#endregion

/**
 * Consolidated selector for User properties
 * @returns {Object} authProps An object containing User properties
 */
export const authPropsSelector = createSelector(
  userIsAuthenticated,
  userIsSmokeAdmin,
  userIsDNR,
  userIsSAW,
  userIsPrivateType,
  userIsGovAgencyType,
  usersAgencyPaysByVoucher,
  userIsAgentType,
  userIsVerifiedAgent,
  userIsFetching,
  loginError,
  userPersonIsGovCustType,
  userIsADFS,
  (isAuthenticated, isAdmin, isDnr, isSAWUser, isPrivate, isGovAgent, paysByVoucher, isAgent, isVerifiedAgent, IsLoadingUser, LoginError, isStateGovCust, isADFS) => {
    return {
      isAuthenticated,
      isAdmin,
      isDnr,
      isSAWUser,
      isPrivate,
      isGovAgent,
      paysByVoucher,
      isAgent,
      isVerifiedAgent,
      IsLoadingUser,
      LoginError,
      isStateGovCust,
      isADFS,
    }
  }
)

const userHasAddressesSelector = ormSelector(
  userPersonIdSelector,
  ({ Person, }, personId) => {
    if (personId === null || isNaN(personId) || personId < 1) {
      return false
    }
    const person = Person.withId(personId)
    if (!person || !person.Addresses) {
      return false
    }
    return person.Addresses.toRefArray().some(o => !o.IsLocal)
  }
)

const userHasPhonesSelector = ormSelector(
  userPersonIdSelector,
  ({ Person, }, personId) => {
    if (personId === null || isNaN(personId) || personId < 1) {
      return false
    }
    const person = Person.withId(personId)
    if (!person || !person.Phones) {
      return false
    }
    return person.Phones.toRefArray().some(o => !o.IsLocal)
  }
)

const userHasAgencySelector = ormSelector(
  userPersonIdSelector,
  ({ Person, }, personId) => {
    if (personId === null || isNaN(personId) || personId < 1) {
      return false
    }
    const person = Person.withId(personId)
    if (!person || !person.Agencies) {
      return false
    }
    return person.Agencies.exists()
  }
)

const userHasRegionSelector = ormSelector(
  userPersonIdSelector,
  ({ Person, }, personId) => {
    if (personId === null || isNaN(personId) || personId < 1) {
      return false
    }
    const person = Person.withId(personId)
    if (!person || !person.Regions) {
      return false
    }
    return person.Regions.exists()
  }
)

export const profileStatusSelector = createSelector(
  userPersonModelSelector,
  userIsPrivateType,
  userIsAgentType,
  userIsGovAgencyType,
  userIsSAW,
  userIsDNR,
  userIsStateGovCust,
  userIsFetching,
  userHasAddressesSelector,
  userHasPhonesSelector,
  userHasRegionSelector,
  userHasAgencySelector,
  (person, isPrivateType, isAgent, isGovAgent, isSAWUser, isDnr, isStateGov, isFetching, hasAddrs, hasPhones, hasRegion, hasAgency) => {
    // Don't show the alert if we don't know anything about the person yet
    if (isFetching || !person) {
      return { IsComplete: true, }
    }
    // All users need at least the Contact Method and one or more Phones
    const status = {
      HasPersonType    : isPrivateType || isAgent || isGovAgent || isDnr || isStateGov,
      HasContactMethod : person.ContactMethodId > 0,
      HasPhones        : hasPhones,
    }

    if (isDnr) {
      // DNR Users also need a Region
      status.HasRegion = hasRegion
    }
    
    if (isSAWUser) {
      status.HasAddresses = hasAddrs
      if (isAgent || isGovAgent) {
        status.HasAgency = hasAgency
      }
    }
    // If every value is true, then the profile is complete
    status.IsComplete = Object.values(status).every(v => v === true)
    return status
  }
)