import { attr, fk, Model, } from 'redux-orm'

/**
 * ForestHealthExemptProblemTypeXref model
 */
export default class ForestHealthExemptProblemTypeXref extends Model {
  static get modelName () {
    return 'ForestHealthExemptProblemTypeXref'
  }

  static get options () {
    return {
      idAttribute: 'ForestHealthExemptProblemTypeXrefId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      ForestHealthExemptProblemTypeXrefId : attr(),
      ForestHealthExemptId                : fk({ to: 'ForestHealthExempt', relatedName: 'ForestHealthExemptProblemTypeXrefs', as: 'ForestHealthExempt', }),
      ForestHealthProblemTypeId           : fk({ to: 'ForestHealthProblemType', relatedName: 'ForestHealthExemptProblemTypeXrefs', as: 'ForestHealthProblemType', }),
      CreateDate                          : attr(),
      CreateBy                            : attr(),
      UpdateDate                          : attr(),
      UpdateBy                            : attr(),
    }
  }
}