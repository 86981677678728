import { Model, attr, } from 'redux-orm'
import { renderDate, formatLegalDesc, renderTons, renderAcres, renderCheckOrXIcon, renderLatLong, } from '../utilities/columnHelpers'
import { dateFormatter, objectToQueryParams, objHasProp, } from '../utilities'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnPermitSearch extends Model {

  static get modelName () {
    return 'BurnPermitSearch'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitId',
    }
  }

  static endpoint (params) {
    let endpoint = `${REACT_APP_SERVER_URL}BurnPermits/Search`
    if (params && typeof params === 'object') {
      endpoint += `?${objectToQueryParams(params)}`
    }
    return endpoint
  }

  static getDataTablesColumns (props) {
    
    let showLandownerAgent = false, enableCart = false
    
    if (props && objHasProp(props, 'showLandownerAgent')) {
      showLandownerAgent = props.showLandownerAgent
    }

    if (props && objHasProp(props, 'enableCart')) {
      enableCart = props.enableCart
    }
    
    const cols = [
      {
        title     : 'Order',
        data      : 'BurnPermitId',
        orderable : false,
        width     : 15,
        visible   : enableCart,
      },
      {
        title     : 'Application Id',
        data      : 'BurnPermitId',
        className : 'text-right pr-4',
        type      : 'num',
        render    : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return `<a href='/permits/${data}' data-burn-permit-id='${data}'>${data}</a>`
        },
      },
      {
        title          : 'Permit Number',
        data           : 'BurnPermitNumber',
        defaultContent : '',
        className      : 'text-right pr-4',
      },
      {
        title          : 'Unit Name',
        data           : 'BurnUnitName',
        defaultContent : '',
        width          : '100px',
      },
      {
        title          : 'Burn Type(s)',
        data           : 'BurnType',
        defaultContent : '',
      },
      {
        title          : 'Status',
        data           : 'BurnPermitApplicationStatus',
        defaultContent : '',
        width          : '75px',
        render         : (data, drawType, row) => {
          if (drawType === 'sort') {
            if (row.BurnPermitStatus) {
              return `${row.BurnPermitStatus}<br/>${dateFormatter(row.BurnPermitStatusDate, 'MMM DD, YYYY')}`
            }
            return `${data}<br/>${dateFormatter(row.BurnPermitApplicationStatusDate, 'MMM DD, YYYY')}`
          }
          if (drawType !== 'display') {
            return row.BurnPermitStatus || row.BurnPermitApplicationStatus
          }
          else if (!data) {
            return data
          }
          if (row.PermitIsSuspended) {
            return 'Suspended'
          }
          else if (row.BurnPermitStatus) {
            return `${row.BurnPermitStatus}<br/>${dateFormatter(row.BurnPermitStatusDate, 'MMM DD, YYYY')}`
          }
          return `${data}<br/>${dateFormatter(row.BurnPermitApplicationStatusDate, 'MMM DD, YYYY')}`
        },
      },
      {
        title          : 'Expiration Date',
        data           : 'BurnPermitExpirationDate',
        className      : 'text-right pr-4',
        render         : renderDate,
        defaultContent : '',
        width          : '75px',
      },
      {
        title  : 'In UGA',
        data   : 'IsUGA',
        render : renderCheckOrXIcon,
      },
      {
        title          : 'Forest Health Exempt',
        data           : 'IsForestHealthExempt',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType === 'sort') {
            return data === null ? 0 : data === true ? 1 : -1
          }
          if (drawType !== 'display') {
            return data
          }
          if (data === null) {
            return data
          }
          return renderCheckOrXIcon(data, drawType)
        },
      },
      {
        title          : 'County',
        data           : 'County',
        defaultContent : '',
      },
      {
        title          : 'Legal Description',
        defaultContent : '',
        render         : (data, drawType, row) => {
          if (drawType !== 'display') {
            return row.LegalDescSection
          }
          return formatLegalDesc(row)
        },
      },
      {
        title          : 'Burn Address',
        width          : '200px',
        defaultContent : '',
        render         : (data, drawType, row) => {
          if (drawType !== 'display') {
            return row.Address1
          }

          let address = row.Address1
          if (row.Address2) {
            address += '<br/>' + row.Address2
          }
          if (row.Address3) {
            address += '<br/>' + row.Address3
          }
          if (row.AddressCity) {
            address += '<br/>' + row.AddressCity
          }
          if (row.AddressState) {
            address += ', ' + row.AddressState
          }
          if (row.AddressZip) {
            address += ' ' + row.AddressZip
          }

          return address
        },
      },
      {
        title          : 'Est. Permit Tonnage',
        data           : 'TotalPermitTonnage',
        className      : 'text-right pr-4',
        defaultContent : '',
        render         : renderTons,
      },
      {
        title          : 'Total Requested Tons',
        data           : 'TotalRequestedTons',
        className      : 'text-right pr-4',
        defaultContent : '',
        render         : renderTons,
      },
      {
        title          : 'Harvest Acres',
        data           : 'BurnAcres',
        className      : 'text-right pr-4',
        defaultContent : '',
        render         : renderAcres,
      },
      {
        title          : 'Total Requested Acres',
        data           : 'TotalRequestedAcres',
        className      : 'text-right pr-4',
        defaultContent : '',
        render         : renderAcres,
      },
      {
        title   : 'Lat, Long',
        visible : false,
        data    : '',
        render  : renderLatLong,
      },
    ]

    if (showLandownerAgent) {
      const landowner = {
        defaultContent : '',
        title          : 'Landowner',
        data           : 'Landowner',
        width          : '150px',
      }
      const agent = {
        defaultContent : '',
        title          : 'Agent',
        data           : 'Agent',
        width          : '150px',
      }
      const agency = {
        defaultContent : '',
        title          : 'Agency',
        data           : 'Agency',
        width          : '150px',
      }
      cols.splice(cols.findIndex(c => c.title === 'Unit Name'), 0, landowner)
      cols.splice(cols.findIndex(c => c.title === 'Unit Name'), 0, agent)
      cols.splice(cols.findIndex(c => c.title === 'Unit Name'), 0, agency)
    }

    return cols
  }

  static get fields () { 
    return {
      // Common Properties
      BurnPermitId             : attr(),
      // Model-Specific Properties
      BurnPermitNumber         : attr(),
      BurnUnitName             : attr(),
      TotalPermitTonnage       : attr(),
      Elevation                : attr(),
      BurnAcres                : attr(),
      TotalRequestedTons       : attr(),
      TotalRequestedAcres      : attr(),
      AgencyId                 : attr(),
      Agency                   : attr(),
      AgentId                  : attr(),
      Agent                    : attr(),
      LandownerId              : attr(),
      Landowner                : attr(),
      LegacyId                 : attr(),
      BurnPermitExpirationDate : attr(),
      PermitIsSuspended        : attr(),
      BurnPermitStatus         : attr(),
      BurnPermitStatusDate     : attr(),
      LegalDescQ1              : attr(),
      LegalDescQ2              : attr(),
      LegalDescSection         : attr(),
      LegalDescTownship        : attr(),
      LegalDescRange           : attr(),
      LegalDescDirection       : attr(),
      Address1                 : attr(),
      Address2                 : attr(),
      Address3                 : attr(),
      AddressCity              : attr(),
      AddressState             : attr(),
      AddressZip               : attr(),
      County                   : attr(),
      PlannedIgnitionDates     : attr(),
      BurnTypes                : attr(),
      Latitude                 : attr(),
      Longitude                : attr(),
      PermitOrderId            : attr(),
      IsUGA                    : attr(),
      IsForestHealthExempt     : attr(),
    }
  }
}