import { createReducer, createActions, } from 'reduxsauce'
import { addItemToArray, removeItemFromArray, } from '../utilities'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  AddItemToCart      : [ 'itemId', ],
  RemoveItemFromCart : [ 'itemId', ],
  EmptyCart          : null,
})

export const CartTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  CartItemIds: [],
}

export const AddItemToCart = (state, { itemId, }) => {
  const newState = { ...state, }
  newState.CartItemIds = addItemToArray(newState.CartItemIds, parseInt(itemId))
  return newState
}

export const RemoveItemFromCart = (state, { itemId, }) => {
  const newState = { ...state, }
  newState.CartItemIds = removeItemFromArray(newState.CartItemIds, parseInt(itemId))
  return newState
}

export const EmptyCart = () => {
  return { ...INITIAL_STATE, }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_ITEM_TO_CART]      : AddItemToCart,
  [Types.REMOVE_ITEM_FROM_CART] : RemoveItemFromCart,
  [Types.EMPTY_CART]            : EmptyCart,
})