import { attr, fk, Model, } from 'redux-orm'
import * as Yup from 'yup'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnPermitLocation extends Model {
  static get modelName () {
    return 'BurnPermitLocation'
  }

  toString () {
    return `${this.BurnPermitLocationId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermitLocations`
  }

  static getValidationSchema (schemaType) {

    const { latLong = false, plss = false, } = schemaType || {}

    let shapeObj = {}

    if (latLong === true) {

      const {
        Latitude,
        Longitude,
      } = this.fields

      shapeObj = {
        Latitude: Yup.number()
          .when([ 'Longitude', 'Force', ] , {
            is: (Long, Force) => {
              return !!Long || Force
            },
            then: Yup.number().required(`Latitude must be between ${Latitude.opts.min} and ${Latitude.opts.max}.`),
          })
          .typeError('Latitude must be a number')
          .min(Latitude.opts.min, `Latitude must be between ${Latitude.opts.min} and ${Latitude.opts.max}.`)
          .max(Latitude.opts.max, `Latitude must be between ${Latitude.opts.min} and ${Latitude.opts.max}.`),
        Longitude: Yup.number()
          .when([ 'Latitude', 'Force', ], {
            is: (Lat, Force) => {
              return !!Lat || Force
            },
            then: Yup.number().required(`Longitude must be between ${Longitude.opts.min} and ${Longitude.opts.max}.`),
          })
          .typeError('Longitude must be a number')
          .min(Longitude.opts.min, `Longitude must be between ${Longitude.opts.min} and ${Longitude.opts.max}.`)
          .max(Longitude.opts.max, `Longitude must be between ${Longitude.opts.min} and ${Longitude.opts.max}.`),
      }
    }
    else if (plss === true) {

      const {
        LegalDescriptionSection,
        LegalDescriptionTownship,
        LegalDescriptionRange,
      } = this.fields

      shapeObj = {
        LegalDescriptionQuarter1: Yup.string()
          .ensure()
          .required('Please select a Quarter'),
        LegalDescriptionQuarter2: Yup.string()
          .ensure()
          .required('Please select a Quarter'),
        LegalDescriptionSection: Yup.number()
          .min(LegalDescriptionSection.opts.min, `Section must be between ${LegalDescriptionSection.opts.min} and ${LegalDescriptionSection.opts.max}.`)
          .max(LegalDescriptionSection.opts.max, `Section must be between ${LegalDescriptionSection.opts.min} and ${LegalDescriptionSection.opts.max}.`)
          .required('Please enter a Section'),
        LegalDescriptionTownship: Yup.number()
          .min(LegalDescriptionTownship.opts.min, `Township must be between ${LegalDescriptionTownship.opts.min} and ${LegalDescriptionTownship.opts.max}.`)
          .max(LegalDescriptionTownship.opts.max, `Township must be between ${LegalDescriptionTownship.opts.min} and ${LegalDescriptionTownship.opts.max}.`)
          .required('Please enter a Township'),
        LegalDescriptionRange: Yup.number()
          .min(LegalDescriptionRange.opts.min, `Range must be between ${LegalDescriptionRange.opts.min} and ${LegalDescriptionRange.opts.max}.`)
          .max(LegalDescriptionRange.opts.max, `Range must be between ${LegalDescriptionRange.opts.min} and ${LegalDescriptionRange.opts.max}.`)
          .required('Please enter a Range'),
        LegalDescriptionDirectionId: Yup.string()
          .ensure()
          .required('Please select a Direction'),
      }

    }
    else {
      
      const {
        SlopePercent,
        ElevationFeet,
      } = this.fields

      shapeObj = {
        RegionId: Yup.string()
          .ensure()
          .required('Please choose the Region you intend to burn in.'),
        CountyId: Yup.string()
          .ensure()
          .required('Please choose the County you intend to burn in.'),
        FireDistrictDepartmentId: Yup.string()
          .ensure()
          .required('Please choose the Fire District you intend to burn in.'),
        DrivingDirections: Yup.string()
          .ensure()
          .required('Please enter driving directions'),
        SlopePercent: Yup.number()
          .typeError('Slope percent must be a number')
          .min(SlopePercent.opts.min, `Slope percent must be between ${SlopePercent.opts.min} and ${SlopePercent.opts.max}`)
          .max(SlopePercent.opts.max, `Slope percent must be between ${SlopePercent.opts.min} and ${SlopePercent.opts.max}`)
          .required('Please enter a slope percent'),
        ElevationFeet: Yup.number()
          .typeError('Elevation must be a number')
          .min(ElevationFeet.opts.min, `Elevation must be between ${ElevationFeet.opts.min} and ${ElevationFeet.opts.max} feet`)
          .max(ElevationFeet.opts.max, `Elevation must be between ${ElevationFeet.opts.min} and ${ElevationFeet.opts.max} feet`)
          .required('Please enter an elevation'),
      }
    }

    return Yup.object().shape(shapeObj, [ 'Latitude', 'Longitude', ])
  }
}

BurnPermitLocation.options = {
  idAttribute: 'BurnPermitLocationId',
}

BurnPermitLocation.fields = {
  // Common Properties
  BurnPermitLocationId              : attr(),
  CreateDate                        : attr(),
  CreateBy                          : attr(),
  UpdateDate                        : attr(),
  UpdateBy                          : attr(),
  // Model-Specific Properties
  Latitude                          : attr({ min: 45.5500, max: 49.0000, }),
  Longitude                         : attr({ min: -124.7667, max: -116.9167, }),
  DrivingDirections                 : attr(),
  LegalDescriptionQuarter1          : attr(),
  LegalDescriptionQuarter2          : attr(),
  LegalDescriptionSection           : attr({ min: 1, max: 96, }),
  LegalDescriptionTownship          : attr({ min: 1, max: 41, }),
  LegalDescriptionRange             : attr({ min: 1, max: 47, }),
  LegalDescriptionDirectionLocation : attr(),
  ElevationFeet                     : attr({ min: 0, max: 14411, }),
  SlopePercent                      : attr({ min: 0, max: 1000, }),
  IsUGA                             : attr(),
  // Relationships
  AddressId                         : fk({ to: 'Address', relatedName: 'BurnPermitLocations', as: 'Address', }),
  AddressLocation                   : attr(),
  CountyId                          : fk({ to: 'County', relatedName: 'BurnPermitLocations', as: 'County', }),
  RegionId                          : fk({ to: 'Region', relatedName: 'BurnPermitLocations', as: 'Region', }),
  FireDistrictDepartmentId          : fk({ to: 'FireDistrictDepartment', relatedName: 'BurnPermitLocations', as: 'FireDistrictDepartment', }),
  LegalDescriptionDirectionId       : fk({ to: 'Direction', relatedName: 'BurnPermitLocations', as: 'LegalDescriptionDirection', }),
}