import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useSearchParams, } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Container, Row, } from 'reactstrap'
import { bool, } from 'prop-types'

// Actions
import AppActions from '../../redux/AppRedux'

// Selectors
import { signatureCallbackState, } from '../../selectors/burnPermitSignatureSelectors'

// Components
import {
  ApplicationSigned,
  CancelSigning,
  DeclineSigning,
  PermitSigned,
  SigningError,
} from './'

// Actions
import BurnPermitSignatureActions from '../../redux/BurnPermitSignatureRedux'


export const DocuSignCallback = (props) => {

  const dispatch = useDispatch()

  const [ searchParams, ] = useSearchParams()
  const event = searchParams.get('event')

  // Selectors
  const {
    confirmingSignature,
    confirmationFailed,
    applicationSignatureConfirmed,
    permitSignatureConfirmed,
  } = useSelector(signatureCallbackState)

  React.useEffect(() => {
    dispatch(BurnPermitSignatureActions.docuSignSignatureEvent(event))
  }, [ dispatch, event, ])

  let markup = <Card>
    <CardHeader>
      <div className={'d-flex justify-content-between'}>
        <h3>DocuSign Signature</h3>
      </div>
    </CardHeader>
    <CardBody>
      <p>Getting your DocuSign Signature details.</p>
    </CardBody>
  </Card>

  let pageTitle = 'Getting Signature Details'

  if (!event || confirmationFailed) {
    pageTitle = 'Signing Error'
    markup = <SigningError />
  }
  else if (event === 'cancel') {
    pageTitle = 'Cancelled Signing'
    markup = <CancelSigning />
  }
  else if (event === 'decline') {
    pageTitle = 'Declined Signing'
    markup = <DeclineSigning />
  }
  else if (confirmingSignature) {
    pageTitle = 'Confirming Signature'
    markup = <Card>
      <CardHeader>
        <div className={'d-flex justify-content-between'}>
          <h3>DocuSign Signature</h3>
        </div>
      </CardHeader>
      <CardBody>
        <p>Confirming your DocuSign Signature.</p>
      </CardBody>
    </Card>
  }
  else if (permitSignatureConfirmed) {
    pageTitle = 'Permit Signed!'
    markup = <PermitSigned />
  }
  else if (applicationSignatureConfirmed) {
    pageTitle = 'Application Signed!'
    const enableCreateOrder = props.paysByVoucher !== true
    markup = <ApplicationSigned enableCreateOrder={enableCreateOrder}/>
  }

  React.useEffect(() => {
    dispatch(AppActions.setPageTitle(pageTitle))
  }, [ dispatch, pageTitle, ])

  return <Container className={'my-4'}>
    <Row className={'mb-3'}>
      <Col>
        {markup}
      </Col>
    </Row>
  </Container>
}

DocuSignCallback.propTypes = {
  paysByVoucher: bool,
}

DocuSignCallback.defaultProps = {
  paysByVoucher: false,
}

export default React.memo(DocuSignCallback)