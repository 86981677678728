// Libraries
import React, { useState, } from 'react'
import { Row, Col, Button, Form, FormGroup,FormFeedback, Label, Input, } from 'reactstrap'
import { func, string, } from 'prop-types'

// Utilities
import stopEvent from '../utilities/stopEvent'

const noFuncProvided = () => {
  console.error('Permit number search was not set up correctly.')
}

const PermitNumberSearch = ({
  doSearch,
  onChange,
  error,
  searchHeaderText,
}) => {
  const [ permitNumber, setPermitNumber, ] = useState('')
  // when user types in the box
  // update state
  const onInputChange = evt => {
    setPermitNumber(evt.target.value)
    onChange(evt.target.value)
  }

  // when form gets a keypress
  // if it's 13, submit whatever is in the box
  const onKeyPress = evt => {
    if (evt.charCode === 13) {
      handleSearch(evt)
    }
  }

  // 
  const handleSearch = evt => {
    if (evt) {
      stopEvent(evt)
    }
    doSearch(permitNumber)
  }

  return <Form onKeyPress={onKeyPress}>
    <h4>{searchHeaderText}</h4>
    <Row className={'my-5'}>
      <Col>
        <FormGroup>
          <Label for={'txt-permit-number'}>Permit Number Search</Label>
          <Input
            id={'txt-permit-number'}
            onChange={onInputChange}
            value={permitNumber || ''}
            invalid={error.length > 0}
          />
          <FormFeedback>{error}</FormFeedback>
        </FormGroup>
      </Col>
      <Col>
        <Button className={'mr-3 float-left'} onClick={handleSearch}>Find</Button>
      </Col>
    </Row>
  </Form>
}


PermitNumberSearch.propTypes = {
  doSearch         : func,
  onChange         : func,
  error            : string,
  searchHeaderText : string,
}

PermitNumberSearch.defaultProps = {
  doSearch         : noFuncProvided,
  onChange         : noFuncProvided,
  error            : '',
  searchHeaderText : 'Please search for a permit.',
}

export default PermitNumberSearch