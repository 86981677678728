import { attr, Model, } from 'redux-orm'
import { number, string, } from 'prop-types'
import * as Yup from 'yup'

export default class Email extends Model {
  static get modelName () {
    return 'Email'
  }

  toString () {
    return `${this.EmailAddress}`
  }

  static endpoint () {
    return 'Emails'
  }

  static getValidationSchema (isEmailRequired) {
    const {
      EmailAddress,
    } = this.fields

    let emailAddr = Yup.string()
      .ensure()
      .email('Please enter a valid email address')
      .max(EmailAddress.opts.max, `Email address must be ${EmailAddress.opts.max} characters or less`)

    if (isEmailRequired) {
      emailAddr = emailAddr.required('Please enter an email address')
    } else {
      emailAddr = emailAddr.notRequired()
    }

    return Yup.object().shape({
      EmailAddress: emailAddr,
    })
  }

  static get options () {
    return {
      idAttribute: 'EmailId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      EmailId                : attr({ inputType: 'number', inputLabel: 'Email Id', }),
      CreateDate             : attr({ getDefault: () => new Date().toISOString(), }),
      CreateBy               : attr(),
      UpdateDate             : attr({ getDefault: () => new Date().toISOString(), }),
      UpdateBy               : attr(),
      // Model-Specific Properties
      EmailAddress           : attr({ getDefault: () => '',  max: 256, }),
      NormalizedEmailAddress : attr({ getDefault: () => '', }),
      EmailConfirmed         : attr(),

      // Reverse fields
      // Agencies
    }
  }

  static get propTypes () {
    return {
      EmailId                : number,
      CreateDate             : string,
      CreateBy               : string,
      UpdateDate             : string,
      UpdateBy               : string,
      EmailAddress           : string,
      NormalizedEmailAddress : string,
    }
  }
}