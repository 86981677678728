/**
 * This file setup was modified from the example found here:
 *  https://gist.github.com/jfbloom22/6ef92d9ff28007be8ce1154d28e7c66e
 */
import { createStore, applyMiddleware, compose, } from 'redux'
import { persistStore, persistReducer, createTransform, } from 'redux-persist'
//import storage from 'redux-persist/es/storage'
import * as localForage from 'localforage'
import { createMiddleware, } from 'redux-beacon'
import GoogleAnalytics, { trackPageView, } from '@redux-beacon/google-analytics'
import { createOffline, } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index'
import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue'
import { composeWithDevTools, } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'
import { logger, } from 'redux-logger'

import rootReducer, { EnhanceOffline, } from './'
import { objHasProp, } from '../utilities'
import { AppTypes, } from './AppRedux'

const {
  REACT_APP_ANALYTICS_ENABLED,
  REACT_APP_SHOW_LOGGING,
  REACT_APP_ENABLE_REDUX_DEV_TOOLS,
  NODE_ENV,
  // eslint-disable-next-line no-undef
} = process.env

/**
 * This array of actions is used by the custom queue config to ensure
 * only the latest call to this action is processed. This is helpful in
 * cases when users will tab through the Calendar or execute multiple 
 * searches on the Burn Permit or Request Searches.
 * 
 * see this for more info regarding manipulating the queue: https://github.com/redux-offline/redux-offline/blob/develop/docs/api/config.md#queue
 */
const takeLatestActions = [
  'SEARCH_BURN_REQUESTS',
  'GET_MY_BURN_REQUESTS',
  'GET_BURN_REQUEST_INFO',
  'SEARCH_BURN_PERMITS',
  'GET_MY_BURN_PERMITS',
  'SET_START_END',
]

let store
const getStoreInst = () => store

const queue = {
  ...defaultQueue,
  enqueue (arr, action) {
    const { type, } = action
    if (takeLatestActions.includes(type)) {
      const actionIdx = arr.map(a => a.type).indexOf(type)
      if (actionIdx > -1) {
        arr.splice(actionIdx, 1)
      }
    }
    arr.push(action)
    return arr
  },
}

// eslint-disable-next-line no-unused-vars
const effect = async (_effect, _action) => {
  const { token, } = (_effect.store.getState() || {}).User || {}
  const init = {
    headers: {
      'Content-Type'  : 'application/json',
      'Authorization' : `Bearer ${token}`,
    },
    ..._effect.init,
  }
  if (objHasProp(init, 'body') && typeof init.body !== 'string') {
    init.body = JSON.stringify(init.body)
  }
  const resp = await fetch(_effect.url, init)
  if (!resp.ok && resp.status !== 404) {
    throw resp
  }
  return resp
}

const customEffect = (getStore) => (effectPayload, action) => {
  effectPayload.store = getStore()
  return effect(effectPayload, action)
}

// eslint-disable-next-line no-unused-vars
const discard = async (resp, _action, _retries) => {
  if (!resp) {
    return false
  }
  const { status, } = resp
  if (status < 400 || status === 404) {
    return false
  }
  
  return true
}

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist : false,
  effect  : customEffect(getStoreInst),
  discard,
  queue,
})

const persistConfig = {
  key        : 'wa_dnr_burn_portal',
  transforms : [
    createTransform(
      (inboundState) => {
        return { ...inboundState, }
      },
      (outboundState) => {
        return { ...outboundState, }
      },
      { whitelist: [ 'orm', ], }
    ),
  ],
  storage: localForage,
}

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(rootReducer)
)

// If REACT_APP_ANALYTICS_ENABLED is not set to `true`
// this will never get used
const pageViewMiddleware = {
  [AppTypes.LOG_LAST_ROUTE]: trackPageView(action => ({
    page: action.lastRoute,
  })),
}

// creates the store
export default (rootReducer, rootSaga) => {

  const sagaMiddleware = createSagaMiddleware()

  const middleware = [
    offlineMiddleware,
    sagaMiddleware,
  ]
  if (REACT_APP_ANALYTICS_ENABLED === true) {
    const gaMiddleware = createMiddleware(pageViewMiddleware, GoogleAnalytics())
    middleware.push(gaMiddleware)
  }

  if (REACT_APP_SHOW_LOGGING === 'true') {
    middleware.push(logger)
  }

  const enhancers = [ offlineEnhanceStore, applyMiddleware(...middleware), ]

  // When downloading Permits, Requests, etc. for offline while developing, or while in prod, you may
  // want to disable Redux Dev Tools before you do the download otherwise the app will crash
  let composer = compose
  if (NODE_ENV !== 'production' || REACT_APP_ENABLE_REDUX_DEV_TOOLS) {
    composer = composeWithDevTools({ trace: true, traceLimit: 25, })
  }
  
  const reducer = EnhanceOffline(persistedReducer)
  store = createStore(reducer, composer(...enhancers))

  // kick off root saga
  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return { persistor, store, }
}