import { createSelector, } from 'reselect'
import { REACT_APP_VERSION, } from './env'

/*** State Selectors */

const appState = state => state.app

export const appStateSelector = createSelector(
  appState,
  state => {
    return {
      ...state,
      HistoryLength: state.historyLength || 5,
    }
  }
)

export const lastRouteSelector = createSelector(
  appState,
  state => {
    const routeEntry = state.routeHistory[1]
    if (routeEntry && routeEntry.route) {
      return routeEntry.route
    }
    return '/' // Default to the home page
  }
)

export const appVersionSelector = createSelector(
  appState,
  (state) => {
    const { version, } = state
    if (version !== REACT_APP_VERSION) {
      return ''
    }
    return version
  }
)

export const appHistorySelector = createSelector(
  appState,
  state => {
    return state.routeHistory
  }
)

export const returnUrlSelector = createSelector(
  appState,
  state => {
    return state.returnUrl
  }
)
