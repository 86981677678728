import { put, all, call, fork, select, takeLatest, takeEvery, } from 'redux-saga/effects'
import { difference, } from 'lodash'

// Reducers
import { OrmTypes, } from '../redux/OrmRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import { AgencyTypes, } from '../redux/AgencyRedux'
import { AppTypes, } from '../redux/AppRedux'
import { UiTypes, } from '../redux/UiRedux'
import { MergeTypes, } from '../redux/MergeRedux'

// Sagas
import { fetchAndCheck, getLookupData, doFetch, checkModelConcurrency, showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { createLocalModel, upsertLocalModels, upsertLocalModel, serverHasNewerData, } from './OrmSagas'

// Selectors
import { agencySimpleSelector, agencyIds, } from '../selectors/agencySelectors'
import { agentXrefsSelector, } from '../selectors/agentSelectors'
import { userIsDNR, } from '../selectors/userSelectors'
import { getModelEndpoint, getModelIdAttr, } from '../selectors/modelSelectors'


// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const AGENCY_ENDPOINT = REACT_APP_SERVER_URL + 'Agencies'
const AGENCY_MODEL_NAME = 'Agency'


function* fail (message, saveType) {
  if (saveType) {
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType, })
  }
  if (message) {
    yield put({ type: ApiTypes.FAILURE, error: message, })
  }
  yield call(hideLoading)
}

/**
 * Retrieve Agency information by Agency ID, include Address, Email, and Phone Number
 * @param {Object} action - The redux action payload
 * @param {String} action.agencyId - The agency ID to get data for
 */
export function* getAllAgencies (forceRefreshFromServer = false, basicInfo = false) {
  try {
    yield call(showLoading)
    if (forceRefreshFromServer === false) {
      const modelIsConcurrent = yield call(checkModelConcurrency, AGENCY_ENDPOINT, AGENCY_MODEL_NAME)
      if (modelIsConcurrent) {
        return true
      }
    }
    let agencyEndpoint = AGENCY_ENDPOINT
    if (basicInfo !== null) {
      agencyEndpoint += `?basicInfo=${basicInfo}`
    }
    let res = yield call(fetchAndCheck, agencyEndpoint)
    const { response, statuscode, } = res

    if (statuscode !== 200) {
      throw new Error(response && response.error ? response.error : 'Failed to refresh Agencies.')
    }
    
    if (!Array.isArray(response)) {
      yield call(fail, 'Agencies failed to load.')
      return
    }

    // preserve the old xrefs
    const oldXrefs = yield select(agentXrefsSelector)

    const storedAgencyIds = yield select(agencyIds)
    const incomingIds = response.map(a => a.AgencyId)
    const agenciesToDelete = difference(storedAgencyIds, incomingIds)
    if (agenciesToDelete.length) {
      yield put({
        type         : OrmTypes.DESTROY_RANGE,
        modelName    : AGENCY_MODEL_NAME,
        modelIdArray : agenciesToDelete,
      })
    }

    // Once the agency object is enriched for redux-orm, upsert it
    const hasNewData = yield call(serverHasNewerData, AGENCY_MODEL_NAME, null, response)
    if (hasNewData) {
      yield call(upsertLocalModels, AGENCY_MODEL_NAME, response)
    }

    if (oldXrefs.length) {
      yield put({
        type      : OrmTypes.REPLACE_ALL, 
        modelName : 'PersonAgencyXref',
        objects   : oldXrefs,
      })
    }
  }
  catch (error) {
    yield call(fail, error.message || 'Agencies failed to load.')
  }
  finally {
    yield call(hideLoading)
  }
}


export function* createNewAgency () {
  const newAgency = yield call(createLocalModel, AGENCY_MODEL_NAME)
  yield put({
    type     : AgencyTypes.SET_AGENCY_ID,
    agencyId : newAgency.AgencyId,
    isNew    : true,
  })
}

/**
 * Delete an Agency
 */
export function* deleteAgency ({ agencyId, }) {
  yield call(showLoading)
  yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
  yield put({ type: AgencyTypes.SAVING, saveType: AGENCY_MODEL_NAME, })
  agencyId = parseInt(agencyId)
  if (!Number.isInteger(agencyId)) {
    yield call(fail, 'Invalid AgencyId provided.', AGENCY_MODEL_NAME )
    return
  }
  // set up the request payload and url
  const request = { method: 'DELETE', }

  // POST the object
  let response, statuscode
  try {
    let res = yield call(fetchAndCheck, `${AGENCY_ENDPOINT}/${agencyId}`, request)
    response = res.response
    statuscode = res.statuscode
  }
  catch (error) {
    yield call(fail, 'Failed to delete Agency', AGENCY_MODEL_NAME)
    return
  }

  if (statuscode !== 200) {
    yield call(fail, response && response.error ? response.error : 'Failed to delete Agency.', AGENCY_MODEL_NAME)
    return
  }

  // Update the agency - server db is already updated
  yield put({
    type      : OrmTypes.DESTROY,
    modelName : AGENCY_MODEL_NAME,
    modelId   : agencyId,
  })
  
  yield all([
    put({ type: AgencyTypes.SET_AGENCY_ID, agencyId: '', }),
    put({ type: AgencyTypes.SAVED, saveType: AGENCY_MODEL_NAME, }),
    put({ type: AppTypes.HIDE_LOADING, }),
    put({ type: AppTypes.SHOW_SUCCESS, }),
  ])
  
  yield put({ type: AppTypes.REDIRECT_TO, route: '/admin/agencies', })
}

/**
 * POST a new Agency object, GET the created Agency and set the active Agency
 * @param {Object} action - The redux action
 * @param {Object} action.model - The Agency model to POST
 */
export function* createAgency ({ model, }) {
  yield call(showLoading)
  yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
  yield put({ type: AgencyTypes.SAVING, saveType: AGENCY_MODEL_NAME, })
  if (!model) {
    yield call(fail, 'No model object provided.', AGENCY_MODEL_NAME )
    return
  }

  // POST the object
  let response, statuscode
  try {
    let res = yield call(doFetch, AGENCY_ENDPOINT, { method: 'POST', body: model, })
    response = res.responseBody
    statuscode = res.statusCode
  }
  catch (error) {
    console.error(error)
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
    yield call(fail, 'Failed to create Agency', AGENCY_MODEL_NAME)
    return
  }

  if (statuscode !== 201) {
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
    yield call(fail, response && response.error ? response.error : 'Failed to refresh Agencies.')
    return
  }

  // If we didn't get a response with an Id, stop here
  if (!response || !response.AgencyId) {
    let emsg = 'Did not receive a response with an ID.'
    if (typeof response === 'string') {
      emsg = response
    }
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
    yield call(fail, emsg, AGENCY_MODEL_NAME)
    return
  }

  // Update the agency - server db is already updated
  yield put({
    type       : OrmTypes.UPSERT,
    modelName  : AGENCY_MODEL_NAME,
    properties : response,
  })
  
  yield all([
    // Set the new agency ID as active on the agency form
    put({ type: AgencyTypes.SET_AGENCY_ID, agencyId: response.AgencyId, }),
    put({ type: AgencyTypes.SAVED, saveType: 'Agency', }),
    call(hideLoading),
    put({ type: AppTypes.SHOW_SUCCESS, }),
  ])
  yield put({ type: AppTypes.REDIRECT_TO, route: `/admin/agencies/${response.AgencyId}`, })
}


/**
 * Crate an agency-related Address, Email, or Phone Number
 * @param {Object} action - The redux action
 * @param {Object} action.model - The model to create
 * @param {String} action.modelType - Either Email, Address, or Phone  
 * @param {int} action.agencyId - The agency ID to assoicate with the object
 */
export function* createAgencyObject ({ model, modelType, agencyId, }){
  if (!modelType) {
    yield call(fail, 'Unable to create object without a type specified.')
    return
  }
  if (!agencyId) {
    yield call(fail, 'Unable to create object without an agency ID')
    return
  }
  if (!model) {
    yield call(fail, 'No model object provided.')
    return
  }
  if ((modelType !== 'Email') && (modelType !== 'Address') && (modelType !== 'Phone')) {
    yield call(fail, 'Unable to create ' + modelType, modelType)
    return
  }
  yield call(showLoading)
  yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: modelType, })
  yield put({ type: AgencyTypes.SAVING, saveType: modelType, })

  // POST the object - this sets the agency fk in the database but we 
  // still need to update it here 
  // These Agency endpoints return the created object
  let response, statuscode, keyProp = yield select(getModelIdAttr, modelType)
  try {
    delete model[keyProp]
    let res = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}Agencies/${agencyId}/${modelType}`, { method: 'POST', body: model, })
    response = res.response
    statuscode = res.statuscode
  }
  catch (error) {
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: modelType, })
    yield call(fail, 'Failed creating ' + modelType, modelType)
    return
  }

  if (statuscode !== 201) {
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: modelType, })
    yield call(fail, response && response.error ? response.error : 'Failed to refresh Agencies.')
    return
  }
  
  // If we didn't get an Id in response something went wrong
  if (!response[keyProp]) {
    yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: modelType, })
    yield call(fail,'Did not receive a valid response when creating ' + modelType, modelType)
    return
  }

  // Upsert the modelType
  yield put({
    type       : OrmTypes.UPSERT,
    modelName  : modelType,
    properties : response,
  })
  
  // Get the current agency information from ORM
  const agency = yield select(agencySimpleSelector, agencyId)
  if (!agency) {
    yield call(fail,'Unable to retrieve existing agency data.', modelType)
    return
  }

  // add the new id
  agency[keyProp] = response[keyProp]

  // Update the agency
  yield put({
    type       : OrmTypes.UPSERT,
    modelName  : AGENCY_MODEL_NAME,
    properties : agency,
  })
  
  yield all([
    put({ type: AgencyTypes.SAVED, saveType: modelType, }),
    call(hideLoading),
    put({ type: AppTypes.SHOW_SUCCESS, }),
  ])
}

function* fetchAgencyDataForMerge ({ agencyId, }) {
  yield call(showLoading)
  yield put({ type: MergeTypes.FETCHING_DATA_FOR_MERGE, isFetchingData: true, })
  yield call(fetchAgencyData, { agencyId, basicInfo: false, })
  yield put({ type: MergeTypes.FETCHING_DATA_FOR_MERGE, isFetchingData: false, })
  yield call(hideLoading)
}


/**
 * Retrieve Agency information by Agency ID, include Address, Email, and Phone Number
 * @param {Object} action - The redux action payload
 * @param {String} action.agencyId - The agency ID to get data for
 * @param {String} action.basicInfo - The agency ID to get data for
 */
export function* fetchAgencyData ({ agencyId, basicInfo = true, }) {
  if (!agencyId || agencyId < 1) {
    return
  }
  yield call(showLoading)
  // get response body
  let response, statuscode
  try {
    let res = yield call(fetchAgency, agencyId, basicInfo)
    response = res.response
    statuscode = res.statuscode
  }
  catch (error) {
    yield call(fail, 'Failed to retreive agency data.')
    return
  }

  if (statuscode !== 200) {
    yield call(fail, response && response.error ? response.error : 'Failed to retreive agency data.')
    return
  }

  const { AddressId, EmailId, PhoneId, } = response
  const contactReqs = []
  // if we have an ID it should be good, so get it
  if (EmailId) {
    contactReqs.push(call(getAgencyObject, EmailId, 'Email'))
  }

  if (AddressId) {
    contactReqs.push(call(getAgencyObject, AddressId, 'Address', 'AddressType'))
  }

  if (PhoneId) {
    contactReqs.push(call(getAgencyObject, PhoneId, 'Phone', 'PhoneType'))
  }

  if (contactReqs.length) {
    yield all(contactReqs)
  }

  const userIsDnr = yield select(userIsDNR)
  if (userIsDnr) {
    const resp = yield call(doFetch, `${REACT_APP_SERVER_URL}Agencies/${agencyId}/People`)
    if (!resp.ok) {
      yield call(showError, 'Could not retrieve Agents for the selected Agency')
    }
    else {
      const xrefs = resp.responseBody.map(p => {
        return {
          PersonAgencyXrefId       : p.PersonAgencyXrefId,
          PersonAgencyXrefAgencyId : agencyId,
          PersonAgencyXrefPersonId : p.PersonId,
        }
      })
      const xrefIds = xrefs.map(x => x.PersonAgencyXrefId)
      const filter = x => xrefIds.includes(x.PersonAgencyXrefId)
      const hasNewData = yield call(serverHasNewerData, 'PersonAgencyXref', filter, resp.responseBody)
      if (hasNewData) {
        yield call(upsertLocalModels, 'PersonAgencyXref', xrefs)
      }
    }
  }
  
  const hasNewData = yield call(serverHasNewerData, AGENCY_MODEL_NAME, { AgencyId: agencyId, EmailId, PhoneId, AddressId, }, response)
  if (hasNewData) {
    // Once the agency object is enriched for redux-orm, upsert it
    yield call(upsertLocalModel, AGENCY_MODEL_NAME, response)
  }

  yield call(hideLoading)
}


export function* getAgencyObject (id, model, lookupDataModel) {
  try {
    if (lookupDataModel) {
      // Ensure we have the model types loaded
      yield call(getLookupData, { modelName: lookupDataModel, })
    }

    const endpoint = yield select(getModelEndpoint, model)

    const { response, statuscode, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}${endpoint}/${id}`)
    if (statuscode !== 200) {
      yield call(fail, response && response.error ? response.error : `Failed to retreive Agency ${model} data.`)
      return
    }
    const modelIdAttr = yield select(getModelIdAttr, model)
    const hasNewData = yield call(serverHasNewerData, model, { [modelIdAttr]: id, }, response)
    if (hasNewData) {
      yield fork(upsertLocalModel, model, response)
    }
  }
  catch (error) {
    yield call(fail, `Failed to retreive Agency ${model} data.`)
    return
  }
}


/**
 * Helper to fetch an agency by id
 * @param {int} agencyId 
 * @returns {Object} The fetch response
 */
export function* fetchAgency (agencyId, basicInfo = true) {
  // set up the initial agency request
  const endpoint = `${REACT_APP_SERVER_URL}Agencies/${agencyId}?basicInfo=${basicInfo ? 1 : 0}`
  const body = { method: 'GET', }

  // get response body or throw
  return yield call(fetchAndCheck, endpoint, body)
}

export function* createAgencyObjects ({ agencyId, address, email, phone, }) {
  yield all([
    call(createAgencyObject, {
      model     : address, 
      modelType : 'Address', 
      agencyId,
    }),
    call(createAgencyObject, {
      model     : email,
      modelType : 'Email',
      agencyId,
    }),
    call(createAgencyObject, {
      model     : phone,
      modelType : 'Phone',
      agencyId,
    }),
  ])
}


/**
 * Update an agency-related Address, Email, or Phone Number
 * @param {Object} action - The redux action
 * @param {Object} action.model - The model to create
 * @param {String} action.modelType - Either Email, Address, or Phone  
 * @param {int} action.agencyId - The agency ID to assoicate with the object
 */
export function* updateAgencyObject ({ model, modelType, modelId, agencyId, }) {

  if (!modelType) {
    yield call(fail, 'Unable to update object without a type specified.')
    return
  }
  if ((modelType !== 'Email') && (modelType !== 'Address') && (modelType !== 'Phone')) {
    yield call(fail, `${modelType} is not a valid Agency Object`)
    return
  }

  if (!agencyId) {
    yield call(fail, `Unable to update ${modelType} without an agency ID`)
    return
  }
  if (!model) {
    yield call(fail, `Unable to update ${modelType} data`)
    return
  }

  yield all([
    put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: modelType, }),
    put({ type: AgencyTypes.SAVING, saveType: modelType, }),
    call(showLoading),
  ])

  try {
    const modelEndpoint = yield select(getModelEndpoint, modelType)
    const { response, statuscode, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}${modelEndpoint}/${modelId}`, { method: 'PUT', body: model, })

    if (statuscode !== 204) {
      yield call(fail, response && response.error ? response.error : 'Failed updating ' + modelType, modelType)
      return
    }
  }
  catch (error) {
    yield call(fail, 'Failed updating ' + modelType, modelType)
    return
  }

  yield all([
    call(getAgencyObject, modelId, modelType),
    put({ type: AgencyTypes.SAVED, saveType: modelType, }),
    call(hideLoading),
    put({ type: AppTypes.SHOW_SUCCESS, }),
  ])
}


/**
 * PUT a an Agency object, GET the updated Agency
 * @param {Object} action - The redux action
 * @param {Object} action.model - The Agency model to POST
 */
export function* updateAgency ({ model, agencyId, }) {
  yield call(showLoading)
  yield put({ type: AgencyTypes.CLEAR_SAVE_STATE, saveType: AGENCY_MODEL_NAME, })
  yield put({ type: AgencyTypes.SAVING, saveType: AGENCY_MODEL_NAME, })
  if (!model) {
    yield call(fail, 'No model object provided to agency update method.', AGENCY_MODEL_NAME)
    return
  }
  if (!agencyId) {
    yield call(fail, 'No agency id provided to agency update method.', AGENCY_MODEL_NAME)
    return
  }
  // set up the request payload and url
  const updateEndpoint = REACT_APP_SERVER_URL + 'Agencies/' + agencyId

  try {
    const request = { method: 'PUT', body: model, }

    // response should be a NOCONTENT
    const { response, statuscode, } = yield call(fetchAndCheck, updateEndpoint, request)

    if (statuscode !== 204) {
      yield call(fail, response && response.error ? response.error : 'Failed to update Agency', AGENCY_MODEL_NAME)
      return
    }
  
    yield all([
      call(fetchAgencyData, { agencyId, basicInfo: false, }),
      put({ type: AgencyTypes.SAVED, saveType: AGENCY_MODEL_NAME, }),
      call(hideLoading),
      put({ type: AppTypes.SHOW_SUCCESS, }),
    ])
  }
  catch (error) {
    yield call(fail, 'Failed to update Agency', AGENCY_MODEL_NAME)
    return
  }
}


export function* mergeAgencies ({ agency, mergedAgencyId, }) {

  yield call(showLoading)

  const agencyEndpoint = `${REACT_APP_SERVER_URL}Agencies/${agency.AgencyId}/Merge`
  
  const request = { method: 'PUT', body: { Agency: agency, MergedAgencyId: mergedAgencyId, }, }
  
  try {
    const { statuscode, } = yield call(fetchAndCheck, agencyEndpoint, request)

    if (statuscode !== 204) {
      // we do not need to show a failure toast here as the `fetchAndCheck` saga does that for us
      return
    }

    yield all([
      put({ type: AppTypes.SHOW_SUCCESS, }),
      // Destroy the local copy of the Agency that was merged
      put({ type: OrmTypes.DESTROY, modelName: 'Agency', modelId: mergedAgencyId, }),
    ])
    yield put({ type: AppTypes.REDIRECT_TO, route: `/admin/agencies/${agency.AgencyId}`, })
  }
  catch (error) {
    yield call(showError, 'Failed merging Agencies.')
  }
  finally {
    yield call(hideLoading)
  }
}


export function* verifyAgencyUser ({ agencyId, personAgencyXrefId, }) {
  yield call(showLoading)

  if (!Number.isInteger(personAgencyXrefId)) {
    yield call(fail, 'A valid PersonAgencyXrefId must be provided')
    return
  }
  if (!Number.isInteger(agencyId)) {
    yield call(fail, 'A valid AgencyId must be provided')
    return
  }

  // set up the request payload and url
  const updateEndpoint = `${REACT_APP_SERVER_URL}Agencies/${agencyId}/User`

  try {
    const request = { method: 'PUT', body: { PersonAgencyXrefId: personAgencyXrefId, }, }

    const { response, statuscode, } = yield call(fetchAndCheck, updateEndpoint, request)

    if (statuscode !== 200) {
      // we do not need to show a failure toast here as the `fetchAndCheck` saga does that for us
      return
    }
    // Toggle the Active Person to trigger a state update that will allow the orm update to set
    // the relationship so the Verify By/On values show up in the UI after we reactive the person
    yield put({ type: 'ACTIVE_PERSON_ID', personId: -1, })

    yield all([
      put({ type: OrmTypes.UPSERT, modelName: 'PersonAgencyXref', properties: response, }),
      put({ type: AppTypes.SHOW_SUCCESS, }),
    ])
    
    const personId = response.PersonAgencyXrefPersonId
    yield put({ type: 'ACTIVE_PERSON_ID', personId, })
  }
  catch (error) {
    yield call(fail, 'Failed to verify Agency User')
  }
  finally {
    yield all([
      call(hideLoading),
      put({ type: UiTypes.CLOSE_MODAL, }),
    ])
  }
}

/**
 * Get an Agency and related information
 * @param {number} agencyId - The ID of the Agency to request
 * @param {number} basicInfo - The ID of the Agency to request
 */
export function* getAgency (agencyId, basicInfo = true) {
  // can't do anything without a person id
  if (!agencyId || agencyId < 1) {
    return
  }

  try {
    const { res, response, statuscode, } = yield call(fetchAgency, agencyId, basicInfo)
    if (statuscode !== 200) {
      return
    }

    // if response is null, stop here
    if (!response) {
      yield call(showError, 'Did not receive a response for Agency ' + agencyId)
      return
    }

    const hasNewData = yield call(serverHasNewerData, AGENCY_MODEL_NAME, { AgencyId: agencyId, }, response)
    if (hasNewData) {
      yield call(upsertLocalModel, AGENCY_MODEL_NAME, response)
    }
    return res
  }
  catch (e) {
    yield call(showError, 'An error occurred requesting Agency ' + agencyId)
    return
  }
}


export const AgencySagas = [
  takeLatest(AgencyTypes.FETCH_AGENCY_DATA, fetchAgencyData),
  takeLatest(AgencyTypes.FETCH_AGENCY_DATA_FOR_MERGE, fetchAgencyDataForMerge),
  takeLatest(AgencyTypes.GET_ALL_AGENCIES, getAllAgencies),
  takeLatest(AgencyTypes.CREATE_NEW_AGENCY, createNewAgency),
  takeLatest(AgencyTypes.CREATE_AGENCY, createAgency),
  takeLatest(AgencyTypes.DELETE_AGENCY, deleteAgency),
  takeLatest(AgencyTypes.UPDATE_AGENCY, updateAgency),
  takeEvery(AgencyTypes.MERGE_AGENCIES, mergeAgencies),
  takeEvery(AgencyTypes.CREATE_AGENCY_OBJECT, createAgencyObject),
  takeEvery(AgencyTypes.CREATE_AGENCY_OBJECTS, createAgencyObjects),
  takeEvery(AgencyTypes.UPDATE_AGENCY_OBJECT, updateAgencyObject),
  takeLatest(AgencyTypes.VERIFY_AGENCY_USER, verifyAgencyUser),
]