// Libraries
import React, { useState, } from 'react'
import { Field, } from 'formik'
import { Col, FormGroup, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, } from 'reactstrap'
import { string, number, PropTypes, func, bool, } from 'prop-types'
// Components
import { ValidatingField, } from '../FormControls'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, } = process.env
const LINK_PROP_SHAPE = {
  href : string,
  text : string,
}
const LINK_PROP_DEFAULT = {
  href : '',
  test : '',
}

export const LinkPreview = ({ href = '', text = '' , form = false, }) => {
  let hrefToRender = href || ''
  let textToRender = text || ''

  if (hrefToRender.endsWith('.pdf')) {
    hrefToRender = hrefToRender.concat('?' + (new Date()).getTime())
  }
  if (hrefToRender.includes('$SERVER')) {
    hrefToRender = hrefToRender.replace('$SERVER', REACT_APP_SERVER)
  }
  if (form) {
    return <FormGroup>
      <Label>Preview (Opens in new window)</Label>
      <p><a href={hrefToRender} target={'_blank'} rel={'noreferrer noopener'}>{textToRender}</a></p>
    </FormGroup>
  }
  return <a href={hrefToRender} target={'_blank'} rel={'noreferrer noopener'}>{textToRender}</a>
}

LinkPreview.propTypes = {
  ...LINK_PROP_SHAPE,
  form: bool,
}
LinkPreview.defaultProps = {
  ...LINK_PROP_DEFAULT,
  form: false,
}

export const LinkEditablePreview = ({ val, idx, arrayHelpers, listLen, isValid, dirty, setFieldValue, } ) => {
  const { href = '', text = '', isNew = false, } = val
  let hrefToRender = href || ''
  let textToRender = text || ''
  const [ modalOpen, setModalOpen, ] = useState(isNew)
  if (isNew) {
    setFieldValue('RuleValue[' + idx + ']', { href, text, }, false)
  }
  const toggleModal = () => setModalOpen(!modalOpen)
  const remove = () => {
    setModalOpen(false)
    arrayHelpers.remove(idx)
  }
  return <>
    <ButtonGroup className={'mr-1'}>
      <Button 
        onClick={toggleModal} 
        size={'sm'} 
        title={'Edit Link'} 
        alt={'Edit Link'}
        disabled={modalOpen}
      >
        <span className={'far fa-edit'}/>
      </Button>
      <Button
        color={'danger'}
        onClick={remove}
        size={'sm'}
        title={'Remove Link'}
        alt={'Remove Link'}
        disabled={modalOpen}
      >
        <span className={'far fa-minus-square'}/>
      </Button>
      <Button 
        color={'primary'}
        onClick={() => arrayHelpers.swap(idx, idx - 1)} 
        size={'sm'} 
        title={'Move Up'} 
        alt={'Move Up'}
        disabled={modalOpen || (idx < 1)}
      >
        <span className={'fas fa-arrow-up'} />
      </Button>
      <Button
        color={'primary'}
        onClick={() => arrayHelpers.swap(idx, idx+1)}
        size={'sm'}
        title={'Move Down'}
        alt={'Move Down'}
        disabled={modalOpen || (idx === listLen - 1)}
      >
        <span className={'fas fa-arrow-down'} />
      </Button>
    </ButtonGroup>
    <p>
      { modalOpen 
        ? 'Editing...' 
        : <LinkPreview href={hrefToRender} text={textToRender} />
      }
    </p>
    {modalOpen &&
      <Modal isOpen={modalOpen} toggle={toggleModal} size={'lg'} backdrop={'static'}>
        <ModalHeader tag={'h2'}>
          Editing Link
        </ModalHeader>
        <ModalBody>
          <LinkEditCard
            val={val} 
            idx={idx} 
            onDone={toggleModal}
            onDelete={remove}
            isValid={isValid}
            dirty={dirty}
          />
        </ModalBody>
      </Modal>
    }
  </>
}
LinkEditablePreview.propTypes = {
  val: PropTypes.shape({
    ...LINK_PROP_SHAPE,
    isNew: bool,
  }),
  idx          : number,
  isValid      : bool,
  arrayHelpers : PropTypes.shape({
    remove: func,
  }),
}
LinkEditablePreview.defaultProps = {
  val: {
    ...LINK_PROP_DEFAULT,
    isNew: false,
  },
  idx          : null,
  isValid      : false,
  arrayHelpers : {
    remove: () => {},
  },
}


export const LinkEditCard = ({ val, idx, onDone, onDelete, isValid, dirty, }) => (
  <Col xs={12} key={'rule-val-pair-' + idx} className={'my-2'}>
    <FormGroup>
      <Label for={'RuleValue[' + idx + '].href'} className={'mt-2'}>Link Target</Label>
      <Field name={'RuleValue[' + idx + '].href'} component={ValidatingField} type={'text'} />
    </FormGroup>
    <FormGroup>
      <Label for={'RuleValue[' + idx + '].text'} className={'mt-2'}>Link Text</Label>
      <Field name={'RuleValue[' + idx + '].text'} component={ValidatingField} type={'text'} />
    </FormGroup>
    <LinkPreview href={val.href} text={val.text} form={true}/>
    <ModalFooter className={'d-flex justify-content-between'}>
      <Button color={'danger'} style={{ marginRight: 'auto', }} onClick={onDelete} className={'mt-2'}>Delete</Button><br />   
      <Button color={'secondary'} onClick={onDone} disabled={dirty && !isValid}>Done Editing</Button>
    </ModalFooter>
  </Col>
)

LinkEditCard.propTypes = {
  ...LinkEditablePreview.propTypes,
  onDone: func,
}

LinkEditCard.defaultProps = {
  ...LinkEditablePreview.defaultProps,
  onDone: () => {},
}