import { attr, fk, Model, many, } from 'redux-orm'

export default class Region extends Model {
  static get modelName () {
    return 'Region'
  }

  toString () {
    return `${this.RegionId}`
  }

  static endpoint () {
    return 'Regions'
  }

  static get options () {
    return {
      idAttribute: 'RegionId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      RegionId           : attr(),
      CreateDate         : attr(),
      CreateBy           : attr(),
      UpdateDate         : attr(),
      UpdateBy           : attr(),
      // Model-Specific Properties
      RegionCode         : attr(),
      RegionName         : attr(),
      RegionAbbreviation : attr(),
      RegionDescription  : attr(),
      // Relationships
      RegionPhoneId      : fk({ to: 'Phone', relatedName: 'Regions', as: 'RegionPhone', }),
      RegionAddressId    : fk({ to: 'Address', relatedName: 'Regions', as: 'RegionAddress', }),
      RegionEmailId      : fk({ to: 'Email', relatedName: 'Regions', as: 'RegionEmail', }),
      RegionCounties     : many({ to: 'County', relatedName: 'Regions', }),
    }
  }
}