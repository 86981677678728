// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'

// Components
import { PopoverButton, } from '../../FormControls'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'


const OnlinePopover = () => {
  const online = useSelector(appIsOnlineSelector)
  if (!online) {
    return <PopoverButton
      buttonClassName={'py-0'}
      popoverHeader={'Online only'}
      popoverBody={'Downloading a Burn Permit Application document can only be performed while connected to the internet.'}
    />
  }
  return null
}

export default React.memo(OnlinePopover)