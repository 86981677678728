import {
  SIMPLE_ORANGE_POLYGON,
  SIMPLE_BLUE_POLYGON,
  SIMPLE_GREEN_POLYGON,
  SIMPLE_DARK_BLUE_POLYGON,
  SIMPLE_DARK_PURPLE_POLYGON,
  FIRE_DANGER_RENDERER,
} from './renderers'

const {
  REACT_APP_WAMAS_GIS_SERVICES,
  REACT_APP_DNR_GIS_SERVICES,
  // eslint-disable-next-line no-undef
} = process.env

export const COUNTIES_SERVICE_URL = `${REACT_APP_WAMAS_GIS_SERVICES}MapServices/LocationFinder_GPservice/MapServer/3`
export const COUNTIES = {
  url          : COUNTIES_SERVICE_URL,
  id           : 'Counties',
  title        : 'Counties',
  outFields    : [ '*', ],
  visible      : false,
  renderer     : SIMPLE_ORANGE_POLYGON,
  labelingInfo : {
    labelExpressionInfo : { expression: '$feature.COUNTY_NM', },
    symbol              : {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title   : '{COUNTY_NM} County',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'ECY_REGION', label: 'ECY Region', },
        { fieldName: 'COUNTY_FIP', label: 'County FIP', },
        { fieldName: 'AIR_REGION', label: 'Air Region', },
      ],
    }, ],
  },
}

export const TRS_SERVICE_URL = `${REACT_APP_WAMAS_GIS_SERVICES}MapServices/LocationFinder_GPservice/MapServer/7`
export const TRS = {
  url          : TRS_SERVICE_URL,
  id           : 'TRS',
  title        : 'Township Range Sections',
  outFields    : [ '*', ],
  visible      : false,
  renderer     : SIMPLE_ORANGE_POLYGON,
  labelingInfo : {
    labelExpressionInfo : { expression: '$feature.TRS_CD', },
    symbol              : {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title   : 'TRS {TRS_CD}',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'TOWNSHIP_NR', label: 'Township #', },
        { fieldName: 'TOWNSHIP_FRA_CD', label: 'Township Fraction', },
        { fieldName: 'TOWNSHIP_DIR_CD', label: 'Township Direction', },
        { fieldName: 'RANGE_NR', label: 'Range #', },
        { fieldName: 'RANGE_FRA_CD', label: 'Range Fraction', },
        { fieldName: 'RANGE_DIR_CD', label: 'Range Direction', },
        { fieldName: 'SECTION_NR', label: 'Section #', },
        { fieldName: 'SECTION_FRA_CD', label: 'Section Fraction', },
        { fieldName: 'SECTION_DIR_CD', label: 'Section Direction', },
      ],
    }, ],
  },
}

export const REGIONS_SERVICE_URL = `${REACT_APP_DNR_GIS_SERVICES}Public_Boundaries/WADNR_PUBLIC_Cadastre_Jurisdiction/MapServer/0`
export const REGIONS = {
  url          : REGIONS_SERVICE_URL,
  id           : 'Regions',
  title        : 'Regions',
  outFields    : [ '*', ],
  visible      : false,
  renderer     : SIMPLE_BLUE_POLYGON,
  labelingInfo : {
    labelExpressionInfo : { expression: '$feature.JURISDICT_LABEL_NM', },
    symbol              : {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title: '{JURISDICT_LABEL_NM} Region',
  },
}

export const FIRE_DISTRICTS_SERVICE_URL = `${REACT_APP_DNR_GIS_SERVICES}Public_Wildfire/WADNR_PUBLIC_WD_WildFire_Data/MapServer/6`
export const FIRE_DISTRICTS = {
  url          : FIRE_DISTRICTS_SERVICE_URL,
  id           : 'FireDistricts',
  title        : 'Fire Districts',
  outFields    : [ '*', ],
  visible      : false,
  renderer     : SIMPLE_GREEN_POLYGON,
  labelingInfo : {
    labelExpressionInfo : { expression: '$feature.FPD_DESC', },
    symbol              : {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title   : 'Fire District',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'FPD_DESC', label: 'Fire District/Dept.', },
        { fieldName: 'FPD_CODE', label: 'Fire District/Dept. Code', },
        { fieldName: 'TANKER', label: 'Has Tanker', },
        { fieldName: 'COUNTY', label: 'County', },
      ],
    }, ],
  },
}


export const UGA = {
  url                  : 'https://services.arcgis.com/jsIt88o09Q0r1j8h/ArcGIS/rest/services/CityUGA2019/FeatureServer/0',
  id                   : 'UGA',
  title                : 'Urban Growth Area',
  outFields            : [ '*', ],
  definitionExpression : 'UGA_NM <> \'\' and UGA_NM is not null',
  renderer             : SIMPLE_DARK_BLUE_POLYGON,
  visible              : false,
  labelingInfo         : {
    labelExpressionInfo: {
      expression: '$feature.UGA_NM',
    },
    symbol: {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title   : '{UGA_NM}',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'UGA_NM', label: 'UGA Name', },
        { fieldName: 'UGA_NM2', label: 'UGA Name Alt.', },
        { fieldName: 'GMA', label: 'Growth Mgmt. Act', },
        { fieldName: 'INCORP', label: 'Incorporated', },
        { fieldName: 'CITY_NM', label: 'City', },
        { fieldName: 'COUNTY_NM', label: 'County', },
        { fieldName: 'ORIGIN', label: 'Origin', },
      ],
    }, ],
  },
}


export const FIRE_DANGER = {
  url          : `${REACT_APP_DNR_GIS_SERVICES}Public_Wildfire/WADNR_PUBLIC_WD_WildfireDanger/MapServer/0`,
  id           : 'FIRE_DANGER',
  title        : 'Fire Danger',
  outFields    : [ '*', ],
  visible      : true,
  renderer     : FIRE_DANGER_RENDERER,
  labelingInfo : {
    labelExpressionInfo: {
      expression: '$feature.FIREDANGER_AREA_NM',
    },
    symbol: {
      type      : 'text',
      color     : 'black',
      haloSize  : 1,
      haloColor : 'white',
    },
  },
  popupTemplate: {
    title   : '{FIREDANGER_AREA_NM} - {FIRE_DANGER_LEVEL_NM}',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'FIREDANGER_AREA_NM', label: 'Area Name', },
        { fieldName: 'FIRE_DANGER_LEVEL_NM', label: 'Fire Danger Level', },
        { fieldName: 'FIRE_DANGER_LEVEL_CD', label: 'Fire Danger Code', },
        { fieldName: 'NOTES_TXT', label: 'Comments', },
        { fieldName: 'DNR_REGION_NAME', label: 'Region', },
        { fieldName: 'REGION_PHONE_NO', label: 'Phone #', },
        { fieldName: 'REGION_EMAIL_ADDR', label: 'Email', },
      ],
    }, ],
  },
}


export const BURN_BAN = {
  url           : `${REACT_APP_DNR_GIS_SERVICES}Public_Wildfire/WADNR_PUBLIC_WD_WildfireDanger/MapServer/1`,
  id            : 'BURN_BAN',
  title         : 'Burn Bans',
  outFields     : [ '*', ],
  visible       : true,
  popupTemplate : {
    title   : '{FIREDANGER_AREA_NM} - {BURN_BAN_LEVEL_NM}',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'FIREDANGER_AREA_NM', label: 'Area Name', },
        { fieldName: 'BURN_BAN_LEVEL_NM', label: 'Burn Ban Level', },
        { fieldName: 'BURN_BAN_LEVEL_CD', label: 'Burn Ban Code', },
        { fieldName: 'NOTES_TXT', label: 'Comments', },
        { fieldName: 'DNR_REGION_NAME', label: 'Region', },
        { fieldName: 'REGION_PHONE_NO', label: 'Phone #', },
        { fieldName: 'REGION_EMAIL_ADDR', label: 'Email', },
      ],
    }, ],
  },
}


export const SMOKE_PLANNING_AREAS = {
  url           : 'https://services.arcgis.com/6lCKYNJLvwTXqrmp/arcgis/rest/services/AQ/FeatureServer/1',
  id            : 'SMOKE_PLANNING_AREAS',
  title         : 'Smoke Planning Areas',
  outFields     : [ '*', ],
  visible       : false,
  renderer      : SIMPLE_DARK_PURPLE_POLYGON,
  popupTemplate : {
    title   : '{AreaID} - {AreaName}',
    content : [ {
      type       : 'fields',
      fieldInfos : [
        { fieldName: 'AreaID', label: 'Area ID', },
        { fieldName: 'AreaName', label: 'Area Name', },
        { fieldName: 'AQDataPeriod', label: 'AQ Data Period', },
      ],
    }, ],
  },
}

// This is a reverse order of the order the layers will draw
// Meaning BURN_BAN is the top most layer and will appear over all other layers
export const ALL_LAYERS = [
  TRS,
  COUNTIES,
  REGIONS,
  FIRE_DISTRICTS,
  UGA,
  SMOKE_PLANNING_AREAS,
  FIRE_DANGER,
  BURN_BAN,
]
export default ALL_LAYERS