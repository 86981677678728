import React from 'react'
import { Alert, } from 'reactstrap'


const PileLocationDocAlert = () => {
  return <Alert color={'warning'}>
    <p>
      You <b>must</b> upload a document that includes a map of the approximate location(s) of your pile(s)
      in the <b>Documents</b> section and set the Document Type to <b>Pile Location Map</b>.
    </p>
  </Alert>
}

export default PileLocationDocAlert