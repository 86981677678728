// Libraries
import React from 'react'
import { Formik, Field, ErrorMessage, Form, } from 'formik'
import { Row, Col, FormGroup, Label, FormFeedback, Button, } from 'reactstrap'
import { object, func, bool, string, PropTypes, } from 'prop-types'

// Utilities
import { dateFormatter, } from '../../utilities'

// Components
import AuditData from '../AuditData'
import ValidatingField from '../FormControls/ValidatingField'

// Models
import BurnPermitOrder from '../../models/BurnPermitOrder'


const CASH_METHOD = 'Third Party Payments (ACH Credit, POS, Cash)'
const PAYMENT_METHODS = [ { name: 'Cash', method: CASH_METHOD, }, { name: 'Check', method: 'Check', }, { name: 'Voucher', method: 'Voucher', }, ]


const PaymentMethod = props => {
  const { method, errors, onChange, } = props
  return <>
    <Field
      invalid={(!!errors.PaymentMethod).toString()}
      name={'PaymentMethod'}
      className={`form-control ${errors.PaymentMethod ? ' is-invalid' : ''}`}
    >
      {({ field, }) => (
        <input
          {...field}
          type={'radio'}
          checked={field.value === method.method}
          id={`${method.name}-radio`}
          data-method={method.method}
          onChange={onChange}
          aria-labelledby={`payment-method ${method.name}-radio-label`}
        />
      )}
    </Field>
    <Label
      className={'mx-2'}
      for={`${method.name}-radio`}
      id={`${method.name}-radio-label`}
    >{method.name}</Label>
  </>
}

PaymentMethod.propTypes = {
  method   : object,
  errors   : object,
  onChange : func,
}

PaymentMethod.defaultProps = {
  method   : {},
  errors   : {},
  onChange : () => {},
}

export default class PhysicalPaymentForm extends React.Component {

  static propTypes = {
    order                       : object,
    appDetail                   : object,
    onSubmit                    : func,
    eligibleForVoucherPayment   : PropTypes.oneOfType([ bool, string, ]),
    eligableForCashCheckPayment : bool,
  }

  static defaultProps = {
    order                       : null,
    appDetail                   : null,
    onSubmit                    : () => {},
    eligableForCashCheckPayment : false,
  }

  state = {
    isVoucher: false,
  }

  onKeyPress = (evt) => {
    if (evt.charCode === 13) {
      this.formik.submitForm()
    }
  }

  changePaymentMethod = evt => {
    const { method, }= evt.target.dataset
    const { order, } = this.props
    const isVoucher = method === 'Voucher'
    this.setState({ isVoucher, }, () => {
      let values = {
        FirstName          : '',
        LastName           : '',
        CheckDate          : '',
        CheckNumber        : '',
        TotalAmountPaid    : '',
        PaymentMethod      : method,
        BurnPermitOrderId  : '',
        PaymentCommandCode : 'Payment',
        TransactionDate    : new Date(),
        VoucherNumber      : '',
        VoucherDate        : '',
        Comment            : '',
        BurnPermitIds      : [],
      }
      if (order) {
        values.BurnPermitOrderId = order.BurnPermitOrderId
        values.BurnPermitIds = order.BurnPermitOrderXref.map(x => x.BurnPermitId)
        if (order.ApplicationDetail && method === order.ApplicationDetail.PaymentMethod) {
          values = this.mapOrderToFormikValues()
        }
      }
      this.formik.resetForm({ values, })
    })
  }

  getPrefix = (method) => {
    if (method === 'Check') {
      return method
    }
    else if (method === 'Voucher') {
      return method
    }
    return 'Payment'
  }

  getPaymentMethods = () => {
    const { eligibleForVoucherPayment, eligableForCashCheckPayment, } = this.props
    let paymentMethods = PAYMENT_METHODS
    if (!eligibleForVoucherPayment){
      paymentMethods = paymentMethods.filter(m => m.name !== 'Voucher')
    }
    else {
      paymentMethods = paymentMethods.filter(m => m.name === 'Voucher')
    }
    if (!eligableForCashCheckPayment){
      paymentMethods = paymentMethods.filter(m => 
        m.name !== 'Cash' && m.name !== 'Check'
      )
    }
    return paymentMethods
  }

  getPaymentMethodName = (paymentMethod) => {
    const paymentMethods = this.getPaymentMethods()
    const method = paymentMethods.filter(m => m.method === paymentMethod)[0]
    if (method) {
      return method.name
    }
    return ''
  }

  setDateToToday = evt => {
    const { target, } = evt.target.dataset
    this.formik.setFieldValue(target, dateFormatter(new Date(), 'YYYY-MM-DD'))
  }

  setFullAmount = evt => {
    const { target, } = evt.target.dataset
    this.formik.setFieldValue(target, this.props.order.BurnPermitOrderAmount)
  }

  setFormikNode = node => this.formik = node

  commandCode = 'Payment'

  mapOrderToFormikValues = () => {
    const { order, appDetail, eligibleForVoucherPayment, } = this.props
    return {
      BurnPermitOrderId  : order.BurnPermitOrderId,
      BurnPermitIds      : order.BurnPermitOrderXref.map(x => x.BurnPermitId),
      FirstName          : appDetail ? appDetail.FirstName || '' : '',
      LastName           : appDetail ? appDetail.LastName || '' : '',
      CheckDate          : order ? dateFormatter(order.SettlementSubmissionDate || '', 'YYYY-MM-DD') : '',
      CheckNumber        : appDetail ? appDetail.CheckNumber || '' : '',
      VoucherNumber      : appDetail ? appDetail.VoucherNumber || '' : '',
      VoucherDate        : order ? dateFormatter(order.SettlementSubmissionDate || '', 'YYYY-MM-DD') : '',
      Comment            : order.BurnPermitOrderVoucher ? order.BurnPermitOrderVoucher.Comment || '' : '',
      TotalAmountPaid    : appDetail ? order.TotalAmountPaid || '' : '',
      PaymentCommandCode : this.commandCode,
      PaymentMethod      : eligibleForVoucherPayment ? 'Voucher' : (appDetail ? appDetail.PaymentMethod || CASH_METHOD : CASH_METHOD),
      TransactionDate    : appDetail ? dateFormatter(appDetail.TransactionDate || '') : new Date(),
    }
  }

  render () {
    const { order, appDetail, onSubmit, eligibleForVoucherPayment, } = this.props
    const paymentMethods = this.getPaymentMethods()
    return <Formik
      initialValues={this.mapOrderToFormikValues()}
      validationSchema={BurnPermitOrder.getValidationSchema(eligibleForVoucherPayment || this.state.isVoucher || (appDetail ? appDetail.PaymentMethod === 'Voucher' : false))}
      onSubmit={onSubmit}
      innerRef={this.setFormikNode}
    >
      {({ errors, values, }) => (
        <Form onKeyPress={this.onKeyPress} className={'cash-check-form'}>
          <Row className={'mt-4'}>
            <Col>
              <h2 style={{ fontSize: '1.5rem', }}>Record {this.getPaymentMethodName(values.PaymentMethod)} Payment</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for={'PaymentMethod'} className={'mr-2'} id={'payment-method'}>Payment Method</Label>
                { paymentMethods.map(m => <PaymentMethod key={m.name} errors={errors} method={m} onChange={this.changePaymentMethod} />) }
                <ErrorMessage name={'PaymentMethod'}>
                  {errorMessage => <FormFeedback invalid={(errorMessage) ? 'invalid' : ''}>{errorMessage}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            </Col>
          </Row>
          <Row className={'mt-4'}>
            <Col>
              <FormGroup>
                <Label for={'FirstName'}>Payer First Name</Label>
                <Field
                  id={'FirstName'}
                  name={'FirstName'}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for={'LastName'}>Payer Last Name</Label>
                <Field
                  id={'LastName'}
                  name={'LastName'}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <div className={'d-flex'}>
                  <div className={'w-100 mr-4'}>
                    {
                      values.PaymentMethod === 'Voucher'
                        ? <>
                          <Label for={'VoucherDate'}>Voucher Date</Label>
                          <Field
                            id={'VoucherDate'}
                            name={'VoucherDate'}
                            type={'date'}
                            component={ValidatingField}
                          />
                        </>
                        : <>
                          <Label for={'CheckDate'}>{values.PaymentMethod === 'Check' ? 'Check' : 'Payment'} Date</Label>
                          <Field
                            id={'CheckDate'}
                            name={'CheckDate'}
                            type={'date'}
                            component={ValidatingField}
                          />
                        </>
                    }
                  </div>
                  <Button
                    size={'md'}
                    color={'primary'}
                    onClick={this.setDateToToday}
                    data-target={values.PaymentMethod === 'Voucher' ? 'VoucherDate' : 'CheckDate'}
                  >Today</Button>
                </div>
              </FormGroup>
              {
                values.PaymentMethod === 'Check' &&
                  <FormGroup>
                    <Label for={'CheckNumber'}>Check Number</Label>
                    <Field
                      id={'CheckNumber'}
                      name={'CheckNumber'}
                      component={ValidatingField}
                    />
                  </FormGroup>
              }
              <FormGroup>
                <div className={'d-flex'}>
                  <div className={'w-100 mr-4'}>
                    <Label for={'TotalAmountPaid'}>{this.getPrefix(values.PaymentMethod)} Amount</Label>
                    <Field
                      id={'TotalAmountPaid'}
                      name={'TotalAmountPaid'}
                      type={'number'}
                      min={0}
                      step={0.01}
                      component={ValidatingField}
                    />
                  </div>
                  <Button
                    size={'md'}
                    color={'primary'}
                    onClick={this.setFullAmount}
                    data-target={'TotalAmountPaid'}
                    style={{ whiteSpace: 'nowrap', }}
                  >Full Amount</Button>
                </div>
              </FormGroup>
              {
                values.PaymentMethod === 'Voucher' && <>
                  <FormGroup>
                    <Label for={'VoucherNumber'}>Voucher Number</Label>
                    <Field
                      id={'VoucherNumber'}
                      name={'VoucherNumber'}
                      component={ValidatingField}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for={'Comment'}>Comment</Label>
                    <Field
                      id={'Comment'}
                      name={'Comment'}
                      component={'textarea'}
                      className={'form-control'}
                    />
                  </FormGroup>
                </>
              }
            </Col>
          </Row>
          <Row className={'audit-data p-2'}>
            <AuditData
              CreateBy={order.CreateBy}
              CreateDate={order.CreateDate}
              UpdateBy={order.UpdateBy}
              UpdateDate={order.UpdateDate}
            />
          </Row>
          <Row>
            <Button type={'submit'}>Submit</Button>
          </Row>
        </Form>
      )}
    </Formik>
  }
}