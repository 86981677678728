import React, { useState, } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, } from 'reactstrap'
import { Field, } from 'formik'
import { RequiredLabel, ValidatingField, } from '../FormControls'
import { bool, object, } from 'prop-types'
import { dateFormatter, } from '../../utilities'
import { useDispatch, } from 'react-redux'
import PostBurnActions from '../../redux/PostBurnRedux'

const DidNotBurn = (props) => {
  const { readOnly, values, } = props
  const [ isOpen, setIsOpen, ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const dispatch = useDispatch()
  const submitDidNotBurn = () => {
    if (values['DidNotBurnReason'] !== '') {
      values['DidNotBurn'] = true
      values['BurnDate'] = dateFormatter(Date.now(), 'YYYY-MM-DD')
      dispatch(PostBurnActions.createPostBurn(values))
    }
  }
  return (
    <div>
      <Button color={'light'} onClick={toggle}>Did Not Burn</Button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Did Not Burn Reason</ModalHeader>
        <ModalBody>
          <FormGroup>
            <RequiredLabel for={'DidNotBurnReason'}>Why was the request not burned?</RequiredLabel>
            <Field name={'DidNotBurnReason'} type={'textarea'} readOnly={readOnly} component={ValidatingField}/>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>{' '}
          <Button onClick={() => submitDidNotBurn()}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

DidNotBurn.propTypes = {
  readOnly : bool,
  values   : object,
}

export default DidNotBurn