import * as Yup from 'yup'

// Models
import BurnPermitLocation from '../../../models/BurnPermitLocation'

const { Latitude, Longitude, } = BurnPermitLocation.fields

const ValidationSchema = Yup.object().shape({
  InteractionDescription : Yup.string().required('You must provide a description of the event.'),
  Latitude               : Yup.number()
    .typeError('Latitude must be a number')
    .min(Latitude.opts.min, `Latitude must be between ${Latitude.opts.min} and ${Latitude.opts.max}.`)
    .max(Latitude.opts.max, `Latitude must be between ${Latitude.opts.min} and ${Latitude.opts.max}.`),
  Longitude: Yup.number()
    .typeError('Longitude must be a number')
    .min(Longitude.opts.min, `Longitude must be between ${Longitude.opts.min} and ${Longitude.opts.max}.`)
    .max(Longitude.opts.max, `Longitude must be between ${Longitude.opts.min} and ${Longitude.opts.max}.`),
  FollowUp    : Yup.bool().nullable().required('Please specify whether or not a DNR representative should follow up with you regarding this complaint.'),
  PhoneNumber : Yup.string()
    .nullable()
    .test('phone-number-length', 'Phone number must contain 10 digits (i.e., 123-456-7890)', phone => {
      if (!phone) {
        return true
      }
      const alphaReg = /\D/g
      if (alphaReg.test(`${phone}`)) {
        return false
      }
      const digits = `${phone}`.replace(alphaReg, '')
      return digits.length === 10 || digits.length === 0
    }),
  EmailAddress: Yup.string()
    .nullable()
    .test('email-address-format', 'Email address must be in the form of: name@provider.domain', email => {
      if (!email) {
        return true
      }
      const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return reg.test(`${email}`) || !email
    }),
})

export default ValidationSchema
