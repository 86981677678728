// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { CardText, } from 'reactstrap'

// Selectors
import { activeBurnPermitIdSelector, permitApplicationSignedByDnr, } from '../../../../selectors/burnPermitSelectors'
import { signatureByPermitId, } from '../../../../selectors/burnPermitSignatureSelectors'

// Utilities
import { dateFormatter, } from '../../../../utilities'


const ApplicantSignature = () => {

  // Selectors
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const {
    PermitApplicantSignedBy,
    PermitApplicantSignedDate,
  } = useSelector(state => signatureByPermitId(state, burnPermitId))

  const signedByDnr = useSelector(state => permitApplicationSignedByDnr(state, burnPermitId))
  // const applicantCanSign = useSelector(applica)

  if (signedByDnr) {
    return <CardText><b>Application submitted by DNR, no applicant signature required.</b></CardText>
  }

  const markup = <CardText>
    Applicant<br/>
    Signed By: {PermitApplicantSignedBy}<br/>
    Signed On: {dateFormatter(PermitApplicantSignedDate, 'dddd, MMMM D YYYY, h:mm:ss a')}
  </CardText>
  return markup
}


export default React.memo(ApplicantSignature)
