import { attr, Model, } from 'redux-orm'

export default class CustomerInteractionBurnPermitXref extends Model {
  static get modelName () {
    return 'CustomerInteractionBurnPermitXref'
  }

  toString () {
    return `${this.CustomerInteractionBurnPermitXrefId}`
  }

  static endpoint () {
    return 'CustomerInteractionBurnPermitXrefs'
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionBurnPermitXrefId',
    }
  }

  static get fields () {
    return {
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),

      // Relationships
      CustomerInteractionBurnPermitXrefId : attr(),
      CustomerInteractionId               : attr(),
      // fk({
      //   to          : 'CustomerInteraction',
      //   relatedName : 'CustomerInteractionBurnPermitXrefs',
      //   as          : 'CustomerInteraction',
      // }),
      BurnPermitId                        : attr(),
      // fk({
      //   to          : 'BurnRequest',
      //   relatedName : 'CustomerInteractionBurnPermitXrefs',
      //   as          : 'BurnRequest',
      // }),
    }
  }
}
