// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { connect, } from 'react-redux'
import { Container, Row, Col, CardText, Button, ListGroup, ListGroupItem, Alert, FormGroup, Label, ButtonGroup, } from 'reactstrap'
import { func, bool, array, shape, string, number, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import BurnRequestListActions from '../redux/BurnRequestListRedux'
import MapActions from '../redux/MapRedux'
import PostBurnActions from '../redux/PostBurnRedux'
import UiActions from '../redux/UiRedux'
import PersonActions from '../redux/PersonRedux'

// Components
import ConfirmationModal from '../components/ConfirmationModal'
import { SuccessIcon, } from '../components/Icons'
import PostBurnPrompt from '../components/PostBurnPrompt'
import LoadingBar from '../components/LoadingBar'
import { ButtonGroupButton, } from '../components/FormControls'
import withRouter from '../components/withRouter'
import BurnRequestNotices from '../components/Modals/BurnRequestNotices'
import BurnRequestTable from '../components/Search/BurnRequestTable'

// Selectors
import { postBurnPromptSelector, } from '../selectors/postBurnSelectors'
import { requestDownloadedCount, burnRequestsSelector, } from '../selectors/burnRequestSelectors'
import { userPersonIdSelector, } from '../selectors/userSelectors'
import { userSeesPostBurnPrompt, } from '../selectors/burnRequestPermissionSelectors'
import { getUnsubmittedRequests, } from '../selectors/personSelectors'

// Utilities
import stopEvent from '../utilities/stopEvent'

// Map Config

const noopAction = () => {
  console.error('An action was not executed')
}

const DOWNLOAD_BURN_REQUESTS = 'DOWNLOAD_BURN_REQUESTS'


export class BurnRequestListContainer extends React.Component {
  constructor (props) {
    super(props)
  }

  static propTypes = {
    // from withRouter HOC
    navigate: func,

    // actions
    ClearPostBurnStatus   : func,
    GetPostBurnStatus     : func,
    SetPageTitle          : func,
    ToggleMap             : func,
    TogglePostBurn        : func,
    OpenModal             : func,
    CloseDownloadModal    : func,
    DownloadRequests      : func,
    SetDownloadStatus     : func,
    GetMyBurnRequests     : func,
    ToggleDownloadMapData : func,
    // selectors
    isMapShowing          : bool,
    isPromptShowing       : bool,
    postBurnPrompt        : array,
    // from parent
    checkPostBurn         : bool,
    online                : bool,
    DownloadStatuses      : shape({
      BurnPermitsDownloaded  : bool,
      BurnRequestsDownloaded : bool,
      PostBurnsDownloaded    : bool,
    }),
    DownloadMapData      : bool,
    openModalKey         : string,
    isDownloading        : bool,
    lastDownload         : string,
    lastDownloadDuration : string,
    downloadRequestCount : number,
    requests             : array,
    personId             : number,
    burnRequestNotices   : array,
    GetRequestNotices    : func,
    isAuthenticated      : bool,
  }

  static defaultProps = {
    ClearPostBurnStatus : noopAction,
    GetPostBurnStatus   : noopAction,
    SetPageTitle        : noopAction,
    ToggleMap           : noopAction,
    TogglePostBurn      : noopAction,
    isMapShowing        : false,
    isPromptShowing     : true,
    postBurnPrompt      : [],
    checkPostBurn       : false,
  }

  state = {
    downloadMapData       : false,
    requestModalDismissed : false,
  }

  componentDidMount () {
    const { online, checkPostBurn, SetPageTitle, ClearPostBurnStatus, GetPostBurnStatus, GetMyBurnRequests, } = this.props
    SetPageTitle('My Burn Requests')
    GetMyBurnRequests()
    if (checkPostBurn) {
      ClearPostBurnStatus()
      if (online) {
        GetPostBurnStatus()
      }
    }
    this.props.GetRequestNotices(this.props.personId)
  }

  componentDidUpdate (prevProps) {
    const { openModalKey, DownloadStatuses, } = this.props
    
    if (openModalKey === DOWNLOAD_BURN_REQUESTS) {
      if (prevProps.DownloadMapData && !this.props.DownloadMapData && !DownloadStatuses.MapDataDownloaded) {
        this.props.SetDownloadStatus('MapData', true)
      }
    }
    else if (prevProps.openModalKey === DOWNLOAD_BURN_REQUESTS && !openModalKey) {
      this.setState({ downloadMapData: false, })
    }
  }

  componentWillUnmount () {
    this.props.ClearPostBurnStatus()
  }

  togglePostBurn = () => {
    this.props.TogglePostBurn(!this.props.isPromptShowing)
  }

  downloadRequests = evt => {
    stopEvent(evt)

    const { OpenModal, } = this.props

    OpenModal(DOWNLOAD_BURN_REQUESTS)
  }

  confirmDownloadRequests = () => {
    if (this.state.downloadMapData) {
      if (!this.props.isMapShowing) {
        this.props.ToggleMap()
      }
      this.props.ToggleDownloadMapData()
    }
    this.props.DownloadRequests()
  }

  closeDownloadModal = () => {
    const { openModalKey, CloseDownloadModal, } = this.props
    if (openModalKey === DOWNLOAD_BURN_REQUESTS) {
      CloseDownloadModal()
    }
  }

  toggleMapDownload = evt => {
    const { classList, } = evt.target
    if (classList.contains('active')) {
      return
    }
    this.setState({ downloadMapData: !this.state.downloadMapData, })
  }

  closeBurnReqsNotices = () => this.setState({ requestModalDismissed: true, })

  render () {
    const { postBurnPrompt, DownloadStatuses, isAuthenticated, requests, isMapShowing, } = this.props
    const { requestModalDismissed, } = this.state 
    const showPostBurn = (postBurnPrompt && postBurnPrompt.length > 0)
    const aboveTableContent = <>
      <CardText className={'my-2 px-2'}>
        {
          this.props.checkPostBurn
            ? 'View your Burn Requests and Post-Burn data here. Click New Request to start a new Burn Request.'
            : 'View the Burn Requests of the Burn Permits you\'re assigned to here.'
        }
      </CardText>
      {
        this.props.online &&
      <CardText className={'my-2 px-2'}>
        Click <Button color={'light'} size={'sm'} onClick={this.downloadRequests} className={'mx-1'}><span className={'fa fa-download mr-2'}></span>Download</Button> to allow access to your Burn Requests while offline.
      </CardText>
      }
      {
        showPostBurn &&
    <Row className={'my-2'}>
      <Col>
        <PostBurnPrompt
          postBurnPrompt={this.props.postBurnPrompt}
          isPromptShowing={this.props.isPromptShowing}
          onToggleList={this.togglePostBurn}
          showExpander={true}
        />
      </Col>
    </Row>
      }
    </>
    const belowTableContent = <Row>
      <Col>
        <Link to={'/burn-requests/new'} className={'btn btn-link'}>New Request</Link>
      </Col>
    </Row>
    return (
      <Container className={'py-2'}>
        <h1>My Burn Requests</h1>
        <BurnRequestNotices
          requests={this.props.burnRequestNotices}
          isOpen={!requestModalDismissed && this.props.burnRequestNotices.length > 0}
          closeModal={this.closeBurnReqsNotices}
        />
        <BurnRequestTable
          requests={requests}
          showMap={isMapShowing}
          showCardHeader={true}
          isAuthenticated={isAuthenticated}
          aboveTableContent={aboveTableContent}
          belowTableContent={belowTableContent}
          showPermitAction={true}
          layerConfig={'My Requests'}
        /> 
        <ConfirmationModal
          disableSubmit={this.props.isDownloading}
          submitActionLabel={'Download'}
          cancelActionLabel={'Close'}
          cancelAction={this.closeDownloadModal}
          modalKey={DOWNLOAD_BURN_REQUESTS}
          modalTitle={'Download Burn Requests'}
          submitAction={this.confirmDownloadRequests}
          modalBody={<>
            {
              this.props.lastDownload
                ? <>
                  <p>You last downloaded { this.props.downloadRequestCount || requests.length } Burn Requests on<br/>{ this.props.lastDownload } which took { this.props.lastDownloadDuration }.</p>
                  <Alert color={'primary'}>This can take a couple minutes depending on your internet connection.</Alert>
                </>
                : <p>Click Download below to download the your Burn Requests, Burn Permits, and Post Burn data for offline use.</p>
            }
            <FormGroup>
              <Label>Download Map Data?</Label>
              <ButtonGroup className={'d-block'}>
                <ButtonGroupButton text={'Yes'} isActive={this.state.downloadMapData} onClick={this.toggleMapDownload} />
                <ButtonGroupButton text={'No'} isActive={!this.state.downloadMapData} onClick={this.toggleMapDownload} />
              </ButtonGroup>
              {
                this.state.downloadMapData && <>
                  <p>If you want access to Imagery offline, make sure to set it as your active basemap before starting the download</p>
                  <p>Not all basemaps are available offline or at all zoom levels.</p>
                  <Alert color={'warning'} className={'mt-2'}><b>Warning!</b> Downloading map data is best performed on a fast, stable Wi-Fi connection.</Alert>
                </>
              }
            </FormGroup>
            { this.props.isDownloading && <>
              <p>Please wait while we download your { this.props.downloadRequestCount || requests.length } Burn Requests.</p>
              <ListGroup>
                {
                  this.state.downloadMapData &&
                    <ListGroupItem>
                      Map Data <LoadingBar className={'download-progress'} show={!DownloadStatuses.MapDataDownloaded} /> { this.props.DownloadStatuses.MapDataDownloaded && <SuccessIcon className={'download-success'} /> }
                    </ListGroupItem>
                }
                <ListGroupItem>
                    Burn Permits <LoadingBar className={'download-progress'} show={!DownloadStatuses.BurnPermitsDownloaded} /> { DownloadStatuses.BurnPermitsDownloaded && <SuccessIcon className={'download-success'} /> }
                </ListGroupItem>
                <ListGroupItem>
                    Burn Requests <LoadingBar className={'download-progress'} show={!DownloadStatuses.BurnRequestsDownloaded} /> { DownloadStatuses.BurnRequestsDownloaded && <SuccessIcon className={'download-success'} /> }
                </ListGroupItem>
                <ListGroupItem>
                    Post Burns <LoadingBar className={'download-progress'} show={!DownloadStatuses.PostBurnsDownloaded} /> { DownloadStatuses.PostBurnsDownloaded && <SuccessIcon className={'download-success'} /> }
                </ListGroupItem>
              </ListGroup>
            </>
            }
          </>}
        />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  const personId = userPersonIdSelector(state)
  let postBurnPrompt = []
  const checkPostBurn = userSeesPostBurnPrompt(state)
  if (checkPostBurn) {
    postBurnPrompt = postBurnPromptSelector(state)
  }
  const {
    isDownloading,
    isMapShowing,
    isPostBurnShowing,
    lastDownloadFormatted,
    lastDownloadDuration,
    DownloadStatuses,
  } = state.BurnRequestList

  let downloadRequestCount = 0
  if (state.offline.online) {
    downloadRequestCount = requestDownloadedCount(state)
  }

  const { showModal, modalKey, } = state.ui.Modal
  const { online, } = state.offline
  return {
    online,
    postBurnPrompt,
    showModal,
    requests           : burnRequestsSelector(state),
    openModalKey       : modalKey,
    isPromptShowing    : isPostBurnShowing,
    isMapShowing       : isMapShowing,
    lastDownload       : lastDownloadFormatted,
    lastDownloadDuration,
    isDownloading,
    DownloadStatuses,
    downloadRequestCount,
    DownloadMapData    : state.Map.downloadMapData,
    checkPostBurn,
    burnRequestNotices : getUnsubmittedRequests(state),
    personId,
  }
}

const mapDispatchToProps = {
  SetPageTitle          : AppActions.setPageTitle,
  ToggleMap             : BurnRequestListActions.toggleMap,
  GetPostBurnStatus     : PostBurnActions.getPbPromptsForUser,
  ClearPostBurnStatus   : PostBurnActions.clearPostBurnPrompt,
  TogglePostBurn        : BurnRequestListActions.togglePbs,
  DownloadRequests      : BurnRequestListActions.downloadRequests,
  GetMyBurnRequests     : BurnRequestListActions.getMyBurnRequests,
  SetDownloadStatus     : BurnRequestListActions.setRequestsDownloadStatus,
  CloseDownloadModal    : BurnRequestListActions.closeRequestsDownloadModal,
  ToggleDownloadMapData : MapActions.toggleDownloadMapData,
  OpenModal             : UiActions.openModal,
  GetRequestNotices     : PersonActions.getUnsubmittedRequests,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnRequestListContainer))