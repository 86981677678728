import { createSelector, } from 'reselect'
import { parseContentStateValue, } from '../utilities'

import { ormSelector, ormWithPropSelector, ormByIdSelector, } from './selectors'

export const conditionStateSelector = state => state.PermitConditions

export const conditionErrorsSelector = createSelector(
  conditionStateSelector,
  state => state ? state.conditionErrors : null
)

export const activePermitIdSelector = createSelector(
  conditionStateSelector,
  state => state ? state.burnPermitId : ''
)

/**
 * Map the ORM condition value data to something the UI can handle.
 */
export const conditionValuesNewForBurnPermitSelector = ormSelector(
  activePermitIdSelector,
  ({ BurnPermit, BurnPermitConditionXref, }, BurnPermitId) => {
    if (!BurnPermitId) {
      return {}
    }

    let conditionXrefs = []
    // Try to get the data through the Permit model
    if (BurnPermit.idExists(BurnPermitId)) {
      conditionXrefs = BurnPermit.withId(BurnPermitId).BurnPermitConditionXrefs.all().toModelArray()
    }
    // But provide a fallback in case for some reason the user goes straight to the Conditions
    // page without ever visiting another page that would've requested the permit
    else if (BurnPermitConditionXref.filter({ BurnPermitId, }).exists()) {
      conditionXrefs = BurnPermitConditionXref.filter({ BurnPermitId, }).toModelArray()
    }

    if (!conditionXrefs || (conditionXrefs.length === 0)) {
      return {}
    }

    let valueObj = {}
    conditionXrefs.forEach((x) => {
      if (!x.BurnPermitCondition) {
        return
      }
      const condition = x.BurnPermitCondition
      if (!condition) {
        return
      }

      const { BurnPermitConditionName, } = condition

      switch (BurnPermitConditionName) {
      case 'Wind direction':
        if (x.BurnPermitWindDirectionConditions.exists()) {
          valueObj[x.BurnPermitConditionId] = x.BurnPermitWindDirectionConditions.toRefArray().map(x => x.DirectionId)
        } else {
          valueObj[x.BurnPermitConditionId] = []
        }
        break
      case 'Days of the week':
        if (x.BurnPermitProhibitedDayConditions.exists()) {
          valueObj[x.BurnPermitConditionId] = x.BurnPermitProhibitedDayConditions.toRefArray().map(x => x.DayOfWeekId)
        } else {
          valueObj[x.BurnPermitConditionId] = []
        }
        break
      case 'Equipment requirements':
        if (x.BurnPermitEquipmentConditions.exists()) {
          valueObj[x.BurnPermitConditionId] = x.BurnPermitEquipmentConditions.toRefArray().map(x => x.EquipmentTypeId)
          const otherDesc = x.BurnPermitEquipmentConditionXrefs.toRefArray().filter(x => x.OtherDescription).map(x => x.OtherDescription)[0]
          if (otherDesc) {
            valueObj[x.BurnPermitConditionId].push(otherDesc)
          }
        } else {
          valueObj[x.BurnPermitConditionId] = []
        }
        break
      case 'Other conditions':
        if (x.OtherConditionFormatted) {
          valueObj[x.BurnPermitConditionId] = parseContentStateValue(x.OtherConditionFormatted)
        } else {
          valueObj[x.BurnPermitConditionId] = parseContentStateValue(x.OtherCondition)
        }
        break
      case 'Required contact':
        // do nothing - this one is handled elsewhere
        break
      default:
        valueObj[x.BurnPermitConditionId] = true
        Object.keys(x._fields).forEach(key => {
          if (!key.startsWith('Create') && !key.startsWith('Update') && !key.endsWith('Id') && x[key]) {
            valueObj[x.BurnPermitConditionId] = x[key]
          }
        })
        break
      }
    })
    return valueObj
  }
)
const conditionsSelector = ormSelector(
  ({ BurnPermitCondition, }) => {
    if (!BurnPermitCondition) {
      return []
    }
    const query = BurnPermitCondition.all()
    if (!query.exists()) {
      return []
    }
    return query.filter(c => c.BurnPermitConditionName !== 'Required contact').orderBy('BurnPermitConditionId').toModelArray()
  }
)

export const contactConditionSelector = ormSelector(
  ({ BurnPermitCondition, }) => {
    if (!BurnPermitCondition) {
      return null
    }
    const query = BurnPermitCondition.all()
    if (!query.exists()) {
      return null
    }
    return query.filter(c => c.BurnPermitConditionName === 'Required contact').first()
  }
)

const mapContactCondition = (contact) => {
  if (!contact) {
    return {
      ContactName                          : '',
      PhoneNumber                          : '',
      PhoneExt                             : '',
      BurnPermitRequiredContactConditionId : -1,
    }
  }
  return {
    ContactName                          : contact.ContactName || '',
    PhoneNumber                          : contact.PhoneNumber || '',
    PhoneExt                             : contact.PhoneExt || '',
    BurnPermitRequiredContactConditionId : contact.BurnPermitRequiredContactConditionId || -1,
  }
}

const mapContactConditionForFormik = (contacts) => {
  if (!contacts || !Array.isArray(contacts)) {
    return {
      Contacts: [],
    }
  }
  return {
    Contacts: contacts.map(mapContactCondition),
  }
}

export const contactConditionXrefForPermitSelector = ormByIdSelector(
  contactConditionSelector,
  ({ BurnPermitConditionXref, }, burnPermitId, contactCondition) => {
    if (!BurnPermitConditionXref || !contactCondition) {
      return null
    }
    const query = BurnPermitConditionXref.filter(x =>
      x.BurnPermitConditionId === contactCondition.BurnPermitConditionId
      && x.BurnPermitId === burnPermitId)

    if (!query.exists()) {
      return null
    }
    return query.first()
  }
)
  

export const contactConditionForPermitSelector = ormByIdSelector(
  contactConditionSelector,
  ({ BurnPermitCondition, BurnPermitConditionXref, BurnPermitRequiredContactCondition, }, burnPermitId, contactCondition) => {
    const returnDefault = !BurnPermitRequiredContactCondition || !BurnPermitConditionXref || !BurnPermitCondition || !contactCondition || !burnPermitId
    if (returnDefault) {
      return mapContactConditionForFormik()
    }
    const query = BurnPermitConditionXref.filter(x => 
      x.BurnPermitConditionId === contactCondition.BurnPermitConditionId
      && x.BurnPermitId === burnPermitId)

    if (!query.exists()) {
      return mapContactConditionForFormik()
    }
    const xref = query.first()
    if (!xref || !xref.BurnPermitRequiredContactConditions 
      || !xref.BurnPermitRequiredContactConditions.exists()) {
      return mapContactConditionForFormik()
    }
    
    const contacts = xref.BurnPermitRequiredContactConditions.toRefArray()
    return mapContactConditionForFormik(contacts)
  }
)


export const burnPermitConditionsSelector = ormSelector(
  conditionsSelector,
  (orm, conditions) => {
    return conditions.map((c) => {
      return {
        BurnPermitConditionId          : c.BurnPermitConditionId,
        CreateBy                       : c.CreateBy,
        CreateDate                     : c.CreateDate,
        UpdateBy                       : c.UpdateBy,
        UpdateDate                     : c.UpdateDate,
        BurnPermitConditionName        : c.BurnPermitConditionName,
        BurnPermitConditionDescription : c.BurnPermitConditionDescription,
        ParentConditionId              : c.ParentConditionId,
        ChildConditions                : c.ChildConditions ? c.ChildConditions.all().toRefArray().map((c) => c.BurnPermitConditionId) : [],
        Category                       : c.Category,
      }
    })
  }
)

export const conditionFieldToNameMapSelector = ormWithPropSelector(
  ({ BurnPermitConditionXref, }, fieldName) => {
    if (!BurnPermitConditionXref || !fieldName) {
      return null
    }
    if (typeof BurnPermitConditionXref.mapFieldToName !== 'function') {
      return null
    }
    return BurnPermitConditionXref.mapFieldToName(fieldName)
  }
)

export const permitConditionNameByIdSelector = ormByIdSelector(
  ({ BurnPermitCondition, }, conditionId) => {
    if (isNaN(conditionId)) {
      return null
    }

    if (!BurnPermitCondition.all().exists()) {
      return null
    }
    let c = BurnPermitCondition.withId(conditionId)
    if (!c) {
      return null
    }
    return c.BurnPermitConditionName
  }
)


export const permitConditionByNameSelector = ormWithPropSelector(
  ({ BurnPermitCondition, }, conditionName) => {
    if (!BurnPermitCondition.all().exists()) {
      return null
    }
    let c = null
    try {
      c = BurnPermitCondition.get({ BurnPermitConditionName: conditionName, })
    } catch (e) {
      return null
    }
    if (!c) {
      return null
    }
    return {
      BurnPermitConditionId          : c.BurnPermitConditionId,
      CreateBy                       : c.CreateBy,
      CreateDate                     : c.CreateDate,
      UpdateBy                       : c.UpdateBy,
      UpdateDate                     : c.UpdateDate,
      BurnPermitConditionName        : c.BurnPermitConditionName,
      BurnPermitConditionDescription : c.BurnPermitConditionDescription,
      ParentConditionId              : c.ParentConditionId,
      ChildConditions                : c.ChildConditions ? c.ChildConditions.all().toRefArray().map(c => c.BurnPermitConditionId) : [],
      Category                       : c.Category,
    }
  }
)

export const conditionValueKeySelector = ormWithPropSelector(
  ({ BurnPermitConditionXref, },  conditionName) => {
    if (!BurnPermitConditionXref) {
      return null
    }
    if (typeof BurnPermitConditionXref.mapConditionValueKey !== 'function') {
      return null
    }
    return BurnPermitConditionXref.mapConditionValueKey(conditionName)
  }
)


export const permitHasConditions = createSelector(
  conditionValuesNewForBurnPermitSelector,
  values => {
    return values ? Object.values(values).length > 0 : false
  }
)
