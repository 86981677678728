// Libraries
import React from 'react'
import * as Yup from 'yup'
import { Formik, Field, Form, } from 'formik'
import { bool, string, func, } from 'prop-types'
import { Col, Label, Row, Button, } from 'reactstrap'

// Components
import ValidatingField from '../FormControls/ValidatingField'


const REVOKE_PERMIT_SCHEMA = Yup.object().shape({
  RevokedDate: Yup.date().required('You must provide the date the Burn Permit was revoked.'),
})


const RevokePermitForm = (props) => {
  const { 
    RevokedDate,
    Comment,
    readOnly,
    onSubmit,
    onUndo,
    isRevoked,
  } = props

  const canClear = !readOnly && !!RevokedDate

  return <Formik
    validationSchema={REVOKE_PERMIT_SCHEMA}
    initialValues={{ RevokedDate, Comment, }}
    enableReinitialize={true}
    onSubmit={onSubmit}
    validateOnMount={true}
  >
    <Form>
      {!readOnly && <p>Revoke a Burn Permit when burning should be <b>permanently</b> restricted. Make sure to search for any active Burn Requests that may need to be denied as part of this revocation.</p>}
      <Row className={'mb-2'}>
        <Col>
          <Label for={'RevokedDate'}>Revoke Date</Label>
          <Field
            type={'date'}
            id={'RevokedDate'}
            name={'RevokedDate'}
            readOnly={readOnly}
            component={ValidatingField}
          />
        </Col>
        <Col>
          <Label for={'Revoke-Comment'}>Comment</Label>
          <Field id={'Revoke-Comment'} name={'Comment'} component={'textarea'} className={'form-control'} />
          <small className={'text-warning d-block'}>Warning: This comment will be visible to the permittee and/or agent.</small>
        </Col>
      </Row>
      { !readOnly &&
        <Row className={'mb-2'}>
          <Col>
            { !isRevoked && <Button type={'submit'} className={'btn-primary float-right'}>Revoke Permit</Button> }
            { canClear && <Button className={'btn-danger'} onClick={onUndo}>Undo Revocation</Button> }
          </Col>
        </Row>
      }
    </Form>
  </Formik>
}

RevokePermitForm.propTypes = {
  readOnly    : bool,
  RevokedDate : string,
  Comment     : string,
  onSubmit    : func,
  onUndo      : func,
  isRevoked   : bool,
}

RevokePermitForm.defaultProps = {
  readOnly    : false,
  RevokedDate : '',
  Comment     : '',
  onSubmit    : () => {},
  onUndo      : () => {},
}

export default RevokePermitForm