import { attr, Model, } from 'redux-orm'

export default class BurnPermitUnit extends Model {
  static get modelName () {
    return 'BurnPermitUnit'
  }

  toString () {
    return `${this.BurnPermitUnitName}`
  }

  static get options () {
    return  {
      idAttribute: 'BurnPermitUnitId',
    }
  }

  static get fields () {
    return {
      BurnPermitUnitId   : attr(),
      BurnPermitUnitName : attr(),
    }
  }
}