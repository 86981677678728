// Libraries
import React from 'react'
import ReactDOM from 'react-dom'
import { connect, } from 'react-redux'
import { object, array, func, string, bool, } from 'prop-types'
import { Container, Card, CardBody, } from 'reactstrap'

// Components
import DataTable from '../components/DataTable'

// Actions
import BurnPermitOrderActions from '../redux/BurnPermitOrderRedux'
import AppActions from '../redux/AppRedux'

// Selectors
import { ordersSelector, } from '../selectors/orderSelectors'

// Models
import BurnPermitOrder from '../models/BurnPermitOrder'

// Utilities
import stopEvent from '../utilities/stopEvent'


const ORDER_DATATABLE_COLUMNS = BurnPermitOrder.getDataTablesColumns()

export class BurnPermitOrderListContainer extends React.Component {

  constructor (props) {
    super(props)

    const permitIdColIdx = ORDER_DATATABLE_COLUMNS.findIndex(c => c.data === 'BurnPermitOrderXref')
    const orderIdColIdx = ORDER_DATATABLE_COLUMNS.findIndex(c => c.name === 'pay')

    const { RedirectTo, } = this.props
    const { navigate, } = this.props

    this.columnDefs = [
      {
        targets     : permitIdColIdx,
        createdCell : (td, cellData) => {
          if (!cellData || !Array.isArray(cellData) || cellData.length === 0) {
            return td
          }
          // eslint-disable-next-line
          ReactDOM.render(
            cellData.map(({ BurnPermitId, }) => {
              // Sometimes the destructured property is an object, this will check for that and handle it
              if (typeof BurnPermitId !== 'number' && typeof BurnPermitId === 'object' && 'BurnPermitId' in BurnPermitId) {
                BurnPermitId = BurnPermitId.BurnPermitId
              }
              return <React.Fragment key={`permit-link-${BurnPermitId}`}>
                <a
                  key={`permits-${BurnPermitId}`}
                  href={`/permits/${BurnPermitId}`}
                  className={'text-dark'}
                  onClick={e => {
                    stopEvent(e)
                    navigate({ pathname: '/permits/' + BurnPermitId, state: '/permits/orders', })
                  }}>{BurnPermitId}</a>
                <br/>
              </React.Fragment>
            })
            , td
          )
        },
      },
      {
        targets     : orderIdColIdx,
        createdCell : (td, data) => {
          if (!data) {
            return td
          }
          
          // Always show a view detail control when the order is not paid
          // This will allow another route for users to access the unpaid order to either
          // remove applications from the order or delete the order altogether
          // eslint-disable-next-line
          ReactDOM.render(
            <>
              <a
                key={'view-order-' + data.BurnPermitOrderId}
                className={'btn btn-primary btn-sm mr-2'}
                href={`/permits/orders/${data.BurnPermitOrderId}`}
                onClick={e => {
                  stopEvent(e)
                  RedirectTo(`/permits/orders/${data.BurnPermitOrderId}`)
                }}>View</a>
              {
                // Only show the Pay control when the order is not paid
                data.AllowPayment &&
                  <a
                    key={'pay-order-' + data.BurnPermitOrderId}
                    className={'btn btn-primary btn-sm'}
                    href={`/permits/orders/${data.BurnPermitOrderId}/pay`}
                    onClick={e => {
                      stopEvent(e)
                      RedirectTo(`/permits/orders/${data.BurnPermitOrderId}/pay`)
                    }}>Pay</a>
              }
            </>
            , td
          )
        },
      },
    ]
  }

  static propTypes = {
    RedirectTo       : func,
    GetOrders        : func,
    SetPageTitle     : func,
    orders           : array,
    match            : object,
    navigate         : func,
    shouldRedirectTo : string,
    isDnr            : bool,
  }

  componentDidMount () {
    const { SetPageTitle, GetOrders, } = this.props

    SetPageTitle('All Orders')
    GetOrders()
  }

  render () {
    return <Container className={'py-4'}>
      <h1>Burn Permit Orders</h1>
      <Card className={'w-100'}>
        <CardBody className={'pt-2'}>
          <DataTable
            columns={ORDER_DATATABLE_COLUMNS}
            columnDefs={this.columnDefs}
            records={this.props.orders}
            elementId={'order-list-table'}
            rowIdProp={'BurnPermitOrderId'}
            onDeleteRecordClicked={this.onDeleteRecordClicked}
            disableRowClick={true}
            disableDefaultOrdering={true}
            autoWidth={false}
          />
        </CardBody>
      </Card>
    </Container>
  }
}

function mapStateToProps (state) {
  return {
    orders: ordersSelector(state),
  }
}

const mapDispatchToProps = {
  RedirectTo   : AppActions.redirectTo,
  SetPageTitle : AppActions.setPageTitle,
  GetOrders    : BurnPermitOrderActions.getOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitOrderListContainer)