import React from 'react'
import { FormGroup, Col, Label, Input,  } from 'reactstrap'
import { func, string, bool, array, number, object, PropTypes, } from 'prop-types'
import RequiredLabel from './RequiredLabel'


export function RadioInputs (props) {
  const {
    values,
    label,
    inputName,
    onChange,
    readOnly,
    checkedValue,
    invalid,
    errorMessage,
    showPrompt,
    required,
    className,
  } = props
  return <Col xs={'12'}>
    <fieldset>
      <legend>
        {
          required && !readOnly 
            ? <RequiredLabel>{label}</RequiredLabel>
            : <Label>{label}</Label>
        }
      </legend>
      { !readOnly && showPrompt && <small className={'d-block mb-2'}>(Choose only one)</small> }
      {invalid && <div className={'invalid-feedback radio-invalid-feedback'}>{errorMessage}</div>}
      {
        values.map(v => {
          const key = `radio-input-${v.Value}`
          return <FormGroup className={className} key={key}>
            <Label className={'form-radio-label'}>
              <Input
                type={'radio'}
                value={v.Value}
                name={inputName}
                onChange={onChange}
                checked={v.Value === checkedValue}
                readOnly={readOnly}
                disabled={readOnly}
                invalid={invalid}
              />
              <div className={readOnly && v.Value !== checkedValue ? 'grey-text' : null}>{v.Text}</div>
            </Label>
          </FormGroup>
        })
      }
    </fieldset>
  </Col>
}

RadioInputs.propTypes = {
  inputName    : string,
  onChange     : func,
  checkedValue : PropTypes.oneOfType([ string, number, ]),
  readOnly     : bool,
  invalid      : bool,
  errorMessage : string,
  label        : PropTypes.oneOfType([ string, object, ]),
  values       : array.isRequired,
  required     : bool,
  showPrompt   : bool,
  className    : string,
}

RadioInputs.defaultProps = {
  readOnly     : false,
  inputName    : null,
  onChange     : () => {},
  checkedValue : null,
  invalid      : false,
  errorMessage : '',
  showPrompt   : true,
  className    : 'pl-5 mb-1',
}

export default RadioInputs