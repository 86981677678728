import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'

export default class BurnPermitReferenceDirectionXref extends Model {
  static get modelName () {
    return 'BurnPermitReferenceDirectionXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitReferenceDirectionXrefId',
    }
  }

  static get validationSchema () {
    return Yup.object().shape({
      ShowDistanceDirection : Yup.bool(),
      DistanceId            : Yup.number()
        .when([ 'ShowDistanceDirection', 'ValidateDistance', ], {
          is   : (show, val) => show && val,
          then : Yup.number()
            .min(1, 'You must select a Distance')
            .required('You must select a Distance'),
        }),
      DirectionId: Yup.number()
        .when('ShowDistanceDirection', {
          is   : true,
          then : Yup.number()
            .min(1, 'You must select a Direction')
            .required('You must select a Direction'),
        }),
    })
  }

  static get fields () {
    return {
      BurnPermitReferenceDirectionXrefId : attr(),
      ReferenceDirectionId               : fk({ 
        to          : 'ReferenceDirection', 
        as          : 'ReferenceDirection', 
        relatedName : 'BurnPermitReferenceDirectionXref',
      }),
      BurnPermitSiteId: fk({
        to          : 'BurnPermitSite',
        as          : 'BurnPermitSite',
        relatedName : 'BurnPermitReferenceDirectionXref',
      }),
      CreateBy   : attr(),
      CreateDate : attr(),
      UpdateBy   : attr(),
      UpdateDate : attr(),
    }
  }
}