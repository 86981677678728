// Libraries
import React, { useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap'

// Actions
import BurnPermitFormActions from '../../../redux/BurnPermitFormRedux'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'
import { activeBurnPermitIdSelector, } from '../../../selectors/burnPermitSelectors'


const LoadingApplication = () => {

  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const online = useSelector(appIsOnlineSelector)
  const activeBurnPermitId = useSelector(activeBurnPermitIdSelector)

  // DidMount
  useEffect(() => {
    dispatch(BurnPermitFormActions.createPermitApplication())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // DidUpdate
  useEffect(() => {
    if (Number.isInteger(activeBurnPermitId)) {
      navigate(`/permits/${activeBurnPermitId}`)
    }
  }, [ navigate, activeBurnPermitId, ])

  // Memoed values
  const loadingAppContainerSize = useMemo(() => ({ size: 6, offset: 3, }), [])

  return <Container className={'pt-2'}>
    <Row>
      <Col md={loadingAppContainerSize}>
        <Card>
          <CardBody>
            <b>Loading Application...</b>
            {
              !online && <CardText>
                <b>You are creating a Burn Permit Application while offline.</b>
                <br/>Once you regain internet connectivity, all data saved while offline will be automatically submitted.
              </CardText>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
}

export default React.memo(LoadingApplication)