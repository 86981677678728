import { attr, fk, Model, } from 'redux-orm'

export default class ReferenceDirection extends Model {
  static get modelName () {
    return 'ReferenceDirection'
  }

  static get options () {
    return {
      idAttribute: 'ReferenceDirectionId',
    }
  }

  static get fields () {
    return {
      ReferenceDirectionId : attr({ type: 'number', }),
      DistanceId           : fk({ 
        to          : 'Distance', 
        as          : 'Distance', 
        relatedName : 'ReferenceDistance',
      }),
      DirectionId: fk({ 
        to          : 'Direction', 
        as          : 'Direction', 
        relatedName : 'ReferenceDirection',
      }),
      ReferenceDirectionTypeId: fk({ 
        to          : 'ReferenceDirectionType', 
        as          : 'ReferenceDirectionType', 
        relatedName : 'ReferenceDirectionType',
      }),
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}