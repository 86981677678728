import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  // for the container
  activePersonId              : [ 'personId', ],
  deactivatePerson            : null,
  // data loading
  getAllPeople                : [ 'forceRefreshFromServer', ],
  getAllLandowners            : null,
  getAllAgents                : null,
  getPerson                   : [ 'personId', 'permitAndRequestSearch', ],
  getLandownerInfo            : [ 'personId', ],
  getAgentInfo                : [ 'personId', ],
  getPersonLookupData         : null,
  newPerson                   : null,
  createPerson                : [ 'person', 'email', ],
  updatePerson                : [ 'person', ],
  getAssignableUsers          : null,
  applicationUsersSuccess     : [ 'payload', ],
  // Phone numbers
  getAgentPhones              : [ 'personId', ],
  createLocalPhoneForPerson   : [ 'personId', ],
  createPersonPhone           : [ 'personId', 'phone', ],
  deletePersonPhone           : [ 'personId', 'phoneId', ],
  // Addresses
  getAgentAddresses           : [ 'personId', ],
  createLocalAddressForPerson : [ 'personId', ],
  createPersonAddress         : [ 'personId', 'address', ],
  deletePersonAddress         : [ 'personId', 'addressId', ],
  // Email
  getAgentEmail               : [ 'personId', ],
  deletePersonEmail           : [ 'personId', ],
  createPersonEmail           : [ 'personId', 'email', ],
  // Region
  updatePersonRegion          : [ 'personId', 'regionId', 'makesDecisions', ],
  setPersonRegion             : [ 'personId', 'regionId', 'makesDecisions', ],
  mergePeople                 : [ 'person', 'mergedPersonId', ],
  getDataForPeopleMerge       : null,
  getPersonDataForMerge       : [ 'personId', ],
  getUnsubmittedRequests      : [ 'personId', ],
  setUnsubmittedRequests      : [ 'requests', ],
  setInactiveMetadata         : [ 'key', 'activeOnly', ],
})


export const PersonTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Object.freeze({
  activePerson : -1,
  isNewPerson  : false,
  inactiveData : {
    Addresses: {
      activeOnly: true,
    },
    Phones: {
      activeOnly: true,
    },
  },
})

export const getPerson = (state, { personId, }) => {
  const newState = { ...state, }
  newState.activePerson = personId
  newState.isNewPerson = false
  newState.inactiveData = { ...INITIAL_STATE.inactiveData, }
  return newState
}

export const newPerson = (state) => {
  const newState = { ...state, }
  newState.isNewPerson = true
  newState.inactiveData = { ...INITIAL_STATE.inactiveData, }
  return newState
}

export const activePersonId = (state, { personId, }) => {
  const newState = { ...state, }
  newState.activePerson = personId
  newState.inactiveData = { ...INITIAL_STATE.inactiveData, }
  return newState
}

export const deactivatePerson = (state) => {
  const newState = { ...state, }
  newState.activePerson = -1
  newState.isNewPerson = false
  newState.inactiveData = { ...INITIAL_STATE.inactiveData, }
  return newState
}

export const setUnsubmittedRequests = (state, { requests = null, }) => {
  const newState = { ...state, }
  newState.burnRequestsPrompts = requests
  return newState
}

const setInactiveMetadata = (state, { key, activeOnly = true, }) => {
  const newState = { ...state, }
  if (!newState.inactiveData) {
    newState.inactiveData = { ...INITIAL_STATE.inactiveData, }
  }
  newState.inactiveData[key] = {
    activeOnly,
  }
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PERSON]               : getPerson,
  [Types.NEW_PERSON]               : newPerson,
  [Types.ACTIVE_PERSON_ID]         : activePersonId,
  [Types.DEACTIVATE_PERSON]        : deactivatePerson,
  [Types.SET_UNSUBMITTED_REQUESTS] : setUnsubmittedRequests,
  [Types.SET_INACTIVE_METADATA]    : setInactiveMetadata,
})