// Libraries
import React from 'react'
import { FormGroup, Label, Button, Card, CardBody, } from 'reactstrap'
import { Field, } from 'formik'
import { number, func, } from 'prop-types'

// Components
import { ValidatingField, } from '../FormControls'


const ContactConditionCard = ({ idx, removeFunc, }) => {
  const onClick = React.useCallback(() => removeFunc(idx), [ idx, removeFunc, ])
  if (isNaN(idx) || idx < 0) {
    return null
  }
  const fieldNamePrefix = `Contacts[${idx}].`
  return <Card>
    <CardBody>
      <FormGroup>
        <Label for={`${fieldNamePrefix}ContactName`}>To Notify Before Ignition</Label>
        <Field name={`${fieldNamePrefix}ContactName`} id={`${fieldNamePrefix}ContactName`} component={ValidatingField} type={'text'} />
      </FormGroup>
      <FormGroup>
        <Label for={`${fieldNamePrefix}PhoneNumber`}>Phone</Label>
        <Field name={`${fieldNamePrefix}PhoneNumber`} id={`${fieldNamePrefix}PhoneNumber`} component={ValidatingField} type={'text'} />
      </FormGroup>
      <FormGroup>
        <Label for={`${fieldNamePrefix}PhoneExt`}>Ext.</Label>
        <Field name={`${fieldNamePrefix}PhoneExt`} id={`${fieldNamePrefix}PhoneExt`} component={ValidatingField} type={'text'} />
      </FormGroup>
      <div className={'d-flex justify-content-between'}>
        <Button color={'danger'} size={'sm'} onClick={onClick}>Delete</Button>
      </div>
    </CardBody>
  </Card>
}

ContactConditionCard.propTypes = {
  idx        : number,
  removeFunc : func,
}

ContactConditionCard.defaultProps = {
  idx        : -1,
  removeFunc : () => {},
}

export default ContactConditionCard