// Libraries
import { put, select, call, fork, takeLatest, } from 'redux-saga/effects'

// Sagas
import { getLookupData, doFetch, showError, extractPayload, } from './ApiSagas'
import { upsertLocalModel, upsertLocalModels, replaceAll, } from './OrmSagas'
import { getBurnPermit, } from './BurnPermitSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import BurnPermitFuelsActions, { BurnPermitFuelsTypes, } from '../redux/BurnPermitFuelsRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'

// Models
import BurnPermitFuel from '../models/BurnPermitFuel'

// Selectors
import { networkStateSelector, getBurnPermitId, } from '../selectors/selectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'

// Constants
const BURN_PERMIT_FUEL_MODEL_NAME = BurnPermitFuel.modelName
const BURN_PERMIT_FUEL_ENDPOINT = BurnPermitFuel.endpoint()

function* getFuelsLookupData () {
  // The getLookupData saga will update the ORM for us
  // so we don't need to worry about the response
  yield fork(getLookupData, { modelName: 'BurnIgnitionType', })
  yield fork(getLookupData, { modelName: 'DuffType', })
  yield fork(getLookupData, { modelName: 'FuelDiameter', })
}

/**
 * Get the Burn Permit Fuels information for the provided Burn Permit Fuel ID.
 * Will also request the lookup data to map the statuses to for the controls
 * @param {number} burnPermitId
 */
export function* getBurnPermitFuelsInfo ({ burnPermitFuelId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (!burnPermitFuelId) {
      yield call(showError, 'You must provide a Burn Permit Fuel ID in order to retrieve Fuel Information.')
      return
    }

    yield fork(getFuelsLookupData)

    // Creates the Burn Permit Fuels Urls
    const permitFuelsUrl = `${BURN_PERMIT_FUEL_ENDPOINT}/${burnPermitFuelId}`
    
    const permitFuelsResp = yield call(doFetch, permitFuelsUrl)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitFuelsResp.ok === false) {
      yield call(showError, `An error occurred fetching the Burn Permit Fuels Information for ID: ${burnPermitFuelId}.`)
      return
    }

    permitFuelsResp.responseBody.IsLocal = false
    yield call(upsertFuelsData, permitFuelsResp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* upsertFuelsData (fuels) {
  if (Array.isArray(fuels.BurnPermitFuelLoadingXref)) {
    const xrefs = [ ...fuels.BurnPermitFuelLoadingXref, ]
    yield call(replaceAll, 'BurnPermitFuelLoadingXref', xrefs, { BurnPermitFuelId: fuels.BurnPermitFuelId, })
    delete fuels.BurnPermitFuelLoadingXref
  } else {
    yield call(replaceAll, 'BurnPermitFuelLoadingXref', [], { BurnPermitFuelId: fuels.BurnPermitFuelId, })
  }
  yield call(upsertLocalModel, BURN_PERMIT_FUEL_MODEL_NAME, fuels)
}

export function* downloadBurnPermitFuelsInfo () {
  try {
    const permitFuelsResp = yield call(doFetch, `${BURN_PERMIT_FUEL_ENDPOINT}/Download`)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitFuelsResp.ok === false) {
      yield call(showError, 'An error occurred downloading Burn Permit Fuels Information')
      return
    }

    const {
      fuels,
      fuelLoadings,
      ignitionTypes,
    } = permitFuelsResp.responseBody

    for (let i = 0, len = fuels.length; i < len; i++) {
      const f = fuels[i]
      const { BurnPermitFuelId, } = f
      f.IsLocal = false
      f.BurnIgnitionTypes = ignitionTypes.filter(x => x.BurnPermitFuelId === BurnPermitFuelId).map(x => x.BurnIgnitionTypeId)
    }
    yield call(replaceAll, 'BurnPermitFuelLoadingXref', fuelLoadings)
    yield call(upsertLocalModels, BURN_PERMIT_FUEL_MODEL_NAME, fuels)
    
    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'FuelsInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* updateBurnPermitFuels ({ burnPermitFuel, }) {
  const { online, } = yield select(networkStateSelector)
  const { BurnPermitFuelId, } = burnPermitFuel
  const localBurnPermitFuel = yield select(modelByIdSelector, { modelName: BURN_PERMIT_FUEL_MODEL_NAME, modelId: BurnPermitFuelId, })
  if (!localBurnPermitFuel.IsLocal) {
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitFuelsTypes.UPDATE_BURN_PERMIT_FUELS_REQUEST,
        url         : `${BURN_PERMIT_FUEL_ENDPOINT}/${BurnPermitFuelId}`,
        method      : 'PUT',
        keyName     : BurnPermitFuel.options.idAttribute,
        keyValue    : BurnPermitFuelId,
      })
    }
    const calcFee = true
    yield put(BurnPermitFuelsActions.updateBurnPermitFuelsRequest(burnPermitFuel, online, calcFee))
  }
  
  if (!online) {
    const model = { ...burnPermitFuel, Submit: true, }
    yield call(upsertFuelsData, model)
  }
}

function* updateBurnPermitFuelsSuccess (resp) {
  try {
    const burnPermitFuel = yield call(extractPayload, resp)
    yield call(upsertFuelsData, burnPermitFuel)
    const { showSuccess, calcFee, } = resp
    if (showSuccess) {
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
    if (calcFee) {
      // call get burn permit so to tonnage and fee update
      const burnPermitId = yield select(getBurnPermitId, { BurnPermitFuelId: burnPermitFuel.BurnPermitFuelId, })
      yield call(getBurnPermit, { burnPermitId, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* submitOfflineFuelsEdits (localId, serverId) {
  try {
  // Get the local fuel model
    const burnPermitFuel = yield select(modelByIdSelector, { modelName: BURN_PERMIT_FUEL_MODEL_NAME, modelId: localId, })
    if (burnPermitFuel.Submit) {
    // Get the ref data
      const permitFuelRef = { ...burnPermitFuel._fields, }
      permitFuelRef.BurnPermitFuelId = serverId
      permitFuelRef.BurnIgnitionTypes = burnPermitFuel.BurnIgnitionTypes.toRefArray().map(t => t.BurnIgnitionTypeId)
      permitFuelRef.BurnPermitFuelLoadingXref = burnPermitFuel.BurnPermitFuelLoadingXrefs.toRefArray()
      // Update it
      const url = `${BURN_PERMIT_FUEL_ENDPOINT}/${serverId}`
      const fuelResp = yield call(doFetch, url, { method: 'PUT', body: permitFuelRef, })
      if (fuelResp.ok) {
        yield fork(updateBurnPermitFuelsSuccess, { payload: fuelResp.responseBody, modelName: BURN_PERMIT_FUEL_MODEL_NAME, })
        return
      }
      yield call(showError, fuelResp.responseBody)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}


export const BurnPermitFuelsSagas = [
  takeLatest(BurnPermitFuelsTypes.GET_BURN_PERMIT_FUELS_INFO, getBurnPermitFuelsInfo),
  takeLatest(BurnPermitFuelsTypes.UPDATE_BURN_PERMIT_FUELS, updateBurnPermitFuels),
  takeLatest(BurnPermitFuelsTypes.FUELS_REQUEST_SUCCESS, updateBurnPermitFuelsSuccess),
]