import { createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getAllRegions : [ 'regionsOnly', ],
  getRegion     : [ 'regionId', ],
})

export const RegionTypes = Types
export default Creators