import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitOrderXref extends Model {
  static get modelName () {
    return 'BurnPermitOrderXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitOrderXrefId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitOrderXrefId : attr(),
      BurnPermitId          : fk({ to: 'BurnPermit', relatedName: 'BurnPermitOrderXrefs', as: 'BurnPermit', }),
      BurnPermitOrderId     : fk({ to: 'BurnPermitOrder', relatedName: 'BurnPermitOrderXrefs', as: 'BurnPermitOrder', }),
  
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}