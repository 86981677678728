import React from 'react'
import { Button, } from 'reactstrap'
import { bool, } from 'prop-types'

const Submit = ({ mustSignPermit, }) => {
  return {
    label   : 'Submit',
    caption : <>
      <p>
        Once you&apos;ve completed all the sections, click the <Button size={'sm'} className={'readonly'} color={'secondary'}>Submit</Button>&nbsp;
        button in the <b>Signatures</b> section to submit the application to DNR {mustSignPermit ? 'for review' : 'and have your Permit Issued'}.
      </p>
    </>,
    icon  : 'fas fa-file-signature',
    color : 'secondary',
  }
}

Submit.propTypes = {
  mustSignPermit: bool,
}

export default Submit