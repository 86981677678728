import React, { useState, useCallback, } from 'react'
import { arrayOf, element, object, string, oneOfType, } from 'prop-types'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'


const NavbarDropdown = props => {
  const { children, menuStyle, title, text, } = props
  // State and refs
  const [ dropdownOpen, setDropdownOpen, ] = useState(false)

  // UI Callbacks
  const toggleDropdown = useCallback(() => {
    setDropdownOpen(!dropdownOpen)
  }, [ setDropdownOpen, dropdownOpen, ])

  const markup = <Dropdown nav toggle={toggleDropdown} isOpen={dropdownOpen}>
    <DropdownToggle
      nav
      caret
      onClick={toggleDropdown}
      data-toggle={'dropdown'}
      aria-expanded={dropdownOpen}
      aria-label={title}
      title={title}
    >
      {text}
    </DropdownToggle>
    <DropdownMenu onClick={toggleDropdown} style={menuStyle}>
      {children}
    </DropdownMenu>
  </Dropdown>
  return markup
}

NavbarDropdown.propTypes = {
  children  : oneOfType([ string, element, arrayOf(element), ]),
  menuStyle : object,
  text      : oneOfType([ string, arrayOf(element), ]),
  title     : string,
}

export default React.memo(NavbarDropdown)