import { createReducer, createActions, } from 'reduxsauce'
    
/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  goToInstructionStep       : [ 'step', 'burnPermitId', 'completedSteps', ],
  acknowledgedInstructions  : null,
  completedInstructionSteps : [ 'burnPermitId', 'steps', ],
})
  
export const BurnPermitInstructionsTypes = Types
export default Creators
  
/* ------------- Initial State ------------- */
  
export const INITIAL_STATE = {
  instructionStep          : 'Apply',
  instructionsAcknowledged : false,
  completedSteps           : {},
}
    
/* ------------- Reducers ------------- */
const goToInstructionStep = (state, { step, burnPermitId, completedSteps, }) => {
  const newState = { ...state, }
  newState.instructionStep = step || 'Apply'
  if (parseInt(burnPermitId) > 0 && Array.isArray(completedSteps)) {
    if (('completedSteps' in newState) === false) {
      newState.completedSteps = {}
    }
    newState.completedSteps[burnPermitId] = [ ...new Set([ ...completedSteps, ]), ]
  }
  return newState
}

const acknowledgedInstructions = (state) => {
  const newState = { ...state, }
  newState.instructionsAcknowledged = true 
  return newState
}

const completedInstructionSteps = (state, { burnPermitId, steps, }) => {
  const newState = { ...state, }
  if (('completedSteps' in newState) === false) {
    newState.completedSteps = {}
  }
  const completedSteps = 'completedSteps' in newState ? newState.completedSteps[burnPermitId] || []: []
  newState.completedSteps[burnPermitId] = [ ...new Set([ ...completedSteps, ...steps, ]), ]
  return newState
}
  
/* ------------- Hookup Reducers To Types ------------- */
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.COMPLETED_INSTRUCTION_STEPS] : completedInstructionSteps,
  [Types.GO_TO_INSTRUCTION_STEP]      : goToInstructionStep,
  [Types.ACKNOWLEDGED_INSTRUCTIONS]   : acknowledgedInstructions,
})