import { attr, Model, many, } from 'redux-orm'
import * as Yup from 'yup'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class ForestHealthExempt extends Model {
  static get modelName () {
    return 'ForestHealthExempt'
  }

  toString () {
    return `Forest Health Exempt ${this.ForestHealthExemptId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}ForestHealthExemptions`
  }

  static get options () {
    return {
      idAttribute: 'ForestHealthExemptId',
    }
  }

  static getValidationSchema () {
    return Yup.object().shape({
      ForestHealthExemptId : Yup.number(),
      ProblemTypeId        : Yup.number().required('You must select a Problem Type'),
      AlternativeToRxFire  : Yup.string().required('You must describe the forest health situation and the objectives to be achieved with burning'),
    })
  }

  static get fields () {
    return {
      // Common Properties
      ForestHealthExemptId              : attr(),
      CreateDate                        : attr(),
      CreateBy                          : attr(),
      UpdateDate                        : attr(),
      UpdateBy                          : attr(),
      // Model-Specific Properties
      AlternativeToRxFire               : attr(),
      AlternativeNotAppropriateReason   : attr(),
      ForestHealthExemptionApprovalFlag : attr(),
      ForestHealthExemptionApprovalDate : attr(),

      // Relationships
      ForestHealthProblemTypes: many({ to: 'ForestHealthProblemType', as: 'ForestHealthProblemTypes', through: 'ForestHealthExemptProblemTypeXref', }),
    }
  }
}