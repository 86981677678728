import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitDocument extends Model {
  static get modelName () {
    return 'BurnPermitDocument'
  }

  static endpoint () {
    return 'BurnPermitDocuments'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitDocumentId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitDocumentId     : attr(),
      BurnPermitId             : fk({ to: 'BurnPermit', relatedName: 'BurnPermitDocument', as: 'BurnPermit', }),
      DocumentName             : attr(),
      BurnPermitDocumentTypeId : fk({ to: 'BurnPermitDocumentType', relatedName: 'BurnPermit', as: 'BurnPermitDocumentType', }),
      CreateBy                 : attr(),
      CreateDate               : attr(),
      UpdateBy                 : attr(),
      UpdateDate               : attr(),
    }
  }
}