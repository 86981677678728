import { attr, Model, fk, many, } from 'redux-orm'
import * as Yup from 'yup'
import { getTodaysDate, } from '../utilities'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnPermitSite extends Model {
  static get modelName () {
    return 'BurnPermitSite'
  }

  toString () {
    return `Burn Permit Site ${this.BurnPermitSiteId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermitSites`
  }

  static get validationSchema () {
    return Yup.object().shape({
      HarvestDate: Yup.date()
        .max(getTodaysDate(), 'You cannot enter a future Harvest Date.'),
      BurnUnitName       : Yup.string().required('You must enter a name for this Burn Unit.'),
      NumberPeopleOnsite : Yup.number()
        .min(1, 'You must enter the number of people on site.')
        .required('You must enter the number of people on site.'),
      ForestTypeId: Yup.number()
        .min(1, 'You must select a Forest Type for your area.')
        .required('You must select a Forest Type for your area.'),
      BurnReasonId: Yup.number()
        .typeError('You must select a Burn Reason.')
        .min(1, 'You must select a Burn Reason.')
        .required('You must select a Burn Reason.'),
      PlannedBurnDays: Yup.array()
        .min(1, 'You must select at least one day of the week you plan to burn.')
        .required('You must select at least one day of the week you plan to burn.'),
      ClosedSeasonBurn: Yup.bool()
        .required('You must indicate whether or not you plan to burn during the closed season.'),
      BurnPermitSiteEquipment: Yup.array()
        .of(Yup.object().shape({
          OnsiteFlag                  : Yup.bool(),
          OffsiteFlag                 : Yup.bool(),
          FireLineConstructionFlag    : Yup.bool(),
          ExtinguishingFlag           : Yup.bool(),
          OffsiteEquipmentArrivalTime : Yup.string(),
          NumberOfGallons             : Yup.number(),
          OtherEquipmentDescription   : Yup.string(),
          EquipmentTypeId             : Yup.number(),
        })),
      NumberOfHandPilesToBurnAtOnce: Yup.number()
        .min(1, 'The minimum number of Hand piles to burn must be 1 or greater.'),
      NumberOfMachinePilesToBurnAtOnce: Yup.number()
        .min(1, 'The minimum number of Machine piles to burn must be 1 or greater.'),
    })
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitSiteId',
    }
  }

  static get fields () {
    return {
      // Common fields
      BurnPermitSiteId : attr(),
      CreateBy         : attr(),
      CreateDate       : attr(),
      UpdateDate       : attr(),
      UpdateBy         : attr(),
  
      // Relationships
      ForestTypeId            : fk({ to: 'ForestType', relatedName: 'BurnPermitSites', as: 'ForestType', }),
      BurnPermitSiteEquipment : many({ to: 'BurnPermitSiteEquipmentXref', relatedName: 'BurnPermitSites', }),
      BurnReasonId            : fk({ to: 'BurnReason', relatedName: 'BurnPermitSites', as: 'BurnReason', }),
      DaysOfWeek              : many({ to: 'DaysOfWeek', relatedName: 'BurnPermitSites', }),
  
      // Attributes
      BurnUnitName                     : attr(),
      ForestPracticePermitNumber       : attr(),
      ClosedSeasonBurn                 : attr(),
      NumberHandPilesPerBurn           : attr(),
      NumberMachinePilesPerBurn        : attr(),
      TotalNumberHandPiles             : attr(),
      TotalNumberMachinePiles          : attr(),
      NumberPeopleOnsite               : attr(),
      BarkBeetleOutbreakPreventionFlag : attr(),
      WaitForWetConditionsFlag         : attr(),
      HarvestDate                      : attr(),
      SnowOffDate                      : attr(),
      HandConstructedPileWidthFeet     : attr(),
      MachineConstructedPileWidthFeet  : attr(),
      HandConstructedPileHeightFeet    : attr(),
      MachineConstructedPileHeightFeet : attr(),
      NumberOfHandPilesToBurnAtOnce    : attr(),
      NumberOfMachinePilesToBurnAtOnce : attr(),
    }
  }
}