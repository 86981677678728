// Libraries
import React, { useEffect, } from 'react'
import { Container, Row,Col, } from 'reactstrap'
import { array, func, bool, } from 'prop-types'
import { useDispatch, useSelector, } from 'react-redux'

// Reducers
import AppActions from '../redux/AppRedux'
import RequestSearchActions from '../redux/BurnRequestSearchRedux'

// Selectors
import { burnRequestsSelector, burnRequestsOfflineSelector, } from '../selectors/burnRequestSelectors'

// Components
import BurnRequestTable from '../components/Search/BurnRequestTable'
import BurnRequestSearchForm from '../components/Search/BurnRequestSearchForm'
import ExportRequests from '../components/ExportRequests'

// Hooks
import { useOnlineSelector, } from '../hooks'

const LARGE_RESULT_CLEAR_MSG = 'Your search results were cleared because your search returned over '
 + '1,000 records which degrades the performance of the application. Please click the Search '
 + 'button to execute your search again.'

const BurnRequestSearchContainer = ({
  isAuthenticated,
  isAdmin,
}) => {
  const reduxDispatch = useDispatch()
  const showMap = useSelector((state) => {
    if (state && state.BurnRequestSearch) {
      return state.BurnRequestSearch.showMap
    }
    return false
  })
  const online = useOnlineSelector()
  const burnRequests = useSelector((state) => {
    if (online) {
      return burnRequestsSelector(state)
    } else {
      burnRequestsOfflineSelector(state)
    }
  })


  useEffect(() => {
    reduxDispatch(AppActions.setPageTitle('Burn Request Search'))
    reduxDispatch(RequestSearchActions.setDownloadingRequestsExport(false))
    return () => {
      if (online && burnRequests && burnRequests.length > 1000) {
        reduxDispatch(RequestSearchActions.resetRequestResults(LARGE_RESULT_CLEAR_MSG))
      } 
      reduxDispatch(RequestSearchActions.setDownloadingRequestsExport(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className={'mt-2 mb-4 burn-request-search-container'}>
      <div className={'d-flex justify-content-between mb-2'}>
        <h1>Search Burn Requests</h1>
        { isAdmin && <ExportRequests /> }
      </div>
      <Row className={'mb-3'}>
        <Col>
          <BurnRequestSearchForm isAuthenticated={isAuthenticated}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <BurnRequestTable 
            requests={burnRequests}
            showMap={showMap}
            showCardHeader={false}
            isAuthenticated={isAuthenticated}
            layerConfig={'Request Search'}
          />
        </Col>
      </Row>
    </Container>
  )
}

BurnRequestSearchContainer.propTypes = {
  SetPageTitle       : func,
  BurnRequests       : array,
  ShowMap            : bool,
  isAuthenticated    : bool,
  online             : bool,
  isAdmin            : bool,
  ClearSearchResults : func,
}

export default BurnRequestSearchContainer
