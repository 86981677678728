// Libraries
import React, { useMemo, } from 'react'
import { Row, Col, Label, Input,  } from 'reactstrap'
import { string, func, bool, number, object, oneOfType, } from 'prop-types'
import PopoverButton from '../FormControls/PopoverButton'

const SearchInput = props => {
  const {
    label,
    labelClassName,
    name,
    onChange,
    onBlur,
    value,
    defaultValue,
    placeholder,
    showPopover,
    popoverHeader,
    popoverBody,
  } = props

  const colSize = useMemo(() => (showPopover ? 9 : 12), [ showPopover, ])
  const labelFor = useMemo(() => (`txt-search-${name}`), [ name, ])
  const popover = useMemo(() => {
    if (!showPopover) {
      return null
    }
    return <Col xs={3}>
      <PopoverButton
        buttonClassName={'btn-icon px-0'}
        popoverHeader={popoverHeader}
        popoverBody={popoverBody}
      />
    </Col>
  }, [ popoverHeader, popoverBody, showPopover, ])

  const input = useMemo(() => {
    const inputProps = {
      id          : labelFor,
      name        : name,
      onChange    : onChange,
      onBlur      : onBlur,
      placeholder : placeholder,
    }
    if (value) {
      inputProps.value = value || ''
    }
    if (defaultValue) {
      inputProps.defaultValue = defaultValue || ''
    }

    return <Input {...inputProps} />
  }, [ value, defaultValue, labelFor, placeholder, name, onChange, onBlur, ])

  return <>
    <Row>
      <Col xs={colSize}>
        <Label
          for={labelFor}
          className={labelClassName}
        >{label}</Label>
      </Col>
      {popover}
    </Row>
    {input}
  </>
}

SearchInput.propTypes = {
  name           : string.isRequired,
  label          : string.isRequired,
  onChange       : func.isRequired,
  onBlur         : func,
  labelClassName : string,
  value          : string,
  defaultValue   : oneOfType([ number, string, ]),
  placeholder    : string,
  showPopover    : bool,
  popoverHeader  : string,
  popoverBody    : oneOfType([ object, string, ]),
}

SearchInput.defaultProps = {
  showPopover: false,
}

export default React.memo(SearchInput)
