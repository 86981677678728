import React from 'react'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody, } from 'reactstrap'
import { string, object, PropTypes, func, bool, } from 'prop-types'

function toggleOpenClass (e) {
  if (e.target.nodeName.toLowerCase() === 'button') {
    e.target.firstElementChild.classList.toggle('open')
  }
  else {
    e.target.classList.toggle('open')
  }
}

const PopoverButton = props => {
  const {
    color,
    buttonClassName,
    iconClassName,
    popoverClassName,
    placement,
    popoverHeader,
    popoverBody,
    disabled,
    style,
    label,
  } = props
  const id = `po-${popoverHeader.split(' ').join('')}`
  const iconClass = `info-button fa ${iconClassName}`
  const className = `po ${buttonClassName}`
  return <>
    <Button 
      color={color}
      id={id}
      className={className}
      title={popoverHeader}
      aria-label={popoverHeader}
      disabled={disabled}
      onClick={toggleOpenClass}
      style={style}
    >
      {`${label ? `${label} `: ''}`}<span className={iconClass} alt={'info'}></span>
    </Button>
    <UncontrolledPopover 
      placement={placement}
      target={id}
      className={popoverClassName}
    >
      <PopoverHeader>{popoverHeader}</PopoverHeader>
      <PopoverBody>{popoverBody}</PopoverBody>
    </UncontrolledPopover>
  </>
}

PopoverButton.propTypes = {
  color            : string,
  buttonClassName  : string,
  popoverClassName : string,
  iconClassName    : string,
  placement        : string,
  popoverHeader    : string.isRequired,
  popoverBody      : PropTypes.oneOfType([ string, object, ]),
  onClick          : func,
  disabled         : bool,
  style            : object,
  label            : string,
}

PopoverButton.defaultProps = {
  color            : 'link',
  placement        : 'top',
  buttonClassName  : '',
  iconClassName    : 'fa-info',
  disabled         : false,
  popoverClassName : '',
}

export default PopoverButton