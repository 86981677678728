// Libraries
import React, { useEffect, useState, useCallback, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import { bool, } from 'prop-types'
import { Row, Col, Container, } from 'reactstrap'

// Actions
import BurnPermitInstructionsActions from '../../../redux/BurnPermitInstructionsRedux'
import BurnPermitFormActions from '../../../redux/BurnPermitFormRedux'
import AppActions from '../../../redux/AppRedux'

// Components
import PermitFailedToLoad from '../PermitFailedToLoad'
import AgentUnverified from '../../AgentUnverified'
import ProfileStatus from '../../Profile/ProfileStatus'
import HowToApply from './HowToApply'
import DoYouNeedABurnPermit from './DoYouNeedABurnPermit'
import ApplicationMethods from './ApplicationMethods'
import LoadingApplication from './LoadingApplication'

// Selectors
import { profileStatusSelector, } from '../../../selectors/userSelectors'
import { userAcknowledgedInstructions, }  from '../../../selectors/instructionsSelectors'


const CreateBurnPermit = props => {
  const {
    isDnr,
    isAuthenticated,
    isUnverifiedAgent,
  } = props

  // State
  const [ answeredPropertyQuestion, setIsUnimprovedProperty, ] = useState(false)
  const [ showApplicationMethods, setShowApplicationMethods, ] = useState(false)
  const [ showInstructions, setShowInstructions, ] = useState(false)
  const [ isCreatingApplication, setCreateApplication, ] = useState(false)

  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const instructionsAcknowledged = useSelector(userAcknowledgedInstructions)
  const profileStatus = useSelector(profileStatusSelector)

  // OnMount
  useEffect(() => {
    dispatch(BurnPermitInstructionsActions.goToInstructionStep('Apply'))
    if (isDnr) {
      setIsUnimprovedProperty(true)
      setCreateApplication(true)
    }
    // Clear the active Burn Permit
    dispatch(BurnPermitFormActions.activeBurnPermit(''))
    dispatch(AppActions.setPageTitle('Apply for Burn Permit'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // DidUpdate
  useEffect(() => {
    if (instructionsAcknowledged) {
      setIsUnimprovedProperty(true)
      setCreateApplication(true)
    }
  }, [ instructionsAcknowledged, ])

  // UI Callbacks
  const isUnimprovedProperty = useCallback(() => {
    setIsUnimprovedProperty(true)
    if (instructionsAcknowledged || isDnr) {
      setCreateApplication(true)
      return
    }
    setShowApplicationMethods(true)
  }, [ instructionsAcknowledged, isDnr, ])

  const applyOnline = useCallback(() => {
    if (isAuthenticated !== true) {
      navigate('/login')
      return
    }
    setShowApplicationMethods(false)
    setShowInstructions(true)
  }, [ navigate, isAuthenticated, ])

  const understand = useCallback(() => {
    setShowInstructions(false)
    setCreateApplication(true)
  }, [])

  // Memoed values
  const profileStatusContainerSize = useMemo(() => ({ size: 8, offset: 2, }), [])

  // This is the general order to check for ability to apply
  // as well as the flow through the application screening and instructions
  if (profileStatus.IsComplete === false) {
    return <Container className={'pt-2'}>
      <Row>
        <Col md={profileStatusContainerSize}>
          <ProfileStatus />
        </Col>
      </Row>
    </Container>
  }
  if (isUnverifiedAgent) {
    return <AgentUnverified />
  }
  if (answeredPropertyQuestion === false) {
    return <DoYouNeedABurnPermit yesCallback={isUnimprovedProperty} />
  }
  if (showApplicationMethods) {
    return <ApplicationMethods applyOnlineCallback={applyOnline} />
  }
  if (showInstructions) {
    return <HowToApply understandCallback={understand} />
  }
  if (isCreatingApplication) {
    return <LoadingApplication />
  }

  return <PermitFailedToLoad />
}

CreateBurnPermit.propTypes = {
  isDnr             : bool,
  isAuthenticated   : bool,
  isUnverifiedAgent : bool,
}

CreateBurnPermit.defaultProps = {
  isDnr             : false,
  isAuthenticated   : false,
  isUnverifiedAgent : false,
}

export default React.memo(CreateBurnPermit)