import React from 'react'
import { Alert, } from 'reactstrap'

const NeedsPileGroups = () => {
  return <Alert color={'danger'}>
    <p>
      You must add one or more valid Pile Groups or change your Burn Type in the Area Info section.
    </p>
  </Alert>
}

export default NeedsPileGroups