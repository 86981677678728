import React from 'react'
import { Alert, } from 'reactstrap'

const AreaIsExempt = () => {
  return <Alert color={'warning'}>
    <p>
      Pile Burn Areas under ten acres do not require pile groups.
    </p>
  </Alert>
}

export default AreaIsExempt