import { createSelector, } from 'reselect'
import { activeBurnPermitIdSelector, } from './burnPermitSelectors'
import { ormSelector, ormWithPropSelector, ormByIdSelector, } from './selectors'


const orderStateSelector = state => state.BurnPermitOrder || {}

export const newBurnPermitOrderId = createSelector(
  orderStateSelector,
  orderState => orderState.newBurnPermitOrderId
)

const mapBurnPermitOrderXref = (xref) => {
  if (!xref) {
    return null
  }
  return {
    BurnPermitOrderId     : xref.BurnPermitOrderId || '',
    BurnPermitId          : xref.BurnPermitId || '',
    BurnPermitOrderXrefId : xref.BurnPermitOrderXrefId || '',
    RegionName            : xref.RegionName || '',
    BurnPermitNumber      : xref.BurnPermitNumber || '',
    CreateBy              : xref.CreateBy || '',
    CreateDate            : xref.CreateDate || '',
    UpdateBy              : xref.UpdateBy || '',
    UpdateDate            : xref.UpdateDate || '',
    Tonnage               : xref.BurnPermit ? xref.BurnPermit.TotalPermitTonnage : 0,
  }
}

const mapApplicationDetail = (appDetail) => {
  if (!appDetail) {
    return null
  }
  return {
    CheckNumber        : appDetail.CheckNumber || '',
    FirstName          : appDetail.FirstName || '',
    LastName           : appDetail.LastName || '',
    PaymentAmount      : appDetail.PaymentAmount || '',
    PaymentCommandCode : appDetail.PaymentCommandCode || '',
    PaymentMethod      : appDetail.PaymentMethod || '',
    TransactionDate    : appDetail.TransactionDate || '',
    VoucherNumber      : appDetail.VoucherNumber || '',
    Comment            : appDetail.Comment || '',
  }
}

const mapOrderStrings = (xrefs) => {
  let regionString = '', permitNumberString = ''
  if (xrefs && xrefs.exists()){
    if (xrefs.count() === 1) {
      const { BurnPermitNumber, RegionName, } = xrefs.first()
      return { permitNumberString: BurnPermitNumber || '', regionString: RegionName || '', } 
    }
    xrefs.toRefArray().forEach(({ RegionName, BurnPermitNumber, }) => {
      if (RegionName && RegionName.length > 0 && RegionName !== '') {
        if (regionString === '') {
          regionString = RegionName
        }
        // Only display distinct Region Names
        else if (regionString.indexOf(RegionName) < 0) {
          regionString = regionString + ', ' + RegionName
        }
      }
  
      if (BurnPermitNumber && BurnPermitNumber.length > 0 && BurnPermitNumber !== '') {
        if (permitNumberString === '') {
          permitNumberString = BurnPermitNumber
        } else {
          permitNumberString = permitNumberString + ', ' + BurnPermitNumber
        }
      }
    })
  }
  return { regionString, permitNumberString, }
}

const mapOrder = (order) => {
  if (!order) {
    return null
  }
  let xrefs = [], orderStrings = { regionString: '', permitNumberString: '', }
  if (order.BurnPermitOrderXrefs && order.BurnPermitOrderXrefs.exists()) {
    xrefs = order.BurnPermitOrderXrefs.toModelArray().map(mapBurnPermitOrderXref)
    orderStrings = mapOrderStrings(order.BurnPermitOrderXrefs)
  }
  return {
    ApplicationDetail        : mapApplicationDetail(order.ApplicationDetail),
    AuthorizationCode        : order.AuthorizationCode || '',
    BurnPermitOrderAmount    : order.BurnPermitOrderAmount || '',
    BurnPermitOrderId        : order.BurnPermitOrderId || '',
    BurnPermitOrderXref      : xrefs,
    BurnPermitRegions        : orderStrings.regionString,
    BurnPermitNumbers        : orderStrings.permitNumberString,
    CardType                 : order.CardType || '',
    ConfirmationNumber       : order.ConfirmationNumber || '',
    CreateBy                 : order.CreateBy || '',
    CreateDate               : order.CreateDate || '',
    EPayResultMessage        : order.EPayResultMessage || '',
    EPayReturnCode           : order.EPayReturnCode || '',
    PaymentHash              : order.PaymentHash || '',
    SettlementSubmissionDate : order.SettlementSubmissionDate || '',
    TotalAmountPaid          : order.TotalAmountPaid || '',
    AllowPayment             : order.AllowPayment,
    UpdateBy                 : order.UpdateBy || '',
    UpdateDate               : order.UpdateDate || '',
    TotalTonnage             : xrefs.map(x => x.Tonnage).reduce((a, b) => a + b, 0),
    BurnPermitOrderVoucher   : order.BurnPermitOrderVoucher,
  }
}


export const ordersSelector = ormSelector(
  ({ BurnPermitOrder, }) => {
    return BurnPermitOrder.all()
      .orderBy('CreateDate', 'desc')
      .toModelArray()
      .map(mapOrder)
  }
)

export const orderHasPaymentSelector = ormByIdSelector(
  ({ BurnPermitOrder, }, orderId) => {
    if (isNaN(orderId)) {
      return false
    }
    const order = BurnPermitOrder.withId(orderId)
    if (!order) {
      return false
    }
    return !!order.PaymentHash
  }
)

export const orderByIdSelector = ormByIdSelector(
  ({ BurnPermitOrder, }, orderId) => {
    if (isNaN(orderId)) {
      return null
    }
    const order = BurnPermitOrder.withId(orderId)
    if (!order) {
      return null
    }
    return mapOrder(order)
  }
)

export const orderByPermitIdsSelector = ormWithPropSelector(
  ({ BurnPermitOrder, }, BurnPermitIds) => {
    const order = BurnPermitOrder.all()
      .toModelArray()
      .filter(o => o.BurnPermitOrderXref
        .filter(x => (BurnPermitIds || []).includes(x.BurnPermitId)).count() > 0
      )[0]
    return mapOrder(order)
  }
)

export const orderIdForActivePermitSelector = ormSelector(
  activeBurnPermitIdSelector,
  ({ BurnPermitOrderXref, }, BurnPermitId) => {
    const order = BurnPermitOrderXref.all()
      .toModelArray()
      .filter(x => x.BurnPermitId === BurnPermitId)[0]
    if (order) {
      return order.BurnPermitOrderId
    }
    return null
  }
)

export const permitsByOrderId = ormByIdSelector(
  ({ BurnPermit, BurnPermitOrder, }, BurnPermitOrderId) => {
    if (isNaN(BurnPermitOrderId)) {
      return []
    }
    const order = BurnPermitOrder.withId(BurnPermitOrderId)
    if (!order || order.BurnPermits === null) {
      return []
    }

    const permitIds = order.BurnPermitOrderXref.toRefArray().map(x => x.BurnPermitId)
    const permits = BurnPermit
      .filter(p => permitIds.includes(p.BurnPermitId))
      .toRefArray()
    return permits
  }
)


export const orderHasIssuedPermit = ormByIdSelector(
  ({ BurnPermitOrder, BurnPermitStatus, BurnPermitStatusXref, }, BurnPermitOrderId) => {
    if (isNaN(BurnPermitOrderId)) {
      return false
    }
    const order = BurnPermitOrder.withId(BurnPermitOrderId)
    if (!order || order.BurnPermits === null) {
      return false
    }
    if (!BurnPermitStatus.filter({ BurnPermitStatusName: 'Issued', }).exists()) {
      return false
    }

    const permitStatusIds = order.BurnPermitOrderXref.toRefArray().map(x => {
      const permitStatus = BurnPermitStatusXref
        .filter({ BurnPermitId: x.BurnPermitId, })
        .orderBy('CreateDate', 'desc')
        .first()
      if (permitStatus) {
        return permitStatus.BurnPermitStatusId
      }
      return -1
    })
    const issuedStatusId = BurnPermitStatus.filter({ BurnPermitStatusName: 'Issued', }).first().BurnPermitStatusId
    return permitStatusIds.includes(issuedStatusId)
  }
)


export const orderEligibleForVoucherPayment = ormByIdSelector(
  ({ BurnPermit, BurnPermitOrder, }, BurnPermitOrderId) => {
    if (isNaN(BurnPermitOrderId)) {
      return false
    }
    const order = BurnPermitOrder.withId(BurnPermitOrderId)
    if (!order || order.BurnPermits === null) {
      return false
    }

    const permitIds = order.BurnPermitOrderXref.toRefArray().map(x => x.BurnPermitId)
    const permits = BurnPermit
      .filter(p => permitIds.includes(p.BurnPermitId))
      .toModelArray()
    const permitsAreEligible = permits.map(p => {
      return {
        BurnPermitId  : p.BurnPermitId,
        PaysByVoucher : (
          (p.Agent !== null && p.Agent.Agencies.exists() && p.Agent.Agencies.first().PaysByVoucher)
          ||
          (p.Burner !== null && p.Burner.Agencies.exists() && p.Burner.Agencies.first().PaysByVoucher)
        ),
      }
    }, [])
    const allPermitsForVoucherPayingAgencies = permitsAreEligible.every(p => p.PaysByVoucher)
    if (allPermitsForVoucherPayingAgencies) {
      return true
    }
    // If some are eligible, but some are not, return the error message
    else if (permitsAreEligible.some(p => p.PaysByVoucher) && permitsAreEligible.some(p => !p.PaysByVoucher)) {
      return `The following Permits do not belong to an Agency that pays by Voucher: ${permitsAreEligible.filter(p => !p.PaysByVoucher).map(p => p.BurnPermitId, []).join(', ')}`
    }
    // Otherwise, the permit(s) are not eligible
    return null
  }
)