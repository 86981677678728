import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitFuelLoadingXref extends Model {
  static get modelName () {
    return 'BurnPermitFuelLoadingXref'
  }

  toString () {
    return `${this.BurnPermitFuelLoadingXrefName}`
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitFuelLoadingXrefId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitFuelLoadingXrefId : attr(),
      TotalTonsPerAcre            : attr(),
      BurnPermitFuelId            : fk({ to: 'BurnPermitFuel', relatedName: 'BurnPermitFuelLoadingXrefs', as: 'BurnPermitFuel', }),
      FuelDiameterId              : fk({ to: 'FuelDiameter', relatedName: 'BurnPermitFuelLoadingXrefs', as: 'FuelDiameter', }),
  
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}