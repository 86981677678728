// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader, } from 'reactstrap'
import { string, bool, number, PropTypes, } from 'prop-types'

// Components
import Upload from '../../../../Upload'

// Reducers
import UiActions from '../../../../../redux/UiRedux'
import BurnPermitPileGroupActions from '../../../../../redux/BurnPermitPileGroupRedux'
import { isModalOpen, } from '../../../../../selectors/uiSelectors'
import DownloadCSV from '../../../../Icons/DownloadCSV'

const MODAL_KEY = 'BATCH_PILE_UPLOAD'

// eslint-disable-next-line no-undef
const { REACT_APP_BATCH_UPLOAD_TEMPLATE, } = process.env

const BatchUpload = props => {
  const {
    className,
    disabled,
    permitId,
  } = props

  const dispatch = useDispatch()

  const openModal = React.useCallback(() => dispatch(UiActions.openModal(MODAL_KEY)), [ dispatch, ])
  const closeModal = React.useCallback(() => dispatch(UiActions.closeModal()), [ dispatch, ])
  const modalOpen = useSelector(state => isModalOpen(state, MODAL_KEY))
  const toggleModal = useCallback(() => {
    if (modalOpen) {
      closeModal()
    }
    else {
      openModal()
    }
  }, [ openModal, closeModal, modalOpen, ])

  const upload = useCallback((files) => {
    dispatch(BurnPermitPileGroupActions.uploadBatchPiles(permitId, files))
  }, [ dispatch, permitId, ])

  return <div className={className}>
    <Button
      color={'secondary'}
      size={'sm'}
      disabled={disabled}
      onClick={openModal}
    >
      Batch Upload
    </Button>
    <Modal isOpen={modalOpen}>
      <ModalHeader toggle={toggleModal}>
        Batch Upload Pile Groups
      </ModalHeader>
      <ModalBody>
        <p>Use the upload control below to drag and drop a <kbd>.csv</kbd> into or click the area to open a File Explorer to locate your Pile Groups you want to include as part of this application.</p>
        <p>A template can be found here <a
          href={REACT_APP_BATCH_UPLOAD_TEMPLATE}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          pile_calc_batch_template.csv <DownloadCSV />
        </a>. Remove the `HandEx` and `MachineEx` examples when populating the template.</p>
        <Upload
          readOnly={disabled}
          title={''}
          uploadFiles={upload}
          validFileExtensions={'.csv'}
        />
      </ModalBody>
    </Modal>
  </div>
}

BatchUpload.propTypes = {
  className : string,
  disabled  : bool,
  permitId  : PropTypes.oneOfType([ number, string, ]),
}

export default React.memo(BatchUpload)