import './wdyr'

import promiseFinally from 'promise.prototype.finally'
promiseFinally.shim()

import 'dotenv/config'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, } from 'react-redux'
import { PersistGate, } from 'redux-persist/es/integration/react'
import { BrowserRouter as Router, } from 'react-router-dom'

// Service Worker
import registerServiceWorker from './registerServiceWorker'

// Redux
import CreateStore from './redux/CreateStore'
import rootReducer from './redux/index'

// Sagas
import rootSaga from './sagas/index'
// eslint-disable-next-line no-unused-vars
import axe from 'react-axe'

// Components
import App from './App'
import AppLoading from './components/AppLoading'

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  //axe(React, ReactDOM, 1000)
}

const { store, persistor, } = CreateStore(rootReducer, rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<Router><AppLoading /></Router>}
      persistor={persistor}>
      <Router>
        <App persistor={persistor} />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

registerServiceWorker()

export { store, }

export { persistor, }
