// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'
import { object, bool, func, } from 'prop-types'
import { Formik, Field, } from 'formik'
import { Row, Col, FormGroup, Label, } from 'reactstrap'

// Reducers
import ApiActions from '../../redux/ApiRedux'

// Components
import { YesNoRadioButtonGroup, } from '../FormControls'
import AuditData from '../AuditData'

// Models
import Agency from '../../models/Agency'
import ValidatingField from '../FormControls/ValidatingField'

const validationSchema = Agency.getValidationSchema()


export class AgencyForm extends React.Component {
  static propTypes = {
    isDisabled    : bool,
    agency        : object,
    readOnly      : bool,
    createFn      : func,
    updateFn      : func,
    showAuditData : bool,
    isNewAgency   : bool,
    // dispatch
    CreateRecord  : func,
    UpdateRecord  : func,
  }

  static defaultProps = {
    readOnly      : false,
    createFn      : null,
    updateFn      : null,
    showAuditData : false,
    isNewAgency   : false,
  }

  validateForm = () => {
    return this.formik.validateForm()
  }

  submitForm = () => {
    this.formik.submitForm()
  }

  touched = () => {
    if (this.formik && this.formik && this.formik.touched) {
      return Object.values(this.formik.touched).some(t => t === true)
    }
    return true
  }

  submit = (values) => {
    const { isNewAgency, agency, UpdateRecord, CreateRecord, } = this.props
    if (!isNewAgency && agency && agency.AgencyId) {
      values['AgencyId'] = agency.AgencyId
      values['AddressId'] = agency.AddressId
      values['PhoneId'] = agency.PhoneId
      values['EmailId'] = agency.EmailId
      if (!this.props.updateFn) {
        UpdateRecord('Agency', agency.AgencyId, values, 'Agencies')
      } else {
        this.props.updateFn(values)
      }
    } else {
      delete values.AgencyId
      if (!this.props.createFn) {
        CreateRecord('Agency', values, 'Agencies', true)
      } else {
        this.props.createFn(values)
      }
    }
  }
  
  setFormikNode = node => this.formik = node

  render () {
    const { agency, readOnly, showAuditData, } = this.props
    if (!agency) {
      return null
    }
    return (
      <Formik
        initialValues={{ ...agency, }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
      >
        <>
          <Row>
            <Col>
              <FormGroup>
                <Label>Agency Name</Label>
                <Field
                  name={'AgencyName'}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Agency Abbreviation</Label>
                <Field
                  name={'AgencyAbbreviation'}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Agency Sub-Name 1</Label>
                <Field
                  name={'AgencySubName1'}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Agency Sub-Name 2</Label>
                <Field
                  name={'AgencySubName2'}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Agency Other Name</Label>
                <Field
                  name={'AgencyOtherName'}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Field name={'AutoRegionApproval'}>
                  {({ field, form, }) => (
                    <YesNoRadioButtonGroup
                      legend={
                        <>
                          Auto Region Approval
                          <br/>
                          <small>Typically used for Federal Agencies only</small>
                        </>
                      }
                      onChange={evt => {
                        form.setFieldValue(field.name, evt.target.value === 'yes')
                        form.setFieldTouched(field.name, true)
                      }}
                      inputName={field.name}
                      checkedValue={field.value ? 'yes' : 'no'}
                      readOnly={readOnly}
                      errorMessage={form.errors[field.name]}
                      invalid={!!form.errors[field.name]}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Field name={'PaysByVoucher'}>
                  {({ field, form, }) => (
                    <YesNoRadioButtonGroup
                      legend={
                        <>
                          Pays By Voucher
                          <br/>
                          <small>Typically used for State or Federal Agencies only</small>
                        </>
                      }
                      onChange={evt => {
                        form.setFieldValue(field.name, evt.target.value === 'yes')
                        form.setFieldTouched(field.name, true)
                      }}
                      inputName={field.name}
                      checkedValue={field.value ? 'yes' : 'no'}
                      readOnly={readOnly}
                      errorMessage={form.errors[field.name]}
                      invalid={!!form.errors[field.name]}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
          </Row>
          {
            showAuditData === true && !isEmpty(agency) &&
                <Row className={'audit-data p-2'}>
                  <AuditData
                    CreateBy={agency.CreateBy}
                    CreateDate={agency.CreateDate}
                    UpdateBy={agency.UpdateBy}
                    UpdateDate={agency.UpdateDate}
                  />
                </Row>
          }
        </>
      </Formik>
    )
  }
}

const mapDispatchToProps = {
  CreateRecord : ApiActions.createRecord,
  UpdateRecord : ApiActions.updateRecord,
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true, })(AgencyForm)