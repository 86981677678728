// Libraries
import React, { useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { array, } from 'prop-types'

// Components
import ConfirmationModal from '../../../ConfirmationModal'

// Selectors
import { agencyAutoRegionApprovalByPermitId, } from '../../../../selectors/agencySelectors'
import { activeBurnPermitIdSelector, } from '../../../../selectors/burnPermitSelectors'
import { userMustUploadPermitToSubmit, } from '../../../../selectors/burnPermitPermissionSelectors'
import { isGovAgentPermit, } from '../../../../selectors/burnPermitSelectors'
import usePermitFileUpload from '../../../../hooks/usePermitFileUpload'


const UploadPermitWarning = props => {

  const { files, } = props

  // Selectors
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const isGovPermit = useSelector(isGovAgentPermit)
  const mustUploadPermit = useSelector(userMustUploadPermitToSubmit)
  const autoRegionApproval = useSelector(state => agencyAutoRegionApprovalByPermitId(state, burnPermitId))

  // Redux actions
  const dispatch = useDispatch()

  const doUpload = usePermitFileUpload(dispatch, burnPermitId, mustUploadPermit, isGovPermit, files)

  const modalBody = useMemo(() => {
    return <>
      <p>
        This permit lists an Agency configured to have their applications auto-approved and permits auto-issued.
      </p>
      <p>
        Are you sure you want to upload this document now? If you confirm now, the scanned
        application will be uploaded and the Permit will be Issued.
      </p>
    </>
  }, [])

  const renderModal = mustUploadPermit && autoRegionApproval
  if (!renderModal) {
    return null
  }
  return <ConfirmationModal
    modalKey={'CONFIRM_FILE_UPLOAD_SIDE_EFFECTS'}
    modalTitle={'Upload and Issue Permit'}
    modalBody={modalBody}
    submitAction={doUpload}
  />
}

UploadPermitWarning.defaultProps = {
  files: [],
}

UploadPermitWarning.propTypes = {
  files: array,
}

export default React.memo(UploadPermitWarning)