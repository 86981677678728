import React from 'react'
import { Label, Input, Row, Col, } from 'reactstrap'
import { func, string, array, bool, object, PropTypes, number, } from 'prop-types'

const BaseCondition = (props) => {
  const {
    condition,
    selected,
    onConditionSelected,
    readOnly,
    error,
    children,
    label,
    indent,
  } = props
  
  const onChange = React.useCallback((e) => onConditionSelected(e, condition), [ onConditionSelected, condition, ])
  
  if (!condition) {
    return (
      <Row className={'grey-text'}>
        <Col>
          {children || 'No condition provided.'}
        </Col>
      </Row>
    )
  }
  let errorSection = null
  if (Array.isArray(error)) {
    errorSection = error.map((e, idx) => {
      return <Row key={'err-' + idx} className={'condition-error-text'}>
        <Col>Error: {e}</Col>
      </Row>
    })
  } else if (typeof error === 'string') {
    errorSection = (
      <Row className={'condition-error-text'}>
        <Col>Error: {error}</Col>
      </Row>
    )
  }
  const { BurnPermitConditionId, } = condition
  const conditionKey = `pmt-cnd-enable-check-${BurnPermitConditionId}`
  const className = `${BurnPermitConditionId > 9 ? 'mr-2' : 'mr-3'} mb-2`
  return <>
    <Row className={(readOnly && !selected) || !selected ? 'grey-text' : ''} aria-disabled={(readOnly || !selected)}>
      <Col xs={{ size: 1, offset: indent ? 1 : 0, }} className={'permit-condition-chk'}>
        <Label
          for={conditionKey}
          className={className}
          check
        >
          <Input
            type={'checkbox'}
            readOnly={readOnly}
            checked={selected}
            className={'mr-1'}
            disabled={readOnly}
            onChange={onChange}
            name={conditionKey}
            id={conditionKey}
          />
          {label}.
        </Label>
      </Col>
      <Col>
        {children}
      </Col>
    </Row>
    {errorSection}
  </>
}

BaseCondition.propTypes = {
  selected            : bool,
  condition           : object,
  onConditionSelected : func.isRequired,
  children            : PropTypes.oneOfType([ object, array, string, ]),
  error               : PropTypes.oneOfType([ object, array, string, ]),
  readOnly            : bool,
  label               : PropTypes.oneOfType([ string, number, ]),
  indent              : bool,
}

BaseCondition.defaultProps = {
  onConditionSelected : () => {},
  error               : null,
  selected            : false,
  condition           : {},
  readOnly            : false,
  label               : '',
  indent              : false,
}

export default BaseCondition