// Libraries
import { all, call, put, takeLatest, } from 'redux-saga/effects'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { SearchTypes, } from '../redux/SearchRedux'
import  BurnPermitSearchActions, { BurnPermitSearchTypes, } from '../redux/BurnPermitSearchRedux'
import  BurnRequestSearchActions, { BurnRequestSearchTypes, }  from '../redux/BurnRequestSearchRedux'

// Models
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnRequestSearch from '../models/BurnRequestSearch'
import BurnPermit from '../models/BurnPermit'
import BurnRequest from '../models/BurnRequest'

// Sagas
import { showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { destroyAll, replaceAll, } from './OrmSagas'
import { GetAllRegions, } from './RegionSagas'
import { doFetch, } from './ApiSagas'
import { downloadFile, } from './FileSagas'

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const BURN_REQUEST_ENDPOINT = BurnRequest.endpoint()

// Constants
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName
const BURN_REQUEST_SEARCH_MODEL_NAME = BurnRequestSearch.modelName


function* getLookupData () {
  yield all([
    put({ type: ApiTypes.LOOKUP_DATA, modelName: 'County', }),
    call(GetAllRegions),
    put({ type: ApiTypes.LOOKUP_DATA, modelName: 'BurnPermitApplicationStatus', }),
    put({ type: ApiTypes.LOOKUP_DATA, modelName: 'BurnPermitStatus', }),
    put({ type: ApiTypes.LOOKUP_DATA, modelName: 'BurnRequestStatus', }),
    put({ type: ApiTypes.LOOKUP_DATA, modelName: 'BurnType', }),
  ])
}

function* searchBurnPermitsSuccess ({ payload, }) {
  try {
    const results = yield call([ payload, payload.json, ])
    yield call(replaceAll, BURN_PERMIT_SEARCH_MODEL_NAME, results)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* clearSearchPermits () {
  try {
    yield call(destroyAll, BURN_PERMIT_SEARCH_MODEL_NAME)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* clearSearchRequests () {
  try {
    yield call(destroyAll, BURN_REQUEST_SEARCH_MODEL_NAME)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


export function* downloadPermitsExport () {
  try {
    yield call(showLoading)

    const requestUrl = `${BURN_PERMIT_ENDPOINT}/Export`
    const response = yield call(doFetch, requestUrl)

    const { downloadUrl, fileName, } = response.responseBody

    if (downloadUrl && fileName) {
      yield call(downloadFile, { url: downloadUrl, fileName, })
    } else {
      yield call(showError, 'An error occurred requesting the permit export.')
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield all([
      call(hideLoading),
      put(BurnPermitSearchActions.setDownloadingPermitsExport(false)),
    ])
  }
}

export function* downloadRequestsExport () {
  try {
    yield call(showLoading)

    const requestUrl = `${BURN_REQUEST_ENDPOINT}/Export`
    const response = yield call(doFetch, requestUrl)

    const { downloadUrl, fileName, } = response.responseBody

    if (downloadUrl && fileName) {
      yield call(downloadFile, { url: downloadUrl, fileName, })
    } else {
      yield call(showError, 'An error occurred requesting the permit export.')
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield all([
      call(hideLoading),
      put(BurnRequestSearchActions.setDownloadingRequestsExport(false)),
    ])
  }
}

export const SearchSagas = [
  takeLatest(SearchTypes.GET_SEARCH_LOOKUP_DATA, getLookupData),
  takeLatest(BurnPermitSearchTypes.SEARCH_BURN_PERMITS_SUCCESS, searchBurnPermitsSuccess),
  takeLatest(BurnPermitSearchTypes.DOWNLOAD_PERMITS_EXPORT, downloadPermitsExport),
  takeLatest(BurnPermitSearchTypes.RESET_PERMIT_RESULTS, clearSearchPermits),
  takeLatest(BurnRequestSearchTypes.RESET_REQUEST_RESULTS, clearSearchRequests),
  takeLatest(BurnRequestSearchTypes.DOWNLOAD_REQUESTS_EXPORT, downloadRequestsExport),
]