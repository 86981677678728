import React from 'react'
import { Alert, } from 'reactstrap'

const LegacyTonnage = () => {
  return <Alert color={'warning'}>
    <p>
      Consumed Tonnage was not and cannot be estimated using the new calculator integration because these
      Pile Groups were created before the integration that requires additional data that was not available
      when these pile data were entered.
    </p>
    <p>
    Please check the <b>Documents</b> section for any attached Tonnage Calculations.
    </p>
  </Alert>
}

export default LegacyTonnage