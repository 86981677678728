import React from 'react'
import { Field, } from 'formik'
import { Row, Col, Input, Label, } from 'reactstrap'
import { string, number, func, object, PropTypes, } from 'prop-types'


const FuelDiameter = props => {
  return <Row className={props.inputRowsClass}>
    <Col>
      <Label
        for={`fuel-diam-label-${props.fuelDiam.FuelDiameterId}`}
        className={props.inputLabelsClass}
      >
        {props.fuelDiam.FuelDiameterName}
      </Label>
    </Col>
    <Col>
      <Field name={'BurnPermitFuelLoadingXref'}>
        {({ field, }) => (
          <Input
            {...field}
            id={`fuel-diam-input-${props.fuelDiam.FuelDiameterId}`}
            data-id={props.fuelDiam.FuelDiameterId}
            onChange={props.onChange}
            value={props.value}
            type={'number'}
            min={0.1}
            step={0.1}
            readOnly={props.readOnly}
          />
        )}
      </Field>
    </Col>
  </Row>
}

FuelDiameter.propTypes = {
  fuelDiam         : object,
  onChange         : func,
  inputLabelsClass : string,
  inputRowsClass   : string,
  readOnly         : PropTypes.bool,
  value            : PropTypes.oneOfType([ number, string, ]),
}

FuelDiameter.defaultProps = {
  fuelDiam         : {},
  onChange         : () => {},
  inputLabelsClass : '',
  inputRowsClass   : '',
  value            : '',
}

export default FuelDiameter