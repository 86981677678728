import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitSignature extends Model {
  static get modelName () {
    return 'BurnPermitSignature'
  }

  static endpoint () {
    return 'BurnPermitSignatures'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitSignatureId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitSignatureId     : attr(),
      BurnPermitId              : fk({ to: 'BurnPermit', relatedName: 'BurnPermitSignature', as: 'BurnPermit', }),
      ApplicationEnvelopeId     : attr(),
      ApplicationSignedDate     : attr(),
      ApplicationSignedBy       : attr(),
      PermitEnvelopeId          : attr(),
      PermitApplicantSignedDate : attr(),
      PermitApplicantSignedBy   : attr(),
      PermitApproverSignedDate  : attr(),
      PermitApproverSignedBy    : attr(),
      CreateDate                : attr(),
      UpdateDate                : attr(),
    }
  }
}