// Libraries
import React, { useCallback, useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { Label, } from 'reactstrap'
import { bool, func, string, } from 'prop-types'
import { useFormikContext, } from 'formik'

// Components
import { AutoComplete, } from './'
import FormError from './FormError'

// Hooks
import { useAutoComplete, } from '../Search/hooks'

// Selectors
import { getDnrUsersForSelect, } from '../../selectors/selectors'

// Icons
import RequiredIndicator from '../Icons/RequiredIndicator'


const AssignedStaff = (props) => {
  const {
    fieldName,
    labelClassName,
    required,
  } = props

  let {
    onChange,
    value,
  } = props
  let setFieldValue

  const formik = useFormikContext()
  if (formik) {
    setFieldValue = formik.setFieldValue
    value = {
      label : formik.values[fieldName],
      value : formik.values[`${fieldName}Id`],
    }
  }

  const dnrUsers = useSelector(getDnrUsersForSelect)

  const users = useMemo(() => {
    return [ { Value: '', Text: 'Unassigned', }, ...dnrUsers, ]
  }, [ dnrUsers, ])

  const onAutoComplete = useCallback(e => {
    const { name, id, value, } = e.target
    if (typeof setFieldValue === 'function') {
      setFieldValue(name, value)
      setFieldValue(`${name}Id`, id)
    }
    if (typeof onChange === 'function') {
      onChange({ label: value, value: id, })
    }
  }, [ onChange, setFieldValue, ])

  // TODO: this feels wonky to pass a callback through to a hook instead of passing it directly
  const [ assignedStaffChange, ] = useAutoComplete('AssignedTo', onAutoComplete)

  let requiredIndicator
  if (required) {
    requiredIndicator = <RequiredIndicator />
  }

  return <>
    <Label for={fieldName} className={labelClassName}>Assigned Staff{requiredIndicator}</Label>
    <AutoComplete
      fieldName={fieldName}
      items={users}
      value={value}
      onChange={assignedStaffChange}
      minCharactersToEnter={2}
    />
    <FormError fieldName={fieldName} />
  </>
}

AssignedStaff.defaultProps = {
  fieldName: 'AssignTo',
}

AssignedStaff.propTypes = {
  fieldName      : string,
  labelClassName : string,
  onChange       : func,
  value          : string,
  error          : string,
  required       : bool,
}

export default React.memo(AssignedStaff)
