import { createReducer, createActions, } from 'reduxsauce'

import { dateFormatter, } from '../utilities'

import BurnRequest from '../models/BurnRequest'

const BURN_REQUEST_ENDPOINT = BurnRequest.endpoint()

/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  toggleMap         : null,
  togglePbs         : [ 'showPbs', ],
  getMyBurnRequests : () => ({
    type : 'GET_MY_BURN_REQUESTS',
    meta : {
      offline: {
        effect: {
          url: BURN_REQUEST_ENDPOINT,
        },
        commit   : { type: 'SEARCH_BURN_REQUESTS_SUCCESS', },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  downloadRequests            : null,
  closeRequestsDownloadModal  : null,
  doneDownloadingBurnRequests : null,
  setRequestsDownloadStatus   : [ 'target', 'status', ],
  resetRequestsDownloadStatus : null,
})
  
export const BurnRequestListTypes = Types
export default Creators
  
/* ------------- Initial State ------------- */
  
export const INITIAL_STATE = {
  isMapShowing         : false,
  isPostBurnShowing    : true,
  lastDownload         : '',
  lastDownloadDuration : '',
  isDownloading        : false,
  DownloadStatuses     : {
    BurnPermitsDownloaded  : null,
    BurnRequestsDownloaded : null,
    PostBurnsDownloaded    : null,
    MapDataDownloaded      : null,
  },
}
    
/* ------------- Reducers ------------- */

const toggleMap = (state) => {
  return { ...state, isMapShowing: !state.isMapShowing, }
}

const togglePbs = (state, { showPbs, }) => {
  const newState = Object.assign({}, state)
  newState.isPostBurnShowing = showPbs
  return newState
}

const closeDownloadModal = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = false
  return newState
}

const downloadRequests = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = true
  newState.lastDownloadRaw = new Date()
  return newState
}

const doneDownloadingBurnRequests = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = false
  newState.DownloadStatuses.BurnPermitsDownloaded = null
  newState.DownloadStatuses.BurnRequestsDownloaded = null
  newState.DownloadStatuses.PostBurnsDownloaded = null

  newState.lastDownloadFormatted = dateFormatter(newState.lastDownloadRaw, 'ddd MMM D, YYYY @ HH:mm:ss A')
  const now = new Date()
  let duration = dateFormatter(now).diff(newState.lastDownloadRaw, 'second')
  let seconds = duration, minutes = ''
  if (duration > 60) {
    seconds = duration % 60
    minutes = parseInt(duration / 60)
    minutes = `${minutes} minute${minutes > 1 ? 's' : ''} `
  }
  newState.lastDownloadDuration = `${minutes}${seconds} seconds`
  return newState
}

const resetDownloadStatus =  (state) => {
  const newState = Object.assign({}, state)
  newState.DownloadStatuses.BurnPermitsDownloaded = null
  newState.DownloadStatuses.BurnRequestsDownloaded = null
  newState.DownloadStatuses.PostBurnsDownloaded = null
  return newState
}

const setDownloadStatus = (state, { target, status, }) => {
  const newState = Object.assign({}, state)
  newState.DownloadStatuses[`${target}Downloaded`] = status
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_MAP]                     : toggleMap,
  [Types.TOGGLE_PBS]                     : togglePbs,
  [Types.DOWNLOAD_REQUESTS]              : downloadRequests,
  [Types.RESET_REQUESTS_DOWNLOAD_STATUS] : resetDownloadStatus,
  [Types.SET_REQUESTS_DOWNLOAD_STATUS]   : setDownloadStatus,
  [Types.CLOSE_REQUESTS_DOWNLOAD_MODAL]  : closeDownloadModal,
  [Types.DONE_DOWNLOADING_BURN_REQUESTS] : doneDownloadingBurnRequests,
})