import { createReducer, createActions, } from 'reduxsauce'
import { removeItemFromArray, addItemToArray, } from '../utilities'
import BurnPermit from '../models/BurnPermit'

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  // State actions
  activeBurnPermit               : [ 'BurnPermitId', ],
  previousStep                   : null,
  nextStep                       : null,
  setActiveStep                  : [ 'StepId', ],
  savedAndNextStep               : [ 'StepId', ],
  invalidStep                    : [ 'StepId', ],
  completedSteps                 : [ 'StepIds', ],
  permitFailedToLoad             : null,
  resetPermitLoadFail            : null,
  setConditionErrors             : [ 'errors', ],
  // CRUD actions
  //   These actions have listening sagas that are available for offline use
  createPermitApplication        : null,
  createPermitApplicationRequest : (localId) => ({
    type : 'CREATE_PERMIT_APPLICATION_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : BURN_PERMIT_ENDPOINT,
          init : { method: 'POST', body: { localId, }, },
        },
        commit   : { type: 'CREATE_PERMIT_APPLICATION_SUCCESS', localId, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  createPermitApplicationSuccess : [ 'payload', ],
  deleteApplication              : [ 'permitId', ],
  deleteApplicationRequest       : (permitId) => ({
    type : 'DELETE_APPLICATION_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_ENDPOINT}/${permitId}`,
          init : { method: 'DELETE', },
        },
        commit   : { type: 'DELETE_APPLICATION_SUCCESS', permitId, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  deleteApplicationSuccess       : [ 'payload', ],
  unlockApplication              : [ 'permitId', ],
  //   These actions have listening sagas and are only available for online use
  validatePermit                 : [ 'burnPermitId', ],
  getBurnPermit                  : [ 'burnPermitId', 'updateFee', ],
  getBurnPermitDetail            : [ 'burnPermitId', 'burnPermitNumber', 'forceReload', ],
  agencySubmitBurnPermit         : [ 'burnPermitId', ],
  getBurnPermitStatusHistory     : [ 'burnPermitId', ],
  submitApplicationStatus        : [ 'statusValues', ],
  deleteApplicationStatus        : [ 'BurnPermitId', 'BurnPermitApplicationStatusXrefId', ],
  deletePermitStatus             : [ 'BurnPermitId', 'BurnPermitStatusXrefId', ],
  updateSuspension               : [ 'permitId', 'startDate', 'endDate', 'suspensionComment', ],
  clearSuspension                : [ 'permitId', ],
  extendPermit                   : [ 'permitId', 'expirationDate', ],
  revokePermit                   : [ 'permitId', 'revokeData', ],
  undoRevocation                 : [ 'permitId', ],
  copyApplication                : [ 'permitId', ],
  getPermitFiles                 : [ 'permitId', ],
  downloadPermitFile             : [ 'permitId', 'fileName', 'documentId', ],
  downloadAllFiles               : [ 'permitId', ],
  doneDownloadingFiles           : null,
  deletePermitFile               : [ 'permitId', 'documentId', ],
  uploadPermitFiles              : [ 'permitId', 'files', 'createSignature', ],
  updateDocumentType             : [ 'permitId', 'documentId', 'documentTypeId', 'uploadAnyway', ],
  clearDocTypeInfo               : null,
  checkIfFilesAreAlreadyAttached : [ 'files', ],
  toggleApplicationSummary       : null,
})

export const BurnPermitFormTypes = Types
export default Creators

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  activeBurnPermitId          : null,
  searchParam                 : null,
  applications                : {},
  permitFailedToLoad          : false,
  conditionErrors             : {},
  docTypeInfo                 : null,
  redirectToId                : '',
  applicationSummaryIsShowing : false,
  isDownloadingFiles          : false,
}

export const baseAppState = {
  activeStep                  : 1,
  completedSteps              : [],
  invalidSteps                : [],
  shouldValidate              : false,
  applicationSummaryIsShowing : false,
}

/* ------------- Reducers ------------- */

const validatePermit = (state, { burnPermitId, }) => {
  const newState = { ...state, }

  let app = newState.applications[burnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  app.shouldValidate = true
  
  newState.applications[burnPermitId] = app

  return newState
}

const previousStep = (state) => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  const { activeStep, } = app
  // Steps are 1 based, so default to 2 so the subtraction does not go lower than 1
  app.activeStep = (activeStep > 0 ? activeStep : 2) - 1
  
  newState.applications[newState.activeBurnPermitId] = app

  return newState
}

const nextStep = (state) => {
  const newState = { ...state, }
  
  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }
  
  const { activeStep, } = app
  app.activeStep = (activeStep > 0 ? activeStep : 1) + 1
  
  newState.applications[newState.activeBurnPermitId] = app
  
  return newState
}

const completedSteps = (state, { StepIds, }) => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  app.completedSteps = [ ...new Set(StepIds), ]
  
  newState.applications[newState.activeBurnPermitId] = app
  
  return newState
}

const savedAndNextStep = (state, { StepId, }) => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  app.activeStep = app.activeStep + 1
  app.completedSteps = addItemToArray(app.completedSteps, StepId)
  app.invalidSteps = removeItemFromArray(app.invalidSteps, StepId)
  
  newState.applications[newState.activeBurnPermitId] = app
  
  return newState
}

const setActiveStep = (state, { StepId, }) => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  app.activeStep = StepId > 1 ? StepId : 1

  newState.applications[newState.activeBurnPermitId] = app

  return newState
}

const invalidStep = (state, { StepId, }) => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }

  app.invalidSteps = addItemToArray(app.invalidSteps, StepId)
  app.completedSteps = removeItemFromArray(app.completedSteps, StepId)

  newState.applications[newState.activeBurnPermitId] = app
  
  return newState
}

const activeBurnPermit = (state, { BurnPermitId, }) => {
  const newState = { ...state, }
  
  BurnPermitId = parseInt(BurnPermitId)
  if (!isNaN(BurnPermitId)) {
    newState.activeBurnPermitId = BurnPermitId
  }
  else {
    newState.activeBurnPermitId = ''
  }

  newState.permitFailedToLoad = false
  if (!newState.applications[newState.activeBurnPermitId]) {
    newState.applications[newState.activeBurnPermitId] = { ...baseAppState, }
    newState.applications[newState.activeBurnPermitId].instructionsAcknowledged = true
  }

  newState.instructionStep = 0
  
  return newState
}

const permitFailedToLoad = (state) => {
  const newState = { ...state, }
  newState.permitFailedToLoad = true
  return newState
}

const updateDocumentType = (state, { permitId, documentId, documentTypeId, uploadAnyway = false, }) => {
  const newState = { ...state, }
  newState.docTypeInfo = { permitId, documentId, documentTypeId, uploadAnyway, }
  return newState
}

const clearDocTypeInfo = (state) => {
  const newState = { ...state, }
  newState.docTypeInfo = null
  return newState
}

const toggleApplicationSummary = state => {
  const newState = { ...state, }

  let app = newState.applications[newState.activeBurnPermitId]
  if (!app) {
    app = { ...baseAppState, }
  }
  app.applicationSummaryIsShowing = !app.applicationSummaryIsShowing
  
  newState.applications[newState.activeBurnPermitId] = app

  return newState
}

const getBurnPermitDetail = state => {
  const newState = { ...state, }
  newState.isDownloadingFiles = false
  return newState
}

const downloadAllFiles = state => {
  const newState = { ...state, }
  newState.isDownloadingFiles = true
  return newState
}

const doneDownloadingFiles = state => {
  const newState = { ...state, }
  newState.isDownloadingFiles = false
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACTIVE_BURN_PERMIT]         : activeBurnPermit,
  [Types.PERMIT_FAILED_TO_LOAD]      : permitFailedToLoad,
  [Types.UPDATE_DOCUMENT_TYPE]       : updateDocumentType,
  [Types.CLEAR_DOC_TYPE_INFO]        : clearDocTypeInfo,
  [Types.PREVIOUS_STEP]              : previousStep,
  [Types.NEXT_STEP]                  : nextStep,
  [Types.SET_ACTIVE_STEP]            : setActiveStep,
  [Types.SAVED_AND_NEXT_STEP]        : savedAndNextStep,
  [Types.COMPLETED_STEPS]            : completedSteps,
  [Types.INVALID_STEP]               : invalidStep,
  [Types.VALIDATE_PERMIT]            : validatePermit,
  [Types.TOGGLE_APPLICATION_SUMMARY] : toggleApplicationSummary,
  [Types.GET_BURN_PERMIT_DETAIL]     : getBurnPermitDetail,
  [Types.DOWNLOAD_ALL_FILES]         : downloadAllFiles,
  [Types.DONE_DOWNLOADING_FILES]     : doneDownloadingFiles,
})