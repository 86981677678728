import React from 'react'
import { bool, } from 'prop-types'

const Issued = ({ isIssued, }) => {
  let caption = <>
    <p>If your Permit is Issued, you must sign the Permit in DocuSign in order for the Permit to be Issued.</p>
    <p>
      You can sign your Permit in the Signatures section of your Application, then click the Sign 
      button in the Permit card to sign the Permit in DocuSign.
    </p>
  </>
  if (isIssued) {
    caption = <p>
      Now that your Permit is Issued, you can submit Burn Requests using the assigned Burn Permit Number
    </p>
  }
  return {
    label : 'Issued',
    caption,
    icon  : 'fas fa-burn',
  }
}

Issued.propTypes = {
  isIssued: bool,
}

export default Issued