// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Card, CardBody, CardHeader, } from 'reactstrap'

// Components
import PermitAppLink from './PermitAppLink'

// Selectors
import { signatureCallbackState, } from '../../selectors/burnPermitSignatureSelectors'


const DeclineSigning = () => {
  // Selectors
  const { BurnPermitId, } = useSelector(signatureCallbackState)

  return <Card>
    <CardHeader>
      <div className={'d-flex justify-content-between'}>
        <h3>Declined Signing Application {BurnPermitId}</h3>
        <PermitAppLink BurnPermitId={BurnPermitId}/>
      </div>
    </CardHeader>
    <CardBody>
      <p>
        You declined to sign <PermitAppLink BurnPermitId={BurnPermitId}/>. 
        You will not be Issued a Burn Permit until you sign <PermitAppLink BurnPermitId={BurnPermitId}/>
        &nbsp;using the Burn Portal or in person at a <Link to={'/regions'}>Region</Link> office near you.
      </p>
      <p>
        When you&apos;re ready to sign, navigate to <PermitAppLink BurnPermitId={BurnPermitId}/>, 
        navigate to the Signature section, then click the <b>Sign</b> button to sign <PermitAppLink BurnPermitId={BurnPermitId}/>. 
        Or go to your nearest <Link to={'/regions'}>Region</Link> office.
      </p>
    </CardBody>
  </Card>
}

export default React.memo(DeclineSigning)