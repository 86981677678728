import { attr, Model, } from 'redux-orm'
import * as Yup from 'yup'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const ruleValueSchema = (type) => {
  let valueSchema = null
  switch (type) {
  case 'number':
    valueSchema = Yup.number('Value must be a number')
      .required('Value is required.')
      .typeError('Value must be a number')
    break
  case 'json-links':
    valueSchema = Yup.array().of(Yup.object().shape({
      href : Yup.string().required('A destination is required.'),
      text : Yup.string().required('Text is required.'),
    }))

    break
  default:
    valueSchema = Yup.string('Value is required')
      .ensure('Value is required')
      .required('Value is required')
      .min(1, 'Value is required')
      .max(8000, 'Value must be 8,000 characters or less')
    break
  }
  return valueSchema
}


export default class Rule extends Model {
  static get modelName () {
    return 'Rule'
  }

  toString () {
    return `${this.RuleId}`
  }

  static endpoint () {
    return`${REACT_APP_SERVER_URL}Rules`
  }

  static validationSchema () {
    return Yup.object().shape({
      RuleDescription: Yup.string('Rule description is required')
        .required('Rule description is required')
        .min(1, 'Rule description is required')
        .max(128, 'Rule desciption must be 128 characters or less'),
      RuleValue: Yup.mixed().when('RuleType', ruleValueSchema),
    })
  }

  static arrayValidationSchema () {
    return Yup.array().of(this.validationSchema())
  }
}

Rule.options = {
  idAttribute: 'RuleId',
}

Rule.fields = {
  // Common Properties
  RuleId     : attr(),
  CreateDate : attr(),
  CreateBy   : attr(),
  UpdateDate : attr(),
  UpdateBy   : attr(),

  // Model-Specific Properties
  RuleType        : attr(),
  RuleName        : attr(),
  RuleDescription : attr(),
  RuleEnabled     : attr(),
  RuleValue       : attr(),

  // Relationships
}