// Libraries
import { put, all, call, takeLatest, select, } from 'redux-saga/effects'

// Sagas
import { execWithLoading, } from './BaseSagas'
import { getAgency, } from './AgencySagas'
import { doFetch, showError, } from './ApiSagas'
import { getPerson, getAgent, } from './PersonSagas'
import {
  replaceAll,
  destroyLocalModel,
  destroyLocalModels,
  upsertLocalModel,
  upsertLocalModels,
} from './OrmSagas'
import { getBurnPermit, getBurnPermitStatusHistory, } from './BurnPermitSagas'
import { getBurnPermitSiteInfo, } from './BurnPermitSiteSagas'

// Reducers
import { UiTypes, } from '../redux/UiRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import { OrmTypes, } from '../redux/OrmRedux'
import { AppTypes, } from '../redux/AppRedux'
import { CartTypes, } from '../redux/CartRedux'
import { BurnPermitOrderTypes, } from '../redux/BurnPermitOrderRedux'
import { BurnPermitInstructionsTypes, } from '../redux/BurnPermitInstructionsRedux'

// Models
import BurnPermitOrder from '../models/BurnPermitOrder'

// Selectors
import {
  checkAllPermitsBelongToSameBurnerOrAgent,
  permitsByIds,
  hasIssuedPermits,
  permitByIdIsSubmitted,
} from '../selectors/burnPermitSelectors'
import { orderByPermitIdsSelector, } from '../selectors/orderSelectors'

// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const BURN_PERMIT_ORDER_ENDPOINT = `${REACT_APP_SERVER_URL}${BurnPermitOrder.endpoint()}`

/**
 * Submits a request to retrieve and update the user's Burn Permit Orders
 */
export function* getBurnPermitOrders () {
  yield execWithLoading(function* () {
    const burnPermitsOrdersResp = yield call(doFetch, BURN_PERMIT_ORDER_ENDPOINT)

    if (burnPermitsOrdersResp.ok !== true || !burnPermitsOrdersResp.responseBody) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred retrieving your Burn Permit Orders.', })
      return
    }
    // Extract the Xrefs so we can update the ORM with them properly
    let BurnPermitOrderXrefs = []
    for (let i = 0, len = burnPermitsOrdersResp.responseBody.length; i < len; i++) {
      const order = burnPermitsOrdersResp.responseBody[i]
      BurnPermitOrderXrefs = BurnPermitOrderXrefs.concat(order.BurnPermitOrderXref)
      delete order.BurnPermitOrderXref
    }
    // Clear out what's in the local store then write in the response from the server
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'BurnPermitOrder',
      objects   : burnPermitsOrdersResp.responseBody,
    })

    yield call(replaceAll, 'BurnPermitOrderXref', BurnPermitOrderXrefs)
  })
}

/**
 * Submits a request to retrieve and update the user's Burn Permit Orders
 */
export function* getOrderForBurnPermit ({ burnPermitId, disableLoading = false, }) {
  yield execWithLoading(function* () {
    const burnPermitsOrdersResp = yield call(doFetch, `${BURN_PERMIT_ORDER_ENDPOINT}?permitId=${burnPermitId}`)

    if (burnPermitsOrdersResp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred retrieving your Burn Permit Order.', })
      return
    }

    if (burnPermitsOrdersResp.responseBody.length) {
      const [ order, ] = burnPermitsOrdersResp.responseBody
      const xrefs = order.BurnPermitOrderXref
        .map(x => {
          return {
            BurnPermitOrderXrefId : x.BurnPermitOrderXrefId,
            BurnPermitOrderId     : x.BurnPermitOrderId,
            BurnPermitId          : x.BurnPermitId,
          }
        })
      yield call(upsertLocalModels, 'BurnPermitOrderXref', xrefs)
      delete order.BurnPermitOrderXref
      yield call(upsertLocalModel, 'BurnPermitOrder', order)
    }
    else {
      const localOrder = yield select(orderByPermitIdsSelector, [ parseInt(burnPermitId), ])
      if (localOrder) {
        yield call(destroyLocalModel, 'BurnPermitOrder', localOrder.BurnPermitOrderId)
      }
    }
  }, null, null, disableLoading)
}

export function* getBurnPermitDetailForCart ({ burnPermitId, }) {
  yield execWithLoading(function* () {
    const permitResp = yield call(getBurnPermit, { burnPermitId, })
    if (!permitResp || !permitResp.ok) {
      yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Details for Permit ${burnPermitId}. Either it does not exist or you do not have access.`, })
      return
    }
    
    const siteInfoResp = yield call(getBurnPermitSiteInfo, { burnPermitSiteId: permitResp.responseBody.BurnPermitSiteId, })
    if (!siteInfoResp || !siteInfoResp.BurnPermitSiteId) {
      yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Site Info Details for Permit ${burnPermitId}. Either it does not exist or you do not have access.`, })
      return
    }
    
    const { BurnerId, AgentId, AgencyId, } = permitResp.responseBody

    if (BurnerId) {
      const burnerResp = yield call(getPerson, { personId: BurnerId, })
      if (!burnerResp || !burnerResp.ok) {
        yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Landowner Info Details for Permit ${burnPermitId}. Either it does not exist or you do not have access.`, })
        return
      }
    }
    
    if (AgentId) {
      const agentResp = yield call(getAgent, { personId: AgentId, })
      if (!agentResp || !agentResp.ok) {
        yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Agent Info Details for Permit ${burnPermitId}. Either it does not exist or you do not have access.`, })
        return
      }
    }

    if (AgencyId) {
      const agencyResp = yield call(getAgency, AgencyId, false)
      if (!agencyResp || !agencyResp.ok) {
        yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Agency Info Details for Permit ${burnPermitId}. Either it does not exist or you do not have access.`, })
        return
      }
    }
  })
}

export function* getBurnPermitOrderDetail ({ burnPermitOrderId, }) {
  yield execWithLoading(function* () {

    const resp = yield call(doFetch, `${BURN_PERMIT_ORDER_ENDPOINT}/${burnPermitOrderId}`)

    if (resp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: `An error occurred retrieving Burn Permit Order ${burnPermitOrderId}. Either it does not exist or you do not have access.`, })
      return
    }
    
    const { responseBody: Order, } = resp
    const BurnPermitOrderXrefs = Order.BurnPermitOrderXref.map(x => {
      return {
        BurnPermitId          : x.BurnPermitId,
        BurnPermitOrderId     : x.BurnPermitOrderId,
        BurnPermitOrderXrefId : x.BurnPermitOrderXrefId,
        CreateBy              : x.CreateBy,
        CreateDate            : x.CreateDate,
        UpdateBy              : x.UpdateBy,
        UpdateDate            : x.UpdateDate,
      }
    })
    const permitIds = []
    const permitReqs = [], secondaryReqs = []
    for (let x = 0, len = BurnPermitOrderXrefs.length; x < len; x ++) {
      const burnPermitId = BurnPermitOrderXrefs[x].BurnPermitId
      permitIds.push(burnPermitId)
      permitReqs.push(call(getBurnPermit, { burnPermitId, }))
      secondaryReqs.push(call(getBurnPermitSiteInfo, { burnPermitSiteId: burnPermitId, }))
      secondaryReqs.push(call(getBurnPermitStatusHistory, { burnPermitId, }))
    }
    yield all(permitReqs)
    yield all(secondaryReqs)
    const permits = yield select(permitsByIds, permitIds)
    let personIds = new Set()
    for (let i = 0, len = permits.length; i < len; i++) {
      const { BurnerId, AgentId, } = permits[i]
      if (BurnerId) {
        personIds.add(BurnerId)
      }
      if (AgentId) {
        personIds.add(AgentId)
      }
    }
    personIds = [ ...personIds, ]
    const personReqs = []
    for (let b = 0, bLen = personIds.length; b < bLen; b++) {
      personReqs.push(call(getPerson, { personId: personIds[b], includeLookupData: false, }))
    }
    yield all(personReqs)

    yield call(replaceAll, 'BurnPermitOrderXref', [ ...BurnPermitOrderXrefs, ], { BurnPermitOrderId: burnPermitOrderId, })
    delete Order.BurnPermitOrderXref
    yield put({
      type       : OrmTypes.UPSERT,
      modelName  : 'BurnPermitOrder',
      properties : Order,
    })
  })
}

export function* createBurnPermitOrder ({ BurnPermitIds, }) {
  yield execWithLoading(function* () {
    if (yield select(hasIssuedPermits, BurnPermitIds)) {
      const error = 'Cannot create order with Permits that are already Issued and paid for. Remove them from the order then try again.'
      yield put({ type: ApiTypes.FAILURE, error, })
      return
    }

    // Call selector to validate all BurnPermitIds have the same BurnerID or AgentID
    // Returns nullable bools
    //    If `true` for either, the permits are valid
    //    If `false` for either, the permits are not valid
    //    If `null` for either, the information is not set
    // If neither one are `true`, return an error
    const { allSameBurner, allSameAgent, } = yield select(checkAllPermitsBelongToSameBurnerOrAgent, BurnPermitIds)
    if (allSameBurner !== true && allSameAgent !== true) {
      const error = `Cannot create Burn Permit Order. One or more Permits belong to different ${allSameBurner === false ? 'Burners' : 'Agents'}`
      yield put({ type: ApiTypes.FAILURE, error, })
      return
    }

    const resp = yield call(doFetch, BURN_PERMIT_ORDER_ENDPOINT, { method: 'POST', body: { BurnPermitIds, }, })

    if (resp.ok !== true) {
      let error = 'An error occurred attempting to create an Order for the selected Permit(s).'
      if (!resp.responseBody && resp.responseBody.status === 401) {
        error = 'You are not authorized to create an Order for the selected Permit(s)'
      }
      else {
        error = resp.responseBody
      }
      yield put({ type: ApiTypes.FAILURE, error, })
      return
    }
    const BurnPermitOrderXrefs = resp.responseBody.BurnPermitOrderXref.map(x => {
      return {
        BurnPermitId          : x.BurnPermitId,
        BurnPermitOrderId     : x.BurnPermitOrderId,
        BurnPermitOrderXrefId : x.BurnPermitOrderXrefId,
        CreateBy              : x.CreateBy,
        CreateDate            : x.CreateDate,
        UpdateBy              : x.UpdateBy,
        UpdateDate            : x.UpdateDate,
      }
    })
    delete resp.responseBody.BurnPermitOrderXref
    yield all([
      put({
        type       : OrmTypes.UPSERT,
        modelName  : 'BurnPermitOrder',
        properties : resp.responseBody,
      }),
      call(replaceAll, 'BurnPermitOrderXref', BurnPermitOrderXrefs),
      put({
        type              : BurnPermitOrderTypes.SET_NEW_ORDER_ID,
        burnPermitOrderId : resp.responseBody.BurnPermitOrderId,
      }),
      put({ type: CartTypes.EMPTY_CART, }),
    ])
  })
}

export function* updateBurnPermitOrder ({ burnPermitOrder, }) {
  yield execWithLoading(function* () {

    const resp = yield call(doFetch, `${BURN_PERMIT_ORDER_ENDPOINT}/${burnPermitOrder.BurnPermitOrderId}`, { method: 'PUT', body: burnPermitOrder, })

    if (resp.ok !== true) {
      let error = 'An error occurred updating the Burn Permit Order.'
      if ('responseBody' in resp) {
        if ('error' in resp.responseBody) {
          error = resp.responseBody
        }
        else if ('errors' in resp.responseBody) {
          error = Object.values(resp.responseBody.errors).join('\n')
        }
      }
      yield call(showError, error)
      return
    }

    const reqs = [
      put({ type: AppTypes.SHOW_SUCCESS, }),
      call(getBurnPermitOrderDetail, { burnPermitOrderId: burnPermitOrder.BurnPermitOrderId, }),
    ]
    let burnPermitId
    for (let i = 0, len = burnPermitOrder.BurnPermitIds.length; i < len; i++) {
      burnPermitId = burnPermitOrder.BurnPermitIds[i]
      reqs.push(put({
        type           : BurnPermitInstructionsTypes.GO_TO_INSTRUCTION_STEP,
        step           : 'Review',
        burnPermitId,
        completedSteps : [ 'Apply', 'Sign', 'Pay', ],
      }))
      const isSubmitted = yield select(permitByIdIsSubmitted, burnPermitId)
      if (isSubmitted) {
        reqs.push(put({ type: UiTypes.OPEN_MODAL, modalKey: 'APPLICATION_NEXT_STEPS', }))
      }
    }
    yield all(reqs)
  })
}

function* removePermitFromOrder ({ orderId, permitId, }) {
  const finallySagas = [ put({ type: UiTypes.CLOSE_MODAL, }), ]
  yield execWithLoading(function* () {

    const resp = yield call(doFetch, `${BURN_PERMIT_ORDER_ENDPOINT}/${orderId}/Permit/${permitId}`, { method: 'DELETE', })

    if (resp.ok !== true) {
      const error = resp.responseBody ? resp.responseBody.error : 'An error occurred updating the Burn Permit Order.'
      yield call(showError, error)
      return
    }
    yield all([
      put({ type: AppTypes.SHOW_SUCCESS, }),
      // get new details to remove the previously associated application as well as
      // to show the possibly new fee
      call(getBurnPermitOrderDetail, { burnPermitOrderId: orderId, }),
    ])
  }, null, finallySagas)
}

function* deleteOrder ({ orderId, }) {
  const finallySagas = [ put({ type: UiTypes.CLOSE_MODAL, }), ]
  yield execWithLoading(function* () {

    const resp = yield call(doFetch, `${BURN_PERMIT_ORDER_ENDPOINT}/${orderId}`, { method: 'DELETE', })

    if (resp.ok !== true) {
      const error = resp.responseBody ? resp.responseBody.error : 'An error occurred deleting the Burn Permit Order.'
      yield call(showError, error)
      return
    }
    
    yield all([
      put({ type: AppTypes.SHOW_SUCCESS, }),
      call(destroyLocalModels, 'BurnPermitOrderXref', { BurnPermitOrderId: orderId, }),
      call(destroyLocalModel, 'BurnPermitOrder', orderId),
    ])
  }, null, finallySagas)
}


export const BurnPermitOrderSagas = [
  takeLatest(BurnPermitOrderTypes.GET_ORDERS, getBurnPermitOrders),
  takeLatest(BurnPermitOrderTypes.CREATE_BURN_PERMIT_ORDER, createBurnPermitOrder),
  takeLatest(BurnPermitOrderTypes.GET_BURN_PERMIT_DETAIL_FOR_CART, getBurnPermitDetailForCart),
  takeLatest(BurnPermitOrderTypes.GET_BURN_PERMIT_ORDER_DETAIL, getBurnPermitOrderDetail),
  takeLatest(BurnPermitOrderTypes.UPDATE_BURN_PERMIT_ORDER, updateBurnPermitOrder),
  takeLatest(BurnPermitOrderTypes.REMOVE_PERMIT_FROM_ORDER, removePermitFromOrder),
  takeLatest(BurnPermitOrderTypes.DELETE_ORDER, deleteOrder),
]