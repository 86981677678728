import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getRules     : null,
  updateRule   : [ 'id', 'payload', ],
  rulesLoading : null,
  rulesLoaded  : null,
  
  ruleUpdating     : [ 'id', ],
  ruleUpdated      : [ 'id', ],
  ruleUpdateFailed : [ 'id', ],
  
  resetRuleLoadState: [ 'id', ],
})

export const RuleTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  rulesLoading     : false,
  ruleUpdating     : [],
  ruleUpdated      : [],
  ruleUpdateFailed : [],
}

export const rulesLoading = state => {
  const newState = Object.assign({}, state)
  newState.rulesLoading = true
  return newState
}

export const rulesLoaded = state => { 
  const newState = Object.assign({}, state)
  newState.rulesLoading = false
  return newState
}

export const ruleUpdating = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (!newState.ruleUpdating.includes(id)) {
    newState.ruleUpdating = newState.ruleUpdating.concat(id)
  }
  newState.ruleUpdated = newState.ruleUpdated.filter(x => x !== id)
  newState.ruleUpdateFailed = newState.ruleUpdateFailed.filter(x => x !== id)
  return newState
}

export const ruleUpdated = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (!newState.ruleUpdated.includes(id)) {
    newState.ruleUpdated = newState.ruleUpdated.concat(id)
  }
  newState.ruleUpdating = newState.ruleUpdating.filter(x => x !== id)
  newState.ruleUpdateFailed = newState.ruleUpdateFailed.filter(x => x !== id)
  return newState
}

export const ruleUpdateFailed = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (!newState.ruleUpdateFailed.includes(id)) {
    newState.ruleUpdateFailed = newState.ruleUpdateFailed.concat(id)
  }
  newState.ruleUpdating = newState.ruleUpdating.filter(x => x !== id)
  newState.ruleUpdated = newState.ruleUpdated.filter(x => x !== id)
  return newState
}

export const resetRuleLoadingState = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (!id) {
    newState.ruleUpdating = []
    newState.ruleUpdated = []
    newState.ruleUpdateFailed = []
  } else if (Array.isArray(id)){
    newState.ruleUpdating = newState.ruleUpdating.filter(x => !id.includes(x))
    newState.ruleUpdated = newState.ruleUpdated.filter(x => !id.includes(x))
    newState.ruleUpdateFailed = newState.ruleUpdateFailed.filter(x => !id.includes(x))
  } else {
    newState.ruleUpdating = newState.ruleUpdating.filter(x => x !== id)
    newState.ruleUpdated = newState.ruleUpdated.filter(x => x !== id)
    newState.ruleUpdateFailed = newState.ruleUpdateFailed.filter(x => x !== id)
  }
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RULES]             : rulesLoading,
  [Types.RULES_LOADED]          : rulesLoaded,
  [Types.UPDATE_RULE]           : ruleUpdating,
  [Types.RULE_UPDATE_FAILED]    : ruleUpdateFailed,
  [Types.RULE_UPDATED]          : ruleUpdated,
  [Types.RESET_RULE_LOAD_STATE] : resetRuleLoadingState,
})