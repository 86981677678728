import React from 'react'

const Denied = () => {
  return {
    label   : 'Denied',
    caption : <>
      <p>Your Permit Application is Denied <i className={'fas fa-thumbs-down'} />.</p>
      <p>Contact DNR if you have questions or if you feel this was in error.</p>
    </>,
    icon: 'fas fa-thumbs-down',
  }
}

export default Denied