// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Navigate, } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, } from 'reactstrap'
import { string, func, object, array, bool, PropTypes, } from 'prop-types'

// Reducers
import UiActions from '../redux/UiRedux'

// Utilities
import stopEvent from '../utilities/stopEvent'

class ConfirmationModal extends React.Component {
  static propTypes = {
    loadingComplete   : bool,
    modalBody         : PropTypes.oneOfType([ string, object, ]),
    modalKey          : string,
    modalTitle        : string,
    redirectUrl       : string,
    disableSubmit     : bool,
    disableButtons    : bool,
    submitActionLabel : string,
    submitAction      : func,
    submitArgs        : array,
    successCallback   : func,
    openModalKey      : string,
    closeModal        : func,
    cancelAction      : func,
    cancelActionLabel : string,
    showModal         : bool,
    showSpinner       : bool,
  }

  static defaultProps = {
    disableSubmit     : false,
    submitActionLabel : 'Submit',
    cancelActionLabel : 'Cancel',
    submitArgs        : [],
  }
  
  componentDidUpdate (prevProps) {
    if (prevProps.loadingComplete === false && this.props.loadingComplete === true) {
      this.props.successCallback()
      this.handleClose()
    }
  }

  handleClose = () => {
    const { closeModal, cancelAction, showModal, } = this.props
    if (showModal) {
      closeModal()
    }
    if (typeof cancelAction === 'function') {
      cancelAction()
    }
  }

  toggle = evt => {
    stopEvent(evt)

    if (this.props.showModal) {
      this.handleClose()
    }
  }

  handleSubmit = evt => {
    stopEvent(evt)

    if (this.props.showModal) {
      const success = this.props.submitAction(...this.props.submitArgs)
      if (success) {
        this.toggle()
      }
    }
  }
  
  render () {
    if (this.props.loadingComplete) {
      return <Navigate to={this.props.redirectUrl} />
    }
    const {
      modalTitle,
      modalBody,
      showModal,
      modalKey,
      openModalKey,
      disableSubmit,
      disableButtons,
      submitActionLabel,
      cancelActionLabel,
    } = this.props
    const isOpen = showModal && (openModalKey ? modalKey === openModalKey : true)
    return <Modal 
      isOpen={isOpen}
      toggle={this.toggle}>
      <ModalHeader toggle={this.toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {modalBody}
      </ModalBody>
      <ModalFooter className={'d-flex justify-content-between'}>
        <Button
          color={'light'}
          onClick={this.toggle}
          disabled={disableButtons}
        >
          {cancelActionLabel}
        </Button>
        <div>
          { this.props.showSpinner && <Spinner color={'primary'} /> }
          <Button
            color={'secondary'}
            onClick={this.handleSubmit}
            disabled={disableSubmit || disableButtons}
          >
            {submitActionLabel}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  }
}
  
function mapStateToProps (state, props) {
  const { showModal, modalKey, } = state.ui.Modal
  return {
    showModal    : showModal || props.showModal,
    openModalKey : modalKey,
  }
}

const mapDispatchToProps = {
  closeModal: UiActions.closeModal,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)