import { useCallback, } from 'react'

// Actions
import UiActions from '../redux/UiRedux'
import { useSelector, } from 'react-redux'
import { isModalOpen, } from '../selectors/uiSelectors'

/**
 * A hook to abstract the redux action calls and selector to
 * manage a modal dialog's open or closed state
 * @param {import('redux').Dispatch} dispatch Redux dispatch
 * @param {string} modalKey The modal key to open and track
 * @returns {[boolean, import('react').MouseEventHandler<HTMLButtonElement> | any, import('react').MouseEventHandler<HTMLButtonElement> | any]} Array of the following order:
 *   - {bool} modalIsOpen
 *   - {func} showModal
 *   - {func} closeModal
 */
const useShowModal = (dispatch, modalKey) => {
  const modalIsOpen = useSelector(state => isModalOpen(state, modalKey))
  const showModal = useCallback(() => {
    dispatch(UiActions.openModal(modalKey))
  }, [ dispatch, modalKey, ])
  const closeModal = useCallback(() => {
    dispatch(UiActions.closeModal())
  }, [ dispatch, ])
  return [ modalIsOpen, showModal, closeModal, ]
}

export default useShowModal
