import React from 'react'
import { useSelector, } from 'react-redux'

// Components
import NavbarDropdownItem from '../NavbarDropdownItem'
import NavbarDropdown from '../NavbarDropdown'

// Selectors
import { userCanViewAdminMenu, } from '../../../selectors/permissionSelectors'


const Admin = () => {
  // Selectors
  const canViewAdminMenu = useSelector(userCanViewAdminMenu)

  if (!canViewAdminMenu) {
    return null
  }

  const markup = <NavbarDropdown
    text={'Admin'}
    title={'Admin Menu'}
  >
    <NavbarDropdownItem
      to={'/admin/people'}
      title={'Manage Person records'}
    >
      People
    </NavbarDropdownItem>
    <NavbarDropdownItem
      to={'/admin/agencies'}
      title={'Manage Agencies'}
    >
      Agencies
    </NavbarDropdownItem>
    <NavbarDropdownItem
      to={'/regions'}
      title={'View Region Offices'}
    >
      Regions
    </NavbarDropdownItem>
    <NavbarDropdownItem
      to={'/admin/reports'}
      title={'Admin - Reports'}
    >
      Reports
    </NavbarDropdownItem>
    <NavbarDropdownItem
      to={'/admin/rules'}
    >
      Rules &amp; Settings
    </NavbarDropdownItem>
  </NavbarDropdown>
  return markup
}

export default React.memo(Admin)
