// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'
import { Formik, Field, ErrorMessage, } from 'formik'
import { Row, Col, FormGroup, Label, FormFeedback, ButtonGroup, ListGroup, ListGroupItem, Badge, Fade, Button, } from 'reactstrap'
import { object, bool, func, array, } from 'prop-types'

// Components
import Effect from '../Effect'
import AuditData from '../AuditData'
import { Select, ValidatingField, PopoverButton, ButtonGroupButton, RequiredLabel, } from '../FormControls'

// Selectors
import { phoneTypesSelector, } from '../../selectors/selectors'

// Actions
import ApiActions from '../../redux/ApiRedux'

// Models
import Phone from '../../models/Phone'

// Utilities
import stopEvent from '../../utilities/stopEvent'


export class PhoneForm extends React.Component {

  static propTypes = {
    phone              : object,
    onSubmit           : func,
    phoneTypes         : array,
    readOnly           : bool,
    createFn           : func,
    updateFn           : func,
    UpdateRecord       : func,
    CreateRecord       : func,
    showAuditData      : bool,
    showActiveControl  : bool,
    enableReinitialize : bool,
    actionButton       : object,
  }

  static defaultProps = {
    readOnly      : false,
    createFn      : null,
    showAuditData : false,
  }

  state = {
    showReceivesAlerts: false,
  }

  valSchema = Phone.getValidationSchema()

  componentDidMount () {
    if (this.props.phone) {
      this.showReceivesAlerts(this.props.phone.PhoneTypeId)
    }
  }

  validateForm = async () => {
    return this.formik.validateForm()
  }

  submitForm = () => {
    this.formik.submitForm()
  }

  submit = (values) => {
    const { UpdateRecord, CreateRecord, phone, } = this.props
    const {
      PhoneId,
      PhoneNumber,
      PhoneExtension,
      PhoneTypeId,
      ReceivesAlerts,
      Active,
    } = values

    if (phone && PhoneId && !phone.IsLocal) {
      const phoneObj = {
        PhoneTypeId,
        PhoneId: phone.PhoneId,
        PhoneNumber,
        PhoneExtension,
        ReceivesAlerts,
        Active,
      }
      if (this.props.updateFn) {
        this.props.updateFn(phoneObj)
      }
      else {
        UpdateRecord('Phone', phoneObj)
      }
    }
    else {
      const phoneObj = {
        PhoneId,
        PhoneTypeId,
        PhoneNumber,
        PhoneExtension,
        ReceivesAlerts,
        Active,
        PhoneNumberConfirmed: false,
      }
      if (this.props.createFn) {
        this.props.createFn(phoneObj)
      }
      else {
        CreateRecord('Phone', phoneObj, 'Phones' , true)
      }
    }
  }

  resetForm = () => {
    this.formik.resetForm(this.formik.initialValues)
  }

  touched = () => {
    if (this.formik) {
      return Object.values(this.formik.touched).some(t => t === true)
    }
    // unable to check if touched, just return true
    return true
  }

  showReceivesAlerts = value => {
    let showReceivesAlerts = false
    if (Array.isArray(this.props.phoneTypes)) {
      const mobilePhoneType = this.props.phoneTypes.filter(t => t.Text === 'Mobile')[0]
      if (mobilePhoneType) {
        showReceivesAlerts = parseInt(value) === parseInt(mobilePhoneType.Value)
      }
    }
    this.setState({ showReceivesAlerts, })
  }

  onPhoneTypeChange = e => {
    const { value, name, } = e.target
    if (this.formik) {
      this.formik.setFieldValue(name, value)
      this.showReceivesAlerts(value)
    }
  }

  setFormikNode = node => this.formik = node

  render () {
    const {
      readOnly,
      phone,
      showAuditData,
      showActiveControl,
      actionButton,
      enableReinitialize,
    } = this.props
    return (
      <Formik
        initialValues={phone}
        validationSchema={this.valSchema}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
        enableReinitialize={enableReinitialize}
      >
        {({ values , }) => (
          <>
            <Effect values={values} onChange={this.onChange} />
            <Row>
              <Col xs={12} md={5}>
                <FormGroup>
                  <Field name={'PhoneTypeId'}>
                    {({ field, form, }) => (
                      <Select
                        {...field}
                        label={'Type'}
                        items={this.props.phoneTypes}
                        propertyName={field.name}
                        selectedValue={field.value}
                        errorMessage={form.errors[field.name]}
                        readOnly={readOnly}
                        onChange={this.onPhoneTypeChange}
                      />
                    )}
                  </Field>
                  <ErrorMessage name={'PhoneTypeId'} component={FormFeedback} />
                </FormGroup>
              </Col>
              <Col>
                <Fade in={this.state.showReceivesAlerts}>
                  <FormGroup>
                    <Label>
                      Receives Alerts
                      <PopoverButton
                        buttonClassName={'pl-1 pr-0'}
                        popoverHeader={'Receives Alerts'}
                        popoverBody={<>
                          <p>Check yes if this phone should be used to receive SMS Alerts. Make sure to include the correct Country Code in order to receive the SMS Alert.</p>
                          <ListGroup>
                            <ListGroupItem>United States of America <Badge>1</Badge></ListGroupItem>
                            <ListGroupItem>Canada <Badge>1</Badge></ListGroupItem>
                            <ListGroupItem>Mexico <Badge>52</Badge></ListGroupItem>
                          </ListGroup>
                        </>}
                      />
                    </Label>
                    <Field name={'ReceivesAlerts'}>
                      {({ field, form, }) => (
                        <ButtonGroup className={'d-block'}>
                          <ButtonGroupButton
                            value={field.value}
                            onClick={e => {
                              stopEvent(e)
                              form.setFieldValue(field.name, true)
                              form.setFieldTouched(field.name, true)
                            }}
                            isActive={typeof field.value === 'boolean' ? field.value : false}
                            text={'Yes'}
                          />
                          <ButtonGroupButton
                            value={field.value}
                            onClick={e => {
                              stopEvent(e)
                              form.setFieldValue(field.name, false)
                              form.setFieldTouched(field.name, true)
                            }}
                            isActive={typeof field.value === 'boolean' ? !field.value : true}
                            text={'No'}
                          />
                        </ButtonGroup>
                      )}
                    </Field>
                  </FormGroup>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <FormGroup className={'h-100 d-flex flex-column justify-content-between'}>
                  <RequiredLabel
                    labelFor={'phonenumber'}
                  >
                    Phone Number
                  </RequiredLabel>
                  <Field
                    id={'phonenumber'}
                    name={'PhoneNumber'}
                    readOnly={readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className={'h-100 d-flex flex-column justify-content-between'}>
                  <Label title={'Extension'} for={'extension'}>Ext.</Label>
                  <Field
                    id={'extension'}
                    name={'PhoneExtension'}
                    readOnly={readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
            </Row>
            {
              showActiveControl &&
                <Row className={'mt-3'}>
                  <Col>
                    <FormGroup>
                      <Label>
                        Active
                        <PopoverButton
                          popoverHeader={'Toggle Phone state'}
                          popoverBody={<>
                            <p>Click <b>YES</b> if this phone should be available to use on Permit Applications.</p>
                            <p>Click <b>NO</b> if this phone should <b>NOT</b> be available to use on <b>NEW</b> Permit Applications.</p>
                            <p>Inactivating (clicking <b>NO</b>) an phone already in use on a Permit or Permit Application will not remove the phone from the Permit or Permit Application.</p>
                          </>}
                        />
                      </Label>
                      <Field name={'Active'}>
                        {({ field, form, }) => (
                          <>
                            <ButtonGroup className={'d-block'}>
                              <ButtonGroupButton
                                value={field.value}
                                onClick={e => {
                                  stopEvent(e)
                                  form.setFieldValue(field.name, true)
                                  form.setFieldTouched(field.name, true)
                                }}
                                isActive={typeof field.value === 'boolean' ? field.value : true}
                                text={'Yes'}
                              />
                              <ButtonGroupButton
                                value={field.value}
                                onClick={e => {
                                  stopEvent(e)
                                  form.setFieldValue(field.name, false)
                                  form.setFieldTouched(field.name, true)
                                }}
                                isActive={typeof field.value === 'boolean' ? !field.value : false}
                                text={'No'}
                              />
                            </ButtonGroup>
                            <input
                              id={'phone-active'}
                              type={readOnly ? '' :'hidden'}
                              {...field}
                              value={readOnly ? field.value ? 'true' : 'false' : 'true'}
                              readOnly={readOnly}
                              className={'form-control' + (form.errors[field.name] ? ' is-invalid' : '')}
                              invalid={form.errors[field.name]}
                            />
                            <ErrorMessage name={field.name}>
                              {errorMessage => <FormFeedback className={'text-wrap'}>{errorMessage}</FormFeedback>}
                            </ErrorMessage>
                          </>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
            }
            {
              showAuditData === true && !isEmpty(phone) &&
              <Row className={'audit-data p-2'}>
                <AuditData
                  CreateBy={phone.CreateBy}
                  CreateDate={phone.CreateDate}
                  UpdateBy={phone.UpdateBy}
                  UpdateDate={phone.UpdateDate}
                />
              </Row>
            }
            {
              !readOnly && 
                <div className={'d-flex justify-content-between'}>
                  <Button onClick={this.resetForm} color={'primary'} size={'sm'}>Reset</Button>
                  { actionButton }
                </div>
            }
          </>
        )}
      </Formik>
    )
  }
}

function mapStateToProps (state) {
  return {
    phoneTypes: phoneTypesSelector(state),
  }
}

const mapDispatchToProps = {
  CreateRecord : ApiActions.createRecord,
  UpdateRecord : ApiActions.updateRecord,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PhoneForm)