// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { func, object, array, bool, number, string, PropTypes, } from 'prop-types'
import { Link, } from 'react-router-dom'
import { isEmpty, } from 'lodash'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
} from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'
import UiActions from '../redux/UiRedux'
import BurnPermitFormActions from '../redux/BurnPermitFormRedux'

// Components
import {
  BurnPermitStatusSection,
  BurnPermitAreaSection,
  ApplicantInfoSection,
  BurnPermitBurnLocationSection,
  BurnPermitSiteSection,
  BurnPermitPileGroupsSection,
  BurnPermitFuelsSection,
  BurnPermitSignatureSection,
  ForestHealthExemptionSection,
  PermitFailedToLoad,
  ApplicationDocuments,
  Tonnage,
  Nav,
  StatusHeader,
} from '../components/PermitSections'
import ConfirmationModal from '../components/ConfirmationModal'
import {
  ApplicationNextSteps,
  PermitNextSteps,
  DeleteApplication,
  CopyApplication,
} from '../components/Modals'
import withRouter from '../components/withRouter'

// Selectors
import { permitIdAndSectionIdsByPermitIdSelector, permitStatus, } from '../selectors/selectors'
import {
  permitApplicationIsSigned,
  permitEstimatedFee,
  permitTotalPermitTonnage,
  permitIsSuspendedSelector,
  permitIsRevokedSelector,
  permitApplicationStatus,
  activePermitIsLegacy,
  permitIsInUGA,
  permitIsExpiredSelector,
  permitIsForForestHealth,
  activeBurnPermitStateSelector,
} from '../selectors/burnPermitSelectors'
import { userSelector, } from '../selectors/userSelectors'
import {
  userCanEditLegacyPermitApplInfo,
  userCanReviewFHExemption,
  userCanEditLocationPastSubmission,
} from '../selectors/burnPermitPermissionSelectors'

// Utilities
import stopEvent from '../utilities/stopEvent'
import { authPropsDefaults, authPropsTypes, } from '../utilities/props'


class BurnPermitFormContainer extends React.Component {

  constructor (props) {
    super(props)

    this.renderSteps = this.renderSteps.bind(this)
    
    const { SetPageTitle, GetBurnPermitDetail, CloseModal, params, } = this.props
    // Close all modals to start
    CloseModal()
    GetBurnPermitDetail(params.BurnPermitId)

    SetPageTitle(`Burn Permit Application ${params.BurnPermitId}`)
  }

  static propTypes = {
    // from withRouter HOC
    params   : object,
    navigate : func,

    GetBurnPermit               : func,
    GetBurnPermitDetail         : func,
    SetPageTitle                : func,
    SetActiveStep               : func,
    ValidatePermit              : func,
    ActiveBurnPermit            : func,
    DeleteApplication           : func,
    CopyApplication             : func,
    ToggleApplicationSummary    : func,
    OpenModal                   : func,
    CloseModal                  : func,
    activeBurnPermit            : object,
    activeStep                  : number,
    applicationStatus           : object,
    permitStatus                : object,
    permitFailedToLoad          : bool,
    isRevoked                   : bool,
    isSuspended                 : bool,
    isExpired                   : bool,
    isIssued                    : bool,
    readOnly                    : bool,
    isSigned                    : bool,
    Documents                   : array,
    TotalPermitTonnage          : PropTypes.oneOfType([ number, string, ]),
    EstimatedFee                : PropTypes.oneOfType([ number, string, ]),
    OpenModalKey                : string,
    online                      : bool,
    isInUGA                     : bool,
    ShowForestHealth            : bool,
    canEditLocAfterSub          : bool,
    showUnlockButton            : bool,
    applicationSummaryIsShowing : bool,
    // from parent
    ...authPropsTypes,
  }

  static defaultProps = {
    readOnly                    : false,
    applicationStatus           : null,
    isSigned                    : false,
    applicationSummaryIsShowing : false,
    ...authPropsDefaults,
  }

  componentDidUpdate (prevProps) {
    const { activeBurnPermit, SetPageTitle, } = this.props

    // This can happen if a user creates the application while offline,
    // then regains an internet connection while still on the application page
    if (!prevProps.online && this.props.online) {
      let prevPermitId, currPermitId
      if (!isEmpty(prevProps.activeBurnPermit)) {
        prevPermitId = prevProps.activeBurnPermit.BurnPermitId
      }
      if (!isEmpty(activeBurnPermit)) {
        currPermitId = activeBurnPermit.BurnPermitId
      }
      if (prevPermitId !== currPermitId && Number.isInteger(currPermitId)) {
        SetPageTitle(`Burn Permit Application ${activeBurnPermit.BurnPermitId}`)
        this.props.navigate(`/permits/${activeBurnPermit.BurnPermitId}`)
        return
      }
    }
    
    // This captures the scenario where a Burn Permit is copied and the user
    // uses the browser navigation to move forward/backward between the permits
    // Or when a user copies an application
    const { params, } = this.props
    if (prevProps.params.BurnPermitId !== params.BurnPermitId) {
      SetPageTitle(`Burn Permit Application ${params.BurnPermitId}`)
      this.props.GetBurnPermitDetail(params.BurnPermitId)
      return
    }
    
    if (!prevProps.showInvalidAppModal && this.props.showInvalidAppModal) {
      this.props.OpenModal(this.invalidApplicationModal)
    }
  }

  scrollToStep = () => {
    // make sure that it is visible in the step nav in case they are on mobile
    let btnNav = document.getElementsByClassName('btn-nav')
    if (!Array.isArray(btnNav)) {
      btnNav = []
    }
    const activeBtn = [ ...btnNav, ].filter(b => b.classList.contains('active'))[0]
    const container = document.getElementsByClassName('application-nav')[0]
    if (activeBtn && container && typeof container.scrollTo === 'function') {
      container.scrollTo(activeBtn.offsetLeft, 0)
    }
  }

  validatePermit = () => {
    if (this.props.online) {
      this.props.ValidatePermit(this.props.activeBurnPermit.BurnPermitId)
    }
  }

  reloadPermit = evt => {
    stopEvent(evt)
    if (this.props.online) {
      this.props.GetBurnPermitDetail(this.props.activeBurnPermit.BurnPermitId, null, true)
    }
  }

  instructionsModalKey = 'APPLICATION_NEXT_STEPS'
  invalidApplicationModal = 'INVALID_APPLICATION'
  unlockApplicationModalKey = 'UNLOCK_PERMIT_APPLICATION'
  deleteAppModalKey = 'DELETE_PERMIT_APPLICATION'
  copyAppModalKey = 'COPY_PERMIT_APPLICATION'

  openModal = evt => {
    stopEvent(evt)
    const dataset = evt.target.nodeName === 'SPAN'
      ? evt.target.parentNode.dataset
      : evt.target.dataset
    const { modalkey, } = dataset
    this.props.OpenModal(modalkey)
  }

  renderSteps () {
    
    const {
      activeBurnPermit,
      User,
      isSigned,
      isDenied,
      readOnly,
      isIssued,
      isVerifiedAgent,
      isDnr,
      ShowForestHealth,
      isInUGA,
      applicationStatus,
      canEditLocAfterSub,
      activeStep,
    } = this.props

    // To dynamically render Steps, such as the Forest Health Step
    // break up the component from its props so it is not rendered when declaring the array
    // This makes it easier to add, remove, or reorder the Steps while maintaining
    // functionality for the next and previous controls as well as ensuring the Step
    // id ordering is correct
    const steps = [
      [
        BurnPermitAreaSection,
        {
          title            : 'Burn Area',
          burnPermitId     : activeBurnPermit.BurnPermitId,
          burnPermitAreaId : activeBurnPermit.BurnPermitAreaId,
          readOnly         : readOnly,
        },
      ],
      [
        ApplicantInfoSection,
        {
          title        : 'Applicant',
          burnPermitId : activeBurnPermit.BurnPermitId,
          readOnly     : this.props.isLegacy && this.props.canEditLegacyAppl ? false : readOnly,
          userPersonId : User.personId,
          isDnr        : isDnr,
          isAgent      : (this.props.isAgent && isVerifiedAgent) || (this.props.isGovAgent && isVerifiedAgent),
        },
      ],
      [
        BurnPermitBurnLocationSection,
        {
          title                : 'Burn Location',
          burnPermitId         : activeBurnPermit.BurnPermitId,
          burnPermitLocationId : activeBurnPermit.BurnPermitLocationId,
          applicationStatus    : applicationStatus ? applicationStatus.Status : '',
          // DNR users can edit the section until the Permit is Issued
          readOnly             : !canEditLocAfterSub || isDenied ? readOnly : isIssued,
          isInUGA,
        },
      ],
      [
        BurnPermitSiteSection,
        {
          title            : 'Burn Site',
          burnPermitId     : activeBurnPermit.BurnPermitId,
          burnPermitSiteId : activeBurnPermit.BurnPermitSiteId,
          readOnly         : readOnly,
        },
      ],
      [
        BurnPermitPileGroupsSection,
        {
          title             : 'Pile Groups',
          burnPermitId      : activeBurnPermit.BurnPermitId,
          readOnly          : readOnly,
          applicationStatus : applicationStatus ? applicationStatus.Status : '',
        },
      ],
      [
        BurnPermitFuelsSection,
        {
          title            : 'Fuels',
          burnPermitId     : activeBurnPermit.BurnPermitId,
          burnPermitFuelId : activeBurnPermit.BurnPermitFuelId,
          readOnly         : readOnly,
        },
      ],
      [
        ApplicationDocuments,
        {
          title        : 'Documents',
          burnPermitId : activeBurnPermit.BurnPermitId,
        },
      ],
      [
        BurnPermitSignatureSection,
        {
          title        : 'Signature',
          burnPermitId : activeBurnPermit.BurnPermitId,
          readOnly     : readOnly,
        },
      ],
    ]

    // If the applicant set the burn reason to Forest Health
    if (ShowForestHealth) {
      // The Forest Health section needs to be rendered in order to capture the exempt data
      // and provide the forest health approval interface for DNR users
      const forestHealthSection = [
        ForestHealthExemptionSection,
        {
          title             : 'Forest Health Exemption',
          burnPermitId      : activeBurnPermit.BurnPermitId,
          readOnly          : isSigned,
          showSaveControls  : this.props.canReviewFHExemption ? true : !isSigned,
          applicationStatus : applicationStatus ? applicationStatus.Status : '',
        },
      ]
      // Insert it in to the array after the burn site section
      steps.splice(steps.findIndex(s => s[1].title === 'Burn Site') + 1, 0, forestHealthSection)
    }

    this.scrollToStep()

    // Map the steps to dynamically set the sectionId and key for each component
    const labels = steps.map(s => s[1].title).filter(t => !!t)
    return <>
      <Nav
        currentStep={activeStep - 1}
        totalSteps={labels.length}
        invalidSteps={this.props.invalidSteps}
        completedSteps={this.props.completedSteps}
        labels={labels}
        goToStep={stepIdx => {
          this.props.SetActiveStep(stepIdx + 1)
        }}
      />
      {
        steps.map((s, idx) => {
          const Comp = s[0]
          const props = s[1]
          props.sectionId = idx + 1
          return <Comp key={`BurnPermitFormSection-${idx + 1}`} {...props} />
        })
      }
    </>
  }

  render () {
    const { activeBurnPermit, permitFailedToLoad, } = this.props

    const burnPermitIsReadyToLoad = !isEmpty(activeBurnPermit)

    if (burnPermitIsReadyToLoad === false && permitFailedToLoad === false) {
      return <Container className={'py-2'}>
        <Row>
          <Col md={{ size: '6', offset: '3', }}>
            <h1 style={{ textAlign: 'center', }}>Loading Application...</h1>
          </Col>
        </Row>
      </Container>
    }
    else if (permitFailedToLoad === true) {
      return <PermitFailedToLoad />
    }
    
    const {
      isDnr,
      isRevoked,
      isExpired,
      isSuspended,
      applicationStatus,
      isIssued,
      online,
      isInUGA,
      ShowForestHealth,
      showUnlockButton,
    } = this.props

    let headerText = `Burn Permit Application ${ activeBurnPermit ? activeBurnPermit.BurnPermitId : ''}`
    let newRequestUrl = ''
    if (isIssued) {
      headerText = `Burn Permit ${activeBurnPermit.BurnPermitNumber}`
      newRequestUrl = `/burn-requests/new?burnPermitNumber=${activeBurnPermit.BurnPermitNumber}`
    }

    const statusProps = {
      isSuspended,
      isRevoked,
      isExpired,
      enableSummary               : false,
      appStatus                   : applicationStatus,
      enableToggler               : !!applicationStatus,
      toggleApplicationSummary    : this.props.ToggleApplicationSummary,
      applicationSummaryIsShowing : this.props.applicationSummaryIsShowing,
    }
    
    return <Container className={'py-2'}>
      <Row className={'burn-permit-header'}>
        <Col xs={12} md={7}>
          <h1 className={'m-0 d-inline'}>
            { headerText }
          </h1>
          {
            isInUGA &&
              <h2
                title={'Application is for burning within an Urban Growth Area'}
                className={'ml-2 d-inline'}
              >
                <Badge color={'warning'} className={'text-light'}><span className={'fas fa-city'}/></Badge>
              </h2>
          }
          {
            ShowForestHealth &&
              <h2
                className={'ml-2 d-inline'}
                style={{ color: 'white', }}
                title={'Application is for burning under a Forest Health Exemption'}
              >
                <Badge color={'success'}><span className={'fas fa-tree'}/></Badge>
              </h2>
          }
          <ApplicationNextSteps />
          <PermitNextSteps />
        </Col>
        <Col xs={12} md={5}>
          <StatusHeader {...statusProps} />
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Col
          xs={12}
          md={6}
          className={'order-1 order-md-0 mt-2 mt-md-0 d-flex flex-row'}
          style={{ gap: '1em', }}
        >
          {
            applicationStatus && <>
              <CopyApplication
                online={online}
                openModal={this.openModal}
                modalKey={this.copyAppModalKey}
                submitAction={this.props.CopyApplication}
                submitArgs={[ this.props.activeBurnPermit.BurnPermitId, ]}
              />
            </>
          }
          {
            showUnlockButton && <>
              <Button
                size={'sm'}
                color={'primary'}
                onClick={this.openModal}
                data-modalkey={this.unlockApplicationModalKey}
              >
                <span className={'d-block d-lg-none fa fa-unlock p-1'}/>
                <span className={'d-none d-lg-block'}>Unlock</span>
              </Button>
              <ConfirmationModal
                modalKey={this.unlockApplicationModalKey}
                modalTitle={'Unlock Burn Permit Application'}
                modalBody={<>
                  <p>Are you sure you wish to unlock this Burn Permit Application to edit it?</p>
                  <p>This action will permanently delete your DocuSign signature and is <b>irreversible</b>.</p>
                </>}
                submitAction={this.props.UnlockApplication}
                submitArgs={[ this.props.activeBurnPermit.BurnPermitId, ]}
              />
              <Modal isOpen={this.props.OpenModalKey === this.invalidApplicationModal}>
                <ModalHeader
                  tag={'h4'}
                  toggle={this.props.CloseModal}
                >
                  Invalid Application
                </ModalHeader>
                <ModalBody>
                  <p>
                      Your application has one or more invalid sections.
                      You must click the <b>Unlock</b> button at the bottom of the page then update each invalid section.
                  </p>
                  <p>
                      Once all sections are valid, you may then sign the application and create an order with it.
                  </p>
                </ModalBody>
              </Modal>
            </>
          }
          {
            applicationStatus && applicationStatus.Status === 'Pending' && <>
              <DeleteApplication
                openModal={this.openModal}
                modalKey={this.deleteAppModalKey}
                submitAction={this.props.DeleteApplication}
                submitArgs={[ this.props.activeBurnPermit.BurnPermitId, ]}
              />
            </>
          }
          {
            !isIssued && <>
              <Button
                size={'sm'}
                color={'light'}
                onClick={this.openModal}
                data-modalkey={this.instructionsModalKey}
              >
                <span className={'d-block d-lg-none fa fa-list-ol p-1'}/>
                <span className={'d-none d-lg-block'}>Instructions</span>
              </Button>
            </>
          }
          { (isIssued && !isExpired) && newRequestUrl && <Link to={newRequestUrl} className={'mr-2'}>New Burn Request</Link> }
        </Col>
        <Col xs={12} md={6} className={'order-0 order-md-1'}>
          <Tonnage
            online={this.props.online}
            EstimatedFee={this.props.EstimatedFee}
            TotalPermitTonnage={this.props.TotalPermitTonnage}
          />
        </Col>
      </Row>
      {
        this.props.applicationSummaryIsShowing
          ? <BurnPermitStatusSection
            isDnr={isDnr}
            isOpen={this.props.applicationSummaryIsShowing}
            title={'Application Summary'}
            burnPermitId={activeBurnPermit.BurnPermitId}
            isSuspended={isSuspended}
            isRevoked={isRevoked}
          />
          : this.renderSteps()
      }
    </Container>
  }
}

function mapStateToProps (state) {
  
  const {
    activeBurnPermitId,
    permitFailedToLoad,
    activeStep,
    completedSteps,
    invalidSteps,
    applicationSummaryIsShowing,
  } = activeBurnPermitStateSelector(state)

  let isIssued = false
  let isDenied = false
  let readOnly = true
  let canValidate = false
  const isSigned = permitApplicationIsSigned(state, activeBurnPermitId)

  let status = permitStatus(state, activeBurnPermitId)
  if (!status) {
    status = permitApplicationStatus(state, activeBurnPermitId)
  }
  else {
    isIssued = true
  }

  let isPending = false
  if (status) {
    isDenied = status.Status === 'Denied'
    isPending = status.Status === 'Pending'
    if (isPending || status.Status === 'Info Required') {
      readOnly = isSigned
      canValidate = true
    }
  }

  const showInvalidAppModal = status && isPending && isSigned && invalidSteps.length > 0

  const User = userSelector(state)
  const { online, } = state.offline

  return {
    online,
    isIssued,
    isDenied,
    readOnly,
    isSigned,
    User,
    activeStep,
    completedSteps,
    invalidSteps,
    applicationSummaryIsShowing,
    permitFailedToLoad,
    showUnlockButton     : status && isPending && isSigned,
    isInUGA              : permitIsInUGA(state, activeBurnPermitId),
    applicationStatus    : status,
    activeBurnPermit     : permitIdAndSectionIdsByPermitIdSelector(state, activeBurnPermitId),
    EstimatedFee         : permitEstimatedFee(state),
    TotalPermitTonnage   : permitTotalPermitTonnage(state),
    isSuspended          : permitIsSuspendedSelector(state),
    isRevoked            : permitIsRevokedSelector(state),
    isExpired            : permitIsExpiredSelector(state),
    OpenModalKey         : state.ui.Modal.modalKey,
    isLegacy             : activePermitIsLegacy(state),
    ShowForestHealth     : permitIsForForestHealth(state, activeBurnPermitId),
    canEditLegacyAppl    : userCanEditLegacyPermitApplInfo(state),
    canReviewFHExemption : userCanReviewFHExemption(state),
    canEditLocAfterSub   : userCanEditLocationPastSubmission(state),
    canValidate,
    showInvalidAppModal,
  }
}

const mapDispatchToProps = {
  SetPageTitle             : AppActions.setPageTitle,
  GetBurnPermit            : BurnPermitFormActions.getBurnPermit,
  GetBurnPermitDetail      : BurnPermitFormActions.getBurnPermitDetail,
  SetActiveStep            : BurnPermitFormActions.setActiveStep,
  ActiveBurnPermit         : BurnPermitFormActions.activeBurnPermit,
  ValidatePermit           : BurnPermitFormActions.validatePermit,
  DeleteApplication        : BurnPermitFormActions.deleteApplication,
  UnlockApplication        : BurnPermitFormActions.unlockApplication,
  CopyApplication          : BurnPermitFormActions.copyApplication,
  ToggleApplicationSummary : BurnPermitFormActions.toggleApplicationSummary,
  OpenModal                : UiActions.openModal,
  CloseModal               : UiActions.closeModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnPermitFormContainer))
