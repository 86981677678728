import { attr, Model, } from 'redux-orm'

export default class ReferenceDirectionType extends Model {
  static get modelName () {
    return 'ReferenceDirectionType'
  }

  toString () {
    return `${this.ReferenceDirectionTypeName}`
  }

  static endpoint () {
    return 'ReferenceDirectionTypes'
  }

  static get options () {
    return {
      idAttribute: 'ReferenceDirectionTypeId',
    }
  }

  static get fields () {
    return {
      ReferenceDirectionTypeId          : attr({ type: 'number', }),
      ReferenceDirectionTypeName        : attr(),
      ReferenceDirectionTypeDescription : attr(),
      CreateDate                        : attr(),
      UpdateDate                        : attr(),
    }
  }
}