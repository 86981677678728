import React, { useMemo, } from 'react'
import {
  Container,
  Card,
  Col,
  CardHeader,
  CardBody,
  Row,
  CardText,
} from 'reactstrap'
import { Link, } from 'react-router-dom'
import { string, } from 'prop-types'

function LoggingIn ({ loginError, }) {
  let text = 'Please wait while we log you in...'
  let title = 'Logging In'
  let actions = null
  if (loginError) {
    text = <>
      An error occurred while logging in:<br/>{loginError}<br/><br/>
      Please try logging in again or contact support if the issue persists.
    </>
    title = 'Error Logging In'
    actions = <div className={'card-actions'}>
      <Link to={'/login'} className={'btn btn-link'}>Back to Login</Link>
      <Link to={'/'} className={'btn btn-secondary'}>Go to the Home Page</Link>
    </div>
  }

  const colSizes = useMemo(() => ({ size: '6', offset: '3', }), [])

  return <Container>
    <Row className={'mt-4'}>
      <Col md={colSizes}>
        <Card className={'not-found'}>
          <CardHeader tag={'h1'}>
            {title}
          </CardHeader>
          <CardBody>
            <CardText className={'text-center'}>
              {text}
            </CardText>
            {actions}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
}

LoggingIn.propTypes = {
  loginError: string,
}

LoggingIn.defaultProps = {
  loginError: null,
}

export default React.memo(LoggingIn)