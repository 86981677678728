import { createActions, } from 'reduxsauce'
    
/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  getBurnPermitSiteInfo       : [ 'burnPermitSiteId', ],
  updateBurnPermitSite        : [ 'burnPermitSite', ],
  updateBurnPermitSiteRequest : (modelName, url, body, showSuccess) => ({
    type : 'UPDATE_RECORD_REQUEST',
    meta : {
      offline: {
        effect   : { url, init: { method: 'PUT', body: { ...body, }, }, },
        commit   : { type: 'UPDATE_BURN_PERMIT_SITE_SUCCESS', modelName, showSuccess, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  updateBurnPermitSiteSuccess: [ 'payload', 'modelName', 'showSuccess', ],
})
  
export const BurnPermitSiteTypes = Types
export default Creators