import { attr, Model, fk, many, } from 'redux-orm'
import * as Yup from 'yup'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

function requiredNumberSchema (message, min) {
  if (typeof min !== 'number') {[
    min = 1,
  ]}
  return Yup.number().min(min, message).required(message)
}

export default class BurnPermitFuel extends Model {
  static get modelName () {
    return 'BurnPermitFuel'
  }

  toString () {
    return `Burn Permit Fuel ${this.BurnPermitFuelId}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermitFuels`
  }

  static validationSchema () {

    const valSchema = {
      BurnIgnitionTypes: Yup.array()
        .min(1, 'You must choose one or more Ignition Type'),
      DuffTypeId: Yup.number()
        .when('ShowFuelsCalculator', {
          is        : true,
          then      : requiredNumberSchema('You must choose a Duff Type.'),
          otherwise : Yup.number().nullable(),
        }),
      DuffDepth: Yup.number()
        .when('ShowFuelsCalculator', {
          is        : true,
          then      : requiredNumberSchema('Duff Depth must be a number greater than or equal to 0 (zero).', 0),
          otherwise : Yup.number().nullable(),
        }),
      LitterDepth: Yup.number()
        .when('ShowFuelsCalculator', {
          is        : true,
          then      : requiredNumberSchema('Litter Depth must be a number greater than or equal to 0 (zero).', 0),
          otherwise : Yup.number().nullable(),
        }),
      Shrubs: Yup.number()
        .when('ShowFuelsCalculator', {
          is        : true,
          then      : requiredNumberSchema('Shrubs must be a number greater than or equal to 0 (zero).', 0),
          otherwise : Yup.number().nullable(),
        }),
      GrassHerb: Yup.number()
        .when('ShowFuelsCalculator', {
          is        : true,
          then      : requiredNumberSchema('Grass/Herb must be a number greater than or equal to 0 (zero).', 0),
          otherwise : Yup.number().nullable(),
        }),
      BurnPermitFuelLoadingXref: Yup.array()
        .when('ShowFuelsCalculator', {
          is   : true,
          then : Yup.array()
            .of(
              Yup.object().shape({
                TonsPerAcre      : requiredNumberSchema('You must enter a value greater than 0 (zero).', 0.1),
                BurnPermitFuelId : Yup.number().required(),
                FuelDiameterId   : Yup.number().required(),
              })
            )
            .min(1, 'You must enter one or more Fuel Loading value greather than 0 (zero).')
            .required('You must enter one or more Fuel Loading value greather than 0 (zero).'),
          otherwise: Yup.array().nullable(),
        }),
    }

    return Yup.object().shape(valSchema)
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitFuelId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      BurnPermitFuelId                 : attr(),
      OtherBurnIgnitionTypeDescription : attr(),
      LitterDepth                      : attr(),
      DuffDepth                        : attr(),
      Shrubs                           : attr(),
      GrassHerb                        : attr(),
      ConsumedTonnage                  : attr(),
      TotalTonsPerAcre                 : attr(),
      CreateBy                         : attr(),
      CreateDate                       : attr(),
      UpdateBy                         : attr(),
      UpdateDate                       : attr(),

      // Relationships
      DuffTypeId                : fk({ to: 'DuffType', relatedName: 'BurnPermitFuels', as: 'DuffType', }),
      BurnIgnitionTypes         : many({ to: 'BurnIgnitionType', relatedName: 'BurnPermitFuels', }),
      BurnPermitFuelLoadingXref : many({ to: 'FuelDiameter', relatedName: 'BurnPermitFuels', through: 'BurnPermitFuelLoadingXref', }),
    }
  }
}