import { attr, Model, } from 'redux-orm'

export default class BurnPermitStatus extends Model {
  static get modelName () {
    return 'BurnPermitStatus'
  }

  toString () {
    return `${this.BurnPermitStatusName}`
  }

  static endpoint () {
    return 'BurnPermitStatuses'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitStatusId',
    }
  }

  static get fields () {
    return {
    // Model-Specific Properties
      BurnPermitStatusId          : attr(),
      BurnPermitStatusName        : attr({ getDefault: () => '', }),
      BurnPermitStatusDescription : attr({ getDefault: () => '', }),
  
      // Audit Properties
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}