// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { func, string, array, bool, object, PropTypes, } from 'prop-types'
import { isEqual, } from 'lodash'

// Components
import Condition from './Condition'
import MultiSelectCondition from './MultiSelectCondition'

// Redux
import ConditionActions from '../../redux/BurnPermitConditionsRedux'

// Selectors
import { equipmentTypeCheckboxSelector, daysOfWeekCheckboxSelector, } from '../../selectors/selectors'
import { directionsSelectSelector, } from '../../selectors/burnPermitSiteSelectors'
import { 
  conditionValuesNewForBurnPermitSelector,
  conditionErrorsSelector,
  burnPermitConditionsSelector,
} from '../../selectors/conditionsSelectors'

class PermitConditions extends React.Component {

  constructor (props) {
    super(props)
    const { PermitConditionValues, } = this.props
    this.state = { ...this.mapSelectedValues(PermitConditionValues), }
  }

  static propTypes = {
    Conditions            : array,
    Directions            : array,
    DaysOfWeek            : array,
    Equipment             : array,
    PermitConditionValues : object,
    readOnly              : bool,
    disabled              : bool,
    onChanged             : func,
    SetConditionErrors    : func,
    ClearConditionError   : func,
    onValidityChanged     : func.isRequired,
    ConditionErrors       : PropTypes.oneOfType([ array, string, object , ]),
  }

  static defaultProps = {
    Conditions            : [],
    Directions            : [],
    DaysOfWeek            : [],
    Equipment             : [],
    PermitConditionValues : null,
    onValidityChanged     : null,
    onChanged             : null,
    ConditionErrors       : null,
    readOnly              : false,
    disabled              : false,
  }

  mapSelectedValues = values => {
    return { 
      selectedConditions        : values ? Object.keys(values).map(c => parseInt(c)) : [],
      selectedConditionValues   : values || {},
      selectedConditionvalidity : values ? Object.keys(values).reduce((res, id) => {
        res[id] = true
        return res
      }, {}) : {},
    } 
  }

  componentDidUpdate (prevProps) {
    const { PermitConditionValues, disabled, } = this.props

    // values changed
    if (!isEqual(prevProps.PermitConditionValues, PermitConditionValues)) {
      const mappedValues = this.mapSelectedValues(PermitConditionValues)
      if (!isEqual(this.state, mappedValues)) {
        this.setState({ ...mappedValues, })
      }
    }
    // This will typically happen a user begins editing the conditions 
    // of an issued permit but clicks the cancel button. When this happens,
    // reset the conditions, values and validation messages to the originals
    if (prevProps.disabled === false && disabled === true) {
      const mappedValues = this.mapSelectedValues(PermitConditionValues)
      if (!isEqual(this.state, mappedValues)) {
        this.setState({ ...mappedValues, })
      }
    }
  }

  /**
   * Event handler for when a condition is checked.
   * Adds/removes entries in selected, values, and validitiy state objs/arrays
   * @callback permitConditionChecked
   * @param {object} e - The check event
   * @param {object} e.target - The event target
   * @param {bool} e.target.checked - The checked status of the target elem
   * @param {Object} condition    The clicked condition's information
   */
  permitConditionChecked = (e, condition) => {
    const { checked, } = e.target
    const { 
      BurnPermitConditionId, 
      ParentConditionId,
      ChildConditions,
      Category,
    } = condition
    const { 
      selectedConditions,
      selectedConditionValues, 
      selectedConditionValidity, 
    } = this.state

    const newConditionValues = { ...selectedConditionValues, }
    const newConditionValidity = { ...selectedConditionValidity, }
    let newSelectedConditions = [ ...selectedConditions, ]

    if (checked) {
      // check to see this is a child condition
      const addParent = ParentConditionId && !selectedConditions.includes(ParentConditionId)
      // set up our ids to concat
      let idsToAdd = [ BurnPermitConditionId, ]
      if (addParent) {
        // assume parent conditions are always basic and add a true value
        newConditionValues[ParentConditionId] = true
        newConditionValidity[ParentConditionId] = true
        idsToAdd.push(ParentConditionId)
      }
      if (ChildConditions.length > 0) {
        // Call the redux action to set a validation message that requires
        // one of the child conditions to be checked
        this.props.SetConditionErrors({ [BurnPermitConditionId]: `You must select one or more of the following conditions: ${ChildConditions.join(', ')}`, })
      }
      else if (ParentConditionId > 0 && this.props.ConditionErrors[ParentConditionId]) {
        this.props.ClearConditionError(ParentConditionId)
      }
      // this should always be true, but prevent duplicates
      if (!selectedConditions.includes(BurnPermitConditionId)) {
        // mark the checked condition as selected
        newSelectedConditions = selectedConditions.concat(idsToAdd)
        
        // configure a default value based on the condition category
        if (Category === 'Text' || Category === 'Number') {
          // text/number conditions are invalid by default
          newConditionValidity[BurnPermitConditionId] = false
          newConditionValues[BurnPermitConditionId] = ''
        } else if (Category === 'Boolean') {
          newConditionValidity[BurnPermitConditionId] = true
          newConditionValues[BurnPermitConditionId] = true
        }
      }
    } else {
      // if this is a parent condition, deselect this condition and all children
      if (ChildConditions.length > 0) {
        this.props.ClearConditionError(BurnPermitConditionId)
        newSelectedConditions = selectedConditions.filter((id) => (id !== BurnPermitConditionId) && (!ChildConditions.includes(id)))
        ChildConditions.forEach((x) => {
          if (x in newConditionValues) {
            delete newConditionValues[x] 
          }
          if (x in newConditionValidity) {
            delete newConditionValidity[x]
          }
        })
      } else {
        // not a parent, remove the deselected condition
        newSelectedConditions = selectedConditions.filter(s => s !== BurnPermitConditionId)
        // check if there is a ParentConditionId and deselect the parent if all other children are deselected
        if (ParentConditionId > 0) {
          // get parent condition
          const parentCond = this.props.Conditions.find(c => c.BurnPermitConditionId === ParentConditionId)
          if (parentCond) {
            // get the sibling condition ids of parent condition, excluding the current one
            const siblings = parentCond.ChildConditions.filter(c => c !== BurnPermitConditionId)
            // check if selectedConditions has any other children condition ids in it
            if (newSelectedConditions.some(s => siblings.includes(s)) === false) {
              // if no other children/siblings are selected, remove the parent condition
              newSelectedConditions = newSelectedConditions.filter(s => s !== ParentConditionId)
              this.props.ClearConditionError(BurnPermitConditionId)
            }
          }
        }
      }
      // remove value and validation status
      if (BurnPermitConditionId in newConditionValidity) {
        delete newConditionValidity[BurnPermitConditionId]
      }
      if (BurnPermitConditionId in newConditionValues) {
        delete newConditionValues[BurnPermitConditionId]
      }
    }

    this.setState({
      selectedConditions        : newSelectedConditions,
      selectedConditionValues   : newConditionValues,
      selectedConditionValidity : newConditionValidity,
    })
    
    this.props.onChanged(newConditionValues)
    this.props.onValidityChanged(newConditionValidity)
  }

  conditionValidityChanged = (id, valid) => {
    if (!id || ((valid === null) || (valid === undefined))) {
      return
    }
    let nextValids = {
      ...this.state.selectedConditionValidity,
    }
    nextValids[id] = valid
    this.setState({ selectedConditionValidity: nextValids, })
    this.props.onValidityChanged(nextValids)
  }

  conditionValueChanged = (id, value) => {
    if (!id) {
      return
    }
    let nextState = {}
    if (this.state.selectedConditionValues) {
      nextState = {
        ...this.state.selectedConditionValues,
      }
    }
    nextState[id] = value
    this.setState({ 
      selectedConditionValues: nextState,
    })
    this.props.onChanged(nextState)
  }

  multiSelectValueChanged = (BurnPermitConditionId, newValue) => {
    if (!BurnPermitConditionId || !newValue) {
      return
    }
    let nextValues = {
      ...this.state.selectedConditionValues,
    }
    const oldValue = this.state.selectedConditionValues[BurnPermitConditionId]
    if (oldValue && Array.isArray(oldValue)) {
      if (oldValue.includes(newValue)) {
        nextValues[BurnPermitConditionId] = oldValue.filter(v => v !== newValue)
      }
      else if (typeof newValue === 'string') {
        nextValues[BurnPermitConditionId] = [ ...oldValue.filter(v => typeof v !== 'string'), newValue, ]
      }
      else {
        nextValues[BurnPermitConditionId] = oldValue.concat([ newValue, ])
      }
    }
    else {
      nextValues[BurnPermitConditionId] = [ newValue, ]
    }
    this.setState({ 
      selectedConditionValues: nextValues, 
    })
    this.props.onChanged(nextValues)
  }

  render () {
    const { 
      Conditions, 
      Equipment,
      Directions,
      DaysOfWeek,
      readOnly,
      disabled,
      ConditionErrors,
    } = this.props
    const { 
      selectedConditions,
      selectedConditionValues,
    } = this.state

    let markup = null
    if (Conditions && Conditions.length > 0) {
      markup = Conditions.map((c, idx) => {
        const { 
          BurnPermitConditionId,
          ParentConditionId, 
          BurnPermitConditionName, 
        } = c

        const value = selectedConditionValues[BurnPermitConditionId] !== null? selectedConditionValues[BurnPermitConditionId] : ''
        let selected = selectedConditions.includes(BurnPermitConditionId)
        if (ParentConditionId) {
          selected = selected && selectedConditions.includes(ParentConditionId)
        }
        const key = `condition-${BurnPermitConditionId}`
        const error = ConditionErrors ? ConditionErrors[BurnPermitConditionId] : null

        let condition = null
        const basicProps = {
          key,
          value,
          selected,
          error,
          readOnly,
          disabled,
          condition                : c,
          label                    : idx + 1,
          indent                   : ParentConditionId > 0,
          parentIsSelected         : selectedConditions.includes(ParentConditionId),
          onConditionChecked       : this.permitConditionChecked,
          conditionValidityChanged : this.conditionValidityChanged,
        }
        switch (BurnPermitConditionName) {
        case 'Wind direction':
          condition = <MultiSelectCondition {...basicProps} data={Directions} onValueChanged={this.multiSelectValueChanged} />
          break
        case 'Days of the week':
          condition = <MultiSelectCondition {...basicProps} data={DaysOfWeek} onValueChanged={this.multiSelectValueChanged} />
          break
        case 'Equipment requirements':
          condition = <MultiSelectCondition {...basicProps} data={Equipment} onValueChanged={this.multiSelectValueChanged} />
          break
        case 'Other conditions':
          condition = <Condition {...basicProps} onValueChanged={this.conditionValueChanged} />
          break
        default:
          condition = <Condition {...basicProps} onValueChanged={this.conditionValueChanged} />
          break
        }
        return condition
      })
    }

    return markup
  }
}

const FIRE_LINE_CONSTRUCTION = 'Fire Line Construction'

function mapStateToProps (state) {
  return {
    Conditions            : burnPermitConditionsSelector(state),
    DaysOfWeek            : daysOfWeekCheckboxSelector(state),
    Directions            : directionsSelectSelector(state),
    Equipment             : equipmentTypeCheckboxSelector(state, { category: FIRE_LINE_CONSTRUCTION, }),
    PermitConditionValues : conditionValuesNewForBurnPermitSelector(state),
    ConditionErrors       : conditionErrorsSelector(state),
  }
}

const mapDispatchToProps = {
  ClearConditionError : ConditionActions.clearConditionError,
  SetConditionErrors  : ConditionActions.setConditionErrors,
}

export default connect(mapStateToProps, mapDispatchToProps)(PermitConditions)