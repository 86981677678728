import { attr, Model, } from 'redux-orm'

export default class ForestHealthProblemType extends Model {
  static get modelName () {
    return 'ForestHealthProblemType'
  }

  toString () {
    return `${this.ForestHealthProblemTypeName}`
  }

  static endpoint () {
    return 'ForestHealthProblemTypes'
  }

  static get options () {
    return {
      idAttribute: 'ForestHealthProblemTypeId',
    }
  }

  static get fields () {
    return {
      ForestHealthProblemTypeId          : attr({ type: 'number', }),
      ForestHealthProblemTypeName        : attr(),
      ForestHealthProblemTypeDescription : attr(),
      CreateDate                         : attr(),
      UpdateDate                         : attr(),
    }
  }
}