import { attr, Model, } from 'redux-orm'

export default class BurnRequestCancellationReason extends Model {
  static get modelName () {
    return 'BurnRequestCancellationReason'
  }

  toString () {
    return `${this.BurnRequestCancellationReason}`
  }

  static endpoint () {
    return 'BurnRequestCancellationReasons'
  }

  static get options () {
    return {
      idAttribute: 'BurnRequestCancellationReasonId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnRequestCancellationReasonId          : attr(),
      BurnRequestCancellationReason            : attr({ getDefault: () => '', }),
      BurnRequestCancellationReasonDescription : attr({ getDefault: () => '', }),
  
      // Audit Properties
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}