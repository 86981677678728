import {
  createReducer,
  createActions,
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  resetApplicantInfo         : null,
  getApplicantInfoData       : null,
  setSelectedAgencyId        : [ 'agencyId', ],
  setSelectedAgentId         : [ 'personId', ],
  setSelectedLandownerId     : [ 'personId', ],
  setTargetLandowner         : [ 'targetLandowner', ],
  updateApplicantInfo        : [ 'info', ],
  setSelectedApplicantInfo   : [ 'applicantInfo', ],
  setApplicantSectionLoading : [ 'loading', ],
  setApplicantInfo           : [ 'BurnPermitId', ],
  setMailToAgent             : [ 'mailToAgent', ],
  setContactData             : [ 'stateProp', 'value', ],
})

export const ApplicantInfoTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  agentId                      : '',
  landownerId                  : '',
  agencyId                     : '',
  mailToAgent                  : null,
  targetLandowner              : '',
  sectionIsLoading             : false,
  BurnerPermitAddressId        : '',
  BurnerPermitPrimaryPhoneId   : '',
  BurnerPermitSecondaryPhoneId : '',
  AgentPermitAddressId         : '',
  AgentPermitPrimaryPhoneId    : '',
  AgentPermitSecondaryPhoneId  : '',
}

const resetApplicantInfo = () => {
  return { ...INITIAL_STATE, }
}

const getApplicantInfoData = () => {
  const newState = { ...INITIAL_STATE, }
  newState.sectionIsLoading = true
  return newState
}

const setApplicantSectionLoading = (state, { loading, }) => {
  const newState = { ...state, }
  newState.sectionIsLoading = loading || false
  return newState
}

const setTargetLandowner = (state, { targetLandowner, }) => {
  const newState = { ...state, }
  newState.targetLandowner = targetLandowner || ''
  newState.mailToAgent = targetLandowner === 'Agency' ? true : newState.mailToAgent
  newState.BurnerPermitAddressId = ''
  newState.BurnerPermitPrimaryPhoneId = ''
  newState.BurnerPermitSecondaryPhoneId = ''
  return newState
}

const setSelectedAgencyId = (state, { agencyId, }) => {
  const newState = { ...state, }
  newState.targetLandowner = 'Agency'
  if (agencyId !== state.agencyId) {
    newState.agencyId = agencyId || ''
  }
  newState.BurnerPermitAddressId = ''
  newState.BurnerPermitPrimaryPhoneId = ''
  newState.BurnerPermitSecondaryPhoneId = ''
  return newState
}

const setSelectedAgentId = (state, { personId, }) => {
  const newState = { ...state, }
  if (personId !== state.agentId) {
    newState.agentId = personId || ''
  }
  newState.AgentPermitAddressId = ''
  newState.AgentPermitPrimaryPhoneId = ''
  newState.AgentPermitSecondaryPhoneId = ''
  return newState
}

const setSelectedLandownerId = (state, { personId, }) => {
  const newState = { ...state, }
  newState.targetLandowner = 'Person'
  if (personId !== state.landownerId) {
    newState.landownerId = personId || ''
  }
  newState.BurnerPermitAddressId = ''
  newState.BurnerPermitPrimaryPhoneId = ''
  newState.BurnerPermitSecondaryPhoneId = ''
  return newState
}

const setMailToAgent = (state, { mailToAgent, }) => {
  const newState = { ...state, }
  if (mailToAgent !== state.mailToAgent) {
    newState.mailToAgent = mailToAgent
  }
  if (newState.mailToAgent === false) {
    newState.agentId = ''
  }
  newState.AgentPermitAddressId = ''
  newState.AgentPermitPrimaryPhoneId = ''
  newState.AgentPermitSecondaryPhoneId = ''
  return newState
}

const setContactData = (state, { stateProp, value, }) => {
  const newState = { ...state, }
  newState[stateProp] = value || ''
  return newState
}

const setSelectedApplicantInfo = (state, { applicantInfo, }) => {
  const newState = { ...state, }
  newState.targetLandowner = applicantInfo.landownerId > 0 ? 'Person' : applicantInfo.agencyId > 0 ? 'Agency' : ''
  newState.agentId = applicantInfo.agentId || ''
  newState.landownerId = applicantInfo.landownerId || ''
  newState.agencyId = applicantInfo.agencyId || ''
  newState.mailToAgent = applicantInfo.mailToAgent || null
  newState.BurnerPermitAddressId = ''
  newState.BurnerPermitPrimaryPhoneId = ''
  newState.BurnerPermitSecondaryPhoneId = ''
  newState.AgentPermitAddressId = ''
  newState.AgentPermitPrimaryPhoneId = ''
  newState.AgentPermitSecondaryPhoneId = ''
  return newState
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer({ ...INITIAL_STATE, }, {
  [Types.RESET_APPLICANT_INFO]          : resetApplicantInfo,
  [Types.SET_SELECTED_AGENCY_ID]        : setSelectedAgencyId,
  [Types.SET_SELECTED_AGENT_ID]         : setSelectedAgentId,
  [Types.SET_SELECTED_LANDOWNER_ID]     : setSelectedLandownerId,
  [Types.SET_MAIL_TO_AGENT]             : setMailToAgent,
  [Types.SET_SELECTED_APPLICANT_INFO]   : setSelectedApplicantInfo,
  [Types.SET_TARGET_LANDOWNER]          : setTargetLandowner,
  [Types.GET_APPLICANT_INFO_DATA]       : getApplicantInfoData,
  [Types.SET_APPLICANT_SECTION_LOADING] : setApplicantSectionLoading,
  [Types.SET_CONTACT_DATA]              : setContactData,
})