import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'

export default class Phone extends Model {
  static get modelName () {
    return 'Phone'
  }

  toString () {
    // Remove non integer values
    let number = ('' + this.PhoneNumber).replace(/\D/g, '')
    // Match the pieces of the phone number
    const match = number.match(/^(\d{3})(\d{3})(\d{4})$/)
    // If it parsed successfully
    if (match) {
      // Format the phone number
      number = '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    // append the extension is there is one
    if (this.PhoneExtension && this.PhoneExtension.length > 0) {
      number += ' x' + this.PhoneExtension
    }
    return number
  }

  static endpoint () {
    return 'Phones'
  }

  static getValidationSchema () {
    const {
      PhoneNumber,
      PhoneTypeId,
    } = this.fields

    return Yup.object().shape({
      PhoneNumber: Yup.number()
        .typeError('Phone number must be a number')
        .max(PhoneNumber.opts.max, `Phone number must be ${PhoneNumber.opts.max} characters or less`)
        .required('Please enter a phone number'),
      PhoneExtension: Yup.number()
        .nullable()
        .typeError('Extension must be a number'),
      PhoneTypeId: Yup.number()
        .min(PhoneTypeId.opts.min, 'Please select a phone type')
        .required('Please select a phone type'),
      ReceivesAlerts: Yup.bool(),
    })
  }

  static get options () {
    return {
      idAttribute: 'PhoneId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PhoneId              : attr(),
      CreateDate           : attr(),
      CreateBy             : attr(),
      UpdateDate           : attr(),
      UpdateBy             : attr(),
      // Model-Specific Properties
      PhoneNumber          : attr({ getDefault: () => '', max: 9999999999, }),
      PhoneExtension       : attr(),
      PhoneNumberConfirmed : attr(),
      ReceivesAlerts       : attr(),
      Active               : attr({ getDefault: () => true, }),
  
      // Relationships
      PhoneTypeId: attr({
        getDefault : () => 1,
        min        : 1, 
      }),
      PhoneType: fk({ 
        to          : 'PhoneType', 
        as          : 'PhoneType', 
        relatedName : 'Phones', 
      }),

      // Reverse Fields
      // Agencies 
    }
  }
}