import {
  createReducer,
  createActions,
} from 'reduxsauce'

import BurnPermit from '../models/BurnPermit'
import { dateFormatter, getNow, } from '../utilities'

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()

/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  togglePermitMap  : null,
  getMyBurnPermits : () => ({
    type : 'GET_MY_BURN_PERMITS',
    meta : {
      offline: {
        effect: {
          url: BURN_PERMIT_ENDPOINT,
        },
        commit   : { type: 'SEARCH_BURN_PERMITS_SUCCESS', },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  closeDownloadModal         : null,
  downloadBurnPermits        : null,
  doneDownloadingBurnPermits : null,
  setDownloadStatus          : [ 'target', 'status', ],
  resetDownloadStatus        : null,
})
  
export const BurnPermitListTypes = Types
export default Creators
  
/* ------------- Initial State ------------- */
  
export const INITIAL_STATE = {
  isMapShowing         : false,
  lastDownload         : '',
  lastDownloadDuration : '',
  isDownloading        : false,
  DownloadStatuses     : {
    BurnPermitsDownloaded   : null,
    StatusHistoryDownloaded : null,
    AreaInfoDownloaded      : null,
    ApplicantInfoDownloaded : null,
    LocationInfoDownloaded  : null,
    SiteInfoDownloaded      : null,
    PileInfoDownloaded      : null,
    FuelsInfoDownloaded     : null,
    ConditionsDownloaded    : null,
    MapDataDownloaded       : null,
  },
}
    
/* ------------- Reducers ------------- */

export const togglePermitMap = (state) => {
  return { ...state, isMapShowing: !state.isMapShowing, }
}

export const closeDownloadModal = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = false
  return newState
}

export const downloadBurnPermits = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = true
  newState.lastDownloadRaw = new Date()
  return newState
}

export const doneDownloadingBurnPermits = (state) => {
  const newState = Object.assign({}, state)
  newState.isDownloading = false
  const keys = Object.keys(newState.DownloadStatuses)
  keys.forEach(k => {
    newState.DownloadStatuses[k] = null
  })
  newState.lastDownloadFormatted = dateFormatter(newState.lastDownloadRaw, 'ddd MMM D, YYYY @ HH:mm:ss A')
  let duration = getNow().diff(newState.lastDownloadRaw, 'second')
  let seconds = duration, minutes = ''
  if (duration > 60) {
    seconds = duration % 60
    minutes = parseInt(duration / 60)
    minutes = `${minutes} minute${minutes > 1 ? 's' : ''} `
  }
  newState.lastDownloadDuration = `${minutes}${seconds} seconds`
  return newState
}

export const resetDownloadStatus =  (state) => {
  const newState = Object.assign({}, state)
  newState.BurnPermitsDownloaded   = null
  const keys = Object.keys(newState.DownloadStatuses)
  keys.forEach(k => {
    newState.DownloadStatuses[k] = null
  })
  return newState
}

export const setDownloadStatus = (state, { target, status, }) => {
  const newState = Object.assign({}, state)
  newState.DownloadStatuses[`${target}Downloaded`] = status
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_PERMIT_MAP]             : togglePermitMap,
  [Types.RESET_DOWNLOAD_STATUS]         : resetDownloadStatus,
  [Types.SET_DOWNLOAD_STATUS]           : setDownloadStatus,
  [Types.CLOSE_DOWNLOAD_MODAL]          : closeDownloadModal,
  [Types.DOWNLOAD_BURN_PERMITS]         : downloadBurnPermits,
  [Types.DONE_DOWNLOADING_BURN_PERMITS] : doneDownloadingBurnPermits,
})