import { attr, Model, } from 'redux-orm'

export default class ForestType extends Model {
  static get modelName () {
    return 'ForestType'
  }

  toString () {
    return `${this.ForestTypeName}`
  }

  static endpoint () {
    return 'ForestTypes'
  }

  static getDataTablesColumns () {
    return [
      {
        title : 'Forest Type Name',
        data  : 'ForestTypeName',
      },
      {
        title : 'Detailed Forest Type',
        data  : 'ForestTypeExtendedName',
      },
      {
        title : 'Forest Type/Fuel Description',
        data  : 'ForestTypeDescription',
      },
    ]
  }

  static get options () {
    return {
      idAttribute: 'ForestTypeId',
    }
  }
  
  static get fields () {
    return {
      // Common Properties
      ForestTypeId : attr(),
      CreateDate   : attr(),
      UpdateDate   : attr(),
  
      // Model-Specific Properties
      ForestTypeName         : attr(),
      ForestTypeDescription  : attr(),
      ForestTypeLocationName : attr(),
      ForestTypeExtendedName : attr(),
    }
  }
}