import { createSelector, } from 'reselect'

const dashboardSelector = state => state.Dashboard

export const tileLinkSelector = createSelector(
  dashboardSelector,
  state => state.TileLinks
)

export const dashMsgsSelector = createSelector(
  dashboardSelector,
  ({ Messages = {}, }) => {
    if (!Messages) {
      Messages = {}
    }
    return {
      Alert   : Messages.Alert || null,
      Warning : Messages.Warning || null,
      Info    : Messages.Info || null,
    }
  }
)