// Libraries
import React from 'react'
import * as Yup from 'yup'
import { Formik, Field, Form, } from 'formik'
import { bool, string, func, } from 'prop-types'
import { Col, Label, Row, Button, } from 'reactstrap'

// Utilities
import { isAfter, } from '../../utilities'

// Components
import ValidatingField from '../FormControls/ValidatingField'


const PERMIT_SUSPENSION_SCHEMA = Yup.object().shape({
  SuspensionStartDate: Yup.date()
    .label('Suspension Start Date')
    .typeError('Suspension Start Date must be a valid date.')
    .test('start-or-end-hasvalue',
      'Either Suspension Start or End Date must be specified.',
      function (value) {
        if (value || this.parent.SuspensionEndDate) {
          return true
        }
        return false
      }
    )
    .test('start-before-end',
      'Suspension Start Date must be before Suspension End Date.',
      function (value) {
        if (value && this.parent.SuspensionEndDate) {
          return isAfter(value, this.parent.SuspensionEndDate)
        }
        return true
      }
    ),
  SuspensionEndDate: Yup.date()
    .label('Suspension End Date')
    .typeError('Suspension End Date must be a valid date.')
    .test('end-after-start',
      'Suspension End Date must be after Suspension Start Date.',
      function (value) {
        if (value && this.parent.SuspensionStartDate) {
          return isAfter(this.parent.SuspensionStartDate, value)
        }
        return true
      }
    ),
  SuspensionComment: Yup.string(),
})


const SuspensionForm = (props) => {
  const { 
    suspensionStartDate, 
    suspensionEndDate, 
    suspensionComment,
    readOnly, 
    onSubmit, 
    onEndSuspension,
  } = props

  const canClear = !readOnly && (!!suspensionStartDate || !!suspensionEndDate)

  return <Formik
    validationSchema={PERMIT_SUSPENSION_SCHEMA}
    initialValues={{
      SuspensionStartDate : suspensionStartDate || '',
      SuspensionEndDate   : suspensionEndDate || '',
      SuspensionComment   : suspensionComment || '',
    }}
    enableReinitialize={true}
    onSubmit={onSubmit}
    validateOnMount={true}
  >
    
    <Form>
      <p>Suspend a Burn Permit when burning should be <b>temporarily</b> restricted. Make sure to search for any active Burn Requests that may need to be denied as part of this suspension.</p>
      <Row className={'mb-2'}>
        <Col>
          <Label for={'SuspensionStartDate'}>Suspension Start Date</Label>
          <Field
            type={'date'}
            id={'SuspensionStartDate'}
            name={'SuspensionStartDate'}
            readOnly={readOnly}
            component={ValidatingField}
          />
        </Col>
        <Col>
          <Label for={'SuspensionEndDate'}>Suspension End Date</Label>
          <Field
            type={'date'}
            id={'SuspensionEndDate'}
            name={'SuspensionEndDate'}
            readOnly={readOnly}
            component={ValidatingField}
          />
        </Col>
      </Row>
      <Row className={'mb-2'}>
        <Label for={'SuspensionComment'}>Reason for Suspension?</Label>
        <Field
          type={'textarea'}
          id={'SuspensionComment'}
          name={'SuspensionComment'}
          readOnly={readOnly}
          component={ValidatingField}
        />
      </Row>
      {
        !readOnly &&
          <Row className={'mb-2'}>
            <Col>
              <Button type={'submit'} className={'btn-primary float-right'}>Save Suspension</Button>
              { canClear && <Button className={'btn-danger'} onClick={onEndSuspension}>End Suspension</Button> }
            </Col>
          </Row>
      }
    </Form>
  </Formik>
}

SuspensionForm.propTypes = {
  readOnly            : bool,
  suspensionStartDate : string,
  suspensionEndDate   : string,
  suspensionComment   : string,
  onSubmit            : func,
  onEndSuspension     : func,
}

SuspensionForm.defaultProps = {
  readOnly            : false,
  suspensionStartDate : '',
  suspensionEndDate   : '',
  suspensionComment   : '',
  onSubmit            : () => {},
  onEndSuspension     : () => {},
}

export default SuspensionForm