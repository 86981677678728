// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Formik, Field, } from 'formik'
import { isEmpty, } from 'lodash'
import { FormFeedback, Row, Col, Input, Label, Card, CardBody, } from 'reactstrap'
import { bool, string, number, func, array, object, PropTypes, } from 'prop-types'

// Reducers
import BurnPermitFuelsActions from '../../redux/BurnPermitFuelsRedux'

// Components
import BurnPermitFormSection from './FormSection'
import Effect from '../Effect'
import AuditData from '../AuditData'
import {
  RequiredLabel,
  ValidatingField,
  CheckBoxes,
  FuelDiameters,
  Select,
} from '../FormControls'

// Selectors
import { 
  burnIgnitionTypesCheckboxSelector,
  duffTypeSelectSelector,
  fuelsInfoByIdSelector,
  networkStateSelector,
} from '../../selectors/selectors'

// Utilities
import retry from '../../utilities/retry'
import stopEvent from '../../utilities/stopEvent'

// Models
import BurnPermitFuel from '../../models/BurnPermitFuel'
import { activeBurnPermitStateSelector, } from '../../selectors/burnPermitSelectors'


class BurnFuelsSection extends React.Component {
  constructor (props) {
    super(props)

    this.validationSchema = BurnPermitFuel.validationSchema()
    this.onFormChange = this.onFormChange.bind(this)
    this.validateSection = this.validateSection.bind(this)
  }

  static propTypes = {
    online                 : bool,
    // This prop is passed to the <BurnPermitFormSection /> component
    title                  : string.isRequired,
    sectionId              : number.isRequired,
    DuffTypes              : array,
    FuelDiams              : array,
    FuelsInfo              : object,
    api                    : object,
    burnPermitFuelId       : number,
    BurnIgnitionTypes      : array,
    validationSchema       : object,
    UpdateBurnPermitFuels  : func,
    GetBurnPermitFuelsInfo : func,
    readOnly               : bool,
    TotalPermitTonnage     : PropTypes.oneOfType([ number, string, ]),
    EstimatedFee           : PropTypes.oneOfType([ number, string, ]),
    isActive               : bool,
  }

  static defaultProps = {
    readOnly: false,
  }

  state = {
    otherDescError : '',
    isValid        : '',
  }

  calcInputLabels = 'pl-0 pl-md-4 mt-2'
  calcInputRows = 'my-2'

  componentDidMount () {
    const { GetBurnPermitFuelsInfo, online, burnPermitFuelId, isActive, } = this.props
    if (online && isActive) {
      GetBurnPermitFuelsInfo(burnPermitFuelId)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.GetBurnPermitFuelsInfo(this.props.burnPermitFuelId)
    }
  }

  async validateSection ({ evt, submit = true, }) {
    if (!this.formik) {
      return await retry(this.validateSection, { submit, }, 100)
    }
    stopEvent(evt)
    let isValid = false
    if (this.formik) {
      isValid = await this.formIsValid()
      if (isValid && submit) {
        await this.formik.submitForm()
      }
    }
    return isValid
  }

  formIsValid = async () => {
    const errs = await this.formik.validateForm()
    const otherDescError = this.checkIfOtherDescriptionIsRequired()
    const inputsWithError = Object.keys(errs)
    const isValid = (!errs || inputsWithError.length === 0) && !otherDescError
    if (inputsWithError.length > 0) {
      for (let i = 0, len = inputsWithError.length; i < len; i++) {
        if (!this.formik.touched[inputsWithError[i]]) {
          this.formik.setFieldTouched(inputsWithError[i])
        }
      }
    }
    return isValid
  }

  checkIfOtherDescriptionIsRequired = () => {
    const { BurnIgnitionTypes, } = this.props
    const { values: formValues, } = this.formik
    const otherIgnType = BurnIgnitionTypes.some(b => b.Text === 'Other' && formValues.BurnIgnitionTypes.includes(b.Value))
    // If 'Other' Ignition Type is chosen
    // Make sure a description is entered
    let otherDescError = ''
    if (otherIgnType && !formValues.OtherBurnIgnitionTypeDescription) {
      otherDescError = 'You must enter a description for the \'Other\' Ignition Type or uncheck \'Other\''
    }
    if (otherDescError !== this.state.otherDescError) {
      this.setState({ otherDescError, })
    }
    return !!otherDescError
  }

  submit = values => {
    this.props.UpdateBurnPermitFuels(values)
  }

  /**
   * Sums the values of all fuel diameter inputs set, the Shrub tonnage per acre, and Grass tonnage per acre
   */
  calculateTonnage = () => {
    if (!this.formik) {
      return
    }
    let tonsPerAcre = 0.0
    const { Shrubs, GrassHerb, BurnPermitFuelLoadingXref, } = this.formik.values
    let fuelsTotal = BurnPermitFuelLoadingXref
      .map(f => f.TonsPerAcre)
      .reduce((a, b) => {
        return a + b
      }, tonsPerAcre)
    tonsPerAcre = fuelsTotal + (Shrubs || 0.0) + (GrassHerb || 0.0)
    this.formik.setFieldValue('TotalTonsPerAcre', tonsPerAcre.toFixed(1))
  }

  /**
   * When a value is entered, changed, or removed from any Fuel Diameter input
   * this will update the state property array to include the configured values
   */
  onFuelDiamChange = e => {
    
    const thisFuelDiamId = parseInt(e.target.dataset.id)
    const { BurnPermitFuelLoadingXref, } = this.formik.values
    const fuelDiams = [ ...BurnPermitFuelLoadingXref, ]

    // If it's already in the array
    const fuelDiamIndex = fuelDiams.map(f => f.FuelDiameterId).indexOf(thisFuelDiamId)
    if (fuelDiamIndex > -1) {
      // Remove it
      fuelDiams.splice(fuelDiamIndex, 1,)
    }

    // If there's a value to calculate, add it the array
    if (e.target.value) {
      fuelDiams.push({
        TonsPerAcre      : parseFloat(e.target.value),
        BurnPermitFuelId : this.props.burnPermitFuelId,
        FuelDiameterId   : thisFuelDiamId,
      })
    }
    this.formik.setFieldValue('BurnPermitFuelLoadingXref', fuelDiams)
  }

  onFormChange (changedEntries) {
    if (changedEntries.every(e => e[0] === 'TotalTonsPerAcre')) {
      return
    }
    
    this.validateSection({ submit: false, }).then(isValid => {
      this.setState({ isValid, }, () => this.calculateTonnage())
    })
  }

  setFormikNode = node => this.formik = node

  render () {
    const {
      readOnly,
      FuelsInfo,
      BurnIgnitionTypes,
    } = this.props

    let form = null
    if (!isEmpty(FuelsInfo)) {
      form = <Formik
        initialValues={{ ...FuelsInfo, }}
        enableReinitialize={true}
        validationSchema={this.validationSchema}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
      >
        {({ values, errors, }) => (
          <>
            <Effect values={values} onChange={this.onFormChange} />
            <Card className={'w-100 mb-2 mx-1'}>
              <CardBody>
                <Row>
                  <Col>
                    <Field name={'BurnIgnitionTypes'}>
                      {({ field, form, }) => (
                        <CheckBoxes
                          form={form}
                          inputName={field.name} 
                          CheckboxLabel={'Ignition Types'}
                          CheckboxData={BurnIgnitionTypes}
                          OtherInputName={'OtherBurnIgnitionTypeDescription'}
                          OtherDescError={this.state.otherDescError}
                          readOnly={readOnly}
                          required={true}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                { 
                  values.ShowFuelsCalculator && <>
                    <Row className={'mt-2'}>
                      <Col>
                        <h4>Broadcast/Natural estimated gross fuel loading</h4>
                        <p>Enter values to the nearest tenth.</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={'12'} md={'6'}>
                        <Row>
                          <Col>
                            <b style={{ 'borderBottom': '1px solid #777', }}>Diameter of Fuel (inches)</b>
                          </Col>
                          <Col>
                            <b style={{ 'borderBottom': '1px solid #777', }}>Tons/Acre (0.1 or greater)</b>
                          </Col>
                        </Row>
                        { 
                          Array.isArray(errors.BurnPermitFuelLoadingXref)
                            ? errors.BurnPermitFuelLoadingXref
                              .filter(e => !!e)
                              .map(e => {
                                const err = Object.values(e).join('\n\n')
                                return <>
                                  <Input value={!!err} invalid={!!err} type={'hidden'}/>
                                  <FormFeedback>{err}</FormFeedback>
                                </>
                              })
                            : errors.BurnPermitFuelLoadingXref
                              ? <>
                                <Input value={!!errors.BurnPermitFuelLoadingXref} invalid={!!errors.BurnPermitFuelLoadingXref} type={'hidden'}/>
                                <FormFeedback>{errors.BurnPermitFuelLoadingXref}</FormFeedback>
                              </>
                              : null
                        }
                        <FuelDiameters
                          BurnPermitFuelLoadingXref={values.BurnPermitFuelLoadingXref}
                          inputLabelsClass={this.calcInputLabels}
                          inputRowsClass={this.calcInputRows}
                          onChange={this.onFuelDiamChange}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col xs={'12'} md={'6'}>
                        <Row className={'d-none d-md-block'}><Col>&nbsp;</Col></Row>
                        <Row className={`${this.calcInputRows} d-none d-md-block`}>&nbsp;</Row>
                        <Row className={this.calcInputRows}>
                          <Col>
                            <RequiredLabel labelFor={'Shrubs'} className={this.calcInputLabels}>Shrubs (tons/acre)</RequiredLabel>
                          </Col>
                          <Col>
                            <Field
                              type={'number'}
                              name={'Shrubs'}
                              min={0}
                              step={0.1}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </Col>
                        </Row>
                        <Row className={this.calcInputRows}>
                          <Col>
                            <RequiredLabel for={'GrassHerb'} className={this.calcInputLabels}>Grass/Herb (tons/acre)</RequiredLabel>
                          </Col>
                          <Col>
                            <Field
                              name={'GrassHerb'}
                              type={'number'}
                              min={0}
                              step={0.1}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </Col>
                        </Row>
                        <Row className={this.calcInputRows}>
                          <Col>
                            <RequiredLabel for={'duff-type'} className={this.calcInputLabels}>Duff Type</RequiredLabel>
                          </Col>
                          <Col>
                            <Field name={'DuffTypeId'}>
                              {({ field, }) => (
                                <Select
                                  id={'duff-type'}
                                  required={true}
                                  items={this.props.DuffTypes}
                                  propertyName={field.name}
                                  onChange={field.onChange}
                                  selectedValue={field.value}
                                  errorMessage={errors[field.name]}
                                  readOnly={readOnly}
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row className={this.calcInputRows}>
                          <Col>
                            <RequiredLabel for={'DuffDepth'} className={this.calcInputLabels}>Duff depth (inches)</RequiredLabel>
                          </Col>
                          <Col>
                            <Field
                              name={'DuffDepth'}
                              type={'number'}
                              min={0}
                              step={0.1}
                              invalid={!!errors.DuffDepth}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </Col>
                        </Row>
                        <Row className={this.calcInputRows}>
                          <Col>
                            <RequiredLabel for={'LitterDepth'} className={this.calcInputLabels}>Litter depth (inches)</RequiredLabel>
                          </Col>
                          <Col>
                            <Field
                              name={'LitterDepth'}
                              type={'number'}
                              min={0}
                              step={0.1}
                              invalid={!!errors.LitterDepth}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={'12'}>
                        <Row className={'mt-3'}>
                          <Col xs={12} sm={'8'}>
                            <Label
                              for={'total-tons'}
                              className={'mr-2 d-block d-sm-flex flex-column align-items-bottom text-left text-sm-right'}
                            >
                              Total tons per Acre
                              <br/>
                              <small>(auto-calculated sum of tonnages including &gt; 3 inch rotten fuel, shrub, grass/herb)</small>
                            </Label>
                          </Col>
                          <Col xs={12} sm={'4'} className={'d-flex flex-column justify-content-end pb-2'}>
                            <Input id={'total-tons'} readOnly value={values.TotalTonsPerAcre} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <AuditData {...values} />
                    </Row>
                    <Row className={'mt-4'}>
                      <b style={{ 'margin': 'auto', }}>Broadcast and Natural Prescribed Burning May Require a Burn Plan for Permit Issuance</b>
                    </Row>
                  </>
                }
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    }
    
    return <BurnPermitFormSection
      {...this.props}
      validateSection={this.validateSection}
      isValid={typeof this.state.isValid === 'boolean' ? this.state.isValid : ''}
    >
      { form }
    </BurnPermitFormSection>
  }
}

function mapStateToProps (state, props) {
  const { online, } = networkStateSelector(state)
  const { activeStep, } = activeBurnPermitStateSelector(state)
  return {
    online,
    isActive          : activeStep === props.sectionId,
    BurnIgnitionTypes : burnIgnitionTypesCheckboxSelector(state),
    DuffTypes         : duffTypeSelectSelector(state),
    FuelsInfo         : fuelsInfoByIdSelector(state, props.burnPermitFuelId),
  }
}

const mapDispatchToProps = {
  GetBurnPermitFuelsInfo : BurnPermitFuelsActions.getBurnPermitFuelsInfo,
  UpdateBurnPermitFuels  : BurnPermitFuelsActions.updateBurnPermitFuels,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnFuelsSection)