import { createActions, createReducer, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getPermitConditions       : [ 'burnPermitId', ],
  setPermitForConditions    : [ 'burnPermitId', ],
  setActivePermitConditions : [ 'conditions', 'updateIssuedConditions', ],
  setContactConditions      : [ 'contactObj', 'updateIssuedContacts', ],
  setConditionErrors        : [ 'errors', ],
  clearConditionError       : [ 'conditionId', ],
})

export const PermitConditionTypes = Types
export default Creators

const INITIAL_STATE = {
  conditionErrors : {},
  burnPermitId    : '',
}

const setPermitForConditions = (state, { burnPermitId, }) => {
  const newState = { ...state, }
  newState.burnPermitId = burnPermitId || ''
  return newState
}

const setConditionErrors = (state, { errors, }) => {
  const newState = { ...state, }
  newState.conditionErrors = errors || {}
  return newState
}

const clearConditionError = (state, { conditionId, }) => {
  const newState = { ...state, }
  delete newState.conditionErrors[conditionId]
  return newState
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_CONDITION_ERROR]     : clearConditionError,
  [Types.SET_CONDITION_ERRORS]      : setConditionErrors,
  [Types.SET_PERMIT_FOR_CONDITIONS] : setPermitForConditions,
})