// Libraries
import React from 'react'
import { Row, Col, Card, CardBody, Label, } from 'reactstrap'
import { object, } from 'prop-types'

// Components
import MailTo from './MailTo'
import TelPhone from './TelPhone'


const RegionCard = props => {
  if (!props.region) {
    return <Row><Col xs={12}>No region data available.</Col></Row>
  }
  const {
    RegionName,
    RegionPhone,
    RegionAddress,
    RegionEmail,
    RegionCounties,
  } = props.region
  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <h2>{`${RegionName} Region`}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7} className={'mt-3'}>
            <Label className={'font-weight-bold mb-0'}>Email</Label>
            <div className={'my-2 pl-2'}>
              <MailTo email={RegionEmail} subject={'Burn Portal Question'} />
            </div>
            <Label className={'font-weight-bold mt-2 mb-0'}>Phone</Label>
            <div className={'my-2 pl-2'}>
              <TelPhone phoneNumber={RegionPhone} />
            </div>
            <Label className={'font-weight-bold mt-2 mb-2'}>Address</Label>
            <div
              className={'pl-2'}
              style={{ whiteSpace: 'pre', }}
            >
              {RegionAddress}
            </div>
          </Col>
          <Col xs={12} md={5} className={'mt-3'}>
            <h4>Counties Included</h4>
            <ul>
              {
                RegionCounties.map((c, idx) => (
                  <li key={`region-${idx}`}>
                    {c.CountyName}
                  </li>
                ))
              }
            </ul>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

RegionCard.propTypes = {
  region: object,
}

RegionCard.defaultProps = {
  region: null,
}

export default RegionCard