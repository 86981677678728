// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, } from 'react-redux'
import { Button, } from 'reactstrap'
import { string, bool, number, PropTypes, } from 'prop-types'

// Components
import ConfirmationModal from '../../../../ConfirmationModal'

// Reducers
import UiActions from '../../../../../redux/UiRedux'
import BurnPermitPileGroupActions from '../../../../../redux/BurnPermitPileGroupRedux'


const MODAL_KEY = 'DELETE_PILE_GROUP'

const DeletePile = props => {
  const {
    disabled,
    pileGroupName,
    pileGroupId,
    hideButton,
  } = props

  const dispatch = useDispatch()

  const openModal = useCallback(() => dispatch(UiActions.openModal(MODAL_KEY)), [ dispatch, ])

  const submit = useCallback(() => {
    dispatch(BurnPermitPileGroupActions.deletePileGroup(pileGroupId))
  }, [ dispatch, pileGroupId, ])

  let btn = <Button
    color={'danger'}
    size={'sm'}
    disabled={disabled}
    onClick={openModal}
    data-pile-group-id={pileGroupId}
  >
    Delete
  </Button>
  if (hideButton) {
    btn = null
  }

  return <>
    {btn}
    <ConfirmationModal
      modalKey={MODAL_KEY}
      modalTitle={'Delete Pile Group'}
      modalBody={<p>Are you sure you want to delete Pile Group <b>{pileGroupName}</b>?</p>}
      submitAction={submit}
      submitArgs={[ pileGroupId, ]}
    />
  </>
}

DeletePile.propTypes = {
  pileGroupName : string,
  pileGroupId   : PropTypes.oneOfType([ number, string, ]),
  disabled      : bool,
  hideButton    : bool,
}

export default React.memo(DeletePile)