import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import { Button, } from 'reactstrap'
import { string, } from 'prop-types'

// Selectors
import { lastRouteSelector, } from '../../selectors/appSelectors'
import { isModalOpen, } from '../../selectors/uiSelectors'

// Components
import ConfirmationModal from '../ConfirmationModal'

// Redux
import UiActions from '../../redux/UiRedux'
import { userIsDNR, } from '../../selectors/userSelectors'

const modalKey = 'CANCEL_COMPLAINT'

const CustomerInteraction = props => {
  const { interactionType, } = props
  // Selectors
  const lastRoute = useSelector(lastRouteSelector)
  const isOpen = useSelector(state => isModalOpen(state, modalKey))
  const userIsDnr = useSelector(userIsDNR)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // UI Callbacks
  const cancelInteraction = useCallback(() => {
    dispatch(UiActions.openModal(modalKey))
  }, [ dispatch, ])

  const confirmCancel = useCallback(() => {
    dispatch(UiActions.closeModal())
    navigate(lastRoute)
  }, [ dispatch, navigate, lastRoute, ])

  if (userIsDnr) {
    return null
  }

  const markup = <>
    <ConfirmationModal
      cancelActionLabel={'No'}
      modalKey={modalKey}
      modalTitle={`Cancel ${interactionType}`}
      modalBody={`Are you sure you want to cancel your ${interactionType}?`}
      submitAction={confirmCancel}
      submitActionLabel={'Yes'}
      showModal={isOpen}
    />
    <Button
      color={'primary'}
      onClick={cancelInteraction}
    >Cancel</Button>
  </>
  return markup
}

CustomerInteraction.propTypes = {
  interactionType: string,
}

export default React.memo(CustomerInteraction)
