import { combineReducers, } from 'redux'
import { reducer as orm, } from './OrmRedux'
import { reducer as app, } from './AppRedux'
import { reducer as Map, } from './MapRedux'
import { reducer as ui, } from './UiRedux'
import { reducer as ApplicantInfo, } from './ApplicantInfoSectionRedux'
import { reducer as BurnPermitList, } from './BurnPermitListRedux'
import { reducer as BurnPermitSearch, } from './BurnPermitSearchRedux'
import { reducer as PermitConditions, } from './BurnPermitConditionsRedux'
import { reducer as BurnPermitForm, } from './BurnPermitFormRedux'
import { reducer as BurnPermitInstructions, } from './BurnPermitInstructionsRedux'
import { reducer as BurnPermitPileGroups, } from './BurnPermitPileGroupRedux'
import { reducer as BurnPermitSignature, } from './BurnPermitSignatureRedux'
import { reducer as BurnPermitOrder, } from './BurnPermitOrderRedux'
import { reducer as BurnRequestList, } from './BurnRequestListRedux'
import { reducer as BurnRequestDetail, } from './BurnRequestDetailRedux'
import { reducer as BurnRequestSearch, } from './BurnRequestSearchRedux'
import { reducer as Calendar, } from './CalendarRedux'
import { reducer as CustomerInteraction, } from './CustomerInteractionRedux'
import { reducer as Dashboard, } from './DashboardRedux'
import { reducer as GeoCoordinate, } from './GeoCoordinateRedux'
import { reducer as Files, } from './FileRedux'
import { reducer as api, } from './ApiRedux'
import { reducer as Agency, } from './AgencyRedux'
import { reducer as Person, } from './PersonRedux'
import { reducer as User, } from './UserRedux'
import { reducer as PostBurn, } from './PostBurnRedux'
import { reducer as Reports, } from './ReportsRedux'
import { reducer as Rules, } from './RulesRedux'
import { reducer as Merge, } from './MergeRedux'
import { reducer as Cart, } from './CartRedux'

const appReducer = combineReducers({
  orm,
  api,
  app,
  Map,
  ui,
  ApplicantInfo,
  BurnPermitInstructions,
  BurnPermitForm,
  BurnPermitList,
  BurnPermitPileGroups,
  BurnPermitOrder,
  BurnPermitSearch,
  BurnPermitSignature,
  BurnRequestDetail,
  BurnRequestList,
  BurnRequestSearch,
  PermitConditions,
  Calendar,
  CustomerInteraction,
  GeoCoordinate,
  Dashboard,
  Agency,
  Person,
  PostBurn,
  User,
  Files,
  Reports,
  Rules,
  Merge,
  Cart,
})

// This allows us to take advantage of persistance between deployments
// but can purge the user's local state when we update app versions to
// prevent trying to rehydrate an incompatible state
const rootReducer = (state, action) => {
  if (action.type === 'persist/PURGE') {
    state = undefined
  }
  return appReducer(state, action)
}


export const EnhanceOffline = reducer => {
  // take original reducer and return new one:
  return function (state, action) {
    switch (action.type) {
    case 'CANCEL_SUBMIT': {
      const { outbox, } = state.offline
      const actionIndex = outbox.findIndex(o => {
        const { effect, commit, } = o.meta.offline
        let matchingAction = 
          o.type === action.action_type // match the redux action type
          && effect.url === action.url // match the url of the request
          && effect.init.method === action.method // match the method of the request
        // If there's a request body, match on that as well
        if (matchingAction && effect.init && effect.init.body && action.keyName && action.keyValue) {
          matchingAction = (effect.init.body[action.keyName] === action.keyValue || commit.localId === action.keyValue)
        }
        return matchingAction
      })

      if (actionIndex > -1) {
        outbox.splice(actionIndex, 1)
      }

      return { ...state, offline: { ...state.offline, outbox, }, }
    }
    default:
      // just proxy all other actions
      return reducer(state, action)
    }
  }
}

export default rootReducer