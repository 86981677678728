// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { Card, CardBody, CardHeader, } from 'reactstrap'

// Selectors
import { signatureCallbackState, } from '../../selectors/burnPermitSignatureSelectors'

// Components
import MailTo from '../MailTo'
import PermitAppLink from './PermitAppLink'

const {
  REACT_APP_SUPPORT_EMAIL,
  // eslint-disable-next-line no-undef
} = process.env

const SigningError = () => {
  // Selectors
  const {
    BurnPermitId,
    signatureError,
  } = useSelector(signatureCallbackState)

  return <Card>
    <CardHeader>
      <div className={'d-flex justify-content-between'}>
        <h3>Signing Error</h3>
        <PermitAppLink BurnPermitId={BurnPermitId}/>
      </div>
    </CardHeader>
    <CardBody>
      <p>
        An error occurred confirming your DocuSign signature. 
        Please contact <MailTo email={REACT_APP_SUPPORT_EMAIL} subject={'DocuSign Signature Confirmation'} />.
      </p>
      { signatureError ? <p>{signatureError}</p> : null }
    </CardBody>
  </Card>
}

export default React.memo(SigningError)