// Libraries
import React from 'react'
import { Card, CardHeader, CardBody,  } from 'reactstrap'
import { string, } from 'prop-types'
import { Link, } from 'react-router-dom'

// Components
import AuditData from '../AuditData'


const ReportCard = ({ Name, Description, Id, CreatedBy, CreatedDate, ModifiedBy, ModifiedDate, }) => {
  return <Card>
    <CardHeader>{Name}</CardHeader>
    <CardBody>
      <p>{Description || 'No report description.'}</p>
      <Link to={`/admin/reports/${Id}`}>View Report</Link>
      <AuditData
        CreateBy={CreatedBy}
        CreateDate={CreatedDate}
        UpdateBy={ModifiedBy}
        UpdateDate={ModifiedDate}
        className={'mt-3'}
      />
    </CardBody>
  </Card>
}

ReportCard.propTypes = {
  Name         : string,
  Description  : string,
  Id           : string,
  CreatedBy    : string,
  CreatedDate  : string,
  ModifiedBy   : string,
  ModifiedDate : string,
}

export default ReportCard