// Libraries
import { all, put, select, call, takeLatest, } from 'redux-saga/effects'
import { isEqual, } from 'lodash'

// Sagas
import { doFetch, showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { getBurnPermitStatusHistory, getPermitFiles, updateNextSteps, } from './BurnPermitSagas'
import { destroyLocalModels, serverHasNewerData, upsertLocalModel, } from './OrmSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import { OrmTypes, } from '../redux/OrmRedux'
import { UiTypes, } from '../redux/UiRedux'
import { BurnPermitSignatureTypes, } from '../redux/BurnPermitSignatureRedux'
import { BurnPermitInstructionsTypes, } from '../redux/BurnPermitInstructionsRedux'

// Selectors
import {
  signatureByPermitId,
  permitBeingReissued,
} from '../selectors/burnPermitSignatureSelectors'
import { userSetsPermitDatesWhenSigning, } from '../selectors/burnPermitPermissionSelectors'
import { userIsDNR, } from '../selectors/userSelectors'

// Models
import BurnPermitSignature from '../models/BurnPermitSignature'
import BurnPermit from '../models/BurnPermit'


// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const BURN_PERMIT_SIGNATURE_ENDPOINT = BurnPermitSignature.endpoint()


function* setLoadingDocuSignUrl (loading = false) {
  yield put({ type: BurnPermitSignatureTypes.LOADING_DOCU_SIGN_URL, loading, })
}


export function* getBurnPermitSignatures ({ burnPermitId, verify = true, disableLoading = false, }) {
  try {
    if (!disableLoading) {
      yield call(showLoading)
    }
    yield put({ type: BurnPermitSignatureTypes.GETTING_SIGNATURES, getting: true, })
    burnPermitId = parseInt(burnPermitId)
    if (isNaN(burnPermitId)) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply a Burn Permit ID to get the Application and Permit Signatures.', })
      return
    }

    const permitAppSignaturesUrl = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/${burnPermitId}/`

    const permitAppSignaturesResp = yield call(doFetch, permitAppSignaturesUrl)

    if (permitAppSignaturesResp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred attempting to retrieve the Burn Permit Application Signatures.', })
      return
    }

    const { responseBody, } = permitAppSignaturesResp

    const localSignature = yield select(signatureByPermitId, burnPermitId)
    if (!responseBody) {
      if (localSignature) {
        yield put({
          type      : OrmTypes.DESTROY,
          modelName : BurnPermitSignature.modelName,
          modelId   : localSignature.BurnPermitSignatureId,
        })
      }
      else {
        yield call(destroyLocalModels, BurnPermitSignature.modelName, { BurnPermitId: burnPermitId, })
      }
    }
    else {
      const permitSignature = permitAppSignaturesResp.responseBody
      const hasNewData = yield call(serverHasNewerData, BurnPermitSignature.modelName, { BurnPermitId: burnPermitId, }, permitSignature)
      // The ExpiringSoon and IsExpired props are calculated upon request and do not modify the Create/UpdateDate props
      // So we also want to check to see if the signature is becoming or already expired by comparing the object equality
      if (hasNewData || !isEqual(localSignature, permitSignature)) {
        yield call(destroyLocalModels, BurnPermitSignature.modelName, { BurnPermitId: burnPermitId, })
        yield call(upsertLocalModel, BurnPermitSignature.modelName, permitSignature)
      }
      if (verify === true) {
        const { BurnPermitSignatureId, } = permitSignature
        yield call(verifyPermitSignature, { BurnPermitSignatureId, burnPermitId, })
      }
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: BurnPermitSignatureTypes.GETTING_SIGNATURES, getting: false, })
    if (!disableLoading) {
      yield call(hideLoading)
    }
  }
}

export function* downloadBurnPermitSignatures (person) {
  try {

    let downloadSignaturesUrl = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/Download`

    if (person && person.personId > 0) {
      const currUserIsDnr = yield select(userIsDNR)
      if (currUserIsDnr) {
        downloadSignaturesUrl += `?personId=${person.personId}`
      }
    }

    const downloadResp = yield call(doFetch, downloadSignaturesUrl)
    
    if (downloadResp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred attempting to download Burn Permit Application Signatures.', })
      return
    }

    const { responseBody, } = downloadResp
    
    yield put({
      type      : OrmTypes.UPSERT_RANGE,
      modelName : 'BurnPermitSignature',
      records   : responseBody,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* signPermitApplication ({ burnPermitId, force, }) {
  try {
    const valid = yield call(signatureRequestPreflight, burnPermitId, 'sign', 'Permit Application')
    if (valid !== true) {
      return
    }

    let permitApplicationSignatureUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/SignApplication`
    if (force) {
      permitApplicationSignatureUrl += `?forceSignature=${force}`
    }

    const permitApplicationSignatureResp = yield call(doFetch, permitApplicationSignatureUrl)

    const { error, } = permitApplicationSignatureResp.responseBody
    const enableForce = error && error.includes('forceSignature')
    if (enableForce) {
      const abandonedPermitApplicationId = error.match(/\d+/, 'g').pop()
      const errorMsg = `${error.split('?').shift()}?`
      yield put({
        type  : BurnPermitSignatureTypes.SIGNATURE_FAILED,
        error : errorMsg,
        enableForce,
        abandonedPermitApplicationId,
      })
      yield put({ type: UiTypes.OPEN_MODAL, modalKey: 'FORCE_PERMIT_APPLICATION_SIGNATURE', })
      return
    }

    yield call(handleSignatureResponse, permitApplicationSignatureResp, 'Please validate the data in each section of the Permit Application.')
  }
  catch (error) {
    yield call(showError, error)
    yield call(setLoadingDocuSignUrl)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* confirmApplicationSignature ({ burnPermitId, envelopeId, }) {
  try {
    const valid = yield call(confirmSignaturePreflight, burnPermitId, envelopeId, 'Permit Application')
    if (valid !== true) {
      return
    }

    const permitApplicationSignatureUrl = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/ApplicationSignature`

    const requestObj = {
      method : 'POST',
      body   : {
        BurnPermitId          : burnPermitId,
        ApplicationEnvelopeId : envelopeId,
      },
    }

    const applicationSignatureResp = yield call(doFetch, permitApplicationSignatureUrl, requestObj)
    
    if (applicationSignatureResp.ok !== true || !(applicationSignatureResp.responseBody.BurnPermitDocumentId > 0)) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred attempting to confirm the Burn Permit Application Signature. Please contact support.', })
      return
    }

    yield put({ type: BurnPermitSignatureTypes.APPLICATION_SIGNATURE_CONFIRMED, burnPermitId, })

    // Make sure the instructions start on the Sign step when the user comes back from DocuSign
    yield put({
      type  : BurnPermitInstructionsTypes.COMPLETED_INSTRUCTION_STEPS,
      steps : [ 'Apply', 'Sign', ],
      burnPermitId,
    })

    // now that we know it's confirmed, get the signature
    // and update the status history in case it was a voucher agency signing
    yield all([
      call(getBurnPermitSignatures, { burnPermitId, }),
      call(getBurnPermitStatusHistory, { burnPermitId, }),
    ])

    // Updates the next steps modal and instructions to complete the expected
    // steps and move to the next one depending on the workflow for the application
    // or permit
    yield call(updateNextSteps, burnPermitId)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* previewPermit ({ burnPermitId, }) {
  try {
    const valid = yield call(signatureRequestPreflight, burnPermitId, 'preview', 'Permit')
    if (valid !== true) {
      return
    }

    const permitSignatureUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/PreviewPermit`
    const permitPreviewResp = yield call(doFetch, permitSignatureUrl)

    // Get the signatures again since the API clears out the PermitEnvelopeId of the
    // signature record when someone is previewing the permit.
    // This is necessary so the web client does not try to confirm the permit signature
    // when the user comes back to the burn portal from previewing the permit int DocuSign
    yield call(getBurnPermitSignatures, { burnPermitId, verify: false, })

    yield call(handleSignatureResponse, permitPreviewResp)
  }
  catch (error) {
    yield call(setLoadingDocuSignUrl)
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* signPermit ({ burnPermitId, validDate, expirationDate, }) {
  try {
    const valid = yield call(signatureRequestPreflight, burnPermitId, 'sign', 'Permit')
    if (valid !== true) {
      return
    }

    const setDates = yield select(userSetsPermitDatesWhenSigning)

    if (setDates && !validDate) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply a Valid Date to sign the Permit.', })
      return
    }

    if (setDates && !expirationDate) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply an Expiration Date to sign the Permit.', })
      return
    }

    const permitSignatureUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/SignPermit`
    const body = {
      method : 'POST',
      body   : {
        BurnPermitId             : burnPermitId,
        BurnPermitValidDate      : validDate,
        BurnPermitExpirationDate : expirationDate,
      },
    }
    const permitSignatureResp = yield call(doFetch, permitSignatureUrl, body)

    yield call(handleSignatureResponse, permitSignatureResp)
  }
  catch (error) {
    yield call(showError, error)
    yield call(setLoadingDocuSignUrl)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* reIssuePermit ({ burnPermitId, }) {
  try {
    const valid = yield call(signatureRequestPreflight, burnPermitId, 're-issue', 'Permit')
    if (valid !== true) {
      return
    }

    const permitSignatureUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/ReIssuePermit`
    const body = {
      method : 'POST',
      body   : {
        BurnPermitId: burnPermitId,
      },
    }
    const permitSignatureResp = yield call(doFetch, permitSignatureUrl, body)

    yield call(handleSignatureResponse, permitSignatureResp)
  }
  catch (error) {
    yield call(showError, error)
    yield call(setLoadingDocuSignUrl)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* confirmPermitSignature ({ burnPermitId, envelopeId, }) {
  try {
    const valid = yield call(confirmSignaturePreflight, burnPermitId, envelopeId, 'Permit')
    if (valid !== true) {
      return
    }

    let permitSignatureUrl = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/${burnPermitId}/PermitSignature`

    const isReissuing = yield select(permitBeingReissued)
    if (isReissuing) {
      permitSignatureUrl += '?isReissuing=true'
    }

    const requestObj = {
      method : 'PUT',
      body   : {
        BurnPermitId     : burnPermitId,
        PermitEnvelopeId : envelopeId,
      },
    }

    const permitSignatureResp = yield call(doFetch, permitSignatureUrl, requestObj)

    let docIdIsMissing = false
    const userIsDnr = yield select(userIsDNR)
    if (!userIsDnr) {
      docIdIsMissing = !(permitSignatureResp.responseBody.BurnPermitDocumentId > 0)
    }
    if (permitSignatureResp.ok !== true || docIdIsMissing) {
      yield put({ type: ApiTypes.FAILURE, error: permitSignatureResp.responseBody.error || 'An error occurred attempting to confirm the Burn Permit Signature. Please contact support.', })
      return
    }

    yield put({ type: BurnPermitSignatureTypes.PERMIT_SIGNATURE_CONFIRMED, burnPermitId, })
    yield put({ type: BurnPermitInstructionsTypes.COMPLETED_INSTRUCTION_STEPS, burnPermitId, steps: [ 'Approved', 'Issued', ], })
    // now that we know it's confirmed, get the signature
    yield call(getBurnPermitSignatures, { burnPermitId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* signatureRequestPreflight (burnPermitId, signatureType, docType) {
  yield call(showLoading)

  yield put({ type: BurnPermitSignatureTypes.RESET_SIGNATURE_STATE, })
  yield call(setLoadingDocuSignUrl, true)

  if (!burnPermitId) {
    yield put({ type: ApiTypes.FAILURE, error: `You must supply a Burn Permit ID to ${signatureType} the ${docType}.`, })
    return false
  }
  return true
}

export function* confirmSignaturePreflight (burnPermitId, envelopeId, docType) {
  yield call(showLoading)

  if (isNaN(parseInt(burnPermitId))) {
    yield put({ type: ApiTypes.FAILURE, error: `You must supply a Burn Permit ID to confirm signing the ${docType}.`, })
    return false
  }

  if (!envelopeId) {
    yield put({ type: ApiTypes.FAILURE, error: `You must supply a Envelope ID to confirm signing the ${docType}.`, })
    return false
  }

  return true
}

export function* handleSignatureResponse (response, errorMsg) {
  if (response.ok !== true) {
    let error = response.responseBody.error || 'An error occurred attempting to retrieve the Burn Permit Signature URL. Please contact support.'
    if (errorMsg) {
      error += `\n\n${errorMsg}`
    }
    yield call(showError, error)
    yield call(setLoadingDocuSignUrl)
    return
  }

  const { DocuSignUrl, } = response.responseBody
  if (!DocuSignUrl) {
    let error = 'There is an issue with the Burn Permit Signature response. Please contact support.'
    if (errorMsg) {
      error += `\n\n${errorMsg}`
    }
    yield call(showError, error)
    yield call(setLoadingDocuSignUrl)
    return
  }

  window.location.href = DocuSignUrl
}

export function* submitSignatureEvent ({ event, }) {
  try {
    const url = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/ToConfirm?signatureEvent=${event}`
    const permitSignatureResp = yield call(doFetch, url)
    const { error, BurnPermitSignature, } = permitSignatureResp.responseBody
    if (error) {
      let abandonedPermitApplicationId = ''
      let errorMsg = error
      const enableForce = error.includes('forceSignature')
      if (enableForce) {
        abandonedPermitApplicationId = error.match(/\d+/, 'g').pop()
        errorMsg = `${errorMsg.split('?').shift()}?`
      }
      yield put({
        type  : BurnPermitSignatureTypes.SIGNATURE_FAILED,
        error : errorMsg,
        enableForce,
        abandonedPermitApplicationId,
      })
      return
    }
    else if (!BurnPermitSignature) {
      const message = 'No Burn Permit Signature found to confirm.'
      yield put({ type: BurnPermitSignatureTypes.SIGNATURE_FAILED, error: message, })
      return
    }

    const {
      BurnPermitId,
      ApplicationEnvelopeId,
      ApplicationSignedBy,
      PermitEnvelopeId,
      PermitApproverSignedBy,
      PermitApplicantSignedBy,
    } = BurnPermitSignature

    if (event === 'signing_complete') {
      if (ApplicationEnvelopeId && !ApplicationSignedBy) {
        yield call(confirmApplicationSignature, { burnPermitId: BurnPermitId, envelopeId: ApplicationEnvelopeId, })
      }
      else if (PermitEnvelopeId && (!PermitApproverSignedBy || !PermitApplicantSignedBy)) {
        yield call(confirmPermitSignature, { burnPermitId: BurnPermitId, envelopeId: PermitEnvelopeId, })
      }
      // This is likely the case when a dnr user is previewing a permit
      else {
        yield put({ type: BurnPermitSignatureTypes.SIGNATURE_COMPLETE, burnPermitId: BurnPermitId, })
        yield put({ type: AppTypes.REDIRECT_TO, route: `/permits/${BurnPermitId}`, })
      }
    }
    else if (event === 'viewing_complete') {
      yield put({ type: AppTypes.REDIRECT_TO, route: `/permits/${BurnPermitId}`, })
    }
    else {
      yield put({ type: BurnPermitSignatureTypes.SIGNATURE_COMPLETE, burnPermitId: BurnPermitId, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* verifyPermitSignature ({ BurnPermitSignatureId, burnPermitId, }) {
  try {
    yield call(showLoading)
    yield put({ type: BurnPermitSignatureTypes.VERIFYING_SIGNATURES, verifying: true, })
    const permitSignatureUrl = `${REACT_APP_SERVER_URL}${BURN_PERMIT_SIGNATURE_ENDPOINT}/${BurnPermitSignatureId}/VerifySignatures`
    const permitSignatureResp = yield call(doFetch, permitSignatureUrl)

    if (permitSignatureResp.ok) {
      const { shouldUpdateSignature, shouldUpdateDocs, } = permitSignatureResp.responseBody
      if (shouldUpdateDocs) {
        yield call(getPermitFiles, { permitId: burnPermitId, })
      }
      if (shouldUpdateSignature) {
        // Make sure we don't recurse
        yield call(getBurnPermitSignatures, { burnPermitId, verify: false, })
      }
    }
  }
  catch (error) {
    yield call(showError, error.message)
  }
  finally {
    // Always clear this in case something unexpected happens 
    // Or the user navigates back from DocuSign using the browser controls
    yield call(setLoadingDocuSignUrl)
    yield put({ type: BurnPermitSignatureTypes.VERIFYING_SIGNATURES, verifying: false, })
  }
}


export const BurnPermitSignatureSagas = [
  takeLatest(BurnPermitSignatureTypes.GET_BURN_PERMIT_SIGNATURES, getBurnPermitSignatures),
  takeLatest(BurnPermitSignatureTypes.RE_ISSUE_PERMIT, reIssuePermit),
  takeLatest(BurnPermitSignatureTypes.PREVIEW_PERMIT, previewPermit),
  takeLatest(BurnPermitSignatureTypes.SIGN_PERMIT, signPermit),
  takeLatest(BurnPermitSignatureTypes.SIGN_PERMIT_APPLICATION, signPermitApplication),
  takeLatest(BurnPermitSignatureTypes.CONFIRM_APPLICATION_SIGNATURE, confirmApplicationSignature),
  takeLatest(BurnPermitSignatureTypes.CONFIRM_PERMIT_SIGNATURE, confirmPermitSignature),
  takeLatest(BurnPermitSignatureTypes.DOCU_SIGN_SIGNATURE_EVENT, submitSignatureEvent),
]
