import { put, takeEvery, call, } from 'redux-saga/effects'
import { DashboardTypes, } from '../redux/DashboardRedux'
import { doFetch, } from './ApiSagas'


// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, REACT_APP_SERVER_URL, } = process.env

function* getDashboardMessages () {
  try {
    const url = `${REACT_APP_SERVER_URL}Dashboard/Notices`
    const msgs = yield call(doFetch, url)
    if (!msgs.ok) {
      console.error('Error refreshing dashboard messages.')
      return
    }
    yield put({
      type     : DashboardTypes.SET_DASH_MSGS,
      messages : msgs.responseBody,
    })
  } catch (error) {
    console.error('Error refreshing dashboard messages.')
  }
}


export function* getTileLinks () {
  try{
    const url = `${REACT_APP_SERVER_URL}Dashboard/Links`
    const links = yield call(doFetch, url)
    if (!links.ok) {
      console.error('Error refreshing dashboard links.')
      return
    }

    const sanitizedLinks = {
      ...links.responseBody,
    }

    for (let prop in sanitizedLinks) {
      const cleanLinks = sanitizedLinks[prop].map(l => {
        if (l.Href.endsWith('.pdf')) {
          l.Href = l.Href.concat('?'+ (new Date()).getTime())
        }
        if (l.Href.includes('$SERVER')) {
          l.Href = l.Href.replace('$SERVER', REACT_APP_SERVER)
        }
        return {
          heading : l.Heading,
          href    : l.Href,
          text    : l.Text,
        }
      })
      sanitizedLinks[prop] = cleanLinks
    }

    yield put({
      type  : DashboardTypes.SET_TILE_LINKS,
      links : sanitizedLinks,
    })
  } catch (error) {
    console.error('Error refreshing dashboard links.')
  }

}

export const DashboardSagas = [
  takeEvery(DashboardTypes.GET_TILE_LINKS, getTileLinks),
  takeEvery(DashboardTypes.GET_DASH_MSGS, getDashboardMessages),
]