import { attr, Model, } from 'redux-orm'

export default class BurnReason extends Model {
  static get modelName () {
    return 'BurnReason'
  }

  static endpoint () {
    return 'BurnReasons'
  }

  static get options () {
    return {
      idAttribute: 'BurnReasonId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnReasonId          : attr(),
      BurnReasonCode        : attr(),
      BurnReasonName        : attr({ getDefault: () => '', }),
      BurnReasonDescription : attr({ getDefault: () => '', }),
  
      // Audit Properties
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}