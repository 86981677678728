// Libraries
import React from 'react'
import { Row, Col, FormGroup, Label, Button, FormFeedback, Card, CardBody, CardHeader, Collapse, Table, } from 'reactstrap'
import { object, func, bool, string, number, array,  } from 'prop-types'
import { Formik, ErrorMessage, Field, Form, } from 'formik'

// Components
import AuditData from '../AuditData'
import { RequiredLabel, ValidatingField, PopoverButton, } from '../FormControls'
import Effect from '../Effect'
import ExternalLink from '../ExternalLink'
import DidNotBurn from '../Modals/DidNotBurn'

// Models
import PostBurn from '../../models/PostBurn'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import { scrollToErrors, } from '../../utilities'


const CollapseSectionHeader = ({ onClick, text, isOpen, }) => {
  return <>
    <h4 className={'d-inline-block m-0'}>{text}</h4>
    <span className={'ml-2 cursor-pointer fa fa-chevron-' + (isOpen ? 'down': 'up')} onClick={onClick} onKeyPress={onClick} role={'button'} tabIndex={0}></span>
  </>
}

CollapseSectionHeader.propTypes = {
  onClick : func,
  text    : string,
  isOpen  : bool,
}

const NumberField = props => {
  return <Field
    {...props}
    component={ValidatingField}
  />
}

NumberField.propTypes = {
  type        : string,
  step        : number,
  min         : number,
  max         : number,
  placeholder : string,
  name        : string,
  readOnly    : bool,
}

NumberField.defaultProps = {
  type        : 'number',
  min         : 0.00,
  placeholder : '0.00',
}


export class PostBurnForm extends React.Component {
  static propTypes = {
    onSaveClick          : func,
    burnTypes            : array,
    postBurn             : object,
    validationSchema     : object,
    CancelPostBurnSubmit : func,
    PostBurnIsSubmitting : bool,
    burnRequest          : object,
  }

  static defaultProps = {
    onSaveClick      : () => {},
    burnTypes        : [],
    postBurn         : null,
    validationSchema : null,
    burnRequest      : null,
  }

  state = {
    genSectionOpen     : true,
    burnSectionOpen    : true,
    fuelSectionOpen    : true,
    weatherSectionOpen : true,
  }

  componentDidMount () {
    this.calculateConsumedTonnage()
  }

  // eslint-disable-next-line no-unused-vars
  hasErrors = (_errors) => {
    scrollToErrors('.post-burn')
  }

  cancelSubmit = evt => {
    stopEvent(evt)
    this.props.CancelPostBurnSubmit(this.props.postBurn.PostBurnId)
  }

  calculateConsumedTonnage = values => {
    // Don't attempt to calculate if we don't have a handle on formik
    // or if formik isn't dirty, like when the form is reset
    if (!this.formik || this.formik.dirty !== true) {
      return
    }

    const { pileType, broadcastType, naturalType, } = this.getBurnTypes()
    let ConsumedTons = 0.0
    if (pileType) {
      const { TotalPileConsumedTons, TotalPermitTonnage, PilesIgnitedPercent, } = values
      const pileIgnPerc = parseInt(PilesIgnitedPercent || 0)
      if (Number.isInteger(pileIgnPerc)) {
        ConsumedTons += ((pileIgnPerc / 100) * (TotalPileConsumedTons || TotalPermitTonnage))
      }
    }
    if (broadcastType || naturalType) {
      const { TotalTonsPerAcre, BurnedBlackAcres, BurnedBlackPercent, ProposedBurnArea, } = values
      const burnedBlackAcres = parseInt(BurnedBlackAcres || 0)
      const burnedBlackPerc = parseInt(BurnedBlackPercent || 0)
      if (TotalTonsPerAcre && Number.isInteger(burnedBlackAcres) && burnedBlackAcres > 0) {
        ConsumedTons += (burnedBlackAcres * TotalTonsPerAcre)
      }
      else if (TotalTonsPerAcre && Number.isInteger(burnedBlackPerc) && burnedBlackPerc > 0) {
        ConsumedTons += ((burnedBlackPerc / 100) * ProposedBurnArea * TotalTonsPerAcre)
      }
    }
    if (ConsumedTons === 0.0 && values.ConsumedTons > 0) {
      ConsumedTons = values.ConsumedTons
    }
    this.formik.setFieldValue('ConsumedTons', ConsumedTons)
  }

  getBurnTypes = () => {
    return {
      pileType      : this.props.burnTypes.includes('Pile'),
      broadcastType : this.props.burnTypes.includes('Broadcast'),
      naturalType   : this.props.burnTypes.includes('Natural'),
    }
  }

  setFormik = node => node ? this.formik = node : null

  render () {
    const { 
      onSaveClick,
      postBurn,
      PostBurnIsSubmitting,
      burnRequest,
    } = this.props

    const {
      genSectionOpen,
      burnSectionOpen,
      fuelSectionOpen,
      weatherSectionOpen,
    } = this.state

    const { pileType, broadcastType, naturalType, } = this.getBurnTypes()
    const burnTypes = {
      pileBurn             : pileType,
      broadcastNaturalBurn : broadcastType || naturalType,
    }

    const readOnly = !postBurn || PostBurnIsSubmitting
    const showCancel = postBurn && PostBurnIsSubmitting
    const valSchema = PostBurn.getValidationSchema(burnTypes)

    return <Formik 
      initialValues={{ ...postBurn, }}
      onSubmit={onSaveClick}
      validationSchema={valSchema}
      enableReinitialize={true}
      innerRef={this.setFormik}
    >
      {({ values, errors, dirty, touched, isSubmitting, }) => (
        <Form className={'post-burn'}>
          <Effect
            values={values}
            errors={errors}
            touched={touched}
            hasErrors={this.hasErrors}
            onChange={() => this.calculateConsumedTonnage(values)}
            isSubmitting={isSubmitting}
          />
          <p>If you did <b>not</b> burn, click the Did Not Burn button and enter the reason.</p>
          <Row>
            <Col>
              <CollapseSectionHeader onClick={() => this.setState({ genSectionOpen: !genSectionOpen, })} text={'General Information'} isOpen={genSectionOpen} />
            </Col>
          </Row>
          <Collapse isOpen={genSectionOpen} className={'mt-2'}>
            <Row className={'mb-2'}>
              <Col xs={6} sm={4}>
                <FormGroup>
                  <RequiredLabel labelFor={'BurnDate'}>Burn Date</RequiredLabel>
                  <Field
                    type={'date'}
                    readOnly={readOnly}
                    name={'BurnDate'}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col xs={6} sm={4}>
                <FormGroup>
                  <RequiredLabel labelFor={'IgnitionPeriod'}>Ignition Period (mins)</RequiredLabel>
                  <Field
                    type={'number'}
                    step={1}
                    max={postBurn && postBurn.MultiBurnDayFlag ? 2880 : 720}
                    placeholder={'0'}
                    readOnly={readOnly}
                    name={'IgnitionPeriod'}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col xs={6} sm={4}>
                <FormGroup>
                  <RequiredLabel labelFor={'BurnTime'}>Burn Time (24 HR HHMM)</RequiredLabel>
                  <Field
                    type={'number'}
                    step={1}
                    min={0}
                    max={2359}
                    placeholder={'0800'}
                    readOnly={readOnly}
                    name={'BurnTime'}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for={'PostBurnComments'}>Post-Burn Comments</Label>
                  <Field
                    type={'textarea'}
                    component={ValidatingField}
                    readOnly={readOnly}
                    name={'PostBurnComments'}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col>
              <CollapseSectionHeader onClick={() => this.setState({ burnSectionOpen: !burnSectionOpen, })} text={'Burn Information'} isOpen={burnSectionOpen} />
            </Col>
          </Row>
          <Collapse isOpen={burnSectionOpen} className={'mt-2'}>
            <Row>           
              {
                pileType && <Col xs={6} sm={4}>
                  <FormGroup>
                    <RequiredLabel labelFor={'PilesIgnitedPercent'}>
                      Piles Ignited (%)
                      <br/>
                      <small>Enter <b>100</b> if all piles were ignited.</small>
                    </RequiredLabel>
                    <NumberField
                      readOnly={readOnly}
                      name={'PilesIgnitedPercent'}
                      component={ValidatingField}
                    />
                  </FormGroup>
                </Col>
              }
              {
                (broadcastType || naturalType) && <>
                  {
                    pileType && <Col xs={6} md={4}>
                      <FormGroup>
                        <Label for={'BurnedBlackPilePercent'}>Piles Burned Black (%)</Label>
                        <NumberField
                          max={100.00}
                          readOnly={readOnly}
                          name={'BurnedBlackPilePercent'}
                          component={ValidatingField}
                        />
                      </FormGroup>
                    </Col>
                  }
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'BurnedBlackPercent'}>Area Burned Black (%)</Label>
                      <NumberField
                        readOnly={readOnly}
                        name={'BurnedBlackPercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'BurnedBlackAcres'}>Acres Burned Black</Label>
                      <NumberField
                        readOnly={readOnly}
                        name={'BurnedBlackAcres'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'BurnedBlackCanopyPercent'}>Canopy Burned Black (%)</Label>
                      <NumberField
                        max={100.00}
                        readOnly={readOnly}
                        name={'BurnedBlackCanopyPercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'BurnedBlackShrubPercent'}>Shrub Burned Black (%)</Label>
                      <NumberField
                        max={100.00}
                        readOnly={readOnly}
                        name={'BurnedBlackShrubPercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                </>
              }
              <Col xs={6} md={4}>
                <FormGroup>
                  <Label for={'ConsumedTons'}>
                    Consumed Tons<br/>
                    <small>
                      Auto-calculated based on {values.TotalPermitTonnage.toLocaleString()} total permitted tons
                    </small>
                    <PopoverButton
                      popoverHeader={'Total Consumed Tons Breakdown'}
                      popoverBody={<>
                        <p>The table below breaks down the total tonnage for this Burn Permit used for calculating the Consumed Tons.</p>
                        <Table striped responsive>
                          <thead>
                            <tr>
                              <th>Burn Type</th>
                              <th>Tons</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              values.TotalPileConsumedTons > 0
                                ? <>
                                  <tr>
                                    <td>Pile</td>
                                    <td>{values.TotalPileConsumedTons.toFixed(2).toLocaleString()}</td>
                                  </tr>
                                  <tr>
                                    <td>Broadcast/Natural</td>
                                    <td>{(values.TotalPermitTonnage - values.TotalPileConsumedTons).toFixed(2).toLocaleString()}</td>
                                  </tr>
                                </>
                                : ''
                            }
                            <tr>
                              <td className={'text-right'}><b>Total</b></td>
                              <td><b>{values.TotalPermitTonnage.toLocaleString()}</b></td>
                            </tr>
                          </tbody>
                        </Table>
                      </>}
                    />
                  </Label>
                  <NumberField
                    readOnly={true}
                    name={'ConsumedTons'}
                    component={ValidatingField}
                  />
                </FormGroup>                
              </Col>  
            </Row>
          </Collapse>
          {
            (broadcastType || naturalType) && <>
              <Row>
                <Col>
                  <CollapseSectionHeader onClick={() => this.setState({ fuelSectionOpen: !fuelSectionOpen, })} text={'Fuels Information'} isOpen={fuelSectionOpen} />
                </Col>
              </Row>
              <Collapse isOpen={fuelSectionOpen} className={'mt-2'}>
                <Row className={'mb-2'}>
                  <Col xs={6} sm={4}>
                    <FormGroup>
                      <Label for={'FuelMoistureMethod'}>Fuel Moisture Method</Label>
                      <Field
                        component={'select'}
                        readOnly={readOnly}
                        invalid={errors.FuelMoistureMethod ? 'true' : 'false'}
                        name={'FuelMoistureMethod'}
                        id={'FuelMoistureMethod'}
                        className={'form-control' + (errors.FuelMoistureMethod ? ' is-invalid' : '')}
                      >
                        <option>Select a value</option>
                        <option value={'Measured'}>Measured</option>
                        <option value={'NFDRS'}>NFDRS</option>
                      </Field>
                      <ErrorMessage name={'FuelMoistureMethod'}>
                        {errorMessage => <FormFeedback invalid={(errorMessage) ? 'invalid' : ''}>{errorMessage}</FormFeedback>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={4}>
                    <FormGroup>
                      <Label for={'TenHourFuelMoisturePercent'}>10-Hour Fuel Moisture (%)</Label>
                      <NumberField
                        max={100.00}
                        readOnly={readOnly}
                        name={'TenHourFuelMoisturePercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={4}>
                    <FormGroup>
                      <Label for={'ThousandHourFuelMoisturePercent'}>1,000-Hour Fuel Moisture (%)</Label>
                      <NumberField
                        max={100.00}
                        readOnly={readOnly}
                        name={'ThousandHourFuelMoisturePercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={4}>
                    <FormGroup>
                      <Label for={'DuffFuelMoisturePercent'}>Duff Fuel Moisture (%)</Label>
                      <NumberField
                        max={100.00}
                        readOnly={readOnly}
                        name={'DuffFuelMoisturePercent'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                </Row>   
              </Collapse>     
            </>
          }       
          {
            (broadcastType || naturalType) && <>
              <Row>
                <Col>
                  <CollapseSectionHeader onClick={() => this.setState({ weatherSectionOpen: !weatherSectionOpen, })} text={'Weather Information'} isOpen={weatherSectionOpen} />
                </Col>
              </Row>
              <Collapse isOpen={weatherSectionOpen} className={'mt-2'}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for={'WeatherCollectionMethod'}>Weather Collection Method</Label>
                      <Field
                        component={'select'}
                        readOnly={readOnly}
                        invalid={errors.WeatherCollectionMethod ? 'true' : 'false'}
                        name={'WeatherCollectionMethod'}
                        id={'WeatherCollectionMethod'}
                        className={'form-control' + (errors.WeatherCollectionMethod ? ' is-invalid' : '')}
                      >
                        <option>Select a value</option>
                        <option value={'Taken on site'}>Taken on site</option>
                        <option value={'RAWS'}>RAWS</option>
                      </Field>
                      <ErrorMessage name={'WeatherCollectionMethod'}>
                        {errorMessage => <FormFeedback invalid={(errorMessage) ? 'invalid' : ''}>{errorMessage}</FormFeedback>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for={'WeatherStationName'}>
                        Weather Station Name
                        <br/>
                        <small>RAWS Station ID</small>
                        <PopoverButton
                          popoverHeader={'NOAA Weather Stations'}
                          popoverBody={
                            <p>Find the nearest station to your burn location using <ExternalLink href={'https://www.wrh.noaa.gov/map/?hazard=true&obs=true&wfo=otx'} text={'this map'} />.</p>
                          }
                        />
                      </Label>
                      <Field
                        readOnly={readOnly}
                        name={'WeatherStationName'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'WindSpeedMph'}>Wind Speed (MPH)</Label>
                      <NumberField
                        readOnly={readOnly}
                        name={'WindSpeedMph'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'NumberDaysSinceRain'}>Number of Days Since Rain</Label>
                      <NumberField
                        step={1}
                        placeholder={'0'}
                        readOnly={readOnly}
                        name={'NumberDaysSinceRain'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={4}>
                    <FormGroup>
                      <Label for={'MidflameWindSpeed'}>Mid-Flame Wind Speed</Label>
                      <NumberField
                        readOnly={readOnly}
                        name={'MidflameWindSpeed'}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>       
            </>
          }
          <Row>
            <Col className={'d-flex justify-content-between mt-2'}>
              <Button color={'link'} type={'reset'} disabled={readOnly || !dirty}>Reset</Button>
              {(!postBurn.BurnDate && burnRequest) && <DidNotBurn values={values} readOnly={readOnly} onSaveClick={onSaveClick}/>}

              <div>
                {
                  postBurn && <div className={'d-inline-block mr-4'}>
                    <AuditData
                      CreateBy={postBurn.CreateBy}
                      CreateDate={postBurn.CreateDate}
                      UpdateBy={postBurn.UpdateBy}
                      UpdateDate={postBurn.UpdateDate}
                    />
                  </div>
                }
                { showCancel && <Button type={'cancel'} color={'danger'} onClick={this.cancelSubmit} className={'mr-4'}>Cancel</Button> }
                <Button type={'submit'} disabled={readOnly}>Save</Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  }
}

export const PostBurnFormCard = props => {
  const {
    isOpen,
    onClick,
    onSaveClick,
    readOnly,
    postBurn,
    validationSchema,
    burnTypes,
  } = props

  return <Card>
    <CardHeader tag={'h2'} onClick={onClick}>
      Post Burn Reporting
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody>
        <PostBurnForm
          readOnly={readOnly}
          postBurn={postBurn}
          onSaveClick={onSaveClick}
          burnTypes={burnTypes}
          validationSchema={validationSchema}
        />
      </CardBody>
    </Collapse>
  </Card>
}

PostBurnFormCard.propTypes = {
  ...PostBurnForm.propTypes,
  isOpen  : bool,
  onClick : func,
}

PostBurnFormCard.defaultProps = {
  ...PostBurnForm.defaultProps,
  isOpen  : true,
  onClick : () => { },
}

export default PostBurnFormCard