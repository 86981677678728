// Libraries
import React from 'react'
import { Formik, Field, } from 'formik'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'
import { FormGroup, Row, Col, Card, CardBody, } from 'reactstrap'
import { number, func, array, object, bool, } from 'prop-types'

// Components
import BurnPermitFormSection from './FormSection'
import AuditData from '../AuditData'
import { CheckBoxes, PopoverButton, ValidatingField, RequiredLabel, } from '../FormControls'

// Reducers
import ApiActions from '../../redux/ApiRedux'
import BurnPermitAreaActions from '../../redux/BurnPermitAreaRedux'

// Selectors
import { burnPermitAreaByIdSelector, burnTypesCheckboxSelector, } from '../../selectors/selectors'

// Models
import BurnPermitArea from '../../models/BurnPermitArea'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import retry from '../../utilities/retry'
import Effect from '../Effect'

const valSchema = BurnPermitArea.validationSchema

class BurnPermitAreaSection extends React.Component {
  
  constructor (props) {
    super(props)
    this.validateSection = this.validateSection.bind(this)
  }
  
  static propTypes = {
    sectionId            : number.isRequired,
    BurnTypes            : array.isRequired,
    BurnPermitArea       : object,
    burnPermitAreaId     : number,
    Failure              : func,
    UpdateBurnPermitArea : func,
    GetBurnAreaInfo      : func,
    readOnly             : bool,
    online               : bool,
    isActive             : bool,
  }

  static defaultProps = {
    readOnly: false,
  }
  
  state = {
    isValid: '',
  }
  componentDidMount () {
    const { online, GetBurnAreaInfo, burnPermitAreaId, isActive, } = this.props
    if (online && isActive) {
      GetBurnAreaInfo(burnPermitAreaId)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.GetBurnAreaInfo(this.props.burnPermitAreaId)
    }
  }

  async validateSection ({ evt, submit = true, }) {
    if (!this.formik) {
      return await retry(this.validateSection, { submit, }, 100)
    }
    stopEvent(evt)
    if (this.formik) {
      const errs = await this.formik.validateForm()
      const inputsWithError = Object.keys(errs)
      const isValid = !errs || inputsWithError.length === 0
      if (isValid && submit) {
        this.formik.submitForm()
      }
      else if (inputsWithError.length > 0) {
        for (let i = 0, len = inputsWithError.length; i < len; i++) {
          this.formik.setFieldTouched(inputsWithError[i])
        }
      }
      return isValid
    }
    return false
  }

  submit = values => {
    this.props.UpdateBurnPermitArea(values)
  }

  setFormikNode = node => this.formik = node
  
  onFormChange = () => {
    this.validateSection({ submit: false, }).then(isValid => {
      this.setState({ isValid, })
    })
  }
  render () {
    
    const { readOnly, BurnPermitArea, } = this.props

    let form = null
    if (!isEmpty(BurnPermitArea)) {
      form = <Card className={'w-100'}>
        <CardBody>
          <Formik
            initialValues={BurnPermitArea}
            enableReinitialize={true}
            validationSchema={valSchema}
            onSubmit={this.submit}
            innerRef={this.setFormikNode}
          >
            {({ values, }) => (
              <>
                <Effect values={values} onChange={this.onFormChange}></Effect>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <RequiredLabel labelFor={'HarvestAcres'}>Size of unit area (acres)</RequiredLabel>
                      <PopoverButton
                        placement={'bottom'}
                        buttonClassName={'py-0'}
                        popoverHeader={'Unit Area'}
                        popoverBody={<>
                        This is the size in acres of the area your piled forest debris was generated from.
                          <ul style={{ paddingLeft: '1.5em', }}>
                            <li>For <b>Pile Burns</b> = the area piled forest material is accumulated from</li>
                            <li>For <b>Underburns</b> = found &quot;as-is&quot; in nature</li>
                          </ul>
                        </>
                        }
                      />
                      <Field
                        type={'number'}
                        name={'HarvestAcres'}
                        readOnly={readOnly}
                        component={ValidatingField}
                      
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <Field name={'BurnTypes'}>
                      {({ field, form, }) => (
                        <CheckBoxes
                          form={form}
                          inputName={field.name}
                          CheckboxLabel={'Burn Types'}
                          CheckboxData={this.props.BurnTypes}
                          readOnly={readOnly}
                          required={true}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={12}><AuditData {...values} /></Col>
                </Row>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    }

    return <BurnPermitFormSection
      {...this.props}
      validateSection={this.validateSection}
      disableBack={true}
      isValid={typeof this.state.isValid === 'boolean' ? this.state.isValid : ''}
    >
      { form }
    </BurnPermitFormSection>
  }
}

function mapStateToProps (state, props) {
  const { online, } = state.offline
  const { applications, activeBurnPermitId, } = state.BurnPermitForm
  const isActive = applications[activeBurnPermitId].activeStep === props.sectionId
  return {
    online,
    isActive,
    BurnTypes      : burnTypesCheckboxSelector(state),
    BurnPermitArea : burnPermitAreaByIdSelector(state, props.burnPermitAreaId),
  }
}

const mapDispatchToProps = {
  Failure              : ApiActions.failure,
  GetBurnAreaInfo      : BurnPermitAreaActions.getBurnAreaInfo,
  UpdateBurnPermitArea : BurnPermitAreaActions.updateBurnPermitArea,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitAreaSection)