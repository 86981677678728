import { createSelector, } from 'reselect'
import { ormSelector, ormByIdSelector, } from './selectors'

const rulesStateSelector = state => state.Rules

export const rulesSelector = ormSelector(
  session => {
    if (!session.Rule || !session.Rule.all().exists()) {
      return []
    }
    return session.Rule.all().orderBy('RuleId').toRefArray()
  }
)

export const ruleSelector = ormByIdSelector(
  (session, RuleId) => {
    if (isNaN(RuleId) || !session.Rule || !session.Rule.idExists(RuleId)) {
      return null
    }
    return session.Rule.withId(RuleId)
  }
)


export const rulesLoadingSelector = createSelector(
  rulesStateSelector,
  ({ rulesLoading, }) => rulesLoading ? rulesLoading : false
)

export const ruleUpdatingSelector = createSelector(
  rulesStateSelector,
  ({ ruleUpdating, }) => ruleUpdating ? ruleUpdating : []
)

export const ruleUpdatedSelector = createSelector(
  rulesStateSelector,
  ({ ruleUpdated, }) => ruleUpdated ? ruleUpdated : []
)

export const ruleUpdateFailedSelector = createSelector(
  rulesStateSelector,
  ({ ruleUpdateFailed, }) => ruleUpdateFailed ? ruleUpdateFailed : []
)