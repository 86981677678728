// Libraries
import React from 'react'
import { Row, Col, Button, } from 'reactstrap'
import { useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'

// Selectors
import { userIsDNR, } from '../../../selectors/userSelectors'

// Components
import PermitFeePopover from '../../PermitFeePopover'

const PayStep = () => {
  const isDnr = useSelector(userIsDNR)
  
  let readyToPay = <p>
    Once you&apos;ve reviewed your order, click the Pay Order button. You will be redirected to an external payment processor where you can
    pay by ACH or Credit Card.
  </p>
  if (isDnr) {
    readyToPay = <p>Once you&apos;ve reviewed the order, enter the Cash or Check payment information then click Submit.</p>
  }
  return <Row>
    <Col xs={12} md={6} className={'p-2'}>
      <h3>Bundling Applications?</h3>
      <div className={'p-2'}>
        <p>
          If you are applying for multiple permits, you can bundle them together to combine the tonnages
          to take advantage of the Permit Fee Schedule<PermitFeePopover placement={'right'} buttonClassName={'p-0 ml-2'} />.
        </p>
        <p>Click the <b>Apply</b> button below to begin another application.</p>
        <p>
          When you&apos;re ready to pay for your Permit Applications, go to <Link to={'/permits'}>My Permits</Link>, add your Permit Application(s) to your Cart by clicking the <Button size={'sm'} className={'fa fa-plus btn-success readonly'} />,&nbsp;
          then click the <Button size={'sm'} className={'readonly'}><span className={'fa fa-shopping-cart'} /></Button> to Checkout.
        </p>
      </div>
    </Col>
    <Col xs={12} md={6} className={'p-2'}>
      <h3>Ready to Pay?</h3>
      <div className={'p-2'}>
        <p>Click the <b>Create Order</b> button below. This will take you to your Order Details page so you can review your Burn Permit Order.</p>
        {readyToPay}
      </div>
    </Col>
  </Row>
}

const Pay = () => {
  return {
    label   : 'Pay',
    caption : <PayStep />,
    icon    : 'fas fa-credit-card',
  }
}

export default Pay