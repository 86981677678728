import { ormWithPropSelector, } from './selectors'

const mapCounty = county => {
  if (!county) {
    return null
  }
  return {
    CountyId   : county.CountyId,
    CountyName : county.CountyName,
  }
}

const mapRegion = region => {
  if (!region) {
    return null
  }
  return {
    RegionId           : region.RegionId,
    RegionCode         : region.RegionCode,
    RegionName         : region.RegionName,
    RegionAbbreviation : region.RegionAbbreviation,
    RegionDescription  : region.RegionDescription,
    RegionPhoneId      : region.PhoneId,
    RegionAddressId    : region.AddressId,
    RegionEmailId      : region.EmailId,
    CreateDate         : region.CreateDate,
    CreateBy           : region.CreateBy,
    UpdateDate         : region.UpdateDate,
    UpdateBy           : region.UpdateBy,
    RegionAddress      : region.RegionAddress ? region.RegionAddress.toString() : '',
    RegionPhone        : region.RegionPhone ? region.RegionPhone.toString() : '',
    RegionEmail        : region.RegionEmail ? region.RegionEmail.toString() : '',
    RegionCounties     : region.RegionCounties.orderBy('CountyName').toRefArray().map(mapCounty),
  }
}

/**
 * Selector for Regions
 */
export const allRegionsSelector = ormWithPropSelector(
  ({ Region, }, includeDivision = false) => {
    if (!Region.all().exists()) {
      return []
    }
    let regions = Region.all()
    if (includeDivision === false) {
      regions = regions.filter(r => r.RegionName !== 'Division')
    }
    return regions.orderBy('RegionName').toModelArray().map(mapRegion)
  }
)

export const regionsForSelectSelector = ormWithPropSelector(
  ({ Region, }, includeDivision = false) => {
    if (!Region.all().exists()) {
      return []
    }
    let regions = Region.all()
    if (includeDivision === false) {
      regions = regions.filter(r => r.RegionName !== 'Division')
    }
    return regions.orderBy('RegionName').toModelArray().map((r) => {
      let defaultAssignee = null
      const qs = r.PersonRegionXrefs.filter({ RegionDefaultAssignee: true, })
      if (qs.exists()) {
        defaultAssignee = qs.first().PersonRegionXrefPersonId
      }
      return {
        Text            : r.RegionName,
        Value           : r.RegionId,
        DefaultAssignee : defaultAssignee,
      }
    })
  }
)
