import React from 'react'
import { Button, } from 'reactstrap'
import PropTypes from 'prop-types'
import { 
  deleteModalName,
  multiDayModalName,
  multiDayUpdateModalName,
  multiDayUpdateSubmitModalName,
} from './BurnRequestForm'
import { cancelModalName, } from './Modals/CancelBurnRequest'

const BurnRequestFormButtons = ({
  showSaveButton = false,
  showSubmitButton = false,
  showDeleteButton = false,
  showCancelButton = false,
  isMultiDay = false,
  isChildRequest = false,
  openModal = null,
  submitRequestFlag = null,
}) => 
{
  let buttons = [], addWrapper = showSubmitButton, wrappedButtons = []

  if (showSaveButton) {
    let saveButton = null
    if (isChildRequest){
      saveButton = (
        <Button 
          key={'save-req'} 
          type={'submit'}
          onClick={openModal}
          data-modal-key={multiDayUpdateModalName} 
        >Save</Button>
      )
    } else {
      saveButton = <Button key={'save-req'} type={'submit'}>Save</Button>
    }
    if (addWrapper) {
      wrappedButtons.push(saveButton)
    } else {
      buttons.push(saveButton)
    }
  }
  if (showSubmitButton){
    let submitButton = null
    if (isChildRequest || isMultiDay) {
      submitButton = (
        <Button 
          className={'ml-3'} 
          type={'submit'}
          color={'primary'} 
          onClick={openModal}
          key={'submit-req'} 
          data-modal-key={isMultiDay ? multiDayModalName : multiDayUpdateSubmitModalName} 
        >Submit</Button>
      )
    } else {
      submitButton = (
        <Button
          className={'ml-3'}
          type={'submit'}
          color={'primary'}
          onClick={submitRequestFlag}
          key={'submit-req'}
        >Submit</Button>
      )
    }
    wrappedButtons.push(submitButton)
  }
  if (showDeleteButton) {
    buttons.push(<Button
      className={'text-danger pl-0 pr-0'}
      color={'link'}
      onClick={openModal}
      data-modal-key={deleteModalName}
      key={'delete-req'}
    >
        Delete
    </Button>)
  }
  if (showCancelButton) {
    buttons.push(<Button
      className={'text-danger pl-0 pr-0'}
      color={'link'}
      onClick={openModal}
      data-modal-key={cancelModalName}
      key={'cancel-req'}
    >
        Cancel
    </Button>)
  }
  if (addWrapper) {
    return <><div key={'left-buttons'}>{wrappedButtons}</div>{buttons}</>
  }
  return buttons
}

BurnRequestFormButtons.propTypes = {
  showSaveButton    : PropTypes.bool, 
  showSubmitButton  : PropTypes.bool, 
  showDeleteButton  : PropTypes.bool, 
  showCancelButton  : PropTypes.bool, 
  isMultiDay        : PropTypes.bool,  
  isChildRequest    : PropTypes.bool,  
  openModal         : PropTypes.func, 
  submitRequestFlag : PropTypes.func,
}

export default BurnRequestFormButtons