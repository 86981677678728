import { attr, Model, } from 'redux-orm'

export default class Direction extends Model {
  static get modelName () {
    return 'Direction'
  }

  toString () {
    return `${this.DirectionName}`
  }

  static endpoint () {
    return 'Directions'
  }

  static get options () {
    return {
      idAttribute: 'DirectionId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      DirectionId           : attr(),
      CreateDate            : attr(),
      UpdateDate            : attr(),
      // Model-Specific Properties
      DirectionName         : attr(),
      DirectionAbbreviation : attr(),

      // Relationships
    }
  }
}