import React from 'react'
import { Nav, } from 'reactstrap'

// Components
import Help from './Help'
import Account from './Account'
import NavHistory from './NavHistory'


const RightNavbarItems = () => {
  return <Nav navbar className={'navbar-right'}>
    <Help />
    <NavHistory />
    <Account />
  </Nav>
}

export default React.memo(RightNavbarItems)
