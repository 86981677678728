import { attr, Model, } from 'redux-orm'

export default class AlertPreference extends Model {
  static get modelName () {
    return 'AlertPreference'
  }

  static endpoint () {
    return 'AlertPreferences'
  }

  static get options () {
    return {
      idAttribute: 'AlertPreferenceId',
    }
  }

  static get fields () {
    return {
      AlertPreferenceId          : attr(),
      AlertPreferenceName        : attr(),
      AlertPreferenceDescription : attr(),
      CreateDate                 : attr(),
      CreateBy                   : attr(),
      UpdateDate                 : attr(),
      UpdateBy                   : attr(),
    }
  }
}