import React from 'react'

// Components
import NavbarItem from '../NavbarItem'


const Calendar = () => {
  const markup = <NavbarItem
    to={'/calendar'}
    text={'Calendar'}
  />
  return markup
}

export default React.memo(Calendar)
