import {
  createReducer,
  createActions,
} from 'reduxsauce'


const { Types, Creators, } = createActions({
  fetchAgencyData         : [ 'agencyId', 'basicInfo', ],
  fetchAgencyDataForMerge : [ 'agencyId', ],
  getAllAgencies          : null,
  createAgencyObject      : [ 'model', 'modelType', 'agencyId', ],
  createAgencyObjects     : [ 'agencyId', 'address', 'email', 'phone', ],
  updateAgencyObject      : [ 'model', 'modelType', 'modelId', 'agencyId', ],
  createNewAgency         : null,
  createAgency            : [ 'model', ],
  deleteAgency            : [ 'agencyId', ],
  updateAgency            : [ 'model', 'agencyId', ],
  setAgencyId             : [ 'agencyId', 'isNew', ],
  resetAgencyId           : null,
  saved                   : [ 'saveType', ],
  saving                  : [ 'saveType', ],
  clearSaveState          : [ 'saveType' , ],
  clearAllSaveState       : null,
  verifyAgencyUser        : [ 'agencyId', 'personAgencyXrefId', ],
  mergeAgencies           : [ 'agency', 'mergedAgencyId', ],
  convertPersonToAgency   : [ 'personId', ],
  mergePersonToAgency     : [ 'personId', 'agencyId', ],
})

export const AgencyTypes = Types
export default Creators

export const INITIAL_STATE = Object.freeze({
  agencyId : -1,
  personId : '',
  isNew    : false,
  saving   : {
    'Phone'   : false,
    'Email'   : false,
    'Address' : false,
    'Agency'  : false,
  }, 
  saved: {
    'Phone'   : false,
    'Email'   : false,
    'Address' : false,
    'Agency'  : false,
  },
})

export const saved = (state, { saveType, }) => {
  if (saveType === 'Phone' || saveType === 'Address' || saveType === 'Email' || saveType === 'Agency') {
    const newState = { ...state, }
    newState.saved = { ...state.saved, }
    newState.saving = { ...state.saving, }
    newState.saved[saveType] = true
    newState.saving[saveType] = false
    return newState
  }
  return state  
}

export const saving = (state, { saveType, }) => {
  if (saveType === 'Phone' || saveType === 'Address' || saveType === 'Email' || saveType === 'Agency') {
    const newState = { ...state, }
    newState.saved = { ...state.saved, }
    newState.saving = { ...state.saving, }
    newState.saved[saveType] = false
    newState.saving[saveType] = true
    return newState
  }
  return state
}

export const clearSaveState = (state, { saveType, }) => {
  if (saveType === 'Phone' || saveType === 'Address' || saveType === 'Email' || saveType === 'Agency') {
    const newState = { ...state, }
    newState.saved = { ...state.saved, }
    newState.saving = { ...state.saving, }
    newState.saved[saveType] = false
    newState.saving[saveType] = false
    return newState
  }
  return state
}

export const clearAllSaveState = (state) => {
  const newState = { ...state, }
  newState.saving = {
    'Phone'   : false,
    'Email'   : false,
    'Address' : false,
    'Agency'  : false,
  }
  newState.saved = {
    'Phone'   : false,
    'Email'   : false,
    'Address' : false,
    'Agency'  : false,
  }
  newState.isNew = false
  return newState 
}

export const fetchAgencyData = (state, { agencyId, }) => {
  const newState = { ...state, }
  newState.agencyId = agencyId
  return newState
}

export const setAgencyId = (state, { agencyId, isNew, }) => {
  const newState = { ...state, }
  newState.agencyId = agencyId
  newState.isNew = isNew || false
  return newState
}

export const resetAgencyId = (state, ) => {
  const newState = { ...state, }
  newState.agencyId = -1
  newState.isNew = false
  newState.personId = ''
  return newState
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_AGENCY_DATA]           : fetchAgencyData,
  [Types.FETCH_AGENCY_DATA_FOR_MERGE] : fetchAgencyData,
  [Types.SET_AGENCY_ID]               : setAgencyId,
  [Types.RESET_AGENCY_ID]             : resetAgencyId,
  [Types.SAVING]                      : saving,
  [Types.SAVED]                       : saved,
  [Types.CLEAR_SAVE_STATE]            : clearSaveState,
  [Types.CLEAR_ALL_SAVE_STATE]        : clearAllSaveState,
})