import React from 'react'
import { Alert, } from 'reactstrap'


const ForestHealthDocAlert = () => {
  return <Alert color={'warning'}>
    <p>
      You <b>must</b> upload a document that further outlines why this application qualifies for a Forest Health Exemption
      in the <b>Documents</b> section and set the Document Type to <b>Forest Health Burn Documentation</b>.
    </p>
    <p>
      Documentation must include a brief description of the alternatives to burning that could 
      achieve the desired objective and why those alternatives are not appropriate for the situation.
    </p>
  </Alert>
}

export default ForestHealthDocAlert