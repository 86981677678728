import { ORM, } from 'redux-orm'
import Address from './Address'
import AddressType from './AddressType'
import Agency from './Agency'
import AlertPreference from './AlertPreference'
import AlertMethod from './AlertMethod'
import ApplicationUser from './ApplicationUser'
import ArrivalTime from './ArrivalTime'
import BurnIgnitionType from './BurnIgnitionType'
import BurnPermit from './BurnPermit'
import BurnPermitApplicationStatus from './BurnPermitApplicationStatus'
import BurnPermitApplicationStatusXref from './BurnPermitApplicationStatusXref'
import BurnPermitArea from './BurnPermitArea'
import BurnPermitCondition from './BurnPermitCondition'
import BurnPermitConditionXref from './BurnPermitConditionXref'
import BurnPermitDocument from './BurnPermitDocument'
import BurnPermitDocumentType from './BurnPermitDocumentType'
import BurnPermitEquipmentConditionXref from './BurnPermitEquipmentConditionXref'
import BurnPermitReferenceDirectionXref from './BurnPermitReferenceDirectionXref'
import BurnPermitProhibitedDayConditionXref from './BurnPermitProhibitedDayConditionXref'
import BurnPermitWindDirectionConditionXref from './BurnPermitWindDirectionConditionXref'
import BurnPermitFuel from './BurnPermitFuel'
import BurnPermitFuelLoadingXref from './BurnPermitFuelLoadingXref'
import BurnPermitLocation from './BurnPermitLocation'
import BurnPermitOrder from './BurnPermitOrder'
import BurnPermitOrderXref from './BurnPermitOrderXref'
import BurnPermitPileGroup  from './BurnPermitPileGroup'
import BurnPermitSearch from './BurnPermitSearch'
import BurnPermitSignature from './BurnPermitSignature'
import BurnPermitSite from './BurnPermitSite'
import BurnPermitSiteEquipmentXref from './BurnPermitSiteEquipmentXref'
import BurnPermitStatus from './BurnPermitStatus'
import BurnPermitStatusXref from './BurnPermitStatusXref'
import BurnPermitRequiredContactCondition from './BurnPermitRequiredContactCondition'
import BurnPermitUnit from './BurnPermitUnit'
import BurnReason from './BurnReason'
import BurnRequest from './BurnRequest'
import BurnRequestCancellationReason from './BurnRequestCancellationReason'
import BurnRequestSearch from './BurnRequestSearch'
import BurnRequestStatus from './BurnRequestStatus'
import BurnRequestStatusXref from './BurnRequestStatusXref'
import BurnType from './BurnType'
import ContactMethod from './ContactMethod'
import County from './County'
import CustomerInteraction from './CustomerInteraction'
import CustomerInteractionBurnPermitXref from './CustomerInteractionBurnPermitXref'
import CustomerInteractionDocument from './CustomerInteractionDocument'
import CustomerInteractionStatus from './CustomerInteractionStatus'
import CustomerInteractionStatusXref from './CustomerInteractionStatusXref'
import CustomerInteractionType from './CustomerInteractionType'
import DaysOfWeek from './DaysOfWeek'
import Direction from './Direction'
import Distance from './Distance'
import DuffType from './DuffType'
import Email from './Email'
import EquipmentType from './EquipmentType'
import FireDistrictDepartment from './FireDistrictDepartment'
import ForestHealthExempt from './ForestHealthExempt'
import ForestHealthProblemType from './ForestHealthProblemType'
import ForestHealthExemptProblemTypeXref from './ForestHealthExemptProblemTypeXref'
import ForestType from './ForestType'
import FuelDiameter from './FuelDiameter'
import LocationQuarter from './LocationQuarter'
import Person from './Person'
import PersonAgencyXref from './PersonAgencyXref'
import PersonAlertPreferenceXref from './PersonAlertPreferenceXref'
import PersonRegionXref from './PersonRegionXref'
import PersonType from './PersonType'
import Phone from './Phone'
import PhoneType from './PhoneType'
import PostBurn from './PostBurn'
import Region from './Region'
import ReferenceDirection from './ReferenceDirection'
import ReferenceDirectionType from './ReferenceDirectionType'
import Rule from './Rule'
import SpeciesWoodDensity from './SpeciesWoodDensity'

const orm = new ORM({
  stateSelector: state => state.orm,
})

orm.register(
  Address,
  AddressType,
  Agency,
  AlertPreference,
  AlertMethod,
  ApplicationUser,
  ArrivalTime,
  BurnIgnitionType,
  BurnPermit,
  BurnPermitApplicationStatus,
  BurnPermitApplicationStatusXref,
  BurnPermitArea,
  BurnPermitCondition,
  BurnPermitConditionXref,
  BurnPermitDocument,
  BurnPermitDocumentType,
  BurnPermitEquipmentConditionXref,
  BurnPermitProhibitedDayConditionXref,
  BurnPermitReferenceDirectionXref,
  BurnPermitRequiredContactCondition,
  BurnPermitWindDirectionConditionXref,
  BurnPermitFuel,
  BurnPermitFuelLoadingXref,
  BurnPermitLocation,
  BurnPermitOrder,
  BurnPermitOrderXref,
  BurnPermitPileGroup,
  BurnPermitSearch,
  BurnPermitSignature,
  BurnPermitSite,
  BurnPermitSiteEquipmentXref,
  BurnPermitStatus,
  BurnPermitStatusXref,
  BurnPermitUnit,
  BurnReason,
  BurnRequest,
  BurnRequestCancellationReason,
  BurnRequestSearch,
  BurnRequestStatus,
  BurnRequestStatusXref,
  BurnType,
  ContactMethod,
  County,
  CustomerInteraction,
  CustomerInteractionBurnPermitXref,
  CustomerInteractionDocument,
  CustomerInteractionStatus,
  CustomerInteractionStatusXref,
  CustomerInteractionType,
  DaysOfWeek,
  Direction,
  Distance,
  DuffType,
  Email,
  EquipmentType,
  FireDistrictDepartment,
  ForestHealthExempt,
  ForestHealthProblemType,
  ForestHealthExemptProblemTypeXref,
  ForestType,
  FuelDiameter,
  LocationQuarter,
  Person,
  PersonType,
  PersonAgencyXref,
  PersonAlertPreferenceXref,
  PersonRegionXref,
  Phone,
  PhoneType,
  PostBurn,
  Region,
  ReferenceDirection,
  ReferenceDirectionType,
  Rule,
  SpeciesWoodDensity,
)

export default orm
