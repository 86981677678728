import { attr, Model, } from 'redux-orm'

export default class FuelDiameter extends Model {
  static get modelName () {
    return 'FuelDiameter'
  }

  toString () {
    return `${this.FuelDiameterName}`
  }

  static endpoint () {
    return 'FuelDiameters'
  }

  static get options () {
    return {
      idAttribute: 'FuelDiameterId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      FuelDiameterId          : attr(),
      // Model-Specific Properties
      FuelDiameterName        : attr(),
      FuelDiameterDescription : attr(),
      FuelDiameterCategory    : attr(),
  
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}