// Libraries
import React, { useRef, useCallback, useEffect, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Field, Form, Formik, } from 'formik'
import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  FormFeedback,
} from 'reactstrap'
import { bool, object, } from 'prop-types'

// Components
import { CancelEditPile, DeletePile, } from './Buttons'
import DimensionField from './DimensionField'
import { RadioInputs, RequiredLabel, Select, ValidatingField, } from '../../../FormControls'

// Reducers
import BurnPermitPileGroupActions from '../../../../redux/BurnPermitPileGroupRedux'

// Models
import BurnPermitPileGroup from '../../../../models/BurnPermitPileGroup'

// Selectors
import { lookupDataSelector, } from '../../../../selectors/burnPermitPileGroupsSelectors'

// Images
import cone from '../../../../images/pile_shapes/cone2.gif'
import half_cone from '../../../../images/pile_shapes/half_cone.gif'
import half_cylinder from '../../../../images/pile_shapes/half_cylinder.gif'
import half_ellipsoid from '../../../../images/pile_shapes/half_ellipsoid.gif'
import half_sphere from '../../../../images/pile_shapes/half_sphere.gif'
import irreg_solid from '../../../../images/pile_shapes/irreg_solid.gif'
import paraboloid from '../../../../images/pile_shapes/paraboloid2.gif'
import formGroupClasses from '../FormGroupClasses'
const pileImages = [ half_sphere, paraboloid, half_cylinder, half_cone, cone, half_ellipsoid, irreg_solid, ]

const pileImageSrc = (pileShapeNumber) => {
  if (Number.isNaN(pileShapeNumber)) {
    return null
  }
  return pileImages[pileShapeNumber]
}

const SHAPE_DIMENSION_PROPS = BurnPermitPileGroup.ShapeDimensionProps
const validationSchema = BurnPermitPileGroup.validationSchema

const PileGroupForm = props => {
  const {
    ActiveBurnPermitPileGroup,
    readOnly,
    RequestFailed,
  } = props

  const dispatch = useDispatch()

  const formikRef = useRef(null)
  const setFormikRef = React.useCallback((node) => {
    if (node) {
      formikRef.current = node
    }
    return () => {
      if (formikRef && formikRef.current) {
        formikRef.current = null
      }
    }
  }, [])

  const mounted = useRef()
  const reqFailed = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true
    } else {
      // do componentDidUpdate logic
      const formik = formikRef.current
      if (formik && reqFailed.current === false && RequestFailed === true) {
        formik.setSubmitting(false)
      }
    }
  })
  useEffect(() => reqFailed.current = RequestFailed, [ RequestFailed, ])

  const submit = useCallback((values) => {
    // If Hand Piles, clear out the machine pile data
    if (parseInt(values.PileType) === 1) {
      values.PileQuality = null
      values.PercentSoil = null
      values.PackingRatio = null
      values.SpeciesOneDensityId = null
      values.SpeciesOneCompositionPercent = null
      values.SpeciesTwoDensityId = null
      values.SpeciesTwoCompositionPercent = null
    }
    // If Machine Piles, clear out the hand pile data
    else if (parseInt(values.PileType) === 2) {
      values.HandPileSpecies = null
      if (values.SpeciesOneCompositionPercent === 100) {
        values.SpeciesTwoDensityId = null
        values.SpeciesTwoCompositionPercent = null
      }
    }

    // Clear out dimension props that are not to be used for the selected shape
    const targetDimensions = SHAPE_DIMENSION_PROPS[values.PileShape]
    const dimensionProps = SHAPE_DIMENSION_PROPS[SHAPE_DIMENSION_PROPS.length - 1]
    for (let i = 0, len = dimensionProps.length; i < len; i++) {
      const dimension = dimensionProps[i]
      if (targetDimensions.includes(dimension) === false && !!values[dimension]) {
        values[dimension] = null
      }
    }

    delete values.SpeciesOneDensity
    delete values.SpeciesTwoDensity

    if (values.IsLocal === true) {
      dispatch(BurnPermitPileGroupActions.createBurnPermitPileGroup(values))
    }
    else {
      dispatch(BurnPermitPileGroupActions.updateBurnPermitPileGroup(values))
    }
  }, [ dispatch, ])

  const reset = useCallback(() => {
    const formik = formikRef.current
    formik.resetForm(formik.initialValues)
  }, [ formikRef, ])

  const {
    PileShapes,
    HandPileSpecies,
    PileTypes,
    PileQuality,
    SpeciesWoodDensities,
    PackingRatios,
  } = useSelector(lookupDataSelector)

  return <Formik
    initialValues={{ ...ActiveBurnPermitPileGroup, }}
    enableReinitialize={true}
    validationSchema={validationSchema}
    onSubmit={submit}
    innerRef={setFormikRef}
  >
    {({ values, handleSubmit, isSubmitting, }) => (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <RequiredLabel>Denotes Required Fields</RequiredLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <h5 className={'mb-1'}>Pile Group Info</h5>
            <hr className={'mt-0'} />
            <Row className={'p-2'}>
              <Col xs={8}>
                <FormGroup className={formGroupClasses}>
                  <Label for={'PileGroupName'}>Group Name</Label>
                  <Field
                    id={'PileGroupName'}
                    name={'PileGroupName'}
                    readOnly={readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup className={formGroupClasses}>
                  <RequiredLabel labelFor={'PileCount'}>Pile Count</RequiredLabel>
                  <Field
                    id={'PileCount'}
                    name={'PileCount'}
                    min={1}
                    type={'number'}
                    readOnly={readOnly}
                    component={ValidatingField}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className={'mb-1 mt-2 pt-1'}>Dimensions <small>(feet)</small></h5>
                <hr className={'mt-0'} />
              </Col>
            </Row>
            <Row className={'p-2'}>
              <Col xs={12}>
                <FormGroup className={formGroupClasses}>
                  <Field name={'PileShape'}>
                    {({ field, form, }) => (
                      <Select
                        {...field}
                        label={<RequiredLabel labelFor={field.name}>Pile Shape</RequiredLabel>}
                        id={field.name}
                        items={PileShapes}
                        propertyName={field.name}
                        selectedValue={field.value}
                        errorMessage={form.errors[field.name]}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                </FormGroup>
              </Col>
              {
                Number.isInteger(parseInt(values.PileShape)) && <>
                  <DimensionField
                    label={'Width 1'}
                    propName={'WidthOne'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                  <DimensionField
                    label={'Height 1'}
                    propName={'HeightOne'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                  <DimensionField
                    label={'Length 1'}
                    propName={'LengthOne'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                  <DimensionField
                    label={'Width 2'}
                    propName={'WidthTwo'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                  <DimensionField
                    label={'Height 2'}
                    propName={'HeightTwo'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                  <DimensionField
                    label={'Length 2'}
                    propName={'LengthTwo'}
                    pileShape={values.PileShape}
                    readOnly={readOnly}
                    formGroupClasses={formGroupClasses}
                  />
                </>
              }
            </Row>
            <Row>
              <Col xs={12} className={'p-2'}>
                {Number.isInteger(parseInt(values.PileShape)) && <>
                  <img src={pileImageSrc(values.PileShape)} alt={'Pile Shape'} />
                </>
                }
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <h5 className={'mb-1'}>Composition</h5>
            <hr className={'mt-0'} />
            <Row className={'p-2'}>
              <Col xs={4}>
                <FormGroup className={formGroupClasses}>
                  <Field name={'PileType'}>
                    {({ field, form, }) => (
                      <RadioInputs
                        label={<RequiredLabel labelFor={field.name}>Pile Type</RequiredLabel>}
                        id={field.name}
                        inputName={field.name}
                        checkedValue={parseInt(field.value)}
                        invalid={!!form.errors[field.name]}
                        errorMessage={form.errors[field.name]}
                        onChange={field.onChange}
                        readOnly={readOnly}
                        values={PileTypes}
                        showPrompt={false}
                        className={'pl-4 mb-1'}
                      />
                    )}
                  </Field>
                </FormGroup>
              </Col>
              {
                `${values.PileType}` === '1' &&
                  <Col>
                    <FormGroup className={`${formGroupClasses.replace('mb-2', 'mb-0')}`}>
                      <Field name={'HandPileSpecies'}>
                        {({ field, form, }) => (
                          <RadioInputs
                            label={<RequiredLabel labelFor={field.name}>Type</RequiredLabel>}
                            id={field.name}
                            inputName={field.name}
                            checkedValue={parseInt(field.value)}
                            invalid={!!form.errors[field.name]}
                            errorMessage={form.errors[field.name]}
                            onChange={field.onChange}
                            readOnly={readOnly}
                            values={HandPileSpecies}
                            showPrompt={false}
                            className={'pl-4 mb-1'}
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
              }
              {
                `${values.PileType}` === '2' && <>
                  <Col xs={6}>
                    <FormGroup className={`${formGroupClasses.replace('mb-2', 'mb-0')}`}>
                      <Field name={'PileQuality'}>
                        {({ field, form, }) => (
                          <RadioInputs
                            label={<RequiredLabel labelFor={field.name}>Quality</RequiredLabel>}
                            id={field.name}
                            inputName={field.name}
                            checkedValue={parseInt(field.value)}
                            invalid={!!form.errors[field.name]}
                            errorMessage={form.errors[field.name]}
                            onChange={field.onChange}
                            readOnly={readOnly}
                            values={PileQuality}
                            showPrompt={false}
                            className={'pl-4 mb-1'}
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xs={2}>
                    <FormGroup>
                      <RequiredLabel labelFor={'PercentSoil'}>% Soil</RequiredLabel>
                      <Field
                        type={'number'}
                        min={1}
                        step={1}
                        id={'PercentSoil'}
                        name={'PercentSoil'}
                        readOnly={readOnly}
                        component={ValidatingField}
                      />
                    </FormGroup>
                  </Col>
                </>
              }
            </Row>
            {
              `${values.PileType}` === '2' &&
                <>
                  <Row className={'p-2'}>
                    <Col>
                      <FormGroup>
                        <Field name={'PackingRatio'}>
                          {({ field, form, }) => (
                            <RadioInputs
                              label={<RequiredLabel labelFor={field.name}>Packing Ratio</RequiredLabel>}
                              id={field.name}
                              inputName={field.name}
                              checkedValue={`${field.value}`}
                              invalid={!!form.errors[field.name]}
                              errorMessage={form.errors[field.name]}
                              onChange={field.onChange}
                              readOnly={readOnly}
                              values={PackingRatios}
                              showPrompt={false}
                              className={'pl-4 mb-1'}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={'p-3'}>
                    <Col>
                      <FormGroup className={formGroupClasses}>
                        <Field name={'SpeciesOneDensityId'}>
                          {({ field, form, }) => (
                            <Select
                              {...field}
                              label={<RequiredLabel labelFor={field.name}>Species 1</RequiredLabel>}
                              id={field.name}
                              items={SpeciesWoodDensities}
                              propertyName={field.name}
                              selectedValue={field.value}
                              errorMessage={form.errors[field.name]}
                              readOnly={readOnly}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className={formGroupClasses}>
                        <RequiredLabel labelFor={'SpeciesOneCompositionPercent'}>Species 1 %</RequiredLabel>
                        <Field
                          type={'number'}
                          min={0}
                          step={1}
                          max={100}
                          id={'SpeciesOneCompositionPercent'}
                          name={'SpeciesOneCompositionPercent'}
                          readOnly={readOnly}
                          component={ValidatingField}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={'p-3'}>
                    <Col>
                      <FormGroup className={formGroupClasses}>
                        <Field name={'SpeciesTwoDensityId'}>
                          {({ field, form, }) => (
                            <Select
                              {...field}
                              label={<Label for={field.name}>Species 2</Label>}
                              id={field.name}
                              items={SpeciesWoodDensities}
                              propertyName={field.name}
                              selectedValue={field.value}
                              errorMessage={form.errors[field.name]}
                              readOnly={readOnly}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className={formGroupClasses}>
                        <Label for={'SpeciesTwoCompositionPercent'}>Species 2 %</Label>
                        <Field
                          type={'number'}
                          min={0}
                          step={1}
                          max={100}
                          id={'SpeciesTwoCompositionPercent'}
                          name={'SpeciesTwoCompositionPercent'}
                          readOnly={readOnly}
                          component={ValidatingField}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className={'mb-2'}>
                      <FormFeedback
                        className={'text-danger'}
                        style={{ display: (values.SpeciesOneCompositionPercent || 0) + (values.SpeciesTwoCompositionPercent || 0) === 100 ? 'none' : 'block', }}
                      >
                        Species 1 Composition Percent &amp; Species 2 Composition Percent must Total 100%.
                      </FormFeedback>
                    </Col>
                  </Row>
                </>
            }
          </Col>
        </Row>
        <Row className={'mt-4'}>
          <Col>
            <CancelEditPile
              disabled={isSubmitting}
              IsLocal={ActiveBurnPermitPileGroup.IsLocal}
            />
            <Button
              color={'primary'}
              size={'sm'}
              type={'reset'}
              disabled={isSubmitting}
              onClick={reset}
              className={'ml-2'}
            >
              Reset
            </Button>
          </Col>
          <Col className={'d-flex justify-content-end'}>
            <DeletePile
              disabled={isSubmitting}
              pileGroupId={values.BurnPermitPileGroupId}
              pileGroupName={values.PileGroupName}
            />
            <Button
              color={'success'}
              size={'sm'}
              type={'submit'}
              disabled={isSubmitting}
              className={'ml-2'}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
}

PileGroupForm.propTypes = {
  readOnly                  : bool,
  RequestFailed             : bool,
  ActiveBurnPermitPileGroup : object,
}

export default React.memo(PileGroupForm)