// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'
import { Formik, Field, } from 'formik'
import { Col, Row, FormGroup, Label, Fade, } from 'reactstrap'
import { bool, array, func, } from 'prop-types'

// Components
import Select from './Select'
import Effect from '../Effect'

// Models
import BurnPermitReferenceDirectionXref from '../../models/BurnPermitReferenceDirectionXref'

// Selectors
import {
  referenceDirectionTypesSelector,
  distancesSelectSelector,
  directionsSelectSelector,
  burnPermitReferenceDirectionsSelector,
} from '../../selectors/burnPermitSiteSelectors'


class BurnPermitReferenceDirections extends React.Component {
  constructor (props) {
    super(props)

    this.validationSchema = BurnPermitReferenceDirectionXref.validationSchema
    this.submit = this.submit.bind(this)
    this.validateForms = this.validateForms.bind(this)
    this.formiks = []
  }

  static propTypes = {
    Directions              : array,
    Distances               : array,
    ReferenceDirectionTypes : array,
    referenceDirections     : array,
    submit                  : func,
    onChange                : func,
    readOnly                : bool,
  }

  async validateForms () {
    const validForms = await Promise.all([ ...this.formiks.filter(f => !!f).map(async f => {
      const errors = await f.validateForm()
      return isEmpty(errors)
    }, []), ])
    return validForms.every(v => v)
  }

  submit () {
    // Only return forms that are checked/visible
    const refDirs = this.formiks.filter(f => !!f)
      .map(f => f.values)
      .filter(f => !!f.ShowDistanceDirection)
    return refDirs
  }

  submitForm = async (values) => {
    return values
  }

  setFormik = node => {
    if (node) {
      this.formiks.splice(node.values.FormIndex, 1, node)
    }
  }

  render () {
    const {
      readOnly,
      onChange,
      referenceDirections,
      ReferenceDirectionTypes,
    } = this.props
    return <>
      <Row>
        <Label id={'ref-dirs-label'}>Burn Proximities<br/><small>(Check all that apply)</small></Label>
      </Row>
      {
        ReferenceDirectionTypes.map((r, idx) => {
          const refDir = referenceDirections.find(rd => rd.ReferenceDirectionTypeId === r.ReferenceDirectionTypeId) || {}
          return <Formik
            key={`RefDirForm-${r.ReferenceDirectionTypeId}`}
            enableReinitialize={true}
            initialValues={{
              FormIndex                         : idx,
              ReferenceDirectionTypeId          : r.ReferenceDirectionTypeId,
              ReferenceDirectionTypeDescription : r.ReferenceDirectionTypeDescription,
              DistanceId                        : refDir.DistanceId || '',
              DirectionId                       : refDir.DirectionId || '',
              ReferenceDirectionId              : refDir.ReferenceDirectionId,
              ValidateDistance                  : r.ReferenceDirectionTypeDescription.indexOf('100 feet') === -1,
              ShowDistanceDirection             : !!refDir.ReferenceDirectionId,
            }}
            innerRef={this.setFormik}
            validationSchema={this.validationSchema}
            onSubmit={this.submitForm}
          >
            {({ values, errors, }) => (
              <>
                {
                  typeof onChange === 'function'
                    ? <Effect values={values} onChange={onChange} />
                    : null
                }
                <Row key={`RefDirType-${values.ReferenceDirectionTypeId}`}>
                  <Col xs={'12'} md={'6'}>
                    <FormGroup className={'pl-4'}>
                      <Label check>
                        {/* This doesn't need to represent an actual value, just manipulate the UI */}
                        <Field
                          name={'ShowDistanceDirection'}
                          type={'checkbox'}
                          className={'form-check-input'}
                          checked={values.ShowDistanceDirection}
                          disabled={readOnly}
                          readOnly={readOnly}
                        />
                        { values.ReferenceDirectionTypeDescription }
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={'6'} md={'3'}>
                    <Fade in={values.ShowDistanceDirection && values.ReferenceDirectionTypeDescription.indexOf('100 feet') === -1}>
                      <Field name={'DistanceId'}>
                        {({ field, }) => (
                          <Select
                            label={'Distance'}
                            items={this.props.Distances}
                            onChange={field.onChange}
                            propertyName={field.name}
                            selectedValue={field.value}
                            errorMessage={errors[field.name]}
                            readOnly={readOnly}
                            required={true}
                          />
                        )}
                      </Field>
                    </Fade>
                  </Col>
                  <Col xs={'6'} md={'3'}>
                    <Fade in={values.ShowDistanceDirection}>
                      <Field name={'DirectionId'}>
                        {({ field, }) => (
                          <Select
                            label={'Direction'}
                            items={this.props.Directions}
                            onChange={field.onChange}
                            propertyName={field.name}
                            selectedValue={field.value}
                            errorMessage={errors[field.name]}
                            readOnly={readOnly}
                            required={true}
                          />
                        )}
                      </Field>
                    </Fade>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        })
      }
      
    </>
  }
}

function mapStateToProps (state, props) {
  const { online, } = state.offline
  return {
    online,
    Distances               : distancesSelectSelector(state),
    Directions              : directionsSelectSelector(state),
    ReferenceDirectionTypes : referenceDirectionTypesSelector(state),
    referenceDirections     : burnPermitReferenceDirectionsSelector(state, props.burnPermitSiteId),
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true, })(BurnPermitReferenceDirections)