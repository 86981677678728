import { useCallback, } from 'react'

// Actions
import BurnPermitFormActions from '../redux/BurnPermitFormRedux'
import UiActions from '../redux/UiRedux'

const usePermitFileUpload = (dispatch, burnPermitId, mustUploadPermit, isGovPermit, files) => {
  return useCallback(() => {
    dispatch(BurnPermitFormActions.uploadPermitFiles(burnPermitId, files, true))
    if (mustUploadPermit && isGovPermit) {
      dispatch(UiActions.closeModal())
    }
  }, [ dispatch, burnPermitId, mustUploadPermit, isGovPermit, files, ])
}

export default usePermitFileUpload