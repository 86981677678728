// Libraries
import React from 'react'
import { Row, } from 'reactstrap'
import { func, number, string, bool, object, PropTypes, array, } from 'prop-types'

// Models
import BurnPermitConditionXref from '../../models/BurnPermitConditionXref'

// Components
import InputCondition from './InputCondition'
import EditorCondition from './EditorCondition'
import BaseCondition from './BaseCondition'


/** Renders a Boolean, Number, or Text Condition */
const Condition = (props) => {
  const {
    condition,
    value,
    selected,
    onConditionChecked,
    onValueChanged,
    conditionValidityChanged,
    label,
    indent,
    parentIsSelected,
    readOnly,
  } = props
    
  if (!condition) {
    return 'No condition provided.'
  }
    
  const {
    BurnPermitConditionName,
    BurnPermitConditionId,
    Category,
  } = condition

  let conditionBody = null
  const conditionProps = {
    selected,
    condition,
    readOnly,
    onValueChanged,
    conditionValidityChanged,
    label,
    indent,
    key       : `cond-${BurnPermitConditionId}`,
    value     : value || '',
    valSchema : BurnPermitConditionXref.getValidationSchema(BurnPermitConditionName),
  }
  let unit = BurnPermitConditionXref.getUnit(BurnPermitConditionName)
  if (unit) {
    conditionProps.unit = unit
  }

  conditionProps.readOnly = readOnly || (indent && !parentIsSelected)
  switch (Category) {
  case 'Number':
    conditionBody = <InputCondition type={'number'} {...conditionProps} />
    break
  case 'Text':
    conditionBody = <InputCondition {...conditionProps} />
    break
  case 'Textarea':
    conditionBody = <EditorCondition type={'textarea'} {...conditionProps} />
    break
  case 'Boolean':
    conditionProps.value = typeof value !== 'boolean' ? false : value
    conditionBody = <Row>{condition.BurnPermitConditionDescription}</Row>
    break
  default:
    return null
  } 
  return <BaseCondition
    {...conditionProps}
    onConditionSelected={onConditionChecked}
    error={props.error}
  > 
    {conditionBody}
  </BaseCondition>
}

Condition.propTypes = {
  value                    : PropTypes.oneOfType([ object, string, bool, number, ]),
  condition                : object,
  selected                 : bool,
  parentIsSelected         : bool,
  readOnly                 : bool,
  onConditionChecked       : func.isRequired,
  onValueChanged           : func.isRequired,
  error                    : PropTypes.oneOfType([ object, string, array, ]),
  conditionValidityChanged : func.isRequired,
  label                    : PropTypes.oneOfType([ number, string, ]),
  indent                   : bool,
}

Condition.defaultProps = {
  value                    : null,
  condition                : {},
  selected                 : false,
  onConditionChecked       : null,
  onValueChanged           : null,
  conditionValidityChanged : null,
  error                    : null,
  readOnly                 : false,
  label                    : '',
  indent                   : false,
}

export default Condition