import { createSelector, } from 'reselect'
import { ormByIdSelector, } from './selectors'


const signatureState = state => state.BurnPermitSignature

export const permitSignatureState = createSelector(
  signatureState,
  signatureState => signatureState
)

export const signatureByPermitId = ormByIdSelector(
  ({ BurnPermitDocument, BurnPermitSignature, }, PermitId) => {
    if (isNaN(PermitId)) {
      return {}
    }

    const permitApplicationSignature = BurnPermitSignature
      .filter({ BurnPermitId: PermitId, })
      .first()
    if (!permitApplicationSignature) {
      return {}
    }
    // Only return the signature if there is at least app signature info
    let appIsSigned = !!permitApplicationSignature.ApplicationEnvelopeId
    if (!appIsSigned) {
      // Or check if it was signed by DNR and there is a mailed application uploaded
      appIsSigned = BurnPermitDocument
        .filter({ BurnPermitId: PermitId, })
        .toModelArray()
        .some(doc => doc.BurnPermitDocumentType && doc.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Mailed Application')
    }
    if (!appIsSigned && !permitApplicationSignature.PermitEnvelopeId) {
      return {}
    }

    return permitApplicationSignature || {}
  }
)

export const permitBeingReissued = createSelector(
  signatureState,
  signature => signature && signature.isReissuingPermit
)

export const isLoadingDocuSignUrl = createSelector(
  signatureState,
  signature => signature && signature.isLoadingDocuSignUrl || false
)

export const signatureCallbackState = createSelector(
  signatureState,
  signature => {
    const {
      burnPermitId,
      applicationSignatureEnvelopeId,
      permitSignatureEnvelopeId,
      applicationSignatureConfirmed,
      permitSignatureConfirmed,
      confirmingSignature,
      confirmationFailed,
      signatureError,
    } = signature
    return {
      BurnPermitId          : burnPermitId,
      ApplicationEnvelopeId : applicationSignatureEnvelopeId,
      PermitEnvelopeId      : permitSignatureEnvelopeId,
      applicationSignatureConfirmed,
      permitSignatureConfirmed,
      confirmingSignature,
      confirmationFailed,
      signatureError,
    }
  }
)
