// Libraries
import { put, call, takeEvery, } from 'redux-saga/effects'

// Reducers
import { CalendarTypes, } from '../redux/CalendarRedux'

// Sagas
import { extractPayload, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'

/**
 * Maps the Burn Request Search response array to calendar events then
 * calls a dispatch action to set the events on the calendar reducer
 * @param {Object} resp
 */
export function* calendarEventsSuccess (resp) {
  const burnReqs = yield call(extractPayload, resp)
  yield put({ type: CalendarTypes.SET_EVENTS, events: [ ...burnReqs, ], })
  yield call(hideLoading)
}

export const CalendarSagas = [
  takeEvery(CalendarTypes.CALENDAR_EVENTS_SUCCESS, calendarEventsSuccess),
  takeEvery(CalendarTypes.SET_START_END, showLoading),
]