// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, } from 'react-redux'
import { Button, } from 'reactstrap'

// Reducers
import BurnPermitPileGroupActions from '../../../../../redux/BurnPermitPileGroupRedux'


const AddPile = () => {

  const dispatch = useDispatch()

  const addPile = useCallback(() => {
    dispatch(BurnPermitPileGroupActions.addLocalPileGroup())
  }, [ dispatch, ])

  return <Button
    size={'sm'}
    color={'success'}
    onClick={addPile}
  >
    Add Pile Group
  </Button>
}

export default React.memo(AddPile)