// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { object, string, func, bool, } from 'prop-types'
import { Container, Row, Col, Card, CardBody, } from 'reactstrap'
import { Link, } from 'react-router-dom'

// Reducers
import ApiActions from '../../redux/ApiRedux'
import AppActions from '../../redux/AppRedux'
import ReportActions from '../../redux/ReportsRedux'

// Components
import ReportMessageCard from './ReportMessageCard'
import withRouter from '../withRouter'

// Utilities
import stopEvent from '../../utilities/stopEvent'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, } = process.env
const REPORT_SERVER_URL = `${REACT_APP_SERVER}Reports`

class ReportViewer extends React.Component {

  constructor (props) {
    super(props)

    // Set a flag indicating if the user is using an outdated version of Edge or not
    const ua = navigator.userAgent
    const edge = ua.indexOf('Edge/')
    // Tried using regex, but the old version doesn't support one of the regex operators 🤦‍♂️
    this.isOldEdgeBrowser = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) < 18
  }

  static propTypes = {
    online         : bool,
    params         : object.isRequired,
    ReportName     : string,
    ReportPath     : string,
    Token          : string,
    SetPageTitle   : func,
    ShowLoading    : func,
    HideLoading    : func,
    DownloadReport : func,
    Failure        : func,
  }

  state = {
    loading    : true,
    reportHtml : '',
    page       : 1,
  }

  componentDidMount = () => {
    const { SetPageTitle, ReportName, online, } = this.props

    SetPageTitle(ReportName)
    if (online) {
      this.reportRequest()
    }
  }

  reportRequest = async (params) => {
    const { ReportPath, Token, ShowLoading, } = this.props
    
    ShowLoading()
    
    let url = `${REPORT_SERVER_URL}/Report?reportPath=${ReportPath}`
    if (params) {
      url += `&${params}`
    }
    const req = await fetch(url, {
      headers: {
        'Content-Type'  : 'text/html',
        'Authorization' : `Bearer ${Token}`,
      },
    })

    if (this.state.reportHtml) {
      this.setState({ reportHtml: '', })
    }

    const state = { ...this.state, }
    state.loading = false

    const resp = await req.text()

    if (req.ok === false) {
      state.error = resp || 'An error occurred requesting the report.'
      this.setState(state)
      this.props.HideLoading()
      return
    }
    state.reportHtml = resp

    this.setState(state, this.extractTable)
  }

  collectionToArray = collection => {
    return Array.prototype.slice.call(collection)
  }

  extractTable = () => {
    let tableEls = this.reportNode.getElementsByTagName('table')
    if (!tableEls || !tableEls.length) {
      return
    }
    if (this.isOldEdgeBrowser) {
      tableEls = this.collectionToArray(tableEls)
    }
    // Get a list of tables
    // Turn the HTMLCollection into an array
    const tables = [ ...tableEls, ]
    // Get instance of the deepest table that has the actual data
    const dataTable = tables[tables.length - 1]
    // Get a handle on the content div
    const content = this.reportNode.getElementsByClassName('ReportViewerContent')[0]
    // In the case an error occurred, no elements will be rendered in the content div
    if (!content.firstElementChild) {
      return
    }
    // Empty the content div
    content.removeChild(content.firstElementChild)
    // Append the datatable
    content.appendChild(dataTable)

    // Add the datatable classes to the table
    const table = this.reportNode.getElementsByTagName('table')[0]
    table.classList.add('table', 'table-striped',  'dataTable', 'border')

    // Get a handle on the body
    const tbody = table.getElementsByTagName('tbody')[0]
    // Remove the first row since it's empty
    tbody.removeChild(tbody.firstElementChild)
    // Get the table rows
    let rows = tbody.getElementsByTagName('tr')
    let { children, } = rows[0]
    if (this.isOldEdgeBrowser) {
      rows = this.collectionToArray(rows)
      children = this.collectionToArray(children)
    }
    // Get the new first one that is the headers
    const headerCells = [ ...children, ]
    // Create an actual thead and row
    const thead = document.createElement('thead')
    const headerRow = document.createElement('tr')
      
    // Add the cells to the new thead row
    for (let i = 0, len = headerCells.length; i < len; i++) {
      const newTh = document.createElement('th')
      newTh.innerText = headerCells[i].innerText
      headerRow.appendChild(newTh)
    }
    thead.appendChild(headerRow)
    // Add the new thead to the table before the body
    table.insertBefore(thead, tbody)

    this.getElements()
    this.props.HideLoading()
  }

  getElements = () => {
    
    this.dropdownToggle = this.reportNode.querySelector('#ExportMenu')
    this.currentPage = this.reportNode.querySelector('#ReportViewerCurrentPage')
    
    const totalPages = this.reportNode.querySelector('#TotalPages')

    const newState = { ...this.state, }
    if (totalPages) {
      newState.totalPages = parseInt(totalPages.innerText)
    }

    this.setState(newState)
  }

  onKeyUp = evt => {
    const { id, value, } = evt.target
    if (id === 'ReportViewerCurrentPage') {
      const currentPage = parseInt(value)
      this.currentPage.value = currentPage
      this.setState({ page: currentPage, })
    }
  }

  onKeyPress = evt => {
    const { id, } = evt.target
    if (id === 'ReportViewerCurrentPage' && evt.charCode === 13) {
      stopEvent(evt)
      const { page, totalPages, } = this.state
      if (page > totalPages) {
        this.props.Failure(`The specified page number ${page} is greater than Total Pages available ${totalPages}.`)
        return
      }
      else if (page < 1 || isNaN(page)) {
        this.props.Failure(`You must specify a page number between 1 and ${totalPages}.`)
        return
      }
      const params = this.buildParamString()
      this.reportRequest(`${params}&page=${page}`)
    }
  }

  buildParamString = () => {
    const paramContainer = this.reportNode.getElementsByClassName('ParametersContainer')[0]
    if (!paramContainer) {
      this.reportRequest()
      return ''
    }

    let inputs = paramContainer.getElementsByTagName('input')
    let selects = paramContainer.getElementsByTagName('select')
    if (this.isOldEdgeBrowser) {
      inputs = this.collectionToArray(inputs)
      selects = this.collectionToArray(selects)
    }

    let params = [ ...inputs, ...selects, ]
    params = params
      .filter(p => !!p.value)
      .map(p => `${p.name}=${p.value}`)
      .join('&')
    
    return params
  }

  viewReportWithParams = () => {
    const params = this.buildParamString()
    this.reportRequest(params)
  }

  exportReport = exportType => {
    const params = this.buildParamString()
    const exportReportRequest = `${this.props.ReportPath}&${params}`
    this.props.DownloadReport(exportReportRequest, exportType)
  }

  onClick = evt => {
    const { id, name, type, classList, parentElement, } = evt.target

    // If it's the date inputs, don't stop the event otherwise the browser
    // calendar controls won't display
    if (type === 'date') {
      return
    }

    stopEvent(evt)
    
    if (id === 'NextPage') {
      this.setPage(this.state.page + 1)
    }
    else if (id === 'PreviousPage') {
      this.setPage(this.state.page - 1)
    }
    else if (id === 'FirstPage' || id === 'Refresh') {
      this.setPage(1)
    }
    else if (id === 'LastPage') {
      this.setPage(this.state.totalPages)
    }
    else if (classList.contains('Export')) {
      this.exportReport(name)
      this.exportDropdownToggle()
    }
    else if (classList.contains('ViewReport')) {
      this.viewReportWithParams()
    }
    else if ((id === 'ExportMenu' || parentElement.id === 'ExportMenu') || this.state.exportMenuOpen) {
      this.exportDropdownToggle()
    }
  }

  setPage = page => {
    if (page < 1 || page > this.state.totalPages || isNaN(page)) {
      this.props.Failure(`You must specify a page number between 1 and ${this.state.totalPages}.`)
      return
    }
    this.setState({ page, }, () => {
      const params = this.buildParamString()
      this.reportRequest(`${params}&page=${this.state.page}`)
    })
  }

  exportDropdownToggle = () => {
    const { nextElementSibling: exportMenu, } = this.dropdownToggle
    exportMenu.classList.toggle('show')
    this.setState({ exportMenuOpen: exportMenu.classList.contains('show'), })
  }

  setNode = node => this.reportNode = node

  render () {
    let children = ''
    if (!this.props.online) {
      children = <ReportMessageCard message={`Accessing report ${this.props.ReportName} requires an internet connection.`} />
    }
    else if (this.state.loading) {
      children = <ReportMessageCard message={'Loading Report...'} />
    }
    else if (this.state.error) {
      children = <Card>
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: this.state.error, }} />
        </CardBody>
      </Card>
    }
    else {
      children = <Card>
        <CardBody
          onClick={this.onClick}
          onKeyPress={this.onKeyPress}
          onKeyUp={this.onKeyUp}
          className={'p-2'}
        >
          <div dangerouslySetInnerHTML={{ __html: this.state.reportHtml, }} ref={this.setNode} />
        </CardBody>
      </Card>
    }

    return <Container className={'mt-4'}>
      <Row>
        <Col>
          <h1><Link to={'/admin/reports'}>Reports</Link> - {this.props.ReportName}</h1>
        </Col>
      </Row>
      <Row className={'mb-4 w-100'}>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  }
}

const mapStateToProps = (state, props) => {
  const { id, } = props.params
  let ReportName = 'Report', ReportPath = ''
  const report = state.Reports.reports.filter(r => r.Id === id)
  if (report.length) {
    ReportName = report[0].Name
    ReportPath = report[0].Path
  }
  const { online, } = state.offline
  return {
    online,
    ReportName,
    ReportPath,
    Token: state.User.token,
  }
}

const mapDispatchToProps = {
  Failure        : ApiActions.failure,
  ShowLoading    : AppActions.showLoading,
  HideLoading    : AppActions.hideLoading,
  SetPageTitle   : AppActions.setPageTitle,
  DownloadReport : ReportActions.downloadReport,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportViewer))