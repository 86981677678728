// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, Collapse, } from 'reactstrap'
import { array, object, number, bool, func, } from 'prop-types'

// Components
import { CheckBoxes, } from '../components/FormControls'

// Utilities
import { dateFormatter, } from '../utilities'


const basePropTypes = {
  burnRequest : object,
  burnedTons  : number,
  burnedAcres : number,
  daysOfWeek  : array,
  permitDays  : array,
}

const baseDefaultProps = {
  burnRequest : null,
  burnedTons  : 0,
  burnedAcres : 0,
  daysOfWeek  : [],
  permitDays  : [],
}

const BurnRequestSummaryCard = ({
  burnRequest,
  burnedTons,
  burnedAcres,
  daysOfWeek,
  permitDays,
  onClick,
  isOpen,
}) => {
  if (!burnRequest) {
    return <Card>
      <CardHeader>
        <h2 className={'d-inline-block m-0'}>
          No Burn Request
        </h2>
      </CardHeader>
    </Card>
  }
  let collapse
  if (typeof onClick === 'function') {
    collapse = <span
      onClick={onClick}
      onKeyPress={onClick}
      role={'button'}
      tabIndex={0}
      className={`fa fa-chevron-${isOpen ? 'down' : 'up'} cursor-pointer float-right`}
      style={{ transform: 'translateY(50%)', }}
    ></span>
  }
  return <Card>
    <CardHeader>
      <h2 className={'d-inline-block m-0'}>
        <Link to={'/burn-requests/' + burnRequest.BurnRequestId}>
          Burn Request {burnRequest.BurnRequestId}
        </Link>
      </h2>
      { burnRequest.AutoGenerated ? <span className={'ml-2'}>(Created Automatically)</span> : null}
      { collapse }
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody>
        <BurnRequestSummary
          burnRequest={burnRequest}
          burnedTons={burnedTons}
          burnedAcres={burnedAcres}
          daysOfWeek={daysOfWeek}
          permitDays={permitDays}
        />
      </CardBody>
    </Collapse>
  </Card>
}

BurnRequestSummaryCard.propTypes = {
  ...basePropTypes,
  isOpen  : bool,
  onClick : func,
}

BurnRequestSummaryCard.defaultProps = {
  ...baseDefaultProps,
  isOpen  : true,
  onClick : () => { },
}



const BurnRequestSummary = ({ burnRequest, daysOfWeek, }) => {
  let children = <Col xs={12} md={6}>No Burn Request</Col>
  if (!burnRequest) {
    return null
  }

  const {
    PlannedIgnitionDate,
    PlannedIgnitionTime,
    PlannedIgnitionPeriod,
    ProposedBurnArea,
    TotalProposedBurnQuantity,
    DaysOfWeek,
  } = burnRequest
    
  const dateAndTime = `${dateFormatter(PlannedIgnitionDate, 'MM/DD/YYYY')} ${PlannedIgnitionTime}`
  if (burnRequest) {
    children = <>
      <Col xs={12} md={6}>
        <FormGroup>
          <Label for={'burn-req-ignition-date'}>Ignition Date &amp; Time</Label>
          <Input
            readOnly={true}
            id={'burn-req-ignition-date'}
            value={dateAndTime}
          />
        </FormGroup>
        <FormGroup>
          <Label for={'burn-req-ingition-period'}>Ignition Period (mins)</Label>
          <Input
            id={'burn-req-ingition-period'}
            value={PlannedIgnitionPeriod}
            readOnly={true}
          />
        </FormGroup>
        <FormGroup>
          <Label for={'burn-req-acres-burn'}>Acres to Burn</Label>
          <Input
            id={'burn-req-acres-burn'}
            readOnly={true}
            value={ProposedBurnArea}
          />
        </FormGroup>
        <FormGroup className={'mb-1'}>
          <Label for={'burn-req-tons-burn'}>Tons to Burn</Label>
          <Input
            id={'burn-req-tons-burn'}
            type={'text'}
            readOnly={true}
            value={TotalProposedBurnQuantity}
          />
        </FormGroup>
      </Col>
      <Col xs={12} md={6}>
        <CheckBoxes
          inputName={'PlannedBurnDays'}
          CheckboxLabel={'Planned burn days'}
          CheckboxData={daysOfWeek}
          CheckedData={DaysOfWeek}
          readOnly={true}
        />
      </Col>
    </>
  }
  return <Row>{children}</Row>
}

BurnRequestSummary.propTypes = { ...basePropTypes, }

BurnRequestSummary.defaultProps = { ...baseDefaultProps, }

export { BurnRequestSummary, }
export default BurnRequestSummaryCard