import React from 'react'
import { Button, } from 'reactstrap'
import { useDispatch, } from 'react-redux'
import { string, func, } from 'prop-types'

// Redux
import UiActions from '../../redux/UiRedux'


const CloseModalButton = props => {
  
  const { buttonText, onClick, } = props
  
  const dispatch = useDispatch()
  
  const closeModal = React.useCallback(() => {
    if (typeof onClick === 'function') {
      onClick()
    }
    dispatch(UiActions.closeModal())
  }, [ dispatch, onClick, ])
  
  return <Button
    size={'sm'}
    color={'link'}
    onClick={closeModal}
  >
    {buttonText}
  </Button>	
}

CloseModalButton.propTypes = {
  buttonText : string,
  onClick    : func,
}

CloseModalButton.defaultProps = {
  buttonText : 'Close',
  onClick    : () => {},
}

export default CloseModalButton