import React from 'react'
import { object, string, } from 'prop-types'

export const DangerIcon = props => {
  const { style, title, } = props
  return <span
    className={'fa fa-times-circle text-danger'}
    style={{ ...style, }}
    title={title}
  />
}

DangerIcon.propTypes = {
  style : object,
  title : string,
}

DangerIcon.defaultProps = {
  style : {},
  title : '',
}

export default DangerIcon