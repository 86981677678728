// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { 
  Button, 
  Row, 
  Col, 
  FormGroup, 
  Input, 
  Label,
  Alert,
  Fade,
  Form,
  Card,
  CardBody,
} from 'reactstrap'
import { bool, } from 'prop-types'

// Components
import { AssignedStaff, AutoComplete, RowToggler, Select, } from '../FormControls'
import { ApplicationId, PermitNumber, SearchInput, NumberRange, UGA, ForestHealth, } from '../SearchInputs'

// Reducers
import AppActions from '../../redux/AppRedux'
import BurnPermitSearchActions, { INITIAL_SEARCH_PARAMS, } from '../../redux/BurnPermitSearchRedux'

// Selectors
import { regionsForSelectSelector, } from '../../selectors/regionSelectors'
import {
  countySelectSelector,
  getDnrUsersForSelect,
  permitApplicationStatuses,
  permitStatuses,
  burnTypesCheckboxSelector,
} from '../../selectors/selectors'
import { userCanSearchByAssignee, } from '../../selectors/permissionSelectors'
import { burnPermitSearchDataSelector, } from '../../selectors/burnPermitSelectors'

// Hooks
import {
  useInputChange,
  usePermitSearchActions,
  useSearchLookupsFetch,
  useEnterPressHandler,
  useAutoComplete,
} from './hooks'


function initState (nextState = null) {
  if (nextState) {
    return { ...nextState, }
  }
  return {
    searchParams: { ...INITIAL_SEARCH_PARAMS, },
  }
}

function searchFormReducer (state, action) {
  if (!action || !action.type) {
    return state
  }
  switch (action.type) {
  case 'INPUT_CHANGE': {
    if (!action.payload) {
      break
    }
    const nextParams = { ...state.searchParams, ...action.payload, }
    return { ...state, searchParams: nextParams, }
  }
  case 'RESET_PARAMS' : {
    return initState()
  }
  default: 
    break
  }
  return state
}

const SearchFormCard = (props) => {
  const { isAuthenticated, } = props

  const BurnTypes           = useSelector(burnTypesCheckboxSelector)
  const ApplicationStatuses = useSelector(permitApplicationStatuses)
  const PermitStatuses      = useSelector(permitStatuses)
  const Regions             = useSelector(regionsForSelectSelector)
  const Counties            = useSelector(countySelectSelector)
  const dnrUsers            = useSelector(getDnrUsersForSelect)
  const canSearchAssignee   = useSelector(userCanSearchByAssignee)
  const reduxDispatch = useDispatch()

  // hook to request lookup data on mount
  useSearchLookupsFetch(reduxDispatch, canSearchAssignee)

  const { showLocation, showMap, searchWarning, searchParams: savedSearchParams, } = useSelector(burnPermitSearchDataSelector)

  const [ state, dispatch, ] = React.useReducer(searchFormReducer, {
    searchParams: savedSearchParams,
  }, initState)

  const { searchParams, } = state

  const [ dispatchInputChange, onChange, onSelect, ] = useInputChange(dispatch)

  const onAutoComplete = useCallback(e => {
    const { name, id, value, } = e.target
    dispatchInputChange({ [name]: value, [`${name}Id`]: id, })
  }, [ dispatchInputChange, ])

  const [ assignedStaffChange, ] = useAutoComplete('AssignedTo', onAutoComplete)

  const [ regionAutoChange, ] = useAutoComplete('Region', onAutoComplete)

  const [ countyAutoChange, ] = useAutoComplete('County', onAutoComplete)

  const onReset = React.useCallback(() => { dispatch({ type: 'RESET_PARAMS', }) }, [])
  const [ onToggleMap, toggleLocation, onResetClick, ] = usePermitSearchActions(reduxDispatch, onReset)

  /**
   * Search button handler, update redux with new search values 
   */
  const onSearchClick = React.useCallback(() => {
    reduxDispatch(AppActions.showLoading())
    reduxDispatch(BurnPermitSearchActions.searchBurnPermits(searchParams))
  }, [ reduxDispatch, searchParams, ])

  const handleKeyPress = useEnterPressHandler(onSearchClick)

  const assignedUserSelect = React.useMemo(() => {
    let assignedTo = ''
    if (searchParams) {
      assignedTo = searchParams.AssignedTo
    }
    if (canSearchAssignee && dnrUsers && dnrUsers.length > 0) {
      return (
        <Col sm={6} md={4} lg={2}>
          <FormGroup>
            <AssignedStaff
              value={assignedTo}
              onChange={assignedStaffChange}
            />
          </FormGroup>
        </Col>
      )
    }
    return null
  }, [ searchParams, assignedStaffChange, canSearchAssignee, dnrUsers, ])

  return <Card className={'mb-3'}>
    <CardBody className={'py-2 px-3'}>
      <Form onKeyPress={handleKeyPress}>
        <Row>
          <Col sm={6} md={4} lg={2}>
            <FormGroup>
              <ApplicationId
                name={'PermitId'}
                value={searchParams.PermitId}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <FormGroup>
              <PermitNumber
                value={searchParams.BurnPermitNumber}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
          {
            isAuthenticated && <>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Agent'}
                    name={'AgentName'}
                    onChange={onChange}
                    value={searchParams.AgentName}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Agency'}
                    name={'AgencyName'}
                    onChange={onChange}
                    value={searchParams.AgencyName}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={2}>
                <FormGroup>
                  <SearchInput
                    label={'Landowner'}
                    name={'LandownerName'}
                    onChange={onChange}
                    value={searchParams.LandownerName}
                  />
                </FormGroup>
              </Col>
            </>
          }
          { assignedUserSelect }
          <Col sm={6} md={4} lg={2}>
            <Select
              label={'Burn Type'}
              items={BurnTypes}
              propertyName={'BurnTypeId'}
              selectedValue={searchParams.BurnTypeId}
              onChange={onSelect}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <Select
              label={'Application Status'}
              items={ApplicationStatuses}
              propertyName={'BurnPermitApplicationStatusId'}
              selectedValue={searchParams.BurnPermitApplicationStatusId}
              onChange={onSelect}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <Select
              label={'Permit Status'}
              items={PermitStatuses}
              propertyName={'BurnPermitStatusId'}
              selectedValue={searchParams.BurnPermitStatusId}
              onChange={onSelect}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <NumberRange
              label={'Acres'}
              minValue={searchParams.MinAcres}
              minName={'MinAcres'}
              maxValue={searchParams.MaxAcres}
              maxName={'MaxAcres'}
              onChange={onChange}
            />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <NumberRange
              label={'Tons'}
              minValue={searchParams.MinTons}
              minName={'MinTons'}
              maxValue={searchParams.MaxTons}
              maxName={'MaxTons'}
              onChange={onChange}
            />
          </Col>
          <Col xs={6} md={4} lg={2}>
            <UGA IsUGA={searchParams.IsUGA} onChange={onChange} />
          </Col>
          <Col xs={6} md={4} xl={2}>
            <ForestHealth IsForestHealthExempt={searchParams.IsForestHealthExempt} onChange={onChange} />
          </Col>
        </Row>
        <Row>
          <Col sm={'5'} md={'4'} lg={'3'} className={'mb-3'}>
            <RowToggler rowLabel={'Location Fields'} onClick={toggleLocation} show={showLocation} />
          </Col>
        </Row>
        <Fade in={showLocation} style={{ height: showLocation ? 'auto' : '0', }}>
          <Row>
            <Col sm={6} md={4} lg={2}>
              <FormGroup>
                <SearchInput
                  label={'Unit Name'}
                  name={'UnitName'}
                  onChange={onChange}
                  value={searchParams.UnitName}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'2'}>
              <FormGroup>
                <Label for={'County'}>County</Label>
                <AutoComplete
                  items={Counties}
                  value={searchParams.County}
                  onChange={countyAutoChange}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'2'}>
              <FormGroup>
                <Label for={'Region'}>Region</Label>
                <AutoComplete
                  items={Regions}
                  value={searchParams.Region}
                  onChange={regionAutoChange}
                  minCharactersToEnter={0}
                />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'3'}>
              <FormGroup>
                <Label for={'Address'}>Address</Label>
                <Input id={'Address'} name={'Address'} onChange={onChange} value={searchParams.Address} placeholder={'123 Main St Spokane, WA 12345'} />
              </FormGroup>
            </Col>
            <Col sm={'6'} md={'3'}>
              <FormGroup>
                <Label for={'LegalDesc'}>Legal Description</Label>
                <Input id={'LegalDesc'} name={'LegalDesc'} onChange={onChange} value={searchParams.LegalDesc} placeholder={'S00 T00 R00 East'}/>
              </FormGroup>
            </Col>
          </Row>
        </Fade>
        <Row>
          <Col xs={6} md={2}>
            <Button outline color={'primary'} id={'btn-reset'} onClick={onResetClick}>Reset</Button>
          </Col>
          <Col xs={12} md={6} className={'search-warning'}>
            <Fade in={!!searchWarning}><Alert color={'warning'}>{searchWarning}</Alert></Fade>
          </Col>
          <Col xs={6} md={4}>
            <Button color={'light'} onClick={onToggleMap}>{showMap ? 'Hide' : 'Show'} Map</Button>
            <Button color={'primary'} id={'btn-search'} onClick={onSearchClick} className={'float-right'}>Search</Button>
          </Col>
        </Row>
      </Form>
    </CardBody>
  </Card>
}

SearchFormCard.propTypes = {
  isAuthenticated: bool,
}

export default React.memo(SearchFormCard)
