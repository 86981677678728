// Libraries
import React from 'react'
import { string, object, PropTypes, } from 'prop-types'

// Utilities
import { dateFormatter, } from '../utilities'

const TITLE_DATE_FORMAT = 'dddd, MMMM D YYYY, h:mm:ss a'
const VISIBLE_DATE_FORMAT = 'MM/DD/YYYY'

const AuditRow = ({ Label, AuditBy, AuditDate, }) => {
  if (!AuditBy && !AuditDate) {
    return null
  }

  let markup = []
  if (AuditBy) {
    markup.push(`${Label} by ${AuditBy}`)
  }
  if (AuditDate) {
    const auditDate = dateFormatter(AuditDate)
    return <small title={auditDate.format(TITLE_DATE_FORMAT)}>{markup[0]} {auditDate.fromNow()} on {auditDate.format(VISIBLE_DATE_FORMAT)}</small>
  }
  return <small>{markup[0]}</small>
}

AuditRow.propTypes = {
  Label     : string,
  AuditBy   : string,
  AuditDate : PropTypes.oneOfType([ string, object, ]),
}
 
const AuditData = ({ CreateBy, CreateDate, UpdateBy, UpdateDate, className, style, }) => {
  return  <div className={`audit-data${className ? ` ${className}`: ''}`} style={{ ...style, }}>
    <AuditRow Label={'Created'} AuditBy={CreateBy} AuditDate={CreateDate} />
    <AuditRow Label={'Updated'} AuditBy={UpdateBy} AuditDate={UpdateDate} />
  </div>
}

AuditData.propTypes = {
  CreateBy   : string,
  CreateDate : PropTypes.oneOfType([ string, object, ]),
  UpdateBy   : string,
  UpdateDate : PropTypes.oneOfType([ string, object, ]),
  className  : string,
  style      : object,
}

AuditData.defaultProps = {
  style: {},
}

export default AuditData