import { attr, fk, Model, } from 'redux-orm'

export default class CustomerInteractionStatusXref extends Model {
  static get modelName () {
    return 'CustomerInteractionStatusXref'
  }

  toString () {
    return `${this.CustomerInteractionStatusXrefId}`
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionStatusXrefId',
    }
  }

  static get fields () {
    return {
    // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),

      // Model-Specific Properties
      CustomerInteractionStatusXrefId : attr(),
      StatusDate                      : attr(),
      Comment                         : attr(),
      CommentInternalOnly             : attr(),
      ContactedReportingParty         : attr(),

      // Relationships
      CustomerInteractionId: fk({
        to          : 'CustomerInteraction',
        relatedName : 'CustomerInteractionStatusXrefs',
        as          : 'CustomerInteraction',
      }),
      CustomerInteractionStatusId: fk({
        to          : 'CustomerInteractionStatus',
        relatedName : 'CustomerInteractionStatusXrefs',
        as          : 'CustomerInteractionStatus',
      }),
      AssignedToId: fk({
        to          : 'ApplicationUser',
        relatedName : 'CustomerInteractionAssignedUsers',
        as          : 'AssignedTo',
      }),
    }
  }
}
