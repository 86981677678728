import {
  Apply,
  Denied,
  Issued,
  Review,
  Separator,
  Sign,
} from '.'
  
const NoPaySteps = ({ paysByVoucher, isIssued, isDenied, }) => {
  return [
    Apply(),
    Separator(),
    Sign({ paysByVoucher, }),
    Separator(),
    Review(),
    Separator(),
    isDenied ? Denied() : Issued({ isIssued, }),
  ]
}
  
export default NoPaySteps