import React from 'react'
import { connect, } from 'react-redux'
import { Helmet, } from 'react-helmet'
import { Route, Routes, } from 'react-router-dom'
import { string, object, func, bool, array, shape, } from 'prop-types'

// Actions
import AppActions from './redux/AppRedux'
import ApiActions from './redux/ApiRedux'
import UserActions from './redux/UserRedux'

// Components
import withRouter from './components/withRouter'
import ErrorMessage from './components/ErrorMessage'
import SuccessToast from './components/SuccessToast'
import ErrorBoundary from './components/ErrorBoundary'
import LoadingBar from './components/LoadingBar'
import DNRNavbar from './components/Navbar'
import { CreateBurnPermit, } from './components/PermitSections'
import NotFound from './components/NotFound'
import BurnPermitOrderDetail from './components/PermitOrders/BurnPermitOrderDetail'
import DocuSignCallback from './components/DocuSign/DocuSignCallback'
import ReloadApp from './components/ReloadApp'
import ReportViewer from './components/Reports/ReportViewer'
import OfflineMessage from './components/OfflineMessage'
import AppLoading from './components/AppLoading'
import GeneralMessage from './components/GeneralMessage'
import SmokeForm from './components/CustomerInteraction/Smoke/SmokeForm'
import SmokeSearch from './components/CustomerInteraction/Smoke/SmokeSearch'
import SmokeSuccess from './components/CustomerInteraction/Smoke/SmokeSuccess'

// Containers
import DashboardContainer from './containers/DashboardContainer'
import CalendarContainer from './containers/CalendarContainer'
import BurnPermitListContainer from './containers/BurnPermitListContainer'
import BurnPermitOrderListContainer from './containers/BurnPermitOrderListContainer'
import BurnPermitOrderPayContainer from './containers/BurnPermitOrderPayContainer'
import BurnRequestListContainer from './containers/BurnRequestListContainer'
import BurnRequestDetailContainer from './containers/BurnRequestDetailContainer'
import BurnPermitSearchContainer from './containers/BurnPermitSearchContainer'
import BurnRequestSearchContainer from './containers/BurnRequestSearchContainer'
import BurnPermitFormContainer from './containers/BurnPermitFormContainer'
import LoginContainer from './containers/LoginContainer'
import ProfileContainer from './containers/ProfileContainer'
import ForestFuelTypesContainer from './containers/ForestFuelTypesContainer'
import AgencyContainer from './containers/AgencyContainer'
import AgencyListContainer from './containers/AgencyListContainer'
import PersonListContainer from './containers/PersonListContainer'
import PersonContainer from './containers/PersonContainer'
import PermitConditionsContainer from './containers/PermitConditionsContainer'
import RegionListContainer from './containers/RegionListContainer'
import PostBurnContainer from './containers/PostBurnContainer'
import ReportListContainer from './containers/ReportListContainer'
import RulesContainer from './containers/RulesContainer'
import MergePeopleContainer from './containers/MergePeopleContainer'
import HelpContainer from './containers/HelpContainer'
import MergeAgenciesContainer from './containers/MergeAgenciesContainer'
import SmokeComplaintsContainer from './containers/SmokeComplaintsContainer'

// Selectors
import { userNetworkNotifEnabled, authPropsSelector, userIsUnverifiedAgent, } from './selectors/userSelectors'
import { userMakesPayments, } from './selectors/permissionSelectors'
import { appVersionSelector, } from './selectors/appSelectors'

// Styles
import './styles/App.scss'

// Middleware
import PrivateRoute from './middleware/PrivateRoute'
import AdminRoute from './middleware/AdminRoute'
import AdminOrAgentRoute from './middleware/AdminOrAgentRoute'
import { authPropsDefaults, authPropsTypes, } from './utilities/props'

const {
  REACT_APP_ANALYTICS_ENABLED,
  REACT_APP_ANALYTICS_ID,
  REACT_APP_ENV_NAME_DISPLAY,
  REACT_APP_VERSION,
// eslint-disable-next-line no-undef
} = process.env

const IS_PRODUCTION = REACT_APP_ENV_NAME_DISPLAY !== 'production'
const APP_IS_INSTALLED = window.matchMedia('(display-mode: standalone)').matches

const EnvName = () => {
  const markup = []
  for (let i = markup.length, len = 20; i < len; i++) {
    const key = `env-name-${i}`
    markup.push(<p className={'text-danger d-inline-block mx-4'} key={key}>{REACT_APP_ENV_NAME_DISPLAY}</p>)
  }
  return <div className={'position-fixed'} style={{ width: '10000em', pointerEvents: 'none', }}>{ markup }</div>
}


class App extends React.Component {
  constructor (props) {
    super(props)
    
    // Check version and purge before app or any other component finishes mounting
    if (props.version !== REACT_APP_VERSION) {
      props.persistor.purge().then(() => {
        props.UpdateAppVersion(REACT_APP_VERSION)
      })
      return
    }

    const { online, offlinePrepared, CheckTokenExpiration, authProps, } = this.props
    const { isAuthenticated, } = authProps
    if (isAuthenticated) {
      CheckTokenExpiration()
    }
    const getLookupData = online && isAuthenticated

    if (getLookupData) {
      // When someone installs the app on their device, go get all the lookup data
      window.addEventListener('appinstalled', () => {
        if (this.props.online) {
          this.props.DownloadLookupData()
        }
        // If they have not already enabled network notifications, enable
        // the notifications for the user since the app is now installed
        // and they will likely use it offline or in low/spotty connectivity scenarios 
        if (this.props.NetworkNotificationsEnabled !== true) {
          this.props.ToggleNetworkNotifications()
        }
        // Detect if user is on mobile safari
        // solution adopted from: https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari#29696509
        const { userAgent, } = window.navigator
        const iOS = !!userAgent.match(/iP(ad|hone)/i)
        const webkit = !!userAgent.match(/WebKit/i)
        const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i)
        if (iOSSafari) {
          // Safari on iOS does not support the CacheAPI which is essential for providing offline map support
          // see: https://developer.mozilla.org/en-US/docs/Web/API/Cache#Browser_compatibility
          this.props.OfflineMessage('WARNING! The browser you are using is not capable of providing offline map functionality. If you need to use the maps offline, please use Chrome on iOS.')
        }
      })
    }
    // When someone launches the app they installed on their device, and they're online,
    // go get all the lookup data if we haven't already
    if (APP_IS_INSTALLED && getLookupData && !offlinePrepared) {
      this.props.DownloadLookupData()
    }

    window.addEventListener('newContentAvailable', () => {
      this.setState({ showReloadApp: true, })
    })
  }

  static propTypes = {
    // from withRouter HOC
    location : object,
    navigate : func,
    
    persistor                   : object,
    version                     : string,
    UpdateAppVersion            : func,
    HideLoading                 : func,
    LogLastRoute                : func,
    user                        : object,
    isUnverifiedAgent           : bool,
    redirectToRoute             : string,
    pageTitle                   : string,
    IsLoadingUser               : bool,
    LoginError                  : string,
    isVerifiedAgent             : bool,
    RouteHistory                : array,
    online                      : bool,
    offlinePrepared             : bool,
    OfflineMessage              : func,
    NetworkNotificationsEnabled : bool,
    ToggleNetworkNotifications  : func,
    appIsInstalled              : bool,
    DownloadLookupData          : func,
    CheckAgentStatus            : func,
    CheckTokenExpiration        : func,
    userPays                    : bool,
    userDoesNotSign             : bool,
    RedirectTo                  : func,
    authProps                   : shape(authPropsTypes),
  }

  static defaultProps = {
    LoginError   : '',
    RouteHistory : [],
    authProps    : shape(authPropsDefaults),
  }

  state = {
    showReloadApp: false,
  }
  
  componentDidUpdate = (prevProps) => {
    const { authProps, location, HideLoading, isUnverifiedAgent, LogLastRoute, online, CheckAgentStatus, NetworkNotificationsEnabled, offlinePrepared, CheckTokenExpiration, } = this.props
    const { isAuthenticated, } = authProps
    if (prevProps.location.pathname !== location.pathname) {
      if (online && isUnverifiedAgent) {
        CheckAgentStatus()
      }
      LogLastRoute(prevProps.location.pathname, prevProps.pageTitle)
      setTimeout(HideLoading, 2000)
      
      if (isAuthenticated) {
        CheckTokenExpiration()
      }
    }
    if (NetworkNotificationsEnabled && isAuthenticated) {
      if (!prevProps.online && online) {
        this.props.OfflineMessage('You are now back online. Any requests you made while offline will now be processed.\n\nYou can disable these notifications in your profile.')
      }
      else if (prevProps.online && !online) {
        this.props.OfflineMessage('You are now offline. All requests you make while offline will be processed once you\'re back online.\n\nYou can disable these notifications in your profile.')
      }
    }
    // When someone launches the app they installed on their device, they're online, and they're authenticated,
    // go get all the lookup data if we haven't already
    if (this.props.appIsInstalled && !prevProps.authProps.isAuthenticated && isAuthenticated && !offlinePrepared) {
      this.props.DownloadLookupData()
    }
    if (prevProps.redirectToRoute === '' && this.props.redirectToRoute !== '') {
      this.props.RedirectTo()
      this.props.navigate({ pathname: this.props.redirectToRoute, state: location.pathname, })
    }
  }

  render () {
    if (this.props.version !== REACT_APP_VERSION) {
      return <AppLoading />
    }
    const {
      pageTitle,
      user,
      RouteHistory,
      authProps,
      userPays,
    } = this.props
    return <>
      <LoadingBar style={{ top: 0, zIndex: 10, }} className={'position-fixed'} />
      <DNRNavbar {...authProps} AppVersion={REACT_APP_VERSION} appHistory={RouteHistory}/>
      <main id={'outer-container'}
        className={'App'}>
        <Helmet>
          <meta charSet={'utf-8'} />
          <title>{pageTitle}</title>
          {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
          {
            REACT_APP_ANALYTICS_ENABLED && <script async src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_ANALYTICS_ID}`}></script>
          }
          {
            REACT_APP_ANALYTICS_ENABLED && <script src={'/gtag.js'} type={'text/javascript'} />
          }
        </Helmet>
        <>
          { IS_PRODUCTION && <EnvName /> }
          <ErrorMessage />
          <OfflineMessage />
          <GeneralMessage />
          <SuccessToast />
          { this.state.showReloadApp && <ReloadApp /> }
          <ErrorBoundary authInfo={{ ...authProps, user, }} appVersion={this.props.version}>
            <Routes>
              <Route path={'/'}
                element={<DashboardContainer {...authProps} />}
              />
                
              <Route path={'/calendar'}
                element={<CalendarContainer />}
              />
              <Route path={'/help'}
                element={<HelpContainer />}
              />
                
              {/* Auth */}
              <Route path={'/Login'}
                element={<LoginContainer />}
              />
              <Route path={'/Profile'}
                element={
                  <PrivateRoute {...{ ...authProps, restrictUnverifiedAgent: false, }}>
                    <ProfileContainer {...{ ...authProps, restrictUnverifiedAgent: false, }} />
                  </PrivateRoute>
                }
              />

              {/* Permits */}
              <Route path={'/permits'}>
                <Route index
                  element={
                    <PrivateRoute {...authProps}>
                      <BurnPermitListContainer {...authProps} />
                    </PrivateRoute>
                  }
                />
                <Route path={'new'}
                  element={<CreateBurnPermit {...authProps} />}
                />
                <Route path={'search'}
                  element={<BurnPermitSearchContainer user={user} {...authProps} />}
                />
                
                <Route path={':BurnPermitId'}>
                  <Route index
                    element={
                      <PrivateRoute {...authProps}>
                        <BurnPermitFormContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                  <Route path={'conditions'}
                    element={
                      <PrivateRoute {...authProps}>
                        <PermitConditionsContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                  <Route path={'postburn'}
                    element={
                      <PrivateRoute {...authProps}>
                        <PostBurnContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route path={'signingcallback'}
                  element={
                    <PrivateRoute {...{ ...authProps, restrictAgency: this.props.userDoesNotSign, }}>
                      <DocuSignCallback {...authProps} />
                    </PrivateRoute>
                  }
                />
                <Route path={'forestfueltypes'}
                  element={<ForestFuelTypesContainer />}
                />

                {/* Orders */}
                <Route path={'orders'}>
                  <Route index
                    element={
                      <PrivateRoute {...{ ...authProps, restrictAgency: !userPays, }}>
                        <BurnPermitOrderListContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                  <Route path={':BurnPermitOrderId'}>
                    <Route index
                      element={
                        <PrivateRoute {...{ ...authProps, restrictAgency: !userPays, }}>
                          <BurnPermitOrderDetail {...authProps} />
                        </PrivateRoute>
                      }
                    />
                    <Route path={'pay'}
                      element={
                        <PrivateRoute {...{ ...authProps, restrictAgency: !userPays, }}>
                          <BurnPermitOrderPayContainer {...authProps} />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>

              {/* Burn Requests */}
              <Route path={'/burn-requests'}>
                <Route index
                  element={
                    <PrivateRoute {...authProps}>
                      <BurnRequestListContainer {...authProps} />
                    </PrivateRoute>
                  }
                />
                <Route path={'search'}
                  element={<BurnRequestSearchContainer {...authProps}/>}
                />
                <Route path={'new'}
                  element={
                    <PrivateRoute {...authProps}>
                      <BurnRequestDetailContainer {...authProps} />
                    </PrivateRoute>
                  }
                />
                <Route path={':BurnRequestId'}>
                  <Route index
                    element={
                      <PrivateRoute {...authProps}>
                        <BurnRequestDetailContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                  <Route path={'postburn'}
                    element={
                      <PrivateRoute {...authProps}>
                        <PostBurnContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>
                
              {/* Post Burns */}
              <Route path={'/post-burns'}>
                <Route path={'new'}
                  element={
                    <PrivateRoute {...authProps}>
                      <PostBurnContainer {...authProps} />
                    </PrivateRoute>
                  }
                />
                <Route path={':PostBurnId'}>
                  <Route index
                    element={
                      <PrivateRoute {...authProps}>
                        <PostBurnContainer {...authProps} />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>

              {/* Admin */}
              <Route path={'/admin'}>
                <Route path={'agencies'}>
                  <Route index
                    element={
                      <AdminRoute {...authProps}>
                        <AgencyListContainer {...authProps} />
                      </AdminRoute>
                    }
                  />
                  <Route path={':id'}
                    element={
                      <AdminRoute {...authProps}>
                        <AgencyContainer {...authProps} />
                      </AdminRoute>
                    }
                  />
                  <Route path={'new'}
                    element={
                      <AdminRoute {...authProps}>
                        <AgencyContainer {...authProps} />
                      </AdminRoute>
                    }
                  />
                  {
                    authProps.isDnr && <Route path={'merge'}
                      element={
                        <AdminRoute {...authProps}>
                          <MergeAgenciesContainer {...authProps} />
                        </AdminRoute>
                      }
                    />
                  }
                </Route>
                <Route path={'people'}>
                  <Route index
                    element={
                      <AdminOrAgentRoute {...authProps}>
                        <PersonListContainer {...authProps} />
                      </AdminOrAgentRoute>
                    }
                  />
                  <Route path={':id'}
                    element={
                      <AdminOrAgentRoute {...authProps}>
                        <PersonContainer {...authProps} />
                      </AdminOrAgentRoute>
                    }
                  />
                  <Route path={'new'}
                    element={
                      <AdminOrAgentRoute {...authProps}>
                        <PersonContainer {...authProps} />
                      </AdminOrAgentRoute>
                    }
                  />
                  <Route path={'merge'}
                    element={
                      <AdminRoute {...authProps}>
                        <MergePeopleContainer {...authProps} />
                      </AdminRoute>
                    }
                  />
                </Route>
                <Route path={'reports'}>
                  <Route index
                    element={
                      <AdminRoute {...authProps}>
                        <ReportListContainer {...authProps} />
                      </AdminRoute>
                    }
                  />
                  <Route path={':id'}
                    element={
                      <AdminRoute {...authProps}>
                        <ReportViewer {...authProps} />
                      </AdminRoute>
                    }
                  />
                </Route>
                <Route path={'rules'}
                  element={
                    <AdminRoute {...authProps}>
                      <RulesContainer {...authProps} />
                    </AdminRoute>
                  }
                />
              </Route>
              
              <Route path={'/regions'}
                element={<RegionListContainer />}
              />

              <Route path={'/smokecomplaints'}>
                <Route index
                  element={<SmokeComplaintsContainer />}
                />
                <Route path={'new'}
                  element={<SmokeForm />}
                />
                <Route path={':id'}
                  element={
                    <AdminRoute {...authProps}>
                      <SmokeForm />
                    </AdminRoute>
                  }
                />
                <Route path={'search'}
                  element={
                    <AdminRoute {...authProps}>
                      <SmokeSearch />
                    </AdminRoute>
                  }
                />
                <Route path={'success'}
                  element={<SmokeSuccess />}
                />
              </Route>
              {/* If no routes are matched, show a 404 */}
              <Route path={'*'} element={<NotFound />} />

            </Routes>
          </ErrorBoundary>
        </>
      </main>
    </>
  }
}

function mapStateToProps (state) {
  const { pageTitle, routeHistory, redirectToRoute, } = state.app
  const { online, } = state.offline 
  return {
    redirectToRoute,
    pageTitle,
    version                     : appVersionSelector(state),
    online,
    offlinePrepared             : state.api.offlinePrepared,
    RouteHistory                : routeHistory,
    authProps                   : authPropsSelector(state),
    NetworkNotificationsEnabled : userNetworkNotifEnabled(state),
    userPays                    : userMakesPayments(state),
    isUnverifiedAgent           : userIsUnverifiedAgent(state),
  }
}

const mapDispatchToProps = {
  UpdateAppVersion           : AppActions.updateAppVersion,
  HideLoading                : AppActions.hideLoading,
  LogLastRoute               : AppActions.logLastRoute,
  RedirectTo                 : AppActions.redirectTo,
  OfflineMessage             : ApiActions.offlineMessage,
  DownloadLookupData         : ApiActions.downloadLookupData,
  CheckAgentStatus           : UserActions.checkAgentVerification,
  CheckTokenExpiration       : UserActions.checkTokenExpiration,
  ToggleNetworkNotifications : UserActions.toggleNetworkNotifications,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))