import React from 'react'
import { Formik, Field, ErrorMessage, } from 'formik'
import { Form, Label, FormFeedback, } from 'reactstrap'
import * as Yup from 'yup'
import ConfirmationModal from '../ConfirmationModal'
import PropTypes from 'prop-types'

export const cancelModalName = 'modal-cancel-burn-request'
const cancelValidationSchema = Yup.object().shape({
  BurnRequestId                   : Yup.number().required('A Burn Request Id must be provided'),
  BurnRequestCancellationReasonId : Yup.number().required('You must choose a Cancellation Reason.'),
})

const noop = () => {}
const CancelBurnRequestModal = ({
  submitCancel = noop,
  burnRequestId = 0,
  cancellationReasons = [],
}) => {


  const cancelForm = React.useRef(null)
  const [ showSpinner, setShowSpinner, ] = React.useState(false)

  const setCancelFormNode = React.useCallback((node) => {
    if (node) {
      cancelForm.current = node
    }
  }, [])

  const onFormikSubmit = React.useCallback((values) => {
    setShowSpinner(true)
    submitCancel(values)
  }, [ setShowSpinner, submitCancel, ])

  

  const onSubmitButton = React.useCallback(() => {
    if (cancelForm && cancelForm.current) {
      cancelForm.current.submitForm()
    }
  }, [])

  const cancellationReasonsOptions = cancellationReasons.map(c => {
    return <option key={'cancel-' + c.Value} value={c.Value}>{c.Text}</option>
  })
  const cancelModalBody = (
    <Formik
      initialValues={{
        BurnRequestId        : burnRequestId,
        CancellationReasonId : '',
        Comment              : '',
      }}
      onSubmit={onFormikSubmit}
      validationSchema={cancelValidationSchema}
      innerRef={setCancelFormNode}
    >
      {({ errors, }) => (
        <Form>
          <p>Are you sure you want to cancel this Burn Request?</p>
          <input type={'hidden'} name={'BurnRequestId'} />
          <ErrorMessage name={'BurnRequestId'} component={FormFeedback} />
          <Label>Cancellation Reasons</Label>
          <Field component={'select'} name={'BurnRequestCancellationReasonId'} className={'form-control ' + (errors.BurnRequestCancellationReasonId ? 'is-invalid' : '')}>
            <option value={''}>Please choose a cancellation reason</option>
            {cancellationReasonsOptions}
          </Field>
          <ErrorMessage name={'BurnRequestCancellationReasonId'} component={FormFeedback}/>
          <Label>Comment</Label>
          <Field component={'textarea'} name={'Comment'} className={'form-control'} />
        </Form>
      )}
    </Formik>
  )
  return (
    <ConfirmationModal
      modalKey={cancelModalName}
      modalTitle={'Cancel Burn Request'}
      modalBody={cancelModalBody}
      submitAction={onSubmitButton}
      showSpinner={showSpinner}
    />
  )
}

CancelBurnRequestModal.propTypes = {
  burnRequestId       : PropTypes.number,
  submitCancel        : PropTypes.func,
  cancellationReasons : PropTypes.array,
}

export default CancelBurnRequestModal