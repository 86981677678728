// Libraries
import React, { useCallback, useEffect, useState, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { Row, Col, Card, CardBody, } from 'reactstrap'
import { number, bool, string, } from 'prop-types'

// Reducers
import BurnPermitSignatureActions from '../../../redux/BurnPermitSignatureRedux'
import BurnPermitFormActions from '../../../redux/BurnPermitFormRedux'
import UiActions from '../../../redux/UiRedux'

// Components
import AgreementTerms from './AgreementTerms'
import OnlinePopover from './OnlinePopover'
import BurnPermitFormSection from '../FormSection'
import Upload from '../../Upload'
import {
  ForestHealthDocAlert,
  PileLocationDocAlert,
  TonnageCalculationDocAlert,
} from '../../Alerts'
import { SignApplication, ForcePermitApplicationSignature, } from '../../Modals'
import { AutoApproval, BurnReasonDoc, } from './Alerts'
import { ApplicationSignature, PermitSignature, } from './Cards'
import { UploadPermitWarning, } from './Modals'

// Selectors
import {
  userMustSignPermitToSubmit,
  userMustUploadPermitToSubmit,
} from '../../../selectors/burnPermitPermissionSelectors'
import {
  isGovAgentPermit,
  permitHasSignedPermitDoc,
  permitApplicationIsSigned,
  documentsSectionIsValid,
  permitByIdIsSubmitted,
  permitIsIssued,
} from '../../../selectors/burnPermitSelectors'
import { agencyAutoRegionApprovalByPermitId, agencyPaysByVoucherByPermitId, } from '../../../selectors/agencySelectors'
import { isLoadingDocuSignUrl, } from '../../../selectors/burnPermitSignatureSelectors'
import { appIsOnlineSelector, } from '../../../selectors/selectors'

// Hooks
import usePermitFileUpload from '../../../hooks/usePermitFileUpload'

// Utilities
import stopEvent from '../../../utilities/stopEvent'


const Signatures = props => {

  const {
    burnPermitId,
    readOnly,
  } = props

  // State and refs
  const [ files, setFiles, ] = useState([])

  // Selectors
  const online = useSelector(appIsOnlineSelector)
  const isLoading = useSelector(isLoadingDocuSignUrl)
  const documentsSection = useSelector(state => documentsSectionIsValid(state, burnPermitId))

  // User permission selectors
  const mustUploadPermit = useSelector(userMustUploadPermitToSubmit)
  const mustSignPermit = useSelector(userMustSignPermitToSubmit)
  const hasSignedPermit = useSelector(state => permitHasSignedPermitDoc(state, burnPermitId))

  // Permit Flag selectors
  const isGovPermit = useSelector(isGovAgentPermit)
  const paysByVoucher = useSelector(state => agencyPaysByVoucherByPermitId(state, burnPermitId))
  const autoRegionApproval = useSelector(state => agencyAutoRegionApprovalByPermitId(state, burnPermitId))

  // Permit Status selectors
  const applicationIsSigned = useSelector(state => permitApplicationIsSigned(state, burnPermitId))
  const isIssued = useSelector(state => permitIsIssued(state, burnPermitId))
  const isSubmitted = useSelector(state => permitByIdIsSubmitted(state, burnPermitId))

  let {
    hideContinue,
  } = props
  if (!hideContinue) {
    if (isLoading) {
      hideContinue = true
    }
    if (applicationIsSigned) {
      hideContinue = true
    }
    if (isSubmitted) {
      hideContinue = true
    }
    if (!autoRegionApproval && mustUploadPermit) {
      hideContinue = true
    }
  }

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks

  const onSignClick = useCallback(e => {
    stopEvent(e)

    if (isLoading) {
      return
    }

    if (autoRegionApproval && online) {
      dispatch(BurnPermitFormActions.agencySubmitBurnPermit(burnPermitId))
    }
    else {
      dispatch(UiActions.openModal('DOCUSIGN_MODAL_SIGN_BURN_PERMIT_APPLICATION'))
    }
  }, [ dispatch, online, isLoading, burnPermitId, autoRegionApproval, ])

  const uploadFiles = useCallback(files => {
    setFiles(files)
  }, [])

  const doUpload = usePermitFileUpload(dispatch, burnPermitId, mustUploadPermit, isGovPermit, files)

  useEffect(() => {
    if (files.length) {
      if (mustUploadPermit && isGovPermit) {
        // open a modal
        dispatch(UiActions.openModal('CONFIRM_FILE_UPLOAD_SIDE_EFFECTS'))
      } else {
        doUpload()
      }
    }
  }, [ dispatch, files, doUpload, mustUploadPermit, isGovPermit, ])

  // mount effect
  useEffect(() => {
    dispatch(BurnPermitSignatureActions.loadingDocuSignUrl(false))
  }, [ dispatch, ])


  // Markup
  const hasSignature = applicationIsSigned || hasSignedPermit

  let markup = []

  if (!documentsSection.isValid) {
    if (documentsSection.needsForestDoc) {
      markup.push(<Col xs={12} key={'forest-health-doc'}><ForestHealthDocAlert /></Col>)
    }
    if (documentsSection.needsPileLocDoc) {
      markup.push(<Col xs={12} key={'pile-loc-doc'}><PileLocationDocAlert /></Col>)
    }
    if (documentsSection.needsTonnageDocs) {
      markup.push(<Col xs={12} key={'tonnage-calculation-doc'}><TonnageCalculationDocAlert /></Col>)
    }
  }
  else {

    if (!isIssued && !hasSignature && autoRegionApproval) {
      markup.push(<Col xs={12} key={'auto-approval'}>
        <Row>
          <AutoApproval />
        </Row>
      </Col>)
    }
    else if (!mustUploadPermit && paysByVoucher && !isSubmitted && !isIssued) {
      markup.push(<Col xs={12} key={'pays-by-voucher'}>
        <Row>
          <BurnReasonDoc />
        </Row>
      </Col>)
    }
    else if (hasSignature) {
      markup.push(
        <Col
          xs={12}
          lg={6}
          className={'mb-2'}
          key={'download-application'}
        >
          <ApplicationSignature />
        </Col>
      )
      if (isIssued) {
        const signPermit = <Col
          xs={12}
          lg={6}
          key={'permit-signature'}
        >
          <PermitSignature />
        </Col>
        markup.push(signPermit)
      }
    }
    else if (mustUploadPermit && !isSubmitted) {
      markup.push(<p key={'upload-scan-desc'}><b>You must upload a scanned copy of the applicant&apos;s signed Permit Application in order to proceed.</b></p>)
      markup.push(
        <Col xs={'6'} key={'upload-scan'}>
          <Upload
            readOnly={!documentsSection.isValid}
            title={'Upload Scanned Application'}
            uploadFiles={uploadFiles}
            validFileExtensions={'.pdf'}
          />
        </Col>
      )
    }
  }

  let preContinueControl = null
  if (!hasSignature && !readOnly && !isLoadingDocuSignUrl && mustSignPermit) {
    preContinueControl = <OnlinePopover key={'sign-app-online'} />
  }

  return <BurnPermitFormSection
    {...props}
    readOnly={readOnly}
    hideContinue={hideContinue}
    disableContinue={!documentsSection.isValid || !online}
    preContinueControl={preContinueControl}
    continueText={autoRegionApproval || mustUploadPermit ? 'Submit' : 'Sign'}
    continueFunc={onSignClick}
  >
    <Col>
      <Card>
        <CardBody>
          <AgreementTerms />
          <Row>{ markup }</Row>
        </CardBody>
      </Card>
    </Col>
    <UploadPermitWarning files={files} />
    <ForcePermitApplicationSignature BurnPermitId={burnPermitId} />
    <SignApplication burnPermitId={burnPermitId} />
  </BurnPermitFormSection>
}

Signatures.defaultProps = {
  burnPermitId : -1,
  hideContinue : false,
  readOnly     : false,
  title        : 'Signature',
}

Signatures.propTypes = {
  burnPermitId : number,
  hideContinue : bool,
  readOnly     : bool,
  title        : string,
}

export default React.memo(Signatures)
