import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitEquipmentConditionXref extends Model {
  static get modelName () {
    return 'BurnPermitEquipmentConditionXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitEquipmentConditionXrefId',
    }
  }

  static get fields () {
    return {
      BurnPermitEquipmentConditionXrefId : attr(),
      BurnPermitConditionXrefId          : fk({ 
        to          : 'BurnPermitConditionXref', 
        as          : 'BurnPermitConditionXref', 
        relatedName : 'BurnPermitEquipmentConditionXrefs',
      }),
      EquipmentTypeId: fk({
        to          : 'EquipmentType',
        as          : 'EquipmentType',
        relatedName : 'BurnPermitEquipmentConditionXrefs',
      }),
      CreateBy         : attr(),
      CreateDate       : attr(),
      UpdateBy         : attr(),
      UpdateDate       : attr(),
      OtherDescription : attr(),
    }
  }
}