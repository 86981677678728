import React, { useCallback, useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { bool, func, number, oneOfType, string, } from 'prop-types'

// Hooks
import { useShowModal, } from '../../../../hooks'

// Reducers
import { CustomerInteractionTypes, } from '../../../../redux/CustomerInteractionRedux'

// Selectors
import {
  smokeComplaintassociatedBurns,
  smokeComplaintAssociationFormIsVisible,
} from '../../../../selectors/customerInteractionSelectors'
import { appStateSelector, } from '../../../../selectors/appSelectors'

// Components
import SearchForm from './SearchForm'
import AssociatedBurns from './AssociatedBurns'
import FindBurnsBtn from './FindBurnsBtn'
import LoadingBar from '../../../LoadingBar'


const ActionBtn = props => {
  const className = useMemo(() => {
    let iconClasses = 'fa fa-plus'
    if (props.isAdd) {
      iconClasses = 'fa fa-minus'
    }
    return iconClasses
  }, [ props.isAdd, ])
  return <Button
    onClick={props.onClick}
    size={'sm'}
    className={className}
    color={'primary'}
  />
}

ActionBtn.propTypes = {
  isAdd   : bool,
  onClick : func,
}

const LinkBurnDialog = props => {
  const { CustomerInteractionId, userIsDnr, } = props

  // Selectors
  const associatedBurns = useSelector(state => smokeComplaintassociatedBurns(state, CustomerInteractionId))
  const showForm = useSelector(smokeComplaintAssociationFormIsVisible)
  const { showLoading, } = useSelector(appStateSelector)

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks
  const [ isOpen, openModal, closeModal, ] = useShowModal(dispatch, 'LINK_BURNS')

  const hasBurns = useMemo(() => (Array.isArray(associatedBurns) && associatedBurns.length), [ associatedBurns, ])

  const btnText = useMemo(() => {
    let text = 'Burns'
    if (hasBurns) {
      return `${associatedBurns.length} ${text}`
    }
    return 'Link ' + text
  }, [ hasBurns, associatedBurns, ])

  const modalText = useMemo(() => {
    let text = 'Burns'
    if (showForm) {
      return 'Link ' + text
    }
    return 'Associated ' + text
  }, [ showForm, ])

  useEffect(() => {
    if (userIsDnr && !showForm) {
      dispatch({
        type : CustomerInteractionTypes.GET_ASSOCIATED_BURNS,
        id   : CustomerInteractionId,
      })
    }
  }, [ showForm, CustomerInteractionId, userIsDnr, dispatch, ])

  const viewAssociatedBurns = useCallback(() => {
    dispatch({
      type : CustomerInteractionTypes.TOGGLE_FIND_BURNS_FORM,
      show : false,
    })
    openModal()
  }, [ openModal, dispatch, ])

  const findBurns = useCallback(() => {
    dispatch({
      type : CustomerInteractionTypes.TOGGLE_FIND_BURNS_FORM,
      show : true,
    })
  }, [ dispatch, ])

  const addEditBurns = useCallback(() => {
    findBurns()
    openModal()
  }, [ openModal, findBurns, ])

  const modalBodyStyle = useMemo(() => ({ minHeight: '53vh', }), [])
  const loadingBarStyle = useMemo(() => ({
    top     : 0,
    zIndex  : 10,
    display : showLoading ? 'block': 'none',
  }), [ showLoading, ])

  if (userIsDnr !== true) {
    return null
  }

  return <>
    <ButtonGroup className={'link-burns-btn-group'}>
      <Button
        onClick={viewAssociatedBurns}
        size={'sm'}
        color={'primary'}
      >{btnText}</Button>
      <ActionBtn onClick={addEditBurns} />
    </ButtonGroup>
    <Modal isOpen={isOpen} size={'xl'}>
      <ModalHeader toggle={closeModal}>{modalText}</ModalHeader>
      <ModalBody style={modalBodyStyle}>
        <LoadingBar style={loadingBarStyle} />
        <SearchForm
          CustomerInteractionId={CustomerInteractionId}
          show={showForm}
        />
        <FindBurnsBtn
          onClick={findBurns}
          show={!showForm}
        />
        <AssociatedBurns
          burns={associatedBurns}
          CustomerInteractionId={CustomerInteractionId}
          show={!showForm}
        />
      </ModalBody>
    </Modal>
  </>
}

LinkBurnDialog.propTypes = {
  CustomerInteractionId : oneOfType([ string, number, ]),
  userIsDnr             : bool,
}

export default React.memo(LinkBurnDialog)
