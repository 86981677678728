/* eslint-disable no-dupe-else-if */
// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { object, number, string, bool, func, array, PropTypes, } from 'prop-types'
import { connect, } from 'react-redux'
import { Container, Row, Col, Button, Card, CardBody, CardHeader, CardFooter, Alert, Badge, } from 'reactstrap'

// Components
import BurnRequestReview from '../components/BurnRequestReview'
import BurnPermitSummaryCard from '../components/BurnPermitSummary'
import PermitNumberSearch from '../components/PermitNumberSearch'
import RelatedBurnRequests from '../components/RelatedBurnRequests'
import PostBurnPrompt from '../components/PostBurnPrompt'
import BurnRequestForm from '../components/BurnRequestForm'
import withRouter from '../components/withRouter'

// Selectors
import {
  burnRequestByIdSelector,
  burnRequestStatus,
  burnRequestCancellationReasonsForSelect,
  burnRequestHasPostBurn,
  burnRequestStatusObjSelector,
  burnRequestIsApproved,
  burnRequestValSchemaSelector,
  reqCanSubmitWithoutSignature,
  permitIsPileBurnOnly,
} from '../selectors/burnRequestSelectors'
import {
  permitIsActiveSelector,
  permitHasPermitSignatures,
  permitPeopleIds,
  permitIsInUGA,
  permitIsForForestHealth,
  permitHasSignedPermitDoc,
} from '../selectors/burnPermitSelectors'
import {
  userIsSmokeMgmt,
  userIsUnverifiedAgent,
  userPersonIdSelector,
} from '../selectors/userSelectors'
import { permitByBurnPermitNumberSelector, } from '../selectors/selectors'
import { postBurnPromptSelector, } from '../selectors/postBurnSelectors'
import { userMakesSMDecisions, } from '../selectors/personSelectors'
import { signatureByPermitId, } from '../selectors/burnPermitSignatureSelectors'
import {
  userCanEditAnyInfoRequiredRequest,
  userSeesDetailedStatus,
  userCanEnterAnyIgnDate,
  userSeesPostBurnPrompt,
  userCanOverrideDecisions,
  userCanBypassSignature,
} from '../selectors/burnRequestPermissionSelectors'
import { userCreatesGovAgentPermits, } from '../selectors/burnPermitPermissionSelectors'

// Actions
import AppActions from '../redux/AppRedux'
import BurnRequestDetailActions from '../redux/BurnRequestDetailRedux'
import PostBurnActions from '../redux/PostBurnRedux'
import BurnPermitFormActions from '../redux/BurnPermitFormRedux'

// Models

// Utilities
import { getTomorrowsDate, queryParamsToObject, } from '../utilities'
import stopEvent from '../utilities/stopEvent'
import { authPropsTypes, authPropsDefaults, } from '../utilities/props'

// Constants
const noopAction = () => {
  console.error('An action was not executed')
}
export class BurnRequestDetailContainer extends React.Component {
  static propTypes = {
    // from withRouter HOC
    location : object,
    params   : object,

    // from parent
    canBypassSignature : bool,
    online             : bool,

    // selectors
    burnRequest            : object,
    BurnPermitId           : number,
    requestStatus          : object,
    CancellationReasons    : array,
    createsGovAgentPermits : bool,
    searchParam            : string,
    LoadingBurnRequest     : bool,
    PermitFailedToLoad     : bool,
    permitIsActive         : array,
    requestId              : number,
    postBurnPrompt         : PropTypes.arrayOf(object),
    permitIsSigned         : object,
    hasSignedPermitDocs    : bool,
    hasPostBurn            : bool,

    // actions
    SetPageTitle            : func,
    GetBurnRequestInfo      : func,
    ResetToInitial          : func,
    UpdateSearchParam       : func,
    GetBurnPermitInfo       : func,
    ClearPostBurnStatus     : func,
    GetPostBurnStatus       : func,
    GetPostBurnsByRequestId : func,
    GetPostBurnsByPermit    : func,
    isSmokeMgmt             : bool,
    IsRegionApproved        : bool,
    MakesSMDecisions        : bool,
    CanOverrideDecisions    : bool,
    ...authPropsTypes,
    IsPermitGovAgent        : bool,
    isInUGA                 : bool,
    ShowForestHealth        : bool,
  }

  static defaultProps = {
    PostBurnValidationSchema : null,
    canBypassSignature       : false,
    createsGovAgentPermits   : false,
    permitIsActive           : [ false, '', ],
    ClearPostBurnStatus      : noopAction,
    GetPostBurnStatus        : noopAction,
    PermitFailedToLoad       : false,
    ...authPropsDefaults,
  }

  state = {
    BurnPermitNumber      : '',
    BurnPermitNumberError : '',
    initialized           : false,
    showSpinner           : false,
    expandPostBurnPrompt  : false,
  }

  componentDidMount () {
    const { SetPageTitle, GetBurnRequestInfo, online, GetPostBurnsByRequestId, GetPostBurnsByPermit, } = this.props
    
    const { BurnRequestId, } = this.props.params
    if (online && this.props.showPostBurnPrompt) {
      this.props.GetPostBurnStatus()
    }

    if (BurnRequestId && BurnRequestId !== 'new') {
      SetPageTitle(`Burn Request #${BurnRequestId}`)
      
      if (online) {
        GetBurnRequestInfo(BurnRequestId)
        GetPostBurnsByRequestId(BurnRequestId)
      }
    }
    else {
      SetPageTitle('New Burn Request')
      const params = queryParamsToObject()
      if (params !== null && params.burnPermitNumber) {
        GetPostBurnsByPermit(null, params.burnPermitNumber)
      }
    }

    this.tomorrow = getTomorrowsDate('YYYY-MM-DD')
    const { search, } = this.props.location
    if (search) {
      const BurnPermitNumber = search.replace('?', '').split('=')[1]
      this.onPermitSearchChanged(BurnPermitNumber)
      this.doPermitSearch(BurnPermitNumber)
    }
  }

  componentWillUnmount () {
    this.props.ResetToInitial()
    this.props.ClearPostBurnStatus()
  }

  componentDidUpdate (prevProps) {
    const { location, } = this.props

    // If the user tries to navigate to /burn-request/new again, or if they created and saved a request then go to /new again
    // clear the current burn permit to restore the search
    if ((prevProps.location.key !== location.key) && this.props.params.BurnRequestId === 'new') {
      this.setState({ BurnPermitNumber: '', })
      this.props.UpdateSearchParam('')
      return
    }

    if (prevProps.PermitFailedToLoad === false && this.props.PermitFailedToLoad) {
      this.setState({ BurnPermitNumberError: 'Could not find a matching Burn Permit for the provided Burn Permit Number.', })
    }

    const { requestId, } = this.props
    if (prevProps.requestId !== requestId && requestId && requestId !== 'new') {
      this.props.SetPageTitle(`Burn Request #${requestId}`)
      if (this.props.online) {
        this.props.GetBurnRequestInfo(requestId)
        this.props.GetPostBurnsByRequestId(requestId)
      }
    }
  }

  togglePostBurn = () => {
    this.setState({ expandPostBurnPrompt: !this.state.expandPostBurnPrompt, })
  }

  reloadRequest = evt => {
    stopEvent(evt)
    let requestId
    if (this.props.burnRequest && Number.isInteger(this.props.burnRequest.BurnRequestId)) {
      requestId = this.props.burnRequest.BurnRequestId
    }
    if (this.props.online) {
      if (requestId > -1) {
        this.props.GetBurnRequestInfo(this.props.burnRequest.BurnRequestId)
      }
      else {
        window.location.reload()
      }
    }
  }

  onPermitSearchChanged = permitNumber => {
    if (this.props.PermitFailedToLoad){
      this.props.ClearPermitLoadFail()
    }
    this.setState({ BurnPermitNumber: permitNumber, BurnPermitNumberError: '', })
  }

  doPermitSearch = permitNumber => {
    if (!this.state.initialized) {
      this.setState({ initialized: true, })
    }
    if (permitNumber) {
      this.props.UpdateSearchParam(permitNumber)
      if (this.props.online) {
        this.props.GetBurnPermitInfo(permitNumber)
      }
      return
    }
    this.setState({ BurnPermitNumberError: 'You must provide a valid Burn Permit Number.', })
  }

  render () {
    const { BurnRequestId, } = this.props.params

    const { LoadingBurnRequest, canBypassSignature, isSmokeMgmt, } = this.props

    let message
    if (LoadingBurnRequest) {
      message = 'Loading Burn Request...'
    }
    else if (BurnRequestId !== 'new' && BurnRequestId > -1 && !this.props.burnRequest) {
      message = 'Burn Request not found.'
    }

    if (message) {
      return (
        <Container className={'py-2'}>
          <Row>
            <Col md={{ size: '8', offset: '2', }}>
              <h1 className={'text-center'}  style={{ fontSize: '2rem', }}>{message}</h1>
            </Col>
          </Row>
        </Container>
      )
    }

    const {
      burnRequest,
      BurnPermitId,
      requestStatus,
      permitIsSigned,
      hasSignedPermitDocs,
      IsRegionApproved,
      MakesSMDecisions,
      CanOverrideDecisions,
      IsPermitGovAgent,
      isInUGA,
      showDetailedStatus,
      requestStatusFlags,
    } = this.props

    const { IsApproved, IsSubmitted, IsCancelled, IsDenied, } = requestStatusFlags

    const {
      BurnPermitNumber,
      BurnPermitNumberError,
    } = this.state

    let children

    const agencySigned = permitIsSigned.agency || hasSignedPermitDocs
    const applicantSigned = permitIsSigned.applicant || hasSignedPermitDocs
    const bypassSignature = (permitIsSigned.dnrSubmission && canBypassSignature) || IsPermitGovAgent || this.props.isGovAutoPermit

    if (!BurnPermitId) {
      children = <PermitNumberSearch
        doSearch={this.doPermitSearch}
        onChange={this.onPermitSearchChanged}
        error={BurnPermitNumberError}
        searchHeaderText={'Please search for a permit and then provide the required information to request smoke management approval.'}
      />
    }
    else if (!burnRequest && BurnPermitId && !this.props.permitIsActive[0]) {
      children = 
        <Row>
          <Col>
            <p className={'text-danger'}>Burn Requests cannot be submitted for this permit. {this.props.permitIsActive[1]}</p>
          </Col>
        </Row>
    }
    else if (!BurnPermitId && (!BurnPermitNumber || BurnPermitNumber.length === 0)) {
      children =
        <Row>
          <Col>
            <p className={'text-danger'}>Burn Permit does not have a valid Burn Permit Number</p>
          </Col>
        </Row>
    }
    else if (!burnRequest && BurnPermitNumber && !bypassSignature && (!agencySigned || !applicantSigned)) {
      let bodyText = null
      if (agencySigned) {
        bodyText = <>
          <p>Permit {BurnPermitNumber} has been Issued and signed by a DNR representative.</p>
          <p>Before submitting Burn Requests or burning with this permit, you will need to review any conditions set for your permit and
            digitally sign with DocuSign. You can sign your permit at any time using the link in the <b>Signature</b> section of 
            your Burn Permit.
          </p>
          <p>Use the link below to review your Burn Permit and sign when you are ready.</p>
        </>
      } else {
        bodyText = <>
          <p>Permit {BurnPermitNumber} has been Issued but is awaiting a signture from a DNR representative and cannot be used to burn or submit Burn Requests.</p>
          <p>Review the <b>Application and Permit Status</b> section of your permit to check for any changes to the status of your permit.</p>
          <p>Use the link below to navigte to your permit.</p>
        </>
      }
      
      children =
        <Row>
          <Col>
            <Card>
              <CardHeader tag={'h4'} className={'d-flex justify-content-between'}>
              Burn Permit {BurnPermitNumber} is Awaiting Signature
              </CardHeader>
              <CardBody>
                {bodyText}
              </CardBody>
              <CardFooter className={'d-flex justify-content-between'}>
                <Link to={'/permits/' + BurnPermitId}>Review my Permit</Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
    }
    else {     
      const statusName = requestStatus ? requestStatus.Status || '' : ''
      children =
        <>
          <Row>
            <Col xs={12} lg={6} className={'mb-4'}>
              <BurnPermitSummaryCard
                BurnPermitId={BurnPermitId}
                showPermitLink={true}
              />
            </Col>
            <Col xs={12} lg={6}>
              <BurnRequestForm
                burnRequest={burnRequest}
                requestStatus={requestStatus}
                requestStatusFlags={requestStatusFlags}
                requestId={this.props.requestId}
                BurnPermitId={BurnPermitId}
                isDnr={this.props.isDnr}
                IsPermitGovAgent={IsPermitGovAgent}
              />
            </Col>
          </Row>

          {
            burnRequest && !LoadingBurnRequest && <>
              {
                this.props.online &&
                  <Row className={'mt-2'}>
                    <Col>
                      <RelatedBurnRequests 
                        requestId={burnRequest.BurnRequestId} 
                        requestStatus={statusName}
                        updateDate={burnRequest.UpdateDate || ''}
                      />
                    </Col>
                  </Row>
              }
              { requestStatus && 
                  <Row className={'mt-4'}>
                    {/* This will still show for cancelled requests for DNR users that can override decisions */}
                    <BurnRequestReview
                      hideForm={IsCancelled || IsDenied}
                      burnRequestId={burnRequest.BurnRequestId}
                      isSmokeMgmt={isSmokeMgmt}
                      IgnitionDate={burnRequest.PlannedIgnitionDate}
                      IsDenied={IsDenied}
                      IsApproved={IsApproved}
                      MakesSMDecisions={MakesSMDecisions}
                      CanOverrideDecisions={CanOverrideDecisions}
                      isSAWUser={this.props.isSAWUser}
                    />
                  </Row>
              } 
            </>
          }
        </>
    }
    const multiDayIcon = burnRequest && burnRequest.MultiDayBurnFlag === true ? <i className={'fas fa-m pl-1'} aria-hidden={true} aria-label={'Multi-Day Burn'} title={'Multi-Day Burn'}></i> : null
    const header = BurnRequestId ? `Burn Request #${BurnRequestId}` : 'Request Smoke Management Approval'
    const { postBurnPrompt, ShowForestHealth, } = this.props
    const hasPostBurnPrompt = postBurnPrompt && postBurnPrompt.length > 0
    return <Container className={'py-2'}>
      <Row>
        <Col xs={9}>
          <h1 style={{ fontSize: '2rem', }}>
            {header}{multiDayIcon}
            { isInUGA && <Badge color={'secondary'} className={'ml-2'}>In a UGA</Badge> }
            { ShowForestHealth && <Badge color={'success'} className={'ml-2'}><span className={'fas fa-tree'}></span></Badge> }
          </h1>
        </Col>
        <Col xs={3} className={'d-flex justify-content-end'}>
          <Button color={'link'} onClick={this.reloadRequest} disabled={!this.props.online} className={'py-2'}>Reload<span className={'fa fa-redo pl-2'} style={{ fontSize: '0.8em', }}></span></Button>
        </Col>
      </Row>
      {
        hasPostBurnPrompt && <Row className={'my-2'}>
          <Col>
            <PostBurnPrompt
              postBurnPrompt={this.props.postBurnPrompt}
              isPromptShowing={this.state.expandPostBurnPrompt}
              onToggleList={this.togglePostBurn}
              showExpander={true}
            />
          </Col>
        </Row>
      }
      {
        showDetailedStatus && (IsSubmitted && !IsApproved) && <Row className={'my-2'}>
          <Col>
            <Alert color={'info'}><p className={'m-0 text-center'}><b>Awaiting Smoke Management Review.</b></p></Alert>
          </Col>
        </Row>
      }
      {
        IsApproved && !IsRegionApproved && <Row className={'my-2'}>
          <Col>
            <Alert color={'info'}><p className={'m-0 text-center'}><b>Awaiting Region Review.</b></p></Alert>
          </Col>
        </Row>
      }
      {children}
    </Container>
  }
}

function mapStateToProps (state, props) {
  const { BurnRequestDetail, } = state

  const { BurnRequestId, } = props.params
  const requestId = parseInt(BurnRequestId)
  let burnRequest = null, requestStatus = null, BurnPermitId = null, postBurnPrompt = [], hasPostBurn = false
  let requestStatusObj = {}, IsRegionApproved = false
  if (!isNaN(requestId)) {
    burnRequest = burnRequestByIdSelector(state, requestId)
    requestStatus = burnRequestStatus(state, requestId)
    hasPostBurn = burnRequestHasPostBurn(state, requestId)
    requestStatusObj = burnRequestStatusObjSelector(state, requestId)
    if (requestStatusObj && requestStatusObj.IsApproved) {
      IsRegionApproved = burnRequestIsApproved(state, requestId)
    }
  }

  if (burnRequest) {
    BurnPermitId = burnRequest.BurnPermitId
  }

  const BurnPermitNumber = BurnRequestDetail.searchParam
  if (!BurnPermitId && BurnPermitNumber && BurnPermitNumber.length > 0) {
    const burnPermit = permitByBurnPermitNumberSelector(state, BurnPermitNumber)
    if (burnPermit) {
      BurnPermitId = burnPermit.BurnPermitId
    }
  }

  let CancellationReasons = []
  if (requestStatus && !hasPostBurn) {
    CancellationReasons = burnRequestCancellationReasonsForSelect(state)
  }

  const { AgentId, BurnerId, } = permitPeopleIds(state, BurnPermitId)
  const userPersonId = userPersonIdSelector(state)
  let IsPermitGovAgent = false
  const createsGovAgentPermits = userCreatesGovAgentPermits(state)
  if (createsGovAgentPermits) {
    if (!isNaN(AgentId) && (userPersonId === AgentId)) {
      IsPermitGovAgent = true      
    } else if (!isNaN(BurnerId) && (userPersonId === BurnerId)) {
      IsPermitGovAgent = true
    }
  }
  const permitIsActive = permitIsActiveSelector(state, BurnPermitId)

  if (props.showPostBurnPrompt) {
    postBurnPrompt = postBurnPromptSelector(state)
  }

  const MakesSMDecisions = userMakesSMDecisions(state, userPersonId)
  const CanOverrideDecisions = userCanOverrideDecisions(state)
  
  const { online, } = state.offline
  return {
    online,
    burnRequest,
    requestStatus,
    CancellationReasons,
    permitIsActive,
    requestId,
    BurnPermitId,
    hasPostBurn,
    ...requestStatusObj,
    IsRegionApproved,
    MakesSMDecisions,
    CanOverrideDecisions,
    postBurnPrompt,
    IsPermitGovAgent,
    requestStatusFlags  : requestStatusObj,
    searchParam         : BurnRequestDetail.searchParam,
    LoadingBurnRequest  : BurnRequestDetail.loadingBurnRequest,
    PermitFailedToLoad  : state.BurnPermitForm.permitFailedToLoad,
    isSmokeMgmt         : userIsSmokeMgmt(state),
    permitIsSigned      : permitHasPermitSignatures(state, BurnPermitId),
    hasSignedPermitDocs : permitHasSignedPermitDoc(state, BurnPermitId),
    Signature           : signatureByPermitId(state, BurnPermitId),
    validationSchema    : burnRequestValSchemaSelector(state),
    isInUGA             : permitIsInUGA(state, BurnPermitId),
    ShowForestHealth    : permitIsForForestHealth(state, BurnPermitId),
    isGovAutoPermit     : reqCanSubmitWithoutSignature(state, BurnPermitId),
    isPileBurnOnly      : permitIsPileBurnOnly(state, BurnPermitId),
    canEditInfoReq      : userCanEditAnyInfoRequiredRequest(state),
    showDetailedStatus  : userSeesDetailedStatus(state),
    anyIgnitionDate     : userCanEnterAnyIgnDate(state),
    showPostBurnPrompt  : userSeesPostBurnPrompt(state),
    canBypassSignature  : userCanBypassSignature(state),
    isUnverifiedAgent   : userIsUnverifiedAgent(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle            : AppActions.setPageTitle,
  GetBurnRequestInfo      : BurnRequestDetailActions.getBurnRequestInfo,
  ResetToInitial          : BurnRequestDetailActions.resetRequestToInitial,
  UpdateSearchParam       : BurnRequestDetailActions.updateRequestSearchParam,
  GetBurnPermitInfo       : BurnRequestDetailActions.getBurnPermitInfo,
  GetPostBurnStatus       : PostBurnActions.getPbPromptsForUser,
  ClearPostBurnStatus     : PostBurnActions.clearPostBurnPrompt,
  GetPostBurnsByRequestId : PostBurnActions.getPostBurnsByRequestId,
  GetPostBurnsByPermit    : PostBurnActions.getPostBurnsByPermit,
  ClearPermitLoadFail     : BurnPermitFormActions.resetPermitLoadFail,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnRequestDetailContainer))