import React from 'react'
import { useSelector, } from 'react-redux'

// Selectors
import { userCanManageAgentPeople, } from '../../../selectors/permissionSelectors'

// Components
import NavbarItem from '../NavbarItem'


const Landowners = () => {
  const canViewAgentPeople = useSelector(userCanManageAgentPeople)
  if (!canViewAgentPeople) {
    return null
  }
  const markup = <NavbarItem
    to={'/admin/people'}
    text={'Landowners'}
    title={'Landowners'}
  />
  return markup
}

export default React.memo(Landowners)
