// Libraries
import { all, put, call, fork, select, takeEvery, takeLatest, } from 'redux-saga/effects'

// Sagas
import { apiSuccess, getLookupData, doFetch, showError, } from './ApiSagas'
import { destroyLocalModel, updateLocalModel, upsertLocalModel, upsertLocalModels, createLocalModel, } from './OrmSagas'
import { GetAllRegions, } from './RegionSagas'

// Reducers
import { AppTypes, } from '../redux/AppRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'
import BurnPermitLocationActions, { BurnPermitLocationTypes, } from '../redux/BurnPermitLocationRedux'
import { GeoCoordinateTypes, } from '../redux/GeoCoordinateRedux'

// Models
import BurnPermitLocation from '../models/BurnPermitLocation'
import BurnPermitSearch from '../models/BurnPermitSearch'
import Address from '../models/Address'

// Selectors
import {
  permitLocationByIdSelector,
  networkStateSelector,
  getBurnPermit,
  getBurnPermitId,
} from '../selectors/selectors'
import {
  modelByIdSelector,
  getModelIdAttr,
} from '../selectors/modelSelectors'

// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const ADDRESS_MODEL_NAME = Address.modelName
const ADDRESS_ENDPOINT = Address.endpoint()
const BURN_PERMIT_LOCATION_ENDPOINT = BurnPermitLocation.endpoint()
const BURN_PERMIT_LOCATION_MODEL_NAME = BurnPermitLocation.modelName
const LOCATION_ID_NAME = BurnPermitLocation.options.idAttribute
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName

function* getBurnLocationLookupData () {
  // The getLookupData saga will update the ORM for us
  // so we don't need to worry about the response
  yield fork(getLookupData, { modelName: 'LocationQuarter', })
  yield fork(getLookupData, { modelName: 'Direction', })
  yield fork(GetAllRegions)
  yield fork(getLookupData, { modelName: 'County', })
  yield fork(getLookupData, { modelName: 'FireDistrictDepartment', })
}

/**
 * Get the Burn Permit Location information for the provided Burn Permit Location ID.
 * Will also request the lookup data to map the statuses to for the controls
 * @param {number} burnPermitId
 */
export function* getBurnLocationInfo ({ burnPermitLocationId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (!burnPermitLocationId) {
      yield call(showError, 'You must provide a Burn Permit Location ID in order to retrieve Location Information.')
      return
    }

    yield put({ type: GeoCoordinateTypes.RESET_GEO_COORDINATE, })
    
    yield fork(getBurnLocationLookupData)

    // Creates the Burn Permit Location Urls
    const permitLocationUrl = `${BURN_PERMIT_LOCATION_ENDPOINT}/${burnPermitLocationId}`
    
    const permitLocationResp = yield call(doFetch, permitLocationUrl)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitLocationResp.ok === false) {
      yield call(showError, `An error occurred fetching the Burn Permit Location Information for ID: ${burnPermitLocationId}.`)
      return
    }

    permitLocationResp.responseBody.IsLocal = false

    const { AddressId, } = permitLocationResp.responseBody
    if (AddressId) {
      const address = yield call(doFetch, `${REACT_APP_SERVER_URL}Addresses/${AddressId}`)
      if (address.ok && address.responseBody) {
        yield call(upsertLocalModel, ADDRESS_MODEL_NAME, address.responseBody, true)
      }
      else {
        yield call(showError, (address.responseBody || 'Did not receive a valid Address response'))
      }
    }

    // Upsert the Burn Permit Location Info
    yield call(upsertLocalModel, BURN_PERMIT_LOCATION_MODEL_NAME, permitLocationResp.responseBody, true)
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* downloadBurnLocationInfo () {
  try {
    const downloadResp = yield call(doFetch, `${BURN_PERMIT_LOCATION_ENDPOINT}/Download`)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (downloadResp.ok === false) {
      yield call(showError, 'An error occurred downloading the Burn Permit Location Information.')
      return
    }

    const {
      permitLocations,
      addresses,
    } = downloadResp.responseBody

    if (Array.isArray(addresses) && addresses.length) {
      const addrs = []
      for (let i = 0, len = addresses.length; i < len; i++) {
        addrs.push({ ...addresses[i], IsLocal: false, })
      }
      yield call(upsertLocalModels, ADDRESS_MODEL_NAME, addrs)
    }

    if (Array.isArray(permitLocations) && permitLocations.length) {
      const locs = []
      for (let i = 0, len = permitLocations.length; i < len; i++) {
        locs.push({ ...permitLocations[i], IsLocal: false, })
      }
      yield call(upsertLocalModels, BURN_PERMIT_LOCATION_MODEL_NAME, locs)
    }

    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'LocationInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* processBurnLocationAddress ({ BurnPermitLocationId, Address, method, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    const { AddressId, } = Address
    let localLocation = yield select(modelByIdSelector, { modelName: BURN_PERMIT_LOCATION_MODEL_NAME, modelId: BurnPermitLocationId, })

    if (!localLocation.IsLocal) {
      if (!online) {
        const idAttr = yield select(getModelIdAttr, ADDRESS_MODEL_NAME)
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : BurnPermitLocationTypes.PROCESS_BURN_PERMIT_LOCATION_ADDRESS_REQUEST,
          url         : `${BURN_PERMIT_LOCATION_ENDPOINT}/${BurnPermitLocationId}/Address${method !== 'POST' ? `/${AddressId}`: ''}`,
          method      : method,
          keyName     : idAttr,
          keyValue    : AddressId,
        })
      }
      if (method === 'POST' && 'AddressId' in Address) {
        delete Address.AddressId
      }
      yield put(BurnPermitLocationActions.processBurnLocationAddressRequest(BurnPermitLocationId, Address, method, online))
    }
    
    if (!online) {
      let addressId = AddressId || null
      if (method === 'DELETE') {
        yield call(destroyLocalModel, ADDRESS_MODEL_NAME, Address.AddressId)
      }
      else {
        const localAddress = yield createLocalModel(ADDRESS_MODEL_NAME, Address)
        // Set the new Address Id
        addressId = localAddress.AddressId
      }
      yield call(updateLocationForAddress, BurnPermitLocationId, addressId)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* locationAddressSuccess (resp) {
  try {
    if (resp.delete && resp.AddressId) {
      yield call(destroyLocalModel, ADDRESS_MODEL_NAME, resp.AddressId)
    }
    else {
      const address = yield call([ resp.payload, resp.payload.json, ])
      if (address) {
        yield call(upsertLocalModel, ADDRESS_MODEL_NAME, address)
        yield call(updateLocationForAddress, resp.BurnPermitLocationId, address.AddressId)
      }
    }

    if (resp.showSuccess) {
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* updateLocationForAddress (BurnPermitLocationId, AddressId) {
  // Get the Burn Permit Location Record
  const burnPermLoc = yield select(permitLocationByIdSelector, BurnPermitLocationId)
  burnPermLoc.AddressId = AddressId // AddressId can be null
  // Update the Burn Permit Location Record to set or remove the association to the Address
  yield call(updateLocalModel, BURN_PERMIT_LOCATION_MODEL_NAME, burnPermLoc.BurnPermitLocationId, burnPermLoc)
}

/**
 * Create a Burn Address object
 * @param {number} BurnPermitLocationId - The Burn Permit ID
 * @param {Object} Address - The Address data to create
 */
export function* createBurnLocationAddress ({ BurnPermitLocationId, Address, }) {
  yield call(processBurnLocationAddress, { BurnPermitLocationId, Address, method: 'POST', })
}

/**
 * Delete a Burn Address object
 * @param {Object} addressId - The Burn Address ID
 */
export function* deleteBurnLocationAddress ({ BurnPermitLocationId, AddressId, }) {
  yield call(processBurnLocationAddress, { BurnPermitLocationId, Address: { AddressId, }, method: 'DELETE', })
}

/**
 * Update a Burn Address object
 * @param {Object} addressId - The Burn Address ID
 */
export function* updateBurnLocationAddress ({ Address, }) {
  yield put({
    type      : ApiTypes.UPDATE_RECORD_REQUEST,
    url       : `${REACT_APP_SERVER_URL}/${ADDRESS_ENDPOINT}/${Address.AddressId}`,
    body      : Address,
    modelName : ADDRESS_MODEL_NAME,
  })
}

function* cancelRequest (pathMod, id) {
  yield put({
    type        : ApiTypes.CANCEL_SUBMIT,
    action_type : BurnPermitLocationTypes.UPDATE_BURN_PERMIT_LOCATION_LEGAL_DESC_REQUEST,
    url         : `${BURN_PERMIT_LOCATION_ENDPOINT}/${pathMod}/${id}`,
    method      : 'PUT',
    keyName     : LOCATION_ID_NAME,
    keyValue    : id,
  })
}

function* updateBurnPermitLocationLegalDesc ({ burnPermitLocation, }) {

  const { online, } = yield select(networkStateSelector)

  const burnPermit = yield select(getBurnPermit, { BurnPermitLocationId: burnPermitLocation.BurnPermitLocationId, })
  
  // check if model is related to a burn permit that is local
  // if it is not, check for and cancel any queued requests
  if (!burnPermit.IsLocal) {
    // if we're offline, cancel any queued req, then submit a new one
    if (!online) {
      yield call(cancelRequest, 'PutLegalLocationDescription', burnPermitLocation.BurnPermitLocationId)
    }
    yield put(BurnPermitLocationActions.updateBurnPermitLocationLegalDescRequest(burnPermitLocation, online))
  }

  if (!online) {
    const updatedLocationInfo = yield call(updateLocalModel, BURN_PERMIT_LOCATION_MODEL_NAME, burnPermitLocation.BurnPermitLocationId, burnPermitLocation)
    // update local search result so the My Permits and Search results show the pending updates
    const burnPermitId = yield select(getBurnPermitId, { BurnPermitLocationId: updatedLocationInfo.BurnPermitLocationId, })
    const searchUpdate = {
      LegalDescQ1        : updatedLocationInfo.LegalDescriptionQuarter1,
      LegalDescQ2        : updatedLocationInfo.LegalDescriptionQuarter2,
      LegalDescSection   : updatedLocationInfo.LegalDescriptionSecion,
      LegalDescTownship  : updatedLocationInfo.LegalDescriptionTownship,
      LegalDescRange     : updatedLocationInfo.LegalDescriptionRange,
      LegalDescDirection : updatedLocationInfo.LegalDescriptionDirection.DirectionName,
    }
    yield call(updateLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, burnPermitId, searchUpdate)
  }
}

function* updateBurnPermitLocationLatLong ({ burnPermitLocation, }) {
  
  const { online, } = yield select(networkStateSelector)

  const burnPermit = yield select(getBurnPermit, { BurnPermitLocationId: burnPermitLocation.BurnPermitLocationId, })
  
  // check if model is related to a burn permit that is local
  // if it is not, check for and cancel any queued requests
  if (!burnPermit.IsLocal) {
    // if we're offline, cancel any queued req, then submit a new one
    if (!online) {
      yield call(cancelRequest, 'PutLatitudeLongitude', burnPermitLocation.BurnPermitLocationId)
    }
    yield put(BurnPermitLocationActions.updateBurnPermitLocationLatLongRequest(burnPermitLocation, online))
  }

  if (!online) {
    const updatedLocationInfo = yield call(updateLocalModel, BURN_PERMIT_LOCATION_MODEL_NAME, burnPermitLocation.BurnPermitLocationId, burnPermitLocation)
    // update local search result so the My Permits and Search results show the pending updates
    const burnPermitId = yield select(getBurnPermitId, { BurnPermitLocationId: updatedLocationInfo.BurnPermitLocationId, })
    const searchUpdate = {
      Latitude  : updatedLocationInfo.Latitude,
      Longitude : updatedLocationInfo.Longitude,
    }
    yield call(updateLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, burnPermitId, searchUpdate)
  }
}

function* updateBurnPermitLocationAdditionalInfo ({ burnPermitLocation, }) {
  
  const { online, } = yield select(networkStateSelector)

  const burnPermit = yield select(getBurnPermit, { BurnPermitLocationId: burnPermitLocation.BurnPermitLocationId, })
  
  // check if model is related to a burn permit that is local
  // if it is not, check for and cancel any queued requests
  if (!burnPermit.IsLocal) {
    // if we're offline, cancel any queued req, then submit a new one
    if (!online) {
      yield call(cancelRequest, 'PutAdditionalLocationInfo', burnPermitLocation.BurnPermitLocationId)
    }
    yield put(BurnPermitLocationActions.updateBurnPermitLocationAdditionalInfoRequest(burnPermitLocation, online))
  }

  if (!online) {
    const model = { ...burnPermitLocation, Submit: true, }
    const updatedLocationInfo = yield call(updateLocalModel, BURN_PERMIT_LOCATION_MODEL_NAME, burnPermitLocation.BurnPermitLocationId, model)
    // update local search result so the My Permits and Search results show the pending updates
    const burnPermitId = yield select(getBurnPermitId, { BurnPermitLocationId: updatedLocationInfo.BurnPermitLocationId, })
    const searchUpdate = {
      County : updatedLocationInfo.County.CountyName,
      Region : updatedLocationInfo.Region.RegionName,
    }
    yield call(updateLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, burnPermitId, searchUpdate)
  }
}

export function* submitOfflineLocationEdits (localId, serverId) {
  try {
  // Get the local location model
    const burnPermitLocation = yield select(modelByIdSelector, { modelName: BurnPermitLocation.modelName, modelId: localId, })
    if (burnPermitLocation.Submit) {
    // Get the ref data
      const permitLocationRef = { ...burnPermitLocation._fields, }
      // Set the server id
      permitLocationRef.BurnPermitLocationId = serverId
    
      // PLSS and Additional Info are always required
      const legalDescUrl = `${BURN_PERMIT_LOCATION_ENDPOINT}/PutLegalLocationDescription/${serverId}`
      const locInfoUrl = `${BURN_PERMIT_LOCATION_ENDPOINT}/PutAdditionalLocationInfo/${serverId}`
      const locReqs = [
        call(updateLocInfo, legalDescUrl, permitLocationRef),
        call(updateLocInfo, locInfoUrl, permitLocationRef),
      ]

      // Only submit the lat long req if lat longs are set
      if (permitLocationRef.Latitude && permitLocationRef.Longitude) {
        const latLongUrl = `${BURN_PERMIT_LOCATION_ENDPOINT}/PutLatitudeLongitude/${serverId}`
        locReqs.push(call(updateLocInfo, latLongUrl, permitLocationRef))
      }

      // Only submit the address req if an address is set
      const locationAddress = yield select(modelByIdSelector, { modelName: 'Address', modelId: burnPermitLocation.AddressId, })
      if (locationAddress) {
        const addr = { ...locationAddress._fields, }
        delete addr.AddressId
        const locAddrUrl = `${BURN_PERMIT_LOCATION_ENDPOINT}/${serverId}/Address`
        locReqs.push(call(updateLocInfo, locAddrUrl, addr, 'POST'))
      }
      yield all(locReqs)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* updateLocInfo (url, body, method = 'PUT') {
  const resp = yield call(doFetch, url, { method, body, })
  if (resp.ok) {
    yield fork(apiSuccess, { payload: resp.responseBody, modelName: BURN_PERMIT_LOCATION_MODEL_NAME, })
  }
  else {
    yield call(showError, resp.responseBody)
  }
}


export const BurnPermitLocationSagas = [
  takeLatest(BurnPermitLocationTypes.GET_BURN_LOCATION_INFO, getBurnLocationInfo),
  takeLatest(BurnPermitLocationTypes.CREATE_BURN_LOCATION_ADDRESS, createBurnLocationAddress),
  takeLatest(BurnPermitLocationTypes.DELETE_BURN_LOCATION_ADDRESS, deleteBurnLocationAddress),
  takeLatest(BurnPermitLocationTypes.LOCATION_ADDRESS_REQUEST_SUCCESS, locationAddressSuccess),
  takeEvery(BurnPermitLocationTypes.UPDATE_BURN_PERMIT_LOCATION_LEGAL_DESC, updateBurnPermitLocationLegalDesc),
  takeEvery(BurnPermitLocationTypes.UPDATE_BURN_PERMIT_LOCATION_LAT_LONG, updateBurnPermitLocationLatLong),
  takeEvery(BurnPermitLocationTypes.UPDATE_BURN_PERMIT_LOCATION_ADDITIONAL_INFO, updateBurnPermitLocationAdditionalInfo),
  takeLatest(BurnPermitLocationTypes.GET_LOCATION_LOOKUP_DATA, getBurnLocationLookupData),
]