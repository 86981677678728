// Libraries
import { Field, } from 'formik'
import React from 'react'
import { Col, FormGroup, } from 'reactstrap'
import { string, bool, number, PropTypes, } from 'prop-types'

// Components
import { RequiredLabel, ValidatingField, } from '../../../FormControls'

// Models
import BurnPermitPileGroup from '../../../../models/BurnPermitPileGroup'

// This is implicitly ordered so that the indexing matches the PileShape value
const SHAPE_DIMENSION_PROPS = BurnPermitPileGroup.ShapeDimensionProps


const DimensionField = props => {
  const {
    pileShape,
    propName,
    readOnly,
    label,
    formGroupClasses,
  } = props
  const dimensions = SHAPE_DIMENSION_PROPS[pileShape] || []
  if (!dimensions.includes(propName)) {
    return null
  }
  return <Col xs={4}>
    <FormGroup className={formGroupClasses}>
      <RequiredLabel labelFor={propName}>{label}</RequiredLabel>
      <Field
        id={propName}
        name={propName}
        readOnly={readOnly}
        component={ValidatingField}
      />
    </FormGroup>
  </Col>
}

DimensionField.propTypes = {
  label            : string,
  propName         : string,
  readOnly         : bool,
  pileShape        : PropTypes.oneOfType([ number, string, ]),
  formGroupClasses : string,
}

export default DimensionField
