import {
  FIRE_DISTRICTS,
  TRS,
  UGA,
} from '../map/featureLayers'
import { BURN_PERMITS, } from '../map/geojsonLayers'

const DashboardLayerConfig = {
  name         : 'Dashboard',
  hiddenLayers : [
    FIRE_DISTRICTS.id,
    TRS.id,
    UGA.id,
    BURN_PERMITS().id,
  ],
  unavailableLayers: [],
}

export default DashboardLayerConfig