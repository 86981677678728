import { put, call, takeEvery, takeLatest, all, } from 'redux-saga/effects'

// Reducers
import { GeoCoordinateTypes, } from '../redux/GeoCoordinateRedux'

// Utilities
import { objHasProp, } from '../utilities'

// Sagas
import { showError, } from './ApiSagas'

// Map Config
import {
  COUNTIES,
  COUNTIES_SERVICE_URL,
  FIRE_DISTRICTS,
  FIRE_DISTRICTS_SERVICE_URL,
  REGIONS,
  REGIONS_SERVICE_URL,
  TRS,
  TRS_SERVICE_URL,
} from '../config/map/featureLayers'


const {
  REACT_APP_SHOW_LOGGING,
  REACT_APP_WAMAS_GIS_SERVICES,
  REACT_APP_REQUEST_PROXY_URL,
// eslint-disable-next-line no-undef
} = process.env

export function* geocode ({ address, city, state, zip, singleLine, }) {
  try {
    // call the address locator service to get address location candidates
    const result = yield call(addressLookup, { address, city, state, zip, singleLine, })

    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.log('result: ', result)
    }

    // if there aren't any results or no candidates just exit early
    if (!result || result.candidates.length < 1) {
      return false
    }
    else if (result.error) {
      yield call(showError, result.error)
      return
    }

    // extract the address candidate object and the lat/long
    const addressCandidate = result.candidates[0]
    const {
      x: longitude,
      y: latitude,
    } = addressCandidate.location

    // query for the county, firedistrict, region, and trs 
    // with the candidate lat/long
    const [ county, fireDistrict, region, trs, ] = yield all([
      call(getCountyByCoord, latitude, longitude),
      call(getFireDistrictByCoord, latitude, longitude),
      call(getRegionByCoord, latitude, longitude),
      call(getTRSByCoord, latitude, longitude),
    ])

    addressCandidate.layerId = 'ADDRESS'
    addressCandidate.source = 'ADDRESS'
    trs.source = 'ADDRESS'
    fireDistrict.source = 'ADDRESS'
    region.source = 'ADDRESS'
    county.source = 'ADDRESS'

    // update state with the results
    yield put({
      type    : GeoCoordinateTypes.UPDATE_GEOCODED_LOCATION,
      address : addressCandidate,
      county,
      fireDistrict,
      region,
      trs,
    })
    return true
  }
  catch(e) {
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: 'An error occurred attempting to geocode the address.', })
    return false
  }
}

export function* geocodeAddress ({ address, city, state, zip, }) {
  try {
    // call the address locator service to get address location candidates
    const result = yield call(addressLookup, { address, city, state, zip, })

    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.log('result: ', result)
    }

    // if there aren't any results or no candidates just exit early
    if (!result || result.candidates.length < 1) {
      return false
    }
    else if (result.error) {
      yield call(showError, result.error)
      return
    }

    // extract the address candidate object
    const addressCandidate = result.candidates[0]
    addressCandidate.layerId = 'ADDRESS'

    // update state with the results
    yield put({
      type    : GeoCoordinateTypes.UPDATE_GEOCODED_LOCATION,
      address : addressCandidate,
    })
    return true
  }
  catch(e) {
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: 'An error occurred attempting to geocode the address.', })
    return false
  }
}

export function* addressLookup ({ address, city, state, zip, singleLine, format = 'json', }) {
  try {
    if ((!address || !city || !state || !zip) && !singleLine && !format) {
      return null
    }
    let requestUrl = `${REACT_APP_REQUEST_PROXY_URL}?${REACT_APP_WAMAS_GIS_SERVICES}Locators/USA_StreetAddress/GeocodeServer/findAddressCandidates?OutFields=Address`
    if (address) {
      requestUrl += `&Street=${address || ''}`
    }
    if (city) {
      requestUrl += `&City=${city || ''}`
    }
    if (state) {
      requestUrl += `&State=${state || ''}`
    }
    if (zip) {
      requestUrl += `&Zip=${zip || ''}`
    }
    if (singleLine) {
      requestUrl += `&Single+Line+Input=${encodeURI(singleLine || '')}`
    }
    if (format) {
      requestUrl += `&f=${format}`
    }

    const request = yield call(fetch, requestUrl, { 'method': 'GET', })

    if (!request.ok) {
      const error = request.statusText
      yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error, })
      return { error, }
    }

    const result = yield call([ request, 'json', ])

    if (objHasProp(result, 'error')) {
      const error = `An error occurred attempting to query address ${address}. See error: ${result.error.message}`
      yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error, })
      return error
    }

    return result
  }
  catch (error) {
    yield call(showError, error)
  }
}


export function* coordIntFeatsQueryRequest (latitude, longitude, serviceUrl, outFields, includeGeometry = false, format = 'json') {
  if (!latitude || !longitude || !serviceUrl || !outFields) {
    return null
  }
  const returnGeometry = includeGeometry === true ? 'true' : 'false'
  let requestUrl = `${REACT_APP_REQUEST_PROXY_URL}?${serviceUrl}/query`
    + `?geometry=${longitude},${latitude}`
    + `&outFields=${outFields || '*'}`
    + `&f=${format}`
    + '&spatialRel=esriSpatialRelIntersects'
    + '&geometryType=esriGeometryPoint'
    + '&inSR=4326'
    + `&returnGeometry=${returnGeometry}`

  const request = yield call(fetch, requestUrl, { 'method': 'GET', })

  if (!request.ok) {
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: request.statusText, })
    return null
  }

  const result = yield call([ request, 'json', ])

  if (objHasProp(result, 'error')) {
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: `An error occurred attempting to query for ${longitude},${latitude}. See error: ${result.error.message}`, })
    return null
  }

  return result
}


export function* getRegionByCoord (latitude, longitude) {
  try {
    const response = yield call(coordIntFeatsQueryRequest, latitude, longitude, REGIONS_SERVICE_URL, 'JURISDICT_LABEL_NM')
    const { features, } = response
    if (features && features.length > 0) {
      features[0].layerId = REGIONS.id
      return features[0]
    }
    return null
  }
  catch(e) {
    yield put({ 
      type  : GeoCoordinateTypes.GEOCODE_ERROR, 
      error : `An error occurred attempting to get the Region that contains lat ${latitude} long ${longitude}.`, 
    })
    return null
  }
}

export function* getTRSByCoord (latitude, longitude) {
  try {
    const response = yield call(coordIntFeatsQueryRequest, latitude, longitude, TRS_SERVICE_URL, 'TOWNSHIP_NR,SECTION_NR,RANGE_NR,RANGE_DIR_CD', true, 'geojson')

    const { features, } = response
    if (features && features.length > 0) {
      features[0].layerId = TRS.id
      return features[0]
    }
    return null
  }
  catch(e) {
    yield put({ 
      type  : GeoCoordinateTypes.GEOCODE_ERROR, 
      error : `An error occurred attempting to get the TRS that contains lat ${latitude} long ${longitude}.`, 
    })
    return null
  }
}

export function* getCountyByCoord (latitude, longitude) {
  try {
    const response = yield call(coordIntFeatsQueryRequest, latitude, longitude, COUNTIES_SERVICE_URL, 'COUNTY_NM')

    const { features, } = response
    if (features && features.length > 0) {
      features[0].layerId = COUNTIES.id
      return features[0]
    }
    return null
  }
  catch(e) {
    yield put({ 
      type  : GeoCoordinateTypes.GEOCODE_ERROR, 
      error : `An error occurred attempting to get the County that contains lat ${latitude} long ${longitude}.`, 
    })
    return null
  }
}

export function* getFireDistrictByCoord (latitude, longitude) {
  try {
    const response = yield call(coordIntFeatsQueryRequest, latitude, longitude, FIRE_DISTRICTS_SERVICE_URL, 'FPD_DESC')

    const { features, } = response
    if (features && features.length > 0) {
      features[0].layerId = FIRE_DISTRICTS.id
      return features[0]
    }
    return null
  }
  catch(e) {
    yield put({ 
      type  : GeoCoordinateTypes.GEOCODE_ERROR, 
      error : `An error occurred attempting to get the Fire District that contains lat ${latitude} long ${longitude}.`, 
    })
    return null
  }
}

export function* geocodeLocation ({  latitude, longitude, }) {
  try {
    const [ county, fireDistrict, region, trs, ] = yield all([
      call(getCountyByCoord, latitude, longitude),
      call(getFireDistrictByCoord, latitude, longitude),
      call(getRegionByCoord, latitude, longitude),
      call(getTRSByCoord, latitude, longitude),
    ])

    yield put({
      type: GeoCoordinateTypes.UPDATE_GEOCODED_LOCATION,
      county,
      fireDistrict,
      region,
      trs,
    })
  }
  catch (e) {
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: 'An error occurred attempting to gather location information.', })
    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.error(e)
    }
    return null
  }
}


function prefixWithZero (number) {
  return number.toString().length === 1 ? `0${number}` : number
}

export function* getTRSExtent ({ section, township, range, rangeDir, }) {
  // These values need to be prefixed with leading zeroes
  section = prefixWithZero(section)
  township = prefixWithZero(township)
  range = prefixWithZero(range)

  const whereClause = `SECTION_NR = '${section}' AND TOWNSHIP_NR = '${township}' AND RANGE_NR = '${range}' AND RANGE_DIR_CD = '${rangeDir}'`
  try {
    let requestUrl = `${REACT_APP_REQUEST_PROXY_URL}?${TRS_SERVICE_URL}/query`
    + `?where=${window.encodeURI(whereClause)}`
    + '&outFields=TRS_CD'
    + '&groupByFieldsForStatistics=TRS_CD'
    + '&f=geojson'

    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.log('requestUrl: ', requestUrl)
    }
    yield put({ type: GeoCoordinateTypes.SET_IS_GEOCODING, })

    const request = yield call(fetch, requestUrl, { 'method': 'GET', })

    if (!request.ok) {
      yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: request.statusText, })
      return
    }

    const result = yield call([ request, 'json', ])

    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.log('result: ', result)
    }
    // If a match, set it 
    if (objHasProp(result, 'error')) {
      yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: `An error occurred attempting to query for ${whereClause}. See error: ${result.error.message}`, })
      return false
    }
    yield put({ type: GeoCoordinateTypes.TRS_SUCCESS, result, })
    return true
  }
  catch(e) {
    console.error(e)
    yield put({ type: GeoCoordinateTypes.GEOCODE_ERROR, error: `An error occurred attempting to query for ${whereClause}.`, })
    return false
  }
}

export const GeoCoordinateSagas = [
  takeEvery(GeoCoordinateTypes.GEOCODE, geocode),
  takeEvery(GeoCoordinateTypes.GET_TRS_EXTENT, getTRSExtent),
  takeLatest(GeoCoordinateTypes.GET_TRS_BY_COORD, getTRSByCoord),
  takeLatest(GeoCoordinateTypes.GEOCODE_LOCATION_SECTION, geocodeLocation),
  takeLatest(GeoCoordinateTypes.GEOCODE_ADDRESS_FORM, geocodeAddress),
]
