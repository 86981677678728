import { createActions, } from 'reduxsauce'
import BurnPermitLocation from '../models/BurnPermitLocation'

const BURN_PERMIT_LOCATION_ENDPOINT = BurnPermitLocation.endpoint()
const BURN_PERMIT_LOCATION_MODEL_NAME = BurnPermitLocation.modelName

const { Types, Creators, } = createActions({
  getLocationLookupData                    : null,
  getBurnLocationInfo                      : [ 'burnPermitLocationId', ],
  updateBurnPermitLocationLegalDesc        : [ 'burnPermitLocation', ],
  updateBurnPermitLocationLegalDescRequest : (burnPermitLocation, showSuccess) => ({
    type : 'UPDATE_BURN_PERMIT_LOCATION_LEGAL_DESC_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_LOCATION_ENDPOINT}/PutLegalLocationDescription/${burnPermitLocation.BurnPermitLocationId}`,
          init : { method: 'PUT', body: { ...burnPermitLocation, }, },
        },
        commit   : { type: 'API_SUCCESS', modelName: BURN_PERMIT_LOCATION_MODEL_NAME, showSuccess, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  updateBurnPermitLocationLatLong        : [ 'burnPermitLocation', ],
  updateBurnPermitLocationLatLongRequest : (burnPermitLocation, showSuccess) => ({
    type : 'UPDATE_BURN_PERMIT_LOCATION_LAT_LONG_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_LOCATION_ENDPOINT}/PutLatitudeLongitude/${burnPermitLocation.BurnPermitLocationId}`,
          init : { method: 'PUT', body: { ...burnPermitLocation, }, },
        },
        commit   : { type: 'API_SUCCESS', modelName: BURN_PERMIT_LOCATION_MODEL_NAME, showSuccess, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  updateBurnPermitLocationAdditionalInfo        : [ 'burnPermitLocation', ],
  updateBurnPermitLocationAdditionalInfoRequest : (burnPermitLocation, showSuccess) => ({
    type : 'UPDATE_BURN_PERMIT_LOCATION_ADDITIONAL_INFO_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_LOCATION_ENDPOINT}/PutAdditionalLocationInfo/${burnPermitLocation.BurnPermitLocationId}`,
          init : { method: 'PUT', body: { ...burnPermitLocation, }, },
        },
        commit   : { type: 'API_SUCCESS', modelName: BURN_PERMIT_LOCATION_MODEL_NAME, showSuccess, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  createBurnLocationAddress         : [ 'BurnPermitLocationId', 'Address', ],
  deleteBurnLocationAddress         : [ 'BurnPermitLocationId', 'AddressId', ],
  processBurnLocationAddressRequest : (BurnPermitLocationId, address, method, showSuccess) => ({
    type : 'PROCESS_BURN_PERMIT_LOCATION_ADDRESS_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_LOCATION_ENDPOINT}/${BurnPermitLocationId}/Address${method !== 'POST' ? `/${address.AddressId}`: ''}`,
          init : { method, body: { ...address, }, },
        },
        commit   : { type: 'LOCATION_ADDRESS_REQUEST_SUCCESS', BurnPermitLocationId, AddressId: address.AddressId, showSuccess, delete: (method === 'DELETE'), },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  locationAddressRequestSuccess: [ 'payload', ],
})

export const BurnPermitLocationTypes = Types
export default Creators