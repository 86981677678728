// Libraries
import React from 'react'
import { Button, } from 'reactstrap'
import { useDispatch, } from 'react-redux'
import { func, bool, } from 'prop-types'

// Components
import ConfirmationModal from '../ConfirmationModal'
import { PopoverButton, } from '../FormControls'

// Redux
import UiActions from '../../redux/UiRedux'

const modalKey = 'EDIT_CONTACTS_CONDITIONS'

const EditContactsAndConditions = props => {
  const { online, submitAction, } = props
  
  const dispatch = useDispatch()
  
  const onClick = React.useCallback(() => dispatch(UiActions.openModal(modalKey)), [ dispatch, ])
  
  const onConfirm = React.useCallback(() => {
    dispatch(UiActions.closeModal())
    submitAction()
  }, [ dispatch, submitAction, ])
  
  return <>
    <Button
      color={'secondary'}
      size={'md'}
      onClick={onClick}
      disabled={!online}
    >
      <span className={'d-block d-lg-none fa fa-edit p-1'}/>
      <span className={'d-none d-lg-block'}>Edit</span>
    </Button>
    {
      !online
        ? <PopoverButton
          buttonClassName={'py-0'}
          key={'edit-contacts-conditions'}
          popoverHeader={'Online only'}
          popoverBody={'Editing Contacts and Conditions can only be performed while connected to the internet.'}
        />
        : null
    }
    <ConfirmationModal
      modalKey={modalKey}
      modalTitle={'Edit Contacts and Conditions'}
      modalBody={<>
        <p>
          Are you sure you want to update these Contacts and Conditions?
        </p>
        <p>
          If so, you must sign a new permit in DocuSign.
        </p>
      </>}
      submitActionLabel={'Yes, I\'m sure'}
      submitAction={onConfirm}
    />
  </>
}

EditContactsAndConditions.propTypes = {
  submitAction : func,
  online       : bool,
}

export default EditContactsAndConditions