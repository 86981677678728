import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getCustomerInteraction                 : [ 'id', ],
  newCustomerInteraction                 : [ 'interactionType', ],
  toggleCustomerInteractionMap           : null,
  clearCustomerInteractionSearch         : [ 'interactionType', ],
  upsertCustomerInteraction              : [ 'values', ],
  upsertCustomerInteractionSuccess       : [ 'id', ],
  getCustomerInteractionLookups          : null,
  submitSmokeComplaint                   : [ 'values', ],
  createCustomerInteractionStatus        : [ 'values', ],
  createCustomerInteractionStatusSuccess : null,
  associateBurn                          : [ 'values', ],
  getAssociatedBurns                     : [ 'id', ],
  associatedBurns                        : [ 'data', ],
  removeBurnAssociation                  : [ 'values', ],
  removeBurnAssociationSuccess           : [ 'xrefId', ],
  smokePromptAcknowledged                : null,
  tourCompleted                          : [ 'interactionType', ],
  searchCustomerInteractions             : [ 'searchParams', ],
  searchCustomerInteractionsResults      : [ 'results', ],
  mapDragEnd                             : [ 'dragEvt', ],
  showCustomerInteractionStatusForm      : [ 'showForm', ],
  openModal                              : null,
  toggleFindBurnsForm                    : [ 'show', ],
  findAssociatedBurns                    : [ 'searchParams', ],
  findAssociatedBurnsResults             : [ 'data', ],
  geocodeCustIntAddress                  : [ 'address', ],
  geocodeCustIntAddressSuccess           : [ 'location', 'regionId', ],
})

export const CustomerInteractionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  targetType : '',
  id         : '',
  Smoke      : {
    acknowledgedPrompt : false,
    tourCompleted      : false,
    showMap            : false,
  },
  mapState      : {},
  searchParams  : {},
  searchResults : {},
  status        : {
    success  : false,
    showForm : false,
  },
  associatedBurns: {
    showForm      : false,
    data          : [],
    searchResults : [],
    searchParams  : {},
  },
}

const mapDragEnd = (state, { dragEvt, }) => {
  const newState = { ...state, }
  if (!dragEvt || (!dragEvt.center && !dragEvt.zoom)) {
    return newState
  }
  const { center, zoom, } = dragEvt
  newState.mapState[newState.targetType] = { center, zoom, }
  return newState
}

const geocodeCustIntAddressSuccess = (state, { location, regionId, }) => {
  const newState = { ...state, }
  if (!location || (!location.x && !location.y)) {
    return newState
  }
  const targetTypeMapState = {
    ...newState.mapState[newState.targetType],
    location,
    regionId,
  }
  newState.mapState[newState.targetType] = targetTypeMapState
  return newState
}

const clearCustomerInteractionSearch = (state, { interactionType, }) => {
  const newState = { ...state, }
  interactionType = interactionType || newState.targetType
  newState.searchParams[interactionType] = {}
  newState.searchResults[interactionType] = []
  return newState
}

const toggleCustomerInteractionMap = (state) => {
  const newState = { ...state, }
  const currMapState = { ...(newState.mapState[newState.targetType] || {}), }
  let { showMap, } = currMapState
  if (showMap === undefined || showMap === null) {
    showMap = false
  }
  newState.mapState[newState.targetType] = { ...currMapState, showMap: !showMap, }
  return newState
}

const searchCustomerInteractions = (state, { searchParams, }) => {
  const newState = { ...state, }
  newState.searchParams = {
    [newState.targetType]: { ...searchParams, },
  }
  return newState
}

const searchCustomerInteractionsResults = (state, { results, }) => {
  const newState = { ...state, }
  if (!newState.searchResults) {
    newState.searchResults = {}
  }
  newState.searchResults[newState.targetType] = [ ...results, ]
  return newState
}

const newCustomerInteraction = (state, { interactionType, }) => {
  const newState = { ...state, }
  newState.targetType = interactionType
  newState.id = null
  newState.status = { ...INITIAL_STATE.status, }
  newState.associatedBurns = { ...INITIAL_STATE.associatedBurns, }
  newState.mapState = { ...INITIAL_STATE.mapState, }
  newState.searchParams = { ...INITIAL_STATE.searchParams, }
  newState.searchResults = { ...INITIAL_STATE.searchResults, }
  return newState
}

const upsertCustomerInteractionSuccess = (state, { id, }) => {
  const newState = { ...state, }
  newState.id = id
  newState.mapState = {}
  return newState
}

const createCustomerInteractionStatusSuccess = (state) => {
  const newState = { ...state, }
  newState.status.success = true
  return newState
}

const showCustomerInteractionStatusForm = (state, { showForm, }) => {
  const newState = { ...state, }
  newState.status.showForm = showForm
  newState.status.success = false
  return newState
}

const smokePromptAcknowledged = state => {
  const newState = { ...state, Smoke: { ...state.Smoke, }, }
  newState.Smoke.acknowledgedPrompt = true
  return newState
}

const tourCompleted = (state, { interactionType, }) => {
  const newState = { ...state, [interactionType]: { ...state[interactionType], }, }
  newState[interactionType].tourCompleted = true
  return newState
}

export const submitSmokeComplaint = (state) => {
  const newState = { ...state, Smoke: { ...state.Smoke, }, }
  newState.Smoke.acknowledgedPrompt = false
  return newState
}

const openStatusModal = (state) => {
  const newState = { ...state, }
  newState.status.showForm = false
  newState.status.success = false
  return newState
}

const toggleFindBurnsForm = (state, { show, }) => {
  const newState = { ...state, }
  newState.associatedBurns =  { ...newState.associatedBurns, }
  newState.associatedBurns.showForm = show
  return newState
}

const findAssociatedBurns = (state, { searchParams, }) => {
  const newState = { ...state, }
  newState.associatedBurns =  { ...newState.associatedBurns, }
  newState.associatedBurns.searchParams = { ...searchParams, }
  return newState
}

const findAssociatedBurnsResults = (state, { data, }) => {
  const newState = { ...state, }
  newState.associatedBurns =  { ...newState.associatedBurns, }
  newState.associatedBurns.searchResults = [ ...data, ]
  return newState
}

const associatedBurns = (state, { data, }) => {
  const newState = { ...state, }
  newState.associatedBurns =  { ...newState.associatedBurns, }
  newState.associatedBurns.data = [ ...data, ]
  return newState
}

const removeBurnAssociationSuccess = (state) => {
  const newState = { ...state, }
  newState.associatedBurns =  { ...newState.associatedBurns, }
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_CUSTOMER_INTERACTION_SEARCH]          : clearCustomerInteractionSearch,
  [Types.TOGGLE_CUSTOMER_INTERACTION_MAP]            : toggleCustomerInteractionMap,
  [Types.SEARCH_CUSTOMER_INTERACTIONS]               : searchCustomerInteractions,
  [Types.SEARCH_CUSTOMER_INTERACTIONS_RESULTS]       : searchCustomerInteractionsResults,
  [Types.NEW_CUSTOMER_INTERACTION]                   : newCustomerInteraction,
  [Types.UPSERT_CUSTOMER_INTERACTION_SUCCESS]        : upsertCustomerInteractionSuccess,
  [Types.CREATE_CUSTOMER_INTERACTION_STATUS_SUCCESS] : createCustomerInteractionStatusSuccess,
  [Types.SUBMIT_SMOKE_COMPLAINT]                     : submitSmokeComplaint,
  [Types.SMOKE_PROMPT_ACKNOWLEDGED]                  : smokePromptAcknowledged,
  [Types.TOUR_COMPLETED]                             : tourCompleted,
  [Types.MAP_DRAG_END]                               : mapDragEnd,
  [Types.OPEN_MODAL]                                 : openStatusModal,
  [Types.SHOW_CUSTOMER_INTERACTION_STATUS_FORM]      : showCustomerInteractionStatusForm,
  [Types.TOGGLE_FIND_BURNS_FORM]                     : toggleFindBurnsForm,
  [Types.FIND_ASSOCIATED_BURNS]                      : findAssociatedBurns,
  [Types.FIND_ASSOCIATED_BURNS_RESULTS]              : findAssociatedBurnsResults,
  [Types.ASSOCIATED_BURNS]                           : associatedBurns,
  [Types.REMOVE_BURN_ASSOCIATION_SUCCESS]            : removeBurnAssociationSuccess,
  [Types.GEOCODE_CUST_INT_ADDRESS_SUCCESS]           : geocodeCustIntAddressSuccess,
})
