import { createReducer, createActions, } from 'reduxsauce'

import BurnRequestSearch from '../models/BurnRequestSearch'
    
/* ------------- Types and Action Creators ------------- */
  
const { Types, Creators, } = createActions({
  toggleRequestLocation    : null,
  toggleRequestSearchMap   : null,
  resetRequestSearchParams : null,
  setRequestSearchParam    : [ 'param', ],
  searchBurnRequests       : (searchParams) => ({
    type : 'SEARCH_BURN_REQUESTS',
    searchParams,
    meta : {
      offline: {
        effect: {
          url: BurnRequestSearch.endpoint(searchParams),
        },
        commit   : { type: 'SEARCH_BURN_REQUESTS_SUCCESS', },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  searchBurnRequestsSuccess    : [ 'payload', ],
  setDownloadingRequestsExport : [ 'isDownloading', ],
  downloadRequestsExport       : [],
  resetRequestResults          : [ 'message', ],
})
  
export const BurnRequestSearchTypes = Types
export default Creators
  
/* ------------- Initial State ------------- */
export const INITIAL_SEARCH_PARAMS = {
  BurnRequestId            : '',
  BurnPermitId             : '',
  BurnPermitNumber         : '',
  RequestStatusId          : '',
  BurnRequestStartDate     : '',
  BurnRequestEndDate       : '',
  PlannedIgnitionStartDate : '',
  PlannedIgnitionEndDate   : '',
  AssignedTo               : '',
  AssignedToId             : '',
  UnitName                 : '',
  County                   : '',
  CountyId                 : '',
  LegalDesc                : '',
  Address                  : '',
  MinAcres                 : '',
  MaxAcres                 : '',
  MinTons                  : '',
  MaxTons                  : '',
  Region                   : '',
  RegionId                 : '',
  BurnType                 : '',
  BurnTypeId               : '',
  LandownerName            : '',
  AgentName                : '',
  HasPostBurn              : '',
  MultiDayBurns            : '',
  IsUGA                    : '',
  IsForestHealthExempt     : '',
}

export const INITIAL_STATE = {
  showMap             : false,
  showLocation        : false,
  defaultSearchParams : null,
  searchParams        : { ...INITIAL_SEARCH_PARAMS, },
  searchWarning       : 'Please enter one or more search parameters, then click Search.',
  isDownloadingExport : false,
}
    
/* ------------- Reducers ------------- */

export const setRequestSearchParam = (state, { param, }) => {
  const newState = { ...state, }
  newState.searchParams = { ...newState.searchParams, ...param, }
  return newState
}

export const searchBurnRequests = (state, { searchParams, }) => {
  const newState = { ...state, searchParams: { ...searchParams, }, }
  const hasParamVals = Object.entries(newState.searchParams)
    .map(e => e[1])
    .reduce((acc, val) => acc.concat(val), [])
    .some(v => !!v)
  let searchWarning = ''
  if (!hasParamVals) {
    searchWarning = 'Because you did not enter search values, you will only receive the first 1,000 records.'
  }
  newState.searchWarning = searchWarning
  return newState
}

export const resetRequestSearchParams = (state) => {
  const newState = { ...state, }
  newState.searchParams = { ...INITIAL_SEARCH_PARAMS, ...newState.defaultSearchParams, }
  return newState
}

export const toggleRequestSearchMap = (state) => {
  const newState = Object.assign({}, state)
  newState.showMap = !newState.showMap
  return newState
}

export const toggleRequestLocation = (state) => {
  const newState = Object.assign({}, state)
  newState.showLocation = !newState.showLocation
  return newState
}

export const setDownloadingExport = (state, { isDownloading, }) => {
  return { 
    ...state, 
    isDownloadingExport: isDownloading, 
  } 
}

export const downloadRequestsExport = (state) => {
  return { 
    ...state, 
    isDownloadingExport: true, 
  } 
}

export const resetRequestResults = (state, { message, }) => {
  if (message) {
    return {
      ...state,
      searchWarning: message,
    }
  }
  return state
}

/* ------------- Hookup Reducers To Types ------------- */
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOGGLE_REQUEST_SEARCH_MAP]       : toggleRequestSearchMap,
  [Types.TOGGLE_REQUEST_LOCATION]         : toggleRequestLocation,
  [Types.SET_REQUEST_SEARCH_PARAM]        : setRequestSearchParam,
  [Types.SEARCH_BURN_REQUESTS]            : searchBurnRequests,
  [Types.RESET_REQUEST_SEARCH_PARAMS]     : resetRequestSearchParams,
  [Types.SET_DOWNLOADING_REQUESTS_EXPORT] : setDownloadingExport,
  [Types.DOWNLOAD_REQUESTS_EXPORT]        : downloadRequestsExport,
  [Types.RESET_REQUEST_RESULTS]           : resetRequestResults,
})