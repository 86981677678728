// Libraries
import React, { useCallback, } from 'react'
import { Link, } from 'react-router-dom'
import { isEmpty, } from 'lodash'
import { bool, func, array, object, string, PropTypes, number, } from 'prop-types'
import { Button, Container, Row, Col, ModalHeader, ModalBody, ModalFooter, Modal, Table, Badge, } from 'reactstrap'
import stopEvent from '../utilities/stopEvent'

const ModalTd = ({ children, }) => {
  return <td className={'px-2 py-1'}>{children}</td>
}

ModalTd.propTypes = {
  children: PropTypes.node,
}

const CalendarModal = ({
  toggleModal,
  modalOpen = false,
  title,
  iconText,
  icon,
  status,
  id,
  eventLink,
  eventDetails = [],
  viewOnMap,
}) => {
  let iconHeader = null
  if (icon) {
    iconHeader = <Container className={'p-2'}>
      <Row>
        <Col>
          <Badge color={'light'} className={'mr-2'} title={iconText}><span className={icon}></span></Badge>
          {iconText}
        </Col>
      </Row>
    </Container>
  }

  const eventDetailRows = eventDetails.map((d, idx) => {
    return <tr key={`${id}-${idx}`}>
      <ModalTd>{d.Label}</ModalTd>
      <ModalTd>{d.Value}</ModalTd>
    </tr>
  })

  if (!isEmpty(status)) {
    eventDetailRows.unshift(<tr key={`${id}-status`}>
      <ModalTd>Status</ModalTd>
      <ModalTd><Badge color={status.Color}>{status.Value}</Badge></ModalTd>
    </tr>)
  }

  const onMapLinkClick = useCallback((e) => {
    stopEvent(e)
    viewOnMap(id)
  },[ id, viewOnMap, ])

  const footerLink = !isEmpty(eventLink) ? <Link to={eventLink.Url}>{eventLink.Text}</Link> : null
  const mapLink = viewOnMap ? <Button onClick={onMapLinkClick}>View On Map</Button> : null

  return <Modal isOpen={modalOpen} toggle={toggleModal}>
    <ModalHeader toggle={toggleModal} tag={'p'}>
      { title }
    </ModalHeader>
    <ModalBody className={'p-0 calendar-event-detail'}>
      {iconHeader}
      <Table className={'mb-0'}>
        <tbody>
          {eventDetailRows}
        </tbody>
      </Table>
    </ModalBody>
    <ModalFooter className={'d-flex justify-content-between'}>{mapLink} {footerLink}</ModalFooter>
  </Modal>
}

CalendarModal.propTypes = {
  eventLink    : object,
  status       : object,
  id           : number,
  eventDetails : array,
  toggleModal  : func,
  modalOpen    : bool,
  title        : string,
  iconText     : string,
  icon         : string,
  viewOnMap    : func,
}

export default CalendarModal