import { attr, Model, } from 'redux-orm'

export default class ArrivalTime extends Model {
  static get modelName () {
    return 'ArrivalTime'
  }

  toString () {
    return `${this.ArrivalTimeName}`
  }

  static get options () {
    return {
      idAttribute: 'ArrivalTimeId',
    }
  }

  static get fields () {
    return {
      ArrivalTimeId   : attr(),
      ArrivalTimeName : attr({ getDefault: () => '', }),
      CreateDate      : attr(),
      UpdateDate      : attr(),
    }
  }
}