import React, { useEffect, } from 'react'
import { useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router'
import { userIsDNR, } from '../selectors/userSelectors'


const SmokeComplaintsContainer = () => {
  const navigate = useNavigate()

  // Selectors
  const isDnr = useSelector(userIsDNR)

  // Redux actions
  useEffect(() => {
    if (isDnr) {
      navigate('/smokecomplaints/search')
    }
    else {
      navigate('/smokecomplaints/new')
    }
  }, [ navigate, isDnr, ])

  return null
}

export default React.memo(SmokeComplaintsContainer)
