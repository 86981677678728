import { attr, Model, } from 'redux-orm'

export default class CustomerInteractionType extends Model {
  static get modelName () {
    return 'CustomerInteractionType'
  }

  toString () {
    return `${this.CustomerInteractionTypeId}`
  }

  static endpoint () {
    return 'CustomerInteractionTypes'
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionTypeId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),

      // Model-Specific Properties
      CustomerInteractionTypeId   : attr(),
      CustomerInteractionTypeName : attr(),
    }
  }
}
