// Libraries
import { put, call, select, takeEvery, } from 'redux-saga/effects'

// Reducers 
import { ApiTypes, } from '../redux/ApiRedux'
import { ForestHealthTypes, } from '../redux/ForestHealthRedux'

// Sagas
import { showError, doFetch, } from './ApiSagas'
import { replaceAll, upsertLocalModel, } from './OrmSagas'
import { getBurnPermit, } from './BurnPermitSagas'

// Models
import BurnPermit from '../models/BurnPermit'
import ForestHealthExempt from '../models/ForestHealthExempt'
import ForestHealthExemptProblemTypeXref from '../models/ForestHealthExemptProblemTypeXref'

// Selectors
import { networkStateSelector, } from '../selectors/selectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'

// Constants
const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const FOREST_HEALTH_EXEMPT_MODEL_NAME = ForestHealthExempt.modelName
const PROBLEM_TYPE_XREF_MODEL_NAME = ForestHealthExemptProblemTypeXref.modelName


/**
 * Get the Forest Health Data for the provided Forest Health ID.
 * Will also request the lookup data to map the statuses to for the controls
 * @param {number} forestHealthId
 */
export function* getForestHealthData ({ forestHealthId, }) {
  try {

    if (!forestHealthId) {
      yield call(showError, 'You must provide a Forest Health ID in order to retrieve the application Forest Health Data.')
      return
    }

    const permitForestHealthUrl = `${ForestHealthExempt.endpoint()}/${forestHealthId}`
    
    const permitForestHealthResp = yield call(doFetch, permitForestHealthUrl)
    // If it's not an Ok, 200, or 404 response, throw an Error
    if (permitForestHealthResp.ok === false) {
      yield call(showError, `An error occurred fetching the Forest Health application data ID: ${forestHealthId}.`)
      return
    }

    const forestHealthExempt = permitForestHealthResp.responseBody
    yield call(upsertForestHealthData, forestHealthExempt)
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Creates the Forest Health data for a Burn Permit Application
 * specified by the `burnPermitId`
 * @param {number} burnPermitId
 * @param {object} forestHealthData
 */
function* createForestHealthData ({ burnPermitId, forestHealthData, }) {
  const { online, } = yield select(networkStateSelector)
  
  const requestData = { ...forestHealthData, }
  const { ForestHealthExemptId, } = requestData
  
  const localForestHealthExempt = yield select(modelByIdSelector, { modelName: FOREST_HEALTH_EXEMPT_MODEL_NAME, modelId: ForestHealthExemptId, })
  
  if (!localForestHealthExempt || !localForestHealthExempt.IsLocal) {
    const url = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/ForestHealthExempt`
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : ForestHealthTypes.UPDATE_BURN_PERMIT_SITE_REQUEST,
        url         : url,
        method      : 'POST',
        keyName     : ForestHealthExempt.options.idAttribute,
        keyValue    : ForestHealthExemptId,
      })
    }

    requestData.ForestHealthExemptProblemTypeXref = [ { ForestHealthProblemTypeId: requestData.ProblemTypeId, }, ]
    // Ensure we set correct default values for this scenario
    requestData.ForestHealthExemptId = 0
    requestData.AlternativeNotAppropriateReason = ''
    const resp = yield call(doFetch, url, { method: 'POST', body: requestData, })
    if (!resp.ok) {
      const error = resp.responseBody.error || 'An error occurred creating the Forest Health Exempt record'
      yield call(showError, error)
    }

    const newForestHealthExempt = resp.responseBody

    yield call(upsertForestHealthData, newForestHealthExempt)

    yield put({ type: 'SHOW_SUCCESS', })
    // Get the burn permit info again to get the forest health id set
    yield call(getBurnPermit, { burnPermitId, })
  }
  
  if (!online) {
    yield call(upsertForestHealthData, localForestHealthExempt, true)
  }
}

/**
 * Updates the Forest Health data for a Burn Permit Application
 * specified by the `burnPermitId`
 * @param {number} burnPermitId
 * @param {object} forestHealthData
 */
function* updateForestHealthData ({ burnPermitId, exemptId, forestHealthData, }) {
  const { online, } = yield select(networkStateSelector)
  
  const requestData = { ...forestHealthData, }
  
  const localForestHealthExempt = yield select(modelByIdSelector, { modelName: FOREST_HEALTH_EXEMPT_MODEL_NAME, modelId: exemptId, })
  
  if (!localForestHealthExempt || !localForestHealthExempt.IsLocal) {
    const url = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/ForestHealthExempt/${exemptId}`
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : ForestHealthTypes.UPDATE_BURN_PERMIT_SITE_REQUEST,
        url         : url,
        method      : 'PUT',
        keyName     : ForestHealthExempt.options.idAttribute,
        keyValue    : exemptId,
      })
    }

    requestData.ForestHealthExemptProblemTypeXref = [ { ForestHealthProblemTypeId: requestData.ProblemTypeId, }, ]

    const resp = yield call(doFetch, url, { method: 'PUT', body: requestData, })
    if (!resp.ok) {
      const error = resp.responseBody.error || 'An error occurred creating the Forest Health Exempt record'
      yield call(showError, error)
    }

    const forestHealthExempt = resp.responseBody

    yield call(upsertForestHealthData, forestHealthExempt)

    yield put({ type: 'SHOW_SUCCESS', })
  }
  
  if (!online) {
    yield call(upsertForestHealthData, localForestHealthExempt, true)
  }
}

/**
 * Handles the data transformation and upserting of the Forest Health Exempt
 * and Forest Health Exempt Problem Type Xrefs
 * @param {object} forestHealthExempt 
 * @param {bool} IsLocal - sets a property marking the model as only existing locally and not on the server
 */
function* upsertForestHealthData (forestHealthExempt, IsLocal = false) {

  const { ForestHealthExemptProblemTypeXref, } = forestHealthExempt

  let typeXrefs = []
  // Migrated permits do not have problem types, so add a guard
  // to prevent spreading a non-iterable instance
  if (ForestHealthExemptProblemTypeXref) {
    typeXrefs = [ ...ForestHealthExemptProblemTypeXref, ]
  }
  delete forestHealthExempt.ForestHealthExemptProblemTypeXref

  forestHealthExempt.IsLocal = IsLocal
  yield call(upsertLocalModel, FOREST_HEALTH_EXEMPT_MODEL_NAME, forestHealthExempt)
  
  yield call(replaceAll, PROBLEM_TYPE_XREF_MODEL_NAME, typeXrefs, { ForestHealthExemptId: forestHealthExempt.ForestHealthExemptId, })
}


export const ForestHealthSagas = [
  takeEvery(ForestHealthTypes.GET_FOREST_HEALTH_DATA, getForestHealthData),
  takeEvery(ForestHealthTypes.CREATE_FOREST_HEALTH_DATA, createForestHealthData),
  takeEvery(ForestHealthTypes.UPDATE_FOREST_HEALTH_DATA, updateForestHealthData),
]