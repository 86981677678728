// Libraries
import { put, call, select, fork, takeEvery, } from 'redux-saga/effects'
import StackTrace from 'stacktrace-js'

// Reducers
import { AppTypes, } from '../redux/AppRedux'
import ApiActions, { ApiTypes, } from '../redux/ApiRedux'
import { OrmTypes, } from '../redux/OrmRedux'

// Selectors
import { networkStateSelector, checkedLookupDataSelector, } from '../selectors/selectors'
import { getModelEndpoint, getLatestDateByModelName, modelByIdSelector, getModelIdAttr, } from '../selectors/modelSelectors'
import { getUserToken, } from '../selectors/userSelectors'

// Sagas
import { hideLoading, } from './AppSagas'
import { downloadFile as FileDownload, } from './FileSagas'
import { updateLocalModel, upsertLocalModel, } from './OrmSagas'

// Utilities
import { dateFormatter, objHasProp, } from '../utilities'

const {
  REACT_APP_SERVER_URL,
  REACT_APP_SHOW_LOGGING,
// eslint-disable-next-line no-undef
} = process.env


/**
 * Uses the Fetch object to issue a request to the provided URL with optional requestBody.
 * If no `headers` are provided in the `requestBody`, it will assume the content and type is json.
 * If no `Authorization` property is set in the `requestBody.headers`, the property will be set with
 * the token returned by the `getUserToken` selector.
 * @param {String} url - The url to fetch
 * @param {Object} requestBody - The body of the fetch request
 * @returns {Object} an object with response and statusCode keys 
 */
export function* doFetch (url, requestBody = {}) {
  // Both parameters are required, so check for them
  if (!url) {
    yield call(showError, 'Did not receive url parameter for fetch.')
    return
  }

  const reqBody = { ...requestBody, }

  // If no headers are provided in the requestBody, assume json
  if (objHasProp(reqBody, 'headers') === false) {
    // Otherwise set up the requestBody headers
    reqBody.headers = {
      'Content-Type': 'application/json',
    }
  }

  if (objHasProp(reqBody, 'body') && typeof reqBody.body === 'object' && reqBody.headers['Content-Type'] === 'application/json') {
    reqBody.body = JSON.stringify(reqBody.body)
  }

  // For convenience, set up auth headers if none were provided
  if (objHasProp(reqBody.headers, 'Authorization') === false) {
    // Call the user token selector
    const userToken = yield select(getUserToken)
    if (!userToken) {
      console.warn(`Unable to locate user token, may not be able to complete fetch for ${url}`)
    }
    else {
      reqBody.headers.Authorization = `Bearer ${userToken}`
    }
  }

  let response = null
  let statusCode = null
  let ok = false
  try {
    response = yield call(fetch, url, reqBody)
    statusCode = response.status
    ok = response.ok
  } catch (error) {
    console.error(`[doFetch]: ${error}`)
    console.error(error)
    response = null
    statusCode = null
    ok = false
  }

  let responseBody = null
  if (statusCode && statusCode !== 204) {
    try {
      responseBody = yield call([ response, 'json', ])
    }
    catch (error) {
      responseBody = null
    }
  }
  return { responseBody, statusCode, ok, }
}

export function* downloadFile (url, fileName) {
  try {

    // Call the user token selector
    const userToken = yield select(getUserToken)
    // If we didn't get an auth token, throw
    if (!userToken) {
      yield call(showError, 'Unable to locate user token, unable to complete fech at ' + url + '.')
      return
    }

    const request = {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
    }
    const resp = yield call(fetch, url, request)
    
    if (resp.ok !== true || resp.responseBody === null) {
      yield call(showError, 'An error occurred attempting to download the file. Please contact support.')
      return
    }

    const fileCode = yield call([ resp, resp.json, ])

    const downloadUrl =`${REACT_APP_SERVER_URL}BurnPermitSignatures/Download/${fileCode.fileCode}`
    const downloadFileName = `${fileName}_${dateFormatter(new Date(), 'YYYY-MM-DD_HH:mm:ss')}.pdf`
    yield fork(FileDownload, { url: downloadUrl, fileName: downloadFileName, })
  }
  catch (error) {
    yield call(showError, error.message)
  }
}

const POST_LOG_URL = `${REACT_APP_SERVER_URL}Log/Error`

export function* logError ({ errorInfo, localState, }) {
  try {
    const requestObj = {
      method : 'POST',
      body   : { errorInfo, localState, },
    }

    const postLogResp = yield call(doFetch, POST_LOG_URL, requestObj)

    if (postLogResp.ok !== true) {
      yield call(showError, postLogResp.responseBody || 'An error occurred while attempting to log the application error.')
    }
    return postLogResp.ok
  }
  catch (error) {
    yield call(showError, error.message)
  }
}


/**
 * Do a fetch with some basic error code checking.
 * Add additional status codes as necessary and error actions
 * @param {String} url 
 * @param {Object} body 
 * @returns the fetch response
 */
export function* fetchAndCheck (url, body) {
  // do the fetch
  const res = yield call(doFetch, url, body)
  
  // pull out the values
  const response = res.responseBody
  const statuscode = res.statusCode
  const ok = res.ok

  // if !ok, the request failed
  if (!ok) {
    let error = ''
    // item not found
    if (statuscode === 404 && !response) {
      error = response || 'Could not find an API for ' + url
    }
    // bad request
    else if (statuscode === 400 && !response) {
      error = response || 'Bad Request Response from ' + url
    }
    else if (objHasProp(response, 'error') && !!response.error) {
      error = response.error
    }
    // if it's not 200 OK or 201 Created or 204 No Content it's unexpected
    else {
      error = response || 'Unexpected ' + statuscode + ' response from ' + url
    }
    yield call(showError, error)
  }

  return { response, statuscode, res, }
}


/**
 * Function to check if the locally stored model set is concurrent
 * with the server side data.
 * @param {string} requestUrl
 * @param {string} modelName
 */
export function* checkModelConcurrency (requestUrl, modelName) {
  // Not all endpoints have this configured yet, but try anyways
  try {
    // Get the latest date
    const latestModelDate = yield select(getLatestDateByModelName, modelName)
    // If no date is found, we can't check if it's concurrent
    if (!latestModelDate) {
      return false
    }
    // Check if models are concurrent
    const response = yield call(doFetch, requestUrl + '/IsConcurrent?date=' + latestModelDate)
    
    // If there's no response, default to false
    return response ? response.responseBody : false
  }
  catch (e) {
    return false
  }
}

/**
 * Gets the records from the API server and overwrites the local records with the response
 * using the provided local model name
 * @param  {string} endpoint
 * @param  {string} modelName
 */
function* lookupDataRequest (endpoint, modelName, forceCheck = false) {

  let requestUrl = REACT_APP_SERVER_URL + endpoint

  if (REACT_APP_SHOW_LOGGING === 'true') {
    console.log('requestUrl: ', requestUrl)
  }

  const checkedData = yield select(checkedLookupDataSelector)
  const modelHasBeenChecked = checkedData.some(l => l === modelName)
  if (modelHasBeenChecked && forceCheck === false) {
    return true
  }

  const modelIsConcurrent = yield call(checkModelConcurrency, requestUrl, modelName)
  if (modelIsConcurrent) {
    return true
  }

  const response = yield call(doFetch, requestUrl)

  const { responseBody, } = response

  if (REACT_APP_SHOW_LOGGING === 'true') {
    console.log('response.body: ', responseBody)
  }

  if (responseBody) {
    // Add new records
    if(Array.isArray(responseBody)) {
      yield put({ type: OrmTypes.REPLACE_ALL, modelName, objects: responseBody, })
    }
    else {
      yield put({ type: OrmTypes.UPSERT, modelName, properties: responseBody, })
    }
    yield put({ type: ApiTypes.CHECKED_LOOKUP_DATA, modelName, })
  }
}

export function* apiUpdate ({ modelName, body, }) {
  try {
    if (!modelName) {
      yield call(showError, 'You must supply at least a `modelName`')
      return
    }

    if (body === null || Object.keys(body).length === 0) {
      yield call(showError, 'You must supply at least one key/value for the request body object')
      return
    }

    const idAttr = yield select(getModelIdAttr, modelName)
    const modelId = body[idAttr]

    let endpoint = yield select(getModelEndpoint, modelName)

    if (endpoint.indexOf(REACT_APP_SERVER_URL) === -1) {
      endpoint = `${REACT_APP_SERVER_URL}${endpoint}`
    }

    const requestUrl =  `${endpoint}/${modelId}`

    if (REACT_APP_SHOW_LOGGING === 'true') {
      console.log('requestUrl: ', requestUrl)
    }

    const { online, } = yield select(networkStateSelector)
    const localModel = yield select(modelByIdSelector, { modelName, modelId, })

    if (!localModel.IsLocal) {
      if (!online) {
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : ApiTypes.UPDATE_RECORD_REQUEST,
          url         : requestUrl,
          method      : 'PUT',
          keyName     : idAttr,
          keyValue    : modelId,
        })
      }
      yield put(ApiActions.updateRecordRequest(modelName, requestUrl, body, online))
    }
    
    if (!online) {
      yield call(updateLocalModel, modelName, modelId, body)
    }
  }
  catch (error) {
    yield call(showError, error.message)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Get records from the API server using the provided model name value.
 * @param  {string}   modelName
 */
export function* getLookupData ({ modelName, forceCheck = false, }) {
  try {
    const endpoint = yield select(getModelEndpoint, modelName)
    yield call(lookupDataRequest, endpoint, modelName, forceCheck)
  }
  catch (error) {
    yield call(showError, error)
  }
}


export function* showError (error) {
  if (error instanceof Error) {
    const stackFrames = yield call(StackTrace.fromError, error)
    console.error('error: ', stackFrames)
  }
  if (typeof error === 'object' && error !== null) {
    error = error.message
  }
  else if (error === null || error === undefined) {
    error = 'An unknown error occurred'
  }
  console.error('error: ', error)
  yield put({ type: ApiTypes.FAILURE, error, })
}

export function extractError (resp) {
  let error
  if (!resp) {
    return error
  }
  if (typeof resp.responseBody === 'string') {
    error = resp.responseBody
  }
  else if (typeof resp.responseBody === 'object') {
    if ('error' in resp.responseBody && !!resp.responseBody.error) {
      error = resp.responseBody.error
    }
    else if ('errors' in resp.responseBody && Array.isArray(resp.responseBody.errors) && resp.responseBody.errors.length) {
      error = resp.responseBody.errors.join('\n\n')
    }
    else {
      const values = Object.values(resp.responseBody)
      error = ''
      if (values.length) {
        error = [ ...new Set(values), ].join('\n\n')
      }
    }
  }
  return error
}

export function* extractPayload (resp) {
  try {
    // Responses with status code 204 mean No Content
    // so there is no response body payload
    // to extract
    if (resp.payload.status === 204) {
      return null
    }
    let obj
    if (typeof resp.payload.json === 'function') {
      obj = yield call([ resp.payload, resp.payload.json, ])
    }
    else if (typeof resp.payload === 'object' && ((resp.payload instanceof Response) === false)) {
      obj = resp.payload
    }
    return obj
  }
  catch (error) {
    console.error(error)
  }
}

export function* apiSuccess (resp) {
  try {
    const { modelName, showSuccess, } = resp
    const obj = yield call(extractPayload, resp)
    if (obj) {
      // Models created locally will have a `IsLocal` property set to true
      // Now that we're receiving a server response, we want to set that to
      // false as it affects workflows
      obj.IsLocal = false
      yield call(upsertLocalModel, modelName, obj)
    }
    if (showSuccess) {
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* apiFail (resp) {
  const responseBody = yield call(extractPayload, resp)
  let error = yield call(extractError, { responseBody, })
  if (!error) {
    error = 'An unknown error was received from the server.'
  }
  yield call(showError, error)
}

export function apiResponseIsAuthorized (resp) {
  let auth = true
  if (resp && resp.payload && resp.payload.status === 404) {
    if (resp.payload.url && resp.payload.url.indexOf('Denied') > -1) {
      auth = false
    }
  } 
  return auth
}

export const ApiSagas = [
  takeEvery(ApiTypes.LOOKUP_DATA, getLookupData),
  takeEvery(ApiTypes.UPDATE_RECORD, apiUpdate),
  takeEvery(ApiTypes.LOG_ERROR, logError),
  takeEvery(ApiTypes.API_SUCCESS, apiSuccess),
  takeEvery(ApiTypes.API_FAIL, apiFail),
]