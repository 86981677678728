// Libraries
import React, { useMemo, } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Table, Col, } from 'reactstrap'
import { func, array, bool, } from 'prop-types'
import { useDispatch, } from 'react-redux'
import { Link, } from 'react-router-dom'

// Components
import CloseModalButton from './CloseModalButton'

// Redux
import { BurnRequestDetailTypes, } from '../../redux/BurnRequestDetailRedux'

// Utilities
import { dateFormatter, } from '../../utilities'

const BurnRequestNotices = ({
  requests = [],
  isOpen = false,
  closeModal, 
}) => {
  const reduxDispatch = useDispatch()
  const submitBurnRequest = React.useCallback((id) => {
    reduxDispatch({ type: BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST, id, })
  }, [ reduxDispatch, ])

  const isSubmitting = requests.filter(f => f.Submitting === true).length > 0
  const requestTable = useMemo(() => {
    if (requests && requests.length > 0) {
      return (
        <Table size={'sm'} responsive={true}>
          <thead>
            <tr>
              <th></th>
              <th>Permit #</th>
              <th>Request Date</th> 
              <th>Ignition Time</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {requests.map((val) => {
              const requestLink = `/burn-requests/${val.BurnRequestId}`
              const { Submitting, Submitted, Failed, } = val
              let submitButton
              if (Submitting) {
                submitButton = (<Button color={'secondary'} size={'sm'} disabled={true}>Submitting</Button>)
              } else if (Failed) {
                submitButton = (<Button color={'danger'} size={'sm'} disabled={true}>Failed</Button>)
              } else if (Submitted) {
                submitButton = (<Button color={'secondary'} size={'sm'} disabled={true}>Submitted</Button>
                )
              } else {
                submitButton = (
                  <Button color={'secondary'} size={'sm'} disabled={isSubmitting} onClick={() => submitBurnRequest(val.BurnRequestId)}>
                      Submit
                  </Button>
                )
              }
              return (
                <>
                  <tr key={`req-${val.BurnRequestId}`}>
                    <th scope={'row'}><Link to={requestLink}>{val.BurnRequestId}</Link>{val.MultiDayBurnFlag ? <i className={'fas fa-m pl-1'} aria-hidden={true} aria-label={'Multi-Day Burn'} title={'Multi-Day Burn'}></i> : null}</th>
                    <td>{val.BurnPermitNumber}</td>
                    <td>{val.BurnRequestDate ? dateFormatter(val.BurnRequestDate, 'MM-DD-YYYY') : null}</td>
                    <td>
                      {val.PlannedIgnitionDate ? dateFormatter(val.PlannedIgnitionDate, 'MM-DD-YYYY') : null}
                      {' '}
                      {val.PlannedIgnitionTime ? dateFormatter(val.PlannedIgnitionTime, 'HH:MM') : null}
                    </td>
                    <td>
                      <Link className={'btn btn-primary btn-sm'} to={`/burn-requests/${val.BurnRequestId}`} disabled={isSubmitting}>View</Link>
                    </td>
                    <td>
                      {submitButton}
                    </td>
                  </tr>
                  {
                    Failed &&
                  <tr>
                    <td colSpan={6}>
                      <span className={'text-danger'}>Request submission failed. Please view and update the Request and retry the submission.</span>
                    </td>
                  </tr>
                  }
                </>
              )
            })}
          </tbody>
        </Table>
      )
    }
    return []
  }, [ requests, submitBurnRequest, isSubmitting, ])

  if (!requests || requests.length === 0) {
    return null
  }

  return <Modal isOpen={isOpen} size={'lg'}>
    <ModalHeader>Unsubmitted Burn Requests</ModalHeader>
    <ModalBody>
      <Row className={'mb-2'}>
        <Col>
          <b>The following Burn Requests are scheduled to start within the next 24 hours and have not been submitted or reviewed by DNR staff.</b>
          <br/>
          <br/>
          Use the controls below to <b>View</b> the Burn Request or <b>Submit</b> the Burn Request now.
        </Col>
      </Row>
      <Row>
        <Col>
          {requestTable}
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter className={'d-flex justify-content-between'}>
      <CloseModalButton onClick={closeModal} />
    </ModalFooter>
  </Modal>
}

BurnRequestNotices.defaultProps = {
  closeModal: () => {},
}

BurnRequestNotices.propTypes = {
  requests   : array,
  isOpen     : bool,
  closeModal : func,
}

export default React.memo(BurnRequestNotices)