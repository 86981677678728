import { attr, fk, Model, } from 'redux-orm'
import * as Yup from 'yup'

export default class BurnPermitCondition extends Model {
  static get modelName () {
    return 'BurnPermitCondition'
  }

  static endpoint () {
    return 'BurnPermitConditions'
  }

  static getValidationSchema () {
    const {
      BurnPermitConditionName,
      BurnPermitConditionDescription,
    } = this.fields

    return Yup.object().shape({
      BurnPermitConditionName: Yup.string()
        .max(BurnPermitConditionName.opts.max, `Condition name name must be ${BurnPermitConditionName.opts.max} characters or less`)
        .required('Please enter a condition name.'),
      BurnPermitConditionDescription: Yup.string()
        .max(BurnPermitConditionDescription.opts.max, `Description must be ${BurnPermitConditionDescription.opts.max} characters or less`)
        .required('Please enter a condition description.'),
    })
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitConditionId',
    }
  }

  static get fields () {
    return {
      BurnPermitConditionId          : attr(),
      // Common fields
      CreateBy                       : attr(),
      CreateDate                     : attr(),
      UpdateBy                       : attr(),
      UpdateDate                     : attr(),
      // Foriegn keys
      ParentConditionId              : fk({ to: 'BurnPermitCondition', as: 'ParentCondition', relatedName: 'ChildConditions', }),
      // Model-specific fields
      BurnPermitConditionName        : attr({ max: 64, }),
      BurnPermitConditionDescription : attr({ max: 256, }),
      // Non database field
      Category                       : attr(),
    }
  }
}