import { attr, Model, fk, } from 'redux-orm'
import { object, array, string, number, } from 'yup'

export const MAX_CONTACTS = 5

export const contactSchema = object().shape({
  Contacts: array().of(
    object().shape({
      BurnPermitRequiredContactConditionId : number(),
      ContactName                          : string()
        .max(255, 'Contact name must be 255 characters or less')
        .required('Please enter a contact name'),
      PhoneNumber: number()
        .typeError('Phone number must be a number')
        .test('phone-number-length', 'Phone number must be 10 digits', val => {
          return (val || '').toString().length === 10
        })
        .required('Please enter a phone number'),
      PhoneExt: string()
        .max(100, 'Contact name must be 100 characters or less'),
    })
  ).max(MAX_CONTACTS, `A maximum of ${MAX_CONTACTS} required contacts can be saved`),
})


export default class BurnPermitRequiredContactCondition extends Model {
  static get modelName () {
    return 'BurnPermitRequiredContactCondition'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitRequiredContactConditionId',
    }
  }

  static get getValidationSchema () {
    return contactSchema
  }

  static get fields () {
    return {
      BurnPermitRequiredContactConditionId : attr(),
      BurnPermitConditionXrefId            : fk({ 
        to          : 'BurnPermitConditionXref', 
        as          : 'BurnPermitConditionXref',
        relatedName : 'BurnPermitRequiredContactConditions' ,
      }),
      ContactName : attr(),
      PhoneNumber : attr(),
      PhoneExt    : attr(),
      CreateBy    : attr(),
      CreateDate  : attr(),
      UpdateBy    : attr(),
      UpdateDate  : attr(),
    }
  }
}
