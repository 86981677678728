import React from 'react'
// import { Prompt, } from 'react-router'
import * as PropTypes from 'prop-types'
import { isEmpty, difference, isEqual, } from 'lodash'

class Effect extends React.Component {
  static propTypes = {
    location                  : PropTypes.object,
    values                    : PropTypes.object,
    onChange                  : PropTypes.func,
    errors                    : PropTypes.object,
    hasErrors                 : PropTypes.func,
    // This flag must be provided as it's used as toggle to prevent an infinite loop
    isSubmitting              : PropTypes.bool.isRequired,
    showDetailedError         : PropTypes.bool,
    promptPendingChangesOnNav : PropTypes.bool,
    isDirty                   : PropTypes.bool,
  }

  static defaultProps = {
    values                    : {},
    errors                    : {},
    isSubmitting              : false,
    showDetailedError         : false,
    promptPendingChangesOnNav : false,
    isDirty                   : false,
  }

  componentDidUpdate (prevProps) {
    const {
      values,
      errors,
      onChange,
      hasErrors,
      isSubmitting,
      showDetailedError,
    } = this.props

    const changedEntries = Object.entries(prevProps.values).filter(e => {
      const entry = values[e[0]]
      if (Array.isArray(entry)) {
        if (entry.every(e => typeof e !== 'object')) {
          // Convert the values to strings to make sure there being compared
          // using the same type
          const prevVals = entry.map(en => en && en.toString())
          const currVals = e[1].map(en => en && en.toString())
          return difference(prevVals, currVals).length
        }
        else {
          return !isEqual(entry[0], e[1][0])
        }
      }
      else {
        // Perform an object equality comparison instead of an identity comparison
        // This is because some of the values being compared are coming from form inputs
        // and sometimes they may not have their types properly set. In the case of numerical
        // values, the identity comparison would say "1" <> 1 in the case of a number input or
        // select where the value was a number from the server response, but the change by the
        // user made the pending value in `values` a string version
        // eslint-disable-next-line
        return entry != e[1]
      }
    })
    if (changedEntries.length || !isEqual(prevProps.values, values)) {
      if (typeof onChange === 'function') {
        onChange(changedEntries, values)
      }
    }
    
    if (isSubmitting && !isEmpty(errors) && typeof(hasErrors) === 'function') {
      let errorMessage = 'Error: One or more form inputs are invalid.'
      if (showDetailedError) {
        errorMessage += '\n\n' + Object.values(errors).join('\n')
      }
      hasErrors(errorMessage)
    }
  }

  render () {
    const { promptPendingChangesOnNav, isDirty, } = this.props
    if (promptPendingChangesOnNav && isDirty) {
      return null
      // TODO: handle replacing Prompt
      // return <Prompt
      //   when={isDirty}
      //   message={'You have unsaved changes in the form. Are you sure you want to leave?'}
      // />
    }

    return null
  }
}

export default Effect