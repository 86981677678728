import { attr, fk, Model, many, } from 'redux-orm'
import { number, string, } from 'prop-types'
import * as Yup from 'yup'
import { renderCheckIcon, } from '../utilities/columnHelpers'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class Person extends Model {
  static get modelName () {
    return 'Person'
  }

  toString () {
    return `${(this.PersonFirstName || '').trim()} ${(this.PersonLastName || '').trim()}`.trim()
  }

  static endpoint ({ dnrOnly, personId, }) {
    let personEndpoint = `${REACT_APP_SERVER_URL}People`
    if (dnrOnly) {
      personEndpoint += '/DNR'
    }
    if (Number.isInteger(personId)) {
      personEndpoint += `/${personId}/UserInfo`
    }
    return personEndpoint
  }

  static getDataTablesColumns (full = false) {
    if (full){
      return  [ {
        title : 'Id',
        type  : 'num',
        data  : 'PersonId',
        width : 15,
      },
      {
        title : 'Type',
        data  : 'PersonTypeName',
      },
      {
        title : 'First Name',
        data  : 'PersonFirstName',
      },
      {
        title   : 'Middle Name',
        data    : 'PersonMiddleName',
        visible : false,
      },
      {
        title : 'Last Name',
        data  : 'PersonLastName',
      },
      {
        title  : 'Is User',
        data   : 'IsUser',
        render : renderCheckIcon,
      },
      {
        title : 'Email',
        data  : 'EmailAddress',
      },
      {
        title : 'Region',
        data  : 'RegionName',
      },
      {
        title : 'Agency',
        data  : 'AgencyName',
      },
      {
        title   : 'Phone',
        data    : 'PhoneNumber',
        visible : false,
      }, ]
    } else {
      return [ {
        title : 'Id',
        type  : 'num',
        data  : 'PersonId',
        width : 15,
      },
      {
        title : 'First Name',
        data  : 'PersonFirstName',
      },
      {
        title   : 'Middle Name',
        data    : 'PersonMiddleName',
        visible : false,
      },
      {
        title : 'Last Name',
        data  : 'PersonLastName',
      },
      {
        title  : 'Is User',
        data   : 'IsUser',
        render : renderCheckIcon,
      },
      {
        title : 'Email',
        data  : 'EmailAddress',
      },
      {
        title   : 'Phone',
        data    : 'PhoneNumber',
        visible : false,
      }, ]
    }
  }

  static getValidationSchema () {
    const {
      PersonFirstName,
      PersonMiddleName,
      PersonLastName,
    } = this.fields

    return Yup.object().shape({
      PersonFirstName: Yup.string()
        .max(PersonFirstName.opts.max, `First name must be ${PersonFirstName.opts.max} characters or less`)
        .required('Please enter a first name'),
      PersonMiddleName: Yup.string()
        .max(PersonMiddleName.opts.max, `Middle name must be ${PersonMiddleName.opts.max} characters or less`)
        .ensure(),
      PersonLastName: Yup.string()
        .max(PersonLastName.opts.max, `Last name must be ${PersonLastName.opts.max} characters or less`)
        .required('Please enter a last name'),
      PersonTypeId: Yup.number()
        .min(1, 'Please select a person type')
        .required('Please select a person type'),
      ContactMethodId: Yup.number()
        .min(1, 'Please select a contact method')
        .required('Please select a contact method'),
    })
  }

  static getMinValidationSchema () {
    const {
      PersonFirstName,
      PersonMiddleName,
      PersonLastName,
    } = this.fields

    return Yup.object().shape({
      PersonFirstName: Yup.string()
        .max(PersonFirstName.opts.max, `First name must be ${PersonFirstName.opts.max} characters or less`)
        .required('Please enter a first name'),
      PersonMiddleName: Yup.string()
        .max(PersonMiddleName.opts.max, `Middle name must be ${PersonMiddleName.opts.max} characters or less`)
        .ensure(),
      PersonLastName: Yup.string()
        .max(PersonLastName.opts.max, `Last name must be ${PersonLastName.opts.max} characters or less`)
        .required('Please enter a last name'),
    })
  }

  static get options () {
    return {
      idAttribute: 'PersonId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PersonId          : attr(),
      CreateDate        : attr(),
      CreateBy          : attr(),
      UpdateDate        : attr(),
      UpdateBy          : attr(),
      // Model-Specific Properties
      PersonFirstName   : attr({ max: 80, }),
      PersonMiddleName  : attr({ max: 80, }),
      PersonLastName    : attr({ max: 80, }),
      IsUser            : attr(),
      ParentId          : attr(),
      ConvertedToAgency : attr(),
      
      // Relationships
      
      EmailId         : fk({ to: 'Email', relatedName: 'People', as: 'Email', }),
      ContactMethodId : fk({ to: 'ContactMethod', relatedName: 'People', as: 'ContactMethod', }),
      PersonTypeId    : fk({
        to          : 'PersonType',
        relatedName : 'People',
        as          : 'PersonType',
      }),
      AlertPreferences: many({
        to          : 'AlertPreference',
        relatedName : 'People',
        through     : 'PersonAlertPreferenceXref',
      }),
      Addresses: many({ 
        to          : 'Address', 
        relatedName : 'People',
      }),
      Phones: many({ 
        to          : 'Phone',
        relatedName : 'People',
      }),
      Regions: many({
        to          : 'Region',
        relatedName : 'People',
        through     : 'PersonRegionXref',
      }),
      Agencies: many({
        to          : 'Agency',
        relatedName : 'People',
        through     : 'PersonAgencyXref',
      }),
    }
  }

  static get propTypes () {
    return {
      PersonId   : number,
      CreateDate : string,
      UpdateDate : string,
    }
  }
}