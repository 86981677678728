// Libraries
import React from 'react'
import { useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { Card, CardBody, CardHeader, CardText, } from 'reactstrap'

// Components
import OnlinePopover from '../OnlinePopover'
import DownloadApplicationLinks from '../../Buttons/DownloadApplicationLinks'

// Selectors
import {
  activeBurnPermitIdSelector,
  permitApplicationSignedByDnr,
} from '../../../../selectors/burnPermitSelectors'
import { signatureByPermitId, } from '../../../../selectors/burnPermitSignatureSelectors'

// Utilities
import { dateFormatter, } from '../../../../utilities'


const ApplicationSignature = () => {

  // Selectors
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const {
    ApplicationSignedBy,
    ApplicationSignedDate,
    ApplicationEnvelopeId,
    CreateBy,
    CreateDate,
  } = useSelector(state => signatureByPermitId(state, burnPermitId))
  const signedByDnr = useSelector(state => permitApplicationSignedByDnr(state, burnPermitId))

  const download = useMemo(() => {
    if (ApplicationEnvelopeId !== '[AUTO-APPROVED]') {
      return <>
        <DownloadApplicationLinks burnPermitId={burnPermitId} />
        <OnlinePopover key={'download-app-online'} />
      </>
    }
    return <p>No signature or download available.</p>
  }, [ ApplicationEnvelopeId, burnPermitId, ])

  const signedBy = useMemo(() => {
    if (signedByDnr) {
      return <b>Submitted by DNR employee</b>
    }
    return 'Applicant'
  }, [ signedByDnr, ])

  const markup = <Card>
    <CardHeader>Application</CardHeader>
    <CardBody>
      <CardText>
        {signedBy}<br/>
        Signed By: {ApplicationSignedBy || CreateBy}<br/>
        Signed On: {dateFormatter(ApplicationSignedDate || CreateDate, 'dddd, MMMM D YYYY, h:mm:ss a')}
      </CardText>
      {download}
    </CardBody>
  </Card>
  return markup
}

export default React.memo(ApplicationSignature)
