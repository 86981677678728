// Libraries
import { fork, call, all, takeLatest, } from 'redux-saga/effects'

// Sagas
import { fetchAndCheck, getLookupData, showError, checkModelConcurrency, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { upsertLocalModels, upsertLocalModel, } from './OrmSagas'

// Reducers
import { RegionTypes, } from '../redux/RegionsRedux'

// Models
import Region from '../models/Region'

// CONSTANTS
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const REGION_ENDPOINT =`${REACT_APP_SERVER_URL}Regions`
const REGION_MODEL_NAME = Region.modelName

/**
 * Get all regions
 */
export function* GetAllRegions (args) {
  try {
    let regionsOnly = false
    if (!!args && 'regionsOnly' in args) {
      regionsOnly = args.regionsOnly || false
    }
    const isConcurrent = yield call(checkModelConcurrency, REGION_ENDPOINT, REGION_MODEL_NAME)
    if (isConcurrent) {
      return isConcurrent
    }

    yield call(showLoading)
    const { response, statuscode, } = yield call(fetchAndCheck, `${REGION_ENDPOINT}?regionsOnly=${regionsOnly}`)

    if (statuscode !== 200 || !response || !Array.isArray(response)) {
      yield call(showError, response && response.error ? response.error : 'Failed retrieving Region data.')
    }

    const ormObjs = { Address: [], Phone: [], Email: [], Region: [], PersonRegionXref: [], }
    response.forEach((r) => {
      if (r.RegionEmail) {
        ormObjs.Email.push(r.RegionEmail)
      }
      if (r.RegionAddress) {
        ormObjs.Address.push(r.RegionAddress)
      }
      if (r.RegionPhone) {
        ormObjs.Phone.push(r.RegionPhone)
      }
      if (r.PersonRegionXref && Array.isArray(r.PersonRegionXref)) {
        ormObjs.PersonRegionXref = ormObjs.PersonRegionXref.concat(r.PersonRegionXref)
      }
      delete r['RegionEmail']
      delete r['RegionAddress']
      delete r['RegionPhone']
      delete r['PersonRegionXref']
      ormObjs.Region.push(r)
    })

    yield all([
      call(getLookupData, { modelName: 'County', }),
      call(getLookupData, { modelName: 'AddressType', }),
      call(getLookupData, { modelName: 'PhoneType', }),
    ])
    
    const objs = Object.entries(ormObjs)
    for (let i = 0, len = objs.length; i < len; i++) {
      const [ modelName, data, ] = objs[i]
      if (data.length) {
        yield fork(upsertLocalModels, modelName, data)
      }
    }
  }
  catch (e) {
    console.error(e)
    yield call(showError, 'Failed retrieving Region data.')
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Get a region and related information
 * @param {Object} action - the redux action
 * @param {number} action.regionId - the region ID to get
 */
export function* GetRegion ({ regionId, }) {
  // can't do anything without a region id
  if (!regionId) {
    yield call(showError, 'Region ID not provided.')
    return
  }
  try {
    // set up the initial region request
    const { response, statuscode, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}Regions/${regionId}`)
    // if response is null, stop here
    if (!response) {
      yield call(showError, 'Did not receive a response for Region ' + regionId)
      return
    }
  
    if (statuscode !== 200) {
      yield call(showError, response && response.error ? response.error : 'Failed retrieving Region data.')
    }
  
    if (response.RegionEmail) {
      yield call(upsertLocalModel, 'Email', response.RegionEmail)
    }
    else if (response.RegionEmailId) {
      yield fork(getRegionContactData, 'Emails', response.RegionEmailId, 'Email')
    }
  
    if (response.RegionAddress) {
      yield call(upsertLocalModel, 'Address', response.RegionAddress)
    }
    else if (response.RegionAddressId) {
      yield fork(getRegionContactData, 'Addresses', response.RegionAddressId, 'Address')
    }
  
    if (response.RegionPhone) {
      yield call(upsertLocalModel, 'Phone', response.RegionPhone)
    }
    else if (response.RegionPhoneId) {
      yield fork(getRegionContactData, 'Phones', response.RegionPhoneId, 'Phone')
    }
  
    delete response['RegionEmail']
    delete response['RegionPhone']
    delete response['RegionAddress']
  
    // start a bunch of tasks in parallel
    yield all([
      call(upsertLocalModel, REGION_MODEL_NAME, response),
      call(getLookupData, { modelName: 'County', }),
      call(getLookupData, { modelName: 'AddressType', }),
      call(getLookupData, { modelName: 'PhoneType', }),
    ])
  }
  catch (e) {
    yield call(showError, 'An error occurred requesting Region ' + regionId)
    return
  }
}

function* getRegionContactData (urlMod, urlId, model) {
  try {
    const { response, statuscode, } = yield call(fetchAndCheck, `${REACT_APP_SERVER_URL}${urlMod}/${urlId}`)
    if (statuscode !== 200 || !response) {
      yield call(showError, response && response.error ? response.error : `An error occurred requesting Region ${model} data`)
    }
    yield call(upsertLocalModel, model, response)
  }
  catch (e) {
    yield call(showError, `Failed to retrieve Region ${model} data.`)
    return
  }
}

export const RegionSagas = [
  takeLatest(RegionTypes.GET_ALL_REGIONS, GetAllRegions),
  takeLatest(RegionTypes.GET_REGION, GetRegion),
]