// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Container, } from 'reactstrap'
import { func, array, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import ApiActions from '../redux/ApiRedux'

// Components
import DataTable from '../components/DataTable'

// Models
import ForestType from '../models/ForestType'

// Selectors
import { forestTypesSelector, } from '../selectors/selectors'


export class ForestFuelTypesContainer extends React.Component {
  static propTypes = {
    forestTypes   : array,
    columns       : array,
    SetPageTitle  : func,
    GetLookupData : func,
  }

  columns = ForestType.getDataTablesColumns()

  componentDidMount () {
    const { SetPageTitle, GetLookupData, } = this.props

    SetPageTitle('Forest Type/Fuels Descriptions')
    GetLookupData('ForestType')
  }

  render () {
    return (
      <Container className={'my-4'}>
        <DataTable
          columns={this.columns}
          records={this.props.forestTypes}
          elementId={'forest-types-table'}
          disableRowClick={true}
        />
      </Container>
    )  
  }
}

const mapStateToProps = (state) => {
  return {
    forestTypes: forestTypesSelector(state),
  }
}

const mapDispatchToProps = {
  GetLookupData : ApiActions.lookupData,
  SetPageTitle  : AppActions.setPageTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForestFuelTypesContainer)