import React from 'react'
import { string, } from 'prop-types'

import PopoverButton from './FormControls/PopoverButton'
import ExternalLink from './ExternalLink'

function PermitFeePopover (props) {
  const { buttonClassName, placement, } = props
  return <PopoverButton
    placement={placement}
    buttonClassName={buttonClassName || 'py-2'}
    popoverClassName={'popover-med-width'}
    popoverHeader={'Permit Fee'}
    popoverBody={
      <ul style={{ paddingLeft: '1.5em', }}>
        <li>The permit <ExternalLink href={`https://www.dnr.wa.gov/publications/rp_burn_feesched.pdf?${(new Date()).getTime()}`} text={'fee'} /> is based on the Total Permit Tonnage of all Permits included in the order.
        </li>
        <li>
          <b>Note:</b> An applicant submitting multiple applications within one DNR region may elect to pay one fee based on
          the combined tonnage of all applications when:
          <ul>
            <li>the combined tonnage of all applications equals or exceeds 100 tons, and</li>
            <li>applications are submitted and paid for as one order.</li>
          </ul>
        </li>                        
      </ul>
    }
  />
}

PermitFeePopover.defaultProps = {
  buttonClassName : 'py-2',
  placement       : 'bottom',
}

PermitFeePopover.propTypes = {
  buttonClassName : string,
  placement       : string,
}

export default PermitFeePopover