import React from 'react'
import { FormGroup, Label, Button, ButtonGroup, } from 'reactstrap'
import { string, func, } from 'prop-types'

import { PopoverButton, } from '../FormControls'


export const UGA = ({ IsUGA, onChange, }) => {
  return <FormGroup>
    <Label>
    Is in UGA
      <PopoverButton
        buttonClassName={'btn-icon px-0 ml-3 mb-1'}
        popoverHeader={'Is in UGA'}
        popoverBody={'Use this control to find Applications and Permits for burning within or outside an Urban Growth Boundary (UGA).'}
      />
    </Label>
    <br/>
    <ButtonGroup>
      <Button color={'light'} name={'IsUGA'} onClick={onChange} value={true} active={IsUGA === 'true'}>Yes</Button>
      <Button color={'light'} name={'IsUGA'} onClick={onChange} value={false} active={IsUGA === 'false'}>No</Button>
      <Button color={'light'} name={'IsUGA'} onClick={onChange} value={''} active={IsUGA === ''}>All</Button>
    </ButtonGroup>
  </FormGroup>
}

UGA.propTypes = {
  IsUGA    : string,
  onChange : func,
}

UGA.defaultProps = {
  IsUGA    : '',
  onChange : () => {},
}

export default UGA