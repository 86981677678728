// Libraries
import React from 'react'
import { bool, } from 'prop-types'

// Components
import Complete from './Complete'
import Incomplete from './Incomplete'

const Status = (props) => {
  if (props.complete) {
    return <Complete />
  }
  return <Incomplete />
}

Status.defaultProps = {
  complete: false,
}

Status.propTypes = {
  complete: bool,
}

export default React.memo(Status)