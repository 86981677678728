import { attr, Model, } from 'redux-orm'

export default class AddressType extends Model {
  static get modelName () {
    return 'AddressType'
  }

  toString () {
    return `${this.AddressTypeName}`
  }

  static endpoint () {
    return 'AddressTypes'
  }

  static get options () {
    return {
      idAttribute: 'AddressTypeId',
    }
  }
  
  static get fields () {
    return {
      // Common Properties
      AddressTypeId          : attr(),
      CreateDate             : attr(),
      CreateBy               : attr(),
      UpdateDate             : attr(),
      UpdateBy               : attr(),
      // Model Specific Properties
      AddressTypeName        : attr(),
      AddressTypeDescription : attr(),
    }
  }
}