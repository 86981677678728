import { filterSelector, ormWithPropSelector, } from './selectors'
import { isAfter, objHasProp, } from '../utilities'


export const getModelIdAttr = ormWithPropSelector(
  (session, modelName) => {
    const dbSet = session[modelName]
    if (!dbSet) {
      return null
    }

    return dbSet.options.idAttribute
  }
)

export const modelByIdSelector = ormWithPropSelector(
  (session, { modelName, modelId, }) => {
    const dbSet = session[modelName]
    if (!dbSet) {
      return null
    }

    const props = {}
    props[dbSet.options.idAttribute] = modelId
    if (dbSet.exists(props)) {
      return dbSet.withId(modelId)
    }
    return null
  }
)

export const itemsByModelNameSelector = ormWithPropSelector(
  (session, modelName) => {
    const model = session[modelName]
    if (model) {
      return model.all().toModelArray()
    }
    return []
  }
)

export const modelExists = ormWithPropSelector(
  (session, { modelName, modelId, }) => {
    const dbSet = session[modelName]
    if (!dbSet) {
      return false
    }
    const props = {}
    props[dbSet.options.idAttribute] = modelId
    const exists = dbSet.exists(props)
    return exists
  }
)

export const modelsExist = ormWithPropSelector(
  (session, { modelName, filter, }) => {
    const dbSet = session[modelName]
    if (!dbSet || !filter) {
      return false
    }
    const exists = dbSet.filter(filter).exists()
    return exists
  }
)

export const getXrefIdsForModel = ormWithPropSelector(
  (session, { modelName, filterObj, }) => {
    const dbSet = session[modelName]
    if (!dbSet) {
      return null
    }
    
    const models = dbSet.filter(filterObj)
    if (!models.exists()) {
      return null
    }
    const xrefIds = models.toRefArray().map(m => m[dbSet.options.idAttribute])
    return xrefIds
  }
)

export const newModelSelector = ormWithPropSelector(
  (session, { modelName, props, }) => {
    const dbSet = session[modelName]
    if (!dbSet) {
      return null
    }
    // Check to see if there are any existing models
    // If there aren't any, default the ID to one (1) instead of zero (0)
    // since zero (0) is a falsey value and there is logic
    // throughout the app that checks IDs of models to see
    // if it is greater than zero (0) and/or a truthy value
    if (dbSet.all().toModelArray().length === 0) {
      props[dbSet.options.idAttribute] = 1
    }
    const newModel = dbSet.create(props)
    newModel.IsLocal = true
    return newModel
  }
)

export const getModelCount = ormWithPropSelector(
  filterSelector,
  (session, modelName, filter) => {
    const model = session[modelName]
    if (!model) {
      return 0
    }
    let models = []
    if (filter) {
      models = model.filter(filter).all()
    }
    else {
      models = model.all()
    }
    return models.count()
  }
)

export const getLatestDateByModelName = ormWithPropSelector(
  filterSelector,
  (session, modelName, filterObj) => {
    const model = session[modelName]
    if (!model) {
      return null
    }
    let models = []
    if (filterObj) {
      models = model.filter(filterObj).all()
    }
    else {
      models = model.all()
    }
    if (!models.exists()) {
      return null
    }
    models = models.toModelArray()
    let latestCreate, latestUpdate
    if (objHasProp(model.fields, 'CreateDate')) {
      latestCreate = models.reduce((latestCreateDate, currentModel) => {
        if (isAfter(latestCreateDate, currentModel.CreateDate) || !latestCreateDate) {
          return currentModel.CreateDate
        }
        return latestCreateDate
      }, null)
    }
    if (objHasProp(model.fields, 'UpdateDate')) {
      latestUpdate = models.reduce((latestUpdateDate, currentModel) => {
        if (isAfter(latestUpdateDate, currentModel.UpdateDate) || !latestUpdateDate) {
          return currentModel.UpdateDate
        }
        return latestUpdateDate
      }, null)
    }
    return isAfter(latestCreate, latestUpdate) ? latestUpdate : latestCreate
  }
)

export const modelValidationSchemaSelector = ormWithPropSelector(
  (session, modelName) => {
    if (!modelName) {
      return null
    }
    const model = session[modelName]
    if (!model) {
      return null
    }
    if (!model.getValidationSchema || (typeof model.getValidationSchema !== 'function')){
      return null
    }
    return model.getValidationSchema()
  }
)

export const getModelEndpoint = ormWithPropSelector(
  (session, modelName) => {
    if (!modelName) {
      return null
    }
    const model = session[modelName]
    // Try to get the endpoint but provide a default
    const endpoint = typeof model.endpoint === 'function' ? model.endpoint() : modelName + 's'
    return endpoint
  }
)