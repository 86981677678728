import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  getReports         : null,
  reportsDoneLoading : null,
  setReports         : [ 'reports', ],
  downloadReport     : [ 'reportPath', 'format', ],
})

export const ReportTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  reportsLoading : false,
  reports        : [],
}

export const getReports = (state) => {
  const newState = Object.assign({}, state)
  newState.reportsLoading = true
  return newState
}

export const reportsDoneLoading = (state) => {
  const newState = Object.assign({}, state)
  newState.reportsLoading = false
  return newState
}

export const setReports = (state, { reports, }) => {
  const newState = Object.assign({}, state)
  newState.reports = [ ...new Set(reports), ]
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORTS]          : getReports,
  [Types.SET_REPORTS]          : setReports,
  [Types.REPORTS_DONE_LOADING] : reportsDoneLoading,
})