import { createSelector, } from 'reselect'
import { userIsSmokeStaff, userIsSmokeAdmin, userIsRegionAdmin, userIsSmokeMgmt, } from './userSelectors'
import { createAuthSelector, } from './permissionSelectors'

//#region burn request permission selectors

export const userCanBypassSignature = createAuthSelector(
  ({ isSAWUser, isGovAgent, isDnr , isVerifiedAgent, }) => 
    (isSAWUser && isGovAgent && isVerifiedAgent) || isDnr
)

export const userCanEditAnyInfoRequiredRequest = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userSeesDetailedStatus = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanEnterAnyIgnDate = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userSeesPostBurnPrompt = createAuthSelector(
  ({ isSAWUser, isStateGovCust, }) => isSAWUser || isStateGovCust
)

//#endregion

//#region burn request review permissions

export const userSeesInternalReqStatus = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanAssignBurnRequest = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanChangeRequestStatus = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userMustEnterStatusComment = createAuthSelector(
  ({ isStateGovCust, isSAWUser, }) => isSAWUser || isStateGovCust
)

/**
 * Returns whether or not the current user can override a Burn Request Decision
 * @returns {Boolean} The user can override a Burn Request Decision
 */
export const userCanOverrideDecisions = createSelector(
  userIsSmokeStaff,
  userIsSmokeAdmin,
  userIsRegionAdmin,
  (isSmokeStaff, isSmokeAdmin, isRegionAdmin) => 
    isSmokeStaff === true || isSmokeAdmin === true || isRegionAdmin === true
)

export const userCanMakeSMDecisions = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userIsDivisionApprover = createSelector(
  userIsSmokeMgmt,
  (isSmokeMgmt) => isSmokeMgmt
)

//#endregion
