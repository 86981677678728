import { attr, Model, fk, } from 'redux-orm'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class BurnRequestStatusXref extends Model {
  static get modelName () {
    return 'BurnRequestStatusXref'
  }

  toString () {
    return `${this.BurnRequestStatusXrefName}`
  }

  static endpoint (requestId, history = false) {
    let url = `${REACT_APP_SERVER_URL}BurnRequests`
    
    if (requestId) {
      url += `/${requestId}`
    }
    
    url += '/RequestStatus'
    
    if (history === false) {
      return url
    }
    
    url += '/History'

    if (!requestId) {
      url += '/Download'
    }

    return url
  }
  
  static deleteEndpoint (requestId, xrefId) {
    const url = `${REACT_APP_SERVER_URL}BurnRequests/${requestId}/RequestStatus/${xrefId}`
    return url
  }

  static get options () {
    return {
      idAttribute: 'BurnRequestStatusXrefId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnRequestStatusXrefId         : attr(),
      BurnRequestId                   : fk({ as: 'BurnRequest', to: 'BurnRequest', relatedName: 'BurnRequestStatusXrefs', }),
      BurnRequestStatusId             : fk({ to: 'BurnRequestStatus', relatedName: 'BurnRequestStatusXrefs', as: 'BurnRequestRequestStatus', }),
      BurnRequestCancellationReasonId : fk({ to: 'BurnRequestCancellationReason', relatedName: 'BurnRequestCancellationReasons', as: 'BurnRequestCancellationReason', }),
      StatusDate                      : attr(),
      Comment                         : attr(),
      AssignedToId                    : fk({ to: 'ApplicationUser', relatedName: 'BurnRequestAssignedUsers', as: 'AssignedTo', }),
      SiteInspected                   : attr(),
      InternalOnly                    : attr(),
      
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}