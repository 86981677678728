import React, { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { ButtonGroup, Label, } from 'reactstrap'

// Actions
import UserActions from '../../redux/UserRedux'

// Components
import { ButtonGroupButton, } from '../FormControls'

// Selectors
import { userNetworkNotifEnabled, } from '../../selectors/userSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'


const NetworkNotifications = () => {

  // Hooks
  const dispatch = useDispatch()

  // Selectors
  const NetworkNotificationsEnabled = useSelector(userNetworkNotifEnabled)

  // UI Callbacks
  const toggleNetworkNotifications = useCallback(e => {
    stopEvent(e)
    const { classList, } = e.target
    if (!classList.contains('active')) {
      dispatch(UserActions.toggleNetworkNotifications())
    }
  }, [ dispatch, ])

  return <>
    <Label for={'network-status'}>
      Network Status Notifications
    </Label>
    <ButtonGroup className={'d-block'}>
      <ButtonGroupButton
        text={'On'}
        isActive={NetworkNotificationsEnabled}
        onClick={toggleNetworkNotifications}
      />
      <ButtonGroupButton
        text={'Off'}
        isActive={!NetworkNotificationsEnabled}
        onClick={toggleNetworkNotifications}
      />
    </ButtonGroup>
  </>
}

export default React.memo(NetworkNotifications)