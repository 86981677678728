// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { CardText, } from 'reactstrap'

// Selectors
import { activeBurnPermitIdSelector, } from '../../../../selectors/burnPermitSelectors'
import { signatureByPermitId, } from '../../../../selectors/burnPermitSignatureSelectors'

// Utilities
import { dateFormatter, } from '../../../../utilities'


const ApproverSignature = () => {

  // Selectors
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const {
    PermitApproverSignedBy,
    PermitApproverSignedDate,
  } = useSelector(state => signatureByPermitId(state, burnPermitId))

  const markup = <CardText>
    DNR<br/>
    Signed By: {PermitApproverSignedBy}<br/>
    Signed On: {dateFormatter(PermitApproverSignedDate, 'dddd, MMMM D YYYY, h:mm:ss a')}
  </CardText>
  return markup
}


export default React.memo(ApproverSignature)
