import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const HAND_PILE_TYPE = 1
const MACHINE_PILE_TYPE = 2
const SHAPE_DIMENSION_PROPS = [
  [ 'HeightOne', ],
  [ 'HeightOne', 'WidthOne', ],
  [ 'HeightOne', 'WidthOne', 'LengthOne', ],
  [ 'WidthOne', 'LengthOne', 'WidthTwo', ],
  [ 'WidthOne', 'LengthOne', 'WidthTwo', ],
  [ 'HeightOne', 'WidthOne', 'LengthOne', ],
  [ 'HeightOne', 'WidthOne', 'LengthOne', 'HeightTwo', 'WidthTwo', 'LengthTwo', ],
]

const positiveVal = 'Please enter a value greater than zero (0).'

function requiredDimension (dimensionPropName) {
  return {
    is: shape => {
      return Number.isInteger(shape) && SHAPE_DIMENSION_PROPS[shape].includes(dimensionPropName)
    },
    then: Yup.number().typeError('Please enter a number.').positive(positiveVal).required('Please enter a value.'),
  }
}

export default class BurnPermitPileGroup extends Model {
  static get modelName () {
    return 'BurnPermitPileGroup'
  }

  toString () {
    return `${this.PilesConsumedTonnage} ${this.TotalNumberBurnPiles}`
  }

  static endpoint () {
    return `${REACT_APP_SERVER_URL}BurnPermitPileGroups`
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitPileGroupId',
    }
  }

  static get ShapeDimensionProps () {
    return SHAPE_DIMENSION_PROPS
  }

  static dataTableColumns ({ input = false, output = false, }) {
    if (input === true) {
      return [
        {
          data      : 'BurnPermitPileGroupId',
          orderable : false,
        },
        {
          title : 'ID',
          data  : 'BurnPermitPileGroupId',
        },
        {
          title : 'Name',
          data  : 'PileGroupName',
        },
        {
          title : '# of Piles',
          data  : 'PileCount',
        },
        {
          title : 'Type',
          data  : 'PileTypeName',
        },
        {
          title : 'Shape',
          data  : 'PileShapeName',
        },
        {
          title  : 'Dimensions',
          render : function (data, drawType, row) {
            if (drawType !== 'display') {
              return ''
            }
            let dimensions = []
            if (row.WidthOne) {
              dimensions.push(`${row.WidthOne}'W1`)
            }
            if (row.HeightOne) {
              dimensions.push(`${row.HeightOne}'H1`)
            }
            if (row.LengthOne) {
              dimensions.push(`${row.LengthOne}'L1`)
            }
            if (row.WidthTwo) {
              dimensions.push(`${row.WidthTwo}'W2`)
            }
            if (row.HeightTwo) {
              dimensions.push(`${row.HeightTwo}'H2`)
            }
            if (row.LengthTwo) {
              dimensions.push(`${row.LengthTwo}'L2`)
            }
            return dimensions.join(' x ')
          },
        },
        {
          title : 'Quality',
          data  : 'PileQualityName',
        },
        {
          title  : '% Soil',
          data   : 'PercentSoil',
          render : function (data, drawType) {
            if (drawType !== 'display') {
              return data
            }
            return data ? `${data}%` : ''
          },
        },
        {
          title : 'Packing Ratio',
          data  : 'PackingRatio',
        },
        {
          title : 'Hand Pile Species',
          data  : 'HandPileSpeciesName',
        },
        {
          title  : 'Species 1',
          render : function (data, drawType, row) {
            if (drawType !== 'display' || !row.SpeciesOneDensity) {
              return ''
            }
            return `${row.SpeciesOneDensity} - ${row.SpeciesOneCompositionPercent}%`
          },
        },
        {
          title  : 'Species 2',
          render : function (data, drawType, row) {
            if (drawType !== 'display' || !row.SpeciesTwoDensity) {
              return ''
            }
            return `${row.SpeciesTwoDensity} - ${row.SpeciesTwoCompositionPercent}%`
          },
        },
      ]
    }
    else if (output === true) {
      const baseProps = {
        class : 'text-right',
        type  : 'num',
      }
      return [
        {
          title : 'ID',
          data  : 'BurnPermitPileGroupId',
        },
        {
          ...baseProps,
          title : 'Geometric Volume',
          data  : 'GeometricVolume',
        },
        {
          ...baseProps,
          title : 'True Wood Volume',
          data  : 'TrueWoodVolume',
        },
        {
          ...baseProps,
          title : 'Pile Biomass',
          data  : 'PileBiomass',
        },
        {
          ...baseProps,
          title : 'Consumed Fuel',
          data  : 'ConsumedFuel',
        },
        {
          ...baseProps,
          title : 'PM',
          data  : 'Pm',
        },
        {
          ...baseProps,
          title : 'PM 2.5',
          data  : 'Pm25',
        },
        {
          ...baseProps,
          title : 'PM 10',
          data  : 'Pm10',
        },
        {
          ...baseProps,
          title : 'CO',
          data  : 'Co',
        },
        {
          ...baseProps,
          title : 'CO2',
          data  : 'Co2',
        },
        {
          ...baseProps,
          title : 'CH4',
          data  : 'Ch4',
        },
        {
          ...baseProps,
          title : 'NMHC',
          data  : 'Nmhc',
        },
      ]
    }
  }

  static get validationSchema () {
    return Yup.object().shape({
      PileCount       : Yup.number().nullable().positive(positiveVal).required('Please enter the # of Piles in this group'),
      PileShape       : Yup.number().nullable().required('Please choose the shape of the Pile.'),
      PileType        : Yup.number().nullable().required('Please choose the type of Pile construction'),
      HeightOne       : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('HeightOne')),
      WidthOne        : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('WidthOne')),
      LengthOne       : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('LengthOne')),
      HeightTwo       : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('HeightTwo')),
      WidthTwo        : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('WidthTwo')),
      LengthTwo       : Yup.number().typeError('Please enter a number.').nullable().when('PileShape', requiredDimension('LengthTwo')),
      HandPileSpecies : Yup.number().nullable()
        .when('PileType', {
          is   : t => t === HAND_PILE_TYPE,
          then : Yup.number().nullable().required('Please choose the species for these piles'),
        }),
      PercentSoil: Yup.number().nullable()
        .when('PileType', {
          is   : t => t === MACHINE_PILE_TYPE,
          then : Yup.number().nullable().positive(positiveVal).required('Please enter the % as a whole number of the pile that is soil'),
        }),
      PackingRatio: Yup.number().nullable()
        .when('PileType', {
          is   : t => t === MACHINE_PILE_TYPE,
          then : Yup.number().nullable().positive(positiveVal).required('Please enter the Packing Ratio as a whole number of the pile that is soil'),
        }),
      PileQuality: Yup.number().nullable()
        .when('PileType', {
          is   : t => t === MACHINE_PILE_TYPE,
          then : Yup.number().nullable().required('Please choose the quality of the soil'),
        }),
      SpeciesOneDensityId: Yup.number().nullable()
        .when('PileType', {
          is   : t => t === MACHINE_PILE_TYPE,
          then : Yup.number().nullable().required('Please choose a Species'),
        }),
      SpeciesOneCompositionPercent: Yup.number()
        .when('PileType', {
          is   : t => t === MACHINE_PILE_TYPE,
          then : Yup.number()
            .max(100, 'Composition % must be 100 or less')
            .integer('Please enter the % as a whole number')
            .positive(positiveVal)
            .required('Please enter the % as a whole number of the species that composes these piles'),
          otherwise: Yup.number().nullable(),
        }),
      SpeciesTwoDensityId: Yup.number()
        .when([ 'PileType', 'SpeciesOneCompositionPercent', ], {
          is        : (pt, c1) => pt === MACHINE_PILE_TYPE && !isNaN(c1) && c1 < 100,
          then      : Yup.number().typeError('Please choose a second Species').positive(positiveVal).required('Please choose a second Species'),
          otherwise : Yup.number().nullable(),
        }),
      SpeciesTwoCompositionPercent: Yup.number()
        .when([ 'PileType', 'SpeciesOneCompositionPercent', ], (pt, c1, schema) => {
          if (pt === MACHINE_PILE_TYPE){
            if (!isNaN(c1) && c1 === 100) {
              return schema.nullable()
                .min(0, 'Total composition must be 100%')
                .max(0, 'Total composition cannot exceed 100%')
            }
            return schema.integer('Please enter the % as a whole number')
              .max(100, 'Composition % must be 100 or less')
              .typeError('Composition must be a number')
              .positive(positiveVal)
              .required('Please enter the % as a whole number of the species that composes these piles')
          }
          return schema.nullable()
        }),
    })
  }

  static get fields () {
    return {
      BurnPermitPileGroupId : attr(),
      BurnPermitId          : fk({ to: 'BurnPermit', relatedName: 'BurnPermitPileGroups', as: 'BurnPermit', }),

      // Pile Group / Esimated Consumed Tonnage Calculator input fields
      PileCount                    : attr(),
      PileType                     : attr(),
      PileShape                    : attr(),
      PileGroupName                : attr(),
      WidthOne                     : attr(),
      HeightOne                    : attr(),
      LengthOne                    : attr(),
      WidthTwo                     : attr(),
      HeightTwo                    : attr(),
      LengthTwo                    : attr(),
      PileQuality                  : attr(),
      PercentSoil                  : attr(),
      PackingRatio                 : attr(),
      HandPileSpecies              : attr(),
      SpeciesOneDensityId          : fk({ to: 'SpeciesWoodDensity', relatedName: 'BurnPermitPileGroupSpeciesOneDensity', as: 'SpeciesOneDensity', }),
      SpeciesOneCompositionPercent : attr(),
      SpeciesTwoDensityId          : fk({ to: 'SpeciesWoodDensity', relatedName: 'BurnPermitPileGroupSpeciesTwoDensity', as: 'SpeciesTwoDensity', }),
      SpeciesTwoCompositionPercent : attr(),
      
      // Estimated Consumed Tonnage Calculator result fields
      GeometricVolume : attr(),
      TrueWoodVolume  : attr(),
      PileBiomass     : attr(),
      ConsumedFuel    : attr(),
      Pm              : attr(),
      Pm25            : attr(),
      Pm10            : attr(),
      Co              : attr(),
      Co2             : attr(),
      Ch4             : attr(),
      Nmhc            : attr(),
      LegacyTonnage   : attr(),

      // Audit Fields
      CreateDate : attr(),
      UpdateDate : attr(),
      CreateBy   : attr(),
      UpdateBy   : attr(),
    }
  }
}