// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Formik, Field, ErrorMessage, } from 'formik'
import {
  FormFeedback,
  FormGroup,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  CardHeader,
  CardText,
  Alert,
  Badge,
  Button,
} from 'reactstrap'
import { isEqual, isEmpty, } from 'lodash'
import { string, number, func, array, object, PropTypes, bool, } from 'prop-types'

// Components
import { AddressForm, GeoCoordinateForm, PLSSForm, } from '../Forms'
import ESRIMap from '../ESRIMap'
import Effect from '../Effect'
import BurnPermitFormSection from './FormSection'
import AuditData from '../AuditData'
import LoadingBar from '../LoadingBar'
import {
  PopoverButton,
  RequiredLabel,
  Select,
  ValidatingField,
  YesNoRadioButtonGroup,
} from '../FormControls'

// Actions
import ApiActions from '../../redux/ApiRedux'
import BurnPermitLocationActions from '../../redux/BurnPermitLocationRedux'
import GeoCoordinateActions from '../../redux/GeoCoordinateRedux'
import MapActions from '../../redux/MapRedux'

// Models
import BurnPermitLocation from '../../models/BurnPermitLocation'

// Selectors
import {
  directionsForSelectSelector,
  permitLocationByIdSelector,
  addressTypeByNameSelector,
  countiesForSelectSelector,
  fireDistrictDepartmentsForSelectSelector,
  locationAddress,
} from '../../selectors/selectors'
import { regionsForSelectSelector, } from '../../selectors/regionSelectors'
import { userCanEditUGA, } from '../../selectors/burnPermitPermissionSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import retry from '../../utilities/retry'
import { getArrayValueFromText, } from '../../utilities'
import { formatAddress, formatLegalDesc, } from '../../utilities/columnHelpers'

// Map Config
import { ALL_LAYERS, UGA, REGIONS, FIRE_DISTRICTS, COUNTIES, TRS, } from '../../config/map/featureLayers'
import { POLYGON_SYMBOL, } from '../../config/map/symbols'
import { geoLocationFormSelector, } from '../../selectors/geoSelectors'

const PHYSICAL_ADDRESS_TYPE = 'Physical'
const TARGET_FEATURE_LAYER_IDS = [ REGIONS.id, FIRE_DISTRICTS.id, COUNTIES.id, TRS.id, ]


const geoLocCardHeight = { minHeight: '17em', }
const mapCardHeight = { minHeight: '24em', }

class BurnPermitBurnLocationSection extends React.Component {

  constructor (props) {
    super(props)
    this.validationSchema = BurnPermitLocation.getValidationSchema()
    this.validateSection = this.validateSection.bind(this)
    this.onIntersectingFeaturesChanged = this.onIntersectingFeaturesChanged.bind(this)
    this.onAddressFormBlur = this.onAddressFormBlur.bind(this)
    this.validateAt = this.validateAt.bind(this)
  }
  
  static propTypes = {
    sectionId                           : number.isRequired,
    UpdateAdditionalLocationInfo        : func,
    GetBurnLocationInfo                 : func,
    ResetGeoCoordinate                  : func,
    DeleteBurnLocationAddress           : func,
    CreateBurnLocationAddress           : func,
    CheckIfGraphicsOverlapLayerFeatures : func,
    GeneralMessage                      : func,
    Failure                             : func,
    burnPermitLocationId                : PropTypes.oneOfType([ number, string, ]),
    permitLocation                      : object,
    GeoCoordinateState                  : object,
    IsGeocoding                         : bool,
    BurnPermitId                        : PropTypes.oneOfType([ number, string, ]),
    Map                                 : object,
    address                             : object,
    addressType                         : object,
    Directions                          : array,
    Regions                             : array,
    Counties                            : array,
    FireDistrictDepartments             : array,
    readOnly                            : bool,
    online                              : bool,
    isInUGA                             : bool,
    canEditUga                          : bool,
    isActive                            : bool,
    geocodedLocation                    : array,
    Geocode                             : func,
    ResetGeocoded                       : func,
  }

  state = {
    legalDescError: '',
  }

  componentDidMount = () => {
    if (this.props.isActive) {
      this.props.CheckIfGraphicsOverlapLayerFeatures()
    }
    const {
      burnPermitLocationId,
      GetBurnLocationInfo,
      online,
    } = this.props

    if (this.props.isActive && online) {
      GetBurnLocationInfo(burnPermitLocationId)
    }
  }

  componentWillUnmount () {
    this.props.ResetGeoCoordinate()
    this.props.CheckIfGraphicsOverlapLayerFeatures()
  }

  componentDidUpdate (prevProps) {
    // Don't fire off anything unless we're active
    if (!this.props.isActive) {
      return
    }

    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.CheckIfGraphicsOverlapLayerFeatures()
      this.props.GetBurnLocationInfo(this.props.burnPermitLocationId)
    }
    
    const { canEditUga, } = this.props

    const { TRSResult, } = this.props.GeoCoordinateState
    if (TRSResult) {
      if (TRSResult.features.length === 0 && !this.state.legalDescError) {
        this.setState({ legalDescError: 'Could not geocode the provided legal description.', })
      }
      else if (TRSResult.features.length && this.state.legalDescError) {
        this.setState({ legalDescError: '', })
      }
    }

    const { graphicsOverlap, } = this.props.Map

    if (!prevProps.Map.graphicsOverlap && graphicsOverlap && canEditUga) {
      if (this.formik) {
        this.formik.setFieldValue('IsUGA', 'yes')
      }
      else {
        console.error('Could not set the UGA radio control as formik was not available')
      }
    }
    else if (isEqual(prevProps.Map.graphicsOverlap, graphicsOverlap) && !graphicsOverlap && canEditUga) {
      if (this.formik && this.formik.values.IsUGA === 'yes') {
        this.formik.setFieldValue('IsUGA', 'no')
      }
    }

    if (prevProps.geocodedLocation !== this.props.geocodedLocation) {
      this.onIntersectingFeaturesChanged(this.props.geocodedLocation, true)
    }

    if (!prevProps.address && this.props.address || prevProps.address !== this.props.address) {
      this.onAddressFormBlur()
    }
  }

  validateAt (prop, values, errorCallback) {
    this.validationSchema
      .validateAt(prop, values, { abortEarly: true, } )
      .catch(errorCallback)
  }
  
  async onIntersectingFeaturesChanged (intersecting, resetGeocodeResult = false) {
    // no data, just return
    if (!intersecting || intersecting.length < 1) {
      return
    }
    else if(!this.formik) {
      console.error('Could not auto-populate form inputs as formik was not available')
      return
    }

    // Include "ADDRESS" to handle lookup service result 
    const layersToHandle = [ ...TARGET_FEATURE_LAYER_IDS, 'ADDRESS', ]
    // Filter out any extra layers (when handling map result)
    let features = intersecting.filter(f => layersToHandle.includes(f.layerId))

    // Counties, regions, and fire depts. are all part of the burn location form
    // just set the value with formik
    const { Counties, Regions, FireDistrictDepartments, } = this.props

    // get the status of the coordinate and plss forms
    const { hasValues: gcHasValues, valid: gcIsValid, } = await this.getFormStatus(this.GeoCoordinateForm)
    const { hasValues: trsHasValues, valid: trsIsValid, } = await this.getFormStatus(this.PLSSForm)


    for (let f = 0, len = features.length; f < len; f++) {
      const feature = features[f] 
      let checkBeforeSetting = false
      if (feature.source === 'ADDRESS' && gcHasValues && gcIsValid && trsHasValues && trsIsValid) {
        checkBeforeSetting = true
      }

      switch (feature.layerId) {
      case COUNTIES.id: {
        const setCountyField = () => {
          const county = getArrayValueFromText(Counties, feature.attributes.COUNTY_NM)
          if (county) { this.formik.setFieldValue('CountyId', county) }
        }
        if (checkBeforeSetting) {
          this.validateAt('CountyId', this.formik.values, setCountyField)
          break
        }
        setCountyField()
        break
      }
      case FIRE_DISTRICTS.id: {
        const setFireDistField = () => {
          const fire = getArrayValueFromText(FireDistrictDepartments, feature.attributes.FPD_DESC)
          if (fire) { this.formik.setFieldValue('FireDistrictDepartmentId', fire) }
        }
        if (checkBeforeSetting) {
          this.validateAt('FireDistrictDepartmentId', this.formik.values, setFireDistField)
          break
        }
        setFireDistField()
        break
      }
      case REGIONS.id: {
        const setRegionField = () => {
          const region = getArrayValueFromText(Regions, feature.attributes.JURISDICT_LABEL_NM)
          if (region) { this.formik.setFieldValue('RegionId', region) }
        }
        if (checkBeforeSetting) {
          this.validateAt('RegionId', this.formik.values, setRegionField)
          break
        }
        setRegionField()
        break
      }
      case TRS.id: {
        if (checkBeforeSetting) {
          // this came from an address geocode request and we already have a valid TRS so just exit
          break
        } 
        // call the plss form method to set everything
        this.PLSSForm.setTRSWithFeature(feature)
        break
      }
      case 'ADDRESS': {
        const addressLocation = JSON.stringify({
          type        : 'point',
          coordinates : [ feature.location.x, feature.location.y, ],
        })
        this.AddressForm.formik.setFieldValue('AddressLocation', addressLocation)
        break
      }
      default: 
        break
      }
    }
    // should only clear the geocode result if this method 
    // was called in response to new results (instead of from map layers)
    if (resetGeocodeResult) {
      this.props.ResetGeocoded()
    }
  }

  /**
   * Get status information about a child form
   * @param {React.Component} form the form component to check
   * @returns form status information object
   */
  getFormStatus = async (form) => {
    const hasValues = form.hasValues()
    const touched = Object.values(form.formik.touched).some(v => v === true)
    let errors = {}
    if (touched && hasValues) {
      errors = await form.formik.validateForm({
        ...form.formik.values,
        Force: true,
      })
    }
    const valid = Object.keys(errors).length === 0 && form.formik.isValid
    const formStatusObj = { hasValues, touched, errors, valid, }
    return formStatusObj
  }

  async validateSection ({ evt, submit = true, }) {
    stopEvent(evt)
    if (!this.formik || !this.PLSSForm || !this.GeoCoordinateForm || !this.AddressForm) {
      const isValid = await retry(this.validateSection, { evt, submit, }, 100)
      return isValid
    }

    const { readOnly, canEditUga, } = this.props
    
    if (readOnly && !canEditUga) {
      return false
    }

    // Get the sub-form states
    // (address, geocoordinate, plss/trs)
    const addressForm =  this.AddressForm
    const {
      touched: addressTouched,
      // errors: addressErrors,
      hasValues: addressHasValues,
      valid: addressValid,
    } = await this.getFormStatus(this.AddressForm)

    // TRS is always required. Either address or geocoordinate is also required
    const plssForm = this.PLSSForm
    const {
      // touched: plssTouched,
      // errors: plssErrors,
      // hasValues: plssHasValues,
      valid: plssValid,
    } = await this.getFormStatus(this.PLSSForm)

    const geocoordForm =  this.GeoCoordinateForm
    const {
      touched: geocoordTouched,
      // errors: geocoordErrors,
      hasValues: geocoordHasValues,
      valid: geocoordValid,
    } = await this.getFormStatus(this.GeoCoordinateForm)

    if (!readOnly && submit) {
      // either the geocoordinate needs to be valid or the address needs to be valid
      if (!(geocoordHasValues && geocoordValid) && !(addressHasValues && addressValid)){
        this.props.Failure('You must complete either the Burn Address or Geolocation form.')
        return false
      }
    }

    // Check if there are errors with the Additional Location info
    const additionalLocationInfoErrors = await this.formik.validateForm()
    const AdditionalInfoValid = Object.keys(additionalLocationInfoErrors).length === 0
    // If there are
    if (!AdditionalInfoValid) {
      // Attempt the submit to reveal the inline validation messages
      this.submitForm()
    }

    const coordHasValidValues = geocoordHasValues && geocoordValid
    const addressHasValidValues = addressHasValues && addressValid

    // if either the address form or the geocoordinate form has values and is valid
    // and the other form is either empty or valid, consider the form valid.
    // also the plss and other location info must be valid
    const isValid = AdditionalInfoValid 
      && plssValid 
      && (
        (coordHasValidValues && (addressHasValidValues || !addressHasValues))
        || (addressHasValidValues && (coordHasValidValues || !geocoordHasValues))
      )

    if (isValid && submit && !readOnly) {
      plssForm.submitForm()
      if (geocoordTouched && coordHasValidValues) {
        geocoordForm.submitForm()
      }
      if (addressTouched && addressHasValidValues) {
        addressForm.submitForm()
      }
      this.submitForm()
    }
    return isValid
  }

  submitForm = () => {
    this.formik.submitForm()
  }

  submit = (values) => {
    
    const {
      FireDistrictDepartmentId,
      DrivingDirections,
      SlopePercent,
      ElevationFeet,
      RegionId,
      CountyId,
      IsUGA,
    } = values

    const { BurnPermitLocationId, } = this.props.permitLocation
    const additionalLocationInfo = {
      FireDistrictDepartmentId,
      DrivingDirections,
      SlopePercent,
      ElevationFeet,
      BurnPermitLocationId,
      RegionId,
      CountyId,
      IsUGA: IsUGA === 'yes',
    }
      
    const { GeocodeResult, } = this.props.GeoCoordinateState
    if (GeocodeResult && GeocodeResult.location) {
      additionalLocationInfo.AddressLocation = JSON.stringify({
        type        : 'point',
        coordinates : [ GeocodeResult.location.x, GeocodeResult.location.y, ],
      })
    }

    this.props.UpdateAdditionalLocationInfo(additionalLocationInfo)
  }

  buildGeoLocGraphics = () => {

    const { GeocodeResult, TRSResult, } = this.props.GeoCoordinateState
    const { BurnPermitId, address, permitLocation, } = this.props
    const { LatLong, } = this.props.Map
    const graphics = []
    const graphicsForUGA = []

    // Add polygons first
    const plssFormAvailable = this.PLSSForm && this.PLSSForm.formik  && this.PLSSForm.formik.values.LegalDescriptionLocation
    if (TRSResult || permitLocation.LegalDescriptionLocation || plssFormAvailable) {
      let features= []
      if (TRSResult && TRSResult.features.length) {
        features = TRSResult.features.map(f => {
          return {
            geometry   : { rings: f.geometry.coordinates, type: f.geometry.type.toLowerCase(), },
            attributes : { ...f.properties, },
          }
        }, [])
      }
      else if (permitLocation.LegalDescriptionLocation) {
        const geometry = JSON.parse(permitLocation.LegalDescriptionLocation)
        features.push({
          geometry   : { rings: geometry.coordinates, type: 'polygon', },
          attributes : {
            TRS_CD: formatLegalDesc(permitLocation),
          },
        })
      }
      else if (plssFormAvailable) {
        const geometry = JSON.parse(this.PLSSForm.formik.values.LegalDescriptionLocation)
        features.push({
          geometry   : { rings: geometry.coordinates, type: 'polygon', },
          attributes : {
            TRS_CD: formatLegalDesc(this.PLSSForm.formik.values),
          },
        })
      }
      for (let f = 0, fLen = features.length; f < fLen; f++) {
        const convertedFeature = features[0]
        convertedFeature['popupTemplate'] = { 
          title   : 'Burn Permit Legal Description', 
          content : [ { 
            type       : 'fields', 
            fieldInfos : [
              { fieldName: 'TRS_CD', label: 'Legal Desc.', },
            ], 
          }, ],
        }
        convertedFeature.symbol = POLYGON_SYMBOL
        graphics.push(convertedFeature)
      }
    }
    
    // Then points
    if (!isEmpty(LatLong) && LatLong.Latitude > 0 && LatLong.Longitude < 0) {
      const { Latitude, Longitude, } = LatLong
      const latLongPoint = {
        geometry: {
          type      : 'point',
          latitude  : Latitude,
          longitude : Longitude,
        },
        attributes: {
          BurnPermitID : BurnPermitId,
          Latitude     : Latitude,
          Longitude    : Longitude,
        },
        popupTemplate: {
          title   : 'Burn Location Lat/Long',
          content : [ {
            type       : 'fields',
            fieldInfos : [
              { fieldName: 'BurnPermitID', label: 'Burn Permit ID', visible: true, },
              { fieldName: 'Longitude', label: 'Longitude', visible: true, },
              { fieldName: 'Latitude', label: 'Latitude', visible: true, },
            ],
          }, ],
        },
      }
      graphicsForUGA.push(latLongPoint)
      graphics.push(latLongPoint)
    }

    // Only try to show the geocoordinate address result is there is one
    const addressFormHasLocation = this.AddressForm && this.AddressForm.formik && this.AddressForm.formik.values.AddressLocation

    if (GeocodeResult || (address && address.AddressLocation) || addressFormHasLocation) {
      let x, y, addressText
      if (GeocodeResult) {
        addressText = GeocodeResult.address
        x = GeocodeResult.location.x
        y = GeocodeResult.location.y
      }
      else if (address && address.AddressLocation) {
        addressText = formatAddress(address)
        const geoJson = JSON.parse(address.AddressLocation)
        x = geoJson.coordinates[0]
        y = geoJson.coordinates[1]
      }
      else if (addressFormHasLocation) {
        addressText = formatAddress(address)
        const geoJson = JSON.parse(this.AddressForm.formik.values.AddressLocation)
        x = geoJson.coordinates[0]
        y = geoJson.coordinates[1]
      }
      if (x && y) {
        const addressPoint = {
          geometry: {
            type      : 'point',
            latitude  : y,
            longitude : x,
          },
          attributes: {
            BurnPermitID : BurnPermitId,
            // Latitudes are +/- 0:90 and Longitudes are +/- 0:180
            // The precision arguments are intentionally different in 
            // order to display the same number of decimal values
            Latitude     : y.toPrecision(8),
            Longitude    : x.toPrecision(9),
            Address      : addressText,
          },
          popupTemplate: {
            title   : 'Burn Location Address (Geocoded)',
            content : [ {
              type       : 'fields',
              fieldInfos : [
                { fieldName: 'BurnPermitID', label: 'Burn Permit ID', visible: true, },
                { fieldName: 'Address', label: 'Address', visible: true, },
                { fieldName: 'Longitude', label: 'Longitude', visible: true, },
                { fieldName: 'Latitude', label: 'Latitude', visible: true, },
              ],
            }, ],
          },
        }
        // Only check if the Address is in the UGA if there is no Lat/Long point
        if (graphicsForUGA.length === 0) {
          graphicsForUGA.push(addressPoint)
        }
        graphics.push(addressPoint)
      }
    }

    // Only check if the graphics change to limit the actions dispatched and queries issued
    if (this.props.Map.MapActive && !isEqual(this.props.Map.overlapGraphics, graphicsForUGA)) {
      setTimeout(() => this.props.CheckIfGraphicsOverlapLayerFeatures(UGA.id, graphicsForUGA), 100)
    }

    return graphics
  }

  onFormChange = (changedData = []) => {
    const { canEditUga, GeneralMessage, } = this.props

    if (canEditUga && changedData.some(d => d[0] === 'IsUGA')) {
      GeneralMessage('UGA Change', 'Please outline in an Application Status comment why you changed this value.')
    }
    this.validateSection({ submit: false, }).then(isValid => {
      this.setState({ isValid, })
    })
  }

  deleteAddress = evt => {
    stopEvent(evt)
    const { addressid, } = evt.target.dataset
    this.props.DeleteBurnLocationAddress(this.props.burnPermitLocationId, addressid)
  }

  createAddress = address => {
    this.props.CreateBurnLocationAddress(this.props.burnPermitLocationId, address)
  }

  setMapLatLong = async (data) => {
    if (this.mapRef && this.mapRef.setLatLong) {
      await this.mapRef.setLatLong(data)
    }
  }

  

  async onAddressFormBlur (evt) {
    // if this blur is for an element contained in the form, ignore it
    if (evt) {
      const { currentTarget, relatedTarget, } = evt
      if (currentTarget && relatedTarget && currentTarget.contains(relatedTarget)) {return}
    }
    
    // if the form isn't ready or hasn't changed just exit
    if (!this.AddressForm || !this.AddressForm.formik || !this.AddressForm.formik.touched || !this.props.online) {
      return
    }

    // only geocode if the form is valid, run validate with Force = true
    // to make sure everything is checked
    this.AddressForm.formik.validateForm({
      ...this.AddressForm.formik.values,
      Force: true,
    }).then((errors) => {
      if (!isEmpty(errors)) { return }
      const {
        StreetLine1,
        AddressCity,
        AddressState,
        AddressZipCode,
      } = this.AddressForm.formik.values
      if (AddressZipCode.toString().length === 5) {
        this.props.Geocode(StreetLine1, AddressCity, AddressState, AddressZipCode)
      }
    })
      .catch(err => console.error('formik validate error', err))
  }


  setFormikRef = node => this.formik = node

  setAddressFormRef = node => this.AddressForm = node

  setGeoCoordFormRef = node => this.GeoCoordinateForm = node

  setPLSSFormRef = node => this.PLSSForm = node

  setMapRef = node => this.mapRef = node

  render () {
    const { permitLocation, address, addressType, readOnly, isInUGA, canEditUga, } = this.props
    
    // Force Physical Address Type
    if (address && addressType) {
      address.AddressTypeId = addressType.AddressTypeId
    }

    let children = ''

    if (!permitLocation) {
      children = <Col xs={{ size: 4, offset: 4, }}>
        <Card>
          <CardBody>
            <CardText>Loading Burn Location Data...</CardText>
          </CardBody>
        </Card>
      </Col>
    }
    else {
      const burnLocationMapData = [
        {
          layerId    : this.props.burnPermitLocationId,
          layerTitle : 'Burn Permit Locations',
          data       : this.buildGeoLocGraphics(),
        },
      ]
      const initialValues = {
        FireDistrictDepartmentId : permitLocation.FireDistrictDepartmentId,
        DrivingDirections        : permitLocation.DrivingDirections,
        SlopePercent             : permitLocation.SlopePercent,
        ElevationFeet            : permitLocation.ElevationFeet,
        CountyId                 : permitLocation.CountyId,
        RegionId                 : permitLocation.RegionId,
        IsUGA                    : permitLocation.IsUGA, 
        CreateBy                 : permitLocation.CreateBy,
        CreateDate               : permitLocation.CreateDate,
        UpdateBy                 : permitLocation.UpdateBy,
        UpdateDate               : permitLocation.UpdateDate,
      }
      children =
        <>
          <Col xs={'12'} lg={'4'} className={'d-flex align-items-stretch order-0'}>
            <Card className={'w-100'} style={geoLocCardHeight}>
              <CardHeader tag={'h4'}>
                Geolocation
                <PopoverButton
                  disabled={this.props.Map.MapLoading}
                  popoverHeader={'Geolocation'}
                  popoverBody={<>
                    <p>Enter values in decimal degrees format.</p>
                    <p>
                      Latitude/longitude is required when there is not
                      a street address for the burn location. If the burn area is large, the latitude/longitude should be
                      provided for the approximate center of the burn area.
                    </p>
                    <p>
                      There are several acceptable ways to obtain the latitude and longitude including:
                    </p>
                    <ul>
                      <li>Google Maps (See Appendix A for directions on using Google Maps to determine latitude and
                        longitude)</li>
                      <li>Google Earth</li>
                      <li>GPS unit (NAD 83 datum)</li>
                    </ul>
                  </>
                  }
                />
              </CardHeader>
              <CardBody className={'d-flex justify-content-between flex-column'}>
                <GeoCoordinateForm
                  BurnPermitLocationId={permitLocation.BurnPermitLocationId}
                  ref={this.setGeoCoordFormRef}
                  onChange={this.onFormChange}
                  readOnly={readOnly}
                  setMapLatLong={this.setMapLatLong}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={'12'} lg={'8'} className={'d-flex align-items-stretch order-2 order-lg-1 mt-3 mt-lg-0'}>
            <Card>
              <CardHeader tag={'h4'}>
                Legal Description
                <PopoverButton
                  disabled={this.props.Map.MapLoading}
                  popoverHeader={'Legal Description'}
                  popoverBody={'You can often find this on your Statutory Warranty Deed, Property Tax Statement or by contacting the County Assessor\'s Office.'}
                />
              </CardHeader>
              <CardBody className={'legal-desc d-flex justify-content-between flex-column'}>
                <Label>
                    Provide the legal description by ¼, ¼, Section, Township, and Range that most closely represents the 
                    location of the property where the forest material is being removed and burned. If the burn area is large 
                    provide the legal description that best represents the center of the burn area.
                </Label>
                <PLSSForm
                  BurnPermitLocationId={permitLocation.BurnPermitLocationId}
                  ref={this.setPLSSFormRef}
                  onChange={this.onFormChange}
                  readOnly={readOnly}
                />
                { this.state.legalDescError && <>
                  <input type={'hidden'} invalid={'true'} className={'form-control is-invalid'} />
                  <FormFeedback>{this.state.legalDescError}</FormFeedback>
                </>
                }
              </CardBody>
            </Card>
          </Col>
          <Col xs={'12'} lg={'7'} className={'mt-3 d-flex align-items-stretch order-1 order-lg-2'}>
            <Card className={'w-100'} style={mapCardHeight}>
              <CardHeader className={'position-relative'}>
                <h4 className={'m-0'}>Map</h4>
                <LoadingBar show={this.props.IsGeocoding} />
              </CardHeader>
              <CardBody className={'p-0'}>
                <ESRIMap
                  config={'Permit Application'}
                  mapData={burnLocationMapData}
                  featureLayers={ALL_LAYERS}
                  TargetFeatureLayerIds={TARGET_FEATURE_LAYER_IDS}
                  legendOpen={false}
                  parentRef={this.setMapRef}
                  onIntersectingFeaturesChanged={this.onIntersectingFeaturesChanged}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={'12'} lg={'5'} className={'mt-3 order-3'}>
            <Card>
              <CardHeader tag={'h4'}>Burn Address</CardHeader>
              <CardBody
                onBlur={this.onAddressFormBlur}
              >
                <AddressForm
                  addressId={permitLocation.AddressId}
                  address={address}
                  createFn={this.createAddress}
                  ref={this.setAddressFormRef}
                  onChange={this.onFormChange}
                  readOnly={readOnly}
                  showAddressTypes={false}
                  shouldGeocode={false}
                  disableState={true}
                  disableStreeLine3={true}
                  description={<>
                    This must be a valid street address in Washington&nbsp;
                    where <b>the forest material is being removed and burned</b>. 
                    It is <b>not</b> your home or mailing address.
                  </>}
                  actionButton={!readOnly && permitLocation.AddressId > 0
                    ? <Button
                      key={`btn-del-adr-${permitLocation.AddressId}`}
                      size={'sm'}
                      color={'danger'}
                      data-addressid={permitLocation.AddressId}
                      onClick={this.deleteAddress}
                    >
                      Delete
                    </Button>
                    : null
                  }
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={'12'} className={'mt-3 order-4'}>
            <Card>
              <CardHeader tag={'h4'}>Additional Location Information</CardHeader>
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={this.validationSchema}
                  onSubmit={this.submit}
                  innerRef={this.setFormikRef}
                >
                  {({ values, errors, }) => (
                    <>
                      <Effect values={values} onChange={this.onFormChange} />
                      <Row>
                        <Col xs={'12'} sm={'4'}>
                          <FormGroup>
                            <RequiredLabel for={'txt-number-elevation'}>Elevation of Burn Unit</RequiredLabel>
                            <Field
                              name={'ElevationFeet'}
                              id={'txt-number-elevation'}
                              type={'number'}
                              placeholder={124}
                              value={values.ElevationFeet}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={'12'} sm={'6'}>
                          <FormGroup>
                            <RequiredLabel for={'txt-number-slope'}>Slope (average percent slope of unit)</RequiredLabel>
                            <Field
                              name={'SlopePercent'}
                              id={'txt-number-slope'}
                              type={'number'}
                              placeholder={2.5}
                              value={values.SlopePercent}
                              readOnly={readOnly}
                              component={ValidatingField}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={'6'}>
                          <FormGroup>
                            <Field name={'RegionId'}>
                              {({ field, form, }) => (
                                <Select
                                  label={'Region'}
                                  items={this.props.Regions}
                                  propertyName={field.name}
                                  {...field}
                                  selectedValue={field.value || -1}
                                  errorMessage={form.errors[field.name]}
                                  readOnly={readOnly}
                                  required={true}
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col md={'6'}>
                          <FormGroup>
                            <Field name={'CountyId'}>
                              {({ field, form, }) => (
                                <Select
                                  label={'County'}
                                  items={this.props.Counties}
                                  propertyName={field.name}
                                  {...field}
                                  selectedValue={field.value || -1}
                                  errorMessage={form.errors[field.name]}
                                  readOnly={readOnly}
                                  required={true}
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <RequiredLabel for={'fire-district-dept'}>Local Fire Department or Fire District Name</RequiredLabel>
                            <PopoverButton
                              buttonClassName={'py-0'}
                              popoverHeader={'Local Fire Department or Fire District Name'}
                              popoverBody={<>Please choose your fire district from the list. If in an unprotected area, choose Unprotected. If you do not know, choose Unknown.</>}
                            />
                            <Field name={'FireDistrictDepartmentId'}>
                              {({ field, form, }) => (
                                <Select
                                  id={'fire-district-dept'}
                                  items={this.props.FireDistrictDepartments}
                                  propertyName={field.name}
                                  {...field}
                                  selectedValue={field.value || -1}
                                  errorMessage={form.errors[field.name]}
                                  readOnly={readOnly}
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label for={'IsUGA'} id={'UGA-label'}>
                              Are you burning within an Urban Growth Area (UGA)?
                              <br/>
                              <small>This value is auto-populated based on the Lat/Long and/or Address you provide.</small>
                            </Label>
                            <Field name={'IsUGA'}>
                              {({ field, }) => (
                                <>
                                  <YesNoRadioButtonGroup
                                    onChange={field.onChange}
                                    inputName={field.name}
                                    checkedValue={(field.value || false).toString()}
                                    readOnly={!canEditUga}
                                    ariaLabel={'UGA-label'}
                                  />
                                  {
                                    (field.value && (field.value === 'yes' || field.value.toString() === 'true')) && <Alert color={'warning'}>
                                      The location of your planned burn is within or near a UGA.
                                      You will be required to submit Burn Requests for <b>all</b> burns, regardless of tonnage.
                                    </Alert>
                                  }
                                </>
                              )}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <RequiredLabel for={'txt-directions'}>Provide driving directions to the burn location from the nearest primary road, highway, or state route (895 characters)</RequiredLabel>
                            <Field
                              name={'DrivingDirections'}
                              component={'textarea'}
                              id={'txt-directions'}
                              placeholder={'Type out directions here'}
                              value={values.DrivingDirections}
                              className={`form-control ${errors.DrivingDirections ? 'is-invalid' : ''}`}
                              readOnly={readOnly}
                            />
                            <ErrorMessage name={'DrivingDirections'} component={FormFeedback}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row><AuditData {...values} /></Row>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </>
    }
    return <BurnPermitFormSection
      {...this.props}
      isValid={typeof this.state.isValid === 'boolean' ? this.state.isValid : ''}
      validateSection={this.validateSection}
      readOnly={canEditUga ? false : readOnly}
      sectionBadge={isInUGA ? <Badge color={'secondary'} className={'ml-3'}>In a UGA</Badge> : null}
    >
      { children }
    </BurnPermitFormSection>
  }
}

function mapStateToProps (state, props) {
  
  const permitLocation = permitLocationByIdSelector(state, props.burnPermitLocationId)
  
  let address = permitLocation ? permitLocation.Address : null
  if (!address && permitLocation) {
    address = locationAddress(state, permitLocation.BurnPermitLocationId)
  }

  const userCanEditUga = userCanEditUGA(state)
  // DNR Users can edit the UGA value if the application is not Denied, Issued or Revoked.
  const canEditUga = userCanEditUga && (
    props.applicationStatus !== 'Denied' &&
    props.applicationStatus !== 'Issued' &&
    props.applicationStatus !== 'Revoked'
  )
  const { online, } = state.offline

  const { applications, activeBurnPermitId, } = state.BurnPermitForm
  const isActive = applications[activeBurnPermitId].activeStep === props.sectionId
  return {
    online,
    permitLocation,
    address,
    canEditUga,
    isActive,
    Map                     : state.Map,
    GeoCoordinateState      : state.GeoCoordinate,
    IsGeocoding             : state.GeoCoordinate.IsGeocoding,
    IsGeocodingLocation     : state.GeoCoordinate.IsGeocodingLocation,
    geocodedLocation        : geoLocationFormSelector(state),
    BurnPermitId            : state.BurnPermitForm.activeBurnPermitId,
    addressType             : addressTypeByNameSelector(state, PHYSICAL_ADDRESS_TYPE),
    Regions                 : regionsForSelectSelector(state),
    Counties                : countiesForSelectSelector(state),
    FireDistrictDepartments : fireDistrictDepartmentsForSelectSelector(state),
    Directions              : directionsForSelectSelector(state, (d => [ 'E', 'W', ].includes(d.DirectionAbbreviation))),
  }
}

const mapDispatchToProps = {
  UpdateAdditionalLocationInfo        : BurnPermitLocationActions.updateBurnPermitLocationAdditionalInfo,
  GetBurnLocationInfo                 : BurnPermitLocationActions.getBurnLocationInfo,
  DeleteBurnLocationAddress           : BurnPermitLocationActions.deleteBurnLocationAddress,
  CreateBurnLocationAddress           : BurnPermitLocationActions.createBurnLocationAddress,
  ResetGeoCoordinate                  : GeoCoordinateActions.resetGeoCoordinate,
  Geocode                             : GeoCoordinateActions.geocode,
  ResetGeocoded                       : GeoCoordinateActions.resetGeolocatedLocation,
  CheckIfGraphicsOverlapLayerFeatures : MapActions.checkIfGraphicsOverlapLayerFeatures,
  GeneralMessage                      : ApiActions.generalMessage,
  Failure                             : ApiActions.failure,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitBurnLocationSection) 