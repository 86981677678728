import React, { useCallback, useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { string, } from 'prop-types'
import { useFormikContext, } from 'formik'

// Components
import ESRIMap from '../ESRIMap'

// Redux
import MapActions from '../../redux/MapRedux'

// Selectors
import { latLongSelector, mapStateSelector, } from '../../selectors/mapSelectors'
import { regionsForSelectSelector, } from '../../selectors/regionSelectors'

// Map
import { REGIONS, } from '../../config/map/featureLayers'


const CustomerInteractionsMap = props => {
  const {
    layerTitle,
    mapConfig,
    popupTitle,
  } = props

  const { setFieldValue, } = useFormikContext()

  // Selectors
  const { Latitude, Longitude, } = useSelector(latLongSelector)
  const regions = useSelector(regionsForSelectSelector)
  const { MapActive, MapLoading, } = useSelector(mapStateSelector)

  // Redux actions
  const dispatch = useDispatch()

  // mount effect
  useEffect(() => {
    dispatch(MapActions.getLatLongFromMap(true, true))
  }, [ dispatch, ])

  const mapData = useMemo(() => {
    const eventLocData = []
    if (Latitude && Longitude && MapActive && !MapLoading) {
      eventLocData.push(
        {
          geometry: {
            type      : 'point',
            latitude  : Latitude,
            longitude : Longitude,
          },
          attributes: {
            Latitude,
            Longitude,
          },
          popupTemplate: {
            popupTitle : popupTitle || layerTitle,
            content    : [
              {
                type       : 'fields',
                fieldInfos : [
                  {
                    fieldName: 'Longitude',
                  },
                  {
                    fieldName: 'Latitude',
                  },
                ],
              },
            ],
          },
        }
      )
      setFieldValue('Latitude', Latitude)
      setFieldValue('Longitude', Longitude)
    }
    return [
      {
        layerId : 'customerInteractionlocation',
        layerTitle,
        data    : eventLocData,
      },
    ]
  }, [ Latitude, Longitude, popupTitle, layerTitle, MapActive, MapLoading, setFieldValue, ])

  const intersectingFeatures = useCallback(intersecting => {
    // no data, just return
    if (!Array.isArray(intersecting) || !intersecting.length) {
      return
    }
    // Filter out any extra layers (when handling map result)
    const [ region, ] = intersecting.filter(f => f.layerId === REGIONS.id)
    const [ matchingRegion, ] = regions.filter(r => r.Text === region.attributes.JURISDICT_LABEL_NM)
    if (matchingRegion && 'Value' in matchingRegion && MapActive && !MapLoading) {
      setFieldValue('RegionId', matchingRegion.Value)
    }
  }, [ regions, MapActive, MapLoading, setFieldValue, ])

  const targetFeatureLayerIds = useMemo(() => ([ REGIONS.id, ]), [])

  return <ESRIMap
    config={mapConfig}
    legendOpen={false}
    mapData={mapData}
    onIntersectingFeaturesChanged={intersectingFeatures}
    TargetFeatureLayerIds={targetFeatureLayerIds}
    zoom={6}
  />
}

CustomerInteractionsMap.propTypes = {
  layerTitle : string,
  mapConfig  : string,
  popupTitle : string,
}

export default React.memo(CustomerInteractionsMap)
