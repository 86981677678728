// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { Card, CardBody, CardHeader, } from 'reactstrap'
import { bool, } from 'prop-types'

// Selectors
import {
  activeBurnPermitIdSelector,
  permitHasSignedPermitDoc,
} from '../../../../selectors/burnPermitSelectors'
import {
  userMustSignPermitToSubmit,
} from '../../../../selectors/burnPermitPermissionSelectors'
import { signatureByPermitId, } from '../../../../selectors/burnPermitSignatureSelectors'
import {
  agencyAutoRegionApprovalByPermitId,
  agencyPaysByVoucherByPermitId,
} from '../../../../selectors/agencySelectors'
import { userIsDNR, } from '../../../../selectors/userSelectors'

// Components
import SignPermit from '../Buttons/SignPermit'
import ApplicantSignature from '../Permit/ApplicantSignature'
import ApproverSignature from '../Permit/ApproverSignature'
import DownloadPermitLinks from '../../Buttons/DownloadPermitLinks'


const PermitSignature = () => {
  // Selectors
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const {
    PermitEnvelopeId,
    PermitApproverSignedBy,
  } = useSelector(state => signatureByPermitId(state, burnPermitId))

  const userIsDnr = useSelector(userIsDNR)
  const paysByVoucher = useSelector(state => agencyPaysByVoucherByPermitId(state, burnPermitId))
  const autoRegionApproval = useSelector(state => agencyAutoRegionApprovalByPermitId(state, burnPermitId))

  const needsApproverSignature = !PermitApproverSignedBy

  const mustSignPermit = useSelector(userMustSignPermitToSubmit)
  const hasSignedPermit = useSelector(state => permitHasSignedPermitDoc(state, burnPermitId))
  // We don't need to show the Sign button if the Permit is issued and signed.
  // One way to determine if the Permit is fully Issued and Signed is to
  // check to see if the signed docs are downloaded (this.props.hasSignedPermit).
  // These docs do not get downloaded by the API unless the requisite signatures have
  // been made, so once we have those, we don't need to also check the signatures, envelopeId, etc.
  // This covers all user signing scenarios, including when issuing a Permit
  // to a non-user Agent of an Agency where other Agents of that Agency are
  // users and need to make Burn Requests on the permit issued to a non-user Agent
  const needsApplicantSignature = mustSignPermit && hasSignedPermit !== true

  const isAutoApproved = (paysByVoucher || autoRegionApproval) && PermitEnvelopeId === '[AUTO-APPROVED]'

  let markup = []
  if (needsApproverSignature) {
    if (userIsDnr) {
      markup.push(<p key={'need-approver-signature'}>Issue Permit in the Application Summary area.</p>)
    }
    else {
      markup.push(<p key={'need-approver-signature'}>Waiting for the Approver to sign.</p>)
    }
  }
  else {
    markup.push(<ApproverSignature key={'approver-signature'} />)
    if (isAutoApproved) {
      markup.push(<p key={'auto-approved'}><b>Permit was automatically approved and issued, permit downloads are unavailable.</b></p>)
    }
    else if (needsApplicantSignature) {
      markup.push(<SignPermit key={'sign-permit'} />)
    }
    else if (!hasSignedPermit) {
      markup.push(<p key={'need-applicant-signature'}><b>Waiting for the Applicant to sign.</b></p>)
    }
    else {
      markup.push(<ApplicantSignature key={'applicant-signature'} />)
      markup.push(<DownloadPermitLinks key={'download-in-doc-section'} burnPermitId={burnPermitId} />)
    }
  }

  return <Card>
    <CardHeader>Permit</CardHeader>
    <CardBody>
      {markup}
    </CardBody>
  </Card>
}

PermitSignature.defaultProps = {
  autoRegionApproval : false,
  paysByVoucher      : false,
}

PermitSignature.propTypes = {
  autoRegionApproval : bool,
  paysByVoucher      : bool,
}

export default React.memo(PermitSignature)
