import React from 'react'


const AgreementTerms = () => {
  const terms = <>
    <p>
      Permit applicants are required to follow and obey all applicable provisions of Chapter 76.04 RCW (Forest
      Protection), Chapter 70.94 RCW (Washington Clean Air Act), Chapter 332-24 WAC (Forest Protection), and the
      Smoke Management Plan in effect at the time of burning.
    </p>
    <p>I certify that:</p>
    <div>
      <ul>
        <li>
          If granted a permit, I agree to comply with Chapter 76.04 RCW (Forest Protection), Chapter 70.94
          RCW (Washington Clean Air Act), Chapter 332-24 WAC (Forest Protection), the Smoke Management Plan in
          effect at the time of burning, and the conditions contained in the permit;
        </li>
        <li>
          The information provided is true and accurate to the best of my knowledge;
        </li>
        <li>
          I believe the proposed burning is reasonably necessary, and that no practical alternative exists;
        </li>
        <li>
          I grant the Department of Natural Resources (DNR), or its representative, access to all acreage listed on
          any burning permit application I submit or on any burning permit I am issued, including private roads
          or access ways under my control needed to access the listed acreage for the purpose of
          investigating conditions specific to the burning permit or application;
        </li>
        <li>
          If applying as the landowner&apos;s agent, I have landowner written approval to conduct the burning
          requested in this application.
        </li>
      </ul>
    </div>
    <p>
      To the extent reasonable and consistent with carrying out the duties of the Department of Natural
      Resources burning permitting program, you will be notified and given the option to accompany DNR,
      or its duly authorized representatives, when accessing your property.
    </p>
  </>
  return terms
}

export default React.memo(AgreementTerms)
