// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, } from 'react-redux'
import { Button, } from 'reactstrap'
import { bool, } from 'prop-types'

// Reducers
import BurnPermitPileGroupActions from '../../../../../redux/BurnPermitPileGroupRedux'


const CancelEditPile = props => {
  const {
    IsLocal,
    disabled,
  } = props

  const dispatch = useDispatch()

  const cancel = useCallback(() => {
    if (IsLocal) {
      dispatch(BurnPermitPileGroupActions.deletePileGroup())
    }
    else {
      dispatch(BurnPermitPileGroupActions.editBurnPermitPileGroup())
    }
  }, [ dispatch, IsLocal, ])

  return <Button
    color={'light'}
    size={'sm'}
    disabled={disabled}
    onClick={cancel}
  >
    Cancel
  </Button>
}

CancelEditPile.propTypes = {
  disabled : bool,
  IsLocal  : bool,
}

export default React.memo(CancelEditPile)