import React from 'react'
import { Label, } from 'reactstrap'
import { array, string, bool, oneOfType, } from 'prop-types'

// Icons
import RequiredIndicator from '../Icons/RequiredIndicator'


function RequiredLabel (props) {
  const {
    children,
    labelFor,
    readOnly,
    className,
  } = props
  return <Label
    for={labelFor}
    className={className}
  >
    { children }
    { !readOnly && <RequiredIndicator /> }
  </Label>
}

RequiredLabel.defaultProps = {
  className: '',
}

RequiredLabel.propTypes = {
  labelFor  : string,
  children  : oneOfType([ string, array, ]),
  readOnly  : bool,
  className : string,
}

export default React.memo(RequiredLabel)
