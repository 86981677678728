import { objHasProp, } from '../utilities'

export const getBasemapLayer = (map) => {
  const { items, } = map.basemap.baseLayers
  return items.filter(i => i.visible)[0]
}

export const getColRowFromXy = (x, y, level, map) => {
  const { tileInfo, } = getBasemapLayer(map)
  const { resolution, } = tileInfo.lods[level]
  const { cols, rows, } = tileInfo.toJSON()
  const col = Math.floor((x - tileInfo.origin.x) / (cols * resolution))
  const row = Math.floor((tileInfo.origin.y - y) / (rows * resolution))
  return [ col, row, ]
}

export const getTileUrlsByExtent = (extent, level, map) => {
  const layer = getBasemapLayer(map)
  const urls = []
  const { xmin, xmax, ymin, ymax, } = extent
  const [ col1, row1, ] = getColRowFromXy(xmin, ymin, level, map)
  const [ col2, row2, ] = getColRowFromXy(xmax, ymax, level, map)
  let minCol, maxCol, minRow, maxRow
  if (col1 < col2) {
    minCol = col1
    maxCol = col2
  }
  else {
    minCol = col2
    maxCol = col1
  }
  if (row1 < row2) {
    minRow = row1
    maxRow = row2
  }
  else {
    minRow = row2
    maxRow = row1
  }
  for (var i = minCol; i <= maxCol; i++) {
    for (var j = minRow; j <= maxRow; j++) {
      urls.push(layer.getTileUrl(level, j, i))
    }
  }
  return urls
}

function popupField (field) {
  return {
    fieldName : field.name,
    label     : field.label || field.name,
    visible   : objHasProp(field, 'visible') ? field.visible : true,
  }
}

export const buildPopupTemplate = (layer) => {
  const template = {
    title   : layer.title,
    content : [
      {
        type       : 'fields',
        fieldInfos : layer.fields.map(popupField),
      },
    ],
  }
  if (layer.actions) {
    template.actions = layer.actions
  }
  return template
}
