import React from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

function withRouter (Component) {
  function ComponentWithRouterProp (props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    props = {
      ...props,
      location,
      navigate,
      params,
    }
    return <Component {...props} />
  }

  return ComponentWithRouterProp
}

export default withRouter