// Libraries
import React, { useCallback, useState, } from 'react'
import { useDispatch, } from 'react-redux'
import { func, } from 'prop-types'
import {
  Input,
  Label,
  Container,
  Card,
  CardBody,
  Button,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap'

// Actions
import BurnPermitInstructionsActions from '../../../redux/BurnPermitInstructionsRedux'

// Components
import Instructions from '../../Instructions'


const HowToApply = props => {
  const { understandCallback, }= props

  // Hooks
  const dispatch = useDispatch()

  // State
  const [ doNotShowAgain, setDoNotShowAgain, ] = useState(false)

  // UI Callbacks
  const checkboxChange = useCallback(() => {
    setDoNotShowAgain(!doNotShowAgain)
  }, [ doNotShowAgain, ])

  const userUnderstandsInstructions = useCallback(() => {
    if (doNotShowAgain) {
      dispatch(BurnPermitInstructionsActions.acknowledgedInstructions())
    }
    if (typeof understandCallback === 'function') {
      understandCallback()
    }
  }, [ dispatch, doNotShowAgain, understandCallback, ])

  return <Container className={'pt-2'}>
    <h1>How to Apply for a Burn Permit</h1>
    <Card className={'w-100'}>
      <CardBody>
        <p>Please review the steps outlined below required to obtain a Burn Permit.</p>
        <p>
          Once you&apos;ve reviewed the steps, click the <b>I Understand</b> button below to continue to the Burn Permit Application
        </p>
        <Instructions />
      </CardBody>
    </Card>
    <div className={'d-flex justify-content-end mt-2 align-items-center'}>
      <Label check className={'mr-3'}>
        <Popover
          placement={'top'}
          target={'do-not-show-again'}
          isOpen={doNotShowAgain}
          className={'help-popover'}>
          <PopoverHeader>View Instructions</PopoverHeader>
          <PopoverBody tabIndex={0}>
            <p>
              To view these instructions again during the application process, look for the&nbsp;
              <Button size={'sm'} color={'light'} className={'readonly'}>Instructions</Button>
              &nbsp; in the top left of the application, underneath the Burn Permit Application ID.
            </p>
          </PopoverBody>
        </Popover>
        <Input
          id={'do-not-show-again'}
          type={'checkbox'}
          onChange={checkboxChange}
        />{' '}Do not show again
      </Label>
      <Button onClick={userUnderstandsInstructions}>I understand</Button>
    </div>
  </Container>
}

HowToApply.propTypes = {
  understandCallback: func,
}

export default React.memo(HowToApply)