import { dateFormatter, } from '../utilities'
import { ormSelector, ormByIdSelector, ormWithPropSelector, propsSelector, } from './selectors'


export const referenceDirectionTypesSelector = ormSelector(
  ({ ReferenceDirectionType, }) => {
    return ReferenceDirectionType.all().toRefArray()
  }
)

export const distancesSelectSelector = ormSelector(
  ({ Distance, }) => {
    return Distance.all().toModelArray().map((d) => {
      return {
        Value : d.DistanceId,
        Text  : d.DistanceName,
      }
    })
  }
)

export const directionsSelectSelector = ormSelector(
  ({ Direction, }) => {
    return Direction.all().toModelArray().map((d) => {
      return {
        Value : d.DirectionId,
        Text  : d.DirectionName,
      }
    })
  }
)

export const burnReasonSelectSelector = ormSelector(
  ({ BurnReason, }) => {
    return BurnReason.all().toModelArray().map((b) => {
      return {
        Value : b.BurnReasonId,
        Text  : `${b.BurnReasonName} (${b.BurnReasonCode})`,
      }
    })
  }
)

export const burnUnitSelector = ormSelector(
  ({ BurnPermitUnit, }) => {
    return BurnPermitUnit.all().toModelArray().map((u) => {
      return {
        Value : u.BurnPermitUnitName,
        Text  : u.BurnPermitUnitName,
      }
    })
  }
)

export const forestTypesSelectSelector = ormSelector(
  ({ ForestType, }) => {
    return ForestType.all().orderBy(b => [ b.ForestTypeLocationName, b.ForestTypeName, ]).toModelArray().map((b) => {
      return {
        Value : b.ForestTypeId,
        Text  : b.ForestTypeName,
        Group : b.ForestTypeLocationName,
      }
    })
  }
)

export const arrivalTimeForSelectSelector = ormSelector(
  ({ ArrivalTime, }) => {
    return ArrivalTime.all().toRefArray().map(a => {
      return { 'Value': a.ArrivalTimeId, 'Text': a.ArrivalTimeName, }
    })
  }
)

export const equipmentTypeCheckboxSelector = ormWithPropSelector(
  propsSelector,
  (session, { category, useDesc, }) => {
    let models = session.EquipmentType.all().toModelArray()
    if (category) {
      models = models.filter(e => e.EquipmentTypeCategoryName === category || !e.EquipmentTypeCategoryName)
    }
    return models.map((e) => {
      return {
        Value : e.EquipmentTypeId,
        Text  : useDesc !== true ? e.EquipmentTypeName : e.EquipmentTypeDescription,
      }
    })
  }
)

export const burnPermitSiteByIdSelector = ormByIdSelector(
  ({ BurnPermitSite, }, SiteId) => {
    if (isNaN(SiteId)) {
      return null
    }
    const site = BurnPermitSite.withId(SiteId)
    if (!site) {
      return null
    }

    const mappedSite = {
      BurnPermitSiteId: site.BurnPermitSiteId,

      BurnUnitName                     : site.BurnUnitName || '',
      NumberPeopleOnsite               : site.NumberPeopleOnsite || '',
      HarvestDate                      : site.HarvestDate ? dateFormatter(site.HarvestDate, 'YYYY-MM-DD') : '',
      SnowOffDate                      : site.SnowOffDate ? dateFormatter(site.SnowOffDate, 'YYYY-MM-DD') : '',
      BarkBeetleOutbreakPreventionFlag : site.BarkBeetleOutbreakPreventionFlag || '',
      WaitForWetConditionsFlag         : site.WaitForWetConditionsFlag || '',
      ForestTypeId                     : site.ForestTypeId || '',
      BurnReasonId                     : site.BurnReasonId || '',
      ForestPracticePermitNumber       : site.ForestPracticePermitNumber || '',
      ClosedSeasonBurn                 : typeof site.ClosedSeasonBurn === 'boolean' ? site.ClosedSeasonBurn : '',
      PlannedBurnDays                  : site.DaysOfWeek.toRefArray().map(d => d.DayOfWeekId) || [],
      NumberOfHandPilesToBurnAtOnce    : site.NumberOfHandPilesToBurnAtOnce || '',
      NumberOfMachinePilesToBurnAtOnce : site.NumberOfMachinePilesToBurnAtOnce || '',

      CreateBy   : site.CreateBy,
      CreateDate : site.CreateDate,
      UpdateBy   : site.UpdateBy,
      UpdateDate : site.UpdateDate,
    }
    return mappedSite
  }
)

export const burnPermitReferenceDirectionsSelector = ormByIdSelector(
  ({ BurnPermitSite, ReferenceDirection, }, SiteId) => {
    if (isNaN(SiteId)) {
      return []
    }
    const site = BurnPermitSite.withId(SiteId)
    if (!site || !site.BurnPermitReferenceDirectionXref) {
      return []
    }
    const siteRefDirs = site.BurnPermitReferenceDirectionXref.toRefArray().map(d => d.ReferenceDirectionId)
    const refDirs = ReferenceDirection.filter(rd => siteRefDirs.includes(rd.ReferenceDirectionId)).toRefArray()
    return refDirs
  }
)

const baseSiteEquip = 
{
  BurnPermitSiteEquipmentXrefId : '',
  BurnPermitSiteId              : '',
  EquipmentTypeIds              : [],
  OnsiteFlag                    : false,
  OffsiteFlag                   : false,
  FireLineConstructionFlag      : false,
  ExtinguishingFlag             : false,
  OffsiteEquipmentArrivalTime   : '',
  NumberOfGallons               : '',
  OtherEquipmentDescription     : '',
  HasEquipAnswer                : '',
}

const siteEquipCombos = () => {
  const combos = []
  for (let i = 0, len = 4; i < len; i++) {
    combos.push({
      ...baseSiteEquip,
      // Makes sure the first pair of questions are FireLine
      FireLineConstructionFlag : i < 2,
      // The second pair are Extinguish
      ExtinguishingFlag        : i > 1,
      // This makes sure that each question appears in the order
      // of onsite then offsite
      OnsiteFlag               : (i % 2) !== 1,
      OffsiteFlag              : (i % 2) === 1,
    })
  }
  return combos
}

export const burnPermitSiteEquipmentSelector = ormByIdSelector(
  ({ BurnPermitSite, BurnPermitSiteEquipmentXref, ArrivalTime, }, SiteId) => {
    if (isNaN(SiteId)) {
      return []
    }
    const site = BurnPermitSite.withId(SiteId)
    if (!site) {
      return []
    }

    // Set up the default objects to be used for rendering the forms
    const siteEquips = [ ...siteEquipCombos(), ]
    
    // Get the permit site equipment
    const permitSiteEquip = BurnPermitSiteEquipmentXref.filter({ BurnPermitSiteId: SiteId, }).toRefArray()

    const mappedEquips = []
    // If there are, loop the default objects and match up
    // the permit site equipment object
    siteEquips.forEach(e => {
      let mappedValues = {
        ...e,
        // Default it to No since they have successfully saved the section already
        HasEquipAnswer: site.UpdateDate ? false : '',
      } // start with a copy of the default object
      const equips = permitSiteEquip.filter(p => {
        return (
          (
            p.FireLineConstructionFlag === e.FireLineConstructionFlag
            || p.ExtinguishingFlag === e.ExtinguishingFlag
          )
          && (
            p.OnsiteFlag === e.OnsiteFlag
            || p.OffsiteFlag === e.OffsiteFlag
          )
        )
      })
      if (equips.length) {
        mappedValues = { ...mappedValues, ...equips[0], }
        mappedValues.OnsiteFlag = typeof mappedValues.OnsiteFlag === 'boolean' ? mappedValues.OnsiteFlag : false
        mappedValues.OffsiteFlag = typeof mappedValues.OffsiteFlag === 'boolean' ? mappedValues.OffsiteFlag : false
        mappedValues.ExtinguishingFlag = typeof mappedValues.ExtinguishingFlag === 'boolean' ? mappedValues.ExtinguishingFlag : false
        mappedValues.FireLineConstructionFlag = typeof mappedValues.FireLineConstructionFlag === 'boolean' ? mappedValues.FireLineConstructionFlag : false
        const offsiteArrivalTimeQuery = ArrivalTime.filter({ ArrivalTimeName: mappedValues.OffsiteEquipmentArrivalTime, })
        if (mappedValues.OffsiteEquipmentArrivalTime && offsiteArrivalTimeQuery.exists()) {
          const offsiteArrivalTime = offsiteArrivalTimeQuery.first()
          mappedValues.OffsiteEquipmentArrivalTime = offsiteArrivalTime ? offsiteArrivalTime.ArrivalTimeId : ''
        }
        else {
          mappedValues.OffsiteEquipmentArrivalTime = ''
        }
        mappedValues.OtherEquipmentDescription = equips.map(e => e.OtherEquipmentDescription).filter(d => !!d)[0] || ''
        mappedValues.EquipmentTypeIds = equips.map(e => e.EquipmentTypeId)
        mappedValues.HasEquipAnswer = mappedValues.EquipmentTypeIds.filter(e => e !== null).length > 0
      }
      mappedEquips.push(mappedValues)
    })
    
    return mappedEquips
  }
)