import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitProhibitedDayConditionXref extends Model {
  static get modelName () {
    return 'BurnPermitProhibitedDayConditionXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitProhibitedDayConditionXrefId',
    }
  }

  static get fields () {
    return {
      BurnPermitProhibitedDayConditionXrefId : attr(),
      BurnPermitConditionXrefId              : fk({ 
        to          : 'BurnPermitConditionXref', 
        as          : 'BurnPermitConditionXref', 
        relatedName : 'BurnPermitProhibitedDayConditionXrefs',
      }),
      DayOfWeekId: fk({
        to          : 'DaysOfWeek',
        as          : 'DayOfWeek',
        relatedName : 'BurnPermitProhibitedDayConditionXrefs',
      }),
      CreateBy   : attr(),
      CreateDate : attr(),
      UpdateBy   : attr(),
      UpdateDate : attr(),
    }
  }
}