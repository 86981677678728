import { createReducer, createActions, } from 'reduxsauce'
import { dateFormatter, } from '../utilities'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  lookupData          : [ 'modelName', ],
  checkedLookupData   : [ 'modelName', ],
  downloadLookupData  : null,
  clearLookupChecks   : null,
  failure             : [ 'error', ],
  updateRecord        : [ 'modelName', 'body', ],
  updateRecordRequest : (modelName, url, body, showSuccess) => ({
    type : 'UPDATE_RECORD_REQUEST',
    meta : {
      offline: {
        effect   : { url, init: { method: 'PUT', body: { ...body, }, }, },
        commit   : { type: 'API_SUCCESS', modelName, showSuccess, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  createRecord            : [ 'modelName', 'body', 'endpoint', 'useResponseToCreate', 'shouldRedirect', ],
  clearError              : [ 'id', ],
  clearAllErrors          : null,
  logError                : [ 'errorInfo', 'localState', ],
  generalMessage          : [ 'title', 'message', ],
  clearGeneralMessage     : [ 'id', ],
  clearAllGeneralMessages : null,
  offlineMessage          : [ 'message', ],
  clearOfflineMessage     : [ 'id', ],
  cancelSubmit            : [ 'action_type', 'url', 'method', 'keyName', 'keyValue', ],
  apiSuccess              : [ 'payload', ],
  apiFail                 : [ 'payload', ],
  donePreparingOffline    : null,
})

export const ApiTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  isLoading         : false,
  requestError      : [],
  lookupDataChecked : [],
  offlineMessages   : [],
  generalMessages   : [],
  preparingOffline  : false,
  offlinePrepared   : false,
}
  
/* ------------- Reducers ------------- */

export const failure = (state, { error, }) => {
  const newState = Object.assign({}, state)
  newState.isLoading = false
  const occurredAtDate = new Date()
  // Default to the current time in ticks
  let errorId = occurredAtDate.getTime()
  // If errors exist though, just add to the value to ensure
  // it doesn't clash with existing IDs
  if (newState.requestError.length) {
    errorId = newState.requestError.sort()[newState.requestError.length - 1].id + 1
  }
  let errorMessage = error || 'An unknown error occurred.'
  if (typeof error === 'object' && error !== null) {
    // caught errors
    if ('message' in error || 'error' in error) {
      errorMessage = error.message || error.error
    }
    // endpoint model validation failures or unhandled 500 errors
    else if ('title' in error) {
      errorMessage = error.title
    }
    // saga/other display errors
    else if (error != null) {
      errorMessage = error.toString()
    }
    else {
      errorMessage = 'An unknown error occurred.'
    }
  }
  newState.requestError = [
    ...newState.requestError,
    {
      id         : errorId,
      message    : errorMessage,
      occurredAt : dateFormatter(occurredAtDate, 'hh:mm:ss a'),
    },
  ]
  return newState
}

export const clearError = (state, { id, }) => {
  const newState = Object.assign({}, state)
  const errorIdx = newState.requestError.map(e => e.id).indexOf(id)
  newState.requestError.splice(errorIdx, 1)
  newState.requestError = [ ...newState.requestError, ]
  return newState
}

export const clearAllErrors = (state) => {
  const newState = Object.assign({}, state)
  newState.requestError = []
  return newState
}

export const checkedLookupData = (state, { modelName, }) => {
  const newState = Object.assign({}, state)
  const setValues = [ ...new Set([ ...newState.lookupDataChecked, modelName, ]), ]
  newState.lookupDataChecked = setValues
  return newState
}

export const clearLookupChecks = (state) => {
  const newState = Object.assign({}, state)
  newState.lookupDataChecked = []
  return newState
}

export const offlineMessage = (state, { message, }) => {
  const newState = Object.assign({}, state)
  newState.isLoading = false
  const occurredAtDate = new Date()
  const id = occurredAtDate.getTime()
  newState.offlineMessages = [
    ...newState.offlineMessages,
    {
      id,
      message,
      occurredAt: dateFormatter(occurredAtDate, 'hh:mm:ss a'),
    },
  ]
  return newState
}

export const clearOfflineMessage = (state, { id, }) => {
  const newState = Object.assign({}, state)
  const msgIdx = newState.offlineMessages.map(e => e.id).indexOf(id)
  newState.offlineMessages.splice(msgIdx, 1)
  newState.offlineMessages = [ ...newState.offlineMessages, ]
  return newState
}

export const generalMessage = (state, { title, message, }) => {
  const newState = Object.assign({}, state)
  newState.isLoading = false
  const occurredAtDate = new Date()
  const id = occurredAtDate.getTime()
  newState.generalMessages = [
    ...(newState.generalMessages || []),
    {
      id,
      message,
      title,
      occurredAt: dateFormatter(occurredAtDate, 'hh:mm:ss a'),
    },
  ]
  return newState
}

export const clearGeneralMessage = (state, { id, }) => {
  const newState = Object.assign({}, state)
  const msgIdx = newState.generalMessages.map(e => e.id).indexOf(id)
  newState.generalMessages.splice(msgIdx, 1)
  newState.generalMessages = [ ...newState.generalMessages, ]
  return newState
}

export const clearAllGeneralMessages = (state) => {
  const newState = Object.assign({}, state)
  newState.generalMessages = []
  return newState
}

const downloadLookupData = (state) => {
  return { ...state, preparingOffline: true, }
}

const donePreparingOffline = (state) => {
  return { ...state, preparingOffline: false, offlinePrepared: true, }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FAILURE]                    : failure,
  [Types.CLEAR_ERROR]                : clearError,
  [Types.CLEAR_ALL_ERRORS]           : clearAllErrors,
  [Types.CHECKED_LOOKUP_DATA]        : checkedLookupData,
  [Types.CLEAR_LOOKUP_CHECKS]        : clearLookupChecks,
  [Types.OFFLINE_MESSAGE]            : offlineMessage,
  [Types.CLEAR_OFFLINE_MESSAGE]      : clearOfflineMessage,
  [Types.GENERAL_MESSAGE]            : generalMessage,
  [Types.CLEAR_GENERAL_MESSAGE]      : clearGeneralMessage,
  [Types.CLEAR_ALL_GENERAL_MESSAGES] : clearAllGeneralMessages,
  [Types.DOWNLOAD_LOOKUP_DATA]       : downloadLookupData,
  [Types.DONE_PREPARING_OFFLINE]     : donePreparingOffline,
})