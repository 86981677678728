/**
 * @typedef {object} FileComparison
 * @property {Array<string>} files1 Array of file names
 * @property {Array<string>} files2 Array of file names
 */

/**
 * @typedef {object} FileComparisonResult
 * @property {string} errMessage An error
 * @property {Array<string>} repeatingNames Array of file names that exist in both input arrays
 */

/**
 * Compares the arrays of filenames to ensure only unique file names are provided
 * @param {FileComparison} fileComparison Arrays of file names
 * @returns {FileComparisonResult}
 */
export function compareFileNames ({ files1, files2, }) {
  const fileNamesToUpload = files1.map(f => f.name)
  const uniqueFileNamesToUpload = [ ...new Set(files1.map(f => f.name)), ]
  let repeatingNames = []
  if (uniqueFileNamesToUpload.length !== files1.length) {
    // get file name(s) that are repeating
    repeatingNames = fileNamesToUpload.filter((n, _, arr) => {
      return arr.filter(v => v === n).length > 1
    })
  }
  // compare uniqueFileNamesToUpload to the currentFilesNames
  const currentFilesNames = files2.map(f => f.DocumentName)
  if (currentFilesNames.some(n => uniqueFileNamesToUpload.includes(n))) {
    // get file name(s) that are repeating
    repeatingNames = [ ...currentFilesNames, ...uniqueFileNamesToUpload, ]
      .filter((n, _, arr) => {
        return arr.filter(v => v === n).length > 1
      })
  }
  // Only return a unique set of the repeating file names
  repeatingNames = [ ...new Set(repeatingNames), ]
  let errMessage = []
  if (repeatingNames.length) {
    errMessage = [
      'One or more documents you are attempting to upload has the same name as an existing document.',
      'Document names must be unique.',
    ]
  }
  return { errMessage, repeatingNames, }
}
