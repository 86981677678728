import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'

// Components
import NavbarDropdownItem from '../NavbarDropdownItem'
import NavbarDropdown from '../NavbarDropdown'

// Selectors
import { userCanCreateNewRequest, } from '../../../selectors/permissionSelectors'
import { userIsAuthenticated, } from '../../../selectors/userSelectors'


const BurnRequests = () => {
  // Selectors
  const canCreateNewRequest = useSelector(userCanCreateNewRequest)
  const isAuthenticated = useSelector(userIsAuthenticated)

  const RequestsAndCreateLinks = useMemo(() => {
    if (!canCreateNewRequest) {
      return null
    }
    return <>
      <NavbarDropdownItem
        to={'/burn-requests'}
        title={'View My Burn Requests'}
      >
        My Requests
      </NavbarDropdownItem>
      <NavbarDropdownItem
        to={'/burn-requests/new'}
        title={'Submit a new Burn Request'}
      >
        New
      </NavbarDropdownItem>
    </>
  }, [ canCreateNewRequest, ])

  const PostBurnLink = useMemo(() => {
    if (!isAuthenticated) {
      return null
    }
    return <NavbarDropdownItem
      to={'/post-burns/new'}
      title={'Enter Post-Burn Report'}
    >
      Enter Post-Burn
    </NavbarDropdownItem>
  }, [ isAuthenticated, ])

  const markup = <NavbarDropdown
    text={'Burn Requests'}
    title={'Burn Requests Menu'}
  >
    {RequestsAndCreateLinks}
    {PostBurnLink}
    <NavbarDropdownItem
      to={'/burn-requests/search'}
      title={'Search Burn Requests'}
    >
      Search
    </NavbarDropdownItem>
  </NavbarDropdown>
  return markup
}

export default React.memo(BurnRequests)
