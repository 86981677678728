// Libraries
import React from 'react'
import { array, } from 'prop-types'
import { Label, ListGroup, ListGroupItem, } from 'reactstrap'


const UserGroups = (props) => {
  const { userGroups, } = props
  if (!userGroups) {
    return null
  }
  return <>
    <Label>
      Groups
    </Label>
    <ListGroup>
      { userGroups.map(g => <ListGroupItem key={g}>{g}</ListGroupItem>) }
    </ListGroup>
  </>
}

UserGroups.defaultProps = {
  userGroups: [],
}

UserGroups.propTypes = {
  userGroups: array,
}

export default React.memo(UserGroups)