import React from 'react'
import { FormGroup, Input, Label, FormFeedback, } from 'reactstrap'
import { PropTypes, number, string, array, func, bool, object, } from 'prop-types'
import { objHasProp, } from '../../utilities'
import { RequiredLabel, } from '.'

/**
 * Builds a `<select />` element using the provided `items`
 */
export default class Select extends React.Component {
  static propTypes = {
    label          : PropTypes.oneOfType([ string, object, ]),
    items          : array,
    propertyName   : string,
    onChange       : func.isRequired,
    errorMessage   : string,
    className      : string,
    labelClassName : string,
    readOnly       : bool,
    style          : object,
    id             : PropTypes.oneOfType([ number, string, ]),
    selectedValue  : PropTypes.oneOfType([ number, string, ]),
    dataset        : object,
    defaultText    : string,
    required       : bool,
  }

  static defaultProps = {
    id            : '',
    errorMessage  : '',
    selectedValue : '',
    readOnly      : false,
    dataset       : {},
    defaultText   : 'value',
  }

  buildOptions = () => {
    const { items, defaultText, } = this.props

    let markup = [ <option value={''} key={-1}>Choose a {defaultText}</option>, ]

    // If no data is provided, whether as an incorrect type or an empty array,
    // return the current markup as we can't reasonably parse what's provided
    if (Array.isArray(items) === false || items.length === 0) {
      return markup
    }
    
    const hasGroups = items.some(i => objHasProp(i, 'Group'))
    if (hasGroups === false) {
      for (let i = 0, len = items.length; i < len; i++) {
        const item = items[i]
        markup.push(<option value={item.Value} key={i}>{item.Text}</option>)
      }
    }
    else {
      // Not all items need to be in a group
      // Groupless items should be added to the list first
      const grouplessItems = items.filter(i => objHasProp(i, 'Group') === false)
      for (let j = 0, jlen = grouplessItems.length; j < jlen; j++) {
        const item = grouplessItems[j]
        markup.push(<option value={item.Value} key={j}>{item.Text}</option>)
      }
      // Then gather the group names
      const groups = items.map(i => i.Group).filter((val, idx, arr) => { return arr.indexOf(val) === idx })
      // Then loop the groups
      for (let g = 0, glen = groups.length; g < glen; g++) {
        const groupLabel = groups[g]
        // Build the optgroups with their nested options
        markup.push(
          <optgroup label={groupLabel} key={g}>{
            items
              .filter(i => i.Group === groupLabel)
              .map((item, idx) => {
                return <option value={item.Value} key={idx} title={item.Title || item.Description || item.Text}>{item.Text}</option>
              })
          }</optgroup>
        )
      }
    }
    return markup
  }

  onChange = evt => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(evt)
    }
  }

  getLabel = () => {
    const { id, label, labelClassName, required, } = this.props
    if (!label) {
      return null
    }
    if (typeof label === 'object') {
      return label
    }
    if (typeof label === 'string') {
      if (required) {
        return <RequiredLabel for={id.toString()} className={labelClassName}>{label}</RequiredLabel>
      }
      return <Label for={id.toString()} className={labelClassName}>{label}</Label> 
    }
    return null
  }

  render () {
    const { propertyName, selectedValue, errorMessage, className, readOnly, style, dataset, } = this.props
    let { id, } = this.props
    if (!id) {
      id = `${propertyName}_${(new Date()).getMilliseconds()}`
    }
    return (
      <FormGroup className={className} style={style}>
        { this.getLabel() }
        <Input 
          type={'select'}
          value={(selectedValue !== null && selectedValue !== undefined ? selectedValue : '').toString()}
          onChange={this.onChange}
          id={id}
          name={propertyName}
          invalid={errorMessage.length > 0}
          readOnly={readOnly}
          disabled={readOnly}
          className={errorMessage.length ? 'is-invalid': ''}
          {...(dataset)}
        >
          {this.buildOptions()}
        </Input>
        <FormFeedback>{errorMessage}</FormFeedback>
      </FormGroup>
    )
  }
}