import { attr, fk, Model, } from 'redux-orm'
import * as Yup from 'yup'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

export default class PostBurn extends Model {
  static get modelName () {
    return 'PostBurn'
  }

  toString () {
    return `${this.BurnPermitNumber || this.BurnPermitId}`
  }

  static getValidationSchema (schemaType) {
    
    const { pileBurn = false, broadcastNaturalBurn = false, } = schemaType || {}

    let shapeObj = {}

    if (pileBurn === true) {
      shapeObj = {
        PilesIgnitedPercent: Yup.number()
          .typeError('Please enter the Piles Ignited percentage')
          .min(0, 'Piles Ignited must be 0.00% or greater')
          .required('Piles Ignited percentage is required'),
      }
    }
    if (broadcastNaturalBurn === true) {
      shapeObj = {
        ...shapeObj,
        BurnedBlackAcres: Yup.number().typeError('Please enter the Burn Black Acres or Percent')
          .test('burn-percent-or-acres-required',
            'Only enter one value for either Area Burned Black (%) or Acres Burned Black',
            function (acres) {
              const { BurnedBlackPercent, } = this.parent
              if ((!BurnedBlackPercent && acres > -1) || (BurnedBlackPercent > -1 && !acres)) {
                return true
              }
              return false
            }),
        BurnedBlackPercent: Yup.number().typeError('Please enter the Burn Black Acres or Percent')
          .test('burn-acres-or-percent-required',
            'Only enter one value for either Area Burned Black (%) or Acres Burned Black',
            function (percent) {
              const { BurnedBlackAcres, } = this.parent
              if ((!BurnedBlackAcres && percent > -1) || (BurnedBlackAcres > -1 && !percent)) {
                return true
              }
              return false
            }),
        BurnedBlackCanopyPercent: Yup.number().typeError('Please enter the Burned Black Canopy percent')
          .max(100, 'Burned Black Canopy Percentage must be 100.00% or less')
          .min(0, 'Burned Black Canopy Percentage must be 0.00% or greater')
          .required('Please enter the percentage of Canopy burned black'),
        BurnedBlackShrubPercent: Yup.number().typeError('Please enter the Burned Black Shrub percent')
          .min(0, 'Burned Black Shrub Percentage must be 0.00% or greater')
          .max(100, 'Burned Black Shrub Percentage must be 100.00% or less')
          .required('Please enter the percentage of Shrubs burned black'),
        FuelMoistureMethod      : Yup.string().ensure(),
        WeatherCollectionMethod : Yup.string()
          .max(64, 'Weather Collection Method must be 64 characters or less'),
        WeatherStationName: Yup.string()
          .max(64, 'Weather Station Name must be 64 characters or less'),
        WindSpeedMph: Yup.number().typeError('Please enter the Wind Speed recorded at the time of ignition')
          .min(0, 'Wind Speed must be 0 or greater'),
        NumberDaysSinceRain: Yup.number().typeError('Please enter the Number of Days since rain')
          .min(0, 'Number of Days Since Rain must be zero or greater')
          .required('Please enter the Number of Days since rain'),
        TenHourFuelMoisturePercent: Yup.number().typeError('Please enter the Ten Hour Fuel Moisture percent')
          .min(0.00, '10-Hour Fuel Moisture Percentage must be 0.00% or greater')
          .max(100.00, '10-Hour Fuel Moisture Percentage must be 100.00% or less'),
        ThousandHourFuelMoisturePercent: Yup.number().typeError('Please enter the Thousand Hour Fuel Moisture percent')
          .min(0.00, '1,000-Hour Fuel Moisture Percentage must be 0.00% or greater')
          .max(100.00, '1,000-Hour Fuel Moisture Percentage must be 100.00% or less')
          .required('Please enter the Thousand Hour Fuel Moisture percent'),
        DuffFuelMoisturePercent: Yup.number().typeError('Please enter the Duff Fuel Moisture percent')
          .min(0.00, 'Duff Fuel Moisture Percentage must be 0.00% or greater')
          .max(100.00, 'Duff Fuel Moisture Percentage must be 100.00% or less'),
        MidflameWindSpeed: Yup.number().typeError('Please enter the Mid-Flame Wind Speed')
          .min(0, 'Mid-Flame Wind Speed must be 0 or greater'),
      }

      if (pileBurn) {
        shapeObj = {
          ...shapeObj,
          BurnedBlackPilePercent: Yup.number().typeError('Please enter the Burned Black Pile percent')
            .max(100, 'Burned Black Piles Percentage must be 100.00% or less')
            .min(0, 'Burned Black Piles Percentage must be 0.00% or greater')
            .required('Please enter the Burned Black Pile percent'),
        }
      }
    }

    shapeObj = {
      ...shapeObj,
      BurnDate: Yup.date()
        .required('Burn Date is required')
        .max(new Date(), 'Burn Date must be in the past'),
      IgnitionPeriod: Yup.number()
        .when('MultiDayBurnFlag', {
          is   : flag => flag,
          then : Yup.number()
            .required('Ignition Period is required.')
            .min(0, 'Ignition Period must be zero or greater')
            .test(
              'multi-day-ignition-period',
              'Ignition Periods for multi day burns must be less than 2,880 min.',
              period => {
                return period < 2880
              }
            ),
          otherwise: Yup.number()
            .required('Ignition Period is required.')
            .min(0, 'Ignition Period must be zero or greater')
            .test(
              'single-day-ignition-period',
              'Ignition Periods for single day burns must be less than 780 min.',
              period => {
                return period < 780
              }
            ),
        }),
      BurnTime: Yup.number()
        .when('IgnitionPeriod', {
          is: (num) => {
            return num > 0
          },
          then: Yup.number()
            .test(
              'between-0000-and-2359',
              'Burn time must be in 24-hour time, between 0000 and 2359',
              (hoursMins) => {
                if (!hoursMins) {
                  return false
                }
                return hoursMins > 0
              }
            )
            .required('Please enter a burn time'),
        }),
      ConsumedTons: Yup.number()
        .typeError('Consumed Tons must be a number')
        .positive('Consumed Tons must be positive')
        .min(0, 'Consumed Tons must be greater than zero'),      
      PostBurnComments: Yup.string()
        .max(1000, 'Post-Burn Comments must be 1,000 characters or less'),
      DidNotBurnReason: Yup.string()
        .when('BurnDate', {
          is: (date) => {
            return !date
          }, 
          then: Yup.string().max(100, 'Did Not Burn Reason must be 100 characters or less').required('Did Not Burn Reason is required if you are not burning.'),
        }),
    }

    return Yup.object().shape(shapeObj)
  }

  static endpoint (PostBurnId) {
    let endpoint = `${REACT_APP_SERVER_URL}PostBurnReports`
    if (PostBurnId) {
      endpoint += `/${PostBurnId}`
    }
    return endpoint
  }

  static get options () {
    return {
      idAttribute: 'PostBurnId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PostBurnId                      : attr(),
      CreateDate                      : attr(),
      CreateBy                        : attr(),
      UpdateDate                      : attr(),
      UpdateBy                        : attr(),
      // Model-Specific Properties
      PilesIgnitedPercent             : attr(),
      BurnDate                        : attr(),
      BurnTime                        : attr(),
      IgnitionPeriod                  : attr(),
      ConsumedTons                    : attr(),
      BurnedBlackPercent              : attr(),
      BurnedBlackAcres                : attr(),
      BurnedBlackPilePercent          : attr(),
      BurnedBlackCanopyPercent        : attr(),
      BurnedBlackShrubPercent         : attr(),
      FuelMoistureMethod              : attr(),
      WeatherCollectionMethod         : attr(),
      WeatherStationName              : attr(),
      WindSpeedMph                    : attr(),
      NumberDaysSinceRain             : attr(),
      TenHourFuelMoisturePercent      : attr(),
      ThousandHourFuelMoisturePercent : attr(),
      DuffFuelMoisturePercent         : attr(),
      MidflameWindSpeed               : attr(),
      PostBurnComments                : attr(),
      LegacyId                        : attr(),
      DidNotBurn                      : attr(),
      DidNotBurnReason                : attr(),
      // Relationships
      BurnPermitId                    : fk({ to: 'BurnPermit', relatedName: 'PostBurns', as: 'BurnPermit', }),
      BurnRequestId                   : fk({ to: 'BurnRequest', relatedName: 'PostBurns', as: 'BurnRequest', }),
      // FuelMoistureMethodId : fk({ to: 'FuelMoistureMethod', relatedName: 'PostBurns', as: 'FuelMoistureMethod', }),
    }
  }
}
