// Libraries
import React from 'react'
import { Col, FormGroup, Label, Button, } from 'reactstrap'
import { Formik, Field, Form, } from 'formik'
import { useDispatch, useSelector, } from 'react-redux'
import * as Yup from 'yup'

// Actions
import AppActions from '../../redux/AppRedux'

// Components
import { PopoverButton, ValidatingField, } from '../FormControls'

// Selectors
import { appStateSelector, } from '../../selectors/appSelectors'

const valSchema = Yup.object().shape({
  HistoryLength: Yup.number()
    .typeError('History length must be a number')
    .min(1)
    .required('Please enter a history length'),
})

function HistorySettingForm () {

  // Hooks
  const dispatch = useDispatch()

  // Selectors
  const { HistoryLength, } = useSelector(appStateSelector)

  // UI Callbacks
  const submit = React.useCallback(({ HistoryLength: newHistLen, }) => {
    dispatch(AppActions.setHistoryLength(newHistLen))
  }, [ dispatch, ])

  const initialValues = { HistoryLength, }
  return (
    <Formik 
      validationSchema={valSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      <Form className={'row'}>
        <Col md={6}>
          <FormGroup>
            <Label for={'HistoryLength'}>History Length
              <PopoverButton
                placement={'bottom'}
                popoverClassName={'popover-med-width'}
                popoverHeader={'History Length'}
                popoverBody={
                  <p>
                    This changes the number of history items displayed in the navbar.
                    This setting is stored in your browser and is reset when logging out or using a different browser.
                  </p>
                }
              />
            </Label>
            <Field
              type={'number'}
              min={'1'}
              max={'255'}
              name={'HistoryLength'}
              id={'HistoryLength'}
              component={ValidatingField}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Button
            size={'sm'}
            className={'ml-2 my-2'}
            type={'submit'}>
            Set History Length
          </Button>
        </Col>
      </Form>
    </Formik>
  )
}

export default React.memo(HistorySettingForm)