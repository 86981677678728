import React from 'react'
import { Input, Row, FormFeedback, Col, } from 'reactstrap'
import { string, bool, object, func, PropTypes, number, } from 'prop-types'

export default class InputCondition extends React.Component {
  
  constructor (props) {
    super(props)

    this.state = {
      value: props.value,
    }
  }

  static propTypes = {
    selected                 : bool,
    condition                : object,
    value                    : PropTypes.oneOfType([ string, number, ]),
    type                     : string,
    readOnly                 : bool,
    unit                     : string,
    onValueChanged           : func.isRequired,
    valSchema                : object,
    conditionValidityChanged : func.isRequired,
  }

  static defaultProps = {
    selected                 : false,
    condition                : {},
    value                    : '',
    readOnly                 : false,
    unit                     : null,
    onValueChanged           : null,
    valSchema                : null,
    conditionValidityChanged : null,
    type                     : 'text',
  }

  state = {
    valid : true,
    error : '',
  }

  isValid = (value) => {
    const { valSchema, selected, readOnly, } = this.props
    if (!selected || readOnly) {
      return { valid: true, error: '', }
    }

    let valid = false
    let error = ''
    try {
      valSchema.validateSync(value, { abortEarly: true, })
      valid = true
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        valid = false
        error = err.errors[0]
      }
    }
    return { valid, error, }
  }

  componentDidMount () {
    if (this.props.selected) {
      const { valid, error, } = this.isValid(this.props.value)
      this.setState({ valid, error, }, () => this.props.conditionValidityChanged(this.props.condition.BurnPermitConditionId, valid))
    }
  }

  componentDidUpdate (prevProps) {
    const { valid, error, } = this.isValid(this.props.value)
    const { selected, conditionValidityChanged, condition, } = this.props

    if (selected && (this.state.valid !== valid)) {
      this.setState({ valid, error, }, conditionValidityChanged(condition.BurnPermitConditionId, valid))
    } else if (!selected && !this.state.valid) {
      this.setState({ valid: true, error: '', }, () => conditionValidityChanged(condition.BurnPermitConditionId, true))
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value, })
    }
  }

  onValueChanged = evt => {
    const { value, } = evt.target
    const { onValueChanged, conditionValidityChanged, condition, } = this.props
    const { valid, error, } = this.isValid(value)
    this.setState({ valid, error, value, }, () => {
      onValueChanged(condition.BurnPermitConditionId, value)
      conditionValidityChanged(condition.BurnPermitConditionId, valid)
    })
  }

  render () {
    const { 
      condition, 
      selected,
      readOnly,
      unit,
      type,
    } = this.props

    const {
      valid,
      error,
      value,
    } = this.state

    if (!condition) {
      return 'No condition provided.'
    }
    const invalid = selected && !readOnly && !valid
    let className = 'inline-text-input'
    let additionalProps
    if (type === 'textarea') {
      additionalProps = {
        rows: 5,
      }
      className = 'textarea-condition'
    } else if (type !== 'text') {
      className = 'number-condition'
      additionalProps = {
        min         : 0,
        step        : 1,
        placeholder : selected ? '0' : '',
      }
    }
    const id = `condition-${condition.BurnPermitConditionId}-input`
    const rowClassName = `${(readOnly && !value) || !selected ? 'grey-text' : null} mb-2`
    return (
      <Row className={rowClassName} aria-disabled={(readOnly && !value) || !selected}>
        <Col xs={12} className={'p-0 m-0'}>
          <label htmlFor={id} className={'d-inline'}>{condition.BurnPermitConditionDescription}: </label>
          {
            readOnly && selected ? <span className={'ml-1 d-inline'}>{value} {unit}</span>
              : <>
                <Input
                  type={type}
                  className={className}
                  value={selected ? value || '' : ''}
                  onChange={this.onValueChanged}
                  readOnly={readOnly}
                  disabled={readOnly}
                  invalid={invalid}
                  {...additionalProps}
                  aria-label={'Condition #' + condition.BurnPermitConditionId}
                  id={id}
                />
                {unit}
                { invalid && <FormFeedback valid={valid}>{error}</FormFeedback> }
              </>
          }
        </Col>
      </Row>
    )
  }
}