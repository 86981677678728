// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { object, func, bool, arrayOf, number, } from 'prop-types'
import { Container, Card, CardBody, Col, Row, } from 'reactstrap'

// Selectors
import { 
  ruleUpdatedSelector, 
  ruleUpdatingSelector, 
  ruleUpdateFailedSelector, 
  rulesLoadingSelector, 
  rulesSelector,
} from '../selectors/ruleSelectors'

// Reducers
import AppActions from '../redux/AppRedux'
import RuleActions from '../redux/RulesRedux'

// Components
import { RuleListForm, } from '../components/Forms'

export class RulesContainer extends React.Component {
  static propTypes = {
    online            : bool,
    SetPageTitle      : func,
    isAdmin           : bool,
    rules             : arrayOf(object),
    UpdateRule        : func,
    rulesUpdated      : arrayOf(number),
    rulesUpdating     : arrayOf(number),
    rulesUpdateFailed : arrayOf(number),
    ResetRuleState    : func,
    GetRules          : func,
  }
 
  static defaultProps = {
    SetPageTitle      : () => {},
    rules             : [],
    UpdateRule        : () => {},
    rulesUpdating     : [],
    rulesUpdated      : [],
    rulesUpdateFailed : [],
    ResetRuleState    : () => {},
    GetRules          : () => {},
    isAdmin           : false,
  }

  state = {
    editing: [],
  }

  componentDidMount () {
    this.props.SetPageTitle('System Rules & Settings')
    if (this.props.online) {
      this.props.GetRules()
    }
  }
  
  render () {
    const {
      online,
      isAdmin,
      rules, 
      UpdateRule, 
      rulesUpdated,
      rulesUpdating,
      rulesUpdateFailed,
      ResetRuleState,
    } = this.props

    return (
      <Container className={'my-4'}>
        <Row className={'ml-1 mb-2'}>
          <Col xs={12}><h1>System Rules &amp; Settings</h1></Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card className={'mt-2 w-100'}>
              <CardBody className={'table'}>
                { !online && <Row><Col><p><b>Editing Rules and Settings requires an internet connection.</b></p></Col></Row>}
                <RuleListForm
                  canEdit={online && isAdmin} 
                  rules={rules}
                  onSubmit={UpdateRule}
                  rulesUpdating={rulesUpdating}
                  rulesUpdated={rulesUpdated}
                  rulesUpdateFailed={rulesUpdateFailed}
                  resetLoad={ResetRuleState}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

function mapStateToProps (state) {
  const { online, } = state.offline
  return {
    online,
    rules             : rulesSelector(state),
    rulesLoading      : rulesLoadingSelector(state),
    rulesUpdating     : ruleUpdatingSelector(state),
    rulesUpdated      : ruleUpdatedSelector(state),
    rulesUpdateFailed : ruleUpdateFailedSelector(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle   : AppActions.setPageTitle,
  GetRules       : RuleActions.getRules,
  UpdateRule     : RuleActions.updateRule,
  ResetRuleState : RuleActions.resetRuleLoadState,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(RulesContainer)
