import { Model, attr, } from 'redux-orm'
import { renderCheckIcon, renderDateTime, renderTons, renderAcres, renderCheckOrXIcon, renderLatLong, } from '../utilities/columnHelpers'
import { dateFormatter, objectToQueryParams, objHasProp, } from '../utilities'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env


export default class BurnRequestSearch extends Model {

  static get modelName () {
    return 'BurnRequestSearch'
  }

  static get options () {
    return {
      idAttribute: 'BurnRequestId',
    }
  }

  static getDataTablesColumns (props) {
    
    let showLandownerAgent = false
    
    if (props && objHasProp(props, 'showLandownerAgent')) {
      showLandownerAgent = props.showLandownerAgent
    }
    
    const cols = [
      {
        title     : 'Burn Request ID',
        data      : 'BurnRequestId',
        className : 'text-right pr-4',
        type      : 'num',
      },
      {
        title      : 'Post-Burn',
        data       : 'HasPostBurn',
        searchable : false,
        render     : renderCheckOrXIcon,
      }, 
      {
        title     : 'Permit Number',
        data      : 'BurnPermitNumber',
        className : 'text-right pr-4',
      },
      {
        title     : 'Application ID',
        data      : 'BurnPermitId',
        type      : 'num',
        visible   : false,
        className : 'text-right pr-4',
      },
      {
        title : 'Unit Name',
        data  : 'BurnUnitName',
        width : '100px',
      },
      {
        title     : 'Request Date',
        data      : 'BurnRequestDate',
        className : 'text-right pr-4',
        render    : renderDateTime,
        width     : '75px',
      },
      {
        title          : 'Multi Day Burn',
        data           : 'MultiDayBurn',
        defaultContent : '',
        render         : renderCheckIcon,
      },
      {
        title   : 'Burn Priority',
        data    : 'BurnPriority',
        visible : false,
      },
      {
        title  : 'Status',
        data   : 'BurnRequestStatus',
        width  : '75px',
        render : (data, drawType, row) => {
          if (drawType === 'sort') {
            return row.BurnRequestStatusDate
          }
          if (drawType !== 'display') {
            return row.BurnRequestStatus
          }
          else if (!data) {
            return data
          }
          return `${row.BurnRequestStatus}<br/>${dateFormatter(row.BurnRequestStatusDate, 'MMM DD, YYYY')}`
        },
      },
      {
        title  : 'Ignition Date',
        data   : 'PlannedIgnitionDate',
        render : (data, drawType, row) => {
          if (drawType !== 'display') {
            return data
          }
          return `${dateFormatter(data, 'MMM DD, YYYY')} ${row.PlannedIgnitionTime}`
        },
      },
      {
        title     : 'Post Burn Date',
        data      : 'PostBurnDate',
        className : 'text-right pr-4',
        render    : renderDateTime,
      },
      {
        title  : 'Ignition Period',
        data   : 'PlannedIgnitionPeriod',
        render : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return `${data} mins`
        },
      },
      {
        title  : 'In UGA',
        data   : 'IsUGA',
        render : renderCheckOrXIcon,
      },
      {
        title          : 'Forest Health Exempt',
        data           : 'IsForestHealthExempt',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType === 'sort') {
            return data === null ? 0 : data === true ? 1 : -1
          }
          if (drawType !== 'display') {
            return data
          }
          if (data === null) {
            return data
          }
          return renderCheckOrXIcon(data, drawType)
        },
      },
      {
        title          : 'Smoke Dispersed',
        data           : 'SmokeDispersedFlag',
        defaultContent : '',
        render         : renderCheckIcon,
        visible        : false,
      },
      {
        title     : 'Est. Permit Tonnage',
        data      : 'TotalPermitTonnage',
        className : 'text-right pr-4',
        render    : renderTons,
      },
      {
        title     : 'Total Proposed Burn',
        data      : 'TotalProposedBurnQuantity',
        className : 'text-right pr-4',
        render    : renderTons,
      },
      {
        title     : 'Post Burn Tonnage',
        data      : 'PostBurnTonnage',
        className : 'text-right pr-4',
        render    : renderTons,
      },
      {
        title     : 'Harvest Acres',
        data      : 'BurnAcres',
        className : 'text-right pr-4',
        render    : renderAcres,
      },
      {
        title     : 'Proposed Burn Area',
        data      : 'ProposedBurnArea',
        className : 'text-right pr-4',
        render    : renderAcres,
      },
      {
        title     : 'Post Burn Area',
        data      : 'PostBurnArea',
        className : 'text-right pr-4',
        render    : renderAcres,
      },   
      {
        title   : 'Lat, Long',
        data    : '',
        visible : false,
        render  : renderLatLong,
      },
    ]

    if (showLandownerAgent) {
      const landowner = {
        defaultContent : '',
        title          : 'Landowner',
        data           : 'Landowner',
        width          : '150px',
      }
      const agent = {
        defaultContent : '',
        title          : 'Agent',
        data           : 'Agent',
        width          : '150px',
      }
      cols.splice(cols.findIndex(c => c.title === 'Unit Name'), 0, landowner)
      cols.splice(cols.findIndex(c => c.title === 'Unit Name'), 0, agent)
    }

    return cols
  }

  static endpoint (params) {
    let endpoint = `${REACT_APP_SERVER_URL}BurnRequests/Search`
    if (params && typeof params === 'object') {
      endpoint += `?${objectToQueryParams(params)}`
    }
    return endpoint
  }

  static get fields () { 
    return {
      BurnRequestId            : attr(),
      BurnPermitId             : attr(),
      BurnPermitNumber         : attr(),
      BurnRequestStartDate     : attr(),
      BurnRequestEndDate       : attr(),
      PlannedIgnitionStartDate : attr(),
      PlannedIgnitionEndDate   : attr(),
      AssignedToId             : attr(),
      RequestStatusId          : attr(),
      UnitName                 : attr(),
      County                   : attr(),
      Region                   : attr(),
      LegalDesc                : attr(),
      MinAcres                 : attr(),
      MaxAcres                 : attr(),
      MinTons                  : attr(),
      MaxTons                  : attr(),
      BurnType                 : attr(),
      AgentName                : attr(),
      LandownerName            : attr(),
      HasPostBurn              : attr(),
      MultiDayBurns            : attr(),
      IsUGA                    : attr(),
      IsForestHealthExempt     : attr(),
      PostBurnDate             : attr(),
      PostBurnTonnage          : attr(),
      PostBurnArea             : attr(),
    }
  }
}