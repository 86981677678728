// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { useSelector, } from 'react-redux'
import { number, } from 'prop-types'

// Selectors
import { permitIsIssued, permitIsDenied, permitIsApproved, activeBurnPermitIdSelector, } from '../../selectors/burnPermitSelectors'
import { userCanAutoSubmitPermit, userMustSignIssuedPermit, } from '../../selectors/burnPermitPermissionSelectors'
import { instructionsSelector, } from '../../selectors/instructionsSelectors'

// Components
import StepsNav from './StepsNav'
import {
  PrivateAndAgentAndDNRSteps,
  NoPaySteps,
  AutoIssuedSteps,
} from './Steps'
import { agencyAutoRegionApprovalByPermitId, agencyPaysByVoucherByPermitId, } from '../../selectors/agencySelectors'


const Instructions = props => {

  let { burnPermitId, } = props
  const activeBurnPermitId = useSelector(activeBurnPermitIdSelector)
  if (!burnPermitId) {
    burnPermitId = activeBurnPermitId
  }

  const { instructionStep, completedSteps, } = useSelector(state => instructionsSelector(state, burnPermitId))

  const canAutoSubmit = useSelector(userCanAutoSubmitPermit)
  const mustSignPermit = useSelector(userMustSignIssuedPermit)
  const autoIssue = useSelector(state => agencyAutoRegionApprovalByPermitId(state, burnPermitId))
  const paysByVoucher = useSelector(state => agencyPaysByVoucherByPermitId(state, burnPermitId))

  const isApproved = useSelector(permitIsApproved)
  const isIssued = useSelector(permitIsIssued)
  const isDenied = useSelector(permitIsDenied)
  let steps = []
  // Fallback to the configuration for the agent/agency of the application
  if (canAutoSubmit || paysByVoucher || autoIssue) {
    // Auto Submit applicants do not pay up front, they pay by voucher later
    // and some of their Permits are auto-issued
    if ((mustSignPermit || paysByVoucher) && !autoIssue) {
      steps = NoPaySteps({ paysByVoucher, isIssued, isDenied, })
    }
    else {
      steps = AutoIssuedSteps({ mustSignPermit, isIssued, })
    }
  }
  else {
    steps = PrivateAndAgentAndDNRSteps({ isIssued, isDenied, isApproved, })
  }
  const appSteps = <StepsNav
    steps={steps}
    currentStep={props.currentStep || instructionStep}
    completedSteps={completedSteps}
  />
  const { caption, } = steps.find(s => s.label === instructionStep) || {}
  const help = <p>
    If you need additional help, check out the <Link to={'/help'}>Help</Link>&nbsp;
    page or contact your <Link to={'/regions'} target={'_blank'} rel={'noopener'}>local DNR Region Office</Link>
  </p>
  const markup = <>
    <div>{appSteps}</div>
    <div className={'mb-2 p-4 border'}>{caption}</div>
    <div>{help}</div>
  </>
  return markup
}

Instructions.propTypes = {
  currentStep  : number,
  burnPermitId : number,
}

export default Instructions
