import { attr, Model, } from 'redux-orm'

export default class FireDistrictDepartment extends Model {
  static get modelName () {
    return 'FireDistrictDepartment'
  }

  toString () {
    return `${this.FireDistrictDepartmentDescription}`
  }

  static endpoint () {
    return 'FireDistrictDepartments'
  }

  static get options () {
    return {
      idAttribute: 'FireDistrictDepartmentId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      FireDistrictDepartmentId          : attr(),
      CreateDate                        : attr(),
      CreateBy                          : attr(),
      UpdateDate                        : attr(),
      UpdateBy                          : attr(),
      // Model-Specific Properties
      FireDistrictDepartmentCode        : attr(),
      FireDistrictDepartmentDescription : attr(),

      // Relationships
    }
  }
}