// Libraries
import React, { useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Container, Col, Row, } from 'reactstrap'

// Selectors
import { appIsOnlineSelector, } from '../selectors/selectors'
import { allRegionsSelector, } from '../selectors/regionSelectors'

// Reducers
import RegionActions from '../redux/RegionsRedux'
import AppActions from '../redux/AppRedux'

// Components
import RegionCard from '../components/RegionCard'


const RegionListContainer = () => {

  const dispatch = useDispatch()
  const online = useSelector(appIsOnlineSelector)

  // mount effect
  React.useEffect(() => {
    dispatch(AppActions.setPageTitle('WA DNR Region Offices'))
    if (online) {
      dispatch(RegionActions.getAllRegions(true))
    }
  }, [ dispatch, online, ])

  const regions = useSelector(allRegionsSelector)

  const markup = useMemo(() => {
    if (Array.isArray(regions) === false) {
      return 'No regions found'
    }
    return regions.map((r) => (
      <Col
        key={`rgn-card-${r.RegionId}`}
        xs={12}
        lg={6}
      >
        <RegionCard region={r} />
      </Col>
    ))
  }, [ regions, ])
  return (
    <Container className={'pt-2 py-4'}>
      <h1>DNR Region Offices</h1>
      <Row className={'region-cards'}>
        {markup}
      </Row>
    </Container>
  )
}

export default RegionListContainer
