import { useCallback, useEffect, } from 'react'
import { useSelector, } from 'react-redux'

// Reducer Actions
import UIActions  from '../redux/UiRedux'

// Selectors
import { isNavbarOpen, } from '../selectors/uiSelectors'

const useToggleNavbar = (dispatch) => {
  const navbarOpen = useSelector(isNavbarOpen)
  useEffect(() => {
    let overflow = 'auto'
    if (navbarOpen) {
      overflow = 'hidden'
    }
    document.body.style.overflow = overflow
  }, [ navbarOpen, ])
  const toggleNavbar = useCallback(() => {
    dispatch(UIActions.toggleNavbar())
  }, [ dispatch, ])
  return [ navbarOpen, toggleNavbar, ]
}

export default useToggleNavbar
