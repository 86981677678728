import { attr, Model, } from 'redux-orm'

export default class Distance extends Model {
  static get modelName () {
    return 'Distance'
  }

  toString () {
    return `${this.DistanceName}`
  }

  static endpoint () {
    return 'Distances'
  }

  static get options () {
    return {
      idAttribute: 'DistanceId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      DistanceId   : attr(),
      CreateDate   : attr(),
      UpdateDate   : attr(),
      // Model-Specific Properties
      DistanceName : attr(),

      // Relationships
    }
  }
}