import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'

// Selectors
import { appHistorySelector, } from '../../../selectors/appSelectors'
import { isNavbarOpen, } from '../../../selectors/uiSelectors'

// Components
import NavbarDropdownItem from '../NavbarDropdownItem'
import NavbarDropdown from '../NavbarDropdown'


const NavHistory = () => {

  // Selectors
  const appHistory = useSelector(appHistorySelector)
  const showAppHistory = useMemo(() => (Array.isArray(appHistory) && appHistory.length), [ appHistory, ])
  const navbarOpen = useSelector(isNavbarOpen)

  // UI Callbacks
  const historyItemStyle = useMemo(() => ({ padding: '0.1em 0.5em', fontSize: '0.95rem', }), [])
  const dropdownMenuStyle = useMemo(() => ({ minWidth: '13.5rem', padding: '0.5em 0', }), [])

  const navbarText = useMemo(() => {
    let text = [
      <span key={'nav-history-icon'} className={'fa fa-history'}/>,
    ]
    if (navbarOpen) {
      text.push(<span key={'nav-history-text'} className={'d-inline-block d-lg-none mr-2'}>Page History</span>)
    }
    return text
  }, [ navbarOpen, ])

  if (!showAppHistory) {
    return null
  }

  const markup = <NavbarDropdown
    text={navbarText}
    title={'Recent page history'}
    menuStyle={dropdownMenuStyle}
  >
    {
      appHistory.map((histItem, idx) => {
        const route = histItem.route || histItem
        const title = histItem.title || route
        return <NavbarDropdownItem
          style={historyItemStyle}
          key={'hist-' + idx}
          to={route}
          title={'Navigate back to ' + title}
        >
          {title}
        </NavbarDropdownItem>
      })
    }
  </NavbarDropdown>
  return markup
}

export default React.memo(NavHistory)
