// Libraries
import React from 'react'
import { Button, } from 'reactstrap'
import { string, func, array, bool, } from 'prop-types'

// Components
import ConfirmationModal from '../ConfirmationModal'
import { PopoverButton, } from '../FormControls'

const CopyApplication = props => {
  const { openModal, modalKey, online, submitAction, submitArgs, } = props
  return <>
    <Button
      color={'dark'}
      size={'sm'}
      onClick={openModal}
      data-modalkey={modalKey}
      disabled={!online}
    >
      <span className={'d-block d-lg-none fa fa-copy p-1'}/>
      <span className={'d-none d-lg-block'}>Copy</span>
    </Button>
    {
      !online
        ? <PopoverButton
          buttonClassName={'py-0'}
          key={'copy-app'}
          popoverHeader={'Online only'}
          popoverBody={'Copying a Burn Permit Application can only be performed while connected to the internet.'}
        />
        : null
    }
    <ConfirmationModal
      modalKey={modalKey}
      modalTitle={'Copy Burn Permit Application'}
      modalBody={<p>
          Are you sure you wish to start a new Burn Permit Application from a copy of this one?
      </p>}
      submitAction={submitAction}
      submitArgs={submitArgs}
    />
  </>
}

CopyApplication.propTypes = {
  openModal    : func,
  modalKey     : string,
  submitArgs   : array,
  submitAction : func,
  online       : bool,
}

export default CopyApplication