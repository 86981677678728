// Libraries
import React from 'react'
import { bool, func, array, string, number, } from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, } from 'reactstrap'

// Components
import BurnPermitCartSummary from './BurnPermitCartSummary'
import ConfirmationModal from '../../components/ConfirmationModal'
import RemovePermitFromOrderModal from './RemovePermitFromOrderModal'


const EMPTY_CART_MODAL = 'EMPTY_CART_MODAL'


const CartModal = props => {
  return <>
    <Modal isOpen={props.isOpen} size={props.size}>
      <ModalHeader>{props.headerText}</ModalHeader>
      <ModalBody>
        <BurnPermitCartSummary
          RemovePermitFromOrder={props.onRemoveClick}
          BurnPermitIds={[ ...props.permitIds, ]}
          usePopover={false}
        />
      </ModalBody>
      <ModalFooter className={'justify-content-between'}>
        <Button
          color={props.leftButtonColor}
          onClick={props.onCloseClick}
        >{props.leftButtonText}</Button>
        <div>
          <Button
            color={props.emptyCartColor}
            onClick={props.onEmptyCartClick}
            className={'mr-2'}
          >{props.emptyCartText}</Button>
          <Button
            color={props.rightButtonColor}
            onClick={props.onCheckoutClick}
            style={{ marginLeft: 'auto', }}
          >{props.rightButtonText}</Button>
        </div>
      </ModalFooter>
    </Modal>
    <RemovePermitFromOrderModal
      idToRemove={props.idToRemove}
      orderId={props.orderId}
      confirmRemoveClick={props.confirmRemoveClick}
    />
    <ConfirmationModal
      modalKey={EMPTY_CART_MODAL}
      modalTitle={'Empty Permit Order Cart'}
      modalBody={<p>Are you sure you want to <b>remove all Permit Applications</b> from the Cart?</p>}
      cancelActionLabel={'No'}
      submitActionLabel={'Yes'}
      submitAction={props.confirmEmptyClick}
      submitArgs={[ true, ]}
    />
  </>
}

CartModal.propTypes = {
  modalKey            : string,
  orderId             : number,
  idToRemove          : string,
  isOpen              : bool,
  onRemoveClick       : func,
  onCheckoutClick     : func,
  onCloseClick        : func,
  onEmptyCartClick    : func,
  confirmRemoveClick  : func,
  confirmEmptyClick   : func,
  permitIds           : array,
  size                : string,
  leftButtonColor     : string,
  leftButtonText      : string,
  emptyCartColor      : string,
  emptyCartText       : string,
  rightButtonColor    : string,
  rightButtonText     : string,
  headerText          : string,
  removePermitMessage : string,
  removePermitTitle   : string,
}

CartModal.defaultProps = {
  isOpen           : false,
  size             : 'lg',
  onRemoveClick    : () => {},
  onCheckoutClick  : () => {},
  onCloseClick     : () => {},
  onEmptyCartClick : () => {},
  leftButtonColor  : 'information',
  leftButtonText   : 'Close',
  emptyCartColor   : 'danger',
  emptyCartText    : 'Empty Cart',
  rightButtonColor : 'secondary',
  rightButtonText  : 'Checkout',
  headerText       : 'Cart Summary',
}

export default CartModal