// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { connect, } from 'react-redux'
import { Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, Collapse, } from 'reactstrap'
import { array, object, number, bool, func, } from 'prop-types'

// Components
import CheckBoxes from '../components/FormControls/CheckBoxes'

// Utilities
import { dateFormatter, } from '../utilities'

// Selectors
import {
  burnedAcresByPermitSelector,
  burnedTonsByPermitSelector,
  daysOfWeekCheckboxSelector,
} from '../selectors/selectors'
import { permitDaysSelector, } from '../selectors/burnPermitSelectors'
import { getPermitSummaryForRequest, } from '../selectors/burnRequestSelectors'
import AuditData from './AuditData'


const BurnPermitSummary = ({
  BurnPermitId,
  burnPermit,
  burnedTons,
  burnedAcres,
  daysOfWeek,
  permitDays,
  showConditionLink,
}) => {
  
  if (!burnPermit) {
    return null
  }

  const conditionsUrl = `/permits/${BurnPermitId}/conditions`
  const auditData = burnPermit ? <Row><Col><AuditData CreateBy={burnPermit.CreateBy} UpdateBy={burnPermit.UpdateBy} CreateDate={burnPermit.CreateDate} UpdateDate={burnPermit.UpdateDate}/></Col></Row> : null
  
  return <>
    { showConditionLink &&
      <Row className={'mb-2'}>
        <Col>
          <small>Please view the <Link to={conditionsUrl}>conditions of your permit</Link> before submitting your request.</small>
        </Col>
      </Row>
    }
    <Row>
      <Col md={4}>
        {
          burnPermit.MailPermitToAgentFlag && burnPermit.Agent
            ? <FormGroup>
              <Label for={'txt-agent-name'}>Agent</Label>
              <Input
                id={'txt-agent-name'}
                value={burnPermit.Agent}
                readOnly={true}
              />
            </FormGroup>
            : burnPermit.MailPermitToLandownerFlag && burnPermit.Burner
              ? <FormGroup>
                <Label for={'txt-landowner-name'}>Landowner</Label>
                <Input
                  id={'txt-landowner-name'}
                  value={burnPermit.Burner}
                  readOnly={true}
                />
              </FormGroup>
              : null
        }
      </Col>
      <Col md={5}>
        <FormGroup>
          <Label for={'txt-unit-name'}>Unit Name</Label>
          <Input
            id={'txt-unit-name'}
            type={'text'}
            readOnly={true}
            value={burnPermit.UnitName}
          />
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <Label for={'txt-burn-type'}>Burn Types</Label>
          <Input
            id={'txt-burn-type'}
            type={'text'}
            readOnly={true}
            value={burnPermit.BurnTypes}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row className={'my-3'}>
      <Col md={6}>
        <FormGroup>
          <Label for={'txt-burned-permitted-acres'}>Burned / Permitted Acres</Label>
          <Input
            id={'txt-burned-permitted-acres'}
            type={'text'}
            readOnly={true}
            value={`${burnedAcres.toLocaleString()} / ${burnPermit.HarvestAcres.toLocaleString()}`}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for={'txt-burned-permitted-tons'}>Burned / Permitted Tons</Label>
          <Input
            id={'txt-burned-permitted-tons'}
            type={'text'}
            readOnly={true}
            value={`${burnedTons.toLocaleString()} / ${burnPermit.TotalPermitTonnage.toLocaleString()}`}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label for={'txt-issue-date'}>Issue Date</Label>
          <Input
            id={'txt-issue-date'}
            type={'text'}
            readOnly={true}
            value={dateFormatter(burnPermit.BurnPermitValidDate || '', 'MM/DD/YYYY')} />
        </FormGroup>
        <FormGroup>
          <Label for={'txt-valid-date'}>Valid Date</Label>
          <Input
            readOnly={true}
            type={'text'}
            id={'txt-valid-date'}
            value={dateFormatter(burnPermit.BurnPermitValidDate || '', 'MM/DD/YYYY')} />
        </FormGroup>
        <FormGroup>
          <Label for={'txt-expiration-date'}>Expiration Date</Label>
          <Input
            id={'txt-expiration-date'}
            type={'text'}
            readOnly={true}
            value={dateFormatter(burnPermit.BurnPermitExpirationDate || '', 'MM/DD/YYYY')} />
        </FormGroup>
      </Col>
      <Col md={6}>
        <CheckBoxes
          CheckboxLabel={'Permitted Planned Burn Days'}
          CheckboxData={daysOfWeek}
          CheckedData={permitDays}
          readOnly={true}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Label for={'txt-latitude-longitude'}>Latitude, Longitude</Label>
          <Input
            id={'txt-latitude-longitude'}
            readOnly={true}
            value={`${burnPermit.Latitude}, ${burnPermit.Longitude}`}
          />
        </FormGroup>
      </Col>
      <Col md={6}></Col>
    </Row>
    {
      burnPermit.ForestHealthExempt && <Row>
        <Col md={6}>
          <FormGroup>
            <Label for={'txt-fh-exempt-approved'}>Forest Health Exemption</Label>
            <Input
              id={'txt-fh-exempt-approved'}
              readOnly={true}
              value={`${burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalFlag ? 'Approved' : 'Denied'}`}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for={'txt-fh-exempt-problem'}>Forest Health Problem</Label>
            <Input
              id={'txt-fh-exempt-problem'}
              readOnly={true}
              value={burnPermit.ForestHealthExempt.ProblemType}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for={'txt-fh-exempt-alt-rx'}>Alternative to Rx Burn Reason</Label>
            <Input
              id={'txt-fh-exempt-alt-rx'}
              readOnly={true}
              value={burnPermit.ForestHealthExempt.AlternativeToRxFire}
              type={'textarea'}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for={'txt-fh-exempt-not-appropriat'}>Alternative Not Appropriate Reason</Label>
            <Input
              id={'txt-fh-exempt-not-appropriat'}
              readOnly={true}
              value={burnPermit.ForestHealthExempt.AlternativeNotAppropriateReason}
              type={'textarea'}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          {
            burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalFlag &&
                <FormGroup>
                  <small>Approved By: {burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalBy}</small><br/>
                  <small>Approved On: {dateFormatter(burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalDate, 'MM-DD-YYYY hh:mm:ss a')}</small>
                </FormGroup>
          }
        </Col>
      </Row>
    }
    {auditData}
  </>
}

BurnPermitSummary.propTypes = {
  BurnPermitId      : number,
  burnPermit        : object,
  burnedTons        : number,
  burnedAcres       : number,
  daysOfWeek        : array,
  permitDays        : array,
  showConditionLink : bool,
}

BurnPermitSummary.defaultProps = {
  burnPermit        : null,
  burnedTons        : 0,
  burnedAcres       : 0,
  daysOfWeek        : [],
  permitDays        : [],
  showConditionLink : true,
}

const BurnPermitSummaryCard = ({
  BurnPermitId,
  burnPermit,
  burnedTons,
  burnedAcres,
  daysOfWeek,
  permitDays,
  isOpen,
  onClick,
  showConditionLink,
  showPermitLink,
}) => {

  const permitUrl = `/permits/${BurnPermitId}`
  const headerText = `Burn Permit ${burnPermit ? burnPermit.BurnPermitNumber : BurnPermitId}`
  let collapse
  if (typeof onClick === 'function') {
    collapse = <span
      onClick={onClick}
      onKeyPress={onClick}
      role={'button'}
      tabIndex={0}
      className={`fa fa-chevron-${isOpen ? 'down' : 'up'} cursor-pointer float-right`}
      style={{ transform: 'translateY(50%)', }}
    ></span>
  }
  return <Card>
    <CardHeader>
      <h2 className={'text-dark d-inline-block m-0'}>
        {
          showPermitLink
            ? <Link to={permitUrl}>{headerText}</Link>
            : headerText
        }
      </h2>
      <span className={'ml-2'}>({burnPermit ? burnPermit.Status : ''})</span>
      { collapse }
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody>
        <BurnPermitSummary
          burnPermit={burnPermit}
          burnedTons={burnedTons}
          burnedAcres={burnedAcres}
          daysOfWeek={daysOfWeek}
          permitDays={permitDays}
          showConditionLink={showConditionLink}
          BurnPermitId={BurnPermitId}
        />
      </CardBody>
    </Collapse>
  </Card>
}

BurnPermitSummaryCard.propTypes = {
  ...BurnPermitSummary.propTypes,
  isOpen  : bool,
  onClick : func,
}

BurnPermitSummaryCard.defaultProps = {
  ...BurnPermitSummary.defaultProps,
  isOpen: true,
}

function mapStateToProps (state, props) {
  const { BurnPermitId, } = props
  const burnPermit = getPermitSummaryForRequest(state, BurnPermitId)
  
  return {
    burnPermit,
    daysOfWeek  : daysOfWeekCheckboxSelector(state),
    burnedAcres : burnedAcresByPermitSelector(state, BurnPermitId),
    burnedTons  : burnedTonsByPermitSelector(state, BurnPermitId),
    permitDays  : permitDaysSelector(state, BurnPermitId),
  }
}

export default connect(mapStateToProps)(BurnPermitSummaryCard)