import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'

// Components
import NavbarDropdownItem from '../NavbarDropdownItem'
import NavbarDropdown from '../NavbarDropdown'

// Selectors
import { userCanCreateNewPermit, userMakesPayments, } from '../../../selectors/permissionSelectors'


const Permits = () => {
  // Selectors
  const canCreateNewPermit = useSelector(userCanCreateNewPermit)
  const canMakePayments = useSelector(userMakesPayments)

  const PermitsAndApplyLinks = useMemo(() => {
    if (!canCreateNewPermit) {
      return null
    }
    return <>
      <NavbarDropdownItem
        to={'/permits'}
        title={'My Permits'}
      >
        My Permits
      </NavbarDropdownItem>
      <NavbarDropdownItem
        to={'/permits/new'}
        title={'Apply for a Burn Permit Application'}
      >
        Apply
      </NavbarDropdownItem>
    </>
  }, [ canCreateNewPermit, ])

  const OrdersLink = useMemo(() => {
    if (!canMakePayments) {
      return null
    }
    return <NavbarDropdownItem
      to={'/permits/orders'}
      title={'View Permit Application Orders'}
    >
      Orders
    </NavbarDropdownItem>
  }, [ canMakePayments, ])

  const menuStyles = useMemo(() => ({ width: 'auto', }), [])

  const markup = <NavbarDropdown
    text={'Permits'}
    title={'Burn Permits Menu'}
    menuStyle={menuStyles}
  >
    {PermitsAndApplyLinks}
    <NavbarDropdownItem
      to={'/permits/search'}
      title={'Search Burn Permits'}
    >
      Search
    </NavbarDropdownItem>
    {OrdersLink}
  </NavbarDropdown>
  return markup
}

export default React.memo(Permits)
