import { createReducer, createActions, } from 'reduxsauce'

import BurnRequestSearch from '../models/BurnRequestSearch'


/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  setStartEnd: (dataStartDate, dataEndDate, viewStartDate, viewEndDate, viewType) => ({
    type    : 'SET_START_END',
    payload : { dataStartDate, dataEndDate, viewStartDate, viewEndDate, viewType, },
    meta    : {
      offline: {
        effect: {
          url: BurnRequestSearch.endpoint({ PlannedIgnitionStartDate: dataStartDate, PlannedIgnitionEndDate: dataEndDate, }),
        },
        commit   : { type: 'CALENDAR_EVENTS_SUCCESS', },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  calendarEventsSuccess : [ 'payload', ],
  setEvents             : [ 'events', ],
  setView               : [ 'view', ],
})

export const CalendarTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  startDate : new Date().toJSON(),
  endDate   : '',
  events    : [],
  viewType  : 'dayGridMonth',
}

export const setStartEnd = (state, { payload, }) => {
  const newState = { ...state, }
  newState.dataStartDate = payload.dataStartDate
  newState.dataEndDate = payload.dataEndDate
  newState.viewStartDate = payload.viewStartDate
  newState.viewEndDate = payload.viewEndDate
  newState.viewType = payload.viewType
  return newState
}

export const setEvents = (state, { events, }) => {
  const newState = { ...state, }
  newState.events = [ ...events, ]
  return newState
}

export const setView = (state, { view, }) => {
  const newState = { ...state, }
  newState.viewType = view
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_START_END] : setStartEnd,
  [Types.SET_EVENTS]    : setEvents,
  [Types.SET_VIEW]      : setView,
})