// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { debounce, isEmpty, isEqual, } from 'lodash'
import { Formik, Field, ErrorMessage, } from 'formik'
import { Row, Col, FormGroup, Label, Button, ButtonGroup, FormFeedback, } from 'reactstrap'
import {
  object,
  bool,
  array,
  func,
  number,
  string,
  shape,
  PropTypes,
} from 'prop-types'

// Components
import Effect from '../Effect'
import AuditData from '../AuditData'
import {
  Select,
  ValidatingField,
  RequiredLabel,
  PopoverButton,
  ButtonGroupButton,
} from '../FormControls'

// Selectors
import { addressTypesSelectSelector, } from '../../selectors/selectors'

// Reducers
import GeoCoordinateActions from '../../redux/GeoCoordinateRedux'
import ApiActions from '../../redux/ApiRedux'

// Models
import Address from '../../models/Address'

// Utilities
import {
  addressPropsDefaults,
  addressPropsTypes,
} from '../../utilities/props'
import stopEvent from '../../utilities/stopEvent'


export class AddressForm extends React.Component {

  constructor (props) {
    super(props)

    this.onChange = debounce(this.onChange.bind(this), 300)
    this.hasValues = this.hasValues.bind(this)

    this.state = {
      lastGeocode: null,
    }
  
    this.setValSchema()
  }

  static propTypes = {
    online             : bool,
    addressId          : PropTypes.oneOfType([ number, string, ]),
    address            : shape(addressPropsTypes),
    showAddressTypes   : bool,
    showActiveControl  : bool,
    addressTypes       : array,
    disableState       : bool,
    disableStreeLine3  : bool,
    readOnly           : bool,
    enableReinitialize : bool,
    createFn           : func,
    updateFn           : func,
    shouldGeocode      : bool,
    CreateRecord       : func,
    UpdateRecord       : func,
    Geocode            : func,
    ClearGeocodeResult : func,
    onChange           : func,
    showAuditData      : bool,
    validateStrict     : bool,
    resetOnBlur        : bool,
    actionButton       : object,
    description        : PropTypes.oneOfType([ object, string, ]),
  }

  static defaultProps = {
    showAddressTypes   : true,
    readOnly           : false,
    createFn           : null,
    shouldGeocode      : true,
    showAuditData      : false,
    validateStrict     : false,
    enableReinitialize : true,
  }

  setValSchema () {
    this.valSchema = this.props.validateStrict
      ? Address.getBasicValidationSchema()
      : Address.getValidationSchema()
  }

  componentDidMount () {
    const { shouldGeocode, address, online, } = this.props

    if (online && shouldGeocode && address && !address.AddressLocation) {
      this.geocodeAddress(address)
    }

    this.setValSchema()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.validateStrict !== this.props.validateStrict) {
      this.setValSchema()
    }
  }
  
  geocodeAddress = address => {
    const { Geocode, } = this.props
    const {
      StreetLine1,
      AddressCity,
      AddressState,
      AddressZipCode,
    } = address
    const geocodeValues = {
      StreetLine1,
      AddressCity,
      AddressState,
      AddressZipCode,
    }
    // If some of the values are empty, don't attempt to geocode
    if (Object.values(geocodeValues).some(v => !v)) {
      // Should clear any geocoded value in the map state tho
      this.props.ClearGeocodeResult()
      return
    }
    
    if (!isEqual(this.state.lastGeocode, geocodeValues)) {
      Geocode(StreetLine1, AddressCity, AddressState, AddressZipCode)
      this.setState({ lastGeocode: geocodeValues, })
    }
  }

  async validateForm (force) {
    if (!this.formik) {
      return null
    }
    const touched = this.touched()
    this.formik.setFieldValue('Force', force || false)
    if (force || touched) {
      const formKeys = Object.keys(this.formik.values)
      for (let i = 0, len = formKeys.length; i < len; i++) {
        this.formik.setFieldTouched(formKeys[i])
      }
    }
    return this.formik.validateForm()
  }

  clearForm = () => {
    const values = { ...this.formik.initialValues, }
    const addrKeys = Object.keys(values)
    const touched = {}
    for (let key of addrKeys) {
      if (this.props.disableState && key === 'AddressState') {
        continue
      }
      values[key] = ''
      touched[key] = false
    }
    this.formik.setValues(values)
    // Use a setTimeout here so that it executes
    // after formik has updated its state and sets
    // the errors for the required fields so then
    // we can clear them
    setTimeout(() => {
      const errors = { ...this.formik.errors, }
      for (let err of Object.keys(errors).filter(e => addrKeys.includes(e))) {
        delete errors[err]
      }
      this.formik.setErrors(errors)
      this.formik.setTouched(touched)
    }, 1)
  }

  resetForm = () => {
    this.formik.resetForm(this.formik.initialValues)
  }

  submitForm = () => {
    this.formik.submitForm()
  }

  touched = () => {
    if (this.formik) {
      return Object.values(this.formik.touched).some(t => t === true)
    }
    // unable to check if touched, just return true
    return true
  }

  submit = (values) => {
    const {
      address,
      addressTypes,
      CreateRecord,
      UpdateRecord,
    } = this.props
    let { addressId, } = this.props
    
    addressId = parseInt(address ? address.AddressId : addressId)

    if (isNaN(parseInt(values.AddressTypeId))) {
      const defaultAddrType = addressTypes.find(a => a.Text === 'Mailing')
      values.AddressTypeId = defaultAddrType.Value
    }
    // Get the object entries
    const valueEntries = Object.entries(values)
    // Get the number of entered values
    const enteredValues = valueEntries.filter(v => v[1]).length
    // Check if they're IDs only
    const idsOnly = valueEntries
      // filter out the object entries to only those that have a value
      .filter(e => !!e[1])
      // Then check to see if all the properties are ID props only
      .every(e => e[0].indexOf('Id') > -1)

    // If we have an AddressId and more than 1 value entered
    // the user is updating the Address
    if (!isNaN(addressId) && enteredValues && !idsOnly && (!address || (address && !address.IsLocal))) {
      values['AddressId'] = addressId
      if (this.props.updateFn) {
        this.props.updateFn(values)
      }
      else {
        UpdateRecord('Address', values)
      }
    }
    // Only attempt to create an address if they likely have 
    // enough values
    else if (enteredValues > 2) {
      if (typeof this.props.createFn === 'function') {
        this.props.createFn(values)
      }
      else {
        CreateRecord('Address', values, 'Addresses', true)
      }
    }
  }

  hasValues () {
    if (!this.formik) {
      return false
    }
    const {
      StreetLine1,
      AddressCity,
      AddressState,
      AddressZipCode,
    } = this.formik.values
    const values = {
      StreetLine1,
      AddressCity,
      AddressState,
      AddressZipCode,
    }
    // If the user enters a value in one or more inputs, but then clears them, `dirty` will return false
    // WA is always set in the form, therefore entries.some checks for values that aren't euqivalent to WA
    return this.formik.dirty || Object.entries(values).some(([ , val, ]) => !!val && val !== 'WA')
  }

  // this method is bound with debounce in the constructor to reduce changes and geocode calls
  onChange () {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange()
    }
    if (this.props.online && this.formik && this.props.shouldGeocode) {
      this.geocodeAddress(this.formik.values)
    }
  }

  setFormikNode = node => this.formik = node

  resetTouchedOnBlur = evt => {
    const { name, value, } = evt.target
    this.formik.setTouched({ ...this.formik.touched, [name]: !!value, })
  }

  render () {
    const {
      disableState,
      disableStreeLine3,
      readOnly,
      showAddressTypes,
      showActiveControl,
      addressTypes,
      description,
      enableReinitialize,
      resetOnBlur,
    } = this.props
  
    const address = {
      // Ensure all the required properties are set
      ...addressPropsDefaults,
      // Override the default values with the incoming object values
      ...this.props.address,
    }
    // Ensure every property is not null or undefined otherwise
    // React/Formik will spit out a warning about a component changing
    // from uncontrolled to controlled
    for (let key of Object.keys(address)) {
      if (address[key] === null || address[key] === undefined) {
        address[key] = ''
      }
    }

    return <Formik
      enableReinitialize={enableReinitialize}
      validationSchema={this.valSchema}
      initialValues={address}
      onSubmit={this.submit}
      innerRef={this.setFormikNode}
    >
      {({ values, }) => (
        <>
          <Effect values={values} onChange={this.onChange} />
          { description && <p>{description}</p> }
          <Row>
            {
              showAddressTypes &&
                <Col>
                  <FormGroup>
                    <Field name={'AddressTypeId'}>
                      {({ field, form, }) => (
                        <Select
                          {...field}
                          id={'addresstype'}
                          label={'Address Type'}
                          items={addressTypes}
                          propertyName={field.name}
                          selectedValue={values[field.name]}
                          readOnly={readOnly}
                          errorMessage={form.errors[field.name]}
                        />
                      )}
                    </Field>
                  </FormGroup>
                </Col>
            }
          </Row>
          <FormGroup>
            <RequiredLabel
              labelFor={'streetline1'}
            >
              Street Line 1
            </RequiredLabel>
            <Field
              id={'streetline1'}
              name={'StreetLine1'}
              readOnly={readOnly}
              component={ValidatingField}
              onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
            />
          </FormGroup>
          <FormGroup>
            <Label for={'streetline2'}>Street Line 2</Label>
            <Field
              id={'streetline2'}
              name={'StreetLine2'}
              readOnly={readOnly}
              component={ValidatingField}
              onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
            />
          </FormGroup>
          {
            disableStreeLine3
              ? null
              : <FormGroup>
                <Label for={'streetline3'}>Street Line 3</Label>
                <Field
                  id={'streetline3'}
                  name={'StreetLine3'}
                  readOnly={readOnly}
                  component={ValidatingField}
                  onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
                />
              </FormGroup>
          }
          <Row>
            <Col>
              <FormGroup>
                <RequiredLabel
                  labelFor={'city'}
                >
                  City
                </RequiredLabel>
                <Field
                  id={'city'}
                  name={'AddressCity'}
                  readOnly={readOnly}
                  component={ValidatingField}
                  onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <RequiredLabel
                  labelFor={'state'}
                  readOnly={disableState}
                >
                  State
                </RequiredLabel>
                <Field
                  id={'state'}
                  name={'AddressState'}
                  readOnly={disableState || readOnly}
                  component={ValidatingField}
                  onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <RequiredLabel
                  labelFor={'zip'}
                >
                  Zip
                </RequiredLabel>
                <Field
                  id={'zip'}
                  name={'AddressZipCode'}
                  readOnly={readOnly}
                  component={ValidatingField}
                  onBlur={resetOnBlur ? this.resetTouchedOnBlur : null}
                />
              </FormGroup>
            </Col>
          </Row>
          {
            showActiveControl &&
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      Active
                      <PopoverButton
                        popoverHeader={'Toggle Address state'}
                        popoverBody={<>
                          <p>Click <b>YES</b> if this address should be available to use on Permit Applications.</p>
                          <p>Click <b>NO</b> if this address should <b>NOT</b> be available to use on <b>NEW</b> Permit Applications.</p>
                          <p>Inactivating (clicking <b>NO</b>) an address already in use on a Permit or Permit Application will not remove the address from the Permit or Permit Application.</p>
                        </>}
                      />
                    </Label>
                    <Field name={'Active'}>
                      {({ field, form, }) => (
                        <>
                          <ButtonGroup className={'d-block'}>
                            <ButtonGroupButton
                              value={field.value}
                              onClick={e => {
                                stopEvent(e)
                                form.setFieldValue(field.name, true)
                                form.setFieldTouched(field.name, true)
                              }}
                              isActive={typeof field.value === 'boolean' ? field.value : true}
                              text={'Yes'}
                            />
                            <ButtonGroupButton
                              value={field.value}
                              onClick={e => {
                                stopEvent(e)
                                form.setFieldValue(field.name, false)
                                form.setFieldTouched(field.name, true)
                              }}
                              isActive={typeof field.value === 'boolean' ? !field.value : false}
                              text={'No'}
                            />
                          </ButtonGroup>
                          <input
                            id={'address-active'}
                            type={readOnly ? '' :'hidden'}
                            {...field}
                            value={readOnly ? field.value ? 'true' : 'false' : 'true'}
                            readOnly={readOnly}
                            className={'form-control' + (form.errors[field.name] ? ' is-invalid' : '')}
                            invalid={form.errors[field.name]}
                          />
                          <ErrorMessage name={field.name}>
                            {errorMessage => <FormFeedback className={'text-wrap'}>{errorMessage}</FormFeedback>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
          }
          {
            this.props.showAuditData === true && !isEmpty(address) &&
              <Row className={'audit-data p-2'}>
                <AuditData
                  CreateBy={address.CreateBy}
                  CreateDate={address.CreateDate}
                  UpdateBy={address.UpdateBy}
                  UpdateDate={address.UpdateDate}
                />
              </Row>
          }
          {
            !readOnly && 
              <div className={'d-flex justify-content-between'}>
                <div>
                  <Button
                    onClick={this.resetForm}
                    color={'light'}
                    size={'sm'}
                  >
                    Reset
                  </Button>
                  <PopoverButton
                    buttonClassName={'px-2 py-0'}
                    popoverHeader={'Reset Form'}
                    popoverBody={<p>
                      This will reset to the initial values if there were
                      any or clear any errors if the form was initially empty
                    </p>}
                  />
                  <Button
                    onClick={this.clearForm}
                    color={'light'}
                    size={'sm'}
                    className={'ml-2'}
                    readOnly={!this.hasValues()}
                  >
                    Clear
                  </Button>
                  <PopoverButton
                    buttonClassName={'px-2 py-0'}
                    popoverHeader={'Clear Form'}
                    popoverBody={<>
                      <p>
                        This will clear out all inputs, but some may still 
                        be required if other form data is not provided.
                      </p>
                      <b>It is only enabled when there are values entered.</b>
                    </>}
                  />
                </div>
                { this.props.actionButton }
              </div>
          }
        </>
      )}
    </Formik>
  }
}

function mapStateToProps (state) {
  const { online, } = state.offline
  return {
    online,
    addressTypes: addressTypesSelectSelector(state),
  }
}

const mapDispatchToProps = {
  CreateRecord       : ApiActions.createRecord,
  UpdateRecord       : ApiActions.updateRecord,
  Geocode            : GeoCoordinateActions.geocode,
  ClearGeocodeResult : GeoCoordinateActions.clearGeocodeResult,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(AddressForm)