// Libraries
import React, { useCallback, useEffect, useMemo, useState, } from 'react'
import { bool, } from 'prop-types'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap'
import { useSelector, } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom'

// Selectors
import { lastRouteSelector, } from '../../../selectors/appSelectors'

// Hooks
import useInterval from '../../../hooks/useInterval'


const NoPermitModal = (props) => {
  const { isOpen, } = props

  // Hooks
  const navigate = useNavigate()

  // State
  const [ seconds, setSeconds, ] = useState(5)
  const [ countdown, startCountdown, ] = useState(false)

  // Selectors
  const lastRoute = useSelector(lastRouteSelector)

  const interval = useMemo(() => countdown ? 1000 : null, [ countdown, ])

  useInterval(() => {
    const secondsRemaining = seconds - 1
    setSeconds(secondsRemaining)
    if (secondsRemaining < 1) {
      navigate(lastRoute)
    }
  }, interval)

  const okClick = useCallback(() => navigate(lastRoute), [ navigate, lastRoute, ])

  useEffect(() => {
    if (isOpen && lastRoute) {
      startCountdown(true)
    }
  }, [ startCountdown, isOpen, lastRoute, ])

  // Memoed values
  const redirectToUrl = useMemo(() => {
    let url = ''
    if (lastRoute) {
      url = window.location.origin + lastRoute
    }
    return url
  }, [ lastRoute, ])

  const secondsText = useMemo(() => {
    let text = `${seconds} second`
    if (seconds > 1) {
      text += 's'
    }
    return text
  }, [ seconds, ])

  return <Modal isOpen={isOpen}>
    <ModalBody>
      If the debris you want to burn is from an improved property, you do not need a burn permit from the
      Department of Natural Resources. You may, however, need a permit from your local clean air agency.
    </ModalBody>
    <ModalFooter>
      {
        lastRoute
          ? <Row>
            <Col xs={12}>
              <Alert color={'primary'}>
                You will now be redirected to <Link to={lastRoute}>{redirectToUrl}</Link>
                &nbsp;in {secondsText}.
              </Alert>
            </Col>
            <Col xs={12}>
              <Link to={lastRoute}>Go now</Link>
            </Col>
          </Row>
          : <Button
            color={'secondary'}
            onClick={okClick}
          >OK</Button>
      }
    </ModalFooter>
  </Modal>
}

NoPermitModal.defaultProps = {
  isOpen: false,
}

NoPermitModal.propTypes = {
  isOpen: bool,
}

export default React.memo(NoPermitModal)