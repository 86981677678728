// Libraries
import React from 'react'
import { render as ReactDOMRender, } from 'react-dom'
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from 'reactstrap'
import { bool, string, number, object, func, array,  } from 'prop-types'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'

// Components
import BurnPermitFormSection from '../FormSection'
import PileGroupForm from './Form/PileGroupForm'
import {
  AddPile,
  DeletePile,
  DeleteAllPiles,
  BatchUpload,
} from './Form/Buttons'
import DataTable from '../../DataTable'
import ExternalLink from '../../ExternalLink'
import {
  EstimateTonnage,
  PileGroupInstructions,
} from '.'
import {
  LegacyTonnage,
  NeedsPileGroups,
  AreaIsExempt,
}  from './Alerts'

// Selectors
import {
  burnPermitPileGroupsByPermitIdSelector,
} from '../../../selectors/burnPermitPileGroupsSelectors'
import { permitSectionIsActive, } from '../../../selectors/burnPermitSelectors'

// Reducers
import UiActions from '../../../redux/UiRedux'
import BurnPermitPileGroupActions from '../../../redux/BurnPermitPileGroupRedux'

// Models
import BurnPermitPileGroup from '../../../models/BurnPermitPileGroup'

// Utilities
import stopEvent from '../../../utilities/stopEvent'

const BURN_PERMIT_PILE_GROUP_VALIDATION_SCHEMA = BurnPermitPileGroup.validationSchema
const DELETE_PILE_GROUP = 'DELETE_PILE_GROUP'


class BurnPermitPileGroupsSection extends React.Component {
  constructor (props) {
    super(props)

    this.renderTableOrForm = this.renderTableOrForm.bind(this)
    this.validateSection = this.validateSection.bind(this)

    this.inputColumns = BurnPermitPileGroup.dataTableColumns({ input: true, })
    this.inputColumnDefs = [
      {
        targets     : 0,
        createdCell : (td, cellData) =>
          ReactDOMRender(
            <button
              className={'btn btn-sm fa fa-minus btn-danger'}
              title={`Delete Pile Group ${cellData}`}
              onClick={this.deletePileGroup}
              data-pile-group-id={cellData}
            />
            , td
          ),
      },
    ]
  }

  static propTypes = {
    title                     : string.isRequired,
    sectionId                 : number.isRequired,
    applicationStatus         : string,
    ActiveBurnPermitPileGroup : object,
    DeleteLocalPileGroups     : func,
    EditBurnPermitPileGroup   : func,
    OpenModal                 : func,
    BurnPermitPileGroups      : array,
    CalculatorResults         : array,
    burnPermitId              : number,
    GetBurnPileGroups         : func,
    readOnly                  : bool,
    online                    : bool,
    RequestFailed             : bool,
    PileBurnTypeIsChecked     : bool,
    TonnageIsEstimated        : bool,
    isValid                   : bool,
    needsPileGroups           : bool,
    isActive                  : bool,
    isExemptArea              : bool,
  }

  static defaultProps = {
    readOnly: false,
  }

  state = {
    pileGroupId   : -1,
    pileGroupName : '',
  }

  componentDidMount () {
    const { burnPermitId, GetBurnPileGroups, online, isActive, } = this.props
    if (online && isActive) {
      GetBurnPileGroups(burnPermitId)
    }
  }

  componentWillUnmount () {
    this.props.EditBurnPermitPileGroup()
    this.props.DeleteLocalPileGroups()
  }

  componentDidUpdate (prevProps) {
    // Don't fire off anything unless we're active
    if (!this.props.isActive) {
      return
    }

    if (prevProps.isActive !== true && this.props.isActive === true) {
      this.props.GetBurnPileGroups(this.props.burnPermitId)
    }
  }

  async validateSection () {
    const { BurnPermitPileGroups, isValid, PileBurnTypeIsChecked, isExemptArea, } = this.props
    if (PileBurnTypeIsChecked === false) {
      return true
    }
    if (BurnPermitPileGroups.length === 0 && !isExemptArea) {
      return false
    }

    try {
      if (isValid === false) {
        // If there are any errors, the validate method actually throws an exception so the errors are logged in the catch
        await Promise.all(BurnPermitPileGroups.map(g => BURN_PERMIT_PILE_GROUP_VALIDATION_SCHEMA.validate(g), []))
      }
      return isValid
    }
    catch (error) {
      console.dir(error)
      return false
    }
  }

  deletePileGroup = evt => {
    stopEvent(evt)
    const { pileGroupId, } = evt.target.dataset
    const { PileGroupName, } = this.props.BurnPermitPileGroups
      .find(g => g.BurnPermitPileGroupId.toString() === pileGroupId)
    this.setState({ pileGroupId, pileGroupName: PileGroupName, }, () => this.props.OpenModal(DELETE_PILE_GROUP))
  }

  renderTableOrForm = () => {
    let markup = []
    const {
      readOnly,
      ActiveBurnPermitPileGroup,
      BurnPermitPileGroups,
      CalculatorResults,
    } = this.props
    if (!readOnly && !isEmpty(ActiveBurnPermitPileGroup)) {
      const form = <PileGroupForm
        key={'pile-group-edit-form'}
        readOnly={readOnly}
        ActiveBurnPermitPileGroup={ActiveBurnPermitPileGroup}
      />
      markup.push(form)
    }
    else {
      const {
        needsPileGroups,
        isExemptArea,
        burnPermitId,
        TonnageIsEstimated,
      } = this.props
      markup.push(
        <div key={'pile-groups-table'}>
          {
            needsPileGroups && <Row>
              <Col>
                <NeedsPileGroups />
              </Col>
            </Row>
          }
          {
            isExemptArea && <Row>
              <Col>
                <AreaIsExempt />
              </Col>
            </Row>
          }
          {
            !readOnly && <div className={'d-flex justify-content-start'}>
              <AddPile />
              <BatchUpload
                className={'ml-2'}
                disabled={readOnly}
                permitId={burnPermitId}
              />
              <DeleteAllPiles
                className={'ml-2'}
                permitId={burnPermitId} />
            </div>
          }
          <DataTable
            columns={this.inputColumns}
            columnDefs={!readOnly ? this.inputColumnDefs : null}
            enableExport={false}
            disablePageLengthChange={true}
            onRowClick={!readOnly ? this.props.EditBurnPermitPileGroup : null}
            disableRowClick={readOnly}
            rowIdProp={'BurnPermitPileGroupId'}
            records={BurnPermitPileGroups}
            elementId={'burn-permit-pile-groups-table'}
          />
          <DeletePile
            hideButton={true}
            pileGroupId={this.state.pileGroupId}
            pileGroupName={this.state.pileGroupName}
          />
        </div>
      )
      if (CalculatorResults.length) {
        let calcMarkup
        if (CalculatorResults.some(r => r.LegacyTonnage > 0) && this.props.applicationStatus !== 'Pending') {
          calcMarkup = <div key={'estimated-consumed-tonnage'} className={'mt-5'}>
            <LegacyTonnage />
          </div>
        }
        else {
          calcMarkup = <div key={'estimated-consumed-tonnage'} className={'mt-5'}>
            <EstimateTonnage
              burnPermitId={burnPermitId}
              TonnageIsEstimated={TonnageIsEstimated}
              CalculatorResults={CalculatorResults}
            />
          </div>
        }
        markup.push(calcMarkup)
      }
    }
    return markup
  }

  render () {
    return <BurnPermitFormSection
      {...this.props}
      sectionBadge={<Badge color={'info'} className={'ml-2'}>{this.props.BurnPermitPileGroups.length}</Badge>}
      showControls={isEmpty(this.props.ActiveBurnPermitPileGroup)}
      validateSection={this.validateSection}
    >
      <Card className={'w-100'}>
        <CardBody>
          <Row>
            <Col xs={8} sm={9}>
              <p>
                The Burn Portal uses the <ExternalLink href={'https://depts.washington.edu/nwfire/piles/'} text={' Piled Fuels Biomass and Emissions Calculator'} />
                to calculate the tonnage of the forest material to be burned.
              </p>
            </Col>
            <Col xs={4} sm={3}>
              <PileGroupInstructions />
            </Col>
          </Row>
          {this.renderTableOrForm()}
        </CardBody>
      </Card>
    </BurnPermitFormSection>
  }
}

const mapStateToProps = (state, props) => {
  const { burnPermitId, sectionId, } = props
  const pileGroupState = burnPermitPileGroupsByPermitIdSelector(state, burnPermitId)
  const isActive = permitSectionIsActive(state, sectionId)
  return {
    isActive,
    ...pileGroupState,
  }
}

const mapDispatchToProps = {
  GetBurnPileGroups       : BurnPermitPileGroupActions.getBurnPileGroups,
  EditBurnPermitPileGroup : BurnPermitPileGroupActions.editBurnPermitPileGroup,
  DeleteLocalPileGroups   : BurnPermitPileGroupActions.deleteLocalPileGroups,
  OpenModal               : UiActions.openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(BurnPermitPileGroupsSection)