import React from 'react'
import { string, } from 'prop-types'
import { ErrorMessage, useFormikContext, } from 'formik'
import { FormFeedback, } from 'reactstrap'

const FormError = (props) => {
  const {
    fieldName,
  } = props
  const formik = useFormikContext()
  if (!fieldName || !formik) {
    return null
  }
  const error = formik.errors[`${fieldName}Id`]
  return <>
    <input
      type={'hidden'}
      name={`${fieldName}Id`}
      // @ts-ignore
      invalid={error ? 'true' : 'false'}
      className={'d-none form-control' + (error ? ' is-invalid' : '')}
    />
    <ErrorMessage name={`${fieldName}Id`} component={FormFeedback} />
  </>
}

FormError.propTypes = {
  fieldName: string,
}

export default React.memo(FormError)
