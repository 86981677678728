import { createActions, } from 'reduxsauce'
import BurnPermitArea from '../models/BurnPermitArea'

const BURN_PERMIT_AREA_ENDPOINT = BurnPermitArea.endpoint()


const { Types, Creators, } = createActions({
  getBurnAreaInfo             : [ 'burnPermitAreaId', ],
  updateBurnPermitArea        : [ 'burnPermitAreaInfo', ],
  updateBurnPermitAreaRequest : (burnPermitArea, showSuccess = false, calcFee = false) => ({
    type : 'UPDATE_BURN_PERMIT_AREA_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : `${BURN_PERMIT_AREA_ENDPOINT}/${burnPermitArea.BurnPermitAreaId}`,
          init : { method: 'PUT', body: { ...burnPermitArea, }, },
        },
        commit: {
          type    : 'UPDATE_BURN_PERMIT_AREA_SUCCESS',
          localId : burnPermitArea.BurnPermitAreaId,
          showSuccess,
          calcFee,
        },
        rollback: { type: 'API_FAIL', },
      },
    },
  }),
  updateBurnPermitAreaSuccess: [ 'payload', ],
})
  
export const BurnPermitAreaTypes = Types
export default Creators