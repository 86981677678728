// Libraries
import React from 'react'
import { Row, Col, Button, } from 'reactstrap'
import { number, func, bool, object, } from 'prop-types'
import { Formik, FieldArray, Form, } from 'formik'

// Components
import Effect from '../Effect'
import { ContactConditionCard, } from '.'

// Models
import { contactSchema, MAX_CONTACTS, } from '../../models/BurnPermitRequiredContactCondition'


const AddContactButton = props => {
  const { arrayHelpers, disabled, } = props
  const addContact = React.useCallback(() => arrayHelpers.push({ ContactName: '', PhoneNumber: '', PhoneExt: '', }), [ arrayHelpers, ])
  return <Button
    disabled={disabled}
    color={'success'}
    onClick={addContact}
    size={'sm'}
    title={'Add Required Contact'}
    alt={'Add Required Contact'}
  >
    Add Required Contact
  </Button>
}

AddContactButton.propTypes = {
  arrayHelpers : object,
  disabled     : bool,
}

const ContactConditionForm = (props) => {
  const { contacts, onSubmit, onDirtyChanged, } = props
  return <Formik
    initialValues={contacts}
    validationSchema={contactSchema}
    enableReinitialize={true}
    onSubmit={onSubmit}
    validateOnMount={true}
  >
    {({ values, isValid, dirty, errors, handleReset, }) => {
      const arrayError = typeof errors.Contacts === 'string' ? errors.Contacts : null
      return <Form>
        <FieldArray name={'Contacts'}>
          {(arrayHelpers) => (
            <Row>
              <Col xs={12} className={'mb-2'}>
                Add up to {MAX_CONTACTS} phone numbers for burners to contact before beginning their burn.
              </Col>
              <Col xs={12} className={'my-2'}>
                <AddContactButton
                  disabled={Array.isArray(values.Contacts) && values.Contacts.length >= MAX_CONTACTS}
                  arrayHelpers={arrayHelpers}
                />
              </Col>
              {
                values && values.Contacts && values.Contacts.map((val, idx) => {
                  return <Col 
                    xs={12} md={6} lg={4} 
                    key={`contact-cond-col-${idx}`}
                    className={'my-1'}
                  >
                    <Effect isDirty={dirty} values={val} onChange={c => {
                      onDirtyChanged(c.length > 0)
                    }} />
                    <ContactConditionCard
                      idx={idx}
                      value={val}
                      removeFunc={arrayHelpers.remove}
                    />
                  </Col>
                })
              }
              <Col xs={12}>
                {arrayError && <span className={'text-danger font-weight-bold mt-3'}>{arrayError}</span>}
              </Col>
              {
                values && values.Contacts && <>
                  <Col xs={12} md={5} className={'text-bottom vertical-align-bottom my-2'}>
                    {!isValid &&
                      <span className={'text-danger font-weight-bold mt-3'}>Validation Errors are present</span>
                    }
                    { isValid && dirty && 
                      <span className={'text-warning font-weight-bold mt-3'}>Unsaved changes</span>
                    }
                  </Col>
                  <Col xs={12} md={7} className={'d-flex justify-content-end'}>
                    <Button
                      onClick={handleReset}
                      color={'primary'}
                      className={'mr-3'}
                      disabled={!dirty}
                    >
                      Reset
                    </Button>
                    <Button
                      type={'submit'}
                      color={'secondary'}
                      key={'submit-req'}
                      disabled={!isValid || values.Contacts.length === 0 || !dirty}
                    >Save Contacts</Button>
                  </Col>
                </>
              }
            </Row>
          )}
        </FieldArray>
      </Form>
    }}
  </Formik>
}

ContactConditionForm.propTypes = {
  idx            : number,
  readOnly       : bool,
  contacts       : object,
  onSubmit       : func,
  onDirtyChanged : func,
}

ContactConditionForm.defaultProps = {
  idx            : null,
  readOnly       : true,
  contacts       : null,
  onSubmit       : () => {},
  onDirtyChanged : () => {},
}

export default ContactConditionForm