/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { isEqual, isEmpty, } from 'lodash'
import { func, array, object, string, number, bool, PropTypes, } from 'prop-types'
import { Container, Row, Col, Card, CardBody, Input, Button, } from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'
import MergeActions from '../redux/MergeRedux'
import AgencyActions from '../redux/AgencyRedux'

// Components
import { AutoComplete, PopoverButton, } from '../components/FormControls'
import { MergeForm, } from '../components/Forms'

// Selectors
import { agencyForMerge, allAgenciesForMerge, agenciesForMergeSelect, } from '../selectors/agencySelectors'

// Utilities
import stopEvent from '../utilities/stopEvent'


export class MergeAgenciesContainer extends React.Component {
  
  constructor (props) {
    super(props)

    const initialState = {
      AgencyOne       : '',
      AgencyTwo       : '',
      AgencyTwoSearch : '',
      mergedAgency    : null,
      checkAllTarget  : null,
      checkedIds      : [],
      focusField      : '',
    }

    this.initialState = { ...initialState, }
    this.state = { ...initialState, }
  }

  static propTypes = {
    history            : object,
    AgenciesForSelect  : array,
    Agencies           : array,
    SetPageTitle       : func,
    GetAllAgencies     : func,
    GetAgencyData      : func,
    MergeAgencies      : func,
    RedirectTo         : func,
    RedirectToAgencyId : PropTypes.oneOfType([ number, string, ]),
    ClearObjectIds     : func,
    AgencyOneId        : number,
    AgencyOneObj       : object,
    AgencyTwoId        : number,
    AgencyTwoObj       : object,
    SetObjectOneId     : func,
    SetObjectTwoId     : func,
    isFetchingData     : bool,
  }

  minCharactersToEnter = 3

  componentDidMount () {
    const { SetPageTitle, GetAllAgencies, SetObjectTwoId, } = this.props
    // Clear out any previously selected second object so the form does not render
    SetObjectTwoId(null)
    SetPageTitle('Merge Agencies')
    GetAllAgencies(true)
    if (!isEmpty(this.props.AgencyOneObj)) {
      const agencyName = this.props.AgencyOneObj.AgencyName.Value
      const AgencyOne = { value: this.props.AgencyOneId, label: agencyName || '', }
      this.setState({
        AgencyOne,
        AgencyOneId     : this.props.AgencyOneObj.AgencyId.Value,
        AgencyOneObj    : this.props.AgencyOneObj,
        AgencyTwoSearch : agencyName.length >= this.minCharactersToEnter
          ? agencyName.substring(0, this.minCharactersToEnter)
          : agencyName,
      })
    }
  }

  componentWillUnmount () {
    this.props.RedirectTo('')
    this.props.ClearObjectIds()
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.AgencyOneObj, this.props.AgencyOneObj) && !isEmpty(this.props.AgencyOneObj)) {
      this.updateSelectedAgency('AgencyOne', this.props.AgencyOneId, this.props.AgencyOneObj)
    }
    if (!isEqual(prevProps.AgencyTwoObj, this.props.AgencyTwoObj) && !isEmpty(this.props.AgencyTwoObj)) {
      this.updateSelectedAgency('AgencyTwo', this.props.AgencyTwoId, this.props.AgencyTwoObj)
    }
  }

  AgencyChange = evt => {
    const { value, name, } = evt.target
    const newState = { ...this.state, }
    newState[name] = value
    if (!value) {
      newState[`${name}Obj`] = null
      if (!newState.AgencyOneObj && !newState.AgencyTwoObj) {
        newState.mergedAgency = null
      }
    }
    
    this.setState(newState)
  }

  getEmptyAgency = Agency => {
    const AgencyClone = { ...Agency, }
    const keys = Object.keys(AgencyClone)
    const newAgency = { }
    for(let i = 0, len = keys.length; i < len; i++) {
      const AgencyProp = keys[i]
      const AgencyValue = AgencyClone[AgencyProp]
      newAgency[AgencyProp] = { ...AgencyValue, }
      newAgency[AgencyProp].Text = ''
      newAgency[AgencyProp].Value = ''
    }
    return newAgency
  }

  AgencyOneSelect = ({ label, value, }) => {
    this.props.GetAgencyData(value)
    this.props.SetObjectOneId(value)
    const mergedAgency = this.getEmptyAgency(this.state.mergedAgency)
    this.setState({
      AgencyOne      : { label, value, },
      mergedAgency,
      focusField     : 'AgencyTwo',
      checkAllTarget : null,
      checkedIds     : [],
    })
  }

  AgencyTwoSelect = ({ label, value, }) => {
    this.props.GetAgencyData(value)
    this.props.SetObjectTwoId(value)
    const mergedAgency = this.getEmptyAgency(this.state.mergedAgency)
    // Clear it out so it can be focused again if someone changes the first Agency input
    this.setState({
      AgencyTwo      : { label, value, }, 
      mergedAgency, 
      focusField     : '',
      checkAllTarget : null,
      checkedIds     : [],
    })
  }

  updateSelectedAgency = (AgencyKey, AgencyId, AgencyObj, forceUpdate = false)  => {
    let { mergedAgency, } = this.state
    if (isEmpty(mergedAgency) || forceUpdate) {
      mergedAgency = this.getEmptyAgency(AgencyObj)
    }

    const newState = { mergedAgency, }
    newState[`${AgencyKey}Id`] = AgencyId
    newState[`${AgencyKey}Obj`] = AgencyObj
    this.setState(newState)
  }

  setMergedValue = evt => {
    let mergedAgency = { ...this.state.mergedAgency, }
    const { id, name, value, dataset, } = evt.target
    mergedAgency[name] = { Value: value, Label: dataset.label, Text: dataset.text, }
    let checkedIds = [ ...this.state.checkedIds, ]
    const checkedInputIdx = checkedIds.findIndex(c => c.indexOf(name) > -1)
    if (checkedInputIdx > -1) {
      checkedIds.splice(checkedInputIdx, 1)
    }
    this.setState({ mergedAgency, checkAllTarget: null, checkedIds: [ ...checkedIds, id, ], })
  }

  reset = evt => {
    stopEvent(evt)
    this.setState({ ...this.initialState, }, this.props.ClearObjectIds)
  }

  resetChoices = evt => {
    stopEvent(evt)
    this.setState({ checkAllTarget: null, mergedAgency: this.getEmptyAgency(this.state.mergedAgency), checkedIds: [], })
  }

  mergeAgencies = evt => {
    stopEvent(evt)
    const { mergedAgency, AgencyOneId, AgencyTwoId, } = this.state
    
    const AgencyKeys = Object.keys(mergedAgency)
    const Agency = AgencyKeys.reduce((p, k) => {
      p[k] = mergedAgency[k].Value
      return p
    }, {})
    // Identify which Agency Id will be tagged as being merged
    // The Agency id that is not in the merged Agency object will be the one to updated to be tagged
    // as merged and will be readonly from here on out.
    const mergedAgencyId = parseInt(mergedAgency.AgencyId.Value) === parseInt(AgencyOneId) ? AgencyTwoId : AgencyOneId
    this.props.MergeAgencies(Agency, mergedAgencyId)
  }

  checkAll = evt => {
    stopEvent(evt)
    const { target, } = evt.target.dataset
    const mergedAgency = { ...this.state[target], }
    const keys = Object.keys(mergedAgency)
    // When choosing all props from one object, default any null
    // values to be empty strings
    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i]
      if (mergedAgency[key].Value !== null) {
        continue
      }
      mergedAgency[key].Value = ''
    }
    const objId = mergedAgency.AgencyId.Value
    const checkedIds = keys.map(k => `${k}-${objId}`)
    this.setState({ checkAllTarget: target, mergedAgency, checkedIds, })
  }

  mergedAgencyInput = mergedAgency => {
    let text = ''
    if (!mergedAgency) {
      return text
    }
    if (mergedAgency.AgencyId && mergedAgency.AgencyId.Value) {
      text += mergedAgency.AgencyId.Value + ': '
    }
    if (mergedAgency.AgencyName && mergedAgency.AgencyName.Value) {
      text += mergedAgency.AgencyName.Value + ' '
    }
    return text
  }

  render () {
    const {
      AgencyOne,
      AgencyTwo,
      AgencyTwoSearch,
      mergedAgency,
      checkAllTarget,
      checkedIds,
    } = this.state
    const {
      AgenciesForSelect,
      AgencyOneId,
      AgencyOneObj,
      AgencyTwoId,
      AgencyTwoObj,
      isFetchingData,
    } = this.props

    // Once all values are set, then merging is enabled
    let someValuesAreNotChosen = true
    if (mergedAgency && !isEmpty(mergedAgency)) {
      someValuesAreNotChosen = Object.entries(mergedAgency).some(e => e[1].Value === null)
    }

    if (!AgencyOne && AgencyOneId) {
      return null
    }

    return <Container className={'my-3 merge-container'}>
      <h1><Link to={'/admin/agencies'}>Agencies</Link> - Merge</h1>
      <Card>
        <CardBody>
          <p>
            Use the autocomplete controls below to locate the two Agency records you wish to merge. All related data such as Agents and Permits will be automatically merged.
            Once these records are merged, <b>they cannot be unmerged</b>.
          </p>
          <Row>
            <Col xs={4}>
              <h4>Find Agency 1</h4>
              <AutoComplete
                autoFocus={!AgencyOneObj}
                fieldName={'AgencyOne'}
                items={AgenciesForSelect.filter(p => p.Value !== AgencyTwoId)}
                value={AgencyOne}
                onChange={this.AgencyOneSelect}
                minCharactersToEnter={this.minCharactersToEnter}
              />
              {
                AgencyOneId && AgencyOneObj &&
                  <MergeForm
                    id={AgencyOneId}
                    obj={AgencyOneObj}
                    targetName={'AgencyOneObj'}
                    onChange={this.setMergedValue}
                    checkedIds={checkedIds}
                    checkAll={checkAllTarget !== null ? checkAllTarget === 'AgencyOneObj' : checkAllTarget}
                    onButtonClick={this.checkAll}
                    disabled={isFetchingData}
                    profileRoute={'/admin/agencies/'}
                  />
              }
            </Col>
            <Col xs={4}>
              <h4>Find Agency 2</h4>
              <AutoComplete
                autoFocus={!!AgencyOneObj}
                fieldName={'AgencyTwo'}
                items={AgenciesForSelect.filter(p => p.Value !== AgencyOneId)}
                value={AgencyTwo}
                searchValue={AgencyTwoSearch}
                onChange={this.AgencyTwoSelect}
                minCharactersToEnter={this.minCharactersToEnter}
                defaultMenuIsOpen={true}
              />
              {
                AgencyTwoId && AgencyTwoObj &&
                  <MergeForm
                    id={AgencyTwoId}
                    obj={AgencyTwoObj}
                    targetName={'AgencyTwoObj'}
                    onChange={this.setMergedValue}
                    checkedIds={checkedIds}
                    checkAll={checkAllTarget !== null ? checkAllTarget === 'AgencyTwoObj' : checkAllTarget}
                    onButtonClick={this.checkAll}
                    disabled={isFetchingData}
                    profileRoute={'/admin/agencies/'}
                  />
              }
            </Col>
            <Col xs={4}>
              <h4 className={'d-inline-block mb-2'}>Merged Agency</h4>
              {
                mergedAgency && <>
                  <Input
                    readOnly={true}
                    value={this.mergedAgencyInput(mergedAgency)}
                  />
                  <MergeForm
                    obj={mergedAgency}
                    showControls={false}
                    buttonText={'Clear'}
                    onButtonClick={this.resetChoices}
                    disabled={isFetchingData}
                  />
                </>
              }
            </Col>
          </Row>
          <Row className={'mt-4'}>
            <Col>
              <Button color={'link'} type={'reset'} onClick={this.reset}>Reset</Button>
              <Button disabled={someValuesAreNotChosen} onClick={this.mergeAgencies} className={'float-right'}>Merge</Button>
              {
                someValuesAreNotChosen &&
                  <PopoverButton
                    buttonClassName={'float-right'}
                    popoverHeader={'Merge Agency'}
                    popoverBody={'You must choose a value from each property of one Agency or the other, even if both properties do not have a value.'}
                  />
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  }
}

const mapStateToProps = (state) => {
  const { ObjectOneId, ObjectTwoId, isFetchingData, } = state.Merge
  return {
    isFetchingData,
    AgencyOneId       : ObjectOneId,
    AgencyTwoId       : ObjectTwoId,
    AgencyOneObj      : agencyForMerge(state, ObjectOneId),
    AgencyTwoObj      : agencyForMerge(state, ObjectTwoId),
    AgenciesForSelect : agenciesForMergeSelect(state),
    Agencies          : allAgenciesForMerge(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle   : AppActions.setPageTitle,
  RedirectTo     : AppActions.redirectTo,
  GetAllAgencies : AgencyActions.getAllAgencies,
  MergeAgencies  : AgencyActions.mergeAgencies,
  GetAgencyData  : AgencyActions.fetchAgencyDataForMerge,
  ClearObjectIds : MergeActions.clearObjectIds,
  SetObjectOneId : MergeActions.setObjectOneId,
  SetObjectTwoId : MergeActions.setObjectTwoId,
}

export default connect(mapStateToProps, mapDispatchToProps)(MergeAgenciesContainer)
