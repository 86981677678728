// Libraries
import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Container, } from 'reactstrap'
import { bool, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import PermitSearchActions from '../redux/BurnPermitSearchRedux'

// Components
import { BurnPermitTableInner, } from '../components/Search/BurnPermitTable'
import SearchFormCard from '../components/Search/BurnPermitSearchForm'

// Selectors
import { permitSearchSelector, permitsOfflineSelector, } from '../selectors/burnPermitSelectors'
import { useCart, } from '../components/Search/useCart'
import ExportPermits from '../components/ExportPermits'
import Cart from '../components/PermitOrders/Cart'

// Hooks
import { useOnlineSelector, } from '../hooks'

const LARGE_RESULT_CLEAR_MSG = 'Your search results were cleared because your search returned over '
 + '1,000 records which degrades the performance of the application. Please click the Search '
 + 'button to execute your search again.'

const BurnPermitSearchContainer = ({
  isDnr,
  isAuthenticated,
  isAdmin,
}) => {
  const reduxDispatch = useDispatch()
  const online = useOnlineSelector()
  const permits = useSelector((state) => {
    if (online) {
      return permitSearchSelector(state)
    } else {
      return permitsOfflineSelector(state)
    }
  })
  const [ cart, cartActions, order, ] = useCart()

  React.useEffect(() => {
    reduxDispatch(AppActions.setPageTitle('Permit Search'))
    reduxDispatch(PermitSearchActions.setDownloadingPermitsExport(false))
    return () => {
      if (online && reduxDispatch && permits && permits.length > 1000) {
        reduxDispatch(PermitSearchActions.resetPermitResults(LARGE_RESULT_CLEAR_MSG))
      }
      reduxDispatch(PermitSearchActions.setDownloadingPermitsExport(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showMap = useSelector((state) => {
    if (state && state.BurnPermitSearch) {
      return state.BurnPermitSearch.showMap
    }
    return false
  })


  return (
    <Container className={'mt-2 mb-4 burn-permit-search-container'}>
      <div className={'d-flex justify-content-between mb-2'}>
        <h1>Search Burn Permits</h1>
        <div className={'d-flex justify-content-between'}>
          { isAdmin && <ExportPermits />}
          { isDnr && <Cart cart={cart} cartActions={cartActions} /> }
        </div>
      </div>
      <SearchFormCard isAuthenticated={isAuthenticated} />
      <BurnPermitTableInner
        permits={permits}
        enableCart={isDnr}
        showMap={showMap}
        showCardHeader={false}
        showLandownerAgent={isAuthenticated}
        showExport={isAdmin}
        isAuthenticated={isAuthenticated}
        cart={cart}
        cartActions={cartActions}
        order={order}
        layerConfig={'Permit Search'}
      />
    </Container>
  )
}

BurnPermitSearchContainer.propTypes = {
  isDnr           : bool,
  isAuthenticated : bool,
  isAdmin         : bool,
}

export default BurnPermitSearchContainer