import { attr, Model, } from 'redux-orm'

export default class BurnType extends Model {
  static get modelName () {
    return 'BurnType'
  }

  toString () {
    return `${this.BurnTypeName}`
  }

  static endpoint () {
    return 'BurnTypes'
  }

  static get options () {
    return  {
      idAttribute: 'BurnTypeId',
    }
  }

  static get fields () {
    return {
      BurnTypeId          : attr({ type: 'number', }),
      BurnTypeName        : attr(),
      BurnTypeDescription : attr(),
      CreateDate          : attr(),
      UpdateDate          : attr(),
    }
  }
}