// Libraries
import { createSelector, } from 'reselect'

// Selectors
import { authPropsSelector, } from './userSelectors'

// Utilities
import { dateFormatter, pacificTimeZoneDate, } from '../utilities'
import { formatLegalDesc, } from '../utilities/columnHelpers'

// Map Config
import { GetRequestSymbolByStatus, } from '../config/map/symbols'
import { burnRequestAction, } from '../config/map/actions'


export const calendarStateSelector = (state) => {
  return state && state.Calendar ? state.Calendar : {}
}

const calendarEventsSelector = createSelector(
  calendarStateSelector,
  calendar => calendar ? calendar.events || [] : []
)

const mapBurnRequestsForCalendar = (multiBurnReqEvents) => (r) => {
  // Javascript assumes all dates are tracked in UTC
  // The ignition and request dates we get from the API have zeroes set for the hours, minutes seconds
  // but it does not have any associated timezone information
  // Since we're 8 hours behind, Safari is displaying the literal UTC time for midnight UTC of the date,
  // which is actually 4 PM the day before in our local time zone
  // startDate.setHours(startDate.getHours() + startDate.getTimezoneOffset() / 60)
  const [ hours, minutes, seconds, ] = r.PlannedIgnitionTime.split(':')
  // To display the correct date value and have the events display on the correct day,
  // we must update the hours to compensate for the timezone offset, which is returned in minutes
  const startDate = pacificTimeZoneDate(r.PlannedIgnitionDate, hours, minutes, seconds)
  const endDate = new Date(startDate)
  // We do not need to manipulate the end date since it is built off of the updated startDate
  endDate.setMinutes(endDate.getMinutes() + r.PlannedIgnitionPeriod)

  const reqDate = new Date(r.BurnRequestDate)
  // Similarly update the request date to handle the timezone offset
  reqDate.setHours(reqDate.getHours() + reqDate.getTimezoneOffset() / 60)

  let color, badgeClass, textColor = '#fff'
  switch(r.BurnRequestStatus) {
  case 'Submitted':
    badgeClass = 'dark'
    color = '#003d6b'
    break
  case 'Info Required':
  case 'Under Review':
    badgeClass = 'secondary'
    color = '#996007'
    break
  case 'Denied':
    badgeClass = 'danger'
    color = '#EA1B0B'
    break
  case '[Division] Approved':
  case '[Region] Approved':
  case 'Approved':
    badgeClass = 'success'
    color = '#53ae76'
    break
  case 'Cancelled':
  default:
    badgeClass = 'light'
    color = '#eee'
    textColor = '#212121'
    break
  }

  // Default to showing the permit number for all users
  let prefix = r.BurnPermitNumber

  // Authenticated users can access the Agent and Landowner names
  // Prioritize displaying the Agent name
  if (r.Agent.trim() !== '') {
    prefix = r.Agent.trim()
  }
  // But if there is no Agent name but there is a Landowner
  // name, then show that
  else if (r.Landowner.trim() !== '') {
    prefix = r.Landowner.trim()
  }

  const eventDetails = [
    { Label: 'Burn Request ID', Value: r.BurnRequestId, },
    { Label: 'Burn Permit #', Value: r.BurnPermitNumber, },
    { Label: 'Unit Name', Value: r.BurnUnitName, },
    { Label: 'Planned Ignition Date', Value: dateFormatter(startDate, 'ddd MMM DD, YYYY'), },
    { Label: 'Planned Ignition Time', Value: r.PlannedIgnitionTime, },
    { Label: 'Planned Ignition Period', Value: `${(r.PlannedIgnitionPeriod || '').toLocaleString()} minutes`, },
    { Label: 'Burn Type(s)', Value: r.BurnType, },
    { Label: 'Burn Request Date', Value: dateFormatter(reqDate, 'ddd MMM DD, YYYY'), },
    { Label: 'Permitted Burn Area', Value: `${(r.BurnAcres || '').toLocaleString()} acres`, },
    { Label: 'Requested Burn Area', Value: `${(r.ProposedBurnArea || '').toLocaleString()} acres`, },
    { Label: 'Permitted Tonnage', Value: `${(r.TotalPermitTonnage || '').toLocaleString()} tons`, },
    { Label: 'Proposed Burn Quantity', Value: `${(r.TotalProposedBurnQuantity || '').toLocaleString()} tons`, },
    { Label: 'Lat, Long', Value: `${r.Latitude}, ${r.Longitude}`, },
  ]

  // If the prefix is different than the permit number, it means the user
  // has access to see the Agent and Landowner info, so make sure those
  // fields are visible in the event details
  const showLandownerAgent = prefix !== r.BurnPermitNumber
  if (showLandownerAgent) {
    eventDetails.splice(eventDetails.findIndex(e => e.Label === 'Burn Permit #'), 0, { Label: 'Landowner', Value: r.Landowner, })
    eventDetails.splice(eventDetails.findIndex(e => e.Label === 'Burn Permit #'), 0, { Label: 'Agent', Value: r.Agent, })
  }

  let icon = '', iconText = ''
  if (r.IsUGA) {
    icon = 'fas fa-city'
    iconText = 'Burn is within a UGA'
  }
  else if (r.IsForestHealthExempt) {
    icon = 'fas fa-tree'
    iconText = 'Burn has a Forest Health Exemption'
  }

  let multiBurn = ''
  
  // given multi-day events come in as one, append to array with updated events (will not be iterated over)
  if (r.MultiDayBurn) {
    multiBurn = 'fas fa-m'
    r.PlannedDays = []
    // mock Planned Days until API is upated.
    // let test_date = new Date(startDate)
    // r.PlannedDays.push(test_date.setDate(test_date.getDate() + 1))
    if (r.PlannedDays.length > 0) {
      r.PlannedDays.forEach(day => {
        let multiStartDate = new Date(day)
        let multiEndDate = new Date(day)
        multiEndDate.setHours(multiEndDate.getHours() + r.PlannedIgnitionTime)
        let multiEventDetails = [ ...eventDetails, ]
        multiEventDetails[3] = { Label: 'Planned Ignition Date', Value: dateFormatter(multiStartDate, 'ddd MMM DD, YYYY'), }
        const newEvent = {
          title     : `${prefix} - ${(r.TotalProposedBurnQuantity || '').toLocaleString()} tons`,
          start     : multiStartDate,
          end       : multiEndDate,
          color     : color,
          textColor : textColor,
          // These are available in the `event` object under `event.extendedProps`
          Id        : r.BurnRequestId,
          icon      : icon,
          iconText  : iconText,
          multiBurn : multiBurn,
          EventLink : {
            Url  : `/burn-requests/${r.BurnRequestId}`,
            Text : 'View Burn Request',
          },
          Status: {
            Value : r.BurnRequestStatus || 'Unknown',
            Color : badgeClass,
          },
          eventDetails: multiEventDetails,
        }
        multiBurnReqEvents.push(newEvent)
      })
      
    }
  }

  return {
    title     : `${prefix} - ${(r.TotalProposedBurnQuantity || '').toLocaleString()} tons`,
    start     : startDate,
    end       : endDate,
    color     : color,
    textColor : textColor,
    // These are available in the `event` object under `event.extendedProps`
    Id        : r.BurnRequestId,
    icon      : icon,
    iconText  : iconText,
    multiBurn : multiBurn,
    EventLink : {
      Url  : `/burn-requests/${r.BurnRequestId}`,
      Text : 'View Burn Request',
    },
    Status: {
      Value : r.BurnRequestStatus || 'Unknown',
      Color : badgeClass,
    },
    eventDetails,
  }
}

export const calendarDataSelector = createSelector(
  calendarEventsSelector,
  (calendarData) => {
    return calendarData.map(mapBurnRequestsForCalendar(calendarData))
  }
)

export const calendarMapDataSelector = createSelector(
  (_, navigate) => navigate,
  calendarEventsSelector,
  authPropsSelector,
  (navigate, calendarData, { isAuthenticated = false, }) => {

    const fields = [
      { name: 'BurnPermitNumber', label: 'Burn Permit Number', },
      { name: 'Status', label: 'Status', },
      { name: 'StatusDate', label: 'Status Date', },
      { name: 'MultiDay', label: 'Multi Day Burn', },
      { name: 'HasPostBurn', label: 'Has Burned', },
      { name: 'BurnTypes', label: 'Burn Types', },
      { name: 'Address', label: 'Address', },
      { name: 'LegalDesc', label: 'Legal Desc.', },
      { name: 'HarvestAcres', label: 'Harvest Acres', },
      { name: 'ExpirationDate',label: 'Expiration Date', },
      { name: 'RequestedAcres', label: 'Requested Acres', },
      { name: 'TotalPermitTonnage', label: 'Est. Permit Tonnage', },
      { name: 'RequestedTons', label: 'Requested Tons', },
      { name: 'PlannedIgnitionDate', label: 'Planned Ignition Date', },
      { name: 'PlannedIgnitionTime', label: 'Planned Ignition Time', },
      { name: 'IsUGA', label: 'Is in UGA', },
      { name: 'IsForestHealthExempt', label: 'Is Forest Health Exempt', },
      { name: 'UnitName', label: 'Burn Unit Name', },
      { name: 'LatLong', label: 'Lat, Long', },
    ]

    if (isAuthenticated) {
      const landowner = { name: 'Landowner', label: 'Landowner', }
      const agent = { name: 'Agent', label: 'Agent', }
      fields.splice(fields.findIndex(c => c.name === 'HasPostBurn'), 0, landowner)
      fields.splice(fields.findIndex(c => c.name === 'HasPostBurn'), 0, agent)
    }

    const layer = {
      layerId    : 'BurnRequests',
      layerTitle : 'Burn Requests',
      data       : [],
      fields,
      idField    : 'BurnRequestId',
      title      : 'Burn Request #{BurnRequestId}',
    }

    let data = calendarData.reduce((prev, curr) => {
      if (!!curr.Longitude && !!curr.Latitude) {
        prev.push(curr)
      }
      return prev
    }, [])

    if (data.length <= 0) {
      return [ layer, ]
    }
    
    for (let i = 0, len = data.length; i < len; i++) {
      const row = data[i]
      const {
        Latitude,
        Longitude,
        BurnPermitId,
        BurnPermitNumber,
        Address,
        BurnAcres,
        BurnTypes,
        BurnUnitName,
        BurnRequestId,
        BurnPermitExpirationDate,
        PlannedIgnitionDate,
        PlannedIgnitionTime,
        TotalPermitTonnage,
        RequestedAcres,
        RequestedTons,
        BurnRequestStatus,
        BurnRequestStatusDate,
        IsUGA,
        IsForestHealthExempt,
      } = row

      let Agent = '', Landowner = ''
      if (isAuthenticated) {
        Agent = row.Agent
        Landowner = row.Landowner
      }
      
      const symbol = GetRequestSymbolByStatus(BurnRequestStatus, IsUGA)
      layer.data.push({
        geometry: {
          type      : 'point',
          latitude  : Latitude,
          longitude : Longitude,
        },
        attributes: {
          BurnRequestId        : BurnRequestId.toString(),
          BurnPermitId         : BurnPermitId.toString(),
          Agent,
          Landowner,
          BurnPermitNumber,
          BurnTypes,
          Address,
          PlannedIgnitionDate  : dateFormatter(PlannedIgnitionDate, 'MMM DD, YYYY'),
          PlannedIgnitionTime,
          RequestedAcres,
          TotalPermitTonnage,
          RequestedTons,
          UnitName             : BurnUnitName,
          LegalDesc            : formatLegalDesc(row),
          Status               : BurnRequestStatus,
          StatusDate           : dateFormatter(BurnRequestStatusDate, 'MMM DD, YYYY'),
          ExpirationDate       : dateFormatter(BurnPermitExpirationDate, 'MMM DD, YYYY'),
          HarvestAcres         : BurnAcres,
          IsUGA                : IsUGA ? 'Yes' : 'No',
          IsForestHealthExempt : IsForestHealthExempt === null ? 'N/A' : IsForestHealthExempt ? 'Yes' : 'No',
          LatLong              : `${Latitude}, ${Longitude}`,
        },
        symbol,
      })
    }
    layer.actions = [ burnRequestAction.bind({ navigate, })(), ]
    return [ layer, ] 
  }
)

