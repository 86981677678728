import { createSelector, } from 'reselect'

// Map Layers
import DEFAULT_FEATURE_LAYERS from '../config/map/featureLayers'
import DEFAULT_GEOJSON_LAYERS from '../config/map/geojsonLayers'



/*** State Selectors */

const mapState = state => state.Map || {}

export const mapStateSelector = createSelector(
  mapState,
  state => state
)

export const latLongSelector = createSelector(
  mapStateSelector,
  state => ({ ...state.LatLong, })
)

export const layerConfigsSelector = createSelector(
  mapState,
  state => {
    return state.componentLayerConfigs || []
  }
)

const featureLayerIds = () => DEFAULT_GEOJSON_LAYERS
  .map(l => {
    const layer = l()
    return { Text: layer.title, Value: layer.id, readOnly: false, }
  })

const featureLayersSelector = createSelector(
  featureLayerIds,
  layerIds => layerIds
)

const geojsonLayerIds = () => DEFAULT_FEATURE_LAYERS
  .map(l => ({ Text: l.title, Value: l.id, readOnly: false, }))

const geojsonLayersSelector = createSelector(
  geojsonLayerIds,
  layerIds => layerIds
)

export const mapLayerIdsSelector = createSelector(
  featureLayersSelector,
  geojsonLayersSelector,
  (featureLayerIds, geojsonLayerIds) => []
    .concat(
      featureLayerIds.reverse(),
      geojsonLayerIds.reverse(),
    )
    // Create a copy of the items so any mutatation do not leak across calls to this selectors
    .map(l => ({ ...l, }))
)

const activeMapIdSelector = createSelector(
  mapStateSelector,
  state => state.activeMapId
)

export const hiddenLayerIdsSelector = createSelector(
  layerConfigsSelector,
  activeMapIdSelector,
  (layerConfigs, configName) => {
    const config = layerConfigs.find(c => c.name === configName)
    if (!config) {
      return []
    }
    return [ ...config.hiddenLayers, ]
  }
)
