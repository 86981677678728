// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEmpty, isEqual, } from 'lodash'
import { array, func, number, bool, string, object, } from 'prop-types'
import { Row, Col, Card, CardBody, Button, CardText, Label, Badge, } from 'reactstrap'

// Reducers
import BurnPermitFormActions from '../../redux/BurnPermitFormRedux'
import ApiActions from '../../redux/ApiRedux'

// Components
import { BurnPermitFormSection, } from '.'
import AuditData from '../AuditData'
import Upload from '../Upload'
import {
  ForestHealthDocAlert,
  PileLocationDocAlert,
  TonnageCalculationDocAlert,
} from '../Alerts'
import { Select, PopoverButton, DownloadAllPermitDocuments, } from '../FormControls'
import ConfirmationModal from '../ConfirmationModal'

// Selectors
import {
  permitApplicationDocuments,
  documentTypesForSelect,
  documentsSectionIsValid,
  permitCanAcceptDocumentUploads,
} from '../../selectors/burnPermitSelectors'
import { networkStateSelector, } from '../../selectors/selectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'


class ApplicationDocuments extends React.Component {
  
  constructor (props) {
    super(props)
    this.validateSection = this.validateSection.bind(this)
  }

  static propTypes = {
    title                          : string,
    burnPermitId                   : number,
    Documents                      : array,
    DocumentTypes                  : array,
    UploadFiles                    : func,
    DownloadFile                   : func,
    DeleteFile                     : func,
    UpdateDocType                  : func,
    Failure                        : func,
    CheckIfFilesAreAlreadyAttached : func,
    online                         : bool,
    canUpload                      : bool,
    DocInfo                        : object,
    sectionValidationInfo          : object,
  }

  static defaultProps = {
    Documents: [],
  }

  state = {
    errorMessage : '',
    badgeClass   : 'info',
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.Documents, this.props.Documents)) {
      this.setState({ badgeClass: 'secondary', })
      setTimeout(() => this.setState({ badgeClass: 'info', }), 1000)
    }
  }

  downloadDocument = (evt, document) => {
    stopEvent(evt)

    this.props.DownloadFile(this.props.burnPermitId, document.DocumentName, document.BurnPermitDocumentId)
  }

  deleteDocument = (evt, documentId) => {
    stopEvent(evt)

    this.props.DeleteFile(this.props.burnPermitId, documentId)
  }
  
  setDocumentType = (evt, docId) => {
    stopEvent(evt)
    const { value, } = evt.target
    if (parseInt(value) < 1) {
      this.setState({ errorMessage: 'You must choose a Document Type.', })
      return
    }
    if (this.state.errorMessage) {
      this.setState({ errorMessage: '', })
    }
    this.props.UpdateDocType(this.props.burnPermitId, docId, evt.target.value)
  }

  uploadFiles = files => this.props.UploadFiles(this.props.burnPermitId, files)
  
  uploadMailedAppDocAnyways = () => {
    if (!isEmpty(this.props.DocInfo)) {
      this.props.UpdateDocType(this.props.burnPermitId, this.props.DocInfo.documentId, this.props.DocInfo.documentTypeId, true)
    }
    else {
      this.props.Failure('Could not determine the document information to update the document type. Please contact support.')
    }
  }

  async validateSection ({ evt, }) {
    if (evt) {
      stopEvent(evt)
    }
    
    return this.props.sectionValidationInfo.isValid
  }

  render () {
    const { Documents, online, canUpload, sectionValidationInfo, } = this.props
    return <BurnPermitFormSection
      {...this.props}
      validateSection={this.validateSection}
      isValid={sectionValidationInfo.isValid}
      sectionBadge={<Badge className={'ml-2'} color={this.state.badgeClass}>{Documents.length}</Badge>}
    >
      <Col xs={12}>
        <Row>
          <Col xs={10}>
            <p>
              Use the control below to upload supplemental documentation for your application.
              <br/>
              This may include Burn Plans, Pile Calculator results, or .zip archives of .shp, .kmz, or other spatial data with boundary geometries of your burn locations.
            </p>
          </Col>
          <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
            <DownloadAllPermitDocuments burnPermitId={this.props.burnPermitId} />
          </Col>
        </Row>
      </Col>
      {
        !sectionValidationInfo.isValid
          ? <>
            {
              sectionValidationInfo.needsForestDoc && <Col xs={12}><ForestHealthDocAlert /></Col>
            }
            {
              sectionValidationInfo.needsPileLocDoc && <Col xs={12}><PileLocationDocAlert /></Col>
            }
            {
              sectionValidationInfo.needsTonnageDocs && <Col xs={12}><TonnageCalculationDocAlert /></Col>
            }
          </>
          : null
      }
      {
        !online
          ? <Col xs={12}>
            <Row>
              <div className={'m-4'}>
                <b>Uploading a document, updating the document type, downloading the document, and deleting the document can only be performed while connected to the internet.</b>
              </div>
            </Row>
          </Col>
          : canUpload
            ? <Col xs={12} sm={6} md={4} className={'mb-2'}>
              <Upload
                title={'Upload Files'}
                uploadFiles={this.uploadFiles}
                onFileSelected={this.props.CheckIfFilesAreAlreadyAttached}
                multiple
              />
              <ConfirmationModal
                modalKey={'UPLOAD_MAILED_APP_DOC'}
                modalTitle={'Upload Mailed Application'}
                modalBody={<>
                  <p>Scans of the Mailed Application should be uploaded in the Signature Section when the application entry is complete and you are ready to sign the application for the applicant.</p>
                  <p>Click <b>Cancel</b> to cancel modifying the document type now and instead click the Delete button on this document then navigate to the Signature Section to upload the document there.</p>
                  <p>Click <b>Continue</b> to modify the document type anyways. However, you will have to delete this document later when you upload the document in the Signature Section when signing the application for the applicant.</p>
                </>}
                submitActionLabel={'Continue'}
                submitAction={this.uploadMailedAppDocAnyways}
                submitArgs={[ true, ]}
              />
            </Col>
            : <Col xs={12}>
              <Row>
                <div className={'m-4'}>
                  <b>This Permit Application is no longer active or valid and is not allowed to have documents uploaded.</b>
                </div>
              </Row>
            </Col>
      }
      {
        Documents.length === 0
          ? <Col xs={12} sm={6} md={4} className={'mb-2'}><Card><CardBody><p>No documents found.</p></CardBody></Card></Col>
          : Documents.map(d => {
            let documentTypes = [ ...this.props.DocumentTypes, ]
            if (!d.ReadOnly) {
              documentTypes = documentTypes.filter(t => !t.ReadOnly)
            }
            return <Col xs={12} sm={6} md={4} key={`${d.DocumentName}${d.BurnPermitDocumentId}`} className={'mb-2'}>
              <Card className={'download-document-row'}>
                <CardBody>
                  <Row className={'mb-4'}>
                    <Col>
                      <Label className={'d-block'}>Document Name</Label>
                      <CardText className={'pl-2 font-weight-bold'}>{d.DocumentName}</CardText>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        items={documentTypes}
                        label={'Document Type'}
                        propertyName={'BurnPermitDocumentTypeId'}
                        readOnly={!online || d.ReadOnly}
                        selectedValue={d.BurnPermitDocumentTypeId}
                        onChange={evt => this.setDocumentType(evt, d.BurnPermitDocumentId)}
                        errorMessage={this.state.errorMessage}
                        className={'w-100'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <AuditData {...d} />
                  </Row>
                  <Row className={'mt-4'}>
                    <Col>
                      <Button
                        color={'link'}
                        title={`Download ${d.DocumentName}`}
                        className={'btn-sm'}
                        onClick={evt => this.downloadDocument(evt, d)}
                        disabled={!online}
                      >
                        <span className={'fa fa-cloud-download-alt mr-2'}></span>Download
                      </Button>
                    </Col>
                    {
                      online && 
                      <Col>
                        <Button
                          color={'danger'}
                          title={`Delete ${d.DocumentName}`}
                          className={'btn-sm float-right'}
                          onClick={evt => this.deleteDocument(evt, d.BurnPermitDocumentId)}
                          disabled={!online || d.ReadOnly}
                        >
                          <span className={'fa fa-trash-alt mr-2'}></span>Delete
                        </Button>
                        { d.ReadOnly &&
                          <PopoverButton
                            buttonClassName={'py-0'}
                            popoverHeader={'Cannot Delete Document'}
                            popoverBody={<>
                              <p>
                              Some documents will become readonly once the Application or Permit advances to the next status.
                              For example, any document(s) uploaded while filling out an application, then the application is submitted, all <b>Pending</b> docs are now readonly.
                              </p>
                              <p>
                              Documents uploaded during the <b>Submitted</b>, <b>Info Required</b>, and <b>Under review</b> statuses can be modified or deleted when the application is in any of those statuses.
                              </p>
                              <p>
                              Once the Permit is <b>Issued</b>, all documents uploaded to the application will then become readonly.
                              Any document uploaded after the Permit is <b>Issued</b> will be modifiable or deletable until the Permit <b>Expires</b> or is <b>Revoked</b>.
                              </p>
                              <p>
                              Once the Permit becomes <b>Expired</b> or <b>Revoked</b>, all documents become readonly.
                              </p>
                            </>}
                          />
                        }
                      </Col>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          })
      }
    </BurnPermitFormSection>
  }
}

const mapStateToProps = (state, props) => {
  const { burnPermitId, } = props
  const { online, } = networkStateSelector(state)
  const canUpload = online && permitCanAcceptDocumentUploads(state, burnPermitId)
  return {
    online,
    canUpload,
    sectionValidationInfo : documentsSectionIsValid(state, burnPermitId),
    Documents             : permitApplicationDocuments(state, burnPermitId),
    DocumentTypes         : documentTypesForSelect(state),
    DocInfo               : { ...state.BurnPermitForm.docTypeInfo, },
  }
}

const mapDispatchToProps = {
  UploadFiles                    : BurnPermitFormActions.uploadPermitFiles,
  DownloadFile                   : BurnPermitFormActions.downloadPermitFile,
  DeleteFile                     : BurnPermitFormActions.deletePermitFile,
  UpdateDocType                  : BurnPermitFormActions.updateDocumentType,
  CheckIfFilesAreAlreadyAttached : BurnPermitFormActions.checkIfFilesAreAlreadyAttached,
  Failure                        : ApiActions.failure,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDocuments)