// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  CardHeader,
} from 'reactstrap'
import { func, object, bool, number, string, shape, arrayOf, } from 'prop-types'
import { connect, } from 'react-redux'
import { isEqual, } from 'lodash'

// Reducers
import ConditionActions from '../redux/BurnPermitConditionsRedux'
import AppActions from '../redux/AppRedux'
import ApiActions from '../redux/ApiRedux'

// Selectors
import {
  permitApplicationIsSigned,
  permitIsIssued,
  burnPermitNumberById,
  permitIsExpiredSelector,
} from '../selectors/burnPermitSelectors'
import { appIsOnlineSelector, } from '../selectors/selectors'
import { isLoadingDocuSignUrl, } from '../selectors/burnPermitSignatureSelectors'
import { contactConditionForPermitSelector, } from '../selectors/conditionsSelectors'
import { userCanEditPermitConditions, } from '../selectors/burnPermitPermissionSelectors'

// Components
import ExternalLink from '../components/ExternalLink'
import { IssuePermit, PreviewPermit, } from '../components/Modals'
import PopoverButton from '../components/FormControls/PopoverButton'
import { ContactConditionList, ContactConditionForm, PermitConditions, } from '../components/Conditions'
import EditContactsAndConditions from '../components/Conditions/EditContactsAndConditions'
import withRouter from '../components/withRouter'



const ConditionWarning = <Row>
  <Col xs={12}><p>1. This permit is not valid until fees are paid and permittee receives their validated permit. Permit may be suspended or modified any time when deemed necessary for the protection of life, property, air quality, or violation of permit conditions.</p></Col>
  <Col xs={12}><p>2. Validated permit and any addendums/attachments must be on site while burning.</p></Col>
  <Col xs={12}><p>3. Permittee shall comply with all requirements of Chapter 332-24 WAC, the Smoke Management Plan in effect at the time of burning, and any additional terms and conditions specified in writing by the Department.</p></Col>
  <Col xs={12} className={'text-danger'}><p>4. Permittee, by igniting a fire pursuant to this permit, accepts all responsibility for fire suppression costs that result from a violation of any of the conditions of this permit. If the fire escapes, regardless of cause, the permittee shall be responsible for paying for people and equipment for fire suppression as required by Chapter 76.04 RCW.</p></Col>
  <Col xs={12} className={'text-danger'}><p>5. Permittee shall call <b>1-800-323-BURN (2876)</b> or visit <b><ExternalLink href={'https:/fortress.wa.gov/dnr/protection/firedanger/'} text={'https:/fortress.wa.gov/dnr/protection/firedanger/'} /></b> each day of ignition prior to lighting any fire. The instructions provided for the county you are burning in, including fire danger and air quality burn bans, become a condition to this permit.</p></Col>
  <Col xs={12}><p>6. No prohibited material shall be burned. Prohibited materials include: Garbage, dead animals, asphalt, petroleum products, paints, rubber products, plastics, treated wood, metal or any substance other than natural vegetation, which when burned releases toxic emissions, dense smoke, or odors.</p></Col>
  <Col xs={12}><p>7. Fire ignited pursuant to this permit shall not create a nuisance from smoke, obscure visibility on public roads and highways, or endanger life and property through negligent spread of fire or pollutants.</p></Col>
  <Col xs={12}><p>8. Permittee shall comply with the following checked conditions:</p></Col>
</Row>

const SaveCancelButtons = props => {
  return <>
    {
      typeof props.onCancelClick === 'function'
        ? <Button
          color={'light'}
          size={'md'}
          className={'mr-2'}
          onClick={props.onCancelClick}
        >
          Cancel
        </Button>
        : null
    }
    <Button
      color={'secondary'}
      size={'md'}
      onClick={props.onSaveClick}
    >
      Save
    </Button>
  </>
}

SaveCancelButtons.propTypes = {
  onCancelClick : func,
  onSaveClick   : func,
}

SaveCancelButtons.defaultProps = {
  onSaveClick: () => {},
}

export class PermitConditionsContainer extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      contactsAreDirty : false,
      editIssuedPermit : false,
    }

    const { SetPageTitle, } = this.props
    const { params, } = this.props

    SetPageTitle(`Burn Permit ${params.BurnPermitId} Conditions`)
    const hasId = params.BurnPermitId && !isNaN(params.BurnPermitId)
    if (hasId) {
      this.props.ConditionSetPermit(params.BurnPermitId)
    }
  }

  static propTypes = {
    // from withRouter HOC
    params: object,

    burnPermitId          : number,
    // selector value
    BurnPermitNumber      : string,
    isIssued              : bool,
    isSigned              : bool,
    isExpired             : bool,
    applicationStatus     : object,
    online                : bool,
    IsLoadingDocuSignUrl  : bool,
    // dispatch
    ConditionSetPermit    : func,
    SetActiveConditions   : func,
    SetPageTitle          : func,
    Failure               : func,
    SetRequiredContacts   : func,
    userCanEditConditions : bool,
    ConditionContacts     : shape({
      Contacts: arrayOf(object),
    }),
  }

  static defaultProps = {
    // selector
    userCanEditConditions : false,
    // dispatch 
    ConditionSetPermit    : null,
    SetActiveConditions   : null,
    SetPageTitle          : null,
    // route
    params                : null,
    ConditionContacts     : {
      Contacts: [],
    },
  }

  componentWillUnmount () {
    this.props.ConditionSetPermit()
  }

  componentDidUpdate (oldProps) {
    const { params, } = this.props
    const paramsChanged = !isEqual(oldProps.params, params)

    if (paramsChanged) {
      const hasId = params && !isNaN(params.BurnPermitId)
      if (hasId) {
        this.props.ConditionSetPermit(params.BurnPermitId)
      }
    }
  }

  submit = (updateIssuedConditions) => {
    const { selectedConditionValidity, } = this.state
    if (selectedConditionValidity && Object.values(selectedConditionValidity).includes(false)) {
      this.props.Failure('You must address all validation errors before you can save the conditions.')
    } else {
      this.props.SetActiveConditions(this.state.selectedConditionValues, updateIssuedConditions)
    }
  }

  submitContacts = (contacts) => {
    this.setState({ contactsAreDirty: false, }, () => {
      this.props.SetRequiredContacts(contacts, this.state.editIssuedPermit)
    })
  }

  onValuesChanged = (newValues) => {
    if (!newValues) {
      return
    }
    this.setState({ selectedConditionValues: newValues, })
  }

  onValidityChanged = (newValid) => {
    if (!newValid) {
      return
    }
    this.setState({ selectedConditionValidity: newValid, })
  }

  onContactChange = (isDirty) => {
    if (isDirty !== this.state.contactsAreDirty) {
      this.setState({ contactsAreDirty: isDirty, })
    }
  }

  toggleEditingContactsAndConditions = () => this.setState({ editIssuedPermit: !this.state.editIssuedPermit, })

  updateContactsAndConditions = () => {
    const updateIssuedConditions = this.state.editIssuedPermit
    this.setState({ editIssuedPermit: false, }, () => this.submit(updateIssuedConditions))
  }

  cancelEditingContactsAndConditions = () => {
    this.setState({ editIssuedPermit: false, selectedConditionValues: null, })
  }

  render () {
    const {
      ConditionContacts,
      isSigned,
      isIssued,
      isExpired,
      applicationStatus,
      online,
      userCanEditConditions,
      burnPermitId,
      BurnPermitNumber,
      IsLoadingDocuSignUrl,
    } = this.props

    const conditionsAreEditable = !(isIssued && isSigned)
    let readOnly = !online || !userCanEditConditions || !conditionsAreEditable || IsLoadingDocuSignUrl
    if (online && this.state.editIssuedPermit) {
      readOnly = false
    }
    let btns
    if (userCanEditConditions && (isIssued || conditionsAreEditable) && !isExpired) {
      if (!isIssued || (isIssued && this.state.editIssuedPermit)) {
        btns = <SaveCancelButtons
          onSaveClick={this.updateContactsAndConditions}
          onCancelClick={this.state.editIssuedPermit ? this.cancelEditingContactsAndConditions : null}
        />
      }
      else {
        btns = <EditContactsAndConditions
          online={online}
          submitAction={this.toggleEditingContactsAndConditions}
        />
      }
    }

    return (
      <Container className={'pt-2 pb-4'}>
        <Row>
          <Col xs={10}>
            <h1>
              <Link to={`/permits/${this.props.burnPermitId}`}>Burn Permit {!conditionsAreEditable ? `Application ${burnPermitId}` : BurnPermitNumber}</Link> - Permit Conditions
            </h1>
          </Col>
          {
            btns && <Col xs={2} className={'d-flex justify-content-end'}>{btns}</Col>
          }
        </Row>
        {
          applicationStatus &&
            <>
              <h2 style={{ fontSize: '1.5rem', }}>
                {applicationStatus.Status} {isSigned && '(Signed)'}
                <Button
                  color={'link'}
                  id={'po-status-description'}
                >
                  <span className={'fa fa-info'} title={`Application Status: ${applicationStatus.Status} ${isSigned && '(Signed)'}}`}></span>
                </Button>
                <PopoverButton
                  placement={'bottom'}
                  popoverClassName={'popover-med-width'}
                  popoverHeader={'Status Description'}
                  popoverBody={<>
                    <p><b>{applicationStatus.Status}</b>: {applicationStatus.StatusDescription}</p>
                    {
                      isSigned &&
                      <p>This application has been digitally signed.
                        See the Signature section of the permit application for more information.</p>
                    }
                  </>
                  }
                />
              </h2>
            </>
        }
        <Row>
          <Col>
            {
              !readOnly &&
                <Card className={'my-3'}>
                  <CardHeader tag={'h2'}>
                    Required Contacts
                  </CardHeader>
                  <CardBody className={'mx-3'}>
                    <ContactConditionForm
                      contacts={ConditionContacts}
                      onDirtyChanged={this.onContactChange}
                      onSubmit={this.submitContacts}
                    />
                  </CardBody>
                </Card>
            }
            <Card className={'my-3'}>
              <CardHeader tag={'h2'}>
                Burning is Authorized Subject to the Following Conditions
              </CardHeader>
              <CardBody className={'mx-3'}>
                {
                  readOnly && ConditionContacts && ConditionContacts.Contacts && ConditionContacts.Contacts.length > 0 &&
                    <Row>
                      <Col>
                        <h3>Required Contacts</h3>
                        <ContactConditionList contacts={ConditionContacts.Contacts} /> 
                      </Col>
                    </Row>
                }
                {
                  readOnly && <Row>
                    <Col xs={12}>
                      <h3>Conditions</h3>
                    </Col>
                    <Col xs={12}>
                      <p>Failure to follow any of the conditions of this permit is a violation of this permit.</p>
                      { ConditionWarning }
                    </Col>
                  </Row>
                } 
                <Row className={'mx-3'}>
                  {
                    !online && conditionsAreEditable && <Col xs={12}>
                      <p><b>Configuring Permit Conditions can only be performed while connected to the internet.</b></p>
                    </Col>
                  }
                  <Col>
                    <PermitConditions
                      onChanged={this.onValuesChanged}
                      onValidityChanged={this.onValidityChanged}
                      readOnly={readOnly}
                      disabled={this.state.editIssuedPermit ? false : !online || !userCanEditConditions || !conditionsAreEditable}
                    />
                  </Col>
                </Row>
                {
                  userCanEditConditions &&
                    <Row className={'mt-3'}>
                      {
                        (conditionsAreEditable || this.state.editIssuedPermit) && <Col className={'d-flex justify-content-end'}>
                          { this.state.contactsAreDirty && <Alert color={'warning'} className={'mr-3 font-weight-bold'}>Contact changes have not been saved</Alert>}
                          {
                            !online && <PopoverButton
                              buttonClassName={'py-0'}
                              key={'issue-permit'}
                              popoverHeader={'Online only'}
                              popoverBody={'Issuing a Permit can only be performed while connected to the internet.'}
                            />
                          }
                          <PreviewPermit
                            burnPermitId={burnPermitId}
                            className={'mr-2'}
                          />
                          <IssuePermit
                            burnPermitId={burnPermitId}
                            className={'mr-2'}
                          />
                          <Button onClick={this.updateContactsAndConditions}>Save</Button>
                        </Col>
                      }
                    </Row>
                }
              </CardBody>
            </Card>
            {
              userCanEditConditions && conditionsAreEditable && this.state.editIssuedPermit &&
                <Row>
                  <Col xs={{ offset: 10, size: 2, }} className={'d-flex justify-content-end'}>
                    <SaveCancelButtons
                      onCancelClick={this.state.editIssuedPermit ? this.cancelEditingContactsAndConditions : null}
                      onSaveClick={this.updateContactsAndConditions}
                    />
                  </Col>
                </Row>
            }
          </Col>
        </Row>
      </Container>
    )
  }
}


function mapStateToProps (state, props) {
  const { params, } = props
  const hasId = params && !isNaN(params.BurnPermitId)
  let activeBurnPermitId = null
  if (hasId) {
    activeBurnPermitId = params.BurnPermitId
  }
  const burnPermitId = parseInt(activeBurnPermitId)
  const online = appIsOnlineSelector(state)
  return {
    online,
    burnPermitId,
    BurnPermitNumber      : burnPermitNumberById(state, burnPermitId),
    isIssued              : permitIsIssued(state, activeBurnPermitId),
    isExpired             : permitIsExpiredSelector(state),
    isSigned              : permitApplicationIsSigned(state, activeBurnPermitId),
    ConditionContacts     : contactConditionForPermitSelector(state, activeBurnPermitId),
    userCanEditConditions : userCanEditPermitConditions(state),
    IsLoadingDocuSignUrl  : isLoadingDocuSignUrl(state),
  }
}

const mapDispatchToProps = {
  ConditionSetPermit  : ConditionActions.setPermitForConditions,
  SetActiveConditions : ConditionActions.setActivePermitConditions,
  SetRequiredContacts : ConditionActions.setContactConditions,
  SetPageTitle        : AppActions.setPageTitle,
  Failure             : ApiActions.failure,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PermitConditionsContainer))
