// Libraries
import { put, select, } from 'redux-saga/effects'

// Reducers
import { OrmTypes, } from '../redux/OrmRedux'

// Selectors
import { newModelSelector, modelByIdSelector, modelExists, getXrefIdsForModel, getLatestDateByModelName, modelsExist, getModelCount, } from '../selectors/modelSelectors'

// Utilities
import { isAfter, } from '../utilities'

// eslint-disable-next-line no-undef
const { REACT_APP_SHOW_LOGGING, } = process.env


export function* createLocalModel (modelName, props = {}) {
  const newModel = yield select(newModelSelector, { modelName, props, })
  yield put({
    type       : OrmTypes.INSERT,
    properties : { ...props, IsLocal: true, },
    modelName,
  })
  return newModel
}

export function* updateLocalModel (modelName, modelId, updateObj) {
  yield put({ type: OrmTypes.UPDATE, modelName, modelId, updateObj, })
  return yield select(modelByIdSelector, { modelName, modelId, })
}

export function* upsertLocalModel (modelName, properties) {
  yield put({ type: OrmTypes.UPSERT, modelName, properties, })
}

export function* upsertLocalModels (modelName, records) {
  yield put({ type: OrmTypes.UPSERT_RANGE, modelName, records, })
}

export function* destroyLocalModel (modelName, modelId) {
  const localModel = yield select(modelByIdSelector, { modelName, modelId, })
  if (localModel) {
    yield put({ type: OrmTypes.DESTROY, modelName, modelId, })
    const exists = yield select(modelExists, { modelName, modelId, })
    return !exists
  }
  return false
}

export function* destroyLocalModels (modelName, filter) {
  // check there are any to destroy first
  const localDataExists = yield select(modelsExist, { modelName, filter, })
  if (localDataExists) {
    yield put({ type: OrmTypes.DESTROY_ALL, modelName, filterObj: filter, })
  }
}

export function* replaceAll (modelName, objects, filterObj) {
  yield put({ type: OrmTypes.REPLACE_ALL, modelName, objects, filterObj, })
  const xrefIds = yield select(getXrefIdsForModel, { modelName: modelName, filterObj, })
  return xrefIds
}

export function* destroyAll (modelName) {
  yield put({ type: OrmTypes.DESTROY_ALL, modelName, })
}


export function* serverHasNewerData (modelName, filter, serverData) {
  // If a non-array object is provided
  if (!Array.isArray(serverData)) {
    // Check that it actually has a value and is not null or undefined
    if (!serverData) {
      if (REACT_APP_SHOW_LOGGING) {
        console.warn('No `serverData` provided')
      }
      return false
    }
    // Then convert it to an array
    serverData = [ serverData, ]
  }

  const localModelCount = yield select(getModelCount, modelName, filter)
  // If the amount server data is not the same, either more or less,
  // then the serverData is newer
  if (serverData.length !== localModelCount) {
    if (REACT_APP_SHOW_LOGGING) {
      console.warn(`Difference in count between local data ${localModelCount} and server data ${serverData.length}`)
    }
    return true
  }
  
  const latestAppStatusDate = yield select(getLatestDateByModelName, modelName, filter)
  // If there is no server data, but there is local data, the data was likely deleted on 
  // the server, so treat the server data as being newer
  if (serverData.length === 0 && latestAppStatusDate) {
    if (REACT_APP_SHOW_LOGGING) {
      console.warn('Data found locally but no data returned from server.')
    }
    return true
  }
  // If there is no object found locally
  // and the server has data, then it's definitely new
  else if (serverData.length > 0 && !latestAppStatusDate) {
    if (REACT_APP_SHOW_LOGGING) {
      console.warn(`No local data found for ${modelName}`)
    }
    return true
  }
  // If there is both server data and local data, compare the audit dates
  const latestServerAppStatusCreateDate = serverData.reduce((latestCreateDate, currentModel) => {
    if (isAfter(latestCreateDate, currentModel.CreateDate) || !latestCreateDate) {
      return currentModel.CreateDate
    }
    return latestCreateDate
  }, null)

  const latestServerAppStatusUpdateDate = serverData.reduce((latestUpdateDate, currentModel) => {
    if (isAfter(latestUpdateDate, currentModel.UpdateDate) || !latestUpdateDate) {
      return currentModel.UpdateDate
    }
    return latestUpdateDate
  }, null)
    
  const hasNewStatuses = isAfter(latestAppStatusDate, latestServerAppStatusCreateDate) || isAfter(latestAppStatusDate, latestServerAppStatusUpdateDate)
  return hasNewStatuses
}