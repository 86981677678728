// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Container, Row, Col, CardBody, Card, } from 'reactstrap'
import { array, func, bool, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'
import ApiActions from '../redux/ApiRedux'

// Selectors
import { getPersonDataTableCols, } from '../selectors/personSelectors'
import { userCanManageAllPeople, userCanManageLandowners, } from '../selectors/permissionSelectors'

// Components
import DataTable from '../components/DataTable'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const ajaxConfig = {
  url  : `${REACT_APP_SERVER_URL}People/GetPersonList`,
  data : {},
}

export class PersonListContainer extends React.Component {
  static propTypes = {
    SetPageTitle        : func,
    columns             : array,
    // from parent
    isDnr               : bool,
    isAgent             : bool,
    isVerifiedAgent     : bool,
    Failure             : func,
    // selectors
    canManageLandowners : bool,
    canManageAllPeople  : bool,
  }

  static defaultProps = {
    columns             : [],
    canManageLandowners : false,
    canManageAllPeople  : false,
  }

  componentDidMount () {
    this.props.SetPageTitle('People')
  }

  render () {
    if (!this.props.canManageAllPeople && !this.props.canManageLandowners) {
      return <Container className={'mt-3'}>
        <Row className={'mt-3'}>
          <Col xs={12} lg={{ size: 6, offset: 3, }}>
            <Card>
              <CardBody>
                <p>You are not authorized to access this page.</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    }
    
    return <Container className={'mt-3'}>
      <Row className={'mt-3'}>
        <Col xs={12} md={3} lg={6}>
          <h1 className={'m-0'}>People</h1>
        </Col>
        <Col xs={12} md={9} lg={6} className={'d-inline-flex align-items-center justify-content-between'}>
          { this.props.isDnr && <Link className={'btn btn-link btn-md'} to={'/admin/people/merge'}>Merge People<span className={'fa fa-users-cog ml-2'} style={{ fontSize: '0.8em', }}></span></Link>}
          <Link className={'btn btn-success btn-md'} to={'/admin/people/new'}>New Person<span className={'fa fa-plus ml-2'} style={{ fontSize: '0.8em', }}></span></Link>
        </Col>
      </Row>
      <Row className={'mt-1 mb-3'}>
        <Col>
          <Card className={'w-100'}>
            <CardBody className={'p-2'}>
              <DataTable
                columns={this.props.columns}
                ajaxConfig={ajaxConfig}
                elementId={'people-table'}
                rowIdProp={'PersonId'}
                onAjaxError={req => {
                  console.error(req)
                  console.dir(req)
                  this.props.Failure('An error occurred requesting People.')
                }}
                enableExport={this.props.isDnr}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  }
}

function mapStateToProps (state) {
  return { 
    columns             : getPersonDataTableCols(state),
    canManageLandowners : userCanManageLandowners(state), 
    canManageAllPeople  : userCanManageAllPeople(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle : AppActions.setPageTitle,
  Failure      : ApiActions.failure,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonListContainer)