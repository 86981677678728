// Libraries
import React, { useCallback, useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { func, } from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Row,
} from 'reactstrap'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'

// Components
import ExternalLink from '../../ExternalLink'
import { PopoverButton, } from '../../FormControls'


// eslint-disable-next-line no-undef
const { REACT_APP_BLANK_PERMIT_APPLICATION_DOWNLOAD, } = process.env

const ApplicationMethods = props => {

  const { applyOnlineCallback, } = props

  // Selectors
  const online = useSelector(appIsOnlineSelector)

  // UI Callbacks
  const applyOnline = useCallback(() => {
    if (typeof applyOnlineCallback === 'function') {
      applyOnlineCallback()
    }
  }, [ applyOnlineCallback, ])

  // Memoed values
  const downloadOnlineOnly = useMemo(() => {
    let markup = null
    if (online !== true) {
      markup = <PopoverButton
        buttonClassName={'py-0'}
        key={'download-empty-permit-app'}
        popoverHeader={'Online only'}
        popoverBody={'Downloading an empty Burn Permit Application can only be performed while connected to the internet.'}
      />
    }
    return markup
  }, [ online, ])

  const downloadLink = useMemo(() => {
    return <ExternalLink
      className={'btn btn-primary'}
      href={REACT_APP_BLANK_PERMIT_APPLICATION_DOWNLOAD}
      title={'Download Blank Permit Application'}
      text={'Download'}
      disabled={!online}
    />
  }, [ online, ])

  const sawLink = useMemo(() => {
    return <ExternalLink
      href={'https://secureaccess.wa.gov/'}
      text={'SAW (Secure Access Washington) account'}
    />
  }, [])

  return <Container className={'pt-2'}>
    <h1>Application Methods</h1>
    <Row>
      <Col md={6} className={'mb-3'}>
        <Card className={'h-100'}>
          <CardHeader>Paper Application</CardHeader>
          <CardBody>
            <CardText>
              Would you rather fill out a paper application?
            </CardText>
            <CardText>
              You will have to either walk-in or mail-in the application to the appropriate&nbsp;
              <Link to={'/regions'}>Region office</Link> and pay by check.
            </CardText>
            <CardText>
              Click the link below to download a blank permit application to print.
              {downloadOnlineOnly}
            </CardText>
          </CardBody>
          <div className={'d-flex flex-column align-items-end m-3'}>
            {downloadLink}
          </div>
        </Card>
      </Col>
      <Col md={6} className={'mb-3'}>
        <Card className={'h-100'}>
          <CardHeader>Apply Online</CardHeader>
          <CardBody>
            <CardText>
              Applying online using your {sawLink} results in faster Permit Application processing time.
            </CardText>
            <CardText>
              You can pay for your Permit Application online and digitally sign the Permit Application.
            </CardText>
            <CardText>
              Click <b>Apply Online</b> below to begin the process and review the steps in more detail.
            </CardText>
          </CardBody>
          <div className={'d-flex flex-column align-items-end m-3'}>
            <Button onClick={applyOnline}>
              Apply Online
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  </Container>
}

ApplicationMethods.propTypes = {
  applyOnlineCallback: func,
}

export default React.memo(ApplicationMethods)