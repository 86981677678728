import { attr, fk, Model, } from 'redux-orm'

export default class CustomerInteractionDocument extends Model {
  static get modelName () {
    return 'CustomerInteractionDocument'
  }

  toString () {
    return `${this.CustomerInteractionDocumentId}`
  }

  static endpoint () {
    return 'CustomerInteractionDocuments'
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionDocumentId',
    }
  }

  static get fields () {
    return {
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),

      // Model-Specific Properties
      CustomerInteractionDocumentId : attr(),
      DocumentName                  : attr(),
      DocumentKey                   : attr(),

      // Relationships
      CustomerInteractionId: fk({
        to          : 'CustomerInteraction',
        relatedName : 'CustomerInteractionDocuments',
        as          : 'CustomerInteraction',
      }),
    }
  }
}
