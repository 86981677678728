import React, { useCallback, } from 'react'
import { render as ReactDOMRender, } from 'react-dom'
import { useNavigate, } from 'react-router-dom'

import stopEvent from '../utilities/stopEvent'

const useTableCellLink = ({ route, text = null, }) => {

  const navigate = useNavigate()

  const linkCellCallback = useCallback((td, cellData) => {
    return ReactDOMRender(
      <a href={`/${route}/${cellData}`}
        className={'text-dark'}
        onClick={e => {
          stopEvent(e)
          navigate(`/${route}/${cellData}`)
        }}>{text || `View #${cellData}`}</a>
      , td)
  }, [ text, route, navigate, ])

  return linkCellCallback
}

export default useTableCellLink
