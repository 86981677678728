import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Button, } from 'reactstrap'
import { func, number, bool, string, } from 'prop-types'

import BurnPermitFormActions from '../../redux/BurnPermitFormRedux'
import { permitApplicationDocuments, } from '../../selectors/burnPermitSelectors'


const DownloadAllPermitDocuments = props => {
  const isDownloading = useSelector(state => state.BurnPermitForm.isDownloadingFiles)
  const documents = useSelector(state => permitApplicationDocuments(state, props.burnPermitId))
  const dispatch = useDispatch()
  
  const onClick = React.useCallback(() => {
    dispatch(BurnPermitFormActions.downloadAllFiles(props.burnPermitId))
  }, [ dispatch, props.burnPermitId, ])

  return <Button
    color={props.color}
    onClick={onClick}
    readOnly={isDownloading || !documents.length}
    disabled={isDownloading || !documents.length}
  >
    {props.buttonText}
  </Button>
}

DownloadAllPermitDocuments.propTypes = {
  burnPermitId : number.isRequired,
  color        : string,
  buttonText   : string,
  onClick      : func,
  readOnly     : bool,
}

DownloadAllPermitDocuments.defaultProps = {
  burnPermitId : null,
  color        : 'dark',
  buttonText   : 'Download All',
  onClick      : () => {},
  readOnly     : false,
}

export default DownloadAllPermitDocuments