// Libraries
import { put, call, all, takeEvery, select, } from 'redux-saga/effects'
import * as jwt from 'jsonwebtoken' 

// Sagas
import { doFetch, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { checkPersonBurnRequests, getPerson, } from './PersonSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { UserTypes, } from '../redux/UserRedux'
import { AppTypes, } from '../redux/AppRedux'

//Selectors
import { networkStateSelector, } from '../selectors/selectors'
import { getUserToken, } from '../selectors/userSelectors'

// CONSTANTS
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, } = process.env
const REQUEST_URL = REACT_APP_SERVER + 'Account/GetUserInfo'
const LOGOUT_URL = REACT_APP_SERVER + 'Account/Logout'
const CHECK_AGENT_URL = REACT_APP_SERVER + 'Account/IsVerified'

/**
 * Parse JWT and set user groups when login is received
 * @param {object} action - The login response from ADFS/SAW
 * @param {String} action.token - The JWT to parse for groups
 */
export function* login ({ token, }) {
  // if there's no token, error out immediately
  if (!token) {
    yield put({ type: ApiTypes.FAILURE, error: 'Could not get user info, no JWT was provided.', })
    yield put({ type: UserTypes.USER_INFO_ERROR, error: 'Could not get user info, no JWT was provided.', })
    return
  }

  // indicate that we're loading profile information
  yield put({ type: UserTypes.USER_FETCH_START, })

  try {
    // decode groups from JWT
    const validationSuccess = yield call(processJWTGroups, token)
    if (!validationSuccess) {
      yield put({ type: ApiTypes.FAILURE, error: 'JWT group processing failed.', })
      yield put({ type: UserTypes.USER_INFO_ERROR, error: 'JWT group processing failed.', })
      return
    }

    /*
      get user information. this may require a rework 
      if the redux-saga package has a major revision. 
      rely on this effect running after the reducer,
      as is the current expected behavior
    */
    const userObj = yield call(userInfoRequest)
    if (!userObj || !userObj.personId) {
      return 
    }

    // get person information if a person id was provided
    // this will update the user's agency status in state
    yield call(getPerson, { personId: userObj.personId, })

    try {
      yield call(checkPersonBurnRequests, { personId: userObj.personId, })
    } catch (error) {
      yield put({ type: ApiTypes.FAILURE, error, })
    }
  }
  catch (error) {
    yield put({ type: ApiTypes.FAILURE, error, })
    yield put({ type: UserTypes.USER_INFO_ERROR, error: 'An error occurred while requesting user data.', })
  }
  finally {
    yield put({ type: UserTypes.USER_FETCH_END, })
  }
}

function* processJWTGroups (token) {
  if (!token) {
    console.error('No JWT was provided to parse groups from.')
    return false
  }
  try {
    // TODO: Verify signature here
    const decoded = yield call([ jwt, jwt.decode, ], token)
    const groupKey = Object.keys(decoded).find(k => k.indexOf('group') > -1)
    const decGroups = decoded[groupKey]
    let groups = []

    if (decGroups) {
      if (Array.isArray(decGroups)) {
        groups = yield call([ groups, 'concat', ], decGroups)
      } else {
        yield call([ groups, 'push', ], decGroups)
      }
    }
    if (groups) {
      yield put({ type: UserTypes.SET_GROUPS, groups, })
    }
    return true
  }
  catch (error) {
    yield put({ type: ApiTypes.FAILURE, error: 'Failed processing user login information. Please log out and then log back in.', })
    return false
  }
}

/**
 * This builds the XHR request and handles the return data.
 * @returns {bool}
 */
export function* logout () {
  yield call(showLoading)
  // No matter what, log out locally
  yield all([
    put({ type: UserTypes.DESTROY_TOKEN_AND_RESET_STATE, }),
    put({ type: AppTypes.SET_RETURN_URL, returnUrl: '', }),
    put({ type: ApiTypes.CLEAR_LOOKUP_CHECKS, }),
    // If it fails, it's prob cuz we're no longer authenticated, so don't worry about the response
    call(doFetch, LOGOUT_URL, { method: 'POST', }),
  ])
  yield call(hideLoading)
}

export function* userInfoRequest () {
  yield call(showLoading)
  try {
    const request = yield call(doFetch, REQUEST_URL)

    if (request.ok === false) {
      yield put({ type: UserTypes.USER_INFO_ERROR, error: 'Could not get user info.', })
      return null
    }

    const { UserInfo, } = request.responseBody
    if (!UserInfo || !UserInfo.PersonId || !UserInfo.UserName) {
      yield put({ type: UserTypes.USER_INFO_ERROR, error: 'User info was malformed.', })
      return null
    }
    const userObj = { personId: UserInfo.PersonId, userName: UserInfo.UserName, }
    yield put({ type: UserTypes.USER_INFO_SUCCESS, ...userObj, })
    return userObj
  }
  catch (error) {
    yield put({ type: UserTypes.USER_INFO_ERROR, error, })
    yield put({ type: ApiTypes.FAILURE, error, })
  }
  finally {
    yield call(hideLoading)
  }
}

function* checkAgentVerification () {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    const res = yield call(doFetch, CHECK_AGENT_URL)
    const { responseBody, statusCode, ok, } = res
    if (statusCode !== 200 || !ok) {
      yield put({
        type  : ApiTypes.FAILURE,
        error : 'Failed checking agent verification status.',
      })
      return
    }

    if (responseBody === true) {
      yield put({
        type            : UserTypes.UPDATE_USER_AGENCY_STATUS,
        isVerifiedAgent : responseBody,
      })
    }
  }
}

function* checkTokenExpiration () {
  const token = yield select(getUserToken)
  if (!token) {
    yield put({ type: UserTypes.LOGOUT, })
  }
  const { exp, } = yield call([ jwt, jwt.decode, ], token)
  const expirationDate = new Date(1970, 0, 1)
  expirationDate.setSeconds(exp)
  expirationDate.setHours(expirationDate.getHours() - (expirationDate.getTimezoneOffset() / 60))
  const isExpired = expirationDate.getTime() < Date.now()
  if (isExpired) {
    yield put({ type: ApiTypes.GENERAL_MESSAGE, title: 'Session Expired', message: 'Your session has expired. Please log in again to continue using the Burn Portal.', })
    yield put({ type: UserTypes.LOGOUT, })
  }
}

export const UserSagas = [
  takeEvery(UserTypes.USER_INFO_REQUEST, userInfoRequest),
  takeEvery(UserTypes.LOGIN_RECEIVED, login),
  takeEvery(UserTypes.LOGOUT, logout),
  takeEvery(UserTypes.CHECK_AGENT_VERIFICATION, checkAgentVerification),
  takeEvery(UserTypes.CHECK_TOKEN_EXPIRATION, checkTokenExpiration),
]
