import {
  Apply,
  Issued,
  Separator,
  Submit,
} from './'
    
const AutoIssuedSteps = ({ mustSignPermit, isIssued, }) => {
  return [
    Apply(),
    Separator(),
    Submit({ mustSignPermit, }),
    Separator(),
    Issued({ isIssued, }),
  ]
}
    
export default AutoIssuedSteps