import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'
import { renderCheckOrXIcon, } from '../utilities/columnHelpers'

export default class Agency extends Model {
  static get modelName () {
    return 'Agency'
  }

  toString () {
    let name = ''
    if (this.AgencyName) {
      name = name + this.AgencyName
    }
    if (this.AgencySubName1) {
      name = name + ' - ' + this.AgencySubName1
    }
    if (this.AgencySubName2) {
      name = name + ' - ' + this.AgencySubName2
    }
    return name
  }

  static endpoint () {
    return 'Agencies'
  }

  static getValidationSchema () {
    const {
      AgencyName,
      AgencySubName1,
      AgencySubName2,
      AgencyOtherName,
      AgencyAbbreviation,
    } = this.fields

    const val = Yup.object().shape({
      AgencyName: Yup.string()
        .max(AgencyName.opts.max, `Agency name name must be ${AgencyName.opts.max} characters or less`)
        .required('Please enter an agency name'),
      AgencySubName1: Yup.string()
        .max(AgencySubName1.opts.max, `Sub name 1 must be ${AgencySubName1.opts.max} characters or less`)
        .nullable(),
      AgencySubName2: Yup.string()
        .max(AgencySubName2.opts.max, `Sub name 2 name must be ${AgencySubName2.opts.max} characters or less`)
        .nullable(),
      AgencyOtherName: Yup.string()
        .max(AgencyOtherName.opts.max, `Other name must be ${AgencyOtherName.opts.max} characters or less`)
        .nullable(),
      AgencyAbbreviation: Yup.string()
        .max(AgencyAbbreviation.opts.max, `Abbreviation name must be ${AgencyAbbreviation.opts.max} characters or less`)
        .nullable(),
    })
    return val
  }

  static getDataTablesColumns () {
    return [
      {
        title : 'Id',
        type  : 'num',
        data  : 'AgencyId',
        width : 15,
      },
      {
        title : 'Agency Name',
        data  : 'AgencyName',
      },
      {
        title          : 'Abbrev',
        data           : 'AgencyAbbreviation',
        defaultContent : '',
      },
      {
        title          : 'Auto Region Approval',
        data           : 'AutoRegionApproval',
        defaultContent : '',
        render         : renderCheckOrXIcon,
      },
      {
        title : 'Email',
        data  : 'Email',
      },
      {
        title : 'Phone',
        data  : 'Phone',
      },
      {
        title          : 'Pays by Voucher',
        data           : 'PaysByVoucher',
        defaultContent : '',
        render         : renderCheckOrXIcon,
      },
    ]
  }
  
  static get options () {
    return {
      idAttribute: 'AgencyId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      AgencyId           : attr(),
      CreateDate         : attr(),
      CreateBy           : attr(),
      UpdateDate         : attr(),
      UpdateBy           : attr(),
      // Model-Specific Properties
      AgencyName         : attr({ max: 50, }),
      AgencySubName1     : attr({ max: 50, }),
      AgencySubName2     : attr({ max: 50, }),
      AgencyOtherName    : attr({ max: 50, }),
      AgencyAbbreviation : attr({ max: 4, }),
      AutoRegionApproval : attr(),
      PaysByVoucher      : attr(),

      // Relationships
      AddressId : fk({ to: 'Address', relatedName: 'Agencies', as: 'Address', }),
      PhoneId   : fk({ to: 'Phone', relatedName: 'Agencies', as: 'Phone', }),
      EmailId   : fk({ to: 'Email', relatedName: 'Agencies', as: 'Email', }),
    }
  }
}