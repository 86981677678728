//Libraries
import React from 'react'
import { bool, number, } from 'prop-types'
import { Alert, } from 'reactstrap'
// import { Prompt, } from 'react-router-dom'


const CartAlert = props => {
  if (!props.visible) {
    return null
  }
  return <>
    <Alert color={'warning'} style={{ margin: 'auto', }}>
      <p className={'mb-0'}>
        Creating a new Order: 
        {props.orderItemCount === 0 && ' No applications selected'}
        {props.orderItemCount === 1 && ' 1 application selected'}
        {props.orderItemCount > 1 && ' ' + props.orderItemCount + ' applications selected'}.
      </p>
      { props.enablePrompt && <p className={'text-danger mb-0'}>If you refresh the page, your cart will be emptied.</p> }
    </Alert>
    {/* TODO: handle replacing Prompt
    <Prompt when={props.enablePrompt} message={'You have not submitted your order, if you continue any unsaved changes will be lost.'} /> */}
  </>
}

CartAlert.propTypes = {
  visible        : bool,
  orderItemCount : number,
  enablePrompt   : bool,
}

CartAlert.defaultProps = {
  enablePrompt: false,
}

export default CartAlert