import React, { useCallback, useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row, } from 'reactstrap'

// Hooks
import {
  useAutoComplete,
  useEnterPressHandler,
  useSearchLookupsFetch,
} from '../../Search/hooks'
import { useTableCellLink, } from '../../../hooks'

// Utilities
import { dateFormatter, } from '../../../utilities'
import { formatAddress, renderCheckIcon, renderLatLong, } from '../../../utilities/columnHelpers'

// Redux
import AppActions from '../../../redux/AppRedux'
import CustomerInteractionActions from '../../../redux/CustomerInteractionRedux'

// Selectors
import {
  custIntSearchParamsSelector,
  customerInteractionStatusesSelector,
  searchComplaintsSelector,
  smokeCompMapStateSelector,
  smokeComplaintTypeId,
} from '../../../selectors/customerInteractionSelectors'
import { regionsForSelectSelector, } from '../../../selectors/regionSelectors'

// Components
import ESRIMap from '../../ESRIMap'
import SimpleTable from '../../SimpleTable'
import { DateRange, PermitNumber, SearchInput, } from '../../SearchInputs'
import { AssignedStaff, AutoComplete, Select, } from '../../FormControls'

// Map
import { customerInteractionAction, } from '../../../config/map/actions'


const layerId = 'smokecomplaints'
const fields = [
  {
    name  : 'CustomerInteractionId',
    label : 'ID',
  },
  {
    name: 'Status',
  },
  {
    name  : 'AssignedTo',
    label : 'Assigned To',
  },
  {
    name  : 'StatusDate',
    label : 'Latest Status Date',
  },
  {
    name  : 'SubmitterName',
    label : 'Submitter name',
  },
  {
    name  : 'InteractionDescription',
    label : 'Description',
  },
  {
    name  : 'FollowUp',
    label : 'Follow up?',
  },
  {
    name: 'Region',
  },
  {
    name: 'Address',
  },
  {
    name: 'Longitude',
  },
  {
    name: 'Latitude',
  },
  {
    name  : 'CreateBy',
    label : 'Created By',
  },
  {
    name  : 'CreateDate',
    label : 'Created Date',
  },
  {
    name  : 'UpdateBy',
    label : 'Update By',
  },
  {
    name  : 'UpdateDate',
    label : 'Updated Date',
  },
]

const SmokeSearch = () => {
  // State and refs
  const navigate = useNavigate()

  // Selectors
  const complaintTypeId = useSelector(smokeComplaintTypeId)
  const { center, zoom, showMap, } = useSelector(smokeCompMapStateSelector)
  const searchParams = useSelector(state => custIntSearchParamsSelector(state, complaintTypeId))
  const complaints = useSelector(searchComplaintsSelector)
  const statuses = useSelector(customerInteractionStatusesSelector)
  const regions = useSelector(regionsForSelectSelector)

  // Redux actions
  const dispatch = useDispatch()

  useSearchLookupsFetch(dispatch, true)
  // UI Callbacks

  useEffect(() => {
    dispatch(AppActions.setPageTitle('Smoke Complaint Search'))
    if (complaintTypeId > 0) {
      dispatch(CustomerInteractionActions.newCustomerInteraction(complaintTypeId))
    }
  }, [ complaintTypeId, dispatch, ])

  const onClearClick = useCallback(() => {
    dispatch(CustomerInteractionActions.clearCustomerInteractionSearch())
  }, [ dispatch, ])

  const onChange = useCallback(e => {
    const { name, value, } = e.target
    const newParams = {
      ...searchParams,
      [name]: value || '',
    }
    newParams.submit = false
    dispatch(CustomerInteractionActions.searchCustomerInteractions(newParams))
  }, [ searchParams, dispatch, ])

  const onAutoComplete = useCallback(e => {
    const { name, id, value, } = e.target
    const newParams = {
      ...searchParams,
      [name]        : value || '',
      [`${name}Id`] : id || '',
    }
    newParams.submit = false
    dispatch(CustomerInteractionActions.searchCustomerInteractions(newParams))
  }, [ searchParams, dispatch, ])

  const [ assignedStaffChange, ] = useAutoComplete('AssignedTo', onAutoComplete)

  const [ regionAutoChange, ] = useAutoComplete('Region', onAutoComplete)

  const onSubmittedDateChosen = useCallback(({ from, to, }) => {
    const newParams = {
      ...searchParams,
      SubmittedOnAfter : dateFormatter(from || '', 'YYYY-MM-DD'),
      SubmitteOnBefore : dateFormatter(to || '', 'YYYY-MM-DD'),
    }
    newParams.submit = false
    dispatch(CustomerInteractionActions.searchCustomerInteractions(newParams))
  }, [ searchParams, dispatch, ])

  const linkCellCallback = useTableCellLink({ route: 'smokecomplaints', })

  const config = useMemo(() => {
    return {
      columns: [
        {
          title : 'ID',
          data  : 'CustomerInteractionId',
          width : 50,
        },
        {
          title : 'Name',
          data  : 'SubmitterName',
        },
        {
          title : 'Description',
          data  : 'InteractionDescription',
        },
        {
          title   : 'Address',
          data    : 'Address',
          visible : false,
          render  : formatAddress,
        },
        {
          title   : 'Lat / Long',
          data    : 'LatLong',
          visible : false,
          render  : renderLatLong,
        },
        {
          title : 'Status',
          data  : 'Status',
        },
        {
          title  : 'Wants Follow Up',
          data   : 'FollowUp',
          render : renderCheckIcon,
        },
        {
          title : 'Assigned To',
          data  : 'AssignedTo',
        },
        {
          title : 'Region',
          data  : 'Region',
        },
        {
          title  : 'Created',
          render : (_, __, { CreateBy, CreateDate, }) => {
            const date = dateFormatter(CreateDate, 'YYYY-MM-DD @ HH:MM:SS')
            return `${CreateBy} - ${date}`},
        },
        {
          title   : 'Updated',
          visible : false,
          render  : (_, __, { UpdateBy, UpdateDate, }) => {
            if (UpdateBy) {
              const date = dateFormatter(UpdateDate, 'YYYY-MM-DD @ HH:MM:SS')
              return `${UpdateBy} - ${date}`
            }
            return ''
          },
        },
      ],
      columnDefs: [
        {
          targets     : 0,
          createdCell : linkCellCallback,
        },
      ],
      data           : complaints,
      scrollY        : '60vh',
      scrollCollapse : true,
      order          : [],
      rowCallback    : (row) => row.classList.add('no-click'),
      buttons        : [ 'colvis', 'excel', 'print', 'csv', 'pdf', ],
    }
  }, [ complaints, linkCellCallback, ])

  /**
   * Search button handler, update redux with new search values 
   */
  const onSearchClick = useCallback(() => {
    const submitParams = { ...searchParams, submit: true, }
    dispatch(CustomerInteractionActions.searchCustomerInteractions(submitParams))
  }, [ searchParams, dispatch, ])

  const handleKeyPress = useEnterPressHandler(onSearchClick)

  const toggleMap = useCallback(() => {
    dispatch(CustomerInteractionActions.toggleCustomerInteractionMap())
  }, [ dispatch, ])

  const mapBtnText = useMemo(() => {
    return showMap
      ? 'Hide Map'
      : 'Show Map'
  }, [ showMap, ])

  const mapColStyle = useMemo(() => ({ height: '530px', }), [])
  const mapClass = useMemo(() => {
    let classString = 'map-container rounded'
    if (showMap) {
      classString += ' my-1'
    }
    else {
      classString += ' d-none'
    }
    return classString
  }, [ showMap, ])

  const mapData = useMemo(() => {
    let eventLocData = []
    if (Array.isArray(complaints) && complaints.length) {
      const fieldInfos = fields.map(({ name, label, }) => ({ fieldName: name, label, }))
      eventLocData = complaints
        .filter(comp => (comp.Latitude && comp.Longitude))
        .map(comp => {
          const { Latitude, Longitude, } = comp
          return {
            geometry: {
              type      : 'point',
              latitude  : Latitude,
              longitude : Longitude,
            },
            attributes    : { ...comp, },
            popupTemplate : {
              title   : 'Smoke Event Location Lat/Long',
              content : [
                {
                  type: 'fields',
                  fieldInfos,
                },
              ],
            },
          }
        })
    }
    return [
      {
        layerId,
        layerTitle : 'Smoke Event Locations',
        data       : eventLocData,
        fields     : [],
        idField    : 'CustomerInteractionId',
        actions    : [ customerInteractionAction.bind({ navigate, })(layerId, layerId, 'View Complaint'), ],
      },
    ]
  }, [ complaints, navigate, ])

  return <Container className={'pb-4'}>
    <h1>Smoke Complaints</h1>
    <Card>
      <CardBody>
        <Form onKeyPress={handleKeyPress}>
          <input
            type={'hidden'}
            name={'CustomerInteractionTypeId'}
            value={complaintTypeId}
          />
          <Row>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <SearchInput
                  label={'Smoke Complaint ID'}
                  labelClassName={'m-0'}
                  name={'CustomerInteractionId'}
                  value={searchParams.CustomerInteractionId}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <SearchInput
                  label={'Permit App ID'}
                  labelClassName={'m-0'}
                  name={'BurnPermitId'}
                  value={searchParams.BurnPermitId}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <PermitNumber
                  value={searchParams.BurnPermitNumber}
                  onChange={onChange}
                  showPopover={false}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <Select
                label={'Status'}
                labelClassName={'m-0'}
                items={statuses}
                propertyName={'StatusId'}
                selectedValue={searchParams.StatusId}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <SearchInput
                  label={'Submitted By'}
                  name={'SubmitterName'}
                  value={searchParams.SubmitterName}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <AssignedStaff
                  value={searchParams.AssignedTo}
                  onChange={assignedStaffChange}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={2} lg={3}>
              <FormGroup>
                <Label for={'Region'}>Region</Label>
                <AutoComplete
                  items={regions}
                  value={searchParams.Region}
                  fieldName={'Region'}
                  onChange={regionAutoChange}
                  minCharactersToEnter={0}
                />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <FormGroup>
                <Label>Submitted Date</Label>
                <DateRange
                  name={'SubmittedDate'}
                  from={searchParams.SubmittedOnAfter}
                  to={searchParams.SubmittedOnBefore}
                  onClick={onSubmittedDateChosen}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button
                color={'primary'}
                id={'btn-clear'}
                onClick={onClearClick}
              >Clear</Button>
            </Col>
            <Col xs={6}>
              <div className={'d-flex justify-content-end'}>
                <Button
                  color={'light'}
                  id={'toggle-map'}
                  onClick={toggleMap}
                  className={'mr-4'}
                >
                  {mapBtnText}
                </Button>
                <Button
                  color={'secondary'}
                  id={'btn-search'}
                  onClick={onSearchClick}
                  className={'float-right'}>Search</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
    <Row className={mapClass}>
      <Col style={mapColStyle}>
        <ESRIMap
          center={center}
          zoom={zoom}
          config={'SmokeComplaints'}
          legendOpen={false}
          mapData={mapData}
        />
      </Col>
    </Row>
    <SimpleTable
      elementId={'SmokeComplaints'}
      config={config}
    />
  </Container>
}

export default React.memo(SmokeSearch)
