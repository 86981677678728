import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import {
  NavLink,
} from 'reactstrap'
import { appIsOnlineSelector, } from '../../../selectors/selectors'

// Selectors
import { userIsAuthenticated, } from '../../../selectors/userSelectors'

// Components
import NavbarDropdown from '../NavbarDropdown'
import NavbarDropdownItem from '../NavbarDropdownItem'
import Logout from './Logout'

const {
  REACT_APP_SAW_LOGIN_URL,
  REACT_APP_SERVER,
  // eslint-disable-next-line no-undef
} = process.env
const ADFS_LOGIN_URL = `${REACT_APP_SERVER}Account/ADFS`

const Account = () => {

  // Selectors
  const isAuthenticated = useSelector(userIsAuthenticated)
  const online = useSelector(appIsOnlineSelector)

  const MenuItems = useMemo(() => {
    if (isAuthenticated) {
      return <>
        <NavbarDropdownItem
          to={'/Profile'}
          title={'My Profile'}
        >
          My Profile
        </NavbarDropdownItem>
        <Logout />
      </>
    }
    return <>
      <NavLink
        href={REACT_APP_SAW_LOGIN_URL}
        title={'Log in with SAW'}
        disabled={!online}
        role={'menuitem'}
      >
        Public (SAW)
      </NavLink>
      <NavLink
        href={ADFS_LOGIN_URL}
        title={'Log in with WA ADFS'}
        disabled={!online}
        role={'menuitem'}
      >
        WA State Employee
      </NavLink>
    </>
  }, [ online, isAuthenticated, ])

  const dropdownText = useMemo(() => (isAuthenticated ? 'Account' : 'Login'), [ isAuthenticated, ])

  const markup = <NavbarDropdown
    text={dropdownText}
    title={`${dropdownText} menu`}
  >
    {MenuItems}
  </NavbarDropdown>
  return markup
}

export default React.memo(Account)
