import { createAuthSelector, } from './permissionSelectors'

//#region signature section permission selectors

/** User must sign a Burn Permit Application with DocuSign to submit it */
export const userMustSignPermitToSubmit = createAuthSelector(
  ({ isSAWUser, isGovAgent, paysByVoucher, isVerifiedAgent, isStateGovCust, }) => {
    let mustSign = isSAWUser || isStateGovCust
    if (isGovAgent && isVerifiedAgent) {
      mustSign = !paysByVoucher
    }
    return mustSign
  }
)

/** User must upload a scanned copy of the Burn Permit Application to submit it */
export const userMustUploadPermitToSubmit = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/**
 * User does not need to sign or upload a copy of a Burn Permit Applicaton
 * if they are a verified Gov't Agent for an Agency that Pays By Voucher
 */
export const userCanAutoSubmitPermit = createAuthSelector(
  ({ isGovAgent, paysByVoucher, isVerifiedAgent, isSAWUser, }) => {
    let canAutoSubmit = false
    if (isSAWUser && isGovAgent && isVerifiedAgent) {
      canAutoSubmit = paysByVoucher
    }
    return canAutoSubmit
  }
)

//#endregion

//#region burn permit form permission selectors

/**
 * User must pay for an Order before a Permit application is submitted
 * if they are:
 *    - a regular SAW user (Private, Agent)
 *    - an unverified Gov't Agent
 *    - a verified Gov't Agent of an Agency not configured to pay by voucher
 *    - a State Gov't Customer
 */
export const userMustPayOrdertoSubmitPermit = createAuthSelector(
  ({ isGovAgent, paysByVoucher, isVerifiedAgent, isSAWUser, isStateGovCust, }) => {
    let mustPay = isSAWUser || isStateGovCust
    if (isGovAgent && isVerifiedAgent) {
      mustPay = !paysByVoucher
    }
    return mustPay
  }
)

/** 
 * User must sign an Issued permit before using it to submit Burn Requests 
 * if they are:
 *    - a regular SAW user (Private, Agent)
 *    - an unverified Gov't Agent
 *    - a verified Gov't Agent of an Agency not configured to pay by voucher
 *    - a State Gov't Customer
 */
export const userMustSignIssuedPermit = createAuthSelector(
  ({ isGovAgent, paysByVoucher, isVerifiedAgent, isSAWUser, isStateGovCust, }) => {
    let mustSign = isSAWUser || isStateGovCust
    if (isGovAgent && isVerifiedAgent) {
      mustSign = !paysByVoucher
    }
    return mustSign
  }
)

/** User can pay for an Order with a voucher */
export const userCanPayWithVoucher = createAuthSelector(
  ({ isStateGovCust, isGovAgent, paysByVoucher, isVerifiedAgent, isDnr, }) => {
    let payWithVoucher = isDnr || isStateGovCust
    if (isGovAgent && isVerifiedAgent) {
      payWithVoucher = paysByVoucher
    }
    return payWithVoucher
  }
)

//#endregion

//#region signature callback permission selectors

/** User is a Permit Approver when Signing */
export const userMakesApproverSignatures = createAuthSelector(
  ({ isDnr , }) => isDnr 
)

//#endregion

//#region burn permit order permission selectors

/** User can edit Orders (record cash/check) */
export const userCanEditOrders = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User can refund Orders */
export const userCanRefundOrders = createAuthSelector(
  ({ isDnr, }) => isDnr
)

//#endregion

//#region burn permit status permission selectors

/** User can see internal only Burn Permit statuses */
export const userCanSeeInternalOnly = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User can edit a Burn Permit's status */
export const userCanEditPermitStatus = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User can edit a Burn Permit's conditions */
export const userCanEditPermitConditions = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User sets Issued/Expiration dates when signing a Permit */
export const userSetsPermitDatesWhenSigning = createAuthSelector(
  ({ isDnr, }) => isDnr
)

//#endregion

//#region applicant info section permission selectors

/** User can select a Landowner for the Burner fields */
export const userCanSelectPermitLandowner = createAuthSelector(
  ({ isDnr, isAgent, isGovAgent, isVerifiedAgent, isSAWUser, }) => 
    isDnr || (isSAWUser && (isAgent || isGovAgent) && isVerifiedAgent)
)

export const userCanSelectGovCustForPermit = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User can update legacy Burner/Agent information */
export const userCanEditLegacyPermitApplInfo = createAuthSelector(
  ({ isDnr, }) => isDnr
)

/** User is the default Burner on a new Burn Permit Application */
export const userIsDefaultLandowner = createAuthSelector(
  ({ isSAWUser, isPrivate, isStateGovCust, }) =>
    (isSAWUser && isPrivate) || isStateGovCust
)

//#endregion

//#region burn permit location section permission selectors

/** User can edit a  Permit Application's UGA status */
export const userCanEditUGA = createAuthSelector(
  ({ isDnr, }) => isDnr 
)

export const userCanEditLocationPastSubmission = createAuthSelector(
  ({ isDnr, }) => isDnr
)

//#endregion

//#region burn permit location section permission selectors
export const userCanRecordCashCheckPayment = createAuthSelector(
  ({ isDnr, }) => isDnr
)
//#endregion

//#region burn permit list permission selectors
export const userCanBeAssignedPermits = createAuthSelector(
  ({ isDnr, }) => isDnr
)
//#endregion

export const userCreatesGovAgentPermits = createAuthSelector(
  ({ isSAWUser, isGovAgent, isVerifiedAgent, }) => 
    isSAWUser && isGovAgent && isVerifiedAgent
)

export const userCanReviewFHExemption = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userSeesStatusModals = createAuthSelector(
  ({ isDnr, isGovAgent, isVerifiedAgent, }) => !isDnr || (isGovAgent && isVerifiedAgent)
)
