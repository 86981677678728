import React from 'react'
import { Label, Input, Col, } from 'reactstrap'
import { array, bool, string, func, } from 'prop-types'

const ConditionCheckboxes = (props) => {
  const { data, pfx, selectedData, onValueChanged, readOnly, } = props
  const onCheckChanged = React.useCallback((val) => onValueChanged(val), [ onValueChanged, ])

  if (!data || data.length < 1) {
    return <span>No data available.</span>
  }

  const checkboxes = data.map(d => {
    const checked = selectedData && selectedData.includes(d.Value)
    return <Col sm={'4'} md={'3'} lg={'2'} key={`${pfx}-chk-col-${d.Value}`}>
      <Label for={`${pfx}-chk-${d.Value}`} className={readOnly && !checked ? 'grey-text' : ''} check>
        <Input
          checked={checked}
          type={'checkbox'}
          id={`${pfx}-chk-${d.Value}`}
          key={`${pfx}-chk-${d.Value}`}
          className={'mr-1'}
          onChange={!readOnly ? () => onCheckChanged(d.Value) : null}
          readOnly={readOnly}
          disabled={readOnly}
        /> 
        {d.Text}
      </Label>
    </Col>
  })
  
  return checkboxes
}

ConditionCheckboxes.propTypes = {
  data           : array,
  selectedData   : array,
  readOnly       : bool,
  pfx            : string.isRequired,
  onValueChanged : func.isRequired,
}

ConditionCheckboxes.defaultProps = {
  data           : [],
  selectedData   : [],
  readOnly       : true,
  pfx            : '',
  onValueChanged : null,
}

export default ConditionCheckboxes