import React, { useCallback, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Button, Fade, Modal, ModalBody, ModalHeader, } from 'reactstrap'
import { number, oneOfType, string, } from 'prop-types'

// Selectors
import { userIsDNR, } from '../../../selectors/userSelectors'
import {
  customerInteractionStatusFormOpenSelector,
  customerInteractionStatusesByIdSelector,
} from '../../../selectors/customerInteractionSelectors'

// Components
import StatusTable from './StatusTable'
import StatusForm from './StatusForm'

// Hooks
import { useShowModal, } from '../../../hooks'

// Reducers
import { CustomerInteractionTypes, } from '../../../redux/CustomerInteractionRedux'


const CustomerInteractionStatus = props => {
  const { CustomerInteractionId, } = props

  const dispatch = useDispatch()

  // Selectors
  const userIsDnr = useSelector(userIsDNR)
  const showForm = useSelector(customerInteractionStatusFormOpenSelector)
  const customerInteractionStatuses = useSelector(state => customerInteractionStatusesByIdSelector(state, CustomerInteractionId))

  const [ statusModalOpen, setModalOpen, closeModal, ] = useShowModal(dispatch, 'CUSTOMER_INTERACTION_STATUS_DIALOG')

  const latestStatus = useMemo(() => {
    if (Array.isArray(customerInteractionStatuses) && customerInteractionStatuses.length) {
      const [ { StatusName, }, ] = customerInteractionStatuses
      return StatusName
    }
    return 'Unassigned'
  }, [ customerInteractionStatuses, ])

  const addStatus = useCallback(() => {
    dispatch({
      type     : CustomerInteractionTypes.SHOW_CUSTOMER_INTERACTION_STATUS_FORM,
      showForm : true,
    })
  }, [ dispatch, ])
  const cancelStatus = useCallback(() => {
    dispatch({
      type     : CustomerInteractionTypes.SHOW_CUSTOMER_INTERACTION_STATUS_FORM,
      showForm : false,
    })
  }, [ dispatch, ])

  const statusClick = useCallback(() => (setModalOpen()), [ setModalOpen, ])
  const statusStyle = useMemo(() => ({ fontSize: '1.5em', }), [])
  const modalSize = useMemo(() => (showForm ? 'lg' : 'xl'), [ showForm, ])
  const modalBodyStyle = useMemo(() => ({ minHeight: '53vh', }), [])

  if (userIsDnr !== true) {
    return null
  }

  const markup = <>
    <h2 className={'text-secondary m-0'}>Status:</h2>
    <Button
      style={statusStyle}
      className={'text-dark font-weight-bold p-0 pl-1 smoke-complaint-status'}
      color={'link'}
      onClick={statusClick}>
      {latestStatus}
    </Button>
    <Modal isOpen={statusModalOpen} size={modalSize}>
      <ModalHeader toggle={closeModal}>Smoke Complaint #{CustomerInteractionId} - Status</ModalHeader>
      <ModalBody style={modalBodyStyle}>
        <Fade in={showForm}>
          <StatusForm
            CustomerInteractionId={CustomerInteractionId}
            onCancelClick={cancelStatus}
          />
        </Fade>
        <Fade in={!showForm}>
          <div className={'d-flex justify-content-end mb-2'}>
            <Button
              size={'sm'}
              color={'success'}
              onClick={addStatus}
            >Add Status</Button>
          </div>
          <StatusTable CustomerInteractionId={CustomerInteractionId} />
        </Fade>
      </ModalBody>
    </Modal>
  </>
  return markup
}

CustomerInteractionStatus.propTypes = {
  CustomerInteractionId: oneOfType([ number, string, ]),
}

export default React.memo(CustomerInteractionStatus)
