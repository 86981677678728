import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { number, oneOfType, string, } from 'prop-types'
import { Table, } from 'reactstrap'

// Selectors
import {
  customerInteractionStatusesByIdSelector,
} from '../../../selectors/customerInteractionSelectors'

// Utilities
import { 
  renderCheckIcon,
  renderCheckOrXIcon,
  renderDateTime,
} from '../../../utilities/columnHelpers'


const StatusTable = props => {
  const { CustomerInteractionId, } = props

  // Selectors
  const customerInteractionStatuses = useSelector(state => customerInteractionStatusesByIdSelector(state, CustomerInteractionId))

  // UI Callbacks
  const trs = useMemo(() => {
    return customerInteractionStatuses.map((v, idx) => {
      return <tr key={`customerinteractionstatus_${idx}`}>
        <td>{v.StatusName}</td>
        <td>{renderDateTime(v.StatusDate, 'display')}</td>
        <td>{v.AssignedTo}</td>
        <td>{v.Comment}</td>
        <td>{renderCheckOrXIcon(v.CommentInternalOnly, 'display', true)}</td>
        <td>{renderCheckIcon(v.ContactedReportingParty, 'display', true)}</td>
        <td>{v.CreateBy}</td>
      </tr>
    })
  }, [ customerInteractionStatuses, ])

  return <Table striped>
    <thead>
      <tr>
        <th>Status</th>
        <th>Status Date</th>
        <th>Assigned To</th>
        <th>Comment</th>
        <th>Internal Only</th>
        <th>Contacted Reporter</th>
        <th>Created By</th>
      </tr>
    </thead>
    <tbody>{trs}</tbody>
  </Table>
}

StatusTable.propTypes = {
  CustomerInteractionId: oneOfType([ number, string, ]),
}

export default React.memo(StatusTable)
