import React from 'react'

// Components
import NavbarItem from '../NavbarItem'


const SmokeComplaints = () => {
  const markup = <NavbarItem
    to={'/smokecomplaints'}
    text={'Smoke Complaints'}
  />
  return markup
}

export default React.memo(SmokeComplaints)
