// Libraries
import { put, call, takeLatest, select, all, } from 'redux-saga/effects'

// Sagas
import { doFetch, getLookupData, showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { replaceAll, upsertLocalModel, upsertLocalModels, } from './OrmSagas'
import { GetAllRegions, } from './RegionSagas'
import { addressLookup, getRegionByCoord, } from './GeoCoordinateSagas'

// Reducers
import { SearchTypes, } from '../redux/SearchRedux'
import { CustomerInteractionTypes, } from '../redux/CustomerInteractionRedux'

// Models
import CustomerInteractionStatusXref from '../models/CustomerInteractionStatusXref'
import CustomerInteractionStatus from '../models/CustomerInteractionStatus'
import CustomerInteractionType from '../models/CustomerInteractionType'
import CustomerInteraction from '../models/CustomerInteraction'

// Selectors
import { userIsDNR, } from '../selectors/userSelectors'
import {
  associatedBurnsSearchParams,
  smokeComplaintPermitXrefs,
  smokeComplaintAssociationFormIsVisible,
} from '../selectors/customerInteractionSelectors'
import { regionsForSelectSelector, } from '../selectors/regionSelectors'

// Utilities
import { objectToQueryParams, } from '../utilities'

// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const CUSTOMER_INTERACTION_ENDPOINT = CustomerInteraction.endpoint()

export function* getCustomerInteractionLookupData () {
  try {
    yield call(showLoading)

    yield all([
      call(getLookupData, { modelName: CustomerInteractionStatus.modelName, }),
      call(getLookupData, { modelName: CustomerInteractionType.modelName, }),
      call(GetAllRegions),
      call(getLookupData, { modelName: 'FireDistrictDepartment', }),
    ])
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* getCustomerInteraction ({ id, }) {
  try {
    yield call(showLoading)
    const userIsDnr = yield select(userIsDNR)
    if (userIsDnr) {
      yield call(getCustomerInteractionStatuses, { id, })
    }
    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${id}`
    const resp = yield call(doFetch, url)
    if (!resp.ok) {
      yield call(showError, resp.responseBody)
    }
    yield call(upsertLocalModel, CustomerInteraction.modelName, resp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* getCustomerInteractionStatuses ({ id, }) {
  try {
    yield call(showLoading)
    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${id}/Statuses`
    const resp = yield call(doFetch, url)
    if (!resp.ok) {
      yield call(showError, resp.responseBody)
    }
    yield call(upsertLocalModels, CustomerInteractionStatusXref.modelName, resp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* upsertCustomerInteraction ({ values, }) {
  try {
    yield call(showLoading)
    let method = 'POST'
    let url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}`
    const userIsDnr = yield select(userIsDNR)
    let { CustomerInteractionId, } = values
    if (CustomerInteractionId > 0) {
      url += `/${CustomerInteractionId}`
      method = 'PUT'
    }
    if (!values.RegionId) {
      console.warn('[CUSTOMER_INTERACTION] No RegionId found, looking up RegionId based on lat/long')
      if (values.Latitude && values.Longitude) {
        const region = yield call(getRegionByCoord, values.Latitude, values.Longitude)
        if (region) {
          const regions = yield select(regionsForSelectSelector)
          const [ { Value, }, ] = regions.filter(r => r.Text === region.attributes.JURISDICT_LABEL_NM)
          values.RegionId = Value
        }
      }
    }
    delete values.CreateBy
    delete values.CreateDate
    delete values.UpdateBy
    delete values.UpdateDate
    const resp = yield call(doFetch, url, { method, body: values, })
    if (!resp.ok) {
      yield call(showError, resp.responseBody)
    }
    if ('responseBody' in resp && !!resp.responseBody) {
      CustomerInteractionId = resp.responseBody.CustomerInteractionId
      yield call(upsertLocalModel, CustomerInteraction.modelName, resp.responseBody)
    }
    else if (userIsDnr) {
      yield call(getCustomerInteraction, { id: CustomerInteractionId, })
    }
    yield put({ type: CustomerInteractionTypes.UPSERT_CUSTOMER_INTERACTION_SUCCESS, id: CustomerInteractionId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* createCustomerInteractionStatus ({ values, }) {
  try {
    yield call(showLoading)
    const { CustomerInteractionId, } = values
    let method = 'POST'
    let url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${CustomerInteractionId}/Status`
    const resp = yield call(doFetch, url, { method, body: values, })
    if (!resp.ok) {
      yield call(showError, resp.responseBody)
    }
    yield call(upsertLocalModel, CustomerInteractionStatusXref.modelName, resp.responseBody)
    yield put({
      type     : CustomerInteractionTypes.SHOW_CUSTOMER_INTERACTION_STATUS_FORM,
      showForm : false,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* searchCustomerInteractions ({ searchParams, }) {
  try {

    if (!searchParams.submit) {
      console.warn('The property `submit` was not found or not set to true.')
      return
    }
    else if (!searchParams || Object.values(searchParams).every(v => !v)) {
      yield call(showError, 'You must set at least one Customer Interaction search parameter.')
      return
    }

    yield call(showLoading)

    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/Search?${objectToQueryParams(searchParams)}`

    const resp = yield call(doFetch, url)
    if (resp.ok !== true || Array.isArray(resp.responseBody) === false) {
      let error = 'An error searching for Customer Interactions.'
      if (resp.responseBody && 'error' in resp.responseBody) {
        error = resp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield put({
      type    : CustomerInteractionTypes.SEARCH_CUSTOMER_INTERACTIONS_RESULTS,
      results : resp.responseBody,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* findAssociatedBurns ({ searchParams, }) {
  try {

    if (!searchParams.submit) {
      console.warn('The property `submit` was not found or not set to true.')
      return
    }
    if (!searchParams || Object.values(searchParams).every(v => !v)) {
      yield call(showError, 'You must set at least one Burn search parameter.')
      return
    }

    yield call(showLoading)

    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/Search/BurnPermits?${objectToQueryParams(searchParams)}`

    const resp = yield call(doFetch, url)
    if (resp.ok !== true || Array.isArray(resp.responseBody) === false) {
      let error = 'An error searching for Burns.'
      if (resp.responseBody && 'error' in resp.responseBody) {
        error = resp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield call(getBurnPermitXrefs, { id: searchParams.CustomerInteractionId, })
    yield put({
      type : CustomerInteractionTypes.FIND_ASSOCIATED_BURNS_RESULTS,
      data : resp.responseBody,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* getCustomerInteractionBurnPermits ({ id, }) {
  try {
    yield call(showLoading)

    const resp = yield call(doFetch, `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${id}/BurnPermits`)
    if (resp.ok !== true || Array.isArray(resp.responseBody) === false) {
      let error = 'An error getting associated Burn Requests.'
      if (resp.responseBody && 'error' in resp.responseBody) {
        error = resp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield call(getBurnPermitXrefs, { id, })
    yield put({
      type : CustomerInteractionTypes.ASSOCIATED_BURNS,
      data : resp.responseBody,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* getBurnPermitXrefs ({ id, }) {
  try {
    yield call(showLoading)

    const xrefsUrl = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${id}/BurnPermitXrefs`

    const xrefsResp = yield call(doFetch, xrefsUrl)
    if (xrefsResp.ok !== true || Array.isArray(xrefsResp.responseBody) === false) {
      let error = 'An error getting associated Burn Permit Xrefs.'
      if (xrefsResp.responseBody && 'error' in xrefsResp.responseBody) {
        error = xrefsResp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield call(replaceAll, 'CustomerInteractionBurnPermitXref', [ ...xrefsResp.responseBody, ])
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* associateBurnPermit ({ values, }) {
  try {
    yield call(showLoading)

    const { CustomerInteractionId, } = values
    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${CustomerInteractionId}/BurnPermitXref`

    const resp = yield call(doFetch, url, { method: 'POST', body: values, })
    if (resp.ok !== true) {
      let error = 'An error associating Burn Permit.'
      if (resp.responseBody && 'error' in resp.responseBody) {
        error = resp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield call(refreshAssociatedPermits, { id: CustomerInteractionId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* removeBurnPermitAssociation ({ values, }) {
  try {
    yield call(showLoading)
    const { CustomerInteractionId, BurnPermitId, } = values
    let xrefs = yield select(smokeComplaintPermitXrefs, CustomerInteractionId)
    xrefs = xrefs.filter(x => x.BurnPermitId === BurnPermitId)
    if (!xrefs.length) {
      yield call(showError, 'No Customer Interaction Burn Permit Xref found to delete')
      return
    }
    const [ { CustomerInteractionBurnPermitXrefId, }, ] = xrefs
    const url = `${REACT_APP_SERVER_URL}${CUSTOMER_INTERACTION_ENDPOINT}/${CustomerInteractionId}/BurnPermitXref/${CustomerInteractionBurnPermitXrefId}`

    const resp = yield call(doFetch, url, { method: 'DELETE', })
    if (resp.ok !== true) {
      let error = 'An error removing Burn Permit association.'
      if (resp.responseBody && 'error' in resp.responseBody) {
        error = resp.responseBody.error
      }
      yield call(showError, error)
      return
    }
    yield call(refreshAssociatedPermits, { id: CustomerInteractionId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* refreshAssociatedPermits ({ id, }) {
  const formIsActive = yield select(smokeComplaintAssociationFormIsVisible)
  if (formIsActive) {
    const searchParams = yield select(associatedBurnsSearchParams)
    // make sure submit is set to true
    yield call(findAssociatedBurns, { searchParams: { ...searchParams, submit: true, }, })
  }
  else {
    yield call(getCustomerInteractionBurnPermits, { id, })
  }
}

function* geocode ({ address, }) {
  try {
    if (!address) {
      console.warn('No address provided to geocode')
      return
    }

    yield call(showLoading)

    const addrLoc = yield call(addressLookup, { singleLine: address, format: 'json', })
    if (!addrLoc || addrLoc.error) {
      let error = 'Could not geocode customer interaction address'
      if (addrLoc && addrLoc.error) {
        error = addrLoc.error
      }
      yield call(showError, error)
      return
    }
    const [ { location, }, ] = addrLoc.candidates.sort((a, b) => b.score - a.score)
    const region = yield call(getRegionByCoord, location.y, location.x)
    let regionId = null
    if (region) {
      const regions = yield select(regionsForSelectSelector)
      const [ { Value, }, ] = regions.filter(r => r.Text === region.attributes.JURISDICT_LABEL_NM)
      regionId = Value
    }
    yield put({
      type: CustomerInteractionTypes.GEOCODE_CUST_INT_ADDRESS_SUCCESS,
      location,
      regionId,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


export const CustomerInteractionSagas = [
  takeLatest(CustomerInteractionTypes.GET_CUSTOMER_INTERACTION, getCustomerInteraction),
  takeLatest(CustomerInteractionTypes.NEW_CUSTOMER_INTERACTION, getCustomerInteractionLookupData),
  takeLatest(CustomerInteractionTypes.UPSERT_CUSTOMER_INTERACTION, upsertCustomerInteraction),
  takeLatest(CustomerInteractionTypes.CREATE_CUSTOMER_INTERACTION_STATUS, createCustomerInteractionStatus),
  takeLatest(CustomerInteractionTypes.SUBMIT_SMOKE_COMPLAINT, upsertCustomerInteraction),
  takeLatest(CustomerInteractionTypes.SEARCH_CUSTOMER_INTERACTIONS, searchCustomerInteractions),
  takeLatest(CustomerInteractionTypes.FIND_ASSOCIATED_BURNS, findAssociatedBurns),
  takeLatest(CustomerInteractionTypes.GET_ASSOCIATED_BURNS, getCustomerInteractionBurnPermits),
  takeLatest(CustomerInteractionTypes.ASSOCIATE_BURN, associateBurnPermit),
  takeLatest(CustomerInteractionTypes.REMOVE_BURN_ASSOCIATION, removeBurnPermitAssociation),
  takeLatest(CustomerInteractionTypes.GEOCODE_CUST_INT_ADDRESS, geocode),
  takeLatest(SearchTypes.GET_SEARCH_LOOKUP_DATA, getCustomerInteractionLookupData),
]
