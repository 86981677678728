import { attr, Model, many, } from 'redux-orm'
import { dateFormatter, currencyFormatter, } from '../utilities'
import * as Yup from 'yup'


export default class BurnPermitOrder extends Model {
  static get modelName () {
    return 'BurnPermitOrder'
  }

  toString () {
    return `${this.BurnPermitOrderInfoName}`
  }

  static endpoint () {
    return 'BurnPermitOrders'
  }

  static getDataTablesColumns () {
    return [
      {
        title : 'ID',
        data  : 'BurnPermitOrderId',
        width : 25,
      },
      {
        title          : 'Amount',
        data           : 'BurnPermitOrderAmount',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return currencyFormatter.format(data)
        },
        width: 75,
      },
      {
        title          : 'Payment Status',
        data           : null,
        defaultContent : '',
        render         : (data, drawType) => {
          // Either a payment has not yet been made, or a payment was unsuccessful - show the EPay Result Message
          if (drawType === 'display' && data.AllowPayment && !data.ApplicationDetail) {
            return data.EPayResultMessage
          }
          // A successful payment was processed, and an application detail record has not yet been recorded
          else if ((!data.ApplicationDetail || !data.ApplicationDetail.PaymentCommandCode) && !data.AllowPayment && data.PaymentHash) {
            return 'Paid'
          }
          // A successful payment was processed, but there was a return on the application detail
          // There is an Application Detail record for a successful payment
          else if (data.ApplicationDetail && data.ApplicationDetail.PaymentCommandCode) {
            return data.ApplicationDetail.PaymentCommandCode
          }
          return 'Pending'
        },
        width: 100,
      },
      {
        title          : 'Payment Method',
        data           : null,
        width          : 150,
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data.CardType
          }
          if ((!data.CardType || data.CardType.length === 0) && data.EPayReturnCode && !data.AllowPayment) {
            return 'E-Check' 
          }
          else if(data.ApplicationDetail && data.ApplicationDetail.PaymentMethod) {
            return data.ApplicationDetail.PaymentMethod
          }
          return data.CardType
        },
      },
      {
        title          : 'Order Created',
        data           : 'CreateDate',
        type           : 'date',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          return dateFormatter(data, 'MMM DD, YYYY HH:mm:ss')
        },
        width: 150,
      },
      {
        title          : 'Transaction Date',
        data           : null,
        type           : 'date',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data.SettlementSubmissionDate
          }
          const ret = data.ApplicationDetail ? data.ApplicationDetail.TransactionDate : data.SettlementSubmissionDate ? data.SettlementSubmissionDate : data.UpdateDate
          if (ret) {
            return dateFormatter(ret, 'MMM DD, YYYY')
          }
          return ''
        },
        width: 150,
      },
      {
        title          : 'Permits',
        data           : 'BurnPermitOrderXref',
        defaultContent : '',
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data
          }
          let html = 'No permits found'
          if (Array.isArray(data)) {
            html = ''
            for (let i = 0, len = data.length; i < len; i++) {
              const permitId = data[i].BurnPermitId
  
              html += `<a href='/permits/${permitId}'>${permitId}</a>`
            }
          }
          return html
        },
      },
      {
        title          : 'Permit Number',
        data           : 'BurnPermitNumbers',
        defaultContent : '',
      },
      {
        title          : 'Region',
        data           : 'BurnPermitRegions',
        defaultContent : '',
      },
      {
        orderable      : false,
        className      : 'btn-order-pay',
        targets        : 0,
        defaultContent : '',
        data           : null,
        name           : 'pay',
        width          : 50,
        render         : (data, drawType) => {
          if (drawType !== 'display') {
            return data.BurnPermitOrderId
          }
          if (!data.AllowPayment) {
            return `<a class='btn btn-primary btn-sm' href='/permits/orders/${data.BurnPermitOrderId}'>View</a>`
          }
          return `<a class='btn btn-primary btn-sm' href='/permits/orders/${data.BurnPermitOrderId}/pay' data-order-id='${data.BurnPermitOrderId}'>Pay</a>`
        },
      },
    ]
  }
  
  static get options () {
    return {
      idAttribute: 'BurnPermitOrderId',
    }
  }

  static getValidationSchema (isVoucher = false) {
    const valSchema = {
      PaymentMethod: Yup.string()
        .ensure()
        .required(),
      FirstName: Yup.string()
        .ensure()
        .required('You must enter a First Name.')
        .max(25, 'First Name must be 25 characters or fewer.'),
      LastName: Yup.string()
        .ensure()
        .required('You must enter a Last Name.')
        .max(50, 'Last Name must be 50 characters or fewer.'),
      TotalAmountPaid: Yup.number()
        .required('You must enter an amount.')
        .positive('The amount must be a positive number.')
        .min(0.01, 'The amount must be greater than $0.01')
        .max(214748.36, 'The amount must be less than $21,478.36'),
    }

    if (isVoucher) {
      valSchema['VoucherNumber'] = Yup.string()
        .ensure()
        .required('You must enter a Voucher Number.')
        .max(25, 'Voucher Number must be 25 characters or fewer.')
      valSchema['VoucherDate'] = Yup.date().when('PaymentMethod', {
        is        : 'Voucher',
        then      : Yup.date().required('You must enter the Voucher Date.'),
        otherwise : Yup.date().required('You must enter the Payment Date.'),
      })
      return Yup.object().shape(valSchema)
    }

    valSchema['CheckNumber'] = Yup.string().when('PaymentMethod', {
      is   : 'Check',
      then : Yup.string().required('You must enter the Check Number.')
        .min(1, 'Check number must have a value.')
        .max(255, 'Check number must be less than 255 characters.'),
    })
    valSchema['CheckDate'] = Yup.date().when('PaymentMethod', {
      is        : 'Check',
      then      : Yup.date().required('You must enter the Check Date.'),
      otherwise : Yup.date().required('You must enter the Payment Date.'),
    })

    return Yup.object().shape(valSchema)
  }

  static get fields () {
    return {
      // Common Properties
      BurnPermitOrderId : attr(),
      CreateDate        : attr({ getDefault: () => new Date(), }),
      CreateBy          : attr(),
      UpdateDate        : attr({ getDefault: () => new Date(), }),
      UpdateBy          : attr(),
  
      // Model-Specific Properties
      BurnPermitOrderAmount    : attr({ getDefault: () => '', }),
      EPayReturnCode           : attr(),
      EPayResultMessage        : attr(),
      ConfirmationNumber       : attr(),
      TotalAmountPaid          : attr(),
      SettlementSubmissionDate : attr(),
      AuthorizationCode        : attr(),
      CardType                 : attr(),
      PaymentHash              : attr(),
      PaymentCommandCode       : attr(),
      AllowPayment             : attr(),
      // These are pseudo properties that exist in a different 
      // database table Burn_Permit_Payment_Application_Detail
      ApplicationDetail        : attr(),
      /*
        {
          CheckNumber,
          PaymentAmount,
          PaymentCommandCode,
          PaymentMethod,
          TransactionDate,
          FirstName,
          LastName,
        }
      */
      // These are pseudo properties that exist in a different 
      // database table Burn_Permit_Order_Voucher
      BurnPermitOrderVoucher   : attr(),
      /*
        {
          VoucherNumber,
          Comment,
          Contact
        },
      */
      BurnPermitOrderXref      : many({ to: 'BurnPermit', relatedName: 'BurnPermitOrder', through: 'BurnPermitOrderXref', }),
    }
  }
}