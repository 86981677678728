import React, { useMemo, } from 'react'

function DownloadCSV () {
  const style = useMemo(() => ({ 'fontSize': '1.5em', color: 'green', }), [])
  return <span
    className={'fa fa-file-csv ml-2'}
    style={style}
  />
}

export default DownloadCSV