import React, { useMemo, } from 'react'
import { useDispatch, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { DropdownItem, NavLink, } from 'reactstrap'
import { bool, element, object, string, oneOfType, } from 'prop-types'

// Hooks
import useNavClick from '../../hooks/useNavClick'


const NavbarDropdownItem = props => {
  const {
    children,
    disabled,
    href,
    to,
    text,
    title,
  } = props
  
  const itemProps = {
    title: title || text,
    disabled,
  }
  if (to) {
    itemProps.to = to
  }
  if (href) {
    itemProps.href = href
  }

  // Redux actions
  const dispatch = useDispatch()

  const [ ,toggleNavbar, ] = useNavClick(dispatch)

  const dropdownItemStyle = useMemo(() => ({ padding: 0, }), [])

  const markup = <DropdownItem style={dropdownItemStyle}>
    <NavLink
      tag={Link}
      onClick={toggleNavbar}
      {...itemProps}
    >
      {children || text}
    </NavLink>
  </DropdownItem>
  return markup
}

NavbarDropdownItem.defaultProps = {
  style: {},
}

NavbarDropdownItem.propTypes = {
  children : oneOfType([ string, element, ]),
  disabled : bool,
  href     : string,
  style    : object,
  to       : string,
  text     : string,
  title    : string,
}

export default React.memo(NavbarDropdownItem)