// Libraries
import React from 'react'
import { PropTypes, } from 'prop-types'

import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-bs4'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.print'

import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'

const defaultConfig = {
  dom      : '<\'row d-flex flex-direction-row\'<\'col-6 col-md-4\'l><\'col-md-4 table-buttons\'B><\'col-6 col-md-4\'f>>rtip',
  rowId    : '_fields.id',
  language : { 
    search            : '_INPUT_',
    searchPlaceholder : 'Filter',
  },
  scrollX    : true,
  lengthMenu : [ [ 10, 25, 50, 100, -1, ], [ 10, 25, 50, 100, 'All', ], ],
  stateSave  : true,
  buttons    : [ 'colvis', ],
}


const SimpleDataTable = ({
  elementId = '',
  className = '',
  width = '100%',
  config = null,
  children,
}) => {
  const tableRef = React.useRef(null)
  const dataTableRef = React.useRef(null)
  let data = config && config.data ? config.data : null

  React.useEffect(() => {
    const table = tableRef, dataTable = dataTableRef
    return () => {
      if (dataTable.current) {
        dataTable.current.destroy({ remove: false, })
        dataTable.current = null
      } 
      table.current = null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!data || !dataTableRef || !dataTableRef.current) {
      return
    }
    const dt = dataTableRef.current
    dt.clear()
    dt.rows.add(data)
    dt.draw()
  }, [ data, ])

  React.useEffect(() => {
    const table = tableRef, dataTable = dataTableRef
    if (!table.current || dataTable.current) {
      return
    }

    if (config) {
      dataTable.current = $(table.current).DataTable({ 
        ...defaultConfig,
        language: { ...defaultConfig.language, },
        ...config,
      })
    } else {
      dataTable.current = $(table.current).DataTable({ 
        ...defaultConfig,
      })
    }
  }, [ config, ])

  return (
    <table
      id={elementId}
      className={`table table-striped ${className}`}
      ref={tableRef}
      style={{ width, }}
    >{children}</table>
  )
}

SimpleDataTable.propTypes = {
  elementId : PropTypes.string.isRequired,
  className : PropTypes.string,
  config    : PropTypes.object,
  children  : PropTypes.node,
  width     : PropTypes.string,
}

export default React.memo(SimpleDataTable)
