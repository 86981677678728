// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEmpty, } from 'lodash'
import { object, number, array, func, bool, } from 'prop-types'
import { Formik, Field, } from 'formik'
import { Col, FormGroup, Label, Row, } from 'reactstrap'

// Actions
import BurnPermitLocationActions from '../../redux/BurnPermitLocationRedux'
import GeoCoordinateActions from '../../redux/GeoCoordinateRedux'

// Components
import Effect from '../Effect'
import { ValidatingField, Select, } from '../FormControls'

// Models
import BurnPermitLocation from '../../models/BurnPermitLocation'

// Selectors
import {
  directionsForSelectSelector,
  locationQuartersSelectSelector,
  permitLocationLegalDesc,
} from '../../selectors/selectors'

// Utilities
import { objHasProp, } from '../../utilities'
import retry from '../../utilities/retry'


class PLSSForm extends React.Component {
  constructor (props) {
    super(props)
    this.validateForm = this.validateForm.bind(this)
    this.hasValues = this.hasValues.bind(this)
  }
  static propTypes = {
    TRSResult                         : object,
    permitLocation                    : object,
    BurnPermitLocationId              : number,
    quarters                          : array,
    directions                        : array,
    GetLocationLookupData             : func,
    UpdateBurnPermitLocationLegalDesc : func,
    ClearTRSResult                    : func,
    GetTRSExtent                      : func,
    onChange                          : func,
    readOnly                          : bool,
    online                            : bool,
    TRSCodeResult                     : object,
  }

  static defaultProps = {
    readOnly: false,
  }

  componentDidMount () {
    const { online, permitLocation, GetLocationLookupData, } = this.props
    if (online) {
      GetLocationLookupData()
      this.getTRSExtent(permitLocation)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.TRSCodeResult !== this.props.TRSCodeResult && this.props.TRSCodeResult && this.formik) {
      this.formik.setValues({
        ...this.formik.values,
        ...this.props.TRSCodeResult,
      })
      const touchedFields = Object.entries(this.props.TRSCodeResult).reduce((prev, curr) => {
        prev[curr] = true
        return prev
      }, {})
      this.formik.setTouched(touchedFields)
    }
  }

  async validateForm () {
    if (!this.formik) {
      const isValid = await retry(this.validateForm, null, 100)
      return isValid
    }
    return await this.formik.validateForm()
  }

  isFormValid = () => {
    if (!this.formik) {
      setTimeout(() => this.isFormValid, 100)
      return
    }
    return this.formik.isValid
  }

  setTRSWithFeature = (feature) => {
    if (!feature || !feature.attributes && !feature.properties) {
      return
    }
    let attrs = feature.attributes ? feature.attributes : feature.properties ? feature.properties : null
    if (!attrs) {
      return
    }

    const { directions, } = this.props
    const { SECTION_NR, TOWNSHIP_NR, RANGE_NR, RANGE_DIR_CD, } = attrs
    const values = { ...this.formik.values, } 
    if (RANGE_DIR_CD) {
      const dirId = directions.filter(d => d.Abbrev.toLowerCase() === RANGE_DIR_CD.toLowerCase())[0]
      values.LegalDescriptionDirectionId = dirId ? dirId.Value : ''
    }
    values.LegalDescriptionQuarter1 = 'None'
    values.LegalDescriptionQuarter2 = 'None'
    values.LegalDescriptionTownship = TOWNSHIP_NR || ''
    values.LegalDescriptionSection = SECTION_NR || ''
    values.LegalDescriptionRange = RANGE_NR || ''
    if (feature.geometry) {
      values.LegalDescriptionLocation = JSON.stringify(feature.geometry)
    }
    this.formik.setValues(values)
  }

  getTRSExtent = (values) => {
    if (!this.props.online || isEmpty(values) || values.LegalDescriptionLocation || typeof values === 'boolean') {
      return
    }

    const {
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
    } = values

    const legalDesc = {
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
    }

    if (Object.values(legalDesc).every(v => !!v)) {

      const { GetTRSExtent, directions, } = this.props

      const directionAbbrev = directions.find(d => d.Value.toString() === LegalDescriptionDirectionId.toString())
      if (objHasProp(directionAbbrev, 'Abbrev')) {
        GetTRSExtent(LegalDescriptionSection, LegalDescriptionTownship, LegalDescriptionRange, directionAbbrev.Abbrev)
      }
    }
    else {
      this.props.ClearTRSResult()
    }
  }

  submitForm = () => {
    this.formik.submitForm()
  }

  submit = (values) => {
    const {
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
      LegalDescriptionQuarter1,
      LegalDescriptionQuarter2,
      LegalDescriptionLocation,
    } = values

    const { UpdateBurnPermitLocationLegalDesc, BurnPermitLocationId, TRSResult, } = this.props
    const burnPermitLocation = {
      BurnPermitLocationId,
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
      LegalDescriptionQuarter1,
      LegalDescriptionQuarter2,
    }
    if (TRSResult && TRSResult.features.length) {
      // if we geocoded on mount
      burnPermitLocation.LegalDescriptionLocation = JSON.stringify(TRSResult.features[0].geometry)
    } else if (LegalDescriptionLocation) {
      // if we geocoded to get the plss
      burnPermitLocation.LegalDescriptionLocation = LegalDescriptionLocation
    }
    UpdateBurnPermitLocationLegalDesc(burnPermitLocation)
  }

  onChange = (changedValues, currValues) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(changedValues, currValues)
    }
    this.getTRSExtent(currValues)
  }

  hasValues () {
    if (!this.formik) {
      setTimeout(() => this.hasValues(), 100)
      return
    }
    return Object.values(this.formik.values).every(v => {
      return !!v
    })
  }

  setFormikNode = node => this.formik = node

  render () {
    const { readOnly, permitLocation, } = this.props
    const {
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
      LegalDescriptionQuarter1,
      LegalDescriptionQuarter2,
    } = permitLocation

    const legalDesc = {
      LegalDescriptionSection,
      LegalDescriptionTownship,
      LegalDescriptionRange,
      LegalDescriptionDirectionId,
      LegalDescriptionQuarter1,
      LegalDescriptionQuarter2,
    }
    return (
      <Formik
        initialValues={legalDesc}
        enableReinitialize={true}
        validationSchema={BurnPermitLocation.getValidationSchema({ plss: true, })}
        onSubmit={this.submit}
        innerRef={this.setFormikNode}
      >
        {({ values, }) => (
          <Row>
            <Effect values={values} onChange={changedValues => this.onChange(changedValues, values)} />
            <Col md={'2'}>
              <FormGroup>
                <Field name={'LegalDescriptionQuarter1'}>
                  {({ field, form, }) => (
                    <Select
                      label={'Quarter'}
                      items={this.props.quarters}
                      propertyName={field.name}
                      {...field}
                      selectedValue={field.value || -1}
                      errorMessage={form.errors[field.name]}
                      readOnly={readOnly}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
            <Col md={'2'}>
              <FormGroup>
                <Field name={'LegalDescriptionQuarter2'}>
                  {({ field, form, }) => (
                    <Select
                      label={'Quarter'}
                      items={this.props.quarters}
                      propertyName={field.name}
                      {...field}
                      selectedValue={field.value || -1}
                      errorMessage={form.errors[field.name]}
                      readOnly={readOnly}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
            <Col md={'2'}>
              <FormGroup>
                <Label for={'LegalDescriptionSection'}>Section</Label>
                <Field
                  id={'LegalDescriptionSection'}
                  name={'LegalDescriptionSection'}
                  type={'number'}
                  placeholder={'26'}
                  min={BurnPermitLocation.fields.LegalDescriptionSection.opts.min}
                  max={BurnPermitLocation.fields.LegalDescriptionSection.opts.max}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col md={'2'}>
              <FormGroup>
                <Label for={'LegalDescriptionTownship'}>Township</Label>
                <Field
                  id={'LegalDescriptionTownship'}
                  name={'LegalDescriptionTownship'}
                  type={'number'}
                  placeholder={'36'}
                  min={BurnPermitLocation.fields.LegalDescriptionTownship.opts.min}
                  max={BurnPermitLocation.fields.LegalDescriptionTownship.opts.max}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col md={'2'}>
              <FormGroup>
                <Label for={'LegalDescriptionRange'}>Range</Label>
                <Field
                  id={'LegalDescriptionRange'}
                  name={'LegalDescriptionRange'}
                  type={'number'}
                  placeholder={'27'}
                  min={BurnPermitLocation.fields.LegalDescriptionRange.opts.min}
                  max={BurnPermitLocation.fields.LegalDescriptionRange.opts.max}
                  readOnly={readOnly}
                  component={ValidatingField}
                />
              </FormGroup>
            </Col>
            <Col md={'2'}>
              <FormGroup>
                <Field name={'LegalDescriptionDirectionId'}>
                  {({ field, form, }) => (
                    <Select
                      label={'Direction'}
                      items={this.props.directions}
                      propertyName={field.name}
                      {...field}
                      selectedValue={field.value || -1}
                      errorMessage={form.errors[field.name]}
                      readOnly={readOnly}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Formik>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const directions = directionsForSelectSelector(state, (d => [ 'E', 'W', ].includes(d.DirectionAbbreviation)))
  const { online, } = state.offline
  return {
    online,
    directions,
    permitLocation : permitLocationLegalDesc(state, ownProps.BurnPermitLocationId),
    quarters       : locationQuartersSelectSelector(state),
    TRSResult      : state.GeoCoordinate.TRSResult,
    TRSCodeResult  : state.GeoCoordinate.TRSCodeResult,
  }
}

const mapDispatchToProps = {
  GetLocationLookupData             : BurnPermitLocationActions.getLocationLookupData,
  UpdateBurnPermitLocationLegalDesc : BurnPermitLocationActions.updateBurnPermitLocationLegalDesc,
  GetTRSExtent                      : GeoCoordinateActions.getTRSExtent,
  ClearTRSResult                    : GeoCoordinateActions.clearTRSResult,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, })(PLSSForm)