// Libraries
import React from 'react'
import { useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Button, } from 'reactstrap'

// Actions
import BurnPermitSignatureActions from '../../../../redux/BurnPermitSignatureRedux'

// Components
import OnlinePopover from '../OnlinePopover'

// Selectors
import {
  activeBurnPermitIdSelector,
  documentsSectionIsValid,
} from '../../../../selectors/burnPermitSelectors'
import { permitSignatureState, signatureByPermitId, } from '../../../../selectors/burnPermitSignatureSelectors'
import { appIsOnlineSelector, } from '../../../../selectors/selectors'

// Utilities
import stopEvent from '../../../../utilities/stopEvent'


const SignPermit = () => {

  const online = useSelector(appIsOnlineSelector)
  const burnPermitId = useSelector(activeBurnPermitIdSelector)
  const documentsSection = useSelector(state => documentsSectionIsValid(state, burnPermitId))
  const { PermitEnvelopeId, } = useSelector(state => signatureByPermitId(state, burnPermitId))
  const { isLoadingDocuSignUrl, } = useSelector(permitSignatureState)

  const dispatch = useDispatch()

  const onSignPermitClick = useCallback(e => {
    stopEvent(e)

    if (isLoadingDocuSignUrl) {
      return
    }

    dispatch(BurnPermitSignatureActions.signPermit(burnPermitId))
  }, [ dispatch, burnPermitId, isLoadingDocuSignUrl, ])

  if (!PermitEnvelopeId) {
    return <p>Waiting for the Approver to sign.</p>
  }
  const markup = <>
    <Button
      className={'mr-3'}
      onClick={onSignPermitClick}
      disabled={!online || !documentsSection.isValid}
      key={'sign-permit'}
    >
      Sign
    </Button>
    <OnlinePopover key={'download-permit-online'} />
  </>
  return markup
}


export default React.memo(SignPermit)
