// Libraries
import React from 'react'
import { Link, } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, CardText, } from 'reactstrap'

const colSize = { size: '6', offset: '3', }

const PermitFailedToLoad = () => {
  return (
    <Container>
      <Row className={'mt-4'}>
        <Col md={colSize}>
          <Card>
            <CardBody>
              <CardText>
                The Burn Permit Application failed to load.
                One of the following may have occurred:
              </CardText>
              <ul>
                <li>Your <Link to={'/Profile'}>Profile</Link> is incomplete and you have not identified your Person Type.</li>
                <li>Your User verification is pending.</li>
                <li>You do not have access to the Burn Permit Application.</li>
                <li>The Burn Permit Application no longer exists.</li>
                <li>You are operating offline and did not download the Burn Permit Application.</li>
              </ul>
              <CardText>Click the button below to navigate to your Permits list.</CardText>
            </CardBody>
          </Card>
          <div className={'d-flex justify-content-end mt-2'}>
            <Link to={'/permits'} className={'btn btn-md btn-secondary'}>View Permits</Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(PermitFailedToLoad)