// Libraries
import React, { useCallback, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Label, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap'

// Actions
import AppActions from '../../redux/AppRedux'
import MapActions from '../../redux/MapRedux'

// Components
import { CheckBoxes, } from '../FormControls'

// Selectors
import { layerConfigsSelector, mapLayerIdsSelector, } from '../../selectors/mapSelectors'


const MapLayers = () => {

  const dispatch = useDispatch()

  const layerConfigs = useSelector(layerConfigsSelector)
  const mapLayerIds = useSelector(mapLayerIdsSelector)

  const [ activeTab, setActiveTab, ] = useState(layerConfigs[0].name)

  const mapLayerTabClick = useCallback(e => {
    const { configname, } = e.target.dataset
    // If we're already on this tab, don't fire off the action
    // which will trigger a re-render
    if (configname === activeTab) {
      return
    }
    setActiveTab(configname)
  }, [ setActiveTab, activeTab, ])

  const getClassName = useCallback(configname => {
    if (activeTab === configname) {
      return 'active'
    }
    return ''
  }, [ activeTab, ])

  const checkboxData = useCallback(config => {
    const data = mapLayerIds
      .filter(l =>
        config.unavailableLayers.includes(l.Value) === false
      )
      // Create a copy of the items so any mutatation do not leak 
      // across calls to this callback
      .map(l => ({ ...l, }))
    if (Array.isArray(config.requiredLayers)) {
      return data.map(l => {
        l.readOnly = config.requiredLayers.includes(l.Value)
        return l
      })
    }
    return data
  }, [ mapLayerIds, ])

  const getCheckedData = useCallback(config => {
    const checkedData = checkboxData(config)
      .filter(l => 
        config.hiddenLayers.includes(l.Value) === false
      )
      .map(l => l.Value)
    return checkedData
  }, [ checkboxData, ])

  const onCheckboxChange = useCallback(e => {
    dispatch(AppActions.hideSuccess())
    const { value, checked, } = e.target
    if (value === 'all') {
      const config = layerConfigs.filter(c => c.name === activeTab)[0]
      const layers = checkboxData(config)
        .filter(d => d.readOnly !== true)
        .map(d => d.Value)
      dispatch(MapActions.toggleAllConfigLayerVisibility(activeTab, layers, checked))
    }
    else {
      dispatch(MapActions.setConfigLayerVisibility(activeTab, value, checked))
    }
    setTimeout(() => dispatch(AppActions.showSuccess()), 100)
  },[ dispatch, activeTab, checkboxData, layerConfigs, ])

  return <>
    <Label>Default Map Layers</Label>
    <Nav tabs>
      {
        layerConfigs.map((config, idx) => {
          return <NavItem key={`map-layer-${idx}`}>
            <NavLink
              data-configname={config.name}
              className={getClassName(config.name)}
              onClick={mapLayerTabClick}
            >{config.name}</NavLink>
          </NavItem>
        })
      }
    </Nav>
    <TabContent activeTab={activeTab}>
      {
        layerConfigs.map((config, idx) => {
          return <TabPane
            tabId={config.name}
            key={`map-layer-tab-${idx}`}
            className={'p-4'}
          >
            <small>
              <i className={'text-danger'}>*</i>&nbsp;
              Items that are checked and read only are required by the application to provide added functionality
            </small>
            <CheckBoxes
              CheckboxData={checkboxData(config)}
              CheckedData={getCheckedData(config)}
              inputName={`map-layer-tab-${config.name}`}
              className={'mt-2'}
              showSelectAll={true}
              showLegend={false}
              onCheckboxChange={onCheckboxChange}
            />
          </TabPane>
        })
      }
    </TabContent>
  </>
}

export default React.memo(MapLayers)
