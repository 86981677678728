import React from 'react'
import { FormGroup, Label, Button, ButtonGroup, } from 'reactstrap'
import { string, func, } from 'prop-types'

import { PopoverButton, } from '../FormControls'


export const ForestHealth = ({ IsForestHealthExempt, onChange, }) => {
  return <FormGroup>
    <Label>
      Forest Health Exempt?
      <PopoverButton
        buttonClassName={'btn-icon px-0 ml-1 mb-1'}
        popoverHeader={'Is Forest Health Exempt'}
        popoverBody={'Use this control to find Applications and Permits for Forest Health Exemption burning.'}
      />
    </Label>
    <br/>
    <ButtonGroup>
      <Button color={'light'} name={'IsForestHealthExempt'} onClick={onChange} value={'true'} active={IsForestHealthExempt === 'true'}>Yes</Button>
      <Button color={'light'} name={'IsForestHealthExempt'} onClick={onChange} value={'false'} active={IsForestHealthExempt === 'false'}>No</Button>
      <Button color={'light'} name={'IsForestHealthExempt'} onClick={onChange} value={''} active={IsForestHealthExempt === ''}>All</Button>
    </ButtonGroup>
  </FormGroup>
}

ForestHealth.propTypes = {
  IsForestHealthExempt : string,
  onChange             : func,
}

ForestHealth.defaultProps = {
  IsForestHealthExempt : '',
  onChange             : () => {},
}

export default ForestHealth