// Libraries
import React, { useCallback, useMemo, useState, useRef, } from 'react'
import ImageGallery from 'react-image-gallery'
import {
  Card,
  CardBody,
  CardText,
} from 'reactstrap'

const imageDir = '/images/property/unimproved'
const defaultOriginalHeight = 350

const UnimprovedPropertyCard = () => {

  const [ originalHeight, setOriginalHeight, ] = useState(defaultOriginalHeight)
  const [ imageIdx, setImageIdx, ] = useState(0)

  const galleryRef = useRef()

  const setGalleryRef = useCallback(node => {
    if (node) {
      galleryRef.current = node
    }
    return () => {
      if (galleryRef && galleryRef.current) {
        galleryRef.current = null
      }
    }
  }, [])

  const screenChange = useCallback(isFullScreen => {
    let newHeight = defaultOriginalHeight
    if (isFullScreen) {
      newHeight = null
    }
    setOriginalHeight(newHeight)
    galleryRef.current.slideToIndex(imageIdx)
  }, [ imageIdx, ])

  const images = useMemo(() => ([
    {
      original   : `${imageDir}/SONH_04_350.jpg`,
      originalHeight,
      thumbnail  : `${imageDir}/SONH_04_tn.jpg`,
      fullscreen : `${imageDir}/SONH_04.jpg`,
    },
    {
      original   : `${imageDir}/EOSP_04_350.jpg`,
      originalHeight,
      thumbnail  : `${imageDir}/EOSP_04_tn.jpg`,
      fullscreen : `${imageDir}/EOSP_04.jpg`,
    },
    {
      original   : `${imageDir}/EOSG_04_350.jpg`,
      originalHeight,
      thumbnail  : `${imageDir}/EOSG_04_tn.jpg`,
      fullscreen : `${imageDir}/EOSG_04.jpg`,
    },
  ]), [ originalHeight, ])

  return <>
    <h1>Unimproved Property</h1>
    <Card>
      <CardBody>
        <CardText>Is the debris you want to burn from an unimproved property?</CardText>
        <CardText>
          If your burnable debris comes from property as seen in these pictures, 
          it is likely permittable by Department of Natural Resources.
        </CardText>
        <ImageGallery
          ref={setGalleryRef}
          items={images}
          onScreenChange={screenChange}
          onSlide={setImageIdx}
        />
      </CardBody>
    </Card>
  </>
}

export default React.memo(UnimprovedPropertyCard)