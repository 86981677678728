/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { isEqual, isEmpty, } from 'lodash'
import { func, array, object, number, bool, } from 'prop-types'
import { Container, Row, Col, Card, CardBody, Input, Button, Alert, } from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'
import MergeActions from '../redux/MergeRedux'
import PersonActions from '../redux/PersonRedux'

// Components
import { AutoComplete, PopoverButton, } from '../components/FormControls'
import { MergeForm, } from '../components/Forms'

// Selectors
import { peopleForMergeSelect, allPeopleForMerge, personForMerge, } from '../selectors/personSelectors'

// Utilities
import stopEvent from '../utilities/stopEvent'


const MergePermitsWarning = ({ mergedPerson, }) => {
  if (
    !mergedPerson
    || ('Permits' in mergedPerson) === false
    || !Array.isArray(mergedPerson.Permits.Text)
    || !mergedPerson.Permits.Warning
  ) {
    return null
  }
  return <Alert color={'warning'}>{mergedPerson.Permits.Warning}</Alert>
}

MergePermitsWarning.propTypes = {
  mergedPerson: object,
}

export class MergePersonContainer extends React.Component {
  
  constructor (props) {
    super(props)

    this.props.GetDataForPeopleMerge()
    this.props.SetPageTitle('Merge People')

    const initialState = {
      personOne       : '',
      personTwo       : '',
      personTwoSearch : '',
      mergedPerson    : null,
      checkAllTarget  : null,
      checkedIds      : [],
      focusField      : '',
    }

    this.initialState = { ...initialState, }
    this.state = { ...initialState, }

    this.checkAll = this.checkAll.bind(this)
    this.resetChoices = this.resetChoices.bind(this)
  }

  static propTypes = {
    history               : object,
    PeopleForSelect       : array,
    People                : array,
    SetPageTitle          : func,
    GetDataForPeopleMerge : func,
    GetPersonDataForMerge : func,
    MergePeople           : func,
    ClearPersonIds        : func,
    PersonOneId           : number,
    PersonOneObj          : object,
    PersonTwoId           : number,
    PersonTwoObj          : object,
    SetPersonOneId        : func,
    SetPersonTwoId        : func,
    isFetchingData        : bool,
  }

  minCharactersToEnter = 3

  componentDidMount () {
    if (!isEmpty(this.props.PersonOneObj)) {
      const personOne = {
        value : this.props.PersonOneId,
        label : `${this.props.PersonOneId}: ${this.props.PersonOneObj.PersonFirstName.Value} ${this.props.PersonOneObj.PersonLastName.Value}`,
      }
      this.setState({
        personOne,
        personTwoSearch: this.props.PersonOneObj.PersonLastName.Value,
      }, () => {
        this.updateSelectedPerson('personOne', this.props.PersonOneId, this.props.PersonOneObj)
      })
    }
  }

  componentWillUnmount () {
    this.props.ClearPersonIds()
  }

  componentDidUpdate (prevProps) {
    const { PersonOneId, PersonOneObj, PersonTwoId, PersonTwoObj, } = this.props
    if (!isEqual(prevProps.PersonOneObj, PersonOneObj) && !isEmpty(PersonOneObj)) {
      this.updateSelectedPerson('personOne', PersonOneId, PersonOneObj, true)
      // Force the reset of PersonTwo if they change PersonOne and PersonTwo exists
      if (PersonOneId > 0 && !isEmpty(PersonTwoObj)) {
        this.updateSelectedPerson('personTwo', PersonTwoId, PersonTwoObj, true)
      }
    }
    if (!isEqual(prevProps.PersonTwoObj, PersonTwoObj) && !isEmpty(PersonTwoObj)) {
      // Force the reset of PersonOne if they change PersonTwo and PersonOne exists
      if (PersonOneId > 0 && !isEmpty(PersonOneObj)) {
        this.updateSelectedPerson('personOne', PersonOneId, PersonOneObj, true)
      }
      this.updateSelectedPerson('personTwo', PersonTwoId, PersonTwoObj)
    }
  }

  personChange = evt => {
    const { value, name, } = evt.target
    const newState = { ...this.state, }
    newState[name] = value
    if (!value) {
      newState[`${name}Obj`] = null
      if (!newState.personOneObj && !newState.personTwoObj) {
        newState.mergedPerson = null
      }
    }
    
    this.setState(newState)
  }

  updateMergedPersonArrays = (mergedPerson) => {
    const { PersonOneObj, PersonTwoObj, } = this.props

    mergedPerson.Addresses.Text = [ ...(PersonOneObj ? PersonOneObj.Addresses.Text : []), ...(PersonTwoObj ? PersonTwoObj.Addresses.Text: []), ]
    mergedPerson.Addresses.Value = [ ...(PersonOneObj ? PersonOneObj.Addresses.Value : []), ...(PersonTwoObj ? PersonTwoObj.Addresses.Value: []), ]

    mergedPerson.Phones.Text = [ ...(PersonOneObj ? PersonOneObj.Phones.Text : []), ...(PersonTwoObj ? PersonTwoObj.Phones.Text: []), ]
    mergedPerson.Phones.Value = [ ...(PersonOneObj ? PersonOneObj.Phones.Value : []), ...(PersonTwoObj ? PersonTwoObj.Phones.Value: []), ]

    mergedPerson.Permits.Warning = PersonOneObj ? PersonOneObj.Permits.Warning : PersonTwoObj ? PersonTwoObj.Permits.Warning : null
    mergedPerson.Permits.Text = [ ...(PersonOneObj ? PersonOneObj.Permits.Text : []), ...(PersonTwoObj ? PersonTwoObj.Permits.Text: []), ]
    mergedPerson.Permits.Value = [ ...(PersonOneObj ? PersonOneObj.Permits.Value : []), ...(PersonTwoObj ? PersonTwoObj.Permits.Value: []), ]

    return { ...mergedPerson, }
  }

  getEmptyPerson = person => {
    const personClone = { ...person, }
    const keys = Object.keys(personClone)
    const newPerson = { }
    for(let i = 0, len = keys.length; i < len; i++) {
      const personProp = keys[i]
      const personValue = personClone[personProp]
      newPerson[personProp] = { ...personValue, }
      newPerson[personProp].Text = Array.isArray(personValue.Text) ? [] : null
      newPerson[personProp].Value = Array.isArray(personValue.Value) ? [] : null
      newPerson[personProp].Warning = ''
    }
    newPerson.Addresses = { Label: 'Addresses', Text: [], Value: [], }
    newPerson.Phones = { Label: 'Phones', Text: [], Value: [], }
    return newPerson
  }

  personOneSelect = ({ label, value, }) => {
    this.props.GetPersonDataForMerge(value)
    this.props.SetPersonOneId(value)
    const mergedPerson = this.getEmptyPerson(this.state.mergedPerson)
    this.setState({
      personOne      : { label, value, },
      mergedPerson,
      checkAllTarget : '',
      checkedIds     : [],
      focusField     : 'personTwo',
    })
  }

  personTwoSelect = ({ label, value, }) => {
    this.props.GetPersonDataForMerge(value)
    this.props.SetPersonTwoId(value)
    const mergedPerson = this.getEmptyPerson(this.state.mergedPerson)
    // Clear it out so it can be focused again if someone changes the first person input
    this.setState({
      personTwo      : { label, value, },
      mergedPerson,
      checkAllTarget : '',
      checkedIds     : [],
      focusField     : '',
    })
  }

  updateSelectedPerson = (personKey, personId, personObj, forceUpdate = false)  => {
    if (personObj === null) {
      return
    }
    let { mergedPerson, } = this.state
    if (isEmpty(mergedPerson) || forceUpdate) {
      mergedPerson = this.getEmptyPerson(personObj)
    }

    mergedPerson = this.updateMergedPersonArrays(mergedPerson)
    const newState = { mergedPerson: { ...mergedPerson, }, }
    newState[`${personKey}Id`] = personId
    newState[`${personKey}Obj`] = { ...personObj, }
    this.setState(newState)
  }

  setMergedValue = evt => {
    const { mergedPerson, } = this.state
    const { id, name, value, dataset, } = evt.target
    mergedPerson[name] = { Value: value, Label: dataset.label, Text: dataset.text, }
    let checkedIds = [ ...this.state.checkedIds, ]
    const checkedInputIdx = checkedIds.findIndex(c => c.indexOf(name) > -1)
    if (checkedInputIdx > -1) {
      checkedIds.splice(checkedInputIdx, 1)
    }
    if (name === 'PersonId') {
      const { personOneId, personOneObj, personTwoId, personTwoObj, } = this.state
      if (personOneId === parseInt(value)) {
        mergedPerson['UserName'] = { ...personOneObj.UserName, }
      }
      else if (personTwoId === parseInt(value)) {
        mergedPerson['UserName'] = { ...personTwoObj.UserName, }
      }
    }
    this.setState({
      mergedPerson,
      checkAllTarget : null,
      checkedIds     : [ ...checkedIds, id, ],
    }, this.checkForMismatchingPersonType)
  }

  reset = evt => {
    stopEvent(evt)
    this.setState({ ...this.initialState, }, this.props.ClearPersonIds)
  }

  resetChoices (evt) {
    stopEvent(evt)

    // Clear the selected inputs for the ultimate merged person
    let { mergedPerson, } = this.state
    mergedPerson = this.getEmptyPerson(mergedPerson)
    // But restore the addresses and phones
    mergedPerson = this.updateMergedPersonArrays(mergedPerson)
    
    // Clear out any warnings
    const { personOneObj, personTwoObj, } = this.state
    const keys = Object.keys(mergedPerson)
    for(let i = 0, len = keys.length; i < len; i++) {
      const personProp = keys[i]
      personOneObj[personProp].Warning = ''
      personTwoObj[personProp].Warning = ''
    }
    
    this.setState({
      checkAllTarget : null,
      checkedIds     : [],
      mergedPerson,
      personOneObj,
      personTwoObj,
    })
  }

  mergePeople = evt => {
    stopEvent(evt)
    const { mergedPerson, personOneId, personTwoId, } = this.state
    
    const personKeys = Object.keys(mergedPerson)
    const person = personKeys.reduce((p, k) => {
      p[k] = mergedPerson[k].Value
      return p
    }, {})
    // Identify which person Id will be tagged as being merged
    // The person id that is not in the merged person object will be the one to updated to be tagged
    // as merged and will be readonly from here on out.
    const mergedPersonId = parseInt(mergedPerson.PersonId.Value) === parseInt(personOneId) ? personTwoId : personOneId
    this.props.MergePeople(person, mergedPersonId)
  }

  checkForMismatchingPersonType = () => {
    const { personOneObj, personTwoObj, mergedPerson, } = this.state
    let warning = null
    if (personOneObj['PersonTypeId'].Text !== personTwoObj['PersonTypeId'].Text) {
      warning = 'Warning: You are choosing to merge People of different types.'
      personOneObj['PersonTypeId'].Warning = warning
      personTwoObj['PersonTypeId'].Warning = warning
      mergedPerson['PersonTypeId'].Warning = warning
      this.setState({ personOneObj, personTwoObj, mergedPerson, })
    }
    else if (personOneObj['PersonTypeId'].Warning || personTwoObj['PersonTypeId'].Warning) {
      personOneObj['PersonTypeId'].Warning = warning
      personTwoObj['PersonTypeId'].Warning = warning
      mergedPerson['PersonTypeId'].Warning = warning
      this.setState({ personOneObj, personTwoObj, mergedPerson, })
    }
  }

  checkAll (evt) {
    stopEvent(evt)
    const { target, } = evt.target.dataset
    const mergedPerson = {
      ...this.state[target],
      Addresses : this.state.mergedPerson.Addresses,
      Phones    : this.state.mergedPerson.Phones,
      Permits   : this.state.mergedPerson.Permits,
    }
    const keys = Object.keys(mergedPerson)
    // When choosing all props from one object, default any null
    // values to be empty strings
    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i]
      if (mergedPerson[key].Value !== null) {
        continue
      }
      mergedPerson[key].Value = ''
    }
    const objId = mergedPerson.PersonId.Value
    const checkedIds = keys.map(k => `${k}-${objId}`)
    this.setState({
      checkAllTarget: target,
      mergedPerson, checkedIds,
    }, this.checkForMismatchingPersonType)
  }

  mergedPersonInput = mergedPerson => {
    let text = ''
    if (!mergedPerson) {
      return text
    }
    if (mergedPerson.PersonId && mergedPerson.PersonId.Value) {
      text += mergedPerson.PersonId.Value + ': '
    }
    if (mergedPerson.PersonFirstName && mergedPerson.PersonFirstName.Value) {
      text += mergedPerson.PersonFirstName.Value + ' '
    }
    if (mergedPerson.PersonLastName && mergedPerson.PersonLastName.Value) {
      text += mergedPerson.PersonLastName.Value
    }
    return text
  }

  render () {
    const {
      personOne,
      personTwo,
      personTwoSearch,
      mergedPerson,
      checkAllTarget,
      checkedIds,
    } = this.state
    const {
      PeopleForSelect,
      PersonOneId,
      PersonOneObj,
      PersonTwoId,
      PersonTwoObj,
      isFetchingData,
    } = this.props

    // Once all values are set, then merging is enabled
    let someValuesAreNotChosen = true
    if (mergedPerson && !isEmpty(mergedPerson)) {
      someValuesAreNotChosen = Object.entries(mergedPerson).some(e => e[1].Value === null)
    }

    if (!personOne && PersonOneId > 0) {
      return null
    }

    return <Container className={'my-3 merge-container'}>
      <h1><Link to={'/admin/people'}>People</Link> - Merge</h1>
      <Card>
        <CardBody>
          <p>
            Use the autocomplete controls below to locate the two Person records you wish to merge. All related data such as Addresses and Phones will be automatically merged.
            Once these records are merged, <b>they cannot be unmerged</b>. Only Person records that are not associated with a DNR user can be merged.
          </p>
          <Row>
            <Col xs={4}>
              <h4>Find Person 1</h4>
              <AutoComplete
                autoFocus={!PersonOneObj}
                fieldName={'personOne'}
                items={PeopleForSelect.filter(p => p.Value !== PersonTwoId)}
                value={personOne}
                onChange={this.personOneSelect}
                minCharactersToEnter={this.minCharactersToEnter}
              />
              {
                PersonOneId && PersonOneObj &&
                  <MergeForm
                    id={PersonOneId}
                    obj={PersonOneObj}
                    targetName={'personOneObj'}
                    onChange={this.setMergedValue}
                    checkedIds={checkedIds}
                    checkAll={checkAllTarget !== null ? checkAllTarget === 'personOneObj' : checkAllTarget}
                    onButtonClick={this.checkAll}
                    disabled={isFetchingData}
                    profileRoute={'/admin/people/'}
                  />
              }
            </Col>
            <Col xs={4}>
              <h4>Find Person 2</h4>
              <AutoComplete
                autoFocus={!!PersonOneObj}
                fieldName={'personTwo'}
                items={PeopleForSelect.filter(p => p.Value !== PersonOneId)}
                value={personTwo}
                searchValue={personTwoSearch}
                onChange={this.personTwoSelect}
                minCharactersToEnter={this.minCharactersToEnter}
                defaultMenuIsOpen={!!personTwoSearch}
              />
              {
                PersonTwoId && PersonTwoObj &&
                  <MergeForm
                    id={PersonTwoId}
                    obj={PersonTwoObj}
                    targetName={'personTwoObj'}
                    onChange={this.setMergedValue}
                    checkedIds={checkedIds}
                    checkAll={checkAllTarget !== null ? checkAllTarget === 'personTwoObj' : checkAllTarget}
                    onButtonClick={this.checkAll}
                    disabled={isFetchingData}
                    profileRoute={'/admin/people/'}
                  />
              }
            </Col>
            <Col xs={4}>
              <h4 className={'d-inline-block mb-2'}>Merged Person</h4>
              {
                mergedPerson && <>
                  <Input
                    readOnly={true}
                    value={this.mergedPersonInput(mergedPerson)}
                  />
                  <MergeForm
                    obj={mergedPerson}
                    showControls={false}
                    buttonText={'Clear'}
                    onButtonClick={this.resetChoices}
                    disabled={isFetchingData}
                  />
                </>
              }
            </Col>
          </Row>
          <Row className={'mt-4'}>
            <Col className={'d-flex justify-content-between'}>
              <Button
                color={'link'}
                type={'reset'}
                onClick={this.reset}
              >Reset</Button>
              <div className={'d-flex justify-content-between'}>
                <MergePermitsWarning mergedPerson={mergedPerson} />
                {
                  someValuesAreNotChosen &&
                  <PopoverButton
                    buttonClassName={'float-right'}
                    popoverHeader={'Merge Person'}
                    popoverBody={'You must choose a value from each property of one Person or the other, even if both properties do not have a value.'}
                  />
                }
                <Button
                  className={'ml-2'}
                  disabled={someValuesAreNotChosen}
                  onClick={this.mergePeople}
                >Merge</Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  }
}

const mapStateToProps = (state) => {
  const { ObjectOneId, ObjectTwoId, isFetchingData, } = state.Merge
  const PersonOneId = ObjectOneId, PersonTwoId = ObjectTwoId
  return {
    isFetchingData,
    PersonOneId,
    PersonOneObj    : personForMerge(state, PersonOneId),
    PersonTwoId,
    PersonTwoObj    : personForMerge(state, PersonTwoId),
    PeopleForSelect : peopleForMergeSelect(state),
    People          : allPeopleForMerge(state),
  }
}

const mapDispatchToProps = {
  SetPageTitle          : AppActions.setPageTitle,
  GetDataForPeopleMerge : PersonActions.getDataForPeopleMerge,
  MergePeople           : PersonActions.mergePeople,
  GetPersonDataForMerge : PersonActions.getPersonDataForMerge,
  ClearPersonIds        : MergeActions.clearObjectIds,
  SetPersonOneId        : MergeActions.setObjectOneId,
  SetPersonTwoId        : MergeActions.setObjectTwoId,
}

export default connect(mapStateToProps, mapDispatchToProps)(MergePersonContainer)
