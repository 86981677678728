import { useState, useCallback, } from 'react'

const useToggle = (defaultValue = false) => {
  const [ toggleState, setToggleState, ] = useState(defaultValue)
  const toggle = useCallback(() => {
    setToggleState(!toggleState)
  }, [ toggleState, ])
  return [ toggleState, toggle, ]
}

export default useToggle