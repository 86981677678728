import { useEffect, } from 'react'
import { useFormikContext, } from 'formik'
import { useSelector, } from 'react-redux'

// Seletors
import { latLongSelector, mapStateSelector, } from '../../../selectors/mapSelectors'


const LatLongEffect = () => {
  const { setFieldValue, } = useFormikContext()
  const { Latitude, Longitude, } = useSelector(latLongSelector)
  const { MapActive, MapLoading, } = useSelector(mapStateSelector)

  useEffect(() => {
    if (MapLoading !== true && MapActive !== true) {
      return
    }
    setFieldValue('Latitude', Latitude || '', true)
    setFieldValue('Longitude', Longitude || '', true)
  }, [ Latitude, Longitude, MapActive, MapLoading, setFieldValue, ])
  return null
}

export default LatLongEffect
