import { attr, Model, } from 'redux-orm'

export default class ContactMethod extends Model {
  static get modelName () {
    return 'ContactMethod'
  }

  toString () {
    return `${this.ContactMethodName}`
  }

  static endpoint () {
    return 'ContactMethods'
  }

  static get options () {
    return {
      idAttribute: 'ContactMethodId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      ContactMethodId          : attr({ inputType: 'number', inputLabel: 'Contact Method Id', }),
      CreateDate               : attr(),
      CreateBy                 : attr(),
      UpdateDate               : attr(),
      UpdateBy                 : attr(),
      // Model-Specific Properties
      ContactMethodName        : attr(),
      ContactMethodDescription : attr(),
  
      // Relationships
    }
  }
}