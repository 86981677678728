/* eslint-disable no-undef */
/** @constant @type {string} React App Version string from `.env`*/
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION

//#region Specific Claims

/** SAW User Claim value from `.env`, lower cased*/
export const REACT_APP_LANDOWNER_GROUP = process.env.REACT_APP_LANDOWNER_GROUP.toLowerCase()
/** DNR Region Staff Claim value  from `.env`, lower cased */
export const REACT_APP_REGION_STAFF = process.env.REACT_APP_REGION_STAFF.toLowerCase()
/** DNR Region Administrator Claim value  from `.env`, lower cased */
export const REACT_APP_REGION_ADMIN = process.env.REACT_APP_REGION_ADMIN.toLowerCase()
/** DNR Smoke Mgmt Staff Claim value  from `.env`, lower cased */
export const REACT_APP_SMOKE_STAFF = process.env.REACT_APP_SMOKE_STAFF.toLowerCase()
/** DNR Smoke Mgmt Admin Claim value  from `.env`, lower cased */
export const REACT_APP_SMOKE_ADMIN = process.env.REACT_APP_SMOKE_ADMIN.toLowerCase()
/** DNR Smoke Mgmt State Gov Customer Claim value  from `.env`, lower cased */
export const REACT_APP_STATE_GOV_CUST = process.env.REACT_APP_STATE_GOV_CUST.toLowerCase()

//#endregion

//#region Read Raw Group Claims

// Read the raw strings from .env
const REACT_APP_LANDOWNER_GROUP_SIDS = process.env.REACT_APP_LANDOWNER_GROUP_SIDS
const REACT_APP_DNR_GROUP_SIDS = process.env.REACT_APP_DNR_GROUP_SIDS
const REACT_APP_DNR_ADMIN_GROUP_SIDS = process.env.REACT_APP_DNR_ADMIN_GROUP_SIDS
const REACT_APP_DNR_SMOKE_MGMT_GROUP_SIDS = process.env.REACT_APP_DNR_SMOKE_MGMT_GROUP_SIDS

//#endregion
/* eslint-enable no-undef */

//#region Process Claim Groups

/** SAW User Claims array from `.env`*/
export const LANDOWNER_GROUP_SIDS = JSON.parse(REACT_APP_LANDOWNER_GROUP_SIDS)
/** ADFS User Claims array from `.env`*/
export const DNR_GROUP_SIDS = JSON.parse(REACT_APP_DNR_GROUP_SIDS)
/** DNR Smoke Mgmt or Region Admin Claims array from `.env`*/
export const ADMIN_GROUP_SIDS = JSON.parse(REACT_APP_DNR_ADMIN_GROUP_SIDS)
/** DNR Smoke Mgmt Claims array from `.env`*/
export const SMOKE_MGMT_GROUP_SIDS = JSON.parse(REACT_APP_DNR_SMOKE_MGMT_GROUP_SIDS)

//#endregion


//#region Person Types

export const PERSON_TYPE_DNR_STAFF = 'DNR Staff'
export const PERSON_TYPE_GOV_CUST = 'WA State Gov\'t Customer'
export const PERSON_TYPE_PRIVATE = 'Private'
export const PERSON_TYPE_GOV_AGENT = 'Government Agency'
export const PERSON_TYPE_AGENT = 'Agent'

//#endregion


export const mapGroupToReadableName = group => {
  group = group.toLowerCase()
  switch (!!group) {
  case group === REACT_APP_REGION_ADMIN:
    return 'Region Administrator'
  case group === REACT_APP_REGION_STAFF:
    return 'Region Staff'
  case group === REACT_APP_SMOKE_STAFF:
    return 'Smoke Management Staff'
  case group === REACT_APP_SMOKE_ADMIN:
    return 'Smoke Management Administrator'
  case group === REACT_APP_STATE_GOV_CUST:
    return 'State Government Customer'
  case group === REACT_APP_LANDOWNER_GROUP:
    return 'SAW User'
  default:
    break
  }
}
