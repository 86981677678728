import React from 'react'
import { Navigate, } from 'react-router-dom'
import { func, object, PropTypes, bool, string, } from 'prop-types'
import NotFound from '../components/NotFound'
import LoggingIn from '../components/LoggingIn'
import AgentUnverified from '../components/AgentUnverified'


function PrivateRoute ({ children, ...props }) {
  if (props.IsLoadingUser || props.LoginError) {
    return <LoggingIn loginError={props.LoginError} />
  }
  if (props.restrictUnverifiedAgent && props.isVerifiedAgent !== true && props.isAgent === true) {
    return <AgentUnverified />
  }
  if (props.isAuthenticated && props.restrictAgency === false) {
    return children
  }
  else if (props.isAuthenticated === false) {
    return <Navigate to={'/Login'} />
  }
  return <NotFound />
}

PrivateRoute.propTypes = {
  children                : PropTypes.oneOfType([ func, object, ]),
  restrictAgency          : bool,
  restrictUnverifiedAgent : bool,
  IsLoadingUser           : bool,
  LoginError              : string,
  isAuthenticated         : bool,
  isAgent                 : bool,
  isVerifiedAgent         : bool,
}

PrivateRoute.defaultProps = {
  children                : null,
  restrictAgency          : false,
  restrictUnverifiedAgent : true,
  IsLoadingUser           : false,
  LoginError              : '',
  isAuthenticated         : false,
  isAgent                 : false,
  isVerifiedAgent         : false,
}

export default PrivateRoute