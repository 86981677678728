import { cloneDeep, } from 'lodash'
import {
  POLYGON_SYMBOL,
  DOCUMENTATION_SYMBOL,
  UNDER_REVIEW_SYMBOL,
  DENIED_SYMBOL,
  APPROVED_SYMBOL,
  ISSUED_SYMBOL,
  SUBMITTED_SYMBOL,
  INFO_REQUIRED_SYMBOL,
  SUSPENDED_SYMBOL,
  REQUEST_APPROVED_SYMBOL,
  REQUEST_SUBMITTED_SYMBOL,
  REQUEST_UNDER_REVIEW_SYMBOL,
  REQUEST_INFO_REQUIRED_SYMBOL,
  REQUEST_DENIED_SYMBOL,
  PIN_SYMBOL,
  POST_BURN_SYMBOL,
  IN_UGA_SYMBOL,
} from './symbols'
import {
  PRIMARY_ORANGE,
  PRIMARY_BLUE,
  GREEN,
  DARK_BLUE,
  DARK_PURPLE,
  GRAY,
} from './colors'


export const BURN_PERMIT_STATUS_POINT = {
  type             : 'unique-value',  // autocasts as new UniqueValueRenderer()
  field            : 'BurnPermitStatus',
  defaultSymbol    : DOCUMENTATION_SYMBOL,
  uniqueValueInfos : [
    {
      value  : 'Issued',
      symbol : ISSUED_SYMBOL,
    },
    {
      value  : 'Approved',
      symbol : APPROVED_SYMBOL,
    },
    {
      value  : 'Pending',
      symbol : DOCUMENTATION_SYMBOL,
    },
    {
      value  : 'Submitted',
      symbol : SUBMITTED_SYMBOL,
    },
    {
      value  : 'Under Review',
      symbol : UNDER_REVIEW_SYMBOL,
    },
    {
      value  : 'Info Required',
      symbol : INFO_REQUIRED_SYMBOL,
    },
    {
      value  : 'Denied',
      symbol : DENIED_SYMBOL,
    },
    {
      value  : 'Suspended',
      symbol : SUSPENDED_SYMBOL,
    },
    {
      value  : 'Revoked',
      symbol : DENIED_SYMBOL,
    },
  ],
}

export const BURN_REQUEST_STATUS_POINT = {
  type             : 'unique-value',  // autocasts as new UniqueValueRenderer()
  field            : 'BurnRequestStatus',
  defaultSymbol    : PIN_SYMBOL,
  uniqueValueInfos : [
    {
      value  : 'Approved',
      symbol : REQUEST_APPROVED_SYMBOL,
    },
    {
      value  : 'Submitted',
      symbol : REQUEST_SUBMITTED_SYMBOL,
    },
    {
      value  : 'Under Review',
      symbol : REQUEST_UNDER_REVIEW_SYMBOL,
    },
    {
      value  : 'Info Required',
      symbol : REQUEST_INFO_REQUIRED_SYMBOL,
    },
    {
      value  : 'Denied',
      symbol : REQUEST_DENIED_SYMBOL,
    },
    {
      value  : 'Cancelled',
      symbol : PIN_SYMBOL,
    },
  ],
  visualVariables: [ {
    type  : 'size',
    field : 'TotalProposedBurnQuantity',
    stops : [
      { value: 100, size: 10, label: 'Less than 100 tons',  },
      { value: 500, size: 20, label: '500 tons',  },
      { value: 1000, size: 30, label: '1,000 tons',  },
      { value: 5000, size: 40, label: 'Greater than 5,000 tons', },
    ],
  }, ],
}

export const UGA_BURN_REQUEST_STATUS_POINT = {
  type             : 'unique-value',  // autocasts as new UniqueValueRenderer()
  field            : 'BurnRequestStatus',
  defaultSymbol    : { ...IN_UGA_SYMBOL, },
  uniqueValueInfos : [ ...BURN_PERMIT_STATUS_POINT.uniqueValueInfos, ]
    .map(i => {
      let { color, } = i.symbol
      if (!Array.isArray(color)) {
        color = [ ...GRAY, ]
      }
      let newInfo = {
        value  : i.value,
        symbol : {
          ...i.symbol,
          haloColor : [ 255, 255, 255, 0.5, ],
          color     : [ ...color, ],
          haloSize  : IN_UGA_SYMBOL.haloSize,
          text      : IN_UGA_SYMBOL.text,
        },
      }
      newInfo.symbol.font.weight = IN_UGA_SYMBOL.font.weight
      return newInfo
    }),
  visualVariables: [ {
    type  : 'size',
    field : 'TotalProposedBurnQuantity',
    stops : [
      { value: 100, size: 16, label: 'Less than 100 tons',  },
      { value: 500, size: 23, label: '500 tons',  },
      { value: 1000, size: 30, label: '1,000 tons',  },
      { value: 5000, size: 40, label: 'Greater than 5,000 tons', },
    ],
  }, ],
}

export const POST_BURN_POINT = {
  type            : 'unique-value',  // autocasts as new UniqueValueRenderer()
  field           : 'BurnRequestStatus',
  defaultSymbol   : POST_BURN_SYMBOL,
  visualVariables : [ {
    type  : 'size',
    field : 'ConsumedTons',
    stops : [
      { value: 100, size: 10, label: 'Less than 100 tons',  },
      { value: 500, size: 20, label: '500 tons',  },
      { value: 1000, size: 30, label: '1,000 tons',  },
      { value: 5000, size: 40, label: 'Greater than 5,000 tons', },
    ],
  }, ],
}

export const UGA_POST_BURN_POINT = {
  ...POST_BURN_POINT,
  defaultSymbol: { ...IN_UGA_SYMBOL, },
}

export const SIMPLE_ORANGE_POLYGON = {
  type   : 'simple',
  symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
    color   : [ ...PRIMARY_ORANGE, 0.05, ],
    outline : {
      width : 0.5,
      color : [ ...PRIMARY_BLUE, ],
    },
  }),
}

export const SIMPLE_BLUE_POLYGON = {
  type   : 'simple',
  symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
    color   : [ ...PRIMARY_BLUE, 0.05, ],
    outline : {
      width : 0.5,
      color : [ ...PRIMARY_ORANGE, ],
    },
  }),
}

export const SIMPLE_GREEN_POLYGON = {
  type   : 'simple',
  symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
    color   : [ ...GREEN, 0.05, ],
    outline : {
      width : 0.5,
      color : [ ...GREEN, ],
    },
  }),
}

export const SIMPLE_DARK_BLUE_POLYGON = {
  type   : 'simple',
  symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
    color   : [ ...DARK_BLUE, 0.05, ],
    outline : {
      width : 0.5,
      color : [ ...DARK_BLUE, ],
    },
  }),
}

export const SIMPLE_DARK_PURPLE_POLYGON = {
  type   : 'simple',
  symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
    color   : [ ...DARK_PURPLE, 0.05, ],
    outline : {
      width : 1.5,
      color : [ ...DARK_PURPLE, ],
    },
  }),
}

const FIRE_DANGER_OPACITY = 0.25
const FIRE_DANGER_OUTLINE = {
  width : 0.5,
  color : [ ...GRAY, ],
}
export const FIRE_DANGER_RENDERER = {
  type             : 'unique-value',  // autocasts as new UniqueValueRenderer()
  field            : 'FIRE_DANGER_LEVEL_CD',
  defaultSymbol    : SIMPLE_BLUE_POLYGON.symbol,
  uniqueValueInfos : [
    {
      value  : 1,
      label  : 'Low',
      symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
        color   : [ ...GREEN, FIRE_DANGER_OPACITY, ],
        outline : FIRE_DANGER_OUTLINE,
      }),
    },
    {
      value  : 2,
      label  : 'Moderate',
      symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
        color   : [ ...PRIMARY_BLUE, FIRE_DANGER_OPACITY, ],
        outline : FIRE_DANGER_OUTLINE,
      }),
    },
    {
      value  : 3,
      label  : 'High',
      symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
        color   : [ 255, 243, 0, FIRE_DANGER_OPACITY, ],
        outline : FIRE_DANGER_OUTLINE,
      }),
    },
    {
      value  : 4,
      label  : 'Very High',
      symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
        color   : [ 230, 152, 0, FIRE_DANGER_OPACITY, ],
        outline : FIRE_DANGER_OUTLINE,
      }),
    },
    {
      value  : 5,
      label  : 'Extreme',
      symbol : Object.assign(cloneDeep(POLYGON_SYMBOL), {
        color   : [ 255, 0, 0, FIRE_DANGER_OPACITY, ],
        outline : FIRE_DANGER_OUTLINE,
      }),
    },
  ],
}