import React from 'react'
import { Alert, } from 'reactstrap'
import { isEmpty, } from 'lodash'
import { useSelector, } from 'react-redux'

// Selectors
import { conditionValuesNewForBurnPermitSelector, } from '../../selectors/conditionsSelectors'


const ConditionsWarning = () => {

  const permitConditionValues = useSelector(conditionValuesNewForBurnPermitSelector)
  const hasConditionsSet = !isEmpty(permitConditionValues)

  if (hasConditionsSet) {
    return null
  }
  return <Alert color={'warning'}>
    <b>No conditions have been set for this Permit.</b>
  </Alert>
}

export default ConditionsWarning