import React from 'react'
import { Button, } from 'reactstrap'
import { string, func, bool, } from 'prop-types'

const ButtonGroupButton = props => {
  const {
    onClick,
    text,
    isActive,
    color,
    readOnly,
    size,
  } = props

  return <Button
    color={color}
    size={size}
    active={isActive}
    onClick={onClick}
    disabled={readOnly}
  >
    {text}
  </Button>
}

ButtonGroupButton.propTypes = {
  onClick  : func,
  text     : string,
  isActive : bool,
  color    : string,
  size     : string,
  readOnly : bool,
}

ButtonGroupButton.defaultProps = {
  color    : 'light',
  size     : 'sm',
  isActive : false,
  readOnly : false,
}

export default React.memo(ButtonGroupButton)