import React from 'react'
import { func, number, array, string, } from 'prop-types'
import { Button, ButtonGroup, } from 'reactstrap'


const Nav = (props) => {
  const dots = []
  let dot
  for (let i = 0; i < props.totalSteps; i++) {
    const isActive = props.currentStep === i
    let icon = 'far fa-circle', color = 'disabled'
    if (isActive) {
      icon = 'fas fa-circle'
      color = 'primary'
    }
    if (props.status === 'Complete' || props.completedSteps.includes(i + 1)) {
      icon = 'fas fa-check-circle'
      color = 'primary'
    }
    else if (props.status === 'Invalid' || props.invalidSteps.includes(i + 1)) {
      icon = 'fas fa-times-circle'
      color = 'danger'
    }
    
    dot = <Button
      key={`step-${i}`}
      color={`nav ${color === 'danger' ? color: ''}`}
      onClick={() => props.goToStep(i)}
      className={`p-2 ${isActive ? 'active': ''}`}
    >
      <span className={`${icon} ${color !== 'danger' ? color: ''}`}></span>
      <p className={`pt-2 m-0 ${isActive ? 'active': ''}`}>{props.labels[i]}</p>
    </Button>
    dots.push(dot)
  }

  return <ButtonGroup className={'application-nav'}>{dots}</ButtonGroup>
}

Nav.propTypes = {
  completedSteps : array.isRequired,
  invalidSteps   : array.isRequired,
  currentStep    : number.isRequired,
  labels         : array.isRequired,
  totalSteps     : number.isRequired,
  goToStep       : func.isRequired,
  status         : string,
}

export default Nav