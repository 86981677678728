import { createReducer, createActions, } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  openModal    : [ 'modalKey', ],
  closeModal   : null,
  toggleNavbar : null,
})

export const UiTypes = Types
export default Creators

/* ------------- Initial State ------------- */

const INITIAL_STATE = {
  Modal: {
    showModal : false,
    modalKey  : '',
  },
  Navbar: {
    isOpen: false,
  },
}

/* ------------- Reducers ------------- */

const openModal = (state, { modalKey, }) => {
  const newState = { ...state, }
  newState.Modal.showModal = true
  if (modalKey) {
    newState.Modal.modalKey = modalKey
  }
  return newState
}

const closeModal = (state) => {
  const newState = { ...state, }
  newState.Modal.showModal = false
  newState.Modal.modalKey = ''
  return newState
}

const toggleNavbar = (state) => {
  const newState = { ...state, }
  if (!newState.Navbar) {
    newState.Navbar = { isOpen: false, }
  }
  newState.Navbar.isOpen = !newState.Navbar.isOpen
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL]    : openModal,
  [Types.CLOSE_MODAL]   : closeModal,
  [Types.TOGGLE_NAVBAR] : toggleNavbar,
})
