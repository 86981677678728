// Libraries
import React from 'react'
import { Alert, } from 'reactstrap'
import { Link, } from 'react-router-dom'
import { useSelector, } from 'react-redux'

// Components
import { PopoverButton, } from '../FormControls'
import Status from './Icons/Status'

// Selectors
import { profileStatusSelector, } from '../../selectors/userSelectors'


const popover = <PopoverButton
  buttonClassName={'p-0'}
  popoverHeader={'Additional Info'}
  popoverBody={'Wherever you see this icon, click on it to find further information about the data it appears near.'}
/>

function ProfileStatus () {

  const profileStatus = useSelector(profileStatusSelector)
  if (!profileStatus || profileStatus.IsComplete === true) {
    return null
  }

  return <Alert color={'primary'}>
    <h2 className={'text-white'}>Welcome to the Burn Portal</h2>
    <p>
      Before you can create and manage Burn Permit Applications, Permits, and Burn Requests,
      you must complete your <Link to={'/profile'}>User Profile</Link>.
    </p>
    <p>This includes:</p>
    <ul>
      { <li>Person Type <Status complete={profileStatus.HasPersonType} /></li> }
      { <li>Preferred Contact Method <Status complete={profileStatus.HasContactMethod} /></li> }
      { <li>Phones <Status complete={profileStatus.HasPhones} /></li> }
      {/*
        We purposefully want to use the identity operator so that the absense of a property 
        doesn't evalutate as a falsey value and show the list item
      */}
      {
        typeof profileStatus.HasAddresses === 'boolean' && 
          <li>Addresses <Status complete={profileStatus.HasAddresses} /></li>
      }
      {
        typeof profileStatus.HasAgency === 'boolean' && 
          <li>Agency <Status complete={profileStatus.HasAgency} /></li>
      }
      {
        typeof profileStatus.HasRegion === 'boolean' && 
          <li>Region <Status complete={profileStatus.HasRegion} /></li>
      }
    </ul>
    <p>
      All throughout the application you will find help/info icons like this one {popover} that provide further explanation
      of the data and application requirements when clicked.
    </p>
    <p>
      If you have any questions, use the <b>Contact Support</b> link at the top or click the
      help icon <span className={'far fa-question-circle'}></span> in the menu bar to access the User Guide or contact your local Region office.
    </p>
  </Alert>
}

export default ProfileStatus