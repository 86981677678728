// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Container, Row, Col, CardBody, Card, } from 'reactstrap'
import { func, } from 'prop-types'

// Reducers
import AppActions from '../redux/AppRedux'

// Icons
import { DownloadPDF, } from '../components/Icons'

// eslint-disable-next-line no-undef
const { REACT_APP_USER_GUIDE, REACT_APP_SUPPORT_EMAIL, } = process.env

class HelpContainer extends React.Component {

  constructor (props) {
    super(props)
    this.mailTo = `mailto:${REACT_APP_SUPPORT_EMAIL}?Subject=Burn Portal Help`
    this.props.SetPageTitle('Burn Portal Help')
  }
  
  static propTypes = {
    SetPageTitle: func,
  }

  render () {
    return <Container className={'pt-2'}>
      <h1>Burn Portal Help</h1>
      <Row>
        <Col className={'help-container'}>
          <Card>
            <CardBody>
              <h2 style={{ color: '#000', }}>Welcome to the Burn Portal</h2>
              <p>
                If you need help Logging In, applying for a Permit Application, or submitting a Burn Request, 
                please refer to the User Guide linked below.
                If you still need help, please contact your nearest <Link to={'/Regions'}>Region</Link> office.
              </p>
              <p>
                Click to download: <a
                  href={REACT_APP_USER_GUIDE}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  Burn_Portal_User_Guide.pdf <DownloadPDF />
                </a>
              </p>
              <p>
                At any point if you experience an issue or need help, click the <b>Contact Support</b> link at the
                top right of the page.
              </p>
              <p>
                If you have questions regarding how to use this website, please send an email to our support group&nbsp;
                <a
                  href={this.mailTo}
                >
                  { REACT_APP_SUPPORT_EMAIL }
                </a>.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  }
}

const mapDispatchToProps = {
  SetPageTitle: AppActions.setPageTitle,
}

export default connect(null, mapDispatchToProps)(HelpContainer)