// Libraries
import React from 'react'
import { Col, Card, CardBody,  } from 'reactstrap'
import { string, } from 'prop-types'

const ReportMessageCard = ({ message, }) => {
  return <Col lg={{ size: 6, offset: 3, }}>
    <Card>
      <CardBody>
        <h4 className={'text-center'}>{message}</h4>
      </CardBody>
    </Card>
  </Col>
}

ReportMessageCard.propTypes = {
  message: string,
}

export default ReportMessageCard