// Libraries
import React from 'react'
import { Button, } from 'reactstrap'
import { string, func, array, } from 'prop-types'

// Components
import ConfirmationModal from '../ConfirmationModal'

const DeleteApplication = props => {
  const { openModal, modalKey, submitAction, submitArgs, } = props
  return <>
    <Button
      size={'sm'}
      color={'danger'}
      onClick={openModal}
      data-modalkey={modalKey}
    >
      <span className={'d-block d-lg-none fa fa-trash-alt p-1'}/>
      <span className={'d-none d-lg-block'}>Delete</span>
    </Button>
    <ConfirmationModal
      modalKey={modalKey}
      modalTitle={'Delete Burn Permit Application'}
      modalBody={<>
        <p>Are you sure you wish to delete this Burn Permit Application?</p>
        <p>This action is <b>permanent and irreversible</b>.</p>
      </>}
      submitAction={submitAction}
      submitArgs={submitArgs}
    />
  </>
}

DeleteApplication.propTypes = {
  openModal    : func,
  modalKey     : string,
  submitArgs   : array,
  submitAction : func,
}

export default DeleteApplication