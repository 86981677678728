import { attr, fk, many, Model, } from 'redux-orm'

export default class CustomerInteraction extends Model {
  static get modelName () {
    return 'CustomerInteraction'
  }

  toString () {
    return `${this.CustomerInteractionId}`
  }

  static endpoint () {
    return 'CustomerInteractions'
  }

  static get options () {
    return {
      idAttribute: 'CustomerInteractionId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      CustomerInteractionId : attr(),
      CreateDate            : attr(),
      CreateBy              : attr(),
      UpdateDate            : attr(),
      UpdateBy              : attr(),

      // Model-Specific Properties
      FollowUp               : attr(),
      FirstName              : attr(),
      LastName               : attr(),
      PhoneNumber            : attr(),
      EmailAddress           : attr(),
      InteractionDescription : attr(),
      Latitude               : attr(),
      Longitude              : attr(),
      Address                : attr(),

      // Relationships
      CustomerInteractionStatuses: many({
        to          : 'CustomerInteractionStatus',
        relatedName : 'CustomerInteractionStatuses',
        as          : 'CustomerInteractionStatuses',
      }),
      CustomerInteractionTypeId: fk({
        to          : 'CustomerInteractionType',
        relatedName : 'CustomerInteractions',
        as          : 'CustomerInteractionType',
      }),
      ContactMethodId: fk({
        to          : 'ContactMethod',
        relatedName : 'CustomerInteractions',
        as          : 'ContactMethod',
      }),
      BurnPermitId: fk({
        to          : 'BurnPermit',
        relatedName : 'CustomerInteractions',
        as          : 'BurnPermit',
      }),
      BurnRequestId: fk({
        to          : 'BurnRequest',
        relatedName : 'CustomerInteractions',
        as          : 'BurnRequest',
      }),
      RegionId: fk({
        to          : 'Region',
        relatedName : 'CustomerInteractions',
        as          : 'Region',
      }),
    }
  }
}
