import { createSelector, } from 'reselect'
import { activeBurnPermitSelector, } from './burnPermitSelectors'
import { ormSelector, } from './selectors'
import { personMapper, } from './personSelectors'


export const applInfoStateSelector = (state) => state.ApplicantInfo

const applInfoAgencyIdSelector = createSelector(
  applInfoStateSelector, 
  (state) => state && !isNaN(state.agencyId) ? state.agencyId : null
)

const applInfoAgentIdSelector = createSelector(
  applInfoStateSelector, 
  (state) => state && !isNaN(state.agentId) ? state.agentId : null
)

const applInfoBurnerIdSelector = createSelector(
  applInfoStateSelector, 
  (state) => state && !isNaN(state.landownerId) ? state.landownerId : null
)

const activePermitBurnerSelector = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => permit && permit.Burner ? permit.Burner : null
)

const activePermitAgentSelector = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => permit && permit.Agent ? permit.Agent : null
)

const activePermitAgencySelector = ormSelector(
  activeBurnPermitSelector,
  ({ Agency, }, permit) => {
    if (permit && permit.Agency) {
      return permit.Agency
    }
    if (permit && permit.AgencyId) {
      return Agency.withId(permit.AgencyId)
    }
    return null
  }
)

const selectedContactData = createSelector(
  applInfoStateSelector, 
  (state) => {
    const {
      landownerId,
      agentId,
      BurnerPermitAddressId,
      BurnerPermitPrimaryPhoneId,
      BurnerPermitSecondaryPhoneId,
      AgentPermitAddressId,
      AgentPermitPrimaryPhoneId,
      AgentPermitSecondaryPhoneId,
    } = state || {}
    return {
      landownerId,
      agentId,
      BurnerPermitAddressId,
      BurnerPermitPrimaryPhoneId,
      BurnerPermitSecondaryPhoneId,
      AgentPermitAddressId,
      AgentPermitPrimaryPhoneId,
      AgentPermitSecondaryPhoneId,
    }
  }
)

const withId = (model, id) => !isNaN(id) && model ? model.withId(id) : null

const applInfoMailToSelector = createSelector(
  applInfoStateSelector,
  (state) => {
    let MailPermitToAgentFlag = null
    if (state && typeof state.mailToAgent === 'boolean') {
      MailPermitToAgentFlag = state.mailToAgent
    }
    return { MailPermitToAgentFlag, }
  }
)

const applInfoBurnerSelector = ormSelector(
  applInfoBurnerIdSelector,
  ({ Person, }, personId) => withId(Person, personId)
)

const applInfoAgentSelector = ormSelector(
  applInfoAgentIdSelector,
  ({ Person, }, personId) => withId(Person, personId)
)

const applInfoAgencySelector = ormSelector(
  applInfoAgencyIdSelector,
  ({ Agency, }, agencyId) => withId(Agency, agencyId)
)

const applInfoFormBurnerSelector = ormSelector(
  applInfoBurnerIdSelector,
  applInfoBurnerSelector,
  activePermitBurnerSelector,
  selectedContactData,
  (session, activeBurnerId, activeBurner, permitBurner, _selectedContactData) => {
    if (isNaN(activeBurnerId) || activeBurnerId < 0) {
      return null
    }

    if (activeBurner) {
      const {
        BurnerPermitAddressId,
        BurnerPermitPrimaryPhoneId,
        BurnerPermitSecondaryPhoneId,
      } = _selectedContactData
      const props = { ...activeBurner, }._fields
      return {
        ...props,
        BurnerName: activeBurner.toString(),
        BurnerPermitAddressId,
        BurnerPermitPrimaryPhoneId,
        BurnerPermitSecondaryPhoneId,
      }
    } else if (permitBurner) {
      return permitBurner
    }
    return null
  }
)

const applInfoFormAgentSelector = ormSelector(
  applInfoAgentIdSelector,
  applInfoAgentSelector,
  activePermitAgentSelector,
  selectedContactData,
  (session, activeAgentId, activeAgent, permitAgent, _selectedContactData) => {
    if (isNaN(activeAgentId) || activeAgentId < 0) {
      return null
    }
    if (activeAgent) {
      const {
        AgentPermitAddressId,
        AgentPermitPrimaryPhoneId,
        AgentPermitSecondaryPhoneId,
      } = _selectedContactData
      const props = { ...activeAgent, }._fields
      return {
        ...props,
        AgentName: activeAgent.toString(),
        AgentPermitAddressId,
        AgentPermitPrimaryPhoneId,
        AgentPermitSecondaryPhoneId,
      }
    } else if (permitAgent) {
      return permitAgent
    }
    return null
  }
)

const mapAgency = function (agency) {
  return {
    AgencyId           : agency.AgencyId,
    CreateDate         : agency.CreateDate,
    CreateBy           : agency.CreateBy,
    UpdateDate         : agency.UpdateDate,
    UpdateBy           : agency.UpdateBy,
    AgencyName         : agency.AgencyName,
    AgencySubName1     : agency.AgencySubName1 || '',
    AgencySubName2     : agency.AgencySubName2 || '',
    AgencyOtherName    : agency.AgencyOtherName || '',
    AgencyAbbreviation : agency.AgencyAbbreviation || '',
    AutoRegionApproval : agency.AutoRegionApproval,
    AddressId          : agency.AddressId,
    EmailId            : agency.EmailId,
    PhoneId            : agency.PhoneId,
    Address            : agency.Address ? agency.Address.toString() : null,
    Phone              : agency.Phone ? agency.Phone.toString() : null,
    Email              : agency.Email ? agency.Email.EmailAddress : null,
  }
}

const applInfoFormAgencySelector = ormSelector(
  applInfoAgencyIdSelector,
  applInfoAgencySelector,
  activePermitAgencySelector,
  (session, activeAgencyId, activeAgency, permitAgency) => {
    if (isNaN(activeAgencyId) || activeAgencyId < 0 || activeAgencyId === '') {
      return null
    }
    if (activeAgency) {
      return mapAgency(activeAgency)
    }
    else if (permitAgency) {
      return mapAgency(permitAgency)
    }
    return null
  }
)

const personAddressLookup = (personAddresses, person) => {
  if (!person) {
    return []
  }
  const query = personAddresses.filter({ fromPersonId: person.PersonId, })
  if (query.exists()) {
    return query.toModelArray().map(x => x.toAddressId).filter(a => !!a && a.Active)
  } else if (person.Addresses && person.Addresses.exists()) {
    return person.Addresses.all().toModelArray().filter(a => !!a && a.Active)
  }
  return []
}

const personPhoneLookup = (personPhones, person) => {
  if (!person) {
    return []
  }
  const query = personPhones.filter({ fromPersonId: person.PersonId, })
  if (query.exists()) {
    return query.toModelArray().map(x => x.toPhoneId).filter(p => !!p && p.Active)
  } else if (person.Phones && person.Phones.exists()) {
    return person.Phones.all().toModelArray().filter(p => !!p && p.Active)
  }
  return []
}

const personEmailLookup = (emails, person) => {
  if (!person) {
    return null
  }
  let email = null
  if (person.EmailId) {
    email = emails.withId(person.EmailId)
  } else {
    email = person.Email
  }
  return email
}

const agencyAddressSelector = ormSelector(
  applInfoAgencySelector,
  ({ Address, }, agency) => {
    if (!agency) {
      return null
    }
    if (agency.Address) {
      return agency.Address
    }
    if (agency.AddressId) {
      return Address.withId(agency.AddressId)
    }
    return null
  }
)

const agentAddressesSelector = ormSelector(
  applInfoFormAgentSelector,
  ({ PersonAddresses, }, person) => personAddressLookup(PersonAddresses, person)
)

const burnerAddressesSelector = ormSelector(
  applInfoFormBurnerSelector,
  ({ PersonAddresses, }, person) => personAddressLookup(PersonAddresses, person)
)

const agentPhonesSelector = ormSelector(
  applInfoFormAgentSelector,
  ({ PersonPhones, }, person) => personPhoneLookup(PersonPhones, person)
)

const agencyPhoneSelector = ormSelector(
  applInfoAgencySelector,
  ({ Phone, }, agency) => {
    if (!agency) {
      return null
    }
    if (agency.Phone) {
      return agency.Phone
    }
    if (agency.PhoneId) {
      return Phone.withId(agency.PhoneId)
    }
    return null
  }
)

const burnerPhonesSelector = ormSelector(
  applInfoFormBurnerSelector,
  ({ PersonPhones, }, person) => personPhoneLookup(PersonPhones, person)
)

const agentEmailSelector = ormSelector(
  applInfoFormAgentSelector, 
  ({ Email, }, agent) => personEmailLookup(Email, agent)
)

const agencyEmailSelector = ormSelector(
  applInfoAgencySelector,
  ({ Email, }, agency) => {
    if (!agency) {
      return null
    }
    if (agency.Email) {
      return agency.Email
    }
    if (agency.EmailId) {
      return Email.withId(agency.EmailId)
    }
    return null
  }
)

const burnerEmailSelector = ormSelector(
  applInfoFormBurnerSelector,
  ({ Email, }, burner) => personEmailLookup(Email, burner)
)

const mapBurnerOrAgent = (person, addresses, phones, email) => {
  if (person) {
    const { Email, Addresses, Phones, } = person
    const mappedPerson = personMapper(person)
    let mappedEmail = null, mappedAddresses = [], mappedPhones = []

    if (email) {
      mappedEmail = {
        EmailId      : email.EmailId,
        EmailAddress : email.EmailAddress,
      }
    } else if (Email) {
      mappedEmail = {
        EmailId      : Email.EmailId,
        EmailAddress : Email.EmailAddress,
      }
    }
    if (addresses && addresses.length > 0) {
      mappedAddresses = addresses.map((a) => {
        return {
          Value : a.AddressId,
          Text  : a.toString(),
        }
      })
    } else if (Addresses && Addresses.all().exists()) {
      mappedAddresses = Addresses.toModelArray().map((a) => {
        return {
          Value : a.AddressId,
          Text  : a.toString(),
        }
      })
    }
    if (phones && phones.length > 0) {
      mappedPhones = phones.map(a => {
        return { 
          Value : a.PhoneId,
          Text  : a.toString(),
        }
      })
      
    } else if (Phones && Phones.all().exists()) {
      mappedAddresses = Phones.toModelArray().map((a) => {
        return {
          Value : a.PhoneId,
          Text  : a.toString(),
        }
      })
    }

    mappedPerson['PersonName'] = person.toString() || ''
    return {
      Person    : mappedPerson,
      Email     : mappedEmail,
      Addresses : mappedAddresses,
      Phones    : mappedPhones,
    }
  }
  return {
    Person    : null,
    Email     : null,
    Addresses : [],
    Phones    : [],
  }
}

export const applicantInfoBurnerSelector = ormSelector(
  applInfoFormBurnerSelector,
  burnerAddressesSelector,
  burnerPhonesSelector,
  burnerEmailSelector,
  (session, person, addresses, phones, email) => 
    person ? mapBurnerOrAgent(person, addresses, phones, email) : null
)

export const applicantInfoAgentSelector = ormSelector(
  applInfoFormAgentSelector,
  agentAddressesSelector,
  agentPhonesSelector,
  agentEmailSelector,
  (session, person, addresses, phones, email) => {
    if (!person) {
      return null
    }
    const agent = mapBurnerOrAgent(person, addresses, phones, email)
    agent.AgencyId = ''
    
    agent.Verified = person.IsUser ? '' : 'N/A'
    const xref = session.PersonAgencyXref.filter({ PersonAgencyXrefPersonId: person.PersonId, }).first()
    if (xref) {
      agent.AgencyId = xref.PersonAgencyXrefAgencyId
      if (person.IsUser) {
        agent.Verified = xref.ConfirmedBy ? 'Yes' : ''
      }
    }
    return agent
  }
)

export const applicantInfoAgencySelector = ormSelector(
  applInfoFormAgencySelector,
  agencyAddressSelector,
  agencyPhoneSelector,
  agencyEmailSelector,
  (session, agency, address, phone, email) => {
    if (!agency) {
      return null
    }
    if (!agency.Address && address) {
      agency.Address = address.toString()
    }
    if (!agency.Phone && phone) {
      agency.Phone = phone.toString()
    }
    if (!agency.Email && email) {
      agency.Email = email.toString()
    }
    return agency
  }
)

const permitApplInfoSelector = ormSelector(
  activeBurnPermitSelector,
  (session, permit) => {
    if (!permit) {
      return null
    }

    return {
      BurnPermitId                 : permit.BurnPermitId,
      BurnerId                     : permit.BurnerId || '',
      Landowner                    : permit.Burner ? permit.Burner.toString() : '',
      BurnerPermitAddressId        : permit.BurnerPermitAddressId || '',
      BurnerPermitPrimaryPhoneId   : permit.BurnerPermitPrimaryPhoneId || '',
      BurnerPermitSecondaryPhoneId : permit.BurnerPermitSecondaryPhoneId || '',
      AgencyId                     : permit.AgencyId || '',
      Agency                       : permit.Agency ? permit.Agency.toString() : '',
      AgentId                      : permit.AgentId || '',
      Agent                        : permit.Agent ? permit.Agent.toString() : '',
      AgentPermitAddressId         : permit.AgentPermitAddressId || '',
      AgentPermitPrimaryPhoneId    : permit.AgentPermitPrimaryPhoneId || '',
      AgentPermitSecondaryPhoneId  : permit.AgentPermitSecondaryPhoneId || '',
      MailPermitToLandownerFlag    : permit.MailPermitToLandownerFlag,
      MailPermitToAgentFlag        : permit.MailPermitToAgentFlag ? permit.MailPermitToAgentFlag : false,
      CreateBy                     : permit.CreateBy || '',
      CreateDate                   : permit.CreateDate || '',
      UpdateBy                     : permit.UpdateBy || '',
      UpdateDate                   : permit.UpdateDate || '',
    }
  }
)

export const permitForApplicantInfoSelector = ormSelector(
  permitApplInfoSelector,
  applInfoFormBurnerSelector,
  applInfoFormAgentSelector,
  applInfoFormAgencySelector,
  applInfoMailToSelector,
  (session, permit, burner, agent, agency, mailTo) => {
    if (!permit) {
      return null
    }

    const { MailPermitToAgentFlag, } = mailTo
    let modifiedPermit = {
      ...permit,
      MailPermitToAgentFlag: MailPermitToAgentFlag !== null
        ? MailPermitToAgentFlag
        : permit.MailPermitToAgentFlag,
    }

    if (agent && modifiedPermit.MailPermitToAgentFlag) {
      if (agent.PersonId !== permit.AgentId) {
        modifiedPermit.AgentId = agent.PersonId
        modifiedPermit.Agent = agent.AgentName
        modifiedPermit.AgentPermitAddressId = agent.AgentPermitAddressId ||  ''
        modifiedPermit.AgentPermitPrimaryPhoneId = agent.AgentPermitPrimaryPhoneId ||  ''
        modifiedPermit.AgentPermitSecondaryPhoneId = agent.AgentPermitSecondaryPhoneId ||  ''

        modifiedPermit.AgencyId = ''
      }
        
      modifiedPermit.Verified = agent.IsUser ? '' : 'N/A'
      const xref = session.PersonAgencyXref.filter({ PersonAgencyXrefPersonId: agent.PersonId, }).first()
      if (xref) {
        modifiedPermit.AgencyId = xref.PersonAgencyXrefAgencyId
        if (agent.IsUser) {
          modifiedPermit.Verified = xref.ConfirmedBy ? 'Yes' : ''
        }
      }

      // Perform this check separately so we don't clear out the Address and Phone IDs
      // This may fire due to the asynchronous nature of how the data is collected and the
      // Permit may have the AgentId set, but it may not have the Person's info downloaded
      // yet which results in the Agent name not appearing in the auto-complete
      if (agent.AgentName !== permit.Agent) {
        modifiedPermit.Agent = agent.AgentName
      }
    }
    else {
      modifiedPermit.Agent = ''
      modifiedPermit.AgentId = ''
      modifiedPermit.AgentPermitAddressId = ''
      modifiedPermit.AgentPermitPrimaryPhoneId = ''
      modifiedPermit.AgentPermitSecondaryPhoneId = ''
    }

    if (agency) {
      if (agency.AgencyId !== permit.AgencyId) {
        modifiedPermit.AgencyId = agency.AgencyId
        modifiedPermit.Agency = agency.AgencyName
      }
    }

    if (burner) {
      if (burner.PersonId !== permit.BurnerId) {
        modifiedPermit.BurnerId                     = burner.PersonId || ''
        modifiedPermit.Landowner                    = burner.BurnerName
        modifiedPermit.BurnerPermitAddressId        = burner.BurnerPermitAddressId || ''
        modifiedPermit.BurnerPermitPrimaryPhoneId   = burner.BurnerPermitPrimaryPhoneId || ''
        modifiedPermit.BurnerPermitSecondaryPhoneId = burner.BurnerPermitSecondaryPhoneId || ''
      }
      // Perform this check separately so we don't clear out the Address and Phone IDs
      // This may fire due to the asynchronous nature of how the data is collected and the
      // Permit may have the BurnerId set, but it may not have the Person's info downloaded
      // yet which results in the Landowner name not appearing in the auto-complete
      if (burner.BurnerName !== permit.Landowner) {
        modifiedPermit.Landowner = burner.BurnerName
      }
    }
    
    return modifiedPermit
  }
)