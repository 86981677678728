import { createSelector, } from 'reselect'
import { ormSelector, ormWithPropSelector, } from './selectors'
import { PERSON_TYPE_GOV_AGENT, PERSON_TYPE_AGENT, PERSON_TYPE_GOV_CUST, } from './env'


/*** State Selectors */

const agentStateSelector = state => state.agency

export const agentCurrentIdSelector = createSelector(
  agentStateSelector,
  agent => agent ? agent.personId : null
)

/*** ORM Selectors */

/**
 * Selector for ApplicantInfoSection, select a minimal amount
 * of information about all agents to populate a dropdown.
 */
export const agentsForSelectSelector = ormWithPropSelector(
  ({ Person, }, filter) => {
    const people = Person.all()
    if (people.exists()) {
      let agents = people.toModelArray().filter(p => p.PersonType && 
                                                    (p.PersonType.PersonTypeName === PERSON_TYPE_AGENT
                                                    || p.PersonType.PersonTypeName === PERSON_TYPE_GOV_AGENT
                                                    || p.PersonType.PersonTypeName === PERSON_TYPE_GOV_CUST))
      if (typeof filter === 'function') {
        agents = agents.filter(filter)
      }
      return agents.map((a) => {
        return {
          Value : a.PersonId,
          Text  : `${a.PersonId}: ${a.toString()}`,
        }
      })
    }
    return []
  }
)

export const agentXrefsSelector = ormSelector(
  ({ PersonAgencyXref, }) => {
    const xrefs = PersonAgencyXref.all()
    if (!xrefs.exists()){
      return []
    }
    return xrefs.toRefArray().map(x => { return {
      PersonAgencyXrefId       : x.PersonAgencyXrefId,
      PersonAgencyXrefAgencyId : x.PersonAgencyXrefAgencyId,
      PersonAgencyXrefPersonId : x.PersonAgencyXrefPersonId,
      ConfirmedBy              : x.ConfirmedBy,
      ConfirmedOn              : x.ConfirmedOn,
      CreateDate               : x.CreateDate,
      CreateBy                 : x.CreateBy,
      UpdateDate               : x.UpdateDate,
      UpdateBy                 : x.UpdateBy,
    }})
  }
)