import React, { useCallback, useMemo, } from 'react'
import { ButtonGroup, FormFeedback, } from 'reactstrap'
import { ErrorMessage, useFormikContext, } from 'formik'
import { bool, element, oneOfType, string, } from 'prop-types'

import ButtonGroupButton from './ButtonGroupButton'


const YesNoButton = props => {
  const { name, text, value, readOnly, } = props
  const { setFieldValue, values, } = useFormikContext()
  const onClick = useCallback(() => {
    if (readOnly) {
      return null
    }
    setFieldValue(name, value, true)
  }, [ setFieldValue, name, value, readOnly, ])

  const isActive = useMemo(() => (values[name] === value), [ values, value, name, ])

  return <ButtonGroupButton
    text={text}
    isActive={isActive}
    onClick={onClick}
  />
}

YesNoButton.propTypes = {
  name     : string,
  readOnly : bool,
  text     : string,
  value    : bool,
}


const YesNoButtonGroup = props => {
  const { name, children, readOnly, } = props
  // State and refs
  const { errors, } = useFormikContext()

  // UI Callbacks
  const error = useMemo(() => (errors[name] !== undefined && errors[name] !== null), [ errors, name, ])
  const hiddenElClass = useMemo(() => (`form-control${(error ? ' is-invalid' : '')}`), [ error, ])

  // mount effect
  const markup = <>
    {children}
    <input
      id={name}
      type={'hidden'}
      className={hiddenElClass}
      invalid={error.toString()}
      readOnly={readOnly}
    />
    <ErrorMessage name={name}>
      {errorMessage => <FormFeedback className={'text-wrap'}>{errorMessage}</FormFeedback>}
    </ErrorMessage>
    <ButtonGroup className={'d-block'}>
      <YesNoButton
        name={name}
        text={'Yes'}
        value={true}
        readOnly={readOnly}
      />
      <YesNoButton
        name={name}
        text={'No'}
        value={false}
        readOnly={readOnly}
      />
    </ButtonGroup>
  </>
  return markup
}

YesNoButtonGroup.defaultProps = {
  readOnly: false,
}

YesNoButtonGroup.propTypes = {
  name     : string,
  children : oneOfType([ element, string, ]),
  readOnly : bool,
}

export default React.memo(YesNoButtonGroup)
