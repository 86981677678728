import React from 'react'
import { Nav, } from 'reactstrap'

// Components
import Admin from './Admin'
import Regions from './Regions'
import Calendar from './Calendar'
import Dashboard from './Dashboard'
import Landowners from './Landowners'
import Permits from './Permits'
import BurnRequests from './BurnRequests'
import SmokeComplaints from './SmokeComplaints'


const LeftNavbarItems = () => {
  return <Nav navbar className={'navbar-left'}>
    <Dashboard />
    <Admin />
    <Regions />
    <Calendar />
    <Landowners />
    <Permits />
    <BurnRequests />
    <SmokeComplaints />
  </Nav>
}

export default React.memo(LeftNavbarItems)
