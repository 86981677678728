import { createSelector, } from 'reselect'
import {
  ormByIdSelector,
  ormSelector,
  ormWithPropSelector,
  propsSelector,
} from './selectors'

/*** State Selectors */

const customerInteractionState = state => state.CustomerInteraction

export const customerInteractionStateSelector = createSelector(
  customerInteractionState,
  propsSelector,
  (state, customerInteractionType) => state[customerInteractionType]
)

export const customerInteractionStatusFormSelector = createSelector(
  customerInteractionState,
  (state) => state.status
)

export const customerInteractionStatusFormOpenSelector = createSelector(
  customerInteractionState,
  (state) => state.status.showForm
)

export const createdInteractionIdSelector = createSelector(
  customerInteractionState,
  state => state.id
)

export const userAcknowledgedSmokeComplaintPrompt = createSelector(
  customerInteractionState,
  state => {
    return state.Smoke.acknowledgedPrompt
  }
)

export const smokeComplaintassociatedBurns = createSelector(
  customerInteractionState,
  state => (state.associatedBurns.data || [])
)

export const smokeComplaintPermitXrefs = ormWithPropSelector(
  ({ CustomerInteractionBurnPermitXref, }, CustomerInteractionId) => {
    if (!CustomerInteractionBurnPermitXref.all().exists()) {
      return []
    }
    let c = null
    try {
      CustomerInteractionId = parseInt(CustomerInteractionId)
      c = CustomerInteractionBurnPermitXref.filter({ CustomerInteractionId, })
    } catch (e) {
      c = null
      console.warn(`Could not find CustomerInteractionBurnPermitXrefs with CustomerInteractionId: ${CustomerInteractionId}`)
    }
    if (!c || !c.all().exists()) {
      return []
    }
    return c.all().toRefArray()
  }
)

export const smokeComplaintAssociatedBurnSearchResults = createSelector(
  customerInteractionState,
  state => (state.associatedBurns.searchResults || [])
)

export const associatedBurnsSearchParams = createSelector(
  customerInteractionState,
  state => (state.associatedBurns.searchParams || {})
)

export const smokeComplaintAssociationFormIsVisible = createSelector(
  customerInteractionState,
  state => {
    return typeof state.associatedBurns.showForm === 'boolean'
      ? state.associatedBurns.showForm
      : false
  }
)

export const customerInteractionTypeSelector = ormWithPropSelector(
  ({ CustomerInteractionType, }, CustomerInteractionTypeName) => {
    if (!CustomerInteractionType.all().exists()) {
      return null
    }
    let c = null
    try {
      c = CustomerInteractionType.get({ CustomerInteractionTypeName, })
    } catch (e) {
      c = null
      console.warn(`Could not find CustomerInteractionType with TypeName: ${CustomerInteractionTypeName}`)
    }
    if (!c) {
      return null
    }
    return {
      CustomerInteractionTypeId   : c.CustomerInteractionTypeId,
      CustomerInteractionTypeName : c.CustomerInteractionTypeName,
    }
  }
)

export const customerInteractionStatusesByIdSelector = ormWithPropSelector(
  ({ CustomerInteractionStatusXref, }, CustomerInteractionId) => {
    if (!CustomerInteractionStatusXref.all().exists()) {
      return []
    }
    let c = null
    try {
      c = CustomerInteractionStatusXref.filter({ CustomerInteractionId, })
    } catch (e) {
      c = null
      console.warn(`Could not find CustomerInteractionStatusXrefs with CustomerInteractionId: ${CustomerInteractionId}`)
    }
    if (!c || !c.all().exists()) {
      return []
    }
    return c.orderBy('StatusDate', 'desc')
      .toModelArray()
      .map(x => {
        return {
          StatusName              : x.CustomerInteractionStatus.CustomerInteractionStatusName,
          StatusDate              : x.StatusDate,
          AssignedTo              : `${x.AssignedTo || 'unknown'}`,
          Comment                 : x.Comment,
          CommentInternalOnly     : x.CommentInternalOnly,
          ContactedReportingParty : x.ContactedReportingParty,
          CreateBy                : x.CreateBy,
          CreateDate              : x.CreateDate,
        }
      })
  }
)

export const smokeComplaintTypeId = ormSelector(
  ({ CustomerInteractionType, }) => {
    const interactionType = CustomerInteractionType.get({ CustomerInteractionTypeName: 'Smoke Complaint', })
    if (!interactionType) {
      return ''
    }
    return interactionType.CustomerInteractionTypeId || ''
  }
)

export const getSmokeComplaintSelector = ormByIdSelector(
  smokeComplaintTypeId,
  ({ CustomerInteraction, }, CustomerInteractionId, CustomerInteractionTypeId) => {
    const defaultComplaint = {
      CustomerInteractionTypeId,
      RegionId               : '',
      BurnPermitId           : '',
      BurnRequestId          : '',
      InteractionDescription : '',
      Latitude               : '',
      Longitude              : '',
      Address                : '',
      FollowUp               : '',
      FirstName              : '',
      LastName               : '',
      PhoneNumber            : '',
      EmailAddress           : '',
      CreateDate             : '',
      CreateBy               : '',
    }
    if (!CustomerInteractionId) {
      return defaultComplaint
    }
    const complaint = CustomerInteraction.get({ CustomerInteractionId, })
    if (!complaint) {
      return defaultComplaint
    }
    return {
      CustomerInteractionId     : complaint.CustomerInteractionId || '',
      CustomerInteractionTypeId : complaint.CustomerInteractionTypeId,
      RegionId                  : complaint.RegionId || '',
      BurnPermitId              : complaint.BurnPermitId || '',
      BurnRequestId             : complaint.BurnRequestId || '',
      InteractionDescription    : complaint.InteractionDescription || '',
      Latitude                  : complaint.Latitude || '',
      Longitude                 : complaint.Longitude || '',
      Address                   : complaint.Address || '',
      FollowUp                  : typeof complaint.FollowUp === 'boolean' ? complaint.FollowUp : '',
      FirstName                 : complaint.FirstName || '',
      LastName                  : complaint.LastName || '',
      PhoneNumber               : complaint.PhoneNumber || '',
      EmailAddress              : complaint.EmailAddress || '',
      CreateDate                : complaint.CreateDate,
      CreateBy                  : complaint.CreateBy,
    }
  }
)

export const smokeCompMapStateSelector = createSelector(
  customerInteractionState,
  ({ mapState, targetType, }) => {
    const { center, zoom, showMap, location, regionId, } = ((mapState || {})[targetType]) || {}
    return { center, zoom, showMap, location, regionId, }
  }
)

export const custIntSearchParamsSelector = createSelector(
  customerInteractionState,
  propsSelector,
  ({ searchParams, }, custIntTypeId) => {
    if (!searchParams || Object.keys(searchParams).length === 0) {
      return {}
    }
    if (!custIntTypeId) {
      return {}
    }
    return searchParams[custIntTypeId] || {}
  }
)

export const searchComplaintsSelector = createSelector(
  customerInteractionState,
  ({ targetType, searchResults, }) => {
    if (!searchResults || Object.keys(searchResults).length === 0) {
      return []
    }
    if (Array.isArray(searchResults[targetType]) !== true) {
      return []
    }
    return searchResults[targetType]
  }
)

export const customerInteractionStatusesSelector = ormSelector(
  ({ CustomerInteractionStatus, }) => {
    return [
      {
        Value : -1,
        Text  : 'Unassigned',
      },
      ...CustomerInteractionStatus.all()
        .orderBy('CustomerInteractionStatusName')
        .toRefArray()
        .map(s => {
          return {
            Value : s.CustomerInteractionStatusId,
            Text  : s.CustomerInteractionStatusName,
          }
        }),
    ]
  }
)

export const customerInteractionTypesSelector = ormSelector(
  ({ CustomerInteractionType, }) => {
    return CustomerInteractionType.all()
      .toRefArray()
      .map(s => {
        return {
          Value : s.CustomerInteractionTypeId,
          Text  : s.CustomerInteractionTypeName,
        }
      })
  }
)
