import React from 'react'
import { number, } from 'prop-types'
import { Link, } from 'react-router-dom'


const PermitAppLink = ({ BurnPermitId, }) => {
  if (!BurnPermitId) {
    BurnPermitId = ''
  }
  let label = `Permit App #${BurnPermitId}`
  if (!BurnPermitId) {
    label = 'My Permits'
  }
  return <Link to={`/permits/${BurnPermitId}`}>
    {label}
  </Link>
}

PermitAppLink.propTypes = {
  BurnPermitId: number,
}

export default React.memo(PermitAppLink)