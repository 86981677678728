import { createSelector, } from 'reselect'
import { authPropsSelector, userIsSmokeMgmt, } from './userSelectors'

export const createAuthSelector = function () {
  const args = Array.prototype.slice.call(arguments)
  return createSelector(authPropsSelector, ...args)
}

//#region App selectors

export const userMakesPayments = createAuthSelector(
  ({ isSAWUser, isVerifiedAgent, isAgent, isDnr, isPrivate, isStateGovCust, isGovAgent, paysByVoucher, }) => {
    let allowed = false
    if (isDnr) {
      allowed = true
    }
    else if (isStateGovCust) {
      allowed = false
    }
    else if (isSAWUser) {
      if (isPrivate) {
        allowed = true
      }
      else if (isAgent && isVerifiedAgent) {
        allowed = true
      }
      else if (isGovAgent && isVerifiedAgent) {
        allowed = !paysByVoucher
      }
    }
    return allowed
  }
)

export const userCanViewAdminMenu = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanCreateNewPermit = createAuthSelector(
  ({ isDnr, isSAWUser, isGovAgent, isAgent, isVerifiedAgent, isPrivate, isStateGovCust, }) => {
    let allowed = false
    if (isDnr || (isStateGovCust && isVerifiedAgent)) {
      allowed = true
    } else if (isSAWUser) {
      if (isPrivate){
        allowed = true
      } else if ((isAgent || isGovAgent) && isVerifiedAgent) {
        allowed = true
      }
    }
    return allowed
  }
)

export const userCanCreateNewRequest = createAuthSelector(
  ({ isDnr, isSAWUser, isGovAgent, isAgent, isVerifiedAgent, isPrivate, isStateGovCust, }) => {
    let allowed = false
    if (isDnr || (isStateGovCust && isVerifiedAgent)){
      allowed = true
    } else if (isSAWUser) {
      if (isPrivate){
        allowed = true
      } else if ((isAgent || isGovAgent) && isVerifiedAgent) {
        allowed = true
      }
    }
    return allowed
  }
)

export const userCanUpdatePersonRegion = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanViewPersonRegion = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanVerifyAgents = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanChangeAlertPrefs = createAuthSelector(
  ({ isSAWUser, isStateGovCust, }) => isSAWUser || isStateGovCust
)

export const userCanViewAgencyAuitData = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanSearchByAssignee = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanViewAppUserData = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userNeedsLandownersOffline = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanEditVerifiedAgentPersonType = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanEditAnyAgency = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanChangeSMDecisionStatus = createAuthSelector(
  userIsSmokeMgmt,
  ({ isDnr, }, isSmokeMgmt) => isDnr && !isSmokeMgmt
)

//#endregion

//#region Profile Person Type Permissions

export const userOnlySelectsInternalType = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userOnlySelectsGovCustType = createAuthSelector(
  ({ isStateGovCust, }) => isStateGovCust
)

export const userOnlySelectsPublicTypes = createAuthSelector(
  ({ isSAWUser, }) => isSAWUser
)

//#endregion

//#region Person Management Permissions
export const userCanCreateNewPeople = createAuthSelector(
  ({ isDnr, isSAWUser, isAgent, isVerifiedAgent, }) => 
    isDnr || (isSAWUser && isAgent && isVerifiedAgent)
)

export const userCanManageGovCustPeople = createAuthSelector(
  ({ isDnr, }) => isDnr 
)

export const userCanManageAgentPeople = createAuthSelector(
  ({ isAgent, isVerifiedAgent, isSAWUser, }) => 
    isSAWUser && isAgent && isVerifiedAgent 
)

export const userCanManageAllPeople = createAuthSelector(
  ({ isDnr, }) => isDnr
)

export const userCanManageLandowners = createAuthSelector(
  ({ isDnr, isSAWUser, isAgent, isVerifiedAgent, }) => 
    isDnr || (isSAWUser && isAgent && isVerifiedAgent)
)
//#endregion