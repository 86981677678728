import React from 'react'

const Review = () => {
  return {
    label   : 'Review',
    caption : <>
      <p>
        A DNR representative will review your application to either approve or deny your application.
        They may reach out to you directly if they have questions about the information you submitted.
      </p>
      <p>
        Once the review is complete, your application will be Approved <i className={'fas fa-thumbs-up'} />&nbsp;
        or Denied <i className={'fas fa-thumbs-down'} />
      </p>
    </>,
    icon: 'fas fa-tasks',
  }
}

export default Review