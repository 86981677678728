// Libraries
import React from 'react'
import { Formik, Field, } from 'formik'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { useDispatch, useSelector, } from 'react-redux'
import * as Yup from 'yup'
import { string, number, bool, } from 'prop-types'

// Components
import { AssignedStaff, AssignToMe, Select, } from '../FormControls'
import { CommentRow, } from './'

// Redux
import BurnPermitFormActions from '../../redux/BurnPermitFormRedux'

// Selectors
import {
  getDnrUsersForSelect,
  networkStateSelector,
  permitApplicationStatuses,
} from '../../selectors/selectors'

// Utilities

// Yup Validation Schemas
const COMMENT_SCHEMA = Yup.object().shape({
  Comment: Yup.string().ensure().required('You must enter a comment.'),
})

const APPLICATION_STATUS_SCHEMA = Yup.object().shape({
  BurnPermitApplicationStatusId : Yup.number().min(1, 'You must choose an status.'),
  AssignedTo                    : Yup.string().ensure().required('You must assign someone.'),
})


const ApplicationStatusForm = props => {

  const {
    burnPermitId,
    isIssued,
    canEditPermitStatus,
    appStatusIsReadOnly,
  } = props

  const dispatch = useDispatch()
  const formikRef = React.useRef(null)

  const { online, } = useSelector(networkStateSelector)

  const dnrUsers = useSelector(getDnrUsersForSelect)

  const submit = React.useCallback((values, form) => {
    // If a permit has been issued, the status can no longer be changed
    if (isIssued === true) {
      return
    }

    values.AssignedToId = (dnrUsers.find(u => u.Text === values.AssignedTo) || {}).Value
    values.AssignedTo = null // Null this out as the API tries to serialize it to a class which will throw an error

    dispatch(BurnPermitFormActions.submitApplicationStatus(values))

    form.resetForm()
  }, [ dispatch, dnrUsers, isIssued, ])

  const setFormikRef = React.useCallback((node) => {
    if (node) {
      formikRef.current = node
    }
    return () => {
      if (formikRef && formikRef.current) {
        formikRef.current = null
      }
    }
  }, [])

  const BurnPermitApplicationStatuses = useSelector((state) => {
    return permitApplicationStatuses(state, burnPermitId)
      .filter(s => s.Text !== 'Pending' && s.Text !== 'Submitted')
  })

  const applicationStatusIsReadOnly = (isIssued || !canEditPermitStatus || appStatusIsReadOnly)

  const valSchema = canEditPermitStatus
    ? APPLICATION_STATUS_SCHEMA
    : COMMENT_SCHEMA

  const form = <Formik
    initialValues={{
      BurnPermitId                  : burnPermitId,
      BurnPermitApplicationStatusId : 0,
      AssignedToId                  : 0,
      AssignedTo                    : '',
      InternalOnly                  : false,
      SiteInspected                 : false,
      Comment                       : '',
    }}
    validationSchema={valSchema}
    innerRef={setFormikRef}
    onSubmit={submit}
    readOnly={!online}
  >
    {(formProps) => (
      <>
        {
          canEditPermitStatus && <Row>
            { !online && <p><b>Recording a Status requires an internet connection. The form will enable when a connection is restored.</b></p> }
            <Col lg={4}>
              <FormGroup>
                <Field name={'BurnPermitApplicationStatusId'}>
                  {({ field, }) => (
                    <Select
                      {...field}
                      label={'Application Status'}
                      items={BurnPermitApplicationStatuses}
                      propertyName={field.name}
                      selectedValue={field.value}
                      errorMessage={formProps.errors.BurnPermitApplicationStatusId}
                      readOnly={!online || applicationStatusIsReadOnly}
                    />
                  )}
                </Field>
              </FormGroup>
            </Col>
            <Col lg={8} className={'mb-2'}>
              <Row>
                <Col xs={7}>
                  <FormGroup>
                    <AssignedStaff />
                  </FormGroup>
                </Col>
                <Col xs={5} className={'d-flex align-items-center'}>
                  <AssignToMe />
                </Col>
              </Row>
            </Col>
            <Col md={6} className={'mb-4'}>
              <FormGroup check>
                <Field name={'InternalOnly'}>
                  {({ field, }) => (
                    <>
                      <Label check>
                        <Input
                          type={'checkbox'}
                          {...field}
                          checked={field.value}
                          disabled={!online}
                        />{' '}
                        Internal Only
                      </Label>
                      {
                        !field.value && 
                        <small className={'text-warning d-block'}>
                          Warning: This comment will be visible to the applicant
                          and they will be emailed the change of Application Status.
                        </small>
                      }
                    </>
                  )}
                </Field>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup check>
                <Field name={'SiteInspected'}>
                  {({ field, }) => (
                    <Label check>
                      <Input
                        type={'checkbox'}
                        {...field}
                        checked={field.value}
                        disabled={!online}
                      />{' '}
                      Site Inspected
                    </Label>
                  )}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        }
        <CommentRow online={online} />
        <Row>
          <Col>
            <Button
              onClick={formProps.handleReset}
              type={'reset'}
              color={'link'}>
                Reset
            </Button>
          </Col>
          <Col className={'d-flex justify-content-end'}>
            <Button
              type={'submit'}
              onClick={formProps.handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </>
    )}
  </Formik>

  return form
}

ApplicationStatusForm.propTypes = {
  burnPermitId                   : number,
  ValidDate                      : string,
  ExpirationDate                 : string,
  isIssued                       : bool,
  readOnly                       : bool,
  showPermitDurationRadioButtons : bool,
  canEditPermitStatus            : bool,
  appStatusIsReadOnly            : bool,
}

export default ApplicationStatusForm
