// Libraries
import React, { useCallback, useEffect, useMemo, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom'
import { Container, Row, Col, } from 'reactstrap'

// Reducers
import AppActions from '../redux/AppRedux'
import DashboardActions from '../redux/DashboardRedux'

// Components
import ESRIMap from '../components/ESRIMap'
import { Tile, Notices, } from '../components/Dashboard'
import ExternalLink from '../components/ExternalLink'

// Layers
import GeoJsonLayers from '../config/map/geojsonLayers'

// Selectors
import { tileLinkSelector, dashMsgsSelector, } from '../selectors/dashboardSelectors'
import { appIsOnlineSelector, } from '../selectors/selectors'


const DashboardContainer = () => {

  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const online = useSelector(appIsOnlineSelector)
  const TileLinks = useSelector(tileLinkSelector)
  const Messages = useSelector(dashMsgsSelector)

  // OnMount / OneTime
  useEffect(() => {
    dispatch(AppActions.setPageTitle('Burn Portal - WA DNR'))
    dispatch(DashboardActions.getTileLinks())
    if (online) {
      dispatch(DashboardActions.getDashMsgs())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLinks = useCallback(links => {
    if (Array.isArray(links) !== true) {
      return []
    }
    return links.map((l, i) => {
      if (!l) {
        return null
      }
      return <ExternalLink {...l} key={`dash-link-${i}`} />
    })
  }, [])

  const layerActionObj = useMemo(() => ({ navigate, }), [ navigate, ])
  const geojsonLayers = useMemo(() => GeoJsonLayers.map(l => l.bind(layerActionObj)()), [ layerActionObj, ])

  return <Container className={'pt-2'}>
    <h1>Burn Portal</h1>
    <Notices messages={Messages} />
    <Row className={'dashboard-row-big'}>
      <Col md={9}>
        <ESRIMap
          zoom={6}
          config={'Dashboard'}
          geojsonLayers={geojsonLayers}
        />
      </Col>
      <Col md={3}>
        <Row className={'dashboard-row-small'}>
          <Col md={12}>
            <Tile title={'Permits'}>
              <Link to={'/permits/search'}>Search Permits</Link>
              <Link to={'/permits/new'}>Apply For A Permit</Link>
              { renderLinks(TileLinks.Permit) }
            </Tile>
          </Col>
        </Row>
        <Row className={'dashboard-row-small'}>
          <Col md={12}>
            <Tile title={'Smoke'}>
              <Link to={'/burn-requests/search'}>Search Burn Requests</Link>
              <Link to={'/burn-requests/new'}>Submit Burn Request</Link>
              { renderLinks(TileLinks.Smoke) }
            </Tile>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <div className={'dashboard-row-small'}>
          <Tile title={'Burn Restrictions'}>
            { renderLinks(TileLinks.BurnRestrictions) }
          </Tile>
        </div>
      </Col>
      <Col md={3}>
        <div className={'dashboard-row-small'}>
          <Tile title={'Air Quality'}>
            { renderLinks(TileLinks.AirQuality) }
          </Tile>
        </div>
      </Col>
      <Col md={3}>
        <div className={'dashboard-row-small'}>
          <Tile title={'State Laws'}>
            { renderLinks(TileLinks.StateLaw) }
          </Tile>
        </div>
      </Col>
      <Col md={3}>
        <div className={'dashboard-row-small'}>
          <Tile title={'Helpful Links'}>
            { renderLinks(TileLinks.Helpful) }
          </Tile>
        </div>
      </Col>
    </Row>
  </Container>
}

export default React.memo(DashboardContainer)