// Libraries
import React, { useState, useCallback, } from 'react'
import { useSelector, } from 'react-redux'
import { Button, Input, Label, } from 'reactstrap'

// Components
import ExternalLink from '../ExternalLink'

// Selectors
import { getUserToken, } from '../../selectors/userSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'

// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER, } = process.env


const ApiToken = () => {
  // State
  const [ copiedMessage, setCopiedMessage, ] = useState('')

  // Selectors
  const token = useSelector(getUserToken)

  // UI Callbacks
  const copyToken = useCallback(async e => {
    stopEvent(e)
    await navigator.clipboard.writeText(token)
    setCopiedMessage('Token copied!')
  }, [ token, ])

  return <>
    <Label for={'api-token'}>
      API Token
    </Label>
    <ExternalLink
      href={REACT_APP_SERVER}
      className={'ml-4'}
      text={'API Explorer'}
    />
    <Button
      size={'sm'}
      className={'float-right'}
      onClick={copyToken}
    >
      Copy to clipboard
    </Button>
    {
      copiedMessage && <p className={'mr-2 my-0 float-right'}>
        {copiedMessage}
      </p>
    }
    <Input
      type={'textarea'}
      rows={3}
      id={'api-token'}
      value={token}
      readOnly
    />
  </>
}

export default React.memo(ApiToken)