import React, { useMemo, } from 'react'
import { FormFeedback, } from 'reactstrap'
import { Field, ErrorMessage, getIn, } from 'formik'
import { object, string, bool, func, number, oneOfType, } from 'prop-types'

const ValidatingField = (props) => {
  const {
    type,
    field,
    form,
    readOnly,
    onChange,
    min,
    max,
    onBlur,
    className,
    id,
    step,
    placeholder,
  } = props
  const isInvalid = useMemo(() => (!!getIn(form.errors, field.name)), [ form.errors, field.name, ])
  const inputClassName = useMemo(() => (`form-control${isInvalid ? ' is-invalid' : ''} ${className ? className : ''}`), [ isInvalid, className, ])
  const newProps = useMemo(() => {
    const _fieldProps = {
      ...field,
      min,
      max,
      placeholder,
      readOnly,
      step,
      type,
      id        : id || field.name,
      invalid   : isInvalid.toString(),
      className : inputClassName,
    }
    _fieldProps.value = _fieldProps.value !== null || _fieldProps.value !== undefined ? _fieldProps.value : ''

    if (type === 'textarea') {
      _fieldProps.component = type
    }
    if (typeof onChange === 'function') {
      _fieldProps.onChange = onChange
    }
    if (typeof onBlur === 'function') {
      _fieldProps.onBlur = onBlur
    }

    return _fieldProps
  }, [ field, id, inputClassName, isInvalid, max, min, onBlur, onChange, placeholder, readOnly, step, type, ])
  return <>
    <Field {...newProps} />
    <ErrorMessage name={newProps.name} component={FormFeedback} />
  </>
}

ValidatingField.propTypes = {
  field       : object,
  form        : object,
  type        : string,
  readOnly    : bool,
  onChange    : func,
  step        : number,
  placeholder : oneOfType([ number, string, ]),
  min         : oneOfType([ number, string, ]),
  max         : oneOfType([ number, string, ]),
  onBlur      : func,
  className   : string,
  id          : string,
}

ValidatingField.defaultProps = {
  field     : {},
  form      : {},
  type      : 'text',
  readOnly  : false,
  className : null,
}

export default React.memo(ValidatingField)
