// Libraries
import React from 'react'
import { Formik, Field, Form, } from 'formik'
import { Row, Col, FormGroup, Label, Button, } from 'reactstrap'
import { object, func, } from 'prop-types'

// Components
import AuditData from '../AuditData'
import { ValidatingField, } from '../FormControls'

// Models
import BurnPermitOrder from '../../models/BurnPermitOrder'

const RefundForm = (props) => {
  const { order, appDetail, onSubmit, } = props
  
  return <Formik
    initialValues={{
      BurnPermitOrderId  : order.BurnPermitOrderId,
      FirstName          : appDetail.FirstName,
      LastName           : appDetail.LastName ,
      CheckDate          : order.SettlementSubmissionDate,
      CheckNumber        : appDetail.CheckNumber,
      VoucherNumber      : appDetail.VoucherNumber,
      VoucherDate        : order.SettlementSubmissionDate,
      TotalAmountPaid    : Math.abs(appDetail.PaymentAmount),
      PaymentMethod      : appDetail.PaymentMethod,
      PaymentCommandCode : 'Refund',
    }}
    validationSchema={BurnPermitOrder.getValidationSchema(!!appDetail.VoucherNumber)}
    onSubmit={onSubmit}
    enableReinitialize={true}
  >
    {({ handleSubmit, }) => (
      <Form onKeyPress={evt => {
        if (evt.charCode === 13) {
          handleSubmit()
        }
      }}>
        <Row className={'mt-4'}>
          <Col>
            <h2 style={{ fontSize: '1.5rem', }}>Refund Payment</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for={'TotalAmountPaid'}>Refund Amount</Label>
              <Field
                id={'TotalAmountPaid'}
                name={'TotalAmountPaid'}
                type={'number'}
                min={0}
                step={0.01}
                component={ValidatingField}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className={'audit-data p-2'}>
          <AuditData
            CreateBy={order.CreateBy}
            CreateDate={order.CreateDate}
            UpdateBy={order.UpdateBy}
            UpdateDate={order.UpdateDate}
          />
        </Row>
        <Row className={'justify-content-end'}>
          <Button type={'submit'}>Submit</Button>
        </Row>
      </Form>
    )}
  </Formik>
}

RefundForm.propTypes = {
  order     : object,
  appDetail : object,
  onSubmit  : func,
}  

RefundForm.defaultProps = {
  order     : null,
  appDetail : null,
  onSubmit  : () => {},
}

export default RefundForm