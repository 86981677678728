import React from 'react'
import { func, array, object, string, number, PropTypes, bool, } from 'prop-types'
import { Row, Col, Card, CardBody, Input, Label, FormGroup, Button, } from 'reactstrap'
import { Link, } from 'react-router-dom'


const FormButton = ({ target, onClick, buttonText, disabled, }) => {
  return <Button
    color={'primary'}
    size={'sm'}
    onClick={onClick}
    data-target={target}
    disabled={disabled}
  >
    {buttonText || 'Select All'}
  </Button>
}

FormButton.propTypes = {
  target     : PropTypes.oneOfType([ object, string, ]),
  onClick    : func,
  buttonText : PropTypes.oneOfType([ object, string, ]),
  disabled   : bool,
}


const MergeForm = ({
  obj,
  id,
  onChange,
  showControls,
  checkAll,
  checkedIds,
  onButtonClick,
  targetName,
  buttonText,
  disabled,
  profileRoute,
}) => {
  const objKeys = Object.keys(obj)
  const markup = []
  for (let i = 0, len = objKeys.length; i < len; i++) {
    const key = objKeys[i]
    const valueObj = obj[key]
    // Chrome devtools cannot handle styling the code when template
    // literals are used inline of elements. Breaking them out in to
    // variables preserves the code styling.
    const domKey = `${key}-group`
    if (Array.isArray(valueObj.Value)) {
      const cardBodyClass = `p-3 ${domKey}`
      const arrMarkup = []
      if (!valueObj.Value.length) {
        const card = <Card key={domKey} className={'my-2'}>
          <CardBody className={cardBodyClass}>No {valueObj.Label}.</CardBody>
        </Card>
        arrMarkup.push(card)
      }
      else {
        for (let a = 0, aLen = valueObj.Value.length; a < aLen; a++) {
          const cardKey = `${domKey}-${a}`
          const card = <Card key={cardKey} className={'my-2'}>
            <CardBody className={cardBodyClass}>
              <Row>
                <Col xs={12} lg={4}>
                  <i className={'fas'}></i>
                  <div>ID: {valueObj.Value[a]}</div>
                </Col>
                <Col xs={12} lg={8}>
                  {
                    Array.isArray(valueObj.Text[a])
                      ? valueObj.Text[a].map((t, i) => <div key={`${cardKey}-text-${i}`}>{t}</div>)
                      : <div>{valueObj.Text[a]}</div>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
          arrMarkup.push(card)
        }
      }
      markup.push(<Row key={domKey}><Col><Label>{valueObj.Label}</Label>{arrMarkup}</Col></Row>)
      continue
    }
    const inputId = `${key}-${id}`
    const _showControls = showControls && valueObj.Readonly !== true
    markup.push(<FormGroup key={domKey}>
      <Row>
        {
          _showControls && <Col xs={1}>
            <Input
              name={key}
              value={valueObj.Value || ''}
              data-label={valueObj.Label}
              data-text={valueObj.Text}
              id={inputId}
              type={'radio'}
              onChange={onChange}
              checked={checkAll || checkedIds.indexOf(inputId) > -1}
              disabled={disabled}
            />
          </Col>
        }
        <Col xs={_showControls ? 11 : 12}>
          <Label
            for={inputId}
            className={'m-0'}
          >
            {valueObj.Label}
          </Label>
          <Input
            value={valueObj.Text || valueObj.Value || ''}
            readOnly={true}
            tabIndex={-1}
            className={valueObj.Warning && 'is-invalid'}
          />
          {
            !_showControls &&
              <Input
                type={'hidden'}
                value={valueObj.Value || ''}
                name={key}
              />
          }
          {
            valueObj.Warning && <div className={'invalid-feedback'}>{valueObj.Warning}</div>
          }
        </Col>
      </Row>
    </FormGroup>
    )
  }
  return <>
    <div className={'mt-3'}>
      <FormButton
        buttonText={buttonText}
        target={targetName}
        onClick={onButtonClick}
        disabled={disabled}
      />
      {
        profileRoute && <Link
          to={`${profileRoute}${id}`}
          className={'ml-2'}
          disabled={disabled}
        >View Profile</Link>
      }
    </div>
    <div className={'mt-4'}>{markup}</div>
  </>
}

MergeForm.propTypes = {
  obj           : object,
  id            : PropTypes.oneOfType([ string, number, ]),
  onChange      : func,
  showControls  : bool,
  checkedIds    : array,
  checkAll      : PropTypes.oneOfType([ string, bool, ]),
  onButtonClick : func,
  targetName    : string,
  buttonText    : string,
  disabled      : bool,
  profileRoute  : string,
}

MergeForm.defaultProps = {
  showControls : true,
  disabled     : false,
}

export default MergeForm