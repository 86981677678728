import { createReducer, createActions, } from 'reduxsauce'

import PostBurn from '../models/PostBurn'
import BurnRequest from '../models/BurnRequest'

const POST_BURN_ENDPOINT = PostBurn.endpoint()

/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  // get by burn request
  getPostBurnsByRequestId: burnRequestId => ({
    type : 'GET_POST_BURNS_BY_REQUEST_ID',
    meta : {
      offline: {
        effect   : { url: BurnRequest.postBurnEndpoint({ burnRequestId, }), },
        commit   : { type: 'GET_POST_BURNS_BY_REQUEST_ID_SUCCESS', burnRequestId, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  getPostBurnsByRequestIdSuccess : [ 'payload', ],
  // get by post burn id
  getPostBurnById                : postBurnId => ({
    type : 'GET_POST_BURN_BY_ID',
    meta : {
      offline: {
        effect   : { url: PostBurn.endpoint(postBurnId), },
        commit   : { type: 'GET_POST_BURN_BY_ID_SUCCESS', postBurnId, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  getPostBurnByIdSuccess : [ 'payload', ],
  // get post burns by permit
  getPostBurnsByPermit   : [ 'permitId', 'permitNumber', ],
  // create actions
  createPostBurn         : [ 'postBurnObj', ],
  createPostBurnRequest  : (postBurnObj) => ({
    type : 'CREATE_POST_BURN_REQUEST',
    meta : {
      offline: {
        effect: {
          url  : POST_BURN_ENDPOINT,
          init : {
            method : 'POST',
            body   : { ...postBurnObj, },
          },
        },
        commit   : { type: 'POST_BURN_REQUEST_SUCCESS', },
        rollback : { type: 'POST_BURN_REQUEST_FAIL', },
      },
    },
  }),
  // update actions
  updatePostBurn        : [ 'postBurnId', 'postBurnObj', ],
  updatePostBurnRequest : (postBurnId, postBurnObj) => ({
    type    : 'UPDATE_POST_BURN_REQUEST',
    payload : { ...postBurnObj, },
    meta    : {
      offline: {
        effect: {
          url  : PostBurn.endpoint(postBurnId),
          init : {
            method : 'PUT',
            body   : { ...postBurnObj, },
          },
        },
        commit   : { type: 'POST_BURN_REQUEST_SUCCESS', },
        rollback : { type: 'POST_BURN_REQUEST_FAIL', },
      },
    },
  }),
  // create/update actions
  postBurnRequestSuccess    : [ 'payload', ],
  postBurnRequestFail       : [ 'payload', ],
  // offline queuing
  cancelPostBurnSubmit      : [ 'id', ],
  postBurnSubmitted         : [ 'PostBurnId', ],
  submittingPostBurns       : [ 'PostBurnId', ],
  // form setting actions
  setPostBurnRequestId      : [ 'id', ],
  postBurnUseRequest        : [ 'useRequest', ],
  setPostBurnPermitId       : [ 'id', ],
  setPostBurnPermitByNumber : [ 'number', ],
  setPostBurnId             : [ 'id', ],
  initializePostBurnForm    : [ 'burnPermitId', 'burnRequestId', 'postBurnId', ],
  // loading indicators
  postBurnLoadingComplete   : null,
  postBurnLoading           : null,
  // post burn prompt actions
  setPostBurnPrompt         : [ 'promptObjs', ],
  clearPostBurnPrompt       : null,
  getPbPromptsForPermit     : [ 'id', ],
  getPbPromptsForUser       : null,
})

export const PostBurnTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Object.freeze({
  ActiveRequestId     : -1,
  ActivePermitId      : -1,
  ActivePostBurnId    : -1,
  PostBurnIsLoading   : false,
  postBurnPrompt      : [],
  SubmittingPostBurns : [],
  PostBurnUsesRequest : true,
})

/* ------------- Reducers ------------- */
export const initializePostBurnForm = (state, action) => {
  const { burnPermitId, burnRequestId, postBurnId, } = action
  const newState = Object.assign({}, state)
  newState.PostBurnUsesRequest = true
  newState.ActiveRequestId = (!isNaN(burnRequestId)) ? parseInt(burnRequestId) : -1
  newState.ActivePermitId = (!isNaN(burnPermitId)) ? parseInt(burnPermitId) : -1
  newState.ActivePostBurnId = (!isNaN(postBurnId)) ? parseInt(postBurnId) : -1
  return newState
}


export const setPostBurnId = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (isNaN(id) || (state.ActivePostBurnId === id)) {
    return state
  }
  newState.ActivePostBurnId = parseInt(id)
  return newState
}

export const setPostBurnRequestId = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (isNaN(id) || (state.ActiveRequestId === id)) {
    if (state.PostBurnUsesRequest === true) {
      return state
    }
    newState.PostBurnUsesRequest = true
    return newState
  }
  newState.PostBurnUsesRequest = true
  newState.ActiveRequestId = parseInt(id)
  return newState
}

export const setPostBurnRequestUse = (state, { useRequest, }) => {
  const newState = Object.assign({}, state)
  if (useRequest === true){
    newState.PostBurnUsesRequest = true
  } else {
    newState.PostBurnUsesRequest = false
    newState.ActiveRequestId = -1
  }
  return newState
}

export const setPostBurnPermitId = (state, { id, }) => {
  const newState = Object.assign({}, state)
  if (isNaN(id) || (state.ActivePermitId === id)) {
    return state
  }
  newState.ActivePermitId = parseInt(id)
  return newState
}

export const postBurnSubmitted = (state, { PostBurnId, }) => {
  const newState = Object.assign({}, state)
  const idIdx = newState.SubmittingPostBurns.indexOf(PostBurnId)
  if (idIdx > -1) {
    newState.SubmittingPostBurns.splice(idIdx, 1)
    return newState
  }
  return state
}

export const cancelPostBurnSubmit = (state, { id, }) => {
  const newState = Object.assign({}, state)
  const idIdx = newState.SubmittingPostBurns.indexOf(id)
  if (idIdx > -1) {
    newState.SubmittingPostBurns.splice(idIdx, 1)
  }
  return newState
}

export const submittingPostBurns = (state, { PostBurnId, }) => {
  const newState = Object.assign({}, state)
  const idIdx = newState.SubmittingPostBurns.indexOf(PostBurnId)
  if (idIdx === -1) {
    newState.SubmittingPostBurns.push(PostBurnId)
  }
  return newState
}

export const postBurnLoading = (state) => {
  const newState = Object.assign({}, state)
  newState.PostBurnIsLoading = true
  return newState
}

export const postBurnLoadingComplete = (state) => {
  const newState = Object.assign({}, state)
  newState.PostBurnIsLoading = false
  return newState
}

export const setPostBurnPrompt = (state, { promptObjs, }) => {
  const newState = Object.assign({}, state)
  newState.postBurnPrompt = promptObjs
  return newState
}

export const clearPostBurnPrompt = (state) => {
  const newState = Object.assign({}, state)
  newState.postBurnPrompt = []
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_POST_BURN_PERMIT_ID]    : setPostBurnPermitId,
  [Types.SET_POST_BURN_REQUEST_ID]   : setPostBurnRequestId,
  [Types.SET_POST_BURN_ID]           : setPostBurnId,
  [Types.POST_BURN_LOADING]          : postBurnLoading,
  [Types.POST_BURN_LOADING_COMPLETE] : postBurnLoadingComplete,
  [Types.SET_POST_BURN_PROMPT]       : setPostBurnPrompt,
  [Types.CLEAR_POST_BURN_PROMPT]     : clearPostBurnPrompt,
  [Types.CANCEL_POST_BURN_SUBMIT]    : cancelPostBurnSubmit,
  [Types.SUBMITTING_POST_BURNS]      : submittingPostBurns,
  [Types.POST_BURN_SUBMITTED]        : postBurnSubmitted,
  [Types.POST_BURN_USE_REQUEST]      : setPostBurnRequestUse,
  [Types.INITIALIZE_POST_BURN_FORM]  : initializePostBurnForm,
})