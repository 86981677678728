import { createSelector, } from 'reselect'
import { ormSelector, ormWithPropSelector, ormByIdSelector, } from './selectors'
import { GetRequestSymbolByStatus, } from '../config/map/symbols'
import { dateFormatter, } from '../utilities'
import { formatLegalDesc, } from '../utilities/columnHelpers'
import { userCanEnterAnyIgnDate, userCanBypassSignature, } from './burnRequestPermissionSelectors'
import { PERSON_TYPE_GOV_AGENT, } from './env'

export const burnReqStateSelector = state => state.BurnRequestDetail

export const requestDownloadedCount = ormSelector(
  ({ BurnRequest, }) => {
    const count = BurnRequest.count()
    return count
  }
)

export const burnRequestsSelector = ormSelector(
  ({ BurnRequestSearch, }) => {
    return BurnRequestSearch.all().toRefArray()
  }
)

const searchStateSelector = state => state.BurnRequestSearch

const burnRequestSearchParamSelector = createSelector(
  searchStateSelector,
  state => state.searchParams || {}
)
export const burnRequestSearchDataSelector = createSelector(
  searchStateSelector,
  state => state || {}
)

export const burnRequestsOfflineSelector = ormWithPropSelector(
  burnRequestSearchParamSelector,
  ({ BurnRequestSearch, }, searchParams) => {

    let results = BurnRequestSearch

    // Default to an empty object just in case the prop is null or undefined
    if (!searchParams) {
      searchParams = {}
    }

    const {
      BurnRequestId,
      BurnPermitId,
      BurnPermitNumber,
      BurnRequestStatus,
      BurnRequestStartDate,
      BurnRequestEndDate,
      PlannedIgnitionStartDate,
      PlannedIgnitionEndDate,
      AssignedTo,
      UnitName,
      County,
      Region,
      LegalDesc,
      MinAcres,
      MaxAcres,
      MinTons,
      MaxTons,
      BurnType,
      AgentName,
      LandownerName,
      Address,
      HasPostBurn,
      MultiDayBurn,
    } = searchParams

    if (BurnRequestId) {
      results = results.filter({ BurnRequestId: parseInt(BurnRequestId), })
    }

    if (BurnPermitId) {
      results = results.filter({ BurnPermitId: parseInt(BurnPermitId), })
    }

    if (BurnPermitNumber) {
      results = results.filter(r => r.BurnPermitNumber && r.BurnPermitNumber.toLocaleLowerCase().includes(BurnPermitNumber.toLocaleLowerCase()))
    }

    if (AgentName) {
      results = results.filter(r => r.Agent && r.Agent.toLocaleLowerCase().includes(AgentName.toLocaleLowerCase()))
    }

    if (LandownerName) {
      results = results.filter(r => r.Landowner && r.Landowner.toLocaleLowerCase().includes(LandownerName.toLocaleLowerCase()))
    }

    if (AssignedTo) {
      results = results.filter({ AssignedTo, })
    }

    if (BurnType) {
      results = results.filter({ BurnType, })
    }

    if (BurnRequestStatus) {
      results = results.filter({ BurnRequestStatus, })
    }

    if (UnitName) {
      results = results.filter(r => r.BurnUnitName && r.BurnUnitName.toLocaleLowerCase().includes(UnitName.toLocaleLowerCase()))
    }

    if (County) {
      results = results.filter({ County, })
    }

    if (Region) {
      results = results.filter({ Region, })
    }

    if (Address) {
      results = results.filter(r => 
        [
          `${r.Address1 || ''} ${r.Address2 || ''} ${r.Address3 || ''}`.trim(),
          `${r.AddressCity ? ` ${r.AddressCity},` : ''} ${r.AddressState} ${r.AddressZip || ''}`.trim(),
        ].join(' ').toLocaleLowerCase().includes(Address.toLocaleLowerCase()))
    }

    if (LegalDesc) {
      results = results.filter(r => 
        `${r.LegalDescQ1 || ''} ${r.LegalDescQ2 || ''} S${r.LegalDescSection} T${r.LegalDescTownship} R${r.LegalDescRange} ${r.LegalDescDirection || ''}`
          .trim().toLocaleLowerCase().includes(LegalDesc.toLocaleLowerCase())
      )
    }

    if (MinAcres) {
      results = results.filter(r => r.BurnAcres >= MinAcres)
    }

    if (MaxAcres) {
      results = results.filter(r => r.BurnAcres <= MaxAcres)
    }

    if (MinTons) {
      results = results.filter(r => r.TotalPermitTonnage >= MinTons)
    }

    if (MaxTons) {
      results = results.filter(r => r.TotalPermitTonnage <= MaxTons)
    }

    if (BurnRequestStartDate) {
      results = results.filter(r => r.BurnRequestDate >= BurnRequestStartDate)
    }

    if (BurnRequestEndDate) {
      results = results.filter(r => r.BurnRequestDate <= BurnRequestEndDate)
    }

    if (PlannedIgnitionStartDate) {
      results = results.filter(r => r.PlannedIgnitionDate >= PlannedIgnitionStartDate)
    }

    if (PlannedIgnitionEndDate) {
      results = results.filter(r => r.PlannedIgnitionDate <= PlannedIgnitionEndDate)
    }

    if (HasPostBurn !== '') {
      results = results.filter({ HasPostBurn, })
    }

    if (MultiDayBurn !== '') {
      results = results.filter({ MultiDayBurn, })
    }

    return results.all().toRefArray()
  }
)

export const mapBurnRequest = (req) => {
  if (!req) {
    return null
  }
  return {
    BurnRequestId              : parseInt(req.BurnRequestId),
    CreateDate                 : req.CreateDate,
    CreateBy                   : req.CreateBy,
    UpdateDate                 : req.UpdateDate,
    UpdateBy                   : req.UpdateBy,
    MultiDayBurnFlag           : req.MultiDayBurnFlag,
    BurnPriority               : req.BurnPriority,
    BurnRequestDate            : req.BurnRequestDate,
    PlannedIgnitionDate        : req.PlannedIgnitionDate,
    PlannedIgnitionPeriod      : parseInt(req.PlannedIgnitionPeriod),
    PlannedIgnitionTime        : req.PlannedIgnitionTime,
    SmokeDispersedFlag         : req.SmokeDispersedFlag,
    TotalProposedBurnQuantity  : req.TotalProposedBurnQuantity,
    ProposedBurnArea           : parseInt(req.ProposedBurnArea),
    RequestorComments          : req.RequestorComments,
    BurnPermitRequestLogNumber : req.BurnPermitRequestLogNumber,
    BurnPermitId               : parseInt(req.BurnPermitId),
    DaysOfWeek                 : req.DaysOfWeek.toModelArray().map(d => d.DayOfWeekId),
    IsLocal                    : req.IsLocal,
    AutoGenerated              : req.AutoGenerated,
    ParentRequestId            : req.ParentRequestId,
  }
}

export const burnRequestByIdSelector = ormByIdSelector(
  ({ BurnRequest, }, id) => {
    if (isNaN(id)) {
      return null
    }

    const req = BurnRequest.withId(id)
    return mapBurnRequest(req)
  }
)
export const burnRequestModelByIdSelector = ormByIdSelector(
  ({ BurnRequest, }, id) => BurnRequest.withId(id)
)

export const getPermitSummaryForRequest = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }

    const burnPermit = BurnPermit.withId(BurnPermitId)
    if (!burnPermit) {
      return null
    }

    const UnitName = burnPermit.BurnPermitSite ? burnPermit.BurnPermitSite.BurnUnitName || '' : ''
    
    let HarvestAcres = '', BurnTypes = []
    if (burnPermit.BurnPermitArea) {
      HarvestAcres = burnPermit.BurnPermitArea.HarvestAcres
      BurnTypes = burnPermit.BurnPermitArea.BurnTypes.toModelArray().join(', ')
    }
    
    let Burner = '', Agent = ''
    if (burnPermit.Burner) {
      Burner = `${burnPermit.Burner.PersonFirstName} ${burnPermit.Burner.PersonLastName}`
    }

    if (burnPermit.Agent) {
      Agent = `${burnPermit.Agent.PersonFirstName} ${burnPermit.Agent.PersonLastName}`
    }
    
    let Status = ''
    const currentStatus = burnPermit.BurnPermitStatusXrefs.orderBy('StatusDate', 'Desc').first()
    if (currentStatus && currentStatus.BurnPermitStatus) {
      Status = currentStatus.BurnPermitStatus.BurnPermitStatusName
    }

    let ForestHealthExempt
    if (burnPermit.ForestHealthExempt !== null) {
      // Migrated permits do not have problem types, so add a guard
      // to prevent spreading a non-iterable instance
      let ProblemType
      if (burnPermit.ForestHealthExempt.ForestHealthProblemTypes.exists()) {
        ProblemType = burnPermit.ForestHealthExempt.ForestHealthProblemTypes.first().ForestHealthExemptProblemTypeName
      }
      ForestHealthExempt = {
        AlternativeToRxFire               : burnPermit.ForestHealthExempt.AlternativeToRxFire || '',
        AlternativeNotAppropriateReason   : burnPermit.ForestHealthExempt.AlternativeNotAppropriateReason || '',
        ForestHealthExemptionApprovalFlag : burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalFlag,
        ForestHealthExemptionApprovalDate : burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalDate || burnPermit.ForestHealthExempt.CreateDate,
        ForestHealthExemptionApprovalBy   : burnPermit.ForestHealthExempt.ForestHealthExemptionApprovalBy || burnPermit.ForestHealthExempt.CreateBy,
        ProblemType,
      }
    }

    let Latitude, Longitude
    if (burnPermit.BurnPermitLocation) {
      Latitude = burnPermit.BurnPermitLocation.Latitude
      Longitude = burnPermit.BurnPermitLocation.Longitude
    }
    
    const mapped = {
      BurnPermitId              : burnPermit.BurnPermitId,
      BurnPermitNumber          : burnPermit.BurnPermitNumber,
      TotalPermitTonnage        : burnPermit.TotalPermitTonnage,
      BurnPermitValidDate       : burnPermit.BurnPermitValidDate,
      BurnPermitExpirationDate  : burnPermit.BurnPermitExpirationDate,
      MailPermitToLandownerFlag : burnPermit.MailPermitToLandownerFlag,
      MailPermitToAgentFlag     : burnPermit.MailPermitToAgentFlag,
      Burner,
      Agent,
      HarvestAcres,
      BurnTypes,
      UnitName,
      Status,
      ForestHealthExempt,
      Latitude,
      Longitude,
      CreateBy                  : burnPermit.CreateBy,
      CreateDate                : burnPermit.CreateDate,
      UpdateBy                  : burnPermit.UpdateBy,
      UpdateDate                : burnPermit.UpdateDate,
    }
    return mapped
  }
)

export const permitIsPileBurnOnly = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    if (isNaN(BurnPermitId)) {
      return null
    }

    const burnPermit = BurnPermit.withId(BurnPermitId)
    if (!burnPermit || !burnPermit.BurnPermitArea || !burnPermit.BurnPermitArea.BurnTypes) {
      return null
    }
    return burnPermit.BurnPermitArea.BurnTypes
      .all()
      .toRefArray()
      .map(t => t.BurnTypeName)
      .every(t => t === 'Pile')
  })

export const getBurnRequestStatusIdByName = ormWithPropSelector(
  ({ BurnRequestStatus, }, BurnRequestStatusName) => {
    const statuses = BurnRequestStatus.filter({ BurnRequestStatusName, })
    if (!statuses.exists()) {
      return null
    }
    return statuses.first().BurnRequestStatusId
  }
)

export const burnRequestStatuses = ormWithPropSelector(
  ({ BurnRequestStatus, }, filter) => {
    let statuses = BurnRequestStatus.all()
    if (typeof filter === 'function') {
      statuses = statuses.filter(filter)
    }
    statuses = statuses
      .toModelArray()
      .map(s => {
        return {
          Value : s.BurnRequestStatusId,
          Text  : s.BurnRequestStatusName,
        }
      })
    return statuses
  }
)

export const burnRequestStatus = ormByIdSelector(
  ({ BurnRequestStatusXref, }, BurnRequestId) => {
    if (isNaN(BurnRequestId)) {
      return null
    }
    
    const statusXref = BurnRequestStatusXref
      .filter({ BurnRequestId, })
      .orderBy('CreateDate', 'desc')
      .first()

    if (!statusXref) {
      return null
    }

    let BurnRequestStatus = {}
    if (statusXref.BurnRequestRequestStatus) {
      BurnRequestStatus = statusXref.BurnRequestRequestStatus
    }

    return {
      BurnRequestStatusXrefId : statusXref.BurnRequestStatusXrefId,
      BurnRequestId           : statusXref.BurnRequestId,
      StatusDate              : statusXref.StatusDate,
      Comment                 : statusXref.Comment,
      Status                  : BurnRequestStatus.BurnRequestStatusName || '',
      BurnRequestStatusId     : BurnRequestStatus.BurnRequestStatusId || '',
      CreateBy                : statusXref.CreateBy,
      CreateDate              : statusXref.CreateDate,
      UpdateBy                : statusXref.UpdateBy,
      UpdateDate              : statusXref.UpdateDate,
    }
  }
)

export const burnRequestStatusHistory = ormByIdSelector(
  ({ BurnRequestStatusXref, }, BurnRequestId) => {
    if (isNaN(BurnRequestId)) {
      return null
    }
    
    const requestStatusXrefs = BurnRequestStatusXref.filter({ BurnRequestId, })
    if (!requestStatusXrefs.exists()) {
      return null
    }

    const statuses = []

    requestStatusXrefs
      .toModelArray()
      .forEach((x) => {
        let statusName = ''
        if (x.BurnRequestRequestStatus && !!x.BurnRequestRequestStatus.BurnRequestStatusName) {
          statusName = x.BurnRequestRequestStatus.BurnRequestStatusName
        }
        
        statuses.push({
          BurnRequestStatusXrefId : x.BurnRequestStatusXrefId,
          CreateBy                : x.CreateBy,
          StatusDate              : x.StatusDate,
          Comment                 : x.Comment,
          Status                  : statusName,
          BurnRequestStatusId     : x.BurnRequestStatusId,
          BurnRequestId           : x.BurnRequestId,
          InternalOnly            : x.InternalOnly,
          AssignedTo              : x.AssignedTo,
        })
      })

    // Order by StatusDate desc just in case
    const sortedStatuses = statuses
      .sort((a, b) => {
        if (a.StatusDate < b.StatusDate) {
          return 1
        }
        if (a.StatusDate > b.StatusDate) {
          return -1
        }
        return 0
      })
    return sortedStatuses
  }
)

export const burnRequestCancellationReasonsForSelect = ormSelector(
  ({ BurnRequestCancellationReason, }) => {
    const reasons = BurnRequestCancellationReason.all()
    if (reasons.exists() !== true) {
      return []
    }
    return reasons
      .toModelArray()
      .map(s => {
        return {
          Value : s.BurnRequestCancellationReasonId,
          Text  : `${s.BurnRequestCancellationReason} - ${s.BurnRequestCancellationReasonDescription}`,
        }
      })
  }
)


export const burnRequestMapDataSelector = ormWithPropSelector(
  ({ BurnRequestSearch, }, showLandownerAgent) => {

    const fields = [
      {
        name  : 'BurnPermitNumber',
        label : 'Burn Permit Number',
      },
      {
        name  : 'Status',
        label : 'Status',
      },
      {
        name  : 'StatusDate',
        label : 'Status Date',
      },
      {
        name  : 'MultiDay',
        label : 'Multi Day Burn',
      },
      {
        name  : 'HasPostBurn',
        label : 'Has Burned',
      },
      {
        name  : 'BurnTypes',
        label : 'Burn Types',
      },
      {
        name  : 'Address',
        label : 'Address',
      },
      {
        name  : 'LegalDesc',
        label : 'Legal Desc.',
      },
      {
        name  : 'HarvestAcres',
        label : 'Harvest Acres',
      },
      {
        name  : 'ExpirationDate',
        label : 'Expiration Date',
      },
      {
        name  : 'RequestedAcres',
        label : 'Requested Acres',
      },
      {
        name  : 'TotalPermitTonnage',
        label : 'Est. Permit Tonnage',
      },
      {
        name  : 'RequestedTons',
        label : 'Requested Tons',
      },
      {
        name  : 'PlannedIgnitionDate',
        label : 'Planned Ignition Date',
      },
      {
        name  : 'PlannedIgnitionTime',
        label : 'Planned Ignition Time',
      },
      {
        name  : 'IsUGA',
        label : 'Is in UGA',
      },
      {
        name  : 'IsForestHealthExempt',
        label : 'Is Forest Health Exempt',
      },
      {
        name  : 'UnitName',
        label : 'Burn Unit Name',
      },
      {
        name  : 'LatLong',
        label : 'Lat, Long',
      },
    ]

    if (showLandownerAgent) {
      const landowner = {
        name  : 'Landowner',
        label : 'Landowner',
      }
      const agent = {
        name  : 'Agent',
        label : 'Agent',
      }
      fields.splice(fields.findIndex(c => c.name === 'HasPostBurn'), 0, landowner)
      fields.splice(fields.findIndex(c => c.name === 'HasPostBurn'), 0, agent)
    }

    const layer = {
      layerId    : 'BurnRequests',
      layerTitle : 'Burn Requests',
      data       : [],
      fields,
      idField    : 'BurnRequestId',
      title      : 'Burn Request #{BurnRequestId}',
    }

    let data = BurnRequestSearch.all().filter(r => !!r.Longitude && !!r.Latitude)
    if (data.exists() === false) {
      return layer
    }
    data = data.toRefArray()
    
    for (let i = 0, len = data.length; i < len; i++) {
      const row = data[i]
      const {
        Latitude,
        Longitude,
        BurnPermitId,
        BurnPermitNumber,
        Address,
        BurnAcres,
        BurnTypes,
        BurnUnitName,
        BurnRequestId,
        BurnPermitExpirationDate,
        PlannedIgnitionDate,
        PlannedIgnitionTime,
        TotalPermitTonnage,
        RequestedAcres,
        RequestedTons,
        BurnRequestStatus,
        BurnRequestStatusDate,
        IsUGA,
        IsForestHealthExempt,
      } = row

      let Agent = '', Landowner = ''
      if (showLandownerAgent) {
        Agent = row.Agent
        Landowner = row.Landowner
      }
      
      const symbol = GetRequestSymbolByStatus(BurnRequestStatus, IsUGA)
      layer.data.push({
        geometry: {
          type      : 'point',
          latitude  : Latitude,
          longitude : Longitude,
        },
        attributes: {
          BurnRequestId        : BurnRequestId.toString(),
          BurnPermitId         : BurnPermitId.toString(),
          Agent,
          Landowner,
          BurnPermitNumber,
          BurnTypes,
          Address,
          PlannedIgnitionDate  : dateFormatter(PlannedIgnitionDate, 'MMM DD, YYYY'),
          PlannedIgnitionTime,
          RequestedAcres,
          TotalPermitTonnage,
          RequestedTons,
          UnitName             : BurnUnitName,
          LegalDesc            : formatLegalDesc(row),
          Status               : BurnRequestStatus,
          StatusDate           : dateFormatter(BurnRequestStatusDate, 'MMM DD, YYYY'),
          ExpirationDate       : dateFormatter(BurnPermitExpirationDate, 'MMM DD, YYYY'),
          HarvestAcres         : BurnAcres,
          IsUGA                : IsUGA ? 'Yes' : 'No',
          IsForestHealthExempt : IsForestHealthExempt === null ? 'N/A' : IsForestHealthExempt ? 'Yes' : 'No',
          LatLong              : `${Latitude}, ${Longitude}`,
        },
        symbol,
      })
    }
    
    return layer
  }
)

export const burnRequestHasPostBurn = ormByIdSelector(
  ({ BurnRequest, }, BurnRequestId) => {
    if (isNaN(BurnRequestId)) {
      return false
    }
    const req = BurnRequest.withId(BurnRequestId)
    if (!req) {
      return false
    }

    return req.PostBurns.filter(pb => { return pb.IsLocal !== true }).exists()
  }
)

export const burnRequestStatusObjSelector = ormByIdSelector(
  burnRequestStatus,
  (orm, burnRequestId, status) => {
    let statusObj = {
      IsCancelled    : false,
      IsSubmitted    : false,
      IsApproved     : false,
      IsInfoRequired : false,
      IsUnderReview  : false,
      IsLocked       : false,
    }
    if (isNaN(burnRequestId) || !status || !status.Status) {
      return statusObj
    }
    statusObj.IsDenied = status.Status === 'Denied'
    statusObj.IsCancelled = status.Status === 'Cancelled'
    statusObj.IsSubmitted = status.Status === 'Submitted'
    statusObj.IsApproved = status.Status === 'Approved'
    statusObj.IsInfoRequired = status.Status === 'Info Required'
    statusObj.IsUnderReview = status.Status === 'Under Review'
    statusObj.IsLocked = status.Status === 'Locked'
    return statusObj
  }
)

export const burnRequestIsApproved = ormByIdSelector(
  ({ BurnRequestStatusXref, }, BurnRequestId) => {
    const statuses = BurnRequestStatusXref
      .orderBy('StatusDate', 'desc')
      .all()
      .toModelArray()
      .filter(x => {
        return x.BurnRequestId === BurnRequestId
      })
      .slice(0, 2)

    if (statuses.length < 2) {
      return false
    }
    return statuses.every(s => s.BurnRequestRequestStatus.BurnRequestStatusName === 'Approved')
  }
)

export const burnRequestInPersonsRegion = ormWithPropSelector(
  (state, reqId, personId) => personId,
  ({ BurnRequest, PersonRegionXref, }, BurnRequestId, PersonId) => {
    let canAccessBurnReq = false
    const burnReq = BurnRequest.withId(BurnRequestId)
    let permitRegionId
    if (burnReq.BurnPermit.BurnPermitLocation) {
      permitRegionId = burnReq.BurnPermit.BurnPermitLocation.RegionId
    }
    const personRegion = PersonRegionXref.filter({ PersonRegionXrefPersonId: PersonId, }).first()
    if (personRegion) {
      canAccessBurnReq = personRegion.PersonRegionXrefRegionId === permitRegionId
    }
    return canAccessBurnReq
  }
)

export const burnRequestRegion = ormWithPropSelector(
  ({ BurnRequest, }, BurnRequestId) => {
    const burnReq = BurnRequest.withId(BurnRequestId)
    let permitRegionId
    if (burnReq.BurnPermit.BurnPermitLocation) {
      permitRegionId = burnReq.BurnPermit.BurnPermitLocation.RegionId
    }
    return permitRegionId
  }
)

export const burnRequestValSchemaSelector = ormSelector(
  userCanEnterAnyIgnDate,
  ({ BurnRequest, }, isDnr) => {
    if (!BurnRequest || !BurnRequest.getValidationSchema || (typeof BurnRequest.getValidationSchema !== 'function')){
      return null
    }
    return BurnRequest.getValidationSchema(isDnr)
  }
)

/** 
 * Determine if a request can be submitted with the slected permit
 * without the applicant's signature
 */
export const reqCanSubmitWithoutSignature = ormSelector(
  (session, id) => id,
  userCanBypassSignature,
  ({ BurnRequest, Person, PersonAgencyXref, BurnPermit, }, permitId, canBypass) => {
    if (!BurnRequest || !BurnPermit || !Person || !PersonAgencyXref || isNaN(permitId)) {
      return false
    } 
    
    // can only submit request without signature as DNR or a Verified Gov Agent
    if (!canBypass) {
      return false
    }

    // if permit isn't loaded, don't submit request
    const permit = BurnPermit.withId(permitId)
    if (!permit) {
      return false
    }

    // permit must be issued or extended to submit the request
    const currentStatus = permit.BurnPermitStatusXrefs.orderBy('StatusDate', 'Desc').first()
    if (!currentStatus || (currentStatus.BurnPermitStatus && currentStatus.BurnPermitStatus.BurnPermitStatusName !== 'Issued' && currentStatus.BurnPermitStatus.BurnPermitStatusName !== 'Extended')) {
      return false
    }

    const agent  = permit.Agent
    const burner = permit.Burner

    // permit is ok, if either agent or burner is a gov agency user then allow
    if (agent && agent.PersonType && agent.PersonType.PersonTypeName === PERSON_TYPE_GOV_AGENT) {
      const xref = PersonAgencyXref.filter(x => x.PersonAgencyXrefPersonId === agent.PersonId).first()
      if (xref && !!xref.ConfirmedBy && !!xref.ConfirmedOn){
        return true
      }
    } 
    if (burner && burner.PersonType && burner.PersonType.PersonTypeName === PERSON_TYPE_GOV_AGENT) {
      const xref = PersonAgencyXref.filter(x => x.PersonAgencyXrefPersonId === burner.PersonId).first()
      if (xref && !!xref.ConfirmedBy && !!xref.ConfirmedOn){
        return true
      }
    }

    // neither agent or burner is an agency user
    return false
  }
)