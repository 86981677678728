// Libraries
import { put, call, select, takeLatest, all, takeEvery, } from 'redux-saga/effects'

// Sagas
import { hideLoading, showLoading, } from './AppSagas'
import { doFetch, getLookupData, showError, extractPayload, } from './ApiSagas'
import { destroyLocalModels, createLocalModel, replaceAll, } from './OrmSagas'
import { getBurnPermitInfo, } from './BurnRequestDetailSagas'
import { getBurnPileGroups, } from './BurnPermitPileGroupSagas'
import { getBurnPermitFuelsInfo, } from './BurnPermitFuelsSagas'
import { apiResponseIsAuthorized, } from './ApiSagas'

// Reducers
import { OrmTypes, } from '../redux/OrmRedux'
import AppActions, { AppTypes, } from '../redux/AppRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import BurnRequestDetailActions from '../redux/BurnRequestDetailRedux'
import PostBurnActions, { PostBurnTypes, } from '../redux/PostBurnRedux'

// Selectors
import { 
  networkStateSelector, 
  permitIdAndSectionIdsByPermitIdSelector, 
  getBurnPermitId, 
  permitByBurnPermitNumberSelector, 
} from '../selectors/selectors'
import {  
  activePostBurnPermitIdSelector,
  havePermitDataForPostBurn,
  postBurnByIdSelector,
  postBurnByActivePermitRequest,
  activePostBurnIds,
  localPostBurnByPermitIdSelector,
} from '../selectors/postBurnSelectors'
import { burnRequestModelByIdSelector, } from '../selectors/burnRequestSelectors'

// Models
import PostBurn from '../models/PostBurn'
import BurnPermit from '../models/BurnPermit'


// CONSTANTS
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env
const POST_BURN_MODEL_NAME = PostBurn.modelName
const POST_BURN_LOADING_COMPLETE = PostBurnActions.postBurnLoadingComplete()
const POST_BURN_LOADING = PostBurnActions.postBurnLoading()
const HIDE_LOADING = AppActions.hideLoading()
const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const POST_BURN_ENDPOINT = PostBurn.endpoint()

function* cancelPostBurnSubmit ({ id, }) {
  yield put({
    type        : ApiTypes.CANCEL_SUBMIT,
    action_type : PostBurnTypes.CREATE_POST_BURN_REQUEST,
    url         : POST_BURN_ENDPOINT,
    method      : 'POST',
    keyName     : 'PostBurnId',
    keyValue    : id,
  })
}

function* getPostBurnsByRequestIdSuccess (resp) {
  const { payload, } = resp
  if (isNaN(resp.burnRequestId)) {
    yield call(showError, 'Received invalid response retrieving Post-Burn for Burn Request.')
    return
  }

  // If a post burn is not found, that's fine, the user will be creating one
  if (payload.status === 404) {
    yield call(destroyLocalModels, POST_BURN_MODEL_NAME, { BurnRequestId: resp.burnRequestId, })
    const burnPermitId = yield select(activePostBurnPermitIdSelector)
    if (burnPermitId) {
      yield call(createLocalModel, POST_BURN_MODEL_NAME, { BurnRequestId: resp.burnRequestId, BurnPermitId: burnPermitId, IsLocal: true, })
    }
    return
  }


  const postBurn = yield call([ payload, payload.json, ])
  yield call(destroyLocalPostBurns, resp.burnRequestId)
  yield put(PostBurnActions.postBurnSubmitted(postBurn.PostBurnId))
  yield call(upsertPostBurns, postBurn, resp.burnRequestId)
}

function* upsertPostBurns (postBurns, BurnRequestId, BurnPermitId = null) { 
  if (Array.isArray(postBurns)) {
    let filterObj = null
    if (BurnRequestId && BurnRequestId > 0) {
      if (filterObj){
        filterObj['BurnRequestId'] = BurnRequestId
      } else {
        filterObj = { BurnRequestId, }
      }
    } 
    if (BurnPermitId && BurnPermitId > 0) {
      if (filterObj) {
        filterObj['BurnPermitId'] = BurnPermitId
      } else {
        filterObj = { BurnPermitId, }
      }
    }
    yield call(replaceAll, POST_BURN_MODEL_NAME, postBurns, filterObj)
  } else if (postBurns) {
    yield put({ type: OrmTypes.UPSERT, modelName: 'PostBurn', properties: postBurns, })
  }
}

function* preflight (PostBurnId) {
  // if offline, show toast to user letting them know it will
  // be created once they come back online.
  // Otherwise, show loading indicators
  const { online, } = yield select(networkStateSelector)
  if (online) {
    yield put(POST_BURN_LOADING)
    yield call(showLoading)
  }
  else {
    const message = 'Your create Post Burn request is queued. Once your device regains internet connectivity, the request will be submitted.'
    yield put({ type: ApiTypes.OFFLINE_MESSAGE, message, })
    yield put(PostBurnActions.submittingPostBurns(PostBurnId))
  }
}

function* createPostBurn ({ postBurnObj, }) {
  const { PostBurnId, } = postBurnObj
  yield call(preflight, PostBurnId)
  yield call(upsertPostBurns, postBurnObj)
  // Because the Type for the createPostBurnRequest is declared as part of the custom config,
  // we have to put a dispatch action rather than call the type in order for redux to react pick it up
  const body = { ...postBurnObj, PostBurnId: 0, }
  yield put(PostBurnActions.createPostBurnRequest(body))
}

function* postBurnRequestFail ({ payload, }) {
  const { error, } = yield call([ payload, payload.json, ])
  // If we want to do anything locally based on the error response, here is a place we can do that
  yield call(showError, error)
  yield put(HIDE_LOADING)
  yield put(POST_BURN_LOADING_COMPLETE)
}

function* postBurnRequestSuccess (resp) {
  try {
    const postBurn = yield call(extractPayload, resp)
    if (postBurn) {
      // upsert the new post burn
      yield call(upsertPostBurns, postBurn, postBurn.BurnRequestId)
      const localPostBurn = yield select(localPostBurnByPermitIdSelector, postBurn.BurnPermitId, postBurn.BurnRequestId)
      if (localPostBurn) {
        yield call(destroyLocalPostBurns, localPostBurn.BurnRequestId, localPostBurn.BurnPermitId)
        yield put(PostBurnActions.postBurnSubmitted(localPostBurn.PostBurnId))
      } else {
        yield put(PostBurnActions.postBurnSubmitted(postBurn.PostBurnId))
      }

      yield call(getPostBurnPrompts, postBurn.BurnPermitId)
      yield put(PostBurnActions.initializePostBurnForm(postBurn.BurnPermitId, postBurn.BurnRequestId, postBurn.PostBurnId))
    }
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put(HIDE_LOADING)
    yield put(POST_BURN_LOADING_COMPLETE)
  }
}

function* updatePostBurn ({ postBurnId, postBurnObj, }) {
  yield preflight(postBurnId)
  yield call(upsertPostBurns, postBurnObj)
  // Because the Type for the updatePostBurnRequest is declared as part of the custom config,
  // we have to put a dispatch action rather than call the type in order for redux to react pick it up
  yield put(PostBurnActions.updatePostBurnRequest(postBurnId, postBurnObj))
}

/**
 * Gather burn permit information and set the active permit Id
 * for the post burn form when provided a burn permit number
 */
function* activatePostBurnPermitByNumber ({ number = null, }) {
  if (!number) {
    yield call(showError, 'No permit number was provided.')
    return
  }
  yield put(POST_BURN_LOADING)
  
  const { online, } = yield select(networkStateSelector)
  if (!online){
    yield put(POST_BURN_LOADING_COMPLETE)
    return
  }

  yield all([
    call(getLookupData, { modelName: 'BurnRequestStatus', }),
    call(getBurnPermitInfo, { burnPermitNumber: number, }),
  ])
  const burnPermit = yield select(permitByBurnPermitNumberSelector, number)
  if (!burnPermit || !burnPermit.BurnPermitId){
    yield put(POST_BURN_LOADING_COMPLETE)
    yield call(showError, `Unable to retrieve information for Permit #${number}`)
    return
  }
  yield put(PostBurnActions.setPostBurnPermitId(burnPermit.BurnPermitId))

  yield all([
    call(getBurnRequestsForPermit, { id: burnPermit.BurnPermitId, }),
    call(getPostBurnPrompts, burnPermit.BurnPermitId),
    call(getBurnPermitInfoForPostBurn, burnPermit.BurnPermitId),
  ])
  yield put(POST_BURN_LOADING_COMPLETE)
}

/**
 * Set the Permit ID and gather for the post burn form
 * If only a number is provided, do extra steps to get the ID
 * If the id/number are invalid or not provided, set the active
 * permit ID to -1 as expected in the display code
 */
function* setPostBurnPermitByNumber ({ number = null, }) {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    if (number && number.length > 0) {
      yield call(activatePostBurnPermitByNumber, { number, } )
    } else {
      yield put(POST_BURN_LOADING_COMPLETE)
    }
  } else {
    if (number && number.length > 0) {
      const burnPermit = yield select(permitByBurnPermitNumberSelector, number)
      if (!burnPermit || !burnPermit.BurnPermitId){
        yield put(POST_BURN_LOADING_COMPLETE)
        yield call(showError, `Unable to retrieve information for Permit #${number} while offline.`)
        return
      }
      yield put(PostBurnActions.setPostBurnPermitId(burnPermit.BurnPermitId))
    }
  }
}

function* getPostBurnPrompts (permitId = null, loading = false) {
  try {
    if (loading) {
      yield put(POST_BURN_LOADING)
      yield call(showLoading)
    }

    let url = `${REACT_APP_SERVER_URL}PostBurnReports/Statuses`
    if (permitId && !isNaN(permitId) && permitId > 0) {
      url = `${url}/${permitId}`
    }
    const response = yield call(doFetch, url)
    if (!response.ok || response.statusCode !== 200) {
      yield call(showError, 'An error occurred requesting Post Burn statuses.')
      return
    }
    const promptObjs = response.responseBody

    if (Array.isArray(promptObjs) && promptObjs.length) {
      yield put(PostBurnActions.setPostBurnPrompt(promptObjs))
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    if (loading) {
      yield put(POST_BURN_LOADING_COMPLETE)
    }
    yield call(hideLoading)
  }
}

export function* getPostBurnsByPermit ({ permitId = null, permitNumber = null, }) {
  try {
    if (!Number.isInteger(permitId)) {
      permitId = yield select(getBurnPermitId, { BurnPermitNumber: permitNumber, })
    }
    const response = yield call(doFetch, `${BURN_PERMIT_ENDPOINT}/${permitId}/PostBurn`)

    if (!response.ok || (response.statusCode !== 200 && response.statusCode !== 404)) {
      let error = `An error occurred requesting Post Burns for Permit Id ${permitId}`
      if (response.responseBody && response.responseBody.error) {
        error = response.responseBody.error
      }
      yield call(showError, error)
      return
    }
    const postBurns = response.responseBody
    if (Array.isArray(postBurns) && postBurns.length) {
      yield call(replaceAll, POST_BURN_MODEL_NAME, postBurns, { BurnPermitId: permitId, })
    }
    
    yield all([
      call(getBurnPermitInfoForPostBurn, permitId),
      call(setOrCreateActivePostBurn),
    ])
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* setOrCreateActivePostBurn () {
  const { BurnPermitId, BurnRequestId, } = yield select(activePostBurnIds)

  let id = -1
  if (BurnPermitId > 0) {
    const postBurn = yield select(postBurnByActivePermitRequest)
    if (postBurn){ 
      id = postBurn.PostBurnId
    }
  }
  if (id === -1) {
    const objToCreate = {
      IsLocal       : true,
      BurnRequestId : BurnRequestId || -1,
      BurnPermitId  : BurnPermitId  || -1,
    }
    const newModel = yield call(createLocalModel, POST_BURN_MODEL_NAME, objToCreate)
    id = newModel.PostBurnId
  }
  yield put(PostBurnActions.setPostBurnId(id))
}

function* getPostBurnStatusesForUser () {
  yield call(getPostBurnPrompts, null, true)
}

function* getBurnRequestsForPermit ({ id, }) {
  try {
    if (isNaN(parseInt(id))) {
      yield call(showError, 'You must supply a Burn Permit ID to retrieve Burn Request data.')
      return
    }
    
    yield put(POST_BURN_LOADING)
    yield call(showLoading)

    const burnRequestUrl = `${REACT_APP_SERVER_URL}BurnPermits/${id}/Requests`
    const burnRequestResponse = yield call(doFetch, burnRequestUrl)

    if ((burnRequestResponse.statusCode !== 404) && (burnRequestResponse.statusCode !== 200)) {
      yield call(showError, 'Received a bad response from the Burn Request endpoint.')
      return
    }
    if ((burnRequestResponse.statusCode === 200) && burnRequestResponse.responseBody && (burnRequestResponse.responseBody.length > 0)) {
      let ack = {
        BurnRequests           : [],
        BurnRequestStatusXrefs : [],
        PostBurns              : [],
      }
      burnRequestResponse.responseBody.forEach((r) => {
        const xrefs = r.BurnRequestStatusXrefs
        const postBurns = r.PostBurns
        if (xrefs && (xrefs.length > 0)) {
          ack.BurnRequestStatusXrefs = ack.BurnRequestStatusXrefs.concat(xrefs)
        }
        if (postBurns && (postBurns.length > 0)) {
          ack.PostBurns = ack.PostBurns.concat(postBurns)
        }
        delete r['BurnRequestStatusXrefs']
        delete r['PostBurns']
        delete r['BurnPermit']
        ack.BurnRequests.push(r)
      })
        
      yield call(upsertPostBurns, ack.PostBurns, null, id)
      yield put({
        type      : OrmTypes.UPSERT_RANGE,
        modelName : 'BurnRequest',
        records   : ack.BurnRequests,
      })
      yield put({
        type      : OrmTypes.UPSERT_RANGE,
        modelName : 'BurnRequestStatusXref',
        records   : ack.BurnRequestStatusXrefs,
      })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put(HIDE_LOADING)
    yield put(POST_BURN_LOADING_COMPLETE)
  }
}

function* destroyLocalPostBurns (burnRequestId, burnPermitId = null) {
  const filter = { IsLocal: true, }
  if (burnPermitId) {
    filter['BurnPermitId'] = burnPermitId
  } 
  if (burnRequestId) {
    filter['BurnRequestId'] = burnRequestId
  }
  yield call(destroyLocalModels, POST_BURN_MODEL_NAME, filter)
}

export function* downloadPostBurns () {
  try {
    const resp = yield call(doFetch, POST_BURN_ENDPOINT)
    if (!resp.ok) {
      yield call(showError, resp.responseBody || 'An error occurred retrieving Post Burns')
    }

    yield call(replaceAll, POST_BURN_MODEL_NAME, resp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* getBurnPermitInfoForPostBurn (burnPermitId) {
  try {
    if (!burnPermitId) {
      yield call(showError, 'You must supply a valid Burn Permit ID.')
      return
    }

    const alreadyHavePermitData = yield select(havePermitDataForPostBurn, burnPermitId)
    if (alreadyHavePermitData) {
      return
    }

    // Get the associated burn permit
    const burnPermit = yield select(permitIdAndSectionIdsByPermitIdSelector, burnPermitId)
    if (!burnPermit) {
      yield call(showError, `Could not find Burn Permit data for ${burnPermitId}`)
      return
    }
    
    yield all([
      call(getBurnPileGroups, { burnPermitId, }),
      call(getBurnPermitFuelsInfo, { burnPermitFuelId: burnPermit.BurnPermitFuelId, }),
    ])
  }
  catch (error) {
    yield call(showError, error)
  }
}


function* getPostBurnByIdSuccess (resp) {
  let postBurn = null
  try {
    if (!apiResponseIsAuthorized(resp)) {
      yield put({ type: ApiTypes.FAILURE, error: 'You are not authorized to access this Post Burn Report.', })
      return
    }
    const { payload, } = resp
    if (payload.status === 404) {
      yield call(showError, 'Unable to locate Post Burn report.')
    } else if (payload.status === 200) {
      postBurn = yield call(extractPayload, resp)
      postBurn.IsLocal = false
      const { BurnPermitId, BurnRequestId, } = postBurn
      yield call(destroyLocalPostBurns, BurnRequestId, BurnPermitId)
      yield call(upsertPostBurns, postBurn, BurnRequestId, BurnPermitId)
    }
  } catch (e) {
    console.error('An error occurred retrieving Post Burn')
    yield call(showError, 'An error occurred retrieving Post Burn.')
  }
}


function* getBurnRequestInfoWrapper (burnRequestId = -1) {
  if (burnRequestId > 0) {
    const getBurnRequestAction = BurnRequestDetailActions.getBurnRequestInfo(burnRequestId)
    getBurnRequestAction.meta.offline.commit = { type: 'GET_BURN_REQUEST_SUCCESS', redirect: false, }
    yield put(getBurnRequestAction)
  }
}

/***************************************************************************************/
function* initializePostBurnForm ({ burnPermitId = -1, burnRequestId = -1, postBurnId = -1, }) {
  // this runs after the reducer, selector should return the new values

  const activeIds = yield select(activePostBurnIds)
  const BurnPermitId = activeIds.BurnPermitId || burnPermitId, 
    BurnRequestId = activeIds.BurnRequestId || burnRequestId, 
    PostBurnId = activeIds.PostBurnId || postBurnId
  const { online, } = yield select(networkStateSelector)

  // post burn will have request and/or permit ids
  if (PostBurnId > 0) {
    const postBurn = yield select(postBurnByIdSelector, PostBurnId)
    if (postBurn) {
      if (postBurn.BurnRequestId > 0) {
        yield put(PostBurnActions.setPostBurnRequestId(postBurn.BurnRequestId))
        // get all the request info
        yield call(getBurnRequestInfoWrapper, postBurn.BurnRequestId)
      }
      if (postBurn.BurnPermitId > 0) {
        yield put(PostBurnActions.setPostBurnPermitId(postBurn.BurnPermitId))
        // if we don't know the burn request id, get the permit info
        if (online && postBurn.BurnRequestId <= 0){
          yield call(getBurnPermitInfo, { burnPermitId: BurnPermitId, })
          yield call(getBurnRequestsForPermit, { id: BurnPermitId, })
        }
      }
    }
    yield put(PostBurnActions.getPostBurnById(PostBurnId))
  } 
  // burn request will have permit id
  else if (BurnRequestId > 0) { 
    const request = yield select(burnRequestModelByIdSelector, BurnRequestId)
    if (request) {
      if (request.BurnPermitId > 0) {
        yield put(PostBurnActions.setPostBurnPermitId(request.BurnPermitId))
      }
      if (request.PostBurns.all().exists()){
        const pb = request.PostBurns.first()
        yield put(PostBurnActions.setPostBurnId(pb.PostBurnId))
      }
    }
    yield call(setOrCreateActivePostBurn)
    yield call(getBurnRequestInfoWrapper, BurnRequestId)
  } 
  // permit by itself will need more information
  else if (BurnPermitId > 0 && online) {
    yield call(getBurnPermitInfo, { burnPermitId: BurnPermitId, })
    yield call(getBurnRequestsForPermit, { id: BurnPermitId, })
  }
}
/**************************************************************************************/

function* createNoRequestPostBurn ({ useRequest, }) {
  if (useRequest === false) {
    yield call(setOrCreateActivePostBurn)
  }
}

export const PostBurnSagas = [
  takeLatest(PostBurnTypes.POST_BURN_USE_REQUEST, createNoRequestPostBurn),
  takeLatest(PostBurnTypes.INITIALIZE_POST_BURN_FORM, initializePostBurnForm),
  takeLatest(PostBurnTypes.CANCEL_POST_BURN_SUBMIT, cancelPostBurnSubmit),
  takeLatest(PostBurnTypes.GET_POST_BURNS_BY_PERMIT, getPostBurnsByPermit),
  takeLatest(PostBurnTypes.SET_POST_BURN_PERMIT_BY_NUMBER, setPostBurnPermitByNumber),
  takeEvery(PostBurnTypes.CREATE_POST_BURN, createPostBurn),
  takeEvery(PostBurnTypes.POST_BURN_REQUEST_SUCCESS, postBurnRequestSuccess),
  takeEvery(PostBurnTypes.POST_BURN_REQUEST_FAIL, postBurnRequestFail),
  takeEvery(PostBurnTypes.UPDATE_POST_BURN, updatePostBurn),
  takeLatest(PostBurnTypes.GET_PB_PROMPTS_FOR_USER, getPostBurnStatusesForUser),
  takeLatest(PostBurnTypes.GET_POST_BURNS_BY_REQUEST_ID_SUCCESS, getPostBurnsByRequestIdSuccess),
  takeLatest(PostBurnTypes.GET_POST_BURN_BY_ID_SUCCESS, getPostBurnByIdSuccess),
]