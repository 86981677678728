import { ormByIdSelector, getValueOrDefault, ormSelector, } from './selectors'
import { createSelector, } from 'reselect'
import { mapBurnRequest, } from './burnRequestSelectors'
import { dateFormatter, } from '../utilities'

// base selector
const postBurnStateSelector = state => state.PostBurn

export const submittingPostburnsSelector = createSelector(
  postBurnStateSelector,
  state => state ? state.SubmittingPostBurns : [] 
)

/* active post burn id */
const activePostBurnIdSelector = createSelector(
  postBurnStateSelector,
  state => state ? state.ActivePostBurnId : -1
)

export const localPostBurnByPermitIdSelector = ormByIdSelector(
  (orm, id, burnRequestId) => burnRequestId,
  ({ PostBurn, }, burnPermitId, burnRequestId) => {
    const qs = PostBurn.filter({
      IsLocal       : true,
      BurnPermitId  : burnPermitId,
      BurnRequestId : burnRequestId,
    })
    if (qs.exists()) {
      return qs.first()
    }
    return null
  }
)

/* active post burn model */
const activePostBurnModelSelector = ormSelector(
  activePostBurnIdSelector,
  ({ PostBurn, }, PostBurnId) => {
    let postBurn = null
    if (PostBurnId && PostBurn) {
      postBurn = PostBurn.withId(PostBurnId)
    }
    return postBurn
  } 
)

export const activePostBurnIsLocal = ormSelector(
  activePostBurnModelSelector,
  (orm, postBurn) => (postBurn && postBurn.IsLocal === true)
)

export const postBurnByIdSelector = ormByIdSelector(
  ({ PostBurn, }, PostBurnId) => {
    let postBurn = null
    if (PostBurnId && PostBurn) {
      postBurn = PostBurn.withId(PostBurnId)
    }
    return postBurn
  } 
)

/* active burn request id */
const activePostBurnRequestIdSelector = createSelector(
  postBurnStateSelector,
  state => state ? state.ActiveRequestId : -1
)

/* active burn request model */
const activePostBurnRequestModelSelector = ormSelector(
  activePostBurnRequestIdSelector,
  ({ BurnRequest, }, BurnRequestId) => {
    let burnRequest = null
    if (BurnRequestId && BurnRequest) {
      burnRequest = BurnRequest.withId(BurnRequestId)
    }
    return burnRequest
  } 
)

export const activePostBurnRequestSelector = ormSelector(
  activePostBurnRequestModelSelector,
  (orm, request) => {
    return mapBurnRequest(request) 
  }
)

/* get active permit id */
export const activePostBurnPermitIdSelector = createSelector(
  postBurnStateSelector,
  state => state ? state.ActivePermitId : -1
)

/* get active permit model */
const activePostBurnPermitModelSelector = ormSelector(
  activePostBurnPermitIdSelector,
  ({ BurnPermit, }, BurnPermitId) => {
    let burnPermit = null
    if (BurnPermitId && BurnPermit) {
      burnPermit = BurnPermit.withId(BurnPermitId)
    }
    return burnPermit
  } 
)

export const postBurnByActivePermitRequest = ormSelector(
  activePostBurnPermitIdSelector,
  activePostBurnRequestIdSelector,
  ({ PostBurn, }, BurnPermitId, BurnRequestId) => {
    if (BurnPermitId > 0) {
      return PostBurn.filter({ BurnPermitId, BurnRequestId, }).first()
    }
    return null
  }
)

export const postBurnPromptSelector = createSelector(
  postBurnStateSelector,
  (state) => state ? state.postBurnPrompt ? state.postBurnPrompt : [] : []
)

export const postBurnLoadingSelector = createSelector(
  postBurnStateSelector,
  (state) => state ? state.PostBurnIsLoading ? state.PostBurnIsLoading : false : false
)

export const activePostBurnIds = createSelector(
  activePostBurnIdSelector,
  activePostBurnRequestIdSelector,
  activePostBurnPermitIdSelector,
  (PostBurnId, BurnRequestId, BurnPermitId) => {
    return {
      PostBurnId,
      BurnRequestId,
      BurnPermitId,
    }
  }
)

const formatTime = (time) => {
  if (!time) {
    return ''
  }
  const fmtTime = time.toString().replace(/:/gi, '')
  if (fmtTime.length === 6) {
    return fmtTime.substring(0, 4)
  }
  if (fmtTime.length < 4) {
    return `0${fmtTime}`  
  }
  return fmtTime
}

export const postBurnDDLSelector = ormSelector(
  activePostBurnPermitIdSelector,
  submittingPostburnsSelector,
  ({ BurnPermit, }, BurnPermitId, submittingPostBurns) => {
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit){
      return []
    }
    const allBurnRequests = permit.BurnRequests.all().toModelArray()
      .filter(r => {
        if (r.AutoGenerated === true) {
          return true
        }
        if (!r.BurnRequestStatusXrefs || !r.BurnRequestStatusXrefs.exists()) {
          return false
        }
        const status = r.BurnRequestStatusXrefs.orderBy('StatusDate', 'desc').first()
        if (!status || !status.BurnRequestRequestStatus) {
          return false
        }
        return true
      })

    const { BurnPermitPile, BurnPermitArea, } = permit
    const isPileType = BurnPermitArea ? BurnPermitArea.BurnTypes.filter(t => t.BurnTypeName === 'Pile').exists() : false
    const pileCount = BurnPermitPile ? BurnPermitPile.TotalNumberBurnPiles : 'Unknown'
    const pileString = (isPileType && BurnPermitPile) ? `${pileCount} piles totalling ` : ''
    const noReqPostBurnQuery = permit.PostBurns.filter({ BurnRequestId: -1, })

    const burnRequests = allBurnRequests.map(r => {
      let Text = `#${r.BurnRequestId}`, Group = 'No Post Burn', burnDetails = `${pileString}`
      const pb = r.PostBurns.first()
      if (pb && pb.IsLocal !== true) {
        const reportDate = dateFormatter(pb.UpdateDate || pb.CreateDate, 'MM/DD/YYYY')
        const ignitedDate = dateFormatter(pb.BurnDate, 'MM/DD/YYYY')
        burnDetails += `${pb.ConsumedTons} tons over ${pb.BurnedBlackAcres || r.ProposedBurnArea} acres`
        Text += ` Reported ${reportDate} - Ignited ${ignitedDate} - ${burnDetails}`
        Group = 'Has Post Burn'
        if (pb.BurnDate && (pb.ConsumedTons === 0 || pb.DidNotBurn)) {
          Group = 'Did Not Burn'
          Text = `#${r.BurnRequestId} Reported ${ignitedDate} - Not Burned`
        }
      } else {
        if (pb && submittingPostBurns.includes(pb.PostBurnId)) {
          Group = 'Post Burn Pending Submission'
        }
        burnDetails += `${r.TotalProposedBurnQuantity} tons over ${r.ProposedBurnArea} acres`
        Text += ` Planned ignition on ${dateFormatter(r.PlannedIgnitionDate, 'MM/DD/YYYY')} - ${burnDetails}`
      }
      return { Text, Group, Value: r.BurnRequestId, }
    })
    let noRequestGroup = 'No Post Burn'
    if (noReqPostBurnQuery.exists()){
      let nrpb = noReqPostBurnQuery.first()
      if (nrpb && submittingPostBurns.includes(nrpb.PostBurnId)) {
        noRequestGroup = 'Post Burn Pending Submission'
      }
    }
    burnRequests.push({ Text: 'No Burn Request', Group: noRequestGroup, Value: 'norequest', })
    return burnRequests
  }
)

const mapPostBurn = (pb = null, req = null, pmt = null) => {
  if (!pb && !req) {
    return null
  }
  let postBurn = {}

  let p = null
  if (pb) {
    p = pb
  } else if (req && req.PostBurns.all().exists()) {
    p = req.PostBurns.first()
  } else {
    return null
  }

  if (p) {
    postBurn = {
      PostBurnId                      : p.PostBurnId,
      BurnPermitId                    : p.BurnPermitId,
      BurnRequestId                   : p.BurnRequestId,
      CreateDate                      : p.CreateDate,
      CreateBy                        : p.CreateBy,
      UpdateDate                      : p.UpdateDate,
      UpdateBy                        : p.UpdateBy,
      PilesIgnitedPercent             : getValueOrDefault(p.PilesIgnitedPercent, ''),
      BurnTime                        : formatTime(p.BurnTime),
      IgnitionPeriod                  : getValueOrDefault(p.IgnitionPeriod, ''),
      ConsumedTons                    : getValueOrDefault(p.ConsumedTons, ''),
      BurnedBlackPercent              : getValueOrDefault(p.BurnedBlackPercent, ''),
      BurnedBlackAcres                : getValueOrDefault(p.BurnedBlackAcres, ''),
      BurnedBlackPilePercent          : getValueOrDefault(p.BurnedBlackPilePercent, ''),
      BurnedBlackCanopyPercent        : getValueOrDefault(p.BurnedBlackCanopyPercent, ''),
      BurnedBlackShrubPercent         : getValueOrDefault(p.BurnedBlackShrubPercent, ''),
      FuelMoistureMethod              : getValueOrDefault(p.FuelMoistureMethod, ''),
      WeatherCollectionMethod         : getValueOrDefault(p.WeatherCollectionMethod, ''),
      WeatherStationName              : getValueOrDefault(p.WeatherStationName, ''),
      WindSpeedMph                    : getValueOrDefault(p.WindSpeedMph, ''),
      NumberDaysSinceRain             : getValueOrDefault(p.NumberDaysSinceRain, ''),
      TenHourFuelMoisturePercent      : getValueOrDefault(p.TenHourFuelMoisturePercent, ''),
      ThousandHourFuelMoisturePercent : getValueOrDefault(p.ThousandHourFuelMoisturePercent, ''),
      DuffFuelMoisturePercent         : getValueOrDefault(p.DuffFuelMoisturePercent, ''),
      MidflameWindSpeed               : getValueOrDefault(p.MidflameWindSpeed, ''),
      PostBurnComments                : getValueOrDefault(p.PostBurnComments, ''),
      DidNotBurnReason                : getValueOrDefault(p.DidNotBurnReason, ''),
      IsLocal                         : p.IsLocal,
    }
  }

  if (req && !p) {
    postBurn['BurnDate'] = dateFormatter(req.PlannedIgnitionDate || '', 'YYYY-MM-DD')
  } else if (p) {
    postBurn['BurnDate'] = dateFormatter(p.BurnDate || '', 'YYYY-MM-DD')
  } else {
    postBurn['BurnDate'] = ''
  }

  postBurn['TotalPileConsumedTons'] = 0
  if (req) {
    if (req.BurnPermit && req.BurnPermit.BurnPermitPileGroups && req.BurnPermit.BurnPermitPileGroups.exists()) {
      postBurn.TotalPileConsumedTons = req.BurnPermit.BurnPermitPileGroups.toRefArray()
        .map(g => g.ConsumedFuel)
        .reduce((tons, total) => total + tons, 0)
    }
    postBurn['PlannedIgnitionDate'] = req.PlannedIgnitionDate
    postBurn['MultiDayBurnFlag'] = req.MultiDayBurnFlag || false
    postBurn['TotalPermitTonnage'] = req.BurnPermit ? req.BurnPermit.TotalPermitTonnage : 0
    postBurn['TotalTonsPerAcre'] = req.BurnPermit && req.BurnPermit.BurnPermitFuel ? req.BurnPermit.BurnPermitFuel.TotalTonsPerAcre : 0
    postBurn['ProposedBurnArea'] = getValueOrDefault(req.ProposedBurnArea, 0)
    postBurn['DidNotBurn'] = req.DidNotBurn || false
  } else {
    postBurn['PlannedIgnitionDate'] = Date.now()
    postBurn['MultiDayBurnFlag'] = false
    postBurn['TotalPermitTonnage'] = pmt ? pmt.TotalPermitTonnage : 0
    postBurn['TotalTonsPerAcre'] = pmt && pmt.BurnPermitFuel ? pmt.BurnPermitFuel.TotalTonsPerAcre : 0
    postBurn['ProposedBurnArea'] = 0
    postBurn['DidNotBurn'] = false
  }

  return postBurn
}

export const postBurnByRequestIdSelector = ormSelector(
  activePostBurnModelSelector,
  activePostBurnRequestModelSelector,
  activePostBurnPermitModelSelector,
  (orm, p, req, pmt) => mapPostBurn(p, req, pmt)
)

export const haveRequestDataForPostBurn = ormSelector(
  activePostBurnRequestModelSelector,
  (orm, request) => {
    if (!request) {
      return false
    }
    const allDataExists = []
    allDataExists.push(request.PlannedIgnitionDate !== null)
    allDataExists.push(request.MultiDayBurnFlag !== null)
    allDataExists.push(request.ProposedBurnArea !== null)
    allDataExists.push(request.PostBurns.all().exists())
    return allDataExists.every(d => d)
  }
)

export const havePermitDataForPostBurn = ormSelector(
  activePostBurnPermitModelSelector,
  (orm, permit) => {
    if (!permit) {
      return false
    }
    const allDataExists = []
    
    allDataExists.push(permit.BurnPermitPileGroups.exists())
    allDataExists.push(permit.BurnPermitFuelId !== null && permit.BurnPermitFuel != null)
    allDataExists.push(permit.BurnPermitAreaId !== null && permit.BurnPermitArea != null)

    return allDataExists.every(d => d)
  }
)
