import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(RelativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Validates and optionally formats the provided date object
 * @param {Date|string} date
 * @param {string?} format
 * @returns {Dayjs|dayjs|Date|string}
 */
export const dateFormatter = (date, format = null, dateOnly = false) => {
  // Only construct and format the date if it is likely a full (enough) date value
  if (!date || (typeof date === 'string' && ((date.split('-').length < 3 && date.split('/').length < 3) || date.length < 8))) {
    return date
  }
  const _date = dayjs(date)
  if (_date.isValid() === false) {
    return date
  }
  if (format) {
    return _date.format(format)
  }
  if (dateOnly === true) {
    return _date.hour(0).minute(0).second(0).millisecond(0)
  }
  return _date
}

/**
 * Returns tomorrow's date as a string
 * @returns {string}
 */
export const getTomorrowsDate = (format) => {
  return dayjs().add(1, 'day').format(format || 'MM/DD/YYYY')
}

/**
 * Returns today's date as a string
 * @returns {string}
 */
export const getTodaysDate = (format) => {
  return dayjs().format(format || 'MM/DD/YYYY')
}

/**
 * Returns the current date/time as an object. 
 * @returns {Object} the current date/time object
 */
export const getNow = () => {
  return dayjs()
}

/**
 * Creates a javascript date object set in Pacfic Timezone
 * @param {Date} dateUTC
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @returns {Object} JS Date/time object set with America/LosAngeles timezone.
 */
export const pacificTimeZoneDate = (dateUTC, hours = 0, minutes = 0, seconds = 0) => {
  let dayDate = dayjs.tz(new Date(dateUTC), 'America/Los_Angeles')
  const date = dayDate.toDate()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  return date
}

/**
 * Returns the current date/time as an object. 
 * @returns {Object} the current date/time object
 */
export const getDateInXDays = (daysFromNow, format) => {
  return dayjs().add(daysFromNow, 'day').format(format || 'YYYY-MM-DD')
}


/**
 * Returns `true` if `secondDate` is later than `firstDate`, otherwise returns `false`
 * @param {string} firstDate - The likely earlier date
 * @param {string} secondDate - The likely later date to confirm is later
 * @returns {boolean}
 */
export const isAfter = (firstDate, secondDate) => {
  return dayjs(secondDate).isAfter(firstDate)
}
