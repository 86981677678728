import { createReducer, createActions, } from 'reduxsauce'

import BurnRequest from '../models/BurnRequest'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  resetRequestToInitial    : null,
  updateRequestSearchParam : [ 'permitNumber',  ],
  loadingBurnRequest       : null,
  doneLoading              : null,
  getBurnPermitInfo        : [ 'burnPermitNumber', ],
  getBurnRequestInfo       : (burnRequestId) => ({
    type : 'GET_BURN_REQUEST_INFO',
    meta : {
      offline: {
        effect: {
          url: BurnRequest.endpoint(burnRequestId),
        },
        commit   : { type: 'GET_BURN_REQUEST_SUCCESS', redirect: true, },
        rollback : { type: 'API_FAIL', },
      },
    },
  }),
  getBurnRequestSuccess       : [ 'payload', ],
  getBurnRequestStatusHistory : [ 'burnRequestId', ],
  createRequest               : [ 'burnRequestInfo', ],
  saveRequest                 : [ 'burnRequestInfo', ],
  submitRequest               : [ 'burnRequestInfo', ],
  cancelBurnRequest           : [ 'burnRequestInfo', ],
  cancelBurnRequestSuccess    : [ 'payload', ],
  agencySubmitRequest         : [ 'burnRequestInfo', ],
  deleteBurnRequest           : [ 'id', ],
  deleteRequestStatus         : [ 'burnRequestId', 'burnRequestStatusXrefId', ],
  submitRequestStatus         : [ 'burnRequestStatus', ],
  overrideDecision            : [ 'burnRequestId', 'comment', ],
  quickSubmitRequest          : [ 'id', ],
  quickSubmitRequestFailure   : [ 'id', ],
  quickSubmitRequestSuccess   : [ 'id', ],
})

export const BurnRequestDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Object.freeze({
  searchParam        : '',
  loadingBurnRequest : false,
})

/* ------------- Reducers ------------- */

export const resetRequestToInitial = () => {
  return INITIAL_STATE
}

export const updateRequestSearchParam = (state, { permitNumber, }) => {
  return { ...state, searchParam: permitNumber, }
}

export const loadingBurnRequest = (state) => {
  return { ...state, loadingBurnRequest: true, }
}

export const doneLoading = (state) => {
  return { ...state, loadingBurnRequest: false, }
}

  
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_REQUEST_TO_INITIAL]    : resetRequestToInitial,
  [Types.UPDATE_REQUEST_SEARCH_PARAM] : updateRequestSearchParam,
  [Types.LOADING_BURN_REQUEST]        : loadingBurnRequest,
  [Types.DONE_LOADING]                : doneLoading,
})