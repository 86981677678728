import React, { useMemo, } from 'react'
import { Container, Card, Col, CardBody, Row, CardText, } from 'reactstrap'

// Componnets
import DNRNavbar from './Navbar'
import LoadingBar from './LoadingBar'

export const AppLoading = () => {
  const loadingBarStyle = useMemo(() => ({ top: 0, zIndex: 10, }), [])
  const colStyles = useMemo(() => ({ size: '6', offset: '3', }), [])
  const cardStyles = useMemo(() => ({ textAlign: 'center', }), [])
  return (
    <div
      id={'outer-container'}
      className={'App'}
    >
      <main id={'page-wrap'}>
        <LoadingBar
          style={loadingBarStyle}
          className={'position-fixed'}
        />
        <DNRNavbar />
        <Container>
          <Row className={'mt-4'}>
            <Col md={colStyles}>
              <Card>
                <CardBody>
                  <CardText style={cardStyles}>
                    Loading...
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  )
}

export default AppLoading
