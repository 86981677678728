import React from 'react'

const Approved = () => {
  return {
    label   : 'Approved',
    caption : <>
      <p>Your Permit Application is Approved! <i className={'fas fa-thumbs-up'} /></p>
      <p>Soon DNR will being Issuing your Permit.</p>
    </>,
    icon: 'fas fa-thumbs-up',
  }
}

export default Approved