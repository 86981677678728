import React from 'react'
import { PropTypes, string, } from 'prop-types'
import { Alert, Row, Col, } from 'reactstrap'

const alertSetup = (color, text, icon, key) => {
  return <Alert color={color} className={'dash-notice'} key={key}>
    <Row>
      <Col xs={2} sm={1}>
        {icon}
      </Col>
      <Col xs={10} sm={11}>
        <p>{text}</p>
      </Col>
    </Row>
  </Alert>
}

function Notices ({ messages, }) {
  const { Info, Warning, } = messages
  // This is to prevent collission with the Alert component from reactstrap
  const AlertMsg = messages.Alert
  const markup = []
  if (AlertMsg) {
    markup.push(alertSetup('danger', AlertMsg, <span key={'danger-notice-ico'} className={'fa fa-ban fa-2x'} />, 'danger-notice'))
  }
  if (Warning) {
    markup.push(alertSetup('warning', Warning, <span key={'warning-notice-ico'} className={'fa fa-exclamation-triangle fa-2x'} />, 'warning-notice'))
  }
  if (Info) {
    markup.push(alertSetup('primary', Info, <span key={'info-notice-ico'} className={'fa fa-info-circle fa-2x'} />, 'info-notice'))
  }
  return markup
}

Notices.propTypes = {
  messages: PropTypes.shape({
    Info    : string,
    Warning : string,
    Alert   : string,
  }),
}

Notices.defaultProps = {
  messages: {
    Info    : null,
    Warning : null,
    Alert   : null,
  },
}

export default Notices