import CalendarLayerConfig from './Calendar'
import DashboardLayerConfig from './Dashboard'
import MyPermitsLayerConfig from './MyPermits'
import MyRequestsLayerConfig from './MyRequests'
import PermitApplicationLayerConfig from './PermitApplication'
import PermitSearchLayerConfig from './PermitSearch'
import RequestSearchLayerConfig from './RequestSearch'
import SmokeComplaintsLayerConfig from './SmokeComplaints'

const DEFAULT_CONFIGS = [
  DashboardLayerConfig,
  CalendarLayerConfig,
  MyPermitsLayerConfig,
  MyRequestsLayerConfig,
  PermitApplicationLayerConfig,
  PermitSearchLayerConfig,
  RequestSearchLayerConfig,
  SmokeComplaintsLayerConfig,
]

export {
  CalendarLayerConfig,
  DashboardLayerConfig,
  MyPermitsLayerConfig,
  MyRequestsLayerConfig,
  PermitApplicationLayerConfig,
  PermitSearchLayerConfig,
  RequestSearchLayerConfig,
  SmokeComplaintsLayerConfig,
}

export default DEFAULT_CONFIGS