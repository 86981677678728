import { attr, Model, } from 'redux-orm'

export default class BurnPermitDocumentType extends Model {
  static get modelName () {
    return 'BurnPermitDocumentType'
  }

  toString () {
    return `${this.BurnPermitDocumentTypeName}`
  }

  static endpoint () {
    return 'BurnPermitDocumentTypes'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitDocumentTypeId',
    }
  }

  static get fields () {
    return {
      BurnPermitDocumentTypeId           : attr(),
      BurnPermitDocumentTypeName         : attr(),
      BurnPermitDocumentTypeDescription  : attr(),
      BurnPermitDocumentTypeCategoryName : attr(),
      CreateBy                           : attr(),
      CreateDate                         : attr(),
      UpdateBy                           : attr(),
      UpdateDate                         : attr(),
      InternalUseOnly                    : attr(),
    }
  }
}