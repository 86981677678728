// Libraries
import React, { useMemo, } from 'react'
import { object, bool, func, string, } from 'prop-types'
import { Col, Row, } from 'reactstrap'

// Components
import { RowToggler, } from '../FormControls'


const HeaderComp = ({ statusName, }) => {
  return <h1 className={'m-0'}>
    Permit Status:
    <span className={'ml-2 info'}>{statusName}</span>
  </h1>
}
HeaderComp.propTypes = {
  statusName: string,
}

const Header = React.memo(HeaderComp)

const StatusHeader = props => {
  const {
    appStatus,
    isSigned,
    isSuspended,
    isRevoked,
    isExpired,
    toggleApplicationSummary,
    applicationSummaryIsShowing,
    headerOnly,
    enableToggler,
    enableSummary,
  } = props

  const { statusName, statusDesc, } = useMemo(() => {
    let statusName = null, statusDesc = null
    if (isSuspended) {
      statusName = 'Suspended'
      statusDesc = 'Permit has been suspended and can not be used for burning at this time.'
    }
    else if (isRevoked) {
      statusName = 'Revoked'
      statusDesc = 'Permit has been revoked and can no longer be used for burning.'
    }
    else if (isExpired) {
      statusName = 'Expired'
      statusDesc = 'Permit has expired and can no longer be used for burning.'
    }
    else if (appStatus) {
      statusName = appStatus.Status
      statusDesc = appStatus.StatusDescription
    }
    return { statusName, statusDesc, }
  }, [ isSuspended, isRevoked, isExpired, appStatus, ])

  const showHeader = useMemo(() => (!!statusName && !!statusDesc), [ statusName, statusDesc, ])

  const showSummaryOrToggler = useMemo(() => (enableSummary || enableToggler), [ enableSummary, enableToggler, ])

  const markup = useMemo(() => {
    if (showSummaryOrToggler !== true) {
      return null
    }
    let markup = []
    if (enableToggler) {
      markup.push(
        <RowToggler
          key={'app-summary'}
          rowLabel={'Application Summary'}
          onClick={toggleApplicationSummary}
          show={applicationSummaryIsShowing}
        />
      )
    }
    if (enableSummary && applicationSummaryIsShowing) {
      markup.push(
        <div key={'status-header'}>
          <p><b>{statusName}</b>: {statusDesc}</p>
          {
            isSigned && <p>This application has been digitally signed. See the Signature section below for more information.</p>
          }
        </div>
      )
    }
    return <Row>
      <Col className={'d-flex flex-row justify-content-end'}>
        {markup}
      </Col>
    </Row>
  }, [
    showSummaryOrToggler,
    enableToggler,
    enableSummary,
    toggleApplicationSummary,
    applicationSummaryIsShowing,
    statusName,
    statusDesc,
    isSigned,
  ])

  if (!appStatus || showHeader !== true) {
    return null
  }

  if (headerOnly === true) {
    return <Header statusName={statusName} />
  }

  return <>
    <Row>
      <Col className={'d-block d-md-flex flex-row justify-content-end'}>
        <Header statusName={statusName} />
      </Col>
    </Row>
    {markup}
  </>
}

StatusHeader.propTypes = {
  appStatus                   : object,
  isSigned                    : bool,
  isSuspended                 : bool,
  isRevoked                   : bool,
  isExpired                   : bool,
  toggleApplicationSummary    : func,
  applicationSummaryIsShowing : bool,
  headerOnly                  : bool,
  enableToggler               : bool,
  enableSummary               : bool,
}

StatusHeader.defaultProps = {
  appStatus                   : {},
  isSigned                    : false,
  isSuspended                 : false,
  isRevoked                   : false,
  isExpired                   : false,
  toggleApplicationSummary    : () => {},
  applicationSummaryIsShowing : false,
  headerOnly                  : false,
  enableToggler               : true,
  enableSummary               : true,
}

export default StatusHeader
