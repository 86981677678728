// Libraries
import { put, select, call, fork, all, takeLatest, } from 'redux-saga/effects'

// Sagas
import { doFetch, getLookupData, showError, } from './ApiSagas'
import { getBurnPermitStatusHistory, } from './BurnPermitSagas'
import { hideLoading, showLoading, } from './AppSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import { OrmTypes, } from '../redux/OrmRedux'
import { PermitConditionTypes, } from '../redux/BurnPermitConditionsRedux'
import { BurnPermitFormTypes, } from '../redux/BurnPermitFormRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'
import { BurnPermitSignatureTypes, } from '../redux/BurnPermitSignatureRedux'

// Selectors
import {
  activePermitIdSelector,
  conditionValueKeySelector,
  conditionFieldToNameMapSelector,
  permitConditionNameByIdSelector,
  permitConditionByNameSelector,
  contactConditionXrefForPermitSelector,
} from '../selectors/conditionsSelectors'
import { networkStateSelector, equipmentTypeCheckboxSelector, } from '../selectors/selectors'

// Models
import BurnPermit from '../models/BurnPermit'

import {
  extractContentStateValues, 
} from '../utilities'

// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()

/**
 * Gather conditions for the active burn permit and set it if necessary.
 * @param {Object} action - The action object
 * @param {number} action.burnPermitId - The permit ID to set
 */
export function* setPermitForConditions ({ burnPermitId, }) {
  try {
    if (!burnPermitId || isNaN(burnPermitId)) {
      return
    }
    // Get the statuses in case for some reason the user navigates straight
    // to the conditions page, getting the statuses will ensure the conditions
    // and issue button are in their correct state (readonly or not) depending
    // on if the Permit is issued or not
    yield call(getBurnPermitStatusHistory, { burnPermitId, })
    yield call(getPermitConditions, { burnPermitId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Gather conditions for the active burn permit.
 * @param {Object} action - The action object
 * @param {number} action.permitId - The permit ID to set
 */
export function* getActivePermitConditions () {
  try{
    const activeId = yield select(activePermitIdSelector)
    yield call(getPermitConditions, { burnPermitId: activeId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* upsertConditonResponse (responseBody, permitId) {
  let dayOfWeekXrefs = []
  let directionXrefs = []
  let equipmentXrefs = []
  let contactConds = []
  const conditionXrefs = responseBody.map((c) => {
    const newXref = { ...c, }
    if (newXref.BurnPermitEquipmentConditionXref) {
      equipmentXrefs = equipmentXrefs.concat(newXref.BurnPermitEquipmentConditionXref)
      delete newXref['BurnPermitEquipmentConditionXref']
    }
    if (newXref.BurnPermitProhibitedDayConditionXref) {
      dayOfWeekXrefs = dayOfWeekXrefs.concat(newXref.BurnPermitProhibitedDayConditionXref)
      delete newXref['BurnPermitProhibitedDayConditionXref']
    }
    if (newXref.BurnPermitWindDirectionConditionXref) {
      directionXrefs = directionXrefs.concat(newXref.BurnPermitWindDirectionConditionXref)
      delete newXref['BurnPermitWindDirectionConditionXref']
    }
    if (newXref.BurnPermitRequiredContactCondition){
      contactConds = contactConds.concat(newXref.BurnPermitRequiredContactCondition)
      delete newXref['BurnPermitRequiredContactCondition']
    }
    return newXref
  })

  const filterObj = {}
  if (permitId) {
    filterObj.BurnPermitId = permitId
  }

  yield put({
    type      : OrmTypes.REPLACE_ALL,
    modelName : 'BurnPermitConditionXref',
    objects   : conditionXrefs,
    filterObj : { BurnPermitId: permitId, },
  })

  if (dayOfWeekXrefs.length > 0) {
    const BurnPermitConditionXrefId = dayOfWeekXrefs.map(x => x.BurnPermitConditionXrefId)[0]
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'BurnPermitProhibitedDayConditionXref',
      objects   : dayOfWeekXrefs,
      filterObj : { BurnPermitConditionXrefId, },
    })
  }

  if (directionXrefs.length > 0) {
    const BurnPermitConditionXrefId = directionXrefs.map(x => x.BurnPermitConditionXrefId)[0]
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'BurnPermitWindDirectionConditionXref',
      objects   : directionXrefs,
      filterObj : { BurnPermitConditionXrefId, },
    })
  }

  if (equipmentXrefs.length > 0) {
    const BurnPermitConditionXrefId = equipmentXrefs.map(x => x.BurnPermitConditionXrefId)[0]
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'BurnPermitEquipmentConditionXref',
      objects   : equipmentXrefs,
      filterObj : { BurnPermitConditionXrefId, },
    })
  }

  if (contactConds.length > 0) {
    const BurnPermitConditionXrefId = contactConds.map(x => x.BurnPermitConditionXrefId)[0]
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'BurnPermitRequiredContactCondition',
      objects   : contactConds,
      filterObj : { BurnPermitConditionXrefId, },
    })
  }
}

function* getConditionLookupData () {
  yield fork(getLookupData, { modelName: 'BurnPermitCondition', })
  yield fork(getLookupData, { modelName: 'Direction', })
  yield fork(getLookupData, { modelName: 'DaysOfWeek', })
  yield fork(getLookupData, { modelName: 'EquipmentType', })
}

/**
 * Gather conditions for the provided burn  id.
 * @param {Object} action - The action object
 * @param {number} action.burnPermitId - The permit ID to request conditions for
 */
export function* getPermitConditions ({ burnPermitId, }) {
  try {

    const offline = yield select(networkStateSelector)
    if (!offline.online) {
      return
    }
    
    yield put({ type: PermitConditionTypes.SET_CONDITION_ERRORS, errors: {}, })
    yield call(showLoading)

    if (isNaN(parseInt(burnPermitId))) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply a Burn Permit ID to view the application.', })
      return
    }

    yield fork(getConditionLookupData)
    const burnPermDetailResp = yield call(doFetch, `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/conditions`)

    if (burnPermDetailResp.ok !== true) {
      yield put({ type: BurnPermitFormTypes.PERMIT_FAILED_TO_LOAD, })
      return
    }
    yield call(upsertConditonResponse, burnPermDetailResp.responseBody, burnPermitId)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* downloadPermitConditions () {
  try {
    const burnPermDetailResp = yield call(doFetch, `${BURN_PERMIT_ENDPOINT}/Conditions/Download`)

    if (burnPermDetailResp.ok !== true) {
      yield call(showError, 'An error occurred download Permit Conditions.')
      return
    }
    yield call(upsertConditonResponse, burnPermDetailResp.responseBody)
    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'Conditions', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* saveContactConditions (permitId, contacts, updateIssuedContacts) {
  try {
    const offline = yield select(networkStateSelector)
    if (!offline.online) {
      return
    }
    yield call(showLoading)
    if (isNaN(parseInt(permitId))) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply a Burn Permit ID to set contact conditions.', })
      return
    }
    if (!contacts) {
      yield put({ type: ApiTypes.FAILURE, error: 'Cannot submit contact conditions without values.', })
      return
    }

    const url = `${REACT_APP_SERVER_URL}BurnPermits/${permitId}/Conditions/Contacts`
    const request = { method: 'POST', body: { Contacts: contacts, UpdateIssuedContacts: updateIssuedContacts, }, }
    const res = yield call(doFetch, url, request)

    if (res.statusCode !== 200 || !res.ok) {
      yield put({ type: ApiTypes.FAILURE, error: res.responseBody, })
      return
    }

    const contactXrefResp = yield call(doFetch, url)
    if (contactXrefResp.ok !== true) {
      yield put({ type: BurnPermitFormTypes.PERMIT_FAILED_TO_LOAD, })
      return
    }

    if (contactXrefResp.statusCode === 200 && contactXrefResp.responseBody) {
      const xref = contactXrefResp.responseBody
      const newContacts = xref.BurnPermitRequiredContactCondition
      delete xref['BurnPermitRequiredContactCondition']
      delete xref['BurnPermitCondition']
      if (newContacts && Array.isArray(newContacts)) {
        yield put({ 
          type       : OrmTypes.UPSERT,
          modelName  : 'BurnPermitConditionXref',
          properties : xref,
        })
        yield put({
          type      : OrmTypes.REPLACE_ALL,
          objects   : newContacts,
          modelName : 'BurnPermitRequiredContactCondition',
          filterObj : { BurnPermitConditionXrefId: xref.BurnPermitConditionXrefId, },
        })
      }
    } else if (contactXrefResp.statusCode === 204) {
      const oldXref = yield select(contactConditionXrefForPermitSelector, permitId)
      yield put({
        type      : OrmTypes.DESTROY_ALL,
        modelName : 'BurnPermitRequiredContactCondition',
        filterObj : { BurnPermitConditionXrefId: oldXref.BurnPermitConditionXrefId, },
      })
      yield put({
        type      : OrmTypes.DESTROY,
        modelName : 'BurnPermitConditionXref',
        modelId   : oldXref.BurnPermitConditionXrefId,
      })
    }
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  } catch (error) {
    yield call(showError, error)
  } finally {
    yield call(hideLoading)
  }
}

export function* submitContactConditionsForActivePermit ({ contactObj, updateIssuedContacts, }) {
  try {
    yield put({ type: AppTypes.SHOW_LOADING , })
    if (!contactObj) {
      yield put({ type: ApiTypes.FAILURE, error: 'Cannot submit contact conditions without values.' , })
      return
    }
    const { Contacts, } = contactObj
    const activeId = yield select(activePermitIdSelector)
    yield call(saveContactConditions, activeId, Contacts, updateIssuedContacts)
  } catch (error) {
    yield call(showError, error)
  } finally {
    yield call(hideLoading)
  }
}


/**
 * Gather conditions for the active burn permit.
 * @param {Object} action - The action object
 * @param {number} action.permitId - The permit ID to set
 * @param {boolean} action.updateIssuedConditions - Flag to indicate whether or not to update conditions on an issued permit
 */
export function* submitConditionsForActivePermit ({ conditions, updateIssuedConditions, }) {
  try {
    yield call(showLoading)
    yield put({ type: AppTypes.SHOW_LOADING, })
    const activeId = yield select(activePermitIdSelector)
    yield call(submitConditionsForPermit, activeId, conditions, updateIssuedConditions)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* mapXref (conditionId, conditionValue, permitId) {
  const conditionName = yield select(permitConditionNameByIdSelector, conditionId)
  const { valueKey, isXref, } = yield select(conditionValueKeySelector, conditionName)

  let xref = { BurnPermitConditionId: conditionId, BurnPermitId: permitId, }
  if (valueKey !== null) {
    if (!isXref) {
      if (valueKey === 'OtherCondition') {
        const [ plainText, formatted, ] = extractContentStateValues(conditionValue)
        xref[valueKey] = plainText
        xref[valueKey + 'Formatted'] = formatted
      } else {
        xref[valueKey] = conditionValue
      }
    }
    else if (conditionValue && Array.isArray(conditionValue)) {

      switch (valueKey) {
      case 'BurnPermitEquipmentConditionXref': {
        const otherEquipDesc = conditionValue.filter(c => typeof c === 'string')[0]
        const chosenEquips = conditionValue.filter(c => typeof c === 'number')
        let equipTypes = [], otherEquipId = null
        if (otherEquipDesc) {
          equipTypes = yield select(equipmentTypeCheckboxSelector, { category: 'Fire Line Construction', })
          otherEquipId = equipTypes.filter(e => e.Text === 'Other').map(e => e.Value)[0]
        }
        xref[valueKey] = chosenEquips.map((id) => {
          const mapped = { 
            BurnPermitConditionXrefId : conditionId,
            EquipmentTypeId           : id,
          }
          // If an Other Equipment is chosen and description is provided
          if (otherEquipId !== null && id === otherEquipId) {
            // Set the description on the Other Equip Type Xref
            mapped.OtherDescription = otherEquipDesc
          }
          return mapped
        })
        break
      }
      case 'BurnPermitProhibitedDayConditionXref':
        xref[valueKey] = conditionValue.map((id) => {
          return {
            BurnPermitConditionXrefId : conditionId,
            DayOfWeekId               : id,
          }
        })
        break
      case 'BurnPermitWindDirectionConditionXref':
        xref[valueKey] = conditionValue.map((id) => {
          return {
            BurnPermitConditionXrefId : conditionId,
            DirectionId               : id,
          }
        })
        break
      default:
        break
      }
    }
  }
  return xref
}


/**
 * Gather conditions for the active burn permit.
 * @param {Object} action - The action object
 * @param {number} action.permitId - The permit ID to set
 */
function* submitConditionsForPermit (permitId, conditions, updateIssuedConditions) {
  try{
    yield call(showLoading)

    if (isNaN(parseInt(permitId))) {
      yield put({ type: ApiTypes.FAILURE, error: 'You must supply a Burn Permit ID to set conditions.', })
      return
    }
    yield put({ type: AppTypes.SHOW_LOADING, })

    if (conditions) {
      const ids = Object.keys(conditions)
      const xrefs = yield all(ids.map((id) => call(mapXref, id, conditions[id], permitId)))
      const burnPermDetailUrl = `${REACT_APP_SERVER_URL}BurnPermits/${permitId}/conditions`
    
      const body = yield call([ JSON, JSON.stringify, ], { ConditionXrefs: xrefs, UpdateIssuedConditions: updateIssuedConditions, })
      const request = { method: 'POST', body, }
      const res = yield call(doFetch, burnPermDetailUrl, request)
  
      if (res.statusCode !== 200 || !res.ok) {
  
        if (typeof res.responseBody === 'string') {
          yield put({ type: ApiTypes.FAILURE, error: res.responseBody, })
        }
        else if (typeof res.responseBody === 'object') {
          const eobj = yield call(parseErrorResponse, res.responseBody)
          if (eobj.validationErrors) {
            yield put({ type: PermitConditionTypes.SET_CONDITION_ERRORS, errors: eobj.validationErrors, })
          }
          yield put({ type: ApiTypes.FAILURE, error: eobj.message, })
        }
        else {
          yield put({ type: ApiTypes.FAILURE, error: `Failed to set conditions for Burn Permit ${permitId}`, })
        }
        return
      }
      yield put({ type: OrmTypes.DESTROY_ALL, modelName: 'BurnPermitConditionXref', filterObj: { BurnPermitId: permitId, }, })
      yield call(upsertConditonResponse, res.responseBody)
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
    if (updateIssuedConditions) {
      yield put({ type: BurnPermitSignatureTypes.RE_ISSUE_PERMIT, burnPermitId: permitId, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* parseErrorResponse (response) {
  let message = null
  let validationErrors = null
  if ((typeof response !== 'object') || (!response)) {
    return { message, validationErrors, }
  }
  
  const { error, errors, title, } = response
  if (title) {
    message = title
  }
  if (error) {
    message = error
    return { message, }
  }

  if (!errors) {
    return { message, validationErrors, }
  }

  if (typeof errors === 'object') {
    const keys = Object.keys(errors)
    let errorObj = {}
    const idMatch = /\[[0-9]+\]\.(.+)/
    for (let e of keys) {
      let found = e.match(idMatch)
      let name = yield select(conditionFieldToNameMapSelector, found[1])
      let condition = yield select(permitConditionByNameSelector, name)
      errorObj[condition.BurnPermitConditionId] = errors[e]
    }
    validationErrors = errorObj
  }
  return { message, validationErrors, }
}


export const BurnPermitConditionSagas = [
  takeLatest(PermitConditionTypes.SET_PERMIT_FOR_CONDITIONS, setPermitForConditions),
  takeLatest(PermitConditionTypes.GET_PERMIT_CONDITIONS, getPermitConditions),
  takeLatest(PermitConditionTypes.SET_ACTIVE_PERMIT_CONDITIONS, submitConditionsForActivePermit),
  takeLatest(PermitConditionTypes.SET_CONTACT_CONDITIONS, submitContactConditionsForActivePermit),
]