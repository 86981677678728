import React from 'react'
import { Link, } from 'react-router-dom'
import { PropTypes, object, bool, func, } from 'prop-types'
import { Alert, } from 'reactstrap'

function PostBurnPrompt ({ postBurnPrompt, isPromptShowing, onToggleList, }) {
  if (!postBurnPrompt || !postBurnPrompt.length) {
    return null
  }
  const iconClass = isPromptShowing ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
  const iconAlt = isPromptShowing ? 'Collapse Burn Request List' : 'Expand Burn Request List'
  const expandIcon = <span
    className={'fa ' + iconClass}
    alt={iconAlt}
    title={iconAlt}
    onClick={onToggleList}
    onKeyPress={onToggleList}
    role={'button'}
    tabIndex={0}
  />
  const postBurnText = <span>You have {postBurnPrompt.length} Burn Request{postBurnPrompt.length > 1 ? 's' : null } in need of Post Burn reporting {expandIcon}</span>
  return <Alert color={'warning'}>
    {postBurnText}
    { isPromptShowing &&
        <ul className={'post-burn-ul'}>
          { postBurnPrompt.map(p => {
            const { BurnRequestId, PostBurnPromptText, } = p
            return <li key={'pb-p-' + BurnRequestId}>
              <Link
                to={'/burn-requests/' + BurnRequestId}
                key={'pb-l-' + BurnRequestId}
                className={'btn p-0 m-0 shadow-none font-weight-bold alert-warning'}
                style={{ textDecorationLine: 'underline', }}
              >#{BurnRequestId}</Link> - {PostBurnPromptText}
            </li>
          })}
        </ul>
    }
  </Alert>
}

PostBurnPrompt.propTypes = {
  postBurnPrompt  : PropTypes.arrayOf(object),
  isPromptShowing : bool,
  onToggleList    : func,
}

PostBurnPrompt.defaultProps = {
  postBurnPrompt  : [],
  isPromptShowing : true,
  onToggleList    : () => console.error('No post-burn prompt toggle callback was provided.'),
}

export default PostBurnPrompt