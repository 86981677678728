// Libraries
import React, { useRef, useCallback, useEffect, useReducer, useMemo, } from 'react'
import { Container, Row, Col,  Badge, Fade, Button, } from 'reactstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import { useDispatch, useSelector, } from 'react-redux'
import { useNavigate, } from 'react-router-dom'

// Reducers
import AppActions from '../redux/AppRedux'
import CalendarActions from '../redux/CalendarRedux'

// Components
import ESRIMap from '../components/ESRIMap'
import { RowToggler, } from '../components/FormControls'
import CalendarModal from '../components/CalendarModal'

// Hooks
import { useToggle, } from '../hooks'

// Utils/Selectors
import { 
  calendarStateSelector, 
  calendarMapDataSelector, 
  calendarDataSelector, 
} from '../selectors/calendarSelectors'
import { dateFormatter, } from '../utilities'


function calendarReducer (state, action) {
  if (!action || !action.type) {
    return state
  }
  switch (action.type) {
  case 'DISPLAY_MODAL': {
    if (!action.payload || !action.payload.event) {
      return state
    }
    const { title, extendedProps, } = action.payload.event
    return {
      ...state,
      modalData: {
        title        : title,
        iconText     : extendedProps.iconText,
        icon         : extendedProps.icon,
        status       : extendedProps.Status,
        id           : extendedProps.Id,
        eventLink    : extendedProps.EventLink,
        eventDetails : extendedProps.eventDetails,
      },
      modalOpen: true,
    }
  }
  case 'TOGGLE_MODAL': 
    return { ...state, modalOpen: !state.modalOpen, }
  case 'TOGGLE_MAP': 
    return { ...state, showMap: !state.showMap, }
  case 'SHOW_ON_MAP':
    if (!action.payload || !action.payload.id) {
      return state
    }
    return {
      ...state,
      modalOpen     : false,
      showMap       : true,
      requestToShow : action.payload.id.toString(),
    }
  case 'REQUEST_SHOWN':
    return {
      ...state,
      requestToShow: '',
    }
  case 'MAP_READY' :
    return {
      ...state,
      mapReady: true,
    }
  default:
    break
  }
  return state
}

// calendar event render callback
const eventRender = (info) => {
  if (info && info.el) {
    info.el.title = info.el.innerText
    if (info.event.extendedProps.icon) {
      const icon = document.createElement('i')
      icon.className = info.event.extendedProps.icon
      icon.classList.add('mr-1')
      info.el.querySelector('.fc-title').insertAdjacentElement('beforebegin', icon)
    }
    if(info.event.extendedProps.multiBurn) {
      const multiBurnIcon = document.createElement('i')
      multiBurnIcon.className = info.event.extendedProps.multiBurn
      multiBurnIcon.classList.add('mr-1')
      info.el.querySelector('.fc-title').insertAdjacentElement('beforebegin', multiBurnIcon)
    }
  }
}

const CalendarContainer = () => {
  // component state
  const [ state, calendarDispatch, ] = useReducer(calendarReducer,
    {
      modalData     : null,
      modalOpen     : false,
      showMap       : false,
      requestToShow : '',
      mapReady      : false,
    }
  )

  // component reducer callbacks
  const toggleMap = useCallback(() => calendarDispatch({ type: 'TOGGLE_MAP', }), [])
  const onMapReady = useCallback(({ layer, }) => {
    if (layer && layer.id === 'BurnRequests'){
      calendarDispatch({ type: 'MAP_READY', })
    }
  }, [])
  const toggleModal = useCallback(() => calendarDispatch({ type: 'TOGGLE_MODAL', }), [])
  const displayModal = useCallback(({ event = null, }) => calendarDispatch({ type: 'DISPLAY_MODAL', payload: { event, }, }), [])
  const viewOnMap = useCallback((id) => calendarDispatch({ type: 'SHOW_ON_MAP', payload: { id, }, }), [])

  // component state
  const { modalData, modalOpen, showMap, mapReady, requestToShow, } = state
  const mapClass = `map-container rounded ${showMap ? 'my-1': 'd-none'}`
  const [ legendShowing, toggleLegend, ] = useToggle(false)
  const mapRef = useRef(null)

  const navigate = useNavigate()

  // redux state
  const dispatch = useDispatch()
  const {
    dataStartDate,
    viewStartDate,
    viewType,
  } = useSelector(calendarStateSelector)
  const events = useSelector(calendarDataSelector)
  const mapLayer = useSelector((state) => calendarMapDataSelector(state, navigate))

  // mount effect
  useEffect(() => {
    dispatch(AppActions.setPageTitle('Burn Calendar'))
  }, [ dispatch, ])

  // unmount effect
  useEffect(() => {
    const mr = mapRef
    return () => {
      if (mr && mr.current) {
        mr.current = null
      }
    }
  }, [])

  // calender datesRender callback
  const calendarRender = useCallback((evt) => {
    const { activeStart, type, currentStart, currentEnd, } = evt.view
    // don't dispatch the changes unless the type or start date has changed
    if (viewType === type && dataStartDate === activeStart.toJSON()) {
      return
    }
    let { activeEnd, } = evt.view
    // Always subtract a millisecond from the end date
    // since this seems to be affecting all view types
    let modifiedEndDate = dateFormatter(activeEnd, null, true)
    const offset = modifiedEndDate.utcOffset()
    modifiedEndDate = modifiedEndDate.subtract(Math.abs(offset), 'minute').subtract(1, 'millisecond')
    dispatch(CalendarActions.setStartEnd(dateFormatter(activeStart, 'YYYY-MM-DD'), dateFormatter(modifiedEndDate, 'YYYY-MM-DD'), currentStart.toJSON(), currentEnd.toJSON(), type))
  }, [ dispatch, dataStartDate, viewType, ])

  // effect to show request on map
  useEffect(() => {
    if (!mapReady || !mapRef || !mapRef.current || !requestToShow) {
      return
    }
    const { view, } = mapRef.current
    // close any open popup
    view.popup.close()
    // find the burn request layer
    const brLayer = view.layerViews.items.find(l => l.layer.id === 'BurnRequests')
    if (!brLayer) {
      console.warn('Unable to find burn request layer.')
      return
    }
    // get a handle on the layer's graphics
    brLayer.queryGraphics().then(
      // success callback
      graphics => {
        const viewRef = view
        // search for the burn request graphic
        const targetGraphic = graphics.find(g => g.getAttribute('BurnRequestId') === requestToShow)
        if (!targetGraphic) {
          console.warn('Unable to find burn request graphic.')
          return
        }
        // open the popup
        viewRef.goTo(targetGraphic.geometry).then(() => {
          viewRef.popup.open({ features: [ targetGraphic, ], location: targetGraphic.geometry, })
          // trigger the zoom action on the request's popup
          viewRef.popup.triggerAction(1)
        })
        calendarDispatch({ type: 'REQUEST_SHOWN', })
      },
      // failure callback
      err => console.error(err)
    )
  }, [ mapReady, requestToShow, ])

  const mapRowStyle = useMemo(() => {
    let height = '0px'
    if (showMap) {
      height = '530px'
    }
    return { height, overflow: 'hidden', }
  }, [ showMap, ])

  const rowToggleStyle = useMemo(() => ({ margin: 'auto 0', }), [])

  const header = useMemo(() => (
    {
      left   : 'today prev,next',
      center : 'title',
      right  : 'dayGridMonth,dayGridWeek,dayGridDay',
    }
  ), [])
  const buttonText = useMemo(() => (
    {
      today : 'Today',
      month : 'Month',
      week  : 'Week',
      day   : 'Day',
    }
  ), [])
  const plugins = useMemo(() => (
    [
      dayGridPlugin,
      timeGridPlugin,
      bootstrapPlugin,
    ]
  ), [])

  const toggleBtnText = useMemo(() => {
    let btnText = 'Show Map'
    if (showMap) {
      btnText = 'Hide Map'
    }
    return btnText
  }, [ showMap, ])

  return <Container className={'pt-2'}>
    <Row>
      <Col className={'calendar-container'}>
        <Row>
          <Col><h1>Burn Calendar</h1></Col>
          <Col>
            <div className={'h-100 d-flex flex-column align-items-end'}>
              <RowToggler
                rowLabel={'Calendar Legend'}
                onClick={toggleLegend}
                show={legendShowing}
                style={rowToggleStyle}
              />
            </div>
          </Col>
        </Row>
        <Fade in={legendShowing} className={'calendar-legend'}>
          <Row>
            <b>Calendar Legend</b>
          </Row>
          <Row>
            <ul>
              <li><Badge color={'dark'}>Submitted</Badge></li>
              <li><Badge color={'light'}>Cancelled</Badge></li>
              <li><Badge color={'secondary'}>Under Review / Info Required</Badge></li>
              <li><Badge color={'success'}>Approved</Badge></li>
              <li><Badge color={'danger'}>Denied</Badge></li>
              <li><Badge color={'light'}><i className={'fas fa-city'}></i> - Burn is in a UGA</Badge></li>
              <li><Badge color={'light'}><i className={'fas fa-tree'}></i> - Burn has a Forest Health Exemption</Badge></li>
              <li><Badge color={'light'}><i className={'fas fa-m'}></i> - Multi-Day Burn Request</Badge></li>
            </ul>
          </Row>
        </Fade>
        <Row className={'pb-1'}>
          <Col>
            <Button onClick={toggleMap}>{toggleBtnText}</Button>
          </Col>
        </Row>
        <Row className={mapClass} style={mapRowStyle}>
          <ESRIMap
            config={'Calendar'}
            mapData={mapLayer}
            parentRef={mapRef}
            onLayerViewCreate={onMapReady}
          />
        </Row>
        <FullCalendar
          events={events}
          eventLimit={true} // allow "more" link when too many events
          eventClick={displayModal}
          eventRender={eventRender}
          datesRender={calendarRender}
          defaultDate={viewStartDate}
          defaultView={viewType}
          header={header}
          buttonText={buttonText}
          plugins={plugins}
          themeSystem={'bootstrap'}
        />
      </Col>
    </Row>
    <CalendarModal 
      modalOpen={modalOpen}
      toggleModal={toggleModal}
      viewOnMap={viewOnMap}
      {...modalData}
    />
  </Container>
}

export default React.memo(CalendarContainer)