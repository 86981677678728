// Libraries
import React from 'react'
import { SuccessIcon, } from '../../Icons'

import style from './style'

function Complete () {
  return <SuccessIcon style={style} title={'You have completed this portion of your profile'} />
}

export default Complete