// Libraries
import React, { useCallback, useMemo, useRef, useState, } from 'react'
import ImageGallery from 'react-image-gallery'
import {
  Card,
  CardBody,
  CardText,
} from 'reactstrap'

// Components
import ExternalLink from '../../ExternalLink'

const imageDir = '/images/property/improved'
const defaultOriginalHeight = 350

const ImprovedPropertyCard = () => {

  const [ originalHeight, setOriginalHeight, ] = useState(defaultOriginalHeight)
  const [ imageIdx, setImageIdx, ] = useState(0)

  const galleryRef = useRef()

  const setGalleryRef = useCallback(node => {
    if (node) {
      galleryRef.current = node
    }
    return () => {
      if (galleryRef && galleryRef.current) {
        galleryRef.current = null
      }
    }
  }, [])

  const screenChange = useCallback(isFullScreen => {
    let newHeight = defaultOriginalHeight
    if (isFullScreen) {
      newHeight = null
    }
    setOriginalHeight(newHeight)
    galleryRef.current.slideToIndex(imageIdx)
  }, [ imageIdx, ])

  const images = useMemo(() => ([
    {
      fullscreen : `${imageDir}/DownriverGolfCourse/downriver-golf-course.jpg`,
      original   : `${imageDir}/DownriverGolfCourse/downriver-golf-course_350.jpg`,
      thumbnail  : `${imageDir}/DownriverGolfCourse/downriver-golf-course_tn.jpg`,
      originalHeight,
    },
    {
      fullscreen : `${imageDir}/SpokaneCityParksRec/CityPark.jpg`,
      original   : `${imageDir}/SpokaneCityParksRec/CityPark_350.jpg`,
      thumbnail  : `${imageDir}/SpokaneCityParksRec/CityPark_tn.jpg`,
      originalHeight,
    },
    {
      fullscreen : `${imageDir}/SpokaneCityParksRec/ComstockPark.jpg`,
      original   : `${imageDir}/SpokaneCityParksRec/ComstockPark_350.jpg`,
      thumbnail  : `${imageDir}/SpokaneCityParksRec/ComstockPark_tn.jpg`,
      originalHeight,
    },
  ]), [ originalHeight, ])

  const ecology = useMemo(() => (
    <ExternalLink
      href={'https://ecology.wa.gov/Regulations-Permits/Permits-certifications/Air-Quality-permits/Burn-permits'}
      text={'this website'}
    />
  ), [])

  return <>
    <h1>Improved Property</h1>
    <Card>
      <CardBody>
        <CardText>Is the debris you want to burn from an improved property?</CardText>
        <CardText>
          If your burnable debris comes from property as seen in these pictures, 
          it is more than likely not permittable by Department of Natural Resources. 
          Please contact your local City or County Fire District for help. 
          You can also review {ecology} for the Department of Ecology for further help and information.
        </CardText>
        <ImageGallery
          ref={setGalleryRef}
          items={images}
          onScreenChange={screenChange}
          onSlide={setImageIdx}
        />
      </CardBody>
    </Card>
  </>
}

export default React.memo(ImprovedPropertyCard)