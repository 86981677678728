// Libraries
import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { array, string, func, object, } from 'prop-types'
import { months, } from 'dayjs/locale/en'

// Utilities
import { dateFormatter, } from '../../utilities'

// Styles
import 'react-day-picker/lib/style.css'

const date = dateFormatter(new Date())
const fromMonth = date.add(-10, 'year').toDate()
const toMonth = date.toDate()

const yearsMarkup = []
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
  yearsMarkup.push(
    <option key={i} value={i}>
      {i}
    </option>
  )
}

const monthsMarkup = months.map((month, i) => (
  <option key={month} value={i}>
    {month}
  </option>
))

function YearMonthForm ({ date, onChange, }) {
  return <div className={'DayPicker-Caption'}>
    {/* eslint-disable-next-line jsx-a11y/no-onchange */}
    <select name={'month'} value={date.getMonth()} className={'d-inline mr-2 form-control'} onChange={onChange}>
      { monthsMarkup }
    </select>
    {/* eslint-disable-next-line jsx-a11y/no-onchange */}
    <select name={'year'} value={date.getFullYear()} className={'d-inline form-control'} onChange={onChange}>
      { yearsMarkup }
    </select>
  </div>
}

YearMonthForm.propTypes = {
  date     : object,
  onChange : func,
}


export default class DateRange extends React.Component {

  static propTypes = {
    from            : string,
    to              : string,
    fromPlaceholder : string,
    toPlaceholder   : string,
    disabledDays    : array,
    enteredTo       : string,
    onClick         : func,
    setValid        : func,
    name            : string,
  }

  static defaultProps = {
    from            : '',
    to              : '',
    fromPlaceholder : 'yyyy-mm-dd',
    toPlaceholder   : 'yyyy-mm-dd',
    disabledDays    : [],
    onClick         : () => {},
    setValid        : () => {},
    name            : '',
  }

  state = {
    fromMonth : date.add(-1, 'year').toDate(),
    toMonth   : toMonth,
    fromValid : true,
    toValid   : true,
  }

  setValid = (from = null, to = null) => {
    const fromValid = from === null ? this.state.fromValid : from
    const toValid = to === null ? this.state.toValid : to
    if ((fromValid || fromValid === null) && (toValid || toValid === null)) {
      this.props.setValid(this.props.name, true)
    } else {
      this.props.setValid(this.props.name, false)
    }
    this.setState({ fromValid, toValid, })
  }

  setParsedValid = (isValid, which) => {
    if (which === 'to') {
      this.setValid(null, isValid)
    } else if (which === 'from') {
      this.setValid(isValid)
    }
  }

  handleFromChange = (from, modifiers, dayPickerInput) => {
    // Change the from date and focus the "to" input field
    this.props.onClick({ from, })
    const _date = dateFormatter(from)
    if (_date) {
      if (typeof _date.isValid === 'function' && _date.isValid()){
        this.setValid(true)
        if (this.toPicker && this.toPicker.getInput()){
          this.toPicker.getInput().focus()
        }
      } else {
        this.setValid(false)
      }
    } else if (dayPickerInput.input.value === '') {
      this.setValid(true)
    }

    if (this.toInput) {
      this.toInput.focus()
    }
  }

  handleToChange = (to, modifiers, dayPickerInput) => {
    const { from, } = this.props
    const picker = dayPickerInput.getDayPicker()
    const input = dayPickerInput.getInput()
    this.props.onClick({ from, to, })
    const _date = dateFormatter(to)

    if (_date) {
      if (typeof _date.isValid === 'function' && _date.isValid()) {
        this.setValid(null, true)
      } else {
        this.setValid(null, false)
      }
    } else if (input && input.value === '') {
      this.setValid(null, true)
    }

    if (from && picker) {
      picker.showMonth(dateFormatter(from).toDate())
    }
  }

  parseDate = (date, which) => {
    if (typeof date === 'string' && (date.length < 10)) {
      if (date.length === 0) {
        this.setParsedValid(true, which)
      } else {
        this.setParsedValid(false, which)
      }
      return
    }

    const _date = dateFormatter(date)
    if (_date) {
      if (typeof _date.isValid === 'function' && _date.isValid()) {
        this.setParsedValid(true, which)
        return _date.toDate()
      } else {
        this.setParsedValid(false, which)
      }
    }
    return
  }

  formatDate = date => {
    if (!date) {
      return date
    }
    const _date = dateFormatter(date, 'YYYY-MM-DD')
    if (_date) {
      return _date
    }
    return date
  }

  handleYearMonthChange = evt => {
    const { year, month, } = evt.target.form
    const targetMonth = new Date(year.value, month.value)
    this.setState({ month: targetMonth, })
  }

  render () {
    const { from, to, disabledDays, } = this.props
    let fromDate = dateFormatter(from)
    if (fromDate && typeof fromDate.isValid === 'function' && fromDate.isValid()) {
      fromDate = fromDate.toDate()
    }
    let toDate = dateFormatter(to)
    if (toDate && typeof toDate.isValid === 'function' && toDate.isValid()) {
      toDate = toDate.toDate()
    }

    const { month, } = this.state
    
    const pickerProps = {
      month,
      canChangeMonth : false,
      // Sets the target month/year to display
      modifiers      : { start: fromDate, end: toDate, },
      // If there are any selected days, provide them so they can be styled
      selectedDays   : [ fromDate, toDate, { fromDate, toDate, }, ],
      captionElement : ({ date, }) => {
        return <YearMonthForm
          date={date}
          onChange={this.handleYearMonthChange}
        />
      },
    }

    if (Array.isArray(disabledDays) && disabledDays.length) {
      pickerProps.disabledDays = disabledDays
    }
    
    return <div className={'d-flex justify-content-around'}>
      <DayPickerInput
        ref={el => this.fromPicker = el}
        value={from}
        placeholder={this.props.fromPlaceholder}
        format={'LL'}
        formatDate={this.formatDate}
        parseDate={date => this.parseDate(date, 'from')}
        dayPickerProps={{ ...pickerProps, }}
        onDayChange={this.handleFromChange}
        title={'From Date'}
        inputProps={{
          className : `form-control ${!this.state.fromValid ? 'is-invalid' : ''}`,
          invalid   : (!this.state.fromValid).toString(),
        }}
      />
      <DayPickerInput
        ref={el => this.toPicker = el}
        value={to}
        placeholder={this.props.toPlaceholder}
        format={'LL'}
        formatDate={this.formatDate}
        parseDate={date => this.parseDate(date, 'to')}
        dayPickerProps={{ ...pickerProps, }}
        onDayChange={this.handleToChange}
        title={'To Date'}
        inputProps={{
          className : `form-control ${!this.state.toValid ? 'is-invalid' : ''}`,
          invalid   : (!this.state.toValid).toString(),
        }}
      />
    </div>
  }
}