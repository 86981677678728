// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { object, func, bool, number, string, PropTypes, } from 'prop-types'
import { Container, Card, CardBody, Button, } from 'reactstrap'
import { Link, } from 'react-router-dom'
import { isEqual, } from 'lodash'

// Components
import BurnPermitCartSummary from './../PermitOrders/BurnPermitCartSummary'
import PhysicalPaymentForm from './PhysicalPaymentForm'
import ConfirmationModal from '../ConfirmationModal'
import RefundForm from './RefundForm'
import OrderPaymentSummary from './OrderPaymentSummary'
import { PopoverButton, } from '../FormControls'
import RemovePermitFromOrderModal, { REMOVE_PERMIT_MODAL, } from './RemovePermitFromOrderModal'
import {
  ApplicationNextSteps,
} from '../Modals'
import withRouter from '../withRouter'

// Actions
import AppActions from '../../redux/AppRedux'
import BurnPermitOrderActions from '../../redux/BurnPermitOrderRedux'
import UiActions from '../../redux/UiRedux'

// Selectors
import { orderByIdSelector, orderHasPaymentSelector, orderHasIssuedPermit, orderEligibleForVoucherPayment, } from '../../selectors/orderSelectors'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import { userCanEditOrders, userCanRefundOrders, } from '../../selectors/burnPermitPermissionSelectors'

const DELETE_ORDER = 'DELETE_ORDER'

export class BurnPermitOrderDetail extends React.Component {

  constructor (props) {
    super(props)
    // Clear out any new order id to start just in case the call in the unmount
    // doesn't execute in time on a page reload or something
    this.props.SetNewOrderId()

    this.confirmDeleteOrder = this.confirmDeleteOrder.bind(this)
    this.state = {
      editing   : false,
      refunding : false,
    }

    const params = new URLSearchParams(window.location.search)

    const paramsObj = {}

    for (let entry of params.entries()) {
      paramsObj[entry[0]] = entry[1]
    }
    if (paramsObj && paramsObj.showNextSteps === 'true') {
      this.props.navigate(`/permits/orders/${this.props.BurnPermitOrderId}`)
      this.props.OpenModal('APPLICATION_NEXT_STEPS')
    }
  }

  static propTypes = {
    // from withRouter HOC
    navigate : func,
    params   : object,

    SetPageTitle              : func,
    SetNewOrderId             : func,
    GetBurnPermitOrderDetail  : func,
    RemovePermitFromOrder     : func,
    UpdateOrder               : func,
    DeleteOrder               : func,
    OpenModal                 : func,
    order                     : object,
    BurnPermitOrderId         : PropTypes.oneOfType([ number, string, ]),
    orderValSchema            : object,
    canEdit                   : bool,
    canRefund                 : bool,
    orderHasPayment           : bool,
    showEditButton            : bool,
    enableRefundButton        : bool,
    eligibleForVoucherPayment : PropTypes.oneOfType([ bool, string, ]),
    isDnr                     : bool,
  }

  static defaultProps = {
    canEdit            : false,
    canRefund          : false,
    order              : null,
    orderHasPayment    : false,
    showEditButton     : false,
    isDnr              : false,
    enableRefundButton : false,
  }

  componentWillUnmount () {
    this.props.SetNewOrderId()
  }

  componentDidMount () {
    const { SetPageTitle, BurnPermitOrderId, GetBurnPermitOrderDetail, } = this.props
    SetPageTitle(`Burn Permit Order ${BurnPermitOrderId}`)
    GetBurnPermitOrderDetail(BurnPermitOrderId)
  }

  componentDidUpdate (oldProps) {
    const orderHadPayment = oldProps.orderHasPayment
    const { orderHasPayment, order, } = this.props
    // hide the form if payment was just entered
    // or if it is saved or if new information came through from the server
    if (orderHasPayment && !orderHadPayment || !isEqual(oldProps.order, order)) {
      this.setState({ editing: false, refunding: false, })
    }

    // Redirect back to the order list after a successful delete
    if (oldProps.order && !this.props.order) {
      this.props.navigate('/permits/orders')
    }
  }

  submit = (values) => {
    this.props.UpdateOrder(values)
  }

  toggleEditing = () => {
    this.setState({ editing: !this.state.editing, })
  }

  toggleRefunding = () => {
    this.setState({ refunding: !this.state.refunding, })
  }

  confirmDeleteOrder (evt) {
    stopEvent(evt)

    this.props.OpenModal(DELETE_ORDER)
  }

  removePermit = evt => {
    const { id, } = evt.target.dataset
    this.setState({ itemIdToRemove: parseInt(id), }, () => {
      this.props.OpenModal(REMOVE_PERMIT_MODAL)
    })
  }

  removeItemFromCart = itemId => {
    this.props.RemovePermitFromOrder(this.props.order.BurnPermitOrderId, itemId)
    return true
  }

  render () {
    const {
      order,
      canEdit,
      canRefund,
      orderHasPayment,
      showEditButton,
      enableRefundButton,
      eligibleForVoucherPayment,
      isDnr,
    } = this.props
    const { editing, refunding, } = this.state
    if (!order) {
      return null
    }

    const showPhysicalPaymentForm = canEdit && (editing || !orderHasPayment)
    const showOrderSummary = (!editing && orderHasPayment)
    const showRefundForm = canRefund && refunding
    const appDetail = order ? order.ApplicationDetail : null
    return <Container className={'py-4'}>
      <ApplicationNextSteps />
      <h1><Link to={'/permits/orders'}>Burn Permit Orders</Link>{' '}- Order Details</h1>
      <Card>
        <CardBody>
          <BurnPermitCartSummary
            RemovePermitFromOrder={this.removePermit}
            BurnPermitIds={order.BurnPermitOrderXref.map(x => x.BurnPermitId, [])}
            orderHasPayment={orderHasPayment}
            BurnPermitOrderAmount={order.BurnPermitOrderAmount}
            TotalTonnage={order.TotalTonnage}
          />
          <RemovePermitFromOrderModal
            idToRemove={this.state.itemIdToRemove}
            orderId={order.BurnPermitOrderId}
            confirmRemoveClick={this.removeItemFromCart}
          />
          {
            showEditButton &&
              <div className={'d-flex justify-content-between pt-2'}>
                <div>
                  <Button
                    size={'sm'}
                    color={editing ? 'warning' : 'success'}
                    onClick={this.toggleEditing}
                    disabled={refunding}
                    className={'mr-2'}
                  >
                    {editing ? 'Done Editing' : 'Edit Payment'}
                  </Button>
                </div>
                <div>
                  {
                    !enableRefundButton &&
                      <PopoverButton 
                        popoverHeader={'Cannot Refund Order'}
                        popoverBody={'You cannot refund an order that paid via PayPoint. Only Cash/Check payments can be refunded through the Burn Portal.'}
                        buttonClassName={'py-0'}
                      />
                  }
                  <Button
                    size={'sm'}
                    color={refunding ? 'warning' : 'secondary'}
                    onClick={this.toggleRefunding}
                    disabled={editing || !enableRefundButton}
                  >
                    {refunding ? 'Done Refunding' : 'Refund'}
                  </Button>
                </div>
              </div>
          }
          {
            showOrderSummary && 
              <OrderPaymentSummary
                order={order}
                appDetail={appDetail}
              />
          }
          {
            showPhysicalPaymentForm && 
              <PhysicalPaymentForm
                onSubmit={this.submit}
                order={order}
                appDetail={appDetail}
                eligibleForVoucherPayment={eligibleForVoucherPayment}
                eligableForCashCheckPayment={isDnr}
              />
          }
          {
            showRefundForm &&
              <RefundForm
                onSubmit={this.submit}
                order={order}
                appDetail={appDetail}
              />
          }
        </CardBody>
      </Card>
      <div className={'d-flex justify-content-between pt-2'}>
        <div>
          <Button
            color={'danger'}
            onClick={this.confirmDeleteOrder}
            id={'delete-order'}
            disabled={orderHasPayment}
          >
            Delete Order
          </Button>
          {
            orderHasPayment &&
              <PopoverButton 
                popoverHeader={'Cannot Delete Order'}
                popoverBody={`You cannot delete an order that is already paid.${enableRefundButton ? ' You can only Refund it.' : ''}`}
                buttonClassName={'py-0'}
              />
          }
        </div>
        { !isDnr && !orderHasPayment && <Link className={'btn btn-secondary'} to={`/permits/orders/${order.BurnPermitOrderId}/pay`}>Pay Order</Link> }
      </div>
      <ConfirmationModal
        modalKey={DELETE_ORDER}
        modalTitle={`Delete Order ${this.props.BurnPermitOrderId}`}
        modalBody={<>
          <p>Are you sure you want to <b>delete Order {this.props.BurnPermitOrderId}</b>?</p>
        </>}
        submitAction={this.props.DeleteOrder}
        submitArgs={[ this.props.BurnPermitOrderId, ]}
      />
    </Container>
  }
}

function mapStateToProps (state, props) {
  const { BurnPermitOrderId, } = props.params // from withRouter HOC
  const order = orderByIdSelector(state, BurnPermitOrderId)
  const hasIssuedPermit = orderHasIssuedPermit(state, BurnPermitOrderId)
  const orderHasPayment = orderHasPaymentSelector(state, BurnPermitOrderId)
  let canEdit = userCanEditOrders(state) && !hasIssuedPermit
  let eligibleForVoucherPayment = false
  if (props.isDnr || (props.isStateGovCust && props.isVerifiedAgent)) {
    eligibleForVoucherPayment = orderEligibleForVoucherPayment(state, BurnPermitOrderId)
    if (typeof eligibleForVoucherPayment === 'boolean') {
      canEdit = eligibleForVoucherPayment
    }
  }
  const canRefund = userCanRefundOrders(state) && orderHasPayment
  const showEditButton = (canEdit && orderHasPayment) && (order && !!order.ApplicationDetail) && !order.EPayReturnCode
  const enableRefundButton = (canRefund && orderHasPayment) && !order.EPayReturnCode
  
  return {
    order,
    showEditButton, 
    enableRefundButton,
    BurnPermitOrderId,
    canEdit,
    canRefund,
    orderHasPayment,
    eligibleForVoucherPayment,
  }
}

const mapDispatchToProps = {
  DeleteOrder              : BurnPermitOrderActions.deleteOrder,
  GetBurnPermitOrderDetail : BurnPermitOrderActions.getBurnPermitOrderDetail,
  RemovePermitFromOrder    : BurnPermitOrderActions.removePermitFromOrder,
  UpdateOrder              : BurnPermitOrderActions.updateBurnPermitOrder,
  SetNewOrderId            : BurnPermitOrderActions.setNewOrderId,
  SetPageTitle             : AppActions.setPageTitle,
  OpenModal                : UiActions.openModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BurnPermitOrderDetail))