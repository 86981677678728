import { attr, fk, Model, } from 'redux-orm'

/**
 * PersonAlertPreferenceXref model
 */
export default class PersonAlertPreferenceXref extends Model {
  static get modelName () {
    return 'PersonAlertPreferenceXref'
  }

  static get options () {
    return {
      idAttribute: 'PersonAlertPreferenceXrefId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PersonAlertPreferenceXrefId : attr(),
      PersonId                    : fk({ to: 'Person', relatedName: 'PersonAlertPreferenceXrefs', as: 'PersonAlertPreferenceXrefPerson', }),
      AlertPreferenceId           : fk({ to: 'AlertPreference', relatedName: 'PersonAlertPreferenceXrefs', as: 'PersonAlertPreferenceXrefAlertPreference', }),
      AlertMethodId               : fk({ to: 'AlertMethod', relatedName: 'PersonAlertPreferenceXrefs', as: 'PersonAlertPreferenceXrefAlertMethod', }),
      CreateDate                  : attr(),
      CreateBy                    : attr(),
      UpdateDate                  : attr(),
      UpdateBy                    : attr(),
    }
  }
}