import { createReducer, createActions, } from 'reduxsauce'
    
/* ------------- Types and Action Creators ------------- */
const { Types, Creators, } = createActions({
  getOrders                  : null,
  getBurnPermitDetailForCart : [ 'burnPermitId', ],
  getBurnPermitOrderDetail   : [ 'burnPermitOrderId', ],
  createBurnPermitOrder      : [ 'BurnPermitIds', ],
  updateBurnPermitOrder      : [ 'burnPermitOrder', ],
  setNewOrderId              : [ 'burnPermitOrderId', ],
  updateOrderPermitIds       : [ 'permitIds', ],
  removePermitFromOrder      : [ 'orderId', 'permitId', ],
  deleteOrder                : [ 'orderId', ],
})
  
export const BurnPermitOrderTypes = Types
export default Creators
  
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  newBurnPermitOrderId: '',
}

/* ------------- Reducers ------------- */

export const setNewOrderId = (state, { burnPermitOrderId, }) => {
  const newState = { ...state, }
  newState.newBurnPermitOrderId = burnPermitOrderId
  return newState
}

export const updateOrderPermitIds = (state, { permitIds, }) => {
  const newState = { ...state, }
  newState.createdOrderPermitIds = permitIds
  if (permitIds === null || permitIds.length === 0) {
    newState.newBurnPermitOrderId = ''
  }
  return newState
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NEW_ORDER_ID]        : setNewOrderId,
  [Types.UPDATE_ORDER_PERMIT_IDS] : updateOrderPermitIds,
})