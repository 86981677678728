import React from 'react'
import { FormGroup, Col, Label, Input,  } from 'reactstrap'
import { func, string, bool, object, PropTypes, } from 'prop-types'

function YesNoRadioButtonGroup ({ inputName, onChange, readOnly, checkedValue, invalid, errorMessage, legend, ariaLabel, }) {
  const id = `${inputName}_${(new Date()).getMilliseconds()}`
  return <Col xs={12}>
    { (invalid || !!errorMessage) && <div className={'invalid-feedback radio-invalid-feedback'}>{errorMessage}</div> }
    <fieldset>
      { legend && <legend>{legend}</legend> }
      <FormGroup className={'pl-5 mb-1'}>
        <Label className={'form-radio-label'} id={inputName + '-yes'}>
          <Input
            id={`${id}-yes`}
            type={'radio'}
            value={'yes'}
            name={inputName}
            onChange={!readOnly ? onChange : null}
            checked={checkedValue === 'yes' || checkedValue === 'true' || checkedValue === true}
            readOnly={readOnly}
            disabled={readOnly}
            invalid={invalid || !!errorMessage}
            aria-labelledby={`${inputName} ${inputName}-yes ${ariaLabel || ''}`}
          />
          <div className={readOnly && (checkedValue !== 'yes') ? 'grey-text' : null}>Yes</div>
        </Label>
      </FormGroup>
      <FormGroup className={'pl-5 mb-0'}>
        <Label className={'form-radio-label'} id={inputName + '-no'}>
          <Input
            id={`${id}-no`}
            type={'radio'}
            value={'no'}
            name={inputName}
            onChange={!readOnly ? onChange : null}
            checked={checkedValue === 'no' || checkedValue === 'false' || checkedValue === false}
            readOnly={readOnly}
            invalid={invalid || !!errorMessage}
            disabled={readOnly}
            aria-labelledby={inputName + ' ' + inputName + '-no ' + ariaLabel}
          />
          <div className={readOnly && (checkedValue !== 'no') ? 'grey-text' : null}>No</div>
        </Label>
      </FormGroup>
    </fieldset>
  </Col>
}

YesNoRadioButtonGroup.propTypes = {
  inputName    : string,
  onChange     : func,
  checkedValue : PropTypes.oneOfType([ string, bool, ]),
  readOnly     : bool,
  invalid      : bool,
  errorMessage : string,
  legend       : PropTypes.oneOfType([ string, object, ]),
  ariaLabel    : string,
}

YesNoRadioButtonGroup.defaultProps = {
  readOnly     : false,
  inputName    : null,
  onChange     : null,
  checkedValue : null,
  invalid      : false,
  errorMessage : '',
}

export default YesNoRadioButtonGroup