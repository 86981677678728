import React from 'react'
import { Row, Col, Label, Input, } from 'reactstrap'
import { string, func, number, PropTypes, } from 'prop-types'

const NumberRange = ({ label, onChange, minName, minValue, minPlaceholder, maxName, maxValue, maxPlaceholder, }) => {
  const id = label.split(' ').join('')
  const labelName = `range-label-${id}`
  return <Row>
    <Col xs={12}>
      <Label for={`min-${id}`} id={labelName}>{label}</Label>
    </Col>
    <Col xs={6}>
      <Input
        id={`min-${id}`}
        name={minName}
        placeholder={minPlaceholder}
        onChange={onChange}
        value={minValue}
        type={'number'}
      />
    </Col>
    <Col xs={6}>
      <Input
        name={maxName}
        placeholder={maxPlaceholder}
        onChange={onChange}
        value={maxValue}
        type={'number'}
        aria-labelledby={labelName}
      />
    </Col>
  </Row>
}

NumberRange.propTypes = {
  label          : string,
  onChange       : func,
  minName        : string,
  minPlaceholder : string,
  minValue       : PropTypes.oneOfType([ string, number, ]),
  maxName        : string,
  maxPlaceholder : string,
  maxValue       : PropTypes.oneOfType([ string, number, ]),
}

NumberRange.defaultProps = {
  minPlaceholder : 'Min',
  maxPlaceholder : 'Max',
}

export default NumberRange