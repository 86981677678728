import React, { useMemo, useRef, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { render as ReactDOMRender, } from 'react-dom'
import { array, bool, number, oneOfType, string, } from 'prop-types'
import { isEqual, } from 'lodash'
import {
  Alert,
  Col,
  Row,
} from 'reactstrap'

// Reducers
import CustomerInteractionActions from '../../../../redux/CustomerInteractionRedux'

// Components
import DataTable from '../../../DataTable'

// Models
import BurnComplaintSearchResult from '../../../../models/BurnComplaintSearchResult'

// Hooks
import { useTableCellLink, } from '../../../../hooks'

// Selectors
import { smokeComplaintPermitXrefs, } from '../../../../selectors/customerInteractionSelectors'


const addToClasses = 'fa-plus btn-success'
const removeFromClasses = 'fa-minus btn-danger'

const addToTitle = 'Link Burn to Complaint'
const removeFromTitle = 'Remove Burn from Complaint'


const AssociatedBurns = props => {
  const {
    enableAdd,
    CustomerInteractionId,
    elementId,
    burns,
    show,
  } = props

  const [ showPrompt, setShowPrompt, ] = useState(false)

  const xrefs = useSelector(state => smokeComplaintPermitXrefs(state, CustomerInteractionId))

  const prevXrefs = useRef(xrefs)
  const redrawTable = !isEqual(prevXrefs.current, xrefs)

  const dispatch = useDispatch()

  const reqLinkCellCallback = useTableCellLink({ route: 'burn-requests', })
  const permLinkCellCallback = useTableCellLink({ route: 'permits', })

  const columns = useMemo(() => {
    const cols = BurnComplaintSearchResult.getDataTablesColumns()
    return [ ...cols, ]
  }, [])

  const burnReqColumnDefs = useMemo(() => {
    const onToggleAssoc = (e) => {
      const BurnPermitId = parseInt(e.target.getAttribute('data-permit-id'))
      if (e.target.classList.value.includes(addToClasses)) {
        dispatch(CustomerInteractionActions.associateBurn({ CustomerInteractionId, BurnPermitId, }))
      }
      else {
        dispatch(CustomerInteractionActions.removeBurnAssociation({ CustomerInteractionId, BurnPermitId, }))
      }
      setShowPrompt(true)
    }
  
    const linkPermitCellCallback = (td, cellData, row) => {
      let classes = removeFromClasses, title = removeFromTitle
      let xrefId
      const IsAssociated = `${cellData}` === 'true'
      const permitId = parseInt(row.BurnPermitId)
      if (enableAdd && !IsAssociated) {
        classes = addToClasses
        title = addToTitle
      }
      if (IsAssociated) {
        const permitIdIdx = xrefs.findIndex(x => x.BurnPermitId === permitId)
        if (permitIdIdx >= 0) {
          xrefId = xrefs[permitIdIdx].CustomerInteractionBurnPermitXrefId
        }
      }
      return ReactDOMRender(<button
        className={`btn btn-sm fa ${classes}`}
        title={title}
        onClick={onToggleAssoc}
        data-permit-id={permitId}
        data-xref-id={xrefId}
      />, td)
    }
    return [
      {
        target      : 0,
        createdCell : linkPermitCellCallback,
      },
      {
        targets     : columns.findIndex(c => c.data === 'BurnRequestId'),
        createdCell : reqLinkCellCallback,
      },
      {
        targets     : columns.findIndex(c => c.data === 'BurnPermitId'),
        createdCell : permLinkCellCallback,
      },
    ]
  }, [ xrefs, columns, enableAdd, CustomerInteractionId, reqLinkCellCallback, permLinkCellCallback, dispatch, ])

  if (!CustomerInteractionId || !show) {
    return null
  }

  return <Row className={'my-3'}>
    <Col xs={12}>
      <Alert isOpen={showPrompt} color={'info'}>
        <p>
          If you have not already, log a status change that includes a brief description
          why you added or removed Burn Permits.
        </p>
        <p>
          If you have added a Burn Permit from another Region than where the complaint
          is located, consider assigning someone from that Region to also review the complaint.
        </p>
      </Alert>
    </Col>
    <Col xs={12}>
      <DataTable
        columns={columns}
        columnDefs={burnReqColumnDefs}
        records={burns}
        elementId={elementId}
        disableRowClick={true}
        disableDefaultOrdering={true}
        redraw={redrawTable}
      />
    </Col>
  </Row>
}

AssociatedBurns.defaultProps = {
  elementId: 'associated-burns-table',
}

AssociatedBurns.propTypes = {
  CustomerInteractionId : oneOfType([ number, string, ]),
  elementId             : string,
  burns                 : array,
  enableAdd             : bool,
  show                  : bool,
}

export default React.memo(AssociatedBurns)
