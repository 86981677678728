// Libraries
import React from 'react'
import { number, func, } from 'prop-types'

// Components
import ConfirmationModal from '../ConfirmationModal'


export const REMOVE_PERMIT_MODAL = 'REMOVE_PERMIT_MODAL'

const RemovePermitFromOrderModal = props => {
  return <ConfirmationModal
    modalKey={REMOVE_PERMIT_MODAL}
    modalTitle={`Remove Permit ${props.idToRemove}${props.orderId ? ` from Order ${props.orderId}`: ''}`}
    modalBody={<p>Are you sure you want to <b>remove Permit Application {props.idToRemove}</b>{props.orderId ? ` from Order ${props.orderId}`: ''}?</p>}
    cancelActionLabel={'No'}
    submitActionLabel={'Yes'}
    submitAction={props.confirmRemoveClick}
    submitArgs={[ props.idToRemove, ]}
  />
}

RemovePermitFromOrderModal.propTypes = {
  idToRemove         : number,
  orderId            : number,
  confirmRemoveClick : func,
}

RemovePermitFromOrderModal.defaultProps = {
  confirmRemoveClick: () => {},
}

export default RemovePermitFromOrderModal