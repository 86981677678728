// Libraries
import { createSelector, } from 'reselect'

// Selectors
import { ormSelector, ormWithPropSelector, ormByIdSelector, } from './selectors'
import { userNameSelector, } from './userSelectors'
import { userCanEditAnyAgency, } from './permissionSelectors'


/*** State Selectors */

const agencyStateSelector = state => state.Agency
const sessionSelector = session => session

export const agencyCurrentIdSelector = createSelector(
  agencyStateSelector,
  agency => agency ? agency.agencyId : null
)

export const agencySavedSelector = createSelector(
  agencyStateSelector,
  agency => agency ? agency.saved : {}
)

export const agencySavingSelector = createSelector(
  agencyStateSelector,
  agency => agency ? agency.saving : {}
)

export const agencyIds = ormSelector(
  ({ Agency, }) => {
    return Agency.all().toRefArray().map(a => a.AgencyId)
  }
)

export const userCreatedAgency = ormSelector(
  agencyCurrentIdSelector,
  userCanEditAnyAgency,
  userNameSelector,
  ({ Agency, }, AgencyId, isAdmin, userName) => {
    // if the user doesn't have a name, stop here
    if (!userName) {
      return false
    }
    // if there's no active agency id, they must be creating one
    if (!AgencyId || (AgencyId < 1)) {
      return true
    }
    // if we have an agency id and we don't have an agency record
    // then we'll wait for it to load
    if (!Agency.idExists(AgencyId)) {
      return false
    }
    // if it's a dnr user they can edit anything
    if (isAdmin) {
      return true
    }
    // if the agency is created by the user, they can edit
    const agency = Agency.withId(AgencyId)
    if (agency.CreateBy === userName) {
      return true
    }
    
    // otherwise return false
    return false
  }
)

/**
 * Selector for AgencyContainer, uses current state.agency.agencyId
 * to select an Agency with related information.
 * @returns {object} the currently selected Agency
 */
export const currentAgencySelector = ormByIdSelector(
  agencyCurrentIdSelector,
  ({ Agency, }, AgencyId) => {
    if (isNaN(AgencyId)) {
      return null
    }
    if (!Agency.idExists(AgencyId)) {
      return null
    }
    const agency = Agency.withId(AgencyId)
    return {
      AgencyId           : agency.AgencyId,
      CreateDate         : agency.CreateDate || '',
      CreateBy           : agency.CreateBy || '',
      UpdateDate         : agency.UpdateDate || '',
      UpdateBy           : agency.UpdateBy || '',
      AgencyName         : agency.AgencyName || '',
      AgencySubName1     : agency.AgencySubName1 || '',
      AgencySubName2     : agency.AgencySubName2 || '',
      AgencyOtherName    : agency.AgencyOtherName || '',
      AgencyAbbreviation : agency.AgencyAbbreviation || '',
      AutoRegionApproval : agency.AutoRegionApproval || false,
      PaysByVoucher      : agency.PaysByVoucher || false,
      AddressId          : agency.AddressId || '',
      EmailId            : agency.EmailId || '',
      PhoneId            : agency.PhoneId || '',
      Address            : agency.Address ? {
        AddressId      : agency.Address.AddressId,
        StreetLine1    : agency.Address.StreetLine1,
        StreetLine2    : agency.Address.StreetLine2 || '',
        StreetLine3    : agency.Address.StreetLine3 || '',
        AddressCity    : agency.Address.AddressCity,
        AddressState   : agency.Address.AddressState,
        AddressZipCode : agency.Address.AddressZipCode,
        AddressCountry : agency.Address.AddressCountry || '',
        AddressTypeId  : agency.Address.AddressTypeId,
        CountyId       : agency.Address.CountyId,
        CreateBy       : agency.Address.CreateBy,
        CreateDate     : agency.Address.CreateDate,
        UpdateBy       : agency.Address.UpdateBy,
        UpdateDate     : agency.Address.UpdateDate,
      } : null,
      Phone: agency.Phone ? {
        PhoneId        : agency.Phone.PhoneId,
        PhoneNumber    : agency.Phone.PhoneNumber,
        PhoneExtension : agency.Phone.PhoneExtension || '',
        PhoneTypeId    : agency.Phone.PhoneTypeId,
        CreateBy       : agency.Phone.CreateBy,
        CreateDate     : agency.Phone.CreateDate,
        UpdateBy       : agency.Phone.UpdateBy,
        UpdateDate     : agency.Phone.UpdateDate,
      } : null,
      Email: agency.Email ? {
        EmailId      : agency.Email.EmailId,
        EmailAddress : agency.Email.EmailAddress,
        CreateBy     : agency.Email.CreateBy,
        CreateDate   : agency.Email.CreateDate,
        UpdateBy     : agency.Email.UpdateBy,
        UpdateDate   : agency.Email.UpdateDate,
      } : null,
    }
  }
)

/**
 * Selector for AgencyListContainer, grabs a small amount of
 * information to display. Should match the DataTable columns
 * defined in the Agency model
 */
export const agenciesForTableSelector = ormSelector(
  ({ Agency, }) => {
    return Agency.all().toModelArray().map((agency) => {
      return {
        AgencyId           : agency.AgencyId,
        AgencyName         : agency.AgencyName || '',
        AgencyAbbreviation : agency.AgencyAbbreviation,
        AutoRegionApproval : agency.AutoRegionApproval,
        PaysByVoucher      : agency.PaysByVoucher,
        Phone              : agency.Phone ? agency.Phone.PhoneNumber || '' : '',
        Email              : agency.Email ? agency.Email.EmailAddress || '' : '',
      }
    })
  }
)


/**
 * Selector for ApplicantInfoSection, select a minimal amount
 * of information about all agences to populate a dropdown.
 */
export const agenciesForSelectSelector = ormWithPropSelector(
  ({ Agency, }, filter) => {
    const dirs = Agency.all()
    if (dirs) {
      let models = dirs.toModelArray()
      if (typeof filter === 'function') {
        models = models.filter(filter)
      }
      return models.map((a) => {
        return {
          Value : a.AgencyId,
          Text  : `${a.AgencyId}: ${a.toString()}`,
        }
      })
    }
    return []
  }
)

/**
 * Simplified Agency Select by Id selector - only return agency.
 * @param {number} AgencyId the agency Id to select.
 * @returns {object} the requested Agency with related information.
 */
export const agencySimpleSelector = ormByIdSelector(
  ({ Agency, }, AgencyId) => {
    if (isNaN(AgencyId)) {
      return null
    }
    if (!Agency.idExists(AgencyId)) {
      return null
    }
    const agency = Agency.withId(AgencyId)
    return {
      AgencyId           : agency.AgencyId,
      CreateDate         : agency.CreateDate,
      CreateBy           : agency.CreateBy,
      UpdateDate         : agency.UpdateDate,
      UpdateBy           : agency.UpdateBy,
      AgencyName         : agency.AgencyName,
      AgencySubName1     : agency.AgencySubName1,
      AgencySubName2     : agency.AgencySubName2,
      AgencyOtherName    : agency.AgencyOtherName,
      AutoRegionApproval : agency.AutoRegionApproval,
      PaysByVoucher      : agency.PaysByVoucher,
      AgencyAbbreviation : agency.AgencyAbbreviation,
      AddressId          : agency.AddressId,
      PhoneId            : agency.PhoneId,
      EmailId            : agency.EmailId,
    }
  }
)

export const allAgencies = createSelector(
  sessionSelector,
  ({ Agency, }) => {
    // Exclude previously merged Agencies
    // Merged records shouldn't be returned from the API, but just in case
    const agencies = Agency.filter(p => { return !p.ParentId }).all()
    return agencies
  }
)

export const allAgenciesForMerge = ormSelector(
  session => {
    const agencies = allAgencies(session)
    if (agencies.exists()) {
      return agencies
        .toModelArray()
        .map(a => {
          let agencyData = {
            AgencyId: {
              Value    : a.AgencyId,
              Label    : 'Agency ID',
              Required : true,
            },
            AgencyName: {
              Value    : a.AgencyName,
              Label    : 'Agency Name',
              Required : true,
            },
            AgencySubName1: {
              Value : a.AgencySubName1,
              Label : 'Agency SubName 1',
            },
            AgencySubName2: {
              Value : a.AgencySubName2,
              Label : 'Agency SubName 2',
            },
            AgencyOtherName: {
              Value : a.AgencyOtherName,
              Label : 'Agency Other Name',
            },
            AgencyAbbreviation: {
              Value : a.AgencyAbbreviation,
              Label : 'Agency Abbreviation',
            },
            AutoRegionApproval: {
              Value : a.AutoRegionApproval,
              Text  : a.AutoRegionApproval ? 'Yes': 'No',
              Label : 'Auto Region Approval',
            },
            AddressId: {
              Value : a.AddressId,
              Text  : a.Address ? a.Address.toString() : '',
              Label : 'Address',
            },
            PhoneId: {
              Value : a.PhoneId,
              Text  : a.Phone ? a.Phone.toString() : '',
              Label : 'Phone',
            },
            EmailId: {
              Value : a.EmailId,
              Text  : a.Email ? a.Email.EmailAddress : '',
              Label : 'Email',
            },
          }
          return agencyData
        })
    }
    return []
  }
)

export const agencyForMerge = ormByIdSelector(
  allAgenciesForMerge,
  ({ Agency, }, agencyId, allAgencies) => {
    if (isNaN(agencyId)) {
      return null
    }
    const agency = Agency.withId(agencyId)
    if (!agency) {
      return null
    }
    const agencyObj = allAgencies.filter(a => a.AgencyId.Value === agencyId)[0]
    if (!agencyObj) {
      return null
    }
    return agencyObj
  }
)

export const agenciesForMergeSelect = ormSelector(
  session => {
    const agencies = allAgencies(session)
    if (agencies.exists()) {
      return agencies
        .orderBy('AgencyName')
        .toRefArray()
        .map(a => {
          return {
            Value : a.AgencyId,
            Text  : `${a.AgencyName} ${a.AgencyAbbreviation ? `(${a.AgencyAbbreviation})` : ''}`,
          }
        })
    }
    return []
  }
)

export const agencyPaysByVoucherByPermitId = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.Agency) {
      return false
    }
    return permit.Agency.PaysByVoucher || false
  }
)

export const agencyAutoRegionApprovalByPermitId = ormByIdSelector(
  ({ BurnPermit, }, BurnPermitId) => {
    const permit = BurnPermit.withId(BurnPermitId)
    if (!permit || !permit.Agency) {
      return false
    }
    return permit.Agency.AutoRegionApproval || false
  }
)
