import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'

export default class BurnPermitSiteEquipmentXref extends Model {
  static get modelName () {
    return 'BurnPermitSiteEquipmentXref'
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitSiteEquipmentXrefId',
    }
  }

  static validationSchema (props) {
    const { idx, } = props
    return Yup.object().shape({
      [`ShowEquipment_${idx}`] : Yup.bool().required('You must choose \'Yes\' or \'No\''),
      [`ShowOther_${idx}`]     : Yup.bool(),
      EquipmentTypeIds         : Yup.array()
        .when(`ShowEquipment_${idx}`, {
          is   : true,
          then : Yup.array()
            .min(1, 'You must choose one or more Equipment types')
            .required('You must choose one or more Equipment types'),
        }),
      OnsiteFlag                  : Yup.bool(),
      OffsiteFlag                 : Yup.bool(),
      FireLineConstructionFlag    : Yup.bool(),
      ExtinguishingFlag           : Yup.bool(),
      OffsiteEquipmentArrivalTime : Yup.string()
        .when([ `ShowEquipment_${idx}`, 'OffsiteFlag', 'FireLineConstructionFlag', ], {
          is   : (show, offsite, fire) => show && offsite && fire,
          then : Yup.string()
            .ensure()
            .required('You must choose an Arrival Time for off site equipment'),
        }),
      NumberOfGallons: Yup.number()
        .nullable()
        .when([ `ShowEquipment_${idx}`, 'ExtinguishingFlag', ], {
          is   : (show, ext) => show && ext,
          then : Yup.number()
            .min(1, 'You must enter a positive number of gallons available'), 
        }),
      OtherEquipmentDescription: Yup.string()
        .nullable()
        .when(`ShowOther_${idx}`, {
          is: show => {
            return show
          },
          then: Yup.string().required('You must enter a description of the equipment'),
        }),
    })
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitSiteEquipmentXrefId : attr(),
      BurnPermitSiteId              : attr(),
      EquipmentTypeId               : fk({ to: 'EquipmentType', relatedName: 'BurnPermitSiteEquipmentXrefs', }),
      OnsiteFlag                    : attr(),
      OffsiteFlag                   : attr(),
      FireLineConstructionFlag      : attr(),
      ExtinguishingFlag             : attr(),
      OffsiteEquipmentArrivalTime   : attr(),
      NumberOfGallons               : attr(),
      OtherEquipmentDescription     : attr(),
      
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}