// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { FormGroup, Label, Row, Col, Button, } from 'reactstrap'
import { Formik, Field, } from 'formik'
import { object, bool, func, } from 'prop-types'
import { isEmpty, } from 'lodash'

// Reducres
import ApiActions from '../../redux/ApiRedux'

// Models
import Email from '../../models/Email'

// Components
import AuditData from '../AuditData'
import ValidatingField from '../FormControls/ValidatingField'


class EmailForm extends React.Component {
  static propTypes = {
    Create          : func,
    Update          : func,
    email           : object,
    readOnly        : bool,
    createFn        : func,
    updateFn        : func,
    deleteFn        : func,
    showDelete      : bool,
    showAuditData   : bool,
    isEmailRequired : bool,
  }

  static defaultProps = {
    readOnly        : false,
    createFn        : null,
    deleteFn        : null,
    showDelete      : false,
    showAuditData   : false,
    isEmailRequired : true,
  }

  touched = () => {
    if (this.formik && this.formik && this.formik.touched) {
      return Object.values(this.formik.touched).some(t => t === true)
    }
    
    // unable to check if touched, just return true
    return true
  }

  validate = async () => {
    return this.formik.validateForm()
  }

  submit = () => {
    this.formik.submitForm()
  }

  /**
   * Return the current EmailAddress value
   */
  getEmailAddress = () => {
    const values = this.formik.values
    if (values && values.EmailAddress) {
      return values.EmailAddress
    }
    return null
  }

  formikSubmit = (values) => {
    const { EmailAddress, } = values
    const { email, Create, Update, } = this.props
    let emailToSubmit = { EmailAddress, }
    
    let create = (!email || isNaN(email.EmailId) || (email.EmailId < 1))

    if (create) {
      if (typeof this.props.createFn === 'function') {
        // if we have a custom create function, use it
        this.props.createFn(emailToSubmit)
      }
      else {
        Create('Email', email.EmailId, emailToSubmit, 'Emails')
      }
    }
    else {
      // add the email ID to what's being submitted
      emailToSubmit['EmailId'] = email.EmailId
      if (typeof this.props.updateFn === 'function') {
        // if we have a custom update function, use it
        this.props.updateFn(emailToSubmit)
      }
      else {
        Update('Email', emailToSubmit)
      }
    }
  }

  setFormikNode = node => this.formik = node

  onDeleteClick = (e) => {
    e.preventDefault()
    if (this.props.email.EmailId && this.props.email.EmailId > 0){
      if (this.props.deleteFn) {
        this.props.deleteFn()
      }
    } else {
      this.formik.setValues({ EmailAddress: '', })
    }
  }

  render () {
    // isEmailRequired checks to see what form we are in and remove .required, any Form that is not the PersonForm will .require validation
    const { email, readOnly, showAuditData, isEmailRequired, showDelete, } = this.props
    const emailFieldSize = {
      xs : showDelete ? 11: 12,
      sm : showDelete ? 11 : 12,
      md : showDelete ? 9 : 12,
      xl : showDelete ? 10 : 12,
    }
    return (
      <Formik
        initialValues={{
          EmailId      : email ? email.EmailId || '' : '',
          EmailAddress : email ? email.EmailAddress || '' : '',
        }}
        enableReinitialize={true}
        validationSchema={readOnly !== true ? Email.getValidationSchema(isEmailRequired) : null}
        onSubmit={this.formikSubmit}
        innerRef={this.setFormikNode}
      >
        <>
          <FormGroup row>
            <Col {...emailFieldSize}>
              <Label for={'email-address'}>Email Address</Label>
              <Field
                id={'email-address'}
                name={'EmailAddress'}
                readOnly={readOnly}
                component={ValidatingField}
              />
            </Col>
            {
              this.props.showDelete &&
                <Col xs={12-emailFieldSize.xs} sm={12-emailFieldSize.sm} md={12-emailFieldSize.md} xl={12-emailFieldSize.xl}>
                  <Button 
                    disabled={!email || !email.EmailId || email.EmailId < 1}
                    color={'danger'} 
                    size={'sm'} 
                    onClick={this.props.deleteFn} 
                    title={'Delete Email Address'}
                    className={'float-right'}
                  >Delete</Button>
                </Col>
            }
          </FormGroup>
          {
            showAuditData === true && !isEmpty(email) &&
            <Row className={'audit-data p-2'}>
              <AuditData
                CreateBy={email.CreateBy}
                CreateDate={email.CreateDate}
                UpdateBy={email.UpdateBy}
                UpdateDate={email.UpdateDate}
              />
            </Row>
          }
        </>
      </Formik>  
    )
  }
}

const mapDispatchToProps = {
  Create : ApiActions.createRecord,
  Update : ApiActions.updateRecord,
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true, })(EmailForm)