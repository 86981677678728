import React, { useMemo, } from 'react'
import { useSelector, } from 'react-redux'

// Components
import NavbarItem from '../NavbarItem'

// Selectors
import { isNavbarOpen, } from '../../../selectors/uiSelectors'


const Help = () => {
  // Selectors
  const navbarOpen = useSelector(isNavbarOpen)

  // UI Callbacks
  const itemText = useMemo(() => {
    if (!navbarOpen) {
      return null
    }
    return <span className={'d-inline-block d-lg-none mr-2'}>Help</span>
  }, [ navbarOpen, ])

  const markup = <NavbarItem
    to={'/help'}
    title={'Help'}
  >
    <>
      {itemText}
      <span className={'far fa-question-circle'}/>
    </>
  </NavbarItem>
  return markup
}

export default React.memo(Help)
