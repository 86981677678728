// Libraries
import React from 'react'
import { Toast, ToastBody, } from 'reactstrap'
import { func, } from 'prop-types'


export const ReloadApp = ({ onClick, }) => {
  return <Toast className={'reload-toast'}>
    <ToastBody>
      <span className={'fa fa-shield-alt mr-4 text-success'}></span>
      New version available! Refresh to install.
      <button
        type={'button'}
        className={'ml-4 mb-1 close'}
        data-dismiss={'toast'}
        aria-label={'Close'}
        onClick={onClick}
      >
        Refresh
      </button>
    </ToastBody>
  </Toast>
}

ReloadApp.propTypes = {
  onClick: func,
}

ReloadApp.defaultProps = {
  onClick: function () {
    window.location = window.location.origin
  },
}

export default ReloadApp