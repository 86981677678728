import React from 'react'
import { string, object, } from 'prop-types'

export const SuccessIcon = props => {
  const { className, style, title, } = props
  let cssClasses = 'fa fa-check-circle text-success'
  if (className) {
    cssClasses += ` ${cssClasses}`
  }
  return <span
    className={cssClasses}
    style={{ ...style, }}
    title={title}
  />
}

SuccessIcon.propTypes = {
  className : string,
  style     : object,
  title     : string,
}

SuccessIcon.defaultProps = {
  style : {},
  title : '',
}

export default SuccessIcon