// Libraries
import React from 'react'
import { useSelector, } from 'react-redux'
import { Link, } from 'react-router-dom'
import { Card, CardBody, CardHeader, } from 'reactstrap'

// Components
import PermitAppLink from './PermitAppLink'
import Instructions from '../Instructions'

// Selectors
import { signatureCallbackState, } from '../../selectors/burnPermitSignatureSelectors'
import { burnPermitNumberById, } from '../../selectors/burnPermitSelectors'


const PermitSigned = () => {

  // Selectors
  const { BurnPermitId, } = useSelector(signatureCallbackState)
  const BurnPermitNumber = useSelector(state => burnPermitNumberById(state, BurnPermitId))

  return <Card>
    <CardHeader>
      <div className={'d-flex justify-content-between'}>
        <h3>Successfully Signed Permit {BurnPermitNumber}!</h3>
        <PermitAppLink BurnPermitId={BurnPermitId}/>
      </div>
    </CardHeader>
    <CardBody>
      <Instructions />
    </CardBody>
    <div className={'d-flex align-items-end flex-column p-4'}>
      <Link
        to={`/burn-requests/new?burnPermitNumber=${BurnPermitNumber}`}
        className={'btn btn-lg btn-primary'}>
        Create Burn Request
      </Link>
    </div>
  </Card>
}

export default React.memo(PermitSigned)