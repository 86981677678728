import React from 'react'
import { object, } from 'prop-types'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Link, } from 'react-router-dom'
import { useDispatch, useSelector, } from 'react-redux'

// Redux
import UiActions from '../../redux/UiRedux'
import BurnPermitSignatureActions from '../../redux/BurnPermitSignatureRedux'

// Selectors
import { isModalOpen, } from '../../selectors/uiSelectors'
import { activeBurnPermitIdSelector, permitIsExtended, } from '../../selectors/burnPermitSelectors'


const PermitNextSteps = () => {

  const permitId = useSelector(activeBurnPermitIdSelector)
  const isOpen = useSelector(state => isModalOpen(state, 'PERMIT_NEXT_STEPS'))
  const isExtended = useSelector(state => permitIsExtended(state, permitId))

  const dispatch = useDispatch()

  const signPermit = React.useCallback(() =>
    dispatch(BurnPermitSignatureActions.signPermit(permitId))
  , [ permitId, dispatch, ])
  return <Modal isOpen={isOpen}>
    <ModalHeader tag={'h4'} toggle={() => dispatch(UiActions.closeModal())}>
      Next Steps: Sign your Permit
    </ModalHeader>
    <ModalBody>
      <p>Your Permit is being { isExtended ? 'Extended': 'Issued' }!</p>
      <p>
        Once you review any conditions set for your permit and sign your Permit in 
        DocuSign you can submit Burn Requests and burn with this permit!
      </p>
    </ModalBody>
    <ModalFooter className={'my-2 d-flex justify-content-between'}>
      <Link
        color={'link'}
        to={`/permits/${permitId}/conditions`}
      >
        Review Permit Conditions
      </Link>
      <Button
        color={'link'}
        onClick={signPermit}
      >
        Sign Permit Now
      </Button>
    </ModalFooter>
  </Modal>
}

PermitNextSteps.propTypes = {
  history  : object,
  location : object,
}

export default PermitNextSteps