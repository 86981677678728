// Libraries
import { put, all, call, select, fork, takeLatest, delay, } from 'redux-saga/effects'

// Sagas
import { getLookupData, doFetch, showError, extractPayload, apiResponseIsAuthorized, } from './ApiSagas'
import { showLoading, hideLoading, } from './AppSagas'
import { getBurnAreaInfo, } from './BurnPermitAreaSagas'
import { getBurnLocationInfo, } from './BurnPermitLocationSagas'
import { downloadBurnPermits, getBurnPermit, getBurnPermitStatusHistory, } from './BurnPermitSagas'
import { getBurnPermitSignatures, } from './BurnPermitSignatureSagas'
import { getBurnPermitSiteInfo, } from './BurnPermitSiteSagas'
import { getPerson, } from './PersonSagas'
import { downloadPostBurns, getPostBurnsByPermit, } from './PostBurnSagas'
import { upsertLocalModel, replaceAll, destroyLocalModel, createLocalModel, updateLocalModel, } from './OrmSagas'
import { getForestHealthData, } from './ForestHealthSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import { BurnRequestDetailTypes, } from '../redux/BurnRequestDetailRedux'
import { BurnRequestSearchTypes, } from '../redux/BurnRequestSearchRedux'
import BurnRequestListActions, { BurnRequestListTypes, } from '../redux/BurnRequestListRedux'
import { UiTypes, } from '../redux/UiRedux'

// Models
import BurnRequest from '../models/BurnRequest'
import BurnRequestSearch from '../models/BurnRequestSearch'
import BurnRequestStatusXref from '../models/BurnRequestStatusXref'

// Selectors
import { permitByBurnPermitNumberSelector, permitByIdSelector, networkStateSelector, } from '../selectors/selectors'
import { permitSummaryDataComplete, permitSearchByIdSelector, } from '../selectors/burnPermitSelectors'
import { getBurnRequestStatusIdByName, } from '../selectors/burnRequestSelectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'
import { userNameSelector, } from '../selectors/userSelectors'
import { lastRouteSelector, } from '../selectors/appSelectors'

// Utilities
import { objHasProp, } from '../utilities'
import { getUnsubmittedRequests, } from '../selectors/personSelectors'
import { PersonTypes, } from '../redux/PersonRedux'


const BURN_REQUEST_MODEL_NAME = BurnRequest.modelName
const BURN_REQUEST_ENDPOINT = BurnRequest.endpoint()
const BURN_REQUEST_DOWNLOAD_ENDPOINT = `${BURN_REQUEST_ENDPOINT}/Download/`
const BURN_REQUEST_SEARCH_MODEL = BurnRequestSearch.modelName


export function* getBurnPermitInfo ({ burnPermitNumber, burnPermitId, }) {
  try {
    if (!burnPermitNumber && !burnPermitId) {
      yield call(showError, 'You must supply a valid Burn Permit Number or Burn Permit ID.')
      return
    }

    if (!burnPermitId && burnPermitNumber) {
      const localBurnPermit = yield select(permitByBurnPermitNumberSelector, burnPermitNumber)
      if (localBurnPermit) {
        burnPermitId = localBurnPermit.BurnPermitId
      }
    }
    
    const alreadyHavePermitData = yield select(permitSummaryDataComplete, burnPermitId)
    if (alreadyHavePermitData) {
      return
    }
    
    // Get the associated burn permit
    yield call(getBurnPermit, { burnPermitId, burnPermitNumber, })

    // Get the associated burn permit
    let burnPermit

    if (burnPermitNumber) {
      burnPermit = yield select(permitByBurnPermitNumberSelector, burnPermitNumber)
    }
    else if (burnPermitId) {
      burnPermit = yield select(permitByIdSelector, burnPermitId)
    }

    if (!burnPermit) {
      yield call(showError, 'Could not find a Burn Permit.')
      return
    }

    // Get the IDs of the related objects we need some of the info for
    const {
      BurnerId,
      AgentId,
      BurnPermitAreaId,
      BurnPermitSiteId,
      BurnPermitLocationId,
      ForestHealthExemptId,
    } = burnPermit

    const sagas = [
      call(getBurnPermitStatusHistory, { burnPermitId: burnPermit.BurnPermitId, }),
      call(getBurnAreaInfo, { burnPermitAreaId: BurnPermitAreaId, }),
      call(getBurnPermitSiteInfo, { burnPermitSiteId: BurnPermitSiteId, }),
      call(getBurnPermitSignatures, { burnPermitId: burnPermit.BurnPermitId, }),
      call(getBurnLocationInfo, { burnPermitLocationId: BurnPermitLocationId, }),
    ]

    if (Number.isInteger(ForestHealthExemptId)){
      sagas.push(call(getForestHealthData, { forestHealthId: ForestHealthExemptId, }))
    }

    if (Number.isInteger(BurnerId)) {
      sagas.push(call(getPerson, { personId: BurnerId, getLookupData: false, }))
    }

    if (Number.isInteger(AgentId)) {
      // getPerson gets the agent xrefs, unlike get agent for some reason
      sagas.push(call(getPerson, { personId: AgentId, getLookupData: false, }))
    }

    // Call the API to get that info
    yield all(sagas)
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* getBurnRequestSuccess (resp) {
  try {
    if (!apiResponseIsAuthorized(resp)) {
      yield put({ type: ApiTypes.FAILURE, error: 'You are not authorized to access this Burn Request.', })
      return
    }
    const burnRequest = yield call(extractPayload, resp)
    let BurnRequestId, BurnPermitId
    if (!objHasProp(burnRequest, 'success')) {
      BurnRequestId = burnRequest.BurnRequestId
      BurnPermitId = burnRequest.BurnPermitId
      burnRequest.IsLocal = false
      yield call(upsertLocalModel, BURN_REQUEST_MODEL_NAME, burnRequest)

      if (resp.localId) {
        yield call(destroyLocalModel, BURN_REQUEST_MODEL_NAME, resp.localId)
      }
    } else if (objHasProp(resp, 'submittedRequest')) {
      BurnRequestId = resp.submittedRequest
    }

    if (BurnRequestId){
      yield fork(getBurnRequestStatusHistory, { burnRequestId: BurnRequestId, })
    }

    // If we don't have all the associated burn permit data, get it
    if (BurnPermitId){
      yield call(getBurnPermitInfo, { burnPermitId: BurnPermitId, })
      yield call(getPostBurnsByPermit, { permitId: BurnPermitId, })
    }

    if (BurnRequestId) {
      if (resp.redirect) {
        yield call(goToRequestId, BurnRequestId)
      }
    }
    if (resp.showSuccess) {
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield fork(hideBurnReqLoading)
  }
}

function* getBurnRequestLookupData () {
  yield all([
    call(getLookupData, { modelName: 'BurnRequestStatus', }),
    call(getLookupData, { modelName: 'BurnRequestCancellationReason', }),
  ])
  yield put({ type: ApiTypes.DONE_PREPARING_OFFLINE, })
}

export function* getBurnRequestStatusHistory ({ burnRequestId, } = {}) {
  try {
    yield fork(getBurnRequestLookupData)
    const resp = yield call(doFetch, BurnRequestStatusXref.endpoint(burnRequestId, true))

    if (!resp.ok) {
      yield call(showError, resp.responseBody || 'An error occurred attempting to fetch Burn Request Status History.')
      return
    }

    const requestStatuses = resp.responseBody
    
    if (Array.isArray(requestStatuses)) {
      let filter
      if (burnRequestId) {
        filter = { BurnRequestId: burnRequestId, }
      }
      yield call(replaceAll, BurnRequestStatusXref.modelName, requestStatuses, filter)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* createRequest ({ burnRequestInfo, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    yield fork(showLoading)

    let localId
    if (!online) {
      const model = { ...burnRequestInfo, }
      model.DaysOfWeek = burnRequestInfo.BurnRequestBurnDaysXref.map(x => x.DayOfWeekId)
      const { BurnRequestId, } = yield call(createLocalModel, BURN_REQUEST_MODEL_NAME, model)
      localId = BurnRequestId
      yield fork(upsertSearchRequestModel, localId, model)
      yield call(goToRequestId, localId)
      yield put({ type: BurnRequestListTypes.GET_MY_BURN_REQUESTS, })
    }

    yield put({
      type : 'OFFLINE/CREATE_BURN_REQUEST',
      meta : {
        offline: {
          effect: {
            url  : BURN_REQUEST_ENDPOINT,
            init : {
              method : 'POST',
              body   : burnRequestInfo,
            },
          },
          commit   : { type: 'GET_BURN_REQUEST_SUCCESS', showSuccess: online, redirect: true, localId, },
          rollback : { type: 'API_FAIL', },
        },
      },
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield fork(hideLoading)
  }
}
function* quickSubmitFailure ({ id, }) {
  const requests = yield select(getUnsubmittedRequests)
  const submittedRequest = requests.find(r => r.BurnRequestId == id)
  submittedRequest.Submitting = false
  submittedRequest.Submitted = false
  submittedRequest.Failed = true
  yield put({ type: PersonTypes.SET_UNSUBMITTED_REQUESTS, requests: [ ...requests, ], })
}

function* quickSubmitSuccess ({ showSuccess, id, }) {
  const requests = yield select(getUnsubmittedRequests)
  const submittedRequest = requests.find(r => r.BurnRequestId == id)
  submittedRequest.Submitting = false
  submittedRequest.Submitted = true
  submittedRequest.Failed = false
  yield put({ type: PersonTypes.SET_UNSUBMITTED_REQUESTS, requests: [ ...requests, ], })
  if (showSuccess) {
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
}

function* quickSubmitRequest ({ id, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    
    const requests = yield select(getUnsubmittedRequests)
    const newRequests = [ ...requests, ]
    const submittingRequest = newRequests.find(r => r.BurnRequestId == id)
    submittingRequest.Submitting = true
    submittingRequest.Submitted = false
    submittingRequest.Failed = false
    yield put({ type: PersonTypes.SET_UNSUBMITTED_REQUESTS, requests: newRequests, })


    let burnRequestUrl = `${BURN_REQUEST_ENDPOINT}/${id}/QuickSubmit`
    yield fork(showLoading)
    yield put({
      type : 'OFFLINE/UPDATE_BURN_REQUEST',
      meta : {
        offline: {
          effect: {
            url  : burnRequestUrl,
            init : {
              method: 'POST',
            },
          },
          commit   : { type: BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST_SUCCESS, showSuccess: online, id, },
          rollback : { type: BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST_FAILURE, id, },
        },
      },
    })

  } 
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield fork(hideLoading)
  }
}

function* saveRequest ({ burnRequestInfo, submit = false, }) {
  try {
    let burnRequestUrl = `${BURN_REQUEST_ENDPOINT}/${burnRequestInfo.BurnRequestId}`
    if (submit) {
      burnRequestUrl += '?submit=true'
    }

    const { online, } = yield select(networkStateSelector)
    yield fork(showLoading)

    const { BurnRequestId, } = burnRequestInfo
    const localBurnReq = yield select(modelByIdSelector, { modelName: BURN_REQUEST_MODEL_NAME, modelId: BurnRequestId, })
    if (!localBurnReq.IsLocal) {
      if (!online) {
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : 'OFFLINE/UPDATE_BURN_REQUEST',
          url         : burnRequestUrl,
          method      : 'PUT',
          keyName     : BurnRequest.options.idAttribute,
          keyValue    : BurnRequestId,
        })
      }
    }
    yield put({
      type : 'OFFLINE/UPDATE_BURN_REQUEST',
      meta : {
        offline: {
          effect: {
            url  : burnRequestUrl,
            init : {
              method : 'PUT',
              body   : burnRequestInfo,
            },
          },
          commit   : { type: 'GET_BURN_REQUEST_SUCCESS', showSuccess: online, },
          rollback : { type: 'API_FAIL', },
        },
      },
    })

    if (!online) {
      const model = { ...burnRequestInfo, }
      model.BurnDays = burnRequestInfo.BurnRequestBurnDaysXref.map(x => x.DayOfWeekId)
      yield call(updateLocalModel, BURN_REQUEST_MODEL_NAME, model)
      yield fork(upsertSearchRequestModel, model.BurnRequestId, model)
      yield put({ type: AppTypes.SHOW_SUCCESS, })
      yield put({ type: BurnRequestListTypes.GET_MY_BURN_REQUESTS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield fork(hideLoading)
  }
}

function* agencySubmitRequest ({ burnRequestInfo, }) {
  try {
    yield call(showLoading)
    const { online, } = yield select(networkStateSelector)
    const { BurnRequestId, } = burnRequestInfo
    const agencySubmitUrl = `${BURN_REQUEST_ENDPOINT}/${BurnRequestId}/Submit`

    const localBurnReq = yield select(modelByIdSelector, { modelName: BURN_REQUEST_MODEL_NAME, modelId: BurnRequestId, })
    if (localBurnReq && !localBurnReq.IsLocal) {
      if (!online) {
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : 'OFFLINE/AGENCY_SUBMIT_BURN_REQUEST',
          url         : agencySubmitUrl,
          method      : 'POST',
        })
      }
    }
    
    yield put({
      type : 'OFFLINE/AGENCY_SUBMIT_BURN_REQUEST',
      meta : {
        offline: {
          effect: {
            url  : agencySubmitUrl,
            init : { method: 'POST', },
          },
          commit   : { type: 'GET_BURN_REQUEST_SUCCESS', showSuccess: online, submittedRequest: BurnRequestId, },
          rollback : { type: 'API_FAIL', },
        },
      },
    })

    if (!online) {
      yield fork(saveRequest, burnRequestInfo)
      yield fork(submitLocalRequest, BurnRequestId)
      yield put({ type: AppTypes.SHOW_SUCCESS, })
      yield put({ type: BurnRequestListTypes.GET_MY_BURN_REQUESTS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* submitRequest ({ burnRequestInfo, }) {
  yield call(saveRequest, { burnRequestInfo, submit: true, })
  const { online, } = yield select(networkStateSelector)
  if (!online) {
    yield fork(submitLocalRequest, burnRequestInfo.BurnRequestId)
    yield put({ type: BurnRequestListTypes.GET_MY_BURN_REQUESTS, })
  }
}

function* submitLocalRequest (BurnRequestId) {
  yield fork(createLocalStatus, BurnRequestId, 'Submitted', '[SYSTEM] Your Burn Request Submission will be submitted once you regain an internet connection.')
}

function* createLocalStatus (BurnRequestId, StatusName, Comment) {
  const BurnRequestStatusId = yield select(getBurnRequestStatusIdByName, StatusName)
  const username = yield select(userNameSelector)
  const dateAsJson = new Date().toJSON()
  yield call(createLocalModel, BurnRequestStatusXref.modelName, {
    BurnRequestId,
    Comment,
    BurnRequestStatusId,
    StatusDate : dateAsJson,
    CreateBy   : username,
    CreateDate : dateAsJson,
  })
}

function* submitRequestStatus ({ burnRequestStatus, }) {
  try {

    yield call(showLoading)

    const { BurnRequestId, } = burnRequestStatus

    const burnRequestUrl = `${BURN_REQUEST_ENDPOINT}/${BurnRequestId}/RequestStatus`

    const requestObj = {
      method : 'POST',
      body   : burnRequestStatus,
    }

    const burnRequestResp = yield call(doFetch, burnRequestUrl, requestObj)

    if (burnRequestResp.ok !== true) {
      yield call(showError, burnRequestResp.responseBody.error || 'An error occurred recording the Burn Request Status.')
      return
    }

    yield put({ type: AppTypes.SHOW_SUCCESS, })
    yield fork(getBurnRequestStatusHistory, { burnRequestId: BurnRequestId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* deleteRequestStatus ({ burnRequestId, burnRequestStatusXrefId, }) {
  try {
    if (isNaN(parseInt(burnRequestId))) {
      yield call(showError, 'You must supply a Burn Request ID to delete a Request Status.')
      return
    }
    if (isNaN(parseInt(burnRequestStatusXrefId))) {
      yield call(showError, 'You must supply a Burn Request Status ID to delete a Request Status.')
      return
    }

    const putAppStatusUrl = `${BurnRequestStatusXref.deleteEndpoint(burnRequestId, burnRequestStatusXrefId)}`

    const applicationStatusResponse = yield call(doFetch, putAppStatusUrl, { method: 'DELETE', })

    if (applicationStatusResponse.ok !== true) {
      yield call(showError, 'An error occurred while attempting to delete the Burn Request Status.')
      return
    }

    yield call(destroyLocalModel, BurnRequestStatusXref.modelName, burnRequestStatusXrefId)
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* cancelBurnRequest ({ burnRequestInfo, }) {
  try {

    yield call(showLoading)

    const { BurnRequestId, } = burnRequestInfo

    const burnRequestUrl = `${BURN_REQUEST_ENDPOINT}/${BurnRequestId}/Cancel`
    
    yield call(showLoading)
    const { online, } = yield select(networkStateSelector)

    const localBurnReq = yield select(modelByIdSelector, { modelName: BURN_REQUEST_MODEL_NAME, modelId: BurnRequestId, })
    if (localBurnReq && !localBurnReq.IsLocal) {
      if (!online) {
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : 'OFFLINE/CANCEL_BURN_REQUEST',
          url         : burnRequestUrl,
          method      : 'PUT',
          keyName     : BurnRequest.options.idAttribute,
          keyValue    : BurnRequestId,
        })
      }
    }
    
    yield put({
      type : 'OFFLINE/CANCEL_BURN_REQUEST',
      meta : {
        offline: {
          effect: {
            url  : burnRequestUrl,
            init : { method: 'PUT', body: burnRequestInfo, },
          },
          commit   : { type: 'CANCEL_BURN_REQUEST_SUCCESS', requestId: BurnRequestId, showSuccess: online, },
          rollback : { type: 'API_FAIL', },
        },
      },
    })

    if (!online) {
      yield fork(createLocalStatus, BurnRequestId, 'Cancelled', '[SYSTEM] Your Burn Request Cancellation will be submitted once you regain an internet connection.')
      yield put({ type: AppTypes.SHOW_SUCCESS, })
      yield put({ type: BurnRequestListTypes.GET_MY_BURN_REQUESTS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: UiTypes.CLOSE_MODAL, })
    yield call(hideLoading)
  }
}

function* cancelBurnRequestSuccess (resp) {
  const success = yield call(extractPayload, resp)
  if (!success) {
    yield call(showError, 'An error occurred Cancelling a Burn Request')
    return
  }
  yield call(getBurnRequestStatusHistory, { burnRequestId: resp.requestId, })
}

/**
 * Delete a Burn Request object
 * @param {Object} id - The Burn Request ID
 */
function* deleteBurnRequest ({ id, }) {
  try {
    if (!id) {
      yield call(showError, 'Must supply a Burn Request ID to delete')
      return
    }
    const localBurnReq = yield select(modelByIdSelector, { modelName: BURN_REQUEST_MODEL_NAME, modelId: id, })
    if (!localBurnReq.IsLocal) {
      const url = `${BURN_REQUEST_ENDPOINT}/${id}`
      yield put({
        type : 'OFFLINE/DELETE_BURN_REQUEST',
        meta : {
          offline: {
            effect: {
              url  : url,
              init : { method: 'DELETE', },
            },
          },
        },
      })
    }
    
    yield put({
      type        : ApiTypes.CANCEL_SUBMIT,
      action_type : 'OFFLINE/CREATE_BURN_REQUEST',
      url         : BURN_REQUEST_ENDPOINT,
      method      : 'POST',
      keyName     : BurnRequest.options.idAttribute,
      keyValue    : parseInt(id),
    })
    
    yield all([
      call(destroyLocalModel, BURN_REQUEST_MODEL_NAME, id),
      call(destroyLocalModel, BURN_REQUEST_SEARCH_MODEL, id),
      put({ type: AppTypes.SHOW_SUCCESS, }),
    ])
    const lastRoute = yield select(lastRouteSelector)
    yield put({ type: AppTypes.REDIRECT_TO, route: lastRoute || '/burn-requests', })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    // Whether successful or not, close the modal
    yield put({ type: UiTypes.CLOSE_MODAL, })
  }
}


function* searchBurnRequestsSuccess (resp) {
  const results = yield call(extractPayload, resp)
  if (Array.isArray(results)) {
    yield call(replaceAll, BurnRequestSearch.modelName, results)
  }
  yield call(hideLoading)
}

/**
 * Submits a bulk request for Burn Requests to enable offline access
 */
function* downloadBurnRequests () {
  try {
    // Update the list container so users can access any new requests that they may be assigned before downloading
    yield put(BurnRequestListActions.getMyBurnRequests())
    yield put({ type: BurnRequestListTypes.RESET_REQUESTS_DOWNLOAD_STATUS, })
    yield fork(getBurnRequestLookupData)
    yield call(downloadBurnPermits)
    yield put({ type: BurnRequestListTypes.SET_REQUESTS_DOWNLOAD_STATUS, target: 'BurnPermits', status: true, })
    
    const downloadResp = yield call(doFetch, BURN_REQUEST_DOWNLOAD_ENDPOINT)
    
    if (downloadResp.ok !== true) {
      yield call(showError, downloadResp.responseBody.error || 'An error occurred download Burn Requests')
      return
    }
    
    yield call(replaceAll, BURN_REQUEST_MODEL_NAME, downloadResp.responseBody)
    yield call(getBurnRequestStatusHistory)
    yield put({ type: BurnRequestListTypes.SET_REQUESTS_DOWNLOAD_STATUS, target: 'BurnRequests', status: true, })

    // do this last (try) to prevent the ORM from destroying the relationships
    yield call(downloadPostBurns)
    yield put({ type: BurnRequestListTypes.SET_REQUESTS_DOWNLOAD_STATUS, target: 'PostBurns', status: true, })

    yield delay(500)
    yield all([
      put({ type: UiTypes.CLOSE_MODAL, }),
      put({ type: BurnRequestListTypes.DONE_DOWNLOADING_BURN_REQUESTS, }),
    ])
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* showBurnReqLoading () {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    yield call(showLoading)
    yield put({ type: BurnRequestDetailTypes.LOADING_BURN_REQUEST, })
  }
}

function* hideBurnReqLoading () {
  const { online, } = yield select(networkStateSelector)
  if (online) {
    yield call(hideLoading)
    yield put({ type: BurnRequestDetailTypes.DONE_LOADING, })
  }
}

function* goToRequestId (id) {
  yield put({
    type  : AppTypes.REDIRECT_TO,
    route : `/burn-requests/${id}`,
  })
}

function* upsertSearchRequestModel (localId, model) {
  const { BurnPermitId, } = model
  const burnPermitSearch = yield select(permitSearchByIdSelector, BurnPermitId)
  const username = yield select(userNameSelector)
  yield fork(upsertLocalModel, BURN_REQUEST_SEARCH_MODEL, {
    BurnRequestId             : localId,
    HasPostBurn               : false,
    BurnPermitId              : BurnPermitId,
    BurnRequestDate           : model.BurnRequestDate,
    MultiDayBurn              : model.MultiDayBurn,
    BurnPriority              : model.BurnPriority,
    PlannedIgnitionDate       : model.PlannedIgnitionDate,
    PlannedIgnitionPeriod     : model.PlannedIgnitionPeriod,
    PlannedIgnitionTime       : model.PlannedIgnitionTime,
    TotalProposedBurnQuantity : model.TotalProposedBurnQuantity,
    ProposedBurnArea          : model.ProposedBurnArea,
    BurnRequestStatus         : '',
    BurnPermitNumber          : burnPermitSearch.BurnPermitNumber,
    Landowner                 : burnPermitSearch.Landowner,
    Agent                     : burnPermitSearch.Agent,
    BurnUnitName              : burnPermitSearch.BurnUnitName,
    TotalPermitTonnage        : burnPermitSearch.TotalPermitTonnage,
    BurnAcres                 : burnPermitSearch.BurnAcres,
    Latitude                  : burnPermitSearch.Latitude,
    Longitude                 : burnPermitSearch.Longitude,
    CreateDate                : new Date().toJSON(),
    CreateBy                  : username,
  })
}


function* overrideDecision ({ burnRequestId, comment, }) {
  try {
    const overrideUrl = `${BURN_REQUEST_ENDPOINT}/${burnRequestId}/Override`

    const overrideResp = yield call(doFetch, overrideUrl, { method: 'PUT', body: { Comment: comment, }, })

    if (overrideResp.ok !== true) {
      let error = 'An error occurred while attempting to override the Burn Request Status.'
      if (overrideResp.responseBody && overrideResp.responseBody.error) {
        error = overrideResp.responseBody.error
      }
      yield call(showError, error)
      return
    }

    yield call(getBurnRequestStatusHistory, { burnRequestId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: UiTypes.CLOSE_MODAL, })
  }
}


export const BurnRequestDetailSagas = [
  takeLatest(BurnRequestSearchTypes.SEARCH_BURN_REQUESTS_SUCCESS, searchBurnRequestsSuccess),
  takeLatest(BurnRequestDetailTypes.GET_BURN_REQUEST_INFO, showBurnReqLoading),
  takeLatest(BurnRequestDetailTypes.GET_BURN_REQUEST_SUCCESS, getBurnRequestSuccess),
  takeLatest(BurnRequestDetailTypes.GET_BURN_PERMIT_INFO, getBurnPermitInfo),
  takeLatest(BurnRequestDetailTypes.GET_BURN_REQUEST_STATUS_HISTORY, getBurnRequestStatusHistory),
  takeLatest(BurnRequestDetailTypes.CREATE_REQUEST, createRequest),
  takeLatest(BurnRequestDetailTypes.SAVE_REQUEST, saveRequest),
  takeLatest(BurnRequestDetailTypes.SUBMIT_REQUEST, submitRequest),
  takeLatest(BurnRequestDetailTypes.DELETE_BURN_REQUEST, deleteBurnRequest),
  takeLatest(BurnRequestDetailTypes.SUBMIT_REQUEST_STATUS, submitRequestStatus),
  takeLatest(BurnRequestDetailTypes.DELETE_REQUEST_STATUS, deleteRequestStatus),
  takeLatest(BurnRequestDetailTypes.CANCEL_BURN_REQUEST, cancelBurnRequest),
  takeLatest(BurnRequestDetailTypes.CANCEL_BURN_REQUEST_SUCCESS, cancelBurnRequestSuccess),
  takeLatest(BurnRequestDetailTypes.AGENCY_SUBMIT_REQUEST, agencySubmitRequest),
  takeLatest(BurnRequestDetailTypes.OVERRIDE_DECISION, overrideDecision),
  takeLatest(BurnRequestListTypes.DOWNLOAD_REQUESTS, downloadBurnRequests),
  takeLatest(ApiTypes.DOWNLOAD_LOOKUP_DATA, getBurnRequestLookupData),
  takeLatest(BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST, quickSubmitRequest),
  takeLatest(BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST_FAILURE, quickSubmitFailure),
  takeLatest(BurnRequestDetailTypes.QUICK_SUBMIT_REQUEST_SUCCESS, quickSubmitSuccess),
]