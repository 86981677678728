// Libraries
import React, { useEffect, useMemo, useRef, } from 'react'
import { render as ReactDOMRender, } from 'react-dom'
import { Link, useNavigate, } from 'react-router-dom'
import { Row, Col, Card, CardHeader, Button, CardBody, } from 'reactstrap'
import { bool, func, object, array, string, } from 'prop-types'
import { isEmpty, difference, } from 'lodash'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import Cart from '../PermitOrders/Cart'
import ESRIMap from '../ESRIMap'
import SimpleDataTable from '../SimpleTable'
import ExportPermits from '../ExportPermits'

// Actions
import BurnPermitListActions from '../../redux/BurnPermitListRedux'
import BurnPermitOrderActions from '../../redux/BurnPermitOrderRedux'

// Selectors
import { permitMapDataSelector, } from '../../selectors/burnPermitSelectors'

// Models
import BurnPermitSearch from '../../models/BurnPermitSearch'

// Utilities
import stopEvent from '../../utilities/stopEvent'

// Map Config
import { permitAction, } from '../../config/map/actions'

// Hooks
import { useCart, } from './useCart'

const addToCartClasses = 'fa-plus btn-success'
const removeFromCartClasses = 'fa-minus btn-danger'

const addToCartTitle = 'Add Application to Cart'
const removeFromCartTitle = 'Remove Application from Cart'

const toggleButtonState = (btn, reset = false) => {
  if (!btn) {
    return
  }
  btn.classList.remove('btn-danger')
  btn.classList.remove('fa-minus')
  btn.classList.remove('btn-success')
  btn.classList.remove('fa-plus')
  // PermitId is being added to the cart
  if (!reset) {
    btn.title = removeFromCartTitle
    btn.classList.add('btn-danger')
    btn.classList.add('fa-minus')
  }
  // PermitId is being removed from the cart
  else {
    btn.title = addToCartTitle
    btn.classList.add('btn-success')
    btn.classList.add('fa-plus')
  }
}

const defaultLayerObj = {}
const mapColStyle = { height: '530px', }

export const BurnPermitTableInner = ({
  permits, 
  enableCart = false,
  aboveTableContent,
  belowTableContent,
  showMap,
  showNewAppLink,
  showCardHeader = true,
  showLandownerAgent,
  isAuthenticated,
  cart, 
  cartActions,
  order,
  layerConfig,
}) => {
  const { columns, appIdColIdx, permitIdColIdx, } = React.useMemo(() =>  {
    const cols = BurnPermitSearch.getDataTablesColumns({ showLandownerAgent, enableCart, })
    return {
      columns        : cols,
      appIdColIdx    : cols.findIndex(c => c.title === 'Application Id'),
      permitIdColIdx : cols.findIndex(c => c.title === 'Order'),
    }
  }, [ enableCart, showLandownerAgent, ])
  
  const mapClass = useMemo(() => {
    let classString = 'map-container rounded'
    if (showMap) {
      classString += ' my-1'
    }
    else {
      classString += ' d-none'
    }
    return classString
  }, [ showMap, ])
  const reduxDispatch = useDispatch()
  const navigate = useNavigate()

  // Create a stable ref to the cart to use inside datatable render callbacks
  const cartItemsRef = useRef(cart.current)
  cartItemsRef.current = cart.current

  // Unmount effect
  useEffect(() => {
    return () => reduxDispatch(BurnPermitOrderActions.updateOrderPermitIds(null))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Permit map layer selector
  const permitMapLayer = useSelector((state) => {
    if (showMap) {
      const layerData = permitMapDataSelector(state, isAuthenticated)
      layerData.actions = [ permitAction.bind({ navigate, })(), ]
      return layerData
    }
    return defaultLayerObj
  })

  // Cart add/remove toggle callback
  const addToCart = React.useCallback((id) => {
    if (id) {
      reduxDispatch(BurnPermitOrderActions.getBurnPermitDetailForCart(id))
      cartActions.addToCart(id)
    }
  }, [ cartActions, reduxDispatch, ])

  // Cart add/remove toggle callback
  const onToggleItemInCart = React.useCallback((e) => {
    stopEvent(e)
    const cartRef = cartItemsRef
    if (cartRef && cartRef.current) {
      const permitId = parseInt(e.target.getAttribute('data-permit-id'))
      const permitIdIdx = cartRef.current.findIndex(id => id === permitId)
      // PermitId is being added to the cart
      if (permitIdIdx === -1) {
        // Call action to get burn permit info, site info, and burner/agent info
        addToCart(permitId)
      }
      else {
        cartActions.removeFromCart(permitId)
      }
    }
  }, [ addToCart, cartActions, ])

  // Update cart add/remove buttons when cart state changes
  React.useEffect(() => {
    // Skip effect if this is the first render (no previous cart data/value)
    if (!cart.previous) {
      return
    }

    const removedItems = difference(cart.previous, cart.current)
    for (let i = 0, len = removedItems.length; i < len; i++) {
      const permitId = removedItems[i]
      const btn = document.querySelector(`button[data-permit-id='${permitId}']`)
      toggleButtonState(btn, true)
    }
      
    const addedItems = difference(cart.current, cart.previous)
    for (let i = 0, len = addedItems.length; i < len; i++) {
      const permitId = addedItems[i]
      const btn = document.querySelector(`button[data-permit-id='${permitId}']`)
      toggleButtonState(btn)
    }
  }, [ cart, ])

  // When an order is created, navigate to payment
  React.useEffect(() => {
    if (order && order.BurnPermitOrderId) {
      navigate(`/permits/orders/${order.BurnPermitOrderId}/pay`)
    }
  }, [ navigate, order, ])


  const ToggleMap = React.useCallback(() => reduxDispatch(BurnPermitListActions.togglePermitMap()), [ reduxDispatch, ])

  // Callback for created permit ID cells
  const createdPermitIdCellCallback = React.useCallback((td, cellData, rowData) => {
    let cellToRender = td
    if (rowData.PermitOrderId) {
      cellToRender = ReactDOMRender(<a href={`/permits/orders/${rowData.PermitOrderId}`}
        className={'text-dark'}
        onClick={e => {
          stopEvent(e)
          navigate(`/permits/orders/${rowData.PermitOrderId}`)
        }}>{rowData.PermitOrderId}</a>, td)
    } else {
      const cartRef = cartItemsRef
      if (!rowData.BurnPermitNumber || (rowData.BurnPermitNumber && rowData.AgencyRegionApproval)) {
        let classes = addToCartClasses, title = addToCartClasses
        if (cartRef && cartRef.current && cartRef.current.indexOf(rowData.BurnPermitId) > -1) {
          classes = removeFromCartClasses
          title = removeFromCartTitle
        }
        cellToRender = ReactDOMRender(<button
          className={`btn btn-sm add-permit-to-order fa ${classes}`}
          title={title}
          onClick={onToggleItemInCart}
          data-permit-id={cellData}
        />, td)
      } else {
        cellToRender = ReactDOMRender('N/A', td)
      }
    }
    return cellToRender
  }, [ navigate, onToggleItemInCart, ])


  // Callback for created application ID cells
  const createdAppIdCellCallback = React.useCallback((td, cellData) => {
    return ReactDOMRender(
      <a href={`/permits/${cellData}`}
        className={'text-dark'}
        onClick={e => {
          stopEvent(e)
          navigate(`/permits/${cellData}`)
        }}>{cellData}</a>
      , td)
  }, [ navigate, ])


  const dtConfig = React.useMemo(() => {
    return {
      columns,
      columnDefs: [
        {
          targets     : permitIdColIdx,
          createdCell : createdPermitIdCellCallback,
        },
        {
          targets     : appIdColIdx,
          createdCell : createdAppIdCellCallback,
        },
      ],
      data           : permits,
      scrollY        : '60vh',
      scrollCollapse : true,
      order          : [],
      rowCallback    : (row) => row.classList.add('no-click'),
      buttons        : [ 'colvis', 'excel', 'print', 'csv', 'pdf', ],
    }
  }, [ appIdColIdx, columns, createdAppIdCellCallback, createdPermitIdCellCallback, permitIdColIdx, permits, ])

  const mapBtnText = useMemo(() => {
    let btnText = 'Show Map'
    if (showMap) {
      btnText = 'Hide Map'
    }
    return btnText
  }, [ showMap, ])

  const mapData = useMemo(() => {
    let data = []
    if (!isEmpty(permitMapLayer)) {
      data = [ permitMapLayer, ]
    }
    return data
  }, [ permitMapLayer, ])

  return <>
    <Card className={'w-100'}>
      { showCardHeader && <CardHeader className={'d-flex justify-content-between permit-table-card-header'}>
        <>
          { showNewAppLink && <Link className={'text-dark'} to={'/permits/new'}>New Application</Link> }
          <Button color={'light'} size={'sm'} onClick={ToggleMap}>{mapBtnText}</Button>
        </>
      </CardHeader>
      }
      <CardBody className={'pt-0 px-2'}>
        {aboveTableContent}
        <Row className={mapClass}>
          <Col style={mapColStyle}>
            <ESRIMap
              config={layerConfig}
              mapData={mapData}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SimpleDataTable config={dtConfig} elementId={'permit-list-table'} />
          </Col>
        </Row>
        {belowTableContent}
      </CardBody>
    </Card>
  </>
}

BurnPermitTableInner.propTypes = {
  showLandownerAgent : bool,
  enableCart         : bool,
  showMap            : bool,
  showNewAppLink     : bool,
  showCardHeader     : bool,
  permits            : array,
  aboveCardContent   : object,
  aboveTableContent  : object,
  belowTableContent  : object,
  title              : string,
  isAuthenticated    : bool,
  showExport         : bool,
  order              : object,
  cartActions        : object,
  cart               : object,
  layerConfig        : string,
}

 

const BurnPermitTable = ({
  permits, 
  enableCart = false,
  aboveCardContent,
  belowTableContent,
  title,
  showMap,
  showNewAppLink,
  showCardHeader = true,
  showLandownerAgent,
  isAuthenticated,
  showExport,
  layerConfig,
}) => { 
  const [ cart, cartActions, order, ] = useCart()

  // Create a stable ref to the cart to use inside datatable render callbacks
  const cartItemsRef = useRef(cart.current)
  cartItemsRef.current = cart.current

  return <>
    <div className={'d-flex justify-content-between mb-2'}>
      <h1>{title}</h1>
      <div className={'d-flex justify-content-between'}>
        { showExport && <ExportPermits />}
        { enableCart && <Cart cart={cart} cartActions={cartActions} /> }
      </div>
    </div>
    {aboveCardContent}
    <BurnPermitTableInner 
      permits={permits} 
      enableCart={enableCart}
      belowTableContent={belowTableContent}
      showMap={showMap}
      showNewAppLink={showNewAppLink}
      showCardHeader={showCardHeader}
      showLandownerAgent={showLandownerAgent}
      isAuthenticated={isAuthenticated}
      cart={cart}
      cartActions={cartActions}
      order={order}
      layerConfig={layerConfig}
    />
  </>
}

BurnPermitTable.propTypes = {
  navigate           : func,
  showLandownerAgent : bool,
  enableCart         : bool,
  showMap            : bool,
  showNewAppLink     : bool,
  showCardHeader     : bool,
  permits            : array,
  aboveCardContent   : object,
  aboveTableContent  : object,
  belowTableContent  : object,
  title              : string,
  isAuthenticated    : bool,
  showExport         : bool,
  layerConfig        : string,
}

export default BurnPermitTable