// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEqual, } from 'lodash'
import { array, string, object, bool, func, number, } from 'prop-types'

import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-bs4'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.print'

import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'

// Components
import withRouter from './withRouter'

// Reducers
import ApiActions from '../redux/ApiRedux'

// Utilities
import stopEvent from '../utilities/stopEvent'

// Selectors
import { getUserToken, } from '../selectors/userSelectors'


class DataTable extends React.Component {
  static propTypes = {
    // from withRouter HOC
    navigate : func,
    location : object,

    elementId               : string.isRequired,
    hiddenCols              : array,
    columns                 : array,
    columnDefs              : array,
    records                 : array,
    clickRoute              : string,
    rowIdProp               : string,
    ajaxConfig              : object,
    onRowClick              : func,
    disableRowClick         : bool,
    disableDefaultOrdering  : bool,
    onDeleteRecordClicked   : func,
    UserToken               : string,
    ordering                : bool,
    pageLength              : number,
    disablePageLengthChange : bool,
    redraw                  : bool,
    scrollY                 : string,
    onAjaxError             : func,
    Failure                 : func,
    className               : string,
    autoWidth               : bool,
    enableExport            : bool,
  }

  static defaultProps = {
    hiddenCols              : [],
    ordering                : true,
    disableRowClick         : false,
    disableDefaultOrdering  : false,
    disablePageLengthChange : false,
    redraw                  : false,
    scrollY                 : '',
    onDeleteRecordClicked   : null,
    className               : '',
    autoWidth               : true,
    enableExport            : true,
  }

  state = {
    records           : [],
    emptyTableMessage : '',
  }

  dataTableInstance = null

  componentDidMount () {
    this.loadTable()
  }

  componentWillUnmount () {
    if (!this.dataTableInstance) {
      return
    }
    this.dataTableInstance.destroy(true)
    this.dataTableInstance = null
  }
  
  componentDidUpdate (prevProps) {
    if (typeof this.props.ajaxConfig === 'object' && !isEqual(prevProps.ajaxConfig, this.props.ajaxConfig)) {
      this.updateAjaxConfig()
    }
    const { records, hiddenCols, } = prevProps
    // Perform a cheap check to see if new records are available to draw
    const hasNewRecords = records && records.length !== this.props.records.length
    const hiddenColsChanged = hiddenCols && hiddenCols.length !== this.props.hiddenCols.length
    if (hasNewRecords || hiddenColsChanged) {
      this.redrawTable()
      return
    }
    // If the record count is the same, see if their properties have changed
    else if(!isEqual(records, this.props.records)) {
      this.redrawTable()
      return
    }
    if (this.dataTableInstance && prevProps.redraw !== true && this.props.redraw === true) {
      this.dataTableInstance.draw()
    }
  }

  redrawTable = () => {
    const { records, hiddenCols, } = this.props
    const table = this.dataTableInstance
    if (records) {
      table.clear()
      table.rows.add(records)
    }
    const dtCols = this.dataTableInstance.columns()
    for (let i = 0, colLen = dtCols.length; i < colLen; i++) {
      const col = this.dataTableInstance.column(dtCols[i])
      col.visible(!hiddenCols.includes(col.index()))
    }
    table.draw()
  }

  onTableRowClicked = param => {
    if (!this.props.disableRowClick) {
      if (typeof this.props.onRowClick !== 'function') {
        let { clickRoute, location, } = this.props
        let route = location.pathname

        if (clickRoute != null) {
          route = clickRoute
        }

        if (route.slice(-1) !== '/') {
          route += '/'
        }
        this.props.navigate(route + param)
      }
      else {
        this.props.onRowClick(param)
      }
    }
  }

  setUserToken = request => {
    request.setRequestHeader('Authorization', `bearer ${this.props.UserToken}`)
  }

  getCurrentAjaxData = () => {
    return this.props.ajaxConfig.data
  }

  updateAjaxConfig = dataTableConfig => {
    const { ajaxConfig, onAjaxError, } = this.props
    if (!ajaxConfig.url) {
      return dataTableConfig
    }

    ajaxConfig.beforeSend = this.setUserToken

    if (this.dataTableInstance) {
      const dtUrl = this.dataTableInstance.ajax.url()
      if (dtUrl && dtUrl != ajaxConfig.url) {
        this.dataTableInstance.ajax.url(ajaxConfig.url).load()
      } else if (dtUrl) {
        this.dataTableInstance.ajax.reload()
      } else {
        this.dataTableInstance.ajax.url(ajaxConfig).load()
      }
      return
    }

    dataTableConfig.ajax = ajaxConfig
    // Assume the data is at the root of the response
    dataTableConfig['ajax']['dataSrc'] = ajaxConfig.dataSrc || ''
    if (typeof onAjaxError === 'function') {
      dataTableConfig.ajax.error = (_jqxhr, _textStatus, _error) => {
        if (_textStatus !== 'abort') {
          onAjaxError(_jqxhr, _textStatus, _error)
        }
      }
    }
    else {
      // eslint-disable-next-line no-unused-vars
      dataTableConfig['ajax']['error'] = (_jqxhr, _textStatus, _error) => {
        if (_textStatus !== 'abort') {
          this.props.Failure('An error occurred requesting data to load in the table.')
        }
      }
    }
    return dataTableConfig
  }

  loadTable = () => {
    let { columnDefs, columns, hiddenCols, ordering, disablePageLengthChange, pageLength, } = this.props
    if (hiddenCols.length) {
      columns = columns.map((c, idx) => {
        if (hiddenCols.includes(idx)) {
          c.visible = false
        }
        return c
      })
    }
    let dataTableConfig = {
      dom      : '<\'row d-flex flex-direction-row\'<\'col-6 col-md-4\'l><\'col-md-4 table-buttons\'B><\'col-6 col-md-4\'f>>rtip',
      columns  : columns,
      rowId    : '_fields.id',
      language : { 
        search            : '_INPUT_',
        searchPlaceholder : 'Filter',
        emptyTable        : this.state.emptyTableMessage,
      },
      scrollX    : true,
      ordering   : ordering,
      autoWidth  : this.props.autoWidth,
      lengthMenu : [ [ 10, 25, 50, 100, -1, ], [ 10, 25, 50, 100, 'All', ], ],
      stateSave  : true,
    }

    if (this.props.enableExport) {
      dataTableConfig['buttons'] = [
        'colvis',
        'excel',
        'print',
        'csv',
        'pdf',
      ]
    } else {
      dataTableConfig['buttons'] = [
        'colvis',
      ]
    }

    if (columnDefs && Array.isArray(columnDefs)) {
      dataTableConfig.columnDefs = columnDefs
    }

    if (disablePageLengthChange) {
      dataTableConfig.lengthChange = false
      dataTableConfig.paging = false
      dataTableConfig.scrollCollapse = true
    }
    else {
      if (window.innerWidth < 768) {
        dataTableConfig.pagingType = 'full'
      }
    }
    if (this.props.scrollY) {
      dataTableConfig.scrollY = this.props.scrollY
      dataTableConfig.scrollCollapse = true
    }

    if (pageLength) {
      dataTableConfig.pageLength = pageLength
    }

    if (this.props.disableRowClick) {
      dataTableConfig['rowCallback'] = function (row) {
        row.classList.add('no-click')
      }
    }

    if (this.props.disableDefaultOrdering) {
      dataTableConfig['order'] = []
    }

    if (Array.isArray(this.props.records)) {
      dataTableConfig['data'] = this.props.records
    }

    if (typeof this.props.ajaxConfig === 'object') {
      dataTableConfig = this.updateAjaxConfig(dataTableConfig)
    }
    const $tableEl = $(this.Table)
    this.dataTableInstance = $tableEl.DataTable(dataTableConfig)
    if (!this.props.disableRowClick) {
      $tableEl.on('click', 'tr', (e) => {
        const rowData = this.dataTableInstance.row(e.target.parentNode).data()
        if (rowData) {
          const param = rowData[this.props.rowIdProp]
          this.onTableRowClicked(param)
        }
      })
    }
    if (this.props.onDeleteRecordClicked) {
      $tableEl.on('click', 'tr td.delete-record', evt => {
        stopEvent(evt)
        const data = this.dataTableInstance.row(evt.target.parentNode.parentNode).data()
        if (data) {
          this.props.onDeleteRecordClicked(data)
        }
      })
    }

    const filterInput = document.querySelector('input[type="search"]')
    if (filterInput) {
      filterInput.setAttribute('aria-label', 'Filter My Permits')
    }
  }

  setTableNode = node => this.Table = node

  render () {
    return (
      <table
        id={this.props.elementId}
        className={`table table-striped ${this.props.className} w-100`}
        ref={this.setTableNode}
      />
    )
  }
}

function mapStateToProps (state) {
  return {
    UserToken: getUserToken(state),
  }
}

const mapDispatchToProps = {
  Failure: ApiActions.failure,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable))
