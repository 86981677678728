import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitStatusXref extends Model {
  static get modelName () {
    return 'BurnPermitStatusXref'
  }

  toString () {
    return `${this.BurnPermitStatusXrefName}`
  }

  static endpoint (permitId, history = false) {
    const url = `BurnPermits/${permitId}/PermitStatus`
    if (history === false) {
      return url
    }
    return `${url}/History`
  }
  
  static deleteEndpoint (permitId, xrefId) {
    const url = `BurnPermits/${permitId}/PermitStatus/${xrefId}`
    return url
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitStatusXrefId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitStatusXrefId : attr(),
      BurnPermitId           : fk({ to: 'BurnPermit', relatedName: 'BurnPermitStatusXrefs', as: 'BurnPermit', }),
      BurnPermitStatusId     : fk({ to: 'BurnPermitStatus', relatedName: 'BurnPermitStatusXrefs', as: 'BurnPermitStatus', }),
      StatusDate             : attr(),
      Comment                : attr(),
      
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}