// Libraries
import { put, call, takeLatest, select, takeEvery, } from 'redux-saga/effects'

// Sagas
import { doFetch, showError, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'

// Reducers
import { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import { RuleTypes, } from '../redux/RulesRedux'

// Models
import Rule from '../models/Rule'
import { OrmTypes, } from '../redux/OrmRedux'
import { ruleSelector, } from '../selectors/ruleSelectors'

function* getRules () {
  try {
    yield call(showLoading)
    const endpoint = `${Rule.endpoint()}`
    const resp = yield call(doFetch, endpoint, { method: 'GET', })
    if (resp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred retrieving Rules.', })
      return
    }

    const rules = resp.responseBody
    let parsedRules = []
    if (Array.isArray(rules)) {
      parsedRules = rules.map((x) => {
        if (x.RuleType !== 'json-links'){
          return x
        }
        try {
          x.RuleValue = JSON.parse(x.RuleValue)
        } catch (e) {
          console.error('failed parsing json for rule ' + x.RuleId)
        }
        return x
      })
    }
    
    yield put({
      type      : OrmTypes.REPLACE_ALL,
      modelName : 'Rule',
      objects   : parsedRules,
    })

  } catch (error) {
    console.error('error: ', error)
    yield put({ type: ApiTypes.FAILURE, error: error.message, })
  } finally {
    yield put({ type: RuleTypes.RULES_LOADED, })
    yield call(hideLoading)
  }
}

function* sanitizeJSON (valueToClean) {
  if (!valueToClean) {
    return null
  }
  const cleaned = yield call([ JSON, JSON.stringify, ], valueToClean)
  return cleaned
}


function* updateRule ({ id, payload, }) {
  if (!id || !payload) {
    yield put({ type: ApiTypes.FAILURE, error: 'Required information was not proved to update a rule.', })
    return
  }
  try {
    yield call(showLoading)
    const endpoint = `${Rule.endpoint()}/${id}`

    const ruleToPut = {
      ...payload,
    }

    if (ruleToPut.RuleType === 'json-links') {
      ruleToPut.RuleValue = yield call(sanitizeJSON, ruleToPut.RuleValue)
    }

    const resp = yield call(doFetch, endpoint, { method: 'PUT', body: ruleToPut, })
    if (resp.ok !== true) {
      yield put({ type: ApiTypes.FAILURE, error: 'An error occurred updating the Rule.', })
      yield put({ type: RuleTypes.RULE_UPDATE_FAILED, id, })
      return
    }

    const ruleToUpsert = yield select(ruleSelector, id)
    if (!ruleToUpsert) {
      yield put({ type: ApiTypes.FAILURE, error: 'Your changes were saved but an error has occurred. Please reload the page before continuing.', })
      yield put({ type: RuleTypes.RULE_UPDATE_FAILED, id, })
      return
    }

    const updateObj = {
      RuleDescription : payload.RuleDescription,
      RuleValue       : payload.RuleValue,
      RuleEnabled     : payload.RuleEnabled,
    }

    yield put({ 
      type      : OrmTypes.UPDATE,
      modelName : 'Rule',
      modelId   : id,
      updateObj,
    })
    yield put({ type: RuleTypes.RULE_UPDATED, id, })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  } catch (error) {
    yield put({ type: RuleTypes.RULE_UPDATE_FAILED, id, })
    yield call(showError, error)
  } finally {
    yield call(hideLoading)
  }
}

export const RulesSagas = [
  takeLatest(RuleTypes.GET_RULES, getRules),
  takeEvery(RuleTypes.UPDATE_RULE, updateRule),
]