import { attr, Model, } from 'redux-orm'

export default class LocationQuarter extends Model {
  static get modelName () {
    return 'LocationQuarter'
  }

  toString () {
    return `${this.LocationQuarterName}`
  }
  
  endpoint = () => {
    return 'LocationQuarters'
  }

  static get options () {
    return {
      idAttribute: 'LocationQuarterId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      LocationQuarterId          : attr(),
      CreateDate                 : attr(),
      UpdateDate                 : attr(),
      // Model-Specific Properties
      LocationQuarterName        : attr(),
      LocationQuarterDescription : attr(),

      // Relationships
    }
  }
}