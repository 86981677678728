import { attr, Model, } from 'redux-orm'

export default class DaysOfWeek extends Model {
  static get modelName () {
    return 'DaysOfWeek'
  }

  toString () {
    return `${this.DayOfWeekName}`
  }

  static endpoint () {
    return 'DaysOfWeek'
  }

  static get options () {
    return {
      idAttribute: 'DayOfWeekId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      DayOfWeekId   : attr({ type: 'number', }),
      CreateDate    : attr(),
      UpdateDate    : attr(),
      // Model-Specific Properties
      DayOfWeekName : attr(),

      // Relationships
    }
  }
}