// Libraries
import React, { useMemo, useCallback, } from 'react'
import { number, bool, array, } from 'prop-types'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import MustCalculate from './Alerts/MustCalculate'
import DataTable from '../../DataTable'
import { CalculateButton, } from '../../FormControls'

// Reducers
import BurnPermitPileGroupActions from '../../../redux/BurnPermitPileGroupRedux'

// Selectors
import { appIsOnlineSelector, } from '../../../selectors/selectors'

// Models
import BurnPermitPileGroup from '../../../models/BurnPermitPileGroup'


const EstimateTonnage = props => {
  const { burnPermitId, TonnageIsEstimated, CalculatorResults, } = props

  const dispatch = useDispatch()

  const online = useSelector(appIsOnlineSelector)

  const calculate = useCallback(() => {
    dispatch(BurnPermitPileGroupActions.estimateConsumedTonnage(burnPermitId))
  }, [ dispatch, burnPermitId, ])

  const outputColumns = useMemo(() => BurnPermitPileGroup.dataTableColumns({ output: true, }), [])

  return <>
    <div className={'d-flex justify-content-between'}>
      <h5 className={'d-inline-block'}>Estimated Consumed Tonnage</h5>
      {
        online && <CalculateButton onClick={calculate} />
      }
    </div>
    {
      online && !TonnageIsEstimated && <MustCalculate />
    }
    <DataTable
      columns={outputColumns}
      enableExport={false}
      disablePageLengthChange={true}
      disableRowClick={true}
      records={CalculatorResults}
      elementId={'estimated-consumed-tonnage-table'}
    />
  </>
}

EstimateTonnage.defaultProps = {
  burnPermitId       : null,
  TonnageIsEstimated : false,
  CalculatorResults  : [],
}

EstimateTonnage.propTypes = {
  burnPermitId       : number,
  TonnageIsEstimated : bool,
  CalculatorResults  : array,
}

export default EstimateTonnage