// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { array, object, func, string, PropTypes, } from 'prop-types'
import { Container, Row, Col, Card, CardHeader, CardBody, CardText, } from 'reactstrap'
import StackTrace from 'stacktrace-js'
import localforage from 'localforage'

// Reducers
import ApiActions from '../redux/ApiRedux'
import AppActions from '../redux/AppRedux'

// Components
import withRouter from './withRouter'


class ErrorBoundary extends React.Component {

  static propTypes = {
    // from withRouter HOC
    location: object,

    children     : PropTypes.oneOfType([ array, object, ]),
    SetPageTitle : func,
    LogError     : func,
    authInfo     : object,
    appVersion   : string,
  }
  
  state = { hasError: false, }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, }
  }

  getState = async () => {
    const itemKeys = [ 'persist:wa_dnr_burn_portal', ]

    const value = await localforage.getItem(itemKeys)
    if (value) {
      return value.toString().replace(/\\/g, '')
    }
    return null
  }

  postError = async (error, errorInfo) => {
    const { authInfo, appVersion, } = this.props
    const localState = await this.getState()
    StackTrace.fromError(error)
      .then(err => {
        this.props.LogError(JSON.stringify({
          authInfo,
          appVersion,
          date     : new Date(),
          url      : window.location.href,
          agent    : window.navigator.userAgent,
          errorMsg : error.toString(),
          errorInfo,
          error    : err,
        }), localState)
      })
  }

  componentDidCatch (error, errorInfo) {
    this.postError(error, errorInfo)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { location, } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ hasError: false, })
    }
    if (prevState.hasError === false && this.state.hasError === true) {
      this.props.SetPageTitle('Error')
    }
  }

  render () {
    if (this.state.hasError) {
      return <Container>
        <Row className={'mt-4'}>
          <Col md={'2'}></Col>
          <Col md={'8'}>
            <Card className={'not-found'}>
              <CardHeader tag={'h3'}>Error</CardHeader>
              <CardBody>
                <CardText style={{ textAlign: 'center', }}>
                  {'We\'re sorry, but there is an issue with the action you are trying to perform.'}
                  <br/>{'We have logged the issue and we\'ll resolve is as soon as possible.'}
                  <br/>Feel free to report the issue as well.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    }

    return this.props.children
  }
}

const mapDispatchToProps = {
  SetPageTitle : AppActions.setPageTitle,
  LogError     : ApiActions.logError,
}

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary))