import { attr, Model, } from 'redux-orm'

export default class BurnRequestStatus extends Model {
  static get modelName () {
    return 'BurnRequestStatus'
  }

  toString () {
    return `${this.BurnRequestStatusName}`
  }

  static endpoint () {
    return 'BurnRequestStatuses'
  }

  static get options () {
    return {
      idAttribute: 'BurnRequestStatusId',
    }
  }

  static get fields () {
    return {
    // Model-Specific Properties
      BurnRequestStatusId          : attr(),
      BurnRequestStatusName        : attr({ getDefault: () => '', }),
      BurnRequestStatusDescription : attr({ getDefault: () => '', }),
  
      // Audit Properties
      CreateDate : attr(),
      UpdateDate : attr(),
    }
  }
}