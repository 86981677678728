// Libraries
import React from 'react'
import { number, string, } from 'prop-types'
import { useDispatch, } from 'react-redux'

// Components
import DocuSignModal from './DocuSignModal'

// Redux
import BurnPermitSignatureActions, { SignatureProcessTypes, } from '../../redux/BurnPermitSignatureRedux'


const PreviewPermit = props => {
  const { burnPermitId, className, } = props

  const dispatch = useDispatch()

  const previewPermit = React.useCallback(() => {
    dispatch(BurnPermitSignatureActions.previewPermit(burnPermitId))
  }, [ dispatch, burnPermitId, ])

  return <DocuSignModal
    onClick={previewPermit}
    className={className}
    modalHeader={'Preview Burn Permit'}
    buttonText={'Preview Permit'}
    signatureType={SignatureProcessTypes.preview}
  />
}

PreviewPermit.defaultProps = {
  className: '',
}

PreviewPermit.propTypes = {
  burnPermitId : number,
  className    : string,
}

export default PreviewPermit
