// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { array, func, bool, } from 'prop-types'
import { Toast, ToastBody, ToastHeader, } from 'reactstrap'

// Actions
import ApiActions from '../redux/ApiRedux'

class OfflineMessage extends React.Component {

  static propTypes = {
    offlineMessages     : array,
    preparingOffline    : bool,
    offlinePrepared     : bool,
    ClearOfflineMessage : func,
    OfflineMessage      : func,
  }

  onClick = evt => {
    const { id, } = evt.target.dataset
    this.props.ClearOfflineMessage(id)
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.preparingOffline && this.props.preparingOffline) {
      this.props.OfflineMessage('You are almost ready to operate offline. Go to "My Burn Permits" and "My Burn Requests" to download your data for offline access.')
    }
  }

  render () {
    const { offlineMessages, } = this.props
    if (offlineMessages.length === 0) {
      return null
    }
    const msgs = []
    for (let i = 0, len = offlineMessages.length; i < len; i++) {
      const msg = offlineMessages[i]
      msgs.push(
        <Toast className={'offline-message'} key={'offline-message-' + i}>
          <ToastHeader className={'pl-2 pr-1'}>
            <span className={'fa fa-wifi mr-2 text-info'}></span>
            Offline Action
            <small className={'ml-2'}>{msg.occurredAt}</small>
            <button
              className={'ml-2 mb-1 close fa fa-times'}
              data-dismiss={'toast'}
              aria-label={'Close'}
              data-id={msg.id}
              onClick={this.onClick}
            ></button>
          </ToastHeader>
          <ToastBody>
            {msg.message}
          </ToastBody>
        </Toast>
      )
    }
    return <div className={'offline-messages'}>{msgs}</div>
  }
}
  
const mapStateToProps = (state, props) => {
  return {
    preparingOffline : state.api.preparingOffline,
    offlinePrepared  : state.api.offlinePrepared,
    offlineMessages  : state.api.offlineMessages,
    location         : props.location,
  }
}

const mapDispatchToProps = {
  ClearOfflineMessage : ApiActions.clearOfflineMessage,
  OfflineMessage      : ApiActions.offlineMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(OfflineMessage)