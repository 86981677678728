// Libraries
import React from 'react'
import { string, func, } from 'prop-types'
import SearchInput from './SearchInput'

const ApplicationId = ({ value, name, onChange, }) => {
  return <SearchInput
    label={'Application ID'}
    labelClassName={'m-0'}
    name={name}
    value={value}
    onChange={onChange}
    showPopover={true}
    popoverHeader={'Permit Application ID'}
    popoverBody={<>Use the <b>Permit Application ID</b> to find Permits that are submitted or in review. <b>Burn Permit Number</b> is not assigned to a Permit until the application is Issued.</>}
  />
}

ApplicationId.propTypes = {
  name     : string,
  value    : string,
  onChange : func,
}

ApplicationId.defaultProps = {
  name: 'ApplicationId',
}

export default ApplicationId