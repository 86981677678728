import { attr, Model, } from 'redux-orm'

export default class EquipmentType extends Model {
  static get modelName () {
    return 'EquipmentType'
  }

  toString () {
    return `${this.EquipmentTypeName}`
  }

  static endpoint () {
    return 'EquipmentTypes'
  }

  static get options () {
    return {
      idAttribute: 'EquipmentTypeId',
    }
  }

  static get fields () {
    return {
      EquipmentTypeId           : attr({ type: 'number', }),
      EquipmentTypeName         : attr(),
      EquipmentTypeDescription  : attr(),
      EquipmentTypeCategoryName : attr(),
      CreateDate                : attr(),
      UpdateDate                : attr(),
    }
  }
}