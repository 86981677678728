import { attr, Model, fk, } from 'redux-orm'
import * as Yup from 'yup'

export default class Address extends Model {
  static get modelName () {
    return 'Address'
  }

  toString () {
    const streetLines = []
    const cityStateZip = []
    if (this.StreetLine1) {
      streetLines.push(this.StreetLine1)
    }
    if (this.StreetLine2) {
      streetLines.push(this.StreetLine2)
    }
    if (this.StreetLine3) {
      streetLines.push(this.StreetLine3)
    }
    if (this.AddressCity) {
      cityStateZip.push(this.AddressCity + ',')
    }
    if (this.AddressState) {
      cityStateZip.push(this.AddressState)
    }
    if (this.AddressZipCode) {
      cityStateZip.push(`${this.AddressZipCode.slice(0,5)}-${this.AddressZipCode.slice(5)}`)
    }
    let addrStr = ''
    if (streetLines.length) {
      addrStr = streetLines.join('\n')
    }
    if (cityStateZip.length) {
      addrStr += '\n' + cityStateZip.join(' ')
    }
    return addrStr
  }

  static endpoint (action) {
    if (action === 'Geocode') {
      return 'Addresses/Geocode'
    }
    return 'Addresses'
  }

  static getBasicValidationSchema () {
    const {
      StreetLine1,
      StreetLine2,
      StreetLine3,
      AddressCity,
      AddressState,
      AddressZipCode,
      AddressTypeId,
    } = this.fields

    return Yup.object().shape({
      StreetLine1: Yup.string()
        .max(StreetLine1.opts.max, `Street address must be ${StreetLine1.opts.max} characters or less`)
        .required('Please enter a street address'),
      StreetLine2: Yup.string()
        .max(StreetLine2.opts.max, `Street address must be ${StreetLine2.opts.max} characters or less`)
        .ensure(),
      StreetLine3: Yup.string()
        .max(StreetLine3.opts.max, `Street address must be ${StreetLine3.opts.max} characters or less`)
        .ensure(),
      AddressCity: Yup.string()
        .max(AddressCity.opts.max, `City must be ${AddressCity.opts.max} characters or less`)
        .required('Please enter a city'),
      AddressState: Yup.string()
        .max(AddressState.opts.max, `Please enter a ${AddressState.opts.max}-letter state abbreviation`)
        .required('Please enter a state'),
      AddressZipCode: Yup.number()
        .max(AddressZipCode.opts.max, `Zip code must be ${AddressZipCode.opts.max} characters or less`)
        .required('Please enter a zip code'),
      AddressTypeId: Yup.number()
        .min(AddressTypeId.opts.min, 'Please select an address type')
        .required('Please select an address type'),
    })
  }

  static get options () {
    return {
      idAttribute: 'AddressId',
    }
  }

  static getValidationSchema () {

    return Yup.object().shape({
      StreetLine1: Yup.string()
        .when([ 'AddressCity', 'AddressState', 'AddressZipCode', 'Force', ], {
          is: (AddressCity, AddressState, AddressZipCode, Force) => {
            return !!(AddressCity || AddressState || AddressZipCode) || Force
          },
          then: Yup.string().ensure()
            .max(this.fields.StreetLine1.opts.max, `Street address must be ${this.fields.StreetLine1.opts.max} characters or less`)
            .required('Please enter a street address'),
        }),
      AddressCity: Yup.string()
        .when([ 'StreetLine1', 'AddressState', 'AddressZipCode', 'Force', ], {
          is: (StreetLine1, AddressState, AddressZipCode, Force) => {
            return !!(StreetLine1 || AddressState || AddressZipCode) || Force
          },
          then: Yup.string().ensure()
            .max(this.fields.AddressCity.opts.max, `City must be ${this.fields.AddressCity.opts.max} characters or less`)
            .required('Please enter a city'),
        }),
      AddressState: Yup.string()
        .when([ 'StreetLine1', 'AddressCity', 'AddressZipCode', 'Force', ], {
          is: (StreetLine1, AddressCity, AddressZipCode, Force) => {
            return !!(StreetLine1 || AddressCity || AddressZipCode) || Force
          },
          then: Yup.string().ensure()
            .max(this.fields.AddressState.opts.max, `Please enter a ${this.fields.AddressState.opts.max}-letter state abbreviation`)
            .required('Please enter a state'),
        }),
      AddressZipCode: Yup.number()
        .when([ 'StreetLine1', 'AddressCity', 'AddressState', 'Force', ], {
          is: (StreetLine1, AddressCity, AddressState, Force) => {
            return !!(StreetLine1 || AddressCity || AddressState) || Force
          },
          then: Yup.number()
            .typeError('Zip code must be a number')
            .max(this.fields.AddressZipCode.opts.max, `Zip code must be ${this.fields.AddressZipCode.opts.max} characters or less`)
            .required('Please enter a zip code'),
        }),
    }, [ 
      [ 'StreetLine1', 'AddressCity', ],
      [ 'StreetLine1', 'AddressState', ],
      [ 'StreetLine1', 'AddressZipCode', ],
      [ 'AddressCity', 'AddressState', ],
      [ 'AddressCity', 'AddressZipCode', ],
      [ 'AddressState', 'AddressZipCode', ],
    ])
  }

  static get fields () {
    return  {
      // Common Properties
      AddressId      : attr(),
      CreateDate     : attr({ getDefault: () => new Date().toISOString(), }),
      CreateBy       : attr(),
      UpdateDate     : attr({ getDefault: () => new Date().toISOString(), }),
      UpdateBy       : attr(),
      // Model-Specific Properties
      StreetLine1    : attr({ getDefault: () => '', max: 32, }),
      StreetLine2    : attr({ getDefault: () => '', max: 32, }),
      StreetLine3    : attr({ getDefault: () => '', max: 32, }),
      AddressCity    : attr({ getDefault: () => '', max: 20, }),
      AddressState   : attr({ getDefault: () => '', max: 2, }),
      AddressZipCode : attr({ getDefault: () => '', max: 999999999, }),
      AddressCountry : attr({ getDefault: () => '', }),
      Active         : attr({ getDefault: () => true, }),

      // Relationships
      AddressTypeId: attr({
        getDefault : () => 1,
        min        : 1, 
      }),
      AddressType: fk({ 
        to          : 'AddressType', 
        relatedName : 'Addresses', 
      }),
      CountyId: fk({ 
        to          : 'County', 
        relatedName : 'Addresses', 
        as          : 'County', 
      }),
    }
  }
}