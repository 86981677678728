import { useRef, useEffect, useCallback, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import CartActions from '../../redux/CartRedux'
import BurnPermitOrderActions from '../../redux/BurnPermitOrderRedux'
import { orderByIdSelector, } from '../../selectors/orderSelectors'

const emptyArray = []

/**
 * Hook to provide access to current and previous cart state and provide redux action callbacks
 * @returns An array continaing cart data, cart actions, and the current order
 */
export function useCart () {
  const reduxDispatch = useDispatch()
  const currentCart = useSelector(({ Cart = null, }) => Cart ? Cart.CartItemIds : emptyArray)
  const prevCart = useRef()
  const orderId = useSelector(({ BurnPermitOrder = {}, }) => BurnPermitOrder.newBurnPermitOrderId)
  const order = useSelector((state) => orderByIdSelector(state, orderId))

  useEffect(() => {
    prevCart.current = currentCart
  }, [ currentCart, ])
  
  const addToCart = useCallback((id) => {
    reduxDispatch(CartActions.AddItemToCart(id))
  }, [ reduxDispatch, ])

  const removeFromCart = useCallback((id) => {
    reduxDispatch(CartActions.RemoveItemFromCart(id))
  }, [ reduxDispatch, ])

  const emptyCart = useCallback(() => {
    reduxDispatch(CartActions.EmptyCart())
  }, [ reduxDispatch, ])

  const createOrder = useCallback(() => {
    reduxDispatch(BurnPermitOrderActions.createBurnPermitOrder(currentCart))
  }, [ currentCart, reduxDispatch, ])

  useEffect(() => {
    reduxDispatch(BurnPermitOrderActions.updateOrderPermitIds(null))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [ 
    { current: currentCart, previous: prevCart.current, },
    { addToCart, removeFromCart, emptyCart, createOrder, },
    order, 
  ]
}