// Libraries
import React from 'react'
import { bool, object, func, string, } from 'prop-types'
import { Editor as RDEditor, } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

const Editor = ({ 
  readOnly,
  onChange,
  name,
  value,
  wrapperClassName,
  editorClassName,
  toolbarClassName,
  id,
}) => {  
  // call parent change callback when editor state changes
  const editorStateChange = React.useCallback((editorState) => {
    if (onChange) {
      onChange({
        target: {
          name,
          value: editorState,
        },
      })
    }
  }, [ name, onChange, ])

  // if the editor is read-only, render as HTML
  const valueHtml = React.useMemo(() => {
    if (readOnly && value && value.getCurrentContent) {
      const rawContentState = convertToRaw(value.getCurrentContent())
      return <div dangerouslySetInnerHTML={{ __html: draftToHtml(rawContentState), }} />
    }
  }, [ readOnly, value, ])

  if (readOnly && value) {
    return valueHtml
  }
  // only return editor when the condition isn't read-only 
  return <RDEditor
    wrapperClassName={`${wrapperClassName} ${readOnly ? 'readonly' : '' }`}
    editorClassName={`${editorClassName} ${readOnly ? 'readonly' : '' }`}
    toolbarClassName={`${toolbarClassName} ${readOnly ? 'readonly d-none' : '' }`}
    readOnly={readOnly}
    disabled={readOnly}
    editorState={value}
    onEditorStateChange={editorStateChange}
    name={name}
    id={id}
  />
}

Editor.propTypes = {
  readOnly         : bool,
  onChange         : func,
  onBlur           : func,
  name             : string,
  className        : string,
  value            : object,
  wrapperClassName : string,
  editorClassName  : string,
  toolbarClassName : string,
  id               : string,
}

Editor.defaultProps = {
  readOnly         : false,
  name             : 'wysiwyg-editor',
  wrapperClassName : '',
  editorClassName  : '',
  toolbarClassName : '',
  id               : '',
}

export default Editor