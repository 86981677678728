// Yup uses curly templates for error messages.
/* eslint-disable no-template-curly-in-string */
import { attr, Model, fk, many, } from 'redux-orm'
import * as Yup from 'yup'


const numberSchema = prefix => {
  return Yup.number()
    .required(prefix + ' is required')
    .positive(prefix + ' must be positive')
    .typeError(prefix + ' must be a number')
}


export default class BurnPermitConditionXref extends Model {
  static get modelName () {
    return 'BurnPermitConditionXref'
  }

  static endpoint () {
    return 'BurnPermitConditionXrefs'
  }

  static mapFieldToName (fieldName) {
    switch (fieldName) {
    case 'ProhibitedFrom':
      return 'Burning prohibited from'
    case 'ProhibitedThrough':
      return 'Burning prohibited through'
    case 'MaxWindSpeedMph':
      return 'Wind speed'
    case 'MinRelHumidityPercent':
      return 'Relative humidity'
    case 'NoIgnitionFuelStart':
      return 'No ignition before'
    case 'NoIgnitionFuelEnd':
      return 'No ignition after'
    case 'DaysToExtinguish':
      return 'Extinguish days'
    case 'MaxPilesBurnedAtOnce':
      return 'Max piles'
    case 'MaxDiameterInches':
      return 'Pile diameter'
    case 'MinWidthFireBreakFeet':
      return 'Fire break width'
    case 'NumberOfPeopleRequired':
      return 'Number of people'
    case 'NumberOfPumpCans':
      return 'Pump cans'
    case 'PumpTrailerCapacityGallons':
      return 'Pump truck/trailer'
    case 'MaxPermitsToBurnAtOnce':
      return 'Multiple permits'
    case 'MaxRequestTonnage':
      return 'Smoke management approval limit'
    case 'OtherCondition':
      return 'Other conditions'
    default:
      return null
    }
  }
  
  static mapConditionValueKey (conditionName) {
    let valueKey = null
    let isXref = false
    switch (conditionName) {
    case 'Burning prohibited from':
      valueKey = 'ProhibitedFrom'
      break
    case 'Burning prohibited through':
      valueKey = 'ProhibitedThrough'
      break
    case 'Wind direction':
      isXref = true
      valueKey = 'BurnPermitWindDirectionConditionXref'
      break
    case 'Wind speed':
      valueKey = 'MaxWindSpeedMph'
      break
    case 'Relative humidity':
      valueKey = 'MinRelHumidityPercent'
      break
    case 'Days of the week':
      isXref = true
      valueKey = 'BurnPermitProhibitedDayConditionXref'
      break
    case 'No ignition before':
      valueKey = 'NoIgnitionFuelStart'
      break
    case 'No ignition after':
      valueKey = 'NoIgnitionFuelEnd'
      break
    case 'Extinguish days':
      valueKey = 'DaysToExtinguish'
      break
    case 'Max piles':
      valueKey = 'MaxPilesBurnedAtOnce'
      break
    case 'Pile diameter':
      valueKey = 'MaxDiameterInches'
      break
    case 'Fire break width':
      valueKey = 'MinWidthFireBreakFeet'
      break
    case 'Number of people':
      valueKey = 'NumberOfPeopleRequired'
      break
    case 'Pump cans':
      valueKey = 'NumberOfPumpCans'
      break
    case 'Pump truck/trailer':
      valueKey = 'PumpTrailerCapacityGallons'
      break
    case 'Equipment requirements':
      isXref = true
      valueKey = 'BurnPermitEquipmentConditionXref'
      break
    case 'Multiple permits':
      valueKey = 'MaxPermitsToBurnAtOnce'
      break
    case 'Smoke management approval limit':
      valueKey = 'MaxRequestTonnage'
      break
    case 'Other conditions':
      valueKey = 'OtherCondition'
      break
    default:
      break
    }
    return { valueKey, isXref, }
  }

  static getValidationSchema (conditionName) {
    const {
      ProhibitedFrom,
      ProhibitedThrough,
      MaxWindSpeedMph,
      MinRelHumidityPercent,
      NoIgnitionFuelStart,
      NoIgnitionFuelEnd,
      DaysToExtinguish,
      MaxPilesBurnedAtOnce,
      MaxDiameterInches,
      MinWidthFireBreakFeet,
      NumberOfPeopleRequired,
      NumberOfPumpCans,
      PumpTrailerCapacityGallons,
      MaxPermitsToBurnAtOnce,
      NotifyBeforeIgnitionEntity,
      NotifyBeforeIgnitionPhone,
      MaxRequestTonnage,
    } = this.fields

    let schema
    switch (conditionName) {
    case 'Burning prohibited from':
      schema = Yup.string()
        .required('Prohibited from description is required')
        .max(ProhibitedFrom.opts.max, 'Prohibited from description must be ${max} characters or less')
      break
    case 'Burning prohibited through':
      schema = Yup.string()
        .required('Prohibited through description is required')
        .max(ProhibitedThrough.opts.max, 'Prohibited through description must be ${max} characters or less')
      break
    case 'Wind speed':
      schema = numberSchema(conditionName)
        .max(MaxWindSpeedMph.opts.max, 'Max wind speed must be less than ${max}')
      break
    case 'Relative humidity':
      schema = numberSchema(conditionName)
        .min(MinRelHumidityPercent.opts.min, 'Minimum humidity must be greater than or equal to ${min}')
        .max(MinRelHumidityPercent.opts.max, 'Minimum humidity must be less than ${max}')
      break
    case 'No ignition before':
      schema = Yup.string()
        .required('Ignition start time value is required')
        .max(NoIgnitionFuelStart.opts.max, 'No ignition start time value must be ${max} or less')
      break
    case 'No ignition after':
      schema = Yup.string()
        .required('Ignition end time value is required')
        .max(NoIgnitionFuelEnd.opts.max, 'No ignition end time value must be ${max} or less')
      break
    case 'Extinguish days':
      schema = numberSchema(conditionName)
        .min(DaysToExtinguish.opts.min, 'Days to extinguish must be greater than or equal to ${min}')
        .max(DaysToExtinguish.opts.max, 'Days to extinguish must be less than ${max}')
      break
    case 'Max piles':
      schema = numberSchema(conditionName)
        .min(MaxPilesBurnedAtOnce.opts.min, 'Maximum burn piles must be greater than or equal to ${min}')
        .max(MaxPilesBurnedAtOnce.opts.max, 'Maximum burn piles must be less than ${max}')
      break
    case 'Pile diameter':
      schema = numberSchema(conditionName)
        .min(MaxDiameterInches.opts.min, 'Pile diameter must be greater than or equal to ${min}')
        .max(MaxDiameterInches.opts.max, 'Pile diameter must be less than ${max}')
      break
    case 'Fire break width':
      schema = numberSchema(conditionName)
        .min(MinWidthFireBreakFeet.opts.min, 'Fire break width must be greater than or equal to ${min}')
      break
    case 'Number of people':
      schema = numberSchema(conditionName)
        .min(NumberOfPeopleRequired.opts.min, 'Number of people must be greater than or equal to ${min}')
      break
    case 'Pump cans':
      schema = numberSchema('Number of pump cans')
        .min(NumberOfPumpCans.opts.min, 'Number of pump cans must be greater than or equal to ${min}')
      break
    case 'Pump truck/trailer':
      schema = numberSchema(conditionName + ' capactity in gallons')
        .min(PumpTrailerCapacityGallons.opts.min, 'Truck capacacity must be greater than or equal to ${min}')
      break
    case 'Multiple permits':
      schema = numberSchema('Number of permits')
        .min(MaxPermitsToBurnAtOnce.opts.min, 'Maximum number of permits must be greater than or equal to ${min}')
      break
    case 'NotifyBeforeIgnitionEntity':
      schema = numberSchema(conditionName)
        .max(NotifyBeforeIgnitionEntity.opts.max, 'Notification entity must be ${max} characters or less')
      break
    case 'NotifyBeforeIgnitionPhone':
      schema = numberSchema(conditionName)
        .max(NotifyBeforeIgnitionPhone.opts.max, 'Notification phone must be ${max} characters or less')
      break
    case 'Other conditions':
      schema = Yup.object()
        .required('Other condition text is required')
      break
    case 'Smoke management approval limit':
      schema = numberSchema('Maximum tons to burn without approval')
        .min(MaxRequestTonnage.opts.min, 'Maximum tons to burn without approval must be greater than or equal to${min}')
      break
    default:
      break
    }
    return schema
  }

  static getUnit (conditionName) {

    let unit
    switch (conditionName) {
    case 'Wind speed':
      unit = 'MPH'
      break
    case 'Relative humidity':
      unit = 'percent'
      break
    case 'No ignition before':
      unit = 'a.m.'
      break
    case 'No ignition after':
      unit = 'p.m.'
      break
    default:
      break
    }
    return unit
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitConditionXrefId',
    }
  }

  static get fields () {
    return {
      // ID field
      BurnPermitConditionXrefId: attr(),

      // Common fields
      CreateBy   : attr(),
      CreateDate : attr(),
      UpdateBy   : attr(),
      UpdateDate : attr(),

      // Foriegn Keys
      BurnPermitId: fk({ 
        to          : 'BurnPermit', 
        as          : 'BurnPermit', 
        relatedName : 'BurnPermitConditionXrefs', 
      }),
      BurnPermitConditionId: fk({ 
        to          : 'BurnPermitCondition', 
        as          : 'BurnPermitCondition', 
        relatedName : 'BurnPermitConditionXrefs', 
      }),

      // Many relationships
      BurnPermitEquipmentConditions: many({
        to          : 'EquipmentType',
        through     : 'BurnPermitEquipmentConditionXref',
        relatedName : 'BurnPermitConditionXrefs',
      }),

      BurnPermitProhibitedDayConditions: many({
        to          : 'DaysOfWeek',
        through     : 'BurnPermitProhibitedDayConditionXref',
        relatedName : 'BurnPermitConditionXrefs',
      }),

      BurnPermitWindDirectionConditions: many({
        to          : 'Direction',
        through     : 'BurnPermitWindDirectionConditionXref',
        relatedName : 'BurnPermitConditionXrefs',
      }),

      // Model-specific fields
      ProhibitedFrom             : attr({ max: 64, }),
      ProhibitedThrough          : attr({ max: 64, }),
      MaxWindSpeedMph            : attr({ min: 1, max: 99999999.99, }),
      MinRelHumidityPercent      : attr({ min: 1, max: 100.00, }),
      NoIgnitionFuelStart        : attr({ max: 32, }),
      NoIgnitionFuelEnd          : attr({ max: 32, }),
      DaysToExtinguish           : attr({ min: 1, max: 366, }),
      MaxPilesBurnedAtOnce       : attr({ min: 1, max: 99999999, }),
      MaxDiameterInches          : attr({ min: 1, max: 999, }),
      MinWidthFireBreakFeet      : attr({ min: 1, }),
      NumberOfPeopleRequired     : attr({ min: 1, }),
      NumberOfPumpCans           : attr({ min: 1, }),
      PumpTrailerCapacityGallons : attr({ min: 1, }),
      MaxPermitsToBurnAtOnce     : attr({ min: 1, }),
      NotifyBeforeIgnitionEntity : attr({ max: 256, }),
      NotifyBeforeIgnitionPhone  : attr({ max: 10, }),
      OtherCondition             : attr({ max: 1000, }),
      MaxRequestTonnage          : attr({ min: 1, }),
    }
  }
}