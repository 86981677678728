// Libraries
import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap'
import { useDispatch, useSelector, } from 'react-redux'

// Components
import CloseModalButton from '../../Modals/CloseModalButton'

// Redux
import UiActions from '../../../redux/UiRedux'

// Selectors
import { isModalOpen, } from '../../../selectors/uiSelectors'
import stopEvent from '../../../utilities/stopEvent'


const modalKey = 'PILE_GROUP_INSTRUCTIONS'

const PileGroupInstructions = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(state => isModalOpen(state, modalKey))

  const openModal = React.useCallback(() => {
    dispatch(UiActions.openModal(modalKey))
  }, [ dispatch, ])

  return <>
    <Button
      size={'sm'}
      onClick={openModal}
      data-modalkey={modalKey}
      color={'light'}
      className={'float-right'}
    >
      View Instructions
    </Button>
    <Modal isOpen={isOpen}>
      <ModalHeader>
        Pile Groups Instructions
      </ModalHeader>
      <ModalBody>
        <p>
          Use the <Button
            size={'sm'}
            onClick={stopEvent}
            color={'success'}
            className={'readonly'}>Add Pile Group</Button>&nbsp;
          below to create one or more Pile Groups.
        </p>
        <p>
          Click on a row in the Pile Groups table below to edit a Pile Group.
        </p>
        <p>
          Click the <Button
            size={'sm'}
            onClick={stopEvent}
            color={'danger'}
            className={'btn btn-sm fa fa-minus btn-danger readonly'} />&nbsp;
          to delete a Pile Group
        </p>
        <p>
          Click the <Button
            size={'sm'}
            onClick={stopEvent}
            color={'info'}
            className={'readonly'}>Calculate</Button>&nbsp;
          to execute the calculator to
          get the Estimated Consumed Tonnage data. The Input and Output files will be automatically attached to this application
          and available for download in the <b>Documents</b> section.
        </p>
        <ModalFooter className={'d-flex justify-content-end'}>
          <CloseModalButton />
        </ModalFooter>
      </ModalBody>
    </Modal>
  </>
}

export default React.memo(PileGroupInstructions)