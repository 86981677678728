/**
 * Filters the provided array of Formik form refs to only the
 * ones that have a `validateForm` method then calls that method
 * on each form
 * @async
 * @param {Array{Formik}} forms An array or Formik forms
 * @returns {bool} Whether or not one or more of the forms is invalid
 */
async function validateAll (forms) {
  try {
    const formsToValidate = forms.filter(f => f && 'validateForm' in f && typeof f.validateForm === 'function' && f.validateForm())
    // Validate all of the forms so that the inline validation messages appear
    const formErrors = await Promise.all(formsToValidate.map(f => f.validateForm()))
    // Return a bool indicating that one or more forms is invalid
    return formErrors.some(f => Object.keys(f).length)
  }
  catch (err) {
    console.error(err)
  }
}

/**
 * Filters the provided array of Formik form refs to only the
 * ones that have one or more inputs that were touched
 * @param {Array{Formik}} forms An array or Formik forms
 */
function submitAll (forms) {
  try {
    const formsToSubmit = forms.filter(f => f && 'touched' in f && typeof f.touched === 'function' && f.touched())
    formsToSubmit.forEach(f => f.submitForm())
  }
  catch (err) {
    console.error(err)
  }
}

export {
  validateAll,
  submitAll,
}
