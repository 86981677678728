// Libraries
import React, { useCallback, useMemo, } from 'react'
import { useField, useFormikContext, } from 'formik'
import { bool, string, } from 'prop-types'

// Components
import { RadioInputs, } from '../FormControls'

// Utilities
import { dateFormatter, } from '../../utilities'


const PermitDurationField = props => {
  const [ field, ] = useField(props)
  const { name, value, } = field

  const PermitDurations = useMemo(() => ([
    {
      Value : '1',
      Text  : 'One Year',
    },
    {
      Value : '2',
      Text  : 'Two Years',
    },
  ]), [])

  const { values, setFieldValue, } = useFormikContext()

  const { ValidDate, } = values

  const expirationYearChange = useCallback((evt) => {
    let expDate = ''
    // Include validation telling users to first set a Valid Date 
    // before selecting a duration
    const newVal = evt.target.value
    if (newVal && ValidDate) {
      expDate = dateFormatter(ValidDate).add(newVal, 'year')
      expDate = dateFormatter(expDate.toDate(), 'YYYY-MM-DD')
    }
    setFieldValue('ExpirationDate', expDate)
    setFieldValue(name, newVal)
    return evt || newVal
  }, [ setFieldValue, name, ValidDate, ])

  return <RadioInputs
    inputName={name}
    label={'Is this permit valid for one or two years?'}
    onChange={expirationYearChange}
    values={PermitDurations}
    checkedValue={value}
    readOnly={!ValidDate}
  />
}

PermitDurationField.defaultProps = {
  name     : 'PermitDuration',
  type     : 'text',
  readOnly : false,
}

PermitDurationField.propTypes = {
  name     : string,
  type     : string,
  readOnly : bool,
}

export default React.memo(PermitDurationField)
