import { attr, Model, } from 'redux-orm'

export default class PersonType extends Model {
  static get modelName () {
    return 'PersonType'
  }

  toString () {
    return `${this.PersonTypeName}`
  }

  static endpoint () {
    return 'PersonTypes'
  }

  static get options () {
    return {
      idAttribute: 'PersonTypeId',
    }
  }

  static get fields () {
    return {
      // Common Properties
      PersonTypeId          : attr({ inputType: 'number', inputLabel: 'Person Type Id', }),
      CreateDate            : attr(),
      CreateBy              : attr(),
      UpdateDate            : attr(),
      UpdateBy              : attr(),
      // Model-Specific Properties
      PersonTypeName        : attr(),
      PersonTypeDescription : attr(),

      // Relationships
    }
  }
}