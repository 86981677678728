import { createSelector, } from 'reselect'
import { ormSelector, networkStateSelector, ormByIdSelector,  burnPermitAreaByIdSelector, } from './selectors'
import { activeBurnPermitIdSelector, permitCanAcceptDocumentUploads, } from './burnPermitSelectors'

import BurnPermitPileGroup from '../models/BurnPermitPileGroup'

const BURN_PERMIT_PILE_GROUP_VALIDATION_SCHEMA = BurnPermitPileGroup.validationSchema

const pileGroupState = state => state.BurnPermitPileGroups

export const activePileGroupId = createSelector(
  pileGroupState,
  state => state.EditBurnPermitPileGroupId
)

export const activePileGroup = ormSelector(
  activePileGroupId,
  ({ BurnPermitPileGroup, }, BurnPermitPileGroupId) => {
    return BurnPermitPileGroup.withId(BurnPermitPileGroupId)
  }
)

export const pileGroupById = ormByIdSelector(
  ({ BurnPermitPileGroup, }, BurnPermitPileGroupId) => {
    return BurnPermitPileGroup.withId(BurnPermitPileGroupId)
  }
)

/**
 * Translated from the PileConstants.java in the PilesCalculator source.
 * The order of values in this array is intentional to match the
 * constant values on the server used by the calculator.
 */
const PILE_SHAPE_CATEGORIES = [
  'Half Sphere',
  'Parabaloid',
  'Half Cylinder',
  'Half Cone Frustrum',
  'Cone Rounded',
  'Half Ellipsoid',
  'Irregular',
]
const pileShapeCategories = createSelector(
  () => {
    return PILE_SHAPE_CATEGORIES.map((c, idx) => {
      return {
        Text  : c,
        // These constant values on the server used by the calculator
        // are zero indexed, so do not modify the value
        Value : idx,
      }
    })
  }
)

/**
 * Translated from the PileConstants.java in the PilesCalculator source.
 * The order of values in this array is intentional to match the
 * constant values on the server used by the calculator.
 */
const HAND_PILE_SPECIES = [
  'Conifer',
  'Shrub',
]
const handPileSpecies = createSelector(
  () => {
    return HAND_PILE_SPECIES.map((c, idx) => {
      return {
        Text  : c,
        // Must increment by one to match the expect
        // constant value on the server used by the calculator
        Value : idx + 1,
      }
    })
  }
)

/**
 * Translated from the PileConstants.java in the PilesCalculator source.
 * The order of values in this array is intentional to match the
 * constant values on the server used by the calculator.
 */
const PILE_TYPE = [
  'Hand',
  'Machine',
]
const pileTypes = createSelector(
  () => {
    return PILE_TYPE.map((c, idx) => {
      return {
        Text  : c,
        // Must increment by one to match the expect
        // constant value on the server used by the calculator
        Value : idx + 1,
      }
    })
  }
)

/**
 * Translated from the PileConstants.java in the PilesCalculator source.
 * The order of values in this array is intentional to match the
 * constant values on the server used by the calculator.
 */
const PILE_QUALITY = [
  'Clean (0%)',
  'Dirty (0 - 10%)',
  'Very Dirty (>10%)',
]
const pileQuality = createSelector(
  () => {
    return PILE_QUALITY.map((c, idx) => {
      return {
        Text  : c,
        // Must increment by one to match the expect
        // constant value on the server used by the calculator
        Value : idx + 1,
      }
    })
  }
)

/**
 * Translated from the PileConstants.java in the PilesCalculator source.
 * The order of values in this array is intentional to match the
 * constant values on the server used by the calculator.
 */
const PACKING_RATIO = {
  10 : '10%: Mostly long-needled pines and/or broadleaf deciduous litter. Fuel diameters < 10 inches.',
  20 : '20%: Mostly short-needled conifers. Fuel diameters < 10 inches.',
  25 : '25%: Highly compacted, clean piles. Log diameters > 10 inches.',
}
const packingRatio = createSelector(
  () => {
    return Object.entries(PACKING_RATIO).map(c => {
      return {
        Text  : c[1],
        Value : c[0],
      }
    })
  }
)


const speciesWoodDensities = ormSelector(
  ({ SpeciesWoodDensity, }) => {
    return SpeciesWoodDensity.all().toRefArray().map(s => {
      return {
        Text  : `${s.CommonName} (${s.Density})`,
        Value : s.SpeciesWoodDensityId,
      }
    })
  }
)


const pileTypeIsChecked = ormSelector(
  activeBurnPermitIdSelector,
  ({ BurnPermitPileGroup, BurnPermit, }, BurnPermitId) => {
    const pileGroups = BurnPermitPileGroup.filter({ BurnPermitId, })
    let permit
    if (pileGroups.length) {
      permit = pileGroups.first().BurnPermit
    }
    else {
      permit = BurnPermit.withId(BurnPermitId)
    }
    if (!permit || !permit.BurnPermitArea) {
      return false
    }
    return permit.BurnPermitArea.BurnTypes.toRefArray().some(t => t.BurnTypeName === 'Pile')
  }
)


export const lookupDataSelector = createSelector(
  pileShapeCategories,
  handPileSpecies,
  pileTypes,
  pileQuality,
  packingRatio,
  speciesWoodDensities,
  (PileShapes, HandPileSpecies, PileTypes, PileQuality, PackingRatios, SpeciesWoodDensities) => {
    return {
      PileShapes,
      HandPileSpecies,
      PileTypes,
      PileQuality,
      PackingRatios,
      SpeciesWoodDensities,
    }
  }
)


const pileGroupInputMapper = (g, idx) => {
  const groupNamePrefix = g.PileType ? `${PILE_TYPE[g.PileType - 1]} ` : ''
  return {
    BurnPermitPileGroupId        : g.BurnPermitPileGroupId,
    BurnPermitId                 : g.BurnPermitId || '',
    PileCount                    : g.PileCount || '',
    PileType                     : g.PileType || '',
    PileTypeName                 : PILE_TYPE[g.PileType - 1] || '',
    PileShape                    : Number.isInteger(g.PileShape) ? g.PileShape : '',
    PileShapeName                : PILE_SHAPE_CATEGORIES[g.PileShape] || '',
    PileGroupName                : g.PileGroupName || `${groupNamePrefix}Pile Group ${g.BurnPermitId}-${idx + 1}`,
    WidthOne                     : g.WidthOne || '',
    HeightOne                    : g.HeightOne || '',
    LengthOne                    : g.LengthOne || '',
    WidthTwo                     : g.WidthTwo || '',
    HeightTwo                    : g.HeightTwo || '',
    LengthTwo                    : g.LengthTwo || '',
    PileQuality                  : g.PileQuality || '',
    PileQualityName              : PILE_QUALITY[g.PileQuality - 1] || '',
    PercentSoil                  : g.PercentSoil || '',
    PackingRatio                 : g.PackingRatio || '',
    PackingRatioName             : PACKING_RATIO[g.PackingRatio] || '',
    HandPileSpecies              : g.HandPileSpecies || '',
    HandPileSpeciesName          : HAND_PILE_SPECIES[g.HandPileSpecies - 1] || '',
    SpeciesOneDensityId          : g.SpeciesOneDensityId || '',
    SpeciesOneDensity            : g.SpeciesOneDensity ? g.SpeciesOneDensity.toString() : '',
    SpeciesOneCompositionPercent : g.SpeciesOneCompositionPercent || 0, // Default to 0%
    SpeciesTwoDensityId          : g.SpeciesTwoDensityId || '',
    SpeciesTwoDensity            : g.SpeciesTwoDensity ? g.SpeciesTwoDensity.toString() : '',
    SpeciesTwoCompositionPercent : g.SpeciesTwoCompositionPercent || 0, // Default to 0%
    TotalCompositionPercent      : parseFloat(g.SpeciesOneCompositionPercent || 0.0) + parseFloat(g.SpeciesTwoCompositionPercent || 0.0),
    CreateBy                     : g.CreateBy,
    CreateDate                   : g.CreateDate,
    UpdateBy                     : g.UpdateBy,
    UpdateDate                   : g.UpdateDate,
    IsLocal                      : g.IsLocal || false,
  }
}


const calculatorResultsMapper = g => {
  return {
    BurnPermitPileGroupId : g.BurnPermitPileGroupId,
    BurnPermitId          : g.BurnPermitId || '',
    GeometricVolume       : g.GeometricVolume || '',
    TrueWoodVolume        : g.TrueWoodVolume || '',
    PileBiomass           : g.PileBiomass || '',
    ConsumedFuel          : g.ConsumedFuel || '',
    Pm                    : g.Pm || '',
    Pm25                  : g.Pm25 || '',
    Pm10                  : g.Pm10 || '',
    Co                    : g.Co || '',
    Co2                   : g.Co2 || '',
    Ch4                   : g.Ch4 || '',
    Nmhc                  : g.Nmhc || '',
    LegacyTonnage         : g.LegacyTonnage || '',
    CreateBy              : g.CreateBy,
    CreateDate            : g.CreateDate,
    UpdateBy              : g.UpdateBy,
    UpdateDate            : g.UpdateDate,
  }
}


export const pileGroupsByPermitId = ormByIdSelector(
  activeBurnPermitIdSelector,
  ({ BurnPermitPileGroup, }, BurnPermitId) => {
    if (BurnPermitId === null || isNaN(BurnPermitId)) {
      return []
    }
    return BurnPermitPileGroup.filter({ BurnPermitId, }).toModelArray()
  }
)



export const burnPermitPileGroupsByPermitIdSelector = createSelector(
  pileGroupsByPermitId,
  networkStateSelector,
  pileGroupState,
  pileTypeIsChecked,
  burnPermitAreaByIdSelector,
  permitCanAcceptDocumentUploads,
  (pileGroups, Network, PileGroupState, PileBurnTypeIsChecked, PermitArea, canUpload) => {
    const state = {
      RequestFailed             : PileGroupState.RequestFailed,
      Validate                  : PileGroupState.Validate,
      online                    : Network.online,
      ActiveBurnPermitPileGroup : {},
      BurnPermitPileGroups      : [],
      CalculatorResults         : [],
      PileBurnTypeIsChecked,
      canUpload,
      isValid                   : false,
      groupsAreValid            : false,
      needsPileGroups           : false,
      needsCalculation          : true,
      isExemptArea              : PermitArea && (!Number.isNaN(PermitArea.HarvestAcres) && PermitArea.HarvestAcres < 10) && Array.isArray(PermitArea.BurnTypes) && PermitArea.BurnTypes.length === 1 && PermitArea.BurnTypes[0] === 1,
    }

    if (Array.isArray(pileGroups) && pileGroups.length > 0) {
      state.BurnPermitPileGroups = pileGroups.map(pileGroupInputMapper)
    }

    if (Number.isInteger(PileGroupState.EditBurnPermitPileGroupId)) {
      const group = state.BurnPermitPileGroups.filter(g => g.BurnPermitPileGroupId === PileGroupState.EditBurnPermitPileGroupId)[0] || {}
      state.ActiveBurnPermitPileGroup = group
    }

    state.CalculatorResults = pileGroups.map(calculatorResultsMapper)
    state.TonnageIsEstimated = state.CalculatorResults.length > 0 && state.CalculatorResults.every(r => r.PileBiomass > 0.0)

    // Pile Calculations are not needed if the application is exempt (meaning under 10 acres for a pile burn)
    // or the application does not have the Pile Type selected
    if (
      (state.isExemptArea && state.BurnPermitPileGroups.length === 0)
      || (PileBurnTypeIsChecked === false)
    ) {
      state.needsCalculation = false
    } else {
      state.needsPileGroups = Array.isArray(state.BurnPermitPileGroups) === false || state.BurnPermitPileGroups.length === 0
      state.needsCalculation = !state.TonnageIsEstimated
    }

    state.isValid        = !state.needsCalculation
    state.groupsAreValid = state.BurnPermitPileGroups
      .map(g => {
        if (g.BurnPermitPileGroupId === PileGroupState.EditBurnPermitPileGroupId) {
          return g
        }
        const entries = Object.entries(g)
        for (let i = 0, len = entries.length; i < len; i++) {
          const key = entries[i][0], value = entries[i][1]
          if (value === '') {
            g[key] = null
          }
        }
        return g
      })
      .map(g => BURN_PERMIT_PILE_GROUP_VALIDATION_SCHEMA.isValidSync(g), [])
    return { ...state, }
  }
)
