// Libraries
import React, { useCallback, } from 'react'
import { useDispatch, } from 'react-redux'
import { Button, } from 'reactstrap'
import { string, bool, number, PropTypes, } from 'prop-types'

// Components
import ConfirmationModal from '../../../../ConfirmationModal'

// Reducers
import UiActions from '../../../../../redux/UiRedux'
import BurnPermitPileGroupActions from '../../../../../redux/BurnPermitPileGroupRedux'


const MODAL_KEY = 'DELETE_ALL_PILE_GROUPS'

const DeleteAllPiles = props => {
  const {
    disabled,
    permitId,
    className,
  } = props

  const dispatch = useDispatch()

  const openModal = useCallback(() => dispatch(UiActions.openModal(MODAL_KEY)), [ dispatch, ])

  const submit = useCallback((permitId) => {
    dispatch(BurnPermitPileGroupActions.deleteAllPileGroups(permitId))
  }, [ dispatch, ])

  return <div className={className}>
    <Button
      color={'danger'}
      size={'sm'}
      disabled={disabled}
      onClick={openModal}
    >
    Delete All Piles
    </Button>
    <ConfirmationModal
      modalKey={MODAL_KEY}
      modalTitle={'Delete All Pile Groups'}
      modalBody={<p>Are you sure you want to delete <b>ALL</b> Pile Groups for Permit ID <b>{permitId}</b>?</p>}
      submitAction={submit}
      submitArgs={[ permitId, ]}
    />
  </div>
}

DeleteAllPiles.propTypes = {
  className : string,
  permitId  : PropTypes.oneOfType([ number, string, ]),
  disabled  : bool,
}

export default React.memo(DeleteAllPiles)