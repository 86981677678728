import { convertToRaw, convertFromRaw, ContentState, EditorState, } from 'draft-js'

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style                 : 'currency',
  currency              : 'USD',
  minimumFractionDigits : 2,
})

export {
  dateFormatter,
  getTodaysDate,
  getTomorrowsDate,
  getDateInXDays,
  getNow,
  pacificTimeZoneDate,
  isAfter,
} from './datetimes'


/**
 * Returns a boolean indicating whether or not the property exists in the provided object.
 * @param {object} obj - The object to check the existance of a property
 * @param {string} prop - The property to check the existance of
 * @returns {boolean}
 */
export const objHasProp = (obj, prop) => {
  if (obj === undefined || obj === null || typeof(obj) !== 'object') {
    console.warn('Attempted object propert check on a non-object')
    return false
  }
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

const scrollToFirst = scope => {
  if (!scope) {
    return false
  }
  let nodes
  if (scope.indexOf('#') > -1) {
    const [ id, _class, ] = scope.split(' ')
    const root = document.getElementById(id.replace('#', ''))
    if (!root) {
      return
    }
    nodes = root.querySelectorAll(_class)
  }
  else {
    nodes = document.querySelectorAll(scope)
  }
  const els = Array.prototype.slice.call(nodes).filter(d => !!d.offsetParent)
  if (!els.length) {
    return false
  }
  const targetEl = els[0]
  const { top, } = targetEl.getBoundingClientRect()
  window.scrollTo({ top: (window.pageYOffset + top) - 150, behavior: 'smooth', })
  if (targetEl.previousElementSibling) {
    const nodename = targetEl.previousElementSibling.nodeName.toLowerCase()
    if (nodename === 'input' || nodename === 'select') {
      targetEl.previousElementSibling.focus()
    }
  }
  return true
}

export const scrollToErrors = (scope = '') => {
  let domScope = '.invalid-feedback'
  if (scope) {
    domScope = `${scope} ${domScope}`
  }
  const hadErrors = scrollToFirst(domScope)
  if (hadErrors) {
    return
  }
  domScope = '.is-invalid'
  if (scope) {
    domScope = `${scope} ${domScope}`
  }
  scrollToFirst(domScope)
}

export function queryParamsToObject () {
  const params = new URLSearchParams(window.location.search)

  const paramsObj = {}

  for (let entry of params.entries()) {
    paramsObj[entry[0]] = entry[1]
  }

  return paramsObj
}

export function objectToQueryParams (obj) {
  return Object.keys(obj)
    .map(k => `${k}=${obj[k]}`)
    .join('&')
}

export function getArrayValueFromText (arr, text) {
  const obj = arr.filter(a => a.Text.toLowerCase() === text.toLowerCase())[0]
  if (obj) {
    return obj.Value
  }
  return null
}

/**
 * Removes an item from an array if it exists in the array.
 * Returns a new array with the item removed.
 * @param {Array} arr 
 * @param {Object} item 
 */
export function removeItemFromArray (arr, item) {
  // Get a copy of unique array items
  const newArr = [ ...new Set(arr), ]
  // Get the index of the item in the array
  const idx = newArr.indexOf(item)
  // If it's there, remove it
  if (idx > -1) {
    newArr.splice(idx, 1)
  }
  return newArr
}

/**
 * Creates a new array of unique items with the provided item included.
 * @param {Array} arr 
 * @param {Object} item
 * @returns {Array}
 */
export function addItemToArray (arr, item) {
  // Get a copy of the array items
  const newArr = [ ...arr, item, ]
  return [ ...new Set(newArr), ]
}

export const formatPhone = (phone) => {
  if (!phone) {
    return
  }
  if (phone.length === 11) {
    return `+${phone.substring(0, 1)} (${phone.substring(1,4)}) ${phone.substring(4, 7)}-${phone.substring(7)}`
  }
  else if (phone.length === 10) {
    return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`
  }
  return phone
}

export const importAllImages = (r) => {
  let images = {}
  r.keys().map((item) => images[item.replace('./', '')] = r(item) )
  return images
}

/**
 * extract plain text and json formatted string version of
 * the provided draftjs editor state
 * @param {EditorState} editorState a draftjs EditorState to read
 * @returns an array with elements [0] : plain text string, [1] : json string of content from the provided editor state
 */
export const extractContentStateValues = (editorState) => {
  let formattedContent = '', plainTextContent = ''
  if (editorState){
    // try to get current content and convert format state to JSON
    // as well as plain text
    try {
      const content = editorState.getCurrentContent()
      formattedContent = JSON.stringify(convertToRaw(content))
      plainTextContent = content.getPlainText()
    }
    catch (e)
    {
      console.error(e)
    }
  }
  return [ plainTextContent, formattedContent, ]
}

/**
 * convert from either a json content state string or 
 * a plain text string to a draftjs editor state
 *  returns an empty editorstate if nothing else
 * @param {string} contentString JSON or plain text string to initialize editor state with
 * @returns a draftjs EditorState populated with the provided content
 */
export const parseContentStateValue = (contentString) => {
  let contentState = null
  let parsed = false
  if (contentString) {
    // try to parse as JSON and create a ContentState
    try {
      const jsonContent = JSON.parse(contentString)
      contentState = convertFromRaw(jsonContent)
      parsed = true
    }
    catch {
      parsed = false
    }
    // if we didn't parse correctly, try treating it as a plain text string
    if (!parsed) {
      try {
        contentState = ContentState.createFromText(contentString)
        parsed = true
      } catch (e) {
        console.error(e)
      }
    }
  }

  // as a last resort just create an empty ContentState
  if (!contentState || !parsed) {
    contentState = ContentState.createFromText('')
  }
  // create an EditorState with the ContentState we created
  return EditorState.createWithContent(contentState)
}