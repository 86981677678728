import React from 'react'
import { Row, Input, } from 'reactstrap'
import { array, bool, object, func, number, string, PropTypes, } from 'prop-types'
import ConditionCheckboxes from './ConditionCheckboxes'
import BaseCondition from './BaseCondition'

export default class MultiSelectCondition extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      valid      : true,
      error      : '',
      otherValue : this.getOtherValue(),
    }
  }

  static propTypes = {
    value                    : array,
    condition                : object,
    data                     : array,
    readOnly                 : bool,
    onValueChanged           : func.isRequired,
    onConditionChecked       : func.isRequired,
    selected                 : bool,
    conditionValidityChanged : func.isRequired,
    label                    : PropTypes.oneOfType([ number, string, ]),
  }

  static defaultProps = {
    value                    : [],
    condition                : {},
    data                     : [],
    readOnly                 : false,
    onValueChanged           : null,
    onConditionChecked       : null,
    selected                 : false,
    conditionValidityChanged : null,
  }

  isValid = (newValue) => {
    const { value, condition, selected, readOnly, } = this.props
    if (!selected || readOnly) {
      return { valid: true, error: '', }
    }
    const { BurnPermitConditionName, } = condition
    let isValidNow = false
    let newError = ''
    if ((value.length === 1) && (newValue === value[0])) {
      newError = `At least one ${BurnPermitConditionName} value must be selected`
    }
    else if (this.otherIsChecked() && !this.state.otherValue) {
      newError = 'You must enter a description for `Other`'
    }
    else {
      isValidNow = true
    }
    return { valid: isValidNow, error: newError, }
  }

  componentDidMount () {
    const { selected, value, condition, } = this.props
    if (condition && selected) {
      const isValidNow = !selected || (value && value.length > 0)
      this.updateValidity(isValidNow, condition.BurnPermitConditionName)
    }
  }

  componentDidUpdate (prevProps) {
    const { selected, value, condition, } = this.props
    const { BurnPermitConditionName, } = condition

    if (!prevProps.selected && selected) {
      this.updateValidity(!value, BurnPermitConditionName)
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ otherValue: this.getOtherValue(), }, () => {
        let valid = value.length > 0
        if (valid && this.otherIsChecked()) {
          const error = this.isValid()
          if (!error.valid) {
            valid = error.valid
            this.setState({ ...error, })
            this.props.conditionValidityChanged(this.props.condition.BurnPermitConditionId, error.valid)
          }
        }
        if (valid) {
          this.updateValidity(valid, BurnPermitConditionName)
        }
      })
    }
  }

  getOtherValue = () => {
    return this.props.value.filter(v => typeof v === 'string')[0] || ''
  }

  updateValidity = (valid, condName = '') => {
    this.setState({
      valid,
      error: valid ? '' : `At least one ${condName} value must be selected`,
    }, () => this.props.conditionValidityChanged(this.props.condition.BurnPermitConditionId, valid))
  }

  onOtherChange = evt => {
    const { value, } = evt.target
    this.setState({ otherValue: value, }, () => {
      this.onValueChanged(this.state.otherValue)
    })
  }

  onValueChanged = (newValue) => {
    const { onValueChanged, conditionValidityChanged, condition, } = this.props
    const { valid, error, } = this.isValid(newValue)
    this.setState({ valid, error, }, () => {
      onValueChanged(condition.BurnPermitConditionId, newValue)
      conditionValidityChanged(condition.BurnPermitConditionId, valid)
    })
  }

  otherIsChecked = id => {
    const { data, value, } = this.props
    return data.some(d => (id === d.Value || value.includes(d.Value)) && d.Text === 'Other')
  }

  render () {
    const {
      data,
      condition,
      value,
      selected,
      onConditionChecked,
      label,
      readOnly,
    } = this.props
    const {
      BurnPermitConditionId,
      BurnPermitConditionDescription,
    } = condition
    const { 
      valid,
      error,
      otherValue,
    } = this.state
    
    return (
      <BaseCondition
        selected={selected}
        condition={condition}
        readOnly={readOnly}
        onConditionSelected={onConditionChecked}
        label={label}
      > 
        <Row className={(readOnly && !selected) ? 'grey-text' : null} aria-disabled={(readOnly && !selected)}>
          { BurnPermitConditionDescription }:
        </Row>
        <Row className={'ml-3 mb-2'}>
          <ConditionCheckboxes
            data={data}
            selectedData={value}
            readOnly={readOnly || !selected}
            className={'mr-2'}
            pfx={`dir-${BurnPermitConditionId}`}
            onValueChanged={this.onValueChanged}
          />
          {
            this.otherIsChecked() && <Input onChange={this.onOtherChange} value={otherValue}/>
          }
          {
            !readOnly && !valid && <div className={'cond-invalid-feedback'}>{ error }</div>
          }
        </Row>
      </BaseCondition>
    )
  }
}
