// Libraries
import React, { useCallback, useEffect, useState, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { Button, } from 'reactstrap'
import { number, } from 'prop-types'

// Hooks
import useDownloadPermitFile from '../../../hooks/useDownloadPermitFile'

// Selectors
import { permitSignatureState, } from '../../../selectors/burnPermitSignatureSelectors'
import { applicationSignatureDocuments, } from '../../../selectors/burnPermitSelectors'

// Utilities
import stopEvent from '../../../utilities/stopEvent'


const DownloadApplicationLinks = props => {
  const { burnPermitId, } = props
  // State and refs
  const [ fileInfo, setFileInfo, ] = useState(null)

  // Selectors
  const { isGettingSignatures, } = useSelector(permitSignatureState)
  const [ app, cert, ] = useSelector(state => applicationSignatureDocuments(state, burnPermitId))

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks
  const downloadDoc = useDownloadPermitFile(dispatch, burnPermitId, fileInfo)

  const downloadApp = useCallback(e => {
    stopEvent(e)
    setFileInfo({ fileName: app.DocumentName, documentId: app.BurnPermitDocumentId, })
  }, [ app, ])

  const downloadCert = useCallback(e => {
    stopEvent(e)
    setFileInfo({ fileName: cert.DocumentName, documentId: cert.BurnPermitDocumentId, })
  }, [ cert, ])

  useEffect(() => {
    if (fileInfo) {
      downloadDoc()
      setFileInfo(null)
    }
  }, [ downloadDoc, fileInfo, setFileInfo, ])

  if (burnPermitId < 0) {
    return 'Did not receive a `burnPermitId`, cannot build links.'
  }

  if (isGettingSignatures) {
    return <p>Getting signatures...</p>
  }

  let markup = []
  if (app) {
    markup.push(
      <Button
        key={'signed-app-doc'}
        color={'link'}
        onClick={downloadApp}
        size={'sm'}
      >
        Application<span className={'fa fa-cloud-download-alt ml-2'} />
      </Button>
    )
  }
  if (cert) {
    markup.push(
      <Button
        key={'signed-cert-doc'}
        color={'link'}
        onClick={downloadCert}
        size={'sm'}
      >
        Certificate<span className={'fa fa-cloud-download-alt ml-2'} />
      </Button>
    )
  }
  return markup
}

DownloadApplicationLinks.defaultProps = {
  burnPermitId: null,
}

DownloadApplicationLinks.propTypes = {
  burnPermitId: number,
}

export default React.memo(DownloadApplicationLinks)
