// Libraries
import { isEmpty, } from 'lodash'
import { all, put, select, call, fork, takeLatest, } from 'redux-saga/effects'

// Sagas
import { hideLoading, showLoading, } from './AppSagas'
import { uploadFiles, } from './FileSagas'
import { doFetch, getLookupData, showError, extractPayload, } from './ApiSagas'
import { getBurnPermit, getPermitFiles, updateTonnageAndFee, } from './BurnPermitSagas'
import {
  updateLocalModel,
  upsertLocalModel,
  upsertLocalModels,
  createLocalModel,
  destroyLocalModels,
  destroyLocalModel,
  replaceAll,
} from './OrmSagas'

// Reducers
import { AppTypes, } from '../redux/AppRedux'
import { ApiTypes, } from '../redux/ApiRedux'
import { UiTypes, } from '../redux/UiRedux'
import { BurnPermitFormTypes, } from '../redux/BurnPermitFormRedux'
import { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'
import BurnPermitPileGroupActions, { BurnPermitPileGroupTypes, } from '../redux/BurnPermitPileGroupRedux'

// Selectors
import { networkStateSelector, } from '../selectors/selectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'
import {
  burnPermitPileGroupsByPermitIdSelector,
  pileGroupsByPermitId,
  pileGroupById,
  activePileGroupId,
} from '../selectors/burnPermitPileGroupsSelectors'
import { activeBurnPermitIdSelector, permitApplicationDocuments, } from '../selectors/burnPermitSelectors'

// Models
import BurnPermitPileGroup from '../models/BurnPermitPileGroup'

// Utilities
import { compareFileNames, } from '../utilities/files'

// Constants
const BURN_PERMIT_PILE_GROUP_ENDPOINT = BurnPermitPileGroup.endpoint()
const BURN_PERMIT_PILE_GROUP_MODEL_NAME = BurnPermitPileGroup.modelName


export function* getBurnPileGroup ({ BurnPermitPileGroupId, }) {
  try {

    if (isNaN(parseInt(BurnPermitPileGroupId))) {
      yield call(showError, 'You must supply a valid Burn Permit Pile Group ID.')
      return
    }

    yield fork(getLookupData, { modelName: 'BurnType', })
    yield fork(getLookupData, { modelName: 'SpeciesWoodDensity', })

    const burnPermPileResp = yield call(doFetch, `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/${BurnPermitPileGroupId}`)

    if (burnPermPileResp.ok !== true) {
      yield call(showError, `An error occurred requesting Burn Permit Pile info for ID ${BurnPermitPileGroupId}`)
      return
    }

    yield call(upsertLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, burnPermPileResp.responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
}


export function* getBurnPileGroups ({ burnPermitId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (isNaN(parseInt(burnPermitId))) {
      yield call(showError, 'You must supply a valid Burn Permit ID.')
      return
    }

    yield fork(getLookupData, { modelName: 'BurnType', })
    yield fork(getLookupData, { modelName: 'SpeciesWoodDensity', })

    const burnPermPileResp = yield call(doFetch, `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/ForPermit/${burnPermitId}`)
    if (burnPermPileResp.ok !== true) {
      yield call(showError, `An error occurred requesting Burn Permit Pile Groups for Permit ID ${burnPermitId}`)
      return
    }
    yield call(replaceAll, BURN_PERMIT_PILE_GROUP_MODEL_NAME, burnPermPileResp.responseBody, { BurnPermitId: burnPermitId, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* downloadPileInfo () {
  try {
    const downloadResp = yield call(doFetch, `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/Download`)

    if (downloadResp.ok !== true) {
      yield call(showError, 'An error occurred downloading Burn Permit Pile Group info')
      return
    }

    yield call(upsertLocalModels, BURN_PERMIT_PILE_GROUP_MODEL_NAME, downloadResp.responseBody)
    
    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'PileInfo', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* createBurnPermitPileGroup ({ BurnPermitPileGroup, }) {
  const { online, } = yield select(networkStateSelector)
  if (BurnPermitPileGroup) {
    if (!online) {
      const { BurnPermitPileGroupId, } = BurnPermitPileGroup
      
      yield call(upsertLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, BurnPermitPileGroup)

      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitPileGroupTypes.CREATE_BURN_PERMIT_PILE_GROUP_REQUEST,
        url         : BURN_PERMIT_PILE_GROUP_ENDPOINT,
        method      : 'POST',
        keyName     : BurnPermitPileGroup.idAttribute,
        keyValue    : BurnPermitPileGroupId,
      })
      
      yield put(BurnPermitPileGroupActions.editBurnPermitPileGroup())
    }
    else {
      yield put({ type: AppTypes.SHOW_LOADING, })
    }
    yield put(BurnPermitPileGroupActions.createBurnPermitPileGroupRequest(BurnPermitPileGroup, online))
  }
}

function* updateBurnPermitPileGroup ({ BurnPermitPileGroup, }) {
  const { online, } = yield select(networkStateSelector)
  const { BurnPermitPileGroupId, } = BurnPermitPileGroup
  const localBurnPermitPileGroup = yield select(modelByIdSelector, { modelName: BURN_PERMIT_PILE_GROUP_MODEL_NAME, modelId: BurnPermitPileGroupId, })
  
  if (localBurnPermitPileGroup && !localBurnPermitPileGroup.IsLocal) {
    if (!online) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitPileGroupTypes.UPDATE_BURN_PERMIT_PILE_GROUP_REQUEST,
        url         : `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/${BurnPermitPileGroupId}`,
        method      : 'PUT',
        keyName     : BurnPermitPileGroup.idAttribute,
        keyValue    : BurnPermitPileGroupId,
      })
    }
    else {
      yield put({ type: AppTypes.SHOW_LOADING, })
    }
    yield put(BurnPermitPileGroupActions.updateBurnPermitPileGroupRequest(BurnPermitPileGroup, online))
  }
  
  if (!online) {
    const model = { ...BurnPermitPileGroup, Submit: true, }
    yield call(updateLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, BurnPermitPileGroupId, model)
  }
}

function* updateBurnPermitPileGroupSuccess (resp) {
  try {
    const pileGroup = yield call(extractPayload, resp)
    // Clear out any local only groups
    yield put({ type: BurnPermitPileGroupTypes.DELETE_LOCAL_PILE_GROUPS, })
    
    let burnPermitId
    if (Array.isArray(pileGroup)) {
      yield call(upsertLocalModels, BURN_PERMIT_PILE_GROUP_MODEL_NAME, pileGroup)
      burnPermitId = pileGroup.map(g => g.BurnPermitId)[0]
    }
    else {
      yield call(upsertLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, pileGroup)
      burnPermitId = pileGroup.BurnPermitId
    }
    
    yield put({ type: BurnPermitPileGroupTypes.EDIT_BURN_PERMIT_PILE_GROUP, })
    
    const validationInfo = yield select(burnPermitPileGroupsByPermitIdSelector, burnPermitId)
    if (validationInfo.isValid) {
      yield put({ type: BurnPermitFormTypes.COMPLETED_STEPS, StepIds: [ 5, ], })
    }

    if (resp.showSuccess) {
      // call get burn permit so the tonnage and fee update
      if (burnPermitId) {
        yield call(getBurnPermit, { burnPermitId, })
        yield fork(getPermitFiles, { permitId: burnPermitId, })
      }
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: AppTypes.HIDE_LOADING, })
  }
}

export function* submitOfflinePileEdits (localPermitId, serverPermitId) {
  try {
    // Get the local pile model
    const pileGroups = yield select(pileGroupsByPermitId, localPermitId)
    if (pileGroups.every(g => g.Submit)) {
      const body = pileGroups.map(g => {
        return {
          ...g._fields,
          BurnPermitPileGroupId : 0,
          BurnPermitId          : serverPermitId,
        }
      })
      // Update them
      const pileResp = yield call(doFetch, BURN_PERMIT_PILE_GROUP_ENDPOINT, { method: 'POST', body, })
      if (pileResp.ok) {
        yield fork(updateBurnPermitPileGroupSuccess, { payload: pileResp.responseBody, modelName: BURN_PERMIT_PILE_GROUP_MODEL_NAME, })
        return
      }
      yield call(showError, pileResp.responseBody)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}


export function* estimateConsumedTonnage ({ burnPermitId, }, ) {
  try {
    yield put({ type: AppTypes.SHOW_LOADING, })
    const resp = yield call(doFetch, `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/EstimateConsumedTonnage/${burnPermitId}`)
    if (!resp.ok) {
      yield call(showError, resp.responseBody ? resp.responseBody.error : '')
      return
    }
    yield call(upsertLocalModels, BURN_PERMIT_PILE_GROUP_MODEL_NAME, resp.responseBody)
    yield call(getPermitFiles, { permitId: burnPermitId, })
    yield fork(updateTonnageAndFee)
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: AppTypes.HIDE_LOADING, })
  }
}


function* addLocalPileGroup () {
  const burnPermitId = yield select(activeBurnPermitIdSelector)
  const { BurnPermitPileGroupId, } = yield call(createLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, { BurnPermitId: burnPermitId, })
  yield put({ type: BurnPermitPileGroupTypes.EDIT_BURN_PERMIT_PILE_GROUP, BurnPermitPileGroupId, })
}


function* deletePileGroup ({ BurnPermitPileGroupId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!Number.isInteger(parseInt(BurnPermitPileGroupId))) {
      BurnPermitPileGroupId = yield select(activePileGroupId)
    }
    const pileGroup = yield select(pileGroupById, BurnPermitPileGroupId)
    if (!pileGroup.IsLocal) {
      if (online) {
        yield put({ type: AppTypes.SHOW_LOADING, })
      }
      yield put(BurnPermitPileGroupActions.deleteBurnPermitPileGroupRequest(BurnPermitPileGroupId, pileGroup.BurnPermitId))
    }
    else {
      yield call(destroyLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, BurnPermitPileGroupId)
    }
    if (!online) {
      yield call(destroyLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, pileGroup.BurnPermitPileGroupId)
      if (pileGroup.IsLocal) {
        yield put({
          type        : ApiTypes.CANCEL_SUBMIT,
          action_type : BurnPermitPileGroupTypes.CREATE_BURN_PERMIT_PILE_GROUP_REQUEST,
          url         : BURN_PERMIT_PILE_GROUP_ENDPOINT,
          method      : 'POST',
          keyName     : BurnPermitPileGroup.options.idAttribute,
          keyValue    : BurnPermitPileGroupId,
        })
      }
    }
    yield put({ type: UiTypes.CLOSE_MODAL, })
  }
  catch (error) {
    yield call(showError, error)
  }
}


function* deleteLocalPileGroups () {
  yield call(destroyLocalModels, BURN_PERMIT_PILE_GROUP_MODEL_NAME, { IsLocal: true, })
}

function* deleteBurnPermitPileGroupSuccess (resp) {
  try {
    const success = yield call(extractPayload, resp)
    let BurnPermitId
    if (success) {
      yield put({ type: BurnPermitPileGroupTypes.EDIT_BURN_PERMIT_PILE_GROUP, })
      const localPileGroup = yield select(pileGroupById, resp.BurnPermitPileGroupId)
      BurnPermitId = localPileGroup.BurnPermitId
      yield call(destroyLocalModel, BURN_PERMIT_PILE_GROUP_MODEL_NAME, resp.BurnPermitPileGroupId)
      yield put({ type: AppTypes.SHOW_SUCCESS, })
    }
    
    const validationInfo = yield select(burnPermitPileGroupsByPermitIdSelector, BurnPermitId)
    if (validationInfo.isValid) {
      yield put({ type: BurnPermitFormTypes.COMPLETED_STEPS, StepIds: [ 5, ], })
    }
    yield call(updateTonnageAndFee)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: AppTypes.HIDE_LOADING, })
  }
}

function* requestFailed (resp) {
  try {
    if (!resp.payload) {
      return
    }
    const payload = yield call(extractPayload, resp)
    if (!payload) {
      return
    }
    let error = payload.error
    if (Array.isArray(payload.errors)) {
      error = payload.errors.join('\n\n')
    }
    else if (payload.errors !== null && typeof payload.errors === 'object' && !isEmpty(payload.errors)) {
      error = [ ...Object.values(payload.errors), ].join('\n\n')
    }
    yield call(showError, error)
    // Toggle off the state flag
    yield put({ type: BurnPermitPileGroupTypes.PILE_GROUP_REQUEST_FAILED, failed: false, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield put({ type: AppTypes.HIDE_LOADING, })
  }
}

/**
 * @typedef {object} BatchPileUpload
 * @property {number} permitId The PermitId to upload the batch pile files to
 * @property {Array<string>} files An array of file names used to locate the users files of batch piles
 */

/**
 * Uploads one or more files to create a batch of piles from and calculate tonnage and emmissions
 * @param {BatchPileUpload} BatchPileUpload The PermitId and array of filenames to upload a batch of pile groups
 * @returns
 */
export function* uploadBatchPiles ({ permitId, files, }) {
  let receivedPiles = false
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply a permitId to upload a Batch File of Pile Groups.')
      return
    }

    const currentFiles = yield select(permitApplicationDocuments, permitId)
    if (currentFiles.length) {
      const { errMessage, repeatingNames, } = yield call(compareFileNames, { files1: files, files2: currentFiles, })
      if (errMessage.length || repeatingNames.length) {
        yield call(showError, errMessage.join('\n\n'))
        return
      }
    }

    const url = `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/BatchUpload/${permitId}`
    const resp = yield call(uploadFiles, { url, files, })

    const { responseBody, ok, } = resp
    if (ok !== true) {
      let error = 'An error occurred uploading the batch pile file to the Permit Application'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }
    receivedPiles = ok

    yield call(upsertLocalModels, BURN_PERMIT_PILE_GROUP_MODEL_NAME, responseBody)
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    const sagas = [
      call(hideLoading),
      put({ type: UiTypes.CLOSE_MODAL, }),
      call(getBurnPermit, { burnPermitId: permitId, updateFee: true, }),
      call(getPermitFiles, { permitId, }),
    ]
    // If we didn't get piles back, call to get them just in case
    // they were made and something else failed in the server side
    // process that caused them to not be returned
    if (receivedPiles !== true) {
      sagas.push(call(getBurnPileGroups, { burnPermitId: permitId, }))
    }
    yield all(sagas)
  }
}

export function* deleteAllBurnPileGroups ({ permitId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (isNaN(parseInt(permitId))) {
      yield call(showError, 'You must supply a valid Burn Permit ID to delete all Pile Groups.')
      return
    }

    const burnPermPileResp = yield call(doFetch, `${BURN_PERMIT_PILE_GROUP_ENDPOINT}/ForPermit/${permitId}`, { method: 'DELETE', })
    if (burnPermPileResp.ok !== true) {
      yield call(showError, `An error occurred deleting all Burn Permit Pile Groups for Permit ID ${permitId}`)
      return
    }
    yield all([
      call(replaceAll, BURN_PERMIT_PILE_GROUP_MODEL_NAME, [], { BurnPermitId: permitId, }),
      put({ type: UiTypes.CLOSE_MODAL, }),
    ])
  }
  catch (error) {
    yield call(showError, error)
  }
}


export const BurnPermitPileGroupSagas = [
  takeLatest(BurnPermitPileGroupTypes.GET_BURN_PILE_GROUP, getBurnPileGroup),
  takeLatest(BurnPermitPileGroupTypes.GET_BURN_PILE_GROUPS, getBurnPileGroups),
  takeLatest(BurnPermitPileGroupTypes.CREATE_BURN_PERMIT_PILE_GROUP, createBurnPermitPileGroup),
  takeLatest(BurnPermitPileGroupTypes.UPDATE_BURN_PERMIT_PILE_GROUP, updateBurnPermitPileGroup),
  takeLatest(BurnPermitPileGroupTypes.UPDATE_BURN_PERMIT_PILE_GROUP_SUCCESS, updateBurnPermitPileGroupSuccess),
  takeLatest(BurnPermitPileGroupTypes.ESTIMATE_CONSUMED_TONNAGE, estimateConsumedTonnage),
  takeLatest(BurnPermitPileGroupTypes.ADD_LOCAL_PILE_GROUP, addLocalPileGroup),
  takeLatest(BurnPermitPileGroupTypes.DELETE_PILE_GROUP, deletePileGroup),
  takeLatest(BurnPermitPileGroupTypes.DELETE_LOCAL_PILE_GROUPS, deleteLocalPileGroups),
  takeLatest(BurnPermitPileGroupTypes.DELETE_BURN_PERMIT_PILE_GROUP_SUCCESS, deleteBurnPermitPileGroupSuccess),
  takeLatest(BurnPermitPileGroupTypes.PILE_GROUP_REQUEST_FAILED, requestFailed),
  takeLatest(BurnPermitPileGroupTypes.UPLOAD_BATCH_PILES, uploadBatchPiles),
  takeLatest(BurnPermitPileGroupTypes.DELETE_ALL_PILE_GROUPS, deleteAllBurnPileGroups),
]