import {
  createReducer,
  createActions,
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators, } = createActions({
  // This is used to trigger the geocode request
  geocode                 : [ 'address', 'city', 'state', 'zip', 'singleLine', ],
  setIsGeocoding          : null,
  getTRSExtent            : [ 'section', 'township', 'range', 'rangeDir' , ],
  trsSuccess              : [ 'result', ],
  geocodeSuccess          : [ 'result', ],
  geocodeError            : [ 'error', ],
  resetGeoCoordinate      : null,
  clearGeocodeResult      : null,
  clearTRSResult          : null,
  getTrsByCoord           : [ 'latitude', 'longitude',  ],
  updateGeocodedLocation  : [ 'region', 'trs', 'fireDistrict', 'county', 'address', ],
  geocodeLocationSection  : [ 'latitude', 'longitude', ],
  resetGeolocatedLocation : null,
  geocodeAddressForm      : [ 'address', 'city', 'state', 'zip', 'singleLine', ],
})

export const GeoCoordinateTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Object.freeze({
  GeocodeResult : null,
  IsGeocoding   : false,
  TRSResult     : null,
  TRSCodeResult : null,
  Error         : null,
  LatLong       : {
    Latitude  : null,
    Longitude : null,
  },
  geocodedLocation  : {},
  geocodingLocation : false,
})

/* ------------- Reducers ------------- */

export const resetGeoCoordinate = () => {
  return INITIAL_STATE
}

export const setIsGeocoding = (state) => {
  const newState = Object.assign({}, state)
  newState.IsGeocoding = !newState.IsGeocoding
  return newState
}

export const geocodeSuccess = (state, { result, }) => {
  const newState = Object.assign({}, state)
  newState.GeocodeResult = result
  newState.Error = null
  newState.IsGeocoding = false
  return newState
}

export const trsSuccess = (state, { result, }) => {
  const newState = Object.assign({}, state)
  newState.TRSResult = result
  newState.Error = null
  newState.IsGeocoding = false
  return newState
}

export const geocodeError = (state, { error, }) => {
  const newState = Object.assign({}, state)
  newState.Error = error
  newState.IsGeocoding = false
  return newState
}

export const clearGeocodeResult = (state) => {
  const newState = Object.assign({}, state)
  newState.GeocodeResult = null
  return newState
}

export const clearTRSResult = (state) => {
  const newState = Object.assign({}, state)
  newState.TRSResult = null
  return newState
}

export const geocodeLocationSection = (state) => {
  return { ...state, geocodingLocation: true, }
}

export const updateGeocodedLocation = (state, { region, trs, fireDistrict, county, address, }) => {
  const geocodedLocation = { ...state.geocodedLocation, }
  if (region) {
    geocodedLocation.region = region
  }
  if (trs) {
    geocodedLocation.trs = trs 
  }
  if (fireDistrict) {
    geocodedLocation.fireDistrict = fireDistrict
  }
  if (county) {
    geocodedLocation.county = county
  }
  if (address) {
    geocodedLocation.address = address
  }

  return { ...state, geocodedLocation, geocodingLocation: false, }
}

export const resetGeolocatedLocation = (state) => {
  return {
    ...state,
    geocodedLocation  : INITIAL_STATE.geocodedLocation,
    geocodingLocation : false,
  }
}

/* ------------- Hookup Reducers To Types ------------- */
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_GEO_COORDINATE]      : resetGeoCoordinate,
  [Types.GEOCODE_SUCCESS]           : geocodeSuccess,
  [Types.TRS_SUCCESS]               : trsSuccess,
  [Types.GEOCODE_ERROR]             : geocodeError,
  [Types.SET_IS_GEOCODING]          : setIsGeocoding,
  [Types.CLEAR_GEOCODE_RESULT]      : clearGeocodeResult,
  [Types.CLEAR_TRS_RESULT]          : clearTRSResult,
  [Types.UPDATE_GEOCODED_LOCATION]  : updateGeocodedLocation,
  [Types.GEOCODE_LOCATION_SECTION]  : geocodeLocationSection,
  [Types.RESET_GEOLOCATED_LOCATION] : resetGeolocatedLocation,
})
