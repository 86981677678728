// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { array, func, object, } from 'prop-types'
import { Toast, ToastBody, ToastHeader, } from 'reactstrap'

// Actions
import ApiActions from '../redux/ApiRedux'

// Components
import withRouter from './withRouter'

class ErrorMessage extends React.Component {

  static propTypes = {
    // from withRouter HOC
    location: object,

    requestError   : array,
    ClearError     : func,
    ClearAllErrors : func,
  }

  onClick = evt => {
    const { id, } = evt.target.dataset
    this.props.ClearError(id)
  }

  // This should clear the errors on change of the route
  componentDidUpdate (prevProps) {
    const { location, ClearAllErrors, } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      ClearAllErrors()
    }
  }
  
  render () {
    const { requestError, } = this.props
    if (requestError.length === 0) {
      return null
    }
    const errors = []
    for (let i = 0, len = requestError.length; i < len; i++) {
      const error = requestError[i]
      errors.push(
        <Toast className={'error-message'} key={'error-message-' + i}>
          <ToastHeader className={'pl-2 pr-1'}>
            <span className={'fa fa-ban mr-2 text-danger'}></span>
            Error
            <small className={'ml-2'}>{error.occurredAt}</small>
            <button
              className={'ml-2 mb-1 close fa fa-times'}
              data-dismiss={'toast'}
              aria-label={'Close'}
              data-id={error.id}
              onClick={this.onClick}
            ></button>
          </ToastHeader>
          <ToastBody>
            {error.message}
          </ToastBody>
        </Toast>
      )
    }
    return <div className={'error-messages'}>{errors}</div>
  }
}
  
const mapStateToProps = (state) => {
  return {
    requestError: state.api.requestError,
  }
}

const mapDispatchToProps = {
  ClearError     : ApiActions.clearError,
  ClearAllErrors : ApiActions.clearAllErrors,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorMessage))