import { createReducer, createActions, } from 'reduxsauce'
import orm from '../models/index'

/* ------------- Types and Action Creators ------------- */

const  { Types, Creators, } = createActions({
  upsert       : [ 'modelName', 'properties', ],
  upsertRange  : [ 'modelName', 'records', ],
  destroy      : [ 'modelName', 'modelId', ],
  destroyRange : [ 'modelName', 'modelIdArray', ],
  destroyAll   : [ 'modelName', 'filterObj', ],
  insert       : [ 'modelName', 'properties', ],
  replaceAll   : [ 'modelName', 'objects', 'filterObj', ],
  update       : [ 'modelName', 'modelId', 'updateObj', ],
})

export const OrmTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = { }

/* ------------- Reducers ------------- */

export const insert = (state, { modelName, properties, }) => {
  const session = orm.session(state)

  const ormModel = session[modelName]

  ormModel.create(properties)

  return session.state
}

export const update = (state, { modelName, modelId, updateObj, }) => {
  const session = orm.session(state)
  const ormModel = session[modelName]
  if (!ormModel) {
    return state
  }
  const entity = ormModel.withId(modelId)
  if (!entity) {
    return state
  }
  entity.update(updateObj)
  return session.state
}


export const upsert = (state, { modelName, properties, }) => {
  const session = orm.session(state)
  
  const ormModel = session[modelName]
  
  ormModel.upsert(properties)

  return session.state
}

/**
 * This function will map the key/values from `data` to the properties declared in `model`
 * @param {Model} model - The Redux-ORM model to map data to
 * @param {object} data - An object of key/values to map data to the ORM model
 * @param {boolean} basePropsOnly - A flag to prevent/allow mapping of relationship models.
 *   Only set this if you are providing EXACTLY that data that should be written to the related table.
 *   The ORM will overwrite any and all existing model data if a model with the same ID exists.
 */
export const mapDataToModel = (model, data, basePropsOnly = true) => {
  // Get the property/field names of the target model
  let modelFields = Object.keys(model.fields)
  if (basePropsOnly === true) {
    modelFields = modelFields.filter(f => model.fields[f].constructor.name !== 'ForeignKey')
  }
  // Get the property/field names of the data object provided
  // and filter them to only those that match those that are in
  // the target model
  // Then map the filtered property/field names
  const matchingDataFields = Object.keys(data).filter(d => modelFields.includes(d))
  // Start a new object to put the matching key/values
  const mappedData = {}
  // Loop the matching data fields and update the mapped data object
  for (let k = 0, kLen = matchingDataFields.length; k < kLen; k++) {
    const key = matchingDataFields[k]
    const value = data[key]
    mappedData[key] = value
  }
  // Return the mapped data
  return mappedData
}

/**
   * Reduce state changes by updating the ORM in one go
   * by passing an array of the records to update instead
   * of calling for an upsert state change within a loop
   */
export const upsertRange = (state, { modelName, records, }) => {
  const session = orm.session(state)

  const ormModel = session[modelName]

  for (let r = 0, rLen = records.length; r < rLen; r++) {
    const properties = records[r]
    ormModel.upsert(properties)
  }

  return session.state
}

export const destroy = (state, { modelName, modelId, }) => {
  const session = orm.session(state)

  const ormModel = session[modelName]

  const modelInstance = ormModel.withId(modelId)

  if (modelInstance && typeof modelInstance.delete === 'function') {
    modelInstance.delete()
  }

  return session.state
}

export const destroyRange = (state, { modelName, modelIdArray, }) => {
  if (!Array.isArray(modelIdArray)) {
    return state
  }
  const session = orm.session(state)
  const ormModel = session[modelName]
  const idAttr = ormModel.idAttribute
  const dataToDelete = ormModel.filter(d => modelIdArray.includes(d[idAttr]))
  dataToDelete.delete()
  return session.state
}

export const destroyAll = (state, { modelName, filterObj, }) => {
  const session = orm.session(state)

  const targetModel = session[modelName]
  
  if (!targetModel) {
    return state
  }

  try {
    let targetData
    if (typeof filterObj === 'object' || typeof filterObj === 'function') {
      targetData = targetModel.filter(filterObj).all()
    }
    else {
      targetData = targetModel.all()
    }
    if (targetData.exists()) {
      targetData.delete()
    }
  }
  catch (e) {
    console.error(e)
  }

  return session.state
}

/**
 * Single action to clear local store for the given model name and write
 * in the objects provided
 */
export const replaceAll = (state, { modelName, objects, filterObj, }) => {
  const session = orm.session(state)

  const targetModel = session[modelName]
  
  if (!targetModel) {
    return state
  }

  try {
    let targetData
    if (typeof filterObj === 'object' || typeof filterObj === 'function') {
      targetData = targetModel.filter(filterObj).all()
    }
    else {
      targetData = targetModel.all()
    }
    if (targetData.exists()) {
      targetData.delete()
    }

    if (Array.isArray(objects)) {
      for (let r = 0, rLen = objects.length; r < rLen; r++) {
        const properties = objects[r]
        targetModel.create(properties)
      }
    }
  }
  catch (e) {
    console.error(e)
  }

  return session.state
}

export const apiGetSuccess = (state, { modelName, payload, }) => {
  const session = orm.session(state)
  
  const ormModel = session[modelName]

  ormModel.create(payload)
  
  return session.state
}


export const apiUpdateSuccess = (state, { modelName, payload, }) => {
  const session = orm.session(state)

  // Not all PUT responses will contain a payload, so only update locally if they do
  if (payload) {
    const ormModel = session[modelName]

    const targetId = payload[ormModel.idAttribute]

    try {

      let sessionItem = ormModel.withId(targetId)
      if (sessionItem) {
        sessionItem.update(payload)
      }
      else {
        sessionItem = ormModel.create(payload)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  
  return session.state
}

export const apiDeleteSuccess = (state, { modelName, modelId, }) => {
  const session = orm.session(state)

  const ormModel = session[modelName]

  try {

    let sessionItem = ormModel.withId(modelId)
    if (sessionItem) {
      sessionItem.delete()
    }
  }
  catch (e) {
    console.error(e)
  }
  
  return session.state
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(orm.getEmptyState(), {
  [Types.INSERT]        : insert,
  [Types.UPSERT]        : upsert,
  [Types.UPSERT_RANGE]  : upsertRange,
  [Types.DESTROY]       : destroy,
  [Types.DESTROY_RANGE] : destroyRange,
  [Types.DESTROY_ALL]   : destroyAll,
  [Types.REPLACE_ALL]   : replaceAll,
  [Types.UPDATE]        : update,
})