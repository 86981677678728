// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { Formik, Field, } from 'formik'
import { array, func, bool, string, number, object, } from 'prop-types'
import { Card, CardBody, Row, Col, Label, Button, Badge, FormGroup, ButtonGroup, } from 'reactstrap'

// Components
import { BurnPermitFormSection, } from '.'
import { Select, ValidatingField, } from '../FormControls'
import { ForestHealthDocAlert, } from '../Alerts'

// Reducers
import ForestHealthActions from '../../redux/ForestHealthRedux'

// Selectors
import { permitForestHealthData, problemTypesSelector, permitNeedsForestHealthDoc, } from '../../selectors/forestHealthSelectors'
import { modelValidationSchemaSelector, } from '../../selectors/modelSelectors'
import { userCanReviewFHExemption, } from '../../selectors/burnPermitPermissionSelectors'

// Utilities
import { dateFormatter, } from '../../utilities'
import stopEvent from '../../utilities/stopEvent'


class ForestHealthExemptionSection extends React.Component {

  constructor (props) {
    super(props)

    this.validateSection = this.validateSection.bind(this)
    this.submit = this.submit.bind(this)
    this.setFormikNode = this.setFormikNode.bind(this)
  }

  static propTypes = {
    title                  : string,
    online                 : bool,
    sectionId              : number,
    burnPermitId           : number,
    ProblemTypes           : array,
    readOnly               : bool,
    forestHealthData       : object,
    valSchema              : object,
    isSigned               : bool,
    showSaveControls       : bool,
    canReviewExemption     : bool,
    needsForestHealthDoc   : bool,
    GetForestHealthData    : func,
    CreateForestHealthData : func,
    UpdateForestHealthData : func,
    userCanReviewExemption : bool,
    isActive               : bool,
  }

  componentDidMount () {
    const { forestHealthData, isActive, } = this.props
    if (isActive && forestHealthData && forestHealthData.ForestHealthExemptId) {
      this.props.GetForestHealthData(forestHealthData.ForestHealthExemptId)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isActive !== true && this.props.isActive === true && this.props.forestHealthData) {
      this.props.GetForestHealthData(this.props.forestHealthData.ForestHealthExemptId)
    }
  }

  async validateSection ({ evt, submit = true, }) {
    if (evt) {
      stopEvent(evt)
    }
    if (this.formik) {
      const errs = await this.formik.validateForm()
      const isValid = !errs || Object.keys(errs).length === 0
      if (isValid && submit) {
        this.formik.submitForm()
      }
      return isValid
    }
    return false
  }

  submit (values) {
    if (!values.ForestHealthExemptId) {
      this.props.CreateForestHealthData(this.props.burnPermitId, values)
    }
    else {
      this.props.UpdateForestHealthData(this.props.burnPermitId, values.ForestHealthExemptId, values)
    }
  }

  setFormikNode (node) {
    this.formik = node
  }
  
  render () {
    const { title, online, canReviewExemption, sectionId, readOnly, forestHealthData, showSaveControls, needsForestHealthDoc, valSchema, userCanReviewExemption, } = this.props
  
    return <BurnPermitFormSection
      title={title}
      validateSection={this.validateSection}
      sectionId={sectionId}
      sectionBadge={<Badge color={'success'} className={'ml-3'}><span className={'fas fa-tree'}></span></Badge>}
      showSaveControls={showSaveControls || canReviewExemption}
    >
      <Card className={'w-100'}>
        <CardBody>
          { needsForestHealthDoc && <Row><ForestHealthDocAlert /></Row> }
          { !online && <p>You <b>cannot</b> edit exemption data while offline.</p> }
          <Formik
            initialValues={{
              ForestHealthExemptId              : forestHealthData ? forestHealthData.ForestHealthExemptId : '',
              ProblemTypeId                     : forestHealthData ? forestHealthData.ProblemTypeId : '',
              AlternativeToRxFire               : forestHealthData ? forestHealthData.AlternativeToRxFire : '',
              AlternativeNotAppropriateReason   : forestHealthData ? forestHealthData.AlternativeNotAppropriateReason : '',
              ForestHealthExemptionApprovalFlag : forestHealthData ? forestHealthData.ForestHealthExemptionApprovalFlag : '',
              ForestHealthExemptionApprovalBy   : forestHealthData ? forestHealthData.ForestHealthExemptionApprovalBy : '',
              ForestHealthExemptionApprovalDate : forestHealthData ? forestHealthData.ForestHealthExemptionApprovalDate : '',
            }}
            enableReinitialize={true}
            validationSchema={valSchema}
            onSubmit={this.submit}
            innerRef={this.setFormikNode}
          >
            {({ values, }) => (
              <>
                <Row>
                  <Col xs={12} md={6} lg={5} xl={4}>
                    <FormGroup>
                      <Field name={'ProblemTypeId'}>
                        {({ field, form, }) => (
                          <Select
                            label={'Forest Health Problem'}
                            items={this.props.ProblemTypes}
                            propertyName={field.name}
                            {...field}
                            selectedValue={field.value || -1}
                            errorMessage={form.errors[field.name]}
                            readOnly={readOnly}
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} lg={7} xl={8}>
                    <FormGroup>
                      <Label for={'txt-alternative-reason'}>Describe the forest health situation and the objectives to be achieved with burning</Label>
                      <Field
                        name={'AlternativeToRxFire'}
                        type={'textarea'}
                        component={ValidatingField}
                        id={'txt-alternative-reason'}
                        placeholder={'Type out reason here'}
                        className={'form-control'}
                        readOnly={readOnly}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {
                    userCanReviewExemption && canReviewExemption &&
                      <Col xs={12} md={6} lg={5} xl={4}>
                        <FormGroup>
                          <Label>Approve or Reject Forest Health Exemption</Label>
                          <br/>
                          <Field name={'ForestHealthExemptionApprovalFlag'}>
                            {({ field, form, }) => (
                              <ButtonGroup>
                                <Button size={'sm'} color={'light'} onClick={() => form.setFieldValue(field.name, true)} active={field.value === true}>Approve</Button>
                                <Button size={'sm'} color={'light'} onClick={() => form.setFieldValue(field.name, false)} active={field.value === false}>Reject</Button>
                              </ButtonGroup>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                  }
                  { values.ForestHealthExemptionApprovalFlag === false 
                    ? <Col xs={12} md={6} lg={7} xl={8}>
                      <Label for={'txt-alternative-not-appropriate'}>Alternative reason not appropriate</Label>
                      <Field
                        name={'AlternativeNotAppropriateReason'}
                        component={'textarea'}
                        id={'txt-alternative-not-appropriate'}
                        placeholder={'Type out reason here'}
                        className={'form-control'}
                        readOnly={!canReviewExemption}
                      />
                    </Col>
                    : values.ForestHealthExemptionApprovalBy &&
                        <Col xs={12} md={6} lg={7} xl={8}>
                          <small>Approved By: {values.ForestHealthExemptionApprovalBy}</small><br/>
                          <small>Approved On: {dateFormatter(values.ForestHealthExemptionApprovalDate, 'MM-DD-YYYY hh:mm:ss a')}</small>
                        </Col>
                  }
                </Row>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </BurnPermitFormSection>
  }
}

const mapStateToProps = (state, props) => {

  // DNR Users can Approve/Deny the Forest Health Exemption value if the application is not Pending
  const userCanReviewExemption = userCanReviewFHExemption(state)
  const canReviewExemption = props.showSaveControls || (userCanReviewExemption && props.applicationStatus !== 'Pending')

  const { online, } = state.offline
  const { applications, activeBurnPermitId, } = state.BurnPermitForm
  const isActive = applications[activeBurnPermitId].activeStep === props.sectionId
  return {
    online,
    isActive,
    canReviewExemption,
    valSchema            : modelValidationSchemaSelector(state, 'ForestHealthExempt'),
    ProblemTypes         : problemTypesSelector(state),
    forestHealthData     : permitForestHealthData(state, props.burnPermitId),
    needsForestHealthDoc : permitNeedsForestHealthDoc(state, props.burnPermitId),
    userCanReviewExemption,
  }
}

const mapDispatchToProps = {
  GetForestHealthData    : ForestHealthActions.getForestHealthData,
  CreateForestHealthData : ForestHealthActions.createForestHealthData,
  UpdateForestHealthData : ForestHealthActions.updateForestHealthData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForestHealthExemptionSection) 