import { attr, Model, fk, } from 'redux-orm'

export default class BurnPermitApplicationStatusXref extends Model {
  static get modelName () {
    return 'BurnPermitApplicationStatusXref'
  }

  toString () {
    return `${this.BurnPermitApplicationStatusXrefName}`
  }

  static endpoint (permitId, history = false) {
    const url = `BurnPermits/${permitId}/ApplicationStatus`
    if (history === false) {
      return url
    }
    return `${url}/History`
  }
  
  static deleteEndpoint (permitId, xrefId) {
    const url = `BurnPermits/${permitId}/ApplicationStatus/${xrefId}`
    return url
  }

  static get options () {
    return {
      idAttribute: 'BurnPermitApplicationStatusXrefId',
    }
  }

  static get fields () {
    return {
      // Model-Specific Properties
      BurnPermitApplicationStatusXrefId : attr(),
      BurnPermitId                      : fk ({ to: 'BurnPermit', relatedName: 'BurnPermitApplicationStatusXrefs', as: 'BurnPermit', }),
      BurnPermitApplicationStatusId     : fk({ to: 'BurnPermitApplicationStatus', relatedName: 'BurnPermitApplicationStatusXrefs', as: 'BurnPermitApplicationStatus', }),
      StatusDate                        : attr(),
      Comment                           : attr(),
      AssignedToId                      : fk({ to: 'ApplicationUser', relatedName: 'ApplicationUsers', as: 'AssignedTo', }),
      SiteInspected                     : attr(),
      InternalOnly                      : attr(),
      ApplicationSection                : attr(),
  
      // Audit Properties
      CreateDate : attr(),
      CreateBy   : attr(),
      UpdateDate : attr(),
      UpdateBy   : attr(),
    }
  }
}