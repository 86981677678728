// Libraries
import React from 'react'
import { DangerIcon, } from '../../Icons'

import style from './style'

function Incomplete () {
  return <DangerIcon style={style} title={'You still need to complete this portion of your profile'} />
}

export default Incomplete