import { createSelector, } from 'reselect'

import {
  addressMapper,
  phoneMapper,
  emailMapper,
  ormSelector,
  ormWithPropSelector,
  propsSelector,
  ormByIdSelector,
} from './selectors'
import { authPropsSelector, } from './userSelectors'
import { userPersonIdSelector, } from './userSelectors'
import { userCanMakeSMDecisions, } from './burnRequestPermissionSelectors'
import { userOnlySelectsGovCustType, userOnlySelectsInternalType, userOnlySelectsPublicTypes, } from './permissionSelectors'
import {
  PERSON_TYPE_DNR_STAFF,
  PERSON_TYPE_GOV_CUST,
  PERSON_TYPE_PRIVATE,
  PERSON_TYPE_GOV_AGENT,
  PERSON_TYPE_AGENT,
  mapGroupToReadableName,
} from './env'
import { getNow, getTodaysDate, isAfter, } from '../utilities'

//#region base selectors

const personStateSelector = state => state.Person
const sessionSelector = session => session

//#endregion

//#region mapping methods

export const personMapper = p => {
  if (!p) {
    return null
  }
  return {
    PersonId          : p.PersonId,
    PersonFirstName   : p.PersonFirstName || '',
    PersonMiddleName  : p.PersonMiddleName || '',
    PersonLastName    : p.PersonLastName || '',
    IsUser            : p.IsUser || false,
    ParentId          : p.ParentId,
    ConvertedToAgency : p.ConvertedToAgency,
    EmailId           : p.EmailId,
    ContactMethodId   : p.ContactMethodId || '',
    PersonTypeId      : p.PersonTypeId || '',
    CreateDate        : p.CreateDate,
    CreateBy          : p.CreateBy,
    UpdateDate        : p.UpdateDate,
    UpdateBy          : p.UpdateBy,
  }
}

const mapPersonForDataTable = p => {
  let person = {
    PersonId         : p.PersonId,
    PersonTypeName   : '',
    PersonFirstName  : p.PersonFirstName || '',
    PersonMiddleName : p.PersonMiddleName || '',
    PersonLastName   : p.PersonLastName || '',
    IsUser           : p.IsUser || false,
    Email            : '',
    Region           : '',
    Agency           : '',
    Phone            : '',
  }
  if (p.PersonType) {
    person.PersonTypeName = p.PersonType.PersonTypeName || ''
  }
  if (p.Email) {
    person.Email = p.Email.EmailAddress
  }
  if (p.Regions !== null && p.Regions.exists()) {
    person.Region = p.Regions.first().RegionName
  }
  if (p.Agencies !== null && p.Agencies.exists()) {
    person.Agency = p.Agencies.first().AgencyName
  }
  if (p.Phones !== null && p.Phones.exists()) {
    person.Phone = p.Phones.first().PhoneNumber
  }
  return person
}

const personTypeMapper = type => {
  return {
    Value : type.PersonTypeId,
    Text  : type.PersonTypeName,
  }
}

//#endregion


//#region person type helper methods

const _getGovCustPersonTypes = (personTypes) => {
  if (!personTypes) {
    return []
  }
  let query = personTypes.all().filter({ PersonTypeName: PERSON_TYPE_GOV_CUST, })
  if (!query.exists()) {
    return []
  }
  return query.toRefArray()
}

const _getPublicPersonTypes = (personTypes) => {
  if (!personTypes) {
    return []
  }
  const query = personTypes.all()
    .filter(t => t.PersonTypeName !== PERSON_TYPE_DNR_STAFF && t.PersonTypeName !== PERSON_TYPE_GOV_CUST)
  if (!query.exists()) {
    return []
  }
  return query.toRefArray()
}

const _getPersonType = (person) => {
  let pType = null
  if (person && person.PersonType) {
    pType = person.PersonType.PersonTypeName
  }
  return pType
}

/** Determine if a person model's type is `DNR Staff` */
const _getDNRPersonTypes = (personTypes) => {
  if (!personTypes) {
    return []
  }
  let query = personTypes.all().filter({ PersonTypeName: PERSON_TYPE_DNR_STAFF, })
  if (!query.exists()) {
    return []
  }
  return query.toRefArray()
}

/** Determine if a person model's type is `DNR Staff` */
const _personIsDnrStaffType = (person) => _getPersonType(person) === PERSON_TYPE_DNR_STAFF
/** Determine if a person model's type is `WA State Gov't Customer` */
const _personIsGovCustType = (person) => _getPersonType(person) === PERSON_TYPE_GOV_CUST

/** 
 * Get the `PersonTypeId` for a given `PersonTypeName` in a `PersonType` QuerySet
 * @param {QuerySet} personTypes an ORM QuerySet of person types
 * @param {string} typeName the `PersonTypeName` to retrieve `PersonTypeId` for
 */
const _getPersonTypeIdByName = (personTypes, typeName) => {
  const qs = personTypes.filter({ PersonTypeName: typeName, })
  if (!qs.exists()){
    return null
  }
  return qs.first().PersonTypeId
}

//#endregion


//#region person type selectors

const getPersonTypeForPersonId = createSelector(
  sessionSelector,
  propsSelector,
  ({ Person, }, personId) => {
    const person = Person.withId(personId)
    if (!person || !person.PersonTypeId || !person.PersonType) {
      return false
    }

    return person.PersonType.PersonTypeName
  }
)

/**
 * Returns bool indicating if the person is an Agency user or not
 * @param {Object} state 
 * @returns {Boolean}
 */
export const personIsAgentType = ormByIdSelector(
  (session, personId) => {
    if (isNaN(personId)) {
      return false
    }
    const PersonTypeName = getPersonTypeForPersonId(session, personId)
    return PersonTypeName === PERSON_TYPE_AGENT
  }
)

/**
 * Returns bool indicating if the person is an Agency user or not
 * @param {Object} state 
 * @returns {Boolean}
 */
export const personIsAgentOrAgencyPerson = ormByIdSelector(
  (session, personId) => {
    if (isNaN(personId)) {
      return false
    }
    const PersonTypeName = getPersonTypeForPersonId(session, personId)
    return PersonTypeName === PERSON_TYPE_GOV_AGENT || PersonTypeName === PERSON_TYPE_AGENT || PersonTypeName === PERSON_TYPE_GOV_CUST
  }
)

export const agentPersonTypeIds = ormSelector(
  ({ PersonType, }) => {
    if (!PersonType) {
      return []
    }
    const query = PersonType.filter(x => x.PersonTypeName === PERSON_TYPE_GOV_AGENT || x.PersonTypeName === PERSON_TYPE_AGENT || x.PersonTypeName === PERSON_TYPE_GOV_CUST)
    if (!query.exists()) {
      return []
    }
    return query.toRefArray().map(x => x.PersonTypeId)
  }
)

export const personTypesForPerson = ormByIdSelector(
  ({ Person, PersonType, }, PersonId) => {
    let types = [], person = null
    if (!isNaN(PersonId)) {
      person = Person.withId(PersonId)
    }
    if (_personIsDnrStaffType(person)) {
      types = _getDNRPersonTypes(PersonType)
    } else if (_personIsGovCustType(person)) {
      types = _getGovCustPersonTypes(PersonType)
    } else {
      types = _getPublicPersonTypes(PersonType)
    }
    return types.map(personTypeMapper)
  }
)

export const personIsDnrSelector = ormByIdSelector(
  ({ Person, }, PersonId) => {
    let person = null
    if (PersonId) {
      person = Person.withId(PersonId)
    }
    return _personIsDnrStaffType(person)
  }
)

/** 
 * Exported wrapper for `_getPersonTypeIdByName`:
 * Get the `PersonTypeId` for a given `PersonTypeName`
*/
export const getPersonTypeIdByName = ormWithPropSelector(
  ({ PersonType, }, typeName) => _getPersonTypeIdByName(PersonType, typeName)
)

export const personTypesByPermissionSelector = ormSelector(
  userOnlySelectsInternalType,
  userOnlySelectsGovCustType,
  userOnlySelectsPublicTypes,
  ({ PersonType, }, internalOnly, govCustOnly, publicOnly) => {
    let qs = PersonType.all()
    if (internalOnly) {
      qs = qs.filter({ PersonTypeName: PERSON_TYPE_DNR_STAFF , })
    } 
    else if (govCustOnly) {
      qs = qs.filter({ PersonTypeName: PERSON_TYPE_GOV_CUST, })
    } 
    else if (publicOnly) {
      qs = qs.filter(p => p.PersonTypeName !== PERSON_TYPE_DNR_STAFF 
        && p.PersonTypeName !== PERSON_TYPE_GOV_CUST)
    }
    return qs.toRefArray().map(personTypeMapper)
  }
)

//#endregion


//#region people by type selectors

export const landownerSelector = ormSelector(
  ({ Person, PersonType, }, filter) => {
    const privatePersonType = PersonType.filter({ PersonTypeName: PERSON_TYPE_PRIVATE, }).first()
    let personTypeId = privatePersonType ? privatePersonType.PersonTypeId : null
    let people = Person.filter(p => p.PersonTypeId === personTypeId || p.PersonTypeName === PERSON_TYPE_PRIVATE ).toModelArray()
    if (typeof filter === 'function') {
      people = people.filter(filter)
    }
    return people.map(p => {
      return {
        Value : p.PersonId,
        Text  : p.toString(),
      }
    })
  }
)

export const landownersForDatatableSelector = createSelector(
  landownerSelector,
  (_, landowners) => {
    return landowners.map(mapPersonForDataTable)
  }
)


export const burnersForApplInfoSelector = createSelector(
  landownerSelector,
  (_, burners) => {
    return burners.map(p => {
      return {
        Value : p.PersonId,
        Text  : p.toString(),
      }
    })
  }
)

export const allPeople = createSelector(
  sessionSelector,
  session => {
    const dnrPersonTypeId = session.PersonType.filter({ PersonTypeName: PERSON_TYPE_DNR_STAFF, }).first().PersonTypeId
    const people = session.Person
      // Exclude previously merged People and DNR person types
      .filter(p => {
        // Merged records shouldn't be returned from the API, but just in case
        return !p.ParentId && p.PersonTypeId !== dnrPersonTypeId
      })
      .all()
    return people
  }
)

export const peopleForMergeSelect = ormSelector(
  session => {
    const people = allPeople(session)
    if (people.exists()) {
      return people
        .orderBy('PersonLastName')
        .orderBy('PersonFirstName')
        .toRefArray()
        .map(p => {
          return {
            Value : p.PersonId,
            Text  : `${p.PersonId}: ${p.PersonFirstName} ${p.PersonLastName}`,
          }
        })
    }
    return []
  }
)

export const allPeopleForMerge = ormSelector(
  session => {
    const people = allPeople(session)
    if (people.exists()) {
      return people
        .toModelArray()
        .map(p => {
          const user = session.ApplicationUser.filter({ PersonId: p.PersonId, }).first()
          let personData = {
            UserName: {
              Readonly : true,
              Text     : user ? user.UserName : '',
              Label    : 'Username',
            },
            PersonId: {
              Value    : p.PersonId,
              Label    : 'Person ID',
              Required : true,
            },
            PersonFirstName: {
              Value    : p.PersonFirstName,
              Label    : 'First Name',
              Required : true,
            },
            PersonMiddleName: {
              Value : p.PersonMiddleName,
              Label : 'Middle Name',
            },
            PersonLastName: {
              Value    : p.PersonLastName,
              Label    : 'Last Name',
              Required : true,
            },
            AlertMethodId: {
              Value : p.AlertMethodId,
              Text  : p.AlertMethod ? p.AlertMethod.AlertMethodName : '',
              Label : 'Alert Method',
            },
            ContactMethodId: {
              Value : p.ContactMethodId,
              Text  : p.ContactMethod ? p.ContactMethod.ContactMethodName : '',
              Label : 'Contact Method',
            },
            PersonTypeId: {
              Value : p.PersonTypeId,
              Text  : p.PersonType ? p.PersonType.PersonTypeName : '',
              Label : 'Person Type',
            },
            EmailId: {
              Value : p.EmailId,
              Text  : p.Email ? p.Email.EmailAddress : '',
              Label : 'Email',
            },
          }
          personData.AgencyId = {
            Label: 'Agency',
          }
          if (p.PersonAgencyXrefs.exists()) {
            const agency = p.PersonAgencyXrefs.first()
            personData.AgencyId.Value = agency.PersonAgencyXrefAgency.AgencyId
            personData.AgencyId.Text = agency.PersonAgencyXrefAgency.AgencyName
          }
          return personData
        })
    }
    return []
  }
)

/**
 * Return the active person id for the person form
 * @return {int} The active person id
 */
export const activePersonFormSelector = createSelector( 
  personStateSelector,
  ({ activePerson, }) => activePerson ? activePerson : -1
)

export const personModelSelector = ormByIdSelector(
  (session, personId) => {
    if (isNaN(personId)) {
      return null
    }
    if (!session.Person.idExists(personId)) {
      return null
    }
    return session.Person.withId(personId)
  }
)

export const personSelector = ormByIdSelector(
  ({ Person, ApplicationUser, }, personId) => {
    if (isNaN(personId)) {
      return null
    }
    if (!Person.idExists(personId)) {
      return null
    }
    const person = Person.withId(personId)
    const user = ApplicationUser.filter({ PersonId: personId, }).toModelArray()
    if (person.IsUser !== true && user.length) {
      person.IsUser = true
    }
    const mappedPerson = personMapper(person)
    if (user.length) {
      mappedPerson['UserName'] = user[0].UserName
      mappedPerson['UserGroups'] = user.map(u => mapGroupToReadableName(u.ClaimValue)).filter(g => !!g)
    }
    return mappedPerson
  }
)

export const personIsUserSelector = ormByIdSelector(
  ({ Person, ApplicationUser, }, personId) => {
    if (isNaN(personId)) {
      return null
    }
    if (!Person.idExists(personId)) {
      return null
    }
    const person = Person.withId(personId)
    const user = ApplicationUser.filter({ PersonId: personId, }).toModelArray()
    if (person.IsUser !== true && user.length) {
      person.IsUser = true
    }
    return person.IsUser
  }
)


export const personPhoneSelector = ormWithPropSelector(
  personStateSelector,
  ({ Person, }, personId, personState) => {
    if (isNaN(personId)) {
      return {
        phones      : [],
        activeCount : 0,
        total       : 0,
      }
    }
    const person = Person.withId(personId)
    if (!person) {
      return {
        phones      : [],
        activeCount : 0,
        total       : 0,
      }
    }
    let phones = person.Phones.toRefArray().map(phoneMapper)
    const total = phones.length
    const activePhones = phones.filter(p => p.Active === true)
    if ('Phones' in personState.inactiveData && personState.inactiveData.Phones.activeOnly) {
      phones = activePhones
    }
    return {
      phones,
      activeCount: activePhones.length,
      total,
    }
  }
)

export const personRegionXrefSelector = ormByIdSelector(
  ({ PersonRegionXref, }, personId) => {
    const personRegions = PersonRegionXref.filter({ PersonRegionXrefPersonId: personId, })
    if (!personRegions.exists()) {
      return {}
    }
    const xref = personRegions.first()
    return {
      PersonRegionXrefId            : xref.PersonRegionXrefId,
      RegionId                      : xref.PersonRegionXrefRegionId,
      RegionDefaultAssignee         : xref.RegionDefaultAssignee ? 'yes' : 'no',
      MakesSmokeManagementDecisions : xref.MakesSmokeManagementDecisions ? 'yes' : 'no',
    }
  }
)

export const userMakesSMDecisions = ormSelector(
  userPersonIdSelector,
  userCanMakeSMDecisions,
  ({ PersonRegionXref, }, personId, isAuthorized) => {
    return isAuthorized && PersonRegionXref
      .filter(x => x.PersonRegionXrefPersonId === parseInt(personId))
      .toRefArray()
      .some(x => x.MakesSmokeManagementDecisions)
  }
)

export const personAgencyXrefSelector = ormWithPropSelector(
  personModelSelector,
  ({ PersonAgencyXref, }, personId, person = null) => {
    if (!person || !person.PersonAgencyXrefs) {
      return {}
    }
    let xref
    if (person.PersonAgencyXrefs.exists()) {
      xref = person.PersonAgencyXrefs.first()
    }
    else if (PersonAgencyXref.filter({ PersonAgencyXrefPersonId: personId, }).exists()) {
      xref = PersonAgencyXref.filter({ PersonAgencyXrefPersonId: personId, }).first()
    }
    if (!xref) {
      return {}
    }
    let AgencyName = ''
    if (xref.PersonAgencyXrefAgency) {
      AgencyName = xref.PersonAgencyXrefAgency.AgencyName
    }
    return {
      PersonAgencyXrefId : xref.PersonAgencyXrefId,
      AgencyId           : xref.PersonAgencyXrefAgencyId,
      AgencyName         : AgencyName,
      ConfirmedOn        : xref.ConfirmedOn,
      ConfirmedBy        : xref.ConfirmedBy,
    }
  }
)

export const personAlertPrefXrefSelector = ormWithPropSelector(
  personModelSelector,
  (session, personId, person = null) => {
    if (!person) {
      return {}
    }
    let alertPrefs = {}
    if (Array.isArray(person.PersonAlertPreferenceXref) && person.PersonAlertPreferenceXref.length) {
      alertPrefs = person.PersonAlertPreferenceXref.reduce((acc, x) => {
        const name = `PersonAlertPreferenceXref_${x.AlertPreferenceId}`
        acc[name] = x
        return acc
      }, {})
    }
    return alertPrefs
  }
)

export const personAddressesSelector = ormWithPropSelector(
  personStateSelector,
  ({ Person, }, personId, personState) => {
    if (isNaN(personId)) {
      return {
        addresses   : [],
        activeCount : 0,
        total       : 0,
      }
    }
    const person = Person.withId(personId)
    if (!person) {
      return {
        addresses   : [],
        activeCount : 0,
        total       : 0,
      }
    }

    let addresses = person.Addresses.toRefArray().map(addressMapper)
    const total = addresses.length
    const activeAddresses = addresses.filter(p => p.Active === true)
    if ('Addresses' in personState.inactiveData && personState.inactiveData.Addresses.activeOnly) {
      addresses = activeAddresses
    }
    return {
      addresses,
      activeCount: activeAddresses.length,
      total,
    }
  }
)

export const personForMerge = ormByIdSelector(
  allPeopleForMerge,
  ({ BurnPermitSearch, BurnPermitSignature, BurnPermitDocument, Person, }, personId, allPeople) => {
    if (isNaN(personId)) {
      return null
    }
    const person = Person.withId(personId)
    if (!person) {
      return null
    }
    const personObj = allPeople.filter(p => p.PersonId.Value === personId)[0]
    if (!personObj) {
      return null
    }
    const personForMerge = { ...personObj, }
    personForMerge.Addresses = {
      Label : 'Addresses',
      Text  : person.Addresses.toModelArray().map(a => a.toString()),
      Value : person.Addresses.toModelArray().map(a => a.AddressId),
    }
    personForMerge.Phones = {
      Label : 'Phones',
      Text  : person.Phones.toModelArray().map(a => a.toString()),
      Value : person.Phones.toModelArray().map(a => a.PhoneId),
    }
    personForMerge.Permits = {
      Label : 'Permits',
      Text  : [],
      Value : [],
    }
    
    const permits = BurnPermitSearch
      .filter(p =>
        p.LandownerId === personId
        || p.AgentId === personId
      )
      .all()
      .toModelArray()
      
    if (permits.length) {
      const permitIds = permits.map(p => p.BurnPermitId, [])
      
      const now = getNow()
      let showWarning = false
      personForMerge.Permits.Value = permitIds
      personForMerge.Permits.Text = permits.map(p => {
        const sig = BurnPermitSignature
          .filter(s => s.BurnPermitId === p.BurnPermitId)
          .first()
        const baseMessages = []
        if (p.BurnPermitNumber) {
          baseMessages.push(`${p.BurnPermitNumber} - ${p.BurnPermitStatus}`)
        }
        else {
          baseMessages.push(p.BurnPermitApplicationStatus)
        }
        // Return the baseMessages if:
        //   * no signature
        //   * a signature without a permit envelope id
        //   * the permit is expired
        if (
          !sig
          || sig.PermitEnvelopeId === '[AUTO-APPROVED]'
          || isAfter(getTodaysDate(), p.BurnPermitExpirationDate)
          || p.LegacyId > 0
        ) {
          return baseMessages
        }
        else if (!sig.PermitEnvelopeId) {
          showWarning = true
          return [ ...baseMessages, 'This Permit still needs to be Issued', ]
        }
        
        const hasSignedPermit = BurnPermitDocument.filter(d => d.BurnPermitId == p.BurnPermitId && !!d.BurnPermitDocumentType)
          .all()
          .toModelArray()
          .some(d => d.BurnPermitDocumentType.BurnPermitDocumentTypeName === 'Signed Permit')
          
        if (isAfter(now, p.BurnPermitExpirationDate) && !hasSignedPermit && (!sig.PermitApproverSignedBy || !sig.PermitApplicantSignedBy)) {
          showWarning = true
          return [ ...baseMessages, 'This Permit may need to be Re-Issued to allow the applicant to DocuSign the Permit', ]
        }
        else if (hasSignedPermit) {
          return baseMessages
        }
      })
      if (showWarning) {
        personForMerge.Permits.Warning = 'One or more Permits may need to be Re-Issued to allow the applicant to DocuSign the Permit.'
      }
    }
    return personForMerge
  }
)

export const personEmailSelector = ormWithPropSelector(
  personModelSelector,
  ({ Email, }, personId, person = null) => {
    if (!person) {
      return null
    }
    let email = person.Email
    if (!email && person.EmailId > -1) {
      const _email = Email.withId(person.EmailId)
      if (_email) {
        email = _email
      }
    }
    return emailMapper(email)
  }
)

export const peopleForDatatableSelector = ormSelector(
  ({ Person, }) => {
    return Person.all().toModelArray().map(mapPersonForDataTable)
  }
)

export const getPersonDataTableCols = ormSelector(
  authPropsSelector,
  ({ Person, }, auth) => Person.getDataTablesColumns(auth.isDnr)
)

export const getUnsubmittedRequests = createSelector(
  personStateSelector,
  (state) => state ? state.burnRequestsPrompts || [] : []
)

export const personInactiveData = createSelector(
  personStateSelector,
  state => {
    return { ...state.inactiveData, }
  }
)
