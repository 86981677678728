// Libraries
import { put, select, call, all, fork, spawn, takeEvery, takeLatest, } from 'redux-saga/effects'
import { isEmpty, } from 'lodash'

// Sagas
import { getLookupData, doFetch, showError, extractPayload, } from './ApiSagas'
import { hideLoading, showLoading, } from './AppSagas'
import { createLocalModel, destroyLocalModel, upsertLocalModels, destroyLocalModels, serverHasNewerData, replaceAll, upsertLocalModel, } from './OrmSagas'
import { uploadFiles, downloadFile, } from './FileSagas'
import { downloadBurnPermitAreaInfo, submitOfflineAreaEdits, } from './BurnPermitAreaSagas'
import { downloadBurnLocationInfo, submitOfflineLocationEdits, } from './BurnPermitLocationSagas'
import { getBurnPermitSignatures, downloadBurnPermitSignatures, } from './BurnPermitSignatureSagas'
import { downloadBurnPermitFuelsInfo, submitOfflineFuelsEdits, } from './BurnPermitFuelsSagas'
import { downloadPileInfo, submitOfflinePileEdits, } from './BurnPermitPileGroupSagas'
import { downloadPermitConditions, } from './BurnPermitConditionSagas'
import { downloadBurnPermitSiteInfo, submitOfflineSiteEdits, } from './BurnPermitSiteSagas'
import { downloadApplicantInfo, } from './BurnPermitApplicantInfoSagas'
import { GetAllRegions, } from './RegionSagas'
import { getOrderForBurnPermit, } from './BurnPermitOrdersSagas'

// Reducers
import ApiActions, { ApiTypes, } from '../redux/ApiRedux'
import { AppTypes, } from '../redux/AppRedux'
import { FileTypes, } from '../redux/FileRedux'
import { mapDataToModel, OrmTypes, } from '../redux/OrmRedux'
import { ApplicantInfoTypes, } from '../redux/ApplicantInfoSectionRedux'
import BurnPermitFormActions, { BurnPermitFormTypes, } from '../redux/BurnPermitFormRedux'
import BurnPermitListActions, { BurnPermitListTypes, } from '../redux/BurnPermitListRedux'
import { CartTypes, } from '../redux/CartRedux'
import { UiTypes, } from '../redux/UiRedux'
import { BurnPermitInstructionsTypes, } from '../redux/BurnPermitInstructionsRedux'
import { BurnPermitSignatureTypes, } from '../redux/BurnPermitSignatureRedux'

// Models
import BurnPermit from '../models/BurnPermit'
import BurnPermitArea from '../models/BurnPermitArea'
import BurnPermitFuel from '../models/BurnPermitFuel'
import BurnPermitLocation from '../models/BurnPermitLocation'
import BurnPermitSearch from '../models/BurnPermitSearch'
import BurnPermitSite from '../models/BurnPermitSite'
import BurnPermitStatusXref from '../models/BurnPermitStatusXref'
import BurnPermitApplicationStatusXref from '../models/BurnPermitApplicationStatusXref'

// Selectors
import {
  permitByIdSelector,
  networkStateSelector,
  permitApplicationStatuses,
  appIsOnlineSelector,
} from '../selectors/selectors'
import { signatureByPermitId, } from '../selectors/burnPermitSignatureSelectors'
import { modelByIdSelector, } from '../selectors/modelSelectors'
import { userIsDNR, userNameSelector, userPersonModelSelector, profileStatusSelector, } from '../selectors/userSelectors'
import { lastRouteSelector, } from '../selectors/appSelectors'
import {
  activeBurnPermitIdSelector,
  documentTypesForSelect,
  permitApplicationDocuments,
  permitApplicationStatus,
  shouldShowApplicationNextSteps,
  shouldShowPermitNextSteps,
} from '../selectors/burnPermitSelectors'

// Utilities
import { dateFormatter, } from '../utilities'
import { compareFileNames, } from '../utilities/files'

// Constants
// eslint-disable-next-line no-undef
const { REACT_APP_SERVER_URL, } = process.env

const BURN_PERMIT_ENDPOINT = BurnPermit.endpoint()
const BURN_PERMIT_MODEL_NAME = BurnPermit.modelName
const BURN_PERMIT_SEARCH_MODEL_NAME = BurnPermitSearch.modelName


function* createPermitApplication () {
  yield put({ type: ApplicantInfoTypes.RESET_APPLICANT_INFO, })
  const { online, } = yield select(networkStateSelector)
  let localId
  if (!online) {
    const username = yield select(userNameSelector)
    const user = yield select(userPersonModelSelector)
    const profile = yield select(profileStatusSelector)
    
    // Create the related section models before creating the burn permit
    const { BurnPermitLocationId, } = yield call(createLocalModel, BurnPermitLocation.modelName, { CreateBy: username, CreateDate: new Date(), })
    const { BurnPermitAreaId, } = yield call(createLocalModel, BurnPermitArea.modelName, { CreateBy: username, CreateDate: new Date(), })
    const { BurnPermitSiteId, } = yield call(createLocalModel, BurnPermitSite.modelName, { CreateBy: username, CreateDate: new Date(), })
    const { BurnPermitFuelId, } = yield call(createLocalModel, BurnPermitFuel.modelName, { CreateBy: username, CreateDate: new Date(), })
    
    const personInfo = {}
    if (profile.HasAgency) {
      personInfo.AgencyId = user.Agencies.first().AgencyId
      personInfo.AgentId = user.PersonId
      personInfo.MailPermitToAgentFlag = true
      personInfo.MailPermitToLandownerFlag = false
    }
    
    const permitProps = {
      BurnPermitAreaId,
      BurnPermitLocationId,
      BurnPermitSiteId,
      BurnPermitFuelId,
      ...personInfo,
      // LandownerId : user.PersonType === 'Landowner' ?  user.PersonType : '',
      CreateBy   : username,
      CreateDate : new Date(),
    }
    // Create a local permit with the fks to the section models and some audit data
    const newPermit = yield call(createLocalModel, BURN_PERMIT_MODEL_NAME, permitProps)

    const { BurnPermitId, } = newPermit
    localId = BurnPermitId

    // Create permit search result record so it's discoverable in the My Permits and Search Permits
    yield call(createLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, { BurnPermitId, BurnPermitApplicationStatus: 'Pending', BurnPermitApplicationStatusDate: new Date(), CreateBy: username, CreateDate: new Date(), })

    // Create a pending status record
    const appStatuses = yield select(permitApplicationStatuses)
    const pendingStatusId = appStatuses.filter(s => s.Text === 'Pending')[0].Value
    const pendingStatusXref = {
      BurnPermitId,
      BurnPermitApplicationStatusId : pendingStatusId,
      StatusDate                    : new Date(),
      Comment                       : `[SYSTEM] ${username} started the application.`,
      CreateBy                      : username,
      CreateDate                    : new Date(),
    }
    yield call(createLocalModel, BurnPermitApplicationStatusXref.modelName, pendingStatusXref)

    // Set the new active burn permit id once all the data is created locally
    yield put({ type: BurnPermitFormTypes.ACTIVE_BURN_PERMIT, BurnPermitId, })
  }

  // Because the Type for the createPostBurnRequest is declared as part of the custom config,
  // we have to put an action creator rather than put the type in order for redux to pick it up
  yield put(BurnPermitFormActions.createPermitApplicationRequest(localId))
}

/**
 * Create a new Burn Permit Application
 */
function* createPermitApplicationSuccess (resp) {
  try {
    if (resp.payload && !resp.payload.ok && resp.payload.status === 404) {
      yield put({ type: BurnPermitFormTypes.PERMIT_FAILED_TO_LOAD, })
      return
    }
    const burnPermit = yield call(extractPayload, resp)
    // server IDs for the section models
    const {
      BurnPermitId,
      BurnPermitAreaId,
      BurnPermitLocationId,
      BurnPermitSiteId,
      BurnPermitFuelId,
    } = burnPermit

    const { localId, } = resp
    if (Number.isInteger(localId)) {
      // get the local model so we can get the local section models
      const localBurnPermit = yield select(modelByIdSelector, { modelName: BURN_PERMIT_MODEL_NAME, modelId: localId, })

      // destroy the local burn permit
      yield call(destroyLocalModel, BURN_PERMIT_MODEL_NAME, localId)

      // PUT to the BurnPermit endpoint to set the applicant info
      // Include the new server IDs for the section models to override the local IDs
      const applicantInfo = {
        ...localBurnPermit._fields,
        BurnPermitId,
        BurnPermitAreaId,
        BurnPermitLocationId,
        BurnPermitSiteId,
        BurnPermitFuelId,
      }
      
      // We want this to be a blocking one so it updates the local model on success
      const url = `${BURN_PERMIT_ENDPOINT}/${BurnPermitId}`
      yield put(ApiActions.updateRecordRequest(BURN_PERMIT_MODEL_NAME, url, applicantInfo, true))

      // delete local search result, a new one will be returned the next time they return to
      // the my permits or search permits page
      yield call(destroyLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, localId)

      // submit the update reqs for each of the sections
      // local section IDs
      const {
        BurnPermitAreaId: localAreaId,
        BurnPermitLocationId: localLocationId,
        BurnPermitSiteId: localSiteId,
        BurnPermitFuelId: localFuelId,
      } = localBurnPermit

      // We want to spin off detached tasks so if one fails, it doesn't stop the others from
      // processing or this parent saga
      // see: https://redux-saga.js.org/docs/api/#spawnfn-args
      yield spawn(submitOfflineAreaEdits, localAreaId, BurnPermitAreaId)
      yield spawn(submitOfflineLocationEdits, localLocationId, BurnPermitLocationId)
      yield spawn(submitOfflineSiteEdits, localSiteId, BurnPermitSiteId)
      yield spawn(submitOfflinePileEdits, localId, BurnPermitId)
      yield spawn(submitOfflineFuelsEdits, localFuelId, BurnPermitFuelId)
    }
    
    yield put({ type: BurnPermitFormTypes.ACTIVE_BURN_PERMIT, BurnPermitId, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

function* getStatusLookupData () {
  // The getLookupData saga will update the ORM for us
  // so we don't need to worry about the response
  yield fork(getLookupData, { modelName: 'BurnPermitStatus', })
  yield fork(getLookupData, { modelName: 'BurnPermitApplicationStatus', })
}


/**
 * Get the Burn Permit Application and Permit Status History for the provided Burn Permit ID.
 * Will also request the lookup data to map the statuses to for the controls
 * @param {object} arg
 * @param {number} arg.burnPermitId
 */
export function* getBurnPermitStatusHistory ({ burnPermitId, }) {
  try {

    if (!burnPermitId) {
      yield call(showError, 'You must provide a Burn Permit ID in order to retrieve statuses.')
      return
    }

    yield fork(getStatusLookupData)

    const getHistory = true

    // Creates the Burn Permit Status Urls
    const permitStatusHistoryUrl = `${REACT_APP_SERVER_URL}${BurnPermitStatusXref.endpoint(burnPermitId, getHistory)}`
    const applicationStatusHistoryUrl = `${REACT_APP_SERVER_URL}${BurnPermitApplicationStatusXref.endpoint(burnPermitId, getHistory)}`

    const {
      permitStatusResponse,
      applicationStatusResponse,
    } = yield all({
      permitStatusResponse      : call(doFetch, permitStatusHistoryUrl),
      applicationStatusResponse : call(doFetch, applicationStatusHistoryUrl),
    })

    // If it's not an Ok, 200, or 404 response, throw an Error
    // Not all Permit Objects will have a Permit Status
    if (permitStatusResponse.ok === false && permitStatusResponse.statusCode !== 200 && permitStatusResponse.statusCode !== 404) {
      yield call(showError, `An error occurred fetching the Permit Status History for ${burnPermitId}.`)
    }

    // If it's not an Ok or 200 response, throw an Error
    // All Permit Objects will have an Application Status
    if (applicationStatusResponse.ok === false && applicationStatusResponse.statusCode !== 200) {
      yield call(showError, `An error occurred fetching the Application Status History for ${burnPermitId}.`)
    }
    
    // Upsert the Burn Permit Statuses
    const serverPermitStatuses = permitStatusResponse.responseBody
    if (permitStatusResponse.ok) {
      // Compare latest status date for each status to status locally
      // If a newer status, then update
      // Otherwise, discard
      let hasNewStatuses = yield call(serverHasNewerData, BurnPermitStatusXref.modelName, { BurnPermitId: burnPermitId, }, serverPermitStatuses)
      if (hasNewStatuses) {
        // Purge the existing Xrefs in case any were deleted server side
        // This would only happen if someone did that directly in the db
        yield call(destroyLocalModels, BurnPermitStatusXref.modelName, { BurnPermitId: burnPermitId, })
        // Upsert the Xrefs, if any
        if (serverPermitStatuses.length) {
          yield call(upsertLocalModels, BurnPermitStatusXref.modelName, serverPermitStatuses)
        }
      }
    }
    
    // Upsert the Burn Permit Application Statuses
    if (applicationStatusResponse.ok) {
      // Compare latest status date for each status to status locally
      // If a newer status, then update
      // Otherwise, discard
      const serverAppStatuses = applicationStatusResponse.responseBody
      let hasNewStatuses = yield call(serverHasNewerData, BurnPermitApplicationStatusXref.modelName, { BurnPermitId: burnPermitId, }, serverAppStatuses)
      if (hasNewStatuses) {
        // Purge the existing Xrefs in case any were deleted server side
        // This would only happen if someone did that directly in the db
        yield call(destroyLocalModels, BurnPermitApplicationStatusXref.modelName, { BurnPermitId: burnPermitId, })
        // Upsert the Xrefs
        yield call(upsertLocalModels, BurnPermitApplicationStatusXref.modelName, serverAppStatuses)
      }
    }
  }
  catch (error) {
    yield call(showError, error)
  }
}


function* downloadBurnPermitStatusHistory () {
  try {
    const permitStatusResponse = yield call(doFetch, `${BURN_PERMIT_ENDPOINT}/StatusHistory/Download`)
    // If it's not an Ok, 200, or 404 response, throw an Error
    // Not all Permit Objects will have a Permit Status
    if (permitStatusResponse.ok === false && permitStatusResponse.statusCode !== 200 && permitStatusResponse.statusCode !== 404) {
      yield call(showError, 'An error occurred downloading the Permit Status Histories')
      return
    }

    const {
      appStatuses,
      permitStatuses,
    } = permitStatusResponse.responseBody

    yield all([
      put({ type: OrmTypes.UPSERT_RANGE, modelName: 'BurnPermitStatusXref', records: permitStatuses, }),
      put({ type: OrmTypes.UPSERT_RANGE, modelName: BurnPermitApplicationStatusXref.modelName, records: appStatuses, }),
    ])
    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'StatusHistory', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Submits a Status change for a Burn Permit
 */
export function* submitApplicationStatus ({ statusValues, }) {
  try {
    
    if (isNaN(parseInt(statusValues.BurnPermitId))) {
      yield call(showError, 'You must supply a Burn Permit ID to submit an Application Status change.')
      return
    }
    if (isNaN(parseInt(statusValues.BurnPermitApplicationStatusId))) {
      yield call(showError, 'You must supply a Burn Permit Application Status ID to submit an Application Status change.')
      return
    }

    const requestObj = {
      method : 'POST',
      body   : statusValues,
    }

    const putAppStatusUrl = `${REACT_APP_SERVER_URL}${BurnPermitApplicationStatusXref.endpoint(statusValues.BurnPermitId)}`

    const applicationStatusResponse = yield call(doFetch, putAppStatusUrl, requestObj)
    
    if (applicationStatusResponse.ok !== true) {
      let { error, errors, } = applicationStatusResponse.responseBody
      if (!error && Array.isArray(errors)) {
        error = errors.join('\r\n')
      }
      yield call(showError, error || 'An error occurred while attempting to change the Application Status.')
      return
    }

    // Upsert the Burn Permit Application Status if there is one
    
    // Map only the values the client model knows about
    const burnPermitApplicationStatus = mapDataToModel(BurnPermitApplicationStatusXref, applicationStatusResponse.responseBody, false)
    // Upsert the Xref
    yield put({
      type       : 'UPSERT',
      modelName  : BurnPermitApplicationStatusXref.modelName,
      properties : burnPermitApplicationStatus,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Delete an application status change for a Burn Permit
 */
export function* deleteApplicationStatus ({ BurnPermitId, BurnPermitApplicationStatusXrefId, }) {
  try {

    if (isNaN(parseInt(BurnPermitId))) {
      yield call(showError, 'You must supply a Burn Permit ID to delete an Application Status.')
      return
    }
    if (isNaN(parseInt(BurnPermitApplicationStatusXrefId))) {
      yield call(showError, 'You must supply a Burn Permit Application Status ID to delete an Application Status.')
      return
    }

    const putAppStatusUrl = `${REACT_APP_SERVER_URL}${BurnPermitApplicationStatusXref.deleteEndpoint(BurnPermitId, BurnPermitApplicationStatusXrefId)}`

    const applicationStatusResponse = yield call(doFetch, putAppStatusUrl, { method: 'DELETE', })

    if (applicationStatusResponse.ok !== true) {
      const error = applicationStatusResponse.responseBody || 'An error occurred while attempting to delete the Application Status.'
      yield call(showError, error)
      return
    }

    yield put({
      type      : OrmTypes.DESTROY, 
      modelName : BurnPermitApplicationStatusXref.modelName, 
      modelId   : BurnPermitApplicationStatusXrefId,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Delete an application status change for a Burn Permit
 */
export function* deletePermitStatus ({ BurnPermitId, BurnPermitStatusXrefId, }) {
  try {

    if (!BurnPermitId) {
      yield call(showError, 'You must supply a Burn Permit ID to delete an Permit Status.')
      return
    }
    if (!BurnPermitStatusXrefId) {
      yield call(showError, 'You must supply a Burn Permit Permit Status ID to delete an Permit Status.')
      return
    }

    const putPermitStatusUrl = `${REACT_APP_SERVER_URL}${BurnPermitStatusXref.deleteEndpoint(BurnPermitId, BurnPermitStatusXrefId)}`

    const permitStatusResponse = yield call(doFetch, putPermitStatusUrl, { method: 'DELETE', })

    if (permitStatusResponse.ok !== true) {
      const error = permitStatusResponse.responseBody || 'An error occurred while attempting to delete the Permit Status.'
      yield call(showError, error)
      return
    }

    yield put({
      type      : OrmTypes.DESTROY, 
      modelName : 'BurnPermitStatusXref', 
      modelId   : BurnPermitStatusXrefId,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Submits a request to check if the Burn Permit with the provided ID is valid or not
 */
export function* validatePermit ({ burnPermitId, }) {
  try {
    
    if (isNaN(parseInt(burnPermitId))) {
      yield call(showError, 'You must supply a Burn Permit ID to check the Application validity.')
      return
    }

    yield call(showLoading)

    const appIsValidUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/IsValid`

    const appIsValidResp = yield call(doFetch, appIsValidUrl)
    
    if (appIsValidResp.ok !== true) {
      yield call(showError, 'An error occurred while attempting to check the Application validity.')
      return
    }

    const appIsValid = appIsValidResp.responseBody.isValid
    const SECTION_IDS = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, ]
    // If the application is valid
    if (appIsValid) {
      // update the app state to reflect they're all complete
      yield put({
        type       : BurnPermitFormTypes.SAVED_SECTIONS,
        sectionIds : SECTION_IDS,
      })
    }
    else {
      // otherwise update the app state to tell each one to validate themselves to reveal the errors
      // Set the array of section ids to validate so the form section component
      // can check to see if it should validate
      yield put({
        type    : BurnPermitFormTypes.COMPLETED_STEPS,
        StepIds : SECTION_IDS,
      })
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


/**
 * Submits a request to submit the Burn Permit for the Agency User
 */
export function* agencySubmitBurnPermit ({ burnPermitId, }) {
  try {

    yield call(showLoading)

    if (!Number.isInteger(parseInt(burnPermitId))) {
      yield call(showError, 'You must supply a Burn Permit ID to submit the Burn Permit.')
      return
    }

    const appSubmittedUrl = `${BURN_PERMIT_ENDPOINT}/${burnPermitId}/Submit`

    const response = yield call(doFetch, appSubmittedUrl)
    const { responseBody, } = response
    if (response.ok !== true) {
      yield call(showError, responseBody.error || 'An error occurred while attempting to submit the Burn Permit.')
      return
    }

    yield fork(getBurnPermitDetail, { burnPermitId, })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* updateTonnageAndFee () {
  const burnPermitId = yield select(activeBurnPermitIdSelector)
  yield fork(getBurnPermit, { burnPermitId, updateFee: true, })
}

/**
 * Submits a request to get the Burn Permit object
 */
export function* getBurnPermit ({ burnPermitId, burnPermitNumber, updateFee = false, forceReload = false, },) {
  try {

    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }

    if (isNaN(parseInt(burnPermitId)) && !burnPermitNumber) {
      yield call(showError, 'You must supply a Burn Permit ID or Burn Permit Number.')
      return
    }

    let url = BURN_PERMIT_ENDPOINT
    if (burnPermitId) {
      url += `/${burnPermitId}`
      if (updateFee) {
        url += '?updateFee=true'
      }
    }
    else if (burnPermitNumber) {
      url += `/0?BurnPermitNumber=${burnPermitNumber}`
      if (updateFee) {
        url += '&updateFee=true'
      }
    }

    const burnPermDetailResp = yield call(doFetch, url)

    if (burnPermDetailResp.ok !== true) {
      if (!isEmpty(burnPermDetailResp.responseBody) && ('error' in burnPermDetailResp.responseBody || 'errors' in burnPermDetailResp.responseBody)) {
        const { error, errors, } = burnPermDetailResp.responseBody
        yield call(showError, error || errors)
      }
      else {
        yield put({ type: BurnPermitFormTypes.PERMIT_FAILED_TO_LOAD, })
      }
      return
    }

    const hasNewData = yield call(serverHasNewerData, BURN_PERMIT_MODEL_NAME, { BurnPermitId: burnPermitId, }, burnPermDetailResp.responseBody)
    if (forceReload || hasNewData) {
      yield call(upsertLocalModel, BURN_PERMIT_MODEL_NAME, burnPermDetailResp.responseBody)
    }

    return burnPermDetailResp
  }
  catch (error) {
    yield call(showError, error)
  }
}

/**
 * Submits a request to get the Burn Permit information and activates the Permit Id
 * used when navigating to the Permit Application
 */
export function* getBurnPermitDetail ({ burnPermitId, burnPermitNumber, forceReload = false, }) {
  try {

    yield call(showLoading)

    yield put({
      type         : BurnPermitFormTypes.ACTIVE_BURN_PERMIT,
      BurnPermitId : burnPermitId,
    })

    const online = yield select(appIsOnlineSelector)
    if (online) {

      const permit = yield call(getBurnPermit, { burnPermitId, burnPermitNumber, forceReload, })

      if (!permit) {
        yield put({ type: BurnPermitFormTypes.PERMIT_FAILED_TO_LOAD, })
        return
      }

      yield all([
        call(getBurnPermitSignatures, { burnPermitId, disableLoading: true, }),
        call(getPermitFiles, { permitId: burnPermitId, disableLoading: true, }),
        call(getBurnPermitStatusHistory, { burnPermitId, }),
        call(getOrderForBurnPermit, { burnPermitId, disableLoading: true, }),
      ])

      yield call(updateNextSteps, burnPermitId)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* updateNextSteps (burnPermitId) {

  const userIsDnr = yield select(userIsDNR)
  const showAppNextSteps = yield select(shouldShowApplicationNextSteps, burnPermitId)
  if (!showAppNextSteps.isIssued) {
    let step = 'Apply', completedSteps = [], showModal = true
    if (showAppNextSteps.isPending) {
      // TODO: set `isValid` later once we have a consolidated validation endpoint
      // to check against
      if (showAppNextSteps.isSigned !== true && showAppNextSteps.isValid === true) {
        step = 'Sign'
        completedSteps = [ 'Apply', ]
      }
      else if (showAppNextSteps.isSigned && showAppNextSteps.isPaid !== true) {
        step = 'Pay'
        completedSteps = [ 'Apply', 'Sign', ]
      }
    }
    // Also check to see if the application is for a voucher agency
    else if (showAppNextSteps.isPaid !== true && showAppNextSteps.paysByVoucher !== true) {
      step = 'Pay'
      completedSteps = [ 'Apply', 'Sign', ]
    }
    else if (showAppNextSteps.isApproved) {
      step = 'Approved'
      completedSteps = [ 'Apply', 'Sign', 'Pay', 'Review', ]
      showModal = userIsDnr ? false : true
    }
    else if (showAppNextSteps.isDenied) {
      step = 'Denied'
      completedSteps = [ 'Apply', 'Sign', 'Pay', 'Review', ]
      showModal = userIsDnr ? false : true
    }
    else {
      step = 'Review'
      completedSteps = [ 'Apply', 'Sign', ]
      if (showAppNextSteps.paysByVoucher !== true) {
        completedSteps.push('Pay')
      }
    }
    yield put({
      type: BurnPermitInstructionsTypes.GO_TO_INSTRUCTION_STEP,
      step,
      burnPermitId,
      completedSteps,
    })
    if (step !== 'Apply' && showModal) {
      yield put({ type: UiTypes.OPEN_MODAL, modalKey: 'APPLICATION_NEXT_STEPS', })
    }
  }
  else {
    const showPermitNextSteps = yield select(shouldShowPermitNextSteps, burnPermitId)
    if (showPermitNextSteps) {
      yield put({ type: UiTypes.OPEN_MODAL, modalKey: 'PERMIT_NEXT_STEPS', })
    }
    else {
      yield put({ type: UiTypes.CLOSE_MODAL, modalKey: 'PERMIT_NEXT_STEPS', })
    }
  }
  
  // As long as it is signed, all the steps are complete
  if (showAppNextSteps.isSigned) {
    yield put({ type: BurnPermitFormTypes.COMPLETED_STEPS, burnPermitId, StepIds: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, ], })
  }
}

function* downloadPermitLookupData () {
  yield spawn(getStatusLookupData)
  yield spawn(getLookupData, { modelName: 'ArrivalTime', })
  yield spawn(getLookupData, { modelName: 'BurnIgnitionType', })
  yield spawn(getLookupData, { modelName: 'BurnPermitCondition', })
  yield spawn(getLookupData, { modelName: 'BurnReason', })
  yield spawn(getLookupData, { modelName: 'BurnType', })
  yield spawn(getLookupData, { modelName: 'County', })
  yield spawn(getLookupData, { modelName: 'DaysOfWeek', })
  yield spawn(getLookupData, { modelName: 'Direction', })
  yield spawn(getLookupData, { modelName: 'Distance', })
  yield spawn(getLookupData, { modelName: 'DuffType', })
  yield spawn(getLookupData, { modelName: 'EquipmentType', })
  yield spawn(getLookupData, { modelName: 'ForestType', })
  yield spawn(getLookupData, { modelName: 'FuelDiameter', })
  yield spawn(getLookupData, { modelName: 'LocationQuarter', })
  yield spawn(getLookupData, { modelName: 'ReferenceDirectionType', })
  yield spawn(getLookupData, { modelName: 'FireDistrictDepartment', })
  yield spawn(GetAllRegions)
}

/**
 * Submits a bulk request for Burn Permits to enable offline access for
 */
export function* downloadBurnPermits () {
  try {
    // Update the list container so users can access any new permits that they may be assigned before downloading
    yield put(BurnPermitListActions.getMyBurnPermits())
    yield put({ type: BurnPermitListTypes.RESET_DOWNLOAD_STATUS, })
    yield fork(downloadPermitLookupData)
    yield fork(downloadBurnPermitSignatures)
    yield fork(downloadBurnPermitAreaInfo)
    yield fork(downloadBurnPermitStatusHistory)
    yield fork(downloadBurnLocationInfo)
    yield fork(downloadBurnPermitSiteInfo)
    yield fork(downloadPileInfo)
    yield fork(downloadBurnPermitFuelsInfo)
    yield fork(downloadPermitConditions)
    yield fork(downloadApplicantInfo)
    
    const url = `${BURN_PERMIT_ENDPOINT}/Download/`

    const downloadPermitsResp = yield call(doFetch, url)

    if (downloadPermitsResp.ok !== true) {
      yield call(showError, downloadBurnPermits.responseBody.error || 'An error occurred download Burn Permits')
      return
    }

    yield put({
      type      : OrmTypes.UPSERT_RANGE,
      modelName : BURN_PERMIT_MODEL_NAME,
      records   : downloadPermitsResp.responseBody,
    })

    yield put({ type: BurnPermitListTypes.SET_DOWNLOAD_STATUS, target: 'BurnPermits', status: true, })
  }
  catch (error) {
    yield call(showError, error)
  }
}

export function* getPermitFiles ({ permitId, disableLoading = false, }) {
  try {
    if (!disableLoading) {
      yield call(showLoading)
    }

    if (!permitId) {
      yield call(showError, 'You must supply a Burn Permit ID to retrieve files for.')
      return
    }

    yield fork(getLookupData, { modelName: 'BurnPermitDocumentType', })
    
    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Documents`

    const fileResp = yield call(doFetch, url)

    const { responseBody, } = fileResp
    if (fileResp.ok !== true) {
      let error = 'An error occurred retrieving files for the Permit Application'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    if (responseBody.length) {
      const hasNewData = yield call(serverHasNewerData, 'BurnPermitDocument', { BurnPermitId: permitId, }, responseBody)
      if (hasNewData) {
        yield call(replaceAll, 'BurnPermitDocument', responseBody, { BurnPermitId: permitId, })
      }
    }

    // Reset the state flag for successful upload
    yield put({ type: FileTypes.SET_SUCCESSFUL_UPLOAD, successful: false, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    if (!disableLoading) {
      yield call(hideLoading)
    }
  }
}

export function* downloadPermitFile ({ permitId, fileName, documentId, }) {
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply a Burn Permit ID to retrieve files for.')
      return
    }

    if (!documentId) {
      yield call(showError, 'You must supply a Permit Document ID to download.')
      return
    }
    
    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Download/${documentId}`

    const response = yield call(doFetch, url)
    const { downloadUrl, } = response.responseBody
    
    const _fileName = fileName.substring(0, fileName.lastIndexOf('.'))
    const fileExt = fileName.substring(fileName.lastIndexOf('.'))

    const downloadFileName = `${_fileName}_${dateFormatter(new Date(), 'YYYY-MM-DD_HH:mm:ss')}${fileExt}`

    yield fork(downloadFile, { url: downloadUrl, fileName: downloadFileName, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

export function* downloadAllFiles ({ permitId, }) {
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply a Burn Permit ID to retrieve files for.')
      return
    }

    const downloadUrl = `${BURN_PERMIT_ENDPOINT}/${permitId}/DownloadDocuments`

    const response = yield call(doFetch, downloadUrl)
    const { fileName, fileCode, } = response.responseBody
    
    const url = `${downloadUrl}/${fileCode}`
    
    yield call(downloadFile, { url, fileName, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield all([
      call(hideLoading),
      put(BurnPermitFormActions.doneDownloadingFiles()),
    ])
  }
}

export function* deletePermitFile ({ permitId, documentId, }) {
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply a Burn Permit ID to delete the specified file for.')
      return
    }
    
    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Document/${documentId}/Delete`

    const response = yield call(doFetch, url, { method: 'DELETE', })

    if (response.ok !== true) {
      yield call(showError, response.responseBody && response.responseBody.error ? response.responseBody.error : null)
      return
    }

    yield put({
      type      : OrmTypes.DESTROY,
      modelName : 'BurnPermitDocument',
      modelId   : documentId,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Upload an array of files to attach to the target Burn Permit.
 * @param {Object} Object containing the target BurnPermitId, file array, and a flag 
 * indicating whether or not an Application Signature should be created
 */
export function* uploadPermitFiles ({ permitId, files, createSignature, }) {
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply a Burn Permit ID to upload files to.')
      return
    }

    // Validate the file(s) being uploaded to ensure they all have a unique name
    // Validate the incoming `files` arg as well as any existing files we already know about
    const currentFiles = yield select(permitApplicationDocuments, permitId)
    if (currentFiles.length) {
      const { errMessage, repeatingNames, } = yield call(compareFileNames, { files1: files, files2: currentFiles, })
      // If there are repeating names, start the early exit
      if (repeatingNames.length) {
        // If the user is creating a signature, give them a more specific error message
        if (createSignature === true) {
          errMessage.push('Please remove the document with the same name in the Document Section, then attempt to upload the Signature Document again.')
        }
        else {
          errMessage.push('Please remove the existing document with the same name or rename the following file(s) then attempt to upload the file(s) again.')
        }
      }
      if (errMessage.length) {
        yield call(showError, errMessage.join('\n\n'))
        return
      }
    }

    let url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Upload`

    if (createSignature) {
      url += '?createApplicationSignature=true'
    }

    const fileResp = yield call(uploadFiles, { url, files, })

    const { responseBody, } = fileResp
    if (fileResp.ok !== true) {
      let error = 'An error occurred uploading files to the Permit Application'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    yield put({
      type      : OrmTypes.UPSERT_RANGE,
      modelName : 'BurnPermitDocument',
      records   : responseBody,
    })

    // If we're creating a signature, refresh the whole permit
    // It may affect the permit status
    if (createSignature){
      yield fork(getBurnPermitDetail, { burnPermitId: permitId, })
    } else {
      yield fork(getBurnPermitSignatures, { burnPermitId: permitId, })
      yield fork(getPermitFiles, { permitId, })
    }

    // Reset the state flag for successful upload to clear the uploaded files
    yield put({ type: FileTypes.SET_SUCCESSFUL_UPLOAD, successful: false, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


/**
 * Upload an array of files to attach to the target Burn Permit.
 * @param {Object} Object containing the target BurnPermitId, file array, and a flag 
 * indicating whether or not an Application Signature should be created
 */
export function* updateDocumentType ({ permitId, documentId, documentTypeId, uploadAnyway = false, }) {
  try {
    yield call(showLoading)

    if (!permitId) {
      yield call(showError, 'You must supply the Burn Permit ID the document is attached to.')
      return
    }

    if (!documentId) {
      yield call(showError, 'You must supply a Burn Permit Document ID to update.')
      return
    }

    if (!documentTypeId) {
      yield call(showError, 'You must supply a Burn Permit Document Type ID to update.')
      return
    }

    // If the current user is DNR, the app is pending, and the doc type chosen is Mailed App
    // Show a confirmation modal outlining the purpose of the document and where it should be uploaded instead
    // However provide them the opportunity to upload anyways while acknowleding that they will have to delete
    // it and upload it in the signature section
    const userIsDnr = yield select(userIsDNR)
    const currAppStatus = yield select(permitApplicationStatus, permitId)
    const isPendingApp = (currAppStatus && currAppStatus.Status === 'Pending')
    const docTypes = yield select(documentTypesForSelect)
    const isMailedApp = (docTypes || []).some(t => t.Value === parseInt(documentTypeId) && t.Text === 'Mailed Application')
    if (userIsDnr && isPendingApp && isMailedApp) {
      if (!uploadAnyway) {
        yield put({ type: UiTypes.OPEN_MODAL, modalKey: 'UPLOAD_MAILED_APP_DOC', })
        return
      }
      else {
        // Call action to hide modal
        yield put({ type: UiTypes.CLOSE_MODAL, })
      }
    }

    let url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Document/${documentId}`

    const resp = yield call(doFetch, url, {
      method : 'PUT',
      body   : {
        BurnPermitId             : permitId,
        BurnPermitDocumentId     : documentId,
        BurnPermitDocumentTypeId : documentTypeId,
      },
    })
    // Clear out the doc info once the doc update has been attempted
    yield put({ type: BurnPermitFormTypes.CLEAR_DOC_TYPE_INFO, })

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred updating the type of the document.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    yield fork(getPermitFiles, { permitId, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Remove suspension start and end dates for a permit
 * @param {Object} updateData - An Object containing the target BurnPermitId
 * @param {number} updateData.permitId - The permit Id to update
 */
export function* clearSuspension ({ permitId, }) {
  yield call(submitSuspension, permitId, null, null, null)
}

/**
 * Update suspension start and end dates for a permit
 * @param {Object} updateData - An Object containing the target BurnPermitId and suspension start and/or end dates
 * @param {number} updateData.permitId - The permit Id to update
 * @param {string} updateData.startDate - An Object containing the target BurnPermitId and suspension start and/or end dates
 * @param {string} updateData.endDate - An Object containing the target BurnPermitId and suspension start and/or end dates
 * @param {string} updateData.suspensionComment - An Object containing the target BurnPermitId and suspension reason
 */
export function* updateSuspension ({ permitId, startDate, endDate, suspensionComment, }){
  if (!permitId) {
    yield call(showError, 'You must supply the Burn Permit ID to edit suspension dates.')
    return
  }

  if (!startDate && !endDate) {
    yield call(showError, 'You must supply a suspension start or end date.')
    return
  }

  yield call(submitSuspension, permitId, startDate, endDate, suspensionComment)
}

/**
 * Private saga to update suspension start and end dates for a permit
 * @param {number} permitId - The permit Id to update
 * @param {string} startDate - An Object containing the target BurnPermitId and suspension start and/or end dates
 * @param {string} endDate - An Object containing the target BurnPermitId and suspension start and/or end dates
 * @param {string} suspensionComment - An Object containing the target BurnPermitId and suspension reason
 */
function* submitSuspension (permitId, startDate, endDate, suspensionComment) {
  try {
    yield call(showLoading)

    let url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Suspension`
    const resp = yield call(doFetch, url, {
      method : 'POST',
      body   : {
        BurnPermitId        : permitId,
        SuspensionStartDate : startDate,
        SuspensionEndDate   : endDate,
        SuspensionComment   : suspensionComment,
      },
    })

    const { responseBody, } = resp

    if (resp.ok !== true) {
      let error = 'An error occurred updating permit suspension dates.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    const updateObj = {
      BurnPermitId        : responseBody.BurnPermitId,
      SuspensionStartDate : responseBody.SuspensionStartDate,
      SuspensionEndDate   : responseBody.SuspensionEndDate,
      SuspensionComment   : responseBody.SuspensionComment,
      UpdateBy            : responseBody.UpdateBy,
      UpdateDate          : responseBody.UpdateDate,
    }

    yield put({ 
      type      : OrmTypes.UPDATE, 
      modelName : BURN_PERMIT_MODEL_NAME,
      modelId   : permitId,
      updateObj,
    })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}


export function* extendPermit ({ permitId, expirationDate, }) {
  if (!permitId || isNaN(permitId)) {
    yield call(showError, 'You must supply the Burn Permit ID of the Permit to Extend.')
    return
  }
  if (!expirationDate) {
    yield call(showError, 'You must supply the new Expiration Date for the Permit to Extend.')
    return
  }
  try {
    yield put({ type: BurnPermitSignatureTypes.LOADING_DOCU_SIGN_URL, loading: true, })
    yield put({ type: UiTypes.CLOSE_MODAL, })
    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Extend`
    const resp = yield call(doFetch, url, {
      method : 'POST',
      body   : {
        BurnPermitId             : permitId,
        BurnPermitExpirationDate : expirationDate,
      },
    })

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred while extending the permit.'
      if (responseBody) {
        if (responseBody.error) {
          error = responseBody.error
        }
        else if (Array.isArray(responseBody.errors) && responseBody.errors.length) {
          error = responseBody.errors.join('\n')
        }
      }
      yield call(showError, error)
      yield put({ type: BurnPermitSignatureTypes.LOADING_DOCU_SIGN_URL, loading: false, })
      return
    }

    const {
      DocuSignUrl,
      PermitExtended,
    } = responseBody
    if (PermitExtended) {
      yield all([
        put({ type: BurnPermitSignatureTypes.LOADING_DOCU_SIGN_URL, loading: false, }),
        put({ type: AppTypes.SHOW_SUCCESS, }),
        fork(getBurnPermitStatusHistory, { burnPermitId: permitId, }),
      ])
      return
    }
    
    if (!DocuSignUrl) {
      yield put({ type: ApiTypes.FAILURE, error: 'There is an issue extending the permit. Please contact support.', })
      yield put({ type: BurnPermitSignatureTypes.LOADING_DOCU_SIGN_URL, loading: false, })
      return
    }
    window.location.href = DocuSignUrl
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Private saga to update suspension start and end dates for a permit
 * @param {number} permitId - The permit Id to update
 * @param {string} revokeData - An Object containing the revocation date and comment
 */
export function* revokePermit ({ permitId, revokeData, }) {
  try {
    yield call(showLoading)

    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Revoke`
    const resp = yield call(doFetch, url, {
      method : 'POST',
      body   : {
        BurnPermitId : permitId,
        StatusDate   : revokeData.RevokedDate,
        Comment      : revokeData.Comment,
      },
    })

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred revoking the permit.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    yield fork(getBurnPermitStatusHistory, { burnPermitId: permitId, })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Reverses the Permit revocation in the event it was improperly revoked
 * @param {number} permitId - The permit Id to restor
 */
export function* undoRevocation ({ permitId, }) {
  try {
    yield call(showLoading)

    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Revoke/Undo`
    const resp = yield call(doFetch, url, { method: 'DELETE', })

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred undoing the permit revocation.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    yield fork(getBurnPermitStatusHistory, { burnPermitId: permitId, })
    yield put({ type: AppTypes.SHOW_SUCCESS, })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Deletes a Pending application. An application in any other state must be cancelled or denied.
 * @param {number} permitId - The permit Id to delete
 */
export function* deleteApplication ({ permitId, }) {
  // Get the local permit first
  const burnPermit = yield select(permitByIdSelector, permitId)
  const { online, } = yield select(networkStateSelector)
  // If it's been saved to the server, submit the req to delete it once we come back online
  if (!burnPermit.IsLocal) {
    yield put(BurnPermitFormActions.deleteApplicationRequest(permitId))
  }
  yield put({ type: BurnPermitFormTypes.ACTIVE_BURN_PERMIT, })
  yield put({ type: CartTypes.REMOVE_ITEM_FROM_CART, itemId: permitId, })

  // If we're not online, delete it locally
  if (!online) {
    yield call(deleteLocalPermit, permitId)
    // If it's local, cancel any pending create request
    if (burnPermit.IsLocal) {
      yield put({
        type        : ApiTypes.CANCEL_SUBMIT,
        action_type : BurnPermitFormTypes.CREATE_PERMIT_APPLICATION_REQUEST,
        url         : `${BURN_PERMIT_ENDPOINT}`,
        method      : 'POST',
        keyName     : 'localId',
        keyValue    : permitId,
      })
    }
  }
}

function* deleteApplicationSuccess (resp) {
  yield call(deleteLocalPermit, resp.permitId)
}

function* deleteLocalPermit (permitId) {
  const { online, } = yield select(networkStateSelector)
  // Delete all related data
  const burnPermit = yield select(permitByIdSelector, permitId)
  yield call(destroyLocalModel, BURN_PERMIT_SEARCH_MODEL_NAME, permitId)
  if (!online) {
    // The configured store is set up to only take the latest of this action
    // so we don't need to manually cancel it ourselves
    yield put(BurnPermitListActions.getMyBurnPermits())
  }
  if (!burnPermit) {
    return
  }
  yield all([
    put({ type: OrmTypes.DESTROY_ALL, modelName: BurnPermitApplicationStatusXref.modelName, filterObj: { BurnPermitId: permitId, }, }),
    put({ type: OrmTypes.DESTROY_ALL, modelName: 'BurnPermitFuelLoadingXref', filterObj: { BurnPermitFuelId: burnPermit.BurnPermitFuelId, }, }),
    put({ type: OrmTypes.DESTROY_ALL, modelName: 'BurnPermitDocument', filterObj: { BurnPermitId: permitId, }, }),
    put({ type: OrmTypes.DESTROY_ALL, modelName: 'BurnPermitSignature', filterObj: { BurnPermitId: permitId, }, }),
    put({ type: OrmTypes.DESTROY_ALL, modelName: 'BurnPermitPileGroup', filterObj: { BurnPermitId: permitId, }, }),
    put({ type: OrmTypes.DESTROY, modelName: BURN_PERMIT_MODEL_NAME, modelId: permitId, }),
    put({ type: OrmTypes.DESTROY, modelName: 'BurnPermitArea', modelId: burnPermit.BurnPermitAreaId, }),
    put({ type: OrmTypes.DESTROY, modelName: 'BurnPermitLocation', modelId: burnPermit.BurnPermitLocationId, }),
    put({ type: OrmTypes.DESTROY, modelName: 'BurnPermitSite', modelId: burnPermit.BurnPermitSiteId, }),
    put({ type: OrmTypes.DESTROY, modelName: 'BurnPermitFuel', modelId: burnPermit.BurnPermitFuelId, }),
  ])
  const lastRoute = yield select(lastRouteSelector)
  yield put({ type: AppTypes.REDIRECT_TO, route: lastRoute.indexOf('new') > -1 ? '/permits' : lastRoute, })
}

/**
 * Copies an existing application and creates a new record from it.
 * @param {number} permitId - The permit Id to copy
 */
export function* copyApplication ({ permitId, }) {
  try {  
    if (!permitId) {
      yield call(showError, 'You must supply the Burn Permit ID of the Permit Application to Copy.')
      return
    }
    let url = `${BURN_PERMIT_ENDPOINT}/${permitId}/CopyBurnPermit`

    const resp = yield call(doFetch, url, {
      method : 'POST',
      body   : { BurnPermitId: permitId, },
    })

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred copying the permit application.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }
    
    yield put({ 
      type  : AppTypes.REDIRECT_TO,
      route : `/permits/${responseBody}`,
    })
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

/**
 * Gets the list of burn units for the current user, or if DNR, for the selected Landowner and/or Agent.
 * @param {number} permitId - The permit Id to use if logged in as a DNR user
 */
export function* getBurnPermitUnits ({ burnPermitId, }) {
  try {
    const { online, } = yield select(networkStateSelector)
    if (!online) {
      return
    }
    let url = `${BURN_PERMIT_ENDPOINT}/GetBurnPermitUnits/`
    if (burnPermitId) {
      url += `?id=${burnPermitId}`
    }

    const resp = yield call(doFetch, url)

    const { responseBody, } = resp
    if (resp.ok !== true) {
      let error = 'An error occurred retrieving the Burn Unit names for the permit application.'
      if (responseBody && responseBody.error) {
        error = responseBody.error
      }
      yield call(showError, error)
      return
    }

    if (Array.isArray(responseBody)) {
      const mappedUnits = responseBody.map(unitName => {
        return {
          BurnPermitUnitName: unitName,
        }
      })
      yield call(replaceAll, 'BurnPermitUnit', mappedUnits)
    }
  }
  catch (error) {
    yield call(showError, error)
  }
  finally {
    yield call(hideLoading)
  }
}

function* unlockApplication ({ permitId, }) {
  try {
    yield call(showLoading)

    const url = `${BURN_PERMIT_ENDPOINT}/${permitId}/Signature`

    const resp = yield call(doFetch, url, { method: 'DELETE', })
    if (resp.ok !== true) {
      const error = resp && resp.responseBody ? resp.responseBody.error : 'An error occurred attempting to unlock your Permit Application. Please contact support.'
      yield call(showError, error)
      return
    }

    const localSignature = yield select(signatureByPermitId, permitId)
    yield call(destroyLocalModel, 'BurnPermitSignature', localSignature.BurnPermitSignatureId)
    yield call(getPermitFiles, { permitId, })
  }
  catch (e) {
    yield call(showError, e)
  }
  finally {
    yield call(hideLoading)
  }
}

function* checkIfFilesAreAlreadyAttached ({ files, }) {
  try {
    const permitId = yield select(activeBurnPermitIdSelector)

    let url = `${BURN_PERMIT_ENDPOINT}/${permitId}/DocumentNameAlreadyAttached`

    const reqs = files.map(f => call(doFetch, `${url}?documentName=${f.name}`))

    const resps = yield all(reqs)

    for (let i = 0, len = resps.length; i < len; i++) {
      const resp = resps[i].responseBody
      if (resp.Exists) {
        yield call(showError, `Document ${resp.DocumentName} is already attached to Burn Permit ID ${resp.BurnPermitId}`)
      }
    }
  }
  catch (e) {
    yield call(showError, e)
  }
}

export const BurnPermitSagas = [
  takeLatest(BurnPermitFormTypes.GET_BURN_PERMIT_STATUS_HISTORY, getBurnPermitStatusHistory),
  takeLatest(BurnPermitFormTypes.SUBMIT_APPLICATION_STATUS, submitApplicationStatus),
  takeLatest(BurnPermitFormTypes.DELETE_APPLICATION_STATUS, deleteApplicationStatus),
  takeLatest(BurnPermitFormTypes.DELETE_PERMIT_STATUS, deletePermitStatus),
  takeLatest(BurnPermitFormTypes.VALIDATE_PERMIT, validatePermit),
  takeLatest(BurnPermitFormTypes.GET_BURN_PERMIT, getBurnPermit),
  takeLatest(BurnPermitFormTypes.GET_BURN_PERMIT_DETAIL, getBurnPermitDetail),
  takeLatest(BurnPermitFormTypes.AGENCY_SUBMIT_BURN_PERMIT, agencySubmitBurnPermit),
  takeLatest(BurnPermitFormTypes.GET_PERMIT_FILES, getPermitFiles),
  takeLatest(BurnPermitFormTypes.UPLOAD_PERMIT_FILES, uploadPermitFiles),
  takeLatest(BurnPermitFormTypes.UPDATE_SUSPENSION, updateSuspension),
  takeLatest(BurnPermitFormTypes.CLEAR_SUSPENSION, clearSuspension),
  takeLatest(BurnPermitFormTypes.EXTEND_PERMIT, extendPermit),
  takeLatest(BurnPermitFormTypes.REVOKE_PERMIT, revokePermit),
  takeLatest(BurnPermitFormTypes.UNDO_REVOCATION, undoRevocation),
  takeLatest(BurnPermitListTypes.DOWNLOAD_BURN_PERMITS, downloadBurnPermits),
  takeLatest(BurnPermitFormTypes.UNLOCK_APPLICATION, unlockApplication),
  // Get lookup data when the app says so
  takeLatest(ApiTypes.DOWNLOAD_LOOKUP_DATA, downloadPermitLookupData),

  takeEvery(BurnPermitFormTypes.DOWNLOAD_PERMIT_FILE, downloadPermitFile),
  takeEvery(BurnPermitFormTypes.DOWNLOAD_ALL_FILES, downloadAllFiles),
  takeEvery(BurnPermitFormTypes.DELETE_PERMIT_FILE, deletePermitFile),
  takeEvery(BurnPermitFormTypes.UPDATE_DOCUMENT_TYPE, updateDocumentType),
  takeEvery(BurnPermitFormTypes.DELETE_APPLICATION, deleteApplication),
  takeEvery(BurnPermitFormTypes.DELETE_APPLICATION_SUCCESS, deleteApplicationSuccess),
  takeEvery(BurnPermitFormTypes.COPY_APPLICATION, copyApplication),
  takeEvery(BurnPermitFormTypes.CREATE_PERMIT_APPLICATION, createPermitApplication),
  takeEvery(BurnPermitFormTypes.CREATE_PERMIT_APPLICATION_SUCCESS, createPermitApplicationSuccess),
  takeEvery(BurnPermitFormTypes.CHECK_IF_FILES_ARE_ALREADY_ATTACHED, checkIfFilesAreAlreadyAttached),
]