import React from 'react'
import { Row, FormFeedback, Col, } from 'reactstrap'
import { string, bool, object, func, PropTypes, } from 'prop-types'
import Editor from '../FormControls/Editor'

const EditorCondition = ({
  value,
  selected,
  condition : {
    BurnPermitConditionId = '',
    BurnPermitConditionDescription = '',
  },
  readOnly,
  onValueChanged,
  valSchema,
  conditionValidityChanged, 
}) => {
  // State and refs
  const [ state, dispatch, ] = React.useReducer(reducer, {
    value,
    valid : true,
    error : '',
  })

  // Update parent when value changes
  React.useEffect(() => {
    if (BurnPermitConditionId && onValueChanged){
      onValueChanged(BurnPermitConditionId, state.value)
    }
  }, [ BurnPermitConditionId, onValueChanged, state.value, ])

  // Update parent when validity changes
  React.useEffect(() => {
    if (BurnPermitConditionId && conditionValidityChanged) {
      conditionValidityChanged(BurnPermitConditionId, state.valid)
    }
  }, [ state.valid, BurnPermitConditionId, conditionValidityChanged, ])

  // Validate when the state value changes
  React.useEffect(() => {
    if (!selected || readOnly || !valSchema) {
      dispatch({ type: 'VALIDATED', payload: { valid: true, error: '', }, })
      return
    }

    let valid = false
    let error = ''
    try {
      valSchema.validateSync(state.value, { abortEarly: true, })
      valid = true
    } catch (err) {
      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        valid = false
        error = err.errors[0]
      }
    }
    dispatch({ type: 'VALIDATED', payload: { valid, error, }, })
  }, [ readOnly, selected, valSchema, state.value, ])

  // Value changeed callback
  const valueChanged = React.useCallback((evt) => {
    dispatch({ type: 'VALUE_CHANGED', payload: evt.target.value, })
  }, [])

  return (
    <Row className={(readOnly && !value) || !selected ? 'grey-text' : null} aria-disabled={(readOnly && !value) || !selected}>
      <Col xs={12} className={'pl-0'} style={{ minHeight: '215px', }}>
        <label htmlFor={`condition-${BurnPermitConditionId}-editor`}>{BurnPermitConditionDescription}:</label>
        <Editor
          wrapperClassName={'editor-condition-outer'}
          editorClassName={'editor-condition-editor'}
          value={selected ? value || '' : ''}
          onChange={valueChanged}
          readOnly={readOnly || !selected}
          disabled={readOnly || !selected}
          invalid={!state.valid}
          aria-label={'Condition #' + BurnPermitConditionId}
          id={`condition-${BurnPermitConditionId}-editor`}
          name={`condition-${BurnPermitConditionId}-editor`}
        />
        <FormFeedback valid={state.valid}>{state.error}</FormFeedback>
        { !readOnly && selected && <label htmlFor={`condition-${BurnPermitConditionId}-editor`} className={'font-italic'}>Text formatting will not be carried onto the Issued Permit document in DocuSign.</label> }
      </Col>
    </Row>
  )
}

EditorCondition.defaultProps = {
  selected                 : false,
  condition                : {},
  value                    : '',
  readOnly                 : false,
  unit                     : null,
  onValueChanged           : null,
  valSchema                : null,
  conditionValidityChanged : null,
  type                     : 'text',
}

EditorCondition.propTypes = {
  selected                 : bool,
  condition                : object,
  value                    : PropTypes.oneOfType([ string, object, ]),
  type                     : string,
  readOnly                 : bool,
  unit                     : string,
  onValueChanged           : func.isRequired,
  valSchema                : object,
  conditionValidityChanged : func.isRequired,
}

function reducer (state, action) {
  if (!action || !action.type) {
    return state
  }
  switch (action.type) {
  case 'VALIDATED' :
    if (!action.payload) {
      break
    }
    return {
      ...state,
      valid : action.payload.valid,
      error : action.payload.error,
    }
  case 'VALUE_CHANGED':
    return {
      ...state,
      value: action.payload,
    }
  default:
    break
  }
  return state
}

export default React.memo(EditorCondition)