import React, { useMemo, } from 'react'

function DownloadPDF () {
  const style = useMemo(() => ({ 'fontSize': '1.5em', color: 'red', }), [])
  return <span
    className={'far fa-file-pdf ml-2'}
    style={style}
  />
}

export default DownloadPDF