// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { func, object, } from 'prop-types'

// Components
import CartButton from './CartButton'
import CartAlert from './CartAlert'
import CartModal from './CartModal'

// Reducers
import UiActions from '../../redux/UiRedux'

// Utilities
import stopEvent from '../../utilities/stopEvent'
import { REMOVE_PERMIT_MODAL, } from './RemovePermitFromOrderModal'

const EMPTY_CART_MODAL = 'EMPTY_CART_MODAL'


class Cart extends React.Component {
  constructor (props) {
    super(props)
   
    this.emptyCartConfirm = this.emptyCartConfirm.bind(this)
  }

  static propTypes = {
    cart        : object,
    cartActions : object,
    ShowModal   : func,
    CloseModal  : func,
  }
  
  static defaultProps = {
    enablePrompt: false,
  }

  state = {
    isModalVisible: false,
  }

  hideCart = evt => {
    stopEvent(evt)
    this.setState({ isModalVisible: false, })
  }

  emptyCart = evt => {
    stopEvent(evt)
    this.props.ShowModal(EMPTY_CART_MODAL)
  }

  emptyCartConfirm (evt) {
    stopEvent(evt)
    // CloseModal is called twice intentionally
    // Without it the UI does not reset completely
    // Prob something to do with having two modals show simultaneously
    this.props.cartActions.emptyCart()
    this.props.CloseModal()
    this.setState({ isModalVisible: false, }, this.props.CloseModal)
  }

  showCart = evt => {
    stopEvent(evt)
    this.setState({ isModalVisible: true, })
  }

  removePermit = evt => {
    const { id, } = evt.target.dataset
    this.setState({ itemIdToRemove: id, }, () => {
      this.props.ShowModal(REMOVE_PERMIT_MODAL)
    })
  }

  removeItemFromCart = itemId => {
    this.props.cartActions.removeFromCart(itemId)
    return true
  }
  
  createOrder = evt => {
    stopEvent(evt)

    if (this.props.cart.current.length === 0) {
      this.setState({ validationError: 'You must select at least one permit to create an order', })
      return
    }

    this.props.cartActions.createOrder(this.props.cart.current)
  }

  render () {
    const { cart, } = this.props
    const { isModalVisible, itemIdToRemove, } = this.state
    return <div className={'cart d-flex justify-content-between'}>
      <CartAlert
        visible={cart.current.length > 0}
        orderItemCount={cart.current.length}
      />
      <CartButton
        onClick={this.showCart}
        disabled={cart.current.length === 0}
        badgeValue={cart.current.length}
      />
      <CartModal
        isOpen={isModalVisible}
        permitIds={[ ...cart.current, ]}
        idToRemove={itemIdToRemove}
        onRemoveClick={this.removePermit}
        confirmRemoveClick={this.removeItemFromCart}
        onCheckoutClick={this.createOrder}
        onCloseClick={this.hideCart}
        onEmptyCartClick={this.emptyCart}
        confirmEmptyClick={this.emptyCartConfirm}
      />
    </div>
  }
}

const mapDispatchToProps = {
  ShowModal  : UiActions.openModal,
  CloseModal : UiActions.closeModal,
}

export default connect(null, mapDispatchToProps)(Cart)