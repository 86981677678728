// Libraries
import React from 'react'
import { connect, } from 'react-redux'
import { isEqual, cloneDeep, difference, } from 'lodash'
import { object, string, bool, func, array, number, oneOfType, } from 'prop-types'

// ESRI ES Modules
import ArcGISMap from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import { addProxyRule, } from '@arcgis/core/core/urlUtils'
import config from '@arcgis/core/config'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer'
import Sketch from '@arcgis/core/widgets/Sketch'
import Graphic from '@arcgis/core/Graphic'
import { geographicToWebMercator, } from '@arcgis/core/geometry/support/webMercatorUtils'
import Extent from '@arcgis/core/geometry/Extent'
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery'
import Compass from '@arcgis/core/widgets/Compass'
import Expand from '@arcgis/core/widgets/Expand'
import Fullscreen from '@arcgis/core/widgets/Fullscreen'
import Home from '@arcgis/core/widgets/Home'
import LayerList from '@arcgis/core/widgets/LayerList'
import Legend from '@arcgis/core/widgets/Legend'
import Locate from '@arcgis/core/widgets/Locate'

// Reducers
import MapActions from '../redux/MapRedux'
import AppActions from '../redux/AppRedux'

// Symbols
import { POINT_SYMBOL, POLYLINE_SYMBOL, POLYGON_SYMBOL, } from '../config/map/symbols'

// Map Layers
import FeatureLayers from '../config/map/featureLayers'

// Utilities
import { objHasProp, } from '../utilities'
import retry from '../utilities/retry'
import { getTileUrlsByExtent, buildPopupTemplate, getBasemapLayer, } from '../utilities/map'

// Selectors
import { getUserToken, } from '../selectors/userSelectors'
import { appIsOnlineSelector, } from '../selectors/selectors'
import { hiddenLayerIdsSelector, mapStateSelector, } from '../selectors/mapSelectors'

// eslint-disable-next-line no-undef
const { REACT_APP_REQUEST_PROXY_URL, } = process.env


// Pad the extent with some buffer in case any side of the extent is near the
// edge of a row or col of a tile
const extentBufferDegrees = 0.15 // this is approximately 10 miles

class ESRIMap extends React.Component {

  static propTypes = {
    online                        : bool,
    ShowLoading                   : func,
    HideLoading                   : func,
    MapLoading                    : func,
    MapDoneLoading                : func,
    SetLatLong                    : func,
    drawCallback                  : func,
    showControls                  : bool,
    center                        : array,
    zoom                          : number,
    mapData                       : array,
    mapLayers                     : array,
    graphics                      : array,
    layerId                       : string,
    overlapGraphics               : array,
    canEdit                       : bool,
    editGraphic                   : object,
    pointSymbol                   : object,
    polygonSymbol                 : object,
    polylineSymbol                : object,
    layerTitle                    : string,
    GetLatLongFromMap             : bool,
    IncludeIntersectingFeatures   : bool,
    featureLayers                 : array,
    geojsonLayers                 : array,
    hiddenLayerIds                : array,
    TargetFeatureLayerIds         : array,
    HideLayer                     : func,
    ShowLayer                     : func,
    downloadMapData               : bool,
    ToggleDownloadMapData         : func,
    GraphicsOverlapFeatures       : func,
    ActiveMapId                   : func,
    MapDragEnd                    : func,
    config                        : string,
    token                         : string,
    legendOpen                    : bool,
    parentRef                     : oneOfType([ object, func, ]),
    onLayerViewCreate             : func,
    onIntersectingFeaturesChanged : func,
  }

  static defaultProps = {
    actions        : {},
    showControls   : true,
    center         : [ -120.4443, 47.2529, ],
    zoom           : 5,
    mapData        : [],
    graphics       : [],
    editGraphic    : {},
    hiddenLayerIds : [],
    polygonSymbol  : POLYGON_SYMBOL,
    pointSymbol    : POINT_SYMBOL,
    polylineSymbol : POLYLINE_SYMBOL,
    canAdd         : false,
    canEdit        : false,
    canDelete      : false,
    legendOpen     : true,
    parentRef      : null,
    featureLayers  : FeatureLayers,
    geojsonLayers  : [],
  }

  state = {
    layerViews: {},
  }

  constructor (props) {
    super(props)

    config.request.proxyUrl = REACT_APP_REQUEST_PROXY_URL
    // Proxy Rules use pattern matching to force the use of the proxy service
    // for all resource requests that match the provided prefixes.
    addProxyRule({
      urlPrefix : 'https://wamas.watech.wa.gov',
      proxyUrl  : REACT_APP_REQUEST_PROXY_URL,
    })
    addProxyRule({
      urlPrefix : 'https://gis.dnr.wa.gov',
      proxyUrl  : REACT_APP_REQUEST_PROXY_URL,
    })
    
    this.props.MapLoading()

    this.setLatLong = this.setLatLong.bind(this)
    this.loadMap = this.loadMap.bind(this)
    this.checkIfGraphicsOverlapLayerFeatures = this.checkIfGraphicsOverlapLayerFeatures.bind(this)
    this.checkLayersAreVisible = this.checkLayersAreVisible.bind(this)
    this.showAllLayers = this.showAllLayers.bind(this)
    this.updateVisibleLayers = this.updateVisibleLayers.bind(this)
  }

  componentDidMount () {
    this.loadMap()
  }

  componentDidUpdate (prevProps, prevState) {
    const { mapData, graphics, GetLatLongFromMap, layerId, overlapGraphics , } = this.props

    if (this.map !== null && !isEqual(prevProps.mapData, mapData)) {
      this.drawGraphics()
    }
    if (this.view !== null && this.graphicsLayer && !isEqual(prevProps.graphics, graphics)) {
      this.graphicsLayer.removeAll()
      for (let g = 0, gLen = graphics.length; g < gLen; g++) {
        this.addGraphic(graphics[g])
      }
      this.view.goTo(this.graphicsLayer.graphics)
    }

    if (this.view && prevProps.GetLatLongFromMap === false && GetLatLongFromMap === true) {
      this.configureMapClick()
    }
    else if (GetLatLongFromMap !== true) {
      this.disableMapClick()
    }

    if (!prevProps.downloadMapData && this.props.downloadMapData) {
      this.downloadTiles()
    }

    if (!!layerId && Array.isArray(overlapGraphics) && overlapGraphics.length) {
      if (!isEqual(prevProps.overlapGraphics, overlapGraphics) || !isEqual(prevProps.layerId, layerId)) {
        this.checkIfGraphicsOverlapLayerFeatures()
      }
    }
    if (this.Legend && Object.entries(prevState.layerViews).length !== Object.entries(this.state.layerViews).length) {
      this.buildLegend()
    }

    const { hiddenLayerIds, } = this.props
    if (!isEqual(prevProps.hiddenLayerIds, hiddenLayerIds)) {
      const layersToShow = difference(prevProps.hiddenLayerIds, hiddenLayerIds)
      const layersToHide = difference(hiddenLayerIds, prevProps.hiddenLayerIds)
      this.updateVisibleLayers(layersToShow, layersToHide)
    }
  }

  updateVisibleLayers (layersToShow, layersToHide) {
    const { layerViews, } = this.state
    const { TargetFeatureLayerIds, hiddenLayerIds, } = this.props

    // Default the visible property if the layerId is in the hiddenLayerIds prop
    for (const layer of this.map.allLayers) {
      layer.visible = hiddenLayerIds.includes(layer.id) === false
    }

    let layerId
    // update both the layerview state and the view layerviews
    // because they can be out of sync sometimes
    for (let key in layerViews) {
      const lView = layerViews[key]
      layerId = lView.layer.id
      if (TargetFeatureLayerIds && !TargetFeatureLayerIds.includes(layerId)) {
        continue
      }

      if (layersToShow.includes(layerId)) {
        lView.visible = true
      }
      else if (layersToHide.includes(layerId)) {
        lView.visible = false
      }
    }

    for (const layerView of this.view.layerViews) {
      layerId = layerView.layer.id
      if (TargetFeatureLayerIds && !TargetFeatureLayerIds.includes(layerId)) {
        continue
      }

      if (layersToShow.includes(layerId)) {
        layerView.visible = true
      }
      else if (layersToHide.includes(layerId)) {
        layerView.visible = false
      }
    }
  }

  componentWillUnmount () {
    if (this.props.parentRef && this.props.parentRef.current) {
      this.props.parentRef.current = null
    }

    if (this.map) {
      this.map.destroy()
    }

    // Clear the active map id so that the selectors
    // don't cache the current map if the user comes right back
    this.props.ActiveMapId()
  }
  
  handleFail = (e) => {
    console.error(e)
    this.setState({ status: 'failed', })
  }

  checkIfGraphicsOverlapLayerFeatures () {
    const layerView = this.state.layerViews[this.props.layerId]
    // Wait until the layerView has been created and available in the component state
    if (!layerView) {
      if (this.hasRecursed > 5) {
        console.error(`The expected layer ${this.props.layerId} was never made available as a view`)
        this.hasRecursed = 0
        return
      }
      this.hasRecursed++
      setTimeout(this.checkIfGraphicsOverlapLayerFeatures, 150)
      return
    }
    // Wait until the layer is visible in the map and done updating
    if (!layerView.visible) {
      if (this.hasRecursed > 5) {
        console.error(`The expected layer ${this.props.layerId} did not load in time`)
        this.hasRecursed = 0
        return
      }
      layerView.visible = true
      this.hasRecursed++
      setTimeout(this.checkIfGraphicsOverlapLayerFeatures, 250)
      return
    }
    this.hasRecursed = 0
    
    const { overlapGraphics, } = this.props
    for (let i = 0, len = overlapGraphics.length; i < len; i++) {
      const query = { spatialRelationship: 'intersects', }
      const graphic = overlapGraphics[i]
      if (graphic.geometry) {
        query.geometry = graphic.geometry
      }
      layerView.queryFeatures(query).then(resp => {
        if (resp.features.length > 0) {
          this.props.GraphicsOverlapFeatures(true, resp.features)
        }
      })
    }
  }

  getExtentForGraphic = (graphic) => {
    let extent = new Extent({
      xmin : graphic.geometry.x - extentBufferDegrees,
      xmax : graphic.geometry.x + extentBufferDegrees,
      ymin : graphic.geometry.y - extentBufferDegrees,
      ymax : graphic.geometry.y + extentBufferDegrees,
    })
    if (extent.spatialReference.isGeographic) {
      extent = geographicToWebMercator(extent)
    }
    return extent
  }

  downloadTiles = async () => {
    if (!this.map) {
      await retry(this.downloadTiles, null, 100)
      return
    }
    const { tileInfo, } = getBasemapLayer(this.map)
    if (tileInfo) {
      let graphicsLayer = this.map.layers.filter(l => l.type === 'graphics')
      if (graphicsLayer && graphicsLayer.items.length) {
        graphicsLayer = graphicsLayer.items[0]
      }
      let extent, gLen = graphicsLayer.graphics.length
      if (gLen > 1) {
        let xmin, xmax, ymin, ymax
        for (var i = 0; i < gLen; i++) {
          const { geometry, } = graphicsLayer.graphics.items[i]
          if (!xmin || geometry.x < xmin) {
            xmin = geometry.x - extentBufferDegrees
          }
          if (!xmax || geometry.x > xmax) {
            xmax = geometry.x + extentBufferDegrees
          }
          if (!ymin || geometry.y < ymin) {
            ymin = geometry.y - extentBufferDegrees
          }
          if (!ymax || geometry.y > ymax) {
            ymax = geometry.y + extentBufferDegrees
          }
        }
        extent = new Extent({
          xmin : xmin,
          xmax : xmax,
          ymin : ymin,
          ymax : ymax,
        })
      }
      else {
        extent = this.getExtentForGraphic(graphicsLayer.graphics.items[0])
      }
      
      if (extent.spatialReference.isGeographic) {
        extent = geographicToWebMercator(extent)
      }
      
      let urls = []
      // For the high zoom levels, macro view, use the general extent of all graphics
      let minLevel = 5, maxLevel = 10
      // If it's a single graphic, just keep going all the way down
      if (graphicsLayer.graphics.length === 1) {
        maxLevel = 15
      }
      var l, j, jLen = graphicsLayer.graphics.length
      for (l = minLevel; l < maxLevel; l++) {
        const tileUrls = getTileUrlsByExtent(extent, l, this.map)
        urls = [ ...urls, ...tileUrls, ]
      }

      if (graphicsLayer.graphics.length > 1) {
        // For the medium zoom levels, get tile urls for each graphic, creating a half
        let minLevel = 10, maxLevel = 15
        for (l = minLevel; l < maxLevel; l++) {
          for (j = 0; j < jLen; j++) {
            const _ext = this.getExtentForGraphic(graphicsLayer.graphics.items[j])
            const tileUrls = getTileUrlsByExtent(_ext, l, this.map)
            urls = [ ...urls, ...tileUrls, ]
          }
        }
      }

      console.log(`Total ${urls.length} tiles`)

      urls = [ ...new Set(urls), ]

      console.log(`Total ${urls.length} unique tiles`)
      const reqs = []
      var uLen = urls.length
      for (var u = 0; u < uLen; u++) {
        reqs.push(fetch(urls[u]))
      }
      await Promise.all(reqs)
      this.props.ToggleDownloadMapData()
    }
  }

  drawCallback = (graphics) => {
    const { drawCallback, } = this.props
    if (typeof drawCallback === 'function') {
      this.props.drawCallback(graphics)
    }
  }

  hasRecursed = 0

  drawGraphics = () => {
    let { mapData, } = this.props
    mapData = mapData || []

    // Clear any popups that may be open
    this.view.popup.close()

    for (let i = 0, len = mapData.length; i < len; i++) {
      const layer = mapData[i]
      if (!layer || !layer.layerId) {
        continue
      }
      const mapLayer = this.map.layers.items.find(l => l.id === layer.layerId.toString())
      if (!mapLayer) {
        this.hasRecursed++
        if (this.hasRecursed === 5) {
          throw new Error('There is an issue displaying the map symbols at this time. Try refreshing the page to fix the problem.')
        }
        this.buildGraphicsLayers()
        return
      }
      // Remove all graphics from the layer since we're (re)building with every state change
      mapLayer.removeAll()

      // If no data in the layer
      if (!layer.data || layer.data.length === 0) {
        // Move on to the next layer
        continue
      }
      
      let popupTemplate
      if (objHasProp(layer, 'fields')) {
        // Build a popup template to use for the feature info popup
        popupTemplate = buildPopupTemplate(layer)
      }
      
      const graphics = []
      if (Array.isArray(layer.data) !== true) {
        throw new Error('ESRIMap expected an array for the props.mapData[]layer.data, but received something else')
      }
      for (let i = 0, len = layer.data.length; i < len; i++) {
        const layerObj = layer.data[i]
        const _graphic = new Graphic({
          geometry: layerObj.geometry || {
            type      : 'point',
            longitude : layerObj.longitude || layerObj.attributes.longitude,
            latitude  : layerObj.latitude || layerObj.attributes.latitude,
          },
          attributes : layerObj.attributes,
          // Fallback to the default point symbol
          symbol     : layerObj.symbol || cloneDeep(this.props.pointSymbol),
        })
        _graphic.popupTemplate = popupTemplate || layerObj.popupTemplate
        graphics.push(_graphic)
      }
      mapLayer.addMany(graphics)
      if (graphics.length) {
        this.view.goTo(graphics)
      }
    }
  }

  addLayersToMap = () => {
    const { mapLayers, } = this.props
    if (Array.isArray(mapLayers)) {
      this.map.addMany(mapLayers)
    }
  }

  buildGraphicsLayers = () => {
    const { mapData, } = this.props

    for (let i = 0, len = mapData.length; i < len; i++) {
      const layer = mapData[i]
      if (!layer) {
        continue
      }
      const graphicsLayer = new GraphicsLayer({
        id    : layer.layerId,
        title : layer.layerTitle,
      })
      this.map.add(graphicsLayer)
    }
    this.drawGraphics()
  }

  createMapControls () {

    const targetWidgetLocation = 'top-left'

    // Keep the Zoom widget to provide a more accessible method to zoom in/out
    // in case users have a disability or pinch to zoom is difficult to use.
    this.view.ui.move('zoom', 'bottom-left')

    const basemapGallery = new BasemapGallery({
      view      : this.view,
      container : document.createElement('div'),
      group     : 'toolbarGroup',
    })

    const bgExpand1 = new Expand({
      view            : this.view,
      content         : basemapGallery.domNode,
      container       : document.createElement('div'),
      expandIconClass : 'esri-icon-basemap',
    })

    const compassWidget = new Compass({
      view      : this.view,
      container : document.createElement('div'),
    })

    const fullscreen = new Fullscreen({
      view      : this.view,
      container : document.createElement('div'),
    })

    const homeWidget = new Home({
      view: this.view,
    })

    const layerList = new LayerList({
      container : document.createElement('div'),
      view      : this.view,
    })

    const locate = new Locate({
      view: this.view,
    })

    const layerListExpand = new Expand({
      // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
      expandIconClass : 'esri-icon-layer-list',
      view            : this.view,
      content         : layerList.domNode,
    })

    this.view.ui.add(layerListExpand, targetWidgetLocation)
    this.view.ui.add(bgExpand1, targetWidgetLocation)
    this.view.ui.add(compassWidget, targetWidgetLocation)
    this.view.ui.add(fullscreen, targetWidgetLocation)
    this.view.ui.add(homeWidget, targetWidgetLocation)
    this.view.ui.add(locate, targetWidgetLocation)

    this.buildLegend()
  }

  // this is a crude approach, but whenever users load a map, navigate away, then come back, especially on the dashboard
  // the legend is constructed before the feature layers are added to the map and ready, so it tries to render a legend
  // for all layers, including the graphics layers (permits, requests, etc.) which are not supported
  buildLegend = () => {
    if (this.Legend) {
      this.Legend.destroy()
      this.Legend = null
    }
    const legend = new Legend({
      view       : this.view,
      container  : document.createElement('div'),
      layerInfos : this.getLegendLayers(),
    })
    const legendExpand = new Expand({
      view              : this.view,
      expanded          : this.props.legendOpen,
      content           : legend.domNode,
      expandIconClass   : 'esri-icon-visible',
      expandTooltip     : 'Show map legend',
      collapseIconClass : 'esri-icon-non-visible',
      collapseTooltip   : 'Hide map legend',
    })
    this.Legend = legendExpand
    
    this.view.ui.add(legendExpand, 'bottom-right')
  }

  getLegendLayers = () => {
    return Object.entries(this.state.layerViews).map(e => {
      const { layer, } = e[1]
      return { title: layer.title, layer, }
    }, [])
  }

  configureFeatureLayers = () => {
    const { featureLayers, hiddenLayerIds, } = this.props
    const layers = []
    let featLayer
    for (let i = 0, len = featureLayers.length; i < len; i++) {
      featLayer = new FeatureLayer(featureLayers[i])
      featLayer.visible = hiddenLayerIds.includes(featLayer.id) === false
      layers.push(featLayer)
    }
    this.map.addMany(layers)
    layers.forEach(l => {
      this.view.whenLayerView(l).then(lv => {
        this.setState({ layerView: { ...this.state.layerViews, [lv.layer.id]: lv, }, })
      })
    })
  }

  configureGeoJSONLayers = () => {
    const { geojsonLayers, hiddenLayerIds, } = this.props

    let layers = [], geoLayer
    for (let i = 0, len = geojsonLayers.length; i < len; i++) {
      geoLayer = new GeoJSONLayer(geojsonLayers[i])
      geoLayer.visible = hiddenLayerIds.includes(geoLayer.id) === false
      layers.push(geoLayer)
    }
    this.map.addMany(layers)
  }

  disableMapClick = () => {
    if (this.mapClickEvent) {
      if (typeof this.mapClickEvent['remove'] === 'function') {
        this.mapClickEvent.remove()
      }
      this.mapClickEvent = null
    }
  }
  
  checkLayersAreVisible () {
    const { layerViews, } = this.state
    const { TargetFeatureLayerIds, } = this.props
    const layerVisibility = []

    // check the layerview state and the view layerviews
    // because they can be out of sync sometimes
    for (let key in layerViews) {
      const lView = layerViews[key]
      if (TargetFeatureLayerIds && TargetFeatureLayerIds.includes(lView.layer.id)) {
        layerVisibility.push(lView.visible)
      }
    }
    
    for (const layerView of this.view.layerViews) {
      if (TargetFeatureLayerIds && TargetFeatureLayerIds.includes(layerView.layer.id)) {
        layerVisibility.push(layerView.visible)
      }
    }
    return layerVisibility.every(v => v === true)
  }
  
  showAllLayers () {
    this.updateVisibleLayers(this.props.TargetFeatureLayerIds, [])
  }

  async getIntersectingFeatures ({ query, recursed = false, }) {
    let IntersectingFeatures = []
    if (!this.checkLayersAreVisible()) {
      this.showAllLayers()
      if (!recursed) {
        return await retry(this.getIntersectingFeatures.bind(this), { query, recursed: true, }, 100)
      }
      return []
    }

    for (const layerView of this.view.layerViews) {
      if (this.props.TargetFeatureLayerIds.includes(layerView.layer.id)) {
        const resp = await layerView.queryFeatures(query)
        if (resp.features.length > 0) {
          const features = resp.features.map(f => ({ layerId: f.layer.id, attributes: f.attributes, }), [])
          IntersectingFeatures = [ ...IntersectingFeatures, ...features, ]
        }
      }
    }
    return IntersectingFeatures
  }

  async setLatLong (evt) {
    // Latitudes are +/- 0:90 and Longitudes are +/- 0:180
    // The precision arguments are intentionally different in 
    // order to display the same number of decimal values
    const Latitude = parseFloat(evt.mapPoint.latitude.toPrecision(8))
    const Longitude = parseFloat(evt.mapPoint.longitude.toPrecision(9))
    const includeIntersecting = evt.includeIntersectingFeatures === true || this.props.IncludeIntersectingFeatures === true
    let intersecting = []
    if (includeIntersecting) {
      const query = {
        geometry: {
          type      : 'point',
          longitude : Longitude,
          latitude  : Latitude,
        },
      }
      intersecting = await this.getIntersectingFeatures({ query, })
    }
    this.props.SetLatLong(Latitude, Longitude)
    if (this.props.onIntersectingFeaturesChanged) {
      this.props.onIntersectingFeaturesChanged(intersecting)
    }
  }

  configureMapClick = () => {
    this.mapClickEvent = this.view.on('click', this.setLatLong)
  }

  loadMap () {

    this.props.ShowLoading()
    this.props.ActiveMapId(this.props.config)

    this.map = new ArcGISMap({
      basemap: 'streets-vector',
    })

    const view = new MapView({
      container : 'mapViewDiv',
      map       : this.map,
      center    : this.props.center,
      zoom      : this.props.zoom,
    })

    this.view = view

    this.view.on('layerview-create', lv => {
      this.setState({ layerView: { ...this.state.layerViews, [lv.layer.id]: lv, }, })
      if (typeof this.props.onLayerViewCreate === 'function') {
        this.props.onLayerViewCreate(lv)
      }
    })

    this.view.on('drag', evt => {
      if (evt.action === 'end') {
        const { center, zoom, } = this.view.map
        this.props.MapDragEnd({ dragEvt: evt, center, zoom, })
      }
    })

    const { showControls, canEdit, mapData, mapLayers, GetLatLongFromMap, } = this.props

    if (GetLatLongFromMap === true) {
      this.configureMapClick()
    }

    this.configureFeatureLayers()
    this.configureGeoJSONLayers()

    if (showControls) {
      this.createMapControls()
    }

    if (canEdit) {
      this.bindSketch()
    }

    if (mapData.length) {
      this.buildGraphicsLayers()
    }

    if (mapLayers) {
      this.addLayersToMap()
    }

    this.view.popup.on('trigger-action', evt => {
      if (typeof evt.action.customAction === 'function') {
        evt.features = [ ...this.view.popup.features, ]
        evt.action.customAction(evt)
      }
    })

    this.props.HideLoading()
    setTimeout(this.props.MapDoneLoading, 1)

    if (this.props.parentRef) {
      const refObj = {
        map        : this.map,
        view       : this.view,
        setLatLong : this.setLatLong,
      }
      if (typeof this.props.parentRef === 'function') {
        this.props.parentRef(refObj)
      } else {
        this.props.parentRef.current = refObj
      }
    }
  }

  getSymbolByGeometry = (geometry) => {
    if (!geometry) {
      return
    }
    let symbol
    if (objHasProp(geometry, 'x') || objHasProp(geometry, 'latitude')) {
      symbol = this.props.pointSymbol
    }
    else if (objHasProp(geometry, 'paths')) {
      symbol = this.props.polylineSymbol
    }
    else if (objHasProp(geometry, 'centroid') || objHasProp(geometry, 'rings') || objHasProp(geometry, 'coordinates')) {
      symbol = this.props.polygonSymbol
    }
    return symbol
  }

  /**
   * Called when `this.props.sketchViewModel`'s `create-complete` event is fired.
   */
  addGraphic (graphic) {
    let symbol = this.getSymbolByGeometry(graphic.geometry)
    // Create a new graphic and set its geometry to
    // `create-complete` event geometry.
    const newGraphic = new Graphic({
      attributes    : graphic.attributes || {},
      geometry      : geographicToWebMercator(graphic.geometry),
      popupTemplate : graphic.popupTemplate || {},
      symbol,
    })
    this.graphicsLayer.graphics.add(newGraphic)
  }

  /**
   * Called when the `#btn-clear` button is clicked
   */
  clearGraphic (e) {
    e.preventDefault()

    this.sketchViewModel.reset()
    this.graphicsLayer.removeAll()
  }

  /**
   * Called when the view is done rendering. This sets up the Esri JS widgets
   * and binds them to the component.
   * @param {Object} Sketch
   * @param {Object} GraphicsLayer
   */
  bindSketch () {
    const { graphics, editGraphic, layerTitle, canEdit, } = this.props

    this.graphicsLayer = this.map.graphicsLayer
    if (typeof this.graphicsLayer === 'undefined') {
      // GraphicsLayer to hold graphics created via sketch view model
      this.graphicsLayer = new GraphicsLayer({
        id    : 'tempGraphics',
        title : layerTitle || 'Graphics',
      })
      this.map.add(this.graphicsLayer)
    }

    if (Array.isArray(graphics)) {
      for(let g = 0, gLen = graphics.length; g < gLen; g++) {
        this.addGraphic(graphics[g], false)
      }
    }

    if (editGraphic && Object.keys(editGraphic).length > 0) {
      this.addGraphic(editGraphic, false)
    }
    this.view.goTo(this.graphicsLayer.graphics)

    if (canEdit !== true) {
      return
    }

    const sketch = new Sketch({
      layer : this.graphicsLayer,
      view  : this.view,
    })

    this.view.ui.add(sketch, 'top-right')

    const self = this
    // Listen the sketch's update-complete and update-cancel events
    sketch.on('update', function (evt) {
      const toolType = evt.toolEventInfo.type
      if (evt.state === 'complete') {
        sketch.update({
          tool     : 'move',
          graphics : [ evt.graphics[0], ],
        })
      }
      else if (toolType === 'move-stop') {
        self.drawCallback(evt.graphics)
        sketch.complete()
      }
    })
    // Listen the sketch's update-complete and update-cancel events
    sketch.on('complete', function (evt) {
      this.drawCallback(evt.graphics)
    })
  }

  render () {
    return <div className={'w-100 position-relative'} id={'mapViewDiv'} />
  }
}

function mapStateToProps (state) {
  const online = appIsOnlineSelector(state)
  const mapState = mapStateSelector(state)
  const hiddenLayerIds = hiddenLayerIdsSelector(state)
  return {
    online,
    ...mapState,
    hiddenLayerIds,
    token: getUserToken(state),
  }
}

const mapDispatchToProps = {
  SetLatLong              : MapActions.setLatLong,
  MapLoading              : MapActions.mapLoading,
  MapDoneLoading          : MapActions.mapDoneLoading,
  HideLayer               : MapActions.hideLayer,
  ShowLayer               : MapActions.showLayer,
  ToggleDownloadMapData   : MapActions.toggleDownloadMapData,
  GraphicsOverlapFeatures : MapActions.graphicsOverlapFeatures,
  ActiveMapId             : MapActions.activeMapId,
  MapDragEnd              : MapActions.mapDragEnd,
  ShowLoading             : AppActions.showLoading,
  HideLoading             : AppActions.hideLoading,
}
export { ESRIMap, }
export default connect(mapStateToProps, mapDispatchToProps)(ESRIMap)