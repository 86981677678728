import React from 'react'
import { Row, } from 'reactstrap'
import { string, func, array, bool, } from 'prop-types'

import FuelDiameter from './FuelDiameter'
import { useSelector, } from 'react-redux'
import { fuelDiametersSelector, } from '../../selectors/selectors'


const FuelDiameters = props => {
  const {
    BurnPermitFuelLoadingXref,
    inputLabelsClass,
    inputRowsClass,
    onChange,
    readOnly,
  } = props

  const FuelDiams = useSelector(fuelDiametersSelector)
  
  const markup = []
  
  const fuelDiamCats = [ ...new Set(FuelDiams.map(d => d.FuelDiameterCategory)), ]
  
  // loop diam cats, filter fueldiams by current cat
  for (let i = 0, len = fuelDiamCats.length; i < len; i++) {
    const fuelDiamCat = fuelDiamCats[i]
    // push cat node to array
    markup.push(
      <Row className={inputRowsClass} key={`fuel-diam-cat-${i}`}>
        <i>{fuelDiamCat}</i>
      </Row>
    )
    const fuelDiams = FuelDiams.filter(d => d.FuelDiameterCategory === fuelDiamCat)
    for (let j = 0, jLen = fuelDiams.length; j < jLen; j++) {
      const fuelDiam = fuelDiams[j]
      // set diam value from state
      const setFuelDiam = BurnPermitFuelLoadingXref.find(f => f.FuelDiameterId === fuelDiam.FuelDiameterId)
      const tonsPerAcre = setFuelDiam ? setFuelDiam.TonsPerAcre : ''
      // push diam nodes to array
      markup.push(<FuelDiameter
        key={`fuel-diam-${fuelDiam.FuelDiameterId}`}
        fuelDiam={fuelDiam}
        value={tonsPerAcre}
        inputLabelsClass={inputLabelsClass}
        inputRowsClass={inputRowsClass}
        onChange={onChange}
        readOnly={readOnly}
      />)
    }
  }
  return markup
}

FuelDiameters.propTypes = {
  BurnPermitFuelLoadingXref : array,
  onChange                  : func,
  inputLabelsClass          : string,
  inputRowsClass            : string,
  readOnly                  : bool,
}

FuelDiameters.defaultProps = {
  BurnPermitFuelLoadingXref : [],
  onChange                  : () => {},
  inputLabelsClass          : '',
  inputRowsClass            : '',
}

export default FuelDiameters