import React, { useCallback, useMemo, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import { func, number, oneOfType, string, } from 'prop-types'
import { Field, Form, Formik, } from 'formik'
import * as Yup from 'yup'
import { Button, Col, FormGroup, Row, } from 'reactstrap'

// Reducers
import CustomerInteractionActions from '../../../redux/CustomerInteractionRedux'

// Selectors
import { customerInteractionStatusesSelector, } from '../../../selectors/customerInteractionSelectors'

// Components
import {
  AssignedStaff,
  RequiredLabel,
  Select,
  ValidatingField,
  YesNoButtonGroup,
} from '../../FormControls'
import AssignToMe from '../../FormControls/AssignToMe'

// Icons
import RequiredIndicator from '../../Icons/RequiredIndicator'


const CUSTOMER_INTERACTION_STATUS_SCHEMA = Yup.object().shape({
  CustomerInteractionId       : Yup.number().required().min(1, 'A Customer Interaction ID must be set'),
  CustomerInteractionStatusId : Yup.number().required('You must choose an status'),
  AssignedTo                  : Yup.string().ensure().required('You must assign someone'),
  AssignedToId                : Yup.string().ensure().required('You must assign someone'),
  Comment                     : Yup.string().ensure().required('You must provided a comment'),
  CommentInternalOnly         : Yup.bool().required('You must choose Yes or No'),
  ContactedReportingParty     : Yup.bool().required('You must choose Yes or No'),
})

const StatusForm = props => {
  const { CustomerInteractionId, onCancelClick, } = props

  // Selectors
  const statuses = useSelector(customerInteractionStatusesSelector)

  // Redux actions
  const dispatch = useDispatch()

  // UI Callbacks
  const submitStatus = useCallback(values => {
    dispatch(CustomerInteractionActions.createCustomerInteractionStatus(values))
  }, [ dispatch, ])

  const _onCancelClick = useCallback(() => {
    if (typeof onCancelClick === 'function') {
      onCancelClick()
    }
  }, [ onCancelClick, ])

  const initValues = useMemo(() => ({
    CustomerInteractionId,
    CustomerInteractionStatusId : '',
    AssignedToId                : '',
    Comment                     : '',
    CommentInternalOnly         : '',
    ContactedReportingParty     : '',
  }), [ CustomerInteractionId, ])

  return <Formik
    initialValues={initValues}
    onSubmit={submitStatus}
    validationSchema={CUSTOMER_INTERACTION_STATUS_SCHEMA}
  >
    <Form>
      <input
        type={'hidden'}
        name={'CustomerInteractionId'}
        value={CustomerInteractionId}
      />
      <small className={'d-block mt-1 mb-3'}>All labels with the<RequiredIndicator /> indicate required inputs.</small>
      <FormGroup>
        <Field name={'CustomerInteractionStatusId'}>
          {
            ({ field, form, }) => (
              <Select
                {...field}
                propertyName={field.name}
                label={'Status'}
                labelClassName={'m-0'}
                items={statuses}
                required={true}
                selectedValue={field.value}
                errorMessage={form.errors[field.name]}
              />
            )
          }
        </Field>
      </FormGroup>
      <Row>
        <Col xs={8}>
          <FormGroup>
            <AssignedStaff fieldName={'AssignedTo'} required={true} />
          </FormGroup>
        </Col>
        <Col xs={4} className={'d-flex align-items-center'}>
          <AssignToMe />
        </Col>
      </Row>
      <FormGroup>
        <YesNoButtonGroup name={'CommentInternalOnly'}>
          <RequiredLabel labelFor={'CommentInternalOnly'}>
            Is this comment for internal use only?
          </RequiredLabel>
        </YesNoButtonGroup>
      </FormGroup>
      <FormGroup>
        <YesNoButtonGroup name={'ContactedReportingParty'}>
          <RequiredLabel labelFor={'ContactedReportingParty'}>
            Did you contact the reporting party?
          </RequiredLabel>
        </YesNoButtonGroup>
      </FormGroup>
      <FormGroup>
        <RequiredLabel labelFor={'Comment'}>
          Provide a comment for this status update.
        </RequiredLabel>
        <Field
          id={'Comment'}
          name={'Comment'}
          type={'textarea'}
          component={ValidatingField}
        />
      </FormGroup>
      <Row className={'py-3'}>
        <Col className={'d-flex justify-content-between'}>
          <div>
            <Button
              color={'primary'}
              onClick={_onCancelClick}
            >Cancel</Button>
            <Button
              type={'reset'}
              color={'link'}
            >Reset</Button>
          </div>
          <Button
            type={'submit'}
            color={'secondary'}
          >Submit</Button>
        </Col>
      </Row>
    </Form>
  </Formik>
}

StatusForm.propTypes = {
  CustomerInteractionId : oneOfType([ number, string, ]),
  onCancelClick         : func,
}

export default React.memo(StatusForm)
